import React, { useEffect, useState } from 'react';
import Header from "../../components/Header";
import ClubLeftSection from "../../components/ClubLeftSection";
import ClubRightSection from "../../components/ClubRightSection";
import { Outlet, useSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import { CentralBase, PrincipalContainer } from './components';
import { ChatServiceInterface } from '../../interfaces/chat/ChatServiceInterface';

toast.configure();

interface AppLayoutProps{
    existEvents:any;
    isAdmin:any;
    ChatServiceInstance:ChatServiceInterface
}

const AppLayout = (props: AppLayoutProps) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeNotification, setActiveNotification] = useState(false);

    useEffect(() => {
        const logged = searchParams.get('signupLogged');
        const notification = searchParams.get('notifications');
        if (logged) {
            toast.error(t('toast_pleaseLogout'));
            navigate('/app');
        }
        if (notification) {
            setActiveNotification(true);
        }
    }, [])

    function handleActiveNotification(e: any) {
        setActiveNotification(e)
    }

    return (
        <PrincipalContainer>
            <ClubLeftSection isAdmin={props.isAdmin} existEvents={props.existEvents} ChatServiceInstance={props.ChatServiceInstance}/>
            <ClubRightSection
                activeNotification={activeNotification}
                handleActiveNotification={handleActiveNotification} />
            <Header handleActiveNotification={handleActiveNotification} ChatServiceInstance={props.ChatServiceInstance}/>
            <CentralBase>
                <div className="club-center">
                    <Outlet />
                </div>
            </CentralBase>
        </PrincipalContainer>
    );
}

export default AppLayout;