import "../../styles.scoped.css";
import '../../styles.css';
import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import ModalDiscardChanges from "./ModalDiscardChanges";
import Select  from 'react-select';
import { orgTypesZaahahSignup } from "../../../../helpers/orgTypeHelper";
import { getSchoolsYouth } from "../../../../store/actions/orgsActions";
import { getOrganizationType } from "../../../../store/actions/orgsActions";
import { cleanWebsite } from "../../../../containers/Signup";
import { Backend, MicroServices } from "../../../../helpers/backendHelper";
import { RootState } from "../../../../store/store";
import useGrades from "../../../../hooks/useGrades";
import SelectItem from "../../../../interfaces/SelectItem";
import { customStylesReactSelectYO, DropdownIndicator } from "../../../../helpers/selectHelper";
import usePostalCodes from "../../../../hooks/usePostalCodes";
import TClub from "../../../../interfaces/TClubs/TClub/TClub";
import { useTranslation } from "react-i18next";

toast.configure()

function ModalAddAdvisor(props: any) {

  const MIN_GRADE = 6;
  const MAX_GRADE = 12;

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [clubsSelected, setClubsSelected] = useState<string[]>([]);
  const [TClubs, setTClubs] = useState<TClub[]>([])

  useEffect(() => {
    dispatch<any>(getOrganizationType());
  }, []);

  const getTClubsByYO = () => {
    Backend(
        MicroServices.Club,
        `/tclubs/by-youth?yoId=${organization?.YOTableId}`
    )
        .then((response) => response.json())
        .then((results) => setTClubs(results))
  }

  const clubs = useSelector((state: RootState) => state.getTemplateClubs.templateClubs);

  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  );
  const orgT = useSelector((state: any) => state.getOrgs.organizationTypes);
  const orgTypes = orgTypesZaahahSignup(orgT);

  useEffect(() => {
    getTClubsByYO();
  }, [organization]);

  const { postalCodes: postalCodesConfig, setCountry: setCountryPostal } = usePostalCodes();

  const { gradesSelect, setCountry: setCountryGrades } = useGrades();
  const [lowGradeSelect, setLowGradeSelect] = useState<SelectItem>();
  const [highGradeSelect, setHighGradeSelect] = useState<SelectItem>();
  
  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryGrades(organization?.countryCode);
      setCountryPostal(organization?.countryCode);
    }
  }, [])

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryGrades(organization?.countryCode);
      setCountryPostal(organization?.countryCode);
    }
  }, [organization])

  const getOptionGrades = () => (
    <>
      {Array.from(Array(MAX_GRADE - MIN_GRADE + 1).keys()).map((number) => (
        <option value={number + MIN_GRADE}>{number + MIN_GRADE}</option>
      ))}
    </>
  );

  const [loadingSchool, setLoadingSchool] = useState(false);
  const [showDiscard, setShowDiscard] = useState(false);

  const [schoolName, setSchoolName] = useState('');
  const [schoolType, setSchoolType] = useState('SCHOOL');
  const [schoolWebsite, setSchoolWebsite] = useState('');
  const [lowGrade, setLowGrade] = useState('6');
  const [highGrade, setHighGrade] = useState('6');
  const [schoolZipcode, setSchoolZipcode] = useState('');

  const [principalEmail, setPrincipalEmail] = useState('');
  const [principalName, setPrincipalName] = useState('');
  const [principalLast, setPrincipalLast] = useState('');

  const createSchool = () => {

    Backend(
      MicroServices.AccessManager,
      `/auth/sign-up-organization`, {
      method: 'POST',
      body: JSON.stringify({
        organization: {
          organizationName: schoolName,
          organizationType: schoolType,
          lowGrade: parseInt(lowGrade),
          highGrade: parseInt(highGrade),
          zipcode: schoolZipcode,
          website: cleanWebsite(schoolWebsite),
          calendarEnd: '05-20',
          YOParentId: organization.id,
          templateClubs: clubsSelected,
          countryCode: organization?.countryCode,
        },
        user: {
          givenName: principalName,
          familyName: principalLast,
          email: principalEmail,
          zipcode: schoolZipcode,
        }
      }),
    })
      .then((results) => {
        if (results.status === 201) {
          toast.success(`${schoolName} ${t('toast_invited')}`);
          onCancel('discard');
          dispatch(getSchoolsYouth(organization.id));
        } else {
          toast.error(`${t('toast_errorInviting')} ${schoolName}. ${t('toast_tryAgain')}`)
        }
        setLoadingSchool(false);
      })
      .catch((e) => {
        toast.error(`${t('toast_errorInviting')} ${schoolName}. ${t('toast_tryAgain')}`)
        setLoadingSchool(false);
      })
  }

  const onCancel = (type: string) => {
    if (type === 'cancel') {
      setShowDiscard(false);
    }
    else {
      setLoadingSchool(false);
      setShowDiscard(false);
      setSchoolName('');
      setSchoolType('SCHOOL');
      setSchoolWebsite('');
      setLowGrade('6');
      setHighGrade('6');
      setSchoolZipcode('');
      setPrincipalEmail('');
      setPrincipalName('');
      setPrincipalLast('');
      setClubsSelected([]);
      setLowGradeSelect(undefined);
      setHighGradeSelect(undefined);
      props.setShow(false);
    }
  }

  const onhideModal = () => {
    setShowDiscard(true);
  }

  const handleSave = () => {
    setLoadingSchool(true);

    if (schoolName === '') {
      setLoadingSchool(false);
      toast.error(t('toast_pleaseAddSchoolName'));
      return;
    }

    if (schoolType === '') {
      setLoadingSchool(false);
      toast.error(t('toast_pleaseSelectSchoolType'));
      return;
    }

    if (schoolWebsite === '') {
      setLoadingSchool(false);
      toast.error(t('toast_pleaseAddWebsite'));
      return;
    }

    if (lowGrade === '') {
      setLoadingSchool(false);
      toast.error(t('toast_pleaseSelectLowGrade'));
      return;
    }

    if (highGrade === '') {
      setLoadingSchool(false);
      toast.error(t('toast_pleaseSelectHighGrade'));
      return;
    }

    if (schoolZipcode === '') {
      setLoadingSchool(false);
      toast.error(t('toast_pleasePostalCode'));
      return;
    } else if (schoolZipcode.length < (postalCodesConfig?.minLength ?? 5) || schoolZipcode.length > (postalCodesConfig?.maxLength ?? 5)) {
      setLoadingSchool(false);
      toast.error(`${t('toast_errorZipCode')} ${postalCodesConfig?.minLength ?? 5} ${t('toast_digits')}`);
      return;
    }

    if (principalName === '') {
      setLoadingSchool(false);
      toast.error(t('toast_addSchoolAdminFirstName'));
      return;
    }

    if (principalLast === '') {
      setLoadingSchool(false);
      toast.error(t('toast_addSchoolAdminLastName'));
      return;
    }

    if (principalEmail === '') {
      setLoadingSchool(false);
      toast.error(t('toast_inviteASchool'));
      return;
    }

    if (clubsSelected.length === 0) {
      setLoadingSchool(false);
      toast.error(t('toast_selectProgram'));
      return;
    }

    createSchool();
  }

  const onChangeClubSelected = (club_name: string) => {
    const clubsSelectedAux = [...clubsSelected];
    const index = clubsSelectedAux.indexOf(club_name)
    if (index !== -1) {
      clubsSelectedAux.splice(index, 1);
    } else {
      clubsSelectedAux.push(club_name);
    }
    setClubsSelected([...clubsSelectedAux]);
  };

  const handleChangeLowGrade = (e: any) => {
    setLowGrade(e.value);
    setLowGradeSelect(e);
  }

  const handleChangeHighGrade = (e: any) => {
    setHighGrade(e.value);
    setHighGradeSelect(e);
  }

  return (
    <>

      <Modal className="modalInviteMemebers" show={props.show} onHide={onhideModal}>
        <Modal.Header>
          <Modal.Title>Add Affiliated School or Other Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-schoolAdd more-space">

            <div className="schoolAdd-row schoolAdd-column">
              <div className="inputs-schoolAdd-row mt-10">
                <div className="option-status">
                  <label className="content-input pv-10">
                    <input
                      type="checkbox"
                      name="button"
                      value={'SCHOOL'}
                      checked={schoolType === 'SCHOOL'}
                      onChange={(e) => setSchoolType(e.target.value)}
                    />
                    <i></i>
                  </label>
                  <label htmlFor="students">School</label>
                </div>

                <div className="option-status">
                  <label className="content-input pv-10">
                    <input
                      type="checkbox"
                      name="button"
                      value={'OTHER'}
                      checked={schoolType === 'OTHER'}
                      onChange={(e) => setSchoolType(e.target.value)}
                    />
                    <i></i>
                  </label>
                  <label htmlFor="students">Other</label>
                </div>

              </div>
            </div>

            <div className="schoolAdd-row schoolAdd-column">
              <div className="inputs-schoolAdd">
                <label className="title-input" htmlFor="i-principal">
                  School or Organization Name*
                </label>
                <input
                  type="text"
                  value={schoolName}
                  onChange={(e) => setSchoolName(e.target.value)}
                />
              </div>
            </div>

            <div className="schoolAdd-row schoolAdd-column">
              <div className="inputs-schoolAdd">
                <label className="title-input" htmlFor="i-principal">
                  School or Organization Website*
                </label>
                <input
                  type="text"
                  value={schoolWebsite}
                  onChange={(e) => setSchoolWebsite(e.target.value)}
                />
              </div>
            </div>

            <div className="schoolAdd-row">
              <div className="grades-display">
                <label className="title-input" htmlFor="i-principal">
                  Low Grade Level*
                </label>
                <div className="select-list-addSchool-react" >
                  {/* <select className="select-addSchool"
                    onChange={(e) => setLowGrade(e.target.value)}
                    value={lowGrade} >
                    <option disabled>Select one type</option>
                    {getOptionGrades()}
                  </select>
                  <FontAwesomeIcon
                    icon="chevron-down"
                    size='lg'
                    color={LAVENDER}
                    className="chevron-down  "

                  /> */}
                  <Select
                      options={gradesSelect}
                      styles={customStylesReactSelectYO()}
                      components={{
                          DropdownIndicator,
                      }}
                      value={lowGradeSelect}
                      onChange={handleChangeLowGrade}
                      placeholder="Low Grade"
                  />
                </div>
              </div>

              <div className="grades-display">
                <label className="title-input" htmlFor="i-principal">
                  High Grade Level*
                </label>
                <div className="select-list-addSchool-react" >
                  {/* <select className="select-addSchool"
                    onChange={(e) => setHighGrade(e.target.value)}
                    value={highGrade} >
                    <option disabled>Select one type</option>
                    {getOptionGrades()}
                  </select>
                  <FontAwesomeIcon
                    icon="chevron-down"
                    size='lg'
                    color={LAVENDER}
                    className="chevron-down  "

                  /> */}
                  <Select
                      options={gradesSelect}
                      styles={customStylesReactSelectYO()}
                      components={{
                          DropdownIndicator,
                      }}
                      value={highGradeSelect}
                      onChange={handleChangeHighGrade}
                      placeholder="High Grade"
                  />
                </div>
              </div>
            </div>

            <div className="schoolAdd-row">
              <div className="inputs-schoolAdd">
                <label className="title-input" htmlFor="i-principal">
                  Administrator First Name*
                </label>
                <input
                  type="text"
                  value={principalName}
                  onChange={(e) => setPrincipalName(e.target.value)}
                />
              </div>

              <div className="inputs-schoolAdd">
                <label className="title-input" htmlFor="i-principal">
                  Administrator Last Name*
                </label>
                <input
                  type="text"
                  value={principalLast}
                  onChange={(e) => setPrincipalLast(e.target.value)}
                />
              </div>
            </div>

            <div className="schoolAdd-row schoolAdd-column">
              <label className="title-input" htmlFor="i-principal">
                Administrator Email*
              </label>
              <div className="inputs-schoolAdd">
                <input
                  type="text"
                  value={principalEmail}
                  onChange={(e) => setPrincipalEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="schoolAdd-row schoolAdd-column">

              {/*<div className="inputs-schoolAdd">
                <label className="title-input" htmlFor="i-principal">
                  School Type
                </label>
                <div className="select-list-addSchool" >
                  <select className="select-addSchool"
                    onChange={(e) => setSchoolType(e.target.value)}
                    value={schoolType} >
                    <option disabled>Select one type</option>
                    {getOptionTypes()}
                  </select>
                  <FontAwesomeIcon
                    icon="chevron-down"
                    size='lg'
                    color={LAVENDER}
                    className="chevron-down  "

                  />
                </div>
              </div>*/}

              <div className="inputs-schoolAdd">
                <label className="title-input" htmlFor="i-principal">
                  Postal Code*
                </label>
                <input
                  type="text"
                  value={schoolZipcode}
                  onChange={(e) => setSchoolZipcode(e.target.value)}
                />
              </div>

            </div>

            <div className="schoolAdd-row schoolAdd-column">
              <label className="title-input-clubs-school pv-10" htmlFor="i-principal">
                {`${organization.name || 'Youth Organization'} Programs available at ${schoolName || 'this new Affiliated Organization'}*`}
              </label>
              <div className="inputs-schoolAdd mt-10">
                {TClubs.map((club) => (
                  <div className="option-status">
                    <label className="content-input pv-10">
                      <input
                        type="checkbox"
                        name="button"
                        value={club.name}
                        checked={clubsSelected.includes(club.id)}
                        onChange={() => onChangeClubSelected(club.id)}
                      />
                      <i></i>
                    </label>
                    <label htmlFor="students">{club.name}</label>
                  </div>
                ))}
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="buttonClose"
            onClick={() => setShowDiscard(true)}
          >
            Cancel
          </Button>
          <Button name="btnSaveAdvisor" className="buttonSend" onClick={handleSave}>
            {loadingSchool ? <div className="icon-spinner-third buttonSendSpinner" /> : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalDiscardChanges show={showDiscard} onHide={onCancel} />
    </>
  );
}

export default ModalAddAdvisor;
