import { useEffect, useMemo, useState } from "react";
import { Backend, MicroServices } from "../helpers/backendHelper";
import useActiveCountry from "./useActiveCountry";

const useTCListAvailableCountries = () => {
    const [usedCountries, setUsedCountries] = useState<string[]>([]);
    const { activeCountriesReactSelect } = useActiveCountry();

    const availableCountriesReactSelect = useMemo(
        () =>
            activeCountriesReactSelect.filter((country) =>
                !usedCountries.includes(country.value)
            ),
        [activeCountriesReactSelect, usedCountries]
    );

    const reloadUsedCountries = () => {
        Backend(MicroServices.Club, "/tclubs/tclists/used-countries")
            .then((response) => response.json())
            .then((results) => {
                if (!Array.isArray(results)) {
                    return;
                }
                setUsedCountries(results);
            });
    };

    useEffect(() => reloadUsedCountries(), []);

    return availableCountriesReactSelect;
};

export default useTCListAvailableCountries;
