import './index.css';
import { useTranslation } from "react-i18next";

export interface PageNumberProps {
    page: number,
    canGoBack: boolean,
    canGoNext: boolean,
    goBack: () => void,
    goNext: () => void,
};

const PageNumber = ({
    page,
    canGoBack,
    canGoNext,
    goBack,
    goNext,
}: PageNumberProps) => {
    const { t, i18n } = useTranslation();
    return (
        <div className="container">
            <div className="wrapper">
                <button
                    className='goBack'
                    disabled={!canGoBack}
                    onClick={goBack}>
                    {t('button__back')}
                </button>
                <span className='page'>{page + 1}</span>
                <button
                    className='goNext'
                    disabled={!canGoNext}
                    onClick={goNext}>
                    {t('button__next')}
                </button>
            </div>
        </div>
    )
};

export default PageNumber;