export const START_SAVE_EVENT = 'START_SAVE_EVENT';
export const SAVE_EVENT_SUCCESS = 'SAVE_EVENT_SUCCESS';
export const SAVE_EVENT_ERROR = 'SAVE_EVENT_ERROR';

export const START_GET_EVENT = 'START_GET_EVENT';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_ERROR = 'GET_EVENT_ERROR';

export const START_EVENT_RSVP = 'START_EVENT_RSVP';
export const EVENT_RSVP_SUCCESS = 'EVENT_RSVP_SUCCESS';
export const EVENT_RSVP_ERROR = 'EVENT_RSVP_ERROR';

export const START_EVENT_INTERESTED = 'START_EVENT_INTERESTED';
export const EVENT_INTERESTED_SUCCESS = 'EVENT_INTERESTED_SUCCESS';
export const EVENT_INTERESTED_ERROR = 'EVENT_INTERESTED_ERROR';

export const GET_MY_EVENT_ERROR = 'GET_MY_EVENT_ERROR';
export const GET_MY_EVENT_SUCCESS = 'GET_MY_EVENT_SUCCESS';
export const START_GET_MY_EVENT = 'START_GET_MY_EVENT';

export const GET_CHECK_EVENT_ERROR = 'GET_CHECK_EVENT_ERROR';
export const GET_CHECK_EVENT_SUCCESS = 'GET_CHECK_EVENT_SUCCESS';
export const START_GET_CHECK_EVENT = 'START_GET_CHECK_EVENT';

export const CHECK_IN_EVENT_ERROR = 'CHECK_IN_EVENT_ERROR';
export const CHECK_IN_EVENT_SUCCESS = 'CHECK_IN_EVENT_SUCCESS';
export const START_CHECK_IN_EVENT = 'START_CHECK_IN_EVENT';

export const CHECK_OUT_EVENT_ERROR = 'CHECK_OUT_EVENT_ERROR';
export const CHECK_OUT_EVENT_SUCCESS = 'CHECK_OUT_EVENT_SUCCESS';
export const START_CHECK_OUT_EVENT = 'START_CHECK_OUT_EVENT';

export const GET_COMPLETE_EVENTS_ERROR = 'GET_COMPLETE_EVENTS_ERROR';
export const GET_COMPLETE_EVENTS_SUCCESS = 'GET_COMPLETE_EVENTS_SUCCESS';
export const START_GET_COMPLETE_EVENTS = 'START_GET_COMPLETE_EVENTS';

export const GET_MY_EVENTS_RSVP_ERROR = 'GET_MY_EVENTS_RSVP_ERROR';
export const GET_MY_EVENTS_RSVP_SUCCESS = 'GET_MY_EVENTS_RSVP_SUCCESS';
export const START_GET_MY_EVENTS_RSVP = 'START_GET_MY_EVENTS_RSVP';

export const GET_MY_FRIENDS_EVENTS_ERROR = 'GET_MY_FRIENDS_EVENTS_ERROR';
export const GET_MY_FRIENDS_EVENTS_SUCCESS = 'GET_MY_FRIENDS_EVENTS_SUCCESS';
export const START_GET_MY_FRIENDS_EVENTS = 'START_GET_MY_FRIENDS_EVENTS';

export const LOGOUT_EVENT = 'LOGOUT_EVENT';