import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InfoMembers from './Tabs/InfoMembers';
import Description from './Tabs/Description';
import Meeting from './Tabs/Meeting';
import './styles.scoped.css';
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";

function Index(props: {
  show: boolean;
  onHide: any;
  club: any;
  setClub: any;
}) {
  const { t, i18n } = useTranslation();
  //States
  const [tabActive, setTabActive] = useState<number>(0);

  //Selectors
  const organization = useSelector((state: any) => state.getOrgs.organizationInfo);

  //Handlers
  function handleClose(e: any) {
    props.onHide(false)
  }
  function handleSave(e: any) {
    props.onHide(true)
  }

  function handleOnClickTab(e: any, tab: any) {
    setTabActive(tab);
  }

  useEffect(() => console.log(tabActive), [tabActive])

  return (
    <>
        <Modal className="modalCreateClub" show={props.show} onHide={()=>handleClose}>
            <Modal.Header>
              <Modal.Title>{props.club?.name || ''}</Modal.Title>
              <FontAwesomeIcon
                icon="times"
                size='xl'
                color={WHITE}
                className="icon-times  "
                onClick={handleClose}
              />
            </Modal.Header>
            <Modal.Body>
                <div className="nav">
                    <div className={tabActive == 0 ? 'active': ''} onClick={(e) => handleOnClickTab(e, 0)}>{t('infoClub__members')}</div>
                    <div className={tabActive == 1 ? 'active': ''} onClick={(e) => handleOnClickTab(e, 1)}>{t('setupSchool__description')}</div>
                    <div className={tabActive == 2 ? 'active': ''} onClick={(e) => handleOnClickTab(e, 2)}>{t('setupSchool__meeting')}</div>
                </div>
                {tabActive === 0 ?
                    <InfoMembers club={props.club} setClub={props.setClub} organization={organization}/>
                  : tabActive === 1 ?
                    <Description club={props.club} setClub={props.setClub}/>
                  : tabActive === 2 ?
                    <Meeting club={props.club} setClub={props.setClub}/>
                  : ''
                }
            </Modal.Body>
            <Modal.Footer>
              <Button className="buttonCancel" onClick={handleClose}>
              {t('button__cancel')}
              </Button>
              <Button className="buttonSend" onClick={tabActive === 2 ? handleSave : (e)=>handleOnClickTab(e,tabActive+1)}>
              {tabActive === 0 ?
                    t('button__next')
                  : tabActive === 1 ?
                  t('button__next')
                  : tabActive === 2 ?
                  t('button__save')
              : ''
        }
      </Button>
    </Modal.Footer>
  </Modal>
    </>
  );
}

export default Index;