import { isTCList } from "../../guards/TClubs/TCList";
import TCList from "../../interfaces/TClubs/TCList/TCList";
import { Backend, MicroServices } from "../backendHelper";

export const NOT_FOUND = 'NOT_FOUND';

export const getTCList = (id: string): Promise<TCList> =>
    new Promise((resolve, reject) => {
        Backend(
            MicroServices.Club,
            `/tclubs/tclists/${id}`
        )
            .then((response) => {
                if (response.status === 404) {
                    return Promise.reject(NOT_FOUND)
                }
                return response.json();
            })
            .then((tclist: TCList) => {
                if (!isTCList(tclist)) {
                    reject()
                    return;
                }
                resolve(tclist)
            })
            .catch(reject);
    })