
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../ClubIconsNavBar';
import { useTranslation } from "react-i18next";

function Index(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <>
        <Modal className="modalSendCAha" show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
                <FontAwesomeIcon
                  icon="times"
                  size='xl'
                  color={WHITE}
                  className="icon-times  "
                  onClick={() => props.onHide(false)}
                />
            </Modal.Header>
            <Modal.Body>
                <FontAwesomeIcon
                  icon="exclamation-circle"
                  size='lg'
                  color={LAVENDER}
                  className="Modalexclamation-circle  "
                />
                <span className="labelEventCreated"> {t('modal_downloadAha')}</span>
            </Modal.Body>
        </Modal>
    </>
  );
}

export default Index;