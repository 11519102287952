import { isINTList } from "../../guards/Interests/IntList";
import INTList from "../../interfaces/Interests/INTLists/INTList";
import { Backend, MicroServices } from "../backendHelper";

export const NOT_FOUND = 'NOT_FOUND';

export const getIntList = (id: string): Promise<INTList> =>
    new Promise((resolve, reject) => {
        Backend(
            MicroServices.Connections,
            `/interests/intlists/${id}`
        )
            .then((response) => {
                if (response.status === 404) {
                    return Promise.reject(NOT_FOUND)
                }
                return response.json();
            })
            .then((intList: INTList) => {
                if (!isINTList(intList)) {
                    reject()
                    return;
                }
                resolve(intList)
            })
            .catch(reject);
    })