import React from 'react'
import './styles.scoped.css'

const index = () => {
    return (
        <div className="footer">
            © StartUp Productions LLC, DBA The Possibility Company <a href="https://possibilitycompany.com/terms-of-service/">Terms of Service</a> - <a href="https://possibilitycompany.com/privacy-policy/">Privacy Policy.</a>
        </div>
    )
}

export default index