import { AnyAction } from "redux";
import { UserResponseMock } from "../../interfaces/user/UserResponse";
import {
  GET_PROFILE_ERROR,
  GET_PROFILE_SUCCESS,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  SIGNUP_ERROR,
  SIGNUP_RESTORE,
  SIGNUP_SUCCESS,
  START_GET_PROFILE,
  START_LOGIN,
  START_SIGNUP,
  START_FORGOT,
  FORGOT_SUCCESS,
  FORGOT_ERROR,
  START_GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  START_USER_STATUS,
  USER_STATUS_SUCCESS,
  USER_STATUS_ERROR,
  START_GET_USERS_LIMIT,
  GET_USERS_LIMIT_SUCCESS,
  GET_USERS_LIMIT_ERROR,
  START_USER_ROLES,
  USER_ROLES_SUCCESS,
  USER_ROLES_ERROR,
  START_USER,
  USER_SUCCESS,
  USER_ERROR,
  START_UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,

  START_USER_INFO,
  USER_INFO_SUCCESS,
  USER_INFO_ERROR,

  START_CREATE_USER_BY_INVITE,
  CREATE_USER_BY_INVITE_SUCCESS,
  CREATE_USER_BY_INVITE_ERROR,
  START_GET_AHA_BALANCE,
  GET_AHA_BALANCE_SUCCESS,
  GET_AHA_BALANCE_ERROR,

  START_SAVE_DEPARTMENTS,
  SAVE_DEPARTMENTS_SUCCESS,
  SAVE_DEPARTMENTS_ERROR,
  START_EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  RESTORE_ERROR,
  RESTORE_SUCCESS,
  START_RESTORE,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  START_CHANGE_PASSWORD,
  RELOAD_COGNITO_USER_ERROR,
  RELOAD_COGNITO_USER_SUCCESS,
  START_RELOAD_COGNITO_USER,
} from "../constants/userConstants";
import userStateInterface from "../../interfaces/store/userState";

const initialState: userStateInterface = {
  loadingLogin: false,
  loadingProfile: false,
  isLoggedIn: false,
  loadingSignup: false,
  orgCreated: false,
  loadingForgot: false,
  userOrgs: [],
  loadingUsers: false,
  users: [],
  usersError: undefined,
  loadingUsersStatus: false,
  usersStatus: [],
  usersStatusError: undefined,
  loadingUsersLimit: false,
  usersLimit: [],
  usersLimitError: undefined,
  loadingUsersRoles: false,
  usersRoles: [],
  usersRolesError: undefined,

  loadingUser: false,
  userError: undefined,
  user: { ...UserResponseMock },

  loadingUpdateUser: false,
  updateUserError: undefined,
  updateUser: [],

  loadingUserInf: false,
  userInfError: undefined,
  userInf: [],

  loadingGetAhaBalance: false,
  GetAhaBalanceError: undefined,
  GetAhaBalance: undefined,

  loadingUserDepartments: false,
  userDepartmentsError: undefined,
  userDepartments: undefined,

  loadingEditUser: false,
  editUserError: undefined,

  userInfo: undefined,
  userRoles: undefined,

  loadingCreateUserInvite: false,
  createUserInvite: undefined,
  createUserInviteError: undefined,

  loadingRestorePassword: false,
  loadingChangePassword: false,
  loadingReloadCognitoUser: false,
};

export const getUserReducer = (state = initialState, action: AnyAction) : userStateInterface => {
  switch (action.type) {
    // LOGIN
    case START_LOGIN:
      return {
        ...state,
        loadingLogin: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loadingLogin: false,
        isLoggedIn: true,
        userInfo: action.payload,
        userRoles: JSON.parse(
          action.payload?.attributes["custom:roles"] || []
        ).flat(1),
        userOrgs: JSON.parse(
          action.payload?.attributes["custom:organizations"] || []
        ).map((org: string, idx: number) => {
          return [
            org,
            JSON.parse(action.payload?.attributes["custom:roles"] || [])[idx] ||
            [],
          ];
        }),
        token: action.payload.auth_key,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loadingLogin: false,
        isLoggedIn: false,
        loginErrorMessage: action.payload.message,
        loginError: action.payload.obj,
      };

    // SIGNUP
    case START_SIGNUP:
      return {
        ...state,
        loadingSignup: true,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        loadingSignup: false,
        orgCreated: true,
      };
    case SIGNUP_RESTORE:
      return {
        ...state,
        orgCreated: false,
      };
    case SIGNUP_ERROR:
      return {
        ...state,
        loadingSignup: false,
      };
    // GET PROFILE
    case START_GET_PROFILE:
      return {
        ...state,
        loadingProfile: true,
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        loadingProfile: false,
        userProfile: action.payload,
      };
    case GET_PROFILE_ERROR:
      return {
        ...state,
        loadingProfile: false,
      };
    
      // RELOAD_COGNITO_USER PASSWORD
    case START_RELOAD_COGNITO_USER:
      return {
        ...state,
        loadingReloadCognitoUser: true,
      };
    case RELOAD_COGNITO_USER_SUCCESS:
      return {
        ...state,
        userRoles: action.payload,
        loadingReloadCognitoUser: false,
      };
    case RELOAD_COGNITO_USER_ERROR:
      return {
        ...state,
        loadingReloadCognitoUser: false,
      };

    // FORGOT PASSWORD
    case START_FORGOT:
      return {
        ...state,
        loadingForgot: true,
      };
    case FORGOT_SUCCESS:
      return {
        ...state,
        loadingForgot: false,
      };
    case FORGOT_ERROR:
      return {
        ...state,
        loadingForgot: false,
      };

    // RESTORE PASSWORD
    case START_RESTORE:
      return {
        ...state,
        loadingRestorePassword: true,
      };
    case RESTORE_SUCCESS:
      return {
        ...state,
        loadingRestorePassword: false,
      };
    case RESTORE_ERROR:
      return {
        ...state,
        loadingRestorePassword: false,
      };

    // CHANGE PASSWORD
    case START_CHANGE_PASSWORD:
      return {
        ...state,
        loadingChangePassword: true,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loadingChangePassword: false,
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        loadingChangePassword: false,
      };

    // GET ORGS
    case START_GET_USERS:
      return {
        ...state,
        loadingUsers: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loadingUsers: false,
      };
    case GET_USERS_ERROR:
      return {
        ...state,
        usersError: action.payload,
        loadingUsers: false,
      };

    // GET ORGS LIMIT
    case START_GET_USERS_LIMIT:
      return {
        ...state,
        loadingUsersLimit: true,
      };
    case GET_USERS_LIMIT_SUCCESS:
      return {
        ...state,
        usersLimit: action.payload.users,
        loadingUsersLimit: false,
      };
    case GET_USERS_LIMIT_ERROR:
      return {
        ...state,
        usersLimitError: action.payload,
        loadingUsersLimit: false,
      };

    // USER STATUS
    case START_USER_STATUS:
      return {
        ...state,
        loadingUsersStatus: true,
      };
    case USER_STATUS_SUCCESS:
      return {
        ...state,
        usersStatus: action.payload,
        loadingUsersStatus: false,
      };
    case USER_STATUS_ERROR:
      return {
        ...state,
        usersStatusError: action.payload,
        loadingUsersStatus: false,
      };

    case START_USER_ROLES:
      return {
        ...state,
        loadingUsersRoles: true,
      };
    case USER_ROLES_SUCCESS:
      return {
        ...state,
        loadingUsersRoles: false,
        usersRoles: action.payload,
      };
    case USER_ROLES_ERROR:
      return {
        ...state,
        loadingUsersRoles: false,
        usersRolesError: action.payload,
      };

    // USER
    case START_USER:
      return {
        ...state,
        loadingUser: true,
      };
    case USER_SUCCESS:
      return {
        ...state,
        loadingUser: false,
        user: action.payload,
      };
    case USER_ERROR:
      return {
        ...state,
        loadingUser: false,
        userError: action.payload,
      };

    case START_UPDATE_USER:
      return {
        ...state,
        loadingUpdateUser: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loadingUpdateUser: false,
        updateUser: action.payload,
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        loadingUpdateUser: false,
        updateUserError: action.payload,
      };
    // USER INFO
    case START_USER_INFO:
      return {
        ...state,
        loadingUserInf: true,
      };
    case USER_INFO_SUCCESS:
      return {
        ...state,
        loadingUserInf: false,
        userInf: action.payload,
      };
    case USER_INFO_ERROR:
      return {
        ...state,
        loadingUserInf: false,
        userInfError: action.payload,
      };
    // USER INFO
    case START_CREATE_USER_BY_INVITE:
      return {
        ...state,
        loadingCreateUserInvite: true,
      };
    case CREATE_USER_BY_INVITE_SUCCESS:
      return {
        ...state,
        loadingCreateUserInvite: false,
        createUserInvite: action.payload,
      };
    case CREATE_USER_BY_INVITE_ERROR:
      return {
        ...state,
        loadingCreateUserInvite: false,
        createUserInviteError: action.payload,
      };
    case LOGOUT:
      return {...initialState};

    case START_GET_AHA_BALANCE:
      return {
        ...state,
        loadingGetAhaBalance: true,
      }
    case GET_AHA_BALANCE_SUCCESS:
      return {
        ...state,
        loadingGetAhaBalance: false,
        GetAhaBalanceError: undefined,
        GetAhaBalance: action.payload,
      }
    case GET_AHA_BALANCE_ERROR:
      return {
        ...state,
        loadingGetAhaBalance: false,
        GetAhaBalanceError: action.payload,
        GetAhaBalance: undefined,
      }

    // USER DEPARTMENTS
    case START_SAVE_DEPARTMENTS:
      return {
        ...state,
        loadingUserDepartments: true,
      }
    case SAVE_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        loadingUserDepartments: false,
        userDepartments: action.payload
      }
    case SAVE_DEPARTMENTS_ERROR:
      return {
        ...state,
        loadingUserDepartments: false,
        userDepartmentsError: action.payload,
      }

    // EDIT USER
    case START_EDIT_USER:
      return {
        ...state,
        loadingEditUser: true,
      }
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        loadingEditUser: false,
      }
    case EDIT_USER_ERROR:
      return {
        ...state,
        loadingEditUser: false,
        editUserError: action.payload,
      }

    default:
      return state;
  }
};
