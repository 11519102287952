import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../store/store";
import Categories from '../Categories';
import { ACTION_TYPE, ClubCreate, DELETE_TYPE } from "../Clubs";
import ListClubs from '../ListClubs';
import { TagsWrapper, TClubsWrapper, Wrapper } from "./TClubList.components";

interface TClubListProps {
    searchTerm: string;
    createCustomClub: () => void;
    dbTClubs: string[];
    readyToCreateTClubs: string[];
    readyToDeleteTClubs: string[];
    handleClickTClub: (type: ACTION_TYPE, tclubId: string) => void;
    handleClickDeleteTClub: (type: DELETE_TYPE, tclubId: string) => void;
}

const TClubList = ({
    searchTerm,
    createCustomClub,
    dbTClubs,
    readyToCreateTClubs,
    readyToDeleteTClubs,
    handleClickTClub,
    handleClickDeleteTClub
}: TClubListProps) => {
    const tags = useSelector((state: RootState) => state.getTClubs.TClubsTags);
    const tclubs = useSelector((state: RootState) => state.getTClubs.TClubs);
    const [selectedCategory, setSelectedCategory] = useState<string>('All');

    const tclubsFiltered = useMemo(
        () => tclubs
            .filter((tclub) => `${tclub.name}$${tclub.tags.join('$')}$${tclub.youth_orgs?.map((yo) => yo.yo_name).join('$')}`.toLowerCase().includes(searchTerm.toLowerCase()))
            .filter((tclub) => selectedCategory === 'All' || tclub.tags.includes(selectedCategory)),
        [
            searchTerm,
            tclubs,
            selectedCategory,
        ]
    )

    return (
        <Wrapper>
            <TagsWrapper>
                <Categories
                    categories={tags} 
                    setSelectedCategory={setSelectedCategory} 
                    selectedCategory={selectedCategory} 
                />
            </TagsWrapper>
            <TClubsWrapper>
                <ListClubs
                    tclubs={tclubsFiltered}
                    createCustomClub={createCustomClub}
                    dbTClubs={dbTClubs}
                    readyToCreateTClubs={readyToCreateTClubs}
                    readyToDeleteTClubs={readyToDeleteTClubs}
                    handleClickTClub={handleClickTClub}
                    handleClickDeleteTClub={handleClickDeleteTClub}
                />
            </TClubsWrapper>
        </Wrapper>
    )
};

export default TClubList;