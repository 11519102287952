import React, { useEffect, useState } from "react";
import "../styles.scoped.css";
import '../styles.css';
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { WHITE } from "../../ClubIconsNavBar";
import ModalInviteMembers from "./ModalInviteMembers";
import ModalAddAdvisor from "./ModalAddAdvisor";

function CardCommunity(props: any) {

  const { community, organization, staffMembers, createCommunities, reloadStaff, getCommunities } = props;

  const [showInviteMembers, setShowInviteMembers] = useState(false);
  const [showInviteAdv, setShowInviteAdv] = useState(false);

  const buttonText = () => {
    return (community.title.includes('Class')) ?
      `Invite ${(community.invitesSend > 1) ? ('More') : ('')} Students` : `Invite ${(community.invitesSend > 1) ? ('More') : ('')} ${community.title}`;
  }

  const handleCloseInvite = () => {
    setShowInviteMembers(false);
  }

  const handleCloseAdv = () => {
    setShowInviteAdv(false);
  }

  return (
    <>
      <div
        id={community.id}
        key={community.id}
        className={`card card-${community.active ? "active" : "desactive"}`}
      >
        <div className={`card-header type-${community.title}`}>
          <FontAwesomeIcon
            icon={faUser}
            size='xl'
            color={WHITE}
            className="icon-user  "
          />
          <FontAwesomeIcon
              className={`icon-header icon-${community.icon}`}
              icon={community.icon}
              size='xl'
              color={WHITE}
            />
          <div>
            <p>{community.name ?? community.title}</p>
            <span>{community.desc}</span>
          </div>
        </div>

        {community.advisors.length > 0 ? (
          <div className="staff-inputs">

            <div className="row">
              <label htmlFor="">
                {community.advisors.length === 1
                  ? "Advisor"
                  : `${community.advisors.length} Advisors`}
              </label>
            </div>

            <div className="adv-club-list">
              {community.advisors.map((adv: any) => (
                <div className="row adv-list-color" key={JSON.stringify(adv)}>
                  <input disabled={true} type="text" defaultValue={`${adv.firstName || adv.firstname || adv.name || ''} ${adv.lastName || adv.lastname || ''}`} />
                  {community.advisors.length > 1 ? (
                    <FontAwesomeIcon
                      icon="times"
                      size='xl'
                      color={WHITE}
                      className="icon-times-white  "
                    />
                  ) : (<></>)}
                </div>
              ))}
            </div>


            <div className="card-adv-label">
              Please invite an invited member of Staff to be an Advisor.
            </div>

          </div>
        ) : (
          <div className="card-desc">
            Please invite an invited member of Staff to be an Advisor.
          </div>
        )}

        <div className="add-advisor">
          <button
            name="btn-addAdvisor"
            className="btnAddAdvisor"
            onClick={() => setShowInviteAdv(true)}
          >
            <FontAwesomeIcon
              icon="user-plus"
              size='xl'
              color={WHITE}
              className="icon-user  "

            />
            <p>Add Advisor</p>
          </button>
        </div>

        <button
          name="btn-FillMembers"
          className={`buttonSendInvites ${community.advisors.length > 0 ? "active" : "inactive"
            } `}
          onClick={() => setShowInviteMembers(true)}
          disabled={community.advisors.length === 0 }
        >
          {buttonText()}
        </button>
        <div className="invites-count">
          {(community.invitesSend > 1) ? `${(community.title === 'Staff') ? (community.invitesSend - community.advisors.length + 1) : (community.invitesSend - community.advisors.length)} invites sent` : ''}
        </div>
      </div>



      <ModalInviteMembers 
        type={community.title} 
        show={showInviteMembers} 
        onHide={handleCloseInvite} 
        club_id={community.club_id} 
        organization={organization}
        createCommunities={createCommunities}
        setShowInviteMembers={setShowInviteMembers}
        reloadStaff={reloadStaff}
        getCommunities={getCommunities}
      />

      <ModalAddAdvisor 
        show={showInviteAdv} 
        onHide={handleCloseAdv} 
        club={community} 
        org={organization}
        staffMembers={staffMembers}
        getCommunities={getCommunities}
        setShowInviteAdv={setShowInviteAdv}
      />
    </>
  );
}

export default CardCommunity;
