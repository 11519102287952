import {UserResponse} from "../interfaces/user/UserResponse";
import User from "../interfaces/user/User";
import { Backend, MicroServices } from "./backendHelper";

export const responseToUser = (response: UserResponse) => ({
    // id: response.SK.split('#')[1],
    // roles: response.roles,
    // state: response.state || '',
    // gender: response.LSI2.split('#')[0],
    // status: response.status,
    // country: response.country,
    // created: response.creationDate,
    // updated: response.updateDate || '',
    // postalCode: response.LSI3.split('#')[response.LSI3.split('#').length - 1],
});

export const UserToResponse = (response: UserResponse, user: User) => ({
    // ...response,
    // LSI2: [user.gender, ...response.LSI2.split('#').slice(1)].join('#'),
    // roles: user.roles,
    // state: user.state,
    // status: user.status,
    // country: user.country,
});

export const getNameUser = (user: UserResponse, userInfo?: any,  userRoles?: any[], organization?: any) => {

    if(userRoles && userRoles.includes('SUPER')){
        return 'Zaahah Superadmin'
    }

    if(userRoles && organization && userRoles.includes('ZAA#ORGADMIN')){
        return organization.name;
    }

    if(user?.firstname && user?.lastname){
        return `${user?.firstname} ${user?.lastname}` || ''
    }

    if(userInfo){
        return userInfo.username || ''
    }
    
    return ''
};

export const formatGender = (gender: string) => {
    if(gender === 'M'){
        return 'Male'
    }

    if(gender === 'F'){
        return 'Female'
    }

    if(gender === 'O'){
        return 'Non binary'
    }

    if(gender === 'P'){
        return 'Prefer not to disclose'
    } //@audit-issue change to avoid O and P gender @comment Dismissed since this is just for formatting

    return '';
}

export const isValidString = (name: any) => typeof name === "string" && name.length > 0;

export const isValidUsername = (username: string): boolean => {

    if(typeof username !== "string"){
        return false;
    }

    if(username.length === 0 || username.length > 15){
        return false;
    }

    if(username.indexOf(" ") !== -1){
        return false;
    }

    if(username.indexOf("&") !== -1){
        return false;
    }

    if(username.indexOf(";") !== -1){
        return false;
    }

    if(username.indexOf("/") !== -1){
        return false;
    }

    if(username.indexOf("[") !== -1){
        return false;
    }

    if(username.indexOf("]") !== -1){
        return false;
    }

    if(username.indexOf(":") !== -1){
        return false;
    }

    if(username.indexOf("|") !== -1){
        return false;
    }

    if(username.indexOf("+") !== -1){
        return false;
    }

    if(username.indexOf("=") !== -1){
        return false;
    }

    if(username.indexOf(",") !== -1){
        return false;
    }

    if(username.indexOf("?") !== -1){
        return false;
    }

    if(username.indexOf("*") !== -1){
        return false;
    }

    if(username.indexOf("%") !== -1){
        return false;
    }

    if(username.indexOf("@") !== -1){
        return false;
    }

    if(username.indexOf("#") !== -1){
        return false;
    }

    if(username.indexOf('"') !== -1){
        return false;
    }

    if(username.indexOf("'") !== -1){
        return false;
    }

    if(username.indexOf("{") !== -1){
        return false;
    }

    if(username.indexOf("}") !== -1){
        return false;
    }

    if(username.indexOf("(") !== -1){
        return false;
    }

    if(username.indexOf(")") !== -1){
        return false;
    }

    if(username.indexOf("¨") !== -1){
        return false;
    }

    if(username.indexOf("´") !== -1){
        return false;
    }

    if(username.indexOf("`") !== -1){
        return false;
    }

    if(username.indexOf("-") !== -1){
        return false;
    }

    if(username.indexOf(".") !== -1){
        return false;
    }

    return true;
}

export const isUserRegistered = (email: string, org_id: string) => new Promise((resolve, reject) => {
    Backend(
        MicroServices.Admin,
        `/users?email=${email}&org=${org_id}`)
        .then((response) => response.json())
        .then((results) => {
          if(results.users && results.users.length > 0){
            resolve(true);
          }
          else{
            resolve(false)
          }
        })
        .catch((error) => {
            reject(error)
        });
})