import React, { SyntheticEvent, useEffect, useState } from "react";
import Header from "../Header";
import styled from "styled-components";
import {
  RobotocondensedRegularNormalWhite16,
  Fontawesome5proRegularNormalLavende,
  RobotocondensedBoldLavender16px,
} from "../../styledMixins";
import "./styles.css";
import AdminLeftMenu from "../AdminLeftMenu";
import InvitesTableAdmin from "../InvitesTableAdmin";
import "../../styles.css";
import Select, { SingleValue } from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import { getUsersWithLimit } from "../../store/actions/userActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
import PageNumber from "../PageNumber";
import SearchTable from "../SearchTable";
import { useTranslation } from "react-i18next";

const PAGE_SIZES: pageSize[] = [10, 50];
export type pageSize = 10 | 50 | 10000;

function Invites() {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<pageSize>(PAGE_SIZES[0]);
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.getUser.token);
  const lastEvaluatedkeys = useSelector((state: any) => state.getOrgs.lastEvaluatedkeys);

  useEffect(() => {
    if (page === 0) {
      dispatch(getUsersWithLimit({ 'token': token, 'limit': pageSize, 'page': page }))
    } else {
      dispatch(getUsersWithLimit({ 'token': token, 'limit': pageSize, 'page': page, 'lastEvaluatedKey': lastEvaluatedkeys[page] }))
    }
  }, [pageSize, page]);

  const handleSetPageSize = (newValue: SingleValue<{ label: string; value: pageSize; }>) => {
    setPageSize(newValue?.value || 10)
  }

  const customStyles = () => ({
    option: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#383838',
    }),
    input: (styles: any, state: any) => ({
      ...styles,
      border: 'none',
      color: 'white',
      fontWeight: "normal",
      paddingLeft: "5px",
      "&:focus": { backgroundColor: 'red' },
    }),
    container: (styles: any) => ({
      ...styles,
      padding: 0,
      backgroundColor: "#281159",
      borderRadius: 5,
    }),
    valueContainer: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: 'transparent',
      padding: 0,
    }),
    menu: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      backgroundColor: "#383838",
      textAlign: "left",
      width: 'auto',
      padding: '5px 10px',
      minWidth: '150px',
      fontFamily: "var(--font-family-roboto_condensed)",
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      textAlign: "left",
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "118px",
      borderRadius: "0px",
      boxShadow: "none",
      border: 0,
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "bold",
      maxWidth: "100%",
      backgroundColor: 'transparent',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: 'white',
      textDecoration: "underline",
      display: 'block',
    }),
    indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
    dropdownIndicator: (styles: any) => ({ ...styles, display: "none" }),
    noOptionsMessage: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: 'grey',
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "normal",
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: 'transparent',
      border: "none",
      paddingLeft: "5px",
      paddingRight: "5px",

    }),
  })

  const [searchSelected, setSearchSelected] = useState('');
  const [typeSelected, setTypeSelected] = useState('');
  const [statusSelected, setStatusSelected] = useState('');

  const viewingOptions = PAGE_SIZES.map((item) => ({
    label: `${item} results`,
    value: item,
  }))

  const canGoBack = () => page !== 0;
  const canGoNext = () => true;

  const goBack = () => {
    setPage(page - 1);
  };

  const goNext = () => {
    setPage(page + 1);
  };

  const handleChangeSearch = (event: any) => {
    setSearchSelected(event.target.value);
  }

  const handleChangeType = (event: any) => {
    setTypeSelected(event.value1);
  }

  const handleChangeStatus = (event: any) => {
    setStatusSelected(event.value1);
  }

  const typeOptions = [
    { value: '', label: 'All' },
    // { value: 'End-User', label: 'End User' },
    // { value: 'Club Token Invite', label: 'Club Token Invite' },
    // { value: 'Advisor', label: 'Advisor' },
    // { value: 'Requested', label: 'Requested' },
    // { value: 'Club Officer', label: 'Club Officer' },
    // { value: 'Club Player', label: 'Club Player' },
    // { value: 'Parent', label: 'Parent' },
    // { value: 'Event Moderator', label: 'Event Moderator' },
    // { value: 'Event Officer', label: 'Event Officer' },
    // { value: 'Conference Organizer', label: 'Conference Organizer' },
    // { value: 'Conference End - User', label: 'Conference End - User' },
    // { value: 'Event Invite', label: 'Event Invite' },
    { value: 'Student', label: 'Student', value1: 'ZAA#STUDENT' },
    { value: 'Staff', label: 'Staff', value1: 'ZAA#STAFF' },
    { value: 'Guardian', label: 'Guardian', value1: 'ZAA#GUARDIAN' },
    { value: 'Alumni', label: 'Alumni', value1: 'ZAA#ALUMNI' },
  ];

  const statusOptions = [
    { value: '', label: 'All', value1: '' },
    { value: 'New', label: 'New', value1: 'NEW' },
    { value: 'Sent', label: 'Sent', value1: 'SENT' },
    { value: 'Used', label: 'Used', value1: 'USED' },
  ];

  return (
    <div className="content-org">
      <TopColumn>
        <ManageUsersCol>
          <Title>{t('manageInvites__title')}</Title>
          <div className="headerInvites">
            <div className="filtersInvites">
              <SearchTable placeholder={t('manageInvites__searchByEmail')} onChange={handleChangeSearch} />
              {/* <div className="filterInputSearch">
                    <input className="inputSearch" 
                           onChange={handleChangeSearch}
                           type="text" 
                           placeholder="Search by Email"/>
                  </div> */}
              <div className="filterSelectType tableManageInvites">
                <label className="labelType" htmlFor="type">{t('manageInvites__inviteType')}</label>
                <Select
                  isMulti={false}
                  defaultValue={{ label: "All", value: '' }}
                  options={typeOptions}
                  styles={customStyles()}
                  onChange={handleChangeType}
                />
                <FontAwesomeIcon
                  icon="chevron-down"
                  size='lg'
                  color={LAVENDER}
                  className="chevron-down  "
                />
              </div>
              <div className="filterSelectStatus tableManageInvites">
                <label className="labelType" htmlFor="status">{t('manageInvites__inviteStatus')}</label>
                <Select
                  isMulti={false}
                  defaultValue={{ label: "All", value: '' }}
                  options={statusOptions}
                  styles={customStyles()}
                  onChange={handleChangeStatus}
                />
                <FontAwesomeIcon
                  icon="chevron-down"
                  size='lg'
                  color={LAVENDER}
                  className="chevron-down  "
                />
              </div>
            </div>
            <div className="show-view-row">
              <Viewing>
                <Viewing1>{t('comm__viewing')}</Viewing1>
                <Box>
                  <Select
                    value={{ value: pageSize, label: `${pageSize} results` }}
                    isMulti={false}
                    options={viewingOptions}
                    placeholder={"View"}
                    styles={customStyles()}
                    onChange={handleSetPageSize}
                  />
                  <ChevronDown />
                </Box>
              </Viewing>
            </div>
          </div>
        </ManageUsersCol>
      </TopColumn>
      <InvitesTableAdmin searchSelected={searchSelected} typeSelected={typeSelected} statusSelected={statusSelected} />
      <PageNumber
        page={page}
        canGoBack={canGoBack()}
        canGoNext={canGoNext()}
        goBack={goBack}
        goNext={goNext}
      />
    </div>
  );
}

const TopColumn = styled.div`
  height: 154px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
  justify-content: space-between;
`;

const ManageUsersCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.h1`
  min-height: 28px;
  font-family: var(--font-family-roboto_condensed);
  font-weight: 700;
  color: var(--lavender);
  font-size: var(--font-size-xxxl);
  letter-spacing: 0;
  padding-bottom: 25px;
`;

const ShowingItems = styled.p`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  align-self: flex-start;
  margin-top: 5px;
  letter-spacing: 0;
  align-self: center;
  text-align: center;
`;

const Viewing = styled.div`
  margin-left: 3px;
  display: flex;
  align-items: center;
  min-width: 197px;
  align-self: flex-end;
  font-family: var(--font-family-roboto_condensed);
`;

const Viewing1 = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  min-width: 50px;
  letter-spacing: 0;
`;

const Box = styled.div`
  height: 40px;
  margin-left: 17px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  min-width: 130px;
  background-color: var(--violent-violet);
`;

const ChevronDownWrapper = styled.div`
  ${Fontawesome5proRegularNormalLavende}
  min-height: 20px;
  margin-left: 27px;
  min-width: 18px;
  letter-spacing: 0;
`;

const ChevronDown = () => <ChevronDownWrapper>
  <FontAwesomeIcon icon="chevron-down" color="#FFFFFF" size="sm" />
</ChevronDownWrapper>

export default Invites;
