import "../../containers/SetupSchool/styles.scoped.css";
import { useEffect, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useSum from "../../hooks/useSum";
import ModalHeader from "../Modals/ModalHeader";
import Tabs, { Tab } from "../Tabs/Tabs";
import useCountWithFilter from "../../hooks/useCountWithFilter";
import TeamsSelect from "./Tabs/TeamsSelect";
import { SelectedTeams } from "../../containers/SetupSchool";
import { useTranslation } from "react-i18next";
import { Grade } from "./Tabs/TeamSelect";
import { castTemplateIdToTemplateName } from "./Teams";
import TClub from "../../interfaces/TClubs/TClub/TClub";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export interface Team {
  id: string;
  title: string;
  template_id: string;
  active: boolean | null;
  gendersAllowed: string[];
  gradesAllowed: number[];
}

type genders = 'F' | 'M' | 'C';

const gendersMap: { [key in genders]: string } = {
  'F': 'Girls',
  'M': 'Boys',
  'C': 'Coed'
};

const generateTeam = (
  title: string,
  genderAllowed: genders,
  idx: number,
  template_id:string
): Team => ({
  id: template_id + genderAllowed + "team" + idx,
  title: title,
  template_id: template_id,
  active: false,
  gendersAllowed: [genderAllowed],
  gradesAllowed: [],
});

const generateTeams = (template_id: string, genderAllowed: genders, selectedTeams: SelectedTeams, tteams:TClub[]): Team[] => {
  const category = castTemplateIdToTemplateName(template_id,tteams)
  const saved_teams = selectedTeams[template_id]
  const team1 = generateTeam(`${gendersMap[genderAllowed]} Freshman ${category} Team`, genderAllowed, 1, template_id)
  const team2 = generateTeam(`${gendersMap[genderAllowed]} JV ${category} Team`, genderAllowed, 2, template_id)
  const team3 = generateTeam(`${gendersMap[genderAllowed]} Varsity ${category} Team`, genderAllowed, 3, template_id)

  const find1 = saved_teams?.find((item) => item.id === team1.id)
  const find2 = saved_teams?.find((item) => item.id === team2.id)
  const find3 = saved_teams?.find((item) => item.id === team3.id)

  return [find1 ? find1 : team1, find2 ? find2 : team2, find3 ? find3 : team3]
}

interface ModalTeamProps {
  tteamID:string;
  showModal: boolean;
  hideModal: () => void;
  addSelectedTeams: (templateName: string, teams: Team[], type: 'Create' | 'Edit' | 'SaveEdit') => void;
  selectedTeams: SelectedTeams;
  setSelectedTeams: (e: any) => void,
  modalType: 'Edit' | 'Create' | 'SaveEdit';
  dynamoTeams: any[],
  setTeamsEdit: (e: any) => void,
  teamsEdit: { [key: string]: any[] },
  teamsDelete: string[],
  setTeamsDelete: (e: any) => void,
  grades: Grade[],
}

const getActiveTeams = (toFilter: Team[]): Team[] =>
  toFilter.filter((t) => t.active === null || t.active);

/**
 ** Abbreviations
 * f -> Female
 * m -> Male
 * c -> Coed
 */

function ModalTeam({
  tteamID,
  showModal,
  hideModal,
  addSelectedTeams,
  selectedTeams,
  setSelectedTeams,
  modalType,
  dynamoTeams,
  setTeamsEdit,
  teamsEdit,
  teamsDelete,
  setTeamsDelete,
  grades,
}: ModalTeamProps) {
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState<number>(0);
  const tteams = useSelector((state: RootState) => state.getTClubs.TTeam);

  const [fTeams, setFTeams] = useState<Team[]>([]);
  const [mTeams, setMTeams] = useState<Team[]>([]);
  const [cTeams, setCTeams] = useState<Team[]>([]);

  const fTeamsCount = useCountWithFilter<Team>(fTeams, getActiveTeams);
  const mTeamsCount = useCountWithFilter<Team>(mTeams, getActiveTeams);
  const cTeamsCount = useCountWithFilter<Team>(cTeams, getActiveTeams);

  const ModalTeamTabs: Tab[] = useMemo(() => ([
    { id: 0, label: `Girls${fTeamsCount > 0 ? ` - ${fTeamsCount} ` : ''}` },
    { id: 1, label: `Boys${mTeamsCount > 0 ? ` - ${mTeamsCount} ` : ''}` },
    { id: 2, label: `Coed${cTeamsCount > 0 ? ` - ${cTeamsCount} ` : ''}` },
  ]), [fTeamsCount, mTeamsCount, cTeamsCount]);

  const totalCount = useSum(fTeamsCount, mTeamsCount, cTeamsCount);

  useEffect(() => {
    setFTeams(generateTeams(tteamID, "F", selectedTeams, tteams));
    setMTeams(generateTeams(tteamID, "M", selectedTeams, tteams));
    setCTeams(generateTeams(tteamID, "C", selectedTeams, tteams));
  }, [selectedTeams, tteamID]);

  const addTeams = () => {
    const newSelectedTeams = [
      ...getActiveTeams(fTeams),
      ...getActiveTeams(mTeams),
      ...getActiveTeams(cTeams),
    ];
    const yoTeam = selectedTeams[tteamID]?.find((item)=>item.id.slice(-1)==='0')
    if(yoTeam){
      addSelectedTeams(tteamID, [...newSelectedTeams, yoTeam], modalType);
    }else{
      addSelectedTeams(tteamID, newSelectedTeams, modalType);
    }
    hideModal();
  };

  return (
    <>
      <Modal className="modalCreateClub" show={showModal} onHide={hideModal}>
        <ModalHeader title={`${castTemplateIdToTemplateName(tteamID,tteams)} Teams`} hideModal={hideModal} />
        <Modal.Body>
          <Tabs
            tabs={ModalTeamTabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {activeTab == 0 ? (
            <TeamsSelect
              teams={fTeams}
              setTeams={setFTeams}
              selectedTeams={selectedTeams}
              setSelectedTeams={setSelectedTeams}
              modalType={modalType}
              dynamoTeams={dynamoTeams}
              setTeamsEdit={setTeamsEdit}
              teamsEdit={teamsEdit}
              teamsDelete={teamsDelete}
              setTeamsDelete={setTeamsDelete}
              grades={grades}
            />
          ) : activeTab == 1 ? (
            <TeamsSelect
              teams={mTeams}
              setTeams={setMTeams}
              selectedTeams={selectedTeams}
              setSelectedTeams={setSelectedTeams}
              modalType={modalType}
              dynamoTeams={dynamoTeams}
              setTeamsEdit={setTeamsEdit}
              teamsEdit={teamsEdit}
              teamsDelete={teamsDelete}
              setTeamsDelete={setTeamsDelete}
              grades={grades}
            />
          ) : activeTab == 2 ? (
            <TeamsSelect
              teams={cTeams}
              setTeams={setCTeams}
              selectedTeams={selectedTeams}
              setSelectedTeams={setSelectedTeams}
              modalType={modalType}
              dynamoTeams={dynamoTeams}
              setTeamsEdit={setTeamsEdit}
              teamsEdit={teamsEdit}
              teamsDelete={teamsDelete}
              setTeamsDelete={setTeamsDelete}
              grades={grades}
            />
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="buttonClose" onClick={hideModal}>
            {t('button__cancel')}
          </Button>
          <Button className="buttonSend" onClick={addTeams}>
            {totalCount === 0 ? t('button__save') : `${t('button__save')} ${totalCount > 0 ? `${totalCount}` : ''}
            ${t('teams__title')}${totalCount > 1 ? 's' : ''}`}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalTeam;
