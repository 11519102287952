import { AnyAction } from "@reduxjs/toolkit";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import i18n from '../../i18n';
import {
  APPROVE_ORG_ERROR,
  APPROVE_ORG_SUCCESS,
  GET_ORGS_ERROR,
  GET_ORGS_LIMIT_ERROR,
  GET_ORGS_LIMIT_SUCCESS,
  GET_ORGS_SUCCESS,
  GET_ORG_ERROR,
  GET_ORG_SUCCESS,
  ORG_NAMES_ERROR,
  ORG_NAMES_SUCCESS,
  ORG_STATUS_ERROR,
  ORG_STATUS_SUCCESS,
  ORG_TYPE_ERROR,
  ORG_TYPE_SUCCESS,
  START_APPROVE_ORG,
  START_GET_ORG,
  START_GET_ORGS,
  START_GET_ORGS_LIMIT,
  START_ORG_NAMES,
  START_ORG_STATUS,
  START_ORG_TYPE,
  START_UPDATE_ORG,
  UPDATE_ORG_ERROR,
  UPDATE_ORG_SUCCESS,
  START_CLUB_NAMES,
  CLUB_NAMES_SUCCESS,
  CLUB_NAMES_ERROR,
  START_UPDATE_LOGO_ORG,
  UPDATE_LOGO_ORG_SUCCESS,
  UPDATE_LOGO_ORG_ERROR,
  START_UPLOAD_ASSET_ORG,
  UPLOAD_ASSET_ORG_SUCCESS,
  UPLOAD_ASSET_ORG_ERROR,
  START_UPLOAD_LOGO_ORG,
  UPLOAD_LOGO_ORG_SUCCESS,
  UPLOAD_LOGO_ORG_ERROR,
  START_ORG_TITLE,
  ORG_TITLE_SUCCESS,
  ORG_TITLE_ERROR,
  START_GET_STAFF_USERS,
  GET_STAFF_USERS_SUCCESS,
  GET_STAFF_USERS_ERROR,
  START_YOUTH_SCHOOLS,
  YOUTH_SCHOOLS_ERROR,
  YOUTH_SCHOOLS_SUCCESS,
} from "../constants/orgsConstants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCommunities } from "./clubActions";
import { useSelector } from "react-redux";
import { Backend, MicroServices } from "../../helpers/backendHelper";

toast.configure()

export const getOrganizations = (token: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_ORGS });
    Backend(
      MicroServices.Admin,
      `/organizations`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_ORGS_SUCCESS,
          payload: results.organizations,
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_ORGS_ERROR,
          payload: error,
        });
      })
  });

export const getOrganizationsWithLimit = (
  token: string,
  limit: number,
  page = 0,
  lastEvaluatedKey = '',
  name = '',
  type = '',
  status = '',
  location = ''
): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    let params = '';
    params += page === 0 ? '' : `&lastEvaluatedKey=${lastEvaluatedKey}`;
    params += name === '' ? '' : `&name=${name}`;
    params += status === '' ? '' : `&status=${status}`;
    params += type === '' ? '' : `&type=${type}`;
    params += location === '' ? '' : `&location=${location.replace(/#/g, '_')}`;
    dispatch({ type: START_GET_ORGS_LIMIT });
    Backend(
      MicroServices.Admin,
      `/organizations?limit=${limit}${params}`
    )
      .then((response) => response.json())
      .then((results) => {
        console.log(results.lastEvaluatedKey);
        dispatch({
          type: GET_ORGS_LIMIT_SUCCESS,
          payload: {
            organizations: results.organizations,
            lastEvaluatedKey: results.lastEvaluatedKey,
            page,
          },
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_ORGS_LIMIT_ERROR,
          payload: error,
        });
      })
  });


export const getOrganizationStatus = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_ORG_STATUS });
    Backend(
      MicroServices.Roster,
      `/organizations/status`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: ORG_STATUS_SUCCESS,
          payload: results.orgStatus,
        })
      })
      .catch((error) => {
        dispatch({
          type: ORG_STATUS_ERROR,
          payload: error,
        });
      })
  });

export const getOrganizationType = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_ORG_TYPE });
    Backend(
      MicroServices.Roster,
      `/organizations/types?title=true`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: ORG_TYPE_SUCCESS,
          payload: (results.orgStatus || []).filter((orgtype: any) => orgtype[0] !== 'OTHER'),
        })
      })
      .catch((error) => {
        dispatch({
          type: ORG_TYPE_ERROR,
          payload: error,
        });
      })
  });

export const getOrganizationNames = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_ORG_NAMES });
    Backend(
      MicroServices.Admin,
      `/organizations/list`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: ORG_NAMES_SUCCESS,
          payload: results,
        })
      })
      .catch((error) => {
        dispatch({
          type: ORG_NAMES_ERROR,
          payload: error,
        });
      })
  });

export const getSchoolsYouth = (orgId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_YOUTH_SCHOOLS });
    Backend(
      MicroServices.Admin,
      `/organizations/${orgId}/child`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: YOUTH_SCHOOLS_SUCCESS,
          payload: results,
        })
      })
      .catch((error) => {
        dispatch({
          type: YOUTH_SCHOOLS_ERROR,
          payload: error,
        });
      })
  });

export const getClubNames = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_CLUB_NAMES });
    Backend(
      MicroServices.Club,
      `/clubs`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: CLUB_NAMES_SUCCESS,
          payload: results.clubs,
        })
      })
      .catch((error) => {
        dispatch({
          type: CLUB_NAMES_ERROR,
          payload: error,
        });
      })
  });

export const getCityNames = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_ORG_NAMES });
    Backend(
      MicroServices.Admin,
      `/location/activeCities`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: ORG_NAMES_SUCCESS,
          payload: results,
        })
      })
      .catch((error) => {
        dispatch({
          type: ORG_NAMES_ERROR,
          payload: error,
        });
      })
  });


export const getOrganizationInfo = (id: string, getComm = true): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_ORG });
    Backend(
      MicroServices.Admin,
      `/organizations/${id}?pending=true`)
      .then(async (response) => {
        if (response.status === 400) {
          const responseNotPending = await Backend(
            MicroServices.Admin,
            `/organizations/${id}?pending=false`)
          return responseNotPending.json();
        }
        return response.json()
      })
      .then((results) => {
        if (getComm) {
          dispatch(getCommunities(id, (results?.status as string)?.startsWith('PENDI')));
        }
        dispatch({
          type: GET_ORG_SUCCESS,
          payload: results,
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
          type: GET_ORG_ERROR,
          payload: error,
        })
      })
  });

export const ApproveOrganization = (id: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_APPROVE_ORG });
    const {t} = i18n;

    Backend(
      MicroServices.AccessManager,
      `/auth/approve-organization`,
      {
        method: 'POST',
        body: JSON.stringify({
          "organizationId": id,
        }),
      })
      .then((results) => {
        dispatch({
          type: APPROVE_ORG_SUCCESS,
        })
        console.log(results)
        if (results.status === 201) {
          toast.success(t('toast_orgApproved'));
          resolve(true);
          return;
        }
        return results.json();
      })
      .then((results) => {
        if (results && 'error' in results) {
          console.log('results', results)
          toast.error(results.error.error)
          dispatch({
            type: APPROVE_ORG_ERROR,
            payload: results.error.error,
          });
          resolve(false);
        }
      })
      .catch((error) => {
        console.log('error', error);

        dispatch({
          type: APPROVE_ORG_ERROR,
          payload: error,
        });
        resolve(false);
        toast.error(t('toast_errorApproving'));
      })
  });

export const updateOrg = (form: any, id: string, pending: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_UPDATE_ORG });
    let params = pending ? '?pending=true' : '?pending=false';
    Backend(
      MicroServices.Admin,
      `/organizations/${id}${params}`,
      {
        method: 'PUT',
        body: JSON.stringify(form),
      },
    )
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: UPDATE_ORG_SUCCESS,
          payload: results,
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_ORG_ERROR,
          payload: error,
        });
        reject(error)
      })
  });

export const updateLogoOrg = (contentType: string, key: string, type: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_UPLOAD_ASSET_ORG });
    Backend(
      MicroServices.Roster,
      `/media/upload-asset`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "ContentType": contentType,
          "Key": key,
          "Type": type
        }),
      },
    )
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: UPLOAD_ASSET_ORG_SUCCESS,
          payload: results,
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
          type: UPLOAD_ASSET_ORG_ERROR,
          payload: error,
        });
        reject(error)
      })
  });

export const uploadImage = (url: string, contentType: string, image: any): ThunkAction<Promise<Response>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_UPLOAD_LOGO_ORG });
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': decodeURIComponent(contentType)
      },
      body: image
    })
      .then((results) => {
        dispatch({
          type: UPLOAD_LOGO_ORG_SUCCESS,
          payload: results,
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
          type: UPLOAD_LOGO_ORG_ERROR,
          payload: error,
        });
        reject(error)
      })
  });

export const updateImageOrg = (logo: string, id: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_UPDATE_LOGO_ORG });
    Backend(
      MicroServices.Admin,
      `/organizations/${id}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          "logo": logo,
        }),
      },
    )
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: UPDATE_LOGO_ORG_SUCCESS,
          payload: results,
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_LOGO_ORG_ERROR,
          payload: error,
        });
        reject(error)
      })
  });

export const getStaffUsers = (organization:any,user:any): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_STAFF_USERS });
    Backend(
      MicroServices.Invite,
      `/invites?pending=${organization.status.startsWith("P") ? "true" : "false"
      }&org_id=${organization.id}&role=ZAA%23STAFF`
    )
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_STAFF_USERS_SUCCESS,
          payload: [{
            email: user.email,
            name: user.firstname,
            lastname: user.lastname
          },
          ...results.map((invite: any) => {
            return {
              email: invite.email,
              name: invite.firstName,
              lastname: invite.lastName,
            };
          })],
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_STAFF_USERS_ERROR,
          payload: error,
        })
      });
  });