import React, { useEffect, useState } from "react";
import Header from "../Header";
import "./styles.css";
import SuperAdminLeftMenu from "../SuperAdminLeftMenu";
import AdminLeftMenu from "../AdminLeftMenu";
import "../../styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../store/store";
import User, { UserMock } from "../../interfaces/user/User";
import { getUser, getUsersRoles, getUsersStatus, updateUser } from "../../store/actions/userActions";
import { UserResponse, UserResponseMock } from "../../interfaces/user/UserResponse";
import { responseToUser, UserToResponse } from "../../helpers/userHelper";
import { useSelector } from "react-redux";
import { getActiveCities, getActiveCountries } from "../../store/actions/locationActions";
import { unixToDateFormatted } from "../../helpers/dateHelper";
import { handleOnChangeSelect } from "../../helpers/formHelper";
import { onlyZAArolesMain} from "../../helpers/roleHelper";
import { capitalize } from "../../helpers/letterHelper";
import OrganizationComplete from "../../interfaces/organization/OrganizationComplete";
import Organization from "../../helpers/orgHelper";
import useGender from "../../hooks/useGender";

// TODO: Send to constants or call API, decide
const GENDER_MAP: {[key: string]: string} = {
  M: 'Male',
  F: 'Female',
  O: 'Non binary', //'Non binary',
  P: 'Prefer not to disclose', //'Prefer not to disclose',
}; //@audit-issue change to avoid O and P gender

/*const GENDER_MAP: {[key: string]: string} = {
  M: 'Male',
  F: 'Female'
};*/

function Organizations() {

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [userForm, setUserForm] = useState<UserResponse>(UserResponseMock);
  const [userResponse, setUserResponse] = useState<UserResponse>(UserResponseMock);
  const [organization, setOrganization] = useState<OrganizationComplete>();


  const roles = useSelector((state: any) => state.getUser.usersRoles);
  const usersStatus = useSelector((state: any) => state.getUser.usersStatus);

  const userRoles = onlyZAArolesMain(roles); 
  const { genders, setCountry: setCountryGender} = useGender();

  useEffect(() => {
    dispatch<any>(getUsersRoles());
    dispatch<any>(getUsersStatus());
    dispatch<any>(getActiveCities());
    dispatch<any>(getActiveCountries());
    dispatch<any>(getUser(id || ''))
      .then((userResponse: UserResponse) => {
        setUserForm(userResponse);
        setUserResponse(userResponse);
      })
  }, []);

  useEffect(() => {
    if (userForm && userForm.organizations.length > 0) {
      Organization.getOrgById(userForm.organizations[0])
        .then((orgResponse) => {
          setOrganization(orgResponse)
        })
    }
  }, [userForm])

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryGender(organization.countryCode);
    }
  }, [organization]);

  const handleOnChangeUserSelect = (
    e: React.ChangeEvent<HTMLSelectElement>,
    inputName: string,
  ) => handleOnChangeSelect(
    e,
    inputName,
    userForm,
    setUserForm,
  )

  const handleUserSave = () => {
    const formToSent = {...userResponse, ...userForm};
    dispatch<any>(updateUser(id || '', formToSent))
    navigate(`/users/${id}/view`);
  }

  const handleChangeGender = (e: any) => {
    setUserForm({
      ...userForm,
      gender: e.target.value,
    });
  };

  const handleChangeRoles = (e: any) => {
    const role = e.target.value;
    console.log(e);
    
    let newRoles = [...userForm.roles];
    if (e.target.checked) {
      newRoles.push(role)
    } else {
      newRoles = newRoles.filter((roleItem: string) => roleItem !== role )
    }

    const newRolesClean = [...Array.from(new Set(newRoles))];
    console.log(newRolesClean)
    setUserForm({
      ...userForm,
      roles: [...newRolesClean]
    })
  };

  const handleOnChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputName: string,
  ) => setUserForm({
    ...userForm,
    [inputName]: e.target.value,
  })

  return (
    
        <div className="content">
          <div className="top-cont">
            <div className="organization robotocondensed-normal-white-16px">Manage Users</div>
            <div className="text-1 robotocondensed-normal-white-16px">/</div>
            <div className="view-organization robotocondensed-normal-white-16px">View Users</div>
            <div className="text-1 robotocondensed-normal-white-16px">/</div>
            <div className="view-organization robotocondensed-bold-lavender-16px">Update User</div>
          </div>
          <div className="org-view-cont">
            <div className="title-column">
              <h1 className="title-name">User</h1>
              <div className="buttons-box">
                <div className="button-w">
                  <div className="cancel">Cancel</div>
                </div>
                <div className="button-p">
                  <div className="save roboto-normal-white-16px" onClick={handleUserSave}>Save</div>
                </div>
              </div>
            </div>
            <div className="view-columns">
              <div className="col-1">
                <div className="info-field-column">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Gender</div>
                  <div className="select-container-status" onChange={handleChangeGender}>
                    {genders.map(({id: genderKey, label: genderValue}) => (
                      <div className="option-status">
                        <label className="content-input">
                          <input
                            type="radio"
                            name="button"
                            value={genderKey}
                            checked={userForm.gender === genderKey}
                          />
                          <i></i>
                        </label>
                        <label htmlFor="students">{genderValue}</label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="info-field-column">
                  <div className="label-cont robotocondensed-bold-lavender-16px">User Roles</div>
                  <div className="roles-container-update-user" onChange={(e) => handleChangeRoles(e)}>
                    {userRoles.map((role: any[]) => (
                      <div className="option-role-update-user robotocondensed-normal-lavender-16px">
                        <label className="content-input">
                          <input
                            type="checkbox"
                            name="button"
                            value={role[0]}
                            checked={userForm.roles.includes(role[1])}
                          />
                          <i></i>
                        </label>
                        <label htmlFor="students">{role[1]}</label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Postal Code</div>
                  <div className="info-field" id="input-color" >
                    <input
                      className="info-field-update roboto-normal-white-16px"
                      type="text"
                      name="name"
                      value={userForm.zipcode}
                      onChange={
                        (e: React.ChangeEvent<HTMLInputElement>) =>
                          handleOnChangeInput(e, 'zipcode')
                      }
                    />
                  </div>
                </div>

                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">State</div>
                  <div className="info-field" id="transparent-back">
                    <div className="roboto-normal-white-16px">{userForm.state}</div>
                  </div>
                </div>

                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Country</div>
                  <div className="info-field" id="transparent-back">
                    <div className="roboto-normal-white-16px">{userForm.country}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Status</div>
                  <div className="info-field" id="input-color">
                    <select
                      className="info-field-update roboto-normal-white-16px"
                      value={userForm.status}
                      onChange={
                        (e: React.ChangeEvent<HTMLSelectElement>) =>
                          handleOnChangeUserSelect(e, 'status')
                      }
                    >
                      {usersStatus.map((status: any) =>
                        <option
                          value={status}
                        >
                          {capitalize(status)}
                        </option>
                      )}
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-2">
                <h1 className="title-col-2">Registration</h1>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">ID</div>
                  <div className="info-field" id="transparent-back">
                    <div className="roboto-normal-white-16px">{userForm.username}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Created</div>
                  <div className="info-field" id="transparent-back">
                    <div className="roboto-normal-white-16px">{unixToDateFormatted(userForm.creationDate)}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Updated</div>
                  <div className="info-field" id="transparent-back">
                    <div className="roboto-normal-white-16px">{unixToDateFormatted(userForm.creationDate)}</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
  );
}

export default Organizations;
