import "../../containers/SetupSchool/styles.scoped.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTemplateClubsGrouped } from "../../store/actions/templateClubActions";
import { RootState } from "../../store/store";
import Categories from './Categories';
import ListClubs from './ListClubs';
import ModalClub from './ModalClub';
import OrganizationComplete from "../../interfaces/organization/OrganizationComplete";
import { Advisor } from "./Communities";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../ClubIconsNavBar";
import ModalKillClub from "./ModalKillClub";
import ModalLoading from "./ModalLoading";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import TClubList from "./ClubTab/TClubList";
import ClubSetupHelper from '../../helpers/club/ClubSetupHelper';

export interface ClubCreate {
    name: string;
    template_id: string;
    member_type: string[];
    maximumNumberMembers: number;
    gendersAllowed: string[];
    gradesAllowed: number[];
    advisors: Advisor[];
    description: string;
    created: boolean;
    skills: string[];
    international: boolean;
    national: boolean;
    regional: boolean;
    local: boolean;
    applicationRequired: boolean;
    clubDuesRequired: boolean;
    meetingDays: string[];
    meetingStart: string;
    meetingEnd: string;
    timezone: string;
    meetingFrequency: string;
    meetingLocation: string;
    id?: string;
    team_distinguisher?: string;
    allowInd?: boolean;
}

interface ClubsProps {
    organization?: OrganizationComplete,
    handleOnClick: (e: any, step: number) => void,
    updateCommInvites: () => void,
    setClubs: (e: any) => void,
    clubs: ClubCreate[],
    setClubsEdit: (e: any) => void,
    clubsEdit: any[],
    clubsDelete: string[],
    setClubsDelete: (e: any) => void,
    alreadyLoaded: { get: boolean, set: any },
    dynamoClubs: { get: any[], set: any }
}

export type ACTION_TYPE = 'SAVE_EDIT' | 'ADD' | 'EDIT';
export type DELETE_TYPE = 'SAVE_DELETE' | 'DELETE' | 'NO_DELETE';

function ClubsTab(props: ClubsProps) {
    const { t, i18n } = useTranslation();
    const [showSetupClub, setShowSetupClub] = useState(false);
    const [title, setTitle] = useState('');
    const [paramSearch, setParamSearch] = useState('');
    const [creatingClubs, setCreatingClubs] = useState<boolean>(false);
    const [change, setChange] = useState<boolean>(false)
    const [clubToEdit, setClubToEdit] = useState<any | undefined>(undefined)
    const [modalClubType, setModalClubType] = useState<'Create' | 'Edit' | 'SaveEdit'>('Create')
    const [prevAdvisors, setPrevAdvisors] = useState<{ [id: string]: Advisor[] }>({})
    const [loading, setLoading] = useState<boolean>(true)
    const [loadingProtection, setLoadingProtection] = useState<boolean>(false)
    const [createCustom, setCreateCustom] = useState<boolean>(false)
    const [dbTClubs, setDBTClubs] = useState<string[]>([])

    const tclubs = useSelector((state: RootState) => state.getTClubs.TClubs);

    //Saved Info
    const getSavedInfo = () => {
        if (props.organization) {
            setLoading(true);
            Backend(
                MicroServices.Admin,
                `/organizations/${props.organization.id}/saved-info?type=CLUB`)
                .then((response) => response.json())
                .then((results) => {
                    setPrevAdvisors(results.ClubAdvisors)
                    props.dynamoClubs.set(results.DynamoClubs)
                    setDBTClubs(results.DynamoClubs.map(({template_id}: any) => template_id));
                })
                .catch(() => toast.error(t('toast_errorLoadingSaved')))
                .finally(() => {
                    props.alreadyLoaded.set(true)
                    setLoading(false)
                })
        }
    };
    useEffect(() => {
        if (!props.alreadyLoaded.get) { getSavedInfo() }
        else { setLoading(false) }
    }, [])

    const dispatch = useDispatch();

    function handleCloseSetupClub(club?: any) {
        if (!club) {
            setShowSetupClub(false)
            setCreateCustom(false)
            return
        }
        switch (modalClubType) {
            case 'Create':
                const clubAux = [...props.clubs];
                if (club && 'name' in club) {
                    clubAux.push(club);
                }
                setShowSetupClub(false)
                setCreateCustom(false)
                props.setClubs(clubAux);
                break
            case 'Edit':
                const clubAuxEdit = [...props.clubs];
                if (club && 'name' in club) {
                    clubAuxEdit[clubAuxEdit.findIndex((item) => item.template_id === club!.template_id)] = club!
                }
                setShowSetupClub(false)
                setCreateCustom(false)
                props.setClubs(clubAuxEdit);
                break
            case 'SaveEdit':
                const clubAuxSaveEdit = [...props.clubsEdit];
                if (club && 'name' in club) {
                    const idx = clubAuxSaveEdit.findIndex((item) => item.template_id === club!.template_id)
                    if (idx === -1) {
                        clubAuxSaveEdit.push(club)
                    } else {
                        clubAuxSaveEdit[idx] = club
                    }
                }
                setShowSetupClub(false)
                setCreateCustom(false)
                props.setClubsEdit(clubAuxSaveEdit);
                break
        }
    };

    const handleClickTClub = (type: ACTION_TYPE, tclubId: string) => {
        setChange(true)
        const tclub = tclubs.find(({id}) => id === tclubId);
        switch(type) {
            case 'SAVE_EDIT':
                const clubSaveEdit = props.clubsEdit.find((club: any) => club.id === tclubId) ?
                    props.clubsEdit.find((club: any) => club.id === tclubId) :
                    props.dynamoClubs.get.find((club: any) => club.id === tclubId)
                setClubToEdit(clubSaveEdit)
                setModalClubType("SaveEdit")
                setTitle(clubSaveEdit?.name || tclub?.TCLists?.[0]?.assignedName || tclub?.name || '');
                setShowSetupClub(true);
                break;
            case 'ADD':
                setClubToEdit({
                    name: tclub?.TCLists?.[0]?.assignedName || tclub?.name,
                    template_id: tclubId,
                    description: tclub?.description,
                    skills_learned: tclub?.tags,
                    member_type: tclub?.member_types,
                    yo: tclub?.youth_orgs,
                    allowInd: tclub?.allowInd
                })
                setModalClubType('Create')
                setTitle(tclub?.TCLists?.[0]?.assignedName || tclub?.name || '');
                setShowSetupClub(true);
                break;
            case 'EDIT':
                const clubEdit = props.clubs.find((club: any) => club.template_id === tclubId)
                setClubToEdit(clubEdit)
                setModalClubType("Edit")
                setTitle(clubEdit?.name || tclub?.TCLists?.[0]?.assignedName || tclub?.name || '');
                setShowSetupClub(true);
                break;
            default:
                break;
        }
    };

    const handleOnClickClub = (e: any, title: any, type: string, customName?: string) => {
        setChange(true)
        switch (type) {
            case 'Create':
                setClubToEdit(undefined)
                setModalClubType(type)
                setTitle(title);
                setShowSetupClub(true);
                break
            case 'Edit':
                //
                setClubToEdit(props.clubs.find((club: any) => club.template_id === title))
                setModalClubType(type)
                setTitle(title);
                setShowSetupClub(true);
                break
            case 'SaveEdit':
                //
                setClubToEdit(props.clubsEdit.find((club: any) => club.template_id === title) ?
                    props.clubsEdit.find((club: any) => club.template_id === title) :
                    props.dynamoClubs.get.find((club: any) => club.template_id === title))
                setModalClubType(type)
                setTitle(title);
                setShowSetupClub(true);
                break
            case 'Delete':
                if (title !== '') {
                    props.setClubs(props.clubs.filter((club: any) => club.template_id !== title));
                } else {
                    props.setClubs(props.clubs.filter((club: any) => club.name !== customName));
                }
                break
            case 'SaveDelete':
                setShowDeleteModal(true)
                setDeleteModalText({
                    title: title !== 'NONE' ? title : `Custom Club: ${customName}`,
                    message: `Are you sure you want to delete ${title !== 'NONE' ? title : customName} Club?`
                })
                break
            case 'ReCreate':
                props.setClubsDelete(props.clubsDelete.filter((item) => item.split('#')[1] !== title))
                handleOnClickClub(e, title, 'SaveEdit')
                break
            default:
                break
        }
    };

    const handleSave = (e: any, goToTeams = false) => {
        if (!props.organization) return;
        setCreatingClubs(true);
        Promise.all([
            ...props.clubs.map((club) => ClubSetupHelper.saveClubAndAdv(club, props!.organization!.id, props!.organization!.status.startsWith('P'))),
            ...props.clubsEdit.map((club) => ClubSetupHelper.editClub(club, props!.organization!.id)),
            ...props.clubsDelete.map((item) => ClubSetupHelper.deleteClub(item.split('#')[0])),
            ...props.clubsEdit.map((club) => ClubSetupHelper.updateAdvisors(club.id, prevAdvisors[club.id], club.advisors, props!.organization!.id))
        ])
            .catch((error) => {
                // handle error creating club
                toast.error(t('toast_thereWasAnErrorCreating'))
                executeFrontErrorProtection()
                console.log('Error creating clubs', error);
            })
            .then((response) => {
                if (!response) {
                    // error
                    toast.error(t('toast_thereWasAnErrorCreating'))
                    executeFrontErrorProtection()
                    return;
                }

                const statusAll = response.reduce((acc, curr) => acc && curr, true)
                if (statusAll) {
                    props.alreadyLoaded.set(false)
                    props.setClubs([])
                    props.setClubsDelete([])
                    props.setClubsEdit([])
                    goToTeams && props.handleOnClick(e, 4);
                    !goToTeams && getSavedInfo();
                } else {
                    toast.error(t('toast_thereWasAnErrorCreating'))
                    executeFrontErrorProtection()
                    // error
                }
            })
            .finally(() => {
                setCreatingClubs(false)
            })
    }

    //Front protection when error occurs
    const executeFrontErrorProtection = () => {
        setLoadingProtection(true)
        getSavedInfo()
    }
    useEffect(() => {
        if (loadingProtection) {
            props.setClubs(props.clubs.filter((club) => props.dynamoClubs.get.findIndex((dynamo) => club.template_id === dynamo.template_id) === -1))
            props.setClubsDelete(props.clubsDelete.filter((clubid) => props.dynamoClubs.get.findIndex((dynamo) => dynamo.id === clubid.split('#')[0]) !== -1))
            setLoadingProtection(false)
        }
    }, [props.dynamoClubs])

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [deleteModalText, setDeleteModalText] = useState<{ message: string, title: string }>({ message: '', title: '' })
    const closeModalDelete = (del: boolean) => {
        setShowDeleteModal(false)
        if (del) {
            if (deleteModalText.title.includes('Custom Club: ')) {
                const clubID = props.dynamoClubs.get.find((club: any) => club.name === deleteModalText.title.replace('Custom Club: ', '')).id
                props.setClubsDelete([...props.clubsDelete, `${clubID}#DELETED`])
            } else {
                const clubID = props.dynamoClubs.get.find((club: any) => club.template_id === deleteModalText.title).id
                props.setClubsDelete([...props.clubsDelete, `${clubID}#${deleteModalText.title}`])
                props.setClubsEdit(props.clubsEdit.filter((club: any) => club.template_id !== deleteModalText.title))
            }
        }
    }

    useEffect(() => {
        if (createCustom) {
            setTitle('') //For saved custom clubs, title is 'NONE'
            setShowSetupClub(true)
            setClubToEdit(undefined)
            setModalClubType('Create')
            setChange(true)
        }
    }, [createCustom])

    useEffect(() => console.log(props.clubsDelete), [props.clubsDelete])

    const handleClickDeleteTClub = (type: DELETE_TYPE, tclubId: string) => {};

    return (
        <>
            <div className="container-clubs-C">
                <h1>Zaahah</h1>
                <div className="btnWrapper">
                    {change ? (
                        <>
                            <button
                                className="button-skip"
                                onClick={(e) => props.handleOnClick(e, 4)}
                                disabled={creatingClubs}
                            >{t('setupSchool__discardChanges')}</button>
                            <button
                                name="btn-Step3-Save"
                                className="button-save"
                                onClick={(e) => handleSave(e, true)}
                                disabled={creatingClubs}
                            >
                                {creatingClubs ? (
                                    <div className="btn-center">
                                        <div className="icon-spinner-third buttonSendSignUp lilSendSignUp"></div>
                                    </div>
                                ) : t('setupSchool__saveGoToTeams')}
                            </button>
                        </>
                    ) : (
                        <button className="button-skip" onClick={(e) => props.handleOnClick(e, 4)}>{t('setupSchool__skip')}</button>
                    )}
                </div>
                <div className="GridSearchLetter">
                    <span>{t('setupSchool__weIdentified')} {props?.organization?.name}.</span>
                    <div className="contentInputSearchGrid">
                        <div className="inputSearch">
                            <FontAwesomeIcon
                                icon="search"
                                size='lg'
                                color={WHITE}
                                className="iconSearch  "
                            />
                            <input className="input-search" type="text" name="data" id="dataas" onChange={(e) => setParamSearch(e.target.value)} />
                        </div>
                        {/*<div className="custom-club-button" onClick={() => setCreateCustom(true)}>
                            <FontAwesomeIcon
                                icon="plus"
                                size='lg'
                                color={WHITE}
                                className=" "
                            />
                            <div className="custom-club-text">Custom Club</div>
                        </div>*/}
                    </div>
                </div>
            </div>
            <div className="selected-clubs">
                {props.dynamoClubs.get.filter((club: any) => !props.clubsDelete.includes(`${club.id}#${club.template_id}`))
                    .filter((club: any) => !props.clubsDelete.includes(`${club.id}#DELETED`)).length > 0 ? (
                    props.dynamoClubs.get.filter((club: any) => !props.clubsDelete.includes(`${club.id}#${club.template_id}`) && !props.clubsDelete.includes(`${club.id}#DELETED`))
                        .filter((club: any) => props.clubsEdit.find((c) => c.id === club.id) === undefined).map((club) => (
                            <div className="saved-club" onClick={() => handleClickTClub('SAVE_EDIT', club.id)} key={club.id + ''}>
                                <p>{club.name}</p>
                                <FontAwesomeIcon
                                    className='icon-times-2 '
                                    icon="times"
                                    size='xl'
                                    color={WHITE}
                                    onClick={(e) => handleOnClickClub(e, club.template_id, 'SaveDelete', club.name)}
                                />
                            </div>
                        ))
                ) : (<></>)}
                {props.clubs.length + props.clubsEdit.length > 0 ? (
                    ([...props.clubs, ...props.clubsEdit]).map((club) => (
                        <div className="selected-club" onClick={() => club && handleClickTClub('EDIT', club.template_id)} key={club.template_id + ''}>
                            <p>{club.name}</p>
                            <FontAwesomeIcon
                                className='icon-times-2 '
                                icon="times"
                                size='xl'
                                color={WHITE}
                                onClick={(e) => handleOnClickClub(e, club.template_id, 'Delete', club.name)}
                            />
                        </div>
                    ))
                ) : (<></>)}
            </div>
            <TClubList
                searchTerm={paramSearch}
                createCustomClub={() => setCreateCustom(true)}
                dbTClubs={dbTClubs}
                readyToCreateTClubs={props.clubs.map(({template_id}) => template_id)}
                readyToDeleteTClubs={props.clubsDelete.map((id) => id.split('#')[1])}
                handleClickTClub={handleClickTClub}
                handleClickDeleteTClub={handleClickDeleteTClub}
            />

            <ModalClub
                show={showSetupClub}
                onHide={handleCloseSetupClub}
                title={title}
                organization={props.organization}
                updateCommInvites={props.updateCommInvites}
                clubToEdit={clubToEdit}
                modalType={modalClubType}
            />

            <ModalKillClub
                show={showDeleteModal}
                message={deleteModalText.message}
                title={deleteModalText.title}
                onHide={closeModalDelete}
            />

            <ModalLoading show={loading || loadingProtection}
                message={loading ? 'Loading Information' : loadingProtection ? t('setupSchool__recoveringInformation') : t('setupSchool__loading')} />
        </>

    );
}

export default ClubsTab;
