import './styles.scoped.css'

interface SignUpHeaderProps {
  title?: string;
  subtitle?: string;
}

const SignUpHeader = ({title, subtitle}: SignUpHeaderProps) => (
  <>
    {title && <h1 className="titleLogin robotocondensed-bold-white-48px">{title}</h1>}
    <br/>
    {subtitle && <p className="titleLogin roboto-normal-white-16px subtitleRegister">{subtitle}</p>}
  </>
);

export default SignUpHeader;