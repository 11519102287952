import "./styles.scoped.css";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { isEmail } from "../../guards/SignupOrg";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { useTranslation } from "react-i18next";

toast.configure();

function Index(props: any) {
  const { t, i18n } = useTranslation();
  const [tabActive, setTabActive] = useState(0);
  const [emailString, setEmailString] = useState('');
  const [loadingAtteendees, setLoadingAttendees] = useState(false);

  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  );

  const {id = ''} = useParams();


  const cancelInvites = () => {
    setEmailString('');
    props.onHide()
    setLoadingAttendees(false);
  }

  const addAttendee = (email:string) => new Promise((resolve, reject) => {
    Backend(
      MicroServices.Events,
      `/event/${id}/invite`,
            {
                method: "POST",
                body:JSON.stringify({
                  email: email
                }), 
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then((response) => {
              if(response.status === 404){
                return resolve(addUnregisterAttendee(email));
              }

              if(response.status === 400){
                toast.error(`${email} ${t('toast_isAlreadyInvited')}`);
                return reject(false);
              }

              if(response.status !== 201){
                toast.error(t('toast_ThereWasError'));
                return reject(false);
              }
              else{
                return resolve(true);
              }
              
            })
            .catch((error) => {
                console.log(error)
            })
  })

  const addUnregisterAttendee = (email:string) => new Promise((resolve, reject) => {
    Backend(
      MicroServices.Events,
      `/event/${id}/invite-unregistered`,
            {
                method: "POST",
                body:JSON.stringify({
                  email: email,
                  event_id: id,
                  org_id: organization.id,
                }), 
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then((response) => {
              if(response.status === 404){
                toast.error(`${email} ${'toast_isNotRegistered'}`);
                return reject(false);
              }

              if(response.status === 400){
                toast.error(`${email} ${t('toast_isAlreadyInvited')}`);
                return reject(false);
              }

              if(response.status !== 201){
                toast.error(t('toast_ThereWasError'));
                return reject(false);
              }
              else{
                return resolve(true);
              }
              
            })
            .catch((error) => {
                console.log(error)
            })
  })

  const inviteAtteendees = () => {
    setLoadingAttendees(true);

    const emails = emailString.split(",");

    const toSend: string[] = [];

    for(let email of emails){
      const e = email.replace(/\s+/g, '');
      if(!isEmail(e)){
        toast.error(`${email} ${t('toast_isNotValidEmail')}`);
        setLoadingAttendees(false);
        return;
      }
      else{
        toSend.push(e);
      }
    }

    Promise.all(
      toSend.map((email:string) => addAttendee(email)
      ))
        .then((results) => {
          toast.success(t('toast_attendeesInvited'));
          cancelInvites();
        })
        .catch((error) => {
          cancelInvites();
          console.error(error);
        });
  }

  return (
    <>
        <Modal className="modalInviteAttendees" show={props.show} onHide={cancelInvites}>
            <Modal.Header>
              <p className="titleModal">Invite Attendees</p>
              <p className="modal-header">Enter email addresses separated by a comma.</p>
              <textarea name="" id="" placeholder={emailString} onChange={(e:any) => setEmailString(e.target.value)}></textarea>
            </Modal.Header>
            {/*<Modal.Body>
                <p className="textUpload">Upload invite list file (xls, csv accepted file types).</p>
                <span className="textFormat">Use following format: Email, First Name, Last Name, Gender, Date of Birth, Zip Code</span>
                <button className="btnFileExample">File example.</button>
                <div className="dragZone">
                    <p>Drag file here <br/> Or </p>
                    <button>Browse</button>
                </div>
            </Modal.Body> //@audit-issue invite unregistered (ZAA#UNDEFINED) users to event*/}
            <Modal.Footer>
              <Button className="buttonClose" onClick={cancelInvites}>
                  Cancel
              </Button>
              <Button className="buttonSend" onClick={inviteAtteendees} disabled={loadingAtteendees}>
                {loadingAtteendees ? (
                      <div className="icon-spinner-third buttonSendSignUp" />
                    ) : (
                      'Send Invites'
                    )}
              </Button>
            </Modal.Footer>
        </Modal>
    </>
  );
}

export default Index;