import React, { useState, useEffect } from "react";
import "./styles.css";
import "./SignUp2.css";
import "./styles.scoped.css";
import DatePicker from "react-datepicker";
import { useNavigate, useParams } from "react-router-dom";
import {
    getInvitesByToken,
    createUserByInvite,
} from "../../store/actions/inviteActions";
import { getOrganizationInfo } from "../../store/actions/orgsActions";
import { useDispatch, useSelector } from "react-redux";
import {
    getCity,
    getCountry,
    getLocation,
} from "../../store/actions/locationActions";
import { cityIdFormat, countryIdFormat } from "../../helpers/locationHelper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkBirth, dateToUpdate } from "../../helpers/dateHelper";
import { userAsMember } from "../../store/actions/clubActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from "../../components/ClubIconsNavBar";
import { isValidUsername } from "../../helpers/userHelper";
import { componentData } from "./signup_styles";
import {
    faBars,
    faClock,
    faMedal,
    faUserShield,
} from "@fortawesome/pro-regular-svg-icons";
import MenuWrp from "../../components/MenuWrp";
import useGender from "../../hooks/useGender";
import {
    ContainerSignup,
    ContentContainer,
    HeaderActionButtons,
    HeaderActions,
    HeaderLogin,
    HeaderLogo,
    LeftContainer,
    Overlap,
    RightContainer,
} from "../LoginWrp/components";
import Footer from "../../components/Footer";
import styled from "styled-components";
import i18n from '../../i18n';

toast.configure();

function SignUpContainer() {
    const dispatch = useDispatch();
    const invite = useSelector((state: any) => state.getInvites.inviteByToken);
    const location = useSelector((state: any) => state.getLocation.location);
    const city = useSelector((state: any) => state.getLocation.cities);
    const country = useSelector((state: any) => state.getLocation.countries);
    const loadingCreateUserInvite = useSelector(
        (state: any) => state.getUser.loadingCreateUserInvite || false
    );

    const navigate = useNavigate();

    // const { t, i18n } = useTranslation();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [gender, setGender] = useState("");
    const [myBirthday, setMyBirthday] = useState(new Date());
    const [password, setPassword] = useState("");
    const [preferedLanguage, setPreferedLanguage] = useState("english");

    const [club, setClub] = useState("");
    const [organization, setOrganization] = useState([]);
    const [organizationsNames, setOrganizationsNames] = useState("");
    const [role, setRole] = useState([]);
    const [zipcode, setZipCode] = useState("");
    const [tokenUser, setTokenUser] = useState("");
    const [countryCode, setCountryCode] = useState();

    const [isActive, setActive] = useState(false);
    const [activePopup, setActivePopup] = useState(false);

    const handleToggle = (e: any) => {
        e.preventDefault();
        setActive(!isActive);
    };

    const { token } = useParams();

    const {
        mainBackground,
        img1,
        img2,
        img3,
        oneUnifyingPlatfo,
        saveTeachersTimeA,
        overlapGroup1,
    } = componentData;

    const { genders, setCountry: setCountryGender } = useGender();

    const handleSubmit = () => {
        const correct = dataCorrect();

        if (correct === "Success") {
            createUser();
        }
    };

    const dataCorrect = () => {

        const {t} = i18n;
        //Check Birthdate entered and age>13
        const birthChecked = checkBirth(dateToUpdate(myBirthday));
        if (!birthChecked) {
            toast.error(t('toast_youMustBe'));
            return;
        }

        //Check gender entered
        if (gender === "") {
            console.log(gender);
            toast.error(t('toast_pleaseSelectGender'));
            return;
        }

        //Check firstname entered
        if (firstName === "") {
            toast.error(t('toast_pleaseFillFirstName'));
            return;
        }

        //Check lastname entered
        if (lastName === "") {
            toast.error(t('toast_pleaseFillLastName'));
            return;
        }

        //Check username entered
        if (!isValidUsername(userName)) {
            toast.error(
                t('toast_pleaseCheckUsername')
            );
            return;
        }

        //Check password entered
        if (password === "") {
            toast.error(t('toast_pleaseFillPassword'));
            return;
        }

        return "Success";
    };

    const createUser = () => {
        const {t} = i18n;
        const formToSend = {
            givenName: firstName,
            familyName: lastName,
            gender: gender,
            email: email,
            userName: userName,
            zipcode: zipcode,
            organizations: organization,
            organizationsNames: organizationsNames,
            roles: role,
            password: password,
            preferredLanguage: preferedLanguage,
            classYear: club,
            birthdate: dateToUpdate(myBirthday),
            location: location,
            city: city,
            country: country,
            countryCode: countryCode,
        };

        dispatch<any>(createUserByInvite(tokenUser, formToSend))
            .then((results: boolean) => {
                if (results) {
                    toast.success(t('toast_userCreated'));
                    navigate("/");
                }
            })
            .catch((error: any) => {
                console.error(error);
            });
    };

    const handleChangeRadio = (event: any) => {
        setGender(event.target.value);
    };

    const handleChange = (event: any) => {
        setPreferedLanguage(event.target.value);
    };

    function handleMenu(e: any) {
        setActivePopup(true);
    }

    function handleCloseMenu() {
        setActivePopup(false);
    }

    useEffect(() => {
        dispatch(
            getInvitesByToken({
                token: token,
            })
        );
    }, []);

    useEffect(() => {
        if (location && typeof location === "string") {
            dispatch<any>(getCountry(countryIdFormat(location)))
                .then((results: any) => { })
                .catch(console.log);

            dispatch<any>(getCity(cityIdFormat(location)))
                .then((results: any) => { })
                .catch(console.log);
        }
    }, [location]);

    useEffect(() => {
        const {t} = i18n;
        if (invite.status === "USED") {
            navigate("/");
            toast.error(t('toast_thisUserIsAlready'));
        }

        if (invite.status === "DELETED") {
            navigate("/");
            toast.error(t('toast_thisInviteIsDeleted'));
        } else if ("firstName" in invite) {
            setFirstName(invite.firstName);
            setLastName(invite.lastName);
            setEmail(invite.email);
            setGender(invite.gender);
            setMyBirthday(new Date(invite.dateOfBirth));

            setClub(invite.club_id);
            setOrganization(invite.org_id);
            setRole(invite.role);
            setTokenUser(invite.token);

            dispatch<any>(getOrganizationInfo(invite.org_id, false))
                .then((results: any) => {
                    setCountryGender(results.countryCode);
                    setCountryCode(results.countryCode);
                    setOrganizationsNames(results.name);
                    setZipCode(results.zipcode);
                })
                .catch(console.log);

            dispatch<any>(getLocation(invite.zipcode))
                .then((results: any) => { })
                .catch(console.log);
        }
    }, [invite]);

    return (
        <ContainerSignup>
            <Overlap active={activePopup} onClick={handleCloseMenu}>
                <MenuWrp activePopup={activePopup} />
            </Overlap>
            <HeaderLogin>
                <HeaderLogo />
                <HeaderActions>
                    <HeaderActionButtons>
                        <button
                            className="loginSignUp"
                            onClick={() => navigate("/")}
                        >
                            Login
                        </button>
                        <button
                            className="signUpSignUp"
                            onClick={() => navigate("/signup")}
                        >
                            Sign Up
                        </button>
                    </HeaderActionButtons>
                    <button className="menuSignUp">
                        <FontAwesomeIcon
                            icon={faBars}
                            size="xl"
                            color={"white"}
                            onClick={(e) => handleMenu(e)}
                            className="buttonMenu"
                        />
                    </button>
                </HeaderActions>
            </HeaderLogin>
            <ContentContainer className={'containerPanelPrincipal'}>
                <LeftContainer>
                    <div className="grayFilter" />
                    <div className="signUpInfo">
                        <h2>Schools and School Systems</h2>
                        <div className="textInfoFirst">
                            One, unifying platform to manage and promote all
                            extracurricular activities ensuring equal awareness
                            and access to opportunity.
                        </div>
                        <div className="textInfo">
                            <FontAwesomeIcon
                                icon={faClock}
                                size="xl"
                                color={"white"}
                            />
                            <p>
                                Save teachers time and receive previously
                                unavailable insight into school or system wide
                                engagement, i.e., by school, gender, ethnicity,
                                class year and socioeconomic status.
                            </p>
                        </div>
                        <div className="textInfo">
                            <FontAwesomeIcon
                                icon={faUserShield}
                                size="xl"
                                color={"white"}
                            />
                            <p>
                                Protect students, with a safe, invitation-only
                                social-network that promotes student exploration
                                of their interests through participation in
                                real-life activities.
                            </p>
                        </div>
                        <div className="textInfo">
                            <FontAwesomeIcon
                                icon={faMedal}
                                size="xl"
                                color={"white"}
                            />
                            <p>
                                Align student engagement with digital rewards –
                                supporting contextual financial literacy and the
                                opportunity for all student to earn
                                micro-scholarships.
                            </p>
                        </div>
                    </div>
                </LeftContainer>

                <RightContainerSignup>
                    <h1 className="valign-text-middle robotocondensed-bold-white-48px">
                        Zaahah Sign up
                    </h1>

                    <div
                        className="overlap-group1"
                        style={{ backgroundImage: `url(${overlapGroup1})` }}
                    >
                        <form className="signup-form">
                            <div className="form-container">
                                <div className="input-names">
                                    <div className="form-parts-3-1">
                                        <div className="frame-5448 isDisabled">
                                            <input
                                                type="text"
                                                className="type-here-the-information roboto-normal-white-16px"
                                                value={firstName}
                                                placeholder="First Name*"
                                                onChange={(e) =>
                                                    setFirstName(e.target.value)
                                                }
                                            />
                                        </div>
                                        <span className="robotocondensed-bold-violent-violet-16px error-text" />
                                    </div>
                                    <div className="form-parts-3-1 form-parts-4-1">
                                        <div className="frame-5448 isDisabled">
                                            <input
                                                type="text"
                                                className="type-here-the-information roboto-normal-white-16px"
                                                value={lastName}
                                                placeholder="Last Name*"
                                                onChange={(e) =>
                                                    setLastName(e.target.value)
                                                }
                                            />
                                        </div>
                                        <span className="robotocondensed-bold-violent-violet-16px error-text" />
                                    </div>
                                </div>

                                <div className="select">
                                    <div className="input-names">
                                        <div className="form-parts-3-1">
                                            <div className="frame-5448 isDisabled">
                                                <input
                                                    type="email"
                                                    className="type-here-the-information roboto-normal-white-16px"
                                                    placeholder="Email*"
                                                    value={email}
                                                />
                                            </div>
                                            <span className="robotocondensed-bold-violent-violet-16px error-text" />
                                        </div>
                                        <div className="form-parts-3-1 form-parts-4-1">
                                            <div className="frame-5448">
                                                <input
                                                    type="text"
                                                    className="type-here-the-information roboto-normal-white-16px"
                                                    placeholder="Username*"
                                                    onChange={(e) =>
                                                        setUserName(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            <span className="robotocondensed-bold-violent-violet-16px error-text" />
                                        </div>
                                    </div>

                                    <div className="Gender-container-signup">
                                        <div className="genderLabel robotocondensed-bold-violent-violet-14px">
                                            Gender*
                                        </div>
                                        <div className="frame-5422-signup">
                                            {genders.map(
                                                ({
                                                    id: genderKey,
                                                    label: genderValue,
                                                }) => (
                                                    <div
                                                        className="option-status"
                                                        onChange={
                                                            handleChangeRadio
                                                        }
                                                    >
                                                        <label className="content-input">
                                                            <input
                                                                type="radio"
                                                                name="button"
                                                                value={
                                                                    genderKey
                                                                }
                                                                checked={
                                                                    gender ===
                                                                    genderKey
                                                                }
                                                            />
                                                            <i />
                                                        </label>
                                                        <label
                                                            htmlFor="students"
                                                            className="roboto-normal-white-14px"
                                                        >
                                                            {genderValue}
                                                        </label>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <span className="robotocondensed-bold-violent-violet-16px error-text" />
                                    </div>
                                </div>

                                <div className="input-names">
                                    <div className="form-parts-3-1">
                                        <div className="frame-5448 myBirthdayContainer isDisabled">
                                            <FontAwesomeIcon
                                                icon="calendar-alt"
                                                size="lg"
                                                color={WHITE}
                                                className="calendar-alt inputIcon  "
                                            />
                                            {/* <DatePicker
                                                dateFormat="MM-dd-yyyy"
                                                selected={myBirthday}
                                                onChange={(date: Date) =>
                                                    setMyBirthday(date)
                                                }
                                                className="inputDirthday"
                                                disabled
                                            /> */}
                                            <span className="roboto-normal-white-14px">{invite.dateOfBirth}</span>
                                        </div>
                                        <span className="robotocondensed-bold-violent-violet-16px error-text" />
                                    </div>
                                    <div className="form-parts-3-1 form-parts-4-1">
                                        <div className="frame-5448 inputPassword">
                                            <input
                                                type={
                                                    isActive
                                                        ? "text"
                                                        : "password"
                                                }
                                                className="type-here-the-information roboto-normal-white-16px"
                                                onChange={(e) =>
                                                    setPassword(e.target.value)
                                                }
                                                style={{ width: '100%' }}
                                                placeholder="Password*"
                                            />
                                            <button
                                                onClick={(e) => handleToggle(e)}
                                                className="buttonEye"
                                                type="button"
                                            >
                                                <FontAwesomeIcon
                                                    icon={
                                                        isActive
                                                            ? "eye-slash"
                                                            : "eye"
                                                    }
                                                    color={LAVENDER}
                                                    size="lg"
                                                    className="eye"
                                                />
                                            </button>
                                        </div>
                                        <span className="robotocondensed-bold-violent-violet-16px error-text" />
                                    </div>
                                </div>

                                <div className="preferedLanguageSelector roboto-normal-white-16px">
                                    <select
                                        className="preferedLanguageSelect roboto-normal-white-16px"
                                        onChange={(e) => handleChange(e)}
                                    >
                                        <option disabled>
                                            Prefered Language*
                                        </option>
                                        <option value="spanish">Spanish</option>
                                        <option value="english" selected>
                                            English
                                        </option>
                                    </select>

                                    <FontAwesomeIcon
                                        icon="chevron-down"
                                        size="lg"
                                        color={LAVENDER}
                                        className="chevron-down  "
                                    />
                                </div>

                                <span className="textTerms">
                                    By clicking Send, you agree to our{" "}
                                    <a href="https://possibilitycompany.com/terms-of-service/">
                                        Terms of Service
                                    </a>{" "}
                                    and{" "}
                                    <a href="https://possibilitycompany.com/privacy-policy/">
                                        Privacy Policy.
                                    </a>
                                </span>

                                <div className="frame-5355">
                                    <button
                                        className={`buttonsSend ${loadingCreateUserInvite &&
                                            "inProcess"}`}
                                        type="button"
                                        onClick={handleSubmit}
                                    >
                                        {loadingCreateUserInvite ? (
                                            <div className="icon-spinner-third buttonSendSignUp" />
                                        ) : (
                                            "Send"
                                        )}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </RightContainerSignup>
            </ContentContainer>
            <Footer />
        </ContainerSignup>
    );
}

const RightContainerSignup = styled(RightContainer)`
    padding: 0 25px 0 15px;
`;

export default SignUpContainer;
