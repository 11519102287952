import { useEffect, useState } from "react";
import "./styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cancelFriendRequest, createFriendRequest, deleteFriend, rejectFriendRequest, saveNewFriend } from "../../store/actions/connectionActions";
import ModalAddOfficer from "./ModalAddOfficer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../ClubIconsNavBar";
import ModalAha from '../Post/ModalAha';
import ModalNoAha from "../Post/ModalNoAha";
import { useTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { toast } from "react-toastify";
import ModalRemoveStudent from "./ModalRemoveStudent";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";

interface ModalConnectionsProps {
    //todo -> put types
    connection:any;
    setOpenModal:any;
    isAdvisor:any;
    officers:any;
    advisors:any;
    username:any;
    filterType:any;
    reloadClub:any;
    ChatServiceInstance:ChatServiceInterface;
}

function Index(props: ModalConnectionsProps) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const userInfo = useSelector((state: any) => state.getUser.userInfo);
    const userRoles = useSelector((state: any) => state.getUser.userRoles);

    const [showModal, setShowModal] = useState(false);
    const [showModalAha, setShowModalAha] = useState(false);
    const [showModalNoAha, setShowModalNoAha] = useState(false);
    const [showModalRemoveStudent, setShowModalRemoveStudent] = useState(false);
    const [fullname, setFullname] = useState('');
    const organization = useSelector((state: any) => state.getOrgs.organizationInfo);
    const { id } = useParams();

    const sendFriend = () => {
        const connect = props.connection?.usernameConnection || props.connection?.username || '';
        if (connect !== '') {
            dispatch<any>(createFriendRequest(userInfo.username, connect));
            props.setOpenModal(false);
        }
    }

    const deleteMyFriend = () => {
        const connect = props.connection?.friendUsername || props.connection?.username || '';
        if (connect !== '') {
            dispatch<any>(deleteFriend(userInfo.username, connect));
            props.setOpenModal(false);
        }
    }

    const rejectRequest = () => {
        const connect = props.connection?.usernameConnection || '';
        dispatch<any>(rejectFriendRequest(connect, userInfo.username))
    }

    const cancelRequest = () => {
        const connect = props.connection?.usernameConnection || '';
        dispatch<any>(cancelFriendRequest(userInfo.username, connect))
    }

    const acceptFriend = () => {
        const connect = props.connection?.usernameConnection || '';
        dispatch<any>(saveNewFriend(userInfo.username, connect))
    }

    const openAha = () => {
        if (typeof props?.connection?.classYear === 'string' || props?.connection?.roles?.includes('ZAA#STUDENT') || props?.connection?.userType === 'STUDENT') {
            setShowModalAha(true);
            setShowModalNoAha(false);
        } else {
            setShowModalAha(false);
            setShowModalNoAha(true);
        }
    }

    function addAdvisor(e: any) {
        props.setOpenModal(false)
        Backend(
            MicroServices.Club,
            `/clubs/${id}/advisor?email=${e.connection.email}`,
            {
                method: 'POST',
            }
        )
            .then((response) => {
                if (response.status === 201) {
                    toast.success(t('toast_advisorAdded'))
                    props.setOpenModal(false)
                }
                else {
                    toast.error(t('toast_ThereWasError'))
                }
            })
            .catch((error) => {
                toast.error(t('toast_ThereWasError'))
                console.log('Error adding advisors:', error)
            })
    };

    useEffect(() => {
        setFullname(props.connection.fullname || `${props.connection.firstname} ${props.connection.lastname}` || '');
    }, [props])

    return (
        <OutsideClickHandler
            onOutsideClick={() => {
                if(!showModal && !showModalAha && !showModalNoAha && !showModalRemoveStudent) props.setOpenModal(false);
            }}
        >
            <div className={`active listOptionEvents optionConnectionsList`}>
                <div className="titleOptions">
                    {t('options__whatWouldYou')}
                </div>
                <ul className="listOptionsPost">

                    {(props.isAdvisor && (props.officers || []).findIndex((item: any) => item.username === props.username) === -1) &&
                        (<li className="roboto-normal-white-16px cursor" onClick={() => setShowModal(true)}>
                            <FontAwesomeIcon
                                icon="puzzle-piece"
                                size='sm'
                                color={WHITE}
                                className="iconConnectionsOptions  "
                            />
                            {t('options__addClubOfficer')}
                        </li>
                        )}

                    {(props.isAdvisor && (props.advisors || []).findIndex((item: any) => item.username === props.username) === -1) && // Change modal for adding advisors
                        (<li className="roboto-normal-white-16px cursor" onClick={() => addAdvisor(props)}>
                            <FontAwesomeIcon
                                icon="user-shield"
                                size='sm'
                                color={WHITE}
                                className="iconConnectionsOptions  "
                            />
                            {t('options__addClubAdvisor')}
                        </li>
                        )}

                    {(props.isAdvisor && (props.advisors || []).findIndex((item: any) => item.username === props.username) === -1) &&
                        (<li className="roboto-normal-white-16px cursor" onClick={() => setShowModalRemoveStudent(true)}>
                            <FontAwesomeIcon
                                icon="user-times"
                                size='sm'
                                color={WHITE}
                                className="iconConnectionsOptions  "
                            />
                            {t('options__removeUserFromComm')}
                        </li>
                        )}

                    {(props.filterType === '1') && (
                        !(!userRoles.includes('ZAA#STUDENT') && ((props.connection.rolesConnection || []).includes('ZAA#STUDENT')) || props.connection.userType === 'STUDENT') &&

                        <li className="roboto-normal-white-16px cursor" onClick={sendFriend}>
                            <FontAwesomeIcon
                                icon="user-plus"
                                size='sm'
                                color={WHITE}
                                className="iconConnectionsOptions  "
                                onClick={sendFriend}
                            />
                            {t('options__addFriend')}
                        </li>

                    )}

                    {(props.filterType === 'delete') && (
                        <li className="roboto-normal-white-16px cursor" onClick={deleteMyFriend}>
                            <FontAwesomeIcon
                                icon="user-minus"
                                size='sm'
                                color={WHITE}
                                className="iconConnectionsOptions  "
                                onClick={deleteMyFriend}
                            />
                            {t('options__deleteFriend')}
                        </li>
                    )}

                    {(props.filterType === 'deleteRequest') && (
                        <>
                            <li className="roboto-normal-white-16px cursor" onClick={rejectRequest}>
                                <FontAwesomeIcon
                                    icon="user-xmark"
                                    size='sm'
                                    color={WHITE}
                                    className="iconConnectionsOptions  "
                                    onClick={rejectRequest}
                                />
                                {t('options__rejectRequest')}
                            </li>
                            <li className="roboto-normal-white-16px cursor" onClick={acceptFriend}>
                                <FontAwesomeIcon
                                    icon="star"
                                    size='sm'
                                    color={WHITE}
                                    className="iconConnectionsOptions  "
                                    onClick={acceptFriend}
                                />
                                {t('options__acceptRequest')}
                            </li>
                        </>
                    )}

                    {(props.filterType === 'cancelRequest') && (
                        <li className="roboto-normal-white-16px cursor" onClick={cancelRequest}>
                            <FontAwesomeIcon
                                icon="trash"
                                size='sm'
                                color={WHITE}
                                className="iconConnectionsOptions  "
                                onClick={cancelRequest}
                            />
                            {t('options__cancelRequest')}
                        </li>
                    )}

                    <ModalAddOfficer show={showModal} setShowModal={setShowModal} fullname={fullname} username={props.username || ''} reloadClub={props.reloadClub} />
                    <ModalRemoveStudent show={showModalRemoveStudent} setShowModal={setShowModalRemoveStudent} name={props.connection.fullname} username={props.connection.username} reloadClub={props.reloadClub} />

                    {
                        ![
                            'ZAA#ALUMNI',
                            'ZAA#GUARDIAN',
                            'ZAA#STAFF',
                            'ZAA#ORGADMIN',
                            'ZAA#SUPERADMIN',
                        ].includes(userRoles[0]) && (
                            <li className="roboto-normal-white-16px cursor liLogoAha" onClick={openAha}>
                                <img className="connectionsOptions aha-logo" src={"/aHa-logo_w.png"} alt="aha-logo" />
                                {t('options__sendAha')}
                            </li>
                        )
                    }
                    <ModalAha postInfo={{
                        author: props.connection.username,
                        imageProfile: props.connection.avatar,
                        nameProfile: `${props.connection.firstname} ${props.connection.lastname}`,
                    }} show={showModalAha} onHide={() => setShowModalAha(false)}
                    ChatServiceInstance={props.ChatServiceInstance} />
                    <ModalNoAha
                        profile={`${props.connection.firstname} ${props.connection.lastname}`} show={showModalNoAha} onHide={() => setShowModalNoAha(false)}
                        user />
                </ul>
            </div>
        </OutsideClickHandler>
    );

}

export default Index;