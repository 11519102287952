// SIGNUP
export const START_SIGNUP = 'START_SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const SIGNUP_RESTORE = 'SIGNUP_RESTORE';

// LOGIN
export const START_LOGIN = 'START_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

// GET PROFILE
export const START_GET_PROFILE = 'START_GET_PROFILE';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';

// LOGOUT
export const LOGOUT = 'LOGOUT';

// FORGOT PASSWORD
export const START_FORGOT = 'START_FORGOT';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_ERROR = 'FORGOT_ERROR';

// RESTORE PASSWORD
export const START_RESTORE = 'START_RESTORE';
export const RESTORE_SUCCESS = 'RESTORE_SUCCESS';
export const RESTORE_ERROR = 'RESTORE_ERROR';

// CHANGE_PASSWORD PASSWORD
export const START_CHANGE_PASSWORD = 'START_CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

// RELOAD_COGNITO_USER PASSWORD
export const START_RELOAD_COGNITO_USER = 'START_RELOAD_COGNITO_USER';
export const RELOAD_COGNITO_USER_SUCCESS = 'RELOAD_COGNITO_USER_SUCCESS';
export const RELOAD_COGNITO_USER_ERROR = 'RELOAD_COGNITO_USER_ERROR';

// GET USERS
export const START_GET_USERS = 'START_GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

// USER STATUS
export const START_USER_STATUS = 'START_USER_STATUS';
export const USER_STATUS_SUCCESS = 'USER_STATUS_SUCCESS';
export const USER_STATUS_ERROR = 'USER_STATUS_ERROR';

// USER LIMIT
export const START_GET_USERS_LIMIT = 'START_GET_USERS_LIMIT';
export const GET_USERS_LIMIT_SUCCESS = 'GET_USERS_LIMIT_SUCCESS';
export const GET_USERS_LIMIT_ERROR = 'GET_USERS_LIMIT_ERROR';

// USER ROLES
export const START_USER_ROLES = 'START_USER_ROLES';
export const USER_ROLES_SUCCESS = 'USER_ROLES_SUCCESS';
export const USER_ROLES_ERROR = 'USER_ROLES_ERROR';

export const START_USER = 'START_USER';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_ERROR = 'USER_ERROR';

export const START_UPDATE_USER = 'START_UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

// USER INFO
export const START_USER_INFO = 'START_USER_INFO';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_ERROR = 'USER_INFO_ERROR';

// CREATE USER BY INVITE
export const START_CREATE_USER_BY_INVITE = 'START_CREATE_USER_BY_INVITE';
export const CREATE_USER_BY_INVITE_SUCCESS = 'CREATE_USER_BY_INVITE_SUCCESS';
export const CREATE_USER_BY_INVITE_ERROR = 'CREATE_USER_BY_INVITE_ERROR';

// UPLOAD ASSET PROFILE PHOTO
export const START_UPLOAD_ASSET_PROFILE_PHOTO = 'START_UPLOAD_ASSET_PROFILE_PHOTO';
export const UPLOAD_ASSET_PROFILE_PHOTO_SUCCESS = 'UPLOAD_ASSET_PROFILE_PHOTO_SUCCESS';
export const UPLOAD_ASSET_PROFILE_PHOTO_ERROR = 'UPLOAD_ASSET_PROFILE_PHOTO_ERROR';

// UPLOAD PROFILE PHOTO
export const START_UPLOAD_PROFILE_PHOTO = 'START_UPLOAD_PROFILE_PHOTO';
export const UPLOAD_PROFILE_PHOTO_SUCCESS = 'UPLOAD_PROFILE_PHOTO_SUCCESS';
export const UPLOAD_PROFILE_PHOTO_ERROR = 'UPLOAD_PROFILE_PHOTO_ERROR';

// UPDATE PROFILE PHOTO
export const START_UPDATE_PROFILE_PHOTO = 'START_UPDATE_PROFILE_PHOTO';
export const UPDATE_PROFILE_PHOTO_SUCCESS = 'UPDATE_PROFILE_PHOTO_SUCCESS';
export const UPDATE_PROFILE_PHOTO_ERROR = 'UPDATE_PROFILE_PHOTO_ERROR';

export const START_GET_AHA_BALANCE = 'START_GET_AHA_BALANCE';
export const GET_AHA_BALANCE_SUCCESS = 'GET_AHA_BALANCE_SUCCESS';
export const GET_AHA_BALANCE_ERROR = 'GET_AHA_BALANCE_ERROR';

// USER DEPARTMENTS
export const START_SAVE_DEPARTMENTS = "START_SAVE_DEPARTMENTS";
export const SAVE_DEPARTMENTS_SUCCESS = "SAVE_DEPARTMENTS_SUCCESS";
export const SAVE_DEPARTMENTS_ERROR = "SAVE_DEPARTMENTS_ERROR";

// EDIT USER
export const START_EDIT_USER = 'START_EDIT_USER'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR'
