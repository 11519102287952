import "./styles.scoped.css";
import ListEmojis, { emojiColor, reactionToEmoji } from './ListEmojis';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from "../../components/ClubIconsNavBar";
import { timeDelay } from "../../helpers/dateHelper";
import { IComment } from "./Comments";

interface ListCommentsProps{
    comment:IComment; 
    image:boolean; 
    emoji:any;
}

function Index(props: ListCommentsProps) {

    return (
        <div className="itemComment">
            <div className="imgProfile">
                <img src={props.comment.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" />
            </div>
            <div className="textComment">
                <p>{props.comment.content}</p>
                {props.image &&
                    <img src="https://www.ebooking.com/es/noticias/wp-content/uploads/2020/04/Les-plus-beaux-paysages-de-France-1-768x576.jpg" alt="" />
                }
                <div className="infoComment">
                    <div className="countEmoji">
                    <FontAwesomeIcon 
                            icon={['fas', reactionToEmoji(props.emoji?.emojis?.reaction)]}
                            size="lg"
                            color={emojiColor(props.emoji?.emojis?.reaction,true)}
                            className="iconReaction"
                        />
                        {`${props.emoji?.emojis?.count || 0}`}
                        <ListEmojis post_id={props.comment.created_at} manageEmoji={props.emoji.manageEmoji} isComment={true}/>
                    </div>
                    <div className="countTime">
                        <label htmlFor="time">{timeDelay(props.comment.created_at)}</label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
