import { useEffect, useState } from "react";
import '../../containers/SetupStudent/styles.scoped.css'
import { useSelector } from "react-redux";
import { Backend, MicroServices } from "../../helpers/backendHelper";

function Index(props: any) {
  const userInfo = useSelector((state: any) => state.getUser.userInfo);

  const [members, setMembers] = useState<any[]>([]);

  useEffect(() => {
    console.log('this thing changed', props)
    Backend(
      MicroServices.Club,
      `/clubs/${props.club.club_id}/members?username=${userInfo.username}`)
      .then((response) => response.json())
      .then((results) => {
        if (Array.isArray(results)) {
          setMembers(results);
        } else {
          setMembers([]);
        }
      });
  }, [props.club.club_id])

  return (
    <div className="containerPhotoSetup">
      <div className="listMembersClub">
        {(members || []).map((member: any) => (
          <div className="cardMember" key={member.id} >
            <img src={member.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" />
            <div>
              <p>{member.fullname} </p>
              {/* <span>{member.class}</span> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Index;
