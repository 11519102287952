import "../../containers/SetupSchool/styles.scoped.css";
import { useState, useCallback, useEffect, useMemo } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { useDropzone } from "react-dropzone";
import { Advisor } from "./Communities";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { addOrgAdminAsAdvisor } from "../../store/actions/clubActions";
import { WHITE } from "../ClubIconsNavBar";
import { faCopy, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { ClubCode, ClubCodeDTO } from "../../interfaces/club/ClubCode";
import { RootState } from "../../store/store";
import { frontendUrl } from "../../AppConfig";
import usePostalCodes from "../../hooks/usePostalCodes";

toast.configure()
const ACCEPTED_FILES = {
  'text/csv': ['.csv']
}

const modalInviteTitle = (type: string, name: string): string => {
  return `Invite ${type.includes('Class') ? 'Students' : (type.length > 0 ? type : 'Members')}${type === 'Staff' ? '' : ` for ${name || type}`}`
}

const toastInviteMessage = (type: string, name?: string): string => {
  // return `${(type.length > 0 ? (type.slice(type.length - 1) === 's' ? type.substring(0, type.length - 1) : name || type) : 'Member')} invites created`;
  return `${name} invites created`;
}

function Index(props: any) {
  const { t, i18n } = useTranslation();
  const { postalCodes: postalCodesConfig, setCountry: setCountryPostal } = usePostalCodes();

  const [fileDenied, setFileDenied] = useState(false);
  const [checkingFile, setCheckingFile] = useState(false);
  const [fileAccepted, setFileAccepted] = useState(false);
  const [fileRejected, setFileRejected] = useState(false);
  const [notAcceptedMsg, setNotAcceptedMsg] = useState('');
  const [base64file, setBase64file] = useState('');
  const [club, setClub] = useState();
  const [clubType, setClubType] = useState('');
  const [loadingInvites, setLoadingInvites] = useState(false);
  const [invitedPeople, setInvitedPeople] = useState<number>(0);
  const [sendInactive, setSendInactive] = useState(true);
  const [showCode, setShowCode] = useState(false);
  const [canShowCode, setCanShowCode] = useState(false);
  const [codeGenerated, setCodeGenerated] = useState(false);
  const [clubCode, setClubCode] = useState<ClubCode>();
  const [members, setMembers] = useState<number>(0);
  const [membersHasError, setMembersHasError] = useState<boolean>(false);
  const [clubCodeHasError, setClubCodeHasError] = useState<boolean>(false);

  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const communities: any[] = useSelector((state: RootState) => state.getClub.GetComm);
  const organization = useSelector((state: any) => state.getOrgs.organizationInfo);

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryPostal(organization?.countryCode);
    }
  }, [])

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryPostal(organization?.countryCode);
    }
  }, [organization])

  const convertToBase64 = (file: File) => new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject('Error: ' + error);
    };
  })

  const onHide = () => {
    setFileDenied(false);
    setCheckingFile(false);
    setFileAccepted(false);
    setFileRejected(false);
    setBase64file('');
    setLoadingInvites(false);
    setSendInactive(true);
    props.onHide();
  }

  const onDrop = useCallback(acceptedFiles => {
    setClub(undefined);
    if (acceptedFiles.length === 0) {
      setFileDenied(true);
      return;
    }
    setFileDenied(false);
    setCheckingFile(true);
    convertToBase64(acceptedFiles[0])
      .then((bareBase64: string | ArrayBuffer | null) => {
        if (!bareBase64) {
          console.log(t('toast_ThereWasError'))
          return;
        }
        const toSendBase64 = (bareBase64 as string).split('base64,')[1]
        //console.log(toSendBase64);
        Backend(
          MicroServices.Invite,
          `/invites/validate?staff=${!props.type.includes('Class') && !['Alumni', 'Guardians'].includes(props.type)}&postalCodeLength=${postalCodesConfig?.minLength}${props.type.includes('Guardians') ? '&guardian=true' : ''}`, {
          method: 'POST',
          body: JSON.stringify({
            file: toSendBase64,
          })
        })
          .then((response) => {
            if (response.status === 200) {
              setBase64file(toSendBase64);
              setCheckingFile(false);
              setFileAccepted(true);
              setFileRejected(false);
              setSendInactive(false);
            } else if (response.status === 400) {
              setCheckingFile(false);
              setFileAccepted(false);
              setFileRejected(true);
              setSendInactive(true);
            }
            return response.json();
          })
          .then((response) => {
            //console.log(response);
            setNotAcceptedMsg(response.message || '');
            setInvitedPeople(response.invites || 0);
          })
      })
  }, [props.type, postalCodesConfig]);

  const dispatch = useDispatch()

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ACCEPTED_FILES,
  });

  const fileExampleFile = 'data:text/csv;charset=utf-8,' + encodeURIComponent(`Z School ID,ID,${props.type.startsWith('G') ? 'School ID,Dependent,School ID,Dependent,School ID,Dependent' : ''}Email,First name,Last name,Gender,Date of birth,City,State,Country,Zip code,Ethnicity,Economic Status`)

  const tcomms = useSelector((state: RootState) => state.getTClubs.TComm);
  const TCId = useMemo(() => tcomms.find(({name}) => name === props.type)?.id || '', [tcomms, props.type]);

  function handleOnClickSendMembers(e: any) {
    setLoadingInvites(true);
    if (props.type === 'Staff') {
      props.createCommunities();
      //console.log('Retrieve or create club staff');
      //console.log(props.organization)
      Backend(
        MicroServices.Club,
        `/clubs?org_id=${props.organization.id}&pending=true`)
        .then((response) => response.json())
        .then(({ clubs }) => new Promise((resolve, reject) => {
          //console.log('get clubs', clubs)
          const staffClub = clubs.find((club: any) => club.template_id === TCId);
          //console.log('get staffClub', staffClub)
          if (staffClub && 'id' in staffClub) {
            //console.log('Staff is created')
            resolve(staffClub);
            props.setSkip(false);
            return;
          }
          //console.log('Creating staff');
          Backend(
            MicroServices.Club,
            `/clubs?pending=${props.organization.status.startsWith('PEND')}`, {
            headers: {
              "Content-Type": "application/json",
            },
            method: 'POST',
            body: JSON.stringify({
              "org_id": props.organization.id,
              "template_id": TCId,
              "status": props.organization.status.startsWith('PEND') ? 'PENDING' : 'ACTIVE',
              "member_type": ["STAFF"],
              "name": "Staff",
              "gendersAllowed": ["M", "F", "O", "P"],
            }),
          })
            .catch((error) => {
              // handle error creating club
              setLoadingInvites(false);
            })
            .then((response) => {
              //console.log('club response', response);
              if (!response) {
                // error
                setLoadingInvites(false);
                return;
              }
              if (response.status === 201) {
                return response.json();
              } else {
                // error
                setLoadingInvites(false);
              }
            })
            .then((results) => {
              props.setSkip(false);
              resolve(results);
            })
        }))
        .catch((error) => {
          // Handle error
          setLoadingInvites(false);
        })
        .then((response: any) => {
          const clubId = response.id;
          setClub(clubId);
          dispatch(addOrgAdminAsAdvisor(clubId, userInfo.username)) //For staff club orgadmin is advisor
          return Promise.all(props.advisors.map((adv: Advisor) => new Promise((resolve, reject) => {
            Backend(
              MicroServices.Invite,
              `/invites`, {
              method: 'POST',
              body: JSON.stringify({
                email: adv.email,
                org_id: props.organization.id,
                club_id: clubId,
                is_advisor: true,
                firstName: adv.firstName,
                lastName: adv.lastName,
              })
            })
              .then((response) => resolve({
                response,
                clubId,
              }))
              .catch((error) => {
                reject(error);
                setLoadingInvites(false);
              })
          })))
        })
        .then((responses: any[]) => {
          //console.log('Create member invites');
          return Backend(
            MicroServices.Invite,
            `/invites/inviteRoster`, {
            method: 'POST',
            body: JSON.stringify({
              file: base64file,
              org_id: props.organization.id,
              club_id: club || responses?.[0]?.clubId,
              is_advisor: false,
            })
          })
            .then((response) => {
              setLoadingInvites(false);
              props.updateCommInvites();
              if (response.status === 200) {
                toast.success(toastInviteMessage(props.type, props.clubName));
                setFileDenied(false);
                setCheckingFile(false);
                setFileAccepted(false);
                setFileRejected(false);
                setBase64file('');
                setSendInactive(true);
                props.onHide(e, 'btnSave', props.idCard, props.type, true);
              } else {
                toast.error(t('toast_errorSendInvites'));
                setLoadingInvites(false);
              }
            });
        })
        .catch((error) => {
          toast.error(t('toast_errorSendInvites'));
          setLoadingInvites(false);
          console.log('error option A', error);
        })
    } else {
      Backend(
        MicroServices.Club,
        `/clubs?org_id=${props.organization.id}&pending=true`)
        .then((response) => response.json())
        .then(({ clubs }) => new Promise((resolve, reject) => {
          const club = clubs.find((club: any) => club.template_id === TCId);
          if (club && 'id' in club) {
            resolve(club);
            return;
          }
          Backend(
            MicroServices.Club,
            `/clubs?pending=${props.organization.status.startsWith('PEND')}`, {
            method: 'POST',
            body: JSON.stringify({
              "org_id": props.organization.id,
              "template_id": TCId,
              "status": props.organization.status.startsWith('PEND') ? 'PENDING' : 'ACTIVE',
              "member_type": (props.type as string).startsWith('Class') ? ["STUDENT"] : (props.type as string).startsWith('Gua') ? ['GUARDIAN'] : ["ALUMNI"],
              "name": props.type,
              "gendersAllowed": ["M", "F", "O", "P"],
            }),
          })
            .catch((error) => {
              // handle error creating club
              setLoadingInvites(false);
            })
            .then((response) => {
              if (!response) {
                // error
                setLoadingInvites(false);
                return;
              }
              if (response.status === 201) {
                return response.json();
              } else {
                // error
                setLoadingInvites(false);
              }
            })
            .then((results) => resolve(results))
        }))
        .catch((error) => {
          // handle error creating club
          setLoadingInvites(false);
        })
        .then((response: any) => {
          const clubId = response.id;
          setClub(clubId);
          return Promise.resolve([{ clubId }])
        })
        .then((responses: any[]) => {
          //console.log('Create member invites');
          return Backend(
            MicroServices.Invite,
            `/invites/inviteRoster${props.type.includes('Guardians') ? '?guardian=true' : ''}`, {
            method: 'POST',
            body: JSON.stringify({
              file: base64file,
              org_id: props.organization.id,
              club_id: club || responses?.[0]?.clubId,
              is_advisor: false,
            })
          })
            .then((response) => {
              setLoadingInvites(false);
              props.updateCommInvites();
              if (response.status === 200) {
                toast.success(toastInviteMessage(props.type, props.clubName));
                setFileDenied(false);
                setCheckingFile(false);
                setFileAccepted(false);
                setFileRejected(false);
                setBase64file('');
                setSendInactive(true);
                props.onHide(e, 'btnSave', props.idCard, props.type, true);
              } else {
                toast.error(t('toast_errorSendInvites'));
                setLoadingInvites(false);
              }
            });
        })
        .catch((error) => {
          toast.error(t('toast_errorSendInvites'));
          setLoadingInvites(false);
          console.log('error option A', error);
        })
    }
  }

  const generateClubCode = () => {
    if (isNaN(members) || members <= 0) {
      setMembersHasError(true);
      return;
    }
    const community = communities.find((comm) => comm.template_id === TCId)
    if (!community) {
      setClubCodeHasError(true);
      return;
    }

    setClubCodeHasError(false);
    setMembersHasError(false);
    Backend(
      MicroServices.Club,
      `/clubs/${community.id}/code`,
      {
        method: 'POST',
        body: JSON.stringify({
          max_members: members,
          members_left: 0,
        } as ClubCodeDTO)
      }
    )
      .then((response) => response.json())
      .then((results) => {
        setClubCode(results);
      })
  };

  useEffect(() => {
    //console.log(props)
    setInvitedPeople(0);
    setClubCode(undefined);
    setMembers(0);
  }, [props]);

  useEffect(() => {
    setCanShowCode(props.type !== 'Staff');
  }, [props.type])


  return (
    <Modal className="modalInviteMemebers" show={props.show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>{modalInviteTitle(props.type, props.clubName)}</Modal.Title>
        <FontAwesomeIcon
          icon={faTimes}
          size='xl'
          color={WHITE}
          className="icon-times"
          onClick={onHide}
        />
      </Modal.Header>
      <Modal.Body>
        {canShowCode && (
          <div className="InviteMembers--selectionContainer">
            <div
              className={`InviteMembers--selectionChip roboto-normal-white-16px ${!showCode ? 'active' : ''}`}
              onClick={() => setShowCode(false)}
            >
              Roster File
            </div>
            <div
              className={`InviteMembers--selectionChip roboto-normal-white-16px ${showCode ? 'active' : ''}`}
              onClick={() => setShowCode(true)}>Invite Link</div>
          </div>
        )}
        {showCode ? (
          <div className="InviteMembers--codeWrapper">
            <div className="InviteMembers--row InviteMembers--formControl">
              <span className="robotocondensed-bold-lavender-16px">Number of Members</span>
              <input
                type="number"
                className="InviteMembers--formInput roboto-normal-white-16px"
                disabled={codeGenerated}
                value={members}
                onChange={(e) => setMembers(parseInt(e.target.value))}
              />
            </div>
            <div className="InviteMembers--row InviteMembers--formControl">
              <span className="robotocondensed-bold-lavender-16px">Link</span>
              <div className="InviteMembers--inputDisabled roboto-normal-white-16px">
                <span>{clubCode?.code ? `${frontendUrl}/signup-code/${clubCode.code}` : ''}</span>
                <button onClick={() => { navigator.clipboard.writeText(`${frontendUrl}/signup-code/${clubCode?.code}`); toast.success(t('toast_linkCopied')); }}>
                  <FontAwesomeIcon
                    icon={faCopy}
                    color={WHITE}
                  />
                </button>
              </div>
            </div>
            {/*<div className="InviteMembers--row InviteMembers--actions">
              <button
                className="InviteMembers--generateCode roboto-normal-white-14px"
                disabled={codeGenerated}
                onClick={generateClubCode}
              >
                Generate
              </button>
        </div>*/}
          </div>
        ) : (
          <div>
            <p className="text-invites">Upload invite list file (csv accepted file types)</p>
            <p className="subtext-invites">Use following format: Z School ID, ID, {props.type.startsWith('Guard') ? 'School ID, Dependent, ': ''}Email, First name, Last name, Gender, Date of birth, City, State, Country, Zip code, Ethnicity, Economic Status.</p>
            <p className="subtext-invites">The gender must be one of the following: M, F, P or O.</p>
            <p className="subtext-invites">{`The Date of Birth must be formatted this way: MM-DD-YYYY e.g. 01-01-2001. ${props.type === 'Staff' ? '(Optional)' : ''}`}</p>
            <p className="subtext-invites">The Zip Code must have {postalCodesConfig?.minLength} digits.</p>
            <a href={fileExampleFile} download="rosterExample.csv">File example</a>
            <input className="dropzone-input" {...getInputProps()} />
            <div className="container-drag" {...getRootProps()}>
              {isDragActive ? (
                <>
                  <p>Release to drop</p>
                </>
              ) : fileDenied ? (
                <>
                  <p>This file is not a CSV. Drag CSV files here</p> or <span>Browse</span>
                </>
              ) : checkingFile ? (
                <>
                  <p>Checking CSV</p>
                </>
              ) : fileAccepted ? (
                <>
                  <p>Your file has the correct format. Go and send invites</p>
                </>
              ) : fileRejected ? (
                <>
                  <p>This CSV format is not correct</p>
                  <p>{notAcceptedMsg}</p>
                  <p>Drag CSV files here</p> or <span>Browse</span>
                </>
              ) : (
                <>
                  <p>Drag CSV files here</p> or <span>Browse</span>
                </>
              )}
            </div>
            <div className="" style={{ marginTop: '15px' }}>
              {invitedPeople > 0 ? (
                <span className="text-invites">{invitedPeople} people will be invited</span>
              ) : undefined}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="buttonClose" onClick={onHide}>
          Cancel
        </Button>
        {(showCode) ?
          (
            <button
              className="btn-sendInviteMember buttonSend roboto-normal-white-14px"
              disabled={codeGenerated}
              onClick={generateClubCode}
            >
              Generate
            </button>
          ) : (
            <Button name="btn-sendInviteMember"
              className={`buttonSend ${(sendInactive || loadingInvites) && 'inProcess'}`}
              onClick={handleOnClickSendMembers} disabled={sendInactive}>
              {loadingInvites ? (
                <div className="icon-spinner-third buttonSendSpinner"></div>
              ) : 'Send invites'}
            </Button>
          )}
        {/*<Button name="btn-sendInviteMember" 
          className={`buttonSend ${sendInactive && 'inProcess'}`} 
          onClick={handleOnClickSendMembers} disabled={sendInactive}>
          {loadingInvites ? (
            <div className="icon-spinner-third buttonSendSpinner"></div>
          ) : 'Send invites'}
          </Button>*/}
      </Modal.Footer>
    </Modal>
  );
}

export default Index;
