import { useDispatch, useSelector } from "react-redux";
import { getOrganizationInfo } from "../../store/actions/orgsActions";
import { getUser } from "../../store/actions/userActions";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './styles.scoped.css';
import ModalImageEditor from '../ModalImageEditor';
import { updateOrg, updateLogoOrg, uploadImage } from "../../store/actions/orgsActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";
import useGrades from "../../hooks/useGrades";
import SelectItem from "../../interfaces/SelectItem";
import Select from 'react-select';
import { customStylesReactSelect, DropdownIndicator } from "../../helpers/selectHelper";
import useActiveCountry, { CountryOption } from "../../hooks/useActiveCountry";
import { Backend, MicroServices } from "../../helpers/backendHelper";

const Index = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userInfo = useSelector((state: any) => state.getUser.userInfo);
    const user = useSelector((state: any) => state.getUser.user);
    const org = useSelector((state: any) => state.getOrgs.organizationInfo);
    const loadingUploadLogoOrg = useSelector((state: any) => state.getOrgs.loadingUploadLogoOrg || false);
    const loadingUpdateOrg = useSelector((state: any) => state.getOrgs.loadingUpdateOrg || false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);

    const [logoOrg, setLogoOrg] = useState<string>(org.logo);
    const [schoolName, setSchoolName] = useState<string>(org.name);
    const [schoolWebsite, setSchoolWebsite] = useState<string>(org.website);
    const [lowGrade, setLowGrade] = useState<number>(org.lowGrade);
    const [highGrade, setHighGrade] = useState<number>(org.highGrade);
    const [country, setCountry] = useState<string>(org.country);
    const [city, setCity] = useState<string>(org.city);
    const [postalCode, setPostalCode] = useState<string>(org.zipcode);
    const [newspaper, setNewspaper] = useState<string>(org.zipcode);
    const [yearbook, setYearbook] = useState<string>(org.zipcode);
    const [primaryContact, setPrimaryContact] = useState(org.primaryContact);
    const [countryCode, setCountryCode] = useState<CountryOption>();

    const [lowAge, setLowAge] = useState<number>(org.lowAge);
    const [highAge, setHighAge] = useState<number>(org.highAge);
    const [address, setAddress] = useState<string>(org.address);
    const [state, setState] = useState<string>(org.state);

    const { activeCountriesReactSelect } = useActiveCountry();
    const { gradesSelect, setCountry: setCountryGrades } = useGrades();
    const [lowGradeSelect, setLowGradeSelect] = useState<SelectItem>();
    const [highGradeSelect, setHighGradeSelect] = useState<SelectItem>();

    useEffect(() => {
        if (org) {
            setLowGradeSelect(gradesSelect.find((grade) => grade.value == org.lowGrade.toString()));
            setHighGradeSelect(gradesSelect.find((grade) => grade.value == org.highGrade.toString()));
        }
    }, [gradesSelect])

    useEffect(() => {
        if (org && org?.countryCode) {
            setCountryGrades(org?.countryCode);
        }
    }, [])

    useEffect(() => {
        if (org && org?.countryCode) {
            setCountryGrades(org?.countryCode);
        }
    }, [org])

    useEffect(() => {
        setCountryCode(activeCountriesReactSelect.find((country) => country.value === org.countryCode))
    }, [org, activeCountriesReactSelect])

    const ages = [
        { value: 13, label: '13' },
        { value: 14, label: '14' },
        { value: 15, label: '15' },
        { value: 16, label: '16' },
        { value: 17, label: '17' },
        { value: 18, label: '18' },
        { value: 19, label: '19' },
        { value: 20, label: '20' },
        { value: 21, label: '21' },
        { value: 22, label: '22' },
    ]

    const grades = [
        { value: 6, label: t('list__grades_6') },
        { value: 7, label: t('list__grades_7') },
        { value: 8, label: t('list__grades_8') },
        { value: 9, label: t('list__grades_9') },
        { value: 10, label: t('list__grades_10') },
        { value: 11, label: t('list__grades_11') },
        { value: 12, label: t('list__grades_12') },
    ];

    const customStyles = () => ({
        container: (styles: any) => ({
            ...styles,
            width: '100%',
            backgroundColor: 'transparent',
            height: 40,
        }),
        indicatorsContainer: (styles: any) => ({
            ...styles,
            display: 'none',

        }),
        control: (styles: any) => ({
            backgroundColor: '#28115a',
            border: 0,
            height: 40,
        }),
        valueContainer: (styles: any) => ({
            height: 40,
        }),
        singleValue: (styles: any) => ({
            color: 'white',
            padding: 12,
        }),
        option: (styles: any) => ({
            color: 'white',
            padding: 12,
            width: 40,
            height: 40,
            backgroundColor: '#2C2C2C',
            display: 'inline-block',
            margin: 5,
            cursor: 'pointer',
        }),
        menu: (styles: any) => ({
            width: 270,
            padding: '0 10px',
            borderRadius: 7,
            backgroundColor: '#383838; position:absolute; top: -115px;',
        }),
    });

    const [showModal, setShowModal] = useState(false);

    function handleCloseModal(e: any, type: any) {
        setShowModal(false);
    };

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSchoolName(event.target.value);
    }

    const handleChangeWebsite = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSchoolWebsite(event.target.value);
    }

    const handleChangeGradeLow = (event: any) => {
        setLowGrade(Number(event.value));
        setLowGradeSelect(event);
    }

    const handleChangeGradeHigh = (event: any) => {
        setHighGrade(Number(event.value));
        setHighGradeSelect(event);
    }

    const handleChangeAgeLow = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setLowAge(Number(event.target.value));
    }

    const handleChangeAgeHigh = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setHighAge(Number(event.target.value));
    }

    const handleChangeCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCountry(event.target.value);
    }

    const _handleCountrySelect = (e: any) => {
        setCountryGrades(e.value)
        setCountryCode(e);
        setCountry(e.value);
    }

    const handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCity(event.target.value);
    }

    const handleChangePostalCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPostalCode(event.target.value);
    }

    const handleChangeNewspaper = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewspaper(event.target.value);
    }

    const handleChangeYearBook = (event: React.ChangeEvent<HTMLInputElement>) => {
        setYearbook(event.target.value);
    }
    const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(event.target.value);
    }
    const handleChangeState = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(event.target.value);
    }

    const handleOnChangePrimary = (
        e: React.ChangeEvent<HTMLInputElement>,
        inputName: string,
    ) => {

        const primary = primaryContact;

        if (inputName === 'name') {
            primary.name = e.target.value;
        }

        if (inputName === 'email') {
            primary.email = e.target.value;
        }

        if (inputName === 'phoneNumber') {
            primary.phoneNumber = Number(e.target.value);
        }

        setPrimaryContact(primary);
    };

    function handleSaveImage(e: any) {
        fetch(e)
            .then(res => res.blob())
            .then(blob => {
                const contentType = e.split(';')[0].split(':')[1];
                const extension = contentType.split('/')[1]
                const file = new File([blob], 'Logo' + org?.id, { type: contentType });

                if (file) {
                    dispatch<any>(updateLogoOrg(
                        file.type,
                        file.name + '.' + extension,
                        "school_logo"
                    ))
                        .then((results: any) => {
                            if (results.body) {
                                dispatch<any>(uploadImage(
                                    results.body,
                                    contentType,
                                    file,
                                ))
                                    .then((result: any) => {
                                        if (result.status === 200) {
                                            const urlImage = results.body.split('?')[0];
                                            setLogoOrg(urlImage)
                                        }
                                    })
                                    .catch((error: any) => {
                                        console.log(error)
                                    })

                            }
                        })
                        .catch((error: any) => {
                            console.log(error)
                        })
                }

            })
    };

    const updateYo = (formToSend: any) => {
        setLoadingUpdate(true)
        Backend(
            MicroServices.Admin,
            `/yo/${org.YOTableId || ''}?pending=${(org.status || '').startsWith('P') ? 'true' : 'false'}&&zaahahIdOrg=${org.id}`,
            {
                method: 'POST',
                body: JSON.stringify(formToSend)
            }
        )
            .then((response) => response.json())
            .then((results) => {
                setLoadingUpdate(false);
                navigate('../my-school')
            })
            .catch(console.error);
    }

    const handleSave = (e: any) => {

        if (org && org.type !== "YOUTHORG") {
            dispatch<any>(updateOrg(
                {
                    name: schoolName,
                    website: schoolWebsite,
                    lowGrade: lowGrade,
                    highGrade: highGrade,
                    country: country,
                    city: city,
                    zipcode: postalCode,
                    logo: logoOrg,
                    yearbook: yearbook,
                    newspaper: newspaper,
                    primaryContact: primaryContact,
                    lowAge: lowAge,
                    highAge: highAge,
                    state: state,
                    address: address,
                },
                org.id,
                org.status === 'ACTIVE' ? false : true,
            ))
                .then((results: any) => {
                    console.log(results);
                    navigate('../my-school')
                })
                .catch((error: any) => {
                    console.log(error)
                })
        }
        else if(org && org.type === "YOUTHORG"){
            updateYo({
                name: schoolName,
                website: schoolWebsite,
                lowGrade: lowGrade,
                highGrade: highGrade,
                country: country,
                city: city,
                zipcode: postalCode,
                logo: logoOrg,
                yearbook: yearbook,
                newspaper: newspaper,
                primaryContact: primaryContact,
                lowAge: lowAge,
                highAge: highAge,
                state: state,
                address: address,
            })
        }
    }

    useEffect(() => {
        if (userInfo.username) {
            dispatch<any>(getUser(userInfo.username))
                .then((results: any) => {
                    dispatch<any>(getOrganizationInfo(results?.organizations[0]))
                        .then(() => {
                            if (primaryContact === undefined) {
                                setPrimaryContact({
                                    name: '',
                                    email: '',
                                    phoneNumber: 3000000000
                                })
                            }
                        })
                })
        }
    }, []);

    return (
        <div className="content">
            <div className="org-view-cont">
                <div className="title-column">
                    <h1 className="title-name">{(org.type === 'YOUTHORG')? t('menu__myOrganization') :t('myschool__title')}</h1>
                    <div className="buttons-box">
                        <div className={`button-w ${(loadingUpdateOrg || loadingUpdate) && 'inProcess'}`} onClick={() => navigate('../my-school')}>
                            <div className="cancel">{t('button__cancel')}</div>
                        </div>
                        <div className={`button-p ${(loadingUpdateOrg || loadingUpdate)  && 'inProcess'}`} onClick={handleSave}>
                            {(loadingUpdateOrg || loadingUpdate)  ?
                                <div className="icon-spinner-third buttonSendSignUp"></div>
                                :
                                <div className="save roboto-normal-white-16px">{t('button__save')}</div>
                            }
                        </div>
                    </div>
                </div>
                <div className="view-columns">
                    <div className="col-1">
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__schoolName')}</div>
                            <div className="info-field" >
                                <input
                                    className="roboto-normal-white-16px"
                                    type="text"
                                    name="name"
                                    onChange={handleChangeName}
                                    defaultValue={org.name}
                                />
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__schoolWebsite')}</div>
                            <div className="info-field">
                                <input
                                    className="roboto-normal-white-16px"
                                    type="text"
                                    defaultValue={org?.website || ''}
                                    onChange={handleChangeWebsite}
                                />
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__Principal')}</div>
                            <div className="columns-inputs">
                                <p className="text-ext robotocondensed-bold-lavender-16px">{t('myschool__firstName')}</p>
                                <div className="info-field" >
                                    <input
                                        className="info-field-update roboto-normal-white-16px"
                                        type="text"
                                        defaultValue={org?.orgAdmin?.firstname || ''}
                                    />
                                </div>
                                <p className="text-ext text-center robotocondensed-bold-lavender-16px">{t('myschool__lastName')}</p>
                                <div className="info-field" >
                                    <input
                                        className="info-field-update roboto-normal-white-16px"
                                        type="text"
                                        defaultValue={org?.orgAdmin?.lastname || ''}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__PrincipalPhone')}</div>
                            <div className="info-field" >
                                <input
                                    className="info-field-update roboto-normal-white-16px"
                                    type="text"
                                    defaultValue={org?.orgAdmin?.phoneNumber || ''}
                                />
                            </div>
                        </div>

                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__Grades')}</div>
                            <div className="columns-inputs">
                                <p className="text-ext robotocondensed-bold-lavender-16px">{t('myschool__low')}</p>
                                <div className="info-field" >
                                    <div className="select-list-1" >
                                        <Select
                                            options={gradesSelect}
                                            styles={customStylesReactSelect()}
                                            components={{
                                                DropdownIndicator,
                                            }}
                                            value={lowGradeSelect}
                                            onChange={handleChangeGradeLow}
                                            placeholder="Low Grade"
                                        />
                                    </div>
                                </div>
                                <p className="text-ext text-center robotocondensed-bold-lavender-16px">{t('myschool__high')}</p>
                                <div className="info-field" >
                                    <div className="select-list-1" >
                                        <Select
                                            options={gradesSelect}
                                            styles={customStylesReactSelect()}
                                            components={{
                                                DropdownIndicator,
                                            }}
                                            value={highGradeSelect}
                                            onChange={handleChangeGradeHigh}
                                            placeholder="Low Grade"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*(org.type === 'YOUTHORG') && (
                            <div className="info-field-row">
                                <div className="label-cont robotocondensed-bold-lavender-16px">Ages</div>
                                <div className="columns-inputs">
                                    <p className="text-ext robotocondensed-bold-lavender-16px">Low</p>
                                    <div className="info-field" >
                                        <div className="select-list-1" >
                                            <select name="" id="" onChange={handleChangeAgeLow}>
                                                {ages.map((grade) => (
                                                    <option className="option-chechbox" key={grade.value} value={grade.value} selected={grade.value === org?.lowAge}>
                                                        {grade.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <p className="text-ext text-center robotocondensed-bold-lavender-16px">High</p>
                                    <div className="info-field" >
                                        <div className="select-list-1" >
                                            <select name="" id="" onChange={handleChangeAgeHigh}>
                                                {ages.map((grade) => (
                                                    <option className="option-chechbox" key={grade.value} value={grade.value} selected={grade.value === org?.highAge}>
                                                        {grade.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                                )*/}

                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__createdBy')}</div>
                            <div className="info-field">
                                <div className="roboto-normal-white-16px">{`${org?.orgAdmin?.firstname} ${org?.orgAdmin?.lastname} `}</div>
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__country')}</div>
                            <div className="info-field" >
                                <Select
                                    options={activeCountriesReactSelect}
                                    styles={customStylesReactSelect()}
                                    components={{
                                        DropdownIndicator
                                    }}
                                    value={countryCode}
                                    onChange={_handleCountrySelect}
                                    placeholder="Country"
                                // className="frame-5448"
                                />
                            </div>
                            {/* <div className="info-field" >
                                <input
                                    className="info-field-update roboto-normal-white-16px"
                                    type="text"
                                    defaultValue={org?.country || ''}
                                    onChange={handleChangeCountry}
                                />
                            </div> */}
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__city')}</div>
                            <div className="info-field" >
                                <input
                                    className="info-field-update roboto-normal-white-16px"
                                    type="text"
                                    defaultValue={org?.city || ''}
                                    onChange={handleChangeCity}
                                />
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__state')}</div>
                            <div className="info-field">
                                <input
                                    className="info-field-update roboto-normal-white-16px"
                                    type="text"
                                    defaultValue={org?.state || ''}
                                    onChange={handleChangeState}
                                />
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__address')}</div>
                            <div className="info-field">
                                <input
                                    className="info-field-update roboto-normal-white-16px"
                                    type="text"
                                    defaultValue={org?.address || ''}
                                    onChange={handleChangeAddress}
                                />
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__postalCode')}</div>
                            <div className="info-field" >
                                <input
                                    className="info-field-update roboto-normal-white-16px"
                                    type="text"
                                    defaultValue={org?.zipcode || ''}
                                    onChange={handleChangePostalCode}
                                />
                            </div>
                        </div>

                        {(org.type !== 'YOUTHORG') && (<>
                            <div className="info-field-row">
                                <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__yearBook')}</div>
                                <div className="info-field" >
                                    <input
                                        className="info-field-update roboto-normal-white-16px"
                                        type="text"
                                        defaultValue={org?.yearbook || ''}
                                        onChange={handleChangeYearBook}
                                    />
                                </div>
                            </div>
                            {/*<div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__Newspaper')}</div>
                            <div className="info-field" >
                                <input
                                    className="info-field-update roboto-normal-white-16px"
                                    type="text"
                                    defaultValue={org?.newspaper || ''}
                                    onChange={handleChangeNewspaper}
                                />
                            </div>
                    </div> */}
                        </>)}

                    </div>
                    <div className="col-2">
                        <ModalImageEditor handleSaveImage={handleSaveImage} show={showModal} onHide={handleCloseModal} />
                        <div className="info-field-row" onClick={() => setShowModal(true)}>
                            <div className="label-cont labelLogo robotocondensed-bold-lavender-16px">{t('myschool__Logo')}</div>
                            <div className={`containerLogo`}>
                                {loadingUploadLogoOrg &&
                                    <div className="icon-spinner-third buttonSendSignUp"></div>
                                }
                                <div className={`divLogo ${loadingUploadLogoOrg ? 'inProcess' : ''}`}>
                                    {org?.logo || logoOrg ?
                                        <img src={logoOrg ? logoOrg : org?.logo} width="150px" />
                                        :
                                        <div className="uploadLogo">
                                            <FontAwesomeIcon
                                                icon="camera"
                                                size='xl'
                                                color={WHITE}
                                                className="camera  "
                                            />
                                            <div className="select-file robotocondensed-normal-white-16px">Select File</div>

                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <h1 className="title-col-2">{t('myschool__Information')}</h1>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__tagLine')}</div>
                            <div className="info-field" >
                                <input
                                    className="info-field-update roboto-normal-white-16px"
                                    type="text"
                                    value=""
                                />
                            </div>
                        </div>



                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__districtOrSystem')}</div>
                            <div className="info-field" >
                                <input
                                    className="info-field-update roboto-normal-white-16px"
                                    type="text"
                                    value=""
                                />
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__districtOrSystemWebsite')}</div>
                            <div className="info-field" >
                                <input
                                    className="info-field-update roboto-normal-white-16px"
                                    type="text"
                                    value=""
                                />
                            </div>
                        </div>

                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">
                                {t('myschool__primaryContact')}
                            </div>
                            <div className="info-field" id="input-color">
                                <input
                                    className="roboto-normal-white-16px"
                                    type="text"
                                    name="name-primary"
                                    defaultValue={org.primaryContact?.name}
                                    onChange={
                                        (e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleOnChangePrimary(e, 'name')
                                    }
                                />
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">
                                {t('myschool__primaryContactPhone')}
                            </div>
                            <div className="info-field" id="input-color">
                                <input
                                    className="roboto-normal-white-16px"
                                    type="text"
                                    name="phone-primary"
                                    defaultValue={org.primaryContact?.phoneNumber}
                                    onChange={
                                        (e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleOnChangePrimary(e, 'phoneNumber')
                                    }
                                />
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">
                                {t('myschool__primaryContactEmail')}
                            </div>
                            <div className="info-field" id="input-color">
                                <input
                                    className="roboto-normal-white-16px"
                                    type="text"
                                    name="email-primary"
                                    defaultValue={org.primaryContact?.email}
                                    onChange={
                                        (e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleOnChangePrimary(e, 'email')
                                    }
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
};

export default Index;
