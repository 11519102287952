import { faEllipsisVAlt } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Backend, MicroServices } from "../../../../helpers/backendHelper";
import { ChatServiceInterface } from "../../../../interfaces/chat/ChatServiceInterface";
import { UserChat } from "../../../../interfaces/chat/UserChat";
import { LAVENDER } from "../../../ClubIconsNavBar";
import { CircleStatusHeader } from "../../../Header";
import { ClickableIcon } from "../../../SuperAdminComponents/basicForSACenter";
import OutsideClickModalChatCenter from "./OutsideClickModalChatCenter";
import { testUUIDV4 } from "../../../../hooks/Chat/ChatService";

const ChatHeader = ({ from, muted, type, ChatServiceInstance }: { from: UserChat, muted: boolean, type: 'USER' | 'GROUP', ChatServiceInstance: ChatServiceInterface }) => {
    const { id, idGroup } = useParams();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [userInformation, setUserInformation] = useState<UserChat>({
        fullName: '',
        username: id || '',
        avatar: '',
        status: 'available'
    });

    const organization = useSelector(
        (state: any) => state.getOrgs.organizationInfo
    );

    useEffect(() => {
        correctUserInformation();
    }, []);

    useEffect(() => {
        correctUserInformation();
    }, [id, idGroup]);

    const correctUserInformation = () => {
        if (!from.fullName && !idGroup && id && !testUUIDV4(id)) {
            Backend(
                MicroServices.Admin,
                `/users/${id}?roleRequest=OA`)
                .then((response) => response.json())
                .then((results) => {
                    setUserInformation({
                        fullName: `${results.firstname} ${results.lastname}`,
                        username: id || '',
                        avatar: results.avatar || '',
                        status: results.status || 'available'
                    })
                })
                .catch(console.error);
            return;
        }
        if (idGroup) {
            Backend(
                MicroServices.Club,
                `/clubs/${id}?pending=${false}`)
                .then((response) => response.json())
                .then((club) => {
                    setUserInformation({
                        fullName: `${club.name} - ${ChatServiceInstance.activeConversation.from.fullName || ''}`,
                        username: ChatServiceInstance.activeConversation.from.username || idGroup || '',
                        avatar: ChatServiceInstance.activeConversation.from.avatar || organization.logo || '',
                        status: ChatServiceInstance.activeConversation.from.status || "available"
                    })
                })
                .catch(console.error)
            return;
        }
        
        setUserInformation(from);
    }

    return (
        <Header>
            <Info>
                <ImageContainer>
                    <CircleStatusHeader status={userInformation.status || 'available'} />
                    <Avatar src={userInformation.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />
                </ImageContainer>
                <ConversationName>
                    {userInformation.fullName || 'New Conversation'}
                </ConversationName>
                {(muted) &&
                    <ClickableIcon
                        icon="volume-slash"
                        size='lg'
                        color={LAVENDER}
                    />}
            </Info>
            <EllipsisSelector
                icon={faEllipsisVAlt}
                size='lg'
                className="ellipsis-v-alt"
                color={LAVENDER}
                onClick={() => setShowModal(true)}
            />
            {(showModal) && <OutsideClickModalChatCenter type={type} setShowModal={setShowModal} ChatServiceInstance={ChatServiceInstance} muted={muted} />}
        </Header>
    );
}

export default ChatHeader;

const Header = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: space-between;
    gap: 16px;
    width: 50%;
    height: 70px;
    background-color: var(--violet);
    box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.25);
    position: fixed;
    align-items: center;
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--onyx);
    border-radius: 36px;
    position: relative;
    margin-left: 50px;
`;

const EllipsisSelector = styled(ClickableIcon)`
    width: 100px;
`;

const Info = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
`;

const Avatar = styled.img`
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
`;

const ConversationName = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
`;