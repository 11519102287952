import { useEffect, useMemo, useState } from "react";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import TClub from "../../interfaces/TClubs/TClub/TClub";

const useTClubsSA = (pageSizeProp: number) => {
    const [pageSize, setPageSize] = useState(pageSizeProp);
    const [tclubs, setTclubs] = useState<TClub[]>([]);

    const tclubPages:TClub[][] = useMemo(() => {
        const pages:TClub[][] = [];

        for (let i = 0; i < tclubs.length; i+=pageSize) {
            pages.push(tclubs.slice(i, i+pageSize));
        }

        if (pages.length === 0) {
            pages.push([]);
        }

        return pages;
    }, [pageSize, tclubs])

    const reloadTClubs = () => {
        Backend(
            MicroServices.Club,
            `/tclubs`
        )
            .then((response) => response.json())
            .then((results: TClub[]) => {
                if (!Array.isArray(results)) {
                    return;
                }
                setTclubs(results);
            });
    }
    
    useEffect(() => reloadTClubs(), []);

    return {
        tclubPages,
        reloadTClubs,
        setPageSize,
    }

}

export default useTClubsSA;