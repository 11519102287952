import { useEffect, useState } from "react";
import "./styles.scoped.css";
import PostEvent from './PostEvent'
import PostImage from './PostImage'
import PostVideo from './PostVideo'
import PostText from './PostText'
import PostMixed from './PostMixed'
import PostBirthday from "./PostBirthday";
import { useTranslation } from "react-i18next";
import Comments from "./Comments";

import { useSelector, useDispatch } from "react-redux";
import { getUserReactionTo } from "./ListEmojis";
import { getClubsImAdv } from "../../store/actions/clubActions";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";

const getUser = (username: string) => new Promise((resolve, reject) => {
	Backend(
		MicroServices.Admin,
		`/users/${username}?roleRequest=OA`)
		.then((r) => r.json())
		.then(resolve)
		.catch(reject)
})

interface ListPostsProps {
	//todo -> put types
	posts:any;
	reloadPosts:any;
	ChatServiceInstance:ChatServiceInterface;
}

function Index(props: ListPostsProps) {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch()

	const [posts, setPosts] = useState<any[]>([]);
	const [reasons, setReasons] = useState([]);
	const [showComments, setShowComments] = useState(false);
	const [idPost, sesIdPost] = useState<number | null>(null);
	const [listAdv, setListAdv] = useState([]);

	const username = useSelector((state: any) => state.getUser.userInfo?.username || '');

	function activeComments(id: number) {
		setShowComments(true)
		sesIdPost(id)
	}

	useEffect(() => {
		if (username) {
			dispatch<any>(getClubsImAdv(username))
				.then((clubs: any) => {
					setListAdv(clubs)
				});
		}
	}, []);

	useEffect(() => {
		const postSorted = props.posts.sort((b: any, a: any) => a.id - b.id);
		const postBirthdayGroups = postSorted
			.filter((post: any) => post.type === 'birthday')
			.reduce((acc: any, curr: any) => ({
				...acc,
				[curr.infoPost.reach_id]: [
					...(acc[curr.infoPost.reach_id] || []),
					curr,
				]
			}), {})
		Promise.all(
			Object.keys(postBirthdayGroups)
				.map(async (id: string) => ({
					birthayTo: id,
					type: 'birthdayGroup',
					user: await getUser(id),
					messages: postBirthdayGroups[id],
				}))
		)
			.then((responses) => {
				responses.forEach((post) => {
					const postBirthdayIdx = postSorted.findIndex((postAux: any) => postAux.type === 'birthday' && post.birthayTo === postAux.infoPost.reach_id);
					postSorted[postBirthdayIdx] = post;
				})
				const postToReturn = postSorted.filter((postAux: any) => postAux.type !== 'birthday');

				return postToReturn
				//setPosts(postToReturn);
			})
			.then((postToReturn) => {
				//Add reactions to posts
				const hbdPostIdx = postToReturn.findIndex((p: any) => p.id === undefined)
				Promise.all(postToReturn.map((item: any) => getUserReactionTo(item.id, username)))
					.then((reactions) => {
						if (hbdPostIdx === -1) {
							setPosts(postToReturn.map((item: any, idx: number) => {
								return {
									...item,
									userReaction: reactions[idx]
								}
							}))
						} else {
							Promise.all(postToReturn[hbdPostIdx].messages.map((item: any) => getUserReactionTo(item.id, username)))
								.then((hbdReactions) => {
									const postToReturnCopy = postToReturn.map((item: any, idx: number) => {
										return {
											...item,
											userReaction: reactions[idx]
										}
									})
									const hbdMessages = postToReturnCopy[hbdPostIdx].messages.map((item: any, idx: number) => {
										return {
											...item,
											userReaction: hbdReactions[idx]
										}
									})
									postToReturnCopy[hbdPostIdx] = {
										...postToReturnCopy[hbdPostIdx],
										messages: hbdMessages
									}
									setPosts(postToReturnCopy)
								})
						}
					})
			})
	}, [props.posts])

	useEffect(() => {
		Backend(
			MicroServices.Posts,
			`/post/reasons-for-flag`)
			.then((response) => response.json())
			.then((results) => {
				setReasons(results)
			})
			.catch(console.log);
	}, []);

	//Emojis
	const [emojis, setEmojis] = useState<{ [post_id: string]: { count: number, reaction: string } }>({})
	const manageEmoji = (post_id: string, reaction: string, add: boolean) => {
		const emojisCopy = { ...emojis }
		emojisCopy[post_id] = {
			count: add ? (emojis[post_id]?.count || 0) + 1 : (emojis[post_id]?.count || 0),
			reaction: reaction
		}
		setEmojis(emojisCopy)
	}
	useEffect(() => {
		setEmojis(
			Object.assign({}, ...posts.map((post) => {
				if (post.id) {
					return {
						[post.id]: {
							count: post.emoji,
							reaction: post.userReaction
						}
					}
				} else {
					//Birthday
					if (post.messages) {
						return Object.assign({}, ...post.messages.map((p: any) => {
							return {
								[p.id]: {
									count: p.emoji,
									reaction: p.userReaction
								}
							}
						}))
					}
				}
			})))
	}, [posts])
	const generateEmojiObject = (post_id: any) => {
		return {
			emojis: emojis[post_id] ? emojis[post_id] : { count: 0, reaction: undefined },
			setEmojis: setEmojis,
			manageEmoji: manageEmoji
		}
	}

	return (
		<>
			{props.posts && props.posts.length ? (posts.map((post: any) => (
				post.type === 'event' ?
					<PostEvent listAdv={listAdv} event={post} reloadPosts={props.reloadPosts} reasons={reasons} activeComments={activeComments} emoji={generateEmojiObject(post.id)} />
					:
					post.type === 'img' ?
						<PostImage listAdv={listAdv} post={post} reloadPosts={props.reloadPosts} reasons={reasons} activeComments={activeComments} emoji={generateEmojiObject(post.id)} ChatServiceInstance={props.ChatServiceInstance}/>
						:
						post.type === 'video' ?
							<PostVideo listAdv={listAdv} post={post} reloadPosts={props.reloadPosts} reasons={reasons} activeComments={activeComments} emoji={generateEmojiObject(post.id)} ChatServiceInstance={props.ChatServiceInstance}/>
							:
							post.type === 'text' || post.type === 'link' || post.type === 'embed' ?
								<PostText
									listAdv={listAdv}
									post={post}
									reloadPosts={props.reloadPosts}
									reasons={reasons}
									activeComments={activeComments}
									emoji={generateEmojiObject(post.id)}
									ChatServiceInstance={props.ChatServiceInstance}
								/>
								: post.type === 'birthdayGroup' ?
									<PostBirthday post={post} reloadPosts={props.reloadPosts} emoji={Object.assign({}, ...post.messages.map((p: any) => ({
										[p.id]: generateEmojiObject(p.id)
									})))} ChatServiceInstance={props.ChatServiceInstance}/>
									:
									<PostMixed listAdv={listAdv} post={post} reloadPosts={props.reloadPosts} reasons={reasons} activeComments={activeComments} emoji={generateEmojiObject(post.id)} ChatServiceInstance={props.ChatServiceInstance}/>
			))
			) : (<p className="textPost">{t('post__noPostsYet')}</p>)}
			<Comments idPost={idPost} showComments={showComments} emoji={generateEmojiObject(idPost)} />
		</>
	);
}

export default Index;
