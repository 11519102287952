import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from './Header';
import '../../containers/SetupStudent/styles.scoped.css';
import ModalImageEditor from '../ModalImageEditor';
import { uploadAsset, uploadImage, updateProfilePhoto } from "../../store/actions/userActions";
import { getUser } from "../../store/actions/userActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
import { useTranslation } from "react-i18next";

function Index() {
  const { t, i18n } = useTranslation();
  const inputId = 'filePicker';
  const [userProfile, setUserProfile] = useState('');
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const [loadingPhoto, setLoadingPhoto] = useState<boolean>(false);

  function handleCloseModal(e: any, type: any) {
    setShowModal(false);
  };

  function handleSaveImage(e: any) {
    setLoadingPhoto(true);
    fetch(e)
      .then(res => res.blob())
      .then(blob => {
        const contentType = e.split(';')[0].split(':')[1];
        const extension = contentType.split('/')[1]
        const file = new File([blob], 'profile' + userInfo.username, { type: contentType });

        if (file) {
          dispatch<any>(uploadAsset(
            file.type,
            file.name + '.' + extension,
            "profile_photo"
          ))
            .then((results: any) => {
              if (results.body) {
                dispatch<any>(uploadImage(
                  results.body,
                  contentType,
                  file,
                ))
                  .then((result: any) => {
                    if (result.status === 200) {
                      const urlImage = results.body.split('?')[0];
                      setUserProfile(urlImage);
                      dispatch<any>(updateProfilePhoto(
                        urlImage,
                        userInfo.username,
                      ))
                        .then((result: any) => {
                          if (result.status === 200) {
                            setUserProfile(urlImage);
                            dispatch<any>(getUser(userInfo.username))
                              .then((result: any) => {
                                setLoadingPhoto(false);
                              });
                          }
                          else{
                            setLoadingPhoto(false);
                          }
                        })
                        .catch((error: any) => {
                          console.log(error)
                          setLoadingPhoto(false);
                        })
                    }
                  })
                  .catch((error: any) => {
                    console.log(error)
                    setLoadingPhoto(false);
                  })

              }
            })
            .catch((error: any) => {
              console.log(error)
              setLoadingPhoto(false);
            })
        }

      })
  };

  useEffect(() => {
    if (userInfo.username) {
      dispatch<any>(getUser(userInfo.username))
        .then((results: any) => {
          setUserProfile(results.avatar)
        })
    }
  }, []);

  return (
    <div className="containerPhotoSetup">
      <Header text={t('setupStudent__uploadPhoto')} step={1} />
      <ModalImageEditor handleSaveImage={handleSaveImage} show={showModal} onHide={handleCloseModal} />
      <div className="uploadProfile">
        <div>
          <label onClick={() => setShowModal(true)} className="photo" htmlFor={inputId}>
            <div>
              <div className="photoStudent">
                {userProfile ?
                  <img src={userProfile} className="imageSchool" />
                  :
                  (
                    (loadingPhoto) ? (
                      <div className="icon-spinner-third buttonSendSignUp" />
                    ) :
                    (<FontAwesomeIcon
                      icon="camera"
                      size="xl"
                      color={WHITE}
                      className="icon-camera  "
                    />)
                  )}

              </div>
              <p>{t('setupStudent__profilePicture')}</p>
              <a>{t('setupStudent__uploadFile')}</a>
            </div>
          </label>
          {/* <input id={inputId} style={{ display: 'none' }} type="file" /> */}
        </div>
        <p />
      </div>
    </div>
  );
}

export default Index;
