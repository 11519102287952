import React, { useEffect, useState } from 'react';
import Header from './Header';
import '../../containers/SetupStudent/styles.scoped.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import UnregisteredUser from '../../interfaces/user/UnregisteredUser';
import FriendRequest from '../../interfaces/user/FriendRequest';
import { getUserFriends, getUserPendingRequests, rejectFriendRequest, saveNewFriend } from '../../store/actions/connectionActions';
import { useTranslation } from "react-i18next";
import useGrades from '../../hooks/useGrades';

interface FriendRequestExtended extends FriendRequest {
  accepted: boolean;
  rejected: boolean;
}

function Index({
  friendRequest,
  setFriendRequest,
  unregRequests,
  setUnregRequests,
}: any) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { grades, setCountry: setCountryGrades } = useGrades();
  const [array, setArray] = useState<any[]>([]);
  const [friendRequests, setFriendRequests] = useState<FriendRequestExtended[]>([])
  const user = useSelector((state: any) => state.getUser.user);
  const userInfo = useSelector((state: any) => state.getUser.userInfo);

  const userSug = useSelector((state: RootState) => state.getConnection.GetUSug);
  const unregSug = useSelector((state: RootState) => state.getConnection.GetUnregSug);
  const pendingRequests = useSelector((state: RootState) => state.getConnection.userPendingRequests);
  const userFriends = useSelector((state: RootState) => state.getConnection.userFriends);

  const organization = useSelector((state: RootState) => state.getOrgs.organizationInfo);

  const castInterests = (interests: string[]): any[] => {
    let count = 0;
    let casted: any[] = []
    for (let interest of interests) {
      casted.push({
        id: `${count}`,
        tag: interest
      })
    }
    return casted
  }

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryGrades(organization.countryCode);
    }
  }, [organization]);

  useEffect(() => {
    if (userSug.length > 0) {
      setArray(userSug
        .filter((c) => c.username !== userInfo.username && pendingRequests.findIndex((req) => req.usernameFrom === c.username) === -1)
        .map((u, i) => {
          return ({
            id: i,
            avatar: u.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png',
            name: `${u.firstname} ${u.lastname}`,
            class: grades.find((grade) => grade.id === (user.grade - (u.classYear - user.classYear)))?.communityLabel?.replace('{{grad_year}}', (u.classYear).toString()) || '',// `${t('setupStudent__classOf')} ${u.classYear}`,
            tags: castInterests(u.interests),
            username: u.username,
          })
        })
      )
    }
  }, [userSug, grades])

  useEffect(() => {
    if (friendRequests.length === 0) {
      setFriendRequests(pendingRequests.map((req) => ({
        ...req,
        accepted: false,
        rejected: false,
      })))
    }
  }, [pendingRequests])

  const handleFriendRequest = (f: any) => {
    setFriendRequest([...friendRequest, f]);
  }

  const handleFriendRequestCancel = (f: any) => {
    setFriendRequest(friendRequest.filter((friend: any) => friend.name !== f.name));
  }

  const handleUnregRequests = (f: UnregisteredUser) => {
    setUnregRequests([...unregRequests, f]);
  }

  const handleUnregRequestsCancel = (f: UnregisteredUser) => {
    setUnregRequests(unregRequests.filter((friend: UnregisteredUser) => friend.email !== f.email));
  }

  const handleRejectFriendRequest = (reqIdx: number, event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const friendRequestsAux = [...friendRequests];
    friendRequestsAux[reqIdx].rejected = true;
    setFriendRequests(friendRequestsAux);
    dispatch<any>(rejectFriendRequest(friendRequestsAux[reqIdx].usernameFrom, friendRequestsAux[reqIdx].usernameTo))
      .then(() => {
        dispatch<any>(getUserFriends(userInfo.username));
        dispatch<any>(getUserPendingRequests(userInfo.username));
      });
  }

  const handleAcceptFriendRequest = (reqIdx: number, event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const friendRequestsAux = [...friendRequests];
    friendRequestsAux[reqIdx].accepted = true;
    setFriendRequests(friendRequestsAux);
    dispatch<any>(saveNewFriend(userInfo.username, friendRequestsAux[reqIdx].usernameFrom))
      .then(() => {
        dispatch<any>(getUserFriends(userInfo.username));
        dispatch<any>(getUserPendingRequests(userInfo.username));
      });
  }

  return (
    <div className="containerPhotoSetup">
      <Header
        text={t('setupStudent__connectWithClass')}
        subtext={t('setupStudent__innovator')}
        step={4}
      />
      <div className="cardsFriends">
        {userFriends.map((friendRequest) => (
          <div className="cardFriend">
            <div className="imageProfile">
              <img src={friendRequest?.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'} alt="" />
            </div>
            <div className="nameFriend">
              {' '}
              <h2>{friendRequest.fullname}</h2>{' '}
            </div>
            <div className="nameClass">{grades.find((grade) => grade.id === user.grade + (Number(friendRequest.classYear) - user.classYear))?.communityLabel?.replace('{{grad_year}}', friendRequest.classYear || '') || ''}</div>
            <div className="infoTabs"></div>
            <span className='buttonFollowing'>
              {t('setupStudent__youAreFriends')}
            </span>
          </div>
        ))}
        {friendRequests.map((friendRequest, reqIdx) => !friendRequest.rejected && (
          <div className="cardFriend">
            <div className="imageProfile">
              <img src={friendRequest?.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'} alt="" />
            </div>
            <div className="nameFriend">
              {' '}
              <h2>{friendRequest.fullname}</h2>{' '}
            </div>
            <div className="nameClass">{grades.find((grade) => grade.id === (user.grade - (Number(friendRequest.classYear) - user.classYear)))?.communityLabel?.replace('{{grad_year}}', String(friendRequest.classYear)) || ''}</div>
            <div className="infoTabs"></div>
            {(!friendRequest.accepted && !friendRequest.rejected) && (
              <div className="buttonsConnections">
                <button className="buttonAddFriend" type="button" onClick={(e) => handleAcceptFriendRequest(reqIdx, e)}>
                  {t('setupStudent__accept')}
                </button>
                <button className="buttonRejectFriend" type="button" onClick={(e) => handleRejectFriendRequest(reqIdx, e)}>
                  {t('setupStudent__reject')}
                </button>
              </div>
            )}
            {friendRequest.accepted && (
              <span className='buttonFollowing'>
                {t('setupStudent__youAreFriends')}
              </span>
            )}
          </div>
        ))}
        {array.map((friend: any) => (
          <div className="cardFriend">
            <div className="imageProfile">
              <img src={friend.avatar} alt="" />
            </div>
            <div className="nameFriend">
              {' '}
              <h2>{friend.name}</h2>{' '}
            </div>
            <div className="nameClass">{friend.class}</div>
            <div className="infoTabs">
              {friend.tags.map((tag: any) => (
                ' #' + tag.tag
              ))}
            </div>
            <div className="buttonsConnections">
              {friendRequest.findIndex((f: any) => f.name === friend.name) !== -1 ? (
                <button className="buttonAddFriend" type="button" onClick={() => handleFriendRequestCancel(friend)}>
                  {t('setupStudent__friendRequested')}
                </button>
              ) : (
                <button className="buttonAddFriend" type="button" onClick={() => handleFriendRequest(friend)}>
                  {t('setupStudent__addFriend')}
                </button>
              )}
            </div>
          </div>
        ))}
        {unregSug.map((unregUser) => (
          <div className="cardFriend">
            <div className="imageProfile">
              <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" alt="" />
            </div>
            <div className="nameFriend">
              {' '}
              <h2>{unregUser.fullname}</h2>{' '}
            </div>
            <div className="nameClass">{grades.find((grade) => grade.id === user.grade)?.communityLabel?.replace('{{grad_year}}', (user.classYear).toString()) || ''}</div>
            <div className="infoTabs">{t('setupStudent__unregisteredUser')}</div>
            <div className="buttonsConnections">
              {unregRequests.findIndex((f: UnregisteredUser) => f.email === unregUser.email) !== -1 ? (
                <button className="buttonAddFriend" type="button" onClick={() => handleUnregRequestsCancel(unregUser)}>
                  {t('setupStudent__friendRequested')}
                </button>
              ) : (
                <button className="buttonAddFriend" type="button" onClick={() => handleUnregRequests(unregUser)}>
                  {t('setupStudent__addFriend')}
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Index;
