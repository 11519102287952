import React, { useState, useEffect } from 'react';
import './styles.css';
import './SignUp2.css';
import { useNavigate } from 'react-router-dom';
import '../../styles.css';
import { ISignUpOrganizationRequest, ISignUpOrganizationRequestKeys } from '../../interfaces/Signup';
import { useDispatch, useSelector } from 'react-redux';
import { orgSignup, restoreSignup } from '../../store/actions/userActions';
import { isGrade, isOrganizationType, isValidHttpUrl, isZipCode } from '../../guards/SignupOrg';
import { RootState } from '../../store/store';
import { orgTypesZaahahSignup } from '../../helpers/orgTypeHelper';
import {getOrganizationType} from '../../store/actions/orgsActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LAVENDER } from '../../components/ClubIconsNavBar';
import { isValidUsername } from '../../helpers/userHelper';
import { getYORep, getYOTypes } from '../../store/actions/yoactions';
import { YORep, YOType } from '../../store/reducers/yoreducer';
import { Backend, MicroServices } from '../../helpers/backendHelper';

interface FieldError {
  hasError: boolean,
  message: string,
}

const defaultFieldError = (): FieldError => ({
  hasError: false,
  message: '',
});

export const cleanWebsite = (web: string) => {
  const website = web.replace(/\s+/g, '').toLowerCase();
  const URL_PROTOCOL = /http(s)?:\/\/.*/;
  if (URL_PROTOCOL.exec(website)) {
    return website;
  }
  return `https://${website}`;
}

const MIN_GRADE = 6;
const MAX_GRADE = 12;
const WEBSITE_REGEX = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

function SignUpContainer() {
  const {
    mainBackground,
    img1,
    img2,
    img3,
    oneUnifyingPlatfo,
    saveTeachersTimeA,
    overlapGroup1,
    vector21,
    number2,
    property1LabelProperty2OpenProps,
  } = componentData;

  const [firstForm, setFirstForm] = useState(true);
  const [form, setForm] = useState({
    user: {
      givenName: '',
      familyName: '',
      gender: '',
      email: '',
      userName: '',
      password: '',
    },
    organization: {
      organizationName: '',
      organizationType: 'SCHOOL',
      website: '',
      lowGrade: 0,
      highGrade: 0,
      zipcode: '',
      YOType: undefined,
      YORep: undefined,
      YOAff: {
        name: '',
        website: '',
        affiliation: 'ind',
        spoName: '',
        spoWebsite: '',
      },
      YORepNote: undefined,
    },
  });

  const [formErrors, setFormErrors] = useState<{ [masterKey in ISignUpOrganizationRequestKeys]: { [key: string]: FieldError } }>({
    user: {
      givenName: defaultFieldError(),
      familyName: defaultFieldError(),
      gender: defaultFieldError(),
      email: defaultFieldError(),
      userName: defaultFieldError(),
      password: defaultFieldError(),
    },
    organization: {
      organizationName: defaultFieldError(),
      organizationType: defaultFieldError(),
      website: defaultFieldError(),
      lowGrade: defaultFieldError(),
      highGrade: defaultFieldError(),
      zipcode: defaultFieldError(),
    },
  });
  const [showOrgErrors, setShowOrgErrors] = useState(false);
  const [showUserErrors, setShowUserErrors] = useState(false);
  const [stepActive, setStepActive] = useState(1);

  useEffect(() => {
    dispatch<any>(getOrganizationType());
    dispatch<any>(getYORep());
    dispatch<any>(getYOTypes());
  }, []);

  const orgT = useSelector((state: any) => state.getOrgs.organizationTypes);
  const orgTypes = orgTypesZaahahSignup(orgT);

  const getOptionTypes = () => (
    <>
      {(orgTypes).map(([value, title] : [string, string]) => (
        <option value={value} key={value}>{title}</option>
      ))}
    </>
  )

  const getOptionRep = () => (
    <>
      {(YORep)
        .sort((a: YORep, b: YORep) => a.index - b.index)
        .map((rep: YORep) => (
          <option value={rep.code} key={rep.code}>{rep.title}</option>
        ))
      }
    </>
  )

  // TODO: Call API for genders
  const [genderList, setGenderList] = useState({
    M: 'Male',
    F: 'Female',
    O: 'Non binary', //'Non binary',
    P: 'Prefer not to disclose', //'Prefer not to disclose',
  }); //@audit-issue change to avoid O and P gender @comment This is the old signup - ignored

  /*const [genderList, setGenderList] = useState({
    M: 'Male',
    F: 'Female'
  });*/

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orgCreated = useSelector((state: any) => state.getUser.orgCreated);
  const loadingSignup = useSelector((state: any) => state.getUser.loadingSignup || false);

  const YORep = useSelector((state: RootState) => state.getYOReducer?.YORep || []);
  const YOTypes = useSelector((state: RootState) => state.getYOReducer?.YOTypes || []);

  useEffect(() => {
    if (orgCreated) {
      dispatch(restoreSignup());
      navigate('/');
    }
  }, [orgCreated])

  const _handleChangeForm = <T extends HTMLSelectElement | HTMLInputElement,>(
    masterKey: ISignUpOrganizationRequestKeys,
    key: string,
    event: React.FormEvent<T>,
    cleanerFunction: (v: string) => unknown = (v: string) => v,
  ) => setForm({
    ...form,
    [masterKey]: {
      ...form[masterKey],
      [key]: cleanerFunction(event.currentTarget.value),
    }
  });

  const _handleChangeOrganization = <T extends HTMLSelectElement | HTMLInputElement,>(
    key: string,
    event: React.FormEvent<T>,
    cleanerFunction?: (v: string) => unknown,
  ) => _handleChangeForm<T>('organization', key, event, cleanerFunction);

  const _handleChangeType = (event: React.FormEvent<HTMLSelectElement>) => _handleChangeOrganization<HTMLSelectElement>('organizationType', event);
  const _handleChangeOrgName = (event: React.FormEvent<HTMLInputElement>) => _handleChangeOrganization<HTMLInputElement>('organizationName', event);
  const _handleChangeOrgWebsite = (event: React.FormEvent<HTMLInputElement>) => _handleChangeOrganization<HTMLInputElement>('website', event);
  const _handleChangeLowGrade = (event: React.FormEvent<HTMLSelectElement>) => _handleChangeOrganization<HTMLSelectElement>('lowGrade', event, parseInt);
  const _handleChangeHighGrade = (event: React.FormEvent<HTMLSelectElement>) => _handleChangeOrganization<HTMLSelectElement>('highGrade', event, parseInt);
  const _handleChangeZipCode = (event: React.FormEvent<HTMLInputElement>) => _handleChangeOrganization<HTMLInputElement>('zipcode', event);
  const _handleYOType = (event: React.FormEvent<HTMLInputElement>) => _handleChangeOrganization<HTMLInputElement>('YOType', event);
  const _handleYORep = (event: React.FormEvent<HTMLSelectElement>) => _handleChangeOrganization<HTMLSelectElement>('YORep', event);
  const _handleYORepNote = (event: React.FormEvent<HTMLInputElement>) => _handleChangeOrganization<HTMLInputElement>('YORepNote', event);
  const _handleChangeYOAffName = (event: React.FormEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      organization: {
        ...form.organization,
        YOAff: {
          ...form.organization.YOAff,
          name: event.currentTarget.value,
        }
      }
    })
  }
  const _handleChangeYOAffWebsite = (event: React.FormEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      organization: {
        ...form.organization,
        YOAff: {
          ...form.organization.YOAff,
          website: event.currentTarget.value,
        }
      }
    })
  }
  const _handleChangeYOAffSpoName = (event: React.FormEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      organization: {
        ...form.organization,
        YOAff: {
          ...form.organization.YOAff,
          spoName: event.currentTarget.value,
        }
      }
    })
  }
  const _handleChangeYOAffSpoWebsite = (event: React.FormEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      organization: {
        ...form.organization,
        YOAff: {
          ...form.organization.YOAff,
          spoWebsite: event.currentTarget.value,
        }
      }
    })
  }

  const _handleChangeYOAffAffiliation = (event: React.FormEvent<HTMLSelectElement>) => {
    setForm({
      ...form,
      organization: {
        ...form.organization,
        YOAff: {
          ...form.organization.YOAff,
          affiliation: event.currentTarget.value,
        }
      }
    })
  }

  const _handleChangeUser = <T extends HTMLSelectElement | HTMLInputElement,>(
    key: string,
    event: React.FormEvent<T>,
    cleanerFunction?: (v: string) => unknown,
  ) => _handleChangeForm<T>('user', key, event, cleanerFunction);

  const _handleGivenName = (event: React.FormEvent<HTMLInputElement>) => _handleChangeUser<HTMLInputElement>('givenName', event);
  const _handleFamilyName = (event: React.FormEvent<HTMLInputElement>) => _handleChangeUser<HTMLInputElement>('familyName', event);
  const _handleEmail = (event: React.FormEvent<HTMLInputElement>) => _handleChangeUser<HTMLInputElement>('email', event);
  const _handleUserName = (event: React.FormEvent<HTMLInputElement>) => _handleChangeUser<HTMLInputElement>('userName', event);
  const _handlePassword = (event: React.FormEvent<HTMLInputElement>) => _handleChangeUser<HTMLInputElement>('password', event);
  const _handleGender = (event: React.FormEvent<HTMLInputElement>) => _handleChangeUser<HTMLInputElement>('gender', event);

  const checkString = (term: string) => term.length > 0;
  const checkOrgType = (term: string) => checkString(term) && isOrganizationType(term);
  const checkOrgWebsite = (term: string) => checkString(term) && isValidHttpUrl(term) && !!WEBSITE_REGEX.exec(term);

  const setError = (masterKey: ISignUpOrganizationRequestKeys, key: string, message: string) => setFormErrors((prev) => ({
    ...prev,
    [masterKey]: {
      ...prev[masterKey],
      [key]: {
        hasError: true,
        message,
      }
    },
  }));

  const setErrorOrg = (key: string, message: string) => setError('organization', key, message);
  const setErrorUser = (key: string, message: string) => setError('user', key, message);

  const cleanError = (masterKey: ISignUpOrganizationRequestKeys, key: string) => setFormErrors((prev) => ({
    ...prev,
    [masterKey]: {
      ...prev[masterKey],
      [key]: {
        hasError: false,
        message: '',
      }
    },
  }));

  const cleanErrorOrg = (key: string,) => cleanError('organization', key);
  const cleanErrorUser = (key: string,) => cleanError('user', key);

  const checkOrganization = (): string[] => {
    const errors: string[] = [];
    if (!checkString(form.organization.organizationName)) {
      errors.push('Organization name can\'t be empty.');
      setErrorOrg('organizationName', 'Organization name can\'t be empty.');
    } else {
      cleanErrorOrg('organizationName');
    }
    if (!checkOrgType(form.organization.organizationType)) {
      errors.push('Select a organization type.');
      setErrorOrg('organizationType', 'Select a organization type.');
    } else {
      cleanErrorOrg('organizationType');
    }
    if (form.organization.organizationType === 'YOUTHORG' && !form.organization.YOType) {
      errors.push('Select one.');
      setErrorOrg('YOType', 'Select one.');
    } else {
      cleanErrorOrg('YOType')
    }
    if (!checkOrgWebsite(cleanWebsite(form.organization.website))) {
      errors.push('Enter a valid url.');
      setErrorOrg('website', 'Enter a valid url.');
    } else {
      cleanErrorOrg('website');
    }
    if (form.organization.lowGrade === 0) {
      errors.push(`Low grade is required`);
      setErrorOrg('lowGrade', `Low grade is required`);
    } else if (!isGrade(form.organization.lowGrade)) {
      errors.push(`Low Grade must be greater than ${MIN_GRADE}.`);
      setErrorOrg('lowGrade', `Low Grade must be greater than ${MIN_GRADE}.`);
    } else {
      cleanErrorOrg('lowGrade');
    }
    if (form.organization.highGrade === 0) {
      errors.push(`High Grade is required`);
      setErrorOrg('highGrade', `High Grade is required`);
    } else if (!isGrade(form.organization.highGrade) || form.organization.lowGrade > form.organization.highGrade) {
      errors.push(`High Grade must be between ${form.organization.lowGrade} and ${MAX_GRADE}.`);
      setErrorOrg('highGrade', `High Grade must be between ${form.organization.lowGrade} and ${MAX_GRADE}.`);
    } else {
      cleanErrorOrg('highGrade');
    }
    if (!isZipCode(form.organization.zipcode)) {
      errors.push('Zip Code must have five digits.');
      setErrorOrg('zipcode', 'Zip Code must have five digits.');
    } else {
      cleanErrorOrg('zipcode');
    }
    return errors;
  }

  const checkUser = (): string[] => {
    const errors = [];

    if (!checkString(form.user.givenName)) {
      errors.push('First name can\'t be empty.');
      setErrorUser('givenName', 'First name can\'t be empty.');
    } else {
      cleanErrorUser('givenName');
    }

    if (!checkString(form.user.givenName)) {
      errors.push('First name can\'t be empty.');
      setErrorUser('givenName', 'First name can\'t be empty.');
    } else {
      cleanErrorUser('givenName');
    }

    if (!checkString(form.user.familyName)) {
      errors.push('Last name can\'t be empty.');
      setErrorUser('familyName', 'Last name can\'t be empty.');
    } else {
      cleanErrorUser('familyName');
    }

    if (!checkString(form.user.gender)) {
      errors.push('You have to select a gender');
      setErrorUser('gender', 'You have to select a gender');
    } else {
      cleanErrorUser('gender');
    }

    if (!checkString(form.user.email)) {
      errors.push('Email can\'t be empty.');
      setErrorUser('email', 'Email can\'t be empty.');
    } else {
      cleanErrorUser('email');
    }

    if (!isValidUsername(form.user.userName)) {
      errors.push('Username can\'t be empty. Use max 15 characters (letters, numbers or _)');
      setErrorUser('userName', 'Username can\'t be empty. Use max 15 characters (letters, numbers or _)');
    } else {
      cleanErrorUser('userName');
    }

    if (!checkString(form.user.password)) {
      errors.push('Password can\'t be empty.');
      setErrorUser('password', 'Password can\'t be empty.');
    } else if (form.user.password.length < 8) {
      errors.push('Password must have at least 8 characters');
      setErrorUser('password', 'Password must have at least 8 characters');
    } else {
      cleanErrorUser('password');
    }

    return errors;
  }

  const _handleSubmit = () => {

    if (checkUser().length <= 0) {
      const formManipulated: ISignUpOrganizationRequest = {
        organization: {
          ...form.organization,
          website: cleanWebsite(form.organization.website),
          calendarEnd: '05-20',
        },
        user: {
          ...form.user,
          zipcode: form.organization.zipcode,
          organizations: [form.organization.organizationName],
          roles: [['Z#ORGADMIN']],
          preferredLanguage: 'english',
          clubs: [],
          clubsAdvisor: [],
          clubsFollower: [],
        },
      }
      console.log('To Send - Signup Org',formManipulated)
      dispatch<any>(orgSignup(formManipulated))
        .then((results: boolean) => {
          if(results){
            setForm({
              user: {
                givenName: '',
                familyName: '',
                gender: '',
                email: '',
                userName: '',
                password: '',
              },
              organization: {
                organizationName: '',
                organizationType: 'SCHOOL',
                website: '',
                lowGrade: 0,
                highGrade: 0,
                zipcode: '',
                YOType: undefined,
                YORep: undefined,
                YORepNote: undefined,
                YOAff: {
                  name: '',
                  website: '',
                  affiliation: 'ind',
                  spoName: '',
                  spoWebsite: '',
                }
              },
            });
          }
        })
    } else {
      setShowUserErrors(true);
    }
  };

  const getOptions = () => (
    <>
      {Array.from(Array(MAX_GRADE - MIN_GRADE + 1).keys()).map((number) => (
        <option value={number + MIN_GRADE} key={number + MIN_GRADE}>{number + MIN_GRADE}</option>
      ))}
    </>
  );

  const displayError = (masterKey: ISignUpOrganizationRequestKeys, key: string, kind: boolean) => kind && formErrors[masterKey][key]?.hasError && formErrors[masterKey][key]?.message;
  const displayErrorOrg = (key: string) => displayError('organization', key, showOrgErrors);
  const displayErrorUser = (key: string) => displayError('user', key, showUserErrors);

  useEffect(() => {
    if (form.organization.organizationType !== 'YOUTHORG') {
      setForm({
        ...form,
        organization: {
          ...form.organization,
          YOType: undefined,
        }
      })
    }
  }, [form.organization.organizationType])

  const handleGoNext = () => {
    if (form.organization.organizationType === 'YOUTHORG') {
      handleGoNextCondition();
      return;
    }
    Backend(
      MicroServices.AccessManager,
      `/auth/website?website=${encodeURI(cleanWebsite(form.organization.website))}`
    )
      .then(async (response) => {
        if (response.status !== 200) {
          alert("Website already exists")
          return;
        }
        handleGoNextCondition();

      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleGoNextCondition = () => {
    if (checkOrganization().length <= 0) {
      setFirstForm(false);
      setStepActive(2);
    } else {
      setShowOrgErrors(true);
    }
  }

  const [isActive, setActive] = useState(false);
  const handleToggle = (e: any) => {
    e.preventDefault();
    setActive(!isActive);
  };

  function buttonGoBack() {
    setFirstForm(true);
    setStepActive(1);
  }

  const getRepresent = () => {
    if (!form.organization.YORep) {
      return '';
    }

    if (form.organization.YORep === 'LOCAL') {
      return 'municipality'
    }
    if (form.organization.YORep === 'NATIONAL') {
      return 'country'
    }
    if (form.organization.YORep === 'STATE') {
      return 'state'
    }
  }

  return (
    <div className="container-center-horizontal">
      <div className="background screen" style={{ backgroundImage: `url(${mainBackground}) no-repeat center center fixed` }} >
        <div className="frame-6129" >
          <div className="group-container" >
            <img className="group-4960" src={img1} />
            <img className="group-4959" src={img2} />
            <img className="group-4961" src={img3} />
          </div>
          <div className="frame-6128" >
            <div
              className="schools-and-school-systems valign-text-middle robotocondensed-bold-white-24px"
            >
              Schools and School Systems
            </div>
            <p className="one-unifying-platfo valign-text-middle robotocondensed-normal-white-16px" >
              {oneUnifyingPlatfo}
            </p>
            <p className="save-teachers-time-a robotocondensed-normal-white-16px" >
              {saveTeachersTimeA}
            </p>
          </div>
        </div>
        {firstForm ? (
          <div
            className="overlap-group1"
            style={{ backgroundImage: `url(${overlapGroup1})` }}
          >
            <form className="signup-form" >
              <div className="signup-form-cont" >
                <h1 className="valign-text-middle robotocondensed-bold-white-48px" >
                  Sign Up
                </h1>
                <div className="form-container2" >
                  <div className="info-column" >
                    <div className={`frame-6136 robotocondensed-bold-white-16px ${stepActive === 1 && 'btnActive '}`} >
                      <div className="circleNum frame-6133">
                        <div className="numberStep" >
                          1
                        </div>
                      </div>
                      <div className="general-information" >
                        General Information
                      </div>
                    </div>
                    <img className="vector-21" src={vector21} />
                    <div className={`frame-6135 robotocondensed-bold-white-16px ${stepActive === 2 && 'btnActive'}`} >
                      <div className="circleNum frame-6134">
                        <div className="numberStep robotocondensed-normal-white-16px" >
                          {number2}
                        </div>
                      </div>
                      <div className="account-information" >
                        Account Information
                      </div>
                    </div>
                  </div>
                  <div className="form-container" >
                    <div className="form-parts" >
                      <p className="select robotocondensed-bold-lavender-16px" >
                      Who do you represent?
                      </p>
                      <div className="select-list-1" >
                        <select className="select-the-information-1 roboto-normal-white-16px" onChange={_handleChangeType} value={form.organization.organizationType} >
                          <option disabled>Select one type</option>
                          {getOptionTypes()}
                        </select>
                        <FontAwesomeIcon
                          icon="chevron-down"
                          size='lg'
                          color={LAVENDER}
                          className="chevron-down  "
                          
                        />
                      </div>
                      <span className='roboto-bold-white-16px error-text'>{displayErrorOrg('organizationType')}</span>
                    </div>
                    {form.organization.organizationType === 'YOUTHORG' && (
                      <div className='YO-wrapper'>
                        <div className="form-parts" >
                          <p className="select robotocondensed-bold-lavender-16px mb-10" >
                            Who do you represent?
                          </p>
                          {YOTypes.sort((a:YOType, b:YOType) => b.code.localeCompare(a.code)).map((type: YOType) => (
                            <div className="optionRadio" key={type.code}>
                              <label className="content-input">
                                <input name="gender" type="radio" value={type.code} checked={form.organization.YOType === type.code} onChange={_handleYOType} />
                                <i></i>
                              </label>
                              <div className="male radioButton robotocondensed-normal-white-16px">{type.title}</div>
                            </div>
                          ))}
                          <span className='roboto-bold-white-16px error-text'>{displayErrorOrg('YOType')}</span>
                        </div>

                        {(form.organization.organizationType === 'YOUTHORG' && form.organization.YOType === 'ADMIN') && (
                          <div className="form-parts mt-inp" >
                            <p className="select robotocondensed-bold-lavender-16px" >
                              Account information for Youth Organization
                            </p>
                            <div className="select-list-1" >
                              <select className="select-the-information-1 roboto-normal-white-16px" onChange={_handleYORep} value={form.organization.YORep} >
                                <option disabled value={undefined} selected>Select one type</option>
                                {getOptionRep()}
                              </select>
                              <FontAwesomeIcon
                                icon="chevron-down"
                                size='lg'
                                color={LAVENDER}
                                className="chevron-down  "
                              />
                            </div>
                            <span className='roboto-bold-white-16px error-text'>{displayErrorOrg('YORep')}</span>
                          </div>
                        )}

                        {(form.organization.organizationType === 'YOUTHORG' && form.organization.YOType === 'ADMIN' && form.organization.YORep !== undefined) && (
                          <div className={`form-parts ${property1LabelProperty2OpenProps.className || ""} mt-inp`} >
                            <div className="label robotocondensed-bold-lavender-14px" >
                              What {getRepresent()} you represent?
                            </div>
                            <div className="select-list-1" >
                              <input name="YORepNote" type="text" className="type-here-the-information roboto-normal-white-16px" onChange={_handleYORepNote} value={form.organization.YORepNote} />
                            </div>
                            <span className='roboto-bold-white-16px error-text'>{displayErrorOrg('YORepNote')}</span>
                          </div>
                        )}

                        {(form.organization.organizationType === 'YOUTHORG' && form.organization.YOType === 'AFFILIATED') && (
                          <>
                            <div className="input-names-YO" > 
                              <div className={'form-parts-YO'} >
                                <div className="select robotocondensed-bold-lavender-14px mb-10" >
                                  School Name
                                </div>
                                <div className="frame-YO" >
                                  <input name="website" type="text" className="type-here-the-information roboto-normal-white-16px" onChange={_handleChangeYOAffName} value={form.organization.YOAff.name} />
                                </div>
                                <span className='roboto-bold-white-16px error-text'>{displayErrorOrg('YOAffName')}</span>
                              </div>
                              <div className={'form-parts-YO form-parts-4-1'} >
                                <div className="select robotocondensed-bold-lavender-14px mb-10" >
                                  School Website
                                </div>
                                <div className="frame-YO" >
                                  <input name="zipcode" type="text" className="type-here-the-information roboto-normal-white-16px" onChange={_handleChangeYOAffWebsite} value={form.organization.YOAff.website} />
                                </div>
                                <span className='roboto-bold-white-16px error-text'>{displayErrorOrg('YOAffWebsite')}</span>
                              </div>
                            </div>
                            {/* <div className="form-parts mt-inp" >
                              <p className="select robotocondensed-bold-lavender-16px" >
                                Select Affiliation
                              </p>
                              <div className="select-list-1" >
                                <select className="select-the-information-1 roboto-normal-white-16px" onChange={_handleChangeYOAffAffiliation} value={form.organization.YOAff.affiliation} >
                                  <option value="ind">Independent</option>
                                  <option value="spo">Community Sponsored Club</option>
                                </select>
                                <FontAwesomeIcon
                                  icon="chevron-down"
                                  size='lg'
                                  color={LAVENDER}
                                  className="chevron-down  "
                                />
                              </div>
                              <span className='roboto-bold-white-16px error-text'>{displayErrorOrg('YOAff.affiliation')}</span>
                            </div> */}
                            {/* {form.organization.YOAff.affiliation === 'spo' && (
                              <div className="input-names-YO" > 
                                <div className={'form-parts-YO'} >
                                  <div className="select robotocondensed-bold-lavender-14px mb-10" >
                                    Sponsor Name
                                  </div>
                                  <div className="frame-YO" >
                                    <input name="website" type="text" className="type-here-the-information roboto-normal-white-16px" onChange={_handleChangeYOAffSpoName} value={form.organization.YOAff.spoName} />
                                  </div>
                                  <span className='roboto-bold-white-16px error-text'>{displayErrorOrg('YOAff.spoName')}</span>
                                </div>
                                <div className={'form-parts-YO form-parts-4-1'} >
                                  <div className="select robotocondensed-bold-lavender-14px mb-10" >
                                    Sponsor Website
                                  </div>
                                  <div className="frame-YO" >
                                    <input name="zipcode" type="text" className="type-here-the-information roboto-normal-white-16px" onChange={_handleChangeYOAffSpoWebsite} value={form.organization.YOAff.spoWebsite} />
                                  </div>
                                  <span className='roboto-bold-white-16px error-text'>{displayErrorOrg('YOAff.spoWebsite')}</span>
                                </div>
                              </div>
                            )} */}
                          </>
                        )}
                      </div>
                    )}

                    <div className={`form-parts-3-1 ${property1LabelProperty2OpenProps.className || ""}`} >
                      <div className="label robotocondensed-bold-lavender-14px" >
                        {property1LabelProperty2OpenProps.label}
                      </div>
                      <div className="frame-5448" >
                        <input name="OrgName" type="text" className="type-here-the-information roboto-normal-white-16px" onChange={_handleChangeOrgName} value={form.organization.organizationName} />
                      </div>
                      <span className='roboto-bold-white-16px error-text'>{displayErrorOrg('organizationName')}</span>
                    </div>

                    <div className="input-names" >
                      <div className={'form-parts-3-1'} >
                        <div className="label robotocondensed-bold-lavender-14px" >
                          Website
                        </div>
                        <div className="frame-5448" >
                          <input name="website" type="text" className="type-here-the-information roboto-normal-white-16px" onChange={_handleChangeOrgWebsite} value={form.organization.website} />
                        </div>
                        <span className='roboto-bold-white-16px error-text'>{displayErrorOrg('website')}</span>
                      </div>
                      <div className={'form-parts-3-1 form-parts-4-1'} >
                        <div className="label robotocondensed-bold-lavender-14px" >
                          Zip Code
                        </div>
                        <div className="frame-5448" >
                          <input name="zipcode" type="text" className="type-here-the-information roboto-normal-white-16px" onChange={_handleChangeZipCode} value={form.organization.zipcode} />
                        </div>
                        <span className='roboto-bold-white-16px error-text'>{displayErrorOrg('zipcode')}</span>
                      </div>
                    </div>
                    <div className="frame-6017" >
                      <div className="form-parts form-parts-1" >
                        <p className="select robotocondensed-bold-lavender-14px" >
                          Low Grade
                        </p>
                        <div className="select-list-1" >
                          <select name="lowGrade" className="select-the-information-1 roboto-normal-white-16px" onChange={_handleChangeLowGrade} value={form.organization.lowGrade}>
                            <option disabled value="0">Select Grade</option>
                            {getOptions()}
                          </select>
                          <FontAwesomeIcon
                            icon="chevron-down"
                            size='lg'
                            color={LAVENDER}
                            className="chevron-down"
                          />
                        </div>
                        <span className='roboto-bold-white-16px error-text'>{displayErrorOrg('lowGrade')}</span>
                      </div>
                      <div className="form-parts form-parts-2" >
                        <p className="select robotocondensed-bold-lavender-14px" >
                          High Grade
                        </p>
                        <div className="select-list-1" >
                          <select name="highGrade" className="select-the-information-1 roboto-normal-white-16px" onChange={_handleChangeHighGrade} value={form.organization.highGrade}>
                            <option disabled value="0">Select Grade</option>
                            {getOptions()}
                          </select>
                          <FontAwesomeIcon
                            icon="chevron-down"
                            size='lg'
                            color={LAVENDER}
                            className="chevron-down"
                          />
                        </div>
                        <span className='roboto-bold-white-16px error-text'>{displayErrorOrg('highGrade')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-5432" >
                <div className="frame-5355 buttonNexSignUp" >
                  <button className="buttons-1 " onClick={handleGoNext} type="button">
                    <div className="login roboto-normal-black-16px">
                      Next
                    </div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div
            className="overlap-group1"
            style={{ backgroundImage: `url(${overlapGroup1})` }}
          >
            <form className="signup-form" >
              <div className="signup-form-cont" >
                <h1 className="valign-text-middle robotocondensed-bold-white-48px" >
                  Sign Up
                </h1>
                <div className="form-container2" >
                  <div className="info-column" >
                    <div className={`frame-6136 robotocondensed-bold-white-16px ${stepActive === 1 && 'btnActive'}`} >
                      <div className="circleNum frame-6133">
                        <div className="numberStep" >
                          1
                        </div>
                      </div>
                      <div className="general-information" >
                        General Information
                      </div>
                    </div>
                    <img className="vector-21" src={vector21} />
                    <div className={`frame-6135 robotocondensed-bold-white-16px ${stepActive === 2 && 'btnActive'}`}>
                      <div className="circleNum frame-6134">
                        <div className="numberStep robotocondensed-normal-white-16px" >
                          {number2}
                        </div>
                      </div>
                      <div className="account-information" >
                        Account Information
                      </div>
                    </div>
                  </div>
                  <div className="form-container" >
                    <div className="input-names" >
                      <div className={'form-parts-3-1'} >
                        <div className="label robotocondensed-bold-lavender-14px" >
                          First Name
                        </div>
                        <div className="frame-5448" >
                          <input name="orgFirstName" type="text" className="type-here-the-information roboto-normal-white-16px" onChange={_handleGivenName} value={form.user.givenName} />
                        </div>
                        <span className='roboto-bold-white-16px error-text'>{displayErrorUser('givenName')}</span>
                      </div>
                      <div className={'form-parts-3-1 form-parts-4-1'} >
                        <div className="label robotocondensed-bold-lavender-14px" >
                          Last Name
                        </div>
                        <div className="frame-5448" >
                          <input name="orgLastName" type="text" className="type-here-the-information roboto-normal-white-16px" onChange={_handleFamilyName} value={form.user.familyName} />
                        </div>
                        <span className='roboto-bold-white-16px error-text'>{displayErrorUser('familyName')}</span>
                      </div>
                    </div>

                    <div className="select">
                      <div className="gender robotocondensed-bold-lavender-14px">Gender</div>
                      <div className="frame-5422">
                        {Object.entries(genderList).map(([key, value]) => (
                          <div className="optionRadio" key={key}>
                            <label className="content-input">
                              <input name="gender" type="radio" value={key} onChange={_handleGender} checked={form.user.gender === key} />
                              <i></i>
                            </label>
                            <div className="male radioButton robotocondensed-normal-white-16px">{value}</div>
                          </div>
                        ))}
                      </div>
                      <span className='roboto-bold-white-16px error-text'>{displayErrorUser('gender')}</span>
                    </div>

                    <div className="input-names" >
                      <div className={'form-parts-3-1'} >
                        <div className="label robotocondensed-bold-lavender-14px" >
                          Email
                        </div>
                        <div className="frame-5448" >
                          <input name="email" type="email" className="type-here-the-information roboto-normal-white-16px" onChange={_handleEmail} value={form.user.email} />
                        </div>
                        <span className='roboto-bold-white-16px error-text'>{displayErrorUser('email')}</span>
                      </div>
                      <div className={'form-parts-3-1 form-parts-4-1'} >
                        <div className="label robotocondensed-bold-lavender-14px" >
                          Username
                        </div>
                        <div className="frame-5448" >
                          <input name="username" type="text" className="type-here-the-information roboto-normal-white-16px" onChange={_handleUserName} value={form.user.userName} />
                        </div>
                        <span className='roboto-bold-white-16px error-text'>{displayErrorUser('userName')}</span>
                      </div>
                    </div>


                    <div className={`form-parts-3-1 ${property1LabelProperty2OpenProps.className || ""}`} >
                      <div className="label robotocondensed-bold-lavender-14px" >
                        Password
                      </div>
                      <div className="frame-5448" >
                        <input name="password" type={isActive ? 'text' : 'password'} className="type-here-the-information roboto-normal-white-16px" onChange={_handlePassword} value={form.user.password} />
                        <button
                          onClick={(e) => handleToggle(e)}
                          className="buttonEye"
                          type="button"
                        >
                          <FontAwesomeIcon
                            icon={isActive ? 'eye-slash' : 'eye'}
                            color={LAVENDER}
                            size='lg'
                            className='eye'
                          />
                        </button>
                      </div>
                      <span className='roboto-bold-white-16px error-text'>{displayErrorUser('password')}</span>
                    </div>
                    <div className="frame-5355">
                      <button
                        className={`buttons border-1px-electric-violet ${loadingSignup && 'inProcess'}`}
                        type="button"
                        onClick={() => buttonGoBack()}>
                        <div className="login roboto-normal-electric-violet-16px">
                          Go back
                        </div>
                      </button>
                      <button className={`buttons-1 ${loadingSignup && 'inProcess'}`} type="button" onClick={_handleSubmit}>
                        <div className="login-1 roboto-normal-white-16px">
                          {loadingSignup ?
                            <div className="icon-spinner-third buttonSendSignUp"></div>
                            :
                            'Send'
                          }
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

const property1DropdownProperty2Open1Data = {
  select: "Are you a student, organization or other?",
  selectTheInformation: "I am a School",
};

const property1LabelProperty2Open1Data = {
  label: "First Name",
  typeHereTheInformation: "Anita",
  className: "",
};

const property1LabelProperty2Open2Data = {
  label: "Last Name",
  typeHereTheInformation: "Ortiz",
  className: "form-parts-4-1",
};

const frame61301Data = {
  property1LabelProperty2Open1Props: property1LabelProperty2Open1Data,
  property1LabelProperty2Open2Props: property1LabelProperty2Open2Data,
};

const property2round1Data = {
  children: "dot-circle",
};

const property2round2Data = {
  children: "circle",
};

const property1DefaultData = {
  gender: "Gender",
  female: "Female",
  male: "Male",
  property2round1Props: property2round1Data,
  property2round2Props: property2round2Data,
};

const property1LabelProperty2Open3Data = {
  label: "Organization Name",
  typeHereTheInformation: "Montessory School",
  className: "form-parts-3",
};

const property1LabelProperty2Open4Data = {
  label: "Website",
  typeHereTheInformation: "www.montschool.com",
  className: "form-parts-4",
};

const frame61302Data = {
  property1LabelProperty2Open1Props: property1LabelProperty2Open3Data,
  property1LabelProperty2Open2Props: property1LabelProperty2Open4Data,
};

const property1LabelProperty2Open5Data = {
  label: "Organization Name",
  typeHereTheInformation: "115114",
  className: "form-parts-5",
};

const property1DropdownProperty2Open2Data = {
  select: "Low Grade",
  selectTheInformation: "1",
  className: "form-parts-1",
};

const property1DropdownProperty2Open3Data = {
  select: "High Grade",
  selectTheInformation: "5",
  className: "form-parts-2",
};

const property1MainData = {
  children: "Next",
};

const componentData = {
  mainBackground: "https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/6219388fb3094ba2a2c54c20/img/desktop---38@1x.png",
  img1: "https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/group-4960@2x.png",
  img2: "https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/group-4959@1x.png",
  img3: "https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/group-4961@2x.png",
  oneUnifyingPlatfo: "One, unifying platform to manage and promote all extracurricular activities ensuring equal awareness and access to opportunity.",
  saveTeachersTimeA: <>Save teachers time and receive previously unavailable insight into school or system wide engagement.<br />Protect students, with a safe, invitation-only social-network.<br />Align student engagement with digital rewards.</>,
  overlapGroup1: "https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/rectangle-3616@1x.png",
  vector21: "https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/vector-21@2x.png",
  number2: "2",
  property1DropdownProperty2Open1Prop: property1DropdownProperty2Open1Data,
  frame61301Props: frame61301Data,
  property1DefaultProps: property1DefaultData,
  frame61302Props: frame61302Data,
  property1LabelProperty2OpenProps: property1LabelProperty2Open5Data,
  property1DropdownProperty2Open2Prop: property1DropdownProperty2Open2Data,
  property1DropdownProperty2Open3Prop: property1DropdownProperty2Open3Data,
  property1MainProps: property1MainData,
};

export default SignUpContainer;