import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import OrganizationComplete from '../../interfaces/organization/OrganizationComplete';
import { getOrganizationInfo } from '../../store/actions/orgsActions';
import { RootState } from '../../store/store';
import { ManagedTemplate } from '../ClubsManageClubsAdmin';
import { ClubCreate } from '../SetupSetup/Clubs';
import ModalClub from '../SetupSetup/ModalClub';
import './styles.scoped.css';
import { toast } from 'react-toastify'
import ModalTeamSetup from '../SetupSetup/ModalTeamSetup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WHITE } from '../ClubIconsNavBar';
import { faChevronRight, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Backend, MicroServices } from '../../helpers/backendHelper';
import ModalCommunity from '../SetupSetup/ModalCommunity';
import { isUserRegistered } from '../../helpers/userHelper';
import { useTranslation } from "react-i18next";
import { getTClubsByCountry, getTCommsByCountry, getTTeamsByCountry } from "../../store/actions/TClubsActions";
import TClubList from '../SetupSetup/ClubTab/TClubList';

const saveClubAndAdv = (club: ClubCreate, organization: any): Promise<boolean> =>
    new Promise((resolve, rejects) => {
        Backend(MicroServices.Club, `/clubs?pending=${organization?.status.startsWith('PEND')}`, {
            method: 'POST',
            body: JSON.stringify({
                org_id: organization?.id,
                name: club.name,
                template_id: club.template_id,
                member_type: club.member_type,
                maximumNumberMembers: club.maximumNumberMembers,
                gendersAllowed: club.gendersAllowed,
                gradesAllowed: club.gradesAllowed,
                //advisors: club.advisors.map((item)=>item.email),
                description: club.description,
                //created: true,
                skills_learned: club.skills,
                participateInternationalCompetitions: club.international,
                participateNationalCompetitions: club.national,
                participateRegionalCompetitions: club.regional,
                participateLocalCompetitions: club.local,
                applicationRequired: club.applicationRequired,
                clubDuesRequired: club.clubDuesRequired,
                meetingDays: club.meetingDays,
                meetingStartTime: club.meetingStart,
                meetingEndTime: club.meetingEnd,
                timezone: club.timezone,
                meetingFrequency: club.meetingFrequency,
                meetingLocation: club.meetingLocation,
                //"status": props?.organization?.status.startsWith('PEND') ? 'PENDING' : 'ACTIVE',
            }),
        })
            .then((results) => results.json())
            .then((results) => {
                const club_id = results.id
                const promises: any[] = []

                club.advisors.forEach(async (adv) => {
                    if (await isUserRegistered(adv.email, organization?.id)) {
                        promises.push(
                            Backend(
                                MicroServices.Club,
                                `/clubs/${club_id}/advisor?email=${adv.email}`,
                                {
                                    method: 'POST',
                                }
                            )
                        )
                    }
                    else {
                        promises.push(
                            Backend(
                                MicroServices.Invite,
                                `/invites`,
                                {
                                    method: 'POST',
                                    body: JSON.stringify({
                                        email: adv.email,
                                        org_id: organization?.id,
                                        club_id: club_id,
                                        is_advisor: true,
                                        firstName: adv.firstName,
                                        lastName: adv.lastName,
                                    })
                                }
                            ))
                    }
                })

                return Promise.all(promises)
            })
            .then((results) => {
                resolve(results.reduce((acc, curr) => acc && curr.status === 201, true))
            })
            .catch((error) => {
                console.log(error)
                rejects(error)
            })
    })

const saveTeamAndAdv = (club: ClubCreate, organization: any): Promise<boolean> =>
    new Promise((resolve, rejects) => {
        Backend(MicroServices.Club, `/clubs?pending=${organization?.status.startsWith('PEND')}`, {
            method: 'POST',
            body: JSON.stringify({
                org_id: organization?.id,
                name: club.name,
                template_id: club.template_id,
                member_type: club.member_type,
                maximumNumberMembers: club.maximumNumberMembers,
                gendersAllowed: club.gendersAllowed,
                gradesAllowed: club.gradesAllowed,
                //advisors: club.advisors.map((item)=>item.email),
                description: club.description,
                //created: true,
                skills_learned: club.skills,
                participateInternationalCompetitions: club.international,
                participateNationalCompetitions: club.national,
                participateRegionalCompetitions: club.regional,
                participateLocalCompetitions: club.local,
                applicationRequired: club.applicationRequired,
                clubDuesRequired: club.clubDuesRequired,
                meetingDays: club.meetingDays,
                meetingStartTime: club.meetingStart,
                meetingEndTime: club.meetingEnd,
                timezone: club.timezone,
                meetingFrequency: club.meetingFrequency,
                meetingLocation: club.meetingLocation,
                //"status": props?.organization?.status.startsWith('PEND') ? 'PENDING' : 'ACTIVE',
                team_distinguisher: club.team_distinguisher,
            }),
        })
            .then((results) => results.json())
            .then((results) => {
                const club_id = results.id
                const promises: any[] = []
                club.advisors.forEach((adv) => promises.push(
                    Backend(
                        MicroServices.Invite,
                        `/invites`,
                        {
                            method: 'POST',
                            body: JSON.stringify({
                                email: adv.email,
                                org_id: organization?.id,
                                club_id: club_id,
                                is_advisor: true,
                                firstName: adv.firstName,
                                lastName: adv.lastName,
                            })
                        }
                    )))
                return Promise.all(promises)
            })
            .then((results) => {
                resolve(results.reduce((acc, curr) => acc && curr.status === 201, true))
            })
            .catch((error) => {
                console.log(error)
                rejects(error)
            })
    })

const index = (
    { type, reload, templates, searchStringCategory, searchString, createCustom }: {
        type: string;
        reload: any;
        templates: { [category: string]: { name: string, templateClubs: ManagedTemplate[] } },
        searchStringCategory: string,
        searchString: string,
        createCustom: { set: any, get: boolean }
    }) => {

    const { t, i18n } = useTranslation();

    const [allCategories, setAllCategories] = useState<string[]>([])
    const [allClubs, setAllClubs] = useState<string[]>([])
    const [categories, setCategories] = useState<string[]>([])
    const [clubs, setClubs] = useState<string[]>([])
    const [selectedCategory, setSelectedCategory] = useState<string>('All Categories')
    const tclubs = useSelector((state: RootState) => state.getTClubs.TClubs);
    const tteams = useSelector((state: RootState) => state.getTClubs.TTeam);
    const tags = useSelector((state: RootState) => state.getTClubs.TClubsTags);

    const findCategoryOfTemplate = (template_id: string): string | undefined => {
        for (let key in templates) {
            const value = templates[key]
            if (value.templateClubs.find((item) => item.category === template_id)) return key
        }
        return undefined
    }

    //Generate arrays to show
    useEffect(() => {
        setAllCategories(Object.keys(templates))
        setSelectedCategory('All Categories')
    }, [templates])


    const tclubsFiltered = useMemo(
        () => tclubs
            .filter((tclub) => tclub.name.toLowerCase().includes(searchString.toLowerCase()))
            .filter((tclub) => selectedCategory === 'All Categories' || tclub.tags.includes(selectedCategory)),
        [
            searchString,
            tclubs,
            selectedCategory,
        ]
    )

    const tteamsFiltered = useMemo(
        () => tteams
            .filter((tclub) => tclub.name.toLowerCase().includes(searchString.toLowerCase())),
        [
            searchString,
            tteams
        ]
    )

    const categoriesFiltered = useMemo(
        () => tags
            .filter((tag) => tag.toLowerCase().includes(searchStringCategory.toLowerCase())),
        [
            searchStringCategory,
        ]
    )

    useEffect(() => {
        if (selectedCategory !== 'All Categories') {
            // setAllClubs(templates[selectedCategory].templateClubs.map((item) => item.name))
            // return
        }

        let array: string[] = []
        for (let category in templates) {
            let value = templates[category].templateClubs;
            array = array.concat(value.map((item) => item.name))
        }
        setAllClubs(array)
    }, [allCategories, selectedCategory])

    useEffect(() => {
        setCategories(allCategories)
        setClubs(allClubs)
    }, [allCategories, allClubs])

    //Filter search
    useEffect(() => {
        setCategories(tags.filter((item) => item.toLowerCase().trim().includes(searchStringCategory.toLocaleLowerCase().trim())))
    }, [searchStringCategory])

    useEffect(() => {
        setClubs(allClubs.filter((item) => item.toLowerCase().trim().includes(searchString.toLocaleLowerCase().trim())))
    }, [searchString])

    //Add a new club
    const dispatch = useDispatch()
    const staffUsers = useSelector((state: RootState) => state.getOrgs.staffUsers)
    const [showClubModal, setShowClubModal] = useState<boolean>(false)
    const [selectedTemplate, setSelectedTemplate] = useState<string>('')

    const [organization, setOrganization] = useState<OrganizationComplete | undefined>(undefined);
    const user = useSelector((state: any) => state.getUser.user);
    useEffect(() => {
        dispatch<any>(getOrganizationInfo(user.organizations[0]))
            .then((orgResults: any) => {
                setOrganization(orgResults);
            })
            .catch(console.log);
    }, [])

    const handleCloseClubModal = (club?: ClubCreate) => {
        setShowClubModal(false)
        if (club) {
            createCustom.set(false)
            saveClubAndAdv(club, organization)
                .then((results) => {
                    if (results) {
                        toast.success(`${t('toast_successCreatingClub')} ${club.template_id} ${t('toast_successCreatingClubT')}`)
                        reload()
                    } else {
                        toast.error(t('toast_errorOccurred'))
                    }
                })
        } else {
            createCustom.set(false)
        }
    }

    const handleCloseTeamModal = (club?: ClubCreate) => {
        setShowClubModal(false)
        if (club) {
            createCustom.set(false)
            const team = completeTeam(club)
            saveTeamAndAdv(team, organization)
                .then((results) => {
                    if (results) {
                        toast.success(`${t('toast_successCreatingTeam')} ${team.template_id} ${t('toast_successCreatingTeamT')}`)
                        reload()
                    } else {
                        toast.error(t('toast_errorOccurred'))
                    }
                })
        } else {
            createCustom.set(false)
        }
    }

    const handleCloseCommunityModal = (club?: ClubCreate) => {
        toast.error(t('toast_creatingNewComm'))
        setShowClubModal(false)
        if (club) {
            createCustom.set(false)
            // TODO
        } else {
            createCustom.set(false)
        }
    }

    const completeTeam = (club: ClubCreate): ClubCreate => {
        if (selectedTemplate === '') return {
            ...club,
            template_id: '',
            gendersAllowed: club.gendersAllowed,
            team_distinguisher: 'Team-Custom',
        }
        const template_parts = selectedTemplate.split(' ')
        return {
            ...club,
            template_id: template_parts.slice(2).join(' '),
            gendersAllowed: template_parts[0] === 'Girls' ? ['F'] : template_parts[0] === 'Boys' ? ['M'] : ['F', 'M', 'O', 'P'],
            team_distinguisher: `${template_parts[1]}-${template_parts[0]}`,
        }
    }

    useEffect(() => {
        if (createCustom.get) {
            setSelectedTemplate('')
            setShowClubModal(true)
        }
    }, [createCustom.get])

    useEffect(() => {
        if (organization && organization?.countryCode) {
            dispatch(getTClubsByCountry(organization?.countryCode));
            dispatch(getTCommsByCountry(organization?.countryCode));
            dispatch(getTTeamsByCountry(organization?.countryCode));
        }
    }, [organization]);

    return (
        <>
            <div className='listAllCategoriesAC'>
                {type === 'CLUB' &&
                    <div className='listItemsCategoriesAC'>
                        <ul>
                            <li className='itemActiveAC'>
                                {selectedCategory}
                                <FontAwesomeIcon
                                    icon={faChevronRight}
                                    size='xl'
                                    color={WHITE}
                                    className="icon-chevron-right"
                                />
                            </li>
                        </ul>
                        <ul className='listCatgAc'>
                            {['All Categories', ...categoriesFiltered].filter((key) => key !== selectedCategory).map((key) => (
                                <li onClick={() => setSelectedCategory(key)}>
                                    {key}
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        size='xl'
                                        color={WHITE}
                                        className="icon-chevron-right"
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                }
                <div className="allCategoriesAC">
                    {type === 'CLUB' ? (
                        (tclubsFiltered).map((tclub) => (
                            <>
                                <div className='itemCategoryAC' onClick={() => {
                                    setSelectedTemplate(tclub.TCLists?.[0]?.assignedName || tclub.name)
                                    setShowClubModal(true)
                                }}>
                                    {tclub.TCLists?.[0]?.assignedName || tclub.name}
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        size='xl'
                                        color={WHITE}
                                        className="icon-chevron-right"
                                    />
                                </div>
                            </>
                        ))
                    )
                        : type === 'TEAM' ? (
                            (tteamsFiltered).map((tclub) => (
                                <>
                                    <div className='itemCategoryAC' onClick={() => {
                                        setSelectedTemplate(tclub.TCLists?.[0]?.assignedName || tclub.name)
                                        setShowClubModal(true)
                                    }}>
                                        {tclub.TCLists?.[0]?.assignedName || tclub.name}
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            size='xl'
                                            color={WHITE}
                                            className="icon-chevron-right"
                                        />
                                    </div>
                                </>
                            ))
                        )
                            : type === 'COMM' ? (
                                "comm"
                            ) :
                                (<></>)
                    }
                    {/* {clubs.map((key) => (
                        <div className='itemCategoryAC' onClick={() => {
                            setSelectedTemplate(key)
                            setShowClubModal(true)
                        }}>
                            {key}
                            <FontAwesomeIcon
                                icon={faPlus}
                                size='xl'
                                color={WHITE}
                                className="icon-chevron-right"
                            />
                        </div>
                    ))} */}
                </div>
            </div>
            {type === 'CLUB' ? (
                <ModalClub
                    staffUsers={staffUsers}
                    updateStaffUsers={() => { }}
                    organization={organization}
                    onHide={handleCloseClubModal}
                    title={selectedTemplate}
                    show={showClubModal}
                    clubToEdit={undefined}
                    modalType={'Create'} />
            ) : type === 'TEAM' ? (
                <ModalTeamSetup
                    show={showClubModal}
                    onHide={handleCloseTeamModal}
                    title={selectedTemplate}
                    organization={organization}
                    staffUsers={staffUsers}
                    updateStaffUsers={() => { }}
                    category={findCategoryOfTemplate(selectedTemplate)}
                    teamSelected={{}}
                    teamToEdit={undefined}
                    not_from_setup={true}
                    createCustom={createCustom.get}
                />
            ) : type === 'COMM' ? (
                <ModalCommunity
                    show={showClubModal}
                    onHide={handleCloseCommunityModal}
                    title={''}
                    clubToEdit={undefined}
                    modalType={'Create'} />
            ) : (<></>)}
        </>

    )
}

export default index