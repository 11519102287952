import "../../containers/SetupSchool/styles.scoped.css";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";
import Select from 'react-select';

toast.configure()

interface ModalAddAdvisorProps {
  show: boolean;
  onHide: (
    e?: any,
    step?: any,
    id?: any,
    type?: any,
    firstName?: string,
    lastName?: string,
    email?: string,
    sendEmail?: boolean
  ) => void;
  idCard?: string;
  type?: string;
  loadingAdvisor?: boolean;
  staffUsers?: any[];
  updateCommInvites?: () => void;
  clubName: string;
  addedAdvisors?: string[];
  name?: string;
}

const styles = {
  option: (styles: any) => ({
    ...styles,
    color: 'white',
    backgroundColor: "#292929",
    fontFamily: 'Roboto Condensed',
    "&:hover": {
      backgroundColor: "#7400F9"
    },
  }),
  input: (styles: any, state: any) => ({
    ...styles,
    color: 'white',
    fontFamily: 'Roboto Condensed'
  }),
  container: (styles: any) => ({
    ...styles,
    width: '100%',
    padding: '0 0 15px 15px',
  }),
  valueContainer: (styles: any, state: any) => ({
    ...styles,
    color: 'white',
  }),
  menu: (styles: any) => ({
    ...styles,
    backgroundColor: '#292929',
    margin: '-12px 0px'
  }),
  control: (styles: any, state: any) => ({
    ...styles,
    height: '20px',
    backgroundColor: '#292929',
    border: 0,
    boxShadow: 'none',
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: 'white',
    fontFamily: 'Roboto Condensed'
  }),
  indicatorSeparator: (styles: any) => ({ ...styles, display: 'none' }),
  dropdownIndicator: (styles: any) => ({ ...styles, }),
  noOptionsMessage: (styles: any) => ({
    ...styles,
    fontFamily: 'Roboto Condensed'
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: 'white',
    fontFamily: 'Roboto Condensed',
  }),
};

function ModalAddAdvisor(props: ModalAddAdvisorProps) {
  const { t, i18n } = useTranslation();
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showError, setShowError] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const staffUsers = useSelector((state: RootState) => state.getInvites.communityInvites.staffUsers || [])

  const handleOnChangeFirstName = (e: any) => {
    const user = (props.staffUsers ? props.staffUsers : staffUsers).find((item: any) => item.email.trim() === email.trim())

    if (user) {
      e.target.value = firstName
    } else {
      setFirstName(e.target.value);
    }
  }
  const handleOnChangeLastName = (e: any) => {
    const user = (props.staffUsers ? props.staffUsers : staffUsers).find((item: any) => item.email.trim() === email.trim())

    if (user) {
      e.target.value = lastName
    } else {
      setLastName(e.target.value);
    }
  }
  const handleOnChangeEmail = (e: any) => {
    setEmail(e.value);
    const user = (props.staffUsers ? props.staffUsers : staffUsers).find((item: any) => item.email.trim() === e.value.trim())
    if (user) {
      setFirstName(user.name)
      setLastName(user.lastname)
    }
  }
  const handleOnChangeSendEmail = (e: any) => {
    setSendEmail(e.target.checked);
  }

  const onHide = (e: any) => {
    if (!(props.staffUsers ? props.staffUsers : staffUsers).map((item: any) => item.email).find((advEmail: string) => advEmail.trim() === email.trim())) {
      setErrorMsg('This is not a staff or principal email. Please provide a staff or principal email or invite this email to staff club.');
      setShowError(true);
    }
    else if ((props.addedAdvisors || []).find((item) => item === email)) {
      setErrorMsg('This advisor is already added. Please provide another staff email or close this window.');
      setShowError(true);
    }
    else {
      setShowError(false);
      setSendEmail(true);
      setFirstName('');
      setLastName('');
      setEmail('');
      props.onHide(
        e,
        'btnSave',
        props.idCard,
        props.type,
        firstName,
        lastName,
        email.trim(),
        sendEmail
      );
    }
  }

  const onCancel = (e: any) => {
    setShowError(false);
    setSendEmail(true);
    setFirstName('');
    setLastName('');
    setEmail('');
    props.onHide(
      e,
      'btnClose',
      props.idCard,
      props.type
    );
  }

  const onhideModal = () => {
    setShowError(false);
    setSendEmail(true);
    setFirstName('');
    setLastName('');
    setEmail('');
    props.onHide();
  }

  return (
    <>

      <Modal className="modalInviteMemebers" show={props.show} onHide={onhideModal}>
        <Modal.Header>
          <Modal.Title>{`${t('modalAddAdvisor__title')} ${props.clubName}`}</Modal.Title>
          <FontAwesomeIcon
            icon="times"
            size='xl'
            color={WHITE}
            className="icon-times  "
            onClick={onCancel}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <label htmlFor="">{t('modalAddAdvisor__email')}</label>
            {/* <input name="emailAdvisor" type="text" style={{ color: 'white', paddingLeft: '10px' }} value={email} onChange={handleOnChangeEmail} /> */}
            <Select
              value={{
                value: email,
                label: email
              }}
              isMulti={false}
              options={(props.staffUsers || staffUsers).map((e: any) => {
                return {
                  label: `${e.name} ${e.lastname} (${e.email})`,
                  value: e.email,
                }
              })}
              placeholder={"Select Email"}
              onChange={handleOnChangeEmail}
              styles={styles}
              components={{
                IndicatorSeparator: () => null
              }}
            />
          </div>
          <div className="row">
            <label htmlFor="">{t('modalAddAdvisor__firstname')}</label>
            <input name="firstNameAdvisor" type="text" style={{ color: 'gray', paddingLeft: '10px' }} value={firstName} onChange={handleOnChangeFirstName} />
          </div>
          <div className="row">
            <label htmlFor="">{t('modalAddAdvisor__lastname')}</label>
            <input name="lastNameAdvisor" type="text" style={{ color: 'gray', paddingLeft: '10px' }} value={lastName} onChange={handleOnChangeLastName} />
          </div>
          <div className="containers-input">
            <div className="option-chechbox">
              <label className="content-input">
                <input type="checkbox" name="principal-button" defaultChecked checked={sendEmail} onChange={handleOnChangeSendEmail} />
                <i></i>
              </label>
              <label htmlFor="students">{t('modalAddAdvisor__sendEmail')}</label>
            </div>
          </div>
          {/*
            <div className="add-advisor-modal">
              <FontAwesomeIcon
                icon="user-plus"
                size='xl'
                color={WHITE}
                className="icon-user  "
              />
              <p>Add another Advisor</p>
            </div> 
          */}
          {showError && (
            <div className="row">
              <p className="roboto-normal-white-16px">{errorMsg}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="buttonClose"
            onClick={(e) => onCancel(e)}
          >
            {t('button__cancel')}
          </Button>
          <Button name="btnSaveAdvisor" className="buttonSend" onClick={(e) => onHide(e)}>
            {props.loadingAdvisor ? <div className="icon-spinner-third buttonSendSpinner" /> : `${t('button__save')}`}
          </Button>
        </Modal.Footer>
      </Modal>
    </>


  );
}

export default ModalAddAdvisor;
