import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { SelectedTeams } from "../../containers/SetupSchool";
import "../../containers/SetupSchool/styles.scoped.css";
import TClub from "../../interfaces/TClubs/TClub/TClub";
import TemplateTeam from "../../interfaces/template-clubs/templateTeam";
import { WHITE } from "../ClubIconsNavBar";
import ListedTeam from "./ListedTeam";
import { Team } from "./ModalTeam";

interface ListTeamsProps {
  setChange: (e: any) => void,
  teams: TClub[];
  addSelectedTeams: (templateName: string, teams: Team[], type: 'Create' | 'Edit' | 'SaveEdit') => void;
  selectedTeams: SelectedTeams;
  setSelectedTeams: (e: any) => void,
  dynamoTeams: any[],
  setTeamsEdit: (e: any) => void,
  teamsEdit: { [key: string]: any[] },
  teamsDelete: string[],
  setTeamsDelete: (e: any) => void,
  deleteTemplate: (templateid: string) => void,
  setCreateCustom: any,
}

const ListTeams = ({
  setChange,
  teams,
  addSelectedTeams,
  selectedTeams,
  setSelectedTeams,
  dynamoTeams,
  setTeamsEdit,
  teamsEdit,
  teamsDelete,
  setTeamsDelete,
  deleteTemplate,
  setCreateCustom,
}: ListTeamsProps) => (
  <div className="listTeams">

    {teams.map((team: TClub) => (
      <ListedTeam
        setChange={setChange}
        tteam={team}
        key={team.name}
        addSelectedTeams={addSelectedTeams}
        selectedTeams={selectedTeams}
        setSelectedTeams={setSelectedTeams}
        isSelected={Object.keys(selectedTeams).includes(team.id)}
        isSaved={selectedTeams[team.id] ? selectedTeams[team.id]?.[0]?.active === null : false}
        dynamoTeams={dynamoTeams}
        setTeamsEdit={setTeamsEdit}
        teamsEdit={teamsEdit}
        teamsDelete={teamsDelete}
        setTeamsDelete={setTeamsDelete}
        deleteTemplate={deleteTemplate}
      />
    ))}
    <div className="custom-club-button" onClick={() => setCreateCustom(true)}>
      <div className="custom-club-text">Custom Team</div>
      <FontAwesomeIcon
        icon="plus"
        size='lg'
        color={WHITE}
        className=" "
      />
    </div>
  </div>
);

export default ListTeams;
