import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import './styles.css'

function DropdownReachPost({ postType, setPostType }: { postType: string, setPostType: any }) {
  const { t, i18n } = useTranslation();
  const userRoles = useSelector((state: any) => state.getUser.userRoles);
  const listClubs = useSelector((state: any) => state.getClub.getMyClubs || []);
  const organization = useSelector((state: any) => state.getOrgs.organizationInfo);


  useEffect(() => {
    if (userRoles.includes('SUPER')) {
      setPostType('ALL')
    }
  }, [userRoles])

  return (
    <>
      {(!userRoles.includes('SUPER')) && (
        <div className="reachPost-container">
          <select
            name=""
            id=""
            value={postType}
            onChange={(event) => setPostType(event.target.value)}
          >
            <option value="" disabled>
              {t('label_selectWherPost')}
            </option>

            {(!userRoles.includes('SUPER')) && (
              <option value="WALL">My Wall</option>
            )}

            {userRoles.includes('ZAA#ORGADMIN') && (
              <option value="ORG">{organization.type === 'YOUTHORG' ? 'Organization Bulletin' : 'School Bulletin'}</option>
            )}

            {listClubs?.length > 0 &&
              listClubs.map((club: any) => (
                <option value={club.club_id}>{`${club.name}${club.YO_id && club.YO_id === organization.id ? ` - ${club.org_name}` : ''}`}</option>
              ))}

          </select>
        </div>)}
    </>
  );
}

export default DropdownReachPost;