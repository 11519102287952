// GET TEMPLATE CLUB
export const START_GET_TEMPLATE_CLUB = "START_GET_TEMPLATE_CLUB";
export const GET_TEMPLATE_CLUB_SUCCESS = "GET_TEMPLATE_CLUB_SUCCESS";
export const GET_TEMPLATE_CLUB_ERROR = "GET_TEMPLATE_CLUB_ERROR";
export const START_GET_TEMPLATE_CLUB_LIMIT = "START_GET_TEMPLATE_CLUB_LIMIT";
export const GET_TEMPLATE_CLUB__LIMIT_SUCCESS =
  "GET_TEMPLATE_CLUB__LIMIT_SUCCESS";
export const GET_TEMPLATE_CLUB__LIMIT_ERROR = "GET_TEMPLATE_CLUB__LIMIT_ERROR";

export const START_GET_CLUBS_ORG = 'START_GET_CLUBS_ORG';
export const GET_CLUBS_ORG_SUCCESS = 'GET_CLUBS_ORG_SUCCESS';
export const GET_CLUBS_ORG_ERROR = 'GET_CLUBS_ORG_ERROR';

export const START_GET_CLUB = 'START_GET_CLUB';
export const GET_CLUB_SUCCESS = 'GET_CLUB_SUCCESS';
export const GET_CLUB_ERROR = 'GET_CLUB_ERROR';

export const START_GET_TEMPLATE_CLUB_INFO = 'START_GET_TEMPLATE_CLUB_INFO';
export const GET_TEMPLATE_CLUB_INFO_SUCCESS = 'GET_TEMPLATE_CLUB_INFO_SUCCESS';
export const GET_TEMPLATE_CLUB_INFO_ERROR = 'GET_TEMPLATE_CLUB_INFO_ERROR';

export const START_UPDATE_TCLUB = 'START_UPDATE_TCLUB';
export const UPDATE_TCLUB_SUCCESS = 'UPDATE_TCLUB_SUCCESS';
export const UPDATE_TCLUB_ERROR = 'UPDATE_TCLUB_ERROR';

export const START_GET_MY_CLUBS = 'START_GET_MY_CLUBS';
export const GET_MY_CLUBS_SUCCESS = 'GET_MY_CLUBS_SUCCESS';
export const GET_MY_CLUBS_ERROR = 'GET_MY_CLUBS_ERROR';

export const START_POST_USER_AS_MEMBER = 'START_POST_USER_AS_MEMBER';
export const POST_USER_AS_MEMBER_SUCCESS = 'POST_USER_AS_MEMBER_SUCCESS';
export const POST_USER_AS_MEMBER_ERROR = 'POST_USER_AS_MEMBER_ERROR';

export const START_POST_USER_AS_ADVISOR = 'START_POST_USER_AS_ADVISOR';
export const POST_USER_AS_ADVISOR_SUCCESS = 'POST_USER_AS_ADVISOR_SUCCESS';
export const POST_USER_AS_ADVISOR_ERROR = 'POST_USER_AS_ADVISOR_ERROR';

export const START_POST_USER_AS_FAN = 'START_POST_USER_AS_FAN';
export const POST_USER_AS_FAN_SUCCESS = 'POST_USER_AS_FAN_SUCCESS';
export const POST_USER_AS_FAN_ERROR = 'POST_USER_AS_FAN_ERROR';

export const START_POST_USER_AS_FOLLOWER = 'START_POST_USER_AS_FOLLOWER';
export const POST_USER_AS_FOLLOWER_SUCCESS = 'POST_USER_AS_FOLLOWER_SUCCESS';
export const POST_USER_AS_FOLLOWER_ERROR = 'POST_USER_AS_FOLLOWER_ERROR';

export const START_POST_USER_AS_JOIN_REQUESTED = 'START_POST_USER_AS_JOIN_REQUESTED';
export const POST_USER_AS_JOIN_REQUESTED_SUCCESS = 'POST_USER_AS_JOIN_REQUESTED_SUCCESS';
export const POST_USER_AS_JOIN_REQUESTED_ERROR = 'POST_USER_AS_JOIN_REQUESTED_ERROR';

export const START_POST_USER_AS_QUIT_REQUESTED = 'START_POST_USER_AS_QUIT_REQUESTED';
export const POST_USER_AS_QUIT_REQUESTED_SUCCESS = 'POST_USER_AS_QUIT_REQUESTED_SUCCESS';
export const POST_USER_AS_QUIT_REQUESTED_ERROR = 'POST_USER_AS_QUIT_REQUESTED_ERROR';

export const START_GET_USER_PENDING_CLUBS = 'START_GET_USER_PENDING_CLUBS';
export const GET_USER_PENDING_CLUBS_SUCCESS = 'GET_USER_PENDING_CLUBS_SUCCESS';
export const GET_USER_PENDING_CLUBS_ERROR = 'GET_USER_PENDING_CLUBS_ERROR';

export const START_DELETE_JOIN = 'START_DELETE_JOIN';
export const DELETE_JOIN_SUCCESS = 'DELETE_JOIN_SUCCESS';
export const DELETE_JOIN_ERROR = 'DELETE_JOIN_ERROR';

export const START_GET_COMMUNITIES = 'START_GET_COMMUNITIES';
export const GET_COMMUNITIES_SUCCESS = 'GET_COMMUNITIES_SUCCESS';
export const GET_COMMUNITIES_ERROR = 'GET_COMMUNITIES_ERROR';

export const START_ORGADMIN_ADVISOR = 'START_ORGADMIN_ADVISOR';
export const ORGADMIN_ADVISOR_SUCCESS = 'ORGADMIN_ADVISOR_SUCCESS';
export const ORGADMIN_ADVISOR_ERROR = 'ORGADMIN_ADVISOR_ERROR';

export const START_CLUBS_IM_ADV = 'START_CLUBS_IM_ADV';
export const CLUBS_IM_ADV_SUCCESS = 'CLUBS_IM_ADV_SUCCESS';
export const CLUBS_IM_ADV_ERROR = 'CLUBS_IM_ADV_ERROR';

export const START_GET_COMM = 'START_GET_COMM';
export const GET_COMM_SUCCESS = 'GET_COMM_SUCCESS';
export const GET_COMM_ERROR = 'GET_COMM_ERROR';

export const START_GET_CLUB_MEMBERS = 'START_GET_CLUB_MEMBERS';
export const GET_CLUB_MEMBERS_SUCCESS = 'GET_CLUB_MEMBERS_SUCCESS';
export const GET_CLUB_MEMBERS_ERROR = 'GET_CLUB_MEMBERS_ERROR';

export const START_EDIT_CLUB = 'START_EDIT_CLUB'
export const EDIT_CLUB_SUCCESS = 'EDIT_CLUB_SUCCESS'
export const EDIT_CLUB_ERROR = 'EDIT_CLUB_ERROR'

export const START_GET_COMM_ORG_SETUP = 'START_GET_COMM_ORG_SETUP'
export const GET_COMM_ORG_SETUP_SUCCESS = 'GET_COMM_ORG_SETUP_SUCCESS'
export const GET_COMM_ORG_SETUP_ERROR = 'GET_COMM_ORG_SETUP_ERROR'

export const LOGOUT_CLUB = 'LOGOUT_CLUB';