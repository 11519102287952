import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import {
  START_SAVE_REACTION,
  SAVE_REACTION_SUCCESS,
  SAVE_REACTION_ERROR,
  GET_MY_POSTS_ERROR,
  GET_MY_POSTS_SUCCESS,
  GET_POSTS_BULLETIN_ERROR,
  GET_POSTS_BULLETIN_SUCCESS,
  GET_POSTS_CLUB_ERROR,
  GET_POSTS_CLUB_SUCCESS,
  GET_POSTS_EVENT_ERROR,
  GET_POSTS_EVENT_SUCCESS,
  GET_POSTS_FRIENDS_ERROR,
  GET_POSTS_FRIENDS_SUCCESS,
  GET_POSTS_ORG_ERROR,
  GET_POSTS_ORG_SUCCESS,
  GET_POSTS_SAVED_ERROR,
  GET_POSTS_SAVED_SUCCESS,
  GET_POSTS_WALL_ERROR,
  GET_POSTS_WALL_SUCCESS,
  GET_POST_ALL_ERROR,
  GET_POST_ALL_SUCCESS,
  START_GET_MY_POSTS,
  START_GET_POSTS_BULLETIN,
  START_GET_POSTS_CLUB,
  START_GET_POSTS_EVENT,
  START_GET_POSTS_FRIENDS,
  START_GET_POSTS_ORG,
  START_GET_POSTS_SAVED,
  START_GET_POSTS_WALL,
  START_GET_POST_ALL,
  CLEAN_POST_EXCEPTION,
} from "../constants/postConstants";
import { mapPosts } from "../../helpers/postHelper";
import { Backend, MicroServices } from "../../helpers/backendHelper";

export const reactToPost = (post_id: string, reaction: string, username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_SAVE_REACTION });
    Backend(
      MicroServices.Posts,
      `/reaction`, {
      method: 'POST',
      body: JSON.stringify(
        {
          username: username,
          post_id: post_id,
          reaction: reaction
        }
      ),
    })
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: SAVE_REACTION_SUCCESS,
          payload: reaction
        })
        resolve(results);
      })
      .catch((error) => {
        dispatch({
          type: SAVE_REACTION_ERROR,
          payload: error,
        });
      });
  });

export const reactToComment = (comment_id: string, reaction: string, username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_SAVE_REACTION });
    Backend(
      MicroServices.Posts,
      `/reaction/comment`, {
      method: 'POST',
      body: JSON.stringify(
        {
          username: username,
          comment_id: comment_id,
          reaction: reaction
        }
      ),
    })
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: SAVE_REACTION_SUCCESS,
          payload: reaction
        })
        resolve(results);
      })
      .catch((error) => {
        dispatch({
          type: SAVE_REACTION_ERROR,
          payload: error,
        });
      });
  });

export const reloadPostsAll = (form = {
  username: '',
  event_id: '',
  club_id: '',
  org_id: '',
  wall_id: '',
}): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_POST_ALL });

    const promises: any[] = [];

    if (form.event_id !== '') {
      promises.push(dispatch(reloadPostsEvent(form.event_id)));
    }

    if (form.club_id !== '') {
      promises.push(dispatch(reloadPostsClub(form.club_id)));
    }

    if (form.org_id !== '') {
      promises.push(dispatch(reloadPostsOrg(form.org_id)));
    }

    if (form.wall_id !== '') {
      promises.push(dispatch(reloadPostsWall(form.wall_id)));
    }

    if (form.username !== '') {
      promises.push(dispatch(reloadPostsSaved(form.username)));
      promises.push(dispatch(reloadMyPosts(form.username)));
      promises.push(dispatch(reloadPostsFriends(form.username)));
      promises.push(dispatch(reloadPostsBulletin(form.username)));
    }

    Promise.all(promises)
      .then((results) => {
        dispatch({
          type: GET_POST_ALL_SUCCESS,
          payload: results,
        })
      })
      .catch((error) => {
        console.error('Error on reloadPostAll: ', error)
        dispatch({
          type: GET_POST_ALL_ERROR,
          payload: error,
        });
      });
  });

export const cleanPostsException =
  (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      dispatch({
        type: CLEAN_POST_EXCEPTION
      });
    };

export const reloadPostsBulletin = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_POSTS_BULLETIN });
    Backend(
      MicroServices.Posts,
      `/posts?reach=BULLETIN&reach_id=${username}`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_POSTS_BULLETIN_SUCCESS,
          payload: mapPosts(results)
        })
        resolve(results);
      })
      .catch((error) => {
        dispatch({
          type: GET_POSTS_BULLETIN_ERROR,
          payload: error,
        });
      });
  });

export const reloadPostsOrg = (orgId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_POSTS_ORG });
    Backend(
      MicroServices.Posts,
      `/posts?reach=ORG&reach_id=${orgId}`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_POSTS_ORG_SUCCESS,
          payload: mapPosts(results)
        })
        resolve(results);
      })
      .catch((error) => {
        dispatch({
          type: GET_POSTS_ORG_ERROR,
          payload: error,
        });
      });
  });

export const reloadPostsFriends = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_POSTS_FRIENDS });
    Backend(
      MicroServices.Posts,
      `/posts/friends?username=${username}`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_POSTS_FRIENDS_SUCCESS,
          payload: mapPosts(results)
        })
        resolve(results);
      })
      .catch((error) => {
        dispatch({
          type: GET_POSTS_FRIENDS_ERROR,
          payload: error,
        });
      });
  });

export const reloadPostsSaved = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_POSTS_SAVED });
    Backend(
      MicroServices.Posts,
      `/posts/saved?username=${username}`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_POSTS_SAVED_SUCCESS,
          payload: mapPosts(results)
        })
        resolve(results);
      })
      .catch((error) => {
        dispatch({
          type: GET_POSTS_SAVED_ERROR,
          payload: error,
        });
      });
  });

export const reloadMyPosts = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_MY_POSTS });
    dispatch(cleanPostsException());
    Backend(
      MicroServices.Posts,
      `/posts/by?username=${username}`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_MY_POSTS_SUCCESS,
          payload: mapPosts(results)
        })
        resolve(results);
      })
      .catch((error) => {
        dispatch({
          type: GET_MY_POSTS_ERROR,
          payload: error,
        });
      });
  });

export const reloadPostsWall = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_POSTS_WALL });
    dispatch(cleanPostsException());
    Backend(
      MicroServices.Posts,
      `/posts?reach=WALL&reach_id=${username}`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_POSTS_WALL_SUCCESS,
          payload: mapPosts(results)
        })
        resolve(results);
      })
      .catch((error) => {
        dispatch({
          type: GET_POSTS_WALL_ERROR,
          payload: error,
        });
      });
  });

export const reloadPostsEvent = (eventId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_POSTS_EVENT });
    dispatch(cleanPostsException());
    Backend(
      MicroServices.Posts,
      `/posts?reach=EVENT&reach_id=${eventId}`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_POSTS_EVENT_SUCCESS,
          payload: mapPosts(results)
        })
        resolve(results);
      })
      .catch((error) => {
        dispatch({
          type: GET_POSTS_EVENT_ERROR,
          payload: error,
        });
      });
  });

export const reloadPostsClub = (clubId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_POSTS_CLUB });
    dispatch(cleanPostsException());
    Backend(
      MicroServices.Posts,
      `/posts?reach=CLUB&reach_id=${clubId}`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_POSTS_CLUB_SUCCESS,
          payload: mapPosts(results)
        })
        resolve(results);
      })
      .catch((error) => {
        dispatch({
          type: GET_POSTS_CLUB_ERROR,
          payload: error,
        });
      });
  });