import styled from "styled-components";

interface TCListSearchProps {
    searchTerm: string;
    setSearchTerm: (term: string) => void;
};

const TCListSearch = ({
    searchTerm,
    setSearchTerm
}: TCListSearchProps) => {
    return (
        <Input
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
        />
    )
};

const Input = styled.input`
    width: 100%;
    border: none;
    margin: none;
    background-color: #28115a;
    font-size: 16px;
    font-family: 'Roboto';
    color: white;
    padding: 9px;
`

export default TCListSearch;