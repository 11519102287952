import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useSelector } from "react-redux";
import { getNameUser } from "../../helpers/userHelper";
import Property1AdminProperty2NoProperty3W from "../Property1AdminProperty2NoProperty3W";
import "./styles.css";

interface LeftMenuProps {
  screen: string;
}

const index = ({ screen }: LeftMenuProps) => {
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const user = useSelector((state: any) => state.getUser.user);
  const userRoles = useSelector((state: any) => state.getUser.userRoles);
  const [showBubble, setShowBubble] = useState(false);

  return (
    <div className={`menu-sidebar ${showBubble && 'enableOverflow'}`}>
      <div className="profile">
        <Property1AdminProperty2NoProperty3W
          logo={undefined}
        />
        <div className="superadmin">
          {getNameUser(user, userInfo, userRoles)}
        </div>
      </div>
      <nav className="menu1">
        <div
          className="section"
          style={{
            backgroundColor:
              screen === "/superadmin/" || screen === "/"
                ? "#250D44"
                : "transparent",
          }}
        >
          <div className="section-cont">
            <a
              className="section-text robotocondensed-normal-white-16px"
              href="/superadmin/"
              style={{
                color:
                  screen === "/superadmin/" || screen === "/"
                    ? "#A885D7"
                    : "white",
                fontWeight:
                  screen === "/superadmin/" || screen === "/"
                    ? "bold"
                    : "normal",
              }}
            >
              Dashboard
            </a>
          </div>
        </div>
        <div
          className="section"
          style={{
            backgroundColor: screen.includes("/superadmin/users")
              ? "#250D44"
              : "transparent",
          }}
        >
          <div className="section-cont">
            <a
              className="section-text robotocondensed-normal-white-16px"
              href="/superadmin/users"
              style={{
                color: screen.includes("/superadmin/users")
                  ? "#A885D7"
                  : "white",
                fontWeight: screen.includes("/superadmin/users")
                  ? "bold"
                  : "normal",
              }}
            >
              Users
            </a>
          </div>
        </div>
        <div
          className="section"
          style={{
            backgroundColor: screen === "Posts" ? "#250D44" : "transparent",
          }}
          onClick={() => setShowBubble(true)}
        >
          <div className="section-cont robotocondensed-normal-white-16px" style={{ position: "relative" }}>
            {/* <a
              className="section-text robotocondensed-normal-white-16px"
              href=""
            > */}
            Posts
            {/* </a> */}
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowBubble(false);
              }}
            >
              <div className={`bubblePostEnable ${showBubble && 'bubbleActive'}`}>
                Viewing system wide posts is presently not available
              </div>
            </OutsideClickHandler>
          </div>

        </div>
        <div
          className="section"
          style={{
            backgroundColor: screen.includes("/superadmin/organizations")
              ? "#250D44"
              : "transparent",
          }}
        >
          <div className="section-cont">
            <a
              className="section-text robotocondensed-normal-white-16px"
              href="/superadmin/organizations"
              style={{
                color: screen.includes("/superadmin/organizations")
                  ? "#A885D7"
                  : "white",
                fontWeight: screen.includes("/superadmin/organizations")
                  ? "bold"
                  : "normal",
              }}
            >
              Organizations
            </a>
          </div>
        </div>
        <div
          className="section"
          style={{
            backgroundColor: screen.includes("/superadmin/yotable")
              ? "#250D44"
              : "transparent",
          }}
        >
          <div className="section-cont">
            <a
              className="section-text robotocondensed-normal-white-16px"
              href="/superadmin/yotable"
              style={{
                color: screen.includes("/superadmin/yotable")
                  ? "#A885D7"
                  : "white",
                fontWeight: screen.includes("/superadmin/yotable")
                  ? "bold"
                  : "normal",
              }}
            >
              Youth Organization
            </a>
          </div>
        </div>
        <div className="section"
          style={{
            backgroundColor: screen.includes("/superadmin/school-database")
              ? "#250D44"
              : "transparent",
          }}>
          <div className="section-cont">
            <a
              className="section-text robotocondensed-normal-white-16px"
              href="/superadmin/school-database"
              style={{
                color: screen.includes("/superadmin/school-database")
                  ? "#A885D7"
                  : "white",
                fontWeight: screen.includes("/superadmin/school-database")
                  ? "bold"
                  : "normal",
              }}
            >
              School Database
            </a>
          </div>
          {/* <div className="section-cont">
            <a className="section-text robotocondensed-normal-white-16px" href="/#" style={{paddingLeft: "50px"}}>
              database
            </a>
          </div> */}
        </div>
        <div className="section"
          style={{
            backgroundColor: screen.includes("/superadmin/school-districts")
              ? "#250D44"
              : "transparent",
          }}>
          <div className="section-cont">
            <a
              className="section-text robotocondensed-normal-white-16px"
              href="/superadmin/school-districts"
              style={{
                color: screen.includes("/superadmin/school-districts")
                  ? "#A885D7"
                  : "white",
                fontWeight: screen.includes("/superadmin/school-districts")
                  ? "bold"
                  : "normal",
              }}
            >
              School Districts
            </a>
          </div>
          <div className="section-cont">
            <a
              className="section-text robotocondensed-normal-white-16px"
              href="/superadmin/school-districts"
              style={{
                paddingLeft: "50px",
                color: screen.includes("/superadmin/school-districts")
                  ? "#A885D7"
                  : "white",
                fontWeight: screen.includes("/superadmin/school-districts")
                  ? "bold"
                  : "normal"
              }}
            >
              or Systems
            </a>
          </div>
        </div>

        <div
          className="section"
          style={{
            backgroundColor: screen.includes("/superadmin/communities")
              ? "#250D44"
              : "transparent",
          }}
        >
          <div className="section-cont">
            <a
              className="section-text robotocondensed-normal-white-16px"
              href="/superadmin/communities"
              style={{
                color: screen.includes("/superadmin/communities")
                  ? "#A885D7"
                  : "white",
                fontWeight: screen.includes("/superadmin/communities")
                  ? "bold"
                  : "normal",
              }}
            >
              Communities
            </a>
          </div>
        </div>

        <div
          className="section"
          style={{
            backgroundColor: screen.includes("/superadmin/clubs")
              ? "#250D44"
              : "transparent",
          }}
        >
          <div className="section-cont">
            <a
              className="section-text robotocondensed-normal-white-16px"
              href="/superadmin/clubs"
              style={{
                color: screen.includes("/superadmin/clubs")
                  ? "#A885D7"
                  : "white",
                fontWeight: screen.includes("/superadmin/clubs")
                  ? "bold"
                  : "normal",
              }}
            >
              Clubs
            </a>
          </div>
        </div>

        <div
          className="section"
          style={{
            backgroundColor: screen.includes("/superadmin/teams")
              ? "#250D44"
              : "transparent",
          }}
        >
          <div className="section-cont">
            <a
              className="section-text robotocondensed-normal-white-16px"
              href="/superadmin/teams"
              style={{
                color: screen.includes("/superadmin/teams")
                  ? "#A885D7"
                  : "white",
                fontWeight: screen.includes("/superadmin/teams")
                  ? "bold"
                  : "normal",
              }}
            >
              Teams
            </a>
          </div>
        </div>

        {/* <div
          className="section"
          style={{
            backgroundColor: screen.includes("/superadmin/template-categories")
              ? "#250D44"
              : "transparent",
          }}
        >
          <div className="section-cont">
            <a
              className="section-text robotocondensed-normal-white-16px"
              href="/superadmin/template-categories"
              style={{
                color: screen.includes("/superadmin/template-categories")
                  ? "#A885D7"
                  : "white",
                fontWeight: screen.includes("/superadmin/template-categories")
                  ? "bold"
                  : "normal",
              }}
            >
              Template
            </a>
          </div>
          <div className="section-cont">
            <a
              className="section-text robotocondensed-normal-white-16px"
              href="/superadmin/template-categories"
              style={{
                color: screen.includes("/superadmin/template-categories")
                  ? "#A885D7"
                  : "white",
                fontWeight: screen.includes("/superadmin/template-categories")
                  ? "bold"
                  : "normal",
              }}
            >
              Categories
            </a>
          </div>
        </div>

        <div
          className="section"
          style={{
            backgroundColor: screen.includes("/superadmin/template-clubs")
              ? "#250D44"
              : "transparent",
          }}
        >
          <div className="section-cont">
            <a
              className="section-text robotocondensed-normal-white-16px"
              href="/superadmin/template-clubs"
              style={{
                color: screen.includes("/superadmin/template-clubs")
                  ? "#A885D7"
                  : "white",
                fontWeight: screen.includes("/superadmin/template-clubs")
                  ? "bold"
                  : "normal",
              }}
            >
              Template
            </a>
          </div>
          <div className="section-cont">
            <a
              className="section-text robotocondensed-normal-white-16px"
              href="/superadmin/template-clubs"
              style={{
                color: screen.includes("/superadmin/template-clubs")
                  ? "#A885D7"
                  : "white",
                fontWeight: screen.includes("/superadmin/template-clubs")
                  ? "bold"
                  : "normal",
              }}
            >
              Clubs
            </a>
          </div>
        </div>*/}

        <div
          className="section"
          style={{
            backgroundColor: screen.includes("/superadmin/tclubs")
              ? "#250D44"
              : "transparent",
          }}
        >
          <div className="section-cont">
            <a
              className="section-text robotocondensed-normal-white-16px"
              href="/superadmin/tclubs"
              style={{
                color: screen.includes("/superadmin/tclubs")
                  ? "#A885D7"
                  : "white",
                fontWeight: screen.includes("/superadmin/tclubs")
                  ? "bold"
                  : "normal",
              }}
            >
              Template
            </a>
          </div>
          <div className="section-cont">
            <a
              className="section-text robotocondensed-normal-white-16px"
              href="/superadmin/tclubs"
              style={{
                color: screen.includes("/superadmin/tclubs")
                  ? "#A885D7"
                  : "white",
                fontWeight: screen.includes("/superadmin/tclubs")
                  ? "bold"
                  : "normal",
              }}
            >
              Clubs
            </a>
          </div>
        </div>
        <div
          className="section"
          style={{
            backgroundColor: screen.includes("/superadmin/interests")
              ? "#250D44"
              : "transparent",
          }}
        >
          <div className="section-cont">
            <a
              className="section-text robotocondensed-normal-white-16px"
              href="/superadmin/interests"
              style={{
                color: screen.includes("/superadmin/interests")
                  ? "#A885D7"
                  : "white",
                fontWeight: screen.includes("/superadmin/interests")
                  ? "bold"
                  : "normal",
              }}
            >
              Interests
            </a>
          </div>
        </div>
        <div
          className="section"
          style={{
            backgroundColor: screen.includes("/superadmin/countries")
              ? "#250D44"
              : "transparent",
          }}
        >
          <div className="section-cont">
            <a
              className="section-text robotocondensed-normal-white-16px"
              href="/superadmin/countries"
              style={{
                color: screen.includes("/superadmin/countries")
                  ? "#A885D7"
                  : "white",
                fontWeight: screen.includes("/superadmin/countries")
                  ? "bold"
                  : "normal",
              }}
            >
              Countries
            </a>
          </div>
        </div>
        <div
          className="section"
          style={{
            backgroundColor: screen.includes("/superadmin/lang")
              ? "#250D44"
              : "transparent",
          }}
        >
          <div className="section-cont">
            <a
              className="section-text robotocondensed-normal-white-16px"
              href="/superadmin/lang"
              style={{
                color: screen.includes("/superadmin/lang")
                  ? "#A885D7"
                  : "white",
                fontWeight: screen.includes("/superadmin/lang")
                  ? "bold"
                  : "normal",
              }}
            >
              Languages
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default index;
