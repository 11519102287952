import "./styles.scoped.css";
import React, { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../../store/store";
import ModalInviteAttendess from './ModalInviteAttendees';
import { useDispatch, useSelector } from "react-redux";
import { getEventInfo, getEventRSVP, getEventInterested, getCheckEvent, getEventNotInterested, getMyEventsComplete } from "../../store/actions/eventActions";
import { applyTimeOffsetForLocal } from "../../helpers/dateHelper";
import { getClubsImAdv } from "../../store/actions/clubActions";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import moment, { Moment } from "moment";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";
import { TimePicker } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER, VIOLET } from "../ClubIconsNavBar";
import { onlyFirstLetterCapitalized } from "../../helpers/letterHelper";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import MyTimePicker from "../MyTimePicker";

export interface moderator {
  username: string;
  avatar: string;
  fullname: string;
  email: string;
  userType?: string;
  classYear?: number;
}

function Index(props: any) {
  const { t, i18n } = useTranslation();
  const perfectTimeSplit = (): string[] | undefined => {
    if (props.event.start && props.event.timezone) {
      return applyTimeOffsetForLocal(
        props.event.start.split('-'), props.event.timezone.split('#')[2].split(':'))
    }
    return ['0', '0', '0', '0', '0'];
  }

  const { id = '' } = useParams();
  const dateTimeSplit: string[] | undefined = perfectTimeSplit();

  const [loadingResponse, setLoadingResponse] = useState(false);
  const [isClubAdvisor, setIsClubAdvisor] = useState(false);
  const [editEvent, setEditEvent] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userRoles = useSelector((state: any) => state.getUser.userRoles)
  const user = useSelector((state: any) => state.getUser.userInfo);
  const clubsOrg = useSelector((state: any) => state.getClub.getClubsOrg || []);

  const [searchParams, setSearchParams] = useSearchParams();
  const event = useSelector((state: any) => state.getEvent.event || {});

  //const [eventType, setEventType] = useState(event.type);
  const [description, setDescription] = useState(event.description);
  const [maxAttendees, setMaxAttendees] = useState(event.max_event_capacity);
  const [meetingPlace, setMeetingPlace] = useState(event.meeting_place);
  const [eventName, setEventName] = useState(event.name);
  const [eventStart, setEventStart] = useState(event.start);
  const [eventEnd, setEventEnd] = useState(event.end);
  const [moderatorsAdded, setModeratorsAdded] = useState<any>([]);
  const [moderatorsDeleted, setModeratorsDeleted] = useState<any>([]);
  const [addAdvisor, setAddAdvisor] = useState(false);
  const [loadingAdvisor, setLoadingAdvisor] = useState(false);
  const [modUsername, setModUsername] = useState('');

  const [eventUsersRelatedCount, setEventUsersRelatedCount] = useState<{
    RSVP: number | undefined, I: number | undefined, NI: number | undefined
  }>({
    RSVP: undefined,
    I: undefined,
    NI: undefined
  })

  //const [eventTypeSelection, setEventTypeSelection] = useState<'school' | 'club' | 'team'>('school')
  //const eventTypes = useSelector((state: RootState) => state.getAha.GetAhaEvents)

  // const ClubTypes: { [k: string]: string | undefined } = {
  //   Competition: "Comp-10-2020",
  //   "Field Trip": "Field-Trip-10-2020",
  //   Fundraiser: "Fundraiser-10-2020",
  //   "Meeting (Club)": "Meeting-10-2020",
  //   "Practice/Rehearsal": "Practice-10-2020",
  //   Retreat: "Retreat-10-2020",
  //   "Study Hall": "Study-Hall-10-2020",
  //   Tournament: "Tournament-10-2020",
  //   "Tryout (Club)": "Tryout-10-2020",
  //   "Volunteering/Community Service": "Volunteer-10-2020",
  //   Performance: "Performance-10-2020",
  //   Other: "Other-10-2020",
  // };

  // const TeamTypes: { [k: string]: string | undefined } = {
  //   Fundraiser: "Fundraiser-10-2020",
  //   Game: "Comp-10-2020",
  //   "Meeting (Team)": "Meeting-10-2020",
  //   Practice: "Practice-10-2020",
  //   "Skills camp/Retreat": "Retreat-10-2020",
  //   "Study Hall": "Study-Hall-10-2020",
  //   Tournament: "Tournament-10-2020",
  //   "Tryout (Team)": "Tryout-10-2020",
  //   "Volunteering/Community Service": "Volunteer-10-2020",
  //   Other: "Other-10-2020",
  // };

  // const SchoolTypes: { [k: string]: string | undefined } = {
  //   "Conference/Retreat": "Retreat-10-2020",
  //   Dance: "Dance-10-2020",
  //   "Field Trip": "Field-Trip-10-2020",
  //   Fundraiser: "Fundraiser-10-2020",
  //   "General Assembly": "General-Assembly-10-2020",
  //   "Open House": "Open-House-10-2020",
  //   Orientation: "Orientation-10-2020",
  //   "Parent/Teacher Meeting": "Parent-Teacher-10-2020",
  //   "Pep Rally": "Pep-Rally-10-2020",
  //   "Study Hall": "Study-Hall-10-2020",
  //   "Training/Workshop": "Workshop-10-2020",
  //   "Volunteering/Community Service": "Volunteer-10-2020",
  //   Other: "Other-10-2020",
  // };

  // const [eventTypes, setEventTypes] = useState<{
  //   [k: string]: string | undefined;
  // }>(SchoolTypes);

  const timezones = useSelector(
    (state: RootState) =>
      state.getLocation.timezones || [
        {
          continent: "America",
          city: "Bogota",
          country: "CO",
          DST: "-05:00",
          STD: "-05:00",
        },
      ]
  );

  const getCurrentTimezone = (): string => {
    const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const continent = (zone || '').split("/")[0];
    const city = (zone || '').split("/")[1];
    const tz = timezones.find(
      (item: any) => item.continent === continent && item.city === city
    );
    const timezone_string = `${tz.continent}#${tz.city}#${tz.STD}`;
    setTimezone(timezone_string !== "" ? timezone_string : "America#Bogota#-05:00");
    if (tz) return timezone_string;
    else return "America#Bogota#-05:00";
  };

  const [timezone, setTimezone] = useState(
    event.timezone !== "" ? event.timezone : getCurrentTimezone()
  );

  const addModerator = (email: string) => {
    if (modUsername === '') return;

    setLoadingAdvisor(true);

    const mod = moderatorsAdded;
    mod.push(email);

    Backend(
      MicroServices.Events,
      `/event/${id || ''}/moderators`, {
      method: "POST",
      body: JSON.stringify({
        email: email
      })
    })
      .then((response) => {
        if (response.status === 201) {
          props.getEventModerators();
          setModeratorsAdded(mod);
          setLoadingAdvisor(false);
          setModUsername('');
        }
        else {
          toast.error(t('toast_errorAddingModerators'));
        }
      })
      .catch(console.error)
      .finally(() => {
        setLoadingAdvisor(false);
        setAddAdvisor(false);
      });
  };

  const deleteModerator = (username: string) => {
    const mod = moderatorsDeleted;
    mod.push(username);

    Backend(
      MicroServices.Events,
      `/event/${id || ''}/moderators/${username || ''}`, {
      method: "DELETE"
    })
      .then((response) => {
        if (response.status === 200) {
          props.setModerators(props.moderators.filter((mod: moderator) => mod.username !== username))
          setModeratorsDeleted(mod);
        }
        else {
          toast.error(t('toast_errorDeletingModerators'))
        }
      })
      .catch(console.error);
  };

  const rollbackModerators = () => {
    /*const deleted = moderatorsDeleted;
    const added = moderatorsAdded;

    if(deleted.length > 0){
      deleted.forEach((username: string) => addModeratorRollBack(username));
    }

    if(added.length > 0){
      added.forEach((email: string) => deleteModeratorRollBack(username));
    }*/
  }

  useEffect(() => {
    const editTrue = searchParams.get('editTrue');

    if (editTrue) {
      setEditEvent(true);
    }

    isAdvisor();

    // if (props.event.club_id === "0") {
    //   setEventTypeSelection('school');
    // }
    // else {
    //   for (let club of clubsOrg) {
    //     if (club.id === props.event.club_id) {

    //       if (club.club_type === 'TEAM') {
    //         setEventTypeSelection('team');
    //       }
    //       else {
    //         setEventTypeSelection('club');
    //       }

    //       break;
    //     }
    //   }
    // }

  }, [props]);

  useEffect(() => {
    if (id === '') {
      return;
    }
    props.getEventModerators();
    dispatch<any>(getEventInfo(id))
      .then(console.log)
      .catch(console.log)
  }, [editEvent]);

  useEffect(() => {
    const editTrue = searchParams.get('editTrue');
    if (props?.event?.event_id && !editTrue) {
      setEditEvent(false);
    }
  }, [props?.event?.event_id])


  useEffect(() => {
    reloadEventResponse();
    if (props.isClubEditInfo) {
      setEditEvent(true);
    }
    const editTrue = searchParams.get('editTrue');
    if (editTrue) {
      setEditEvent(true);
    }
    isAdvisor();
  }, []);

  useEffect(() => {
    Backend(
      MicroServices.Events,
      `/event/${id}/related-users`)
      .then((response) => response.json())
      .then((results) => {
        setEventUsersRelatedCount({
          RSVP: results.RSVP ? results.RSVP.length : 0,
          I: results.I ? results.I.length : 0,
          NI: results.NI ? results.NI.length : 0,
        })
      })
      .catch((e) => {
        setEventUsersRelatedCount({
          RSVP: undefined,
          I: undefined,
          NI: undefined
        })
        console.log(e)
      })
  }, [id])

  const isAdvisor = () => {
    if (userRoles.includes("ZAA#ORGADMIN")) {
      setIsClubAdvisor(true);
      return;
    }

    if (props.event.club_id) {
      dispatch<any>(getClubsImAdv(user.username))
        .then((clubs: any) => {
          for (let club of clubs) {
            if (club.id === props.event.club_id) {
              setIsClubAdvisor(true);
              break;
            }
          }
          return;
        });
    }

    setIsClubAdvisor(false);
    return;
  }

  function buttonRSVP(id: number) {
    setLoadingResponse(true);
    dispatch<any>(getEventRSVP(props.event.timestamp, user.username))
      .then((results: any) => {
        reloadEventResponse();
      })
      .catch(console.log)
  }

  function buttonInterested(id: number) {
    setLoadingResponse(true);
    dispatch<any>(getEventInterested(props.event.timestamp, user.username))
      .then((results: any) => {
        reloadEventResponse();
      })
      .catch(console.log)
  }

  function buttonNotInterested(id: number) {
    setLoadingResponse(true);
    dispatch<any>(getEventNotInterested(props.event.timestamp, user.username))
      .then((results: any) => {
        reloadEventResponse();
      })
      .catch(console.log)
  }

  const cancelEditEvent = () => {
    //setEventType(props.event.type);
    setDescription(props.event.description);
    setMaxAttendees(props.event.max_event_capacity);
    setEventStart(props.event.start);
    setEventEnd(props.event.end);
    setEventName(props.event.name);
    setMeetingPlace(props.event.meeting_place);
    setTimezone(props.event.timezone);
    setEditEvent(false);
    setEndPicked(true);
    setStartPicked(true);
    rollbackModerators();
    setModUsername('');
    setAddAdvisor(false);

    const editTrue = searchParams.get('editTrue');
    if (editTrue) {
      navigate('../../app/events/' + props.event.timestamp)
    }

  }

  const handleditEvent = () => {
    const form = {
      ...event,
      name: eventName,
      meeting_place: meetingPlace,
      timezone: timezone,
      max_event_capacity: parseInt(maxAttendees === "" ? '0' : maxAttendees),
      description: description,
      //type: eventType,
      start: eventStart,
      end: eventEnd,
    };

    try {
      delete form.timestamp;
    } catch (error) {
      console.log("form doesn't have timestamp")
    }

    if (!Array.isArray(form.reach)) {
      const newReach: string[] = [];
      newReach.push(form.reach);
      form.reach = newReach;
    }

    console.log('Info Event to Edit:', form)

    let edit = true;

    for (let property in form) {
      if (form[property] === '' && property !== 'club_type') {
        toast.error(`${t('toast_pleaseAdd')} ${property}`);
        edit = false;
        break;
      }
    }

    if (edit) {
      saveEditEvent(form);
    }
  }

  const saveEditEvent = (Form: any) => {
    Backend(
      MicroServices.Events,
      `/event/${id || ''} `, {
      method: 'PUT',
      body: JSON.stringify(Form),
    })
      .then((response) => {
        if (response.status === 200) {
          setEditEvent(false);
          setModeratorsAdded([]);
          setModeratorsDeleted([]);

          dispatch<any>(getMyEventsComplete(user.username, true))
            .then((envt: any) => {
              toast.success(t('toast_eventUpdated'));
            })
            .catch((error: any) => console.error(error, 'Events error'))
        }
        else {
          toast.error(t('toast_errorUpdatingEvent'));
        }
      })
      .catch(console.error);
  }

  const eventCheck = useSelector((state: any) => state.getEvent.checkEvent || {});

  const reloadEventResponse = () => {
    dispatch<any>(getCheckEvent(props.event.timestamp, user.username))
      .then(console.log)
      .catch(console.log)
      .finally(() => setLoadingResponse(false))
  };

  const updateUserRelationCount = (type: 'RSVP' | 'I' | 'NI') => {
    if (eventCheck.rsvp) {
      if (type === 'RSVP') return
      setEventUsersRelatedCount({
        ...eventUsersRelatedCount,
        [type]: (eventUsersRelatedCount[type] || 0) + 1,
        RSVP: (eventUsersRelatedCount.RSVP || 1) - 1
      })
    } else if (eventCheck.interested) {
      if (type === 'I') return
      setEventUsersRelatedCount({
        ...eventUsersRelatedCount,
        [type]: (eventUsersRelatedCount[type] || 0) + 1,
        I: (eventUsersRelatedCount.I || 1) - 1
      })
    } else if (eventCheck.notInterested) {
      if (type === 'NI') return
      setEventUsersRelatedCount({
        ...eventUsersRelatedCount,
        [type]: (eventUsersRelatedCount[type] || 0) + 1,
        NI: (eventUsersRelatedCount.NI || 1) - 1
      })
    } else {
      setEventUsersRelatedCount({
        ...eventUsersRelatedCount,
        [type]: (eventUsersRelatedCount[type] || 0) + 1,
      })
    }
  }

  //Time Helper
  //event.start/end = "AAAA-MM-DD-HH-MM"
  const getMomentTime = (mom: Moment): string => {
    if (mom === null) return ""
    let year: number | string = mom.year();
    let month: number | string = mom.month() + 1;
    let day: number | string = mom.toDate().getDate();
    let hours: number | string = mom.hours();
    let minutes: number | string = mom.minutes();
    month < 10 ? (month = `0${month}`) : (month = `${month}`);
    day < 10 ? (day = `0${day}`) : (day = `${day}`);
    hours < 10 ? (hours = `0${hours}`) : (hours = `${hours}`);
    minutes < 10 ? (minutes = `0${minutes}`) : (minutes = `${minutes}`);
    return `${year}-${month}-${day}-${hours}-${minutes}`;
  };

  const getTimeMoment = (time: string): Moment => {
    //console.log('getTimeMoment', time)
    const time_parts = (time || '').split("-");
    return moment(
      `${time_parts[0]}-${time_parts[1]}-${time_parts[2]} ${time_parts[3]}:${time_parts[4]
      }`
    );
  };

  const getMomentDate = (mom: Moment | undefined): Date => {
    return mom?.isValid() ? mom.toDate() : new Date();
  };

  const editMoment = (mom: Moment, date: Date): Moment => {
    let year: number | string = date.getFullYear();
    let month: number | string = date.getMonth() + 1;
    let day: number | string = date.getDate();
    let hours: number | string = mom.hours();
    let minutes: number | string = mom.minutes();
    month < 10 ? (month = `0${month}`) : (month = `${month}`);
    day < 10 ? (day = `0${day}`) : (day = `${day}`);
    hours < 10 ? (hours = `0${hours}`) : (hours = `${hours}`);
    minutes < 10 ? (minutes = `0${minutes}`) : (minutes = `${minutes}`);
    return moment(`${year}-${month}-${day} ${hours}:${minutes}`);
  };

  const editMomentClock = (mom1: Moment, mom2: Moment): Moment => {
    if (mom1 === null || mom2 === null) return moment()
    let year: number | string = mom1.year();
    let month: number | string = mom1.month() + 1;
    let day: number | string = mom1.toDate().getDate();
    let hours: number | string = mom2.hours();
    let minutes: number | string = mom2.minutes();
    month < 10 ? (month = `0${month}`) : (month = `${month}`);
    day < 10 ? (day = `0${day}`) : (day = `${day}`);
    hours < 10 ? (hours = `0${hours}`) : (hours = `${hours}`);
    minutes < 10 ? (minutes = `0${minutes}`) : (minutes = `${minutes}`);
    return moment(`${year}-${month}-${day} ${hours}:${minutes}`);
  };

  //States
  const [startPicked, setStartPicked] = useState<boolean>(event.start !== "")
  const [endPicked, setEndPicked] = useState<boolean>(event.end !== "")
  const [start, setStart] = useState<Moment>(
    event.start !== "" ? getTimeMoment(event.start) : moment()
  );
  const [end, setEnd] = useState<Moment>(
    event.end !== "" ? getTimeMoment(event.end) : moment()
  );

  useEffect(() => {
    if (event.start !== "") {
      setStart(getTimeMoment(event.start));
    }
    else {
      setStart(moment())
    }

    if (event.end !== "") {
      setEnd(getTimeMoment(event.end));
    }
    else {
      setEnd(moment());
    }
  }, [event])

  //onChange handlers
  function handleOnStartDateChange(e: any) {
    const new_moment = editMoment(start, e);
    setStart(new_moment);
    setEventStart(getMomentTime(new_moment));
  }
  function handleOnEndDateChange(e: any) {
    const new_moment = editMoment(end, e);
    setEnd(new_moment);
    setEventEnd(getMomentTime(new_moment));
  }
  function handleOnStartTimeChange(e: any) {
    setStartPicked(e !== null);
    const new_moment = editMomentClock(start, e);
    if (new_moment < moment()) {
      toast.error(t('toast_invalidStartTime'))
      return;
    }

    handleOnEndTimeChange(editMomentClock(start, e).add(1, 'hours'))

    setStart(new_moment);
    setEventStart(getMomentTime(e !== null ? new_moment : e));
    setEventEnd(getMomentTime(e !== null ? editMomentClock(start, e).add(1, 'hours') : e));
  }
  function handleOnEndTimeChange(e: any) {
    setEndPicked(e !== null);
    let new_moment = editMomentClock(end, e);

    if (new_moment < moment() || new_moment < start) {
      toast.error(t('toast_invalidEndTime'))
      new_moment = start.add(1, 'hours')
      setEnd(new_moment);
      setEventEnd(getMomentTime(e !== null ? new_moment : e));
      return;
    }

    setEnd(new_moment);
    setEventEnd(getMomentTime(e !== null ? new_moment : e));
  }

  useEffect(() => {
    reloadEventResponse();
  }, [props.event.timestamp]);

  const [showModal, setShowModal] = useState(false);

  function handleCloseModal() {
    setShowModal(false)
  };

  const showAddAdvisor = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAddAdvisor(true);
  }

  const hideAddAdvisor = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAddAdvisor(false);
  }

  useEffect(() => {
    //setEventType(event.type)
    setTimezone(event.timezone !== "" ? event.timezone : getCurrentTimezone())
  }, [event])

  return (
    <div className="club-view-information">
      {(!editEvent) &&
        (<div>
          <div className="information-title-row">
            <h1>{t('event__timeAndLocation')}</h1>
            {(isClubAdvisor) &&
              (
                <div className="GridEventInfo">
                  <div className="edit-info-buttonGrid1" onClick={() => setEditEvent(true)}>
                    <FontAwesomeIcon
                      icon="pen"
                      size='lg'
                      color={WHITE}
                      className=" "
                    />
                  </div>
                  <button className="edit-info-buttonGrid2" onClick={() => setShowModal(true)}>
                    {t('event__inviteAttendees')}
                  </button>
                </div>
              )}
          </div>
          <div className="infoLocation">
            <div className="infoDate">
              <FontAwesomeIcon
                icon="calendar"
                size='lg'
                color={WHITE}
                className="  iconInfoLocation"
              />
              {`${(dateTimeSplit) ? dateTimeSplit[0] : '0'}-${(dateTimeSplit) ? dateTimeSplit[1] : '0'}-${(dateTimeSplit) ? dateTimeSplit[2] : '0'}`}
            </div>
            <div className="infoTime">
              <FontAwesomeIcon
                icon="clock"
                size='lg'
                color={WHITE}
                className="  iconInfoLocation"
              />
              {`${parseInt((dateTimeSplit) ? dateTimeSplit[3] : '0') > 12 ? parseInt((dateTimeSplit) ? dateTimeSplit[3] : '0') - 12 : (parseInt((dateTimeSplit) ? dateTimeSplit[3] : '0') === 0) ? 12 : ((dateTimeSplit) ? dateTimeSplit[3] : '0')}:${(dateTimeSplit) ? dateTimeSplit[4] : '0'} ${parseInt((dateTimeSplit) ? dateTimeSplit[3] : '0') > 12 ? 'PM' : 'AM'}`}
            </div>
            <div className="infoAddress">
              <FontAwesomeIcon
                icon="map-marker-alt"
                size='lg'
                color={WHITE}
                className="  iconInfoLocation"
              />
              {`${props.event.meeting_place}`}
            </div>
          </div>
          <div className="information-title-row">
            <h1>{t('event__description')}</h1>
          </div>
          <p className="robotocondensed-normal-white-16px" dangerouslySetInnerHTML={{ __html: props.event.description }}>
          </p>
          <div className="buttonParticipanting">
            <button className={`btnGoing ${eventCheck.rsvp && 'activeButton'} ${loadingResponse && 'desactiveButton'}`} onClick={() => {
              updateUserRelationCount('RSVP')
              eventCheck.rsvp ? {} : buttonRSVP(props.event.timestamp)
            }}>
              <FontAwesomeIcon
                icon="star"
                size='lg'
                color={eventCheck.rsvp ? LAVENDER : WHITE}
                className=" "
              />
              {`${eventUsersRelatedCount.RSVP !== undefined ? eventUsersRelatedCount.RSVP : ''} ${t('event__going')}`}
            </button>
            <button className={`btnInterested ${eventCheck.interested && 'activeButton'} ${loadingResponse && 'desactiveButton'}`} onClick={() => {
              updateUserRelationCount('I')
              eventCheck.interested ? {} : buttonInterested(props.event.timestamp)
            }}>
              <FontAwesomeIcon
                icon="head-side"
                size='lg'
                color={eventCheck.interested ? LAVENDER : WHITE}
                className=" "
              />
              {`${eventUsersRelatedCount.I !== undefined ? eventUsersRelatedCount.I : ''} ${t('event__interested')}`}
            </button>
            <button className={`btnInterested ${eventCheck.notInterested && 'activeButton'} ${loadingResponse && 'desactiveButton'}`} onClick={() => {
              updateUserRelationCount('NI')
              eventCheck.notInterested ? {} : buttonNotInterested(props.event.timestamp)
            }}>
              <FontAwesomeIcon
                icon="times-circle"
                size='lg'
                color={eventCheck.notInterested ? LAVENDER : WHITE}
                className=" "
              />
              {`${eventUsersRelatedCount.NI !== undefined ? eventUsersRelatedCount.NI : ''} ${t('event__notInterested')}`}
            </button>
          </div>

          <div className="moderators-row">
            <div className="information-title-row">
              <h1>{t('event__moderators')}</h1>
            </div>
            <div className="moderatorsCenter">
              {props.moderators.map((officer: moderator) => (
                <div className="moder-row">
                  <img
                    className="officer-avatar"
                    src={officer.avatar || `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png`}
                    alt=""
                  />
                  <div className="officer-info">
                    <div className="officer-name robotocondensed-normal-lavender-16px">
                      {officer.fullname}
                    </div>
                    <div className="officer-role robotocondensed-normal-white-16px">
                      {officer.userType === 'STUDENT' && officer.classYear ? `Class of ${officer.classYear}` : officer.userType ? onlyFirstLetterCapitalized(officer.userType) : ''}
                    </div>
                  </div>

                </div>
              ))}
            </div>
          </div>

        </div>)}

      {(isClubAdvisor && editEvent) && (
        <div className="infoevent">
          <div className="row">
            <div className="col">
              <label htmlFor="" className="titleInput">{t('event__eventName')}</label>
              <div className="inputEvent">
                <input type="text" defaultValue={event.name} onChange={(e: any) => setEventName(e.target.value)} />
              </div>
            </div>



            <div className="col">
              <label htmlFor="" className="titleInput">{t('event__location')}</label>
              <div className="inputEvent">
                <input type="text" defaultValue={event.meeting_place} onChange={(e: any) => setMeetingPlace(e.target.value)} />
                <FontAwesomeIcon
                  icon="map-marker-alt"
                  size='lg'
                  color={LAVENDER}
                  className="map-marker-alt inputIcon  "
                />
              </div>
            </div>
            <div className="col">
              <label htmlFor="" className="titleInput">{t('event__attendees')}</label>
              <div className="inputEvent">
                <input type="number" defaultValue={event.max_event_capacity} onChange={(e: any) => setMaxAttendees(e.target.value)} />
                <FontAwesomeIcon
                  icon="user-group"
                  size='lg'
                  color={LAVENDER}
                  className="user-group inputIcon  "
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="" className="titleInput">{t('event__eventsInformation')}</label>
              <div className="inputEvent textareaEvent">
                <JoditEditor
                  value={event.description}
                  onBlur={(e: any) => setDescription(e)}
                  config={{
                    buttons: ["bold", "italic", "underline"],
                    readonly: false,
                    toolbarAdaptive: false,
                    showPlaceholder: false,
                    minHeight: 100,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="" className="titleInput">{t('event__from')}</label>
              <div className="inputEvent">
                <DatePicker
                  selected={getMomentDate(start)}
                  onChange={handleOnStartDateChange}
                  minDate={new Date()}
                  showDisabledMonthNavigation
                />
                <FontAwesomeIcon
                  icon="calendar-alt"
                  size='lg'
                  color={WHITE}
                  className="calendar-alt inputIcon  "

                />
              </div>
            </div>
            <div className="col">
              <label htmlFor="" className="titleInput">{t('event__starttime')}</label>
              <div className="inputEvent">
                <MyTimePicker
                  handleClick={handleOnStartTimeChange as any}
                  value={startPicked ? start : undefined}
                  className={'changeInfoDate'}
                />
                {/* <TimePicker
                  onChange={handleOnStartTimeChange}
                  format={"h:mm a"}
                  minuteStep={15}
                  placeholder={""}
                  use12Hours={true}
                  value={startPicked ? start : undefined}
                  showNow={false}
                /> */}
                <FontAwesomeIcon
                  icon="clock"
                  size='xl'
                  color={WHITE}
                  className="iconClock  "

                />
              </div>
            </div>
            <div className="col">
              <label htmlFor="" className="titleInput">{t('event__to')}</label>
              <div className="inputEvent">
                <DatePicker
                  selected={getMomentDate(end)}
                  onChange={handleOnEndDateChange}
                  minDate={new Date()}
                  showDisabledMonthNavigation
                />
                <FontAwesomeIcon
                  icon="calendar-alt"
                  size='lg'
                  color={WHITE}
                  className="calendar-alt inputIcon  "

                />
              </div>
            </div>
            <div className="col">
              <label htmlFor="" className="titleInput">{t('event__endtime')}</label>
              <div className="inputEvent">
                <MyTimePicker
                  handleClick={handleOnEndTimeChange as any}
                  value={endPicked ? end : undefined}
                  className={'changeInfoDate'}
                />
                {/* <TimePicker
                  onChange={handleOnEndTimeChange}
                  format={"h:mm a"}
                  minuteStep={15}
                  placeholder={""}
                  use12Hours={true}
                  value={endPicked ? end : undefined}
                  showNow={false}
                /> */}
                <FontAwesomeIcon
                  icon="clock"
                  size='xl'
                  color={WHITE}
                  className="iconClock  "

                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label htmlFor="" className="titleInput">{t('event__activityType')}</label>
              <div className="inputEvent inputEventType">
                {/* <select
                  name=""
                  id=""
                //onChange={(e: any) => setEventType(e.target.value)}
                //value={eventType}
                > */}
                  {/* {<option value={event.type}>{event.type}</option>} EVENT TYPE CAN'T BE CHANGED BECAUSE OF AHA */}
                  {event.type}
                  {/* {eventTypes[eventTypeSelection].map((type) => (
                    <option value={type.label}>{type.label}</option>
                  ))} */}
                {/* </select> */}
              </div>
            </div>

            <div className="col">
              <label htmlFor="" className="titleInput">{t('event__timezone')}</label>
              <div className="inputEvent">
                <select
                  onChange={(e: any) => setTimezone(e.target.value)}
                  name=""
                  id=""
                  value={timezone}
                >
                  <>
                    {timezones.map((timez: any) => (
                      <>
                        <option
                          value={`${timez.continent}#${timez.city}#${timez.STD}`}
                        >{`(GTM${timez.STD}) ${timez.city} - ${timez.country}`}</option>
                        {(timez.DST !== timez.STD) && (<option
                          value={`${timez.continent}#${timez.city}#${timez.DST}`}
                        >{`(GTM${timez.DST}) Daylight Time ${timez.city} - ${timez.country}`}</option>)}
                      </>
                    ))}
                  </>
                </select>
              </div>
            </div>
          </div>

          <div className="row-edit">
            <div className="information-title-row-edit">
              <h1>{t('event__moderators')}</h1>
              <button className="buttonAdd" onClick={showAddAdvisor}>{t('event__addModerator')}</button>
            </div>
            <div className="inputInfo">
              {addAdvisor && (
                <div className="officer" >
                  <div className='emailOfficerAdd'>
                    Email
                    <div className='inputContainerOfficer'>
                      <input type="text" value={modUsername} onChange={(e) => setModUsername(e.target.value)} />
                    </div>
                  </div>
                  <div className='actionsOfficer'>
                    <button className="buttonTrashOfficer" onClick={hideAddAdvisor}>
                      <FontAwesomeIcon
                        icon="times"
                        size='sm'
                        color={VIOLET}
                        className="trash  "
                      />
                    </button>
                    <button className="buttonPenOfficer" onClick={() => addModerator(modUsername)} disabled={loadingAdvisor}>
                      {loadingAdvisor ? (
                        <div className="icon-spinner-third buttonSendSignUp" />
                      ) : (
                        <FontAwesomeIcon
                          icon="check"
                          size='sm'
                          color={WHITE}
                          className="trash  "
                        />
                      )}
                    </button>
                  </div>
                </div>
              )}
              {props.moderators.map((member: moderator) => (
                <div className="officer" key={member.email}>
                  <div className='profileOfficer'>
                    <p className='nameOfficer'>{member.fullname}</p>
                  </div>
                  <div className='emailOfficer'>
                    Email
                    <div className='inputContainerOfficer'>
                      {member.email}
                    </div>
                  </div>
                  <div className='actionsOfficer'>
                    <button className="buttonTrashOfficer" onClick={() => deleteModerator(member.username)}>
                      <FontAwesomeIcon
                        icon="trash"
                        size='sm'
                        color={VIOLET}
                        className="trash  "
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="row buttonsEvent">
            <button className="buttonCancel" onClick={cancelEditEvent}>{t('button__cancel')}</button>
            <button className="buttonSave" onClick={handleditEvent}>{t('button__save')}</button>
          </div>
        </div>
      )}
      <ModalInviteAttendess
        show={showModal}
        onHide={handleCloseModal}
      />
    </div>
  );
}

export default Index;
