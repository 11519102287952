import "../../styles.scoped.css";
import '../../styles.css';
import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import ModalDiscardChanges from "./ModalDiscardChanges";
import { getOrganizationType } from "../../../../store/actions/orgsActions";
import { getTemplateCategories } from "../../../../store/actions/templateCategoryActions";

toast.configure()



function ModalAddCommunity(props: any) {

  const { show, setShow, onCommunityTypeButtonClick, ownCommunityTypes } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch<any>(getOrganizationType());
  }, []);

  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  );

  const [showDiscard, setShowDiscard] = useState(false);

  const onCancel = (type: string) => {
    if (type === 'cancel') {
      setShowDiscard(false);
    }
    else {

      setShowDiscard(false);
      setShow(false);
    }
  }

  const onhideModal = () => {
    setShowDiscard(true);
  }

  useEffect(() => {
    dispatch<any>(getTemplateCategories())
  }, []);

  return (
    <>

      <Modal className="modalInviteMemebers" show={show} onHide={onhideModal}>
        <Modal.Header>
          <Modal.Title className="modalAddClub-shorter">{`Add ${organization.name || 'your Youth Organization'} Communities. Select which type`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-schoolAdd">

            <div className="schoolAdd-row schoolAdd-column">
              <div className="select-container-status">
                {ownCommunityTypes.map((memberValue: any) => (
                  <div className="option-status">
                    <Button onClick={onCommunityTypeButtonClick} value={memberValue} name="btnSaveAdvisor" className="buttonSend btnSaveCommunity">
                      {memberValue}
                    </Button>
                  </div>
                ))}
              </div>
            </div>

          </div>
        </Modal.Body>
      </Modal>

      <ModalDiscardChanges show={showDiscard} onHide={onCancel} />
    </>
  );
}

export default ModalAddCommunity;
