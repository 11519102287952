import "./styles.scoped.css";
import Complete from "../../components/SetupYouth/Complete";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationInfo, getSchoolsYouth } from "../../store/actions/orgsActions";
import OrganizationComplete from "../../interfaces/organization/OrganizationComplete";
import { Team } from "../../components/SetupSetup/ModalTeam";
import MyYouth from "../../components/SetupYouth/MyYouth/MyYouth";
import SchoolsYouth from "../../components/SetupYouth/SchoolsYouth/SchoolsYouth";
import CommunitiesYouth from "../../components/SetupYouth/CommunitiesYouth/CommunitiesYouth";
import ClubsYouth from "../../components/SetupYouth/ClubsYouth/ClubsYouth";
import CommunitiesOwnYouth from "../../components/SetupYouth/CommunitiesOwnYouth/CommunitiesOwnYouth";
import { getTCommsByCountry } from "../../store/actions/TClubsActions";

export interface SelectedTeams {
  [key: string]: Team[];
}

export interface Principal {
  email: string;
  name: string;
  lastname: string;
}

function SetupYouth({ setShowSetupYouth }: { setShowSetupYouth: any }) {
  const [stepActive, setStepActive] = useState(1);

  //Step 1
  const [organization, setOrganization] = useState<OrganizationComplete | undefined>(undefined);

  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    reloadOrgInformation();
  }, [userInfo]);

  const reloadOrgInformation = () => {
    const orgId = JSON.parse(userInfo.attributes["custom:organizations"])[0];

    dispatch<any>(getOrganizationInfo(orgId))
      .then((orgResults: any) => {
        setOrganization(orgResults);
        dispatch(getSchoolsYouth(orgId));
      })
      .catch(console.log);
  };

  function handleOnClick(e: any, step: number) {
    setStepActive(step);
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    if (organization) {
      dispatch(getSchoolsYouth(organization.id));
    }
  }, []);

  useEffect(() => {
    if (organization) {
      dispatch(getSchoolsYouth(organization.id));
      dispatch(getTCommsByCountry(organization.countryCode || 'USA'));
    }
  }, [organization]);

  return (
    <div className="container-setup-school">
      <div className="navegatorStep">
        <div className="steps">
          <div className="allSteps">

            <div className="step">
              <div
                className={`${stepActive === 1 ? "active" : ""} ${stepActive > 1 ? "activeStep" : ""
                  } stepIcon step-1`}
                onClick={(e) => handleOnClick(e, 1)}
              >
                <div className="circle-setup" />
                <span className="stepTitle">My Organization</span>
              </div>
            </div>
            <div className="step">
              <div
                className={`${stepActive === 2 ? "active" : ""} ${stepActive > 2 ? "activeStep" : ""
                  } stepIcon step-5`}
                onClick={(e) => handleOnClick(e, 2)}
              >
                <div className="circle-setup" />
                <span className="stepTitle">Your Communities</span>
              </div>
            </div>
            <div className="step">
              <div
                className={`${stepActive === 3 ? "active" : ""} ${stepActive > 3 ? "activeStep" : ""
                  } stepIcon step-2`}
                onClick={(e) => handleOnClick(e, 3)}
              >
                <div className="circle-setup" />
                <span className="stepTitle">Clubs</span>
              </div>
            </div>
            <div className="step">
              <div
                className={`${stepActive === 4 ? "active" : ""} ${stepActive > 4 ? "activeStep" : ""
                  } stepIcon step-3`}
                onClick={(e) => handleOnClick(e, 4)}
              >
                <div className="circle-setup" />
                <span className="stepTitle">Schools</span>
              </div>
            </div>
            <div className="step">
              <div
                className={`${stepActive === 5 ? "active" : ""} ${stepActive > 5 ? "activeStep" : ""
                  } stepIcon step-4`}
                onClick={(e) => handleOnClick(e, 5)}
              >
                <div className="circle-setup" />
                <span className="stepTitle">School Communities</span>
              </div>
            </div>
          </div>
          <div className="step">
            <div
              className={`${stepActive === 6 ? "active" : ""} stepIcon step-6`}
              onClick={(e) => handleOnClick(e, 6)}
            >
              <div className="circle-setup" />
              <span className="stepTitle">Complete</span>
            </div>
          </div>
        </div>
      </div>
      {stepActive == 0 ? (
        ''
      ) : stepActive == 1 ? (
        <MyYouth
          handleOnClick={handleOnClick}
          organization={organization}
          reloadOrgInformation={reloadOrgInformation}
        />
      ) : stepActive == 2 ? (
        <CommunitiesOwnYouth
          handleOnClick={handleOnClick}
        />
      ) : stepActive == 3 ? (
        <ClubsYouth
          handleOnClick={handleOnClick}
        />
      ) : stepActive == 4 ? (
        <SchoolsYouth
          handleOnClick={handleOnClick}
        />
      ) : stepActive == 5 ? (
        <CommunitiesYouth
          handleOnClick={handleOnClick}
        />
      ) : (
        <Complete organization={organization} setShowSetupYouth={setShowSetupYouth} />
      )}
    </div>
  );
}

export default SetupYouth;
