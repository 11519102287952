import React from 'react'
import './styles.scoped.css'

const ImageProfile = () => {
    return (
        <div className='photoProfile'>
            <div className='imgProfile'>
                <img src="https://roster-v1-roster-images-development.s3.amazonaws.com/profile_photo/1657049405237/profilecms_23st24.png" alt="" />
            </div>
            <div className="infoProfile">
                <h2>Anna Ortiz</h2>
                <span>City Organization</span>
            </div>
        </div>
    )
}

export default ImageProfile
