import "./styles.scoped.css";
import './styles.css';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationInfo } from "../../store/actions/orgsActions";
import { WHITE } from "../ClubIconsNavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function Index(props: any) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schools = useSelector((state: RootState) => state.getOrgs.schoolsYouth);
  
  const [loading, setLoading] = useState<boolean>(true);

  const checkCommunitySetup = (club_id: string, name:string, pending = "true"): Promise<boolean> =>
  new Promise((resolve, reject) => {
    Backend(
      MicroServices.Invite,
      `/invites?pending=${pending}&club_id=${club_id}`
    )
      .then((response) => response.json())
      .then((results) => {
        if (results.find((invite: any) => invite.role.includes('ADVISOR')) && name !== 'Staff') {
          resolve(true);
          return;
        }
        else if (results.find((invite: any) => !invite.role.includes('ADVISOR')) && name === 'Staff') {
          resolve(true);
          return;
        }
        else {
          Backend(
            MicroServices.Club,
            `/clubs/${club_id}`,
            {
              method: 'PUT',
              body: JSON.stringify({
                "is_setup": false
              }),
            }
          )
            .then((response) => {
              if (response.status === 200) {
                resolve(true);
              } else {
                resolve(false);
              }
              return;
            })
            .catch((e) => reject(e))
        }
      })
      .catch((e) => reject(e))
  })

  const getCommunities = (school_id: string, school_status: boolean) => new Promise((resolve, reject) => {
    Backend(
      MicroServices.Club,
      `/clubs?pending=${school_status}&org_id=${school_id}&club_type=COMM`)
      .then((response) => response.json())
      .then(({clubs}) => resolve(clubs))
      .catch((error) => reject(error))
  })
  
  useEffect(() => {
    Promise.all(schools.map((school: any) => getCommunities(school.id, school.status.startsWith('P'))))
      .then((results) => {
        const communities = results.flat();
        return Promise.all(communities.map((community: any) => checkCommunitySetup(community.id, community.name,(community.status === 'PENDING') ? 'true' : 'false')));
      })
      .then((results: boolean[]) => {
        const commOk : boolean = results.reduce((acc, curr) => acc && curr, true)
        if(commOk){
          Backend(
            MicroServices.AccessManager,
            `/organizations/${props.organization.id}/setup-complete`, { method: 'POST' })
            .then((results) => {
              dispatch<any>(getOrganizationInfo(props.organization.id))
                .then(() => {
                  setLoading(false);
                  props.setShowSetupYouth(false);
                  navigate('../app');
                })
            })
        }
        else{
          toast.error(t('toast_errorCheckingSetup'));
          setLoading(false)
        }
      })
      .catch((e) => {
        toast.error(t('toast_errorCheckingSetup'))
        console.log(e)
        setLoading(false)
      })
  }, [])

  return (
    <div className="container-complete">
      <div className="complete-text">
        <h1>{loading ? `${props.organization.name} is ready to join into Zaahah!` : 'Welcome to Zaahah!'}</h1>
        <h2>{loading ? `Loading ${props.organization.type === 'YOUTHORG' ? 'Organization' : 'School'} Bulletin...` : ''}</h2>
        {loading? (
          <FontAwesomeIcon
            className='icon-spinner-third fontawesome5pro-rotate'
            icon="spinner-third"
            size='xl'
            color={WHITE}
          />
        ) : (<></>)}
      </div>
    </div>
  );
}

export default Index;
