import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './styles.css';
import ClubInterface from "../../interfaces/ClubExpanded";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";

interface DeleteClubProps {
    show: boolean;
    club?: ClubInterface;
    setShow: any;
}
function Index(props: DeleteClubProps) {
    const { t, i18n } = useTranslation();
    const [deleting, setDeleting] = useState<boolean>(false)

    function handleOnClickOk() {
        setDeleting(true)
        Backend(
            MicroServices.Club,
            `/clubs/${props.club?.id || '0'}`, {
            method: 'DELETE'
        })
            .then((results) => {
                if (results.status === 204) {
                    toast.success(`${props.club?.name} ${t('toast_suspended')}`)
                    onHide()
                } else {
                    toast.error(`${t('toast_errorSuspending')} ${props.club?.name}. ${t('toast_tryAgain')}`)
                }
                setDeleting(false)
            })
            .catch((e) => {
                toast.error(`${t('toast_errorSuspending')} ${props.club?.name}. ${t('toast_tryAgain')}`)
                setDeleting(false)
            })
    }

    function onHide() {
        props.setShow(false)
    }

    return (
        <>
            <Modal className="modalDelete" show={props.show} onHide={onHide}>
                <Modal.Header>
                    <Modal.Title>{`${t('modalSuspendClub__title')} ${props.club?.name}`}</Modal.Title>
                    <FontAwesomeIcon
  icon="times"
  size='xl'
  color={WHITE}
  className="icon-times  "
  onClick={() => onHide()}
/>
                </Modal.Header>
                <Modal.Body>
                    {/* <FontAwesomeIcon
  icon="exclamation-triangle"
  size='lg'
  color={LAVENDER}
  className="chevron-down iconCheckOk  "
/> */}
                    <span className="labelEventCreated"> {`${t('modalSuspendClub__areYou')} ${props.club?.name}?`}</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="buttonModalDelete bCancel" onClick={() => onHide()}>
                        {t('button__cancel')}
                    </Button>
                    <Button className="buttonModalDelete bDelete" onClick={() => handleOnClickOk()}>
                        {deleting ? <div className="icon-spinner-third buttonSendSpinner" /> : `${t('button__suspendClub')}`}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Index;