import "../styles.scoped.css";
import '../styles.css';
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../../ClubIconsNavBar";
import { Backend, MicroServices } from "../../../helpers/backendHelper";
import { Advisor } from "../../SetupSetup/Communities";
import Select from 'react-select';
import { useTranslation } from "react-i18next";

toast.configure()

const styles = {
  option: (styles: any) => ({
    ...styles,
    color: 'white',
    backgroundColor: "#292929",
    fontFamily: 'Roboto Condensed',
    "&:hover": {
      backgroundColor: "#7400F9"
    },
  }),
  input: (styles: any, state: any) => ({
    ...styles,
    color: 'white',
    fontFamily: 'Roboto Condensed'
  }),
  container: (styles: any) => ({
    ...styles,
    width: '100%',
    padding: '0 0 15px 15px',
  }),
  valueContainer: (styles: any, state: any) => ({
    ...styles,
    color: 'white',
  }),
  menu: (styles: any) => ({
    ...styles,
    backgroundColor: '#292929',
    margin: '-12px 0px'
  }),
  control: (styles: any, state: any) => ({
    ...styles,
    height: '20px',
    backgroundColor: '#292929',
    border: 0,
    boxShadow: 'none',
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: 'white',
    fontFamily: 'Roboto Condensed'
  }),
  indicatorSeparator: (styles: any) => ({ ...styles, display: 'none' }),
  dropdownIndicator: (styles: any) => ({ ...styles, }),
  noOptionsMessage: (styles: any) => ({
    ...styles,
    fontFamily: 'Roboto Condensed'
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: 'white',
    fontFamily: 'Roboto Condensed',
  }),
};

function ModalAddAdvisor(props: any) {
  const { t, i18n } = useTranslation();
  const { club, org, staffMembers, getCommunities, setShowInviteAdv } = props;

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showError, setShowError] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);

  const [loadingAdvisor, setLoadingAdvisor] = useState(false)

  const handleOnChangeFirstName = (e: any) => {
    setFirstName(e.target.value);
  }
  const handleOnChangeLastName = (e: any) => {
    setLastName(e.target.value);
  }
  const handleOnChangeEmail = (e: any) => {
    setShowError(false);
    setEmail(e.value);
    const user = (staffMembers || []).find((item: any) => item.email.trim() === e.value.trim())
    if (user) {
      setFirstName(user.name)
      setLastName(user.lastname)
    }
  }

  const handleOnChangeSendEmail = (e: any) => {
    setSendEmail(e.target.checked);
  }

  const handleSave = (e: any) => {

    if (staffMembers.findIndex((adv: Advisor) => adv.email === email) === -1) {
      setErrorMsg('This is not a staff or principal email. Please provide a staff or principal email or invite this email to staff club.');
      setShowError(true);
      return
    }
    else if (club.advisors.findIndex((adv: Advisor) => adv.email === email) !== -1) {
      setErrorMsg('This advisor is already added. Please provide another staff email or close this window.');
      setShowError(true);
      return
    }

    sendInviteAdv();
  }

  const sendInviteAdv = () => {
    Backend(
      MicroServices.Invite,
      `/invites`, {
      method: 'POST',
      body: JSON.stringify({
        email: email,
        org_id: org.id,
        club_id: club.club_id,
        is_advisor: true,
        firstName: firstName,
        lastName: lastName,
      })
    })
      .then((response) => {
        setLoadingAdvisor(false);
        setShowError(false);
        setSendEmail(true);
        setFirstName('');
        setLastName('');
        setEmail('');
        getCommunities();
        setShowInviteAdv(false);
      })
      .catch((error) => {
        setLoadingAdvisor(false);
        toast.error(t('toast_errorSendingAdvisor'))
      })


  }

  const onCancel = (e: any) => {
    setLoadingAdvisor(false);
    setShowError(false);
    setSendEmail(true);
    setFirstName('');
    setLastName('');
    setEmail('');
    setShowInviteAdv(false);
  }

  const onhideModal = () => {
    setLoadingAdvisor(false);
    setShowError(false);
    setSendEmail(true);
    setFirstName('');
    setLastName('');
    setEmail('');
  }

  return (
    <>

      <Modal className="modalInviteMemebers" show={props.show} onHide={onhideModal}>
        <Modal.Header>
          <Modal.Title>{`Add Advisor for ${props.club.title}`}</Modal.Title>
          <FontAwesomeIcon
            icon="times"
            size='xl'
            color={WHITE}
            className="icon-times  "
            onClick={onCancel}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <label htmlFor="">Email</label>
            {/* <input name="emailAdvisor" type="text" style={{ color: 'white', paddingLeft: '10px' }} value={email} onChange={handleOnChangeEmail} /> */}
            <Select
              value={{
                value: email,
                label: email
              }}
              isMulti={false}
              options={(staffMembers || []).map((e: any) => {
                return {
                  label: `${e.name} ${e.lastname} (${e.email})`,
                  value: e.email,
                }
              })}
              placeholder={"Select Email"}
              onChange={handleOnChangeEmail}
              styles={styles}
              components={{
                IndicatorSeparator: () => null
              }}
            />
          </div>
          <div className="row">
            <label htmlFor="">First Name</label>
            <input name="firstNameAdvisor" type="text" style={{ color: 'gray', paddingLeft: '10px' }} value={firstName} onChange={handleOnChangeFirstName} />
          </div>
          <div className="row">
            <label htmlFor="">Last Name</label>
            <input name="lastNameAdvisor" type="text" style={{ color: 'gray', paddingLeft: '10px' }} value={lastName} onChange={handleOnChangeLastName} />
          </div>
          <div className="containers-input">
            <div className="option-chechbox">
              <label className="content-input">
                <input type="checkbox" name="principal-button" defaultChecked checked={sendEmail} onChange={handleOnChangeSendEmail} />
                <i></i>
              </label>
              <label htmlFor="students">Send invite email</label>
            </div>
          </div>
          {showError && (
            <div className="row">
              <p className="roboto-normal-white-16px space-top">{errorMsg}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="buttonClose"
            onClick={(e) => onCancel(e)}
          >
            Cancel
          </Button>
          <Button name="btnSaveAdvisor" className="buttonSend" onClick={handleSave}>
            {loadingAdvisor ? <div className="icon-spinner-third buttonSendSpinner" /> : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>


  );
}

export default ModalAddAdvisor;
