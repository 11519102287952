import { useEffect, useState } from "react";
import TwoColumns from "../../../../components/TwoColumns";
import { BaseInput, BaseInputWrapper, BaseTextArea, BaseTextAreaWrapper, CancelButton, ContentWrapper, CreatableSelectWrapper, CreateBtn, Header, MainWrapper, Row, SaveButton, SelectWrapper, Title } from "../../components";
import { BaseFieldState, getFieldState } from "../../utils";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { customStylesRSLeft, customStylesRSMulti, DropdownIndicator } from "../../../../helpers/selectHelper";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ModalDiscardChanges from "../../../../components/SetupSetup/ModalDiscardChanges";
import { useNavigate, useParams } from "react-router-dom";
import { Backend, MicroServices } from "../../../../helpers/backendHelper";
import CheckboxList from "./components/CheckboxList";
import { toast } from "react-toastify";
import TClub from "../../../../interfaces/TClubs/TClub/TClub";
import { capitalize } from "../../../../helpers/letterHelper";
import TableAdmin from "../../../../components/TableAdmin";
import TCLYouth from "../../../../interfaces/TClubs/TCLYouth/TCLYouth";
import AddYouthModal from "./AddYouthModal";

interface TClubTypeOp {
    value: string;
    label: string;
}

interface TClubSkillsOp {
    value: string;
    label: string;
    __isNew__: boolean;
}

type ClubType = 'CLUB' | 'TEAM' | 'COMM';
const CLUB_TYPE = ['CLUB', 'TEAM', 'COMM'];
const CLUB_TYPE_OPTIONS: { label: string, value: ClubType }[] = [
    {
        value: 'CLUB',
        label: 'Club',
    },
    {
        value: 'TEAM',
        label: 'Team',
    },
    {
        value: 'COMM',
        label: 'Community',
    },
];

const MEMBER_TYPE = [
    {
        value: 'STUDENT',
        label: 'Student',
    },
    {
        value: 'ALUMNI',
        label: 'Alumni',
    },
    {
        value: 'STAFF',
        label: 'Staff',
    },
    {
        value: 'GUARDIAN',
        label: 'Guardian',
    },
];

const genderList: any = {
    'M': 'Male',
    'F': 'Female',
    'O': 'Non binary', //'Non binary',
    'P': 'Prefer not to disclose', //'Prefer not to disclose',
};

const UpdateTClub = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {id} = useParams();

    const [TClub, setTClub] = useState<TClub>();

    const [TClubName, setTClubName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [TClubType, setTClubType] = useState<BaseFieldState<ClubType>>(getFieldState());
    const [TClubSkills, setTClubSkills] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [TClubDescription, setTClubDescription] = useState<BaseFieldState<string>>(getFieldState(''));
    const [TClubMemberType, setTClubMemberType] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [showDiscard, setShowDiscard] = useState(false);
    const [showAddYouth, setShowAddYouth] = useState(false);

    const [TClubTypeOption, setTClubTypeOption] = useState<TClubTypeOp>();
    const [TClubSkillsOptions, setTClubSkillsOptions] = useState<TClubSkillsOp[]>([]);

    useEffect(() => {
        getTClub()
    }, []);

    useEffect(() => {
        setFields()
    }, [TClub]);

    const cleanForm = () => {
        setTClubName(getFieldState(''));
        setTClubType(getFieldState());
        setTClubSkills(getFieldState([]));
        setTClubDescription(getFieldState(''));
        setTClubMemberType(getFieldState([]));
        setLoadingCreate(false);
        setShowDiscard(false);
        setTClubTypeOption(undefined);
        setTClubSkillsOptions([]);
    }

    const setFields = () => {
        setTClubName({
            ...TClubType,
            value: TClub?.name,
        });
        setTClubType({
            ...TClubType,
            value: TClub?.club_type,
        });
        setTClubTypeOption({
            label: capitalize(TClub?.club_type || ''),
            value: TClub?.club_type || ''
        });
        setTClubSkills({
            ...TClubType,
            value: TClub?.tags,
        });
        setTClubSkillsOptions((TClub?.tags || []).map((tag: string) => {
            return {
                label: tag,
                value: tag,
                __isNew__: true
            }
        }));
        setTClubDescription({
            ...TClubType,
            value: TClub?.description,
        });
        setTClubMemberType({
            ...TClubType,
            value: TClub?.member_types,
        });
    }

    const onChangeTClubName = (event: React.FormEvent<HTMLInputElement>) => {
        if (event) {
            setTClubName({
                ...TClubName,
                value: event?.currentTarget.value
            });
        }
    }

    const onChangeTClubDescription = (event: React.FormEvent<HTMLTextAreaElement>) => {
        if (event) {
            setTClubDescription({
                ...TClubDescription,
                value: event?.currentTarget.value
            });
        }
    }

    const onChangeTClubType = (element: any) => {
        setTClubTypeOption(element);
        setTClubType({
            ...TClubType,
            value: element.value,
        })
    }

    const onChangeTClubSkills = (updatedList: any) => {
        setTClubSkillsOptions(updatedList);
        setTClubSkills({
            ...TClubSkills,
            value: updatedList.map(({ label }: any) => label),
        })
    }

    const onChangeMemberTypes = (value: string) => {
        console.log(value)
        if (TClubMemberType.value?.includes(value)) {
            const list = [...TClubMemberType.value];
            list.splice(list.indexOf(value), 1)
            setTClubMemberType({
                ...TClubMemberType,
                value: list,
            })
        } else {
            setTClubMemberType({
                ...TClubMemberType,
                value: [...(TClubMemberType.value || []), value]
            })
        }
    }

    const errorsInForm = () => {
        let hasErrors = false;

        if (!TClubName.value || TClubName.value.length <= 0) {
            hasErrors = true;
            setTClubName({
                ...TClubName,
                showError: true,
                error: 'Name is required',
            })
            console.log('Name is required',)
        } else {
            setTClubName(getFieldState(TClubName.value));
        }

        if (!TClubType.value) {
            hasErrors = true;
            setTClubType({
                ...TClubType,
                showError: true,
                error: 'Club Type is required',
            })
            console.log('Club Type is required',)
        } else if (!CLUB_TYPE.includes(TClubType.value)) {
            hasErrors = true;
            setTClubType({
                ...TClubType,
                showError: true,
                error: 'Club Type is must be either Club, Team or Community',
            })
            console.log('Club Type is must be either Club, Team or Community')
        } else {
            setTClubType(getFieldState(TClubType.value));
        }

        if (!TClubSkills.value || TClubSkills.value.length <= 0) {
            hasErrors = true;
            setTClubSkills({
                ...TClubSkills,
                showError: true,
                error: 'Select at least one skill',
            })
            console.log('Select at least one skill',)
        } else {
            setTClubSkills(getFieldState(TClubSkills.value));
        }

        if (!TClubMemberType.value || TClubMemberType.value.length <= 0) {
            hasErrors = true;
            setTClubMemberType({
                ...TClubMemberType,
                showError: true,
                error: 'Select at least one member type',
            })
            console.log('Select at least one member type');
        } else {
            setTClubMemberType(getFieldState(TClubMemberType.value));
        }


        if (!TClubDescription.value || TClubDescription.value.length <= 0) {
            hasErrors = true;
            setTClubDescription({
                ...TClubDescription,
                showError: true,
                error: 'Name is required',
            })
            console.log('Name is required',)
        } else {
            setTClubDescription(getFieldState(TClubDescription.value));
        }

        return hasErrors;
    }

    const onHideDiscard = (type: string) => {
        setShowDiscard(false);
        if (type === 'discard') {
            cleanForm();
            navigate(-1);
        }
    };

    const getTClub = () => {

        let fail = false;
        Backend(
            MicroServices.Club,
            `/tclubs/${id}`
        )
            .then((response) => {
                if (response.status !== 200) fail = true;
                return response.json()
            })
            .then((results: TClub) => {
                if (fail) {
                    toast.error(t('toast_errorGettingTemplate'));
                    console.error(results);
                    return
                }
                setTClub(results);
            })
            .catch((error) => {
                toast.error(t('toast_errorGettingTemplate'));
                console.log(error);
            })
            ;
    }

    const onCancel = () => {
        setShowDiscard(true);
    }

    const onCreate = () => {
        if (errorsInForm()) {
            toast.error(t('toast_errorMissingInformation'));
            return;
        }
        setLoadingCreate(true);
        Backend(
            MicroServices.Club,
            `/tclubs/${id}`,
            {
                method: 'PUT',
                body: JSON.stringify({
                    name: TClubName.value,
                    club_type: TClubType.value,
                    tags: TClubSkills.value,
                    description: TClubDescription.value,
                    member_types: TClubMemberType.value
                })
            }
        )
            .then((response) => {
                if (response.status === 201) {
                    toast.success(t('toast_templateClubUpdated'));
                    cleanForm();
                    navigate(-1);
                    return;
                }

                toast.error(t('toast_errorUpdatingTemplate'));
            })
            .catch(() => toast.error(t('toast_errorUpdatingTemplate')))
            .finally(() => setLoadingCreate(false));
    }

    const onHideAddYouthModal = () => {
        setShowAddYouth(false);
        getTClub();
    }

    return (
        <>
            <MainWrapper>
                <Header>
                    <Row>
                        <Title>Update Template Club</Title>
                    </Row>
                </Header>
                <ContentWrapper>
                    <TwoColumns
                        left={<FormWrapper>
                            <BaseInputWrapper
                                placeholder="Club Name *"
                                value={TClubName.value || ''}
                                onChangeValue={onChangeTClubName}
                                showError={TClubName.showError || false}
                                error={TClubName.error || ''}
                            />
                            <SelectWrapper
                                options={CLUB_TYPE_OPTIONS}
                                styles={customStylesRSLeft()}
                                components={{
                                    DropdownIndicator
                                }}
                                value={TClubTypeOption || ''}
                                onChangeValue={onChangeTClubType}
                                placeholder="Club Type *"
                                showError={TClubType.showError || false}
                                error={TClubType.error || ''}
                            />
                            <CheckboxList
                                title="Member Type *"
                                options={MEMBER_TYPE}
                                valuesSelected={TClubMemberType.value || []}
                                showError={TClubMemberType.showError || false}
                                error={TClubMemberType.error || ''}
                                onChangeValue={onChangeMemberTypes}
                            />
                        </FormWrapper>}
                        right={<FormWrapper>
                            <CreatableSelectWrapper
                                // options={activeCountriesReactSelect}
                                styles={customStylesRSMulti()}
                                value={TClubSkillsOptions}
                                onChangeValue={onChangeTClubSkills}
                                placeholder="Skills Learned *"
                                showError={TClubSkills.showError || false}
                                error={TClubSkills.error || ''}
                            />
                            <BaseTextAreaWrapper
                                placeholder="Club Description *"
                                value={TClubDescription.value || ''}
                                onChangeValue={onChangeTClubDescription}
                                showError={TClubDescription.showError || false}
                                error={TClubDescription.error || ''}
                            />
                        </FormWrapper>}
                    />
                    <RowLeft>
                        <AddYOBtn onClick={() => setShowAddYouth(true)}>
                            Add Youth Organization Affiliated
                        </AddYOBtn>
                    </RowLeft>
                    <TableAdmin
                        headers={[
                            {
                                label: 'Youth Organization',
                            },
                            {
                                label: 'Genders Allowed',
                            },
                            {
                                label: 'Grades  Allowed',
                            },
                        ]}
                        items={TClub?.youth_orgs || []}
                        renderItem={(item: TCLYouth) => ([
                            item.yo_name || item.yo_id,
                            item.genders_allowed?.map((g) => genderList[g])?.join(', '),
                            item.grades_allowed?.join(', ')
                        ])}
                        keyExtractor={({yo_id}: TCLYouth) => yo_id}
                    />
                    <ActionWrapper>
                        <CancelButton onClick={onCancel}>
                            {t('button__cancel')}
                        </CancelButton>
                        <SaveButton onClick={onCreate}>
                            {loadingCreate
                                ? (
                                    <div className="icon-spinner-third buttonSendSignUp"></div>
                                )
                                : t('button__save')
                            }
                        </SaveButton>
                    </ActionWrapper>
                </ContentWrapper>
            </MainWrapper>
            <ModalDiscardChanges
                show={showDiscard}
                onHide={onHideDiscard}
            />
            {TClub && <AddYouthModal
                show={showAddYouth}
                onHide={onHideAddYouthModal}
                tclub={TClub}
            />}
        </>
    )
}

const FormWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const ActionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 15px;
    justify-content: flex-end;
`;

export const AddYOBtn = styled.button`
    align-items: center;
    background-color: var(--electric-violet);
    border-radius: 12px;
    display: flex;
    height: 33px;
    padding: 18px;
    margin-left: 13px;
    border: none;
    cursor: pointer;
    /* Find a better way */
    color: var(--white);
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
`;

const RowLeft = styled(Row)`
    justify-content: flex-end;
`;

export default UpdateTClub;