import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Backend, MicroServices } from "../../../helpers/backendHelper";

function Index(props: any) {

    const [deleting, setDeleting] = useState<boolean>(false)

    function handleOnClickOk() {
        props.setShow(false)
        Backend(
            MicroServices.Club,
            `/clubs/${props.club.club_id}?pending=true&setup=true`,
            {
                method: 'DELETE'
            }
        )
            .then((response) => {
                props.getCommunities()
            })
            .catch((error) => {
                console.log(error);
            })
    }

    function onHide() {
        props.setShow(false)
    }

    return (
        <>
            <Modal className="modalDelete" show={props.show} onHide={onHide}>
                <Modal.Header>
                    <Modal.Title>{`Delete ${props.club?.title}`}</Modal.Title>
                    <FontAwesomeIcon
                        icon="times"
                        size='xl'
                        color='white'
                        className="icon-times  "
                        onClick={() => onHide()}
                    />
                </Modal.Header>
                <Modal.Body>
                    <span className="labelEventCreated"> {`Are you sure you want to delete ${props.club?.title}?`}</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="buttonModalDelete bCancel" onClick={() => onHide()}>
                        Cancel
                    </Button>
                    <Button className="buttonModalDelete bDelete" onClick={() => handleOnClickOk()}>
                        {deleting ? <div className="icon-spinner-third buttonSendSpinner" /> : 'Delete'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Index;