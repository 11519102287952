import React, { useEffect, useState } from "react";
import Header from "../Header";
import styled from "styled-components";
import {
  RobotocondensedRegularNormalWhite16,
  Fontawesome5proRegularNormalLavende,
} from "../../styledMixins";
import "./styles.css";
import "./styles.scoped.css";
import SuperAdminLeftMenu from "../SuperAdminLeftMenu";
import UsersTableOrgAdmin from "../UsersTableOrgAdmin";
import "../../styles.css";
import Select, { SingleValue } from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import { getUser, getUsers, getUsersRoles, getUsersStatus, getUsersWithLimit } from "../../store/actions/userActions";
import { getOrganizationNames } from "../../store/actions/orgsActions";
import { getActiveCities, getActiveCountries } from "../../store/actions/locationActions";
import PageNumber from "../PageNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import AdminTitle from "../../styledComponents/AdminTitle";

const PAGE_SIZES: pageSize[] = [10, 50];
export type pageSize = 10 | 50 | 10000;

function Users() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<pageSize>(PAGE_SIZES[0]);
  const [org, setOrg] = useState('')

  const [users, setUsers] = useState([])
  const user = useSelector((state: any) => state.getUser.user);
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const userRoles = useSelector((state: any) => state.getUser.usersRoles);

  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.getUser.token);
  const lastEvaluatedkeys = useSelector((state: any) => state.getOrgs.lastEvaluatedkeys);

  useEffect(() => {
    if(user){
      if(user.organizations.length === 0){
        setUsers([])
        dispatch<any>(getUser(userInfo.username));
      }else{
        setOrg(user.organizations[0])
        dispatch<any>(getUsersWithLimit({
          'org':user.organizations[0],
          'roleRequest': userRoles.includes('SUPER') ? 'SA': 'OA'
        }))
          .then((users: any) => {
            setUsers(users || [])
          });
      }
    }else{
      setUsers([])
      dispatch<any>(getUser(userInfo.username)); 
    }
    
  }, [user]);

  useEffect(() => {
    dispatch(getUsersStatus());
    dispatch(getOrganizationNames());
    dispatch(getUsersRoles());
    dispatch(getActiveCities());
    dispatch(getActiveCountries());
  }, [])

  // useEffect(() => {
  //   if (page === 0) {
  //     dispatch(getUsersWithLimit({'token':token, 'limit':pageSize, 'page':page,'org':user.organizations[0]}))
  //   } else {
  //     dispatch(getUsersWithLimit({'token':token, 'limit':pageSize, 'page':page, 'lastEvaluatedKey':lastEvaluatedkeys[page],'org':user.organizations[0]}))
  //   }
  // }, [pageSize, page]);

  const handleSetPageSize = (newValue: SingleValue<{ label: string; value: pageSize; }>) => {
    setPageSize(newValue?.value || 10)
  }

  const customStyles = () => ({
    option: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#383838',
    }),
    input: (styles: any, state: any) => ({
      ...styles,
      border: 'none',
      color: 'white',
      fontWeight: "normal",
      paddingLeft: "5px",
      "&:focus": {backgroundColor: 'red'},
    }),
    container: (styles: any) => ({
      ...styles,
      padding: 0,
    }),
    valueContainer: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: 'transparent',
      padding: 0,
    }),
    menu: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      backgroundColor: "#383838",
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      textAlign: "left",
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "118px",
      borderRadius: "0px",
      boxShadow: "none",
      border: 0,
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "bold",
      maxWidth: "200px",
      marginBottom: "5px",
      backgroundColor: 'transparent',
      // textDecoration: outside ? 'underline' : 'none',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: 'white',
      textDecoration: "underline",
      display: 'block',
    }),
    indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
    dropdownIndicator: (styles: any) => ({ ...styles, display: "none" }),
    noOptionsMessage: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: 'grey',
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "normal",
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: 'transparent',
      border: "none",
      paddingLeft: "5px",
      paddingRight: "5px",
      
    }),
  })

  const [roleSelected, setRoleSelected] = useState('');

  const viewingOptions = PAGE_SIZES.map((item) => ({
    label: `${item} results`,
    value: item,
  }))

  const canGoBack = () => page !== 0;
  const canGoNext = () => true;

  const goBack = () => {
    setPage(page - 1);
  };

  const goNext = () => {
    setPage(page + 1);
  };

  const handleChangeRadio = (event: any) => {
    setRoleSelected(event.target.value);
  }

  return (
        <div className="content-org">
          <TopColumn>
            <ManageUsersCol>
              <div className="header--wrapper">
                <AdminTitle>{t('manageUser__title')}</AdminTitle>
                <button className="CreateUser__wrapper" type="button" onClick={() => navigate('/admin/users/create')}>
                  <FontAwesomeIcon
                    icon={faPlus}
                    color="#FFF"
                  />
                  <span className="CreateUser__text roboto-normal-white-16px">Create User</span>
                </button>
              </div>
              <div className="select-container" onChange={handleChangeRadio}>
                <div className="option">
                  <label className="content-input">
                    <input type="radio" name="role-button" value=""/>
                    <i></i>
                  </label>
                  <label htmlFor="students">{t('manageUser__all')}</label>
                </div>
                {/* <div className="option">
                  <label className="content-input">
                    <input type="radio" name="role-button" value="Z%23ORGADMIN"/> {Change to ZAA}
                    <i></i>
                  </label>
                  <label htmlFor="students">Organization Admins</label>
                </div> */}
                <div className="option">
                  <label className="content-input">
                    <input type="radio" name="role-button" value="ZAA%23STUDENT"/>
                    <i></i>
                  </label>
                  <label htmlFor="students">{t('manageUser__student')}</label>
                </div>
                <div className="option">
                  <label className="content-input">
                    <input type="radio" name="role-button" value="ZAA%23STAFF"/>
                    <i></i>
                  </label>
                  <label htmlFor="students">{t('manageUser__staff')}</label>
                </div>
                <div className="option">
                  <label className="content-input">
                    <input type="radio" name="role-button" value="ZAA%23GUARDIAN"/>
                    <i></i>
                  </label>
                  <label htmlFor="students">{t('manageUser__guardian')}</label>
                </div>
                <div className="option">
                  <label className="content-input">
                    <input type="radio" name="role-button" value="ZAA%23ALUMNI"/>
                    <i></i>
                  </label>
                  <label htmlFor="students">{t('manageUser__alumni')}</label>
                </div>
              </div>
              <div className="shwo-view-row">
              <ShowingItems>
                <span className="robotocondensed-normal-white-16px">
                  {t('header__showing')}{' '}
                </span>
                <span className="robotocondensed-bold-white-16px">
                  {(pageSize*page) + 1}-{pageSize*(page + 1)}{' '}
                </span>
                <span className="robotocondensed-normal-white-16px">
                  {t('header__of')}{' '}
                </span>
                <span className="robotocondensed-bold-white-16px">
                  {users.length}{' '}
                </span>
                <span className="robotocondensed-normal-white-16px">
                  {t('manageUser__users')}
                </span>
              </ShowingItems>
              <Viewing>
                <Viewing1>{t('header__viewing')}</Viewing1>
                <Box>
                  <Select
                    value={{value: pageSize, label: `${pageSize} results`}}
                    isMulti={false}
                    options={viewingOptions}
                    placeholder={"View"}
                    styles={customStyles()}
                    onChange={handleSetPageSize}
                  />
                  <ChevronDown />
                </Box>
              </Viewing>
              </div>
              
              
            </ManageUsersCol>
            
          </TopColumn>
          <UsersTableOrgAdmin 
          roleSelected={roleSelected}
          setRoleSelected={setRoleSelected} 
          org={org}
          users={users}
          setUsers={setUsers}/>
          <PageNumber
            page={page}
            canGoBack={canGoBack()}
            canGoNext={canGoNext()}
            goBack={goBack}
            goNext={goNext}
          />
        </div>   
  );
}

const TopColumn = styled.div`
  height: 154px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
  justify-content: space-between;
`;

const ManageUsersCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ShowingItems = styled.p`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  align-self: flex-start;
  margin-top: 5px;
  letter-spacing: 0;
  align-self: center;
  text-align: center;
`;

const Viewing = styled.div`
  margin-left: 3px;
  display: flex;
  align-items: center;
  min-width: 197px;
  align-self: flex-end;
  font-family: var(--font-family-roboto_condensed);
`;

const Viewing1 = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  min-width: 50px;
  letter-spacing: 0;
`;

const Box = styled.div`
  height: 40px;
  margin-left: 17px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  min-width: 130px;
  background-color: var(--violent-violet);
`;

const ChevronDownWrapper = styled.div`
  ${Fontawesome5proRegularNormalLavende}
  min-height: 20px;
  margin-left: 27px;
  min-width: 18px;
  letter-spacing: 0;
`;

const ChevronDown = () => <ChevronDownWrapper>
  <FontAwesomeIcon icon="chevron-down" color="#FFFFFF" size="sm"/>
</ChevronDownWrapper>

export default Users;
