import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Department } from "../../containers/SetupAdvisor";
import { WHITE } from "../ClubIconsNavBar";
import './styles.scoped.css';
import { useTranslation } from "react-i18next";

function Index(props: {
    myDepartments: Department[];
    setMyDepartments: any;
    departments: Department[];
    setDepartments: any;
    handleOnClick: (e: any, step: any, save: boolean) => void;
    showClubsStepAbove: boolean;
}) {
    const { t, i18n } = useTranslation();
    //Handlers
    const handleManageDepartments = (index: number, active: boolean) => {
        if (active) {
            const new_my_departments = [...props.myDepartments]
            if (!new_my_departments.find((item) => item.id === index)) new_my_departments.push({
                id: index,
                title: props.departments[index].title,
                active: true
            })
            props.setMyDepartments(new_my_departments)

            const new_departments = [...props.departments]
            new_departments[index] = {
                id: index,
                title: new_departments[index].title,
                active: true
            }
            props.setDepartments(new_departments)
        } else {
            const new_my_departments = props.myDepartments.filter((item) => item.id !== index)
            props.setMyDepartments(new_my_departments)

            const new_departments = [...props.departments]
            new_departments[index] = {
                id: index,
                title: new_departments[index].title,
                active: false
            }
            props.setDepartments(new_departments)
        }
    }

    return (
        <>
            <div className="titles-header">
                <div className="titles">
                    <div className="myProfile-Header">
                        <h1>Zaahah</h1>
                        <div className="myProfile-Buttons">
                            {props.myDepartments.length >= 1 ?
                                <button className="button-start" onClick={(e) => props.handleOnClick(e, props.showClubsStepAbove ? 4 : 5, true)}>{t('setupSchool__saveAndContinue')}</button>
                                :
                                ""
                            }
                        </div>
                    </div>
                    <span>{t('setupStaff__selectDepartments')}</span>
                </div>
            </div>
            <div className="listDepartmentsSelected">
                {props.myDepartments.length < 1 &&
                    <div className="countDepartment">
                        {t('setupStaff__chooseAll')}
                    </div>
                }
                {/* {props.myDepartments.map((department: any) => (
                    <button>
                        {department.title}
                        <FontAwesomeIcon
                            icon="times"
                            size='xl'
                            color={WHITE}
                            className="icon-times "
                            onClick={() => handleManageDepartments(department.id, false)}
                        />
                    </button>
                ))} */}
            </div>
            <div className="listInterestsStaff">
                {props.departments.map((department: any) => (
                    <button 
                        className={department.active ? 'active' : ''} 
                        onClick={() => handleManageDepartments(department.id, department.active ? false : true)} 
                    > 
                            {department.title} 
                    </button>
                ))}
            </div>
        </>
    );
}

export default Index;
