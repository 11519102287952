import { faCopy, faRotate } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import { dateToUpdate, getBaseYear, stringToDate } from '../../helpers/dateHelper';
import useGender from '../../hooks/useGender';
import { WHITE } from '../ClubIconsNavBar';
import { toast } from 'react-toastify';
import './styles.scoped.css';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

interface AdminTextInputProps {
  placeholder: string;
  label?: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  value: string;
  showErrors: boolean;
  error: () => string;
};

export const AdminTextInput = ({
  placeholder,
  label,
  onChange,
  value,
  showErrors,
  error,
}: AdminTextInputProps) => (
  <div className="AdminTextInput__outer">
    <div className="AdminTextInput__wrapper" >
      <label className="AdminTextInput__label robotocondensed-bold-lavender-16px">{label || placeholder}</label>
      <input
        placeholder={placeholder}
        type="text"
        className="AdminTextInput__input roboto-normal-white-16px"
        onChange={onChange}
        value={value}
      />
    </div>
    {(showErrors && error() !== '') && <span className='roboto-bold-white-16px error-text'>{error()}</span>}
  </div>
);

export const AdminTextNoLabelInput = ({
  placeholder,
  onChange,
  value,
  showErrors,
  error,
}: AdminTextInputProps) => (
  <div className="AdminTextInput__outer">
    <div className="AdminTextInput__wrapper" >
      <input
        placeholder={placeholder}
        type="text"
        className="AdminTextInput__input roboto-normal-white-16px"
        onChange={onChange}
        value={value}
      />
    </div>
    {(showErrors && error() !== '') && <span className='roboto-bold-white-16px error-text'>{error()}</span>}
  </div>
);

interface AdminGenderInputProps {
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  value: string;
  showErrors: boolean;
  error: () => string;
};

export const AdminGenderInput = ({
  onChange,
  value,
  showErrors,
  error,
}: AdminGenderInputProps) => {
  const organization = useSelector((state: any) => state.getOrgs.organizationInfo);
  const { genders, setCountry: setCountryGender } = useGender();

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryGender(organization.countryCode);
    }
  }, [])
  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryGender(organization.countryCode);
    }
  }, [organization])
  return (
    <div className='AdminTextInput__outer'>
      <div className="AdminTextInput__wrapper" >
        <label className="AdminTextInput__label robotocondensed-bold-lavender-16px">Gender</label>
        <div className="AdminGenderInput__options">
          {genders.map(({ id, label }) => (
            <div className="optionRadio" key={id}>
              <label className="content-input">
                <input name="gender" type="radio" value={id} onChange={onChange} checked={value === id} />
                <i className="gender-radio"></i>
              </label>
              <div className="male radioButton robotocondensed-normal-white-16px">{label}</div>
            </div>
          ))}
        </div>
      </div>
      {(showErrors && error() !== '') && <span className='roboto-bold-white-16px error-text'>{error()}</span>}
    </div>
  );
};

interface AdminBirthdayInputProps {
  value: string;
  onChange: (date: string) => any
};

export const AdminBirthdayInput = ({ value, onChange }: AdminBirthdayInputProps) => {
  return (
    <div className="AdminTextInput__outer">
      <div className="AdminTextInput__wrapper" >
        <label className="AdminTextInput__label robotocondensed-bold-lavender-16px">Birthdate</label>
        <div className="form-parts-3-1 AdminBirthdayInput__inputWrapper">
          <div className="frame-5448 myBirthdayContainer">
            <FontAwesomeIcon
              icon="calendar-alt"
              size='lg'
              color={WHITE}
              className="calendar-alt inputIcon"
            />
            <DatePicker
              dateFormat="MM-dd-yyyy"
              selected={value === '' ? undefined : stringToDate(value)}
              onChange={(date: Date) => onChange(dateToUpdate(date))}
              className='inputBirthdayAdmin'
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              maxDate={getBaseYear()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

interface AdminPasswordInputProps {
  label?: string;
  value: string;
  regenerate: () => void,
};


export const AdminPasswordInput = ({
  label = 'Password',
  regenerate,
  value,
}: AdminPasswordInputProps) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="AdminTextInput__outer">
      <div className="AdminTextInput__wrapper" >
        <label className="AdminTextInput__label robotocondensed-bold-lavender-16px">{label}</label>
        <div className="AdminTextInput__inputMock">
          <div className="AdminTextInput__inputMock--text roboto-normal-gray-16px">
            {value}
          </div>
          <div className="AdminTextInput__inputMock--actions">
            <FontAwesomeIcon
              icon={faRotate}
              color={WHITE}
              className="AdminTextInput__inputMock--actionIndv"
              onClick={regenerate}
            />
            <FontAwesomeIcon
              icon={faCopy}
              color={WHITE}
              className="AdminTextInput__inputMock--actionIndv"
              onClick={() => { navigator.clipboard.writeText(value); toast.success(t('toast_passwordCopied')); }}
            />
          </div>
        </div>
      </div>
    </div>
  )

};

interface AdminSelectionInputProps {
  label: string;
  onChange: (e: React.FormEvent<HTMLSelectElement>) => any;
  value: string;
  options: {
    key: string;
    label: string;
    disabled?: boolean;
  }[];
  showErrors: boolean;
  error: () => string;
}

export const AdminSelectionInput = ({
  label,
  onChange,
  value,
  options,
  showErrors,
  error
}: AdminSelectionInputProps) => (
  <div className='AdminTextInput__outer'>
    <div className="AdminTextInput__wrapper" >
      <label className="AdminTextInput__label robotocondensed-bold-lavender-16px">{label}</label>
      <select value={value} onChange={onChange} className="AdminTextInput__inputMock roboto-normal-white-16px AdminSelectInput__select">
        {options.map(({ key, label, disabled }) => (
          <option value={key} disabled={!!disabled}>{label}</option>
        ))}
      </select>
    </div>
    {(showErrors && error() !== '') && <span className='roboto-bold-white-16px error-text'>{error()}</span>}
  </div>
);