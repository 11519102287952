import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import i18n from '../../i18n';
import { CognitoUser, ICognitoUserAttributeData } from 'amazon-cognito-identity-js';
import {
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  EDIT_USER_ERROR,
  EDIT_USER_SUCCESS,
  FORGOT_ERROR,
  FORGOT_SUCCESS,
  GET_AHA_BALANCE_ERROR,
  GET_AHA_BALANCE_SUCCESS,
  GET_USERS_ERROR,
  GET_USERS_LIMIT_ERROR,
  GET_USERS_LIMIT_SUCCESS,
  GET_USERS_SUCCESS,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  RELOAD_COGNITO_USER_ERROR,
  RELOAD_COGNITO_USER_SUCCESS,
  RESTORE_ERROR,
  RESTORE_SUCCESS,
  SAVE_DEPARTMENTS_ERROR,
  SAVE_DEPARTMENTS_SUCCESS,
  SIGNUP_ERROR,
  SIGNUP_RESTORE,
  SIGNUP_SUCCESS,
  START_CHANGE_PASSWORD,
  START_EDIT_USER,
  START_FORGOT,
  START_GET_AHA_BALANCE,
  START_GET_USERS,
  START_GET_USERS_LIMIT,
  START_LOGIN,
  START_RELOAD_COGNITO_USER,
  START_RESTORE,
  START_SAVE_DEPARTMENTS,
  START_SIGNUP,
  START_UPDATE_PROFILE_PHOTO,
  START_UPDATE_USER,
  START_UPLOAD_ASSET_PROFILE_PHOTO,
  START_UPLOAD_PROFILE_PHOTO,
  START_USER,
  START_USER_INFO,
  START_USER_ROLES,
  START_USER_STATUS,
  UPDATE_PROFILE_PHOTO_ERROR,
  UPDATE_PROFILE_PHOTO_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  UPLOAD_ASSET_PROFILE_PHOTO_ERROR,
  UPLOAD_ASSET_PROFILE_PHOTO_SUCCESS,
  UPLOAD_PROFILE_PHOTO_ERROR,
  UPLOAD_PROFILE_PHOTO_SUCCESS,
  USER_ERROR,
  USER_INFO_ERROR,
  USER_INFO_SUCCESS,
  USER_ROLES_ERROR,
  USER_ROLES_SUCCESS,
  USER_STATUS_ERROR,
  USER_STATUS_SUCCESS,
  USER_SUCCESS
} from '../constants/userConstants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Auth } from 'aws-amplify';
import { ISignUpOrganizationRequest } from '../../interfaces/Signup';
import { UserResponse } from '../../interfaces/user/UserResponse';
import getUserParams from '../../interfaces/user/getUserParams';
import { getOrganizationInfo } from './orgsActions';
import { LOGOUT_ORG } from '../constants/orgsConstants';
import { LOGOUT_AHA } from '../constants/ahaConstants';
import { LOGOUT_CLUB } from '../constants/clubConstants';
import { LOGOUT_CONNECTION } from '../constants/connectionsConstants';
import { LOGOUT_EVENT } from '../constants/eventConstants';
import { LOGOUT_INVITES } from '../constants/inviteConstants';
import { LOGOUT_LOCATION } from '../constants/locationConstants';
import { LOGOUT_NOTIFICATION } from '../constants/notificationConstants';
import { LOGOUT_POST } from '../constants/postConstants';
import { LOGOUT_PROFILE } from '../constants/profileConstants';
import { LOGOUT_TEMPLATE_CATEGORY } from '../constants/templateCategoryConstants';
import { LOGOUT_TEMPLATE_CLUB } from '../constants/templateClubConstants';
import { refreshTokens, saveTokens } from './tokenActions';
import { LOGOUT_TOKENS } from '../constants/tokenConstants';
import { Backend, MicroServices } from '../../helpers/backendHelper';
import { getSignInFunction } from "../../helpers/amplifyHelper";
import { isEmail } from "../../guards/SignupOrg";
import { LOGOUT_CHAT } from '../constants/chatConstants';

toast.configure();

interface Error {
  code: string;
  name: string;
  message: string;
}

export const userLogin =
  (
    username: string,
    password: string,
    custom: any = false
  ): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<boolean> => new Promise((resolve, reject) => {
      dispatch({ type: START_LOGIN });


      getSignInFunction({ username, password, custom })
        .then((signInFunc: any) => {
          return signInFunc()
        })
        .then((response: any) => {
          console.log(response);

          if (isEmail(response.username)) {
            try {
              response.username = response.getSignInUserSession()?.getIdToken()?.decodePayload()["cognito:username"];
            } catch (e) {
              console.log("unable to get username from cognitoUser object", e)
            }
          }

          if (!response.getSignInUserSession()) {
            toast.error(
              response.message || 'Error: Please check the information'
            );
            dispatch({
              type: LOGIN_ERROR,
              payload: {
                message: '',
                obj: response,
              },
            });
            resolve(false);
          }

          const accessToken = response.getSignInUserSession()?.getAccessToken().getJwtToken() || ''
          const IDToken = response.getSignInUserSession()?.getIdToken().getJwtToken() || ''
          const refreshToken = response.getSignInUserSession().getRefreshToken().getToken() || ''

          dispatch(saveTokens(accessToken, IDToken, refreshToken))

          localStorage.setItem('user', JSON.stringify(response));
          const orgId = JSON.parse(
            response.attributes['custom:organizations']
          )[0];

          dispatch<any>(getOrganizationInfo(orgId)).catch(console.log);
          const user = {
            ...response,
            auth_key:
              response.getSignInUserSession()?.getAccessToken().getJwtToken() ||
              '',
          }
          dispatch(getBalance(user.username))
          dispatch({
            type: LOGIN_SUCCESS,
            payload: user,
          });
          resolve(true);
        })
        .catch((error: Error) => {
          toast.error(error.message || 'Error: Please try later');
          dispatch({
            type: LOGIN_ERROR,
            payload: {
              message: error.message,
              obj: error,
            },
          });
          resolve(false);
        });
    }
    );

export const getBalance = (username: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
    new Promise((resolve, reject) => {
      dispatch({ type: START_GET_AHA_BALANCE })
      Backend(
        MicroServices.AHA,
        `/person/balance?username=${username}`
      )
        .then((r)=>{
          if (r.status === 200) {
            return r.json()
          } else {
            return Promise.reject(r)
          }
        })
        .then((results) => {
          if(results){
            dispatch({ type: GET_AHA_BALANCE_SUCCESS, payload: results.aHa })
          }
        })
        .catch((error) => {
          console.log('Error catched aHa Balance', error);
          dispatch({ type: GET_AHA_BALANCE_ERROR, payload: error })
        })
    })

export const orgSignup =
  (
    body: ISignUpOrganizationRequest
  ): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_SIGNUP });
        const {t} = i18n;
        Backend(
          MicroServices.AccessManager,
          `/auth/sign-up-organization`, {
          method: 'POST',
          body: JSON.stringify(body),
        })
          .then((r) => {
            if (r.status === 201) {
              toast.success(t('toast_OrgCreated'));
              dispatch({ type: SIGNUP_SUCCESS });
              resolve(true);
            } else {
              return r.json();
            }
          })
          .then((response) => {
            toast.error(
              response.error.message ||
              'There was an error, please check your information and try again later.'
            );
            dispatch({ type: SIGNUP_ERROR, payload: response });
            resolve(false);
          })
          .catch((error) => {
            dispatch({ type: SIGNUP_ERROR, payload: error });
          });
      });

export const restoreSignup =
  (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      dispatch({ type: SIGNUP_RESTORE });
    };

export const reloadSession =
  (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      try {
        // const token = localStorage.getItem('token');
        const userStr = localStorage.getItem('user');
        // const IDToken = localStorage.getItem('IDToken');
        // const refreshToken = localStorage.getItem('refreshToken');
        // const profileStr = localStorage.getItem('profile');
        if (
          //typeof token === 'string' &&
          typeof userStr === 'string' //&&
          //typeof IDToken === 'string' &&
          //typeof refreshToken === 'string' &&
          // typeof profileStr === 'string'
        ) {
          const user = JSON.parse(userStr);
          dispatch(getBalance(user.username))
          dispatch({
            type: LOGIN_SUCCESS,
            payload: user,
          });
          const orgId = JSON.parse(user.attributes['custom:organizations'])[0];

          dispatch(refreshTokens())
          dispatch(reloadUserRoles())

          dispatch<any>(getOrganizationInfo(orgId));
          // dispatch({
          //   type: GET_PROFILE_SUCCESS,
          //   payload: JSON.parse(profileStr),
          // });
          return;
        }
        dispatch(userLogout());
      } catch (error) {
        dispatch(userLogout());
      }
    };

export const userLogout =
  (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      localStorage.clear();
      dispatch({
        type: LOGOUT
      });

      dispatch({
        type: LOGOUT_ORG,
      });

      dispatch({
        type: LOGOUT_CONNECTION,
      });

      dispatch({
        type: LOGOUT_CLUB,
      });

      dispatch({
        type: LOGOUT_EVENT,
      });

      dispatch({
        type: LOGOUT_POST,
      });

      dispatch({
        type: LOGOUT_NOTIFICATION,
      });

      dispatch({
        type: LOGOUT_AHA,
      });

      dispatch({
        type: LOGOUT_PROFILE,
      });

      dispatch({
        type: LOGOUT_LOCATION,
      });

      dispatch({
        type: LOGOUT_INVITES,
      });

      dispatch({
        type: LOGOUT_TEMPLATE_CLUB,
      });

      dispatch({
        type: LOGOUT_TEMPLATE_CATEGORY,
      });

      dispatch({
        type: LOGOUT_TOKENS,
      })

      dispatch({
        type: LOGOUT_CHAT,
      })
    };

export const userForgotPassword =
  (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve) => {
      dispatch({ type: START_FORGOT });
      const {t} = i18n;
      Auth.forgotPassword(username)
        .then((response) => {
          toast.success(t('toast_CheckYourEmail'));
          dispatch({ type: FORGOT_SUCCESS });
          resolve()
        })
        .catch((error) => {
          toast.error(t('toast_ThereWasError'));
          console.error('forgot error', error);
          dispatch({ type: FORGOT_ERROR });
        });
    });

export const userRestorePassword =
  (
    username: string,
    code: string,
    password: string
  ): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve) => {
      dispatch({ type: START_RESTORE });
      const {t} = i18n;
      Auth.forgotPasswordSubmit(username, code, password)
        .then((response) => {
          toast.success(t('toast_passwordRestored'));
          dispatch({ type: RESTORE_SUCCESS });
          resolve();
        })
        .catch((error) => {
          toast.error(t('toast_passwordNotLong'));
          console.error('RESTORE error', error);
          dispatch({ type: RESTORE_ERROR });
        });
    });

export const reloadUserRoles =
  (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve) => {
      dispatch({ type: START_RELOAD_COGNITO_USER });
      const {t} = i18n;
      Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {

          user.getUserAttributes((error, attributes) => {

            if (error) {
              dispatch({ type: RELOAD_COGNITO_USER_ERROR });
              toast.error(t('toast_errorReloading'));
              console.log(error);
              return;
            }

            const rolesAttribute: ICognitoUserAttributeData | undefined = (attributes || []).find((attribute: ICognitoUserAttributeData) => attribute.Name === "custom:roles");

            if (rolesAttribute === undefined) {
              dispatch({ type: RELOAD_COGNITO_USER_ERROR });
              toast.error(t('toast_errorReloadingRol'));
              console.log('User roles are not on cognito user.');
              return;
            }

            const oldUser = JSON.parse(localStorage.getItem('user') as string);
            oldUser.attributes["custom:roles"] = rolesAttribute.Value;
            localStorage.setItem('user', JSON.stringify(oldUser));

            dispatch({
              type: RELOAD_COGNITO_USER_SUCCESS,
              payload: (JSON.parse(rolesAttribute.Value) || []).flat(),
            });

          })
        })
        .catch(error => {
          toast.error(t('toast_errorReloadingUser') + error);
          dispatch({ type: RELOAD_COGNITO_USER_ERROR });
        });
    });

export const changePassword =
  (
    oldPassword: string,
    newPassword: string
  ): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve) => {
      dispatch({ type: START_CHANGE_PASSWORD });
      const {t} = i18n;
      Auth.currentAuthenticatedUser()
        .then(user => Auth.changePassword(user, oldPassword, newPassword))
        .then(data => {
          toast.success(t('toast_passwordChanged'));
          dispatch({ type: CHANGE_PASSWORD_SUCCESS });
          resolve();
        })
        .catch(error => {
          toast.error(t('toast_errorCheckCurrent'));
          dispatch({ type: CHANGE_PASSWORD_ERROR });
        });
    });

export const getUsers =
  (_token?: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_GET_USERS });
        
        Backend(
          MicroServices.Admin,
          `/users`)
          .then((response) => response.json())
          .then((results) => {
            dispatch({
              type: GET_USERS_SUCCESS,
              payload: results.users,
            });
          })
          .catch((error) => {
            dispatch({
              type: GET_USERS_ERROR,
              payload: error,
            });
          });
      });

export const getUsersStatus =
  (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_USER_STATUS });
        Backend(
          MicroServices.Roster,
          `/users/status`)
          .then((response) => response.json())
          .then((results) => {
            dispatch({
              type: USER_STATUS_SUCCESS,
              payload: results.userStatus,
            });
          })
          .catch((error) => {
            dispatch({
              type: USER_STATUS_ERROR,
              payload: error,
            });
          });
      });

export const getUsersRoles =
  (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_USER_ROLES });
        Backend(
          MicroServices.Roster,
          `/users/roles`)
          .then((response) => response.json())
          .then((results) => {
            dispatch({
              type: USER_ROLES_SUCCESS,
              payload: results.userRoles,
            });
          })
          .catch((error) => {
            dispatch({
              type: USER_ROLES_ERROR,
              payload: error,
            });
          });
      });

export const getUsersWithLimit =
  ({
    page = 0,
    id = '',
    role = '',
    org = '',
    country = '',
    city = '',
    status = '',
    date = 'ASC',
    roleRequest = '',
    lastEvaluatedKey = '',
    firstname = '',
    lastname = '',
    email = '',
    limit = 10000,
  }: getUserParams): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        let params = '';
        params += page === 0 ? '' : `&lastEvaluatedKey=${lastEvaluatedKey}`;
        params += id === '' ? '' : `&id=${id}`;
        params += role === '' ? '' : `&role=${role}`;
        params += org === '' ? '' : `&org=${org}`;
        params += country === '' ? '' : `&country=${country}`;
        params += city === '' ? '' : `&city=${city}`;
        params += status === '' ? '' : `&status=${status}`;
        params += firstname === '' ? '' : `&firstname=${firstname}`;
        params += lastname === '' ? '' : `&lastname=${lastname}`;
        params += email === '' ? '' : `&email=${email}`;
        params += `&date=${date}`;
        params +=
          roleRequest === 'OA' || roleRequest === 'SA'
            ? `&roleRequest=${roleRequest}`
            : '';
        dispatch({ type: START_GET_USERS_LIMIT });
        Backend(
          MicroServices.Admin,
          `/users?${params}`)
          .then((response) => response.json())
          .then((results) => {
            dispatch({
              type: GET_USERS_LIMIT_SUCCESS,
              payload: {
                users: results.users,
                lastEvaluatedKey: results.lastEvaluatedKey,
                page,
              },
            });
            resolve(results.users)
          })
          .catch((error) => {
            dispatch({
              type: GET_USERS_LIMIT_ERROR,
              payload: error,
            });
          });
      });

export const getUser =
  (
    id: string
  ): ThunkAction<Promise<UserResponse | undefined>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_USER });
        Backend(
          MicroServices.Admin,
          `/users/${id}?roleRequest=OA`)
          .then((response) => response.json())
          .then((results) => {
            dispatch({
              type: USER_SUCCESS,
              payload: results,
            });

            resolve(results);
          })
          .catch((error) => {
            dispatch({
              type: USER_ERROR,
              payload: error,
            });
          });
      });

export const updateUser =
  (id: string, form: any, roleRequest = 'SA'): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_UPDATE_USER });
        Backend(
          MicroServices.Admin,
          `/users/${id}?roleRequest=${roleRequest}`, {
          method: 'PUT',
          body: JSON.stringify(form),
        })
          .then((response) => response.json())
          .then((results) => {
            dispatch({
              type: UPDATE_USER_SUCCESS,
              payload: results,
            });
          })
          .catch((error) => {
            dispatch({
              type: UPDATE_USER_ERROR,
              payload: error,
            });
          });
      });

export const getUserInf =
  (
    id: string,
    org_id: string
  ): ThunkAction<Promise<UserResponse | undefined>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_USER_INFO });

        Backend(
          MicroServices.Admin,
          `/users?email=${id}&org=${org_id}`)
          .then((response) => response.json())
          .then((results) => {
            dispatch({
              type: USER_INFO_SUCCESS,
              payload: results,
            });

            resolve(results);
          })
          .catch((error) => {
            dispatch({
              type: USER_INFO_ERROR,
              payload: error,
            });
          });
      });

export const uploadAsset = (contentType: string, key: string, type: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_UPLOAD_ASSET_PROFILE_PHOTO });
    Backend(
      MicroServices.Roster,
      `/media/upload-asset`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "ContentType": contentType,
          "Key": key,
          "Type": type
        }),
      },
    )
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: UPLOAD_ASSET_PROFILE_PHOTO_SUCCESS,
          payload: results,
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
          type: UPLOAD_ASSET_PROFILE_PHOTO_ERROR,
          payload: error,
        });
        reject(error)
      })
  });

export const uploadImage = (url: string, contentType: string, image: any): ThunkAction<Promise<Response>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_UPLOAD_PROFILE_PHOTO });
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': decodeURIComponent(contentType)
      },
      body: image
    })
      .then((results) => {
        dispatch({
          type: UPLOAD_PROFILE_PHOTO_SUCCESS,
          payload: results,
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
          type: UPLOAD_PROFILE_PHOTO_ERROR,
          payload: error,
        });
        reject(error)
      })
  });

export const updateProfilePhoto = (image: string, username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_UPDATE_PROFILE_PHOTO });
    Backend(
      MicroServices.Admin,
      `/users/${username}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          "avatar": image,
        }),
      },
    )
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: UPDATE_PROFILE_PHOTO_SUCCESS,
          payload: results,
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_PROFILE_PHOTO_ERROR,
          payload: error,
        });
        reject(error)
      })
  });

export const saveUserDepartments = (username: string, departments: string[]): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_SAVE_DEPARTMENTS });
    Backend(
      MicroServices.Connections,
      `/profile/departments`,
      {
        method: 'POST',
        body: JSON.stringify({
          username: username,
          departments: departments
        }),
      },
    )
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: SAVE_DEPARTMENTS_SUCCESS,
          payload: departments,
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
          type: SAVE_DEPARTMENTS_ERROR,
          payload: error,
        });
        reject(error)
      })
  });

export const editUser = (username: string, roleRequest: 'OA' | 'SA', edits: any): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_EDIT_USER });
    Backend(
      MicroServices.Admin,
      `/users/${username}?roleRequest=${roleRequest}`,
      {
        method: 'PUT',
        body: JSON.stringify(edits),
      },
    )
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: EDIT_USER_SUCCESS
        })
      })
      .catch((error) => {
        dispatch({
          type: EDIT_USER_ERROR,
          payload: error,
        });
        reject(error)
      })
  });