import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ModalImageEditor from "../ModalImageEditor";
import "./styles.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUser, updateProfilePhoto, uploadAsset, uploadImage } from "../../store/actions/userActions";
import { useEffect, useState } from "react";
import GeneralInfo from "../MyAccount/GeneralInfo";
import Preferences from "../MyAccount/Preferences";
import Education from "../MyAccount/Education";
import AccountInfo from "../MyAccount/AccountInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER, WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";

toast.configure();

function ProfileSettings(props: any) {
    const { t, i18n } = useTranslation();
    const { username } = useParams();
    const dispatch = useDispatch();

    const userInfo = useSelector((state: any) => state.getUser.userInfo);
    const userAll = useSelector((state: any) => state.getUser.user);

    const [showModal, setShowModal] = useState(false);
    const [user, setUser] = useState(userAll);
    const [userProfile, setUserProfile] = useState('');
    const [selected, setSelected] = useState("General");
    const [screen, setScreen] = useState("General");

    useEffect(() => {
        if (!userAll) {
            dispatch<any>(getUser(userInfo.username))
                .then((results: any) => {
                    setUser(results);
                    setUserProfile(results.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png');
                });
        }
        else {
            setUser(userAll);
            setUserProfile(userAll.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png');
        }
    }, [username]);

    function handleSaveImage(e: any) {

        fetch(e)
            .then(res => res.blob())
            .then(blob => {
                const contentType = e.split(';')[0].split(':')[1];
                const extension = contentType.split('/')[1]
                const file = new File([blob], 'profile' + userInfo.username, { type: contentType });

                if (file) {
                    dispatch<any>(uploadAsset(
                        file.type,
                        file.name + '.' + extension,
                        "profile_photo"
                    ))
                        .then((results: any) => {
                            if (results.body) {
                                dispatch<any>(uploadImage(
                                    results.body,
                                    contentType,
                                    file,
                                ))
                                    .then((result: any) => {
                                        if (result.status === 200) {
                                            const urlImage = results.body.split('?')[0];
                                            setUserProfile(urlImage);
                                            dispatch<any>(updateProfilePhoto(
                                                urlImage,
                                                userInfo.username,
                                            ))
                                                .then((result: any) => {
                                                    if (result.status === 200) {
                                                        setUserProfile(urlImage);
                                                        setShowModal(false);
                                                    }
                                                    else {
                                                        setShowModal(false);
                                                    }
                                                })
                                                .catch((error: any) => {
                                                    console.log(error)
                                                })
                                        }
                                    })
                                    .catch((error: any) => {
                                        console.log(error)
                                    })

                            }
                            else {
                                toast.error(t('toast_errorImageUrl'));
                            }
                        })
                        .catch((error: any) => {
                            console.log(error)
                        })
                }
                else {
                    toast.error(t('toast_addFile'));
                }

            })
    };

    const closeModal = (e?: any, type?: any) => {
        setShowModal(false);
        console.log('closed modal edit profile photo -> Profile');
    }

    function backInfo(){
        props.backInfo(true)
    }

    function selectScreen(screen: string) {

        switch (screen) {
          case "General":
            return <GeneralInfo user={userAll} backInfo={backInfo}/>;
            break;
          case "Account":
            return <AccountInfo user={userAll}/>
            break;
          case "Work":
            return <Education />;
            break;
          case "Preferences":
            return <Preferences user={userAll}/>;
            break;
          default:
            return <></>;
            break;
        }
      }

    return (
        <>
            {/*<div className="infoSettings">
                <div className='imageSettings'>
                    {(showModal) && (<ModalImageEditor handleSaveImage={handleSaveImage} show={showModal} onHide={closeModal} isProfilePhoto={true} />)}
                    <img src={userProfile !== '' ? userProfile : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'} alt="" onClick={() => setShowModal(true)} />
                </div>

                <div>
                    <p className="nameSettings">{user.firstname} {user.lastname}</p>
                    <ul>
                        {user.organizationsNames.map((request: any) => (
                            <li className='listOrgsSettings'>{request}</li>
                        ))}
                    </ul>
                </div>
            </div>*/}

            <div className="icons-navbar upper">

                <div
                    className={
                        selected === "General" ? "icons-column-selected" : "icons-column"
                    }
                    onClick={() => {
                        setSelected("General");
                        setScreen("General");
                    }}
                >
                    <FontAwesomeIcon
                        icon="user"
                        size='sm'
                        color={
                        selected === "General"
                            ? LAVENDER
                            : WHITE
                        }
                    />
                    <div className={
                        selected === "General"
                        ? "icon-label robotocondensed-bold-lavender-16px"
                        : "icon-label robotocondensed-normal-white-16px"
                    }>
                        {t('profile__information')}
                    </div>
                </div>

                <div
                    className={
                        selected === "Account" ? "icons-column-selected" : "icons-column"
                    }
                    onClick={() => {
                        setSelected("Account");
                        setScreen("Account");
                    }}
                >
                    <FontAwesomeIcon
                        icon="lock-alt"
                        size='sm'
                        color={
                        selected === "Account"
                            ? LAVENDER
                            : WHITE
                        }
                    />
                    <div className={
                        selected === "Account"
                        ? "icon-label robotocondensed-bold-lavender-16px"
                        : "icon-label robotocondensed-normal-white-16px"
                    }>
                        {t('profile__account')}
                    </div>
                </div>

                <div
                    className={
                        selected === "Work" ? "icons-column-selected" : "icons-column"
                    }
                    onClick={() => {
                        setSelected("Work");
                        setScreen("Work");
                    }}
                >
                    <FontAwesomeIcon
                        icon="graduation-cap"
                        size='sm'
                        color={
                        selected === "Work"
                            ? LAVENDER
                            : WHITE
                        }
                    />
                    <div className={
                        selected === "Work"
                        ? "icon-label robotocondensed-bold-lavender-16px"
                        : "icon-label robotocondensed-normal-white-16px"
                    }>
                        {t('profile__educationAndWork')}
                    </div>
                </div>

                <div
                    className={
                        selected === "Preferences" ? "icons-column-selected" : "icons-column"
                    }
                    onClick={() => {
                        setSelected("Preferences");
                        setScreen("Preferences");
                    }}
                >
                    <FontAwesomeIcon
                        icon="cog"
                        size='sm'
                        color={
                        selected === "Preferences"
                            ? LAVENDER
                            : WHITE
                        }
                    />
                    <div className={
                        selected === "Preferences"
                        ? "icon-label robotocondensed-bold-lavender-16px"
                        : "icon-label robotocondensed-normal-white-16px"
                    }>
                        {t('profile__preferences')}
                    </div>
                </div>

            </div>
            {selectScreen(screen)}
        </>
    )
}

export default ProfileSettings;
