import React, { useState } from "react";
import "./styles.scoped.css";
import Select, { SingleValue } from "react-select";
import styled from "styled-components";
import {
  RobotocondensedRegularNormalWhite16,
  Fontawesome5proRegularNormalLavende,
} from "../../styledMixins";
import SchoolDatabaseTable from "../SchoolDatabaseTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const PAGE_SIZES: pageSize[] = [10, 50];
export type pageSize = 10 | 50 | 10000;

function SchoolDatabase() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<pageSize>(PAGE_SIZES[0]);

  const viewingOptions = PAGE_SIZES.map((item) => ({
    label: `${item} results`,
    value: item,
  }));

  const statusOptions = [
    { value: "", label: "All" },
  ];

  const handleSetPageSize = (
    newValue: SingleValue<{ label: string; value: pageSize }>
  ) => {
    setPageSize(newValue?.value || 10);
  };

  const customStyles = () => ({
    option: (styles: any) => ({
      ...styles,
      color: "white",
      backgroundColor: "#383838",
    }),
    input: (styles: any, state: any) => ({
      ...styles,
      border: "none",
      color: "white",
      fontWeight: "normal",
      paddingLeft: "5px",
      "&:focus": { backgroundColor: "red" },
    }),
    container: (styles: any) => ({
      ...styles,
      padding: 0,
      backgroundColor: "#281159",
      borderRadius: 5,
    }),
    valueContainer: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: "transparent",
      padding: 0,
    }),
    menu: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      backgroundColor: "#383838",
      textAlign: "left",
      width: "auto",
      padding: "5px 10px",
      minWidth: "150px",
      fontFamily: "var(--font-family-roboto_condensed)",
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      textAlign: "left",
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "118px",
      borderRadius: "0px",
      boxShadow: "none",
      border: 0,
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "bold",
      maxWidth: "100%",
      backgroundColor: "transparent",
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: "white",
      textDecoration: "underline",
      display: "block",
    }),
    indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
    dropdownIndicator: (styles: any) => ({ ...styles, display: "none" }),
    noOptionsMessage: (styles: any) => ({
      ...styles,
      color: "white",
      backgroundColor: "grey",
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "normal",
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: "white",
      backgroundColor: "transparent",
      border: "none",
      paddingLeft: "5px",
      paddingRight: "5px",
    }),
  });

  return (
    <div className="contentSchoolDatabase">
      <div className="title-row">
        <h1>Schools</h1>
        <div className="button-p-schools">
          <div
            className="roboto-normal-white-16px"
          // onClick={() => navigate("./create")}
          >
            Create School
          </div>
        </div>
      </div>
      <div className="headerInvites">
        <div className="show-view-row">
          <Viewing>
            <Viewing1>Viewing</Viewing1>
            <Box>
              <Select
                value={{ value: pageSize, label: `${pageSize} results` }}
                isMulti={false}
                options={viewingOptions}
                placeholder={"View"}
                styles={customStyles()}
                onChange={handleSetPageSize}
              />
              <ChevronDown />
            </Box>
          </Viewing>
        </div>
      </div>
      <SchoolDatabaseTable />
    </div>
  );
}


const ShowingItems = styled.p`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  align-self: flex-start;
  margin-top: 5px;
  letter-spacing: 0;
  align-self: center;
  text-align: center;
`;

const Viewing = styled.div`
  margin-left: 3px;
  display: flex;
  align-items: center;
  min-width: 197px;
  align-self: flex-end;
  font-family: var(--font-family-roboto_condensed);
`;

const Viewing1 = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  min-width: 50px;
  letter-spacing: 0;
`;

const Box = styled.div`
  height: 40px;
  margin-left: 17px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  min-width: 130px;
  background-color: var(--violent-violet);
`;

const ChevronDownWrapper = styled.div`
  ${Fontawesome5proRegularNormalLavende}
  min-height: 20px;
  margin-left: 27px;
  min-width: 18px;
  letter-spacing: 0;
`;

const ChevronDown = () => <ChevronDownWrapper>
  <FontAwesomeIcon icon="chevron-down" color="#FFFFFF" size="sm"/>
</ChevronDownWrapper>

export default SchoolDatabase;
