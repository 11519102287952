import React, { useEffect, useMemo, useState } from "react";
import "./styles.scoped.css";
import PostText from './PostText';
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";

interface PostBirthdayProps {
  post: {
    birthayTo: string;
    messages: any[];
    user: any;
  },
  reloadPosts: () => any,
  emoji:any,
  ChatServiceInstance:ChatServiceInterface
}



const PostBirthday = ({ post, reloadPosts, emoji, ChatServiceInstance }: PostBirthdayProps) => {
  console.log(emoji)
  const topMessage = useMemo(() => {
    const people = post.messages.map((message: any) => message.infoPost.author_name);
    const peopleClean = [...new Set(people)]
    if (peopleClean.length === 0) return '';
    if (peopleClean.length === 1) {
      return `${peopleClean[0]} has congratulated ${post.user.firstname} on ${post.user.gender === 'F' ? 'her' : 'his'} birthday!`
    }
    if (peopleClean.length === 2) {
      return `${peopleClean[0]} and ${peopleClean[1]} have congratulated ${post.user.firstname} on ${post.user.gender === 'F' ? 'her' : 'his'} birthday!`
    }
    return `${peopleClean[0]}, ${peopleClean[1]} and others have congratulated ${post.user.firstname} on ${post.user.gender === 'F' ? 'her' : 'his'} birthday!`
  }, [post])
  return (
    <div className="itemPost">
      <div className="">
        <div className="infoPostHeader">
          <div className="infoProfile">{topMessage}</div>
        </div>
        <div className="infoPost">
          {post.messages.map((p: any) => <PostText post={p} reloadPosts={reloadPosts} emoji={emoji[p.id]} ChatServiceInstance={ChatServiceInstance} listAdv={undefined} reasons={undefined} activeComments={undefined}/>)}
        </div>
      </div>
    </div>
  );
};

export default PostBirthday;
