import React, { useEffect, useMemo, useState } from "react";
import SetupTeamsCategories from "./SetupTeamsCategories";
import "../../containers/SetupSchool/styles.scoped.css";
import ListTeamsSetup from './ListTeamsSetup';
import { Team } from "./ModalTeam";
import { SelectedTeams } from "../../containers/SetupSchool";
import { ClubCreate } from "./Clubs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../ClubIconsNavBar";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface SetupTeamsProps {
  selectedTeams: SelectedTeams;
  handleOnClickClub: (e: any, title: any, team: Team, type: string) => void,
  teams:ClubCreate[],
  dynamoClubs:any[],
  teamsDelete:any,
  setCreateCustom: any,
}

const SetupTeams = ({ selectedTeams, handleOnClickClub, teams, dynamoClubs, teamsDelete, setCreateCustom}: SetupTeamsProps) => {
  const [activeTeam, setActiveTeam] = useState<number>(0);

  const tteams = useSelector((state: RootState) => state.getTClubs.TTeam);
  
  const selectedList = useMemo(
    () => Object.keys(selectedTeams).filter((template_id)=>tteams.find((tt)=>tt.id === template_id) !== undefined),
    [selectedTeams]
  );

  const activateTeam = (idx: number) => {
    setActiveTeam(idx);
  };

  return (
    <div className="listclubs">
      <SetupTeamsCategories
        activeTeam={activeTeam}
        selectedList={selectedList}
        activateTeam={activateTeam}
        setCreateCustom={setCreateCustom}
      />
      <div className="listClub">
        {selectedList.map((template_id: string, index: number) => (
          <div
            className={`${
              activeTeam == index ? "active" : ""
            } listTeamsSelected`}
          >
            {/* <div className="custom-club-button" onClick={() => setCreateCustom(true)}>
              <div className="custom-club-text">Custom Team</div>
              <FontAwesomeIcon
                icon="plus"
                size='lg'
                color={WHITE}
                className=" "
              />
            </div> */}
            {selectedTeams[template_id].map((item) => (
              <ListTeamsSetup
                teamsDelete={teamsDelete}
                teams={teams} 
                dynamoClubs={dynamoClubs}
                handleOnClickClub={handleOnClickClub}
                title={item.title}
                team={item}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SetupTeams;
