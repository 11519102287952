import React, { useEffect, useState } from "react";
import "./styles.scoped.css";
import { useNavigate } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { useSelector } from "react-redux";
import ModalReportPost from "./ModalReportPost";
import ListEmojis, { emojiColor, reactionToEmoji } from "./ListEmojis";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from "../../components/ClubIconsNavBar";
import { useTranslation } from "react-i18next";

import { Backend, MicroServices } from "../../helpers/backendHelper";
toast.configure();

function Index(props: any) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const userRoles = useSelector((state: any) => state.getUser.userRoles);

  const [selectedItemId, setSelectedItemId] = useState(0);
  const [selectedItemState, setSelectedItemState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [spinnerFlag, setSpinnerFlag] = useState(false);

  function handleCloseModal(data: any, type: any, reason: any) {
    if (type === "save") {
      setSpinnerFlag(true);

      Backend(
        MicroServices.Posts,
        `/flag`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          usernamePoster: data.author,
          postId: data.post_id,
          usernameFlagger: userInfo.username,
          reach: data.reach,
          reachId: data.reach === "WALL" ? data.author : data.reach_id,
          reason: reason,
        }),
      })
        .then((response) => {
          toast.success(t('toast_postFlagged'));
          props.reloadPosts();
          setShowModal(false);
          setSpinnerFlag(false);
        })
        .catch(console.log);
    } else {
      setShowModal(false);
    }
  }

  function savePost(postInfo: any) {
    Backend(
      MicroServices.Posts,
      `/post/save?usernamePoster=${postInfo.author}&postId=${postInfo.post_id
      }&usernameSaver=${userInfo.username}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          toast.success(t('toast_postSaved'));
          props.reloadPosts();
        }
      })
      .catch(console.log);
    setSelectedItemState(false);
  }

  const toggleClick = (id: any) => {
    if (id === selectedItemId) {
      setSelectedItemState(!selectedItemState);
    } else {
      setSelectedItemId(id);
      setSelectedItemState(true);
    }
  };

  function flagPost(postInfo: any) {
    Backend(
      MicroServices.Posts,
      `/flag`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        usernamePoster: postInfo.author,
        postId: postInfo.post_id,
        usernameFlagger: userInfo.username,
        reach: postInfo.reach,
        reachId: postInfo.reach_id,
      }),
    })
      .then((response) => { })
      .catch(console.log);
  }

  const timestampToDayAndHour = (eventStart: string) => {
    const [year, month, day, hour, seconds] = eventStart.split("-");
    //2022-07-02-15-33
    return [`${year}/${month}/${day}`, `${hour}:${seconds}`];
  };

  const [calendar, clock] = timestampToDayAndHour(
    props.event.eventTimestamp || "0-0-0-0-0"
  );
  // console.log(
  //   `event id: ${props.event.id}, calendar: ${calendar}, clock: ${clock}`
  // );

  const promotePost = (postInfo: any) => {

    Backend(
      MicroServices.Posts,
      `/posts/promote?postId=${postInfo.post_id}`, {
      method: "POST"
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(t('toast_promotePost'));
          props.reloadPosts();
        }
        else {
          toast.error(t('toast_error'));
        }
      })
      .catch((error) => {
        toast.error(t('toast_errorTryAgain'))
        console.error(error)
      });
  }

  return (
    <div className="itemPost">
      {/*onClick={() => navigate(`/post/${props.event.id}`)}*/}
      <div className="titlePost">
        <h2
          className="pointer"
          onClick={() => navigate("../events/" + props.event.infoPost.eventId)}
        >
          {props.event.eventName}
        </h2>
        <div className="infoEvent">
          <div className="infoDetail">
            <FontAwesomeIcon
              icon="calendar"
              size="lg"
              color={WHITE}
              className="iconReaction"
            />
            {calendar}
          </div>{" "}
          {/*props.event.day*/}
          <div className="infoDetail">
            <FontAwesomeIcon
              icon="clock"
              size="lg"
              color={WHITE}
              className="iconReaction"
            />
            {clock}
          </div>{" "}
          {/*props.event.day*/}
          <div className="infoDetail">
            <FontAwesomeIcon
              icon="map-marker-alt"
              size="lg"
              color={WHITE}
              className="iconReaction"
            />
            {props.event.eventLocation}
          </div>{" "}
          {/*props.event.day*/}
        </div>
        <button className="btnBots" onClick={() => toggleClick(props.event.id)}>
          <FontAwesomeIcon
            icon="ellipsis-v"
            size="lg"
            color={WHITE}
            className=" "
          />
        </button>

        <OutsideClickHandler
          onOutsideClick={() => {
            setSelectedItemState(false);
          }}
        >
          <div
            className={`${props.event.id === selectedItemId && selectedItemState
              ? "active"
              : ""
              } listOptionEvents`}
            key={props.event.id}
          >
            <div className="titleOptions">
              {" "}
              <FontAwesomeIcon
                icon="arrow-left"
                size="lg"
                color={WHITE}
                className="iconOptions  "
              />{" "}
              {t('options__whatWouldYou')}
            </div>
            <ul className="listOptionsPost">
              <li onClick={() => savePost(props.event.infoPost)}>
                <FontAwesomeIcon
                  icon="bookmark"
                  size="lg"
                  color={WHITE}
                  className="iconOptions  "
                />
                {t('button__save')}
              </li>
              {/*<li><FontAwesomeIcon
  icon="bell"
  size='lg'
  color={WHITE}
  className="iconOptions  "
/>Turn on notifications for this post</li>*/}
              {/* <li><FontAwesomeIcon
  icon="share"
  size='lg'
  color={WHITE}
  className="iconOptions  "
/>Share</li> */}
              {/*<li><img className="aha-logo" src={"/aHa-logo_w.png"} alt="aha-logo" />Send aHa</li>*/}
              <li onClick={() => setShowModal(true)}>
                <FontAwesomeIcon
                  icon="flag"
                  size="lg"
                  color={WHITE}
                  className="iconOptions  "
                />
                {t('options__reportThisPost')}
              </li>
              {/* <li><FontAwesomeIcon
  icon="user-minus"
  size='lg'
  color={WHITE}
  className="iconOptions  "
/>Unfriend</li> */}
              {/*((
                userRoles.includes("ZAA#ORGADMIN") ||
                (userRoles.includes("ZAA#STAFF#ADVISOR") && props.listAdv.findIndex((e: any) => e.id === props.event.infoPost.reach_id) !== -1)) &&
                props.event.infoPost.reach_name != "School Bulletin") &&
                <li onClick={() => promotePost(props.event.infoPost)}>
                  <FontAwesomeIcon
                    icon="megaphone"
                    size='lg'
                    color={WHITE}
                    className="iconOptions"
                  />{t('options__promoteSchool')}
                </li>
              *//*Disabled promote post for event posts*/}
            </ul>
          </div>
        </OutsideClickHandler>
        <ModalReportPost
          reasons={props.reasons}
          show={showModal}
          onHide={handleCloseModal}
          id={props.event.infoPost}
          spinnerFlag={spinnerFlag}
        />
      </div>
      <div className="filePost">
        <img
          className="event-cover-img"
          src={props.event.eventCover}
          alt="No Cover Image"
        />
        {/*onClick={() => navigate('../events/' + props.event.id)}*/}

        {!props.event.infoPost.dependent && <>
          {props.event.eventCover ? (
            <div className="btnReactions">
              <button className="iconReaction emoji pointer">
                <FontAwesomeIcon
                  icon={["fas", reactionToEmoji(props.emoji.emojis.reaction)]}
                  size="lg"
                  color={emojiColor(props.emoji.emojis.reaction)}
                  className="iconReaction"
                />
                {props.emoji.emojis.count}
              </button>
              <ListEmojis post_id={props.event.id} manageEmoji={props.emoji.manageEmoji} />
              <button className="comments pointer" onClick={() => props.activeComments(props.event.id)}>
                <FontAwesomeIcon
                  icon="comment"
                  size="lg"
                  color={WHITE}
                  className="iconReaction"
                />
                {props.event.comments}
              </button>
              {/*<img className="aha-logo-post" src={"/aHa-logo_w.png"} alt="aha-logo">
                          </img>*/}
            </div>
          ) : (
            <div className="btnReactions-horizontal">
              <div className="btnParentGridOnly2">
                <div className="btnGrid1Only2">
                  <FontAwesomeIcon
                    icon={["fas", reactionToEmoji(props.emoji.emojis.reaction)]}
                    size="lg"
                    color={emojiColor(props.emoji.emojis.reaction)}
                    className="iconReaction pointer"
                  />
                  <ListEmojis
                    post_id={props.event.id}
                    manageEmoji={props.emoji.manageEmoji}
                  />
                  <div className="commentsText">{props.emoji.emojis.count}</div>
                </div>

                <div
                  onClick={() => props.activeComments(props.event.id)}
                  className="btnGrid2Only2 naisudg"
                >
                  <FontAwesomeIcon
                    icon="comment"
                    size="lg"
                    color={WHITE}
                    className="pointer  "
                  />
                  <div className="commentsText">{props.event.comments}</div>
                </div>

                {/*<div className="btnGrid3">
                                  <img className="aha-logo-text-post-event" src={"/aHa-logo_w.png"} alt="aha-logo">
                                  </img>
                              </div>*/}
              </div>
            </div>
          )}
        </>}
      </div>
      <div className="infoPost">
        <div className="infoPostHeader eventInfoPostHeader">
          <div className="contImage">
            <img
              className="round-avatar"
              src={
                props.event.imageProfile ||
                "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
              }
              alt=""
            />
          </div>
          <div
            className="infoProfile pointer"
            onClick={() => navigate("../connections/" + props.event.author)}
          >
            {props.event.nameProfile}
          </div>
          <span className="infoWhere">{props.event.eventName}</span>
        </div>
        <p
          className="textPost event-textPost"
          dangerouslySetInnerHTML={{ __html: props.event.textPost }}
        >
          {/*onClick={() => navigate(`/post/${props.event.id}`)}*/}
        </p>
        {/*<button className="star">*/}
        {/*    <FontAwesomeIcon
icon="star"
size='lg'
color={WHITE}
className=" "
/>*/}
        {/*</button>*/}
      </div>
    </div>
  );
}

export default Index;
