import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './styles.scoped.css';

const SearchTable = ({
  onChange,
  placeholder,
}:{
  onChange: (e: any) => any;
  placeholder: string;
}) => (
  <div className="searchWrapper">
    <FontAwesomeIcon icon='search' color='#fff'/>
    <input
      className="searchInput"
      onChange={onChange}
      type="text"
      placeholder={placeholder}
    />
  </div>
)

export default SearchTable;