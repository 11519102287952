/* eslint-disable react/require-default-props */
import React from 'react';
import '../../containers/SetupStudent/styles.scoped.css';
import { useTranslation } from "react-i18next";

function Index({
  text,
  subtext,
  step,
}: {
  text?: string;
  subtext?: string;
  step?: number;
}) {
  const { t, i18n } = useTranslation();
  return (
    <div className="HeaderSetupStudent">
      <div className="texts">
        <h1>Zaahah</h1>
        <h3>{text}</h3>
        <h4>{subtext}</h4>
      </div>
      <div className="textStep">{step ? `${t('setupStudent__step')} ${step}` : t('setupStudent__review')}</div>
    </div>
  );
}

export default Index;
