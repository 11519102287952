import { useState } from "react";
import styled from "styled-components";
import { maxCharacters } from "./ChatUser";

const ReadMore = ({content}:{content: string}) => {

    const [isReadMore, setIsReadMore] = useState(true);

    return (
        <Text>
            {isReadMore ? content.slice(0, maxCharacters): content}
            <Reading 
                onClick={() => setIsReadMore(!isReadMore)} 
                className="read-or-hide"
            >
                {isReadMore ? " ... read more" : "  show less"}
            </Reading>
        </Text>
    );
};

export default ReadMore;

const Text = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    text-align: left;
    display: inline;
    width: 100%;
`;

const Reading = styled.span`
    color: var(--lavender);
    font-weight: 700;
    cursor: pointer;
`;