import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TCListsDropdown from "../../../../components/TClubs/TCList/TCListsDropdown";
import TCListSearch from "../../../../components/TClubs/TCList/TCListSearch";
import TableAdmin from "../../../../components/TableAdmin";
import TClub from "../../../../interfaces/TClubs/TClub/TClub";
import useTClubsSA from "../../../../hooks/tclub/useTClubsSA";
import { LAVENDER } from "../../../../components/ClubIconsNavBar";
import {
    MainWrapper,
    ContentWrapper,
    Header,
    Title,
    Row,
    RowGap,
    CreateBtn,
    ActionWrapper,
    ClickableIcon,
    PhantomElement,
    RowText,
    HeaderSubTitle,
    HeaderText,
    TitleNested,
} from '../../components';
import TCList from "../../../../interfaces/TClubs/TCList/TCList";
import { getTCList } from "../../../../helpers/TClubs/TCList";
import AssignModal from "./AssignModal";
import TClubAssignedComplete from "../../../../interfaces/TClubs/TCList/TClubAssignedComplete";
import ModalDeleteTClub from "../../../../components/TemplateClubsTable/ModalDeleteTemplateClub";

function TCListDetail() {
    const {id} = useParams();
    const navigate = useNavigate();
    const { tclubPages, reloadTClubs } = useTClubsSA(100);

    const [tclist, setTclist] = useState<TCList>();
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [tclubSelected, setTclubSelected] = useState<TClubAssignedComplete>()
    const [TCListSearchTerm, setTCListSearchTerm] = useState('');

    const [toDeleteTClub, settoDeleteTClub] = useState<TClub>();
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const filteredTClubs = useMemo(
        () =>
            tclubPages[0]
                .filter((tclub) => tclub.name.toLowerCase().includes(TCListSearchTerm.toLowerCase())),
        [tclubPages[0], TCListSearchTerm]
    )

    const reloadTCList = useCallback(
      () => {
        if (id) {
            getTCList(id)
                .then((list) => setTclist(list))
                .catch((error) => {navigate(-1)}) // handle error
        }
      },
      [id],
    )

    useEffect(() => reloadTCList(), [id]);

    const openModal = (tclub: TClubAssignedComplete) => {
        setTclubSelected(tclub);
        setShowAssignModal(true);
    }

    const onHideAssignModal = () => {
        setShowAssignModal(false);
        reloadTCList();
    }

    return (
        <>
            <MainWrapper>
                <Header>
                    <Row>
                        <TitleNested onClickBack={() => navigate('/superadmin/tclubs')} key={tclist?.name || ''}>{tclist ? tclist.name : ''}</TitleNested>
                    </Row>
                    <RowText>
                        <HeaderSubTitle>Assign Template Clubs to "{tclist ? tclist.name : ''}"</HeaderSubTitle>
                        <HeaderText>
                            From the default template clubs, you must select and assign club to appear the new list. You can edit the name. If a template club is not found, you may add a new interest.
                        </HeaderText>
                    </RowText>
                    <RowGap>
                        <PhantomElement />
                        <TCListSearch searchTerm={TCListSearchTerm} setSearchTerm={setTCListSearchTerm}/>
                    </RowGap>
                </Header>
                <ContentWrapper>
                    <TableAdmin
                        headers={[
                            {
                                label: 'Template Club',
                            },
                            {
                                label: 'Assigned name',
                            },
                            {
                                label: 'Tags',
                            },
                            {
                                label: 'Actions',
                                right: true,
                            },
                        ]}
                        items={filteredTClubs}
                        renderItem={(item: TClub) => ([
                            item.name,
                            (tclist?.tclubs || []).find(({id}) => id === item.id)?.assignedName || '-',
                            item.tags.join('#'),
                            <ActionWrapper>
                                {!(tclist?.tclubs || []).map(({id}) => id).includes(item.id) && (
                                    <ClickableIcon
                                        icon='down-to-bracket'
                                        size='lg'
                                        className="trash-alt"
                                        color={LAVENDER}
                                        onClick={() => openModal(item)}
                                    />
                                )}
                                <ClickableIcon
                                    icon="pen"
                                    size='lg'
                                    className="trash-alt"
                                    color={LAVENDER}
                                    onClick={() => navigate(`../tclubs/${item.id}/update`)}                                />
                                <ClickableIcon
                                    icon="trash-alt"
                                    className="trash-alt"
                                    size="1x"
                                    color={LAVENDER}
                                    onClick={() => {
                                        settoDeleteTClub(item);
                                        setShowDeleteModal(true); //@audit
                                    }}
                                />
                            </ActionWrapper>
                        ])}
                        keyExtractor={({id}: TClub) => id}
                    />
                </ContentWrapper>
            </MainWrapper>

            {/* modals */}
            <AssignModal
                show={showAssignModal}
                tclubSelected={tclubSelected}
                tclist={tclist}
                onHide={onHideAssignModal}
            />
            <ModalDeleteTClub //@audit
                show={showDeleteModal}
                onHide={() => {
                    setShowDeleteModal(false);
                }}
                id={toDeleteTClub?.id}
                title={toDeleteTClub?.name}
                reload={reloadTClubs}
            />
        </>
    );
}

export default TCListDetail;