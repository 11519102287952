import "./styles.scoped.css";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { useTranslation } from "react-i18next";export interface officer {
    username: string;
    position: string;
}

function Index(props: any) {
    const { t, i18n } = useTranslation();

    const [position, setPosition] = useState('');
    const {id} = useParams();

    function handleOnClickCancel() {
        setPosition('');
        props.setShowModal(false)
    }

    function handleOnClickSave() {
        if(position === ''){
            toast.error(t('toast_addPosition'))
            return;
        }
        createOfficer({
            username: props.username,
            position: position
        })
        handleOnClickCancel();
    }

    const createOfficer = (officer: officer) => {
        Backend(
            MicroServices.Club,
            `/clubs/${id || ''}/officer`,
            {
              method: 'POST',
              body: JSON.stringify(officer)
            }
          )
            .then((response: any) =>{
                if(response.status === 201){
                    toast.success(t('toast_userAddedOfficer'));
                    props.reloadClub();
                }
                else{
                    toast.error(t('toast_errorAddOfficer'))
                }
            })
            .catch(console.error)
      };

    return (
        <>
            <Modal className="modalRepostPost" show={props.show} onHide={handleOnClickCancel}>
                <Modal.Header>
                    <h2 className="modal-title">Add Officer</h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="textReport">
                        {`${props.fullname} will be added as Club Officer`}
                        <p>Please add the new officer position</p>
                    </div>
                    <div className='emailOfficerAdd'>
                        Position
                        <div className='inputContainerOfficer'>
                        <input type="text" value={position} onChange={(e) => setPosition(e.target.value)} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="buttonClose" onClick={() => handleOnClickCancel()}>
                        Cancel
                    </Button>
                    <Button className="buttonSend" onClick={() => handleOnClickSave()}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Index;