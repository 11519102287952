import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { WHITE } from "../ClubIconsNavBar";
import { TitleInput, TitleWrapper } from "./components";

interface TitleEditProps {
    title: string;
    setTitle: (title: string) => void;
}

const TitleEdit = ({
    title,
    setTitle,
}: TitleEditProps) => {
    const [updatingTitle, setUpdatingTitle] = useState(false);

    if (updatingTitle) {
        return (
            <TitleInput
                type="text"
                value={title}
                autoFocus
                onBlur={() => setUpdatingTitle(false)}
                onChange={(e) => setTitle(e.target.value)}
            />
        );
    }

    return (
        <TitleWrapper onClick={() => setUpdatingTitle(true)}>
            {title}
            <FontAwesomeIcon
                icon="pen"
                size="sm"
                color={WHITE}
                className="iconReactionLeft"
            />
        </TitleWrapper>
    );
}

export default TitleEdit;