import React from "react";
import styled from "styled-components";

const Table = styled.table`
    width: 100%;
    color: var(--white);
    font-family: var(--font-family-roboto_condensed);
    font-size: var(--font-size-l2);
    font-style: normal;
    border-radius: 5px;

    & > tr:nth-child(even) {
        background: #250d44;
    }
    & > tr:nth-child(odd) {
        background: #2d184c;
    }
`

const Th = styled.th`
    background-image: url(https://anima-uploads.s3.amazonaws.com/projects/621fc5babe91377f45cca168/releases/62275bda4aa2a115d7083fa6/img/header-1@1x.svg);
    background-size: auto;
    background-position: center center;
    padding: 20px 20px 10px 15px !important;
    text-align: left;
    vertical-align: middle;
    font-weight: 700;
`;

const ThRight = styled(Th)`
    text-align: right;
`

const Td = styled.td`
    text-align: left;
    vertical-align: middle;
    padding: 12px 15px;
    font-weight: 400;
`;

interface Header {
    label: string;
    right?: boolean;
}

interface TableAdmin<T> {
    headers: Header[];
    renderItem: (item: T) => React.ReactNode[];
    items: T[];
    keyExtractor: (item: T) => string;
}

const TableAdmin = <T extends unknown>({
    headers,
    renderItem,
    items,
    keyExtractor,
}: TableAdmin<T>) => (
    <Table>
        <tr>
            {headers.map((header) => header.right ? <ThRight>{header.label}</ThRight> : <Th>{header.label}</Th>)}
        </tr>
        {items.map((item) => (
            <tr key={keyExtractor(item)}>
                {renderItem(item).map((cell) => (
                    <Td key={`${keyExtractor(item)}-${cell?.toString()}`}>{cell}</Td>
                ))}
            </tr>
        ))}
    </Table>
);

export default TableAdmin;