import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Connection from "../../../../interfaces/user/Connection";
import { FromName, Time } from "../../ChatCenter/components/components";
import { useTranslation } from "react-i18next";
import { CircleStatusHeader } from "../../../Header";

const ConnectionListOption = ({ connection }: { connection: Connection}) => {
    const { id } = useParams();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    return (
        <ChatListWrapper
            key={connection.usernameConnection}
            onClick={() => navigate(`/chat/conversation/${connection.usernameConnection}`)}
            active={connection.usernameConnection === id}
        >
            <ChatList>
                <ImageContainer>
                    <CircleStatusHeader status={connection.status?.toLowerCase() || 'available'} />
                    <Avatar src={connection.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />
                </ImageContainer>
                <ColumnFlex>
                    <FromName>{`${connection.firstname || ''} ${connection.lastname || ''}`}</FromName>
                    <MutualP>{`${connection.common} ${connection.common === 1 ? t('connections__mutualClub') : t('connections__mutualClubs')}`}</MutualP>
                </ColumnFlex>
            </ChatList>
        </ChatListWrapper>
    );
}

export default ConnectionListOption;

const ChatList = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap:20px;
    margin-right: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const Avatar = styled.img`
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--onyx);
    border-radius: 36px;
    position: relative;
    margin-left: 8px;
`;

const ColumnFlex = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center !important;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const MutualP = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--white);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

interface ChatListWrapperProps {
    active: boolean;
}

const ChatListWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    background: ${(props: ChatListWrapperProps) => props.active ? 'var(--violet)' : 'transparent'};
    border-radius: 60px 0px 0px 60px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    cursor: pointer;
`;