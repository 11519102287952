import { GroupBase, StylesConfig } from "react-select";

export const components = {
  DropdownIndicator: null,
};

export interface Option {
  readonly label: string;
  readonly value: string;
}

export const createOption = (label: string): Option => ({
  label,
  value: label,
});

export const skillsLearnedStyles: StylesConfig<Option, boolean, GroupBase<Option>> = {
  singleValue: base => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    backgroundColor: 'red',
    fontFamily: 'Roboto Condensed',
  }),
  control: styles => ({
    ...styles,
    backgroundColor: '#28115A',
    borderRadius: '3px',
    boxShadow: 'none',
    border: 0,
    minWidth: '50%',
  }),
  option: styles => ({
    ...styles,
    color: 'white',
    backgroundColor: 'var(--violent-violet)',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Roboto Condensed',
  }),
  placeholder: styles => ({
    ...styles,
    color: 'white',
    textAlign: 'left',
    fontFamily: 'Roboto Condensed',
  }),
  container: styles => ({
    ...styles,
    padding: 0,
    width: '100%',
  }),
  menu: styles => ({
    ...styles,
    borderRadius: '8px',
    backgroundColor: 'var(--violent-violet)',
  }),
  input: (styles: any, state: any) => ({
    ...styles,
    border: 'none',
    color: 'white',
    fontFamily: 'Roboto Condensed',
  }),
  dropdownIndicator: styles => ({ ...styles, display: 'none' }),
  indicatorSeparator: (styles: any) => ({ ...styles, display: 'none' }),
  multiValue: styles => ({
    ...styles,
    color: 'white',
    backgroundColor: 'var(--electric-violet-2)',
  }),
  multiValueLabel: styles => ({
    ...styles,
    color: 'white',
    fontFamily: 'Roboto Condensed',
    fontSize: '16px',
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: 'white',
    backgroundColor: 'transparent',
  }),
};