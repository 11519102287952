import "../../styles.scoped.css";
import '../../styles.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WHITE } from '../../../ClubIconsNavBar';
import TClub from "../../../../interfaces/TClubs/TClub/TClub";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../../../helpers/backendHelper";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { getTemplateClubs } from "../../../../store/actions/templateClubActions";

function ModalDeleteClub(props: { show: boolean, setDelete: Dispatch<SetStateAction<TClub | undefined>>, club?: TClub }) {

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const organization = useSelector(
        (state: any) => state.getOrgs.organizationInfo
      );

    const deleteClub = () => {
        if(!props.club){
            props.setDelete(undefined)
            return
        }

        Backend(
            MicroServices.Club,
            `/tclubs/${props.club!.id}`, {
            method: 'DELETE'
        })
            .then((results) => {
                if (results.status === 201) {
                    toast.success(`${props.club?.name} ${t('toast_deleted')}`);
                    props.setDelete(undefined)
                    dispatch(getTemplateClubs(organization.id));
                } else {
                    toast.error(`${t('toast_errorDeleting')} ${props.club?.name}. ${t('toast_tryAgain')}`)
                }
                props.setDelete(undefined)
            })
            .catch((e) => {
                toast.error(`${t('toast_errorDeleting')} ${props.club?.name}. ${t('toast_tryAgain')}`)
                props.setDelete(undefined)
            })
    }
    return (
        <>
            <Modal className="modalDiscardChanges" show={props.show} onHide={() => props.setDelete(undefined)}>
                <Modal.Header>
                    <Modal.Title className="modalDiscardChangesTitle">{`${t('modalDelete__text')} ${props.club?.name}?`}</Modal.Title>
                    <FontAwesomeIcon
                        icon="times"
                        size='xl'
                        color={WHITE}
                        className="icon-times  "
                        onClick={(e) => props.setDelete(undefined)}
                    />
                </Modal.Header>
                <Modal.Footer className='modalDiscardChanges-footer'>
                    <Button className="buttonClose" onClick={(e) => props.setDelete(undefined)}>
                        {t("button__cancel")}
                    </Button>
                    <Button className="buttonSend" onClick={(e) => deleteClub()}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalDeleteClub;