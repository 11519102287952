import { faShare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { DOMAttributes, MouseEventHandler, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Backend, MicroServices } from '../../helpers/backendHelper';
import { capitalize } from '../../helpers/letterHelper';
import { WHITE } from '../ClubIconsNavBar';
import { useTranslation } from "react-i18next";

import "./styles.css"

interface BirthdayTileProps {
  name: string;
  lastname: string;
  avatar: string;
  username: string;
  unregistered?: boolean;
  email: string;
  comm: any;
}

const BirthdayTile = ({name, lastname, avatar, username, unregistered, email, comm}: BirthdayTileProps) => {
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const userAll = useSelector((state: any) => state.getUser.user);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [message, setMessage] = useState('');

  const sendBirthdayProps = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append('author', userInfo.username);
    formData.append('reach', 'BIRTHDAY');
    formData.append('type', 'BIRTHDAY');
    formData.append('org_id', userAll.organizations[0] || '');
    formData.append('content', message);
    formData.append('reach_id', username);

    // let promise: any = () => Promise.resolve({status: 201});
      let promise: any;
    const bodyEmailBirthdayRequest = JSON.stringify({
          message,
          username: userInfo.username,
          emailTo: email,
          nameTo: name,
          nameFrom: `${userAll.firstname} ${userAll.lastname}`
      })
    if (unregistered) {
        promise = () => Backend(
          MicroServices.Posts,
          `/post/email-birthday`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: bodyEmailBirthdayRequest
        })
    } else {
        promise = () => Backend(
          MicroServices.Posts,
          `/post`, {
            method: "POST",
            headers: {
                // 'Content-Type': 'application/json'
            },
            body: formData,
        })

    }
    // else{
    //   toast.success('Notification sent!')
    //   setLoading(false)
    //   return
    // }
    promise()
        // .then((results: Response)=> {
        //     if(results.status !== 201) {
        //         toast.error('There was an error');
        //         return;
        //     }
        // return fetch(`${PostsUrl}/post`, {
        //     method: "POST",
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: formData,
        // })
    // })
      .then((response: Response) => {
        if(response.status === 201) {
          setMessage('');
          toast.success(t('toast_yourRegards'));
        }
        else{
          toast.error(t('toast_ThereWasError'));
        }
        return response.json();
      })
      .catch((error: any) => console.log('error', error))
      .finally(() => setLoading(false));
  }

  return (
    <div className="club-right-container">
      <div className="club-right-title title-birthday">
        {/* It's {name} {lastname}{lastname.slice(-1)==='s'?"'":"'s"} Birthday! */}
        {t('label__birthday')}{name} {lastname}{lastname.slice(-1)==='s'?"'": i18n.language === 'en' && "'s"}{t('label__birthday2')}
        <span className='text-comm'>{(comm)+ ((unregistered) ? ` - ${t('label_unregistered')}` : '')}</span>
      </div>
        <br />
      <div className="club-birthday-row">
        <img
          className="image-birthday"
          src={avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"}
          alt="avatar"
        />
        <textarea
          className="say-something"
          placeholder={t('label__saySomething')}
          value={message}
          rows={1}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button
          onClick={sendBirthdayProps}
          style={{backgroundColor: 'transparent', border: 'none', cursor: 'pointer'}}
          disabled={message.length <= 0}
        >
          {loading ? (
            <div className="icon-spinner-third buttonSendSignUp" />
          ) : (
            <FontAwesomeIcon
              icon={faShare}
              size='xl'
              color={WHITE}
            />
          )}
        </button>
      </div>
    </div>
  );
};

export default BirthdayTile;