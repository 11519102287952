import { useState, useEffect } from "react";
import "../../containers/SetupSchool/styles.scoped.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from "../../components/ClubIconsNavBar";
import styled from "styled-components";
import TClub from "../../interfaces/TClubs/TClub/TClub";
import ListedTClub from "./ListedTClub";
import { ACTION_TYPE, DELETE_TYPE } from "./Clubs";

interface ListClubsProps {
  createCustomClub: () => void;
  tclubs: TClub[];
  dbTClubs: string[];
  readyToCreateTClubs: string[];
  readyToDeleteTClubs: string[];
  handleClickTClub: (type: ACTION_TYPE, tclubId: string) => void;
  handleClickDeleteTClub: (type: DELETE_TYPE, tclubId: string) => void;
}

const ListClubs = ({
  createCustomClub,
  tclubs,
  dbTClubs,
  readyToCreateTClubs,
  readyToDeleteTClubs,
  handleClickTClub,
  handleClickDeleteTClub,
}: ListClubsProps) => {
  // function handleOnClickAddClub(e: any, title: any) {
  //   if (
  //     props.clubsDelete.find((item: string) => item.split("#")[1] === title)
  //   ) {
  //     props.handleOnClickClub(e, title, "ReCreate");
  //   } else {
  //     props.handleOnClickClub(e, title, "Create");
  //   }
  // }

  // function handleOnClickEditClub(e: any, title: any) {
  //   props.handleOnClickClub(e, title, "Edit");
  // }

  // function handleOnClickSaveEditClub(e: any, title: any) {
  //   props.handleOnClickClub(e, title, "SaveEdit");
  // }

  // function handleOnClickDeleteClub(e: any, title: any) {
  //   e.stopPropagation();
  //   props.handleOnClickClub(e, title, "Delete");
  // }

  // function handleOnClickSaveDeleteClub(e: any, title: any) {
  //   e.stopPropagation();
  //   props.handleOnClickClub(e, title, "SaveDelete");
  // }

  return (
    <>
      <div onClick={createCustomClub}>
        <div className="custom-club-text">Custom Club</div>
        <FontAwesomeIcon
            icon="plus"
            size='lg'
            color={WHITE}
        />
      </div>

      {tclubs.map((tclub) => (
        <ListedTClub
          tclub={tclub}
          isInDB={dbTClubs.includes(tclub.id)}
          isReadyToCreate={readyToCreateTClubs.includes(tclub.id)}
          isReadyToDelete={readyToDeleteTClubs.includes(tclub.id)}
          handleClick={(type) => handleClickTClub(type, tclub.id)}
          handleClickDelete={(type) => handleClickDeleteTClub(type, tclub.id)}
        />
      ))}

      {[
        ...Array(
          3 - (tclubs.length % 3 > 0 ? tclubs.length % 3 : 3)
        ),
      ].map(() => <TransparentClub />)}
    </>
  );
}

const TransparentClub = styled.div`
  background-color: #00000000 !important;
`;

export default ListClubs;
