import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import NumericInput from "react-numeric-input";
import "../../../containers/SetupSchool/styles.scoped.css";
import useGender from "../../../hooks/useGender";
import useGrades from "../../../hooks/useGrades";
import OrganizationComplete from "../../../interfaces/organization/OrganizationComplete";
import { WHITE } from "../../ClubIconsNavBar";
import { ClubCreate } from "../Clubs";
import { Advisor } from "../Communities";
import ModalAddAdvisor from "../ModalAddAdvisor";
import { useTranslation } from "react-i18next";
import { faGameConsoleHandheld } from "@fortawesome/pro-regular-svg-icons";
interface InfoMembersTeamProps {
  club: ClubCreate;
  setClub: (club: ClubCreate) => void;
  setShowAdv: (show: boolean) => void;
  organization?: OrganizationComplete;
  staffUsers?: any[];
  updateStaffUsers?: (staff: any[]) => void;
  updateCommInvites?: () => void
  not_from_setup?: boolean;
  createCustom?: boolean
}

function Index({
  club,
  setClub,
  organization,
  setShowAdv,
  staffUsers,
  updateStaffUsers,
  updateCommInvites,
  not_from_setup,
  createCustom,
}: InfoMembersTeamProps) {
  const [showAdvisor, setShowAdvisor] = useState(false);
  const [addedAdvisors, setAddedAdvisors] = useState<string[]>([])

  const { genders, setCountry: setCountryGenders } = useGender();
  const { grades, setCountry: setCountryGrades } = useGrades();

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryGenders(organization.countryCode);
      setCountryGrades(organization.countryCode);
    }
  }, [])

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryGenders(organization.countryCode);
      setCountryGrades(organization.countryCode);
    }
  }, [organization])

  function handleOnClickAdv() {
    setShowAdvisor(true);
  }
  const { t, i18n } = useTranslation();
  const handleCloseAdv = (
    e: any,
    step: any,
    id: any,
    type: any,
    firstName?: string,
    lastName?: string,
    email?: string,
    sendEmail?: boolean
  ) => {

    if (step === 'btnSave') {
      if (firstName && lastName && email) {
        setClub({
          ...club,
          advisors: [...(club.advisors || []), { firstName, lastName, email }]
        })
      }
    }

    setShowAdvisor(false)

  };

  const handleCheckMemberType = (event: any) => {
    let updateList = [...club.member_type];
    if (event.target.checked) {
      updateList = [...club.member_type, event.target.value];
    } else {
      updateList.splice(club.member_type.indexOf(event.target.value), 1);
    }
    setClub({ ...club, member_type: updateList });
  };

  const handleOnChangeNumberMembers = (value: number | null) => {
    setClub({ ...club, maximumNumberMembers: value || 0 });
  };

  const handleOnChangeGenders = (event: any) => {
    let updateList = [...club.gendersAllowed];
    if (event.target.checked) {
      updateList = [...club.gendersAllowed, event.target.value];
    } else {
      updateList.splice(club.gendersAllowed.indexOf(event.target.value), 1);
    }
    setClub({ ...club, gendersAllowed: updateList });
  }

  const handleCheckGradesAllowed = (event: any) => {
    let updateList = [...(club.gradesAllowed || [])];
    if (event.target.checked) {
      updateList = [...(club.gradesAllowed || []), parseInt(event.target.value)];
    } else {
      updateList.splice(
        (club.gradesAllowed || []).indexOf(parseInt(event.target.value)),
        1
      );
    }
    setClub({ ...club, gradesAllowed: updateList });
  };

  const handleCheckMemberTypeAll = (event: any) => {
    if (event.target.checked) {
      setClub({
        ...club,
        member_type: ['STUDENT', 'STAFF', 'ALUMNI', 'GUARDIAN']
      });
    } else {
      setClub({
        ...club,
        member_type: []
      });
    }
  }

  const handleCheckGradesAllowedAll = (e: any, org: any) => {
    const gradesAll = []

    for (let index = org.lowGrade; index <= org.highGrade; index++) {
      gradesAll.push(index)
    }

    if (e.target.checked) {
      setClub({
        ...club,
        gradesAllowed: gradesAll
      });
    } else {
      setClub({
        ...club,
        gradesAllowed: []
      });
    }
  }

  const handleOnChangeGendersAll = (event: any) => {
    if (event.target.checked) {
      setClub({
        ...club,
        gendersAllowed: ['F', 'M', 'O', 'P']
      });
    } else {
      setClub({
        ...club,
        gendersAllowed: []
      });
    }
  }

  useEffect(() => {
    setAddedAdvisors(club.advisors.map((item: any) => item.email))
  }, [club])

  const handleDeleteAdv = (e: any, adv: Advisor) => {
    e.preventDefault()
    if (club.advisors.length <= 1) return

    setClub({
      ...club,
      advisors: club.advisors.filter((item: any) => item.email !== adv.email)
    })
  }

  return (
    <div className="infoMembers">
      <div className="membersType">
        <h2>{t('setupSchool__membersType')}</h2>
        <span className="subtitle">{t('setupSchool__youCanMark')}</span>
        <div className="containers-input">
          <div className="option-chechbox">
            <label className="content-input">
              <input
                type="checkbox"
                name="checkbox-STUDENT"
                defaultValue="STUDENT"
                onChange={handleCheckMemberType}
                checked={club.member_type.includes("STUDENT")}
              />
              <i />
            </label>
            <label htmlFor="students">{t('setupSchool__student')}</label>
          </div>
          <div className="option-chechbox">
            <label className="content-input">
              <input
                type="checkbox"
                name="checkbox-STAFF"
                defaultValue="STAFF"
                onChange={handleCheckMemberType}
                checked={club.member_type.includes("STAFF")}
              />
              <i />
            </label>
            <label htmlFor="students">{t('setupSchool__staff')}</label>
          </div>
          <div className="option-chechbox">
            <label className="content-input">
              <input
                type="checkbox"
                name="checkbox-ALUMNI"
                defaultValue="ALUMNI"
                onChange={handleCheckMemberType}
                checked={club.member_type.includes("ALUMNI")}
              />
              <i />
            </label>
            <label htmlFor="students">{t('setupSchool__alumni')}</label>
          </div>
          <div className="option-chechbox">
            <label className="content-input">
              <input
                type="checkbox"
                name="checkbox-GUARDIAN"
                defaultValue="GUARDIAN"
                onChange={handleCheckMemberType}
                checked={club.member_type.includes("GUARDIAN")}
              />
              <i />
            </label>
            <label htmlFor="students">{t('setupSchool__guardians')}</label>
          </div>
          <div className="option-chechbox">
            <label className="content-input">
              <input
                type="checkbox"
                name="checkbox-GUARDIAN"
                defaultValue="GUARDIAN"
                onChange={handleCheckMemberTypeAll}
              // checked={club.member_type.includes("GUARDIAN")}
              />
              <i />
            </label>
            <label htmlFor="students">All</label>
          </div>
        </div>
      </div>
      <div className="maximunMembers">
        <h2>{t('setupSchool__maximumNumber')}</h2>
        <NumericInput
          className="form-control"
          min={0}
          value={
            club.maximumNumberMembers !== 0
              ? club.maximumNumberMembers
              : undefined
          }
          onChange={handleOnChangeNumberMembers}
          style={{
            input: {
              marginRight: 3,
              paddingRight: 4,
              textAlign: "left",
              height: 54,
            },
            btnUp: {
              display: "block",
              width: "60px",
              height: "25px",
              boxShadow: "none",
              top: 1,
              right: 0,
              color: "white",
            },
            btnDown: {
              display: "block",
              width: "60px",
              height: "25px",
              boxShadow: "none",
              top: 28,
              right: 0,
              color: "white",
            },
            "input:focus": {
              border: "1px inset #69C",
              outline: "none",
            },
            arrowUp: {
              borderBottomColor: "white",
              marginBottom: 1,
              display: "none",
            },
            arrowDown: {
              borderTopColor: "white",
              marginTop: 1,
              display: "none",
            },
          }}
        />
      </div>
      {createCustom ? (
        <div className="genders">
          <h2>Gender(s) *</h2>
          <div className="containers-input align-left">
            {genders.map(({ id: genderKey, label: genderLabel }) => (
              <div className="option-chechbox">
                <label className="content-input">
                  <input
                    type="checkbox"
                    name={`checkbox-${genderKey}`}
                    value={genderKey}
                    checked={club.gendersAllowed.includes(genderKey)}
                    onChange={handleOnChangeGenders}
                  />
                  <i></i>
                </label>
                <label htmlFor="students">{genderLabel}</label>
              </div>
            ))}
            <div className="option-chechbox">
              <label className="content-input">
                <input
                  type="checkbox"
                  name={`checkbox-all`}
                  value='all'
                  onChange={handleOnChangeGendersAll}
                />
                <i></i>
              </label>
              <label htmlFor="students">All</label>
            </div>
          </div>
        </div>
      ) : (<></>)}
      {not_from_setup || createCustom ? (
        <div className="gradesClub">
          <h2>{t('setupSchool__grades')}</h2>
          <span className="subtitle">{t('setupSchool__youCanMark')}</span>
          <div className="containers-input">
            {organization && Array.from(Array(1 + organization.highGrade - organization.lowGrade)).map((_, idx) => (
              <div className="option-chechbox">
                <label className="content-input">
                  <input
                    type="checkbox"
                    name={`checkbox-${idx + organization.lowGrade}`}
                    value={idx + organization.lowGrade}
                    checked={club.gradesAllowed?.includes(idx + organization.lowGrade)}
                    onChange={handleCheckGradesAllowed}
                  />
                  <i></i>
                </label>
                <label htmlFor="students">{grades.find((grade) => grade.id === idx + organization.lowGrade)?.listLabel || (idx + organization.lowGrade)}</label>
              </div>
            ))}
            <div className="option-chechbox">
              <label className="content-input">
                <input
                  type="checkbox"
                  name={`checkbox-all`}
                  value="all"
                  onChange={(e) => handleCheckGradesAllowedAll(e, organization)}
                />
                <i></i>
              </label>
              <label htmlFor="students">All</label>
            </div>
          </div>
        </div>
      ) : (<></>)}
      <div className="addAdvisor">
        <label htmlFor="">
          {t('setupSchool__theCoach')}
        </label>
        {club.advisors.length === 0 ? (
          <div onClick={handleOnClickAdv} className="add-advisor-modal-team">
            <FontAwesomeIcon
              icon="user-plus"
              size='xl'
              color={WHITE}
              className="icon-user  "

            />
            <p>{t('setupSchool__addCoach')}</p>
          </div>) : (<div className="staff-inputs club-adv">
            {club?.advisors?.map((adv: Advisor) => (<div className="row">
              <input type="text" value={`${adv.firstName} ${adv.lastName}`} />
              {club.advisors.length > 1 ? (
                <FontAwesomeIcon
                  icon="times"
                  size='xl'
                  color={WHITE}
                  className="icon-times-white  "
                  onClick={(e) => handleDeleteAdv(e, adv)}
                />
              ) : (<></>)}
            </div>))}
          </div>)}
        <div onClick={handleOnClickAdv} className="add-advisor-modal">
          <p>{t('setupSchool__addOtherCoach')}</p>
        </div>
      </div>
      <ModalAddAdvisor
        show={showAdvisor}
        onHide={handleCloseAdv}
        idCard={club.name}
        type={club.name}
        staffUsers={staffUsers}
        updateCommInvites={updateCommInvites}
        clubName={club.name}
        addedAdvisors={addedAdvisors}
      />
    </div>
  );
}

export default Index;
