import { useState, useEffect } from "react";
import ModalClub from './ModalClub';
import ModalTeamSetup from './ModalTeamSetup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scoped.css';
import { CCT } from "../../containers/SetupAdvisor";
import ModalCommunity from "./ModalCommunity";
import { useDispatch, useSelector } from "react-redux";
import { getTimezones } from "../../store/actions/locationActions";
import { useTranslation } from "react-i18next";
import useGender from "../../hooks/useGender";
import useGrades from "../../hooks/useGrades";

toast.configure()

function Index(props: {
    handleOnClick: (e: any, step: any, save: boolean) => void;
    clubs: CCT;
    setClubs: any;
    changedClubs: any[];
    setChangedClubs: any;
    completeClubs: any[];
    setCompleteClubs: any;
}) {
    const { t, i18n } = useTranslation();
    //States
    const [tabActive, setTabActive] = useState<number>(1)
    const [showSetupClub, setShowSetupClub] = useState<boolean>(false);
    const [club, setClub] = useState<any>({});

    const organization = useSelector(
        (state: any) => state.getOrgs.organizationInfo
    );

    const getCorrectTabClub = () => {
        /*if(props.clubs.comms.length > 0){
            setTabActive(1)
            return; 
        }*/

        if (props.clubs.clubs.length > 0) {
            setTabActive(2)
            return
        }

        if (props.clubs.teams.length > 0) {
            setTabActive(3)
            return
        }
    }

    //Effects
    const dispatch = useDispatch()
    useEffect(() => {
        // console.log(organization)
        dispatch(getTimezones())

        getCorrectTabClub();
    }, [])

    //Handlers
    function handleCloseSetupClub(saving: boolean) {
        setShowSetupClub(false)
        if (!saving) return
        const changed = [...props.changedClubs]
        const idx = changed.findIndex((item) => item.id === club.id)
        idx === -1 ? changed.push(club) : changed[idx] = club
        props.setChangedClubs(changed)

        const changed_complete = [...props.completeClubs]
        const idx_complete = changed_complete.findIndex((item) => item.id === club.id)
        idx_complete === -1 ? changed_complete.push(club) : changed_complete[idx_complete] = club
        props.setCompleteClubs(changed_complete)

        switch (club.club_type) {
            case 'COMM':
                const changed_info_comm = { ...props.clubs }
                const changed_clubs_comm = [...props.clubs.comms]
                const idx_comm = changed_clubs_comm.findIndex((item) => item.club_id === club.id)
                const new_comm = {
                    id: idx_comm,
                    name: club.name,
                    memberType: club.member_type.join(' • '),
                    genders: club.gendersAllowed.join(' • '),
                    image: club.logo || organization.logo || '',
                    club_id: club.id
                }
                idx_comm === -1 ? changed_clubs_comm.push(new_comm) : changed_clubs_comm[idx_comm] = new_comm
                changed_info_comm.comms = changed_clubs_comm
                props.setClubs(changed_info_comm)
                break
            case 'CLUB':
                const changed_info_club = { ...props.clubs }
                const changed_clubs_club = [...props.clubs.clubs]
                const idx_club = changed_clubs_club.findIndex((item) => item.club_id === club.id)
                const new_club = {
                    id: idx_club,
                    name: club.name,
                    youth_org: club.YO_id ? 'Youth Org' : "",
                    memberType: club.member_type.join(' • '),
                    genders: club.gendersAllowed.join(' • '),
                    grades: club.gradesAllowed.join(' • '),
                    image: club.logo || organization.logo || '',
                    club_id: club.id
                }
                idx_club === -1 ? changed_clubs_club.push(new_club) : changed_clubs_club[idx_club] = new_club
                changed_info_club.clubs = changed_clubs_club
                props.setClubs(changed_info_club)
                break
            case 'TEAM':
                const changed_info_team = { ...props.clubs }
                const changed_clubs_team = [...props.clubs.teams]
                const idx_team = changed_clubs_team.findIndex((item) => item.club_id === club.id)
                const new_team = {
                    id: idx_team,
                    name: club.name,
                    template: club.template_id,
                    memberType: club.member_type.join(' • '),
                    max_members: club.maximumNumberMembers,
                    grades: club.gradesAllowed.join(' • '),
                    image: club.logo || organization.logo || '',
                    club_id: club.id
                }
                idx_team === -1 ? changed_clubs_team.push(new_team) : changed_clubs_team[idx_team] = new_team
                changed_info_team.teams = changed_clubs_team
                props.setClubs(changed_info_team)
                break
            default:
                break
        }
    };

    const handleOnClickClub = (e: any, Club: any) => {
        setClub(props.completeClubs.find((item) => item.id === Club.club_id))
        setShowSetupClub(true)
    };

    function handleOnClickTab(e: any, step: any) {
        setTabActive(step);
    }

    return (
        <>
            <div className="titles-header">
                <div className="titles">
                    <div className="myProfile-Header">
                        <h1>Zaahah</h1>
                        <div className="myProfile-Buttons">
                            {props.changedClubs.length === 0 ?
                                <button className="button-skip" onClick={(e) => props.handleOnClick(e, 5, false)}>{t('setupSchool__skip')}</button>
                                :
                                <div className="buttonHeader">
                                    <button className="button-skip" onClick={(e) => props.handleOnClick(e, 5, false)}>{t('setupSchool__discardChanges')}</button>
                                    <button className="button-start" onClick={(e) => props.handleOnClick(e, 5, true)}>{t('setupSchool__saveAndContinue')}</button>
                                </div>
                            }
                        </div>
                    </div>
                    <h2>{t('setupAdvisor__setupClubs')}</h2>
                    <span>{t('setupAdvisor__YouHaveBeen')}</span>
                </div>
            </div>
            <div className="tabsClubsAndTeams tabsType">
                {/*<button className={`${tabActive === 1 ? 'active' : ''} tabHeader`} onClick={(e) => handleOnClickTab(e, 1)}>
                    {props.clubs.comms.length} {t('menu__communities')}
                </button>*/}
                <button className={`${tabActive === 2 ? 'active' : ''} tabHeader`} onClick={(e) => handleOnClickTab(e, 2)}>
                    {props.clubs.clubs.length} {t('menu__clubs')}
                </button>
                <button className={`${tabActive === 3 ? 'active' : ''} tabHeader`} onClick={(e) => handleOnClickTab(e, 3)}>
                    {props.clubs.teams.length} {t('menu__teams')}
                </button>
            </div>
            <div className="tabsClubsAndTeams">
                {tabActive === 1 ?
                    <>
                        {props.clubs.comms.map((comm: any) => (
                            <div className="cardClubs">
                                <div className="titleClub">
                                    <p>{comm.name}</p>
                                    <div className={`cardPhoto ${comm.image === '' && 'NoImage'}`}>
                                        {comm.image &&
                                            <img src={comm.image} alt="" />
                                        }
                                    </div>
                                </div>
                                <div className="infoClub">
                                    <p>
                                        <label htmlFor="">{t('setupAdvisor__membersType')}</label>
                                        <span>{comm.memberType}</span>
                                    </p>
                                    <p>
                                        <label htmlFor="">{t('setupAdvisor__gender')}</label>
                                        <span>{comm.genders}</span>
                                    </p>
                                    <button onClick={(e) => handleOnClickClub(e, comm)} className="buttonStep"> {(!comm.is_setup) ? t('setupAdvisor__completeSetup') : t('setupAdvisor__SetupComplete')} </button>
                                </div>
                            </div>
                        ))}
                        <ModalCommunity show={showSetupClub} onHide={handleCloseSetupClub} comm={club} setComm={setClub} />
                    </>

                    : tabActive == 2 ?
                        <>
                            {props.clubs.clubs.map((club: any) => (
                                <div className="cardClubs">
                                    <div className="titleClub">
                                        <p>{club.name}</p>
                                        <span>{club.youth_org}</span>
                                        <div className={`cardPhoto ${club.image === '' && 'NoImage'}`}>
                                            {club.image &&
                                                <img src={club.image} alt="" />
                                            }
                                        </div>
                                    </div>
                                    <div className="infoClub">
                                        <p>
                                            <label htmlFor="">{t('setupAdvisor__membersType')}</label>
                                            <span>{club.memberType}</span>
                                        </p>
                                        <p>
                                            <label htmlFor="">{t('setupAdvisor__gender')}</label>
                                            <span>{club.genders}</span>
                                        </p>
                                        <p>
                                            <label htmlFor="">{t('myschool__Grades')}</label>
                                            <span>{club.grades}</span>
                                        </p>
                                        <button onClick={(e) => handleOnClickClub(e, club)} className="buttonStep"> {(!club.is_setup) ? t('setupAdvisor__completeSetup') : t('setupAdvisor__SetupComplete')} </button>
                                    </div>
                                </div>
                            ))}
                            <ModalClub show={showSetupClub} onHide={handleCloseSetupClub} club={club} setClub={setClub} />
                        </>

                        : tabActive == 3 ?
                            <>
                                {props.clubs.teams.map((team: any) => (
                                    <div className="cardClubs">
                                        <div className="titleClub">
                                            <p>{team.template}</p>
                                            <span>{team.name}</span>
                                            <div className={`cardPhoto ${team.image === '' && 'NoImage'}`}>
                                                {team.image &&
                                                    <img src={team.image} alt="" />
                                                }
                                            </div>
                                        </div>
                                        <div className="infoClub">
                                            <p>
                                                <label htmlFor="">{t('setupAdvisor__membersType')}</label>
                                                <span>{team.memberType}</span>
                                            </p>
                                            <p>
                                                <label htmlFor="">{t('setupSchool__maximumNumber')}</label>
                                                <span>{team.max_members}</span>
                                            </p>
                                            <p>
                                                <label htmlFor="">{t('myschool__Grades')}</label>
                                                <span>{team.grades}</span>
                                            </p>
                                            <button onClick={(e) => handleOnClickClub(e, team)} className="buttonStep"> {(!team.is_setup) ? t('setupAdvisor__completeSetup') : t('setupAdvisor__SetupComplete')} </button>
                                        </div>
                                    </div>
                                ))}
                                <ModalTeamSetup show={showSetupClub} onHide={handleCloseSetupClub} team={club} setTeam={setClub} />
                            </>
                            : ''
                }
            </div>

        </>
    );
}

export default Index;
