import "../styles.scoped.css";
import '../styles.css';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { SingleValue } from 'react-select';

import OrganizationComplete from "../../../interfaces/organization/OrganizationComplete";
import { updateOrg, updateLogoOrg, uploadImage } from "../../../store/actions/orgsActions";

import ModalImageEditor from '../../ModalImageEditor';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../../components/ClubIconsNavBar';
import useGrades from "../../../hooks/useGrades";
import SelectItem from "../../../interfaces/SelectItem";
import { customStylesReactSelect, DropdownIndicator } from "../../../helpers/selectHelper";
import { Backend, MicroServices } from "../../../helpers/backendHelper";
import useActiveCountry, { CountryOption } from "../../../hooks/useActiveCountry";
import usePostalCodes from "../../../hooks/usePostalCodes";

export const cleanWebsite = (web: string) => {
    const website = web.replace(/\s+/g, '').toLowerCase();
    const URL_PROTOCOL = /http(s)?:\/\/.*/;
    if (URL_PROTOCOL.exec(website)) {
        return website;
    }
    return `https://${website}`;
}

function MyYouth(props: {
    organization?: OrganizationComplete,
    handleOnClick: (e: any, step: number) => void,
    reloadOrgInformation: () => void,
}) {

    const { organization, reloadOrgInformation } = props;

    const ages = [
        { value: 13, label: '13' },
        { value: 14, label: '14' },
        { value: 15, label: '15' },
        { value: 16, label: '16' },
        { value: 17, label: '17' },
        { value: 18, label: '18' },
        { value: 19, label: '19' },
        { value: 20, label: '20' },
        { value: 21, label: '21' },
        { value: 22, label: '22' },
    ]

    const dispatch = useDispatch();

    const [loadingPhoto, setLoadingPhoto] = useState<boolean>(false);

    const [nameYOrg, setNameYOrg] = useState('');
    const [mission, setMission] = useState('');
    const [address, setAddress] = useState('');

    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');

    const [lowGrade, setLowGrade] = useState<string>();
    const [highGrade, setHighGrade] = useState<string>();
    const [lowAges, setLowAges] = useState<SingleValue<{ value: number; label: string; }>>(ages[0]);
    const [highAges, setHighAges] = useState<SingleValue<{ value: number; label: string; }>>(ages[9]);

    const [website, setWebsite] = useState('');
    const [schoolLogo, setSchoolLogo] = useState('');
    const [skip, setSkip] = useState(true);

    const [saveOrgChanges, setSaveOrgChanges] = useState<boolean>(false);
    const [waitingForOrg, setWaitingForOrg] = useState<boolean>(false);
    const [changeImage, setChangeImage] = useState('');
    const [logoOrg, setLogoOrg] = useState('');

    const loadingUploadLogoOrg = useSelector((state: any) => state.getOrgs.loadingUploadLogoOrg || false);

    const { postalCodes: postalCodesConfig, setCountry: setCountryPostal } = usePostalCodes();

    const { gradesSelect, setCountry: setCountryGrades } = useGrades();
    const [lowGradeSelect, setLowGradeSelect] = useState<SelectItem>();
    const [highGradeSelect, setHighGradeSelect] = useState<SelectItem>();

    const [validateData, setValidateData] = useState<boolean>(true);
    const [showErrorName, setShowErrorName] = useState<boolean>(false);
    const [showErrorWebsite, setShowErrorWebsite] = useState<boolean>(false);
    const [showErrorZipcode, setShowErrorZipcode] = useState<boolean>(false);
    const [zipcodeErrorMessage, setZipcodeErrorMessage] = useState('');

    const [countryCode, setCountryCode] = useState<CountryOption>();

    const { activeCountriesReactSelect } = useActiveCountry();

    useEffect(() => {
        if (organization) {
            setLowGradeSelect(gradesSelect.find((grade) => grade.value == organization.lowGrade.toString()));
            setHighGradeSelect(gradesSelect.find((grade) => grade.value == organization.highGrade.toString()));
        }
    }, [gradesSelect])

    useEffect(() => {
        if (organization && organization?.countryCode) {
            setCountryGrades(organization?.countryCode);
            setCountryPostal(organization?.countryCode);
        }
    }, [])

    useEffect(() => {
        if (organization && organization?.countryCode) {
            setCountryGrades(organization?.countryCode);
            setCountryPostal(organization?.countryCode);
        }
    }, [organization])


    useEffect(() => {
        if (organization) {
            // console.log(organization)
            setWebsite(organization.website);

            setLowGrade(String(organization.lowGrade));
            setHighGrade(String(organization.highGrade));

            setLowAges(ages[organization.lowGrade - 6]);
            setHighAges(ages[organization.highGrade - 6]);

            setAddress(organization.address || '');
            setPostalCode(organization.zipcode || '');
            setLogoOrg(organization.logo || '');
            setNameYOrg(organization.name || '')
            setMission(organization.mission || '')

            setCountry(organization.country || '');
            setCity(organization.city || '');
            setState(organization.state || '');
        }
    }, [organization]);

    const disableSkip = () => {
        if (skip) {
            setSkip(false);
        }
    }

    const handleChangePostalCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        disableSkip();
        setPostalCode(event.target.value);
    }

    const handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
        disableSkip();
        setCity(event.target.value);
    }

    const handleChangeState = (event: React.ChangeEvent<HTMLInputElement>) => {
        disableSkip();
        setState(event.target.value);
    }

    // const handleChangeCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     disableSkip();
    //     setCountry(event.target.value);
    // }

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        disableSkip();
        setNameYOrg(event.target.value);
    }

    const handleChangeMission = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        disableSkip();
        setMission(event.target.value);
    }

    const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        disableSkip();
        setAddress(event.target.value);
    }

    const handleChangeWebsite = (event: React.ChangeEvent<HTMLInputElement>) => {
        disableSkip();
        setWebsite(event.target.value);
    }

    const handleChangeLowGrade = (e: any) => {
        disableSkip();
        setLowGrade(e.value);
        setLowGradeSelect(e);
    }
    const handleChangeHighGrade = (e: any) => {
        disableSkip();
        setHighGrade(e.value);
        setHighGradeSelect(e);
    }

    const handleChangeLowAge = (newValue: SingleValue<{
        value: number;
        label: string;
    }>) => {
        disableSkip();
        setLowAges(newValue);
    }

    const handleChangeHighAge = (newValue: SingleValue<{
        value: number;
        label: string;
    }>) => {
        disableSkip();
        setHighAges(newValue);
    }

    const _handleCountrySelect = (e: any) => {
        disableSkip();
        setCountryGrades(e.value)
        setCountryPostal(e.value)
        setCountryCode(e);
        setCountry(e.value);
    }

    useEffect(() => {
        if (activeCountriesReactSelect.length > 0) {
            setCountryCode(activeCountriesReactSelect.find((countryOption) => countryOption.value === country));
        }
    }, [activeCountriesReactSelect, country])


    useEffect(() => {
        if (waitingForOrg) {
            setSaveOrgChanges(false);
            setWaitingForOrg(false);
            props.handleOnClick(undefined, 2);
        }
    }, [organization]);

    const handleSave = (e: any) => {

        let validateData = true

        if (nameYOrg === '') {
            validateData = false
            setShowErrorName(true)
        } else {
            setShowErrorName(false)
        }

        if (website === '') {
            validateData = false
            setShowErrorWebsite(true)
        } else {
            setShowErrorWebsite(false)
        }

        if (postalCode === '') {
            validateData = false
            setShowErrorZipcode(true)
            setZipcodeErrorMessage('Postal code cannot be empty');
        } else if (postalCode.length < (postalCodesConfig?.minLength ?? 5) || postalCode.length > (postalCodesConfig?.maxLength ?? 5)) {
            validateData = false
            setShowErrorZipcode(true)
            setZipcodeErrorMessage(`Zip Code must have ${postalCodesConfig?.minLength ?? 5} digits.`);
        } else {
            setZipcodeErrorMessage('');
            setShowErrorZipcode(false)
        }

        if (validateData) {
            Backend(
                MicroServices.AccessManager,
                `/auth/website?website=${encodeURI(cleanWebsite(website))}`
            )
                .then((response) => {
                    if (response.status !== 200 && website !== organization?.website) {
                        setValidateData(false)
                        setShowErrorWebsite(true)
                    } else {
                        setShowErrorWebsite(false)
                        if (organization) {
                            setSaveOrgChanges(true);
                            let orgUpdate: any = {
                                name: nameYOrg,
                                website: website,
                                lowGrade: Number(lowGrade),
                                highGrade: Number(highGrade),
                                lowAge: lowAges?.value,
                                highAge: highAges?.value,
                                address: address,
                                zipcode: postalCode,
                                city: city,
                                state: state,
                                country: country,
                                mission: mission,
                            }

                            if (logoOrg !== '') {
                                orgUpdate.logo = logoOrg;
                            }

                            updateYo(orgUpdate);
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                });


        }
    }

    const updateYo = (formToSend: any) => {
        Backend(
            MicroServices.Admin,
            `/yo/${organization?.YOTableId || ''}?pending=${(organization?.status || '').startsWith('P') ? 'true' : 'false'}&&zaahahIdOrg=${organization?.id}`,
            {
                method: 'POST',
                body: JSON.stringify(formToSend)
            }
        )
            .then((response) => response.json())
            .then((results) => {
                setWaitingForOrg(true);
                reloadOrgInformation();
            })
            .catch(console.error);
    }

    const handleDiscard = (e: any, step: number) => {
        setSaveOrgChanges(false);
        props.handleOnClick(e, step);
    }

    const customStyles = () => ({
        container: (styles: any) => ({
            ...styles,
            width: '100%',
            backgroundColor: 'transparent',
            height: 40,
        }),
        indicatorsContainer: (styles: any) => ({
            ...styles,
            display: 'none',

        }),
        control: (styles: any) => ({
            backgroundColor: '#28115a',
            border: 0,
            height: 40,
        }),
        valueContainer: (styles: any) => ({
            height: 40,
        }),
        singleValue: (styles: any) => ({
            color: 'white',
            padding: 12,
        }),
        option: (styles: any) => ({
            color: 'white',
            padding: 12,
            width: 40,
            height: 40,
            backgroundColor: '#2C2C2C',
            display: 'inline-block',
            margin: 5,
            cursor: 'pointer',
        }),
        menu: (styles: any) => ({
            width: 220,
            padding: '0 10px',
            borderRadius: 7,
            backgroundColor: '#383838; position:absolute; top: -115px;',
        }),
    });

    const customStylesAges = () => ({
        container: (styles: any) => ({
            ...styles,
            width: '100%',
            backgroundColor: 'transparent',
            height: 40,
        }),
        indicatorsContainer: (styles: any) => ({
            ...styles,
            display: 'none',

        }),
        control: (styles: any) => ({
            backgroundColor: '#28115a',
            border: 0,
            height: 40,
        }),
        valueContainer: (styles: any) => ({
            height: 40,
        }),
        singleValue: (styles: any) => ({
            color: 'white',
            padding: 12,
        }),
        option: (styles: any) => ({
            color: 'white',
            padding: 12,
            width: 40,
            height: 40,
            backgroundColor: '#2C2C2C',
            display: 'inline-block',
            margin: 5,
            cursor: 'pointer',
        }),
        menu: (styles: any) => ({
            width: 220,
            padding: '0 10px',
            borderRadius: 7,
            backgroundColor: '#383838; position:absolute; top: -165px;',
        }),
    });

    const [showModal, setShowModal] = useState(false);

    function handleCloseModal(e: any, type: any) {
        setShowModal(false);
    };

    function handleSaveImage(e: any) {
        setSchoolLogo(e);
        setLoadingPhoto(true);
        fetch(e)
            .then(res => res.blob())
            .then(blob => {
                const contentType = e.split(';')[0].split(':')[1];
                const extension = contentType.split('/')[1]
                const file = new File([blob], 'Logo' + organization?.id, { type: contentType });

                if (file) {
                    dispatch<any>(updateLogoOrg(
                        file.type,
                        file.name + '.' + extension,
                        "school_logo"
                    ))
                        .then((results: any) => {
                            if (results.body) {

                                console.log(file)

                                dispatch<any>(uploadImage(
                                    results.body,
                                    contentType,
                                    file,
                                ))
                                    .then((result: any) => {
                                        if (result.status === 200) {
                                            const urlImage = results.body.split('?')[0];
                                            setLogoOrg(urlImage)
                                            setSkip(false);
                                        }
                                        setLoadingPhoto(false);
                                    })
                                    .catch((error: any) => {
                                        console.log(error)
                                        setLoadingPhoto(false);
                                    })

                            }
                        })
                        .catch((error: any) => {
                            console.log(error)
                            setLoadingPhoto(false);
                        })
                }

            })
    };

    return (
        <>
            <div className="container-myschool">

                <h1>Congratulations!</h1>

                <span>{organization?.name || 'Your organization'} is on Zaahah. Please update youth organization information and you can then add your schools, communities and clubs related.</span>

                <div className="btnWrapper">
                    {skip || loadingUploadLogoOrg ? (
                        <button
                            className="button-skip"
                            onClick={(e) => props.handleOnClick(e, 2)}>
                            Skip
                        </button>
                    ) : (
                        <>
                            <button
                                className="button-skip"
                                onClick={(e) => handleDiscard(e, 2)}
                                disabled={saveOrgChanges}
                            >
                                Discard changes
                            </button>
                            <button
                                className="button-save"
                                name="btn-Step1-Save"
                                onClick={handleSave}
                                disabled={saveOrgChanges}
                            >
                                {saveOrgChanges ? (
                                    <div className="btn-center">
                                        <div className="icon-spinner-third buttonSendSignUp lilSendSignUp"></div>
                                    </div>
                                ) : 'Save and continue'}
                            </button>
                        </>
                    )}
                </div>
            </div>
            <div className="myschool-inputs-youth">
                <ModalImageEditor handleSaveImage={handleSaveImage} show={showModal} onHide={handleCloseModal} />

                <label onClick={() => setShowModal(true)} className="photo" htmlFor="filePicker">
                    <div>
                        <div className="logo-photo">
                            {schoolLogo || logoOrg ?
                                <img src={schoolLogo || logoOrg} className="imageSchool" />
                                :
                                (
                                    (loadingPhoto) ? (
                                        <div className="icon-spinner-third buttonSendSignUp" />
                                    ) :
                                        (<FontAwesomeIcon
                                            icon="camera"
                                            size="xl"
                                            color={WHITE}
                                            className="icon-camera  "
                                        />)
                                )
                            }

                        </div>
                        <p>Select Organization Logo</p>
                        <a href="#">Upload file</a>
                    </div>
                </label>

                <div className="inputs">

                    <div className="row row2Youth">
                        <div className="containers-input nameorgYo">
                            <label className="title-input titleLittleInput Youth-left" htmlFor="i-principal">
                                Organization Name
                            </label>
                            <input
                                className="Youth-left input-Name"
                                type="text"
                                value={nameYOrg}
                                onChange={handleChangeName}
                                disabled={saveOrgChanges}
                            />
                            {showErrorName &&
                                <label className="textError" htmlFor="">Organization name cannot be empty</label>
                            }

                        </div>

                        <div className="containers-input websiteYouth">
                            <label className="title-input titleLittleInput" htmlFor="i-principal">
                                Organization Website
                            </label>
                            <input
                                type="text"
                                value={website}
                                disabled={saveOrgChanges}
                                onChange={handleChangeWebsite}
                            />
                            {showErrorWebsite &&
                                <label className="textError" htmlFor="">Website cannot be empty or used by another organization</label>
                            }
                        </div>
                    </div>

                    <div className="row row2Youth">
                        <div className="containers-input">
                            <label className="title-input titleLittleInput Youth-left" htmlFor="i-principal">
                                Address
                            </label>
                            <input
                                className="Youth-left input-Name"
                                type="text"
                                value={address}
                                disabled={saveOrgChanges}
                                onChange={handleChangeAddress}
                            />
                        </div>

                        <div className="containers-input websiteYouth">
                            <label className="title-input titleLittleInput" htmlFor="i-principal">
                                Postal Code
                            </label>
                            <input
                                type="text"
                                value={postalCode}
                                disabled={saveOrgChanges}
                                onChange={handleChangePostalCode}
                            />
                            {showErrorZipcode &&
                                <label className="textError" htmlFor="">{zipcodeErrorMessage}</label>
                                // <label className="textError" htmlFor=""></label>
                            }
                        </div>
                    </div>

                    <div className="row row2Youth">
                        <div className="containers-disabled Youth-left">
                            <label className="title-input titleLittleInput" htmlFor="i-principal">
                                City
                            </label>
                            <input
                                type="text"
                                value={city}
                                disabled={saveOrgChanges}
                                onChange={handleChangeCity}
                            />
                        </div>

                        <div className="containers-disabled">
                            <label className="title-input titleLittleInput" htmlFor="i-principal">
                                State
                            </label>
                            <input
                                type="text"
                                value={state}
                                disabled={saveOrgChanges}
                                onChange={handleChangeState}
                            />
                        </div>

                        <div className="containers-disabled">
                            <label className="title-input titleLittleInput" htmlFor="i-principal">
                                Country
                            </label>
                            <Select
                                options={activeCountriesReactSelect}
                                styles={customStylesReactSelect()}
                                components={{
                                    DropdownIndicator
                                }}
                                value={countryCode}
                                onChange={_handleCountrySelect}
                                placeholder="Country"
                            />
                            {/* <input
                                type="text"
                                value={country}
                                disabled={saveOrgChanges}
                                onChange={handleChangeCountry}
                            /> */}
                        </div>

                    </div>

                    <div className="row">
                        <div className="containers-input">
                            <label className="title-input" htmlFor="i-principal">
                                Mission
                            </label>
                            <textarea
                                className="mission-input Youth-left"
                                value={mission}
                                onChange={handleChangeMission}
                            />
                        </div>
                    </div>

                    <div className="row row2Youth ">
                        <div className="containers-input Youth-left rowGrades">
                            <label className="title-input titleLittleInput" htmlFor="i-principal">
                                Grade Level
                            </label>
                            <div className="grades">
                                <div>
                                    {/* <select 
                                        name="lowGrade" 
                                        className="select-the-information-1-MyYouth roboto-normal-white-16px" 
                                        onChange={handleChangeLowGrade} 
                                        value={lowGrade}
                                    >
                                        <option disabled value="0">Select Grade</option>
                                        {getOptions()}
                                    </select> */}
                                    {/* <Select
                                className="selectGrade"
                                menuPlacement="top"
                                options={grades} 
                                value={lowGrade}
                                styles={customStyles()}
                                onChange={handleChangeLowGrade}
                            /> */}
                                    {/* <FontAwesomeIcon
                                        icon="chevron-up"
                                        size='lg'
                                        color={LAVENDER}
                                        className="chevron-up  "
                                    /> */}
                                    <Select
                                        options={gradesSelect}
                                        styles={customStylesReactSelect()}
                                        components={{
                                            DropdownIndicator,
                                        }}
                                        value={lowGradeSelect}
                                        onChange={handleChangeLowGrade}
                                        placeholder="Low Grade"
                                    />
                                </div>


                                <label className="label-to" htmlFor="i-principal">
                                    To
                                </label>

                                <div>
                                    {/* <select 
                                        name="highGrade" 
                                        className="select-the-information-1-MyYouth roboto-normal-white-16px" 
                                        onChange={handleChangeHighGrade} 
                                        value={highGrade}
                                    >
                                        <option disabled value="0">Select Grade</option>
                                        {getOptions()}
                                    </select>
                                    <FontAwesomeIcon
                                        icon="chevron-up"
                                        size='lg'
                                        color={LAVENDER}
                                        className="chevron-up  "
                                    /> */}
                                    <Select
                                        options={gradesSelect}
                                        styles={customStylesReactSelect()}
                                        components={{
                                            DropdownIndicator,
                                        }}
                                        value={highGradeSelect}
                                        onChange={handleChangeHighGrade}
                                        placeholder="High Grade"
                                    />
                                </div>
                            </div>
                        </div>

                        {/*<div className="containers-input">
                            <label className="title-input titleLittleInput" htmlFor="i-principal">
                                Age Level
                            </label>
                            <div className="grades">
                                <div>
                                    <Select
                                        className="selectGrade"
                                        menuPlacement="top"
                                        options={ages}
                                        value={lowAges}
                                        styles={customStylesAges()}
                                        onChange={handleChangeLowAge}
                                    />
                                    <FontAwesomeIcon
                                        icon="chevron-up"
                                        size='lg'
                                        color={LAVENDER}
                                        className="chevron-up  "
                                    />
                                </div>


                                <label className="label-to" htmlFor="i-principal">
                                    To
                                </label>

                                <div>
                                    <Select
                                        options={ages}
                                        value={highAges}
                                        styles={customStylesAges()}
                                        onChange={handleChangeHighAge}
                                    />
                                    <FontAwesomeIcon
                                        icon="chevron-up"
                                        size='lg'
                                        color={LAVENDER}
                                        className="chevron-up  "
                                    />
                                </div>
                            </div>
                        </div>*/}
                    </div>



                </div>
            </div>
        </>

    );
}

export default MyYouth;
