import { useEffect, useMemo, useState } from "react";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import YO from "../../interfaces/YOTable/YO/YO";

const useYOListSA = (pageSizeProp: number) => {
    const [pageSize, setPageSize] = useState(pageSizeProp);
    const [yos, setYos] = useState<YO[]>([]);

    const yoPages:YO[][] = useMemo(() => {
        const pages:YO[][] = [];

        for (let i = 0; i < yos.length; i+=pageSize) {
            pages.push(yos.slice(i, i+pageSize));
        }

        if (pages.length === 0) {
            pages.push([]);
        }

        return pages;
    }, [pageSize, yos])

    const reloadYOList = () => {
        Backend(
            MicroServices.Admin,
            `/yo`
        )
            .then((response) => response.json())
            .then((results: YO[]) => {
                if (!Array.isArray(results)) {
                    return;
                }
                setYos(results);
            });
    }
    
    useEffect(() => reloadYOList(), []);

    return {
        yos,
        yoPages,
        reloadYOList,
        setPageSize,
    }

}

export default useYOListSA;