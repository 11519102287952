import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getClubs } from "../../store/actions/clubActions";
import { getUserFriends, getUserPossibleConnections, getUserPendingRequests, getUserSentRequests, rejectFriendRequest, saveNewFriend, cancelFriendRequest, cancelFriendRequestEmail } from "../../store/actions/connectionActions";
import { getUser } from "../../store/actions/userActions";
import { LAVENDER, WHITE } from "../ClubIconsNavBar";
import ModalConnections from "./ModalConnections";
import OutsideClickHandler from "react-outside-click-handler";
import "./styles.css";
import "./styles.scoped.css";
import { useTranslation } from "react-i18next";
import ModalConnection from '../../components/ModalConnection'
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";

const sortAlphabeticallyOnProperty = (list: any[], property: string, ordSelected: string = 'ASC') => {
  if(list[0] && !(property in list[0]) && property === 'fullname'){
    list = list.map((user: any) => ({...user, fullname: `${user.firstname} ${user.lastname}`}))
  }

  if (ordSelected === 'DESC') {
    return list.sort((b, a) => a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0)
  }
  return list.sort((a, b) => a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0)
}

function Index({ChatServiceInstance}:{ChatServiceInstance:ChatServiceInterface}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const { username = '' } = useParams();

  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const userFriends = useSelector((state: any) => state.getConnection.userFriends || []);
  const userConnections = useSelector((state: any) => state.getConnection.userPossibleConnections || []);
  const userPendingRequest = useSelector((state: any) => state.getConnection.userPendingRequests || []);
  const userSentRequest = useSelector((state: any) => state.getConnection.userSentRequests || []);

  const [activeSort, setActiveSort] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [activeTab, setActiveTab] = useState<number>(0);

  const [setConnection, setSetConnection] = useState<any>('pass');
  const [valueFilter, setValueFilter] = useState("");
  const [selectOrder, setSelectOrder] = useState("ASC");
  const [showModalConnection, setShowModalConnection] = useState<boolean>(false);
  const [infoConnection, setInfoConnection] = useState([]);
  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    dispatch<any>(getUserFriends(userInfo.username));
    dispatch<any>(getUserPossibleConnections(userInfo.username));
    dispatch<any>(getUserPendingRequests(userInfo.username));
    dispatch<any>(getUserSentRequests(userInfo.username));
  }, [])

  function handleChangeSearch(e: any) {
    setValueFilter(e.target.value)
  }

  useEffect(() => {
    connectionsLeftPanel();
  }, [location, userFriends, userConnections, userPendingRequest, userSentRequest])

  const connectionsLeftPanel = () => {

    const connect = (location.pathname || '').split('/');

    if (userConnections && userConnections.length > 0) {
      const isConnect = userConnections.some((us: any) => (us.usernameConnection === connect[connect.length - 1]))
      if (isConnect) {
        setActiveTab(0);
        return;
      }
    }

    if (userFriends && userFriends.length > 0) {
      const isFriend = userFriends.some((us: any) => (us.friendUsername === connect[connect.length - 1]))
      if (isFriend) {
        setActiveTab(1);
        return;
      }
    }

    if (userPendingRequest && userPendingRequest.length > 0) {
      const isPending = userPendingRequest.some((us: any) => (us.usernameFrom === connect[connect.length - 1]))
      if (isPending) {
        setActiveTab(2);
        return;
      }
    }

    if (userSentRequest && userSentRequest.length > 0) {
      const isSent = userSentRequest.some((us: any) => (us.usernameTo === connect[connect.length - 1]))
      if (isSent) {
        setActiveTab(2);
        return;
      }
    }
  }

  const rejectRequest = (usernameFrom: string) => {
    dispatch<any>(rejectFriendRequest(usernameFrom, userInfo.username))
      .then(() => {
        dispatch<any>(getUserPendingRequests(userInfo.username));
      });
  }

  const acceptFriend = (usernameFrom: string) => {
    dispatch<any>(saveNewFriend(userInfo.username, usernameFrom))
      .then(() => {
        dispatch<any>(getUserFriends(userInfo.username));
        dispatch<any>(getUserPendingRequests(userInfo.username));
      });
  }

  const cancelRequest = (usernameTo: string, connection: any) => {

    if (connection.unRegisteredUser) {
      dispatch<any>(cancelFriendRequestEmail(userInfo.username, connection.email))
        .then(() => {
          dispatch<any>(getUserSentRequests(userInfo.username));
        });
    }
    else {
      dispatch<any>(cancelFriendRequest(userInfo.username, usernameTo))
        .then(() => {
          dispatch<any>(getUserSentRequests(userInfo.username));
        });
    }
  }

  const handleDots = (connection: any) => {
    setSetConnection(connection);
    setOpenModal(!openModal);

  }

  const isCorrectDots = (connection: any) => {
    if (connection.friendUsername) {
      return (connection.friendUsername === setConnection.friendUsername);
    }
    else {
      return (connection.usernameConnection === setConnection.usernameConnection);
    }
  }

  const onClickPending = (connection: any) => {

    if (!connection.unRegisteredUser) {
      setInfoConnection(connection)
      setShowModalConnection(true)
    }
  }

  const onClickMyConnections = (connection: any) => {
    setInfoConnection(connection)
    setShowModalConnection(true)
  }

  function handleCloseModalDelete() {
    setShowModalConnection(false)
  }

  const selectConnection = (connection: any) => {

    if (connection.friendUsername === username) {
      return true
    }
    if (connection.usernameConnection === username) {
      return true
    }
    return undefined;
  }

  const getFilteredList = (listName: any[]) => {
    return listName.filter((item: any) => {
      if (!valueFilter) return true;

      if(item.fullname && (
        item.fullname.toUpperCase().includes(valueFilter) ||
        item.fullname.toLowerCase().includes(valueFilter) ||
        item.fullname.includes(valueFilter)
      )){
        return true;
      }

      if (item.firstname && item.lastname && (
        item.firstname.toUpperCase().includes(valueFilter) ||
        item.firstname.toLowerCase().includes(valueFilter) ||
        item.lastname.toUpperCase().includes(valueFilter) ||
        item.lastname.toLowerCase().includes(valueFilter) ||
        item.firstname.includes(valueFilter) ||
        item.lastname.includes(valueFilter)
      )) {
        return true;
      }

      return false;
    })
  }

  const isUserPending = (usernameConnection: any) => {
    if (userPendingRequest && userPendingRequest.length > 0) {
      return userPendingRequest.some((us: any) => (us.usernameFrom === usernameConnection))
    }
    return false;
  }

  const isUserSent = (usernameConnection: any) => {
    if (userSentRequest && userSentRequest.length > 0) {
      return userSentRequest.some((us: any) => (us.usernameTo === usernameConnection))
    }
    return false;
  }

  const getFilterType = (connection: any) => {

    if (connection.friends &&
      !isUserPending(connection.usernameConnection) &&
      !isUserSent(connection.usernameConnection)) {
      return 'delete'
    }
    if (!connection.friends &&
      isUserPending(connection.usernameConnection) &&
      !isUserSent(connection.usernameConnection)) {
      return 'deleteRequest'
    }
    if (!connection.friends &&
      !isUserPending(connection.usernameConnection) &&
      isUserSent(connection.usernameConnection)) {
      return 'cancelRequest'
    }
    return '1'
  }

  return (
    <>
      <div className="tabConnections">
        <div
          className={`tabC tabMyConnection ${activeTab === 0 && 'tabActive'}`}
          onClick={() => setActiveTab(0)}
        >
          {t('bulletin__myConnections')}
        </div>
        <div
          className={`tabC tabMyFriends ${activeTab === 1 && 'tabActive'}`}
          onClick={() => setActiveTab(1)}
        >
          {t('bulletin__myFriends')}
        </div>
        <div
          className={`tabC tabPendingConnection ${activeTab === 2 && 'tabActive'}`}
          onClick={() => setActiveTab(2)}
        >
          {t('bulletin__pending')}
        </div>
      </div>

      <div className="searchConnections">
        <div className="inputSearch">
          <FontAwesomeIcon
            icon="search"
            size='sm'
            color={WHITE}
            className="btnSort  "
          />
          <input type="text" placeholder={t('comm__search')} onChange={(e) => handleChangeSearch(e)} />
        </div>
        <div className="sortIcon">
          <FontAwesomeIcon
            icon="sort-alpha-up"
            size='lg'
            color={WHITE}
            className="btnSort  "
            onClick={() => setActiveSort(!activeSort)}
          />
          <div className={`itemSort ${activeSort && 'sortActive'}`}>

            <label className="titleSort" htmlFor="">{t('options__sort')}</label>
            <OutsideClickHandler
              onOutsideClick={() => {
                setActiveSort(false);
              }}
            >
              <div className="option-status" onClick={() => setSelectOrder('ASC')}>
                <label className="content-input">
                  <input type="radio" name="button" id={'az'} value={'ASC'} />
                  <i></i>
                </label>
                <label className="labelChechbox" htmlFor="az">{t('options__az')}</label>
              </div>

              <div className="option-status" onClick={() => setSelectOrder('DESC')}>
                <label className="content-input">
                  <input type="radio" name="button" id={'za'} value={'DESC'} />
                  <i></i>
                </label>
                <label className="labelChechbox" htmlFor="za">{t('options__za')}</label>
              </div>
            </OutsideClickHandler>
          </div>
        </div>
      </div>

      {activeTab === 2 && (
        <div className='pendingConnectionsSelectBar'>
          <div className='ConnectionsPendingWrap'>
            <p
              className={isPending ? 'pendingConActive pointer' : 'pointer'}
              onClick={() => setIsPending(true)}
            >
              {userPendingRequest.length} {t('connections__pendignRequest')}
            </p>
            <div className={`ConnectionsPendingLine ${isPending ? 'active' : ''}`}></div>
          </div>

          <div className='ConnectionsPendingWrap'>
            <p
              className={!isPending ? 'pendingConActive pointer' : 'pointer'}
              onClick={() => setIsPending(false)}
            >
              {userSentRequest.length} {t('connections__sentRequest')}
            </p>
            <div className={`ConnectionsPendingLine ${!isPending ? 'active' : ''}`}></div>
          </div>
        </div>
      )}

      <div className="containerConnections">

        {(activeTab === 0) && (
          <div className="listConnections">
            <p className="countConnections">{userConnections.length} {t('connections__connections')}</p>

            {sortAlphabeticallyOnProperty(getFilteredList(userConnections), 'fullname', selectOrder).map((connection: any) => (
              <div className={`cardConnection ${(selectConnection(connection)) && 'cardActive'}`}>
                <div className="infoCard"
                  onClick={() => onClickMyConnections(connection)}
                >
                  <div className="imageConnection">
                    <img className="round-avatar" src={connection.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" />
                  </div>
                  <div className="infoConnection">
                    <label htmlFor="" className="nameConnection">{connection.fullname || `${connection.firstname} ${connection.lastname}`}</label>
                    <p className="countClubs">
                      {((connection.mutualClubs === 1) || (connection.common === 1)) ?
                        (connection.mutualClubs || connection.common || 0) + ' ' + t('connections__mutualClub')
                        :
                        (connection.mutualClubs || connection.common || 0) + ' ' + t('connections__mutualClubs')
                      }
                    </p>
                  </div>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon="ellipsis-v"
                    size='lg'
                    color={LAVENDER}
                    className="dots  "
                    onClick={() => handleDots(connection)}
                  />
                  {(openModal && (isCorrectDots(connection))) &&
                    (<ModalConnections connection={setConnection} filterType={getFilterType(connection)} setOpenModal={setOpenModal} isAdvisor={undefined} officers={undefined} advisors={undefined} username={undefined} reloadClub={undefined} ChatServiceInstance={ChatServiceInstance} />)}
                </div>
              </div>
            ))}
          </div>
        )}

        {(activeTab === 1) && (
          <div className="listConnections">
            <p className="countConnections">{userFriends.length} {t('connections__friends')}</p>

            {sortAlphabeticallyOnProperty(getFilteredList(userFriends), 'fullname', selectOrder).map((connection: any) => (
              <div className={`cardConnection ${(selectConnection(connection)) && 'cardActive'}`}>
                <div className="infoCard" onClick={() => onClickMyConnections(connection)}>
                  <div className="imageConnection">
                    <img className="round-avatar" src={connection.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" />
                  </div>
                  <div className="infoConnection">
                    <label htmlFor="" className="nameConnection">{connection.fullname || `${connection.firstname} ${connection.lastname}`}</label>
                    <p className="countClubs">
                      {((connection.mutualClubs === 1) || (connection.common === 1)) ?
                        (connection.mutualClubs || connection.common || 0) + ' ' + t('connections__mutualClub')
                        :
                        (connection.mutualClubs || connection.common || 0) + ' ' + t('connections__mutualClubs')
                      }
                    </p>
                  </div>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon="ellipsis-v"
                    size='lg'
                    color={LAVENDER}
                    className="dots  "
                    onClick={() => handleDots(connection)}
                  />
                  {(openModal && (isCorrectDots(connection))) &&
                    (<ModalConnections connection={setConnection} filterType={'delete'} setOpenModal={setOpenModal} isAdvisor={undefined} officers={undefined} advisors={undefined} username={undefined} reloadClub={undefined} ChatServiceInstance={ChatServiceInstance} />)}
                </div>
              </div>
            ))}
          </div>
        )}

        {activeTab === 2 && (
          <div className="listConnections">

            {(isPending) && sortAlphabeticallyOnProperty(getFilteredList(userPendingRequest), 'fullname', selectOrder).map((connection: any) => (
              <div className={`cardConnection ${(selectConnection(connection)) && 'cardActive'}`}>
                <div className="infoCard" onClick={() => onClickMyConnections(connection)}>
                  <div className="imageConnection">
                    <img className="round-avatar" src={connection.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" />
                  </div>
                  <div className="infoConnection">
                    <label htmlFor="" className="nameConnection">{connection.fullname}</label>
                    {(connection.unRegisteredUser) ?
                      (<p className="countClubs">
                        {t('setupStudent__unregisteredUser')}
                      </p>)
                      :
                      (<p className="countClubs">
                        {connection.mutualClubs === 1 ?
                          (connection.mutualClubs || 0) + ' ' + t('connections__mutualClub')
                          :
                          (connection.mutualClubs || 0) + ' ' + t('connections__mutualClubs')
                        }
                      </p>)
                    }
                  </div>
                </div>

                <div className="buttonGridContainer">
                  <div className="iconOptions buttonSaveFriendRequest" onClick={() => rejectRequest(connection.usernameFrom)}>
                    <FontAwesomeIcon
                      icon="times"
                      color={LAVENDER}
                      size="lg"
                    />
                  </div>
                  <div className="iconOptions buttonCancelFriendRequest" onClick={() => acceptFriend(connection.usernameFrom)}>
                    <FontAwesomeIcon
                      icon="check"
                      color={WHITE}
                      size="lg"
                    />
                  </div>
                </div>

              </div>
            ))}

            {(!isPending) && sortAlphabeticallyOnProperty(getFilteredList(userSentRequest), 'fullname', selectOrder).map((connection: any) => (
              <div className={`cardConnection ${(selectConnection(connection)) && 'cardActive'}`}>
                <div className="infoCard" onClick={() => onClickPending(connection)}>
                  <div className="imageConnection">
                    <img className="round-avatar" src={connection.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" />
                  </div>
                  <div className="infoConnection">
                    <label htmlFor="" className="nameConnection">{connection.fullname}</label>
                    {(connection.unRegisteredUser) ?
                      (<p className="countClubs">
                        {t('setupStudent__unregisteredUser')}
                      </p>)
                      :
                      (<p className="countClubs">
                        {connection.mutualClubs === 1 ?
                          (connection.mutualClubs || 0) + ' ' + t('connections__mutualClub')
                          :
                          (connection.mutualClubs || 0) + ' ' + t('connections__mutualClubs')
                        }
                      </p>)
                    }
                  </div>
                </div>

                <div>
                  <div className="buttonCancelSentRequest" onClick={() => cancelRequest(connection.usernameTo, connection)}>
                    {t('button__cancel')}
                  </div>
                </div>

              </div>
            ))}

          </div>
        )}
      </div>
      <ModalConnection
        show={showModalConnection}
        onHide={handleCloseModalDelete}
        info={infoConnection}
      />
    </>


  );
}

export default Index;