
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ClubList } from "../../../../interfaces/club/ClubList";

const ClubListOption = ({ club }: { club: ClubList}) => {
    const {id, idGroup, clubId} = useParams();
    const navigate = useNavigate();
    
    const organization = useSelector(
        (state: any) => state.getOrgs.organizationInfo
      );

    return (
        <ChatListWrapper 
            key={club.club_id}
            onClick={() => navigate(`/chat/conversation/${club.club_id}/${club.club_id}`)}
        >
            <ChatList>
                <Avatar 
                    active={club.club_id === id || club.club_id === clubId} 
                    src={organization.logo || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"}
                />
                <ClubName active={club.club_id === id || club.club_id === clubId}>{club.name || ''}</ClubName>
            </ChatList>
        </ChatListWrapper>
    );
}

export default ClubListOption;

const ChatList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 80px;
    height: 78px;
    gap: 5px;

    overflow-y: visible;
    overflow-x: hidden;

    /*overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;*/ //@todo ellipsis on clubs names :c
`;

interface ChatListWrapperProps {
    active: boolean;
}

const Avatar = styled.img`
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    border: ${(props: ChatListWrapperProps) => props.active ? '3px solid var(--lavender)' : '0px'};
`;

const ClubName = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: ${(props: ChatListWrapperProps) => props.active ? '700' : '400'};
    font-size: 14px;
    line-height: 16px;
    color: ${(props: ChatListWrapperProps) => props.active ? 'var(--lavender)' : 'var(--white)'};
    text-overflow: ellipsis;
`;

const ChatListWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background: transparent;
    cursor: pointer;
`;