import { Auth } from "aws-amplify";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { REFRESH_TOKEN_ERROR, REFRESH_TOKEN_SUCCESS, SAVE_TOKENS, START_REFRESH_TOKEN } from "../constants/tokenConstants";
import { userLogout } from "./userActions";

export const saveTokens = (accessToken: string, IDToken: string, refreshToken: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({
      type: SAVE_TOKENS,
      payload: {
        accessToken,
        IDToken,
        refreshToken
      }
    });

    localStorage.setItem(
      'token',
      accessToken
    );
    localStorage.setItem(
      'IDToken',
      IDToken
    );
    localStorage.setItem(
      'refreshToken',
      refreshToken
    );

  });

export const refreshTokens = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_REFRESH_TOKEN })
    Auth.currentSession().then((session) => {
      if (session.isValid()) {

        const accessToken = session.getAccessToken().getJwtToken()
        const IDToken = session.getIdToken().getJwtToken()
        const refreshToken = session.getRefreshToken().getToken()

        dispatch({
          type: REFRESH_TOKEN_SUCCESS,
          payload: {
            accessToken,
            IDToken,
            refreshToken
          }
        });

        localStorage.setItem(
          'token',
          accessToken
        );
        localStorage.setItem(
          'IDToken',
          IDToken
        );
        localStorage.setItem(
          'refreshToken',
          refreshToken
        );
        resolve()
      } else {
        dispatch({
          type: REFRESH_TOKEN_ERROR,
          payload: 'Session is no longer valid.'
        })
        dispatch(userLogout())
        resolve()
      }
    })
      .catch((e) => {
        dispatch({
          type: REFRESH_TOKEN_ERROR,
          payload: e
        })
        dispatch(userLogout())
        resolve()
      })
  });
