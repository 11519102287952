import "./styles.scoped.css";
import React, {useEffect, useState} from "react";
import {toast} from 'react-toastify';
import Posts from "../../containers/Posts"
import {useDispatch, useSelector} from "react-redux";
import {getMyClubs} from "../../store/actions/clubActions";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";

toast.configure();

function Index({ChatServiceInstance}:{ChatServiceInstance:ChatServiceInterface}) {
    const { t, i18n } = useTranslation();
    const [tabActive, setTabActive] = useState(0);
    const dispatch = useDispatch();
    const userInfo = useSelector((state: any) => state.getUser.userInfo);
    const organization = useSelector((state: RootState) => state.getOrgs.organizationInfo);

    useEffect(() => {
        if (userInfo.username) {
            dispatch<any>(getMyClubs(userInfo.username))
                .then(console.log)
                .catch(console.log);
        }
    }, [userInfo]);

    return (
        <div className="bulletin">
            <div className="tabsBulletin">
                <div
                    className={`bltTab bulletinFeed ${tabActive === 0 && "tabActive"}`}
                    onClick={() => setTabActive(0)}
                >
                    {t('post__feed')}
                </div>
                <div
                    className={`bltTab bulletinSchoolBulletins ${tabActive === 1 && "tabActive"
                    }`}
                    onClick={() => setTabActive(1)}
                >
                    {organization.type === 'YOUTHORG' ? t('post__organizationBulletin') : t('post__schoolBulletin')}
                </div>
                <div
                    className={`bltTab bulletinFriendsPosts ${tabActive === 2 && "tabActive"
                    }`}
                    onClick={() => setTabActive(2)}
                >
                    {t(`post__friendsPosts`)}
                </div>
                <div
                    className={`bltTab bulletinSavedPosts ${tabActive === 3 && "tabActive"
                    }`}
                    onClick={() => setTabActive(3)}
                >
                    {t('post__savedPost')}
                </div>
            </div>
            <Posts whereIs={'bulletin'} /*postInput={tabActive===0}*/ tabActive={tabActive} eventId={undefined} clubId={undefined} username={undefined} ChatServiceInstance={ChatServiceInstance}></Posts>
        </div>
    );
}

export default Index;
