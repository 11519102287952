import { useEffect, useState } from "react";
import "./styles.css";
import ClubIconsBar from "../../components/ClubIconsNavBar";
import ClubSelectBar from "../../components/ClubSelectBar";
import EventSelectBar from "../../components/EventSelectBar";
import ConnectionSelectBar from "../../components/ConnectionSelectBar";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { LeftPanel } from "../../containers/AppLayout/components";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";

interface ClubLeftSectionProps{
  //todo -> put types
  existEvents:any;
  isAdmin:any;
  ChatServiceInstance:ChatServiceInterface
}

function Index(props: ClubLeftSectionProps) {
  const { id = "" } = useParams();
  const location = useLocation();

  const [tabSelected, setTabSelected] = useState(1);
  const [showBorder, setShowBorder] = useState(false);
  const [selected, setSelected] = useState('Clubs');

  useEffect(() => {
    setShowBorder(
      (tabSelected === 0 && window.location.href.includes('event')) ||
      (tabSelected === 1 && window.location.href.includes('club')) ||
      (tabSelected === 2 && window.location.href.includes('conn'))
    )
  }, [window.location, tabSelected])

  useEffect(() => {
    if(location.pathname.includes('clubs')){
      setTabSelected(1)
      setSelected('Clubs')
    }
    else if(location.pathname.includes('events') && props.existEvents){
      setTabSelected(0)
      setSelected('Events')
    }
    else if(location.pathname.includes('connections')){
      setTabSelected(2)
      setSelected('Connections')
    }
    else if(props.existEvents){
      setTabSelected(0)
      setSelected('Events')
    }
    else{
      setTabSelected(1)
      setSelected('Clubs')
    }
  }, [props.existEvents])
  

  function handleOnClick(step: number) {
    setTabSelected(step);
  }

  useEffect(() => {
    if(id){
      setShowBorder(
        (tabSelected === 0 && window.location.href.includes('event')) ||
        (tabSelected === 1 && window.location.href.includes('club')) ||
        (tabSelected === 2 && window.location.href.includes('conn'))
      )
    }
  }, [id])

  return (
    <LeftPanel>
      <ClubIconsBar handleOnClick={handleOnClick} selected={selected}/>

      {tabSelected == 0 ?
          <EventSelectBar />
        :
        tabSelected == 1 ?
          <ClubSelectBar isAdmin={props.isAdmin}/>
        :
          tabSelected == 2 ?
          <ConnectionSelectBar ChatServiceInstance={props.ChatServiceInstance} />
        :  
          ''
      }
      
    </LeftPanel>
  );
}

export default Index;
