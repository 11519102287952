import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { applyTimeOffsetForLocal } from "../../helpers/dateHelper";
import { useAppDispatch } from "../../store/store";
import { WHITE } from "../ClubIconsNavBar";

function Index(props: any) { 
    const navigate = useNavigate();
    const dateTimeSplit:string[] = applyTimeOffsetForLocal((props.events.start || '-').split('-'), (props.events.timezone || '#:').split('#')[2].split(':')) || ['0','0','0','0','0'];
    
    return (
        <>
            <div className={`cardEvents ${props.id == props.events.timestamp && 'active'}`} onClick={() => navigate('../app/events/'+props.events.timestamp)}>
                <img src="https://images.ctfassets.net/bdyhigkzupmv/6lySzcy7qcIN1tf81Qkus/5b5ac73daeaf61f9057c0b0dd8447a31/hero-guitar-outro.jpg" alt="" />
                <div className="infoCardEvent">
                    <h2>{props.events.name}</h2>
                    <div>
                        <div className="infoLocationItem">
                            <FontAwesomeIcon
                            icon="map-marker-alt"
                            size='sm'
                            color={WHITE}
                            className="iconReaction"
                            />
                            {props.events.meeting_place}
                            </div>
                            <div className="infoLocationItem">
                            <FontAwesomeIcon
                                icon="calendar"
                                size='sm'
                                color={WHITE}
                                className="iconReaction"
                                />
                            {`${dateTimeSplit[0]}-${dateTimeSplit[1]}-${dateTimeSplit[2]}`} {`${(parseInt(dateTimeSplit[3]) % 12)===0 ? 12 : parseInt(dateTimeSplit[3]) % 12}:${dateTimeSplit[4]} ${parseInt(dateTimeSplit[3]) > 12 ? 'PM' : 'AM'}`}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
