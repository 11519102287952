import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getNameUser } from "../../helpers/userHelper";
import Property1AdminProperty2NoProperty3W from "../Property1AdminProperty2NoProperty3W";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";

interface LeftMenuProps {
  screen: string;
}

const index = ({ screen }: LeftMenuProps) => {
  const { t, i18n } = useTranslation();
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const user = useSelector((state: any) => state.getUser.user);
  const userRoles = useSelector((state: any) => state.getUser.userRoles);
  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  );

  const navigate = useNavigate();

  return (
    <div className="menu-sidebar">
      <div className="profile">
        <Property1AdminProperty2NoProperty3W
          logo={organization.logo}
        />
        <div className="superadmin admin-name-wrapper">
          {getNameUser(user, userInfo, userRoles, organization)}
        </div>
      </div>
      <nav className="menu1">
        <div
          className="section"
          style={{
            backgroundColor: screen === "/admin" ? "#250D44" : "transparent",
          }}
          onClick={() => navigate('/admin')}
        >
          <div className="section-cont">
            <FontAwesomeIcon
              icon="chart-bar"
              size='lg'
              color={WHITE}
              className="iconMenu  "
            />
            <span className="section-text robotocondensed-normal-white-16px">
              {t('menu__dashboard')}
            </span>
          </div>
        </div>
        <div
          className="section"
          style={{
            backgroundColor:
              screen === "/admin/my-school" ? "#250D44" : "transparent",
          }}
          onClick={() => navigate('/admin/my-school')}
        >
          <div className="section-cont">
            <FontAwesomeIcon
              icon="building"
              size='lg'
              color={WHITE}
              className="iconMenu  "
            />
            <span className="section-text robotocondensed-normal-white-16px">
            {(organization.type ==='YOUTHORG') ? t('menu__myOrganization'):t('menu__mySchool')}
            </span>
          </div>
        </div>

        <div
          className="section"
          style={{
            backgroundColor: screen.includes("/admin/communities")
              ? "#250D44"
              : "transparent",
          }}
          onClick={() => navigate('/admin/communities')}
        >
          <div className="section-cont">
            <FontAwesomeIcon
              icon="users"
              size='lg'
              color={WHITE}
              className="iconMenu iconUsers  "
            />
            <span className="section-text robotocondensed-normal-white-16px">
            {t('menu__communities')}
            </span>
          </div>
        </div>

        <div
          className="section"
          style={{
            backgroundColor: screen.includes("/admin/clubs")
              ? "#250D44"
              : "transparent",
          }}
          onClick={() => navigate('/admin/clubs')}
        >
          <div className="section-cont">
            <FontAwesomeIcon
              icon="users"
              size='lg'
              color={WHITE}
              className="iconMenu iconUsers  "
            />
            <span className="section-text robotocondensed-normal-white-16px">
            {t('menu__clubs')}
            </span>
          </div>
        </div>
        <div
          className="section"
          style={{
            backgroundColor: screen.includes("/admin/teams")
              ? "#250D44"
              : "transparent",
          }}
          onClick={() => navigate('/admin/teams')}
        >
          <div className="section-cont">
            <FontAwesomeIcon
              icon="users"
              size='lg'
              color={WHITE}
              className="iconMenu iconUsers  "
            />
            <span className="section-text robotocondensed-normal-white-16px">
            {t('menu__teams')}
            </span>
          </div>
        </div>

        <div
          className="section"
          style={{
            backgroundColor: screen.includes("/admin/users")
              ? "#250D44"
              : "transparent",
          }}
          onClick={() => navigate('/admin/users')}
        >
          <div className="section-cont">
            <FontAwesomeIcon
              icon="user"
              size='lg'
              color={WHITE}
              className="iconMenu iconUser  "
            />
            <span className="section-text robotocondensed-normal-white-16px">
            {t('menu__users')}
            </span>
          </div>
        </div>

        {(organization.type ==='YOUTHORG') &&(<div
          className="section"
          style={{
            backgroundColor: screen.includes("/admin/schools-youth")
              ? "#250D44"
              : "transparent",
          }}
          onClick={() => navigate('/admin/schools-youth')}
        >
          <div className="section-cont">
            <FontAwesomeIcon
              icon="school"
              size='lg'
              color={WHITE}
              className="iconMenu iconConciergeBell  "
            />
            <span className="section-text robotocondensed-normal-white-16px">
              My Schools
            </span>
          </div>
        </div>)}

        <div
          className="section"
          style={{
            backgroundColor: screen.includes("/admin/invites")
              ? "#250D44"
              : "transparent",
          }}
          onClick={() => navigate('/admin/invites')}
        >
          <div className="section-cont">
            <FontAwesomeIcon
              icon="concierge-bell"
              size='lg'
              color={WHITE}
              className="iconMenu iconConciergeBell  "
            />
            <span className="section-text robotocondensed-normal-white-16px">
            {t('menu__invites')}
            </span>
          </div>
        </div>

        <div
          className="section"
          style={{
            backgroundColor: screen.includes("/admin/events")
              ? "#250D44"
              : "transparent",
          }}
          onClick={() => navigate('/admin/events')}
        >
          <div className="section-cont">
            <FontAwesomeIcon
              icon="calendar"
              size='lg'
              color={WHITE}
              className="iconMenu iconConciergeBell  "
            />
            <span className="section-text robotocondensed-normal-white-16px">
            {t('menu__events')}
            </span>
          </div>
        </div>

        <div
          className="section"
          style={{
            backgroundColor: screen.includes("/admin/flagged")
              ? "#250D44"
              : "transparent",
          }}
          onClick={() => navigate('/admin/flagged')}
        >
          <div className="section-cont">
            <FontAwesomeIcon
              icon="flag"
              size='lg'
              color={WHITE}
              className="iconMenu iconConciergeBell  "
            />
            <span className="section-text robotocondensed-normal-white-16px">
            {t('menu__flags')}
            </span>
          </div>
        </div>

      </nav>
    </div>
  );
};

export default index;
