import "../../containers/SetupAdvisor/styles.scoped.css";
import { Interest } from "../../containers/SetupAdvisor";
import './styles.scoped.css';
import { WHITE } from "../ClubIconsNavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const filterActiveInterests = (interests: Interest[]) =>
  interests.filter((i) => i.active);

function Index(props: {
  myInterests: Interest[];
  setMyInterests: any;
  interests: Interest[];
  setInterests: any;
  handleOnClick: (e: any, step: any, save: boolean) => void;
}) {
  const { t, i18n } = useTranslation();
  //Handlers
  const handleManageInterest = (index: number, active: boolean) => {
    if (active) {
      const new_my_interests = [...props.myInterests]

      if (!new_my_interests.find((item) => item.id === index)) new_my_interests.push({
        id: index,
        title: (props.interests.find((item) => item.id === index) as Interest).title,
        active: true
      })
      props.setMyInterests(new_my_interests)

      const new_interests = [...props.interests]
      const n = new_interests.findIndex((item) => item.id === index);

      new_interests[n] = {
        id: index,
        title: new_interests[n].title,
        active: true
      }

      props.setInterests(new_interests)
    }
    else {
      const new_my_interests = props.myInterests.filter((item) => item.id !== index)
      props.setMyInterests(new_my_interests)

      const new_interests = [...props.interests]
      const n = new_interests.findIndex((item) => item.id === index);
      new_interests[n] = {
        id: index,
        title: new_interests[n].title,
        active: false
      }
      props.setInterests(new_interests)
    }
  }

  return (
    <>
      <div className="titles-header">
        <div className="titles">
          <div className="myProfile-Header">
            <h1>Zaahah</h1>
            <div className="myProfile-Buttons">
              <button className="button-skip interest" onClick={(e) => props.handleOnClick(e, 3, true)}>{t('setupSchool__skip')}</button>
              {props.myInterests.length > 0 ?
                <button className="button-start interest" onClick={(e) => props.handleOnClick(e, 3, true)}>{t('setupSchool__saveAndContinue')}</button>
                :
                ""
              }
            </div>
          </div>
          <span>{t('setupStudent__selectInterests')}</span>
        </div>

      </div>
      <div className="listInterestSelected">
        <div className="countInterested">
          {props.myInterests.length} / 5
        </div>
        {/* {props.myInterests.map((interest: any) => (
          <button>
            {interest.title}
            <FontAwesomeIcon
              icon="times"
              size='xl'
              color={WHITE}
              className="icon-times "
              onClick={() => handleManageInterest(interest.id, false)}
            />
          </button>
        ))} */}
      </div>
      <div className="listInterestsStaff">
        {props.interests.map((interest: any) => (
          <button
            className={interest.active ? "active" : ""}
            onClick={() => handleManageInterest(interest.id, interest.active ? false : true)}>
            {interest.title}
          </button>
        ))}
      </div>
    </>

  );
}

export default Index;
