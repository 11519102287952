import "../../styles.scoped.css";
import '../../styles.css';
import React, { Dispatch, KeyboardEventHandler, SetStateAction, useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalDiscardChanges from "./ModalDiscardChanges";
import { ActionMeta, OnChangeValue } from 'react-select';
import { LAVENDER } from "../../../ClubIconsNavBar";
import { getOrganizationType } from "../../../../store/actions/orgsActions";
import { Backend, MicroServices } from "../../../../helpers/backendHelper";
import { getTemplateCategories } from "../../../../store/actions/templateCategoryActions";
import CreatableSelect from "react-select/creatable";
import { MEMBER_MAP } from "../../../CreateTemplateClub";
import { getTemplateClubs } from "../../../../store/actions/templateClubActions";
import useGrades from "../../../../hooks/useGrades";
import useGender from "../../../../hooks/useGender";
import { useTranslation } from "react-i18next";
import TClub from "../../../../interfaces/TClubs/TClub/TClub";

toast.configure()

function ModalEditClub(props: {show:boolean,setEdit:Dispatch<SetStateAction<TClub | undefined>>,club?:TClub}) {

  const dispatch = useDispatch();

  const { club, setEdit } = props

  const { t, i18n } = useTranslation();
  useEffect(() => {
    dispatch<any>(getOrganizationType());
  }, []);

  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  );

  const [loadingClub, setLoadingClub] = useState(false);
  const [showDiscard, setShowDiscard] = useState(false);

  const [clubTitle, setClubTitle] = useState(club?.name || '');
  const [filterType, setFilterType] = useState('ALL');
  const [clubType, setClubType] = useState<string>(club?.club_type || 'CLUB');
  const [description, setDescription] = useState(club?.description || '');
  const [category, setCategory] = useState('Academic Competition');
  const [memberType, setMemberType] = useState<string[]>(club?.member_types || ["STUDENT"]);
  const [gendersSelected, setGendersSelected] = useState<string[]>(club?.youth_orgs?.find((yo)=>yo.yo_id === organization.YOTableId)?.genders_allowed || []);
  const [gradesAllowed, setGradesAllowed] = useState<number[]>(club?.youth_orgs?.find((yo)=>yo.yo_id === organization.YOTableId)?.grades_allowed?.map((i)=>parseInt(i)) || [])
  const [skillsLearned, setSkillsLearned] = useState<string[]>(club?.tags || []);

  useEffect(()=>{
    setLoadingClub(false);
    setShowDiscard(false);
    setClubTitle(club?.name || '')
    setFilterType('ALL')
    setClubType(club?.club_type || 'CLUB')
    setDescription(club?.description || '')
    setCategory('Academic Competition')
    setMemberType(club?.member_types || ["STUDENT"])
    setSkillsLearned(club?.tags || [])
    setInputValue('')
    setValue(club?.tags?.map(createOption) || [])
    setGendersSelected(club?.youth_orgs?.find((yo)=>yo.yo_id === organization.YOTableId)?.genders_allowed || []);
    setGradesAllowed(club?.youth_orgs?.find((yo)=>yo.yo_id === organization.YOTableId)?.grades_allowed?.map((i)=>parseInt(i)) || []);
  },[club])

  const createOption = (label: string) => ({
    label,
    value: label,
  });

  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<OnChangeValue<Option, true>>(club?.tags?.map(createOption) || []);

  const { grades, setCountry: setCountryGrades } = useGrades();
  const { genders, setCountry: setCountryGenders } = useGender();

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryGrades(organization?.countryCode);
      setCountryGenders(organization?.countryCode);
    }
  }, []);

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryGrades(organization?.countryCode);
      setCountryGenders(organization?.countryCode);
    }
  }, [organization]);

  const editClub = () => {

    if(!club){
      setEdit(undefined)
      return
    }

    const body = JSON.stringify({
      'name': clubTitle,
      'club_type': clubType,
      'tags': skillsLearned,
      description,
      'member_types': memberType,
      'role_assigned': "NO_ROLE_ASSIGNED",
      youth_orgs: [
        ...(club?.youth_orgs?.filter((yo)=>yo.yo_id!==organization.YOTableId) || []),
        {
          yo_id: organization.YOTableId,
          genders_allowed: gendersSelected,
          grades_allowed: gradesAllowed,
        }
      ]
    });

    Backend(
      MicroServices.Club,
      `/tclubs/${club!.id}`, {
      method: 'PUT',
      body,
    })
      .then((results) => {
        if (results.status === 201) {
          toast.success(`${clubTitle} ${t('toast_edited')}`);
          onCancel('discard');
          dispatch(getTemplateClubs(organization.id));
        } else {
          toast.error(`${t('toast_errorEditing')} ${clubTitle}. ${t('toast_tryAgain')}`)
        }
        setLoadingClub(false);
      })
      .catch((e) => {
        toast.error(`${t('toast_errorEditing')} ${clubTitle}. ${t('toast_tryAgain')}`)
        setLoadingClub(false);
      })
  }

  const onCancel = (type: string) => {
    if (type === 'cancel') {
      setShowDiscard(false);
    }
    else {
      setLoadingClub(false);
      setShowDiscard(false);

      setClubTitle('')
      setFilterType('ALL')
      setClubType('CLUB')
      setDescription('')
      setCategory('Academic Competition')
      setMemberType(["STUDENT"])
      setSkillsLearned([])
      setInputValue('')
      setValue([])
      setGendersSelected([]);
      setGradesAllowed([]);

      setEdit(undefined);
    }
  }

  const onhideModal = () => {
    setShowDiscard(true);
  }

  const handleSave = () => {
    setLoadingClub(true);

    if (clubTitle === '') {
      setLoadingClub(false);
      toast.error(t('toast_pleaseAddClubTitle'));
      return;
    }

    if (filterType === '') {
      setLoadingClub(false);
      toast.error(t('toast_pleaseSelectFilterType'));
      return;
    }

    if (clubType === '') {
      setLoadingClub(false);
      toast.error(t('toast_pleaseSelectClubType'));
      return;
    }

    if (description === '') {
      setLoadingClub(false);
      toast.error(t('toast_pleaseAddClubDesc'));
      return;
    }

    if (category === '') {
      setLoadingClub(false);
      toast.error(t('toast_pleaseSelectCategory'));
      return;
    }

    if (memberType.length === 0) {
      setLoadingClub(false);
      toast.error(t('toast_pleaseSelectMembersType'));
      return;
    }

    if (gendersSelected.length === 0) {
      setLoadingClub(false);
      toast.error(t('toast_pleaseSelectGengers'));
      return;
    }

    if (skillsLearned.length === 0) {
      setLoadingClub(false);
      toast.error(t('toast_pleaseSelectSkill'));
      return;
    }

    editClub();
  }

  const categories = useSelector((state: any) => state.getTemplateCategories.TCategories || []);

  useEffect(() => {
    dispatch<any>(getTemplateCategories())
  }, []);

  const categoriesOptions = categories.map((e: any) => {
    return {
      label: e.name,
      value: e.filterType + `_` + e.name,
    }
  })

  const handleCheckBox = (event: any) => {

    const { value, checked } = event.target;

    if (checked) {
      setMemberType([...memberType, value])
    } else {
      setMemberType(memberType.filter((e) => e !== value));
    }
  }

  const handleCheckBoxGender = (event: any) => {

    const { value, checked } = event.target;

    if (checked) {
      setGendersSelected([...gendersSelected, value])
    } else {
      setGendersSelected(gendersSelected.filter((e) => e !== value));
    }
  }

  const handleCheckGradesAllowed = (event: any) => {
    const { value, checked } = event.target;

    if (checked) {
      setGradesAllowed([...gradesAllowed, Number(value)])
    } else {
      setGradesAllowed(gradesAllowed.filter((e) => e !== Number(value)));
    }
  }

  const components = {
    DropdownIndicator: null,
  };

  const handleInputChange = (inputValue: any) => {
    setInputValue(inputValue);
  };

  interface Option {
    readonly label: string;
    readonly value: string;
  }


  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setInputValue('');
        setValue([...value, createOption(inputValue)])
        event.preventDefault();

        setSkillsLearned([...skillsLearned, inputValue]);

    }
  }

  const handleChange = (
    value: OnChangeValue<Option, true>,
    actionMeta: ActionMeta<Option>
  ) => {
    console.group('Value Changed');
    console.log(value);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
    setValue(value);
  };

  return (
    <>

      <Modal className="modalInviteMemebers" show={props.show} onHide={onhideModal}>
        <Modal.Header>
          <Modal.Title className="modalAddClub-shorter">{`Edit Information of ${clubTitle}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-schoolAdd">

            {/*<div className="schoolAdd-row">
              <div className="inputs-schoolAdd">
                <label className="title-input" htmlFor="i-principal">
                  Type
                </label>
                <div className="select-list-addSchool" >

                  <select className="select-addSchool"
                    onChange={(e) => setClubType(e.target.value)}
                    value={clubType} >
                    <option selected disabled>Club Type</option>
                    <option value="CLUB">Club</option>
                    <option value="TEAM">Team</option>
                  </select>

                  <FontAwesomeIcon
                    icon="chevron-down"
                    size='lg'
                    color={LAVENDER}
                    className="chevron-down  "

                  />
                </div>
              </div>

              <div className="grades-display">
                <label className="title-input" htmlFor="i-principal">
                  Category
                </label>
                <div className="select-list-addSchool" >

                  <select className="select-addSchool"
                    onChange={(e) => setCategory(e.currentTarget.value)}
                    value={category} >
                    <option selected disabled>Category</option>
                    {categoriesOptions.map((categoryOption: any) =>
                      (<option value={categoryOption.value} label={categoryOption.label}></option>)
                    )}
                  </select>

                  <FontAwesomeIcon
                    icon="chevron-down"
                    size='lg'
                    color={LAVENDER}
                    className="chevron-down  "

                  />
                </div>
                    </div>
            </div>

            <div className="schoolAdd-row">

              <div className="inputs-schoolAdd">
                <label className="title-input" htmlFor="i-principal">
                  Filter Type
                </label>

                <div className="select-list-addSchool">
                  <select className="select-addSchool"
                    onChange={(e) => setFilterType(e.target.value)}
                    value={filterType}>
                    <option selected disabled>Type Category</option>
                    <option value="ALL">All</option>
                    <option value="SCHOOL">School</option>
                    <option value="YOUTH">Youth</option>
                  </select>
                  <FontAwesomeIcon
                    icon="chevron-down"
                    size='lg'
                    color={LAVENDER}
                    className="chevron-down  "

                  />
                </div>

              </div>
            </div>*/}

            <div className="schoolAdd-row clublAdd-column">
              <label className="title-input" htmlFor="i-principal">
                Type*
              </label>
              <div className="select-list-addSchool oneColumn-ModalClub" >

                <select className="select-addSchool"
                  onChange={(e) => setClubType(e.target.value)}
                  value={clubType} >
                  <option selected disabled>Club Type</option>
                  <option value="CLUB">Club</option>
                  <option value="TEAM">Team</option>
                  {/*<option value="COMM">Community</option>*/}
                </select>

                <FontAwesomeIcon
                  icon="chevron-down"
                  size='lg'
                  color={LAVENDER}
                  className="chevron-down  "

                />
              </div>
            </div>

            <div className="schoolAdd-row clublAdd-column">
              <label className="title-input" htmlFor="i-principal">
                Name*
              </label>
              <input
                type="text"
                value={clubTitle}
                onChange={(e) => setClubTitle(e.target.value)}
              />
            </div>

            <div className="schoolAdd-row clublAdd-column">
              <label className="title-input" htmlFor="i-principal">
                Description*
              </label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>

            <div className="schoolAdd-row schoolAdd-column">
              <label className="title-input title-input-skills-learned" htmlFor="i-principal">
                Skills learned*
              </label>
              <div className="inputs-schoolAdd">
                <CreatableSelect
                  components={components}
                  inputValue={inputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={handleChange}
                  onInputChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder=""
                  value={value}
                  styles={{
                    singleValue: (base) => ({
                      ...base,
                      display: "flex",
                      alignItems: "center",
                      color: 'white',
                      backgroundColor: 'red',
                    }),
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: '#2c2c2c',
                      borderRadius: "3px",
                      boxShadow: "none",
                      minWidth: "50%",
                      border: "1px solid #a885d7"
                    }),
                    option: (styles) => ({
                      ...styles,
                      color: 'white',
                      backgroundColor: 'var(--violent-violet)',
                      display: 'flex',
                      flexDirection: 'row',
                    }),
                    placeholder: (styles) => ({
                      ...styles,
                      color: 'white',
                      textAlign: "left"
                    }),
                    container: (styles) => ({
                      ...styles,
                      padding: 0,
                      width: "100%"
                    }),
                    menu: (styles) => ({
                      ...styles,
                      borderRadius: "8px",
                      backgroundColor: "var(--violent-violet)",
                    }),
                    input: (styles: any, state: any) => ({
                      ...styles,
                      border: 'none',
                      color: 'white',
                    }),
                    dropdownIndicator: (styles) => ({ ...styles, display: "none" }),
                    indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
                    multiValue: (styles) => ({
                      ...styles,
                      color: 'white',
                      backgroundColor: 'var(--electric-violet-2)',
                    }),
                    multiValueLabel: (styles) => ({
                      ...styles,
                      color: 'white',
                      fontFamily: 'Roboto Condensed',
                      fontSize: '16px'
                    }),
                    multiValueRemove: (styles) => ({
                      ...styles,
                      color: 'white',
                      backgroundColor: "transparent"
                    }),
                  }}
                />

              </div>
            </div>

            <div className="schoolAdd-row schoolAdd-column">
              <label className="title-input" htmlFor="i-principal">
                Member Type*
              </label>
              <div className="select-container-status">
                {Object.entries(MEMBER_MAP).map(([memberKey, memberValue]) => (
                  <div className="option-status">
                    <label className="content-input">
                      <input
                        type="checkbox"
                        name="button"
                        value={memberKey}
                        onChange={handleCheckBox}
                        checked={memberType.includes(memberKey)}
                      />
                      <i></i>
                    </label>
                    <label htmlFor="students">{memberValue}</label>
                  </div>
                ))}
              </div>
            </div>

            <div className="schoolAdd-row schoolAdd-column">
              <label className="title-input" htmlFor="i-principal">
                Gender*
              </label>
              <div className="select-container-status">
                {genders.map(({id: genderKey, label: genderValue}) => (
                  <div className="option-status">
                    <label className="content-input">
                      <input
                        type="checkbox"
                        name="button"
                        value={genderKey}
                        onChange={handleCheckBoxGender}
                        checked={gendersSelected.includes(genderKey)}
                      />
                      <i></i>
                    </label>
                    <label htmlFor="students">{genderValue}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="schoolAdd-row schoolAdd-column">
              <label className="title-input" htmlFor="i-principal">
                Grades*
              </label>
              <div className="select-container-status">
                {organization && Array.from(Array(1 + Number(organization.highGrade) - Number(organization.lowGrade))).map((_, idx) => (
                  <div className="option-chechbox">
                    <label className="content-input">
                      <input
                        type="checkbox"
                        name={`checkbox-${idx + Number(organization.lowGrade)}`}
                        value={idx + Number(organization.lowGrade)}
                        checked={gradesAllowed.includes(idx + Number(organization.lowGrade))}
                        onChange={handleCheckGradesAllowed}
                      />
                      <i></i>
                    </label>
                    <label htmlFor="students">{grades.find((grade) => grade.id === (idx + Number(organization.lowGrade)))?.listLabel ?? (idx + Number(organization.lowGrade))}</label>
                  </div>
                ))}
              </div>
            </div>
            

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="buttonClose"
            onClick={() => setShowDiscard(true)}
          >
            Cancel
          </Button>
          <Button name="btnSaveAdvisor" className="buttonSend" onClick={handleSave}>
            {loadingClub ? <div className="icon-spinner-third buttonSendSpinner" /> : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalDiscardChanges show={showDiscard} onHide={onCancel} />
    </>
  );
}

export default ModalEditClub;
