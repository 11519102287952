import "./styles.scoped.css";
import React, { useState, useEffect } from "react";
import ListComments from '../../components/Post/ListComments';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import ListEmojis from "../../components/Post/ListEmojis";
import PostLinkPreview from "../../components/PostLinkPreview";
import PostEmbedPreview from "../../components/PostEmbedPreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
import { timeDelay } from "../../helpers/dateHelper";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { useTranslation } from "react-i18next";
interface IReaction {
    author: string;
    post_id: string;
    type: 'like' | 'love' | 'laugh' | 'surprise' | 'cry' | 'mad';
    created_at: string;
}

interface IComment {
    author: string;
    post_id: string;
    content: string;
    created_at: string;
}

interface IFile {
    name: string;
    link: string;
    media_id: string;
    type: string;
}
interface IPost {
    post_id: string;
    author: string;
    type: string;
    reach: string;
    reach_id: string;
    content: string;
    org_id: string;
    is_flagged: boolean;
    author_name: string;
    author_avatar: string;
    created_at: string;
    reactions: IReaction[];
    comments: IComment[];
    files?: IFile[];
    link?: string | undefined;
    reach_name?: string;
    eventName?: string | undefined;
    eventTimestamp?: string | undefined;
    eventLocation?: string | undefined;
}

function PostDetail() {
    const { t, i18n } = useTranslation();
    //States & Consts
    const { id } = useParams()
    const user = useSelector((state: any) => state.getUser.user);

    const [post, setPost] = useState<IPost>({
        post_id: '',
        author: '',
        type: '',
        reach: '',
        reach_id: '',
        content: '',
        org_id: '',
        is_flagged: false,
        author_name: '',
        author_avatar: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
        created_at: '',
        reactions: [],
        comments: [],
        files: undefined,
        link: '',
        eventName: "",
        eventTimestamp: "",
        eventLocation: "",
    })

    const [newComment, setNewComment] = useState<string>('')

    const [reactionEmojis, setReactionEmojis] = useState<('like' | 'love' | 'laugh' | 'surprise' | 'cry' | 'mad')[]>([])

    const [updatePost, setUpdatePost] = useState<boolean>(false)

    const [userReaction, setUserReaction] = useState<('like' | 'love' | 'laugh' | 'surprise' | 'cry' | 'mad' | '')>('')

    const [imagePosition, setImagePosition] = useState<number>(0)

    const [allComments, setAllComments] = useState<boolean>(false)

    //Functions
    const reactionToEmoji = {
        'like': "/like.png",
        'love': "/heart_eyes.png",
        'laugh': "/joy.png",
        'surprise': "/scream.png",
        'cry': "/cry.png",
        'mad': "/rage.png"
    }

    //Effects
    useEffect(() => {
        Backend(
            MicroServices.Posts,
            `/post?post_id=${id}`)
            .then((results) => results.json())
            .then((results) => {
                setPost(results)
            })
            .catch((error) => console.log(error))
    }, [id, updatePost])

    useEffect(() => {
        Backend(
            MicroServices.Posts,
            `/post/${post.post_id}/reaction?username=${user.username}`)
            .then((results) => results.json())
            .then((results) => {
                setUserReaction(results !== null ? results.type : '')
                setUpdatePost(!updatePost)
            })
            .catch((error) => console.log(error))
    }, [user])

    useEffect(() => {
        setReactionEmojis(['like', 'love', 'laugh', 'surprise', 'cry', 'mad'].filter((item) => post.reactions.findIndex((item2) => item2.type === item) !== -1) as ('like' | 'love' | 'laugh' | 'surprise' | 'cry' | 'mad')[])
    }, [post])

    //Handlers
    const handleSaveComment = (e: any) => {
        if (e.key !== 'Enter') return
        e.preventDefault()
        if (newComment && newComment !== '') Backend(
            MicroServices.Posts,
            `/comment`, {
            method: "POST",
            body: JSON.stringify({
                username: user.username,
                post_id: id,
                content: newComment
            }),
        })
            .then((results) => {
                if (results.status === 201) {
                    setNewComment('')
                    setUpdatePost(!updatePost)
                } else {
                    toast.error(t('toast_errorCommentingPost'))
                }
            })
            .catch((error) => {
                console.log(error)
                toast.error(t('toast_errorCommentingPost'))
            })
    }

    const mod = (n: number, m: number): number => m !== 0 ? ((n % m) + m) % m : 0;
    const handleChangeImage = (next: boolean) => {
        if (next) {
            setImagePosition(mod(imagePosition + 1, post.files?.length || 0))
        } else {
            setImagePosition(mod(imagePosition - 1, post.files?.length || 0))
        }
    }

    console.log(post)
    return (
        <div className="postDetail">
            <div className="postImage">
                <div className={`contentImage ${(post.type === 'TEXT') ? 'contentOnlyText' : ''}`}>

                    {(post.files && post.files.length > 0) ? (
                        (post.type === 'VIDEO' || post.type === 'MIXED') ?
                            (<video
                                className="VideoInput_video"
                                width="100%"
                                height="400px"
                                controls
                                src={post.files[0].link}
                            />)
                            :
                            (post.type === 'IMG') ?
                                (
                                    <>
                                        {post.files.length > 1 ? (
                                            <div className="btnArrow dtnLeft">
                                                <FontAwesomeIcon
                                                    icon="chevron-left"
                                                    size='xl'
                                                    color={LAVENDER}
                                                    className="chevron  "
                                                    onClick={() => handleChangeImage(false)}
                                                />
                                            </div>
                                        ) : ''}
                                        <img src={post.files[imagePosition].link || "https://isenacode.com/wp-content/uploads/2020/12/IMG_0144.jpg"} alt="" />
                                        {post.files.length > 1 ? (
                                            <div className="btnArrow dtnRight">
                                                <FontAwesomeIcon
                                                    icon="chevron-right"
                                                    size='xl'
                                                    color={LAVENDER}
                                                    className="chevron  "
                                                    onClick={() => handleChangeImage(true)}
                                                />
                                            </div>
                                        ) : ''}
                                    </>
                                )
                                : (
                                    <p>
                                        <div className="postDescription">
                                            {/*{post.content}*/}
                                            <p dangerouslySetInnerHTML={{ __html: post.content }}></p>
                                        </div>
                                    </p>))
                        :
                        <p>
                            <div className="postDescription">
                                {/*{post.content}*/}
                                <p dangerouslySetInnerHTML={{ __html: post.content }}></p>
                            </div>
                            {post.type === "LINK" && (<PostLinkPreview url={post.link}></PostLinkPreview>)}
                            {post.type === "EMBED" && (<PostEmbedPreview embedLink={post.link}></PostEmbedPreview>)}
                        </p>
                    }

                    <div className="btnReactions" data-v-5a47a937="">
                        <button className="emoji" data-v-5a47a937="">
                            {(userReaction === '') ?
                                (<FontAwesomeIcon
                                    icon="fire"
                                    size='lg'
                                    color={WHITE}
                                    className="iconReaction  "
                                />) :
                                (<div className="emojiIcon">
                                    <img src={reactionToEmoji[userReaction]} alt="" />
                                </div>)}
                            <ListEmojis post_id={post.post_id} />
                            {post.reactions.length}
                        </button>
                        <button className="comments" data-v-5a47a937="">
                            <FontAwesomeIcon
                                icon={'comment'}
                                color={WHITE}
                                size='lg'
                                data-v-5a47a937=""
                            />
                            {post.comments.length}
                        </button>
                        <button className="comments" data-v-5a47a937="">
                            <FontAwesomeIcon
                                icon={'comment'}
                                color={WHITE}
                                size='lg'
                                data-v-5a47a937=""
                            />
                            {/*TODO || */ 0}
                        </button>
                    </div>
                </div>
            </div>

            <div className="infoPost">
                <div className="profileInfo">
                    <img className="round-avatar" src={post.author_avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" />
                    <div className="nameProfile">
                        <span> {post.author_name} </span>
                        <label htmlFor="time">{`${post.reach_name || ''} - ${timeDelay(post.created_at)}`}</label>
                    </div>
                    <button className="btnEllipsis">
                        <FontAwesomeIcon
                            icon={'ellipsis-v'}
                            color={WHITE}
                            size='xl'
                            data-v-5a47a937=""
                        />
                    </button>
                </div>
                <div className="postDescription ellps">
                    {/*{post.content}*/}
                    <p dangerouslySetInnerHTML={{ __html: post.content }}></p>
                </div>
                <div className="countActivity">
                    <div className="countReactions">
                        {reactionEmojis.map((emoji) => (
                            <div className="emojiIcon">
                                <img src={reactionToEmoji[emoji]} alt="" />
                            </div>
                        ))}
                        <div className="countNum">
                            {post.reactions.length}
                        </div>
                    </div>
                    <div className="countComments">
                        {post.comments.length} Comments
                    </div>
                </div>
                {allComments ? (
                    <>
                        {post.comments.map((comment) => (
                            <div className="listComments">
                                <div className="listComments">
                                    {/* <ListComments image={true} comment={undefined} /> */}
                                </div>
                            </div>
                        ))}
                    </>
                ) : post.comments.length > 0 ?
                    (
                        <>
                            <div className="listComments">
                                {/* <ListComments image={false} comment={undefined} /> */}
                            </div>
                        </>
                    ) : <div className="allComments">
                        <p>Be the first one to comment something!</p>
                        <FontAwesomeIcon
                            icon="chevron-down"
                            size='lg'
                            color={LAVENDER}
                            className="chevron-down  "
                        />
                    </div>}
                {(post.comments.length > 1 && !allComments) ? (
                    <div className="allComments">
                        <p>View all comments</p>
                        <FontAwesomeIcon
                            icon="chevron-down"
                            size='lg'
                            color={LAVENDER}
                            className="chevron-down  "
                            onClick={() => setAllComments(true)}
                        />
                    </div>
                ) : ''}
                <div className="inputComment">
                    <FontAwesomeIcon
                        icon="paperclip"
                        size='lg'
                        color={WHITE}
                        className="iconPaperclip  "

                    />
                    <input type="text" placeholder="Comment this post"
                        value={newComment}
                        onChange={(e: any) => setNewComment(e.target.value)}
                        onKeyDown={handleSaveComment} />
                </div>
            </div>
        </div>
    );
}

export default PostDetail;