import React, { useState, useEffect } from "react";
import Header from "../Header";
import styled from "styled-components";
import {
  RobotocondensedRegularNormalWhite16,
  Fontawesome5proRegularNormalLavende,
  RobotocondensedBoldLavender16px,
} from "../../styledMixins";
import "./styles.css";
import SuperAdminLeftMenu from "../SuperAdminLeftMenu";
import AdminLeftMenu from "../AdminLeftMenu";
import "../../styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../store/actions/userActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
import { useTranslation } from "react-i18next";
import { unixToDateFormatted } from "../../helpers/dateHelper";

function Organizations() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id = "" } = useParams();

  const user = useSelector((state: any) => state.getUser.user);

  const genderList: any = {
    'M': 'Male',
    'F': 'Female',
    'O': 'Non binary', //'Non binary',
    'P': 'Prefer not to disclose', //'Prefer not to disclose',
  };

  const rolesList: any = {
    'ZAA#ALUMNI': 'Alumni',
    'ZAA#GUARDIAN': 'Guardian',
    'ZAA#STAFF': 'Staff',
    'ZAA#ORGADMIN': 'Admin',
    'ZAA#SUPERADMIN': 'Superadmin',
    'ZAA#STAFF#ADVISOR': 'Staff Advisor',
    'ZAA#STUDENT': 'Student',
  };

  useEffect(() => {
    dispatch<any>(getUser(id))
      .then(console.log)
      .catch(console.error)
  }, []);

  return (

    <div className="content">
      <div className="top-cont">
        <div className="organization robotocondensed-normal-white-16px">{t('manageUser__title')}</div>
        <div className="text-1 robotocondensed-normal-white-16px">/</div>
        <div className="view-organization robotocondensed-bold-lavender-16px">
          {t('manageUser__viewUser')}
        </div>
      </div>
      <div className="org-view-cont org-view-cont-user">
        <div className="title-column">
          <h1 className="title-name">{user.firstname} {user.lastname}</h1>
          <div className="buttons-box">
            <div className="button-p">
              <div className="update roboto-normal-white-16px" onClick={() => navigate('../users/' + user.username + '/update')}>{t('button__update')}</div>
            </div>
          </div>
        </div>
        <div className="row row-1">
          <div className="col-1">
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__firstName')}</div>
              <div className="info-field">
                <div className="roboto-normal-white-16px">{user.firstname}</div>
              </div>
            </div>
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__lastName')} </div>
              <div className="info-field">
                <div className="roboto-normal-white-16px">{user.lastname}</div>
              </div>
            </div>
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">{t('profile__gender')}</div>
              <div className="info-field">
                <div className="roboto-normal-white-16px">{genderList[user.gender]}</div>
              </div>
            </div>
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">{t('setupStaff__birthday')}</div>
              <div className="info-field">
                <div className="roboto-normal-white-16px">{user.birthdate}</div>
              </div>
            </div>
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">{t('modalAddAdvisor__email')}</div>
              <div className="info-field">
                <div className="roboto-normal-white-16px">{user.email}</div>
              </div>
            </div>
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageUser__userRole')} </div>
              <div className="info-field listRoles">
                <div className="roboto-normal-white-16px">
                  <ul>
                    {user.rolesByOrg[0].map((request: any) => (
                      <li>{rolesList[request]}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">{t('profile__phoneNumber')} </div>
              <div className="info-field">
                <div className="roboto-normal-white-16px">{user.phoneNumber}</div>
              </div>
            </div>
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">{t('profile__city')}</div>
              <div className="info-field">
                <div className="roboto-normal-white-16px">{user.city}</div>
              </div>
            </div>
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__postalCode')} </div>
              <div className="info-field">
                <div className="roboto-normal-white-16px">{user.zipcode}</div>
              </div>
            </div>
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__address')}</div>
              <div className="info-field">
                <div className="roboto-normal-white-16px"></div>
              </div>
            </div>
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__state')}</div>
              <div className="info-field">
                <div className="roboto-normal-white-16px"></div>
              </div>
            </div>
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageUser__countryCode')} </div>
              <div className="info-field">
                <div className="roboto-normal-white-16px">{user.country}</div>
              </div>
            </div>
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageInvites__status')}</div>
              <div className="info-field">
                <div className="roboto-normal-white-16px">{user.status}</div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <h1 className="title-col-2">{t('manageUser__registration')}</h1>
        <div className="row row-1">
          <div className="col-1">
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageUser__profiles')}</div>
              <div className="info-field infoDisabled">
                <div className="roboto-normal-white-16px">{user.firstname} {user.lastname}</div>
              </div>
            </div>
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">{t('profile__username')}</div>
              <div className="info-field infoDisabled">
                <div className="roboto-normal-white-16px">{user.username}</div>
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageUser__createdOn')}</div>
              <div className="info-field infoDisabled">
                <div className="roboto-normal-white-16px">{unixToDateFormatted(user.creationDate)}</div>
              </div>
            </div>
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageUser__updateOn')} </div>
              <div className="info-field infoDisabled">
                <div className="roboto-normal-white-16px"></div>
              </div>
            </div>
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageInviteView__organization')}</div>
              <div className="info-field infoDisabled">
                <div className="roboto-normal-white-16px">
                  <ul>
                    {user.organizationsNames.map((request: any) => (
                      <li>{request}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        {/*<div className="title-column">
          <h1 className="title-name">{t('manageUser__family')}</h1>
          <div className="buttons-box">
            <div className="button-p">
              <div className="editPurple roboto-normal-white-16px">{t('manageUser__editFamily')}  </div>
            </div>
          </div>
        </div>
        <div className="row row-1">
          <div className="col-1">
            <div className="family-row">
              <div className="family-row-left">
                <div className="img-container">
                  <img className="prof-pic" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" alt="profile-pic" />
                </div>
                <div className="family-column">
                  <div className="label-cont-family robotocondensed-bold-lavender-16px">Alexander Supertramp</div>
                  <div className="roboto-normal-white-16px">Father</div>
                  <div className="roboto-normal-white-16px">alex@supertramp.com</div>
                </div>
              </div>
              <div className="family-row-right">Approved</div>
            </div>
            <div className="family-row">
              <div className="family-row-left">
                <div className="img-container">
                  <img className="prof-pic" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" alt="profile-pic" />
                </div>
                <div className="family-column">
                  <div className="label-cont-family robotocondensed-bold-lavender-16px">Ana Ortiz</div>
                  <div className="roboto-normal-white-16px">Mother</div>
                  <div className="roboto-normal-white-16px">some@mother.com</div>
                </div>
              </div>
              <div className="family-row-right">Approved</div>
            </div>
          </div>
        </div>
        <div className="row row-1 rowEnd">
          <div className="colRelation">
            <div className="addRelation selectEmail">
              <span>{t('modalAddAdvisor__email')}</span>
              <div className="containerSelect">
                <select name="" id="">
                  <option value="">{t('manageUser__selectEmail')}</option>
                </select>
                <FontAwesomeIcon
                  icon="chevron-down"
                  size='lg'
                  color={LAVENDER}
                  className="chevron-down  "
                />
              </div>
            </div>
            <div className="addRelation selectRelation">
              <span>{t('manageUser__relationship')}</span>
              <div className="containerSelect">
                <select name="" id="">
                  <option value="">{t('manageUser__selectRelationship')}</option>
                </select>
                <FontAwesomeIcon
                  icon="chevron-down"
                  size='lg'
                  color={LAVENDER}
                  className="chevron-down  "
                />
              </div>
            </div>
          </div>
          <div className="buttons-box-white">
            <div className="">
              <div className="">{t('manageUser__addRelation')}</div>
            </div>
          </div>
        </div>*/}
        <div className="spacing40"></div>
      </div>
    </div>
  );
}

const ContentOrganizations = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  width: 100%;
  z-index: 1;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  padding: 30px 1000px;
  align-items: center;
  min-height: 944px;
  background-color: var(--violet);
`;

const ContentUsers = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  width: 1298px;
  z-index: 1;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  align-items: center;
  min-height: 944px;
  background-color: var(--violet);
`;

const FlexRow = styled.div`
  height: 124px;
  display: flex;
  align-items: flex-start;
  min-width: 1250px;
`;

const FlexCol = styled.div`
  width: 182px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 93px;
`;

const Title = styled.h1`
  min-height: 28px;
  font-family: var(--font-family-roboto_condensed);
  font-weight: 700;
  color: var(--lavender);
  font-size: var(--font-size-xxxl);
  letter-spacing: 0;
  padding-bottom: 30px;
`;

const Showing112Of864Items = styled.p`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  align-self: flex-end;
  margin-top: 46px;
  min-width: 198px;
  letter-spacing: 0;
  padding-left: 14px;
`;

const FlexCol1 = styled.div`
  width: 200px;
  position: relative;
  margin-left: 868px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 104px;
  justify-content: flex-end;
`;

const Viewing = styled.div`
  margin-left: 3px;
  display: flex;
  align-items: center;
  min-width: 197px;
  align-self: flex-end;
`;

const Viewing1 = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  min-width: 50px;
  letter-spacing: 0;
`;

const Box = styled.select`
${RobotocondensedBoldLavender16px}
  height: 40px;
  margin-left: 17px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  min-width: 130px;
  background-color: var(--violent-violet);
  border: none;
`;

const Address = styled.div`
  ${RobotocondensedBoldLavender16px}
  min-height: 19px;
  min-width: 65px;
  letter-spacing: 0;
`;
/* const PgNumber = styled.div`
  margin-top: 438px;
  display: flex;  
  padding: 0 30px;
  align-items: center;
  min-width: 360px;
`; */

export default Organizations;
