import "./styles.scoped.css";
import React, { KeyboardEventHandler, useState, useEffect } from "react";
import Select from 'react-select';
import { ActionMeta, OnChangeValue } from "react-select";
import ModalSave from './ModalSave';
import CreatableSelect from "react-select/creatable";
import { useSelector } from "react-redux";
import ModalCreateEvent, { EventInterface } from "../EventSelectBar/ModalCreateEvent";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER } from "../ClubIconsNavBar";
import moment, { Moment } from "moment";
import { capitalize } from "../../helpers/letterHelper";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";

toast.configure();


function EventsListAdmin({ events, handleDelete, valueFilter }: { events: EventInterface[], handleDelete: any, valueFilter: any }) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [listEvents, setListEvents] = useState<EventInterface[]>([]);

    useEffect(() => {
        setListEvents(events);
    }, [events])

    const eventTypes = useSelector((state: RootState) => state.getAha.GetAhaEvents)

    // const ClubTypes: { [k: string]: string | undefined } = {
    //     "Comp-10-2020": "Competition",
    //     "Field-Trip-10-2020": "Field Trip",
    //     "Fundraiser-10-2020": "Fundraiser",
    //     "Meeting-10-2020": "Meeting (Club)",
    //     "Practice-10-2020": "Practice/Rehearsal",
    //     "Retreat-10-2020": "Retreat",
    //     "Study-Hall-10-2020": "Study Hall",
    //     "Tournament-10-2020": "Tournament",
    //     "Tryout-10-2020": "Tryout (Club)",
    //     "Volunteer-10-2020": "Volunteering/Community Service",
    //     "Performance-10-2020": "Performance",
    //     "Other-10-2020": "Other",
    // };

    // const TeamTypes: { [k: string]: string | undefined } = {
    //     "Fundraiser-10-2020": "Fundraiser",
    //     "Comp-10-2020": "Game",
    //     "Meeting-10-2020": "Meeting (Team)",
    //     "Practice-10-2020": "Practice",
    //     "Retreat-10-2020": "Skills camp/Retreat",
    //     "Study-Hall-10-2020": "Study Hall",
    //     "Tournament-10-2020": "Tournament",
    //     "Tryout-10-2020": "Tryout (Team)",
    //     "Volunteer-10-2020": "Volunteering/Community Service",
    //     "Other-10-2020": "Other",
    // };

    // const SchoolTypes: { [k: string]: string | undefined } = {
    //     "Retreat-10-2020": "Conference/Retreat",
    //     "Dance-10-2020": "Dance",
    //     "Field-Trip-10-2020": "Field Trip",
    //     "Fundraiser-10-2020": "Fundraiser",
    //     "General-Assembly-10-2020": "General Assembly",
    //     "Open-House-10-2020": "Open House",
    //     "Orientation-10-2020": "Orientation",
    //     "Parent-Teacher-10-2020": "Parent/Teacher Meeting",
    //     "Pep-Rally-10-2020": "Pep Rally",
    //     "Study-Hall-10-2020": "Study Hall",
    //     "Workshop-10-2020": "Training/Workshop",
    //     "Volunteer-10-2020": "Volunteering/Community Service",
    //     "Other-10-2020": "Other",
    // };

    const getTimeMoment = (time: string) => {
        const time_parts = time.split("-");
        return (
            `${time_parts[0]}-${time_parts[1]}-${time_parts[2]} ${time_parts[3]}:${time_parts[4]}`
        );
    };

    const clubEventType = (evn: EventInterface) : string => {
        if(evn.club_type){
            if(evn.club_type === "CLUB" || evn.club_type === "COMM" ){
                return eventTypes.club.find((item)=>item.label === evn.type)?.label || 'Other'
            }

            if(evn.club_type === "TEAM"){
                return eventTypes.team.find((item)=>item.label === evn.type)?.label || 'Other'
            }

            if(evn.club_type === "SCHOOL"){
                return eventTypes.school.find((item)=>item.label === evn.type)?.label || 'Other'
            }
        }
        return ""
    }

    const correctReach = (eventReach: string) => {
        if (eventReach.indexOf('SCHOOL') !== -1) {
            return eventReach.split("#").join(" ");
        }
        return eventReach.split("_").join(" ");
    }

    return (
        <div className="content-clubEvent">
            <table className="tableListClubEvents robotocondensed-bold-white-16px">
                <tr>
                    <th>
                        <div className="robotocondensed-bold-white-16px">{t('manageEvents__eventName')}</div>
                    </th>
                    <th>
                        <div className="robotocondensed-bold-white-16px">{t('manageEvents__location')}</div>
                    </th>
                    <th>
                        <div className="robotocondensed-bold-white-16px">{t('manageEvents__start')}</div>
                    </th>
                    <th>
                        <div className="robotocondensed-bold-white-16px">{t('manageEvents__end')}</div>
                    </th>
                    <th>
                        <div className="robotocondensed-bold-white-16px">{t('manageEvents__type')}</div>
                    </th>
                    <th>
                        <div className="robotocondensed-bold-white-16px">{t('manageEvents__reach')}</div>
                    </th>
                    <th>
                        <div className="robotocondensed-bold-white-16px">{t('manageEvents__actions')}</div>
                    </th>
                </tr>

                {listEvents.filter((item: any) => {
                    if (!valueFilter) return true;
                    if (item.name.toUpperCase().includes(valueFilter) || 
                        item.name.toLowerCase().includes(valueFilter) || 
                        item.name.includes(valueFilter)
                    ) {
                        return true;
                    }
                    return false;
                }).map((event: any) => (
                    <tr key={event.timestamp} className="robotocondensed-normal-white-16px">
                        <td>
                            <div className="robotocondensed-normal-white-16px">
                                {event.name}
                            </div>
                        </td>
                        <td>
                            <div className="robotocondensed-normal-white-16px">
                                {event.meeting_place}
                            </div>
                        </td>
                        <td>
                            <div className="robotocondensed-normal-white-16px">
                                {getTimeMoment(event.start)}
                            </div>
                        </td>
                        <td>
                            <div className="robotocondensed-normal-white-16px">
                                {getTimeMoment(event.end)}
                            </div>
                        </td>
                        <td>
                            <div className="robotocondensed-normal-white-16px">
                                {clubEventType(event)}
                            </div>
                        </td>
                        <td>
                            <div className="robotocondensed-normal-white-16px">
                                {Array.isArray(event.reach) ?
                                    <ul style={{ listStyle: 'circle inside' }}>
                                        {event.reach.map((reach: any) => (
                                            <li>{capitalize(correctReach(reach))}</li>
                                        ))}
                                    </ul>
                                    :
                                    <ul style={{ listStyle: 'circle inside' }}>
                                        <li>{capitalize(correctReach(event.reach))}</li>
                                    </ul>
                                }
                            </div>
                        </td>
                        <td>
                            <div className="actionsEventsAdmin">
                                <FontAwesomeIcon
                                    icon="eye"
                                    size='xs'
                                    color={LAVENDER}
                                    className="view-user  "
                                    onClick={() => navigate(`/app/events/${event.timestamp}`)}
                                />

                                <FontAwesomeIcon
                                    icon="pen"
                                    size='xs'
                                    color={LAVENDER}
                                    className="update-user  "
                                    onClick={() => navigate(`/app/events/${event.timestamp}?editTrue=true`)}
                                />

                                <FontAwesomeIcon
                                    icon="trash"
                                    size='xs'
                                    color={LAVENDER}
                                    className="paper-plane  "
                                    onClick={() => handleDelete(event.timestamp)}
                                />

                            </div>
                        </td>
                    </tr>
                ))}
            </table>
        </div>
    );
}

export default EventsListAdmin;