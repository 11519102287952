import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { WHITE } from '../../../../components/ClubIconsNavBar';
import ModalDiscardChanges from '../../../../components/SetupSetup/ModalDiscardChanges';
import TCList from '../../../../interfaces/TClubs/TCList/TCList';
import TClubAssignedComplete from '../../../../interfaces/TClubs/TCList/TClubAssignedComplete';
import { CancelButton, ClickableIcon, SaveButton } from '../../components';
import { useTranslation } from 'react-i18next';
import TitleEdit from '../../../../components/TitleEdit';
import { Backend, MicroServices } from '../../../../helpers/backendHelper';
import { toast } from 'react-toastify';
import ModalBaseProps from '../../../../interfaces/modals/ModalBaseProps';

interface AssignModalProps extends ModalBaseProps {
    tclubSelected?: TClubAssignedComplete,
    tclist?: TCList;
}

const AssignModal = ({
    show,
    tclubSelected,
    tclist,
    onHide,
}: AssignModalProps) => {
    const { t } = useTranslation();

    const [showDiscard, setShowDiscard] = useState(false);
    const [loadingAssign, setLoadingAssign] = useState(false);
    const [tclubName, setTclubName] = useState<string>();

    useEffect(() => {
        setTclubName(tclubSelected?.name)
    }, [tclubSelected, show])

    const onCancel = () => setShowDiscard(true);

    const onHideDiscard = (type: string) => {
        setShowDiscard(false);
        if (type === 'discard') {
            onHide();
            setTclubName(undefined);
        }
    };

    const onSaveAssign = () => {
        setLoadingAssign(true);
        Backend(
            MicroServices.Club,
            `/tclubs/tclists/${tclist?.id}/assign-tc`,
            {
                method: 'POST',
                body: JSON.stringify({
                    tcId: tclubSelected?.id,
                    ...(tclubName !== tclubSelected?.name ? {assignedName: tclubName}: {})
                })
            }
        )
            .then((response) => {
                if (response.status === 201) {
                    toast.success(t('toast_templateClubAssigned'));
                    onHide();
                    setTclubName(undefined);
                    return;
                }
            })
            .catch((error) => toast.error(error))
            .finally(() => setLoadingAssign(false));
    };

    return (
        <>
            <Modal className="modalInviteMemebers" show={show} onHide={onCancel}>
                <Modal.Header>
                    <Modal.Title>
                        Assign Template Club to {tclist?.name}
                    </Modal.Title>
                    <ClickableIcon
                        icon={faTimes}
                        size='xl'
                        color={WHITE}
                        className="icon-times"
                        onClick={onCancel}
                    />
                </Modal.Header>
                <Modal.Body>
                    {tclubName && <TitleEdit title={tclubName} setTitle={setTclubName} />}
                </Modal.Body>
                <Modal.Footer className='modalDiscardChanges-footer'>
                    <CancelButton onClick={onCancel}>
                        {t('button__cancel')}
                    </CancelButton>
                    <SaveButton onClick={onSaveAssign}>
                        {loadingAssign
                            ? (
                                <div className="icon-spinner-third buttonSendSignUp"></div>
                            )
                            : t('button__save')
                        }
                    </SaveButton>
                </Modal.Footer>
            </Modal>
            <ModalDiscardChanges
                show={showDiscard}
                onHide={onHideDiscard}
            />
        </>
    );
};

export default AssignModal;