import "./styles.css";
import { useState, useEffect } from 'react';
import ModalDelete from './ModalDelete';
import ModalSuspendPoster from './ModalSuspendPoster';
import { unixToDateFormatted } from "../../helpers/dateHelper";
import { toast } from 'react-toastify'
import { AnyMxRecord } from "dns";
import { faEllipsisVAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER } from "../ClubIconsNavBar";
import { WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
export interface flag {
    post_id: string;
    author: string;
    type: string;
    reach: string;
    reach_id?: string;
    content: string;
    org_id: string;
    is_flagged: string | boolean;
    files?: Ifile[];
    link?: string | null;
    eventName?: string | null;
    eventTimestamp?: string | null;
    eventLocation?: string | null;
    eventCover?: string | null;
    created_at?: string;
    author_name?: string;
    author_avatar?: string;
    reach_name?: string;
}

export interface Ifile {
    name: string;
    link: string;
    media_id: string;
    type: string;
}

function Index({ flags, getOrgFlags, handleDelete }: { flags: flag[], getOrgFlags: any, handleDelete: any }) {
    const { t, i18n } = useTranslation();
    const [selectedItemId, setSelectedItemId] = useState(0);
    const [selectedItemState, setSelectedItemState] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalSuspendPoster, setShowModalSuspendPoster] = useState(false);
    const [modalTitle, setShowModalTitle] = useState('');

    function handleCloseModalDelete(e: any, type: any) {
        setShowModalDelete(false);
        setSelectedItemState(false);
    };

    function handleCloseModalSuspendPoster(e: any, type: any) {
        setShowModalSuspendPoster(false);
        setSelectedItemState(false);
    };

    const toggleClick = (id: any) => {
        if (id === selectedItemId) {
            setSelectedItemState(!selectedItemState)
        } else {
            setSelectedItemId(id);
            setSelectedItemState(true);
        }
    };

    function deleteReport(e: any, flag: any) {
        e.preventDefault();
        Backend(
            MicroServices.Posts,
            `/flag`,
            {
                method: 'DELETE',
                body: JSON.stringify({
                    usernamePoster: flag.author,
                    usernameFlagger: flag.usernameFlagger,
                    postId: flag.post_id
                })
            })
            .then((response) => {
                if (response.status === 204) {
                    toast.success(t('toast_reportDeleted'));
                    handleDelete(true)
                }
                else {
                    toast.error(t('toast_somethingWrong'))
                }
            });
    }

    const deleteContent = (postInfo: any) => {
        Backend(
            MicroServices.Posts,
            `/post?id=${postInfo.post_id}&poster=${postInfo.author}`, {
            method: "DELETE"
        })
            .then((response) => {
                if (response.status === 204) {
                    toast.success(t('toast_contentDeleted'));
                    handleDelete(true)
                }
                else {
                    toast.error(t('toast_errorDeleting'));
                }
            })
            .catch((error) => {
                toast.error(t('toast_errorTryAgain'))
                console.error(error)
            });
    }

    return (
        <div className="content-clubFlags">
            <table className="tableManageUser tableManageFlags robotocondensed-bold-white-16px">
                <tr>
                    <th>
                        <div className="email">
                            <div className="email-1 robotocondensed-bold-white-16px">{t('flaggegPost__flagContent')}</div>
                        </div>
                    </th>
                    <th>
                        <div className="email">
                            <div className="email-1 robotocondensed-bold-white-16px">{t('flaggegPost__reason')}</div>
                        </div>
                    </th>
                    <th>
                        <div className="email">
                            <div className="email-1 robotocondensed-bold-white-16px">{t('flaggegPost__date')}</div>
                        </div>
                    </th>
                    <th>
                        <div className="email">
                            <div className="email-1 robotocondensed-bold-white-16px">{t('flaggegPost__poster')}</div>
                        </div>
                    </th>
                    <th>
                        <div className="email">
                            <div className="email-1 robotocondensed-bold-white-16px">{t('flaggegPost__reach')}</div>
                        </div>
                    </th>
                    <th>
                        <div className="actions robotocondensed-bold-white-16px"></div>
                    </th>
                </tr>
                {flags.map((flag: any) => (
                    <tr key={flag.post_id} className="rowOrganization robotocondensed-normal-white-16px">
                        <td>
                            <div className="email robotocondensed-bold-lavender-16px">
                                {flag.content || ''}
                            </div>
                        </td>
                        <td>
                            <div className="email robotocondensed-normal-white-16px">
                                {flag.flag_reason || ''}
                            </div>
                        </td>
                        <td>
                            <div className="email robotocondensed-normal-white-16px">
                                {unixToDateFormatted(flag.created_at || '')}
                            </div>
                        </td>
                        <td>
                            <div className="email robotocondensed-bold-lavender-16px">
                                {flag.author_name || ''}
                            </div>
                        </td>

                        <td>
                            <div className="email robotocondensed-bold-lavender-16px">
                                {flag.reach_name || ''}
                            </div>
                        </td>

                        <td>
                            <div className="actions buttonActions">
                                <button className="buttonEllipsis" onClick={() => toggleClick(flag.post_id)}>
                                    <FontAwesomeIcon
                                        icon={faEllipsisVAlt}
                                        size='lg'
                                        className="ellipsis-v-alt"
                                        color={LAVENDER}
                                    />
                                </button>
                            </div>
                            <div className={`${flag.post_id === selectedItemId && selectedItemState ? "active" : ""} contentOptions`} key={flag.post_id}>
                                <ul >
                                    <li onClick={() => { deleteContent(flag); /*setShowModalDelete(true); setShowModalTitle('The content has been deleted');*/ }} >
                                        <FontAwesomeIcon
                                            icon="ban"
                                            size='lg'
                                            color={WHITE}
                                            className="ban actionIconFlag  "
                                            />
                                        <p>
                                            {t('infoFlags__deleteContent')}
                                        </p>
                                    </li>
                                    <li onClick={(e) => { deleteReport(e, flag); /*setShowModalDelete(true); setShowModalTitle('The report has been deleted'); */ }}>
                                        <FontAwesomeIcon
                                            icon="bell"
                                            size='lg'
                                            color={WHITE}
                                            className="bell actionIconFlag  "
                                            />
                                        <p>
                                            {t('infoFlags__deleteReport')}
                                        </p>
                                    </li>
                                    {/* <li>
                                        <FontAwesomeIcon
                                            icon="envelope"
                                            size='lg'
                                            color={WHITE}
                                            className="envelope actionIconFlag  "
                                            />
                                        <p>
                                            Contact the Poster
                                        </p>
                                    </li>
                                    <li onClick={() => { setShowModalSuspendPoster(true); }}>
                                        <FontAwesomeIcon
  icon="user-slash"
  size='lg'
  color={WHITE}
  className="user-slash actionIconFlag  "
/>
                                        <p>
                                            Suspend Poster
                                        </p>
                                    </li>
                                    <li>
                                        <FontAwesomeIcon
                                            icon="exclamation"
                                            size='lg'
                                            color={WHITE}
                                            className="exclamation actionIconFlag  "
                                            />
                                        <p>
                                            Warn Poster
                                        </p>
                                    </li> */}
                                </ul>
                            </div>
                        </td>
                    </tr>
                ))}
            </table>
            <ModalDelete show={showModalDelete} onHide={handleCloseModalDelete} title={modalTitle} />
            <ModalSuspendPoster show={showModalSuspendPoster} onHide={handleCloseModalSuspendPoster} />
        </div>
    );
}

export default Index;