import "./styles.css";
import { useState, useEffect, useMemo, useCallback } from "react";
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'tui-image-editor/dist/tui-image-editor.css';
// @ts-ignore
import ImageEditor from "@toast-ui/react-image-editor";
import Cropper from 'react-easy-crop'
import getCroppedImg from "../../helpers/imageHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
import { useTranslation } from "react-i18next";

function Index(props: any) {
	const { t, i18n } = useTranslation();
	const [selectedFile, setSelectedFile] = useState<any>()
	const [preview, setPreview] = useState<any>("");
	const myRef = React.useRef<any>(null);
	const [arrayFiles, setArrayFiles] = useState<any[]>([])
	const [showButtons, setShowButtons] = useState<Boolean>(false)
	const [indexCurrent, setIndexCurrent] = useState<number>(0)
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(1)
	const [imageCropped, setimageCropped] = useState<any>('');
	const [croppedAreaPixels, setCroppedAreaPixels] = useState();

	const onCropComplete = useCallback((croppedArea, croppedAreaPixelsFunc) => {
		console.log(croppedArea, croppedAreaPixelsFunc)
		setCroppedAreaPixels(croppedAreaPixelsFunc)
	}, [])
	function editPhoto(e: any) {
		// const { t, i18n } = useTranslation(); //! Why the f is a hook being called here?
		setArrayFiles([])
		const limit = 5;
		if (e.target.files.length > limit) {
			console.log("Upload Max " + limit + " Files allowed")
		} else {

			setSelectedFile(undefined)
			setPreview('');

			if (!e.target.files || e.target.files.length === 0) {
				setSelectedFile(undefined)
				return
			}

			if (e.target.files.length === 1) {
				setShowButtons(false)
				setSelectedFile(e.target.files[0])
				updateImage(e.target.files[0]);
			} else {
				setArrayFiles(e.target.files)
				// Object.entries(e.target.files).map((img: any) => {
				// 	setArrayFiles([
				// 		...arrayFiles,
				// 		img[1]
				// 	])
				// });

				setShowButtons(true)
				setSelectedFile(e.target.files[0])

				setPreview(URL.createObjectURL(e.target.files[0]))
				updateImage(e.target.files[0]);
			}
		}
	}

	function updateImage(file: File) {
		if (!file) {
			setPreview(undefined)
			return
		}
		const objectUrl = URL.createObjectURL(file)
		setPreview(objectUrl)
		return () => URL.revokeObjectURL(objectUrl)
	}

	async function handleOnClickOk(e: any, type: any) {
		e.preventDefault();

		if (type === 'save') {
			try {
				const croppedImage = await getCroppedImg(
					preview,
					croppedAreaPixels,
					1
				)
				console.log('donee', { croppedImage })
				setimageCropped(croppedImage)
				props.handleSaveImage(croppedImage);
				props.onHide(e, type)
			} catch (e) {
				console.error(e)
			}
		} else if (type === 'cancel') {
			props.onHide(e, type);
		}

		setSelectedFile(undefined)
		setPreview('');
		setArrayFiles([]);
	}

	function imagePrev() {
		setSelectedFile(() => arrayFiles[indexCurrent - 1])
		const instance = myRef?.current?.imageEditorInst;
		const dataUrl = instance.toDataURL();
		console.log(dataUrl)
		fetch(dataUrl)
			.then(res => res.blob())
			.then(blob => {
				const currentDate = new Date();
				const timestamp = currentDate.getTime();
				const contentType = dataUrl.split(';')[0].split(':')[1];
				const file = new File([blob], 'imagePost_' + timestamp, { type: contentType });
				const array = Array.from(arrayFiles);
				array[indexCurrent] = file;
				setArrayFiles(array)
			})
		setIndexCurrent(indexCurrent - 1)
	}

	function imageNext() {
		setSelectedFile(() => arrayFiles[indexCurrent + 1])
		const instance = myRef?.current?.imageEditorInst;
		const dataUrl = instance.toDataURL();
		console.log(dataUrl)
		fetch(dataUrl)
			.then(res => res.blob())
			.then(blob => {
				const currentDate = new Date();
				const timestamp = currentDate.getTime();
				const contentType = dataUrl.split(';')[0].split(':')[1];
				const file = new File([blob], 'imagePost_' + timestamp, { type: contentType });
				const array = Array.from(arrayFiles);
				array[indexCurrent] = file;
				setArrayFiles(array)
			})

		setIndexCurrent(indexCurrent + 1)

	}

	function saveAll() {
		props.handleSaveImage(arrayFiles, false);
	}

	const imageConfig = useMemo(() => ({
		loadImage: {
			path: selectedFile ? preview : "",
			name: "SampleImage"
		},
		uiSize: {
			width: "100%",
			height: "350px"
		},
		menuBarPosition: "right"
	}), [preview]);

	useEffect(() => {

		if (!selectedFile) {
			setPreview(undefined)
			return
		}

		const objectUrl = URL.createObjectURL(selectedFile)
		setPreview(objectUrl)
		return () => URL.revokeObjectURL(objectUrl)

	}, [selectedFile])

	return (
		<>
			<Modal className={`modalImageEditor menuTopHidden ${!preview && 'modalWPreview'}`} show={props.show} onHide={props.onHide}>
				<Modal.Header>
					<Modal.Title>{(props.isProfilePhoto) ? t('modalPhoto__profile') : t('modalPhoto__photo') }</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!preview ? (
						<>
							<label htmlFor="filePickerModal" className="filePickerModal">
								<div className="iconImageCenter">
									<FontAwesomeIcon
										icon="camera"
										size='lg'
										color={WHITE}
										className="iconCamera  "
									/>
									<p>{t('setupSchool__selectFile')}</p>
								</div>
								<input
									id="filePickerModal"
									type={"file"}
									onChange={(e) => editPhoto(e)}
									multiple
								/>
							</label>
							{/* <div className="containersButton">

							</div>
							<div className="containersButtons">
								<FontAwesomeIcon
  icon="crop-alt"
  size='lg'
  color={WHITE}
  className=" "
/>
								<FontAwesomeIcon
  icon="exchange"
  size='lg'
  color={WHITE}
  className=" "
/>
								<FontAwesomeIcon
  icon="pen"
  size='lg'
  color={WHITE}
  className=" "
/>
								<FontAwesomeIcon
  icon="times"
  size='lg'
  color={WHITE}
  className=" "
/>
							</div> */}
						</>

					) : (
						<>
							<div onClick={(e) => handleOnClickOk(e, 'trash')}>
								<div className="iconImageCenterMini">
									<FontAwesomeIcon
										icon="trash"
										size='lg'
										color={WHITE}
										className="iconCamera  "
									/>
								</div>
							</div>
							{showButtons &&
								<>
									{indexCurrent + 1}
									{arrayFiles.length}
									{indexCurrent !== 0 &&
										<button onClick={() => imagePrev()}>Prev</button>
									}
									{(indexCurrent + 1) !== arrayFiles.length &&
										<button onClick={() => imageNext()}>Next</button>
									}

									<button onClick={() => saveAll()}>Save all</button>
								</>
							}
							{/* <ImageEditor
								key={preview}
								ref={myRef}
								includeUI={imageConfig}
								cssMaxHeight={500}
								cssMaxWidth={500}
								selectionStyle={{
									cornerSize: 20,
									rotatingPointOffset: 70
								}}
								usageStatistics={false}
							/> */}
							<Cropper
								image={preview}
								crop={crop}
								zoom={zoom}
								aspect={1}
								onCropChange={setCrop}
								onCropComplete={onCropComplete}
								onZoomChange={setZoom}
								cropShape="round"
							/>
							<div className="controls">
								<input
									type="range"
									value={zoom}
									min={1}
									max={3}
									step={0.1}
									aria-labelledby="Zoom"
									onChange={(e) => {
										setZoom(Number(e.target.value))
									}}
									className="zoom-range"
								/>
							</div>
						</>

					)}
				</Modal.Body>
				<Modal.Footer>
					<Button className="buttonSendEvent" onClick={(e) => handleOnClickOk(e, 'cancel')}>
						{t('button__cancel')}
					</Button>
					<Button className="buttonSendEvent" onClick={(e) => handleOnClickOk(e, 'save')}>
						{t('button__save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default Index;