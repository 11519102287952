import { useEffect, useState } from "react";
import "./styles.css";
import ProfileInformation from "../ProfileInformation";
import ProfileClubs from "../ProfileClubs";
import ProfileConnections from "../ProfileConnections";
import { useParams } from "react-router-dom";
import Posts from "../../containers/Posts";
import ProfileSettings from "../ProfileSettings/ProfileSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER, WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";

interface ProfileViewNavBarProps{
  //todo -> put types
  isUserProfile:any;
  ChatServiceInstance:ChatServiceInterface;
}

function Index(props: ProfileViewNavBarProps) {
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState("Information");
  const [screen, setScreen] = useState("Information");
  const { username } = useParams();

  useEffect(() => {
    setSelected("Information");
    setScreen("Information");
  }, []);

  useEffect(() => {
    setSelected("Information");
    setScreen("Information");
  }, [username]);

  const chooseView = (view: string) => {
    setSelected(view);
    setScreen(view);
  }

  function selectScreen(screen: string) {

    switch (screen) {
      case "Feed":
        return <Posts
          whereIs={(props.isUserProfile) ? 'profile' : 'connection'}
          /*postInput={true} */
          tabActive={(props.isUserProfile) ? 4 : 7}
          username={username}
          eventId={undefined}
          clubId={undefined}
          ChatServiceInstance={props.ChatServiceInstance} />;
        break;
      case "Information":
        return <>
          <ProfileInformation
            isUserProfile={props.isUserProfile}
          />
          <Posts
            whereIs={(props.isUserProfile) ? 'profile' : 'connection'}
            /*postInput={true} */
            tabActive={(props.isUserProfile) ? 4 : 7}
            username={username}
            eventId={undefined}
            clubId={undefined}
            ChatServiceInstance={props.ChatServiceInstance} />
        </>
        break;
      case "Clubs":
        return <ProfileClubs isUserProfile={props.isUserProfile} />;
        break;
      case "Connections":
        return <ProfileConnections isUserProfile={props.isUserProfile} ChatServiceInstance={props.ChatServiceInstance} />;
        break;
      case "Settings":
        return <ProfileSettings />;
        break;
      default:
        return <></>;
        break;
    }
  }

  return (
    <>
      {/*<div className="club-view-navbar">
        {/<div
          className={
            selected === "Feed" ? "club-view-row-selected" : "club-view-row"
          }
          id="left-rad-up"
          onClick={() => {
            setSelected("Feed");
            setScreen("Feed");
          }}
        >
          <div className={"section-label robotocondensed-normal-white-16px"}>
            Feed
          </div>
        </div>/}

        <div
          className={
            selected === "Information"
              ? "club-view-row-selected"
              : "club-view-row"
          }
          id="left-rad-up"
          onClick={() => chooseView('Information')}
        >
          <div className={"section-label robotocondensed-normal-white-16px"}>
            {/Information/}Feed
          </div>
        </div>
        <div
          className={
            selected === "Clubs" ? "club-view-row-selected" : "club-view-row"
          }
          onClick={() => chooseView('Clubs')}
        >
          <div className={"section-label robotocondensed-normal-white-16px"}>
            {(props.isUserProfile) ? 'My Clubs' : 'Clubs'}
          </div>
        </div>

        <div
          className={
            selected === "Connections" ? "club-view-row-selected" : "club-view-row"
          }
          onClick={() => chooseView('Connections')}
        >
          <div className={"section-label robotocondensed-normal-white-16px"}>
          {(!props.isUserProfile) ? 'Connections' : 'Friend Requests'}
          </div>
        </div>

        {(props.isUserProfile) && (
        <div
          className={
            selected === "Settings" ? "club-view-row-selected" : "club-view-row"
          }
          id="right-rad-up"
          onClick={() => chooseView('Settings')}
        >
          <div className={"section-label robotocondensed-normal-white-16px"}>
            Settings
          </div>
        </div>
        )}

      </div>*/}

      <div className="icons-navbar upper">

        <div
          className={
            selected === "Information" ? "icons-column-selected" : "icons-column"
          }
          id="left-rad-up"
          onClick={() => chooseView('Information')}
        >
          <FontAwesomeIcon
            icon="home"
            size='sm'
            color={
              selected === "Information"
                ? LAVENDER
                : WHITE
            }
          />
          <div className={
            selected === "Information"
              ? "icon-label robotocondensed-bold-lavender-16px"
              : "icon-label robotocondensed-normal-white-16px"}
          >
            Feed
          </div>
        </div>
        <div
          className={
            selected === "Clubs" ? "icons-column-selected" : "icons-column"
          }
          onClick={() => chooseView('Clubs')}
        >
          <FontAwesomeIcon
            icon="users"
            size='sm'
            color={
              selected === "Clubs"
                ? LAVENDER
                : WHITE
            }
          />
          <div className={
            selected === "Clubs"
              ? "icon-label robotocondensed-bold-lavender-16px"
              : "icon-label robotocondensed-normal-white-16px"}
          >
            {t('navbar__clubs')}
          </div>
        </div>

        <div
          className={
            selected === "Connections" ? "icons-column-selected" : "icons-column"
          }
          onClick={() => chooseView('Connections')}
        >
          <FontAwesomeIcon
            icon="user"
            size='sm'
            color={
              selected === "Connections"
                ? LAVENDER
                : WHITE
            }
          />
          <div className={
            selected === "Connections"
              ? "icon-label robotocondensed-bold-lavender-16px"
              : "icon-label robotocondensed-normal-white-16px"}
          >
            {t('navbar__connections')}
          </div>
        </div>

      </div>

      {selectScreen(screen)}
    </>
  );
}

export default Index;
