import React, { useEffect, useState } from 'react'
import './styles.scoped.css';
import ListComments from '../../components/Post/ListComments';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
import ListEmojis, { emojiColor, getUserReactionToComment, reactionToEmoji } from './ListEmojis';
import { timeDelay } from '../../helpers/dateHelper';
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from '../../helpers/backendHelper';
interface IReaction {
    author: string;
    post_id: string;
    type: 'like' | 'love' | 'laugh' | 'surprise' | 'cry' | 'mad';
    created_at: string;
}

export interface IComment {
    author: string;
    post_id: string;
    content: string;
    created_at: string;
    avatar?: string;
    reactions: IReaction[]
    userReaction?: string
}
interface IPost {
    post_id: string;
    author: string;
    type: string;
    reach: string;
    reach_id: string;
    content: string;
    org_id: string;
    is_flagged: boolean;
    author_name: string;
    author_avatar: string;
    created_at: string;
    reactions: IReaction[];
    comments: IComment[];
    // files?: IFile[];
    link?: string | undefined;
    reach_name?: string;
    eventName?: string | undefined;
    eventTimestamp?: string | undefined;
    eventLocation?: string | undefined;
}

const reactionToEmojiImg = (reaction: string | undefined): string => {
    if (!reaction) return ''
    switch (reaction) {
        case 'like': return "/fire.png"
        case 'love': return "/heart_eyes.png"
        case 'laugh': return "/joy.png"
        case 'surprise': return "/scream.png"
        case 'cry': return "/cry.png"
        case 'mad': return "/rage.png"
        default: return ''
    }
}

const Comments = (props: any) => {
    const { t, i18n } = useTranslation();
    const username = useSelector((state: any) => state.getUser.userInfo?.username || '');

    const user = useSelector((state: any) => state.getUser.user);

    const [post, setPost] = useState<IPost>({
        post_id: '',
        author: '',
        type: '',
        reach: '',
        reach_id: '',
        content: '',
        org_id: '',
        is_flagged: false,
        author_name: '',
        author_avatar: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
        created_at: '',
        reactions: [],
        comments: [],
        link: '',
        eventName: "",
        eventTimestamp: "",
        eventLocation: "",
    })
    const [newComment, setNewComment] = useState<string>('')

    const [updatePost, setUpdatePost] = useState<boolean>(false)

    const [imagePosition, setImagePosition] = useState<number>(0)

    const [allComments, setAllComments] = useState<boolean>(true)

    const [sendingComment, setSendingComment] = useState<boolean>(false)

    //Handlers
    const handleSaveComment = (e: any) => {
        if (e.type !== 'click' && e.key !== 'Enter') return
        e.preventDefault()
        if (newComment && newComment !== '') {
            setSendingComment(true)
            Backend(
                MicroServices.Posts,
                `/comment`, {
                method: "POST",
                body: JSON.stringify({
                    author: post.author,
                    username: user.username,
                    post_id: props.idPost,
                    content: newComment
                }),
            })
                .then((results) => {
                    if (results.status === 201) {
                        console.log(`Comment made: ${newComment}\nPost Id:${props.idPost}`)
                        setNewComment('')
                        setUpdatePost(!updatePost)
                    } else {
                        toast.error(t('toast_errorCommentingPost'))
                    }
                })
                .catch((error) => {
                    console.log(error)
                    toast.error(t('toast_errorCommentingPost'))
                })
                .finally(() => setSendingComment(false))
        }
    }

    const handleManageImage = () => {
        //SAVE IMAGE AND SAVE COMMENT WITH IMAGE
    }

    //Effects
    useEffect(() => {
        if (props.idPost) {
            Backend(
                MicroServices.Posts,
                `/post?post_id=${props.idPost}`)
                .then((results) => results.json())
                .then((results) => {
                    return results
                    //setPost(results)
                })
                .then((postDB) => {
                    //Add user reaction to comments
                    Promise.all((postDB.comments || []).map((comment: any) => getUserReactionToComment(comment.created_at, username)))
                        .then((reactions) => {
                            console.log(reactions)
                            const postCopy = { ...postDB }
                            postCopy.comments = postCopy.comments.map((item: any, idx: number) => {
                                return {
                                    ...item,
                                    userReaction: reactions[idx]
                                }
                            })
                            setPost(postCopy)
                        })
                })
                .catch((error) => console.log(error))
        }
    }, [props.idPost, updatePost])

    //Emoji
    const [reactionEmojis, setReactionEmojis] = useState<('like' | 'love' | 'laugh' | 'surprise' | 'cry' | 'mad')[]>([])
    useEffect(() => {
        setReactionEmojis(['like', 'love', 'laugh', 'surprise', 'cry', 'mad'].filter((item) => post.reactions.findIndex((item2) => item2.type === item) !== -1) as ('like' | 'love' | 'laugh' | 'surprise' | 'cry' | 'mad')[])
    }, [post])
    useEffect(() => {
        const postCopy = { ...post }
        const reactionIndex = postCopy.reactions.findIndex((item) => item.author === user.username)
        if (reactionIndex === -1) {
            postCopy.reactions.push({
                author: user.username,
                post_id: post.post_id,
                type: props.emoji.emojis.reaction,
                created_at: new Date().valueOf().toString()
            })
        } else {
            postCopy.reactions[reactionIndex] = {
                author: user.username,
                post_id: post.post_id,
                type: props.emoji.emojis.reaction,
                created_at: new Date().valueOf().toString()
            }
        }
        setPost(postCopy)
    }, [props.emoji.emojis.reaction])

    //Comment Emoji
    const [commentEmojis, setCommentEmojis] = useState<{ [comment_id: string]: { count: number, reaction: string } }>({})
    const manageCommentEmoji = (comment_id: string, reaction: string, add: boolean) => {
        console.log(comment_id, reaction, add)
        const emojisCopy = { ...commentEmojis }
        emojisCopy[comment_id] = {
            count: add ? (commentEmojis[comment_id]?.count || 0) + 1 : (commentEmojis[comment_id]?.count || 0),
            reaction: reaction
        }
        setCommentEmojis(emojisCopy)
    }
    useEffect(() => {
        setCommentEmojis(Object.assign({}, ...post.comments.map((comment) => {
            return {
                [comment.created_at]: {
                    count: comment.reactions.length,
                    reaction: comment.userReaction
                }
            }
        })))
    }, [post.comments])
    const generateEmojiCommentObject = (comment_id: any) => {
        return {
            emojis: commentEmojis[comment_id] ? commentEmojis[comment_id] : { count: 0, reaction: undefined },
            setEmojis: setCommentEmojis,
            manageEmoji: manageCommentEmoji
        }
    }

    return (
        <div className={`containerComments ${props.showComments && 'commentsActive'}`}>
            <div className="infoPost">
                <div className="profileInfo">
                    <img className="round-avatar" src={post.author_avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" />
                    <div className="nameProfile">
                        <span> {post.author_name} </span>
                        <label htmlFor="time">{`${post.reach_name || ''} - ${timeDelay(post.created_at)}`}</label>
                    </div>
                    <button className="btnEllipsis">
                        <FontAwesomeIcon
                            icon="ellipsis-v"
                            size="xl"
                            color={WHITE}
                            data-v-5a47a937=""
                        />
                    </button>
                </div>
                <div className="postDescription ellps">
                    <p dangerouslySetInnerHTML={{ __html: post.content }}></p>
                </div>
                <ListEmojis post_id={post.post_id} manageEmoji={props.emoji.manageEmoji} />
                <div className="countActivity">
                    <div className="countReactions">
                        {reactionEmojis.map((emoji) => (
                            <div className="emojiIcon">
                                <img src={reactionToEmojiImg(emoji)} alt="" />
                            </div>
                        ))}
                        <ListEmojis post_id={post.post_id} manageEmoji={props.emoji.manageEmoji} />
                        <div className="countNum">
                            {props.emoji.emojis.count}
                        </div>
                    </div>
                    <div className="countComments">
                        {post.comments.length} {t('comments__title')}
                    </div>
                </div>
                <div className='containerListComments'>
                    {allComments ? (
                        <>
                            {post.comments.map((comment) => (
                                <div className="listComments">
                                    <div className="listComments">
                                        <ListComments comment={comment} image={false} emoji={generateEmojiCommentObject(comment.created_at)} />
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : post.comments.length > 0 ?
                        (
                            <>
                                <div className="listComments">
                                    <ListComments comment={post.comments[0]} image={false} emoji={generateEmojiCommentObject(post.comments[0].created_at)} />
                                </div>
                            </>
                        ) : <div className="allComments">
                            <p>{t('comments__betheFirst')}</p>
                            <FontAwesomeIcon
                                icon="chevron-down"
                                size='lg'
                                color={LAVENDER}
                                className="chevron-down  "
                            />
                        </div>}
                </div>

                {(post.comments.length > 1 && !allComments) ? (
                    <div className="allComments">
                        <p>{t('comments__viewAllComments')}</p>
                        <FontAwesomeIcon
                            icon="chevron-down"
                            size='lg'
                            color={LAVENDER}
                            className="chevron-down  "
                            onClick={() => setAllComments(true)}
                        />
                    </div>
                ) : ''}

            </div>
            <div className="inputComment">
                {/* <FontAwesomeIcon
                        icon="paperclip"
                        size='lg'
                        color={WHITE}
                        className="iconPaperclip  "
                        onClick={handleManageImage}
                    /> */}
                <input type="text" placeholder={t('comments__commentThisPost')}
                    value={newComment}
                    onChange={(e: any) => setNewComment(e.target.value)}
                    onKeyDown={handleSaveComment} />
                {sendingComment ? (
                    <div className="icon-spinner-third buttonSendSignUp" />
                ) : (
                    <FontAwesomeIcon
                        icon="paper-plane-top"
                        size='lg'
                        color={WHITE}
                        className="iconPaperclip  "
                        onClick={handleSaveComment}
                    />
                )}

            </div>
        </div>
    )
}

export default Comments