import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { getClubRole } from "../../helpers/club/getClub";
import { checkString, defaultFieldError, FieldError } from "../../helpers/formHelper";
import { generatePassword } from "../../helpers/passwordHelper";
import { useTranslation } from "react-i18next";

type CreateUserFormKeys =
  'givenName' |
  'familyName' |
  'gender' |
  'email' |
  'userName' |
  'password' |
  'birthdate' |
  'community';

export const useCreateUserForm = (org_id: string, org_name: string, onSuccess: () => void) => {
  const [form, setForm] = useState<{[key in CreateUserFormKeys]: string}>({
    givenName: '',
    familyName: '',
    gender: '',
    email: '',
    userName: '',
    password: generatePassword(),
    birthdate: '',
    community: '',
  });

  const [formErrors, setFormErrors] = useState<{[key in CreateUserFormKeys]: FieldError}>({
    givenName: defaultFieldError(),
    familyName: defaultFieldError(),
    gender: defaultFieldError(),
    email: defaultFieldError(),
    userName: defaultFieldError(),
    password: defaultFieldError(),
    birthdate: defaultFieldError(),
    community: defaultFieldError(),
  });

  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [commRole, setCommRole] = useState<string>();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!form.community || form.community === '') {
      setCommRole(undefined);
      return;
    }
    getClubRole(form.community)
      .then((role) => {
        setCommRole(role);
      })
      .catch(() => {
        setCommRole(undefined);
      })
  }, [form.community])

  const setFormValue = (key: CreateUserFormKeys, e: React.FormEvent<HTMLSelectElement | HTMLInputElement>) => setForm({
    ...form,
    [key]: e.currentTarget.value,
  });

  const setFormValueString = (key: CreateUserFormKeys, value: string) => setForm((old) => ({
    ...old,
    [key]: value,
  }));

  const setError = (key: CreateUserFormKeys, message: string) => setFormErrors((old) => ({
    ...old,
    [key]: {
      hasError: true,
      message,
    }
  }));

  const cleanError = (key: CreateUserFormKeys) => setFormErrors((old) => ({
    ...old,
    [key]: {
      hasError: false,
      message: '',
    }
  }))

  const getError = (key: CreateUserFormKeys): string => (formErrors[key].hasError) ? formErrors[key].message : '';

  const regeneratePassword = () => setFormValueString('password', generatePassword());

  const checkForm = () => {
    const errors: string[] = [];
    if (!checkString(form.givenName)) {
      setError('givenName', 'First Name can\'t be empty.');
      errors.push('First Name can\'t be empty.');
    } else {
      cleanError('givenName');
    }
    
    if (!checkString(form.familyName)) {
      setError('familyName', 'Last Name can\'t be empty.');
      errors.push('Last Name can\'t be empty.');
    } else {
      cleanError('familyName');
    }

    if (!checkString(form.gender)) {
      setError('gender', 'Gender can\'t be empty.');
      errors.push('Gender can\'t be empty.');
    } else {
      cleanError('gender');
    }
    if (!checkString(form.userName)) {
      setError('userName', 'Username can\'t be empty.');
      errors.push('Username can\'t be empty.');
    } else {
      cleanError('userName');
    }
    if (!checkString(form.community)) {
      setError('community', 'Community can\'t be empty.');
      errors.push('community can\'t be empty.');
    } else {
      cleanError('community');
    }
    return errors;
  };

  const submitForm = () => {
    if (checkForm().length > 0) {
      setShowErrors(true);
      return;
    }
    setShowErrors(false);
    setLoading(true);
    Backend(
      MicroServices.AccessManager,
      `/auth/sign-up-code`,
      {
        method: 'POST',
        body: JSON.stringify({
          user: {
            ...form,
            clubs: [form.community],
            clubsAdvisor: [],
            clubsFollower: [],
            organizations: [org_id],
            roles: [[commRole]],
            organizationsNames: [org_name],
          }
        })
      }
    )
    .then(() => {
      toast.success(t('toast_userRegistered'));
      onSuccess();
    })
    .catch(() => {
      toast.error(t('toast_ThereWasError'))
    })
    .finally(() => setLoading(false))
  };

  return {
    form,
    formErrors,
    showErrors,
    setFormValue,
    setFormValueString,
    getError,
    submitForm,
    regeneratePassword,
  };

}