import { faUserFriends, faUserPlus, faUserSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { unixToDateFormatted } from "../../helpers/dateHelper";
import { capitalize } from "../../helpers/letterHelper";
import ClubInterface from "../../interfaces/ClubExpanded";
import { RootState } from "../../store/store";
import { LAVENDER, WHITE } from "../ClubIconsNavBar";
import ModalAddAdvisor from "../SetupSetup/ModalAddAdvisor";
import "./styles.css";
import { useTranslation } from "react-i18next";

const mapRoleAssigned: { [k: string]: string } = {
  'NO_ROLE_ASSIGNED': 'No Role Assigned Club',
  'ZAA#STUDENT': 'Student Club',
  'ZAA#STAFF': 'Staff Club',
  'ZAA#ALUMNI': 'Alumni Club',
  'ZAA#GUARDIAN': 'Guardian Club',
}

const Index = ({ club, manageClub }: {
  club: ClubInterface,
  manageClub: (type: 'Advisors' | 'Members' | 'Delete', club: ClubInterface) => void
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  //States
  const [selectedItemId, setSelectedItemId] = useState('0');
  const [selectedItemState, setSelectedItemState] = useState(false);

  //Effects

  //Functions
  const manageAdvisors = (club: any) => {
    manageClub('Advisors', club)
  }

  const inviteMembers = (club: any) => {
    manageClub('Members', club)
  }

  const suspendClub = (club: any) => {
    manageClub('Delete', club)
  }

  const toggleClick = (id: any) => {
    if (id === selectedItemId) {
      setSelectedItemState(!selectedItemState)
    } else {
      setSelectedItemId(id);
      setSelectedItemState(true);
    }
  };

  return (
    <tr>
      {/*<td className="checklist">
        <div className="">
          <div className="clubs-check">
            <label className="content-input-white">
              <input type="checkbox" name="button" value="Active" />
              <i></i>
            </label>
          </div>
        </div>
      </td>*/}
      <td>
        <div className="underlined id robotocondensed-normal-white-16px"
          onClick={() => navigate(`../../app/clubs/${club.id}`)}
        >
          {club.name}
        </div>
      </td>
      {/* <td>
        <div className="roles robotocondensed-normal-white-16px">
          <ul>{mapRoleAssigned[club.type || ''] || club.type}</ul>
        </div>
      </td> */}
      <td>
        <div className="city robotocondensed-normal-white-16px">
          <ul style={{ listStyle: 'circle inside' }}>
            {(club?.member_type || [])?.length > 0 && club?.member_type?.map((role) => (
              <li>{capitalize(role)}</li>
            ))}
          </ul>
        </div>
      </td>
      <td>
        <p className="organizationName robotocondensed-normal-white-16px">
          <ul>{unixToDateFormatted(club.id)}</ul>
        </p>
      </td>
      {/* <td>
        <div className="advisors robotocondensed-normal-white-16px">
          {club.advisors && club.advisors.map((advisor) => (
            <div className="advisor">
              {advisor.SK}
              <FontAwesomeIcon
  icon="trash-alt"
  size='lg'
  color={LAVENDER}
  className="delete-adv  "
  onClick={() => navigate("./view")}
/>
            </div>)
          )}
        </div>
      </td> */}
      <td>
        <p className="organizationName robotocondensed-normal-white-16px">
          <ul>{club.lastPost && club.lastPost.length > 0 && unixToDateFormatted(club.lastPost)}</ul>
        </p>
      </td>
      <td>
        <div className="status robotocondensed-normal-white-16px">
          <ul>{club.is_setup ? "Yes" : "No"}</ul>
        </div>
      </td>
      <td>
        <div className="status robotocondensed-normal-white-16px">
          <ul>{club.members || 0}</ul>
        </div>
      </td>
      <td>
        <div className="status robotocondensed-normal-white-16px">
          <ul>{club.posts || 0}</ul>
        </div>
      </td>
      <td>
        <div className="status robotocondensed-normal-white-16px">
          <ul>{club.events || 0}</ul>
        </div>
      </td>
      <td>
        <div className="status robotocondensed-normal-white-16px">
          <ul>{club.openInvites || 0}</ul>
        </div>
      </td>

      <td>
        <div className="actions">
          <FontAwesomeIcon
  icon="ellipsis-v"
  size='lg'
  color={LAVENDER}
  className="dots pointer  "
  onClick={() => toggleClick(club.id)}
/>

          {<OutsideClickHandler
            onOutsideClick={() => {
              setSelectedItemState(false);
            }}
          >
            <div className={`${club.id === selectedItemId && selectedItemState ? "activeDots" : ""} listOptionDots`} key={club.id}>
              <ul className="listOptionsClubsss">
                <li onClick={() => manageAdvisors(club)}>
                  <FontAwesomeIcon
                    icon={faUserPlus}
                    size='xl'
                    color={WHITE}
                    className="iconOptions"
                  />
                  {t('options__addAdvisors')}
                </li>
                <li onClick={() => inviteMembers(club)}>
                  <FontAwesomeIcon
                    icon={faUserFriends}
                    size='xl'
                    color={WHITE}
                    className="iconOptions"
                  />
                  {t('options__inviteMembers')}
                </li>
                {club.status !== 'DELETED' ? (
                  <li onClick={() => suspendClub(club)}>
                  <FontAwesomeIcon
                    icon={faUserSlash}
                    size='xl'
                    color={WHITE}
                    className="iconOptions"
                  />
                  {t('options__suspendClub')}
                </li>
                ) : (<></>)}
              </ul>
            </div>
          </OutsideClickHandler>}
        </div>
      </td>

    </tr>
  );
};

export default Index;
