import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import Webcam from "react-webcam";
import { LAVENDER, WHITE } from "../ClubIconsNavBar";
import './styles.css'

function CreatePostPhoto({imgSrc, setImgSrc, setTakePictureOk, loadingPicture, handleSaveImage}
    :{imgSrc: string | null | undefined,setImgSrc: any, setTakePictureOk: any,loadingPicture:boolean, handleSaveImage: any}) {

  const [isCapture, setIsCapture] = useState<boolean>(false);

  useEffect(() => {
    setTakePictureOk(true);
  },[])

  const WebcamCapture = () => {
      const webcam = useRef<Webcam>(null);
  
      const capture = React.useCallback(() => {
          const imageSrc = webcam?.current?.getScreenshot();
          setImgSrc(imageSrc);
          setIsCapture(true)
      }, [webcam, setImgSrc]);

      const trashCapture = () => {
          setImgSrc(null);
          setIsCapture(false)
      }

      const goodCapture = () => {
        setTakePictureOk(true);
        handleSaveImage(imgSrc);
      }

      return (
          <div className="imageCapture">
              <div className="containerCapture">
                  {imgSrc ? (
                      <img
                          src={imgSrc}
                      />
                  ) :
                      <Webcam
                          audio={false}
                          ref={webcam}
                          screenshotFormat="image/jpeg"
                          height={330}
                      />
                  }
              </div>
              <div className="buttonsCapture">
                  {imgSrc ? (
                    <>
                      <button onClick={trashCapture}>
                          <FontAwesomeIcon
                              icon="times"
                              size='lg'
                              color={WHITE}
                              className=" "
                              />
                      </button>
                      <button onClick={goodCapture}>
                        {loadingPicture ? (
                            <div className="icon-spinner-third buttonSendSignUp" />
                        ) : (
                            <FontAwesomeIcon
                            icon="check"
                            size='lg'
                            color={LAVENDER}
                            className=" "
                            />
                        )}
                      </button>
                    </>
                  ) :
                      <button onClick={capture}>
                          <FontAwesomeIcon
                              icon="camera"
                              size='lg'
                              color={WHITE}
                              className=" "
                              />
                      </button>
                  }

              </div>
          </div>
      );
  };
  
  return (
    <>
        <div className="">
          <div id="root"></div>
          <WebcamCapture />
        </div>
    </>
  );
}

export default CreatePostPhoto;