import { useEffect, useState } from "react";
import NumericInput from 'react-numeric-input';
import useGender from "../../../hooks/useGender";
import useGrades from "../../../hooks/useGrades";
import ModalMembersRegistered from "../../ClubMembers/ModalMembersRegistered";
import ModalAddAdvisor from '../ModalAddAdvisor';
import ModalInviteMembers from '../ModalInviteMembers';
import '../styles.scoped.css';
import { useTranslation } from "react-i18next";

function Index(props: {
    team: any;
    setTeam: any;
    organization?: any;
}) {
    const { t, i18n } = useTranslation();
    const [showInviteMembers, setShowInviteMembers] = useState(false);
    const { grades, setCountry: setCountryGrades } = useGrades();

    useEffect(() => {
        if (props.organization && props.organization?.countryCode) {
            setCountryGrades(props.organization.countryCode);
        }
    }, [])

    useEffect(() => {
        if (props.organization && props.organization?.countryCode) {
            setCountryGrades(props.organization.countryCode);
        }
    }, [props.organization])

    function handleCloseInvite(e: any, step: any, id: any, type: any) {
        setShowInviteMembers(false)
    };
    const handleOnClickInvite = (e: any) => { setShowInviteMembers(true) };

    //Handlers
    const handleCheckMemberType = (event: any) => {
        let updateList = [...props.team.member_type];
        if (event.target.checked) {
            updateList = [...props.team.member_type, event.target.value];
        } else {
            updateList.splice(props.team.member_type.indexOf(event.target.value), 1);
        }
        props.setTeam({ ...props.team, member_type: updateList });
    }

    const handleOnChangeNumberMembers = (value: number | null) => {
        props.setTeam({ ...props.team, maximumNumberMembers: value || 0 });
    }

    const handleCheckGradesAllowed = (event: any) => {
        let updateList = [...props.team.gradesAllowed];
        if (event.target.checked) {
            updateList = [...props.team.gradesAllowed, event.target.value];
        } else {
            updateList.splice(props.team.gradesAllowed.indexOf(event.target.value), 1);
        }
        props.setTeam({ ...props.team, gradesAllowed: updateList });
    }

    return (
        <div className="infoMembers">

            <div className="membersType">
                <h2>Member(s) Type *</h2>
                <span className="subtitle">{t('setupSchool__youCanMark')}</span>
                <div className="containers-input">
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input
                                type="checkbox"
                                name="checkbox-STUDENT"
                                value="STUDENT"
                                onChange={handleCheckMemberType}
                                checked={props.team.member_type.includes('STUDENT')}
                            />
                            <i></i>
                        </label>
                        <label htmlFor="students">{t('setupSchool__student')}</label>
                    </div>
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input
                                type="checkbox"
                                name="checkbox-STAFF"
                                value="STAFF"
                                onChange={handleCheckMemberType}
                                checked={props.team.member_type.includes('STAFF')}
                            />
                            <i></i>
                        </label>
                        <label htmlFor="students">{t('setupSchool__staff')}</label>
                    </div>
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input
                                type="checkbox"
                                name="checkbox-ALUMNI"
                                value="ALUMNI"
                                onChange={handleCheckMemberType}
                                checked={props.team.member_type.includes('ALUMNI')}
                            />
                            <i></i>
                        </label>
                        <label htmlFor="students">{t('setupSchool__alumni')}</label>
                    </div>
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input
                                type="checkbox"
                                name="checkbox-GUARDIAN"
                                value="GUARDIAN"
                                onChange={handleCheckMemberType}
                                checked={props.team.member_type.includes('GUARDIAN')}
                            />
                            <i></i>
                        </label>
                        <label htmlFor="students">{t('setupSchool__guardians')}</label>
                    </div>
                </div>
            </div>
            <div className="maximunMembers">
                <h2>{t('setupSchool__maximumNumber')}</h2>
                <NumericInput
                    className="form-control"
                    min={0}
                    value={props.team.maximumNumberMembers !== 0 ? props.team.maximumNumberMembers : undefined}
                    onChange={handleOnChangeNumberMembers}
                    style={{
                        input: {
                            marginRight: 3,
                            paddingRight: 4,
                            textAlign: 'left',
                            height: 54
                        },
                        btnUp: {
                            display: 'block',
                            width: '60px',
                            height: '25px',
                            boxShadow: 'none',
                            top: 1,
                            right: 0,
                            color: 'white'
                        },
                        btnDown: {
                            display: 'block',
                            width: '60px',
                            height: '25px',
                            boxShadow: 'none',
                            top: 28,
                            right: 0,
                            color: 'white'
                        },
                        'input:focus': {
                            border: '1px inset #69C',
                            outline: 'none'
                        },
                        arrowUp: {
                            borderBottomColor: 'white',
                            marginBottom: 1,
                            display: 'none'
                        },
                        arrowDown: {
                            borderTopColor: 'white',
                            marginTop: 1,
                            display: 'none'
                        }
                    }} />
            </div>
            <div className="gradesClub">
                <h2>{t('setupSchool__grades')}</h2>
                <span className="subtitle">{t('setupSchool__youCanMark')}</span>
                <div className="containers-input">
                    {props.organization && Array.from(Array(1 + props.organization.highGrade - props.organization.lowGrade)).map((_, idx) => (
                        <div className="option-chechbox">
                            <label className="content-input">
                                <input
                                    type="checkbox"
                                    name={`checkbox-${idx + props.organization.lowGrade}`}
                                    value={idx + props.organization.lowGrade}
                                    checked={props.team.gradesAllowed.includes(`${idx + props.organization.lowGrade}`)}
                                    onChange={handleCheckGradesAllowed}
                                />
                                <i></i>
                            </label>
                            <label htmlFor="students">{grades.find((grade) => grade.id === idx + props.organization.lowGrade)?.listLabel || (idx + props.organization.lowGrade)}</label>
                        </div>
                    ))}
                </div>
            </div>
            <ModalMembersRegistered show={showInviteMembers} onHide={handleCloseInvite} club_id={props.team.id} />
            {/*<ModalInviteMembers show={showInviteMembers} onHide={handleCloseInvite} club_id={props.team.id}/>*/}
            <button onClick={handleOnClickInvite} className="inviteButton">
                {t('options__inviteMembers')}
            </button>
        </div>
    );
}

export default Index;
