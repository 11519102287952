import "./styles.scoped.css";
import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalSendAha from './ModalSendAha';
import ModalDiscardChanges from "../SetupSetup/ModalDiscardChanges";
import { useDispatch, useSelector } from "react-redux";
import { getBalance } from "../../store/actions/userActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER, WHITE } from "../ClubIconsNavBar";
import { toast } from "react-toastify";
import { RootState } from "../../store/store";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { useTranslation } from "react-i18next";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";
import ChatService from "../../hooks/Chat/ChatService";

interface ModalAhaProps {
    postInfo: {author:string,imageProfile:string,nameProfile:string};
    show: boolean;
    onHide: any;
    ChatServiceInstance: ChatServiceInterface;
}
 
function ModalAha(props: ModalAhaProps) {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const user = useSelector((state: any) => state.getUser.user);
    const [showModalAha, setShowModalAha] = useState(false);
    const [numberAha, setNumberAha] = useState(0);
    const [showDiscard, setShowDiscard] = useState(false);
    const [description, setDescription] = useState('');
    const [loadingSend, setLoadingSend] = useState(false);
    const [reason, setReason] = useState<string>();

    useEffect(()=>{
        if(!props.ChatServiceInstance.chatConnected)props.ChatServiceInstance.connect({
            username: user?.username || '',
            fullName: `${user?.firstname} ${user?.lastname}`,
            avatar: user?.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png',
            status: 'available'
          })
    },[props.ChatServiceInstance.chatConnected])

    const ahaBalance = useSelector((state: RootState) => state.getUser.GetAhaBalance);
    const myUsername = useSelector((state: any) => state.getUser.userInfo.username);

    function handleCloseModalAha(type: any) {
        if (type === 'send') {
            setLoadingSend(true);
            Backend(
                MicroServices.AHA,
                '/transaction/tip-student',
                {
                    method: 'POST',
                    body: JSON.stringify({
                        from: myUsername,
                        to: props.postInfo.author,
                        aHa: Number(numberAha),
                        message: `${reason} - ${description}`
                    })
                }
            )
                .then((response) => {
                    if (response.status === 201) {
                        toast.success(t('toast_tipSent'));
                        props.ChatServiceInstance.sendMessage(
                            props.postInfo.author,
                            description,
                            'aha',
                            numberAha
                            )
                    }
                    else {
                        toast.error(t('toast_errorSendingTip'));
                    }
                    return response.json();
                })
                .then((results) => {
                    console.log(results)
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    dispatch(getBalance(myUsername))
                    setLoadingSend(false);
                    onHideDiscard('discard');
                })
        } else {
            console.log('not sending')
            setShowModalAha(false);
        }
    };

    function handleNumberAha(data: any) {
        setNumberAha(data)
    };

    const onHideDiscard = (type: string) => {
        if (type === 'discard') {
            setNumberAha(0);
            setShowModalAha(false);
            props.onHide();
        }
        setShowDiscard(false);
    };

    const canSend = () => numberAha > 0 &&
        numberAha <= (ahaBalance || 0) &&
        reason !== undefined &&
        description.length > 0;

    return (
        <>
            <Modal className="modalSendAha colorPurple" show={props.show}>
                <Modal.Header>
                    <div className="containerProfile">
                        <div className="infoPostHeader">
                            <div className="contImage">
                                <img className="round-avatar" src={props.postInfo.imageProfile || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" />
                            </div>
                            <div className="infoProfile pointer">
                                Send aHa to {props.postInfo.nameProfile}
                            </div>
                        </div>
                        <FontAwesomeIcon
                            icon="times"
                            size='xl'
                            color={WHITE}
                            className="icon-times  "
                            onClick={() => setShowDiscard(true)}
                        />
                    </div>
                    <h2 className="modal-title">Current balance</h2>
                    <span className="countAha">
                        {ahaBalance !== undefined ? ahaBalance : '-'}
                        <img className="aha-logo-text-post" src={"/aHa-logo_w.png"} alt="aha-logo" />
                    </span>
                </Modal.Header>
                <Modal.Body>
                    <div className="containerAha containerSend">
                        <label htmlFor="">Send</label>
                        <div className="inputSend">
                            <input
                                type="number"
                                value={numberAha}
                                onChange={(e) => handleNumberAha(e.target.value)}
                                className="ahaInput"
                            />
                            <img className="aha-logo-text-post" src={"/aHa-logo_w.png"} alt="aha-logo" />
                        </div>
                    </div>
                    <div className="containerAha containerReason">
                        <label htmlFor="">Reason for sending aHa</label>
                        <div className="inputSend">
                            <select name="" id="" onChange={(e) => setReason(e.currentTarget.value)} value={reason}>
                                <option value={undefined} selected disabled>Select an option</option>
                                <option value="Amazing Content">Amazing Content</option>
                                <option value="Your Positivity">Your Positivity</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>
                    <div className="containerAha containerMessage">
                        <label htmlFor="">Add a message</label>
                        <div className="inputSend">
                            <textarea
                                placeholder="Tell why are you sending aHa."
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                maxLength={100}
                                className="ahaDescriptionTextarea"
                            />
                            <span className="countLetters">{description.length}/100</span>
                        </div>
                    </div>
                    <ModalSendAha
                        profile={props.postInfo.nameProfile}
                        number={numberAha}
                        show={showModalAha}
                        onHide={handleCloseModalAha}
                        loadingSend={loadingSend}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={() => setShowModalAha(true)}
                        className={canSend() ? '' : "DisabledButtonSendAha"}
                        disabled={!canSend()}
                    >Send aHa</button>
                </Modal.Footer>
            </Modal>
            <ModalDiscardChanges show={showDiscard} onHide={onHideDiscard} />
        </>
    );
}

export default ModalAha;