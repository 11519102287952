import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import "./styles.css";
import ClubViewInformation from "../../components/ClubViewInformation";
import ClubViewRequests from "../../components/ClubViewRequests";
import ClubEvents from "../../components/ClubEvents";
import ClubMembers from "../ClubMembers";
import ClubFollowers from "../../components/ClubFollowers";
import ClubFlags from "../../components/ClubFlags";
import ClubInvites from "../../components/ClubInvites";
import ClubResources from "../../components/ClubResources";
import ClubSettings from "../../components/ClubSettings";
import { getClubsImAdv } from "../../store/actions/clubActions";
import { useParams } from "react-router-dom";
import Posts from "../../containers/Posts";
import { useTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";

interface ClubViewNavBarProps{
  //todo -> put types
  club:any;
  officers:any;
  reloadClub:any;
  reloadMembers:any;
  members:any;
  advisors:any;
  reloadOfficers:any;
  reloadAdvisors:any;
  clubCode:any;
  ChatServiceInstance:ChatServiceInterface;
}

function Index(props: ClubViewNavBarProps) {
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState("Feed");
  const [screen, setScreen] = useState("My clubs");
  const [isUserAdvisor, setIsUserAdvisor] = useState(false);
  const [invitesAdded, setInvitesAdded] = useState(false);
  const [showBubble, setShowBubble] = useState(false);


  const userRoles = useSelector((state: any) => state.getUser.userRoles);
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    setSelected("Feed");
    setScreen("Feed");
  }, []);

  useEffect(() => {
    setIsUserAdvisor(false);
    isClubAdvisor();
  }, [id]);

  const isClubAdvisor = () => {
    if (userRoles.includes("ZAA#ORGADMIN")) {
      setIsUserAdvisor(true);
      return;
    }
    dispatch<any>(getClubsImAdv(userInfo.username))
      .then((clubs: any) => {
        for (let club of clubs) {
          if (club.id === id) {
            setIsUserAdvisor(true);
            break;
          }
        }
        return;
      });
  }

  function selectScreen(screen: string) {
    switch (screen) {
      case "Information":
        return <ClubViewInformation
          infoClub={props.club}
          isClubAdvisor={isUserAdvisor}
          officers={props.officers}
          reloadClub={props.reloadClub}
          members={props.members}
          advisors={props.advisors}
          reloadOfficers={props.reloadOfficers}
          reloadAdvisors={props.reloadAdvisors}
        />;
      case "Requests":
        return <ClubViewRequests reloadClub={props.reloadClub} infoClub={props.club} isClubAdvisor={isUserAdvisor} />;
        break;
      case "Events":
        return <ClubEvents infoClub={props.club} isClubAdvisor={isUserAdvisor} />;
        break;
      case "Members":
        return <ClubMembers
          infoClub={props.club}
          isClubAdvisor={isUserAdvisor}
          officers={props.officers}
          reloadClub={props.reloadClub}
          reloadMembers={props.reloadMembers}
          setInvitesAdded={setInvitesAdded}
          members={props.members}
          advisors={props.advisors} 
          ChatServiceInstance={props.ChatServiceInstance}        />;
        break;
      case "Followers":
        return <ClubFollowers infoClub={props.club} /*isClubAdvisor={isUserAdvisor}*/ ChatServiceInstance={props.ChatServiceInstance} />;
        break;
      case "Flags":
        return <ClubFlags />;
        break;
      case "Invites":
        return <ClubInvites infoClub={props.club} invitesAdded={invitesAdded} setInvitesAdded={setInvitesAdded} />;
        break;
      case "Resources":
        return <ClubResources />;
        break;
      case "Settings":
        return <ClubSettings clubCode={props.clubCode} />;
        break;
      case "Feed":
        return <Posts whereIs={'club'} /*postInput={true}*/ clubId={id} tabActive={6} eventId={undefined} username={undefined} ChatServiceInstance={props.ChatServiceInstance} />;
      default:
        return <></>;
        break;
    }
  }

  return (
    <>
      <div className="club-view-navbar">
        <div
          className={
            selected === "Feed" ? "club-view-row-selected" : "club-view-row"
          }
          id="left-rad-up"
          onClick={() => {
            setSelected("Feed");
            setScreen("Feed");
          }}
        >
          <div className={"section-label robotocondensed-normal-white-16px"}>
            {t('infoClub__feed')}
          </div>
        </div>
        <div
          className={
            selected === "Information"
              ? "club-view-row-selected"
              : "club-view-row"
          }
          onClick={() => {
            setSelected("Information");
            setScreen("Information");
          }}
        >
          <div className={"section-label robotocondensed-normal-white-16px"}>
            {t('infoClub__information')}
          </div>
        </div>
        <div
          className={
            selected === "Events" ? "club-view-row-selected" : "club-view-row"
          }
          onClick={() => {
            setSelected("Events");
            setScreen("Events");
          }}
        >
          <div className={"section-label robotocondensed-normal-white-16px"}>
            {t('infoClub__events')}
          </div>
        </div>
        <div
          className={
            selected === "Members" ? "club-view-row-selected" : "club-view-row"
          }
          onClick={() => {
            setSelected("Members");
            setScreen("Members");
          }}
        >
          <div className={"section-label robotocondensed-normal-white-16px"}>
            {t('infoClub__members')}
          </div>
        </div>
        {(props?.club?.club_type !== 'COMM') && (
          <div
            className={
              selected === "Followers"
                ? "club-view-row-selected"
                : "club-view-row"
            }
            id="right-rad-up"
            onClick={() => {
              setSelected("Followers");
              setScreen("Followers");
            }}
          >
            <div className={"section-label robotocondensed-normal-white-16px"}>
              {(props?.club?.club_type === 'TEAM') ? t('infoClub__fan') : t('infoClub__followers') || t('infoClub__followers')}
            </div>
          </div>
        )}

      </div>

      {(isUserAdvisor) &&
        (<div className="club-view-navbar">

          {(props?.club?.club_type !== 'COMM') && (
            <div
              className={
                selected === "Requests" ? "club-view-row-selected" : "club-view-row"
              }
              id="left-rad-down"
              onClick={() => {
                setSelected("Requests");
                setScreen("Requests");
              }}
            >
              <div className={"section-label robotocondensed-normal-white-16px"}>
                {t('infoClub__requests')}
              </div>
            </div>
          )}

          <div
            className={
              selected === "Flags" ? "club-view-row-selected" : "club-view-row"
            }
            onClick={() => {
              setSelected("Flags");
              setScreen("Flags");
            }}
          >
            <div className={"section-label robotocondensed-normal-white-16px"}>
              {t('infoClub__flags')}
            </div>
          </div>
          <div
            className={
              selected === "Invites" ? "club-view-row-selected" : "club-view-row"
            }
            onClick={() => {
              setSelected("Invites");
              setScreen("Invites");
            }}
          >
            <div className={"section-label robotocondensed-normal-white-16px"}>
              {t('infoClub__invites')}
            </div>
          </div>

          <div
            className={
              selected === "Resources"
                ? "club-view-row tabResources" //@todo change club-view-row to club-view-row-selected on style when the resources are active 
                : "club-view-row"
            }
            onClick={() => {
              setSelected("Resources");
              setShowBubble(true);
              // setScreen("Resources");
            }}
          >
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowBubble(false);
              }}
            >
              <div className={`bubbleComingSoon ${showBubble && 'bubbleActive'}`}>
                {t('label_comingSoon')}
              </div>
            </OutsideClickHandler>

            <div className={"section-label robotocondensed-normal-white-16px"}>
              {t('infoClub__resources')}
            </div>
          </div>

          <div
            className={
              selected === "Settings" ? "club-view-row-selected" : "club-view-row"
            }
            id="right-rad-down"
            onClick={() => {
              setSelected("Settings");
              setScreen("Settings");
            }}
          >
            <div className={"section-label robotocondensed-normal-white-16px"}>
              {t('infoClub__settings')}
            </div>
          </div>
        </div>)
      }

      {selectScreen(screen)}
    </>
  );
}

export default Index;
