import { useEffect, useMemo, useState } from "react";
import useTCListsSA from "../../../hooks/tclub/useTCListsSA";
import TCListSelect from "../../../interfaces/TClubs/TCList/TCListSelect";
import Select from "react-select";
import { customStylesReactSelect, customStylesRSLeft, DropdownIndicator } from "../../../helpers/selectHelper";

const ALL_LISTS = {
    label: 'All template clubs',
    value: 'ALL',
}

const BASE_LIST = [
    ALL_LISTS,
    {
        label: 'Create New List',
        value: 'CREATE',
    }
]

interface TCListsDropdownProps {
    setSelected: (id: string) => void;
    reload: boolean;
    onLoaded: () => void;
}

const TCListsDropdown = ({
    setSelected,
    reload,
    onLoaded,
}: TCListsDropdownProps) => {
    const {tclists, reload: reloadTCList} = useTCListsSA();

    const tcOptions: TCListSelect[] = useMemo(() => [
        ...BASE_LIST,
        ...tclists.map((list) => ({
            label: list.name,
            value: list.id,
        }))
    ], [tclists])

    const [tclistSelected, setTclistSelected] = useState<TCListSelect>(ALL_LISTS);

    useEffect(() => {
        if (reload) {
            reloadTCList();
            setTclistSelected(ALL_LISTS);
            setSelected(ALL_LISTS.value);
            onLoaded();
        }
    }, [reload]);

    const handleSetTCList = (selected: any) => {
        setTclistSelected(selected);
        setSelected(selected.value);
    }

    return (
        <Select
            options={tcOptions}
            styles={customStylesRSLeft()}
            components={{
                DropdownIndicator
            }}
            value={tclistSelected}
            onChange={handleSetTCList}
            placeholder="Select a list"
        />
    )
};

export default TCListsDropdown;