import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import '../LoginWrp/styles.scoped.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClock, faUserShield, faMedal } from "@fortawesome/pro-regular-svg-icons";
import MenuWrp from '../../components/MenuWrp'
import Footer from "../../components/Footer";

const AuthLayout: React.FC = ({ children }) => {

  const navigate = useNavigate();
  const [activePopup, setActivePopup] = useState(false)


  function handleMenu(e: any) {
    setActivePopup(true);
  }

  function handleCloseMenu() {
    setActivePopup(false);
  }

  return (
    <>
      <div className='containerSignUp'>
        <div className={`overlap ${activePopup && 'active'}`} onClick={handleCloseMenu}>
          <MenuWrp activePopup={activePopup} />
        </div>
        <div className='headerSignUp'>
          <div className="wrapContainer">
            <div className='logoSignUp'>
              <a href="https://possibilitycompany.com/">
                <img src="https://possibilitycompany.com/wp-content/uploads/2020/02/Possibility-Color_Logotype.png" alt="" />
              </a>
            </div>
            <div className='buttonMenu'>
              <button className="loginSignUp" onClick={() => navigate('/')}>Login</button>
              <button className="signUpSignUp" onClick={() => navigate('/signup')}>Sign Up</button>
              <button className="menuSignUp">
                <FontAwesomeIcon
                  icon={faBars}
                  size='xl'
                  color={"white"}
                  onClick={(e) => handleMenu(e)}
                  className="buttonMenu"
                />
              </button>
            </div>
          </div>
        </div>
        <div className="containerSection containerPanelPrincipal ">
          <div className="col col1">
            <div className='grayFilter'></div>
            <div className="signUpInfo">
              <h2>Schools and School Systems</h2>
              <div className='textInfoFirst'>
                One, unifying platform to manage and promote all extracurricular activities ensuring equal awareness and access to opportunity.
              </div>
              <div className='textInfo'>
                <FontAwesomeIcon
                  icon={faClock}
                  size='xl'
                  color={"white"}
                />
                <p>Save teachers time and receive previously unavailable insight into school or system wide engagement, i.e., by school, gender, ethnicity, class year and socioeconomic status.</p>
              </div>
              <div className='textInfo'>
                <FontAwesomeIcon
                  icon={faUserShield}
                  size='xl'
                  color={"white"}
                />
                <p>Protect students, with a safe, invitation-only social-network that promotes student exploration of their interests through participation in real-life activities.</p>
              </div>
              <div className='textInfo'>
                <FontAwesomeIcon
                  icon={faMedal}
                  size='xl'
                  color={"white"}
                />
                <p>Align student engagement with digital rewards – supporting contextual financial literacy and the opportunity for all student to earn micro-scholarships.</p>
              </div>
            </div>
          </div>
          <div className="col col2">
            <h1 className="valign-text-middle-authLayout robotocondensed-bold-white-48px">
              Zaahah Sign up
            </h1>
            <div
              className="login-blur-container-authLayout"
              style={{ backgroundImage: `url(https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/rectangle-3616@1x.png)` }}
            >
              {children}
            </div>
          </div>
        </div>
        <Footer />
      </div>

    </>
  );
}

export default AuthLayout;