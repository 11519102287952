import "./styles.scoped.css";
import Welcome from '../../components/SetupAdvisor/Welcome';
import MyProfile from '../../components/SetupAdvisor/MyProfile';
import Interests from '../../components/SetupAdvisor/Interests';
import Departments from '../../components/SetupAdvisor/Departments';
import ClubsAndTeams from '../../components/SetupAdvisor/ClubsAndTeams';
import Complete from '../../components/SetupAdvisor/Complete';

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClubSuggestions, getInterestSuggested, getUserOrgSuggestion, SaveInterests } from "../../store/actions/connectionActions";
import { editUser, getUser, saveUserDepartments } from "../../store/actions/userActions";
import { editClub, getClubsImAdv } from "../../store/actions/clubActions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { capitalize } from "../../helpers/letterHelper";
import useGrades from "../../hooks/useGrades";
import { getOrganizationInfo } from "../../store/actions/orgsActions";
import { InterestInfo } from "../SetupStudent";

toast.configure()
export interface Interest {
  id: number;
  title: string;
  active: boolean;
}
export interface Department {
  id: number;
  title: string;
  active: boolean;
}

export interface CCT {
  comms: any[];
  clubs: any[];
  teams: any[];
}

function SetupAdvisor(props: any) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  //States
  const [stepActive, setStepActive] = useState(1);
  const [editedUser, setEditerUser] = useState<any>({})
  const [interests, setInterests] = useState<Interest[]>([])
  const [myInterests, setMyInterests] = useState<Interest[]>([]);
  const [departments, setDepartments] = useState<Department[]>([])
  const [myDepartments, setMyDepartments] = useState<Department[]>([])
  const [suggestedDepartments, setSuggestedDepartments] = useState<string[]>([])
  const [completeClubs, setCompleteClubs] = useState<any[]>([])
  const [editedCompleteClubs, setEditedCompleteClubs] = useState<any[]>([])
  const [clubs, setClubs] = useState<CCT>({ comms: [], clubs: [], teams: [] })
  const [editedClubs, setEditedClubs] = useState<CCT>({ comms: [], clubs: [], teams: [] })
  const [clubsToChange, setClubsToChange] = useState<any[]>([])
  const [showClubsStepAbove, setShowClubsStepAbove] = useState<boolean>(true);

  //Selectors
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const user = useSelector((state: any) => state.getUser.user);
  const suggestedInterests = useSelector((state: any) => state.getConnection.GetISug);
  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  );

  
  const { grades, setCountry: setCountryGrades } = useGrades();

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryGrades(organization.countryCode);
    }

    showClubsStep();
  }, [])

  useEffect(() => {
    showClubsStep();
  }, [editedClubs])

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryGrades(organization.countryCode);
    }
  }, [organization])

  //Effects
  useEffect(() => {
    i18n.changeLanguage(getLanguage(user.preferredLanguage))

    if(organization && "countryCode" in organization && organization.countryCode !== ""){
      dispatch(getInterestSuggested(organization.countryCode));
    }

    dispatch(getUser(userInfo.username));
    Backend(
      MicroServices.Connections,
      `/profile/departments/suggestions`)
      .then((response) => response.json())
      .then((results) => {
        setSuggestedDepartments(results)
      })
  }, []);

  useEffect(() => {
    setEditerUser(user)
    if (user && "organizations" in user && user.organizations.length > 0) {
      
      if(!organization){
        dispatch<any>(getOrganizationInfo(user.organizations[0])) //@todo more orgs
      }

      dispatch(
        getClubSuggestions(user.organizations[0], user.grade, user.gender, user.roles[0].split('#')[1], user.username) //TODO -> When user has more orgs and roles
      );
      dispatch(getUserOrgSuggestion(user.organizations[0], user.roles[0] !== 'ZAA#STUDENT')); //TODO -> When is alumni or guardian
    }
  }, [user]);

  useEffect(() => {
    if (suggestedInterests.length > 0) {
      setInterests(suggestedInterests.map((item: InterestInfo) => ({
        id: item.id,
        title: item.name,
        active: false,
      })));
    }
  }, [suggestedInterests])

  useEffect(() => {
    setDepartments(suggestedDepartments.map((item: string, idx: number) => {
      return {
        id: idx,
        title: item,
        active: false
      }
    }))
  }, [suggestedDepartments])

  useEffect(() => {

    if (userInfo.username) {
      dispatch<any>(getClubsImAdv(userInfo.username))
        .then((results: any) => {
          setCompleteClubs(results)
          setClubs({
            comms: (results.filter((item: any) => item.club_type === 'COMM')).map((item: any, idx: any) => {
              return {
                id: idx,
                name: item.name,
                memberType: item.member_type.map((type: any) => capitalize(type)).join(' • '),
                genders: item.gendersAllowed.join(' • '),
                image: item.club_logo || organization.logo || '',
                club_id: item.id,
                is_setup: item.is_setup || false
              }
            }),
            clubs: (results.filter((item: any) => item.club_type === 'CLUB')).map((item: any, idx: any) => {
              return {
                id: idx,
                name: item.name,
                youth_org: item.YO_id ? 'Youth Org' : "",
                memberType: item.member_type.map((type: any) => capitalize(type)).join(' • '),
                genders: item.gendersAllowed.join(' • '),
                grades: (item.gradesAllowed.map((grade: string) => grades.find((gradeHook) => gradeHook.id === Number(grade))?.listLabel || grade)).join(' • '),
                image: item.club_logo || organization.logo || '',
                club_id: item.id,
                is_setup: item.is_setup || false
              }
            }),
            teams: (results.filter((item: any) => item.club_type === 'TEAM')).map((item: any, idx: any) => {
              return {
                id: idx,
                name: item.name,
                template: item.template_id,
                memberType: item.member_type.map((type: any) => capitalize(type)).join(' • '),
                max_members: item.maximumNumberMembers,
                grades: (item.gradesAllowed.map((grade: string) => grades.find((gradeHook) => gradeHook.id === Number(grade))?.listLabel || grade)).join(' • '),
                image: item.club_logo || organization.logo || '',
                club_id: item.id,
                is_setup: item.is_setup || false
              }
            }),
          })
        })
        .catch(() => toast.error(t('toast_errorOccurred')))
    }
  }, [userInfo, grades]);

  useEffect(() => {
    setEditedClubs(clubs)
  }, [clubs])

  useEffect(() => {
    setEditedCompleteClubs(completeClubs)
  }, [completeClubs])

  //Handlers
  function handleOnClick(e: any, step: any, save: boolean) {
    if (step < 6) {
      setStepActive(step);
    }
    switch (step) {
      case 2:
        if (!save) setEditerUser(user)
        break
      case 5:
        if (!save) {
          setEditedClubs(clubs)
          setEditedCompleteClubs(completeClubs)
          setClubsToChange([])
        }
        break
      default:
        break
    }
  }

  const showClubsStep = () => {

    if (editedClubs.comms.length > 0 && editedClubs.clubs.length === 0 && editedClubs.teams.length === 0) {
      setShowClubsStepAbove(false);
    }
    else {
      setShowClubsStepAbove(true);
    }
  }

  const handleFinish = () => {
    dispatch(
      editUser(userInfo.username,
        'OA',
        {
          firstname: editedUser.firstname,
          lastname: editedUser.lastname,
          gender: editedUser.gender,
          birthdate: editedUser.birthdate
        })
    );
    dispatch(
      SaveInterests(
        userInfo.username,
        myInterests.filter((i: any) => i.active).map((i: any) => i.title),
        user.organizations[0]
      )
    );
    dispatch(
      saveUserDepartments(
        userInfo.username,
        myDepartments.map((item: any) => item.title)
      )
    );
    clubsToChange.forEach((club: any) => dispatch(editClub(club)))
    Backend(
      MicroServices.AccessManager,
      `/users/${userInfo.username}/setup-complete-user`, {
      method: "POST",
    }).then((results) => {
      if (results.status === 200) {
        dispatch<any>(getUser(userInfo.username))
          .then(() => {
            props.setShowSetupUser(false);
            navigate("/app");
          });
      }
    });
  };

  function getLanguage(e: string) {
    switch (e) {
      case "spanish":
        return 'es'
        break;
      case "english":
        return 'en'
        break;
      default:
        return '';
        break;
    }
  }
  
  

  return (
    <div className="container-setup-advisor">
      <div className="navegatorStep">
        <div className="steps">
          <div className="allSteps">
            {/*(<div className="step">
              <div
                className={`${stepActive === 0 ? "active" : ""} ${stepActive > 0 ? "activeStep" : ""
                  } stepIcon step-0`}
                onClick={(e) => handleOnClick(e, 0, true)}
              >
                <div className="circle-setup" />
                <span className="stepTitle">Welcome</span>
              </div>
            </div>)*/}
            <div className={`${showClubsStepAbove ? 'step' : 'stepComplete'}`}>
              <div
                className={`${stepActive === 1 ? "active" : ""} ${stepActive > 1 ? "activeStep" : ""
                  } stepIcon step-1`}
                onClick={(e) => handleOnClick(e, 1, true)}
              >
                <div className="circle-setup" />
                <span className="stepTitle">{t('setupStaff__myProfile')}</span>
              </div>
            </div>
            <div className={`${showClubsStepAbove ? 'step' : 'stepComplete'}`}>
              <div
                className={`${stepActive === 2 ? "active" : ""} ${stepActive > 2 ? "activeStep" : ""
                  } stepIcon step-2`}
                onClick={(e) => handleOnClick(e, 2, true)}
              >
                <div className="circle-setup" />
                <span className="stepTitle">{t('setupStaff__interests')}</span>
              </div>
            </div>
            <div className={`${showClubsStepAbove ? 'step' : 'stepComplete'}`}>
              <div
                className={`${stepActive === 3 ? "active" : ""} ${stepActive > 3 ? "activeStep" : ""
                  } stepIcon step-3`}
                onClick={(e) => handleOnClick(e, 3, true)}
              >
                <div className="circle-setup" />
                <span className="stepTitle">{t('setupStaff__departments')}</span>
              </div>
            </div>
            {(showClubsStepAbove) && (
              <div className={`${showClubsStepAbove ? 'step' : 'stepComplete'}`}>
                <div
                  className={`${stepActive === 4 ? "active" : ""} ${stepActive > 4 ? "activeStep" : ""
                    } stepIcon step-4`}
                  onClick={(e) => handleOnClick(e, 4, true)}
                >
                  <div className="circle-setup" />
                  <span className="stepTitle">{t('dashboard__clubTeams')}</span>
                </div>
              </div>
            )}

            <div className={`${showClubsStepAbove ? 'step' : 'stepComplete'}`}>
              <div
                className={`${stepActive === 5 ? "active" : ""} stepIcon step-5`}
                onClick={(e) => handleOnClick(e, 5, true)}
              >
                <div className="circle-setup" />
                <span className="stepTitle">{t('setupStaff__complete')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*stepActive == 0 ? (
        <Welcome handleOnClick={handleOnClick} />
      ) : */}
      {stepActive == 1 ? (
        <MyProfile handleOnClick={handleOnClick} user={editedUser} setUser={setEditerUser} />
      ) : stepActive == 2 ? (
        <Interests
          handleOnClick={handleOnClick}
          setMyInterests={setMyInterests}
          myInterests={myInterests}
          setInterests={setInterests}
          interests={interests}
        />
      ) : stepActive == 3 ? (
        <Departments
          handleOnClick={handleOnClick}
          setMyDepartments={setMyDepartments}
          myDepartments={myDepartments}
          setDepartments={setDepartments}
          departments={departments}
          showClubsStepAbove={showClubsStepAbove}
        />
      ) : stepActive == 4 ? (
        <ClubsAndTeams
          handleOnClick={handleOnClick}
          clubs={editedClubs}
          setClubs={setEditedClubs}
          changedClubs={clubsToChange}
          setChangedClubs={setClubsToChange}
          completeClubs={editedCompleteClubs}
          setCompleteClubs={setEditedCompleteClubs}
        />
      ) : stepActive == 5 ? (
        <Complete handleFinish={handleFinish} />
      ) : <></>}
    </div>
  );
}

export default SetupAdvisor;
