import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import '../../containers/SetupStudent/styles.scoped.css';
import { useTranslation } from "react-i18next";

function Index() {
  const { t, i18n } = useTranslation();
  const user = useSelector((state: any) => state.getUser.user);

  function getLanguage(e: string) {
    switch (e) {
      case "spanish":
        return 'es'
        break;
      case "english":
        return 'en'
        break;
      default:
        return '';
        break;
    }
  }

  useEffect(() => {
    i18n.changeLanguage(getLanguage(user.preferredLanguage))
  }, []);

  return (
    <div className="containerStartSetup">
      <div className="logo">
        <img src="/Z_aahah_b.png" alt="" />
        <p className="textLogo">Zaahah</p>
      </div>

      <h4 className="nameStudent">{`${user.firstname || ''} ${user.lastname || ''}`}</h4>
      <p>{t('setupStudent__yourRegistration')}</p>
      <p className='span'>
        {t('setupStudent__toAccess')}{' '}
        {/* <img className="aha-logo" src="/aHa-logo_w.png" alt="aha-logo" />{' '} */}
        <i className='aha-icon' />
        {t('setupStudent__rewards')}
      </p>
    </div>
  );
}

export default Index;
