import React, { useState } from "react";
import useGender from "../../hooks/useGenderOld";
import './styles.scoped.css';
import '../../containers/LoginWrp/styles.scoped.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER, WHITE } from "../ClubIconsNavBar";
import DatePicker from 'react-datepicker';
import { dateToUpdate, getBaseYear, stringToDate } from "../../helpers/dateHelper";
import { useMonths, useYears } from "../../hooks/dateHooks";
import { GenderClean } from "../../interfaces/personalization/genders";

interface SignupTextInputProps {
  placeholder: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  value: string;
  showErrors: boolean;
  error: () => string;
};

export const SignupTextInput = ({
  placeholder,
  onChange,
  value,
  showErrors,
  error,
}: SignupTextInputProps) => {

  const [isActive, setActive] = useState(false);
  const handleToggle = (e: any) => {
    e.preventDefault();
    setActive(!isActive);
  };

  return (
    <div className="form-parts-3-1" >
      <div className="frame-5448" >
        <input
          placeholder={placeholder}
          type={(placeholder !== "Password*") ? "text" : (isActive ? 'text' : 'password')}
          className="type-here-the-information roboto-normal-white-16px"
          onChange={onChange}
          value={value}
        />
        {(placeholder === "Password*") && (<button
          onClick={(e) => handleToggle(e)}
          className="buttonEye"
          type="button"
        >
          <FontAwesomeIcon
            icon={isActive ? 'eye-slash' : 'eye'}
            color={LAVENDER}
            size='lg'
            className='eye'
          />
        </button>)}
      </div>
      {showErrors && <span className='robotocondensed-bold-violent-violet-16px error-text'>{error()}</span>}
    </div>
  )
};

interface GenderSelectProps {
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  value: string;
  showErrors: boolean;
  error: string;
  genders: GenderClean[]
};

export const GenderSelect = ({
  onChange,
  value,
  showErrors,
  error,
  genders,
}: GenderSelectProps) => {
  const genderList = useGender();
  return (
    <div className="Gender-container-signup-withError">
      <div className="Gender-container-signup">
        <div className="genderLabel robotocondensed-bold-violent-violet-14px">
          Gender*
        </div>
        <div className="frame-5422-signup">
          <div className="frame-5422">
            {genders.map(({ id: key, label }) => (
              <div className="optionRadio" key={key}>
                <label className="content-input">
                  <input name="gender" type="radio" value={key} onChange={onChange} checked={value === key} />
                  <i className="gender-radio"></i>
                </label>
                <div className="male radioButton robotocondensed-normal-white-16px">{label}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {showErrors && <span className='robotocondensed-bold-violent-violet-16px error-text'>{error}</span>}
    </div>
  );
}

interface BirthdayInputProps {
  value: string;
  onChange: (date: string) => any
};

export const BirthdayInput = ({ value, onChange }: BirthdayInputProps) => {
  return (
    <div className="form-parts-3-1">
      <div className="frame-5448 myBirthdayContainer">
        <FontAwesomeIcon
          icon="calendar-alt"
          size='lg'
          color={WHITE}
          className="calendar-alt inputIcon"
        />
        <DatePicker
          dateFormat="MM-dd-yyyy"
          placeholderText="Birthday"
          selected={value === '' ? undefined : stringToDate(value)}
          onChange={(date: Date) => onChange(dateToUpdate(date))}
          className='inputDirthday'
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          maxDate={getBaseYear()}
        />
      </div>
    </div>
  );
}

interface LanguageDropdownProps {
  value: string;
  onChange: (e: React.FormEvent<HTMLSelectElement>) => void;
};

export const LanguageDropdown = ({ value, onChange }: LanguageDropdownProps) => {
  return (
    <div className="LanguageDropdown-container-signup">
      <div className="preferedLanguageSelector roboto-normal-white-16px">
        <select className="preferedLanguageSelect roboto-normal-white-16px"
          onChange={onChange}
        >
          <option disabled>Prefered Language*</option>
          <option value="spanish">Spanish</option>
          <option value="english" selected>English</option>
        </select>

        <FontAwesomeIcon
          icon="chevron-down"
          size='lg'
          color={LAVENDER}
          className="chevron-down  "

        />

      </div>

      <span className="textTerms">
        By clicking Send, you agree to our{" "}
        <a href="https://possibilitycompany.com/terms-of-service/">
          Terms of Service
        </a>{" "}
        and{" "}
        <a href="https://possibilitycompany.com/privacy-policy/">
          Privacy Policy.
        </a>
      </span>
    </div>
  );
}