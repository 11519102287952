import { useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import './styles.css'
import './styles.scoped.css';
import ModalImageEditor from '../ModalImageEditor';
import { useDispatch, useSelector } from "react-redux";
import { uploadAsset, uploadImage, updateProfilePhoto } from "../../store/actions/userActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER, WHITE } from "../ClubIconsNavBar";
import useGender from "../../hooks/useGender";
import { useTranslation } from "react-i18next";
import { getUser } from "../../store/actions/userActions";

function Index(props: {
    handleOnClick: (e: any, step: any, save: boolean) => void;
    user: any;
    setUser: any;
}) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    //States
    const [showModal, setShowModal] = useState<boolean>(false);
    const [userProfile, setUserProfile] = useState<string>('');
    const [change, setChange] = useState<boolean>(false)
    const [loadingPhoto, setLoadingPhoto] = useState<boolean>(false);

    const organization = useSelector((state: any) => state.getOrgs.organizationInfo);

    const { genders, setCountry: setCountryGenders } = useGender();

    useEffect(() => {
        if (organization && organization?.countryCode) {
            setCountryGenders(organization.countryCode);
        }
    }, [])

    useEffect(() => {
        if (organization && organization?.countryCode) {
            setCountryGenders(organization.countryCode);
        }
    }, [organization])


    //Effects
    useEffect(() => {
        setUserProfile(props.user.avatar)
    }, [props.user])

    //Handlers
    const handleChangeName = (event: any) => {
        setChange(true)
        props.setUser({ ...props.user, firstname: event.target.value })
    }
    const handleChangeLast = (event: any) => {
        setChange(true)
        props.setUser({ ...props.user, lastname: event.target.value })
    }
    const handleChangeGender = (event: any) => {
        setChange(true)
        props.setUser({ ...props.user, gender: event.target.value })
    }
    const handleChangeDate = (event: Date) => {
        setChange(true)
        props.setUser({
            ...props.user,
            birthdate: `${event.getMonth() + 1}-${event.getDate()}-${event.getFullYear()}`
        })
    }

    function handleCloseModal(e: any, type: any) {
        if (type === 'cancel') {
            setShowModal(false);
        }
    };

    function handleSaveImage(e: any) {
        setShowModal(false)
        setLoadingPhoto(true);
        fetch(e)
            .then(res => res.blob())
            .then(blob => {
                const contentType = e.split(';')[0].split(':')[1];
                const extension = contentType.split('/')[1]
                const file = new File([blob], 'profile' + props.user.username, { type: contentType });

                if (file) {
                    dispatch<any>(uploadAsset(
                        file.type,
                        file.name + '.' + extension,
                        "profile_photo"
                    ))
                        .then((results: any) => {
                            if (results.body) {
                                dispatch<any>(uploadImage(
                                    results.body,
                                    contentType,
                                    file,
                                ))
                                    .then((result: any) => {
                                        if (result.status === 200) {
                                            const urlImage = results.body.split('?')[0];
                                            setUserProfile(urlImage);
                                            dispatch<any>(updateProfilePhoto(
                                                urlImage,
                                                props.user.username,
                                            ))
                                                .then((result: any) => {
                                                    if (result.status === 200) {
                                                        setUserProfile(urlImage);
                                                    }
                                                    setLoadingPhoto(false);
                                                    setChange(true)
                                                    dispatch(getUser(props.user.username));
                                                })
                                                .catch((error: any) => {
                                                    console.log(error)
                                                    setLoadingPhoto(false);
                                                })
                                        }
                                    })
                                    .catch((error: any) => {
                                        console.log(error)
                                        setLoadingPhoto(false);
                                    })

                            }
                        })
                        .catch((error: any) => {
                            console.log(error)
                            setLoadingPhoto(false);
                        })
                }

            })
    };

    console.log(props.user.roles)

    return (
        <>
            <div className="titles-header">
                <div className="titles">
                    <div className="myProfile-Header">
                        <h1>Zaahah</h1>
                        <div className="myProfile-Buttons">
                            {!change ?
                                <button className="button-skip" onClick={(e) => props.handleOnClick(e, 2, false)}>{t('setupSchool__skip')}</button>
                                :
                                <div className="ContainerButtons">
                                    <button className="button-skip" onClick={(e) => props.handleOnClick(e, 2, false)}>{t('setupStaff__discardAll')}</button>
                                    <button className="button-start" onClick={(e) => props.handleOnClick(e, 2, true)}>{t('setupSchool__saveAndContinue')}</button>
                                </div>
                            }
                        </div>
                    </div>
                    <h2>{t('setupStaff__congratulations')}</h2>
                    <div className="descCongra">
                        <span style={{ fontWeight: "bold" }}>{`${props.user.firstname} ${props.user.lastname
                            }`}</span>
                        {t('setupStaff__thanksfor')}
                        {props.user?.roles?.includes("ZAA#GUARDIAN") ? ' Guardian ' : ' Alumni '}
                        {t('setupStaff__at')} {organization.name}. {t('setupStaff__pleasecomplete')}
                    </div>
                </div>
            </div>
            <div className="myprofile-inputs">
                <ModalImageEditor handleSaveImage={handleSaveImage} show={showModal} onHide={handleCloseModal} />
                <div onClick={() => setShowModal(true)}>
                    <label className="photo" htmlFor="filePicker">
                        <div className="containerPhoto">
                            <div className="logo-photo">
                                {userProfile ?
                                    <img src={userProfile} className="imageSchool" />
                                    :
                                    (
                                        (loadingPhoto) ? (
                                            <div className="icon-spinner-third buttonSendSignUp" />
                                        ) :
                                            (<FontAwesomeIcon
                                                icon="camera"
                                                size="xl"
                                                color={WHITE}
                                                className="icon-camera  "
                                            />)
                                    )}

                            </div>
                            <p>{t('setupStudent__profilePicture')}</p>
                            <a href="#">{t('setupSchool__uploadFile')}</a>
                        </div>
                    </label>
                </div>
                <div className="inputsContainer">
                    <div className="row">
                        <div className="col">
                            <label className="title-input" htmlFor="i-principal">
                            {t('myschool__firstName')}
                            </label>
                            <div className="containers-input" onChange={handleChangeName}>
                                <input type="text" defaultValue={props.user.firstname} />
                            </div>
                        </div>
                        <div className="col">
                            <label className="title-input" htmlFor="i-principal">
                            {t('myschool__lastName')}
                            </label>
                            <div className="containers-input" onChange={handleChangeLast}>
                                <input type="text" defaultValue={props.user.lastname} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label className="title-input" htmlFor="i-principal">
                            {t('setupStaff__userName')}
                            </label>
                            <div className="containers-input-disabled">
                                <input type="text" defaultValue={props.user.username} disabled />
                            </div>
                        </div>
                        <div className="col">
                            <label className="title-input" htmlFor="i-principal">
                            {t('profile__email')}
                            </label>
                            <div className="containers-input-disabled">
                                <input type="text" defaultValue={props.user.email} disabled />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label className="title-input" htmlFor="i-principal">
                            {t('setupStaff__gender')}
                            </label>
                            <div className="containers-input gendersGridContainer" onChange={handleChangeGender}>
                                {genders.map(({ id: genderKey, label: genderLabel }) => (
                                    <div className="option">
                                        <label className="content-input">
                                            <input
                                                type="radio"
                                                name="principal-button"
                                                defaultValue={genderKey}
                                                checked={props.user.gender === genderKey}
                                            />
                                            <i></i>
                                        </label>
                                        <label htmlFor="students">{genderLabel}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col">
                            <label className="title-input" htmlFor="i-principal">
                            {t('setupStaff__birthday')}
                            </label>
                            <div className="inputBirthdayGrid">
                                <FontAwesomeIcon
                                    icon="calendar"
                                    size='lg'
                                    color={LAVENDER}
                                    className="calendarIconMyProfile  "
                                />
                                <DatePicker
                                    dateFormat="MM-dd-yyyy"
                                    selected={props.user.birthdate ? new Date(props.user.birthdate) : new Date()}
                                    onChange={handleChangeDate}
                                    className="inputDirthday"
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default Index;