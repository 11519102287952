import { useState } from "react";
import OutsideClickHandler from 'react-outside-click-handler';
import 'rc-time-picker/assets/index.css';
import '../styles.scoped.css';
import moment, { Moment } from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { TimePicker } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { WHITE, LAVENDER } from '../../ClubIconsNavBar';

//Time Helper
//club.meetingStart/End = "HH:MM"
const getMomentTime = (mom: Moment): string => {
    if (mom === null) return ""
    let hours: number | string = mom.hours()
    let minutes: number | string = mom.minutes()
    hours < 10 ? hours = `0${hours}` : hours = `${hours}`
    minutes < 10 ? minutes = `0${minutes}` : minutes = `${minutes}`
    return `${hours}:${minutes}`
}

const getTimeMoment = (time: string): Moment => {
    const date = new Date()
    return moment(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${time}`)
}

function Index(props: {
    club: any;
    setClub: any;
}) {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [startPicked, setStartPicked] = useState<boolean>(props.club.meetingStartTime ? props.club.meetingStartTime !== "" : false)
    const [endPicked, setEndPicked] = useState<boolean>(props.club.meetingEndTime ? props.club.meetingEndTime !== "" : false)
    const [start, setStart] = useState<Moment>(props.club.meetingStartTime !== '' ? getTimeMoment(props.club.meetingStartTime) : moment())
    const [end, setEnd] = useState<Moment>(props.club.meetingEndTime !== '' ? getTimeMoment(props.club.meetingEndTime) : moment())

    const timezones = useSelector((state: RootState) => state.getLocation.timezones || [{
        continent: "America",
        city: 'Bogota',
        country: 'CO',
        DST: '-05:00',
        STD: '-05:00'
    }]);

    const getCurrentTimezone = (): string => {
        const zone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const continent = zone.split("/")[0]
        const city = zone.split("/")[1]
        const tz = timezones.find((item: any) => item.continent === continent && item.city === city)
        const timezone_string = `${tz.continent}#${tz.city}#${tz.STD}`
        props.setClub({
            ...props.club,
            timezone: timezone_string !== '' ? timezone_string : 'America#Bogota#-05:00'
        })
        if (tz) return timezone_string
        else return 'America#Bogota#-05:00'
    }

    const [timezone, setTimezone] = useState(props.club.timezone ? (props.club.timezone !== '' ? props.club.timezone : getCurrentTimezone()) : getCurrentTimezone())

    const iconStyle = {
        position: 'absolute',
        width: '24px',
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const starPath =
        'M908.1 353.1l-253.9-36.9L540.7 86.1c-3' +
        '.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L3' +
        '69.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3-12.3 12' +
        '.7-12.1 32.9 0.6 45.3l183.7 179.1-43.4 252.9c-1.2 6.9' +
        '-0.1 14.1 3.2 20.3 8.2 15.6 27.6 21.7 43.2 13.4L512 7' +
        '54l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-' +
        '19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3' +
        ' 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1' +
        ' 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6' +
        ' 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z';

    const getIcon = (path: any, style = {}) => {
        return (

            <svg
                viewBox="0 0 1024 1024"
                fill="currentColor"
                style={{
                    position: 'absolute',
                    top: '10px',
                    left: '10px',
                    color: 'white',
                }}
            >
                <path xmlns="http://www.w3.org/2000/svg" d="M84.17,76.55l-16.9-9.557V32.102c0-2.541-2.061-4.601-4.602-4.601s-4.601,2.061-4.601,4.601v37.575   c0,0.059,0.016,0.115,0.017,0.174c0.006,0.162,0.025,0.319,0.048,0.479c0.021,0.146,0.042,0.291,0.076,0.433   c0.035,0.141,0.082,0.277,0.129,0.414c0.051,0.146,0.1,0.287,0.164,0.426c0.061,0.133,0.134,0.257,0.208,0.383   c0.075,0.127,0.148,0.254,0.234,0.374c0.088,0.122,0.188,0.235,0.288,0.349c0.097,0.11,0.192,0.217,0.299,0.317   c0.107,0.101,0.222,0.19,0.339,0.28c0.126,0.098,0.253,0.191,0.39,0.276c0.052,0.031,0.092,0.073,0.145,0.102L79.64,84.562   c0.716,0.404,1.493,0.597,2.261,0.597c1.605,0,3.163-0.841,4.009-2.337C87.161,80.608,86.381,77.801,84.17,76.55z" />
                <path xmlns="http://www.w3.org/2000/svg" d="M62.834,0C28.187,0,0,28.187,0,62.834c0,34.646,28.187,62.834,62.834,62.834c34.646,0,62.834-28.188,62.834-62.834   C125.668,28.187,97.48,0,62.834,0z M66.834,115.501v-9.167h-8v9.167c-24.77-1.865-44.823-20.872-48.292-45.167h9.459v-8h-9.988   c0.258-27.558,21.716-50.126,48.821-52.167v9.167h8v-9.167c27.104,2.041,48.563,24.609,48.821,52.167h-9.487v8h8.958   C111.657,94.629,91.605,113.636,66.834,115.501z" />

            </svg>
        );
    };

    const inputIcon = getIcon(starPath, iconStyle);

    function onChange(value: any) {
        console.log(value);
    }

    //Handlers
    const handleOnChangeDays = (event: any) => {
        let updateList = [...props.club.meetingDays || []];
        if (event.target.checked) {
            updateList = [...props.club.meetingDays || [], event.target.value];
        } else {
            updateList.splice((props.club.meetingDays || []).indexOf(event.target.value), 1);
        }
        props.setClub({ ...props.club, meetingDays: updateList });
    }

    const handleOnStartChange = (event: Moment) => {
        setStartPicked(event !== null)
        setStart(event)
        props.setClub({ ...props.club, meetingStartTime: getMomentTime(event) })
    }

    const handleOnEndChange = (event: Moment) => {
        setEndPicked(event !== null)
        setEnd(event)
        props.setClub({ ...props.club, meetingEndTime: getMomentTime(event) })
    }

    const handleOnTimezoneChange = (e: any) => {
        setTimezone(e.target.value)
        props.setClub({ ...props.club, timezone: e.target.value })
    }

    return (

        <div className="infoMembers">

            <div className="membersType">
                <h2>{t('setupSchool__days')}</h2>
                <span className="subtitle">{t('setupSchool__youCanMark')}</span>
                <div className="containers-input">
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input
                                type="checkbox"
                                name="principal-button"
                                value="Sunday"
                                checked={props.club.meetingDays?.includes('Sunday') || false}
                                onChange={handleOnChangeDays}
                            />
                            <i></i>
                        </label>
                        <label htmlFor="students">SUN</label>
                    </div>
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input
                                type="checkbox"
                                name="principal-button"
                                value="Monday"
                                checked={props.club.meetingDays?.includes('Monday') || false}
                                onChange={handleOnChangeDays}
                            />
                            <i></i>
                        </label>
                        <label htmlFor="students">MON</label>
                    </div>
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input
                                type="checkbox"
                                name="principal-button"
                                value="Tuesday"
                                checked={props.club.meetingDays?.includes('Tuesday') || false}
                                onChange={handleOnChangeDays}
                            />
                            <i></i>
                        </label>
                        <label htmlFor="students">TUE</label>
                    </div>
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input
                                type="checkbox"
                                name="principal-button"
                                value="Wednesday"
                                checked={props.club.meetingDays?.includes('Wednesday') || false}
                                onChange={handleOnChangeDays}
                            />
                            <i></i>
                        </label>
                        <label htmlFor="students">WED</label>
                    </div>
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input
                                type="checkbox"
                                name="principal-button"
                                value="Thursday"
                                checked={props.club.meetingDays?.includes('Thursday') || false}
                                onChange={handleOnChangeDays}
                            />
                            <i></i>
                        </label>
                        <label htmlFor="students">THU</label>
                    </div>
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input
                                type="checkbox"
                                name="principal-button"
                                value="Friday"
                                checked={props.club.meetingDays?.includes('Friday') || false}
                                onChange={handleOnChangeDays}
                            />
                            <i></i>
                        </label>
                        <label htmlFor="students">FRI</label>
                    </div>
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input
                                type="checkbox"
                                name="principal-button"
                                value="Saturday"
                                checked={props.club.meetingDays?.includes('Saturday') || false}
                                onChange={handleOnChangeDays}
                            />
                            <i></i>
                        </label>
                        <label htmlFor="students">SAT</label>
                    </div>
                </div>
            </div>
            <div className="maximunMembers">
                <h2>{t('setupSchool__time')}</h2>

                <div className="content-time">

                    <div onClick={() => setOpen(true)} >
                        <FontAwesomeIcon
                            icon="clock"
                            size='lg'
                            color={WHITE}
                            className=" "
                        />
                        <TimePicker
                            onChange={() => handleOnStartChange}
                            popupClassName={'time-in'}
                            defaultOpenValue={moment('2:00 PM', 'h:mm a')}
                            format={"h:mm a"}
                            minuteStep={15}
                            placeholder={""}
                            use12Hours={true}
                            value={startPicked ? start : undefined}
                        />
                    </div>
                    <p>
                        {t('setupSchool__to')}
                    </p>
                    <div onClick={() => setOpen(true)} >
                        <FontAwesomeIcon
                            icon="clock"
                            size='lg'
                            color={WHITE}
                            className=" "
                        />
                        <TimePicker
                            onChange={() => handleOnEndChange}
                            popupClassName={'time-in'}
                            defaultOpenValue={moment('2:00 PM', 'h:mm a')}
                            format={"h:mm a"}
                            minuteStep={15}
                            placeholder={""}
                            use12Hours={true}
                            value={endPicked ? end : undefined}
                        />
                    </div>
                </div>
            </div>
            <div className="selectAffiliated">
                <label htmlFor="">{t('setupSchool__timeZone')}</label>
                <div className="affiliated">
                    <select onChange={handleOnTimezoneChange} name="" id="" value={timezone}>
                        <>
                            {timezones.map((timez: any) => (
                                <>
                                    <option
                                        value={`${timez.continent}#${timez.city}#${timez.STD}`}
                                    >{`(GTM${timez.STD}) ${timez.city} - ${timez.country}`}</option>
                                    {(timez.DST !== timez.STD) && (<option
                                        value={`${timez.continent}#${timez.city}#${timez.DST}`}
                                    >{`(GTM${timez.DST}) Daylight Time ${timez.city} - ${timez.country}`}</option>)}
                                </>
                            ))}
                        </>
                    </select>
                </div>
            </div>
            <div className="genders">
                <h2>{t('setupSchool__frequency')}</h2>
                <div className="containers-input">
                    <div className="option">
                        <label className="content-input">
                            <input type="radio" name="principal-button" value=""
                                onClick={(e: any) => props.setClub({ ...props.club, meetingFrequency: 'Weekly' })}
                                defaultChecked={props.club.meetingFrequency ? props.club.meetingFrequency === 'Weekly' : false} />
                            <i></i>
                        </label>
                        <label htmlFor="students">{t('setupSchool__weekly')}</label>
                    </div>
                    <div className="option">
                        <label className="content-input">
                            <input type="radio" name="principal-button" value=""
                                onClick={(e: any) => props.setClub({ ...props.club, meetingFrequency: 'Bi-weekly' })}
                                defaultChecked={props.club.meetingFrequency ? props.club.meetingFrequency === 'Bi-weekly' : false} />
                            <i></i>
                        </label>
                        <label htmlFor="students">{t('setupSchool__biweekly')}</label>
                    </div>
                    <div className="option">
                        <label className="content-input">
                            <input type="radio" name="principal-button" value=""
                                onClick={(e: any) => props.setClub({ ...props.club, meetingFrequency: 'Monthly' })}
                                defaultChecked={props.club.meetingFrequency ? props.club.meetingFrequency === 'Monthly' : false} />
                            <i></i>
                        </label>
                        <label htmlFor="students">{t('setupSchool__monthly')}</label>
                    </div>
                </div>
            </div>
            <div className="meeting-location">
                <h2>{t('setupSchool__meetingLocation')}</h2>
                <div className="containers-input">
                    <input type="text" value={props.club.meetingLocation} onChange={(e: any) => props.setClub({ ...props.club, meetingLocation: e.target.value })} />
                </div>
            </div>
        </div>
    );
}

export default Index;