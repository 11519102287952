import { useEffect, useState } from "react";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import TCList from "../../interfaces/TClubs/TCList/TCList";

const useTCListsSA = () => {
    const [tclists, setTclists] = useState<TCList[]>([]);

    const getAllTCLists = () => {
        Backend(
            MicroServices.Club,
            `/tclubs/tclists`
        )
            .then((response) => response.json())
            .then((results: TCList[]) => {
                if (!Array.isArray(results)) {
                    return;
                }
                setTclists(results);
            })
    };

    useEffect(() => getAllTCLists(), []);

    return {
        tclists,
        reload: getAllTCLists,
    }
}

export default useTCListsSA;