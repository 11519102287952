import { Component } from "react";
import styled from "styled-components";

export const LeftPanel = styled.div`
    width: 25%;
    z-index: 2;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-image: url(https://anima-uploads.s3.amazonaws.com/projects/621fc5babe91377f45cca168/releases/62275bda4aa2a115d7083fa6/img/organizations@1x.svg);
    background-size: cover;
    position: fixed;
    padding-top: 25px;
    padding-bottom: 8%;
    height: 100%;
    overflow-y: auto;
`;

export const CentralBase = styled.div`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 1024px;
    position: relative;
    width: 100%;
    margin-top: 35px;
`;

export const RightPanel = styled.div`
    width: 25%;
    z-index: 2;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(https://anima-uploads.s3.amazonaws.com/projects/621fc5babe91377f45cca168/releases/62275bda4aa2a115d7083fa6/img/organizations@1x.svg);
    background-size: cover;
    position: fixed;
    padding-top: 25px;
    padding-bottom: 110px;
    height: 100%;
    overflow-y: auto;
    right: 0;
`;

export const PrincipalContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;

    a {
        display: contents;
        text-decoration: none;
    }
`;