import React, { useEffect, useState } from "react";
import Header from "../Header";
import styled from "styled-components";
import {
  RobotocondensedRegularNormalWhite16,
  Fontawesome5proRegularNormalLavende,
  RobotocondensedBoldLavender16px,
} from "../../styledMixins";
import "./styles.css";
import SuperAdminLeftMenu from "../SuperAdminLeftMenu";
import AdminLeftMenu from "../AdminLeftMenu";
import "../../styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../store/store";
import { getUser } from "../../store/actions/userActions";
import { UserResponse, UserResponseMock } from "../../interfaces/user/UserResponse";
import User, { UserMock } from "../../interfaces/user/User";
import { formatGender, responseToUser } from "../../helpers/userHelper";
import { unixToDateFormatted } from "../../helpers/dateHelper";
import { useSelector } from "react-redux";
import { rolesArray } from "../../helpers/roleHelper";

function UserView() {

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [user, setUser] = useState<UserResponse>(UserResponseMock)
  const userRoles = useSelector((state: any) => state.getUser.usersRoles);

  useEffect(() => {
    dispatch<any>(getUser(id || ''))
      .then((userResponse: UserResponse) => {
        setUser(userResponse);
      })
  }, []);


  return (
    <div className="container-center-horizontal">
      <div className="organizations screen">
        <SuperAdminLeftMenu screen="Users"/>
        <Header />
        <div className="content">
          <div className="top-cont">
            <div className="organization robotocondensed-normal-white-16px">Manage Users</div>
            <div className="text-1 robotocondensed-normal-white-16px">/</div>
            <div className="view-organization robotocondensed-bold-lavender-16px">
              View User
            </div>
          </div>
          <div className="org-view-cont">
            <div className="title-column">
              <h1 className="title-name">User</h1>
              <div className="buttons-box">
                <div className="button-p">
                  <div className="update roboto-normal-white-16px" onClick={() => navigate(`/superadmin/users/${id}/update`)}>Update</div>
                </div>
              </div>
            </div>
            <div className="view-columns">
              <div className="col-1">
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Gender</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px">{formatGender(user.gender)}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">User Roles</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px">{rolesArray(user.roles).join(' ')}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Postal Code</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px">{user.zipcode}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">State</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px" id="not-set">{user.state || 'Not Set'}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Country</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px">{user.country}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Status</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px" id="not-set">{user.status || 'Not Set'}</div>
                  </div>
                </div>
              </div>

              <div className="col-2">
                <h1 className="title-col-2">Registration</h1>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">ID</div>
                  <div className="info-field" id="transparent-back">
                    <div className="roboto-normal-white-16px">{user.username}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Created</div>
                  <div className="info-field" id="transparent-back">
                    <div className="roboto-normal-white-16px">{unixToDateFormatted(user.creationDate)}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Updated</div>
                  <div className="info-field" id="transparent-back">
                    <div className="roboto-normal-white-16px">{unixToDateFormatted(user.creationDate)}</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ContentOrganizations = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  width: 100%;
  z-index: 1;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  padding: 30px 1000px;
  align-items: center;
  min-height: 944px;
  background-color: var(--violet);
`;

const ContentUsers = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  width: 1298px;
  z-index: 1;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  align-items: center;
  min-height: 944px;
  background-color: var(--violet);
`;

const FlexRow = styled.div`
  height: 124px;
  display: flex;
  align-items: flex-start;
  min-width: 1250px;
`;

const FlexCol = styled.div`
  width: 182px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 93px;
`;

const Title = styled.h1`
  min-height: 28px;
  font-family: var(--font-family-roboto_condensed);
  font-weight: 700;
  color: var(--lavender);
  font-size: var(--font-size-xxxl);
  letter-spacing: 0;
  padding-bottom: 30px;
`;

const Showing112Of864Items = styled.p`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  align-self: flex-end;
  margin-top: 46px;
  min-width: 198px;
  letter-spacing: 0;
  padding-left: 14px;
`;

const FlexCol1 = styled.div`
  width: 200px;
  position: relative;
  margin-left: 868px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 104px;
  justify-content: flex-end;
`;

const Viewing = styled.div`
  margin-left: 3px;
  display: flex;
  align-items: center;
  min-width: 197px;
  align-self: flex-end;
`;

const Viewing1 = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  min-width: 50px;
  letter-spacing: 0;
`;

const Box = styled.select`
${RobotocondensedBoldLavender16px}
  height: 40px;
  margin-left: 17px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  min-width: 130px;
  background-color: var(--violent-violet);
  border: none;
`;

const Address = styled.div`
  ${RobotocondensedBoldLavender16px}
  min-height: 19px;
  min-width: 65px;
  letter-spacing: 0;
`;

/* const PgNumber = styled.div`
  margin-top: 438px;
  display: flex;  
  padding: 0 30px;
  align-items: center;
  min-width: 360px;
`; */

export default UserView;
