import React, { useEffect, useState, useRef } from "react";
import "../styles.scoped.css";
import '../styles.css';
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { WHITE } from "../../ClubIconsNavBar";
import ModalInviteMembers from "./ModalInviteMembers";
import ModalAddAdvisor from "./ModalAddAdvisor";
import { Backend, MicroServices } from "../../../helpers/backendHelper";
import ModalDeleteClub from "./ModalDeleteClub";
import OutsideClickHandler from "react-outside-click-handler";

function CardCommunity(props: any) {

  const { community, organization, staffMembers, createCommunities, reloadStaff, getCommunities } = props;

  let {
    title,
    invitesSend,
    id,
    active,
    icon,
    desc,
    advisors,
    club_id,
    type,
    templateName,
  } = community;

  const [communityName, setCommunityName] = useState(title);
  const [editNameActivated, setEditNameActivated] = useState(false);

  const [showInviteMembers, setShowInviteMembers] = useState(false);
  const [showInviteAdv, setShowInviteAdv] = useState(false);
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [selectedItemState, setSelectedItemState] = useState(false);

  const buttonText = () => {
    return (communityName.includes('Class')) ?
      `Invite ${(invitesSend > 1) ? ('More') : ('')} Students` : `Invite ${(invitesSend > 1) ? ('More') : ('')} ${title}`;
  }

  const handleCloseInvite = () => {
    setShowInviteMembers(false);
  }

  const handleCloseAdv = () => {
    setShowInviteAdv(false);
  }

  const onInputKeyDown = (event: any, id: any) => {
    if (event.key === 'Enter') {
      setEditNameActivated(!editNameActivated)
      saveInfo(id)
    }
  }

  const outsideName = (id: any) => {
    if (editNameActivated) {
      saveInfo(id)
    }
  }

  const saveInfo = (id: any) => {
    Backend(
      MicroServices.Club,
      `/clubs/${id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: communityName,
        })
      }
    )
      .then((response) => {
        getCommunities()
      })
      .catch((error) => {
        console.log(error);
      })
  }

  return (
    <>
      <div
        id={id}
        key={id}
        className={`card card-${active ? "active" : "desactive"}`}
      >
        <div className={`card-header`}>
          <FontAwesomeIcon
            icon="times"
            size='xl'
            color={WHITE}
            className="icon-times-white-del"
            onClick={() => setShowDelete(true)}
          />
          <FontAwesomeIcon
            icon={faUser}
            size='xl'
            color={WHITE}
            className="icon-user  "
          />
          <FontAwesomeIcon
            className={`icon-header icon-${icon}`}
            icon={icon}
            size='xl'
            color={WHITE}
          />
          <OutsideClickHandler
            onOutsideClick={() => {
              setEditNameActivated(false)
              outsideName(community.club_id);
            }}
          >
            <div>
              {!editNameActivated && (<p className="card-name-editable">{communityName}</p>)}
              {editNameActivated && (<input onKeyDown={(e) => onInputKeyDown(e, community.club_id)} onChange={(e) => setCommunityName(e.target.value)} autoFocus placeholder={communityName} className="card-name-editable" />)}
              {active && (<FontAwesomeIcon
                icon="pencil"
                size='xl'
                color={WHITE}
                className="card-name-editable"
                id='pencil-icon'
                onClick={() => setEditNameActivated(!editNameActivated)}
              />)}
              {/* <span>{desc}</span> */}
            </div>
          </OutsideClickHandler>

        </div>

        {advisors.length > 0 ? (
          <div className="staff-inputs">

            <div className="row">
              <label htmlFor="">
                {advisors.length === 1
                  ? "Advisor"
                  : `${advisors.length} Advisors`}
              </label>
            </div>

            <div className="adv-club-list">
              {advisors.map((adv: any) => (
                <div className="row adv-list-color" key={JSON.stringify(adv)}>
                  <input disabled={true} type="text" defaultValue={`${adv.firstName || adv.firstname || adv.name || ''} ${adv.lastName || adv.lastname || ''}`} />
                  {advisors.length > 1 ? (
                    <FontAwesomeIcon
                      icon="times"
                      size='xl'
                      color={WHITE}
                      className="icon-times-white  "
                    />
                  ) : (<></>)}
                </div>
              ))}
            </div>


            <div className="card-adv-label">
              Please invite an invited member of Staff to be an Advisor.
            </div>

          </div>
        ) : (
          <div className="card-desc">
            Please invite an invited member of Staff to be an Advisor.
          </div>
        )}

        <div className="add-advisor">
          <button
            name="btn-addAdvisor"
            className="btnAddAdvisor"
            onClick={() => setShowInviteAdv(true)}
          >
            <FontAwesomeIcon
              icon="user-plus"
              size='xl'
              color={WHITE}
              className="icon-user  "

            />
            <p>Add Advisor</p>
          </button>
        </div>

        <button
          name="btn-FillMembers"
          className={`buttonSendInvites ${advisors.length > 0 ? "active" : "inactive"
            } `}
          onClick={() => setShowInviteMembers(true)}
          disabled={advisors.length === 0}
        >
          {buttonText()}
        </button>
        <div className="invites-count">
          {(invitesSend > 1)
            ? `${(title === 'Staff')
              ? (invitesSend - advisors.length + 1)
              : (advisors.find((user: any) => user.email === organization.orgAdmin.email))
                ? invitesSend - advisors.length + 1
                : (invitesSend - advisors.length)} invites sent` : ''}
        </div>
      </div>

      <ModalDeleteClub
        show={showDelete}
        club={community}
        setShow={setShowDelete}
        getCommunities={getCommunities}
      />

      <ModalInviteMembers
        type={templateName}
        title={title}
        show={showInviteMembers}
        onHide={handleCloseInvite}
        club_id={club_id}
        organization={organization}
        createCommunities={createCommunities}
        setShowInviteMembers={setShowInviteMembers}
        reloadStaff={reloadStaff}
        getCommunities={getCommunities}
      />

      <ModalAddAdvisor
        show={showInviteAdv}
        onHide={handleCloseAdv}
        club={community}
        org={organization}
        staffMembers={staffMembers}
        getCommunities={getCommunities}
        setShowInviteAdv={setShowInviteAdv}
      />
    </>
  );
}

export default CardCommunity;
