import "./styles.css";
import "./styles.scoped.css";
import { useEffect, useState } from "react";
import ModalInviteMembers from "./ModalInviteMembers";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getClubMembers, getClubsImAdv } from "../../store/actions/clubActions";
import ModalConnections from "../ConnectionSelectBar/ModalConnections";
import { getUserFriends, getUserPossibleConnections } from "../../store/actions/connectionActions";
import ModalMembersRegistered from "./ModalMembersRegistered";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { onlyFirstLetterCapitalized } from "../../helpers/letterHelper";
import ModalConnection from "../ModalConnection";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";

interface ClubMembersProps{
  //todo -> put types
  members:any;
  advisors:any;
  isClubAdvisor:any;
  infoClub:any;
  officers:any;
  reloadClub:any;
  reloadMembers:any;
  setInvitesAdded:any;
  ChatServiceInstance:ChatServiceInterface;
}

function Index(props: ClubMembersProps) {
  const { t, i18n } = useTranslation();
  const [showInviteMembers, setShowInviteMembers] = useState(false);
  const [members, setMembers] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [typeFiter, setTypeFilter] = useState('1');
  const [setmember, setSetMember] = useState<any>('pass');
  const [isUserAdvisor, setIsUserAdvisor] = useState(false);

  const { id } = useParams();
  const userInfo = useSelector((state: any) => state.getUser.userInfo);

  const userFriends = useSelector((state: any) => state.getConnection.userFriends || []);
  const userConnections = useSelector((state: any) => state.getConnection.userPossibleConnections || []);
  const userRoles = useSelector((state: any) => state.getUser.userRoles);
  const [valueFilter, setValueFilter] = useState("");
  const [infoConnection, setInfoConnection] = useState([]);
  const [showModalConnection, setShowModalConnection] = useState<boolean>(false);

  const dispatch = useDispatch();

  function handleCloseInvite(e: any, step: any, id: any, type: any) {
    setShowInviteMembers(false);
  }
  const handleOnClickInvite = (e: any) => {
    setShowInviteMembers(true);
  };

  useEffect(() => {
    dispatch<any>(getUserFriends(userInfo.username));
    dispatch<any>(getUserPossibleConnections(userInfo.username));
    isClubAdvisor();
  }, [id])

  useEffect(() => {
    dispatch<any>(getUserFriends(userInfo.username));
    dispatch<any>(getUserPossibleConnections(userInfo.username));
    isClubAdvisor();
  }, [])

  useEffect(() => {
    if (Array.isArray(props.members) && Array.isArray(props.advisors)) {
      setMembers([...props.members, ...props.advisors])
    }
    else if (Array.isArray(props.members)) {
      setMembers([...props.members])
    }
    else if (Array.isArray(props.advisors)) {
      setMembers([...props.advisors])
    }
    else {
      setMembers([])
    }
  }, [props.members, props.advisors]);

  const showButtonSentInvite = () => {
    return (props.isClubAdvisor) ?
      true : false;
  }

  const handleDots = (member: any, username: string) => {
    setTypeFilter('1');
    setSetMember(member);

    //The connection is a friend?
    if (userFriends.find((user: any) => user.friendUsername === username)) {
      setTypeFilter('2');
    }
    //Is a possible connection?
    else if (userConnections.find((user: any) => user.usernameConnection === username)) {
      setTypeFilter('1');
    }
    //The users cannot be friends
    else {
      setTypeFilter('pass');
    }

    setOpenModal(!openModal);
  }

  const isClubAdvisor = () => {
    if (userRoles.includes("ZAA#ORGADMIN")) {
      setIsUserAdvisor(true);
      return;
    }
    dispatch<any>(getClubsImAdv(userInfo.username))
      .then((clubs: any) => {
        for (let club of clubs) {
          if (club.id === id) {
            setIsUserAdvisor(true);
            break;
          }
        }
        return;
      });
  }

  const handleChangeName = (e: any) => {
    setValueFilter(e);
  }

  const showConnection = (e: any) => {
    if (userRoles.includes(`ZAA#${e.userType}`)) {
      setInfoConnection(e)
      setShowModalConnection(true)
    }else if(!userRoles.includes(`ZAA#STUDENT`) && e.userType !== 'STUDENT'){
      setInfoConnection(e)
      setShowModalConnection(true)
    }
  }

  function handleCloseModal() {
    setShowModalConnection(false)
  }

  return (
    <div className="container-members-club">
      {/* <div className="filterInputSearchEventClub">
        <input
          className="inputSearchEventClub"
          type="text"
          placeholder="Search Members"
        />
      </div> */}
      <div className="membersClubHeader">
        <div className="count-members">
          <div className="containerInputSearch">
            <p>{members.length} {t('infoMembers__members')}</p>
            <div className="inputSearchM">
              <FontAwesomeIcon
                icon="search"
                size='lg'
                color={LAVENDER}
                className="search  "
              />
              <input type="text" className="inputSearchM" onChange={(e) => handleChangeName(e.target.value)} />
            </div>
            {(showButtonSentInvite()) && (
              <button onClick={handleOnClickInvite} className="buttonInviteMembersClub">
                {(props?.infoClub?.role_type === 'NO_ROLE_ASSIGNED') ?
                  ('Add Members')
                  :
                  t('options__inviteMembers')
                }
              </button>
            )}
          </div>
          {/* <button className="filterButton">
            <FontAwesomeIcon
              icon="filter"
              size='lg'
              color={LAVENDER}
              className="filter  "
            />
          </button> */}

        </div>
      </div>
      <div className="listMembersClub">
        <ModalConnection
          show={showModalConnection}
          onHide={handleCloseModal}
          info={infoConnection}
          noRedirect={true}
        />
        {members.filter((item: any) => {
          if (!valueFilter) return true;
          if (item.fullname.toUpperCase().includes(valueFilter) || 
              item.fullname.toLowerCase().includes(valueFilter) ||
              item.fullname.includes(valueFilter)
          ) {
            return true;
          }
          return false;
        }).map((member: any) => (
          <div className="cardMember" key={member.username}>
            <div className="imageMember">
              <img
                className="round-avatar"
                onClick={(e) => { showConnection(member) }}
                src={member.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"}
                alt="" />
            </div>

            <div className="ClubMember-container">
              <p className="ClubMember-Name" onClick={(e) => showConnection(member)}>{member.fullname} </p>
              <p className="ClubMember-Position">
                {(member.userType && member.userType === 'STAFF') ?
                  (onlyFirstLetterCapitalized(member.userType))
                  :
                  ((member.unregistered) ?
                    t('label_unregisteredStaff')
                    :
                    ''
                  )}
              </p>
            </div>
            <button>
              <FontAwesomeIcon
                icon="ellipsis-v-alt"
                size='lg'
                color={LAVENDER}
                className="ellipsis-v-alt   cursor"
                onClick={() => handleDots(member, member.username)}
              />
              {(openModal && (member.username === setmember.username)) &&
                (<ModalConnections
                  connection={setmember}
                  filterType={typeFiter}
                  setOpenModal={setOpenModal}
                  isAdvisor={isUserAdvisor}
                  username={member.username}
                  officers={props.officers}
                  advisors={props.advisors}
                  reloadClub={props.reloadClub}
                  ChatServiceInstance={props.ChatServiceInstance}
                />)}
            </button>
          </div>
        ))}
      </div>
      {(props?.infoClub?.role_type === 'NO_ROLE_ASSIGNED') ? (
        <ModalMembersRegistered show={showInviteMembers} onHide={handleCloseInvite} club_id={id} reloadMembers={props.reloadMembers} />
      )
        :
        (<ModalInviteMembers show={showInviteMembers} onHide={handleCloseInvite} club_id={id} setInvitesAdded={props.setInvitesAdded} />
        )
      }
    </div>
  );
}

export default Index;
