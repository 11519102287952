import React from "react";
import styled from "styled-components";

const TopBarWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    position: fixed;
    background: linear-gradient(180deg, #34266C 0%, #332367 77.6%, rgba(50, 35, 102, 0) 100%);
    z-index: 9;
    display: flex;
    justify-content: center;
`;

const StepsWrapper = styled.div`
    padding: 30px 0;
    color: #A885D7;
    width: calc(100% - 350px);
    font-family: Roboto;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 14px;
`;

const TopBar:React.FC = ({children}) => (
    <TopBarWrapper>
        <StepsWrapper>
            {children}
        </StepsWrapper>
    </TopBarWrapper>
);

export default TopBar;