import React, { KeyboardEventHandler, useEffect, useState, useMemo, useRef } from "react";
import CreatableSelect from "react-select/creatable";
import { ActionMeta, OnChangeValue } from "react-select";
import JoditEditor from "jodit-react";
import '../styles.scoped.css';
import { useTranslation } from "react-i18next";
interface Option {
    readonly label: string;
    readonly value: string;
}

function Index(props: {
    club: any;
    setClub: any;
}) {
    const { t, i18n } = useTranslation();
    const createOption = (label: string) => ({
        label,
        value: label,
    });
    
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState<OnChangeValue<Option, true>>(props.club.skills_learned?.map((item: any) => createOption(item)) || []);

    const components = {
        DropdownIndicator: null,
    };

    const handleChange = (
        value: OnChangeValue<Option, true>,
        actionMeta: ActionMeta<Option>
    ) => {
        setValue(value);
        props.setClub({ ...props.club, skills_learned: value.map((item) => item.value) })
    };

    const handleInputChange = (inputValue: any) => {
        setInputValue(inputValue);
    };

    const handleOnBlurDescription = (e: any) => {
        props.setClub({...props.club, description: e})
      };

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setInputValue('');
                if (!value.map((item) => item.value).includes(inputValue)) setValue([...value, createOption(inputValue)]);
                props.setClub({ ...props.club, skills_learned: [...props.club.skills_learned || [], inputValue] })
                event.preventDefault();
        }
    };

    const editor = useRef(null)
    const [content, setContent] = useState('')

    return (
        <div className="infoMembers">
            <div className="membersType">
                <h2>{t('setupSchool__description')}</h2>
                <JoditEditor
                    value={props.club.description}
                    onBlur={handleOnBlurDescription}
                    config={{
                        buttons: ['bold', 'italic', 'underline'],
                        readonly: false,
                        toolbarAdaptive: false,
                        showPlaceholder: false,
                    }}
                />
            </div>
            <div className="membersType">
                <h2>{t('setupSchool__skillsLearningTags')}</h2>
                <CreatableSelect
                    components={components}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder=""
                    value={value}
                    styles={{
                        singleValue: (base) => ({
                            ...base,
                            display: "flex",
                            alignItems: "center",
                            color: 'white',
                            backgroundColor: 'red',
                            fontFamily: 'Roboto Condensed',
                        }),
                        control: (styles) => ({
                            ...styles,
                            backgroundColor: '#2c2c2c',
                            borderRadius: "3px",
                            boxShadow: "none",
                            border: 0,
                            minWidth: "50%"
                        }),
                        option: (styles) => ({
                            ...styles,
                            color: 'white',
                            backgroundColor: 'var(--violent-violet)',
                            display: 'flex',
                            flexDirection: 'row',
                        }),
                        placeholder: (styles) => ({
                            ...styles,
                            color: 'white',
                            textAlign: "left",
                            fontFamily: 'Roboto Condensed',
                        }),
                        container: (styles) => ({
                            ...styles,
                            padding: 0,
                            width: "100%"
                        }),
                        menu: (styles) => ({
                            ...styles,
                            borderRadius: "8px",
                            backgroundColor: "var(--violent-violet)",
                        }),
                        input: (styles: any, state: any) => ({
                            ...styles,
                            border: 'none',
                            color: 'white',
                            fontFamily: 'Roboto Condensed',
                        }),
                        dropdownIndicator: (styles) => ({ ...styles, display: "none" }),
                        indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
                        multiValue: (styles) => ({
                            ...styles,
                            color: 'white',
                            backgroundColor: 'var(--electric-violet-2)',
                        }),
                        multiValueLabel: (styles) => ({
                            ...styles,
                            color: 'white',
                            fontFamily: 'Roboto Condensed',
                            fontSize: '16px'
                        }),
                        multiValueRemove: (styles) => ({
                            ...styles,
                            color: 'white',
                            backgroundColor: "transparent"
                        }),
                    }}
                />
            </div>
            <div className="genders">
                <div className="containers-input">
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input type="checkbox" name="principal-button" value="" checked={props.club.applicationRequired} onClick={(e:any)=>props.setClub({...props.club,applicationRequired:e.target.checked})}/>
                            <i></i>
                        </label>
                        <label htmlFor="students">{t('setupSchool__application')} {t('setupSchool__requiredToJoin')}</label>
                    </div>
                </div>
            </div>
            <div className="genders">
                <div className="containers-input">
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input type="checkbox" name="principal-button" value="" checked={props.club.clubDuesRequired} onClick={(e:any)=>props.setClub({...props.club,clubDuesRequired:e.target.checked})}/>
                            <i></i>
                        </label>
                        <label htmlFor="students">{t('setupSchool__club')} {t('setupSchool__duesRequired')}</label>
                    </div>
                </div>
            </div>
            <div className="genders">
                <h2>{t('setupSchool__participate')}</h2>
                <div className="containers-input">
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input type="checkbox" name="principal-button" value="" checked={props.club.participateLocalCompetitions} onClick={(e:any)=>props.setClub({...props.club,participateLocalCompetitions:e.target.checked})}/>
                            <i></i>
                        </label>
                        <label htmlFor="students">{t('setupSchool__local')}</label>
                    </div>
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input type="checkbox" name="principal-button" value="" checked={props.club.participateRegionalCompetitions} onClick={(e:any)=>props.setClub({...props.club,participateRegionalCompetitions:e.target.checked})}/>
                            <i></i>
                        </label>
                        <label htmlFor="students">{t('setupSchool__regional')}</label>
                    </div>
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input type="checkbox" name="principal-button" value="" checked={props.club.participateNationalCompetitions} onClick={(e:any)=>props.setClub({...props.club,participateNationalCompetitions:e.target.checked})}/>
                            <i></i>
                        </label>
                        <label htmlFor="students">{t('setupSchool__national')}</label>
                    </div>
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input type="checkbox" name="principal-button" value="" checked={props.club.participateInternationalCompetitions} onClick={(e:any)=>props.setClub({...props.club,participateInternationalCompetitions:e.target.checked})}/>
                            <i></i>
                        </label>
                        <label htmlFor="students">{t('setupSchool__international')}</label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
