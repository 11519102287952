import "../styles.scoped.css";
import '../styles.css';
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import OrganizationComplete from "../../../interfaces/organization/OrganizationComplete";
import CardClub from "./cards/CardClub";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../../ClubIconsNavBar";
import ModalAddClub from "./modals/ModalAddClub";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { getSchoolsYouth } from "../../../store/actions/orgsActions";
import {getTemplateClubs} from "../../../store/actions/templateClubActions";
import { Backend, MicroServices } from "../../../helpers/backendHelper";
import TClub from "../../../interfaces/TClubs/TClub/TClub";
import ModalEditClub from "./modals/ModalEditClub";
import ModalDeleteClub from "./modals/ModalDeleteClub";

function ClubsYouth(props: any) {

    const dispatch = useDispatch();

    const [skip, setSkip] = useState<boolean>(true);
    const [showCreateClub,setShowCreateClub] = useState<boolean>(false);
    const [TCListId, setTCListId] = useState<string>();
    const [TClubs, setTClubs] = useState<TClub[]>([])
    const [TClubToEdit,setTClubToEdit] = useState<TClub | undefined>(undefined)
    const [TClubToDelete,setTClubToDelete] = useState<TClub | undefined>(undefined)

    const clubs = useSelector((state: RootState) => state.getTemplateClubs.templateClubs)

    const organization = useSelector(
        (state: any) => state.getOrgs.organizationInfo
      );

    useEffect(() => {
        dispatch(getTemplateClubs(organization.id));
        getTClubsByYO();
    }, [showCreateClub, TClubToEdit, TClubToDelete])

    useEffect(() => getTCListByCountry(), [organization?.countryCode]);

    const getTCListByCountry = () => {
        Backend(
            MicroServices.Club,
            `/tclubs/tclists/by-country?country=${organization?.countryCode}`
        )
            .then((response) => response.json())
            .then(({id}) => setTCListId(id))
    }

    const getTClubsByYO = () => {
        Backend(
            MicroServices.Club,
            `/tclubs/by-youth?yoId=${organization?.YOTableId}`
        )
            .then((response) => response.json())
            .then((results) => {setTClubs(results)})
    }

    return (
        <>
            <div className="container-myschool content-MySchool">
                <h1>Zaahah</h1>
                <span>Add the Programs/Clubs offered by {organization?.name || 'your Youth Organization'}.</span>
                <div className="btnWrapper">
                    {skip ? (
                        <button
                            className="button-skip"
                            onClick={(e) => props.handleOnClick(e, 4)}>
                            Skip
                        </button>
                    ) : (
                        <>
                            <button
                                name="btn-Step2-Save"
                                className="button-save"
                                onClick={(e) => props.handleOnClick(e, 4)}>
                                Continue
                            </button>
                        </>
                    )}
                </div>
            </div>

            <div className="schools">
                {(TClubs || []).map((club) => (<CardClub club={club} setTClubToEdit={setTClubToEdit} setTClubToDelete={setTClubToDelete}/>))}

                <div className="createSchool-card plus"
                     onClick={() => {
                            setShowCreateClub(true);
                            setSkip(false);
                        }}
                >
                    <FontAwesomeIcon
                        icon="plus"
                        size='lg'
                        color={WHITE}
                    />
                </div>
            </div>

            <ModalAddClub show={showCreateClub} setShow={setShowCreateClub} TCListId={TCListId}/>
            <ModalEditClub show={TClubToEdit!==undefined} setEdit={setTClubToEdit} club={TClubToEdit}/> 
            <ModalDeleteClub show={TClubToDelete!==undefined} setDelete={setTClubToDelete} club={TClubToDelete}/>
        </>

    );
}

export default ClubsYouth;
