import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RightPanel } from "../../../containers/AppLayout/components";
import { AvatarParticipant, ChatFiles, ChatLinks, ChatMedia, ColumFlexParticipant, ImageContainerParticipant, ParticipantWrap, ParticipantsTitle, ParticipantsWrapper, ResourcesSelector, TextTypeMember, Text, ScrollParticipants } from "./components";
import { useTranslation } from "react-i18next";
import { ChatResourcesListInterface } from "../../../interfaces/chat/ChatResourcesListInterface";
import { ChatResourceInterface } from "../../../interfaces/chat/ChatResourceInterface";
import { defaultResources } from "../../../helpers/chat/chatHelper";
import { ChatServiceInterface } from "../../../interfaces/chat/ChatServiceInterface";
import { ChatGroupParticipant } from "../../../interfaces/chat/ChatGroupParticipant";
import { capitalize } from "../../../helpers/letterHelper";
import { CircleStatusHeader } from "../../Header";

const ChatRightPanel = ({ ChatServiceInstance }: { ChatServiceInstance: ChatServiceInterface }) => {
    const { t, i18n } = useTranslation();
    const { id, idGroup } = useParams();

    const [selectedValue, setSelectedValue] = useState<string>('media');
    const [showConnections, setShowConnections] = useState<'media-participants' | 'media' | 'participants' | ''>('');
    const [resources, setResources] = useState<ChatResourcesListInterface>(
        defaultResources(id, idGroup)
    );

    useEffect(() => {
        if (location.pathname.includes('conversation')) {
            if (id && !idGroup) {
                setShowConnections('media');
            }
            else if (id && idGroup) {
                setShowConnections('media-participants');
            }
        }
        else {
            setShowConnections('');
        }
    }, [location.pathname])

    useEffect(() => {
        //@todo Delete mockup data ------------------------------
        /*setResources({
            conversation_id: idGroup ? (idGroup || '') : (id || ''),
            images: [{
                from:{
                    fullName: 'Cristian Santos',
                    username: '123456764',
                    avatar: '',
                    status: 'available',
                },
                to:{
                    fullName: 'Juan Gomez',
                    username: '1234910264',
                    avatar: '',
                    status: 'available',
                },
                media:{
                    file:"https://cdn.pixabay.com/photo/2023/03/09/20/02/cat-7840767_640.jpg",
                    type: 'IMG'
                }
            },
            {
                to:{
                    fullName: 'Cristian Santos',
                    username: '123456764',
                    avatar: '',
                    status: 'available',
                },
                from:{
                    fullName: 'Juan Gomez',
                    username: '1234910264',
                    avatar: '',
                    status: 'available',
                },
                media:{
                    file:"https://cdn.pixabay.com/photo/2023/03/06/15/29/bee-7833738_640.jpg",
                    type: 'IMG'
                }
            },
            {
                from:{
                    fullName: 'Cristian Santos',
                    username: '123456764',
                    avatar: '',
                    status: 'available',
                },
                to:{
                    fullName: 'Juan Gomez',
                    username: '1234910264',
                    avatar: '',
                    status: 'available',
                },
                media:{
                    file:"https://cdn.pixabay.com/photo/2023/02/12/13/34/bird-7785106_640.jpg",
                    type: 'IMG'
                }
            },
            {
                to:{
                    fullName: 'Cristian Santos',
                    username: '123456764',
                    avatar: '',
                    status: 'available',
                },
                from:{
                    fullName: 'Juan Gomez',
                    username: '1234910264',
                    avatar: '',
                    status: 'available',
                },
                media:{
                    file:"https://cdn.pixabay.com/photo/2023/02/12/21/29/flowers-7785967_1280.jpg",
                    type: 'IMG'
                }
            },
            {
                from:{
                    fullName: 'Cristian Santos',
                    username: '123456764',
                    avatar: '',
                    status: 'available',
                },
                to:{
                    fullName: 'Juan Gomez',
                    username: '1234910264',
                    avatar: '',
                    status: 'available',
                },
                media:{
                    file:"https://cdn.pixabay.com/photo/2023/02/17/19/59/dog-7796822_1280.jpg",
                    type: 'IMG'
                }
            },
            {
                to:{
                    fullName: 'Cristian Santos',
                    username: '123456764',
                    avatar: '',
                    status: 'available',
                },
                from:{
                    fullName: 'Juan Gomez',
                    username: '1234910264',
                    avatar: '',
                    status: 'available',
                },
                media:{
                    file:"https://cdn.pixabay.com/photo/2023/02/12/23/32/lizard-7786211_1280.jpg",
                    type: 'IMG'
                }
            },
            {
                from:{
                    fullName: 'Cristian Santos',
                    username: '123456764',
                    avatar: '',
                    status: 'available',
                },
                to:{
                    fullName: 'Juan Gomez',
                    username: '1234910264',
                    avatar: '',
                    status: 'available',
                },
                media:{
                    file:"https://cdn.pixabay.com/photo/2023/03/08/10/11/crocuses-7837426_1280.jpg",
                    type: 'IMG'
                }
            },
            {
                to:{
                    fullName: 'Cristian Santos',
                    username: '123456764',
                    avatar: '',
                    status: 'available',
                },
                from:{
                    fullName: 'Juan Gomez',
                    username: '1234910264',
                    avatar: '',
                    status: 'available',
                },
                media:{
                    file:"https://cdn.pixabay.com/photo/2023/03/06/22/00/man-7834594_640.jpg",
                    type: 'IMG'
                }
            }],
            files: [{
                to:{
                    fullName: 'Cristian Santos',
                    username: '123456764',
                    avatar: '',
                    status: 'available',
                },
                from:{
                    fullName: 'Juan Gomez',
                    username: '1234910264',
                    avatar: '',
                    status: 'available',
                },
                media:{
                    file: "https://industri.fatek.unpatti.ac.id/wp-content/uploads/2019/03/108-Simulation-Modeling-and-Analysis-Averill-M.-Law-Edisi-5-2014.pdf",
                    type: 'FILE',
                    title: 'Simulation Modeling and Analysis',
                    fileType: 'pdf',
                    size: '1 Gb'
                }
            },
            {
                to:{
                    fullName: 'Cristian Santos',
                    username: '123456764',
                    avatar: '',
                    status: 'available',
                },
                from:{
                    fullName: 'Juan Gomez',
                    username: '1234910264',
                    avatar: '',
                    status: 'available',
                },
                media:{
                    file:"https://raw.githubusercontent.com/ssanderson/pydata-toolbox/master/notebooks/AAPL.csv",
                    type: 'FILE',
                    title: 'AAPL aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
                    fileType: 'csv',
                    size: '1 Gb'
                }
            }],
            links: [{
                to:{
                    fullName: 'Cristian Santos',
                    username: '123456764',
                    avatar: '',
                    status: 'available',
                },
                from:{
                    fullName: 'Juan Gomez',
                    username: '1234910264',
                    avatar: '',
                    status: 'available',
                },
                media:{
                    file: "https://pixabay.com/es/photos/search/?order=ec",
                    type: 'LINK',
                    title: 'Pixabay'
                }
            },
            {
                from:{
                    fullName: 'Cristian Santos',
                    username: '123456764',
                    avatar: '',
                    status: 'available',
                },
                to:{
                    fullName: 'Juan Gomez',
                    username: '1234910264',
                    avatar: '',
                    status: 'available',
                },
                media:{
                    file:"https://www.youtube.com/watch?v=2Mu7LAhxyp8&t=20852s",
                    type: 'LINK',
                    title: 'Disney Deep Sleep Piano Collection, Sleep Meditation, Calm Music, Relaxing Music(No Mid-roll Ads)'
                }
            },
            {
                to:{
                    fullName: 'Cristian Santos',
                    username: '123456764',
                    avatar: '',
                    status: 'available',
                },
                from:{
                    fullName: 'Juan Gomez',
                    username: '1234910264',
                    avatar: '',
                    status: 'available',
                },
                media:{
                    file: "https://jmeter.apache.org/download_jmeter.cgi",
                    type: 'LINK',
                    title: 'JMeter'
                }
            },
            {
                to:{
                    fullName: 'Cristian Santos',
                    username: '123456764',
                    avatar: '',
                    status: 'available',
                },
                from:{
                    fullName: 'Juan Gomez',
                    username: '1234910264',
                    avatar: '',
                    status: 'available',
                },
                media:{
                    file:"https://docs.cypress.io/api/cypress-api/custom-queries",
                    type: 'LINK',
                    title: 'Cypress'
                }
            }]
        })*/
        //-------------------------------------------------------
    }, [])

    return (
        <RightPanel>
            {(showConnections.includes('participants')) && (
                <ParticipantsWrapper>
                    <ParticipantsTitle>{t("chat_participants")}</ParticipantsTitle>
                    <ScrollParticipants>
                        {ChatServiceInstance.groupParticipants.map((participant) => (
                            <ParticipantWrap>
                                <ImageContainerParticipant>
                                    <CircleStatusHeader status={participant.status || 'available'} />
                                    <AvatarParticipant src={participant.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />
                                </ImageContainerParticipant>
                                <ColumFlexParticipant>
                                    <Text>{participant.fullName}</Text>
                                    <TextTypeMember>{capitalize(participant.chatMemberType || 'MEMBER')}</TextTypeMember>
                                </ColumFlexParticipant>
                            </ParticipantWrap>
                        ))}
                    </ScrollParticipants>
                </ParticipantsWrapper>
            )}
            {(showConnections.includes('media')) && (
                <>
                    <ResourcesSelector
                        values={[['media', resources.images.length], ['files', resources.files.length], ['links', resources.links.length]]}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                    />
                    {(selectedValue === 'media') && (
                        <ChatMedia media={resources.images.map((img: ChatResourceInterface) => img.media.file)} />
                    )}

                    {(selectedValue === 'files') && (
                        <ChatFiles files={resources.files.map((file: ChatResourceInterface) => file.media)} />
                    )}

                    {(selectedValue === 'links') && (
                        <ChatLinks links={resources.links.map((link: ChatResourceInterface) => link.media)} />
                    )}
                </>
            )}
        </RightPanel>
    );
}

export default ChatRightPanel;