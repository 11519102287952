import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Select, { ActionMeta, OnChangeValue, Options, StylesConfig } from "react-select";
import styled from "styled-components";
import { LAVENDER } from "../../components/ClubIconsNavBar";
import Button from 'react-bootstrap/Button';

export const Center = styled.div`
    width: calc(100% - 770px);
    min-height: 1000px;
    background-color: var(--violet);
    padding: 10px;
    margin-top: 67px;
`;

export const CentralBaseChat = styled.div`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
`;