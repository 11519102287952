import "../../styles.scoped.css";
import '../../styles.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WHITE } from '../../../ClubIconsNavBar';

function ModalDiscardChanges(props: { show: boolean, onHide: (type: string) => void }) {
    return (
        <>
            <Modal className="modalDiscardChanges" show={props.show} onHide={() => props.onHide('cancel')}>
                <Modal.Header>
                    <Modal.Title className="modalDiscardChangesTitle">Are you sure you want to discard changes?</Modal.Title>
                    <FontAwesomeIcon
                        icon="times"
                        size='xl'
                        color={WHITE}
                        className="icon-times  "
                        onClick={(e) => props.onHide('cancel')}
                    />
                </Modal.Header>
                <Modal.Footer className='modalDiscardChanges-footer'>
                    <Button className="buttonClose" onClick={(e) => props.onHide('cancel')}>
                        Cancel
                    </Button>
                    <Button className="buttonSend" onClick={(e) => props.onHide('discard')}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalDiscardChanges;