import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { unixToDateFormatted } from "../../helpers/dateHelper";
import { capitalize } from "../../helpers/letterHelper";
import { inviteRoleZAA } from "../../helpers/roleHelper";
import { GRAY, LAVENDER } from "../ClubIconsNavBar";
import "./styles.css";

const Index = ({ user, handleClickId }: any) => {
  const navigate = useNavigate();

  return (
    <tr>
      <td>
        <div className="id robotocondensed-normal-white-16px">
          {user.username}
        </div>
      </td>
      <td>
        <div className="roles robotocondensed-normal-white-16px">
          <ul className="listUsers">
            {user.roles.map((role: string) => (
              <li>{inviteRoleZAA(role || '')}</li>
            ))}
          </ul>
        </div>
      </td>
      <td>
        <p className="organizationName robotocondensed-normal-white-16px ellipses">
          <ul className="listUsers">
            {user.organizationsNames.map((org: string) => (
              <li>{org}</li>
            ))}
          </ul>
        </p>
      </td>
      <td>
        <div className="city robotocondensed-normal-white-16px">
          {user.city}
        </div>
      </td>
      <td>
        <div className="country robotocondensed-normal-white-16px">
          {user.country}
        </div>
      </td>
      <td>
        <div className="join  robotocondensed-normal-white-16px">
          {unixToDateFormatted(user.creationDate)}
        </div>
      </td>
      <td>
        <div className="status robotocondensed-normal-white-16px">
          {capitalize(user.status)}
        </div>
      </td>
      <td>
        <div className=" actions">
          <FontAwesomeIcon
  icon="eye"
  size='lg'
  color={LAVENDER}
  className="view-user  "
  onClick={() => navigate(`./${user.username}/view`)}
/>
          <FontAwesomeIcon
  icon="pen"
  size='lg'
  color={LAVENDER}
  className="update-user  "
  onClick={() => navigate(`./${user.username}/update`)}
/>
          <FontAwesomeIcon
            icon="trash-alt"
            className="trash-alt"
            color={user.status !== "DELETED" ? LAVENDER : GRAY}
            onClick={user.status !== "DELETED" ? ()=>handleClickId(user.username) : ()=>{}}
          />
        </div>
      </td>
    </tr>
  );
};

export default Index;
