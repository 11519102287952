import React, { useEffect, useState } from 'react';
import "./styles.css";
import { MdOutlineHome } from 'react-icons/md';
import { HiOutlineChatAlt2 } from 'react-icons/hi';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Menu from '../Menu'
import { RootState } from '../../store/store';
import styled from 'styled-components';
import { getStatusColor } from '../../helpers/chat/statusHelper';
import { ChatServiceInterface } from '../../interfaces/chat/ChatServiceInterface';

interface HeaderProps {
  //todo -> put types
  handleActiveNotification?: any;
  ChatServiceInstance?: ChatServiceInterface;
}

const Index = (props: HeaderProps) => {

  const userRoles = useSelector((state: any) => state.getUser.userRoles);
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const user = useSelector((state: any) => state.getUser.user);

  const navigate = useNavigate();
  const location = useLocation();

  const [isActive, setActive] = useState(false);
  const [activeChat, setActiveChat] = useState(false);

  const handleToggle = () => {
    setActive(false);
  };

  const activeNotification = () => {
    props.handleActiveNotification(true)
  }

  useEffect(() => {
    if (location.pathname.includes('chat')) {
      setActiveChat(true);
    }
  }, [])

  useEffect(() => {
    if (!activeChat && props.ChatServiceInstance?.chatConnected) props.ChatServiceInstance.getChatList();
  }, [activeChat])

  return (
    <div className="header">
      <div className="zaahah" onClick={() => navigate('/app')}>Zaahah</div>
      <div className="header-options">
        {/* <div className="home" onClick={() => navigate('/app')}>
          <MdOutlineHome color="white" size="30px" />
        </div> */}
        <HiOutlineChatAlt2 color={activeChat ? "var(--lavender)" : "white"} size="30px" className="pointer" onClick={() => navigate('/chat')} />
        {!activeChat && props.ChatServiceInstance?.newMessages !== 0 &&
          <NotificationChatCircle key={props.ChatServiceInstance?.newMessages || 0}
            width={(props.ChatServiceInstance?.newMessages || 0) > 99 ? '25px' : (props.ChatServiceInstance?.newMessages || 0) > 10 ? '20px' : '17px'}>
            {(props.ChatServiceInstance?.newMessages || 0) > 99 ? '99+' : props.ChatServiceInstance?.newMessages}
          </NotificationChatCircle>}
        <div className="img-container" onClick={() => setActive(true)}>
          <CircleStatusHeader status={props.ChatServiceInstance?.connectedUser.status || 'available'} />
          <div className='containerImageProfileHeader'>
            <img className="prof-pic" src={user.avatar ? user.avatar : `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png`} alt="profile-pic" />
          </div>
        </div>
        <Menu show={isActive} onHide={handleToggle} user={userRoles} activeNotification={activeNotification} ChatServiceInstance={props.ChatServiceInstance} />
      </div>
    </div>
  )
};

export default Index;

interface CircleStatusProps {
  status: string;
}

export const CircleStatusHeader = styled.div`
    padding: 6px;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50px;
    background-color: ${(props: CircleStatusProps) => getStatusColor(props.status.toLocaleLowerCase())};
`;

interface NotificationChatProps {
  width: string;
}

export const NotificationChatCircle = styled.div`
    background-color: white;
    border-radius: 50px;
    width: ${(props: NotificationChatProps) => props.width};
    height: 17px;
    position: relative;
    font-size: 15px;
    font-family: 'Roboto Condensed'
`;