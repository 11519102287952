import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import "./styles.css";
import ClubFilterBar from "../../components/ClubFilterBar";
import ClubRow from "../../components/ClubRow";
import { getClubs, getMyClubs, getMyPendingClubs } from "../../store/actions/clubActions";
import { getClubSuggestions, getUserOrgSuggestion } from "../../store/actions/connectionActions";
import { getUser } from "../../store/actions/userActions";
import ModalClub from '../SetupStudent/ModalClub';
import { useLocation } from "react-router-dom";
import ClubInterface from "../../interfaces/ClubExpanded";
import { useTranslation } from "react-i18next";

const sortAlphabeticallyOnProperty = (list: any[], property: string, ordSelected: string = 'ASC') => {
  if (ordSelected === 'DESC') {
    return list.sort((b, a) => a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0)
  }
  return list.sort((a, b) => a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0)
}

function Index(props: any) {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [selected, setSelected] = useState("My clubs");
  const [screen, setScreen] = useState("My clubs");

  const user = useSelector((state: any) => state.getUser.userInfo);
  const userAll = useSelector((state: any) => state.getUser.user);
  const listClubs = useSelector((state: any) => state.getClub.getMyClubs);
  const pendingClubs = useSelector((state: any) => state.getClub.getMyPendingClubs);
  const discoverClubs = useSelector((state: any) => state.getConnection.GetCSug);
  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  );

  const [isAdmin, setIsAdmin] = useState(false);
  const [communities, setCommunities] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [teams, setTeams] = useState([]);
  const [valueFilter, setValueFilter] = useState("");
  const [ordSelected, setOrdSelected] = useState("ASC");
  const [orderField, setOrderField] = useState("name");

  const [tabClubs, setTabClubs] = useState(0);

  const [showSetupClub, setShowSetupClub] = useState(false);
  const [clickedClub, setClickedClub] = useState({});
  const [typeClub, setTypeClub] = useState("");
  const [tabActive, setTabActive] = useState(0);
  const [pendingClubModal, setPendingClubModal] = useState('')

  function getLanguage(e: string) {
    switch (e) {
      case "spanish":
        return 'es'
        break;
      case "english":
        return 'en'
        break;
      default:
        return '';
        break;
    }
  }

  useEffect(() => {

    dispatch<any>(getUser(userAll.username));
    i18n.changeLanguage(getLanguage(userAll.preferredLanguage))
    if (props.isAdmin === 'SUPER') {
      setIsAdmin(true);

      dispatch<any>(getClubs({ club_type: 'COMM' }))
        .then((results: any) => setCommunities(results));

      dispatch<any>(getClubs({ club_type: 'TEAM' }))
        .then((results: any) => setTeams(results));

      dispatch<any>(getClubs({ club_type: 'CLUB' }))
        .then((results: any) => setClubs(results));
    }

    else if (props.isAdmin === 'ELSE') {
      setIsAdmin(false);
      dispatch<any>(getMyClubs(user.username))
        .then(console.log)
        .catch(console.error)

      dispatch<any>(getMyPendingClubs(user.username))
        .then(console.log)
        .catch(console.error)
    }
  }, []);

  useEffect(() => {

    if ((props.isAdmin === 'SUPER' || props.isAdmin === 'ORGADMIN')) {

      adminLeftPanel();
    }
    else {
      userLeftPanel();
    }

  }, [location, teams, clubs, communities, listClubs, pendingClubs])

  const adminLeftPanel = () => {
    const club = (location.pathname || '').split('/');

    if (teams && teams.length > 0) {
      const isTeam = (teams || []).some((team: ClubInterface) => (team.id === club[club.length - 1]))

      if (isTeam) {
        setSelected("Discover");
        setScreen("Discover");
        setTabClubs(2);
        return;
      }
    }

    if (clubs && clubs.length > 0) {
      const isClub = (clubs || []).some((clb: ClubInterface) => (clb.id === club[club.length - 1]))

      if (isClub) {
        setSelected("Pending");
        setScreen("Pending");
        setTabClubs(1);
        return;
      }
    }

    if (communities && communities.length > 0) {
      const isComm = (communities || []).some((comm: ClubInterface) => (comm.id === club[club.length - 1]))

      if (isComm) {
        setSelected("My clubs");
        setScreen("My clubs");
        setTabClubs(0);
        return;
      }
    }
  }

  const sortSetup = (list: any) => {
    console.log(list)
    return(list.sort((b: any, a: any) => a['is_setup'] < b['is_setup'] ? -1 : a['is_setup'] > b['is_setup'] ? 1 : 0))
  }

  const userLeftPanel = () => {
    const club = (location.pathname || '').split('/');
    if (listClubs && listClubs.length > 0) {
      const isMyClub = (listClubs || []).some((myClub: ClubInterface) => (myClub.id === club[club.length - 1]))

      if (isMyClub) {
        setSelected("My clubs");
        setScreen("My clubs");
        setTabClubs(0);
        return;
      }
    }

    if (pendingClubs && pendingClubs.length > 0) {
      const isPending = (pendingClubs || []).some((pend: ClubInterface) => (pend.id === club[club.length - 1]))
      if (isPending) {
        setSelected("Pending");
        setScreen("Pending");
        setTabClubs(1);
        return;
      }
    }
  }

  function handleChangeSearch(e: any) {
    setValueFilter(e)
  }

  function handleCloseSetupClub() {
    setShowSetupClub(false);
  }

  function handleClickClub(e: any) {
    setTypeClub(e.club_type)
    setClickedClub(e)
    setShowSetupClub(true);
  }

  useEffect(() => {
    if (userAll && "organizations" in userAll && userAll.organizations.length > 0) {

      if (props.isAdmin === 'ORGADMIN') {
        setIsAdmin(true);

        dispatch<any>(
          getClubs({
            club_type: 'COMM',
            org_id: userAll.organizations[0],
            youthOrg: (organization.type === 'YOUTHORG') ? "true" : "false"
          }))
          .then((results: any) => setCommunities(results));

        dispatch<any>(
          getClubs({
            club_type: 'TEAM',
            org_id: userAll.organizations[0],
            youthOrg: (organization.type === 'YOUTHORG') ? "true" : "false"
          }))
          .then((results: any) => setTeams(results));

        dispatch<any>(
          getClubs({
            club_type: 'CLUB',
            org_id: userAll.organizations[0],
            youthOrg: (organization.type === 'YOUTHORG') ? "true" : "false"
          }))
          .then((results: any) => setClubs(results));

      }

      else if (props.isAdmin === 'ELSE') {
        setIsAdmin(false);
        dispatch<any>(
          getClubSuggestions(
            userAll.organizations[0],
            userAll.grade,
            userAll.gender,
            userAll.roles[0].split('#')[1],
            userAll.username,
          )
        );//TODO -> When userAll has more orgs and roles
      }
    }

    if (userAll && "organizations" in userAll && userAll.organizations.length > 0) {
      dispatch<any>(getClubSuggestions(userAll.organizations[0], userAll.grade, userAll.gender, userAll.roles[0].split('#')[1], userAll.username,));//TODO -> When userAll has more orgs and roles
    }
  }, [userAll]);

  const getFilteredClubs = (listName: any[]) => {
    return listName.filter((item: any) => {
      if (!valueFilter) return true;
      if (item.name.toUpperCase().includes(valueFilter) ||
        item.name.toLowerCase().includes(valueFilter) ||
        item.name.includes(valueFilter)
      ) {
        return true;
      }
      return false;
    })
  }

  return (
    <>
      <div className="section-navbar">
        <div
          className={
            selected === "My clubs"
              ? "section-column-selected"
              : "section-column"
          }
          id="left-rad"
          onClick={() => {
            setSelected("My clubs");
            setScreen("My clubs");
            setTabClubs(0);
          }}
        >
          <div
            style={{
              backgroundColor:
                selected === "My clubs" ? "var(--lavender)" : "transparent",
            }}
            className={"section-label robotocondensed-normal-white-16px"}
          >
            {isAdmin ? t('bulletin__communinites') : t('bulletin__myClubs')}
          </div>
        </div>
        <div
          className={
            selected === "Pending"
              ? "section-column-selected"
              : "section-column"
          }
          onClick={() => {
            setSelected("Pending");
            setScreen("Pending");
            setTabClubs(1);
          }}
        >
          <div className={"section-label robotocondensed-normal-white-16px"}>
            {isAdmin ? t('bulletin__clubs') : t('bulletin__pending')}
          </div>
        </div>
        <div
          className={
            selected === "Discover"
              ? "section-column-selected"
              : "section-column"
          }
          id="right-rad"
          onClick={() => {
            setSelected("Discover");
            setScreen("Discover");
            setTabClubs(2);
          }}
        >
          <div className={"section-label robotocondensed-normal-white-16px"}>
            {isAdmin ? t('bulletin__teams') : t('bulletin__discover')}
          </div>
        </div>
      </div>
      <ClubFilterBar styles="Left" inputSearch={handleChangeSearch} ordenSelected={setOrdSelected} orderField={setOrderField} isAdmin={isAdmin} />
      <div className="containerListClubs">
        {isAdmin ?
          (tabClubs === 0 && communities?.length > 0 ?
              sortSetup(sortAlphabeticallyOnProperty(getFilteredClubs(communities), orderField, ordSelected)).map((clubF: any) => (
                <>
                  <ClubRow infoClub={clubF} screen={screen} isAdmin={true} />
                </>
              ))
            :
            tabClubs === 1 && clubs?.length > 0 ?
              sortAlphabeticallyOnProperty(getFilteredClubs(clubs), orderField, ordSelected).map((clubF: any) => (
                <>
                  <ClubRow infoClub={clubF} screen={screen} isAdmin={true} />
                </>
              ))
              :
              tabClubs === 2 && teams?.length > 0 ?
                sortAlphabeticallyOnProperty(getFilteredClubs(teams), orderField, ordSelected).map((clubF: any) => (
                  <>
                    <ClubRow infoClub={clubF} screen={screen} isAdmin={true} />
                  </>
                ))
                :
                '')

          :

          (tabClubs === 0 && listClubs?.length > 0 ?
            sortAlphabeticallyOnProperty(getFilteredClubs(listClubs), orderField, ordSelected).map((clubF: any) => (
              <>
                <ClubRow infoClub={clubF} screen={screen} isAdmin={false} />
              </>
            ))
            :
            tabClubs === 1 && pendingClubs?.length > 0 ?
              sortAlphabeticallyOnProperty(getFilteredClubs(pendingClubs), orderField, ordSelected).map((clubF: any) => (
                <>
                  <ClubRow infoClub={clubF} screen={screen} isAdmin={false} detailModal={isAdmin ? false : true} idClub={handleClickClub} pendingClubModal={setPendingClubModal} />
                </>
              ))
              :
              tabClubs === 2 && discoverClubs?.length > 0 ?
                sortAlphabeticallyOnProperty(getFilteredClubs(discoverClubs), orderField, ordSelected).map((clubF: any) => (
                  <>
                    <ClubRow infoClub={clubF} screen={screen} isAdmin={false} detailModal={isAdmin ? false : true} idClub={handleClickClub} pendingClubModal={setPendingClubModal} />
                  </>
                ))
                :
                '')
        }
      </div>
      <ModalClub
        show={showSetupClub}
        onHide={() => handleCloseSetupClub()}
        club={clickedClub}
        tabActive={tabActive}
        setTabActive={setTabActive}
        typeClub={typeClub}
        setShowSetupClub={setShowSetupClub}
        pendingClubModal={pendingClubModal}
      />
    </>
  );
}

export default Index;
