import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import { WHITE } from "../../../../components/ClubIconsNavBar";
import ModalBaseProps from "../../../../interfaces/modals/ModalBaseProps";
import { BaseInput, CancelButton, ClickableIcon, Row, SaveButton } from "../../../../components/SuperAdminComponents/basicForSACenter";
import ModalDiscardChanges from '../../../../components/SetupSetup/ModalDiscardChanges';
import useActiveCountry, { CountryOption } from "../../../../hooks/useActiveCountry";
import Select from 'react-select';
import { customStylesRSMulti } from "../../../../helpers/selectHelper";
import { Backend, MicroServices } from "../../../../helpers/backendHelper";
import { toast } from "react-toastify";
import useTCListAvailableCountries from "../../../../hooks/useTCListAvailableCountries";
import { getFieldState } from "../../../TClubs/utils";
// import { ClickableIcon } from "../../../../components/SuperAdminComponents/basicForSACenter";

interface CreateModalProps extends ModalBaseProps {
}


const CreateModal = ({
    show,
    onHide,
}: CreateModalProps) => {
    const { t } = useTranslation();
    const { activeCountriesReactSelect } = useActiveCountry();

    const [showDiscard, setShowDiscard] = useState(false);
    const [loadingAssign, setLoadingAssign] = useState(false);
    const [listName, setListName] = useState(getFieldState(''));
    const [countryList, setCountryList] = useState(getFieldState<CountryOption[]>([]))

    const onCancel = () => setShowDiscard(true);

    const onCreate = () => {
        if (errorsInForm()) {
            return;
        }
        setLoadingAssign(true);

        Backend(
            MicroServices.Connections,
            '/interests/intlists',
            {
                method: 'POST',
                body: JSON.stringify({
                    name: listName.value,
                    type: 'CSP',
                    countries: (countryList.value || []).map(({value}) => value),
                })
            }
        )
            .then((response) => {
                if (response.status === 201) {
                    toast.success(t('toast_listCreated'));
                    cleanForm();
                    onHide();
                    return;
                }
                toast.error(t('toast_errorAnError'));
            })
            .finally(() => setLoadingAssign(false))
    }

    const onHideDiscard = (type: string) => {
        setShowDiscard(false);
        if (type === 'discard') {
            onHide();
            cleanForm();
        }
    };

    const cleanForm = () => {
        setListName(getFieldState(''));
        setCountryList(getFieldState<CountryOption[]>([]));
    }

    const errorsInForm = () => {
        let errors = false;

        if (!listName.value || listName.value.length <= 0) {
            errors = true;
            setListName({
                ...listName,
                showError: true,
                error: 'Name is required',
            })
        } else {
            setListName(getFieldState(listName.value));
        }

        if (!countryList.value || countryList.value.length <= 0) {
            errors = true;
            setCountryList({
                ...countryList,
                showError: true,
                error: 'At least a country is required',
            })
        } else {
            setCountryList(getFieldState(countryList.value));
        }

        return errors;
    }

    const onChangeListName = (event: React.FormEvent<HTMLInputElement>) => {
        if (event) {
            setListName({
                ...listName,
                value: event?.currentTarget.value
            });
        }
    }

    const onChangeListCountries = (updatedList: any) => {
        setCountryList({
            ...countryList,
            value: updatedList,
        })
    }

    return (
        <>
            <Modal className="modalInviteMemebers" show={show} onHide={onCancel}>
                <Modal.Header>
                    <Modal.Title>
                        Create New List of Interests
                    </Modal.Title>
                    <ClickableIcon
                        icon={faTimes}
                        size='xl'
                        color={WHITE}
                        className="icon-times"
                        onClick={onCancel}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <BaseInput
                            placeholder="Name"
                            onChange={onChangeListName}
                            value={listName.value}
                        />
                    </Row>
                    <Row>
                        <Select
                            options={activeCountriesReactSelect}
                            styles={customStylesRSMulti()}
                            isMulti
                            value={countryList.value}
                            onChange={onChangeListCountries}
                            placeholder="Countries"
                        />
                    </Row>
                </Modal.Body>
                <Modal.Footer className='modalDiscardChanges-footer'>
                    <CancelButton onClick={onCancel}>
                        {t('button__cancel')}
                    </CancelButton>
                    <SaveButton onClick={onCreate}>
                        {loadingAssign
                            ? (
                                <div className="icon-spinner-third buttonSendSignUp"></div>
                            )
                            : t('button__save')
                        }
                    </SaveButton>
                </Modal.Footer>
            </Modal>
            <ModalDiscardChanges
                show={showDiscard}
                onHide={onHideDiscard}
            />
        </>
    );
};

export default CreateModal;