import { AnyAction } from "redux";
import {
  GET_CLUBS_ORG_ERROR,
  GET_CLUBS_ORG_SUCCESS,
  START_GET_CLUBS_ORG,
  START_GET_MY_CLUBS,
  GET_MY_CLUBS_SUCCESS,
  GET_MY_CLUBS_ERROR,
  START_GET_USER_PENDING_CLUBS,
  GET_USER_PENDING_CLUBS_SUCCESS,
  GET_USER_PENDING_CLUBS_ERROR,
  START_POST_USER_AS_MEMBER,
  POST_USER_AS_MEMBER_SUCCESS,
  POST_USER_AS_MEMBER_ERROR,
  START_POST_USER_AS_ADVISOR,
  POST_USER_AS_ADVISOR_SUCCESS,
  POST_USER_AS_ADVISOR_ERROR,
  START_POST_USER_AS_FAN,
  POST_USER_AS_FAN_SUCCESS,
  POST_USER_AS_FAN_ERROR,
  START_POST_USER_AS_FOLLOWER,
  POST_USER_AS_FOLLOWER_SUCCESS,
  POST_USER_AS_FOLLOWER_ERROR,
  START_POST_USER_AS_JOIN_REQUESTED,
  POST_USER_AS_JOIN_REQUESTED_SUCCESS,
  POST_USER_AS_JOIN_REQUESTED_ERROR,
  START_POST_USER_AS_QUIT_REQUESTED,
  POST_USER_AS_QUIT_REQUESTED_SUCCESS,
  POST_USER_AS_QUIT_REQUESTED_ERROR,
  START_DELETE_JOIN,
  DELETE_JOIN_SUCCESS,
  DELETE_JOIN_ERROR,
  START_GET_COMMUNITIES,
  GET_COMMUNITIES_SUCCESS,
  GET_COMMUNITIES_ERROR,
  START_GET_CLUB,
  GET_CLUB_SUCCESS,
  GET_CLUB_ERROR,
  START_ORGADMIN_ADVISOR,
  ORGADMIN_ADVISOR_ERROR,
  ORGADMIN_ADVISOR_SUCCESS,
  START_CLUBS_IM_ADV,
  CLUBS_IM_ADV_SUCCESS,
  CLUBS_IM_ADV_ERROR,
  START_GET_COMM,
  GET_COMM_SUCCESS,
  GET_COMM_ERROR,
  GET_CLUB_MEMBERS_ERROR,
  GET_CLUB_MEMBERS_SUCCESS,
  START_GET_CLUB_MEMBERS,
  START_EDIT_CLUB,
  EDIT_CLUB_ERROR,
  EDIT_CLUB_SUCCESS,
  LOGOUT_CLUB,
  GET_COMM_ORG_SETUP_ERROR,
  GET_COMM_ORG_SETUP_SUCCESS,
  START_GET_COMM_ORG_SETUP,
} from "../constants/clubConstants";
import ClubInterface from "../../interfaces/ClubExpanded";

interface ClubState {
  loadingGetClubsOrg: boolean;
  getClubsOrgError: any;
  getClubsOrg: ClubInterface[];

  loadingGetOrgCommSetup: boolean;
  getOrgCommSetupError: any;
  getOrgCommSetup: ClubInterface[];

  loadingGetClubs: boolean;
  getClubsError: any;
  getClubs: any[];

  loadingGetClub: boolean;
  getClubError: any;
  getClub: any;

  loadingAddOrgAdv: boolean;
  addOrgAdvError: any;
  addOrgAdv: any;

  loadingGetMyClubs: boolean;
  getMyClubsError: any;
  getMyClubs: any[];

  loadingGetMyPendingClubs: boolean;
  getMyPendingClubsError: any;
  getMyPendingClubs: any[];

  loadingPostUserAsMember: boolean;
  postUserAsMemberError: any;
  postUserAsMember: any[];

  loadingPostUserAsAdvisor: boolean;
  postUserAsAdvisorError: any;
  postUserAsAdvisor: any[];

  loadingPostUserAsFan: boolean;
  postUserAsFanError: any;
  postUserAsFan: any[];

  loadingPostUserAsFollower: boolean;
  postUserAsFollowerError: any;
  postUserAsFollower: any[];

  loadingPostUserAsJoinRequested: boolean;
  postUserAsJoinRequestedError: any;
  postUserAsJoinRequested: any[];

  loadingPostUserAsQuitRequested: boolean;
  postUserAsQuitRequestedError: any;
  postUserAsQuitRequested: any[];

  loadingDeleteJoin: boolean;
  deleteJoinError: any;
  deleteJoin: any[];

  loadingGetCommunities: boolean;
  GetCommunitiesError?: any;
  GetCommunities: any[];
  loadingClubsImAdv: boolean;
  clubsImAdvError: any;
  clubsImAdv: any[];

  loadingGetComm: boolean;
  GetCommError?: any;
  GetComm: any[];

  loadingClubMembers: boolean;
  clubMembersError?: any;
  clubMembers: any[];

  loadingEditClub: boolean;
  editClubError?: any;
}

const initialState : ClubState = {
  loadingGetClubsOrg: false,
  getClubsOrgError: undefined,
  getClubsOrg: [],

  loadingGetOrgCommSetup: false,
  getOrgCommSetupError: undefined,
  getOrgCommSetup: [],

  loadingGetClubs: false,
  getClubsError: undefined,
  getClubs: [],

  loadingGetClub: false,
  getClubError: undefined,
  getClub: undefined,

  loadingGetMyClubs: false,
  getMyClubsError: undefined,
  getMyClubs: [],

  loadingGetMyPendingClubs: false,
  getMyPendingClubsError: undefined,
  getMyPendingClubs: [],

  loadingPostUserAsMember: false,
  postUserAsMemberError: undefined,
  postUserAsMember: [],

  loadingPostUserAsAdvisor: false,
  postUserAsAdvisorError: undefined,
  postUserAsAdvisor: [],

  loadingPostUserAsFan: false,
  postUserAsFanError: undefined,
  postUserAsFan: [],

  loadingPostUserAsFollower: false,
  postUserAsFollowerError: undefined,
  postUserAsFollower: [],

  loadingPostUserAsJoinRequested: false,
  postUserAsJoinRequestedError: undefined,
  postUserAsJoinRequested: [],

  loadingPostUserAsQuitRequested: false,
  postUserAsQuitRequestedError: undefined,
  postUserAsQuitRequested: [],

  loadingDeleteJoin: false,
  deleteJoinError: undefined,
  deleteJoin: [],

  loadingGetCommunities: false,
  GetCommunitiesError: undefined,
  GetCommunities: [],
  loadingClubsImAdv: false,
  clubsImAdvError: undefined,
  clubsImAdv: [],

  loadingAddOrgAdv: false,
  addOrgAdvError: undefined,
  addOrgAdv: undefined,

  loadingGetComm: false,
  GetCommError: undefined,
  GetComm: [],

  loadingClubMembers: false,
  clubMembersError: undefined,
  clubMembers: [],

  loadingEditClub: false,
  editClubError: undefined,
};

export const getClubReducer = (state = initialState, action: AnyAction) : ClubState => {
  switch (action.type) {
    case START_GET_CLUBS_ORG:
      return {
        ...state,
        loadingGetClubsOrg: true,
      };
    case GET_CLUBS_ORG_SUCCESS:
      return {
        ...state,
        loadingGetClubsOrg: false,
        getClubsOrg: action.payload,
      };
    case GET_CLUBS_ORG_ERROR:
      return {
        ...state,
        loadingGetClubsOrg: false,
        getClubsOrgError: action.payload,
      };
    
    case START_GET_COMM_ORG_SETUP:
      return {
        ...state,
        loadingGetOrgCommSetup: true,
      };
    case GET_COMM_ORG_SETUP_SUCCESS:
      return {
        ...state,
        loadingGetOrgCommSetup: false,
        getOrgCommSetup: action.payload,
      };
    case GET_COMM_ORG_SETUP_ERROR:
      return {
        ...state,
        loadingGetOrgCommSetup: false,
        getOrgCommSetupError: action.payload,
      };

    case START_GET_CLUB:
      return {
        ...state,
        loadingGetClub: true,
      };
    case GET_CLUB_SUCCESS:
      return {
        ...state,
        loadingGetClub: false,
        getClub: action.payload,
      };
    case GET_CLUB_ERROR:
      return {
        ...state,
        loadingGetClub: false,
        getClubError: action.payload,
      };

    case START_GET_MY_CLUBS:
      return {
        ...state,
        loadingGetMyClubs: true,
      };
    case GET_MY_CLUBS_SUCCESS:
      return {
        ...state,
        loadingGetMyClubs: false,
        getMyClubs: action.payload,
      };
    case GET_MY_CLUBS_ERROR:
      return {
        ...state,
        loadingGetMyClubs: false,
        getMyClubsError: action.payload,
      };

    case START_ORGADMIN_ADVISOR:
      return {
        ...state,
        loadingAddOrgAdv: true,
      };
    case ORGADMIN_ADVISOR_SUCCESS:
      return {
        ...state,
        loadingAddOrgAdv: false,
        addOrgAdv: action.payload,
      };
    case ORGADMIN_ADVISOR_ERROR:
      return {
        ...state,
        loadingAddOrgAdv: false,
        addOrgAdvError: action.payload,
      };

    case START_POST_USER_AS_MEMBER:
      return {
        ...state,
        loadingPostUserAsMember: true,
      };
    case POST_USER_AS_MEMBER_SUCCESS:
      return {
        ...state,
        loadingPostUserAsMember: false,
        postUserAsMember: action.payload,
      };
    case POST_USER_AS_MEMBER_ERROR:
      return {
        ...state,
        loadingPostUserAsMember: false,
        postUserAsMember: action.payload,
      };

    case START_POST_USER_AS_ADVISOR:
      return {
        ...state,
        loadingPostUserAsAdvisor: true,
      };
    case POST_USER_AS_ADVISOR_SUCCESS:
      return {
        ...state,
        loadingPostUserAsAdvisor: false,
        postUserAsAdvisor: action.payload,
      };
    case POST_USER_AS_ADVISOR_ERROR:
      return {
        ...state,
        loadingPostUserAsAdvisor: false,
        postUserAsAdvisor: action.payload,
      };

    case START_POST_USER_AS_FAN:
      return {
        ...state,
        loadingPostUserAsFan: true,
      };
    case POST_USER_AS_FAN_SUCCESS:
      return {
        ...state,
        loadingPostUserAsFan: false,
        postUserAsFan: action.payload,
      };
    case POST_USER_AS_FAN_ERROR:
      return {
        ...state,
        loadingPostUserAsFan: false,
        postUserAsFan: action.payload,
      };

    case START_POST_USER_AS_FOLLOWER:
      return {
        ...state,
        loadingPostUserAsFollower: true,
      };
    case POST_USER_AS_FOLLOWER_SUCCESS:
      return {
        ...state,
        loadingPostUserAsFollower: false,
        postUserAsFollower: action.payload,
      };
    case POST_USER_AS_FOLLOWER_ERROR:
      return {
        ...state,
        loadingPostUserAsFollower: false,
        postUserAsFollower: action.payload,
      };

    case START_POST_USER_AS_JOIN_REQUESTED:
      return {
        ...state,
        loadingPostUserAsJoinRequested: true,
      };
    case POST_USER_AS_JOIN_REQUESTED_SUCCESS:
      return {
        ...state,
        loadingPostUserAsJoinRequested: false,
        postUserAsJoinRequested: action.payload,
      };
    case POST_USER_AS_JOIN_REQUESTED_ERROR:
      return {
        ...state,
        loadingPostUserAsJoinRequested: false,
        postUserAsJoinRequested: action.payload,
      };

    case START_POST_USER_AS_QUIT_REQUESTED:
      return {
        ...state,
        loadingPostUserAsQuitRequested: true,
      };
    case POST_USER_AS_QUIT_REQUESTED_SUCCESS:
      return {
        ...state,
        loadingPostUserAsQuitRequested: false,
        postUserAsQuitRequested: action.payload,
      };
    case POST_USER_AS_QUIT_REQUESTED_ERROR:
      return {
        ...state,
        loadingPostUserAsQuitRequested: false,
        postUserAsQuitRequested: action.payload,
      };

    case START_GET_USER_PENDING_CLUBS:
      return {
        ...state,
        loadingGetMyPendingClubs: true,
      };
    case GET_USER_PENDING_CLUBS_SUCCESS:
      return {
        ...state,
        loadingGetMyPendingClubs: false,
        getMyPendingClubs: action.payload,
      };
    case GET_USER_PENDING_CLUBS_ERROR:
      return {
        ...state,
        loadingGetMyPendingClubs: false,
        getMyPendingClubsError: action.payload,
      };

    case START_DELETE_JOIN:
      return {
        ...state,
        loadingDeleteJoin: true,
      };
    case DELETE_JOIN_SUCCESS:
      return {
        ...state,
        loadingDeleteJoin: false,
        deleteJoin: action.payload,
      };
    case DELETE_JOIN_ERROR:
      return {
        ...state,
        loadingDeleteJoin: false,
        deleteJoinError: action.payload,
      };
    
    case START_GET_COMMUNITIES:
        return {
            ...state,
            loadingGetCommunities: true,
        }
    case GET_COMMUNITIES_SUCCESS:
        return {
            ...state,
            loadingGetCommunities: false,
            GetCommunities: action.payload,
        }
    case GET_COMMUNITIES_ERROR:
        return {
            ...state,
            loadingGetCommunities: false,
            GetCommunitiesError: action.payload,
        }
    

    case START_CLUBS_IM_ADV:
      return {
        ...state,
        loadingClubsImAdv: true,
      };
    case CLUBS_IM_ADV_SUCCESS:
      return {
        ...state,
        loadingClubsImAdv: false,
        clubsImAdv: action.payload,
      };
    case CLUBS_IM_ADV_ERROR:
      return {
        ...state,
        loadingClubsImAdv: false,
        clubsImAdvError: action.payload,
      };

    case START_GET_COMM:
        return {
            ...state,
            loadingGetComm: true,
        }
    case GET_COMM_SUCCESS:
        return {
            ...state,
            loadingGetComm: false,
            GetComm: action.payload,
        }
    case GET_COMM_ERROR:
        return {
            ...state,
            loadingGetComm: false,
            GetCommError: action.payload,
        }

    case START_GET_CLUB_MEMBERS:
        return {
            ...state,
            loadingClubMembers: true,
        }
    case GET_CLUB_MEMBERS_SUCCESS:
        return {
            ...state,
            loadingClubMembers: false,
            clubMembers: action.payload,
        }
    case GET_CLUB_MEMBERS_ERROR:
        return {
            ...state,
            loadingClubMembers: false,
            clubMembersError: action.payload,
        }
    case START_EDIT_CLUB:
      return {
        ...state,
        loadingEditClub: true,
    }
    case EDIT_CLUB_SUCCESS:
      return {
        ...state,
        loadingEditClub: false,
    }
    case EDIT_CLUB_ERROR:
      return {
        ...state,
        loadingEditClub: false,
        editClubError: action.payload,
    }

    case LOGOUT_CLUB:
      return initialState;

    default:
      return state;
  }
};
