import { useEffect } from "react";
import "../../containers/SetupSchool/styles.scoped.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { WHITE, LAVENDER } from '../ClubIconsNavBar';

function Index(props: {
  handleFinish: () => void;
}) {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    props.handleFinish();
  }, [])

  return (
    <div className="container-complete">
        <div className="complete-text">
            <h1>{t('setupStaff__welcomeZaahah')}</h1>
            <h2>{t('setupStaff__weAreChecking')}</h2>
            <span>{t('setupStaff__loadingZaahah')}</span>
            <FontAwesomeIcon
  icon="spinner-third"
  size='xl'
  color={WHITE}
  className="icon-spinner-third  "
/>
        </div>
    </div>
  );
}

export default Index;
