import { useEffect, useMemo, useState } from "react";
import useYOListSA from "../../../../../hooks/YOTable/useYOListSA";
import BaseSelect from "../../../../../interfaces/BaseSelect";
import Select from "react-select";
import {
    customStylesRSLeft,
    DropdownIndicator,
} from "../../../../../helpers/selectHelper";

export interface YOOption extends BaseSelect {}
interface YODropdownProps {
    YOSelected: YOOption | undefined;
    setYOSelected: (item: YOOption) => void;
}

const YODropdown = ({ YOSelected, setYOSelected }: YODropdownProps) => {
    const { yos, reloadYOList } = useYOListSA(100);

    const YOList: YOOption[] = useMemo(
        () =>
            yos.map((yo) => ({
                value: yo.id,
                label: yo.name,
            })),
        [yos]
    );

    useEffect(() => {
        reloadYOList();
    }, []);

    const handleSelectYO = (value: any) => {
        setYOSelected(value as YOOption);
    };

    return (
        <Select
            options={YOList}
            styles={customStylesRSLeft()}
            components={{
                DropdownIndicator,
            }}
            value={YOSelected}
            onChange={handleSelectYO}
            placeholder="Select a youth"
        />
    );
};

export default YODropdown;
