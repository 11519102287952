import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { WHITE } from '../ClubIconsNavBar'
import './styles.scoped.css'

const Menu = (props: any) => {

    const [itemActive, setItemActive] = useState(0)

    function handleItemActive(e: any) {
        props.itemActive(e)
        setItemActive(e)
    }

    return (
        <ul className='menuMyAccount'>
            <li onClick={() => handleItemActive(0)} className={itemActive === 0 ? 'active' : ''}>
                <FontAwesomeIcon
                    icon="user"
                    size='sm'
                    color={WHITE}
                    className="menuIcon  "
                />
                General information
            </li>
            <li onClick={() => handleItemActive(1)} className={itemActive === 1 ? 'active' : ''}>
                <FontAwesomeIcon
                    icon="briefcase"
                    size='sm'
                    color={WHITE}
                    className="menuIcon  "
                />
                My Organization(s)
            </li>
            <li onClick={() => handleItemActive(2)} className={itemActive === 2 ? 'active' : ''}>
                <FontAwesomeIcon
                    icon="envelope"
                    size='sm'
                    color={WHITE}
                    className="menuIcon  "
                />
                Change Email
            </li>
            <li onClick={() => handleItemActive(3)} className={itemActive === 3 ? 'active' : ''}>
                <FontAwesomeIcon
                    icon="lock-alt"
                    size='sm'
                    color={WHITE}
                    className="menuIcon  "
                />
                Change Password
            </li>
            <li onClick={() => handleItemActive(4)} className={itemActive === 4 ? 'active' : ''}>
                <FontAwesomeIcon
                    icon="graduation-cap"
                    size='sm'
                    color={WHITE}
                    className="menuIcon  "
                />
                Education
            </li>
            <li onClick={() => handleItemActive(5)} className={itemActive === 5 ? 'active' : ''}>
                <FontAwesomeIcon
                    icon="briefcase"
                    size='sm'
                    color={WHITE}
                    className="menuIcon  "
                />
                Work History
            </li>
            <li onClick={() => handleItemActive(6)} className={itemActive === 6 ? 'active' : ''}>
                <FontAwesomeIcon
                    icon="link"
                    size='sm'
                    color={WHITE}
                    className="menuIcon  "
                />
                Social Media Accounts
            </li>
            <li onClick={() => handleItemActive(7)} className={itemActive === 7 ? 'active' : ''}>
                <FontAwesomeIcon
                    icon="cog"
                    size='sm'
                    color={WHITE}
                    className="menuIcon  "
                />
                Preferences
            </li>
        </ul>
    )
}

export default Menu