import { useEffect, useMemo, useState } from "react";
import { ChatUrl } from "../../AppConfig";
import WebSocket from 'isomorphic-ws';
import { defaultConnectUser, defaultConversation } from "../../helpers/chat/chatHelper";
import { ChatInterface } from "../../interfaces/chat/ChatInterface";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";
import { Conversation } from "../../interfaces/chat/Conversation";
import { UserChat, chatStatus } from "../../interfaces/chat/UserChat";
import Connection from "../../interfaces/user/Connection";
import { Message, messageType } from "../../interfaces/chat/Message";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { getBalance } from "../../store/actions/userActions";
import { ClubChatInfo } from "../../interfaces/chat/ClubChatInfo";
import { ChatGroupParticipant } from "../../interfaces/chat/ChatGroupParticipant";
import { toast } from 'react-toastify'
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export const testUUIDV4 = (s: string) => new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i).test(s)

const ChatService = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const {id, idGroup} = useParams();

    const [websocket, setWebsocket] = useState<WebSocket>();
    const [chatsList, setChatList] = useState<ChatInterface[]>([]);
    const [connections, setConnections] = useState<Connection[]>([]);
    const [activeConversation, setActiveConversation] = useState<Conversation>(defaultConversation());
    const [connectionError, setConnectionError] = useState<WebSocket.ErrorEvent | undefined>(undefined);
    const [connectedUser, setConnectedUser] = useState<UserChat>(defaultConnectUser());
    const [chatConnected, setChatConnected] = useState<boolean>(false);
    const [newMessages, setNewMessages] = useState<number>(0);
    const [clubChatsList, setClubChatsList] = useState<ClubChatInfo[]>([]);
    const [userGroupsList, setUserGroupsList] = useState<ClubChatInfo[]>([]);
    const [participantsToAdd, setParticipantsToAdd] = useState<ChatGroupParticipant[]>([]);
    const [groupParticipants, setGroupParticipants] = useState<ChatGroupParticipant[]>([]);

    //State updates on new message received (some states inside onMessage are empty)
    //It's a rare behavior found on stackoverflow -> avoid using those states inside the function -> use callbacks for setX)
    const updateChatsList = (bodyMsj: { from: string, message: { message: string, muted: boolean, type: messageType, ahaValue?: number, media?: string[] } }) => {
        setChatList((prevChatList) => {
            const convMessageIdx = prevChatList.findIndex((chat: ChatInterface) => chat.from.username === bodyMsj.from)
            if (convMessageIdx !== -1) {
                //Put message into chatList (lastMessages)
                const chatsNew = [...prevChatList];
                chatsNew[convMessageIdx].lastMessage = {
                    timestamp: new Date().valueOf(),
                    content: bodyMsj.message.message,
                    unread: true
                }
                chatsNew[convMessageIdx].muted = bodyMsj.message.muted
                return chatsNew
            } else {
                //New chat
                getChatList()
                return prevChatList
            }
        })
    }

    const updateActiveConversation = (bodyMsj: { from: string, message: { message: string, muted: boolean, type: messageType, ahaValue?: number, media?: string[] } }) => {
        setActiveConversation((prevConversation) => {
            const isActive = prevConversation.from.username === bodyMsj.from

            //Put message into activeConversation
            if (isActive) {
                const otherUser = prevConversation.from.username === connectedUser.username ? prevConversation.to : prevConversation.from
                return {
                    ...prevConversation,
                    muted: bodyMsj.message.muted,
                    messages: [
                        ...prevConversation.messages,
                        {
                            from: otherUser,
                            to: connectedUser,
                            content: bodyMsj.message.message,
                            id: new Date().valueOf(),
                            type: bodyMsj.message.type,
                            ahaValue: bodyMsj.message.ahaValue,
                            media: bodyMsj.message.media
                        }
                    ]
                }
            } else {
                return prevConversation
            }
        })
    }

    const updateActiveGroupConversation = (bodyMsj: { from: string, message: { chatId: string, message: string, type: messageType, media?: string[], from: UserChat } }) => {
        setActiveConversation((prevConversation) => {
            const isActive = prevConversation.id === bodyMsj.message.chatId

            //Put message into activeConversation
            if (isActive) {
                return {
                    ...prevConversation,
                    messages: [
                        ...prevConversation.messages,
                        {
                            from: {
                                ...bodyMsj.message.from,
                                status: 'available'
                            },
                            to: connectedUser,
                            content: bodyMsj.message.message,
                            id: new Date().valueOf(),
                            type: bodyMsj.message.type,
                            media: bodyMsj.message.media
                        }
                    ]
                }
            } else {
                return prevConversation
            }
        })
        setUserGroupsList((prevChatList) => {
            const convMessageIdx = prevChatList.findIndex((chat: ClubChatInfo) => chat.conversationId === bodyMsj.message.chatId)
            if (convMessageIdx !== -1) {
                //Unread message
                const chatsNew = [...prevChatList];
                chatsNew[convMessageIdx].unread = true
                return chatsNew
            } else {
                //New chat
                getUserGroupsList()
                return prevChatList
            }
        })
    }

    //Private Methods
    const onConnOpen = (event: WebSocket.Event) => {
        if (websocket?.readyState === WebSocket.OPEN) {
            setChatConnected(true)
        }
    }

    const onConnClose = (event: WebSocket.CloseEvent) => {
        setChatConnected(false)
    }

    const onConnError = (event: WebSocket.ErrorEvent) => {
        setConnectionError(event)
        console.log('Connection Error: ', event);
    }

    useEffect(() => {
        setNewMessages(chatsList.reduce(function (acc, curr) {
            return (!curr.muted && curr.lastMessage.unread) ? acc + 1 : acc
        }, 0))
    }, [JSON.stringify(chatsList)])

    const onMessage = (event: WebSocket.MessageEvent) => {
        const body = JSON.parse(event.data.toString())
        const action = body.type
        console.log(body)

        switch (action) {
            case 'lastMessages':
                setChatList(body.message.map((item: any) => {
                    return {
                        from: {
                            fullName: item.fullName,
                            username: item.username,
                            avatar: item.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
                            status: item.status
                        },
                        lastMessage: {
                            timestamp: item.createdAt,
                            content: item.message,
                            unread: item.from === connectedUser.username ? false : !item.read
                        },
                        conversationId: item.id,
                        muted: item.muted
                    }
                }))
                break;
            case 'conversation':
                if (body.message.Users) {
                    setGroupParticipants(Object.values(body.message.Users))
                    setActiveConversation({
                        from: {
                            fullName: body.message.chatName,
                            username: body.message.chatId,
                            avatar: '',
                            status: 'available'
                        },
                        to: connectedUser,
                        messages: getGroupMessages(body, body.message.Users),
                        id: body.message.chatId,
                        muted: body.message.muted,
                        type: 'GROUP'
                    });
                } else {
                    const otherUser: UserChat = {
                        fullName: body.message.User.fullName,
                        username: body.message.User.username,
                        avatar: body.message.User.avatar,
                        status: body.message.status
                    }
                    setActiveConversation({
                        from: otherUser,
                        to: connectedUser,
                        messages: getMessages(body, otherUser),
                        id: `${connectedUser.username}#${body.message.User.username}`,
                        muted: body.message.muted,
                        type: 'USER'
                    });
                }
                break;
            case 'message':
                if (body.from === connectedUser.username) break; //Sent message

                if (body.message.type === 'aha') dispatch(getBalance(connectedUser.username))
                updateChatsList(body)
                updateActiveConversation(body)
                break;
            case 'gmessage':
                if (body.from === connectedUser.username) break; //Sent message
                updateActiveGroupConversation(body)
                break;
            case 'connections':
                setConnections(body.message)
                break;
            case 'uploadMedia':
                Promise.all(body.message.Media.map((item: { media: any, url: string }) => {
                    fetch(item.url, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': decodeURIComponent(item.media.type)
                        },
                        body: item.media
                    })
                }))
                    .then(() => sendMessage(body.message.to, body.message.message, body.message.type, undefined, body.message.Media.map((m: { media: any, url: string }) => m.url)))
                    .catch((e) => {
                        toast.error(t('toast_somethingWrong'))
                        console.log(e)
                    })
                break;
            case 'clubChats':
                setClubChatsList(body.message)
                break;
            case 'userGroups':
                const userGroupsParsed = body.message.map((message: ClubChatInfo) : ChatInterface =>({
                    from: {
                        fullName: message.name,
                        username: message.conversationId,
                        avatar: '',
                        status: 'available'
                    },
                    lastMessage: {
                        timestamp: 0,
                        content: 'Group',
                        unread: message.unread || false,
                    },
                    conversationId: message.conversationId,
                    muted: message.muted || false,
                }))
                setUserGroupsList(body.message)
                setChatList([...chatsList, ...userGroupsParsed])
                break;
            case 'createGroup':
                if (body.message.created) {
                    toast.success(t('toast_chatCreated'))
                    if (body.message.clubId) {
                        getClubChatList(body.message.clubId)
                        navigate(`../chat/conversation/${body.message.clubId}/${body.message.chatId}`);
                    } else {
                        getChatList()
                        navigate(`../chat/conversation/${body.message.chatId}`);
                    }
                } else {
                    toast.error(t('toast_somethingWrong'))
                }
                break;
            case 'possibleReach':
                if (!body.error) {
                    setParticipantsToAdd(body.message)
                }
                break;
            case 'status':
                setActiveConversation((prevActive) => {
                    if (body.from === prevActive.from.username) {
                        return {
                            ...prevActive,
                            from: {
                                ...prevActive.from,
                                status: body.message
                            }
                        }
                    }
                    return prevActive
                })
                setChatList((prevChat) => {
                    const idx = prevChat.findIndex((chat) => chat.from.username === body.from)
                    if (idx >= 0) {
                        const prevCopy = [...prevChat]
                        prevCopy[idx] = {
                            ...prevCopy[idx],
                            from: {
                                ...prevCopy[idx].from,
                                status: body.message
                            }
                        }
                        return prevCopy
                    }
                    return prevChat
                })
                break;
            default:
                break;
        }
    }

    const getMessages = (body: any, otherUser: UserChat): Message[] => {
        if (connectedUser.username !== '') {
            return body.message.conversation.map((item: any, idx: number) => {
                return {
                    from: item.from === connectedUser.username ? connectedUser : otherUser,
                    to: item.from === connectedUser.username ? otherUser : connectedUser,
                    content: item.message,
                    id: item.createdAt,
                    type: item.type,
                    ahaValue: item.ahaValue,
                    media: item.media
                }
            }).reverse()
        }
        return []
    }

    const getGroupMessages = (body: any, otherUsers: { [username: string]: UserChat }): Message[] => {
        if (connectedUser.username !== '') {
            return body.message.conversation.map((item: any, idx: number) => {
                return {
                    from: item.poster === connectedUser.username ? connectedUser : otherUsers[item.poster],
                    content: item.message,
                    id: item.createdAt,
                    type: item.type,
                    media: item.media
                }
            }).reverse()
        }
        return []
    }

    //Public Methods
    const getStringRepresentation = (): string => activeConversation.messages.length + '';

    useEffect(() => {
        if (websocket) {
            websocket.onopen = onConnOpen;
            websocket.onmessage = onMessage;
            websocket.onclose = onConnClose;
            websocket.onerror = onConnError;
        }
    }, [websocket])

    const connect = (user: UserChat) => {
        const token = localStorage.getItem('IDToken');
        setWebsocket(new WebSocket(`${ChatUrl}?token=${token}`));
        setConnectedUser(user);
    }

    const disconnect = () => {
        websocket?.close()
        setChatConnected(false)
        setChatList([]);
        setActiveConversation(defaultConversation());
    }

    const sendMessage = (id: string, message: string, type?: messageType, ahaValue?: number, mediaUrls?: string[], chatId?: string) => {
        if (websocket?.readyState === WebSocket.OPEN) {
            websocket.send(JSON.stringify({
                action: 'sendMessage',
                message: message,
                to: id,
                type: type || 'text',
                ahaValue: ahaValue ? ahaValue * 1 : undefined,
                media: mediaUrls,
                chatId: testUUIDV4(id) ? id : chatId
            }));
            readMessage(id, chatId)

            //Put message into chatList (lastMessages) reading it
            const convMessageIdx = chatsList.findIndex((chat) => chat.from.username === id)
            if (chatId) {
                //Put gmessage into activeConversation
                setActiveConversation({
                    ...activeConversation,
                    messages: [
                        ...activeConversation.messages,
                        {
                            from: connectedUser,
                            to: {
                                fullName: '',
                                username: chatId,
                                avatar: '',
                                status: 'available'
                            },
                            content: message,
                            id: new Date().valueOf(),
                            type: type || 'text',
                            media: mediaUrls
                        }
                    ]
                })
            } else if (convMessageIdx !== -1) {
                const chatsNew = [...chatsList];
                chatsNew[convMessageIdx].lastMessage = {
                    timestamp: new Date().valueOf(),
                    content: message,
                    unread: false
                }
                setChatList(chatsNew);

                //Put message into activeConversation
                setActiveConversation({
                    ...activeConversation,
                    messages: [
                        ...activeConversation.messages,
                        {
                            from: connectedUser,
                            to: chatsList[convMessageIdx].from,
                            content: message,
                            id: new Date().valueOf(),
                            type: type || 'text',
                            ahaValue,
                            media: mediaUrls
                        }
                    ]
                })
            } else {
                //New chat
                getChatList()
                //Put message into activeConversation
                setActiveConversation({
                    ...activeConversation,
                    messages: [
                        {
                            from: connectedUser,
                            to: activeConversation.from,
                            content: message,
                            id: new Date().valueOf(),
                            type: type || 'text',
                            ahaValue,
                            media: mediaUrls
                        }
                    ]
                })
            }
        } else {
            console.error('Chat no connected.');
        }
    }

    const sendMedia = (id: string, media: any[], message: string, msjType: messageType) => {
        console.log({
            id, media, message, msjType
        })
        if (websocket?.readyState === WebSocket.OPEN) {
            websocket.send(JSON.stringify({
                action: 'uploadMedia',
                Media: media,
                to: id,
                message: message,
                type: msjType
            }));
        } else {
            console.error('Chat no connected.');
        }
    }

    const getConversation = (id: string, chatId?: string) => {
        if (websocket?.readyState === WebSocket.OPEN) {
            websocket.send(JSON.stringify({
                action: 'conversation',
                to: id,
                chatId: testUUIDV4(id) ? id : chatId
            }));
            readMessage(id, chatId)
        } else {
            console.error('Chat no connected.');
        }
    }

    const getChatList = () => {
        if (websocket?.readyState === WebSocket.OPEN) {
            websocket.send(JSON.stringify({
                action: 'lastMessages'
            }));
            getConnections();
            getUserGroupsList();
        } else {
            console.error('Chat no connected.');
        }
    }

    const getConnections = () => {
        if (websocket?.readyState === WebSocket.OPEN) {
            websocket.send(JSON.stringify({
                action: 'connections',
                forBirthday: false //@audit
            }));
        } else {
            console.error('Chat no connected.');
        }
    }

    const readMessage = (id: string, chatId?: string) => {
        if (websocket?.readyState === WebSocket.OPEN) {
            websocket.send(JSON.stringify({
                action: 'read',
                from: id,
                chatId: testUUIDV4(id) ? id : chatId
            }));

            //Read message
            const chatsNew = [...chatsList]
            const readChatIdx = chatsNew.findIndex((chat) => chat.from.username === id)
            if (readChatIdx !== -1) {
                chatsNew[readChatIdx] = {
                    ...chatsNew[readChatIdx],
                    lastMessage: {
                        ...chatsNew[readChatIdx].lastMessage,
                        unread: false
                    }
                }
                setChatList(chatsNew)
            }
        } else {
            console.error('Chat no connected.');
        }
    }

    const muteMessage = (mute: boolean) => {
        if (websocket?.readyState === WebSocket.OPEN) {
            websocket.send(JSON.stringify({
                action: 'mute',
                to: activeConversation.from.username,
                mute: mute,
                chatId: activeConversation.type === 'GROUP' ? activeConversation.id : undefined
            }));

            //Mute message
            const chatsNew = [...chatsList]
            const readChatIdx = chatsNew.findIndex((chat) => chat.from.username === activeConversation.from.username)
            if (readChatIdx !== -1) {
                chatsNew[readChatIdx] = {
                    ...chatsNew[readChatIdx],
                    muted: mute
                }
                setChatList(chatsNew)
            }
            setActiveConversation({
                ...activeConversation,
                muted: mute
            })
        } else {
            console.error('Chat no connected.');
        }
    }

    const updateStatus = (status: chatStatus) => {
        if (websocket?.readyState === WebSocket.OPEN) {
            websocket.send(JSON.stringify({
                action: 'status',
                status: status
            }));

            //Update status
            setConnectedUser({
                ...connectedUser,
                status: status
            })
        } else {
            console.error('Chat no connected.');
        }
    }

    const getClubChatList = (clubId: string) => {
        if (clubId === '') return;
        if (websocket?.readyState === WebSocket.OPEN) {
            websocket.send(JSON.stringify({
                action: 'clubChats',
                clubId
            }));
        } else {
            console.error('Chat no connected.');
        }
    }

    const getUserGroupsList = () => {
        if (websocket?.readyState === WebSocket.OPEN) {
            websocket.send(JSON.stringify({
                action: 'userGroups'
            }));
        } else {
            console.error('Chat no connected.');
        }
    }

    const getPossibleParticipants = (clubId?: string) => {
        if (websocket?.readyState === WebSocket.OPEN) {
            websocket.send(JSON.stringify({
                action: 'possibleReach',
                clubId
            }));
        } else {
            console.error('Chat no connected.');
        }
    }

    const createGroupChat = (
        viewable: boolean,
        chatName: string,
        officers?: boolean,
        members?: boolean,
        followers?: boolean,
        advisors?: boolean,
        chatUsers?: string[],
        clubId?: string
    ) => {
        if (websocket?.readyState === WebSocket.OPEN) {
            websocket.send(JSON.stringify({
                action: 'createGroup',
                viewable,
                chatName,
                officers,
                members,
                followers,
                advisors,
                chatUsers,
                clubId
            }));
        } else {
            console.error('Chat no connected.');
        }
    }

    const updateParticipantsToAdd = (toUpdate: ChatGroupParticipant[]) => {
        setParticipantsToAdd(toUpdate);
    }

    //Chat Service Public Instance
    const ChatServiceInstance: ChatServiceInterface = {
        chatsList,
        activeConversation,
        chatConnected,
        connections,
        connectedUser,
        newMessages,
        clubChatsList,
        userGroupsList,
        participantsToAdd,
        groupParticipants,

        getStringRepresentation,
        connect,
        disconnect,
        getConnections,
        sendMessage,
        sendMedia,
        getConversation,
        getChatList,
        getPossibleParticipants,
        readMessage,
        muteMessage,
        updateStatus,
        getClubChatList,
        createGroupChat,
        updateParticipantsToAdd,
    }

    return ChatServiceInstance;

}

export default ChatService;