export const capitalize = (word : string) => {
    if(word === 'YOUTHORG') {
        return 'Youth Organization'
    }
    if(word === 'NONPROFIT'){
        return 'Non Profit'
    }
    if((word === ' ') || (word === '')){
        return ''
    }
    return word[0].toUpperCase() + word.substring(1).toLowerCase();
    
};

export const capitalizeStatus = (word : string) => {
    if(word === 'PENDING_APPROVAL') {
        return 'Pending Approval'
    }
    if(word === 'PENDING_CONFIRMATION'){
        return 'Pending Confirmation'
    }
    if((word === ' ') || (word === '')){
        return ''
    }
    return word[0].toUpperCase() + word.substring(1).toLowerCase();
};

export const onlyFirstLetterCapitalized = (word : string):string =>{
    const text = word.toLowerCase()
    return text.charAt(0).toUpperCase() + text.slice(1);
}