import React from "react";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './MySchool/styles.css';
import ClubInterface from "../../interfaces/ClubExpanded";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";

function Index({show,message}:{show:boolean,message:string}) {
    const { t, i18n } = useTranslation();
    return (
        <>
            <Modal className="modalDelete" show={show}>
                <Modal.Header>
                    <Modal.Title>{message}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className="labelEventCreated"> {t('modalLoading__pleaseWait')}</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="icon-spinner-third">
                        {''}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Index;