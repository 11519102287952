import { useState } from "react";
import "./styles.scoped.css";
import { useNavigate } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from "../../components/ClubIconsNavBar";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";

toast.configure();

function Index(props: any) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [selectedItemId, setSelectedItemId] = useState(0);
  const [selectedItemState, setSelectedItemState] = useState(false);

  const userInfo = useSelector((state: any) => state.getUser.userInfo);

  const toggleClick = (id: any) => {
    if (id === selectedItemId) {
      setSelectedItemState(!selectedItemState);
    } else {
      setSelectedItemId(id);
      setSelectedItemState(true);
    }
  };

  const unsavePost = (id: string) => {
    Backend(
      MicroServices.Posts,
      `/post/${id}/saved?usernameSaver=${userInfo.username}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(t('toast_postUnsaved'));
          props.reloadPosts();
        } else {
          toast.error(t('toast_errorUnsavedPost'));
        }
      })
      .catch(console.error);
  };

  const imageToSave = (post: any) => {
    if (post.type === "event") {
      return post.eventCover;
    }

    if (post.infoPost.files) {
      return post.infoPost.files[0].link
    }

    return post.imageProfile ||
      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
  }

  console.log(props)

  return props.posts.length === 0 ?
    (
      <p className="textPost">{t('post__noPostsYet')}</p>
    )
    :
    (props.posts.map((post: any) => (
      <div className="itemPostSaved">
        <div className="cardPostSaved">
          {/*onClick={() => navigate(post.type === 'event' ? '../events/'+post.id: '../../post/'+post.id)}*/}
          <img
            src={
              imageToSave(post)
            }
            alt=""
          />
          {/*onClick={() => navigate(post.type === 'event' ? '../events/'+post.id: '../../post/'+post.id)}*/}
          <div className="itemPostInformation">
            <div className="nameProfilePost">
              {post.type === "video"
                ? post.nameProfile + `'s Video`
                : post.type === "event"
                  ? "Event: " + post.eventName
                  : post.type === "text"
                    ? post.nameProfile + `'s Text Post`
                    : post.nameProfile + `'s Post`}
            </div>
            <p
              dangerouslySetInnerHTML={{ __html: post.textPost }}
              className="description">
            </p>
            <p className="descriptionPost">
              {post.type === "event"
                ? `${post.eventTimestamp.split('-')[0]}-${post.eventTimestamp.split('-')[1]}-${post.eventTimestamp.split('-')[2]} 
                /${post.eventTimestamp.split('-')[3]}:${post.eventTimestamp.split('-')[4]}
                / ${post.nameReach || ""}`
                : post.type === "video"
                  ? `${post.infoPost.files.length} Videos / ${post.nameReach || ""}`
                  : post.type === "text" ||
                    post.type === "link" ||
                    post.type === "embed"
                    ? `Text Post / ${post.nameReach || ""}`
                    : post.infoPost.files ? `${post.infoPost.files.length} Photos / ${post.nameReach ||
                      ""}` : ''
              }
            </p>
          </div>
        </div>
        <div className="titlePost ">
          <FontAwesomeIcon
            icon="ellipsis-v"
            size="lg"
            color={LAVENDER}
            className="actionsPost dots  "
            onClick={() => toggleClick(post.id)}
          />

          <OutsideClickHandler
            onOutsideClick={() => {
              setSelectedItemState(false);
            }}
          >
            <div
              className={`${post.id === selectedItemId && selectedItemState ? "active" : ""
                } listOptionEvents`}
              key={post.id}
            >
              <div className="titleOptions">
                <FontAwesomeIcon
                  icon="arrow-left"
                  size="lg"
                  color={WHITE}
                  className="iconOptions  "
                />{" "}
                {t('options__whatWouldYou')}
              </div>
              <ul className="listOptionsPost" onClick={() => unsavePost(post.id)}>
                <li>
                  <FontAwesomeIcon
                    icon="folder-minus"
                    size="xl"
                    color={WHITE}
                    className="iconOptions  "
                    onClick={() => unsavePost(post.id)}
                  />
                  {t('options__unsave')}
                </li>
              </ul>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    )))
}

export default Index;
