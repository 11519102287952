import "./styles.css";
import {useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER } from "../ClubIconsNavBar";

function Index() {

    const array = [
        { id: 1, image: 'https://comodibujar.club/wp-content/uploads/2021/02/oso-perezso-kawaii.jpg' },
        { id: 2, image: 'https://comodibujar.club/wp-content/uploads/2021/02/oso-perezso-kawaii.jpg' },
        { id: 3, image: 'https://comodibujar.club/wp-content/uploads/2021/02/oso-perezso-kawaii.jpg' },
        { id: 4, image: 'https://comodibujar.club/wp-content/uploads/2021/02/oso-perezso-kawaii.jpg' },
        { id: 5, image: 'https://comodibujar.club/wp-content/uploads/2021/02/oso-perezso-kawaii.jpg' },
        { id: 6, image: 'https://comodibujar.club/wp-content/uploads/2021/02/oso-perezso-kawaii.jpg' },
        { id: 7, image: 'https://comodibujar.club/wp-content/uploads/2021/02/oso-perezso-kawaii.jpg' }
    ];

    const arrayLinks = [
        { id: 1, url: 'dogsarelife.co', image:"https://comodibujar.club/wp-content/uploads/2021/02/oso-perezso-kawaii.jpg", title: "4 things you should know about dogs" },
        { id: 2, url: 'dogsarelife.com', image:"https://comodibujar.club/wp-content/uploads/2021/02/oso-perezso-kawaii.jpg", title: "5 things you should know about dogs" },
    ];

    function createElements(number: any){

        var i = 0;

        var elements = [];
        for(i = 0; i < number; i++){
            elements.push(<div className="contentImage"><img src={array[i].image} alt="" /></div>);
        }
        return elements;

    }

    return (
        <div className="content-clubFlags">
            <label className="labelListEvents">4 Resources</label>
            <div className="listResources">
                <ul>
                    <li className="itemResources">
                        <FontAwesomeIcon
  icon="link"
  size='lg'
  color={LAVENDER}
  className="link listIcon  "
/>
                        <div className="resourcesDetails">
                            <p> Link resources</p>
                            <span>This is a link resources description</span>
                        </div> 
                    </li>
                    <li className="itemResources">
                        <FontAwesomeIcon
                            icon="file"
                            size='lg'
                            color={LAVENDER}
                            className="file listIcon  "
                        />
                        <div className="resourcesDetails">
                            <p> File resources</p>
                            <span>This is a file resources description</span>
                        </div> 
                    </li>
                    <li className="itemResources">
                        <FontAwesomeIcon
                            icon="link"
                            size='lg'
                            color={LAVENDER}
                            className="link listIcon  "
                        />
                        <div className="resourcesDetails">
                            <p> Link PDF resources</p>
                            <span>This is a link PDF resources description</span>
                        </div> 
                    </li>
                    <li className="itemResources">
                        <FontAwesomeIcon
  icon="file-pdf"
  size='lg'
  color={LAVENDER}
  className="file-pdf listIcon  "
/>
                        <div className="resourcesDetails">
                            <p> File PDF resources</p>
                            <span>This is a PDF resources description</span>
                        </div> 
                    </li>
                </ul>
                <label className="labelListEvents">Media</label>

                <div className={`cardResource ${array.length >= 5 ? 'imgPlus': ''}`}>
                    {createElements(5)}
                </div> 
                <label className="labelListEvents">Links</label>
                
                {arrayLinks.map((link: any) => (
                    <div className="cardLinks">
                        <img src={link.image} alt="" />
                        <div className="linkInfo">
                            <p>{link.title}</p>    
                            <a href={link.url}>{link.url}</a>
                        </div>
                    </div>
                ))}

                
            </div>
        </div>
    );
}

export default Index;