import "./styles.css";
import OrganizationsInfoBar from "../OrganizationsInfoBar"
import Club from "../Club"
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import OutsideClickHandler from 'react-outside-click-handler';
import { getUsersWithLimit } from "../../store/actions/userActions";
import { getClubs } from "../../store/actions/clubActions";
import { getOrganizationInfo, getStaffUsers } from "../../store/actions/orgsActions";
import { onlyZAAroles } from "../../helpers/roleHelper";
import ModalAddAdvisor from "../SetupSetup/ModalAddAdvisor";
import ClubInterface from "../../interfaces/ClubExpanded";
import { RootState } from "../../store/store";
import { createInvite } from "../../store/actions/inviteActions";
import { toast } from 'react-toastify';
import ModalInviteMembers from "../ClubMembers/ModalInviteMembers";
import ModalDeleteClub from "./ModalDeleteClub";
import ModalMembersRegistered from "../ClubMembers/ModalMembersRegistered";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
import { useTranslation } from "react-i18next";

interface FilterUsersAdminProps {
  clubType: string;
  searchString?: string;
}

const Index = ({ clubType, searchString }: FilterUsersAdminProps) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.getUser.token)

  const users = useSelector((state: any) => state.getUser.usersLimit || []);
  const clubs = useSelector((state: any) => state.getClub.getClubsOrg || []);
  const usersStatus = useSelector((state: any) => state.getUser.usersStatus);
  const organizationsAll = useSelector((state: any) => state.getOrgs.organizationNames);
  const userRoles = useSelector((state: any) => state.getUser.usersRoles);
  const activeCountries = useSelector((state: any) => state.getLocation.activeCountries || []);
  const activeCities = useSelector((state: any) => state.getLocation.activeCities || []);

  const types: { [key: string]: string } = {
    ALL: 'All',
    'ZAA#STUDENT': 'Student',
    'ZAA#STAFF': 'Staff',
    'ZAA#GUARDIAN': 'Guardian',
    'ZAA#ALUMNI': 'Alumni',
  };

  const typesOptions = Object.keys(types).map((key) => ({
    label: types[key],
    value: key,
  }));

  const memberTypes = [
    ['STUDENT', t('list__memberTypeStudent')],
    ['STAFF', t('list__memberTypeStaff')],
    ['GUARDIAN', t('list__memberTypeGuardian')],
    ['ALUMNI', t('list__memberTypeAlumni')]
  ];

  const rolesOptions = memberTypes.map((e: any) => ({
    label: e[1],
    value: encodeURIComponent(e[0]),
  }));

  rolesOptions.unshift({ label: t('list__all'), value: "" });

  const statusOptions = usersStatus.map((e: any) => ({
    label: e,
    value: e,
  }));

  statusOptions.unshift({ label: "All", value: "" });

  const countryOptions = activeCountries.map((e: any) => {
    return {
      label: e.name,
      value: e.name,
    }
  })

  countryOptions.unshift({ label: "All", value: "" });

  const cityOptions = activeCities.map((e: any) => {
    return {
      label: e.name,
      value: e.name,
    }
  })

  cityOptions.unshift({ label: "All", value: "" });

  const [selected, setSelected] = useState("");
  const [input, setInput] = useState(false);
  const [outside, setOutside] = useState(false);
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const user = useSelector((state: any) => state.getUser.user);

  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  )

  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [memberType, setMemberType] = useState('')
  const [addedOn, setAddedOn] = useState('')

  const [focusName, setFocusName] = useState(false);
  const [focusType, setFocusType] = useState(false);
  const [focusMemberType, setFocusMemberType] = useState(false);
  const [focusAddedOn, setFocusAddedOn] = useState(false);

  useEffect(() => {
    if ('id' in organization) {
      dispatch(getClubs({
        pending: organization.status.startsWith('PEND'),
        org_id: organization.id,
        club_type: clubType,
        name: name,
        type: type,
        member_roles: memberType,
        youthOrg: (organization.type ==='YOUTHORG') ? "true": "false"
      }))
    }
  }, [organization, clubType, name, type, memberType, /*addedOn*/])

  useEffect(() => {
    const orgId = JSON.parse(userInfo.attributes['custom:organizations'])[0];
    dispatch(getOrganizationInfo(orgId));
  }, []);


  const handleChange = (e: any) => {
    setSelected(e.label);
    console.log(e.label);
  };

  const onInput = (e: any) => {
    setInput(true);
    setOutside(false);
  };

  const outsideClick = (e: any) => {
    // outside ? e.label = "Search by Name" : <></>
  };

  const customStyles = (value: any, input: any, outside: any) => ({
    option: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#383838',
    }),
    input: (styles: any, state: any) => ({
      ...styles,
      border: 'none',
      color: 'white',
      fontWeight: "normal",
      paddingLeft: "5px",
      "&:focus": { backgroundColor: 'red' },
    }),
    container: (styles: any) => ({
      ...styles,
      padding: 0,
    }),
    valueContainer: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: value && value != 'All' ? '#383838' : 'transparent',
      // display: value && !outside ? 'flex' : 'none',
      padding: 0,
    }),
    menu: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      backgroundColor: "#383838",
      width: "fit-content",
      minWidth: "150px",
      textAlign: "left",
      zIndex: "1",
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      textAlign: "left",
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "50px",
      borderRadius: "6px",
      boxShadow: "none",
      border: 0,
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "bold",
      maxWidth: "200px",
      marginBottom: "5px",
      backgroundColor: input ? '#383838' : 'transparent',
      // textDecoration: outside ? 'underline' : 'none',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: 'white',
      display: input ? 'none' : 'block',
    }),
    indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
    dropdownIndicator: (styles: any) => ({ ...styles, display: "none" }),
    noOptionsMessage: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#383838',
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "normal",
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#383838',
      border: "none",
      paddingLeft: "7px",
      paddingRight: "7px",
      paddingBottom: "7px",
      paddingTop: "7px",
      borderRadius: "4px",
      fontSize: "13px",
      width: "125px",

      // display: input ? 'none' : 'block',

    }),
  })

  const handleChangeName = (e: any) => {
    // console.log(123);
    
    // setName(e);
  }

  const handleKeyPress = (event: any) => {
    if(event.key === 'Enter'){
      setName(event.target.value);
    }
  }

  const handleChangeType = (e: any) => {
    setType((e.value === 'ALL') ? '' : e.value);
    setFocusType(e.value !== 'ALL')
  }

  const handleChangeMemberType = (e: any) => {
    setMemberType((e.value === 'ALL') ? '' : e.value);
    setFocusMemberType(e.value !== 'ALL')
  }

  const handleChangeAddedOn = (e: any) => {
    setAddedOn(e);
    setFocusAddedOn(e.value !== 'ALL')
  }

  //Management of clubs
  const staffUsers = useSelector((state: RootState) => state.getOrgs.staffUsers)
  const [showAdvisors, setShowAdvisors] = useState<boolean>(false)
  const [loadingAdvisor, setLoadingAdvisor] = useState<boolean>(false)

  const [showMembers, setShowMembers] = useState<boolean>(false)

  const [showDelete, setShowDelete] = useState<boolean>(false)

  const [clubToManage, setClubToManage] = useState<ClubInterface>()
  const onHideAdvisors = (
    e?: any,
    step?: any,
    id?: any, //NO
    type?: any, //NO
    firstName?: string,
    lastName?: string,
    email?: string,
    sendEmail?: boolean
  ) => {
    if (step === 'btnSave') {
      if (firstName && lastName && email) {
        setLoadingAdvisor(true)
        dispatch<any>(createInvite({
          email,
          org_id: organization!.id,
          club_id: clubToManage!.id,
          is_advisor: true,
          firstName,
          lastName,
        }))
          .then((results: any) => {
            if (results.message === 'Invite already exists') {
              toast.error(`${t('toast_thisisAlready')} ${clubToManage?.name}`)
            } else {
              toast.success(t('toast_invitationCreated'))
            }
            setShowAdvisors(false);
            setLoadingAdvisor(false);
          })
      }
    } else {
      setShowAdvisors(false)
    }
  }

  const manageClub = (type: 'Advisors' | 'Members' | 'Delete', club: ClubInterface) => {
    setClubToManage(club)
    switch (type) {
      case 'Advisors':
        setShowAdvisors(true)
        break
      case 'Members':
        setShowMembers(true)
        break
      case 'Delete':
        setShowDelete(true)
        break
      default:
        break
    }
  }

  return (

    <>
      <table className="tableManageClubs">
        <tr>
          {/* <th className="checklist">
            <div className="id">
              <div className="clubs-check">
                <label className="content-input-white">
                  <input type="checkbox" name="button" value="Active" />
                  <i></i>
                </label>
              </div>
            </div>
          </th>
          <th className="nameTable">
            <div>
              <input
                className={`${focusName ? 'filterIsFocused' : 'inputFilter'} `}
                type="text"
                placeholder="Name"
                onChange={(e) => handleChangeName(e.target.value)}
              />
            </div>
          </th> */}
          {/* {(clubType === 'COMM') ? (
            <th>
              <div className={`roles ${focusType ? 'active' : ''}`}>
                <label className="labelType">{focusType}</label>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setOutside(true);
                    setInput(false);
                    outsideClick(outside);
                  }}
                >
                  <Select
                    isMulti={false}
                    options={typesOptions}
                    placeholder={"Type"}
                    styles={customStyles(selected, input, outside)}
                    onChange={handleChangeType}
                    onFocus={onInput}
                  />
                </OutsideClickHandler>
                <FontAwesomeIcon
  icon="sort-amount-down"
  size='lg'
  color={LAVENDER}
  className="sort-amount-down  "
/>
              </div>
            </th>
          ) :
            (
              <th>
                <div className="join robotocondensed-bold-white-16px">Type</div>
              </th>
            )} */}
          {/* <th>
            <div className={`city ${focusMemberType ? 'active' : ''}`}>
              <label className="labelType">{focusMemberType}</label>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setOutside(true);
                  setInput(false);
                  outsideClick(outside);
                }}
              >
                <Select
                  isMulti={false}
                  options={rolesOptions}
                  placeholder={"Membership"}
                  styles={customStyles(selected, input, outside)}
                  onChange={handleChangeMemberType}
                  onFocus={onInput}
                />
              </OutsideClickHandler>
              <FontAwesomeIcon
  icon="sort-amount-down"
  size='lg'
  color={LAVENDER}
  className="sort-amount-down  "
/>
            </div>
          </th>
          <th>
            <div className={`country roboto-bold-white-16px ${focusAddedOn ? 'active' : ''}`}>
              <label className="labelType">{focusAddedOn}</label>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setOutside(true);
                  setInput(false);
                  outsideClick(outside);
                }}
              >
                <Select
                  isMulti={false}
                  options={countryOptions}
                  placeholder={"Date Added"}
                  styles={customStyles(selected, input, outside)}
                  onChange={handleChangeAddedOn}
                  onFocus={onInput}
                />
              </OutsideClickHandler>
              <FontAwesomeIcon
  icon="sort-amount-down"
  size='lg'
  color={LAVENDER}
  className="sort-amount-down  "
/>
            </div>
          </th>
          <th>
            <div className="join roboto-bold-white-16px">Last Post</div>
          </th>
          <th>
            <div className="join roboto-bold-white-16px">Setup</div>
          </th>
          <th>
            <div className="join robotocondensed-bold-white-16px">Members</div>
          </th>
          <th>
            <div className="join robotocondensed-bold-white-16px">Posts</div>
          </th>
          <th>
            <div className="join robotocondensed-bold-white-16px">Events</div>
          </th>
          <th>
            <div className="join robotocondensed-bold-white-16px">Open invites</div>
          </th>
          <th>
            <div className="actions robotocondensed-bold-white-16px"></div>
          </th> */}
          {/*<th>
            <div className="clubs-check">
              <label className="content-input-white">
                <input type="checkbox" name="button" value="Active" />
                <i></i>
              </label>
            </div>
        </th>*/}
          <th>
            <div>
              <input
                className={`${focusName ? 'filterIsFocused' : 'inputFilter'} `}
                type="text"
                placeholder={t('comm__name')}
                onChange={(e) => handleChangeName(e.target.value)}
                onKeyPress={(e) => handleKeyPress(e)}
              />
            </div>
          </th>
          <th>
            <div className={`w-iconFilter ${focusMemberType ? 'active' : ''}`}>
              <label className="labelType">{focusMemberType}</label>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setOutside(true);
                  setInput(false);
                  outsideClick(outside);
                }}
              >
                <Select
                  isMulti={false}
                  options={rolesOptions}
                  placeholder={t('comm__membership')}
                  styles={customStyles(selected, input, outside)}
                  onChange={handleChangeMemberType}
                  onFocus={onInput}
                />
              </OutsideClickHandler>
              <FontAwesomeIcon
                icon="sort-amount-down"
                size='lg'
                color={LAVENDER}
                className="sort-amount-down  "
              />
            </div>
          </th>

          {/*<th>
            <div className={`w-iconFilter roboto-bold-white-16px ${focusAddedOn ? 'active' : ''}`}>
              <label className="labelType">{focusAddedOn}</label>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setOutside(true);
                  setInput(false);
                  outsideClick(outside);
                }}
              >
                <Select
                  isMulti={false}
                  options={countryOptions}
                  placeholder={"Date Added"}
                  styles={customStyles(selected, input, outside)}
                  onChange={handleChangeAddedOn}
                  onFocus={onInput}
                />
              </OutsideClickHandler>
              <FontAwesomeIcon
  icon="sort-amount-down"
  size='lg'
  color={LAVENDER}
  className="sort-amount-down  "
/>
            </div>
          </th>*/}

          <th>
            <div className="roboto-bold-white-16px">{t('comm__dateAdded')}</div>
          </th>

          <th>
            <div className="roboto-bold-white-16px">{t('comm__lastPost')}</div>
          </th>
          <th>
            <div className="roboto-bold-white-16px">{t('comm__setup')}</div>
          </th>
          <th>
            <div className="robotocondensed-bold-white-16px">{t('comm__members')}</div>
          </th>
          <th>
            <div className="robotocondensed-bold-white-16px">{t('comm__posts')}</div>
          </th>
          <th>
            <div className="robotocondensed-bold-white-16px">{t('comm__events')}</div>
          </th>
          <th>
            <div className="robotocondensed-bold-white-16px">{t('comm__openInvites')}</div>
          </th>
          <th>
            <div className="robotocondensed-bold-white-16px"></div>
          </th>
        </tr>
        {clubs.filter((club: any) => club.name.toLowerCase().trim().includes((searchString || '').toLowerCase().trim()))
          .map((club: any) => (
            <Club club={club} manageClub={manageClub} />
          ))}
      </table>

      <ModalAddAdvisor
        show={showAdvisors}
        onHide={onHideAdvisors}
        staffUsers={staffUsers}
        clubName={clubToManage?.name || ''}
        loadingAdvisor={loadingAdvisor}
      />

      {clubType === 'COMM' ? (
        <ModalInviteMembers
          show={showMembers}
          onHide={() => setShowMembers(false)}
          club_id={clubToManage?.id} />
      ) : (
        <ModalMembersRegistered
          show={showMembers}
          onHide={() => setShowMembers(false)}
          club_id={clubToManage?.id} />
      )}

      <ModalDeleteClub
        show={showDelete}
        club={clubToManage}
        setShow={setShowDelete} />
    </>
  )
};

export default Index;
