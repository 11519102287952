/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';
import Header from './Header';
import '../../containers/SetupStudent/styles.scoped.css';
import { useDispatch, useSelector } from 'react-redux';
import ModalClub from './ModalClub';
import UnregisteredUser from '../../interfaces/user/UnregisteredUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LAVENDER } from '../ClubIconsNavBar';
import { useTranslation } from "react-i18next";
import { getUser } from '../../store/actions/userActions';
import useGrades from '../../hooks/useGrades';

function Index({
  handleOnClick,
  myInterests,
  followingClubs,
  joinedClubs,
  friendRequest,
  unregRequests,
}: {
  handleOnClick: (e: any, step: number) => void;
  myInterests: any[];
  followingClubs: any[];
  joinedClubs: any[];
  friendRequest: any[];
  unregRequests: UnregisteredUser[]
}) {
  const { t, i18n } = useTranslation();
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const user = useSelector((state: any) => state.getUser.user);

  const [showSetupClub, setShowSetupClub] = useState(false);
  const [tabActive, setTabActive] = useState(0);
  const [typeClub, setTypeClub] = useState<any>('');
  const [clickedClub, setClickedClub] = useState({});

  const { grades, setCountry: setCountryGrades } = useGrades();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch<any>(getUser(userInfo.username));
  }, []);

  function handleCloseSetupClub() {
    setShowSetupClub(false);
  }

  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  );

  useEffect(() => console.log('user review', user), [user])
  useEffect(() => console.log('grades review', grades), [grades])
  useEffect(() => {
    if (organization && organization?.countryCode) {
      console.log('grade organization?.countryCode', organization?.countryCode)
      setCountryGrades(organization?.countryCode);
    }
    console.log('organization review', organization);

  }, [organization])

  const gradeLabel = useMemo(() => grades.find((grade) => grade.id === user?.grade)?.communityLabel?.replace('{{grad_year}}', user.classYear) ?? user?.grade, [grades, user])

  function clubState(c: any) {
    const club = joinedClubs.find((item: any) => item.club_id === c.club_id)
    const team = followingClubs.find((item: any) => item.club_id === c.club_id)
    if (club !== undefined) {
      setTypeClub(club.club_type === 'TEAM' ? 'Tryout requested' : 'Join requested')
    } else if (team !== undefined) {
      setTypeClub(team.club_type === 'TEAM' ? 'Fan' : 'Following')
    } else {
      setTypeClub(team.club_type)
    }
  }

  return (
    <div className="containerPhotoSetup">
      <Header />
      <div className="container-review">
        <div className="reviewProfile">
          <div className="titleReview">
            <h2>{t('setupStudent__profile')}</h2>
            <button
              className="buttonPen"
              onClick={(e) => handleOnClick(e, 1)}
              type="button"
            >
              <FontAwesomeIcon
                className='pen'
                icon="pen"
                size='lg'
                color={LAVENDER}
              />
            </button>
          </div>
          <div className="infoProfile">
            <img
              src={user.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"}
              alt=""
            />
            <div>
              <h2>{`${user.firstname || ''} ${user.lastname || ''}`}</h2>
              <p>{`${user?.organizationsNames?.[0] || ''}`}</p>
              <span key={JSON.stringify(grades)}>{gradeLabel}</span>
            </div>
          </div>
        </div>
        <div className="reviewInterests">
          <div className="titleReview">
            <h2>{t('setupStudent__interests')}</h2>
            <button
              className="buttonPen"
              onClick={(e) => handleOnClick(e, 2)}
              type="button"
            >
              <FontAwesomeIcon
                className='pen'
                icon="pen"
                size='lg'
                color={LAVENDER}
              />
            </button>
          </div>
          <div className="interestsSelected">
            {myInterests.map((i) => (<p>{i}</p>))}
          </div>
        </div>
        <div className="reviewClubAndTeams">
          <div className="titleReview">
            <h2>{t('setupStudent__myClubsAndTeams')}</h2>
            <button
              className="buttonAddMore"
              onClick={(e) => handleOnClick(e, 3)}
              type="button"
            >
              {t('setupStudent__addMore')}
            </button>
          </div>
          <div className="ClubAndTeamsSelected">
            <div className="listClubsSelected">
              <ModalClub
                show={showSetupClub}
                onHide={() => handleCloseSetupClub()}
                club={clickedClub}
                tabActive={tabActive}
                setTabActive={setTabActive}
                typeClub={typeClub}
              />
              {[...followingClubs, ...joinedClubs].map((c) => (
                <div className="ClubReview">
                  {(c.cover || organization.logo) ?
                    <img
                      src={c.cover || organization.logo}
                      alt=""
                    />
                    :
                    <div className="noImageCover"></div>
                  }
                  <div>
                    <p>{c.name}</p>
                    <button className="buttonEye" type="button" onClick={() => {/*Finish setup */ }}>
                      <FontAwesomeIcon
                        icon={'eye'}
                        color={LAVENDER}
                        size='lg'
                        className='eye'
                        onClick={() => {
                          setClickedClub(c)
                          setShowSetupClub(true)
                          clubState(c)
                        }}
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="reviewMyConnections">
          <div className="titleReview">
            <h2>{t('setupStudent__myConnections')}</h2>
            <button
              className="buttonAddMore"
              onClick={(e) => handleOnClick(e, 4)}
              type="button"
            >
              {t('setupStudent__addMore')}
            </button>
          </div>
          <div className="ClubAndTeamsSelected">
            <div className="myconnections">
              {friendRequest.map((member) => (
                <div className="FriendReview" key={member.id}>
                  <div className="imageProfile">
                    <img src={member.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" />
                  </div>
                  <p>{member.name} </p>
                </div>
              ))}
              {unregRequests.map((member) => (
                <div className="FriendReview" key={member.email}>
                  <div className="imageProfile">
                    <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" alt="" />
                  </div>
                  <p>{member.fullname} </p>
                  <p> {t('setupStudent__unregisteredUser')}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
