import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { WHITE } from '../../../../components/ClubIconsNavBar';
import ModalDiscardChanges from '../../../../components/SetupSetup/ModalDiscardChanges';
import { useTranslation } from 'react-i18next';
import TitleEdit from '../../../../components/TitleEdit';
import { Backend, MicroServices } from '../../../../helpers/backendHelper';
import { toast } from 'react-toastify';
import ModalBaseProps from '../../../../interfaces/modals/ModalBaseProps';
import { CancelButton, ClickableIcon, SaveButton } from '../../../../components/SuperAdminComponents/basicForSACenter';
import INTList from '../../../../interfaces/Interests/INTLists/INTList';
import INTAssignedComplete from '../../../../interfaces/Interests/INTLists/INTAssignedComplete';

interface AssignModalProps extends ModalBaseProps {
    interestSelected?: INTAssignedComplete,
    intlist?: INTList;
}

const AssignModal = ({
    show,
    interestSelected,
    intlist,
    onHide,
}: AssignModalProps) => {
    const { t } = useTranslation();

    const [showDiscard, setShowDiscard] = useState(false);
    const [loadingAssign, setLoadingAssign] = useState(false);
    const [interestName, setInterestName] = useState<string>();

    useEffect(() => {
        setInterestName(interestSelected?.name)
    }, [interestSelected, show])

    const onCancel = () => setShowDiscard(true);

    const onHideDiscard = (type: string) => {
        setShowDiscard(false);
        if (type === 'discard') {
            onHide();
            setInterestName(undefined);
        }
    };

    const onSaveAssign = () => {
        setLoadingAssign(true);
        Backend(
            MicroServices.Connections,
            `/interests/intlists/${intlist?.id}/assign-int`,
            {
                method: 'POST',
                body: JSON.stringify({
                    intId: interestSelected?.id,
                    ...(interestName !== interestSelected?.name ? {assignedName: interestName}: {})
                })
            }
        )
            .then((response) => {
                if (response.status === 201) {
                    toast.success(t('toast_interestAssigned'));
                    onHide();
                    setInterestName(undefined);
                    return;
                }
            })
            .catch((error) => toast.error(error))
            .finally(() => setLoadingAssign(false));
    };

    return (
        <>
            <Modal className="modalInviteMemebers" show={show} onHide={onCancel}>
                <Modal.Header>
                    <Modal.Title>
                        Assign Interest to {intlist?.name}
                    </Modal.Title>
                    <ClickableIcon
                        icon={faTimes}
                        size='xl'
                        color={WHITE}
                        className="icon-times"
                        onClick={onCancel}
                    />
                </Modal.Header>
                <Modal.Body>
                    {interestName && <TitleEdit title={interestName} setTitle={setInterestName} />}
                </Modal.Body>
                <Modal.Footer className='modalDiscardChanges-footer'>
                    <CancelButton onClick={onCancel}>
                        {t('button__cancel')}
                    </CancelButton>
                    <SaveButton onClick={onSaveAssign}>
                        {loadingAssign
                            ? (
                                <div className="icon-spinner-third buttonSendSignUp"></div>
                            )
                            : t('button__save')
                        }
                    </SaveButton>
                </Modal.Footer>
            </Modal>
            <ModalDiscardChanges
                show={showDiscard}
                onHide={onHideDiscard}
            />
        </>
    );
};

export default AssignModal;