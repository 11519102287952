import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AdminBirthdayInput, AdminGenderInput, AdminPasswordInput, AdminSelectionInput, AdminTextInput } from '../../components/AdminInputs';
import TwoColumns from '../../components/TwoColumns';
import { getCommunities } from '../../store/actions/clubActions';
import { RootState } from '../../store/store';
import AdminTitle from '../../styledComponents/AdminTitle';
import { useCreateUserForm } from './hooks';
import './styles.scoped.css';

const AdminCreateUser = () => {

  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate()

  // Selectors
  const communitiesOptions = useSelector((state: RootState) =>
    [
      {
        key: '',
        label: 'Select community',
        disabled: true,
      },
      ...(state.getClub.GetCommunities || []).map((comm) => ({
        key: comm.id as string,
        label: comm.name as string,
      }))
    ]
  );
  const organization = useSelector(
    (state: RootState) => state.getOrgs.organizationInfo
  );

  // Custom Hooks
  const {
    form,
    showErrors,
    setFormValue,
    setFormValueString,
    getError,
    submitForm,
    regeneratePassword,
  } = useCreateUserForm(organization.id, organization.name, () => navigate(-1));

  // Effects
  useEffect(() => {
    dispatch(getCommunities(organization.id, organization.status.startsWith('PE')));
  }, []);
  useEffect(() => {
    console.log('communities', communitiesOptions)
  }, [communitiesOptions]);

  return (
    <div className="content">
      <div className="CreateUser__header__wrapper">
        <AdminTitle>Create User</AdminTitle>
      </div>
      <div className="CreateUser__form__wrapper">
        <TwoColumns
          left={<>
            <AdminTextInput
              label='First name'
              placeholder=''
              onChange={(e) => setFormValue('givenName', e)}
              value={form.givenName}
              showErrors={showErrors}
              error={() => getError('givenName')}
            />
            <AdminTextInput
              label='Last name'
              placeholder=''
              onChange={(e) => setFormValue('familyName', e)}
              value={form.familyName}
              showErrors={showErrors}
              error={() => getError('familyName')}
            />
            <AdminGenderInput
              onChange={(e) => setFormValue('gender', e)}
              value={form.gender}
              showErrors={showErrors}
              error={() => getError('gender')}
            />
            <AdminBirthdayInput
              value={form.birthdate}
              onChange={(date) => setFormValueString('birthdate', date)}
            />
          </>}
          right={<>
            <AdminSelectionInput
              label='Community'
              value={form.community}
              onChange={(e) => setFormValue('community', e)}
              options={communitiesOptions}
              showErrors={showErrors}
              error={() => getError('community')}
            />
            <AdminTextInput
              label='Email'
              placeholder=''
              onChange={(e) => setFormValue('email', e)}
              value={form.email}
              showErrors={showErrors}
              error={() => getError('email')}
            />
            <AdminTextInput
              label='Username'
              placeholder=''
              onChange={(e) => setFormValue('userName', e)}
              value={form.userName}
              showErrors={showErrors}
              error={() => getError('userName')}
            />
            <AdminPasswordInput
              value={form.password}
              regenerate={regeneratePassword}
            />
            <div className="CreateUser__submit__wrapper">
              <button
                className='CreateUser__submit roboto-normal-white-16px'
                onClick={submitForm}
              >
                Create User
              </button>
            </div>
          </>}
        />
      </div>
    </div>
  );
};

export default AdminCreateUser;
