import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { customStylesRSLeft, DropdownIndicator } from "../../../../helpers/selectHelper";
import useIntListsSA from "../../../../hooks/Interests/useIntListsSA";
import INTSelect from "../../../../interfaces/Interests/INTLists/INTSelect";

const ALL_LISTS = {
    label: 'All Interests',
    value: 'ALL',
}

const BASE_LIST = [
    ALL_LISTS,
    {
        label: 'Create New List',
        value: 'CREATE',
    }
]

interface IntListDropdownProps {
    setSelected: (id: string) => void;
    reload: boolean;
    onLoaded: () => void;
}

const IntListDropdown = ({
    setSelected,
    reload,
    onLoaded,
}: IntListDropdownProps) => {
    const {intlists, reload: reloadTCList} = useIntListsSA();

    const intOptions: INTSelect[] = useMemo(() => [
        ...BASE_LIST,
        ...intlists.map((list) => ({
            label: list.name,
            value: list.id,
        }))
    ], [intlists])

    const [tclistSelected, setTclistSelected] = useState<INTSelect>(ALL_LISTS);

    useEffect(() => {
        if (reload) {
            reloadTCList();
            setTclistSelected(ALL_LISTS);
            setSelected(ALL_LISTS.value);
            onLoaded();
        }
    }, [reload]);

    const handleSetTCList = (selected: any) => {
        setTclistSelected(selected);
        setSelected(selected.value);
    }

    return (
        <Select
            options={intOptions}
            styles={customStylesRSLeft()}
            components={{
                DropdownIndicator
            }}
            value={tclistSelected}
            onChange={handleSetTCList}
            placeholder="Select a list"
        />
    )
};

export default IntListDropdown;