import React, {useState, useEffect} from 'react';
import "./styles.css";
import "./styles.scoped.css";
import ClubInfoCarousel from "../../components/ClubInfoCarousel";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ClubEditInformation from '../ClubEditInformation';
import { Advisor, Officer } from '../../containers/Club';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from "../../components/ClubIconsNavBar";
import { onlyFirstLetterCapitalized } from '../../helpers/letterHelper';
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from '../../helpers/backendHelper';

function Index(props: any) {
  const { t, i18n } = useTranslation();

  const { id } = useParams();
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const [editClubStatus, setEditClubStatus] = useState(false);

  function handleditClub() {
    setEditClubStatus(true)
  }

  const goToInformation = () => {
    setEditClubStatus(false);
    props.reloadClub();
  }

  return (
    <>
      {editClubStatus ?
        <ClubEditInformation
          club={props.infoClub}
          officers={props.officers}
          advisors={props.advisors}
          members={props.members}
          goToInformation={goToInformation}
          reloadOfficers={props.reloadOfficers}
          reloadAdvisors={props.reloadAdvisors}
        />
        :
        <div className="club-view-information">
          <div className="information-title-row">
            <h1>{t('club__information')}</h1>

            {(props.isClubAdvisor) &&
            (<div className="edit-info-button" onClick={() => handleditClub()}>
              <FontAwesomeIcon
                icon="pen"
                size='lg'
                color={WHITE}
                className=" "
              />
              <div className="club-view-edit-text">{t('club__editInformation')}</div>
            </div>
            )}

          </div>
          <p
            dangerouslySetInnerHTML={{ __html: props.infoClub?.description || 'No description' }}
            className="robotocondensed-normal-white-16px">
          </p>
          <div className="key-words-row">
            {((props.infoClub?.skills_learned || []) as string[]).map((skill) => (
              <div className="key-word robotocondensed-normal-lavender-16px">
                {skill}
              </div>
            ))}
          </div>

          <ClubInfoCarousel informationClub={props.infoClub} />

          <div className="information-title-row">
            <div id="officers-row">
              <h1>{t('club__officers')} ({((props.officers || []) as Officer[]).length})</h1>
            </div>
            {/*<div
              className="robotocondensed-bold-lavender-16px"
              id="view-all-officers"
            >
              View all
            </div>*/}
          </div>
          <div className="officers">
            {((props.officers || []) as Officer[]).map((officer) => (
              <div className="officers-row">
                <img
                  className="officer-avatar"
                  src={officer.avatar || `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png`}
                  alt=""
                />
                <div className="officer-info">
                  <div className="officer-name robotocondensed-normal-lavender-16px">
                    {officer.position}
                  </div>
                  <div className="officer-role robotocondensed-normal-white-16px">
                    {officer.fullname}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="information-title-row">
            <div id="officers-row">
              <h1>{t('club__advisors')} ({(props.advisors || []).length})</h1>
            </div>
            <div
              className="robotocondensed-bold-lavender-16px"
              id="view-all-officers"
            >
              {/*listAdvisor.length > 0 &&
                'View all'
              */}
            </div>
          </div>
          <div className="officers">
          {props.advisors.map((advisor: any) => (
            
            <div className="officers-row">
              <img
                className="officer-avatar"
                src={advisor.avatar || `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png`}
                alt="advisor"
              />
              <div className="officer-info">
                <div className="officer-name robotocondensed-normal-lavender-16px">
                  {advisor.fullname || ''}
                </div>
                <div className="officer-role robotocondensed-normal-white-16px">
                  {advisor.userType ? onlyFirstLetterCapitalized(advisor.userType) : (advisor.unregistered ? t('label_unregistered') : '')}
                </div>
              </div>
              {/*<img src={"/ellipsis-v-alt.png"} alt="elipsis" />*/}
            </div>
          ))}
          </div>
        </div>
      }

    </>

  );
}

export default Index;
