import React from "react";
import "../../containers/SetupSchool/styles.scoped.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { castTemplateIdToTemplateName } from "./Teams";

interface SetupTeamsCategoriesProps {
  selectedList: string[];
  activeTeam: number;
  activateTeam: (p: number) => void;
  setCreateCustom: any,
}

const SetupTeamsCategories = ({
  selectedList,
  activeTeam,
  activateTeam,
  setCreateCustom,
}: SetupTeamsCategoriesProps) => {

  const tteams = useSelector((state: RootState) => state.getTClubs.TTeam);

  return (
    <div className="listCategories">
      <ul>
        {selectedList.map((itm, idx) => (
          <li
            className={`${activeTeam == idx ? "active" : ""}`}
            onClick={() => activateTeam(idx)}
            title={castTemplateIdToTemplateName(itm, tteams)}
          >
            {castTemplateIdToTemplateName(itm, tteams)}
            <FontAwesomeIcon
              icon="chevron-right"
              size='xl'
              color={WHITE}
              className="icon-chevron-right  "
            />
          </li>
        ))}
        <div className="custom-club-button" onClick={() => setCreateCustom(true)}>
          <div className="custom-club-text">Custom Team</div>
          <FontAwesomeIcon
            icon="plus"
            size='lg'
            color={WHITE}
            className=" "
          />
        </div>
      </ul>
    </div>
  );
}

export default SetupTeamsCategories;
