import { Backend, MicroServices } from "../helpers/backendHelper"
import { IOrganization, ISignUpOrganizationRequest, IUser } from "../interfaces/Signup"

const isGender = (allegedGender: any): boolean => {
    const genders = [
        "M",
        "F",
        "O",
        "P"
    ]
    return (
        typeof allegedGender === "string" &&
        genders.includes(allegedGender)
    )
}

const isRole = (allegedRole: any): boolean => {
    const roles = [
        "AHA#APPROVER",
        "AHA#CHILDWALLET",
        "AHA#SUPER",
        "AHA#TREASURY",
        "SPONSOR#ASSOCIATE",
        "SPONSOR#ORGADMIN",
        "SPONSOR#SUPER",
        "Z#ALUMNI",
        "Z#GUARDIAN",
        "Z#ORGADMIN",
        "Z#PENDING",
        "Z#SECONDARYADVISOR",
        "Z#STAFF",
        "Z#STAFFADVISOR",
        "Z#STUDENT",
        "Z#SUPER",
        "Z#UNDEF"
    ]
    return (
        typeof allegedRole === "string" &&
        roles.includes(allegedRole)
    )
}

export const isEmail = (allegedEmail: any): boolean => {
    const re = /^\S+@\S+$/
    return (
        typeof allegedEmail === "string" &&
        re.test(allegedEmail)
    );
}

export const isZipCode = (allegedZipCode: any, minLength = 5, maxLength = 5): boolean => {
    return (
        typeof allegedZipCode === "string" &&
        (allegedZipCode.length >= minLength &&
        allegedZipCode.length <= maxLength)
    )
}

const isBirthdate = (allegedBirthdate: any): boolean => {
    if (!allegedBirthdate) {
        return true
    }
    if (typeof allegedBirthdate === "string") {
        try {
            const allegedBirthdateSplit = allegedBirthdate.split("-")
            const year = parseInt(allegedBirthdateSplit[0]);
            const month = parseInt(allegedBirthdateSplit[1]);
            const day = parseInt(allegedBirthdateSplit[2]);


            if (isNaN(year) || isNaN(month) || isNaN(day)) {
                return false
            }

            if (year < 1900 || year > (new Date()).getFullYear()) {
                return false
            }

            if (month < 1 || month > 12) {
                return false
            }

            if (day < 1 || day > 31) {
                return false
            }
        } catch (error) {
            return false
        }

    } else {
        return false
    }
    return true
}

const isArrayOfType = (allegedArrayOfType: any, type: string): boolean => {
    let isArrayOfType = true;
    if (!Array.isArray(allegedArrayOfType)) return false
    allegedArrayOfType.forEach((value) => {
        if (typeof value !== type) {
            isArrayOfType = false
        }
    })
    return isArrayOfType;
}

const isOrganizationsAndRoles = (allegedOrganizations: any, allegedRoles: any): boolean => {
    if (!Array.isArray(allegedOrganizations) || !Array.isArray(allegedRoles)) return false
    if (allegedOrganizations.length !== allegedRoles.length) {
        return false
    }
    let isAllegedRolesValid = true;
    allegedRoles.forEach((value: any) => {
        if (!Array.isArray(value)) isAllegedRolesValid = false
        value.forEach((element: any) => {
            if (!isRole(element)) isAllegedRolesValid = false
        })
    })

    if (!isAllegedRolesValid) return false

    return isArrayOfType(allegedOrganizations, "string");
}

export const isValidHttpUrl = (string: any): boolean => {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

export const isGrade = (allegedGrade: any): boolean => {
    return typeof allegedGrade === "number" && allegedGrade> 0 && allegedGrade < 15
}

export const isOrganizationType = (allegedOrganizationType: any): Promise<boolean> => 
    new Promise((resolve,reject) => {
    Backend(
        MicroServices.Roster,
        `/organizations/types?title=false`)                
    .then((response) => response.json())
    .then((res)=> {
        const organizationTypes:string[] = res.orgStatus
        resolve (
            typeof allegedOrganizationType === "string" &&
            organizationTypes.includes(allegedOrganizationType)
        )
    
    })
})

export const isUser = (allegedUser: any): allegedUser is IUser => {
    return (
        "givenName" in allegedUser && typeof allegedUser.givenName === "string" &&
        "familyName" in allegedUser && typeof allegedUser.familyName === "string" &&
        "gender" in allegedUser && isGender(allegedUser.gender) &&
        "email" in allegedUser && isEmail(allegedUser.email) &&
        "userName" in allegedUser && typeof allegedUser.userName === "string" &&
        "zipCode" in allegedUser && isZipCode(allegedUser.zipCode) &&
        "birthdate" in allegedUser && isBirthdate(allegedUser.birthdate) &&
        "organizations" in allegedUser && "roles" in allegedUser && isOrganizationsAndRoles(allegedUser.organizations, allegedUser.roles) &&
        "password" in allegedUser && typeof allegedUser.password === "string"
    )
}


export const isOrganization = (allegedOrganization: any): Promise<boolean> => new Promise(async (resolve,reject) =>{
    resolve (
        "organizationName" in allegedOrganization && typeof allegedOrganization.organizationName === "string" &&
        "organizationType" in allegedOrganization && await isOrganizationType(allegedOrganization.organizationType) &&
        "website" in allegedOrganization && isValidHttpUrl(allegedOrganization.website) &&
        "lowGrade" in allegedOrganization && isGrade(allegedOrganization.lowGrade) &&
        "highGrade" in allegedOrganization && isGrade(allegedOrganization.highGrade) &&
        "zipCode" in allegedOrganization && isZipCode(allegedOrganization.zipCode)
    )
})

export const isSignUpOrganizationRequest = (body: any): Promise<boolean> => new Promise(async (resolve,reject) =>
{
    resolve (
        "organization" in body && await isOrganization(body.organization) &&
        "user" in body && isUser(body.user) &&
        body.user.organizations.includes(body.organization.organizationName) &&
        body.user.zipCode === body.organization.zipCode
    )
})