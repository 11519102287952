import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import LoginBtn from "../../components/LoginBtn";
import LoginInput from "../../components/LoginInput";
import { userLogin } from "../../store/actions/userActions";
import "./styles.scoped.css";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faClock,
    faUserShield,
    faMedal,
} from "@fortawesome/pro-regular-svg-icons";
import { LAVENDER } from "../../components/ClubIconsNavBar";
import MenuWrp from "../../components/MenuWrp";
import { toast } from "react-toastify";
import { goCircleLoginUrl } from "../../AppConfig";
import Footer from "../../components/Footer";
import styled from "styled-components";
import {
    ContainerSignup,
    ContentContainer,
    HeaderActionButtons,
    HeaderActions,
    HeaderLogin,
    HeaderLogo,
    LeftContainer,
    Overlap,
    RightContainer,
} from "./components";
import { useTranslation } from "react-i18next";

toast.configure();

interface LoginContainerDispatch {
    dispatchUserLogin: (
        username: string,
        password: string,
        custom?: any
    ) => Promise<boolean>;
}

interface LoginContainerProps extends LoginContainerDispatch {}
const Login = ({ dispatchUserLogin }: LoginContainerProps) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        const { error, secret } = searchParamsObject;
        if (error) {
            toast.error(t('toast_errorCircle'));
        } else if (secret) {
            toast.info("You are being authenticated...");
            dispatchUserLogin(username.toLowerCase(), "", searchParamsObject);
        }
    }, []);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [activePopup, setActivePopup] = useState(false);
    const [logGoCirle, setLogGoCirle] = useState(false);

    const handleLogin = () => {
        dispatchUserLogin(username.toLowerCase(), password);
        // .then((response) => {
        //   if (response) {
        //     setUsername('');
        //     setPassword('');
        //   }
        // });
    };

    const handleLoginGoCircle = () => {
        setLogGoCirle(true);
        window.location.href = goCircleLoginUrl;
    };

    function handleMenu(e: any) {
        setActivePopup(true);
    }

    function handleCloseMenu() {
        setActivePopup(false);
    }

    const {
        mainBackground,
        img1,
        img2,
        img3,
        oneUnifyingPlatfo,
        saveTeachersTimeA,
        overlapGroup1,
        vector21,
        number2,
    } = componentData;

    const loadingLogin = useSelector(
        (state: any) => state.getUser.loadingLogin || false
    );

    return (
        <>
            <ContainerSignup>
                <Overlap active={activePopup} onClick={handleCloseMenu}>
                    <MenuWrp activePopup={activePopup} />
                </Overlap>
                <HeaderLogin>
                    <HeaderLogo />
                    <HeaderActions>
                        <HeaderActionButtons>
                            <button
                                className="loginSignUp"
                                onClick={() => navigate("/")}
                            >
                                Login
                            </button>
                            <button
                                className="signUpSignUp"
                                onClick={() => navigate("/signup")}
                            >
                                Sign Up
                            </button>
                        </HeaderActionButtons>
                        <button className="menuSignUp">
                            <FontAwesomeIcon
                                icon={faBars}
                                size="xl"
                                color={"white"}
                                onClick={(e) => handleMenu(e)}
                                className="buttonMenu"
                            />
                        </button>
                    </HeaderActions>
                </HeaderLogin>
                <ContentContainer>
                    <LeftContainer>
                        <div className="grayFilter" />
                        <div className="signUpInfo">
                            <h2>Schools and School Systems</h2>
                            <div className="textInfoFirst">
                                One, unifying platform to manage and promote all
                                extracurricular activities ensuring equal
                                awareness and access to opportunity.
                            </div>
                            <div className="textInfo">
                                <FontAwesomeIcon
                                    icon={faClock}
                                    size="xl"
                                    color={"white"}
                                />
                                <p>
                                    Save teachers time and receive previously
                                    unavailable insight into school or system
                                    wide engagement, i.e., by school, gender,
                                    ethnicity, class year and socioeconomic
                                    status.
                                </p>
                            </div>
                            <div className="textInfo">
                                <FontAwesomeIcon
                                    icon={faUserShield}
                                    size="xl"
                                    color={"white"}
                                />
                                <p>
                                    Protect students, with a safe,
                                    invitation-only social-network that promotes
                                    student exploration of their interests
                                    through participation in real-life
                                    activities.
                                </p>
                            </div>
                            <div className="textInfo">
                                <FontAwesomeIcon
                                    icon={faMedal}
                                    size="xl"
                                    color={"white"}
                                />
                                <p>
                                    Align student engagement with digital
                                    rewards – supporting contextual financial
                                    literacy and the opportunity for all student
                                    to earn micro-scholarships.
                                </p>
                            </div>
                        </div>
                    </LeftContainer>
                    <RightContainer>
                        <div
                            className="login-blur-container"
                            style={{ backgroundImage: `url(${overlapGroup1})` }}
                        >
                            <form className="login-form">
                                <div className="login-form-cont">
                                    <h1 className="titleLogin robotocondensed-bold-white-48px">
                                        Zaahah Login
                                    </h1>
                                    <div className="form-container">
                                        <div className="robotocondensed-bold-lavender-14px">
                                            <form
                                                style={{
                                                    alignItems: "flex-start",
                                                }}
                                            >
                                                <LoginInput
                                                    placeholder="Username"
                                                    handleChange={setUsername}
                                                />
                                                <LoginInput
                                                    placeholder="Password"
                                                    handleChange={setPassword}
                                                    password
                                                    extraStyles={{
                                                        marginTop: 16,
                                                    }}
                                                />
                                                <br />
                                            </form>
                                        </div>
                                        <nav className="robotocondensed-bold-lavender-14px">
                                            <Link
                                                to="/forgot-password"
                                                style={{ color: "white" }}
                                            >
                                                Forgot Password
                                            </Link>
                                        </nav>
                                        <nav className="robotocondensed-bold-lavender-14px">
                                            {/* <Link to="/signup" style={{ color: 'white' }} >Signup</Link> */}
                                        </nav>
                                    </div>
                                </div>
                                <span className="textPrivacyPolicy">
                                    By clicking Login, you agree to our{" "}
                                    <a href="https://possibilitycompany.com/terms-of-service/">
                                        Terms of Service
                                    </a>{" "}
                                    and{" "}
                                    <a href="https://possibilitycompany.com/privacy-policy/">
                                        Privacy Policy.
                                    </a>
                                </span>
                                <br />
                                <div className="frame-5432">
                                    <div className="LoginWrp-buttons">
                                        <button
                                            className={`login-button-GoCircle ${loadingLogin &&
                                                "inProcess"}`}
                                            type="button"
                                            onClick={handleLoginGoCircle}
                                        >
                                            {logGoCirle ? (
                                                <div className="icon-spinner-third buttonSendSignUp" />
                                            ) : (
                                                <div
                                                    className="Login-GoCircle"
                                                    id="Login_Circle"
                                                >
                                                    <img
                                                        src="https://circleauth.gocircle.ai/img/circle_logo_only.svg"
                                                        alt="Go Circle Auth"
                                                    />
                                                    <span>
                                                        Login with Circle
                                                    </span>
                                                </div>
                                            )}
                                        </button>
                                        <button
                                            id="Login_No_Circle"
                                            className={`login-button usual-access ${loadingLogin &&
                                                "inProcess"}`}
                                            type="button"
                                            onClick={handleLogin}
                                        >
                                            {loadingLogin ? (
                                                <div className="icon-spinner-third buttonSendSignUp" />
                                            ) : (
                                                <LoginBtn
                                                    onClick={handleLogin}
                                                    message="Login"
                                                />
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </RightContainer>
                </ContentContainer>
                <Footer />
            </ContainerSignup>
            {/* <div className="container-center-horizontal">
        <div className="background screen" style={{ backgroundImage: `url(${mainBackground}) no-repeat center center fixed` }} >
          <div className="frame-6129" >
            <div className="group-container" >
              <img className="group-4960" src={img1} />
              <img className="group-4959" src={img2} />
              <img className="group-4961" src={img3} />
            </div>
            <div className="frame-6128" >
              <div
                className="schools-and-school-systems valign-text-middle robotocondensed-bold-white-24px"
              >
                Schools and School Systems
              </div>
              <p className="one-unifying-platfo valign-text-middle robotocondensed-normal-white-16px" >
                {oneUnifyingPlatfo}
              </p>
              <p className="save-teachers-time-a robotocondensed-normal-white-16px" >
                {saveTeachersTimeA}
              </p>
            </div>
          </div>

          <div
            className="login-blur-container"
            style={{ backgroundImage: `url(${overlapGroup1})` }}
          >
            <form className="login-form" >
              <div className="login-form-cont" >
                <h1 className="titleLogin robotocondensed-bold-white-48px" >
                  Login
                </h1>
                <div className="form-container" >

                  <div className="robotocondensed-bold-lavender-14px">
                    <form style={{ alignItems: "flex-start" }}>
                      <LoginInput placeholder='Username' handleChange={setUsername} />
                      <LoginInput placeholder='Password' handleChange={setPassword} password extraStyles={{ marginTop: 16 }} />
                      <br />
                    </form>
                  </div>
                  <nav className="robotocondensed-bold-lavender-14px">
                    <Link to="/forgot-password" style={{ color: 'white' }} >Forgot Password</Link>
                  </nav>
                  <nav className="robotocondensed-bold-lavender-14px">
                    <Link to="/signup" style={{ color: 'white' }} >Signup</Link>
                  </nav>
                </div>
              </div>
              <span className="textPrivacyPolicy">By clicking Send, you agree to our Terms of Service and Privacy Policy.</span>
              <br />
              <div className="frame-5432" >
                <div className="frame-5355" >
                  <button  className={`login-button ${loadingLogin&& 'inProcess'}`} type="button" onClick={handleLogin}>
                    {loadingLogin ? 
                        <div className="icon-spinner-third buttonSendSignUp"></div>
                        :
                        <LoginBtn onClick={handleLogin} message='Login' />
                    }
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div> */}
        </>
    );
};

const componentData = {
    mainBackground:
        "https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/6219388fb3094ba2a2c54c20/img/desktop---38@1x.png",
    img1:
        "https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/group-4960@2x.png",
    img2:
        "https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/group-4959@1x.png",
    img3:
        "https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/group-4961@2x.png",
    oneUnifyingPlatfo:
        "One, unifying platform to manage and promote all extracurricular activities ensuring equal awareness and access to opportunity.",
    saveTeachersTimeA: (
        <>
            Save teachers time and receive previously unavailable insight into
            school or system wide engagement.
            <br />
            Protect students, with a safe, invitation-only social-network.
            <br />
            Align student engagement with digital rewards.
        </>
    ),
    overlapGroup1:
        "https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/rectangle-3616@1x.png",
    vector21:
        "https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/vector-21@2x.png",
    number2: "2",
};

const NotShowOnMobile = styled.div``;

const mapDispatchToProps = (dispatch: any) => ({
    dispatchUserLogin: (username: string, password: string, custom?: any) =>
        dispatch(userLogin(username, password, custom)),
});

export default connect(
    null,
    mapDispatchToProps
)(Login);
