import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './styles.scoped.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
function ModalSendAhaConfirmation(props: any) {

  function handleOnClickOk(type: any, id: any){
    props.onHide(type, id)
  }
  
  return (
    <>
        <Modal className="modalSendCAha" show={props.show} onHide={props.onHide}>
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
                <FontAwesomeIcon
  icon="times"
  size='xl'
  color={WHITE}
  className="icon-times  "
  onClick={() => handleOnClickOk('cancel', props.handleId)}
/>
            </Modal.Header>
            <Modal.Body>
                <FontAwesomeIcon
  icon="exclamation-circle"
  size='lg'
  color={LAVENDER}
  className="Modalexclamation-circle  "
/>
                <span className="labelEventCreated"> Are you sure you want to send {props.number} to {props.profile}?</span>
            </Modal.Body>
            <Modal.Footer>
                <Button className="bCancel" onClick={() => handleOnClickOk('cancel', props.handleId)}>
                    Cancel
                </Button>
                <Button className="bSend" onClick={() => handleOnClickOk('send', props.handleId)} disabled={props.loadingSend}>
                    {props.loadingSend ? (
                        <div className="icon-spinner-third buttonSendSignUp" />
                    ) : 'Send'}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  );
}

export default ModalSendAhaConfirmation;