import React, { useEffect, useState } from "react";
import Header from "../Header";
import styled from "styled-components";
import {
  RobotocondensedRegularNormalLavende,
  RobotocondensedBoldWhite16px,
  RobotocondensedRegularNormalWhite16,
  Fontawesome5proRegularNormalLavende,
  RobotocondensedBoldLavender16px,
  RobotoBoldWhite16px,
  ValignTextMiddle,
} from "../../styledMixins";
import "./styles.css";
import SuperAdminLeftMenu from "../SuperAdminLeftMenu";
import AdminLeftMenu from "../AdminLeftMenu";
import "../../styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApproveOrganization, getOrganizationInfo } from "../../store/actions/orgsActions";
import { useAppDispatch } from "../../store/store";
import { unixToDateFormatted } from "../../helpers/dateHelper";
import { capitalize } from "../../helpers/letterHelper";
import { ZERO } from "../ClubIconsNavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import YO from "../../interfaces/YOTable/YO/YO";

const ORGSTATUS: { [key: string]: string } = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  PENDING_APPROVAL: "Pending Approval",
  PENDING_CONFIRMATION: "Pending Confirmation",
}

function Organizations() {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userRoles = useSelector((state: any) => state.getUser.userRoles);
  const organization = useSelector((state: any) => state.getOrgs.organizationInfo || {});
  const loadingApproveOrg = useSelector((state: any) => state.getOrgs.loadingApproveOrg || false);

  const [org, setOrg] = useState<YO>({
    id: '',
    type: 'I',
    name: '',
    mission: '',
    genders: [''],
    highAge: 0,
    lowAge: 0,
    highGrade: 0,
    lowGrade: 0,
    website: '',
    countries: [''],
  });

  const reloadOrg = () => {
    if (id === '') {
      // TODO: Send to 404
      return;
    }
    dispatch<any>(getOrganizationInfo(id))
      .then((results: any) => {
        if (results.error === 'No org') {
          getYo();
        }
      })
      .catch(console.log)
  };

  useEffect(() => {
    reloadOrg();
  }, []);

  const getYo = () => {
    Backend(
      MicroServices.Admin,
      `/yo/${id}`,
      {
        method: 'GET'
      }
    )
      .then((response) => response.json())
      .then((results: YO) => {
        setOrg(results);
      });
  }


  return (
    <div className="container-center-horizontal">
      <div className="organizations screen">
        {userRoles.includes('SUPER') ?
          <SuperAdminLeftMenu screen="Organizations" />
          :
          <AdminLeftMenu screen="Users" />
        }
        <Header />
        <div className="content">
          <div className="top-cont">
            <div className="organization robotocondensed-normal-white-16px">Organization</div>
            <div className="text-1 robotocondensed-normal-white-16px">/</div>
            <div className="view-organization robotocondensed-bold-lavender-16px">
              View Organization
            </div>
          </div>
          <div className="org-view-cont">
            <div className="title-column">
              <h1 className="title-name-1">{organization?.name || org.name}</h1>
              <div className="buttons-box">
                {(organization.status !== 'ACTIVE' && org.id === '') && (
                  <div className={`button-w buttonApproveOrgDfl ${loadingApproveOrg && 'inProcess'}`} onClick={
                    () => dispatch<any>(ApproveOrganization(id))
                      .then((results: any) => {
                        reloadOrg();
                      })
                      .catch(console.log)}>
                    {loadingApproveOrg ?
                      <div className="icon-spinner-third buttonApproveOrg"></div>
                      :
                      <div className="delete">Approve</div>
                    }
                  </div>)}
                <div className={`button-p ${loadingApproveOrg && 'inProcess'}`}>
                  <div className="update roboto-normal-white-16px" onClick={() => navigate(`../organizations/${id}/update`)}>Update</div>
                </div>
              </div>
            </div>
            <div className="view-columns">
              <div className="col-1">
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">ID</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px ellipses">{id || ''}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Name</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px ellipses">{organization?.name || org.name}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Type</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px ellipses">{capitalize(organization?.type || (org.name !== '' ? 'YOUTHORG': ''))}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Website</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px ellipses">{organization?.website || org.website}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px"> Organization <br />Administrator</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px ellipses">{organization?.orgAdmin?.firstname} {organization?.orgAdmin?.lastname}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px"> Administrator <br />Contact Phone</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px ellipses">{organization?.orgAdmin?.phoneNumber}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px"> Created by</div>
                  <div className="info-content">
                    <div className="info-field-medium1">
                      <div className="roboto-normal-white-16px">{organization?.orgAdmin?.firstname} {organization?.orgAdmin?.lastname}</div>
                    </div>
                    <div className="info-field-medium2">
                      <div className="roboto-normal-white-16px ellipses">{organization?.orgAdmin?.email}</div>
                    </div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Created</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px ellipses">{unixToDateFormatted(organization?.created_at) || ''}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Updated</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px ellipses">{unixToDateFormatted(organization?.update_at) || ''}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Status</div>
                  <div className="status-column">
                    <div className={"statusField status-" + organization?.status || ''}>
                      {!!ORGSTATUS[((organization?.status || '') + '') || ''] && (
                        <>
                          <FontAwesomeIcon
                            icon={["fas", "circle"]}
                            color={ZERO}
                            className="place valign-text-middle"
                            size="xs"
                          />
                          <div className="status-field robotocondensed-normal-absolute-16px">
                            {ORGSTATUS[((organization?.status || '') + '') || '']}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px"> Parent<br />Organization</div>
                  <div className="info-content">
                    <div className="info-field-medium1">
                      <div className="roboto-normal-white-16px ellipses"></div>
                    </div>
                    <div className="info-field-medium2">
                      <div className="roboto-normal-white-16px ellipses"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-2">
                {/* <div className="logo-cont">
                        <div className="logo robotocondensed-bold-lavender-16px">Logo</div>
                        <div className="image-wrapper">
                          <img
                            src={organization?.logo || 'https://picsum.photos/200/200'}
                            alt="org-img"
                          />
                        </div>
                    </div> */}

                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Address</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px ellipses">{organization?.address || ''}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">City</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px ellipses">{organization?.city || ''}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">State</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px ellipses">{organization?.state || ''}</div>
                  </div>
                </div>

                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Postal Code</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px ellipses">{organization?.zipcode || ''}</div>
                  </div>
                </div>

                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Country</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px ellipses">{organization?.country || ''}</div>
                  </div>
                </div>

                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px"> Primary <br />Contact</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px ellipses">{organization?.primaryContact?.name}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px"> Primary <br />Contact Phone</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px ellipses">{organization?.primaryContact?.phoneNumber}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px"> Primary <br />Contact Email</div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px ellipses">{organization?.primaryContact?.email}</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ContentOrganizations = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  width: 100%;
  z-index: 1;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  padding: 30px 1000px;
  align-items: center;
  min-height: 944px;
  background-color: var(--violet);
`;

const ContentUsers = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  width: 1298px;
  z-index: 1;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  align-items: center;
  min-height: 944px;
  background-color: var(--violet);
`;

const FlexRow = styled.div`
  height: 124px;
  display: flex;
  align-items: flex-start;
  min-width: 1250px;
`;

const FlexCol = styled.div`
  width: 182px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 93px;
`;

const Title = styled.h1`
  min-height: 28px;
  font-family: var(--font-family-roboto_condensed);
  font-weight: 700;
  color: var(--lavender);
  font-size: var(--font-size-xxxl);
  letter-spacing: 0;
  padding-bottom: 30px;
`;

const Showing112Of864Items = styled.p`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  align-self: flex-end;
  margin-top: 46px;
  min-width: 198px;
  letter-spacing: 0;
  padding-left: 14px;
`;

const FlexCol1 = styled.div`
  width: 200px;
  position: relative;
  margin-left: 868px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 104px;
  justify-content: flex-end;
`;

const Viewing = styled.div`
  margin-left: 3px;
  display: flex;
  align-items: center;
  min-width: 197px;
  align-self: flex-end;
`;

const Viewing1 = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  min-width: 50px;
  letter-spacing: 0;
`;

const Box = styled.select`
${RobotocondensedBoldLavender16px}
  height: 40px;
  margin-left: 17px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  min-width: 130px;
  background-color: var(--violent-violet);
  border: none;
`;

const Address = styled.div`
  ${RobotocondensedBoldLavender16px}
  min-height: 19px;
  min-width: 65px;
  letter-spacing: 0;
`;

/* const PgNumber = styled.div`
  margin-top: 438px;
  display: flex;  
  padding: 0 30px;
  align-items: center;
  min-width: 360px;
`; */

export default Organizations;
