import React, { SyntheticEvent, useEffect, useState } from "react";
import Header from "../Header";
import styled from "styled-components";
import {
  RobotocondensedRegularNormalWhite16,
  Fontawesome5proRegularNormalLavende,
} from "../../styledMixins";
import "./styles.css";
import "./styles.scoped.css";
import AdminLeftMenu from "../AdminLeftMenu";
import SuperAdminLeftMenu from "../SuperAdminLeftMenu";
import ManageClubsSuperAdminTable from "../ManageClubsSuperAdminTable";
import ManageClubsTable from "../ManageClubsTable";
import ClubsManageClubsNavBar from "../ClubsManageClubsNavBar";
import AddClubs from "../AddClubs";
import "../../styles.css";
import Select, { SingleValue, components, PlaceholderProps } from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import { getUsers, getUsersRoles, getUsersStatus, getUsersWithLimit } from "../../store/actions/userActions";
import { getOrganizationNames, getStaffUsers } from "../../store/actions/orgsActions";
import { getActiveCities, getActiveCountries } from "../../store/actions/locationActions";
import PageNumber from "../PageNumber";
import { RootState } from "../../store/store";
import { getTeams, getTemplateClubsGrouped } from "../../store/actions/templateClubActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchTable from "../SearchTable";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { WHITE } from "../ClubIconsNavBar";

export interface ManagedTemplate {
  name: string;
  skills_learned: string[];
  category: string;
  member_type: string[];
  role_type: string;
  team_distinguisher?: string;
}

const PAGE_SIZES: pageSize[] = [10, 50];
export type pageSize = 10 | 50 | 10000;

function Users({ clubType }: { clubType: string }) { //Types -> CLUB TEAM COMM
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<pageSize>(PAGE_SIZES[0]);
  const [search, setSearch] = useState([])

  const usersAll = useSelector((state: any) => state.getUser.users);
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.getUser.token);
  const lastEvaluatedkeys = useSelector((state: any) => state.getOrgs.lastEvaluatedkeys);

  const [searchStringCreated, setSearchStringCreated] = useState<string>('')
  const [searchStringCategory, setSearchStringCategory] = useState<string>('')
  const [searchStringTemplate, setSearchStringTemplate] = useState<string>('')

  const [tabSelected, setTabSelected] = useState(0);

  const user = useSelector((state: any) => state.getUser.user);
  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  )

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getUsersStatus());
    // dispatch(getUsersWithLimit(token, pageSize));
    dispatch(getOrganizationNames());
    dispatch(getUsersRoles());
    dispatch(getActiveCities());
    dispatch(getActiveCountries());

    if (!userRoles.includes('SUPER')) {
      dispatch(getStaffUsers(organization, user));
    }
  }, [])

  useEffect(() => {
    if (page === 0) {
      // dispatch(getUsersWithLimit(token, pageSize, page))
    } else {
      // dispatch(getUsersWithLimit(token, pageSize, page, lastEvaluatedkeys[page]))
    }
  }, [pageSize, page]);

  const handleSetPageSize = (newValue: SingleValue<{ label: string; value: pageSize; }>) => {
    setPageSize(newValue?.value || 10)
  }

  const getClubTypeText = (): string => {
    switch (clubType) {
      case 'COMM':
        return t('comm__title')
      case 'CLUB':
        return t('clubs__title')
      case 'TEAM':
        return t('teams__title')
      default:
        return ''
    }
  }

  const customStyles = () => ({
    option: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#383838',
    }),
    input: (styles: any, state: any) => ({
      ...styles,
      border: 'none',
      color: 'white',
      fontWeight: "normal",
      paddingLeft: "5px",
      "&:focus": { backgroundColor: 'red' },
    }),
    container: (styles: any) => ({
      ...styles,
      padding: 0,
    }),
    valueContainer: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: 'transparent',
      padding: 0,
    }),
    menu: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      backgroundColor: "#383838",
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      textAlign: "left",
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "118px",
      borderRadius: "0px",
      boxShadow: "none",
      border: 0,
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "bold",
      maxWidth: "200px",
      marginBottom: "5px",
      backgroundColor: 'transparent',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: 'white',
      textDecoration: "underline",
      display: 'block',
    }),
    indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
    dropdownIndicator: (styles: any) => ({ ...styles, display: "none" }),
    noOptionsMessage: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: 'grey',
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "normal",
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: 'transparent',
      border: "none",
      paddingLeft: "5px",
      paddingRight: "5px",
    }),
  })


  const viewingOptions = PAGE_SIZES.map((item) => ({
    label: `${item} ${t('comm__results')}`,
    value: item,
  }))

  const canGoBack = () => page !== 0;
  const canGoNext = () => true;

  const goBack = () => {
    setPage(page - 1);
  };

  const goNext = () => {
    setPage(page + 1);
  };

  const actions = [
    { value: "ACA", label: "Manage Advisors", icon: "user-plus" },
    { value: "ICM", label: "Invite Members", icon: "user-group" },
    { value: "ECCA", label: "Suspend Club", icon: "user-slash" },
  ];

  const [selectedAction, setSelectedAction] = useState<selectedActionProps>();

  const handleActionChange = (value: any) => {
    setSelectedAction(value);
  }
  interface selectedActionProps {
    icon?: any;
  }

  const userRoles = useSelector((state: any) => state.getUser.userRoles)

  function handleClickTab(e: any) {
    setTabSelected(e)
  }

  useEffect(() => {
    setSearchStringCategory('')
    setSearchStringCreated('')
    setSearchStringTemplate('')
  }, [clubType])

  //ADD CLUBS

  const [createCustom, setCreateCustom] = useState<boolean>(false)

  const generateTemplate = (template_team: ManagedTemplate, type: string, gender: string): ManagedTemplate => {
    return {
      name: `${gender} ${type} ${template_team.name}`,
      skills_learned: template_team.skills_learned,
      category: template_team.name,
      member_type: template_team.member_type,
      role_type: template_team.role_type,
      team_distinguisher: `${type}-${gender}`
    }
  }
  const generateTemplateTeams = (template_team: ManagedTemplate): ManagedTemplate[] => {
    const templates: ManagedTemplate[] = []
    templates.push(generateTemplate(template_team, 'Freshman', 'Girls'))
    templates.push(generateTemplate(template_team, 'Freshman', 'Boys'))
    templates.push(generateTemplate(template_team, 'Freshman', 'Coed'))
    templates.push(generateTemplate(template_team, 'JV', 'Girls'))
    templates.push(generateTemplate(template_team, 'JV', 'Boys'))
    templates.push(generateTemplate(template_team, 'JV', 'Coed'))
    templates.push(generateTemplate(template_team, 'Varsity', 'Girls'))
    templates.push(generateTemplate(template_team, 'Varsity', 'Boys'))
    templates.push(generateTemplate(template_team, 'Varsity', 'Coed'))
    return templates
  }
  useEffect(() => {
    if (!userRoles.includes('SUPER')) {
      dispatch(getTemplateClubsGrouped());
      dispatch(getTeams());
    }
  }, [])

  const preTemplateClubs: { [category: string]: { name: string, templateClubs: ManagedTemplate[] } } = useSelector((state: RootState) => state.getTemplateClubs.TClubGrouped || {});
  const [templateClubs, setTemplateClubs] = useState<{ [category: string]: { name: string, templateClubs: ManagedTemplate[] } }>({})
  const [filteredTemplateClubs, setFilteredTemplateClubs] = useState<{ [category: string]: { name: string, templateClubs: ManagedTemplate[] } }>({})
  const preTemplateTeams: ManagedTemplate[] = useSelector((state: RootState) => state.getTemplateClubs.Teams || []);
  const [templateTeams, setTemplateTeams] = useState<{ [category: string]: { name: string, templateClubs: ManagedTemplate[] } }>({})
  const [filteredTemplateTeams, setFilteredTemplateTeams] = useState<{ [category: string]: { name: string, templateClubs: ManagedTemplate[] } }>({})

  useEffect(() => {
    if (!userRoles.includes('SUPER')) {
      setTemplateTeams(Object.assign({}, ...preTemplateTeams.map((template) => ({
        [template.name]: {
          name: template.category,
          templateClubs: generateTemplateTeams(template)
        }
      }))))
    }
  }, [preTemplateTeams])

  useEffect(() => {
    if (!userRoles.includes('SUPER')) {
      const temClubs = { ...preTemplateClubs }
      delete temClubs['All']
      setTemplateClubs(temClubs)
    }

  }, [preTemplateClubs])

  //Filter saved info

  const reloadSavedInfo = () => {
    if (!userRoles.includes('SUPER')) {
      let org_id = ''

      if (user) {
        org_id = user.organizations[0];
      }

      Backend(
        MicroServices.Club,
        `/clubs?org_id=${org_id}`)
        .then((response) => response.json())
        .then((results) => {
          const dynamo = results.clubs
          const TClubsCopy = { ...templateClubs }
          const TTeamsCopy = { ...templateTeams }
          for (let club of dynamo) {
            if (club.category && club.template_id) {
              switch (club.club_type) {
                case 'COMM':
                  break
                case 'CLUB':
                  TClubsCopy[club.category].templateClubs = TClubsCopy[club.category].templateClubs.filter((item) => club.template_id !== item.name)
                  break
                case 'TEAM':
                  TTeamsCopy[club.template_id].templateClubs = TTeamsCopy[club.template_id].templateClubs.filter((item) => club.team_distinguisher !== item.team_distinguisher)
                  break
                default:
                  break
              }
            }
          }
          for (let key in TClubsCopy) {
            let value = TClubsCopy[key];
            if (value.templateClubs.length === 0) delete TClubsCopy[key]
          }
          for (let key in TTeamsCopy) {
            let value = TTeamsCopy[key];
            if (value.templateClubs.length === 0) delete TTeamsCopy[key]
          }
          setFilteredTemplateClubs(TClubsCopy)
          setFilteredTemplateTeams(TTeamsCopy)
        })
    }

  }
  useEffect(() => {
    if (!userRoles.includes('SUPER')) {
      reloadSavedInfo()
    }
  }, [templateClubs, templateTeams])

  return (
    <>

      {userRoles.includes('SUPER') ?
        <div className="content-org">
          <TopColumn>
            <ManageUsersCol>
              <Title>
                {(clubType === 'CLUB') ?
                  'Manage Clubs' :
                  ((clubType === 'TEAM') ? 'Manage Teams' :
                    'Manage Communities')}
              </Title>
            </ManageUsersCol>
            <div className="shwo-view-row">
              <ShowingItems>
                <span className="robotocondensed-normal-white-16px">
                  Showing{' '}
                </span>
                <span className="robotocondensed-bold-white-16px">
                  {(pageSize * page) + 1}-{pageSize * (page + 1)}{' '}
                </span>
                <span className="robotocondensed-normal-white-16px">
                  of{' '}
                </span>
                <span className="robotocondensed-bold-white-16px">
                  {usersAll.length}{' '}
                </span>
                <span className="robotocondensed-normal-white-16px">
                  clubs
                </span>
              </ShowingItems>
              <Viewing>
                <Viewing1>Viewing</Viewing1>
                <Box>
                  <Select
                    value={{ value: pageSize, label: `${pageSize} ${t('comm__results')}` }}
                    isMulti={false}
                    options={viewingOptions}
                    placeholder={"View"}
                    styles={customStyles()}
                    onChange={handleSetPageSize}
                  />
                  <ChevronDown />
                </Box>
              </Viewing>
            </div>
          </TopColumn>
          <ManageClubsSuperAdminTable clubType={clubType} />
          <PageNumber
            page={page}
            canGoBack={canGoBack()}
            canGoNext={canGoNext()}
            goBack={goBack}
            goNext={goNext}
          />
        </div>

        :

        <div className="content-org">
          <TopColumn>
            <ManageUsersCol>
              <ClubsManageClubsNavBar handleClickTab={handleClickTab} clubType={clubType} />
              {tabSelected === 0 ?
                <div className="show-view-row">
                  {/* <div className="info-field" id="input-color">
                    <select className="roboto-normal-white-16px">
                        <option selected disabled>Select State</option>
                        <option value="All">All</option>
                        <option value="School">School</option>
                        <option value="Assotiation">Assotiation</option>
                        <option value="Superadmin">Superadmin</option>
                    </select>
                </div> */}
                  <div className="input50">
                    <SearchTable placeholder={`${t('comm__search')} ${getClubTypeText()}`} onChange={(e) => setSearchStringCreated(e.target.value)} />
                    {/* <input value={searchStringCreated} type="text" placeholder={`Search ${getClubTypeText()}`} onChange={(e) => setSearchStringCreated(e.target.value)} /> */}
                  </div>
                  {/* <input
                    isMulti={false}
                    placeholder={
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div className="icon-select fontaweswome5pro-regular-normal-lavender-20px" id="icon-white">search</div>
                        <div className="search roboto-text">Search</div>
                      </div>
                    }
                    styles={{
                      singleValue: (base) => ({
                        ...base,
                        display: "flex",
                        alignItems: "center",
                        color: 'white'
                      }),
                      control: (styles) => ({
                        ...styles,
                        backgroundColor: 'var(--violent-violet)',
                        borderRadius: "3px",
                        boxShadow: "none",
                        border: 0,
                        minWidth: "50%"
                      }),
                      option: (styles) => ({
                        ...styles,
                        color: 'white',
                        backgroundColor: 'var(--violent-violet)',
                        display: 'flex',
                        flexDirection: 'row',
                      }),
                      placeholder: (styles) => ({
                        ...styles,
                        color: 'white',
                        textAlign: "left"
                      }),
                      container: (styles) => ({
                        ...styles,
                        padding: 0,
                        minWidth: "39%"
                      }),
                      menu: (styles) => ({
                        ...styles,
                        borderRadius: "8px",
                        backgroundColor: "var(--violent-violet)",
                      }),
                      input: (styles: any, state: any) => ({
                        ...styles,
                        border: 'none',
                        color: 'white',
                      }),
                    }}
                  /> */}
                  {/*} <div className="actions-container">
                    <div className="what-to-do">What would you like to do?</div>
                    <Select
                      value={selectedAction}
                      isMulti={false}
                      options={actions}
                      placeholder={"Select an action"}
                      styles={{
                        singleValue: (base) => ({
                          ...base,
                          display: "flex",
                          alignItems: "center",
                          color: "white"
                        }),
                        control: (styles) => ({
                          ...styles,
                          backgroundColor: 'var(--violent-violet)',
                          borderRadius: "3px",
                          boxShadow: "none",
                          border: 0,
                          minWidth: "100%"
                        }),
                        option: (styles) => ({
                          ...styles,
                          color: 'white',
                          backgroundColor: 'var(--violent-violet)',
                          display: 'flex',
                          flexDirection: 'row',
                        }),
                        placeholder: (styles) => ({
                          ...styles,
                          color: 'white',
                          textAlign: "left",
                          fontFamily: "Roboto",
                        }),
                        container: (styles) => ({
                          ...styles,
                          padding: 0,
                          minWidth: "30%"
                        }),
                        menu: (styles) => ({
                          ...styles,
                          borderRadius: "8px",
                          backgroundColor: "var(--violent-violet)",
                          fontFamily: "Roboto"
                        }),
                      }}
                      components={{
                        Option,
                        SingleValue,
                        IndicatorSeparator: () => null
                      }}
                      onChange={handleActionChange}
                      isSearchable={false}
                    />
                  </div>*/}
                  <Viewing>
                    <Viewing1>{t('comm__viewing')}</Viewing1>
                    <Box>
                      <Select
                        value={{ value: pageSize, label: `${pageSize} ${t('comm__results')}` }}
                        isMulti={false}
                        options={viewingOptions}
                        placeholder={"View"}
                        styles={customStyles()}
                        onChange={handleSetPageSize}
                      />
                      <ChevronDown />
                    </Box>
                  </Viewing>
                </div>
                :
                tabSelected === 1 ?
                  ''
                  :
                  <div className="show-view-row">
                    {clubType === 'CLUB' &&
                      <div className="inputSearch input25">
                        <input value={searchStringCategory} type="text" placeholder={t('addComunity__searchCategory')} onChange={(e) => setSearchStringCategory(e.target.value)} />
                      </div>
                    }
                    <div className="inputSearch input50">
                      <input value={searchStringTemplate} type="text"
                        placeholder={`Search ${getClubTypeText()} template in all categories`}
                        onChange={(e) => setSearchStringTemplate(e.target.value)} />
                    </div>
                    <div className="custom-club-button" onClick={() => setCreateCustom(true)}>
                      <FontAwesomeIcon
                        icon="plus"
                        size='lg'
                        color={WHITE}
                        className=" "
                      />
                      <div className="custom-club-text">{`Custom ${getClubTypeText()}`}</div>
                    </div>
                  </div>
              }

            </ManageUsersCol>
          </TopColumn>
          {tabSelected === 0 ?
            <>
              <ManageClubsTable clubType={clubType} searchString={searchStringCreated} />
              <PageNumber
                page={page}
                canGoBack={canGoBack()}
                canGoNext={canGoNext()}
                goBack={goBack}
                goNext={goNext}
              />
            </>
            :
            tabSelected === 1 ?
              ''
              :
              <AddClubs
                type={clubType}
                reload={reloadSavedInfo}
                searchStringCategory={searchStringCategory}
                searchString={searchStringTemplate}
                templates={clubType === 'COMM' ? {} : clubType === 'CLUB' ? filteredTemplateClubs : clubType === 'TEAM' ? filteredTemplateTeams : {}}
                createCustom={{ get: createCustom, set: setCreateCustom }} />
          }
        </div>
      }
    </>
  );
}

const TopColumn = styled.div`
  height: 124px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
  justify-content: space-between;
`;

const ManageUsersCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Viewing = styled.div`
  margin-left: 3px;
  display: flex;
  align-items: center;
  min-width: 197px;
  align-self: flex-end;
  font-family: var(--font-family-roboto_condensed);
`;

const Viewing1 = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  min-width: 50px;
  letter-spacing: 0;
`;

const Box = styled.div`
  height: 40px;
  margin-left: 17px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  min-width: 130px;
  background-color: var(--violent-violet);
`;

const ChevronDownWrapper = styled.div`
  ${Fontawesome5proRegularNormalLavende}
  min-height: 20px;
  margin-left: 27px;
  min-width: 18px;
  letter-spacing: 0;
`;

const ChevronDown = () => <ChevronDownWrapper>
  <FontAwesomeIcon icon="chevron-down" color="#FFFFFF" size="sm" />
</ChevronDownWrapper>

const Title = styled.h1`
  min-height: 28px;
  font-family: var(--font-family-roboto_condensed);
  font-weight: 700;
  color: var(--lavender);
  font-size: var(--font-size-xxxl);
  letter-spacing: 0;
  padding-bottom: 30px;
`;

const ShowingItems = styled.p`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  align-self: flex-start;
  margin-top: 5px;
  letter-spacing: 0;
  align-self: center;
  text-align: center;
`;

export default Users;
