import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChatLeftPanel from '../../components/chat/ ChatLeftPanel';
import ChatCenter from '../../components/chat/ChatCenter/Index';
import ChatRightPanel from '../../components/chat/ChatRightPanel';
import Header from '../../components/Header';
import { ChatServiceInterface } from '../../interfaces/chat/ChatServiceInterface';
import { getMyClubs } from '../../store/actions/clubActions';
import { RootState } from '../../store/store';
import { PrincipalContainer} from '../AppLayout/components';
import { CentralBaseChat } from './components';

const Chat = ({ChatServiceInstance}:{ChatServiceInstance: ChatServiceInterface}) => {

    const user = useSelector((state: RootState) => state.getUser.user);
    const dispatch = useDispatch();

    const [activeNotification, setActiveNotification] = useState(false);

    function handleActiveNotification(e: any) {
        setActiveNotification(e)
    }

    useEffect(() => {
        dispatch(getMyClubs(user.username));
        if(ChatServiceInstance.chatConnected){
            ChatServiceInstance.getChatList();
        }
    }, [])

    useEffect(() => {
        if(ChatServiceInstance.chatConnected){
            ChatServiceInstance.getChatList();
        }
    }, [ChatServiceInstance.chatConnected])

    return (
        <PrincipalContainer>
            <Header handleActiveNotification={handleActiveNotification} ChatServiceInstance={ChatServiceInstance}/>
            <ChatLeftPanel ChatServiceInstance={ChatServiceInstance}/>
            <CentralBaseChat>
                <ChatCenter ChatServiceInstance={ChatServiceInstance}/>
            </CentralBaseChat>
            <ChatRightPanel ChatServiceInstance={ChatServiceInstance}/>
        </PrincipalContainer>
    );
}

export default Chat;