import React, { useState } from 'react'
import Header from '../../components/Header';
import Menu from '../../components/MyAccount/Menu';
import GeneralInfo from '../../components/MyAccount/GeneralInfo';
import MyOrganization from '../../components/MyAccount/WorkInfo';
import ChangeEmail from '../../components/MyAccount/AccountInfo';
import ChangePassword from '../../components/MyAccount/ChangePassword';
import Education from '../../components/MyAccount/Education';
import WorkHistory from '../../components/MyAccount/WorkHistory';
import SocialMedia from '../../components/MyAccount/SocialMedia';
import Preferences from '../../components/MyAccount/Preferences';

import './styles.scoped.css';
import { ChatServiceInterface } from '../../interfaces/chat/ChatServiceInterface';

const index = ({ChatServiceInstance}:{ChatServiceInstance:ChatServiceInterface}) => {

    const [itemActive, setItemActive] = useState(0)

    const handleItemActive = (e: any) => {
        setItemActive(e)
    }

    return (
        <div className="containerMyAccount">
            <Header ChatServiceInstance={ChatServiceInstance}/>
            <div className='infoAccount'>
                <div className="myAccountLeftSection">
                    <Menu itemActive={handleItemActive} />
                </div>
                <div className='myAccountCenterSection'>
                    {itemActive === 0 ?
                        {/*<GeneralInfo />*/}
                        :
                        itemActive === 1 ?
                            {/*<MyOrganization />*/}
                            :
                            itemActive === 2 ?
                                {/*<ChangeEmail />*/}
                                :
                                itemActive === 3 ?
                                    <ChangePassword />
                                    :
                                    itemActive === 4 ?
                                        <Education />
                                        :
                                        itemActive === 5 ?
                                            <WorkHistory />
                                            :
                                            itemActive === 6 ?
                                                {/*<SocialMedia />*/}
                                                :
                                                itemActive === 7 &&
                                                {/*<Preferences />*/}

                    }
                </div>
                <div className="myAccountRightSection"></div>
            </div>
        </div>
    )
}

export default index