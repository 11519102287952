import { IconName } from "@fortawesome/pro-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { reactToComment, reactToPost } from "../../store/actions/postActions";
import { DARK_VIOLET, VIOLET, WHITE } from "../ClubIconsNavBar";
import "./styles.scoped.css";

export const reactionToEmoji = (reaction: string | undefined): IconName => {
    if (!reaction) return 'fire'
    switch (reaction) {
        case 'like': return "fire"
        case 'love': return "grin-hearts"
        case 'laugh': return "laugh-squint"
        case 'surprise': return "surprise"
        case 'cry': return "sad-cry"
        case 'mad': return "angry"
        default: return 'fire'
    }
    return 'fire'
}

export const emojiColor = (reaction: string | undefined, isComment?:boolean) => {
    if (!reaction) return WHITE
    return isComment ? VIOLET : DARK_VIOLET;
}

export const getUserReactionTo = (post_id: string, username: string): Promise<string | undefined> =>
    new Promise((resolve, reject) => {
        if (!post_id || !username) { resolve(undefined); return; }
        Backend(
            MicroServices.Posts,
            `/post/${post_id}/reaction?username=${username}`)
            .then((results) => results.json())
            .then((results) => {
                results !== null ? resolve(results.type) : resolve(undefined)
            })
            .catch((error) => {
                console.log(error)
                resolve(undefined)
            })
    })

export const getUserReactionToComment = (comment_id: string, username: string): Promise<string | undefined> =>
    new Promise((resolve, reject) => {
        if (!comment_id || !username) { resolve(undefined); return; }
        Backend(
            MicroServices.Posts,
            `/comment/reaction?username=${username}&comment_id=${comment_id}`)
            .then((results) => results.json())
            .then((results) => {
                results !== null ? resolve(results.type) : resolve(undefined)
            })
            .catch((error) => {
                console.log(error)
                resolve(undefined)
            })
    })

function Index({ post_id, manageEmoji, isComment }: { post_id: string, manageEmoji?: (post_id: string, reaction: string, add: boolean) => void, isComment?: boolean }) {

    const userInfo = useSelector((state: any) => state.getUser.userInfo);
    const dispatch = useDispatch()

    const handleReactionClick = (reaction: string) => {
        if (manageEmoji) {
            manageEmoji(post_id, reaction, false)
            if(isComment){
                dispatch<any>(reactToComment(post_id || "", reaction, userInfo.username || ""))
                .then((results: any) => {
                    if (results.message === 'Reaction created') {
                        manageEmoji(post_id, reaction, true)
                    }
                })
                .catch(() => { })
            }else{
                dispatch<any>(reactToPost(post_id || "", reaction, userInfo.username || ""))
                .then((results: any) => {
                    if (results.message === 'Reaction created') {
                        manageEmoji(post_id, reaction, true)
                    }
                })
                .catch(() => { })
            }
        }
    }

    return (
        <div className="AllEmojis">
            {/*<div className="emojiIcon">*/}
            {/*    <img src="/like.png" alt="" onClick={() => { handleReactionClick("like") }} />*/}
            {/*</div>*/}
            <div className="emojiIcon">
                <img src="/fire.png" alt="" onClick={() => { handleReactionClick("like") }} />
            </div>
            <div className="emojiIcon">
                <img src="/heart_eyes.png" alt="" onClick={() => { handleReactionClick("love") }} />
            </div>
            <div className="emojiIcon">
                <img src="/joy.png" alt="" onClick={() => { handleReactionClick("laugh") }} />
            </div>
            <div className="emojiIcon">
                <img src="/scream.png" alt="" onClick={() => { handleReactionClick("surprise") }} />
            </div>
            <div className="emojiIcon">
                <img src="/cry.png" alt="" onClick={() => { handleReactionClick("cry") }} />
            </div>
            <div className="emojiIcon">
                <img src="/rage.png" alt="" onClick={() => { handleReactionClick("mad") }} />
            </div>
        </div>
    );
}

export default Index;
