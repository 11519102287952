import "./styles.scoped.css";
import React, { KeyboardEventHandler, useState, useEffect } from "react";
import Select from 'react-select';
import { ActionMeta, OnChangeValue } from "react-select";
import ModalSave from './ModalSave';
import CreatableSelect from "react-select/creatable";
import { useDispatch, useSelector } from "react-redux";
import ModalCreateEvent from "../EventSelectBar/ModalCreateEvent";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER } from "../ClubIconsNavBar";
import moment, { Moment } from "moment";
import { capitalize } from "../../helpers/letterHelper";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { RootState } from "../../store/store";

toast.configure();


function Index(props: any) {
    const { t, i18n } = useTranslation();
    interface Option {
        readonly label: string;
        readonly value: string;
    }

    const [createEvent, setCreateEvent] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [listEvents, setListEvents] = useState([]);
    const [reloadEvent, setReloadEvent] = useState(false);
    const [valueFilter, setValueFilter] = useState("");

    const userRoles = useSelector((state: any) => state.getUser.userRoles);

    const navigate = useNavigate();

    function handleCloseModal(e: any, type: any) {
        setCreateEvent(false);
        setShowModal(false);
        if (type == 'modal') {
            setCreateEvent(true);
        } else {
            setCreateEvent(false);
        }
    };

    const getTimeMoment = (time: string) => {
        const time_parts = time.split("-");
        return (
            `${time_parts[0]}-${time_parts[1]}-${time_parts[2]} ${time_parts[3]}:${time_parts[4]}`
        );
    };

    /* Events Mockup
    const array = [
        { id: '1', name: 'Basketball in the park', location: 'Zial Park' },
        { id: '2', name: 'USCH High Leader Support', location: 'Zoom' },
    ];*/

    const customStyles = () => ({
        option: (styles: any) => ({
            ...styles,
            color: 'white',
            backgroundColor: '#383838',
        }),
        input: (styles: any, state: any) => ({
            ...styles,
            border: 'none',
            color: 'white',
            fontWeight: "normal",
            paddingLeft: "5px",
            height: '40px',
            "&:focus": { backgroundColor: 'red' },
        }),
        container: (styles: any) => ({
            ...styles,
            padding: 0,
            backgroundColor: "#281159",
            borderRadius: 5,
            width: '100%',
            height: '40px'
        }),
        valueContainer: (styles: any, state: any) => ({
            ...styles,
            backgroundColor: 'transparent',
            padding: 0,
            height: '40px'
        }),
        menu: (styles: any) => ({
            ...styles,
            borderRadius: 8,
            backgroundColor: "#383838",
            textAlign: "left",
            width: 'auto',
            padding: '5px 10px',
            minWidth: '150px',
            fontFamily: "var(--font-family-roboto_condensed)",
        }),
        control: (styles: any, state: any) => ({
            ...styles,
            textAlign: "left",
            padding: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "118px",
            borderRadius: "0px",
            boxShadow: "none",
            border: 0,
            fontSize: "16px",
            fontFamily: "Roboto",
            fontWeight: "bold",
            maxWidth: "100%",
            backgroundColor: 'transparent',
            height: '40px'
        }),
        placeholder: (styles: any) => ({
            ...styles,
            color: 'white',
            textDecoration: "none",
            display: 'block',
            paddingLeft: '15px',
            fontSize: '14px',
            fontWeight: 100,
            fontFamily: 'var(--font-family-roboto_condensed)'
        }),
        indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
        dropdownIndicator: (styles: any) => ({ ...styles, display: "none" }),
        noOptionsMessage: (styles: any) => ({
            ...styles,
            color: 'white',
            backgroundColor: 'grey',
            fontSize: "16px",
            fontFamily: "Roboto",
            fontWeight: "normal",
        }),
        singleValue: (styles: any) => ({
            ...styles,
            color: 'white',
            backgroundColor: 'transparent',
            border: "none",
            paddingLeft: "5px",
            paddingRight: "5px",

        }),
    })

    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState<OnChangeValue<Option, true>>([]);

    const handleChange = (
        value: OnChangeValue<Option, true>,
        actionMeta: ActionMeta<Option>
    ) => {
        setValue(value);
    };

    const handleInputChange = (inputValue: any) => {
        setInputValue(inputValue);
    };

    const createOption = (label: string) => ({
        label,
        value: label,
    });

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setInputValue('');
                setValue([...value, createOption(inputValue)])
                event.preventDefault();
        }
    };

    const handleChangeSearch = (event: any) => {
        setValueFilter(event.target.value);
    };

    const components = {
        DropdownIndicator: null,
    };

    const getEvents = () => {
        Backend(
            MicroServices.Events,
            `/events/filtered?club_id=${props.infoClub.id || ''}`)
            .then((response) => response.json())
            .then((results) => {
                setListEvents(results.events)
            });
    };

    const handleDeleteEvent = (event_id: string) => {
        Backend(
            MicroServices.Events,
            `/event/${event_id || ''}`,
            {
                method: 'DELETE',
            })
            .then((response) => {
                if (response.status === 200) {
                    toast.success(t('toast_eventDeleted'));
                    setReloadEvent(!reloadEvent)
                }
                else {
                    toast.error(t('toast_somethingWrong'))
                }
            });
    }

    // const ClubTypes: { [k: string]: string | undefined } = {
    //     "Comp-10-2020": "Competition",
    //     "Field-Trip-10-2020": "Field Trip",
    //     "Fundraiser-10-2020": "Fundraiser",
    //     "Meeting-10-2020": "Meeting (Club)",
    //     "Practice-10-2020": "Practice/Rehearsal",
    //     "Retreat-10-2020": "Retreat",
    //     "Study-Hall-10-2020": "Study Hall",
    //     "Tournament-10-2020": "Tournament",
    //     "Tryout-10-2020": "Tryout (Club)",
    //     "Volunteer-10-2020": "Volunteering/Community Service",
    //     "Performance-10-2020": "Performance",
    //     "Other-10-2020": "Other",
    // };

    // const TeamTypes: { [k: string]: string | undefined } = {
    //     "Fundraiser-10-2020": "Fundraiser",
    //     "Comp-10-2020": "Game",
    //     "Meeting-10-2020": "Meeting (Team)",
    //     "Practice-10-2020": "Practice",
    //     "Retreat-10-2020": "Skills camp/Retreat",
    //     "Study-Hall-10-2020": "Study Hall",
    //     "Tournament-10-2020": "Tournament",
    //     "Tryout-10-2020": "Tryout (Team)",
    //     "Volunteer-10-2020": "Volunteering/Community Service",
    //     "Other-10-2020": "Other",
    // };

    // const SchoolTypes: { [k: string]: string | undefined } = {
    //     "Retreat-10-2020": "Conference/Retreat",
    //     "Dance-10-2020": "Dance",
    //     "Field-Trip-10-2020": "Field Trip",
    //     "Fundraiser-10-2020": "Fundraiser",
    //     "General-Assembly-10-2020": "General Assembly",
    //     "Open-House-10-2020": "Open House",
    //     "Orientation-10-2020": "Orientation",
    //     "Parent-Teacher-10-2020": "Parent/Teacher Meeting",
    //     "Pep-Rally-10-2020": "Pep Rally",
    //     "Study-Hall-10-2020": "Study Hall",
    //     "Workshop-10-2020": "Training/Workshop",
    //     "Volunteer-10-2020": "Volunteering/Community Service",
    //     "Other-10-2020": "Other",
    // };

    const eventTypes = useSelector((state:RootState)=>state.getAha.GetAhaEvents)

    useEffect(() => {
        if (props.infoClub) {
            getEvents()
        }
    }, [props.infoClub, reloadEvent])

    return (
        <div className="content-clubEvent">
            <div className="filterInputSearchEventClub" >
                <input className="inputSearchEventClub" type="text" placeholder={t('manageEvents__searchEvents')} onChange={(e) => handleChangeSearch(e)} />
            </div>
            {(props.isClubAdvisor) && (<button className="buttonCreateEvent" onClick={() => setCreateEvent(true)}> {t('infoEvents__createEvent')}  </button>)}
            {(createEvent) && (<ModalCreateEvent
                show={createEvent}
                onHide={handleCloseModal}
                isInsideClub={true}
                club={props.infoClub}
            />)}
            <label className="labelListEvents">{t('infoEvents__yourEvents')}</label>
            <table className="tableListClubEvents robotocondensed-bold-white-16px">
                <tr>
                    <th>
                        <div className="robotocondensed-bold-white-16px">{t('infoEvents__eventName')} </div>
                    </th>
                    <th>
                        <div className="robotocondensed-bold-white-16px">{t('infoEvents__location')}</div>
                    </th>
                    <th>
                        <div className="robotocondensed-bold-white-16px">{t('infoEvents__dates')}</div>
                    </th>
                    <th>
                        <div className="robotocondensed-bold-white-16px">{t('infoEvents__type')}</div>
                    </th>
                    <th>
                        <div className="robotocondensed-bold-white-16px">{t('infoEvents__reach')}</div>
                    </th>
                    <th>
                        <div className="robotocondensed-bold-white-16px">{t('infoEvents__actions')}</div>
                    </th>
                </tr>

                {listEvents.filter((item: any) => {
                    if (!valueFilter) return true;
                    if (item.name.toUpperCase().includes(valueFilter) || 
                        item.name.toLowerCase().includes(valueFilter) ||
                        item.name.includes(valueFilter)
                    ) {
                        return true;
                    }
                    return false;
                }).map((event: any) => (
                    <tr className="robotocondensed-normal-white-16px">
                        <td>
                            <div className="robotocondensed-normal-white-16px">
                                {event.name}
                            </div>
                        </td>
                        <td>
                            <div className="robotocondensed-normal-white-16px">
                                {event.meeting_place}
                            </div>
                        </td>
                        <td>
                            <div className="robotocondensed-normal-white-16px">
                                {t('infoEvents__dateStart')}<br />
                                {getTimeMoment(event.start)}
                                <br /><br />
                                {t('infoEvents__dateEnd')}<br />
                                {getTimeMoment(event.end)}
                            </div>
                        </td>
                        <td>
                            {(props.infoClub?.club_type === 'COMM' || props.infoClub?.club_type === 'CLUB') ?
                                eventTypes.club.find((item)=>item.label === event.type)?.label || 'Other'
                                :
                                eventTypes.team.find((item)=>item.label === event.type)?.label || 'Other'
                            }
                        </td>
                        <td>
                            <div className="robotocondensed-normal-white-16px">
                                {Array.isArray(event.reach) ?
                                    <ul>
                                        {event.reach.map((reach: any) => (
                                            <li>{capitalize(reach.split("_").join(" "))}</li>
                                        ))}
                                    </ul>
                                    :
                                    <ul>
                                        <li>{capitalize(event.reach.split("_").join(" "))}</li>
                                    </ul>
                                }
                            </div>
                        </td>
                        <td>
                            <div className="actionsEvents">
                                <FontAwesomeIcon
                                    icon="eye"
                                    size='xs'
                                    color={LAVENDER}
                                    className="view-user  "
                                    onClick={() => navigate(`/app/events/${event.timestamp}`)}
                                />

                                {(props.isClubAdvisor) &&
                                    (<FontAwesomeIcon
                                        icon="pen"
                                        size='xs'
                                        color={LAVENDER}
                                        className="update-user  "
                                        onClick={() => navigate(`/app/events/${event.timestamp}?editTrue=true`)}
                                    />
                                    )}

                                {(props.isClubAdvisor) &&
                                    (<FontAwesomeIcon
                                        icon="trash"
                                        size='xs'
                                        color={LAVENDER}
                                        className="paper-plane  "
                                        onClick={() => handleDeleteEvent(event.timestamp)}
                                    />
                                    )}

                            </div>
                        </td>
                    </tr>
                ))}
            </table>
        </div>
    );
}

export default Index;