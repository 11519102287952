import { useEffect, useState } from "react";
import JoditEditor from "jodit-react";
import NumericInput from "react-numeric-input";
import { useDispatch, useSelector } from "react-redux";
import { EventFormInterface, EventInterface } from "./ModalCreateEvent";
import "./Description.css";
import { getClubs, getClubsImAdv } from "../../store/actions/clubActions";
import DatePicker from "react-datepicker";
import moment, { Moment } from "moment";
import { RootState } from "../../store/store";
import Button from "react-bootstrap/Button";
import { TimePicker } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER, WHITE } from "../ClubIconsNavBar";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getEventTypes } from "../../store/actions/ahaActions";
import ClubInterface from "../../interfaces/ClubExpanded";
import MyTimePicker from "../../components/MyTimePicker"

toast.configure();

interface CreateEventDescriptionProps {
  onFocus: boolean;
  setOnFocus: (f: boolean) => void;
  event: EventInterface;
  setEvent: (e: EventInterface) => void;
  eventFormError: EventFormInterface;
  onHide: () => void;
  handleOnClickSave: () => void;
  isDisable: boolean;
  isInsideClub?: boolean;
  clubInside?: ClubInterface;
}

interface ReachCheckbox {
  value: boolean;
  name: string;
}

interface ReachCheckboxes {
  school: {
    SCHOOL: ReachCheckbox;
    [key: string]: ReachCheckbox;
  };
  club: {
    [key: string]: ReachCheckbox;
  };
}

const ReachCheckboxesBase = (org_type: string): ReachCheckboxes => {
  return {
    school: {
      SCHOOL: {
        value: false,
        name: org_type === 'YOUTHORG' ? "Entire Organization" : "Entire School",
      },
    },
    club: {
      CLUB_OFFICERS: {
        value: false,
        name: "Officers",
      },
      CLUB_MEMBERS: {
        value: false,
        name: "Members",
      },
      CLUB_FOLLOWERS: {
        value: false,
        name: "Followers",
      },
    },
  };
}


function CreateEventDescription({
  onFocus,
  setOnFocus,
  event,
  setEvent,
  eventFormError,
  onHide,
  handleOnClickSave,
  isDisable,
  isInsideClub,
  clubInside,
}: CreateEventDescriptionProps) {
  const { t, i18n } = useTranslation();
  const clubsOrg = useSelector((state: any) => state.getClub.getClubsOrg || []);
  const org = useSelector((state: RootState) => state.getOrgs.organizationInfo);
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const userRoles = useSelector((state: any) => state.getUser.userRoles);
  const communities = useSelector(
    (state: any) => state.getClub.GetCommunities || []
  );
  const clubsThatImAdvisor = useSelector(
    (state: any) => state.getClub.clubsImAdv || []
  );
  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  )

  const [clubs, setClubs] = useState([]);
  const [reachEvent, setReachEvent] = useState<ReachCheckboxes>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userRoles.includes("ZAA#ORGADMIN")) {
      dispatch(getClubs({
        pending: org.status.startsWith('PEND'),
        org_id: org.id,
        youthOrg: (organization.type === 'YOUTHORG') ? "true" : "false"
      }))
    }
    else {
      dispatch<any>(getClubsImAdv(userInfo.username));
    }
  }, []);

  const setRealClubs = () => {
    if (userRoles.includes("ZAA#ORGADMIN")) {
      setClubs(clubsOrg);
    } else {
      setClubs(clubsThatImAdvisor);
    }
  };

  useEffect(() => {
    setRealClubs();
  }, [clubsOrg, clubsThatImAdvisor]);

  useEffect(() => {
    if (communities && Array.isArray(communities)) {
      //console.log("communities", communities);
      setReachEvent({
        ...ReachCheckboxesBase(organization.type),
        school: {
          ...ReachCheckboxesBase(organization.type).school,
          ...communities.reduce(
            (acc, curr) => ({
              ...acc,
              [`SCHOOL#${curr.id}`]: {
                value: false,
                name: curr.name,
              },
            }),
            {}
          ),
        },
      });
    }
  }, [communities]);

  const [eventTypeSelection, setEventTypeSelection] = useState<'school' | 'club' | 'team'>('school')
  const eventTypes = useSelector((state: RootState) => state.getAha.GetAhaEvents)

  useEffect(() => {
    dispatch<any>(getEventTypes())
  }, [])

  // const ClubTypes: { [k: string]: string | undefined } = {
  //   Competition: "Comp-10-2020",
  //   "Field Trip": "Field-Trip-10-2020",
  //   Fundraiser: "Fundraiser-10-2020",
  //   "Meeting (Club)": "Meeting-10-2020",
  //   "Practice/Rehearsal": "Practice-10-2020",
  //   Retreat: "Retreat-10-2020",
  //   "Study Hall": "Study-Hall-10-2020",
  //   Tournament: "Tournament-10-2020",
  //   "Tryout (Club)": "Tryout-10-2020",
  //   "Volunteering/Community Service": "Volunteer-10-2020",
  //   Performance: "Performance-10-2020",
  //   Other: "Other-10-2020",
  // };

  // const TeamTypes: { [k: string]: string | undefined } = {
  //   Fundraiser: "Fundraiser-10-2020",
  //   Game: "Comp-10-2020",
  //   "Meeting (Team)": "Meeting-10-2020",
  //   Practice: "Practice-10-2020",
  //   "Skills camp/Retreat": "Retreat-10-2020",
  //   "Study Hall": "Study-Hall-10-2020",
  //   Tournament: "Tournament-10-2020",
  //   "Tryout (Team)": "Tryout-10-2020",
  //   "Volunteering/Community Service": "Volunteer-10-2020",
  //   Other: "Other-10-2020",
  // };

  // const SchoolTypes: { [k: string]: string | undefined } = {
  //   "Conference/Retreat": "Retreat-10-2020",
  //   Dance: "Dance-10-2020",
  //   "Field Trip": "Field-Trip-10-2020",
  //   Fundraiser: "Fundraiser-10-2020",
  //   "General Assembly": "General-Assembly-10-2020",
  //   "Open House": "Open-House-10-2020",
  //   Orientation: "Orientation-10-2020",
  //   "Parent/Teacher Meeting": "Parent-Teacher-10-2020",
  //   "Pep Rally": "Pep-Rally-10-2020",
  //   "Study Hall": "Study-Hall-10-2020",
  //   "Training/Workshop": "Workshop-10-2020",
  //   "Volunteering/Community Service": "Volunteer-10-2020",
  //   Other: "Other-10-2020",
  // };

  //const [eventTypes, setEventTypes] = useState<{ label: string, code: string }[]>(SchoolTypes);

  useEffect(() => {
    if (event.club_id === "0") {
      setEventTypeSelection('school');
      setEvent({
        ...event,
        category: "SCHOOL",
        reach: [],
        type: undefined,
      });
    } else {
      for (let club of clubs as any[]) {
        if (club.id === event.club_id) {
          if (club.club_type === "TEAM") {
            setEventTypeSelection('team');
            setEvent({
              ...event,
              category: "TEAM",
              type: undefined,
              reach: [],
            });
          } else {
            setEvent({
              ...event,
              category: "CLUB",
              type: undefined,
              reach: [],
            });
            setEventTypeSelection('club');
          }
          break;
        }
      }
    }
  }, [event.club_id]);

  function handleOnFocus() {
    setOnFocus(true);
  }

  function handleOnBlur() {
    if (event.name === "") {
      setOnFocus(false);
    }
  }

  function handleOnChange(e: any) {
    setEvent({
      ...event,
      ["name"]: e.target.value,
    });
  }

  function handleOnClubChange(e: any) {
    const clubId = e.target.value as string | undefined;
    setEvent({
      ...event,
      ["club_id"]: clubId,
    });
  }

  function handleOnBlurDescription(e: any) {
    setEvent({
      ...event,
      ["description"]: e,
    });
  }

  function handleOnChangeType(e: any) {
    setEvent({
      ...event,
      ["type"]: e.target.value,
    });
  }

  function handleOnChangeNumber(e: any) {
    setEvent({
      ...event,
      ["max_event_capacity"]: e,
    });
  }

  function handleOnChangeReach(e: any) {
    setEvent({
      ...event,
      ["reach"]: e.target.value,
    });
  }

  const handleOnClickCheckboxSchool = (name: string) => {
    if (!reachEvent) return;
    const newValue = !reachEvent.school[name].value;

    let newReach = [...event.reach];

    if (newValue) {
      newReach = [...newReach, name];
    } else {
      newReach.splice(newReach.indexOf(name), 1);
    }

    setEvent({
      ...event,
      reach: newReach,
    });
    setReachEvent({
      ...reachEvent,
      school: {
        ...reachEvent.school,
        [name]: {
          ...reachEvent.school[name],
          value: !reachEvent.school[name].value,
        },
      },
    });
  };

  const handleOnClickCheckboxClub = (name: string) => {
    if (!reachEvent) return;
    const newValue = !reachEvent.club[name].value;

    let newReach = [...event.reach];

    if (newValue) {
      newReach = [...newReach, name];
    } else {
      newReach.splice(newReach.indexOf(name), 1);
    }

    setEvent({
      ...event,
      reach: newReach,
    });
    setReachEvent({
      ...reachEvent,
      club: {
        ...reachEvent.club,
        [name]: {
          ...reachEvent.club[name],
          value: newValue,
        },
      },
    });
  };

  const getReachCheckbox = (
    name: string,
    value: string,
    checked: boolean,
    handleClick: (name: string) => void,
    disabled = false
  ) => (
    <div className="option-status CreateEventForm__reachCheck" key={name}>
      <label
        className={
          "content-input " +
          (disabled ? "CreateEventForm__reachCheckInputWrapper" : "")
        }
      >
        <input
          type="checkbox"
          name="button"
          className="CreateEventForm__reachCheckInput"
          value={value}
          onChange={() => disabled || handleClick(value)}
          checked={disabled || checked}
        />
        <i className={disabled ? "CreateEventForm__reachCheckBox" : ""} />
      </label>
      <label
        htmlFor="students"
        className={disabled ? "CreateEventForm__reachCheckName--disabled" : ""}
      >
        {name}
      </label>
    </div>
  );

  const getSchoolReachCheckboxes = () => (
    <>
      {reachEvent &&
        Object.entries(reachEvent.school).map(([key, value]) =>
          getReachCheckbox(
            value.name,
            key,
            value.value,
            handleOnClickCheckboxSchool,
            key !== "SCHOOL" && reachEvent.school.SCHOOL.value
          )
        )}
    </>
  );

  const getClubReachCheckboxes = () => (
    <>
      {reachEvent &&
        Object.entries(reachEvent.club).map(([key, value]) =>
          getReachCheckbox(
            value.name,
            key,
            value.value,
            handleOnClickCheckboxClub
          )
        )}
    </>
  );

  const getReach = () =>
    event.club_id === "0"
      ? getSchoolReachCheckboxes()
      : getClubReachCheckboxes();

  // FROM SCHEDULE

  //Time Helper
  //event.start/end = "AAAA-MM-DD-HH-MM"
  const getMomentTime = (mom: Moment): string => {
    if (mom === null) return "";
    let year: number | string = mom.year();
    let month: number | string = mom.month() + 1;
    let day: number | string = mom.toDate().getDate();
    let hours: number | string = mom.hours();
    let minutes: number | string = mom.minutes();
    month < 10 ? (month = `0${month}`) : (month = `${month}`);
    day < 10 ? (day = `0${day}`) : (day = `${day}`);
    hours < 10 ? (hours = `0${hours}`) : (hours = `${hours}`);
    minutes < 10 ? (minutes = `0${minutes}`) : (minutes = `${minutes}`);
    return `${year}-${month}-${day}-${hours}-${minutes}`;
  };

  const getTimeMoment = (time: string): Moment => {
    const time_parts = time.split("-");
    // console.log(time_parts);
    return moment(
      `${time_parts[0]}-${time_parts[1]}-${time_parts[2]} ${time_parts[3]}:${time_parts[4]
      }`
    );
  };

  const getMomentDate = (mom: Moment | undefined): Date | undefined => {
    return mom ? mom.toDate() : undefined;
  };

  const editMoment = (mom: Moment, date: Date): Moment => {
    let year: number | string = date.getFullYear();
    let month: number | string = date.getMonth() + 1;
    let day: number | string = date.getDate();
    let hours: number | string = mom.hours();
    let minutes: number | string = mom.minutes();
    month < 10 ? (month = `0${month}`) : (month = `${month}`);
    day < 10 ? (day = `0${day}`) : (day = `${day}`);
    hours < 10 ? (hours = `0${hours}`) : (hours = `${hours}`);
    minutes < 10 ? (minutes = `0${minutes}`) : (minutes = `${minutes}`);
    return moment(`${year}-${month}-${day} ${hours}:${minutes}`);
  };

  const editMomentClock = (mom1: Moment, mom2: Moment): Moment => {
    if (mom1 === null || mom2 === null) return moment();
    let year: number | string = mom1.year();
    let month: number | string = mom1.month() + 1;
    let day: number | string = mom1.toDate().getDate();
    let hours: number | string = mom2.hours();
    let minutes: number | string = mom2.minutes();
    month < 10 ? (month = `0${month}`) : (month = `${month}`);
    day < 10 ? (day = `0${day}`) : (day = `${day}`);
    hours < 10 ? (hours = `0${hours}`) : (hours = `${hours}`);
    minutes < 10 ? (minutes = `0${minutes}`) : (minutes = `${minutes}`);
    return moment(`${year}-${month}-${day} ${hours}:${minutes}`);
  };

  //States
  const [open, setOpen] = useState(false);
  const [startPicked, setStartPicked] = useState<boolean>(event.start !== "");
  const [endPicked, setEndPicked] = useState<boolean>(event.end !== "");
  const [start, setStart] = useState<Moment>(
    event.start !== "" ? getTimeMoment(event.start) : moment()
  );
  const [end, setEnd] = useState<Moment>(
    event.end !== "" ? getTimeMoment(event.end) : moment()
  );

  const timezones = useSelector(
    (state: RootState) =>
      state.getLocation.timezones || [
        {
          continent: "America",
          city: "Bogota",
          country: "CO",
          DST: "-05:00",
          STD: "-05:00",
        },
      ]
  );

  const getCurrentTimezone = (): string => {
    const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const continent = zone.split("/")[0];
    const city = zone.split("/")[1];
    const tz = timezones.find(
      (item: any) => item.continent === continent && item.city === city
    );
    let timezone_string = "";
    if (tz) {
      timezone_string = `${tz.continent}#${tz.city}#${tz.STD}`;
    }
    setEvent({
      ...event,
      timezone:
        timezone_string !== "" ? timezone_string : "America#Bogota#-05:00",
    });
    if (tz) return timezone_string;
    else return "America#Bogota#-05:00";
  };

  const [timezone, setTimezone] = useState(
    event.timezone !== "" ? event.timezone : getCurrentTimezone()
  );

  //onChange handlers
  function handleOnStartDateChange(e: any) {
    const new_moment = editMoment(start, e);
    setEnd(new_moment)
    setStart(new_moment);
    setEvent({
      ...event,
      start: getMomentTime(new_moment),
    });
  }
  function handleOnEndDateChange(e: any) {
    const new_moment = editMoment(end, e);
    setEnd(new_moment);
    setEvent({
      ...event,
      end: getMomentTime(new_moment),
    });
  }
  function handleOnStartTimeChange(e: any) {
    // console.log(e)
    setStartPicked(e !== null);
    const new_moment = editMomentClock(start, e);
    if (new_moment < moment()) {
      toast.error(t('toast_invalidStartTime'))
      return;
    }

    handleOnEndTimeChange(editMomentClock(start, e).add(1, 'hours'), true)
    setStart(new_moment);


    setEvent({
      ...event,
      start: getMomentTime(e !== null ? new_moment : e),
      end: getMomentTime(e !== null ? editMomentClock(start, e).add(2, 'hours') : e),
    });
  }

  function handleOnEndTimeChange(e: any, triggeredFromStartTime = false) {

    setEndPicked(e !== null);
    let new_moment = editMomentClock(end, e);
    // console.log(new_moment)
    if (!triggeredFromStartTime) {
      if (new_moment < moment() || new_moment < start) {
        toast.error(t('toast_invalidEndTime'))
        new_moment = start.add(1, 'hours')
        setEnd(new_moment);
        setEvent({
          ...event,
          end: getMomentTime(e !== null ? new_moment : e),
        });
        return;
      }
    }
    setEnd(new_moment);
    setEvent({
      ...event,
      end: getMomentTime(e !== null ? new_moment : e),
    })
  }

  const handleOnTimezoneChange = (e: any) => {
    setTimezone(e.target.value);
    setEvent({
      ...event,
      timezone: e.target.value,
    });
  };

  const onChange = (time: any, timeString: any) => {
    // console.log(time, timeString);
  };

  return (
    <div className="infoMembers">
      {event.club_id !== "0" && (
        (isInsideClub) ? (
          <div className="selectAffiliated">
            <label htmlFor="">Club Name</label>
            <div className="ClubName-ClubInside-DescriptionEvent">
              {clubInside?.name || ''}
            </div>
          </div>
        ) : (
          <div className="selectAffiliated">
            <label htmlFor="">Select Club *</label>
            <div className="affiliated">
              <select
                name=""
                id=""
                onChange={handleOnClubChange}
                value={
                  event.club_id !== "0"
                    ? undefined
                    : `${event.club_id}`
                }
              >
                <option selected disabled>
                  Select a club
                </option>
                {clubs.map((club: any) => (
                  <option value={club.id}>{`${club.name}${club.YO_id && club.YO_id === organization.id ? ` - ${club.org_name}` : ''}`}</option>
                ))}
              </select>
            </div>
            {eventFormError.club_id.hasError && (
              <span className="CreateEventForm__error">
                {eventFormError.club_id.errorMsg}
              </span>
            )}
          </div>
        ))}
      <div className="selectAffiliated" tabIndex={0}>
        <label htmlFor="activityName" className={onFocus ? "focus" : "noFocus"}>
          {event.category === "SCHOOL" ? t('modalCreateEvent__eventName') : t('modalCreateEvent__activityName')}
        </label>
        <input
          type="text"
          id="activityName"
          value={event.name}
          className="inputActivityName"
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          maxLength={30}
        />
        {eventFormError.name.hasError && (
          <span className="CreateEventForm__error">
            {eventFormError.name.errorMsg}
          </span>
        )}
      </div>
      <div className="textareaActivity" tabIndex={0}>
        <h2>{t('modalCreateEvent__description')}</h2>
        <JoditEditor
          value={event.description}
          onBlur={handleOnBlurDescription}
          config={{
            buttons: ["bold", "italic", "underline"],
            readonly: false,
            toolbarAdaptive: false,
            showPlaceholder: false,
            minHeight: 100,
          }}
        />
        {eventFormError.description.hasError && (
          <span className="CreateEventForm__error">
            {eventFormError.description.errorMsg}
          </span>
        )}
      </div>
      <div className="EventModal__row">
        <div className="EventModal__input inputContainer">
          <label htmlFor="" className="titleInput">
            {t('modalCreateEvent__type')}
          </label>
          <div className="affiliated">
            <select
              tabIndex={0}
              name=""
              id=""
              onChange={handleOnChangeType}
              value={event.type}
            >
              <option selected disabled value={undefined}>
                Select a type
              </option>
              {eventTypes[eventTypeSelection].filter((type) => type.active).map((type) => (
                <option value={type.label}>{type.label}</option>
              ))}
            </select>
          </div>
          {eventFormError.type.hasError && (
            <span className="CreateEventForm__error">
              {eventFormError.type.errorMsg}
            </span>
          )}
        </div>
        <div className="EventModal__input inputContainer" tabIndex={0}>
          <label htmlFor="" className="titleInput">
            {t('modalCreateEvent__maxAttendees')}
          </label>
          <div className="inputContainerIcon">
            <FontAwesomeIcon
              icon="user-group"
              size="lg"
              color={WHITE}
              className="iconMaxAtt  "
            />
            <NumericInput
              className="form-control"
              value={
                event.max_event_capacity !== 0 ? event.max_event_capacity : ""
              }
              onChange={handleOnChangeNumber}
              min={0}
              style={{
                input: {
                  marginRight: 3,
                  paddingRight: 4,
                  textAlign: "left",
                  height: 38,
                  backgroundColor: "#2C2C2C",
                  width: "100%",
                  paddingLeft: "15px",
                  color: "white",
                },
                btnUp: {
                  display: "block",
                  width: "60px",
                  height: "19px",
                  boxShadow: "none",
                  top: 1,
                  right: 0,
                  color: "white",
                  fontSize: "28px",
                },
                btnDown: {
                  display: "block",
                  width: "60px",
                  height: "19px",
                  boxShadow: "none",
                  top: 18,
                  right: 0,
                  color: "white",
                },
                "input:focus": {
                  border: "1px inset #69C",
                  outline: "none",
                },
                arrowUp: {
                  borderBottomColor: "white",
                  marginBottom: 1,
                  display: "none",
                },
                arrowDown: {
                  borderTopColor: "white",
                  marginTop: 1,
                  display: "none",
                },
              }}
            />
          </div>
        </div>
      </div>
      <div className="EventModal__row">
        <div className="maximunMembers EventModal__input" tabIndex={0}>
          <label htmlFor="" className="titleInput">
            {t('modalCreateEvent__from')}
          </label>
          <div className="containerCalendar EventModal__containerCalendar">
            <DatePicker
              preventOpenOnFocus={true}
              selected={getMomentDate(start)}
              onChange={handleOnStartDateChange}
              minDate={new Date()}
              showDisabledMonthNavigation
            />
            <FontAwesomeIcon
              icon="calendar-alt"
              size="lg"
              color={WHITE}
              className="calendar-alt  "
            />
          </div>

          <div className="content-time">
            {/* <div className="contentTime" onClick={() => setOpen(true)}>
              <FontAwesomeIcon
                icon="clock"
                size="xl"
                color={WHITE}
                className="iconClock  "
              />
              <TimePicker
                onChange={handleOnStartTimeChange}
                defaultOpenValue={moment("2:00 PM", "h:mm a")}
                format={"h:mm a"}
                minuteStep={15}
                placeholder={""}
                use12Hours={true}
                value={startPicked ? start : undefined}
                showNow={false}
              />
            </div> */}
            {/* <p>
                          to
                      </p>
                      <div className="contentTime" onClick={() => setOpen(true)} >
                          
                          <TimePicker
                              showSecond={false}
                              popupClassName={'time-in'}
                              format={'h:mm a'}
                              use12Hours
                          />
                      </div> */}
          </div>
          <MyTimePicker icon="clock" handleClick={handleOnStartTimeChange as any} value={startPicked ? start : undefined} showToday={true} />
          {eventFormError.start.hasError && (
            <span className="CreateEventForm__error">
              {eventFormError.start.errorMsg}
            </span>
          )}
        </div>

        <div className="maximunMembers EventModal__input">
          <label htmlFor="" className="titleInput">
            {t('modalCreateEvent__to')}
          </label>
          <div className="containerCalendar EventModal__containerCalendar" tabIndex={0}>
            <DatePicker
              preventOpenOnFocus={true}
              selected={getMomentDate(end)}
              // selected={changeDateEnd ? changeDateEnd: getMomentDate(end)}
              onChange={handleOnEndDateChange}
              minDate={new Date()}
              showDisabledMonthNavigation
            />
            <FontAwesomeIcon
              icon="calendar-alt"
              size="lg"
              color={WHITE}
              className="calendar-alt  "
            />
          </div>
          <div className="content-time">

            {/* <div className="contentTime" onClick={() => setOpen(true)}>
              <FontAwesomeIcon
                icon="clock"
                size="xl"
                color={WHITE}
                className="iconClock  "
              />
              <TimePicker
                onChange={handleOnEndTimeChange as any}
                defaultOpenValue={moment("2:00 PM", "h:mm a")}
                format={"h:mm a"}
                minuteStep={15}
                placeholder={""}
                use12Hours={true}
                value={endPicked ? end : undefined}
                showNow={false}
              />
            </div> */}
            {/* <p>
                          to
                      </p>
                      <div className="contentTime" onClick={() => setOpen(true)} >
                          <FontAwesomeIcon
  icon="clock"
  size='xl'
  color={WHITE}
  className="iconClock  "
  
/>
                          <TimePicker
                              showSecond={false}
                              popupClassName={'time-in'}
                              format={'h:mm a'}
                              use12Hours
                          />
                      </div> */}

          </div>
          <MyTimePicker icon="clock" handleClick={handleOnEndTimeChange as any} value={endPicked ? end : undefined} />
          {eventFormError.end.hasError && (
            <span className="CreateEventForm__error">
              {eventFormError.end.errorMsg}
            </span>
          )}
        </div>
      </div>

      <div className="EventModal__row">
        <div className="EventModal__input">
          <label htmlFor="" className="titleInput">
            {t('modalCreateEvent__timeZone')}
          </label>
          <div className="affiliated">
            <select
              tabIndex={0}
              onChange={handleOnTimezoneChange}
              name=""
              id=""
              value={timezone}
            >
              <>
                {timezones.map((timez: any) => (
                  <>
                    <option
                      value={`${timez.continent}#${timez.city}#${timez.STD}`}
                    >{`(GTM${timez.STD}) ${timez.city} - ${timez.country
                      }`}</option>
                    {timez.DST !== timez.STD && (
                      <option
                        value={`${timez.continent}#${timez.city}#${timez.DST}`}
                      >{`(GTM${timez.DST}) Daylight Time ${timez.city} - ${timez.country
                        }`}</option>
                    )}
                  </>
                ))}
              </>
            </select>
          </div>
          {eventFormError.timezone.hasError && (
            <span className="CreateEventForm__error">
              {eventFormError.timezone.errorMsg}
            </span>
          )}
        </div>
        <div className="meeting-location EventModal__input" tabIndex={0}>
          <label htmlFor="" className="titleInput">
            {t('modalCreateEvent__meetingLocation')}
          </label>
          <div className="containersInput">
            <FontAwesomeIcon
              icon="map-marker-alt"
              size="lg"
              color={WHITE}
              className="map-marker-alt  "
            />
            <input
              type="text"
              value={event.meeting_place}
              onChange={(e) =>
                setEvent({ ...event, meeting_place: e.target.value })
              }
            />
          </div>
          {eventFormError.meeting_place.hasError && (
            <span className="CreateEventForm__error">
              {eventFormError.meeting_place.errorMsg}
            </span>
          )}
        </div>
      </div>

      <div className="inputContainer" tabIndex={0}>
        <label htmlFor="" className="titleInput">
          {t('modalCreateEvent__reach')}
        </label>
        <div className="affiliated CreateEventForm__reachWrapper">
          {getReach()}

          {/* <select
            name=""
            id=""
            onChange={handleOnChangeReach}
            value={event.reach}
          >
            {!event.club_id || event.club_id === "0" ? (
              <>
                <option value="SCHOOL">Entire School</option>
                {(userRoles.includes('ZAA#ORGADMIN')) && (<option value="SCHOOL">All school</option>)}
                <option value="SCHOOL#CLASS">Classes</option>
                <option value="SCHOOL#STAFF">Staff</option>
                <option value="SCHOOL#GUARDIAN">Guardians</option>
              </>
            ) : (
              <>
                <option value="CLUB_OFFICERS">Officers</option>
                <option value="CLUB_MEMBERS">Members</option>
                <option value="CLUB_FOLLOWERS">Followers</option>
              </>
            )}
          </select> */}
        </div>
        {eventFormError.reach.hasError && (
          <span className="CreateEventForm__error">
            {eventFormError.reach.errorMsg}
          </span>
        )}
      </div>
      <div className="createEvent__selectTypeActions">
        <Button tabIndex={0} className={`buttonClose createEvent__btn ${isDisable ? 'btnProcess' : ''}`} onClick={onHide}>
          {t('button__cancel')}
        </Button>
        <Button
          tabIndex={0}
          className={`buttonSend createEvent__btn ${isDisable ? 'btnProcess' : ''}`}
          onClick={handleOnClickSave}
        // disabled={event.type===undefined}
        >
          {isDisable ? (
            <div className="icon-spinner-third buttonSendSignUp" />
          ) : (
            `${t('button__save')}`
          )}
        </Button>
      </div>
    </div>
  );
}

export default CreateEventDescription;
