import OutsideClickHandler from "react-outside-click-handler";
import Select from "react-select";
import "./styles.scoped.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';

const Index = () => {

  return (
    <>
      <table className="tableDistricts robotocondensed-bold-white-16px">
        <tr>
          <th>
            <div>
              ID
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "
              />
            </div>
          </th>
          <th>
            <div>
              Name
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "
              />
            </div>
          </th>
          <th>
            <div>
              Zip
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "
              />
            </div>
          </th>
          <th>
            <div>
              Country
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "
              />
            </div>
          </th>
          <th>
            <div>
              Street
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "
              />
            </div>
          </th>
          <th>
            <div>
              City
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "
              />
            </div>
          </th>
          <th>
            <div>
              State
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "
              />
            </div>
          </th>
          <th>Actions</th>
        </tr>
        {/* {templateClubsAll.filter((item: any) => {
          if (!searchSelected) return true;
          if (item.name.toUpperCase().includes(searchSelected) || item.name.toLowerCase().includes(searchSelected)) {
            return true;
          }
          return false;
        }).map((templateClub: any) => (
          <TemplateClub templateClub={templateClub} />
        ))} */}
      </table>
    </>
  );
};

export default Index;
