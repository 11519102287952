import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  RobotocondensedRegularNormalWhite16,
  Fontawesome5proRegularNormalLavende,
} from "../../styledMixins";
import "./styles.css";
import "../../styles.css";
import Select, { SingleValue } from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import PageNumber from "../PageNumber";
import AdminFlags, { flag } from "../AdminFlags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EventInterface } from "../EventSelectBar/ModalCreateEvent";
import EventsListAdmin from "../EventsListAdmin";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SchoolListYouth from "../SchoolListYouth";
import { RootState } from "../../store/store";
import { getSchoolsYouth } from "../../store/actions/orgsActions";
import { Backend, MicroServices } from "../../helpers/backendHelper";

toast.configure();

const PAGE_SIZES: pageSize[] = [10, 50];
export type pageSize = 10 | 50 | 10000;

function MySchoolsYouth() {

  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<pageSize>(PAGE_SIZES[0]);

  const [reloadSchool, setReloadSchool] = useState(false);
  const schools = useSelector((state: RootState) => state.getOrgs.schoolsYouth);
  
  const [valueFilter, setValueFilter] = useState("");

  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  );

  const getEventsOrg = () => {
    let ok = false;

    /*Backend(
      MicroServices.Events,
      `/events/filtered?org_id=${organization.id || ''}`)
        .then((response) => {
          if(response.status === 200){
            ok = true;
          }
          return response.json()
        })
        .then((results) => {
          if(results.statusCode && results.statusCode !== 200){
            ok = false;
          }

          if(ok){
            setSchools(results.schools)
          }
          else{
            setSchools([]);
            console.log('Error getting Schools', results)
          }
        })
        .catch((error) => {
          console.log(error)
        });*/ //@todo 
  };

  useEffect(() => {
    getEventsOrg();
    dispatch(getSchoolsYouth(organization.id));
    
  }, [])

  const handleSetPageSize = (newValue: SingleValue<{ label: string; value: pageSize; }>) => {
    setPageSize(newValue?.value || 10)
  }

  const customStyles = () => ({
    option: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#383838',
    }),
    input: (styles: any, state: any) => ({
      ...styles,
      border: 'none',
      color: 'white',
      fontWeight: "normal",
      paddingLeft: "5px",
      "&:focus": {backgroundColor: 'red'},
    }),
    container: (styles: any) => ({
      ...styles,
      padding: 0,
    }),
    valueContainer: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: 'transparent',
      padding: 0,
    }),
    menu: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      backgroundColor: "#383838",
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      textAlign: "left",
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "118px",
      borderRadius: "0px",
      boxShadow: "none",
      border: 0,
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "bold",
      maxWidth: "200px",
      marginBottom: "5px",
      backgroundColor: 'transparent',
      // textDecoration: outside ? 'underline' : 'none',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: 'white',
      textDecoration: "underline",
      display: 'block',
    }),
    indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
    dropdownIndicator: (styles: any) => ({ ...styles, display: "none" }),
    noOptionsMessage: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: 'grey',
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "normal",
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: 'transparent',
      border: "none",
      paddingLeft: "5px",
      paddingRight: "5px",
      
    }),
  })

  const viewingOptions = PAGE_SIZES.map((item) => ({
    label: `${item} results`,
    value: item,
  }))

  const canGoBack = () => page !== 0;
  const canGoNext = () => true;

  const goBack = () => {
    setPage(page - 1);
  };

  const goNext = () => {
    setPage(page + 1);
  };

  const handleChangeSearch = (e: any) => {
    setValueFilter(e.target.value);
  };

  return (
    
        <div className="content-org">
          <TopColumn>
            <ManageUsersCol>
              <Title>Manage Schools</Title>
              <div className="shwo-view-row">
                <ShowingItems>
                  <span className="robotocondensed-normal-white-16px">
                    Showing{' '}
                  </span>
                  <span className="robotocondensed-bold-white-16px">
                    {(pageSize*page) + 1}-{pageSize*(page + 1)}{' '}
                  </span>
                  <span className="robotocondensed-normal-white-16px">
                    of{' '}
                  </span>
                  <span className="robotocondensed-bold-white-16px">
                    {schools.length}{' '}
                  </span>
                  <span className="robotocondensed-normal-white-16px">
                    schools
                  </span>
                </ShowingItems>
                <Viewing>
                <div className="filterInputSearchEventAdmin" >
                    <input className="inputSearchEventAdmin" 
                          type="text" 
                          placeholder="Search Events" 
                          onChange={(e) => handleChangeSearch(e)} />
                </div>
                  <Viewing1>Viewing</Viewing1>
                  <Box>
                    <Select
                      value={{value: pageSize, label: `${pageSize} results`}}
                      isMulti={false}
                      options={viewingOptions}
                      placeholder={"View"}
                      styles={customStyles()}
                      onChange={handleSetPageSize}
                    />
                    <ChevronDown />
                  </Box>
                </Viewing>
              </div>
              
              
            </ManageUsersCol>
            
          </TopColumn>
          <SchoolListYouth
            valueFilter={valueFilter}
          />
          <PageNumber
            page={page}
            canGoBack={canGoBack()}
            canGoNext={canGoNext()}
            goBack={goBack}
            goNext={goNext}
          />
        </div>   
  );
}

const TopColumn = styled.div`
  height: 154px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
  justify-content: space-between;
`;

const ManageUsersCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.h1`
  min-height: 28px;
  font-family: var(--font-family-roboto_condensed);
  font-weight: 700;
  color: var(--lavender);
  font-size: var(--font-size-xxxl);
  letter-spacing: 0;
  padding-bottom: 25px;
`;

const ShowingItems = styled.p`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  align-self: flex-start;
  margin-top: 5px;
  letter-spacing: 0;
  align-self: center;
  text-align: center;
`;

const Viewing = styled.div`
  margin-left: 3px;
  display: flex;
  align-items: center;
  min-width: 197px;
  align-self: flex-end;
  font-family: var(--font-family-roboto_condensed);
`;

const Viewing1 = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  min-width: 50px;
  letter-spacing: 0;
`;

const Box = styled.div`
  height: 40px;
  margin-left: 17px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  min-width: 130px;
  background-color: var(--violent-violet);
`;

const ChevronDownWrapper = styled.div`
  ${Fontawesome5proRegularNormalLavende}
  min-height: 20px;
  margin-left: 27px;
  min-width: 18px;
  letter-spacing: 0;
`;

const ChevronDown = () => <ChevronDownWrapper>
  <FontAwesomeIcon icon="chevron-down" color="#FFFFFF" size="sm"/>
</ChevronDownWrapper>

export default MySchoolsYouth;
