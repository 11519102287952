import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  RobotocondensedRegularNormalWhite16,
  Fontawesome5proRegularNormalLavende,
  RobotocondensedBoldLavender16px,
} from "../../styledMixins";
import "./styles.css";
import OrganizationsTable from "../OrganizationsTable";
import "../../styles.css";
import Select, { SingleValue } from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationNames, getOrganizations, getOrganizationStatus, getOrganizationsWithLimit, getOrganizationType } from "../../store/actions/orgsActions";
import PageNumber from "../PageNumber";
import { getActiveCities, getActiveCountries } from "../../store/actions/locationActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PAGE_SIZES: pageSize[] = [10, 50];
export type pageSize = 10 | 50 | 10000;

function Organizations() {

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<pageSize>(PAGE_SIZES[0]);

  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.getUser.token);
  const organizations = useSelector((state: any) => state.getOrgs.organizationNames);
  console.log(organizations.length);
  const lastEvaluatedkeys = useSelector((state: any) => state.getOrgs.lastEvaluatedkeys);

  useEffect(() => {
    dispatch(getOrganizations(token));
    dispatch(getOrganizationsWithLimit(token, pageSize));
    dispatch(getOrganizationStatus());
    dispatch(getOrganizationType());
    dispatch(getOrganizationNames());
    dispatch(getActiveCities());
    dispatch(getActiveCountries());
  }, []);

  useEffect(() => {
    if (page === 0) {
      dispatch(getOrganizationsWithLimit(token, pageSize, page))
    } else {
      dispatch(getOrganizationsWithLimit(token, pageSize, page, lastEvaluatedkeys[page]))
    }
  }, [pageSize, page]);

  const handleSetPageSize = (newValue: SingleValue<{ label: string; value: pageSize; }>) => {
    setPageSize(newValue?.value || 10)
  }

  const customStyles = () => ({
    option: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#383838',
    }),
    input: (styles: any, state: any) => ({
      ...styles,
      border: 'none',
      color: 'white',
      fontWeight: "normal",
      paddingLeft: "5px",
      "&:focus": {backgroundColor: 'red'},
    }),
    container: (styles: any) => ({
      ...styles,
      padding: 0,
    }),
    valueContainer: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: 'transparent',
      padding: 0,
    }),
    menu: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      backgroundColor: "#383838",
      fontFamily: "var(--font-family-roboto_condensed)",
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      textAlign: "left",
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "118px",
      borderRadius: "0px",
      boxShadow: "none",
      border: 0,
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "bold",
      maxWidth: "200px",
      marginBottom: "5px",
      backgroundColor: 'transparent',
      // textDecoration: outside ? 'underline' : 'none',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: 'white',
      textDecoration: "underline",
      display: 'block',
    }),
    indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
    dropdownIndicator: (styles: any) => ({ ...styles, display: "none" }),
    noOptionsMessage: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: 'grey',
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "normal",
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: 'transparent',
      border: "none",
      paddingLeft: "5px",
      paddingRight: "5px",
      // display: input ? 'none' : 'block',
      
    }),
  })

  const viewingOptions = PAGE_SIZES.map((item) => ({
    label: `${item} results`,
    value: item,
  }))

  const canGoBack = () => page !== 0;
  const canGoNext = () => true;

  const goBack = () => {
    setPage(page - 1);
  };

  const goNext = () => {
    setPage(page + 1);
  };

  const userRoles = useSelector((state: any) => state.getUser.userRoles)

  return (
        <ContentOrganizations>
          <FlexRow>
            <FlexCol>
              <Title>Organizations</Title>
              <Showing112Of864Items>
                <span className="robotocondensed-normal-white-16px">
                  Showing{' '}
                </span>
                <span className="robotocondensed-bold-white-16px">
                  {(pageSize*page) + 1}-{pageSize*(page + 1)}{' '}
                </span>
                <span className="robotocondensed-normal-white-16px">
                  of{' '}
                </span>
                <span className="robotocondensed-bold-white-16px">
                  {organizations.length}{' '}
                </span>
                <span className="robotocondensed-normal-white-16px">
                  organizations
                </span>
              </Showing112Of864Items>
            </FlexCol>
            <FlexCol1>
              <Viewing>
                <Viewing1>Viewing</Viewing1>
                <Box>
                  <Select
                    value={{value: pageSize, label: `${pageSize} results`}}
                    isMulti={false}
                    options={viewingOptions}
                    placeholder={"View"}
                    styles={customStyles()}
                    onChange={handleSetPageSize}
                  />
                  <ChevronDown />
                </Box>
              </Viewing>
            </FlexCol1>
          </FlexRow>
          <OrganizationsTable pageSize={pageSize} page={page}/>
          <PageNumber
            page={page}
            canGoBack={canGoBack()}
            canGoNext={canGoNext()}
            goBack={goBack}
            goNext={goNext}
          />
        </ContentOrganizations>
  );
}

const ContentOrganizations = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  width: 100%;
  z-index: 1;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  align-items: center;
  min-height: 944px;
  background-color: var(--violet);
  padding-left: 152px;
`;

const ContentUsers = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  width: 1298px;
  z-index: 1;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  align-items: center;
  min-height: 944px;
  background-color: var(--violet);
`;

const FlexRow = styled.div`
  height: 125px;
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding: 0 15px;
  justify-content: space-between;
`;

const FlexCol = styled.div`
  width: 182px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 93px;
`;

const Title = styled.h1`
  min-height: 28px;
  font-family: var(--font-family-roboto_condensed);
  font-weight: 700;
  color: var(--lavender);
  font-size: var(--font-size-xxxl);
  letter-spacing: 0;
  padding-bottom: 30px;
`;

const Showing112Of864Items = styled.p`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  align-self: flex-end;
  margin-top: 30px;
  letter-spacing: 0;
`;

const FlexCol1 = styled.div`
  width: 200px;
  position: relative;
  margin-left: 868px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 104px;
  justify-content: flex-end;
`;

const Viewing = styled.div`
  margin-left: 3px;
  display: flex;
  align-items: center;
  min-width: 197px;
  align-self: flex-end;
`;

const Viewing1 = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  min-width: 50px;
  letter-spacing: 0;
  font-family: var(--font-family-roboto_condensed);
`;

const Box = styled.div`
  height: 40px;
  margin-left: 17px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  min-width: 130px;
  background-color: var(--violent-violet);
`;

const Address = styled.div`
  ${RobotocondensedBoldLavender16px}
  min-height: 19px;
  min-width: 65px;
  letter-spacing: 0;
`;

const ChevronDownWrapper = styled.div`
  ${Fontawesome5proRegularNormalLavende}
  min-height: 20px;
  margin-left: 27px;
  min-width: 18px;
  letter-spacing: 0;
`;

const ChevronDown = () => <ChevronDownWrapper>
  <FontAwesomeIcon icon="chevron-down" color="#FFFFFF" size="sm"/>
</ChevronDownWrapper>

/* const PgNumber = styled.div`
  margin-top: 438px;
  display: flex;  
  padding: 0 30px;
  align-items: center;
  min-width: 360px;
`; */

export default Organizations;
