import { useMemo } from "react";
import { useSelector } from "react-redux";
import OrganizationComplete from "../interfaces/organization/OrganizationComplete";
import { RootState } from "../store/store";

const getGradesList = (low: number, high: number) =>
  [...Array(1 + high - low).keys()].map((key) => `${key + low}`);

const useGradesList = () => {
  const organization: OrganizationComplete = useSelector(
    (state: RootState) => state.getOrgs.organizationInfo
  );

  const gradesList = useMemo(
    () =>
      getGradesList(organization?.lowGrade || 1, organization?.highGrade || 0),
    [organization]
  );

  return gradesList;
};

export default useGradesList;