import React from 'react';

interface LoginBtnProps {
    onClick: () => void,
    message: string,
    extraStyles?: {[key: string]: string | number},
}

const LoginBtn = ({onClick, message}: LoginBtnProps) => (
    <div className="button-container">
        <span>{message}</span> 
    </div>
);

export default LoginBtn;