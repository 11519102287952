import "../../containers/SetupSchool/styles.scoped.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeams } from "../../store/actions/templateClubActions";
import { RootState } from "../../store/store";
import ListTeams from "./ListTeams";
import ListTeamsSetup from "./ListTeamsSetup";
import ModalTeam, { Team } from "./ModalTeam";
import ModalTeamSetup from "./ModalTeamSetup";
import TemplateTeam from "../../interfaces/template-clubs/templateTeam";
import SelectedTeamsList from "./Tabs/SelectedTeamsList";
import SetupTeams from "./SetupTeams";
import OrganizationComplete from "../../interfaces/organization/OrganizationComplete";
import { ClubCreate } from "./Clubs";
import { SelectedTeams } from "../../containers/SetupSchool";
import { toast } from "react-toastify";
import { Advisor } from "./Communities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../ClubIconsNavBar";
import ModalLoading from "./ModalLoading";
import ModalKillClub from "./ModalKillClub";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import TClub from "../../interfaces/TClubs/TClub/TClub";

interface TeamProps {
  organization?: OrganizationComplete,
  handleOnClick: (e: any, step: number) => void,
  updateCommInvites: () => void,
  setTeams: (e: any) => void,
  teams: ClubCreate[],
  savedTeams: string[],
  setSavedTeams: (e: any) => void,
  setSelectedTeams: (e: any) => void,
  selectedTeams: SelectedTeams,
  setTeamsEdit: (e: any) => void,
  teamsEdit: { [key: string]: any[] },
  teamsCompleteEdit: any[],
  setTeamsCompleteEdit: (e: any) => void,
  teamsDelete: string[],
  setTeamsDelete: (e: any) => void,
  alreadyLoaded: { get: boolean, set: any },
  dynamoClubs: { get: any[], set: any }
}

const deleteAdv = (club_id: string, adv: Advisor): Promise<boolean> =>
  new Promise((resolve, reject) => {
    Backend(
      MicroServices.Invite,
      `/invites/kill`,
      {
        method: "DELETE",
        body: JSON.stringify({
          club_id: club_id,
          email: adv.email
        }),
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then((response) => {
        if (response.status === 201) {
          resolve(true)
        } else {
          resolve(false)
        }
      })
      .catch((error) => {
        resolve(false)
        console.log(error)
      })
  })

const createAdv = (club_id: string, adv: Advisor, organization: any): Promise<boolean> =>
  new Promise((resolve, reject) => {
    Backend(
      MicroServices.Invite,
      `/invites`,
      {
        method: 'POST',
        body: JSON.stringify({
          email: adv.email,
          org_id: organization?.id,
          club_id: club_id,
          is_advisor: true,
          firstName: adv.firstName,
          lastName: adv.lastName,
        })
      }
    )
      .then((results) => {
        resolve(results.status === 201)
      })
      .catch((error) => {
        resolve(false)
        console.log(error)
      })
  })

const updateAdvisors = (club_id: string, prevAdv: Advisor[], newAdv: Advisor[], organization: any): Promise<boolean> =>
  new Promise((resolve, reject) => {
    const advisorsToDelete = prevAdv.filter((adv) => newAdv.find((item) => adv.email === item.email) === undefined)
    const advisorsToCreate = newAdv.filter((adv) => prevAdv.find((item) => adv.email === item.email) === undefined)
    const promises: Promise<boolean>[] = []

    advisorsToCreate.forEach((adv) => promises.push(createAdv(club_id, adv, organization)))
    advisorsToDelete.forEach((adv) => promises.push(deleteAdv(club_id, adv)))

    Promise.all(promises)
      .then((results) => {
        resolve(results.reduce((acc, curr) => acc && curr, true))
      })
      .catch((error) => reject(error))
  })

const editTeam = (club: any, organization: any): Promise<boolean> =>
  new Promise((resolve, reject) => {
    Backend(
      MicroServices.Club,
      `/clubs/${club.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: club.id,
          org_id: organization?.id,
          name: club.name,
          template_id: club.template_id,
          member_type: club.member_type,
          maximumNumberMembers: club.maximumNumberMembers,
          gendersAllowed: club.gendersAllowed,
          gradesAllowed: club.gradesAllowed,
          //advisors: club.advisors.map((item)=>item.email),
          description: club.description,
          //created: true,
          skills_learned: club.skills,
          participateInternationalCompetitions: club.international,
          participateNationalCompetitions: club.national,
          participateRegionalCompetitions: club.regional,
          participateLocalCompetitions: club.local,
          applicationRequired: club.applicationRequired,
          clubDuesRequired: club.clubDuesRequired,
          meetingDays: club.meetingDays,
          meetingStartTime: club.meetingStart,
          meetingEndTime: club.meetingEnd,
          timezone: club.timezone,
          meetingFrequency: club.meetingFrequency,
          meetingLocation: club.meetingLocation,
          status: club.status,
          privacy: club.privacy,
        }),
      })
      .then((response) => response.json())
      .then((results) => {
        resolve(results !== undefined)
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
  })

const deleteTeam = (club_id: string, organization: any): Promise<boolean> =>
  new Promise((resolve, reject) => {
    Backend(
      MicroServices.Club,
      `/clubs/${club_id}/kill`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then((response) => resolve(response.status === 201))
      .catch((error) => {
        console.log(error)
        reject(error)
      })
  })

const saveTeamAndAdv = (club: ClubCreate, organization: any): Promise<boolean> =>
  new Promise((resolve, rejects) => {
    Backend(
      MicroServices.Club,
      `/clubs?pending=${organization?.status.startsWith('PEND')}`, {
      method: 'POST',
      body: JSON.stringify({
        org_id: organization?.id,
        name: club.name,
        template_id: !club.template_id.startsWith('NONE') ? club.template_id : '',
        member_type: club.member_type,
        maximumNumberMembers: club.maximumNumberMembers,
        gendersAllowed: club.gendersAllowed,
        gradesAllowed: club.gradesAllowed,
        //advisors: club.advisors.map((item)=>item.email),
        description: club.description,
        //created: true,
        skills_learned: club.skills,
        participateInternationalCompetitions: club.international,
        participateNationalCompetitions: club.national,
        participateRegionalCompetitions: club.regional,
        participateLocalCompetitions: club.local,
        applicationRequired: club.applicationRequired,
        clubDuesRequired: club.clubDuesRequired,
        meetingDays: club.meetingDays,
        meetingStartTime: club.meetingStart,
        meetingEndTime: club.meetingEnd,
        timezone: club.timezone,
        meetingFrequency: club.meetingFrequency,
        meetingLocation: club.meetingLocation,
        //"status": props?.organization?.status.startsWith('PEND') ? 'PENDING' : 'ACTIVE',
        team_distinguisher: !club.template_id.startsWith('NONE') ? club.team_distinguisher : 'Team-Custom',
      }),
    })
      .then((results) => results.json())
      .then((results) => {
        const club_id = results.id
        const promises: any[] = []
        club.advisors.forEach((adv) => promises.push(
          Backend(
            MicroServices.Invite,
            `/invites`,
            {
              method: 'POST',
              body: JSON.stringify({
                email: adv.email,
                org_id: organization?.id,
                club_id: club_id,
                is_advisor: true,
                firstName: adv.firstName,
                lastName: adv.lastName,
              })
            }
          )))
        return Promise.all(promises)
      })
      .then((results) => {
        resolve(results.reduce((acc, curr) => acc && curr.status === 201, true))
      })
      .catch((error) => {
        console.log(error)
        rejects(error)
      })
  })

export const castTeamDistinguisherToID = (team_distinguisher: string | undefined): string => {
  if (!team_distinguisher) {
    //YO created teams
    return 'Cteam0'
  }

  let teamID = ''
  team_distinguisher.includes('Freshman') ? teamID = '1' :
    team_distinguisher.includes('JV') ? teamID = '2' :
      team_distinguisher.includes('Varsity') ? teamID = '3' :
        teamID = '0'

  let team_gender = ''
  team_distinguisher.includes('Girls') ? team_gender = 'Fteam' :
    team_distinguisher.includes('Boys') ? team_gender = 'Mteam' :
      team_distinguisher.includes('Coed') ? team_gender = 'Cteam' :
        team_gender = 'Cteam'

  return `${team_gender}${teamID}`

}

export const castTeamIdToDistinguisher = (teamID: string | undefined): string => {
  if (!teamID) return ''

  let team_distinguisher = ''
  switch (teamID.slice(-1)) {
    case "1":
      team_distinguisher = 'Freshman'
      break
    case "2":
      team_distinguisher = 'JV'
      break
    case "3":
      team_distinguisher = 'Varsity'
      break
    default:
      team_distinguisher = 'None'
      break
  }

  let team_gender = ''
  teamID.includes('Fteam') ? team_gender = 'Girls' :
    teamID.includes('Mteam') ? team_gender = 'Boys' :
      teamID.includes('Cteam') ? team_gender = 'Coed' :
        team_gender = 'Unknown'

  return `${team_distinguisher}-${team_gender}`
}

export const castTemplateIdToTemplateName = (template_id: string, tteams: TClub[]): string => {
  const tteam = tteams.find((tt) => tt.id === template_id)
  return  tteam?.TCLists?.[0]?.assignedName || tteam?.name || template_id
}

export const getSavedToShow = (dynamoClubs: any, teamsDelete: string[]): any[] => {
  return dynamoClubs?.filter(
    (club: any) => !teamsDelete.includes(`${club.id}#${club.template_id}#${club.team_distinguisher}`))?.filter(
      (club: any) => !teamsDelete.includes(`${club.id}#DELETED#DELETED`)) || []
}

function Index(props: TeamProps) {
  const { t, i18n } = useTranslation();
  const [showSetupTeam, setShowSetupTeam] = useState(false);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [starSetupClub, setStarSetupClub] = useState(false);
  const [goToSetup, setGoToSetup] = useState(false);
  const [teamActive, setTeamActive] = useState(0);
  const [teamSelected, setTeamSelected] = useState<Team>();
  const [loadingTeams, setLoadingTeams] = useState(false);
  const [change, setChange] = useState<boolean>(true)
  const [teamToEdit, setTeamToEdit] = useState<any | undefined>(undefined)
  const [modalTeamType, setModalTeamType] = useState<'Create' | 'Edit' | 'SaveEdit'>('Create')
  const [prevAdvisors, setPrevAdvisors] = useState<{ [id: string]: Advisor[] }>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingProtection, setLoadingProtection] = useState<boolean>(false)
  const [createCustom, setCreateCustom] = useState<boolean>(false)
  const [showContinueButton, setShowContinueButton] = useState<boolean>(false)

  const dispatch = useDispatch();

  const tteams = useSelector((state: RootState) => state.getTClubs.TTeam);

  const teams: TemplateTeam[] = useSelector(
    (state: RootState) => state.getTemplateClubs.Teams || []
  );

  const [listTeams, setListTeams] = useState<TClub[]>(tteams);

  //Saved Info
  const getSavedInfo = () => {
    if (props.organization) {
      Backend(
        MicroServices.Admin,
        `/organizations/${props.organization.id}/saved-info?type=TEAM`)
        .then((response) => response.json())
        .then((results) => {
          setPrevAdvisors(results.ClubAdvisors)
          props.dynamoClubs.set(results.DynamoClubs)
        })
        .catch(() => toast.error(t('toast_errorLoadingSaved')))
        .finally(() => {
          props.alreadyLoaded.set(true)
          setLoading(false)
        })
    }
  };
  useEffect(() => {
    if (!props.alreadyLoaded.get) { getSavedInfo() }
    else { setLoading(false) }
  }, [])
  useEffect(() => {
    const selectedCopy = { ...props.selectedTeams }
    for (let team of props.dynamoClubs.get) {
      if (!team.template_id.startsWith('NONE')) {
        if (!selectedCopy[`${team.template_id}`]) selectedCopy[`${team.template_id}`] = []

        const teamID = castTeamDistinguisherToID(team.team_distinguisher)
        const team_id = `${team.template_id}${teamID}`

        if (!selectedCopy[`${team.template_id}`].find((item) => item.id === team_id)) {
          selectedCopy[`${team.template_id}`].push({
            active: null, //null is for already saved teams on Dynamo
            template_id: team.template_id,
            gradesAllowed: team.gradesAllowed,
            title: team.name,
            gendersAllowed: team.gendersAllowed.length === 1 ? team.gendersAllowed : ['C'],
            id: team_id
          })
        }
      }
    }
    props.setSelectedTeams(selectedCopy)
  }, [props.dynamoClubs.get])

  useEffect(()=>{if(goToSetup)setChange(true)},[goToSetup])

  function handleCloseSetupTeam(team?: any) {
    if (!team) {
      setShowSetupTeam(false)
      setCreateCustom(false)
      return
    }
    switch (modalTeamType) {
      case 'Create':
        const teamAux = [...props.teams];
        if (team && createCustom) {
          teamAux.push({
            ...team,
            template_id: `NONE#${team.name}`,
            gendersAllowed: team.gendersAllowed,
            gradesAllowed: team.gradesAllowed,
            team_distinguisher: `None-Unknown`,
          });
        } else if (team && 'name' in team) {
          const setted_info = props.selectedTeams[team.template_id]?.find((item) => item.id.includes(castTeamDistinguisherToID(team.team_distinguisher)))
          teamAux.push({
            ...team,
            gradesAllowed: setted_info?.gradesAllowed || [],
            gendersAllowed: setted_info?.gendersAllowed ? (setted_info.gendersAllowed[0] === 'C' ? ['M', 'F', 'O', 'P'] : setted_info.gendersAllowed) : []
          });
        }
        setShowSetupTeam(false)
        setCreateCustom(false)
        props.setTeams(teamAux);
        break
      case 'Edit':
        const teamAuxEdit = [...props.teams];
        if (team && 'name' in team) {
          teamAuxEdit[teamAuxEdit.findIndex((item) => item.template_id === team.template_id && item.team_distinguisher === team.team_distinguisher)] = team!
        }
        setShowSetupTeam(false)
        setCreateCustom(false)
        props.setTeams(teamAuxEdit);
        break
      case 'SaveEdit':
        const teamAuxSaveEdit = [...props.teamsCompleteEdit]
        if (team && 'name' in team) {
          const idx = teamAuxSaveEdit.findIndex((item) => item.template_id === team.template_id && item.team_distinguisher === team.team_distinguisher)
          if (idx === -1) {
            teamAuxSaveEdit.push(team)
          } else {
            teamAuxSaveEdit[idx] = team
          }
        }
        setShowSetupTeam(false)
        setCreateCustom(false)
        props.setTeamsCompleteEdit(teamAuxSaveEdit);
        break
    }
  };

  useEffect(() => {
    dispatch(getTeams());
  }, []);

  function handleOnShow(e: any) {
    if (e) {
      setGoToSetup(true);
    }
  }

  function handleOnShowButtons(e: any) {
    if (e) {
      setStarSetupClub(true);
    }
  }

  const finderBool = (club: any, team: Team, tteam: TClub | undefined, customName?: string): boolean => {
    return (club.template_id === tteam?.id || club.template_id === customName) && team.id.includes(castTeamDistinguisherToID(club.team_distinguisher))
  }

  useEffect(()=>{
    if(change /*&& goToSetup*/){
      setShowContinueButton(true);
    }
  },[change])

  const handleOnClickClub = (e: any, title: any, team: Team, type: string, customName?: string) => {
    setShowContinueButton(true)
    setChange(true)
    const tteam = tteams.find(({ id }) => id === team.template_id);
    switch (type) {
      case 'Create':
        setTeamToEdit({
          name: title,
          template_id: tteam?.id,
          description: tteam?.description,
          skills_learned: tteam?.tags,
          member_type: tteam?.member_types
        })
        setModalTeamType(type)
        setTitle(title);
        setTeamSelected(team);
        setShowSetupTeam(true);
        break
      case 'Edit':
        setTeamToEdit(props.teams.find((club: any) => finderBool(club, team, tteam, customName)))
        setModalTeamType(type)
        setTitle(title);
        setTeamSelected(team);
        setShowSetupTeam(true);
        break
      case 'SaveEdit':
        const savedClubToEdit = props.teamsCompleteEdit.find((club: any) => finderBool(club, team, tteam, customName))
        setTeamToEdit(savedClubToEdit ?
          savedClubToEdit :
          props.dynamoClubs.get.find((club: any) => finderBool(club, team, tteam, customName)))
        setModalTeamType(type)
        setTitle(title);
        setTeamSelected(team);
        setShowSetupTeam(true);
        break
      case 'Delete':
        props.setTeams(props.teams?.filter((club: any) => !finderBool(club, team, tteam, customName))?.filter((club: any) => club.template_id !== `NONE#${customName}`))
        break
      case 'SaveDelete':
        setShowDeleteModal(true)
        setDeleteModalText({
          title: customName ? `Custom Club: ${customName}` : title,
          message: `Are you sure you want to delete ${customName ? customName : title}?`
        })
        setDeleteModalType({ type: 'one', info: customName ? {} : team })
        break
      case 'ReCreate':
        const teamRe = props.dynamoClubs.get.find((club: any) => finderBool(club, team, tteam, customName))
        props.setTeamsDelete(props.teamsDelete?.filter((item) => item !== `${teamRe?.id}#${teamRe?.template_id}#${castTeamIdToDistinguisher(team.id)}`))
        handleOnClickClub(e, title, team, 'SaveEdit')
        break
      default:
        break
    }
  };

  const saveTeams = (template_id: string, teams: Team[]) => {
    props.setSelectedTeams({
      ...props.selectedTeams,
      [template_id]: teams,
    });
  };

  const deleteTemplate = (template_id: string) => {
    const selectedTeamsCopy = { ...props.selectedTeams }
    delete selectedTeamsCopy[template_id]
    props.setSelectedTeams(selectedTeamsCopy)

    const teamsEditCopy = { ...props.teamsEdit }
    delete teamsEditCopy[template_id]
    props.setTeamsEdit(teamsEditCopy)

    props.setTeamsDelete([...new Set([...props.teamsDelete].concat(
      props.dynamoClubs.get?.filter((team) => team.template_id === template_id).map(
        (team) => `${team.id}#${team.template_id}#${team.team_distinguisher}`)
    ))])
  }

  const deleteSomeTemplate = (teams: Team[]) => {
    if (teams.length === 0) return
    const template_id = teams[0].template_id

    props.setTeamsDelete([...new Set([...props.teamsDelete].concat(
      props.dynamoClubs.get?.filter((team) => (team.template_id === template_id &&
        teams.findIndex(t => castTeamIdToDistinguisher(t.id) === team.team_distinguisher) !== -1)).map(
          (team) => `${team.id}#${team.template_id}#${team.team_distinguisher}`)
    ))])
  }

  function inputSearch(e: any) {
    const clubAuxSearch = [...tteams];
    var text = e;
    const newData = clubAuxSearch?.filter(function (item: any) {
      const itemData = item.name.toUpperCase();
      const textData = text.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });
    setListTeams(newData);
    // teams = newData
  }

  const handleSave = () => {
    setLoadingTeams(true);

    if (!teamsAreSetup()) {
      toast.error(t('toast_someTeamsAreMissing'))
      setLoadingTeams(false)
      return
    }


    Promise.all([
      ...props.teams.map((club) => saveTeamAndAdv(club, props.organization)),
      ...props.teamsCompleteEdit.map((club) => editTeam(club, props.organization)),
      ...props.teamsDelete.map((item) => deleteTeam(item.split('#')[0], props.organization)),
      ...props.teamsCompleteEdit.map((club) => updateAdvisors(club.id, prevAdvisors[club.id], club.advisors, props.organization))
    ])
      .catch((error) => {
        // handle error creating club
        toast.error(t('toast_thereWasAnErrorTeams'))
        executeFrontErrorProtection()
        console.log('Error creating teams', error);

      })
      .then((response) => {
        if (!response) {
          // error
          toast.error(t('toast_thereWasAnErrorTeams'))
          executeFrontErrorProtection()
          return;
        }

        const statusAll = response.reduce((acc, curr) => acc && curr, true)
        if (statusAll) {
          props.alreadyLoaded.set(false)
          props.setSelectedTeams({})
          props.setTeams([])
          props.setTeamsDelete([])
          props.setTeamsCompleteEdit([])
          props.handleOnClick(null, 5);
        } else {
          toast.error(t('toast_thereWasAnErrorTeams'))
          executeFrontErrorProtection()
          // error
        }
      })
      .finally(() => setLoadingTeams(false));
  };

  //Front protection when error occurs
  const executeFrontErrorProtection = () => {
    setLoadingProtection(true)
    getSavedInfo()
  }
  useEffect(() => {
    if (loadingProtection) {
      props.setTeams(props.teams?.filter((club) => props.dynamoClubs.get.findIndex((dynamo) => club.template_id === dynamo.template_id && club.team_distinguisher === dynamo.team_distinguisher) === -1))
      props.setTeamsDelete(props.teamsDelete?.filter((clubid) => props.dynamoClubs.get.findIndex((dynamo) => dynamo.id === clubid.split('#')[0]) !== -1))
      setLoadingProtection(false)
    }
  }, [props.dynamoClubs])

  const addSelectedTeams = (template_id: string, teams: Team[], type: 'Create' | 'Edit' | 'SaveEdit') => {
    setChange(true)
    switch (type) {
      case 'Edit':
        if (teams.length === 0) {
          const selectedTeamsCopy = { ...props.selectedTeams }
          delete selectedTeamsCopy[template_id]
          props.setSelectedTeams(selectedTeamsCopy)
        } else {
          props.setSelectedTeams({
            ...props.selectedTeams,
            [template_id]: teams,
          })
        }
        break
      case 'Create':
        const reCreate = props.teamsDelete.find((item) => item.split('#')[1] === template_id)
        if (!reCreate && teams.length !== 0) {
          props.setSelectedTeams({
            ...props.selectedTeams,
            [template_id]: teams,
          })
        } else if (teams.length !== 0) {
          //Re Create
          addSelectedTeams(template_id, teams.map((team) => {
            return {
              ...team,
              active: null
            }
          }), 'SaveEdit')
        }
        break
      case 'SaveEdit':
        if (teams.length === 0) {
          //SaveDelete template
          setShowDeleteModal(true)
          setDeleteModalText({
            title: castTemplateIdToTemplateName(template_id,tteams),
            message: `Are you sure you want to delete all teams of ${castTemplateIdToTemplateName(template_id,tteams)}?`
          })
          setDeleteModalType({ type: 'all', info: template_id })
        } else {
          const deletedTeams = props.selectedTeams[template_id]?.filter((item) => item.id.slice(-1) !== '0' && teams.findIndex((item2) => item2.id === item.id) === -1)

          if (deletedTeams.length === 0) {
            props.setSelectedTeams({
              ...props.selectedTeams,
              [template_id]: teams,
            })
            props.setTeamsEdit({ ...props.teamsEdit, [template_id]: teams })
          } else if (props.selectedTeams[template_id].length === 0) {
            setShowDeleteModal(true)
            setDeleteModalText({
              title: castTemplateIdToTemplateName(template_id,tteams),
              message: `Are you sure you want to delete ${deletedTeams.length} teams of ${castTemplateIdToTemplateName(template_id,tteams)}?`
            })
            setDeleteModalType({ type: 'some', info: { teams: teams, template_id: template_id, deletedTeams: deletedTeams } })
          } else {
            props.setSelectedTeams({
              ...props.selectedTeams,
              [template_id]: teams,
            })
          }
        }
        break
      default:
        break
    }
  }

  //Check setup of teams
  const teamsAreSetup = (): boolean => {
    let areSetup = false
    if(Object.keys(props.selectedTeams).length === 0)return true;
    for (let template_id in props.selectedTeams) {
      let teams = props.selectedTeams[template_id];
      const tteam = tteams.find((tt) => tt.id === template_id)

      areSetup = !(
        teams.some((team) =>
          undefined === props.teams.find((club: any) => finderBool(club, team, tteam, club.name))
          &&
          undefined === props.dynamoClubs.get?.filter(
            (club: any) => !props.teamsDelete.includes(`${club.id}#${club.template_id}#${club.team_distinguisher}`))?.find(
              (club: any) => finderBool(club, team, tteam, club.name))
        )
      )

      if (!areSetup) return false
    }
    return areSetup
  }

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [deleteModalText, setDeleteModalText] = useState<{ message: string, title: string }>({ message: '', title: '' })
  const [deleteModalType, setDeleteModalType] = useState<{ type: string, info: any }>({ type: '', info: '' })
  const closeModalDelete = (del: boolean) => {
    setShowDeleteModal(false)
    if (del) {
      switch (deleteModalType.type) {
        case 'all':
          deleteTemplate(deleteModalType.info)
          break
        case 'one':
          if (deleteModalText.title.includes('Custom Club: ')) {
            const teamID = props.dynamoClubs.get.find((club: any) => club.name === deleteModalText.title.replace('Custom Club: ', '')).id
            props.setTeamsDelete([...props.teamsDelete, `${teamID}#DELETED#DELETED`])
          } else {
            const templateID = deleteModalType.info.template_id
            const teamID = props.dynamoClubs.get.find((club: any) => club.template_id === templateID && deleteModalType.info.id.includes(castTeamDistinguisherToID(club.team_distinguisher))).id
            if (templateID) {
              props.setSelectedTeams({ ...props.selectedTeams, [templateID]: props.selectedTeams[templateID]?.filter((item) => item.id !== deleteModalType.info.id) })
              props.setTeamsDelete([...props.teamsDelete, `${teamID}#${templateID}#${castTeamIdToDistinguisher(deleteModalType.info.id)}`])
              props.setTeamsCompleteEdit(props.teamsCompleteEdit?.filter((club: any) => !(club.template_id === templateID && deleteModalType.info.id.includes(castTeamDistinguisherToID(club.team_distinguisher)))))
            }
          }
          break
        case 'some':
          props.setSelectedTeams({
            ...props.selectedTeams,
            [deleteModalType.info.template_id]: deleteModalType.info.teams,
          })
          props.setTeamsEdit({ ...props.teamsEdit, [deleteModalType.info.template_id]: deleteModalType.info.teams })
          deleteSomeTemplate(deleteModalType.info.deletedTeams)
          break
        default:
          break
      }
    }
  }

  useEffect(() => {
    if (createCustom) {
      setTitle('')
      setShowSetupTeam(true)
      setTeamToEdit(undefined)
      setModalTeamType('Create')
      setChange(true)
    }
  }, [createCustom])

  return (
    <>
      <div className="container-clubs-C">
        <h1>Zaahah</h1>

        <div className="btnWrapper">
          {/*Object.keys(props.selectedTeams).length > 0 &&*/ !goToSetup ? (
            <div>
              <button
                className="button-skip"
                onClick={(e) => props.handleOnClick(e, 5)}
              >
                {t('setupSchool__discardTeams')}
              </button>
              <button
                name="btn-setUpTeams"
                className="button-save btnGoToSetup"
                onClick={() => handleOnShow(true)}
              >
                {t('setupSchool__goToSetup')}
              </button>
            </div>
          ) : goToSetup ? (
            showContinueButton ?
              <div>
                <button
                  className={`button-skip ${loadingTeams ? 'btnProcess': ''}`}
                  onClick={handleSave}
                >
                  {t('setupSchool__discardTeams')}
                </button>
                <button
                  className={`button-save btnGoToSetup ${loadingTeams ? 'btnProcess': ''}`}
                  onClick={handleSave}
                >        
                  {loadingTeams ? (
                    <div className="btn-center">
                      <div className="icon-spinner-third buttonSendSignUp lilSendSignUp"></div>
                    </div>
                  ) : 'Save and continue'}
                </button>
              </div> :
              <div>
                <button
                  className="button-skip"
                  onClick={(e) => props.handleOnClick(e, 5)}
                >
                  {t('setupSchool__discardTeams')}
                </button>
                {/* <button name="btn-Step4-Save" className="button-save btnGoToSetup" onClick={handleSave}>
                  {loadingTeams ? (
                    <div className="btn-center">
                      <div className="icon-spinner-third buttonSendSignUp lilSendSignUp"></div>
                    </div>
                  ) : t('setupSchool__skip')}
                </button> */}
              </div>

          ) : (
            <button name="btn-Step4-Save" className="button-skip" onClick={(e) => props.handleOnClick(e, 5)}>
              {loadingTeams ? (
                <div className="btn-center">
                  <div className="icon-spinner-third buttonSendSignUp lilSendSignUp"></div>
                </div>
              ) : t('setupSchool__skip')}
            </button>
          )}
        </div>
        <div className="GridSearchLetter">
          <span>Please select the sports offered. You can then add Boys, Girls and Coed Teams.</span>
          <div className="contentInputSearchGrid">
            <div className="inputSearch">
              <FontAwesomeIcon
                icon="search"
                size='lg'
                color={WHITE}
                className="iconSearch  "
              />
              <input
                className="input-search"
                type="text"
                name="dataTeams"
                id="dataas"
                onChange={(e) => inputSearch(e.target.value)}
              />
            </div>
            {/*goToSetup && <div className="custom-club-button" onClick={() => setCreateCustom(true)}>
              <FontAwesomeIcon
                icon="plus"
                size='lg'
                color={WHITE}
                className=" "
              />
              <div className="custom-club-text">Custom Team</div>
          </div>*/}
          </div>
        </div>
      </div>

      {
        goToSetup ? (
          <>
            <div className="selected-clubs">
              {
                getSavedToShow(props.dynamoClubs.get, props.teamsDelete).map((club) => (
                  <div className="saved-club">
                    <p>{club.name}</p>
                    <FontAwesomeIcon
                      className='icon-times-2 '
                      icon="times"
                      size='xl'
                      color={WHITE}
                      onClick={(e) => handleOnClickClub(e, club.name, {
                        id: `${club.template_id}${castTeamDistinguisherToID(club.team_distinguisher)}`,
                        title: club.name,
                        template_id: club.template_id,
                        active: null,
                        gendersAllowed: club.gendersAllowed,
                        gradesAllowed: club.gradesAllowed,
                      }, "SaveDelete", club.name)}
                    />
                  </div>
                ))}
              {
                props.teams.map((team) => (
                  <div className="selected-club">
                    <p>{team.name}</p>
                    <FontAwesomeIcon
                      className='icon-times-2 '
                      icon="times"
                      size='xl'
                      color={WHITE}
                      onClick={(e) => handleOnClickClub(e, '', {
                        id: `${team.template_id}${castTeamDistinguisherToID(team.team_distinguisher)}`,
                        title: "",
                        template_id: team.template_id,
                        active: true,
                        gendersAllowed: [],
                        gradesAllowed: []
                      }, "Delete", team.name)}
                    />
                  </div>
                ))}
            </div>
            <SetupTeams
              selectedTeams={props.selectedTeams}
              handleOnClickClub={handleOnClickClub}
              teams={props.teams}
              dynamoClubs={props.dynamoClubs.get}
              teamsDelete={props.teamsDelete}
              setCreateCustom={setCreateCustom}
            />
          </>
        ) : (
          <>
            <SelectedTeamsList
              setChange={setChange}
              selectedTeams={props.selectedTeams}
              setSelectedTeams={props.setSelectedTeams}
              deleteTemplate={deleteTemplate}
              dynamoClubs={props.dynamoClubs}
              teamsDelete={props.teamsDelete}
              teams={props.teams}
              handleOnClickClub={handleOnClickClub}
            />
            <ListTeams
              setChange={setChange}
              teams={listTeams}
              addSelectedTeams={addSelectedTeams}
              selectedTeams={props.selectedTeams}
              setSelectedTeams={props.setSelectedTeams}
              dynamoTeams={props.dynamoClubs.get}
              setTeamsEdit={props.setTeamsEdit}
              teamsEdit={props.teamsEdit}
              teamsDelete={props.teamsDelete}
              setTeamsDelete={props.setTeamsDelete}
              deleteTemplate={deleteTemplate}
              setCreateCustom={setCreateCustom}
            />
          </>
        )
      }

      <ModalKillClub
        show={showDeleteModal}
        message={deleteModalText.message}
        title={deleteModalText.title}
        onHide={closeModalDelete}
      />

      <ModalTeamSetup
        show={showSetupTeam}
        onHide={handleCloseSetupTeam}
        title={title}
        organization={props.organization}
        updateCommInvites={props.updateCommInvites}
        handleOnShowButtons={handleOnShowButtons}
        saveTeams={saveTeams}
        category={createCustom ? undefined : category}
        teamSelected={createCustom ? {} : teamSelected}
        teamToEdit={teamToEdit}
        modalType={modalTeamType}
        createCustom={createCustom}
        setSelectedTeams={props.setSelectedTeams}
        selectedTeams={props.selectedTeams}
      />

      <ModalLoading show={loading || loadingProtection}
        message={loading ? t('setupSchool__loadingInformation') : loadingProtection ? t('setupSchool__recoveringInformation') : t('setupSchool__loading')} />
    </>
  );
}

export default Index;
