import {AnyAction} from 'redux';
import {
  START_GET_FEED, GET_FEED_ERROR, GET_FEED_SUCCESS,
  START_SAVE_REACTION, SAVE_REACTION_SUCCESS, SAVE_REACTION_ERROR, LOGOUT_POST, START_GET_POST_ALL, GET_MY_POSTS_ERROR, GET_MY_POSTS_SUCCESS, GET_POST_ALL_ERROR, GET_POST_ALL_SUCCESS, START_GET_MY_POSTS, GET_POSTS_BULLETIN_ERROR, GET_POSTS_BULLETIN_SUCCESS, GET_POSTS_CLUB_ERROR, GET_POSTS_CLUB_SUCCESS, GET_POSTS_EVENT_ERROR, GET_POSTS_EVENT_SUCCESS, GET_POSTS_FRIENDS_ERROR, GET_POSTS_FRIENDS_SUCCESS, GET_POSTS_ORG_ERROR, GET_POSTS_ORG_SUCCESS, GET_POSTS_SAVED_ERROR, GET_POSTS_SAVED_SUCCESS, GET_POSTS_WALL_ERROR, GET_POSTS_WALL_SUCCESS, START_GET_POSTS_BULLETIN, START_GET_POSTS_CLUB, START_GET_POSTS_EVENT, START_GET_POSTS_FRIENDS, START_GET_POSTS_ORG, START_GET_POSTS_SAVED, START_GET_POSTS_WALL, CLEAN_POST_EXCEPTION,
} from '../constants/postConstants';
import postStateInterface from '../../interfaces/store/postState';

const initialState: postStateInterface = {
  loadingReaction: false,
  reactionErrorMessage: undefined,
  reactionError: undefined,
  reaction: undefined,

  loadingGetPostsBulletin: false,
  getPostsBulletinError: undefined,
  getPostsBulletin: [],

  loadingGetPostsOrg: false,
  getPostsOrgError: undefined,
  getPostsOrg: [],

  loadingGetPostsFriends: false,
  getPostsFriendsError: undefined,
  getPostsFriends: [],

  loadingGetPostsSave: false,
  getPostsSaveError: undefined,
  getPostsSave: [],

  loadingGetPostsFeedProfile: false,
  getPostsFeedProfileError: undefined,
  getPostsFeedProfile: [],

  loadingGetPostsFeedConnections: false,
  getPostsFeedConnectionsError: undefined,
  getPostsFeedConnections: [],

  loadingGetPostsEvent: false,
  getPostsEventError: undefined,
  getPostsEvent: [],

  loadingGetPostsClub: false,
  getPostsClubError: undefined,
  getPostsClub: [],

  loadingGetPostAll: false,
  getPostAllError: undefined,
  getPostAll: [],
};

export const getPostReducer = (state = initialState, action: AnyAction): postStateInterface => {
  switch (action.type) {

    case START_SAVE_REACTION:
      return {
        ...state,
        loadingReaction: true
      };
    case SAVE_REACTION_SUCCESS:
      return {
        ...state,
        loadingReaction: false,
        reaction: action.payload
      };
    case SAVE_REACTION_ERROR:
      return {
        ...state,
        loadingReaction: false,
        reactionErrorMessage: action.payload.message,
        reactionError: action.payload.obj,
      };

    case START_GET_POST_ALL:
      return {
        ...state,
        loadingGetPostAll: true
      };
    case GET_POST_ALL_SUCCESS:
      return {
        ...state,
        loadingGetPostAll: false,
        getPostAll: action.payload
      };
    case GET_POST_ALL_ERROR:
      return {
        ...state,
        loadingGetPostAll: false,
        getPostAllError: action.payload,
      };

    case START_GET_POSTS_BULLETIN:
      return {
        ...state,
        loadingGetPostsBulletin: true
      };
    case GET_POSTS_BULLETIN_SUCCESS:
      return {
        ...state,
        loadingGetPostsBulletin: false,
        getPostsBulletin: action.payload
      };
    case GET_POSTS_BULLETIN_ERROR:
      return {
        ...state,
        loadingGetPostsBulletin: false,
        getPostsBulletinError: action.payload,
      };

    case START_GET_POSTS_ORG:
      return {
        ...state,
        loadingGetPostsOrg: true
      };
    case GET_POSTS_ORG_SUCCESS:
      return {
        ...state,
        loadingGetPostsOrg: false,
        getPostsOrg: action.payload
      };
    case GET_POSTS_ORG_ERROR:
      return {
        ...state,
        loadingGetPostsOrg: false,
        getPostsOrgError: action.payload,
      };

    case START_GET_POSTS_FRIENDS:
      return {
        ...state,
        loadingGetPostsFriends: true
      };
    case GET_POSTS_FRIENDS_SUCCESS:
      return {
        ...state,
        loadingGetPostsFriends: false,
        getPostsFriends: action.payload
      };
    case GET_POSTS_FRIENDS_ERROR:
      return {
        ...state,
        loadingGetPostsFriends: false,
        getPostsFriendsError: action.payload,
      };

    case START_GET_POSTS_SAVED:
      return {
        ...state,
        loadingGetPostsSave: true
      };
    case GET_POSTS_SAVED_SUCCESS:
      return {
        ...state,
        loadingGetPostsSave: false,
        getPostsSave: action.payload
      };
    case GET_POSTS_SAVED_ERROR:
      return {
        ...state,
        loadingGetPostsSave: false,
        getPostsSaveError: action.payload,
      };

    case START_GET_MY_POSTS:
      return {
        ...state,
        loadingGetPostsFeedProfile: true
      };
    case GET_MY_POSTS_SUCCESS:
      return {
        ...state,
        loadingGetPostsFeedProfile: false,
        getPostsFeedProfile: action.payload
      };
    case GET_MY_POSTS_ERROR:
      return {
        ...state,
        loadingGetPostsFeedProfile: false,
        getPostsFeedProfileError: action.payload,
      };

    case START_GET_POSTS_WALL:
      return {
        ...state,
        loadingGetPostsFeedConnections: true
      };
    case GET_POSTS_WALL_SUCCESS:
      return {
        ...state,
        loadingGetPostsFeedConnections: false,
        getPostsFeedConnections: action.payload
      };
    case GET_POSTS_WALL_ERROR:
      return {
        ...state,
        loadingGetPostsFeedConnections: false,
        getPostsFeedConnectionsError: action.payload,
      };

    case START_GET_POSTS_EVENT:
      return {
        ...state,
        loadingGetPostsEvent: true
      };
    case GET_POSTS_EVENT_SUCCESS:
      return {
        ...state,
        loadingGetPostsEvent: false,
        getPostsEvent: action.payload
      };
    case GET_POSTS_EVENT_ERROR:
      return {
        ...state,
        loadingGetPostsEvent: false,
        getPostsEventError: action.payload,
      };

    case START_GET_POSTS_CLUB:
      return {
        ...state,
        loadingGetPostsClub: true
      };
    case GET_POSTS_CLUB_SUCCESS:
      return {
        ...state,
        loadingGetPostsClub: false,
        getPostsClub: action.payload
      };
    case GET_POSTS_CLUB_ERROR:
      return {
        ...state,
        loadingGetPostsClub: false,
        getPostsClubError: action.payload,
      };

    case CLEAN_POST_EXCEPTION:
      return {
        ...state,
        getPostsClub: [],
        getPostsEvent: [],
        getPostsFeedConnections: [],
        getPostsFeedProfile: [],
      };

    case LOGOUT_POST:
      return initialState;

    default:
      return state;
  }
};
