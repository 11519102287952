import "../../containers/SetupSchool/styles.scoped.css";
import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InfoMembersTeam from './Tabs/InfoMembersTeam';
import Description from './Tabs/Description';
import Meeting from './Tabs/Meeting';
import { ClubCreate } from "./Clubs";
import { Team } from "./ModalTeam";
import { toast } from "react-toastify";
import ModalAddAdvisor from "./ModalAddAdvisor";
import { useDispatch, useSelector } from "react-redux";
import { getTimezones } from "../../store/actions/locationActions";
import { castTeamIdToDistinguisher } from "./Teams";
import ModalDiscardChanges from "./ModalDiscardChanges";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";

function Index(props: any) {
  const { t, i18n } = useTranslation();
  const [showAdvisor, setShowAdvisor] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [showError, setShowError] = useState(false);
  const [updatingTitle, setUpdatingTitle] = useState(false);
  const [showDiscard, setShowDiscard] = useState(false);

  const handleBeforeClose = () => {
    setShowError(false);
    setErrorMsg('');
    setShowAdvisor(false);
    setTabActive(0);
    setClub({
      name: '',
      template_id: '',
      member_type: [],
      maximumNumberMembers: 0,
      gendersAllowed: [],
      gradesAllowed: [],
      advisors: [],
      description: '',
      created: false,
      skills: [],
      international: false,
      national: false,
      regional: false,
      local: false,
      applicationRequired: false,
      clubDuesRequired: false,
      meetingDays: [],
      meetingStart: '',
      meetingEnd: '',
      timezone: '',
      meetingFrequency: '',
      meetingLocation: '',
      team_distinguisher: '',
    });
  };

  const onHide = () => {
    handleBeforeClose();
    props.onHide();
  }
  
  function handleOnClickSendMembers(e: any) {
    if (club.advisors && club.advisors.length === 0) {
      console.log('error advisors')
      setErrorMsg('The team must have at least one coach to be created.');
      setShowError(true);
    }
    else {
      const ok = checkMinimunData();
      if (ok) {
        props.onHide({
          ...club,
          template_id:props.teamSelected.template_id || `NONE#${club.name?.trim()}`,
          team_distinguisher: castTeamIdToDistinguisher(props.teamSelected.id)
        });
        handleBeforeClose();
        setShowError(false);
        setTabActive(0)
      }
    }
  }

  const checkMinimunData = () => {

    if (club.member_type.length === 0) {
      toast.error(t('toast_pleaseSelectMembers'));
      return false;
    }

    if (props.createCustom && club.gendersAllowed.length === 0) {
      toast.error(t('toast_pleaseSelectGenders'));
      return false;
    }

    if (props.not_from_setup || props.createCustom) {
      if ((club.member_type.indexOf('STUDENT') !== -1) && ((club.gradesAllowed || []).length === 0)) {
        toast.error(t('toast_pleaseSelectGrades'));
        return false;
      }
    }

    if (club.advisors && club.advisors.length === 0) {
      setErrorMsg(t('toast_theTeamMustHave'));
      toast.error(t('toast_pleaseAddCoaches'));
      setShowError(true);
      return false;
    }

    if (club.name?.trim().length === 0){
      toast.error(t('toast_teamNameEmpty'));
      return false;
    }

    return true;
  };

  const [tabActive, setTabActive] = useState(0);

  const [club, setClub] = useState<any>({
    name: props.title,
    template_id: '',
    member_type: [],
    maximumNumberMembers: 0,
    gendersAllowed: [],
    gradesAllowed: [],
    advisors: [],
    description: '',
    created: false,
    skills: [],
    international: false,
    national: false,
    regional: false,
    local: false,
    applicationRequired: false,
    clubDuesRequired: false,
    meetingDays: [],
    meetingStart: '',
    meetingEnd: '',
    timezone: '',
    meetingFrequency: '',
    meetingLocation: '',
    team_distinguisher: ''
  });

  useEffect(() => {
    setClub(props.teamToEdit ? {
      ...props.teamToEdit,
      name: props.teamToEdit.name || '',
      template_id: props.teamToEdit.template_id || '',
      member_type: props.teamToEdit.member_type || [],
      maximumNumberMembers: props.teamToEdit.maximumNumberMembers || 0,
      gendersAllowed: props.teamToEdit.gendersAllowed || [],
      //gradesAllowed: props.teamToEdit.gradesAllowed || props.selectedTeams[props.teamToEdit.template_id]?.find((item:any)=>item.title === props.teamToEdit.name)?.gradesAllowed || [],
      advisors: props.teamToEdit.advisors || [],
      description: props.teamToEdit.description || '',
      created: false,
      skills: props.teamToEdit.skills || props.teamToEdit.skills_learned || [],
      international: props.teamToEdit.international || props.teamToEdit.participateInternationalCompetitions || false,
      national: props.teamToEdit.national || props.teamToEdit.participateNationalCompetitions || false,
      regional: props.teamToEdit.regional || props.teamToEdit.participateRegionalCompetitions || false,
      local: props.teamToEdit.local || props.teamToEdit.participateLocalCompetitions || false,
      applicationRequired: props.teamToEdit.applicationRequired || false,
      clubDuesRequired: props.teamToEdit.clubDuesRequired || false,
      meetingDays: props.teamToEdit.meetingDays || [],
      meetingStart: props.teamToEdit.meetingStart || props.teamToEdit.meetingStartTime || '',
      meetingEnd: props.teamToEdit.meetingEnd || props.teamToEdit.meetingEndTime || '',
      timezone: props.teamToEdit.timezone || '',
      meetingFrequency: props.teamToEdit.meetingFrequency || '',
      meetingLocation: props.teamToEdit.meetingLocation || '',
      team_distinguisher: props.teamToEdit.team_distinguisher || '',
    } : {
      name: props.title,
      template_id: '',
      member_type: [],
      maximumNumberMembers: 0,
      gendersAllowed: [],
      //gradesAllowed: props.selectedTeams ? props.selectedTeams[props.teamSelected?.category || '']?.find((item:any)=>item.title === props.teamSelected?.title)?.gradesAllowed || [] : [],
      advisors: [],
      description: '',
      created: false,
      skills: [],
      international: false,
      national: false,
      regional: false,
      local: false,
      applicationRequired: false,
      clubDuesRequired: false,
      meetingDays: [],
      meetingStart: '',
      meetingEnd: '',
      timezone: '',
      meetingFrequency: '',
      meetingLocation: '',
      team_distinguisher: ''
    })
  }, [props.show])

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.updateStaffUsers) props.updateStaffUsers();
    if (props.updateCommInvites) props.updateCommInvites();
    dispatch(getTimezones())
  }, []);

  function handleOnClick(e: any, tab: any) {
    const ok = checkMinimunData();
    if (ok) {
      setTabActive(tab);
    }
  }

  const nextTab = (e: any, tabActive: any) => {
    if (tabActive === 2) {
      handleOnClickSendMembers(e);
      return;
    } else {
      const ok = checkMinimunData();
      if (ok) {
        handleOnClick(e, tabActive + 1);
      }
      return;
    }
  };

  const onHideDiscard = (type: string) => {
    setShowDiscard(false);
    if (type === 'discard') {
      onHide();
    }
  }

  return (
    <>
      <Modal className="modalCreateClub" show={props.show}>
        <Modal.Header>
          <Modal.Title>
            {updatingTitle ? (
              <input
                type="text"
                className="ModalCreateClub__titleInput"
                value={club.name}
                autoFocus
                onBlur={() => setUpdatingTitle(false)}
                onChange={(e) => setClub({ ...club, name: e.target.value })}
              />
            ) : (
              <div className='ModalCreateClub__headerWrapper' onClick={() => setUpdatingTitle(true)}>
                {club.name}
                <FontAwesomeIcon
                  icon="pen"
                  size='sm'
                  color={WHITE}
                  className=" "

                />
              </div>
            )}
          </Modal.Title>
          <FontAwesomeIcon
            icon="times"
            size='xl'
            color={WHITE}
            className="icon-times  "
            onClick={() => setShowDiscard(true)}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="nav">
            <div 
              className={tabActive === 0 ? 'active' : ''} 
              onClick={(e) => handleOnClick(e, 0)}>
                {t('infoMembers__members')}
            </div>
            <div 
              className={tabActive === 1 ? 'active' : ''} 
              onClick={(e) => handleOnClick(e, 1)}>
                {t('editInformation__description')}
            </div>
            <div 
              className={tabActive === 2 ? 'active' : ''} 
              onClick={(e) => handleOnClick(e, 2)}>
                {t('setupSchool__practices')}
            </div>
          </div>
          {tabActive == 0 ?
            <InfoMembersTeam
              club={club}
              setClub={setClub}
              organization={props.organization}
              setShowAdv={setShowAdvisor}
              staffUsers={props.staffUsers}
              updateStaffUsers={props.updateStaffUsers}
              updateCommInvites={props.updateCommInvites}
              not_from_setup={props.not_from_setup}
              createCustom={props.createCustom}
            />
            : tabActive == 1 ?
              <Description
                isTeam
                club={club}
                setClub={setClub}
              />
              : tabActive == 2 ?
                <Meeting
                  club={club}
                  setClub={setClub} />
                : ''
          }
          {showError && (
            <div className="row">
              <p className="roboto-normal-white-16px">{showError && errorMsg}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="buttonClose" onClick={() => setShowDiscard(true)}>
            Cancel
          </Button>
          <Button 
            name="btn-AddClub" 
            className="buttonSend" 
            onClick={(e: any) => nextTab(e, tabActive)}
          >
            {tabActive === 0 ?
              t('button__next')
              : tabActive === 1 ?
                t('button__next')
                : tabActive === 2 ?
                  t('button__save')
                  : ''
            }
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalDiscardChanges show={showDiscard} onHide={onHideDiscard} />
    </>
  );
}

export default Index;