import React, { useEffect, useState } from "react";
import Header from "../Header";
import "./styles.css";
import SuperAdminLeftMenu from "../SuperAdminLeftMenu";
import AdminLeftMenu from "../AdminLeftMenu";
import "../../styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser, updateUser } from "../../store/actions/userActions";
import DatePicker from 'react-datepicker';
import { post } from "../../helpers/HttpHelper";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import useGender from "../../hooks/useGender";

function Organizations() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.getUser.user);

    const [firstname, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('');
    const [email, setEmail] = useState('')
    const [birthday, setBirthday] = useState<Date | null>(null);
    const [newBirthday, setNewBirthday] = useState<string>('');
    const [roles, setRoles] = useState<string[]>([]);
    const [rolesByOrg, setRolesByOrg] = useState<string[][]>([]);
    const [phone, setPhone] = useState<string>('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [state, setState] = useState('');
    const [status, setStatus] = useState('');
    const [userName, setUserName] = useState('');
    const [organizationsNames, setOrganizationsNames] = useState([]);
    const [organizationIds, setOrganizationIds] = useState<string[]>([]);
    const { id = "" } = useParams();

    const organization = useSelector((state: any) => state.getOrgs.organizationInfo);
    const { genders, setCountry: setCountryGender} = useGender();

    useEffect(() => {
        if (organization && organization?.countryCode) {
            setCountryGender(organization.countryCode);
        }
    }, [])
    useEffect(() => {
        if (organization && organization?.countryCode) {
            setCountryGender(organization.countryCode);
        }
    }, [organization])

    const [rolList, setRolList] = useState({
        'ZAA#ORGADMIN': 'Organization Admin',
        'ZAA#STUDENT': 'Student',
        'ZAA#PARENT': 'Parent',
        'ZAA#STAFF': 'Staff',
        'ZAA#ALUMNI': 'Alumni',
    });

    useEffect(() => {
        if (id) {
            Backend(
                MicroServices.Admin,
                `/users/${id}?roleRequest=OA`)
                .then((response) => response.json())
                .then((results) => {
                    setName(results.firstname);
                    setLastName(results.lastname);
                    setGender(results.gender);
                    setEmail(results.email);
                    results.birthdate !== '0-0-0' ? setBirthday(new Date(results.birthdate)) : setBirthday(null);
                    setRoles(results.roles);
                    setRolesByOrg(results.rolesByOrg);
                    setPhone(results.phoneNumber);
                    setCity(results.city);
                    setCountry(results.country);
                    setPostalCode(results.zipcode);
                    setStatus(results.status);
                    setUserName(results.username);
                    setOrganizationsNames(results.organizationsNames)
                    setOrganizationIds(results.organizations);
                })
        }
    }, [id]);

    const handleChangeName = (e: any) => {
        setName(e.target.value);
    };

    const handleChangeLastName = (e: any) => {
        setLastName(e.target.value);
    };

    const handleChangeGender = (e: any) => {
        setGender(e.target.value);
    };

    const handleChangeBirthday = (e: any) => {
        setNewBirthday(moment(e).format("MM-DD-YYYY"));
        setBirthday(e)
    };

    const handleChangeRoles = (e: any) => {
        //Change rolesByOrg
        const position = organizationIds.indexOf(organizationIds[0]) //TODO -> more orgs
        const listClone = rolesByOrg
        if(listClone[position].includes(e.target.value)){
            const newRoles = listClone[position].filter((rol) => rol !== e.target.value);
            listClone[position] = newRoles
            setRolesByOrg(listClone);
        }else{
            listClone[position] = listClone[position].concat([e.target.value])
            setRolesByOrg(listClone);
        }

        //Change roles
        const role_exists_in_some = listClone.some((sublista:string[])=>sublista.includes(e.target.value))
        if(roles.includes(e.target.value) && !role_exists_in_some){
            const newRoles = roles.filter((rol) => rol !== e.target.value);
            setRoles(newRoles);
        }else{
            setRoles([...new Set([...roles, e.target.value])]);
        }
    };

    const handleChangePhone = (e: any) => {
        setPhone(e.target.value);
    };

    const handleChangePostalCode = (e: any) => {
        setPostalCode(e.target.value);
    };

    const handleChangestatus = (e: any) => {
        setStatus(e.target.value);
    };

    const handleSubmit = () => {        
        dispatch<any>(updateUser(userName, {
            firstname: firstname,
            lastname: lastName,
            gender: gender,
            phoneNumber: Number(phone),
            zipcode: postalCode,
            //birthdate: newBirthday, // TODO 
            // roles: roles,
            // rolesByOrg: rolesByOrg
        }, 'OA'))
        navigate(`../users/${userName}/view`);
    };

    return (

        <div className="content">
            <div className="top-cont">
                <div className="organization robotocondensed-normal-white-16px">{t('manageUser__title')}</div>
                <div className="text-1 robotocondensed-normal-white-16px">/</div>
                <div className="view-organization robotocondensed-normal-white-16px">{t('manageUser__viewUser')}</div>
                <div className="text-1 robotocondensed-normal-white-16px">/</div>
                <div className="view-organization robotocondensed-bold-lavender-16px">{t('manageUser__updateUser')}</div>
            </div>
            <div className="org-view-cont">
                <div className="title-column">
                    <h1 className="title-name">{firstname} {lastName}</h1>
                    <div className="buttons-box">
                        <div className="button-w">
                            <div className="cancel" onClick={() => navigate('../users/' + id + '/view')}>{t('button__cancel')}</div>
                        </div>
                        <div className="button-p">
                            <div className="save roboto-normal-white-16px" onClick={() => handleSubmit()}>{t('button__save')}</div>
                        </div>
                    </div>
                </div>
                <div className="view-columns">
                    <div className="col-1">

                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__firstName')}</div>
                            <div className="info-field" id="input-color">
                                <input
                                    className="roboto-normal-white-16px"
                                    type="text" name="name"
                                    defaultValue={firstname}
                                    onChange={handleChangeName}
                                />
                            </div>
                        </div>

                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__lastName')}</div>
                            <div className="info-field" id="input-color">
                                <input className="roboto-normal-white-16px" type="text" name="lastName" defaultValue={lastName} onChange={handleChangeLastName} />
                            </div>
                        </div>

                        <div className="info-field-row-input">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('profile__gender')}</div>
                            <div className="select-container-status">
                                {genders.map(({id: key, label: value}) => (
                                    <div className="optionRadio">
                                        <label className="content-input">
                                            <input name={`gender_${key}`} type="radio" value={key} checked={gender === key} onChange={handleChangeGender} />
                                            <i></i>
                                        </label>
                                        <div className="male radioButton robotocondensed-normal-white-16px">{value}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('modalAddAdvisor__email')}</div>
                            <div className="info-field" id="input-color">
                                <div className="roboto-normal-white-16px">{email}</div>
                            </div>
                        </div>

                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('setupStaff__birthday')}</div>
                            <div className="info-field disabled" id="input-color">
                                <DatePicker
                                    selected={null}
                                    dateFormat="MM-dd-yyyy"
                                    onChange={(date: Date) => handleChangeBirthday(date)}
                                    placeholderText="MM/DD/YYYY"
                                />
                            </div>
                        </div>

                        <div className="info-field-row-input">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageUser__userRole')}</div>
                            <div className="select-container-status disabled">
                                {Object.entries(rolList).map(([key, value]) => (
                                    <div className="option-status">
                                        <label className="content-input">
                                            <input
                                                type="checkbox"
                                                name="button"
                                                id={key}
                                                value={key}
                                                checked={roles.includes(key)}
                                                onChange={handleChangeRoles}
                                            />
                                            <i></i>
                                        </label>
                                        <label htmlFor="students">{value}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <br />
                        <h1 className="title-col-2">{t('manageUser__registration')}</h1>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageUser__profiles')}</div>
                            <div className="info-field" id="input-color">
                                <div className="roboto-normal-white-16px">{firstname} {lastName}</div>
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('profile__username')}</div>
                            <div className="info-field" id="input-color">
                                <div className="roboto-normal-white-16px">{userName}</div>
                            </div>
                        </div>
                        {/* <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">Set Password</div>
                            <div className="info-field" id="input-color">
                                <input className="roboto-normal-white-16px" type="text" name="name" defaultValue="p@ssw0rd" />
                            </div>
                        </div> */}
                    </div>

                    <div className="col-2">
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('profile__phoneNumber')}</div>
                            <div className="info-field" id="input-color">
                                <input className="roboto-normal-white-16px" type="text" name="phone" onChange={handleChangePhone} defaultValue={phone} />
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('profile__city')}</div>
                            <div className="info-field" id="input-color">
                                <div className="roboto-normal-white-16px">{city}</div>
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__postalCode')}</div>
                            <div className="info-field" id="input-color">
                                <input className="roboto-normal-white-16px" type="text" name="postalCode" onChange={handleChangePostalCode} defaultValue={postalCode} />
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__address')}</div>
                            <div className="info-field disabled" id="input-color">
                                <input className="roboto-normal-white-16px" type="text" name="address" defaultValue="" />
                            </div>
                        </div>
                        <div className="info-field-row disabled">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__state')}</div>
                            <div className="info-field" id="input-color">
                                <select className="roboto-normal-white-16px">
                                    <option selected disabled>Select State</option>
                                    <option value="All">All</option>
                                    <option value="School">School</option>
                                    <option value="Assotiation">Assotiation</option>
                                    <option value="Superadmin">Superadmin</option>
                                </select>
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__country')}</div>
                            <div className="info-field" id="input-color">
                                <div className="roboto-normal-white-16px">{country}</div>
                            </div>
                        </div>
                        <div className="info-field-row disabled">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageInvites__status')}</div>
                            <div className="info-field" id="input-color">
                                <select className="roboto-normal-white-16px">
                                    <option selected disabled>Select Status</option>
                                    <option value="All">All</option>
                                    <option value="School">School</option>
                                    <option value="Assotiation">Assotiation</option>
                                    <option value="Superadmin">Superadmin</option>
                                </select>
                            </div>
                        </div>
                        <br />
                        <div className="info-field-row-registration">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageUser__createdOn')}</div>
                            <div className="info-field" id="transparent-back">

                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageUser__updateOn')}</div>
                            <div className="info-field" id="transparent-back">

                            </div>
                        </div>
                        {/* <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">Invited<br />Organization</div>
                            <div className="info-field" id="input-color">
                                <input className="roboto-normal-white-16px" type="text" name="name" defaultValue="Possibility" />
                            </div>
                        </div> */}
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageInviteView__organization')}</div>
                            <div className="info-field" id="input-color">
                                <div className="roboto-normal-white-16px">
                                    <ul>
                                        {organizationsNames.map((request: any) => (
                                            <li>{request}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                {/*<h1 className="title-family">{t('manageUser__family')}</h1>

                <div className="family-row">
                    <div className="family-row-left">
                        <div className="img-container">
                            <img className="prof-pic" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" alt="profile-pic" />
                        </div>
                        <div className="family-column">
                            <div className="label-cont-family robotocondensed-bold-lavender-16px">Alexander Supertramp</div>
                            <div className="roboto-normal-white-16px">Father</div>
                            <div className="roboto-normal-white-16px">alex@supertramp.com</div>
                        </div>
                    </div>
                    <div className="family-row-right">Approved</div>
                </div>
                <div className="family-row">
                    <div className="family-row-left">
                        <div className="img-container">
                            <img className="prof-pic" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" alt="profile-pic" />
                        </div>
                        <div className="family-column">
                            <div className="label-cont-family robotocondensed-bold-lavender-16px">Ana Ortiz</div>
                            <div className="roboto-normal-white-16px">Mother</div>
                            <div className="roboto-normal-white-16px">some@mother.com</div>
                        </div>
                    </div>
                    <div className="family-row-right">Approved</div>
                                        </div>

                <div className="title-row">
                    <div className="info-field-column-select">
                        <div className="label-cont robotocondensed-bold-lavender-16px">State</div>
                        <div className="info-field-family" id="input-color">
                            <select className="roboto-normal-white-16px">
                                <option selected disabled>Select State</option>
                                <option value="All">All</option>
                                <option value="School">School</option>
                                <option value="Assotiation">Assotiation</option>
                                <option value="Superadmin">Superadmin</option>
                            </select>
                        </div>
                    </div>
                    <div className="info-field-column-select">
                        <div className="label-cont robotocondensed-bold-lavender-16px">Status</div>
                        <div className="info-field-family" id="input-color">
                            <select className="roboto-normal-white-16px">
                                <option selected disabled>Select Status</option>
                                <option value="All">All</option>
                                <option value="School">School</option>
                                <option value="Assotiation">Assotiation</option>
                                <option value="Superadmin">Superadmin</option>
                            </select>
                        </div>
                    </div>
                    <div className="button-family">
                        <div className="add-button" >{t('manageUser__addRelation')}</div>
                    </div>
                </div>*/}
            </div>
        </div>
    );
}

export default Organizations;
