import '../../containers/SetupStudent/styles.scoped.css'
import { applyTimeOffsetForLocal } from '../../helpers/dateHelper'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";

function Index(props: any) {
    const { t, i18n } = useTranslation();
    const getClubGenders = (): string[] => {
        let genders: string[] = []
        if (props.club.gendersAllowed?.includes('M')) genders = genders.concat([t('setupStudent__male')])
        if (props.club.gendersAllowed?.includes('F')) genders = genders.concat([t('setupStudent__female')])
        if (props.club.gendersAllowed?.includes('P')) genders = genders.concat([t('setupStudent__nonidentifying')])
        if (props.club.gendersAllowed?.includes('O')) genders = genders.concat([t('setupStudent__other')])
        return genders
    }

    const getClubMeeting = (): string[] => {
        let days: string[] = []
        if (props.club.meetingDays?.includes('Sunday')) days = days.concat([t('meeting_Sunday')])
        if (props.club.meetingDays?.includes('Monday')) days = days.concat([t('meeting_Monday')])
        if (props.club.meetingDays?.includes('Tuesday')) days = days.concat([t('meeting_Tuesday')])
        if (props.club.meetingDays?.includes('Wednesday')) days = days.concat([t('meeting_Wednesday')])
        if (props.club.meetingDays?.includes('Thursday')) days = days.concat([t('meeting_Thursday')])
        if (props.club.meetingDays?.includes('Friday')) days = days.concat([t('meeting_Friday')])
        if (props.club.meetingDays?.includes('Saturday')) days = days.concat([t('meeting_Saturday')])
        return days
    }

    const meetingFrequency = (): string[] => {
        let days: string[] = []
        if (props.club.meetingFrequency?.includes('Weekly')) days = days.concat([t('meeting_Weekly')])
        if (props.club.meetingFrequency?.includes('Bi-weekly')) days = days.concat([t('meeting_Biweekly')])
        if (props.club.meetingFrequency?.includes('Monthly')) days = days.concat([t('meeting_Monthly')])
        return days
    }

    const getCompetitions = (): string[] => {
        let competitions: string[] = []
        if (props.club.participateInternationalCompetitions) competitions = competitions.concat(['International'])
        if (props.club.participateNationalCompetitions) competitions = competitions.concat(['National'])
        if (props.club.participateRegionalCompetitions) competitions = competitions.concat(['Regional'])
        if (props.club.participateLocalCompetitions) competitions = competitions.concat(['Local'])
        return competitions
    }

    const dateTimeSplitStart: string[] = (props.club.meetingStartTime && props.club.timezone) ?
        applyTimeOffsetForLocal(props.club.meetingStartTime.split('-'), props.club.timezone.split('#')[2].split(':')) || ['0', '0', '0', '0', '0']
        : [];

    const dateTimeSplitEnd: string[] = (props.club.meetingEndTime && props.club.timezone) ?
        applyTimeOffsetForLocal(props.club.meetingEndTime.split('-'), props.club.timezone.split('#')[2].split(':')) || ['0', '0', '0', '0', '0']
        : [];

    return (
        <div className="containerDetailClub">
            <label className="title" htmlFor="">{t('setupSchool__description')}</label>
            <p
                dangerouslySetInnerHTML={{ __html: props.club.description || 'No description' }}
                className="description">
            </p>
            <p className="description allTags">
                {(props.club.tags || []).join(' ')}
            </p>
            <label className="title" htmlFor="">{t('comm__members')}</label>
            <p className="description">
                {(getClubGenders() || []).join(' • ')}
            </p>
            <label className="title" htmlFor="">{t('myschool__Grades')}</label>
            <p className="description">
                {(props.club.gradesAllowed || []).join(' • ')}
            </p>
            <label className="title" htmlFor="">{t('setupSchool__meeting')}</label>
            <p className="description">
                {/* {(props.club.meetingDays || []).join(' • ')} */}
                {(getClubMeeting() || []).join(' • ')}
            </p>
            <div className="dataMeeting">
                <div className="dataDate">
                    <FontAwesomeIcon
                        icon="calendar"
                        size='lg'
                        color={WHITE}
                        className="calendar  iconReaction"
                    />
                    {((meetingFrequency() || []).join(' • ')) || t('setupStudent__frecuencyInfo')}
                </div>
                <div className="dataHour">
                    <FontAwesomeIcon
                        icon="clock"
                        size='lg'
                        color={WHITE}
                        className="clock  iconReaction"
                    />
                    {(dateTimeSplitStart.length !== 0 && dateTimeSplitEnd.length !== 0) ?
                        (`${parseInt(dateTimeSplitStart[3]) > 12 ? parseInt(dateTimeSplitStart[3]) - 12 : (parseInt(dateTimeSplitStart[3]) === 0) ? 12 : dateTimeSplitStart[3]}:${dateTimeSplitStart[4]} ${parseInt(dateTimeSplitStart[3]) > 12 ? 'PM' : 'AM'} --> `
                            + `${parseInt(dateTimeSplitEnd[3]) > 12 ? parseInt(dateTimeSplitEnd[3]) - 12 : (parseInt(dateTimeSplitEnd[3]) === 0) ? 12 : dateTimeSplitEnd[3]}:${dateTimeSplitEnd[4]} ${parseInt(dateTimeSplitEnd[3]) > 12 ? 'PM' : 'AM'}`)
                        : t('setupStudent__timeInfo')}
                </div>
                <div className="dataLocation">
                    <FontAwesomeIcon
                        icon="map-marker-alt"
                        size='lg'
                        color={WHITE}
                        className="map-marker-alt  "
                    />
                    {props.club.meetingLocation || t('setupStudent__locationInfo')}
                </div>
            </div>
            <label className="title" htmlFor="">{t('setupStudent__participateComp')}</label>
            <p className="description">
                {getCompetitions().length !== 0 ? (getCompetitions() || []).join(' • ') : t('setupStudent__compInfo')}
            </p>
        </div>
    );
}

export default Index;
