import React from 'react'
import ImageProfile from './ImageProfile'
import './styles.scoped.css'

const WorkHistory = () => {
  return (
    <div className='containerMyAccount'>
      <ImageProfile />
      <div className='row row3 btns'>
        <div className="col">
          <button className='btnCancel'>Add new position</button>
        </div>
      </div>
    </div>
  )
}

export default WorkHistory