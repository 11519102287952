import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ChatServiceInterface } from "../../../../interfaces/chat/ChatServiceInterface";
import { LAVENDER, WHITE } from "../../../ClubIconsNavBar";
import ModalAha from "../../../Post/ModalAha";
import { ClickableIcon } from "../../../SuperAdminComponents/basicForSACenter";
import { getUser, updateProfilePhoto, uploadAsset, uploadImage, userLogout } from "../../../../store/actions/userActions";

const ChatFooter = ({ ChatServiceInstance }: { ChatServiceInstance: ChatServiceInterface }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { id, idGroup } = useParams();
    const user = useSelector((state: any) => state.getUser.user);

    const [message, setMessage] = useState<string>('');
    const [available, setAvailable] = useState<boolean>(false);
    const [showModalAha, setShowModalAha] = useState<boolean>(false);
    const [preview, setPreview] = useState<any>("");
    const [allImages, setAllImages] = useState<any[]>([])
    const [allImagesSave, setAllImagesSave] = useState<any[]>([])


    useEffect(() => {
        if (ChatServiceInstance.chatConnected) {
            setAvailable(true);
        }
        else {
            setAvailable(false);
        }
    }, [])

    useEffect(() => {
        if (ChatServiceInstance.chatConnected) {
            setAvailable(true);
        }
        else {
            setAvailable(false);
        }
    }, [ChatServiceInstance.chatConnected])

    const handleChangeMessage = (event: React.FormEvent<HTMLInputElement>) => {
        setMessage(event.currentTarget.value);
    }
    const handleKeyDownMessage = (event: any) => {
        if (event.key === 'Enter' && available) {
            sendMessage();
        }
    };

    const sendMessage = () => {

        if (allImagesSave?.length>0 && id) {
            ChatServiceInstance.sendMedia(id,allImagesSave,(message.trim() || ''),'image')
        } else {
            if (message.trim() === '') {
                return;
            }

            if (id && available) {
                ChatServiceInstance.sendMessage(id, message.trim(), undefined, undefined, undefined, idGroup)
                setMessage('')
            }
        }
    }

    const handlePhoto = (e: any) => {
        // setAllImages([])
        // let arrayImages = [];
        const limit = 20;
        if (e.target.files.length > limit) {
            console.log("Upload Max " + limit + " Files allowed")
        } else {

            // setSelectedFile(undefined)
            // setPreview('');

            if (!e.target.files || e.target.files.length === 0) {
                // setSelectedFile(undefined)
                return
            }

            if (e.target.files.length >= 1) {
                // console.log(e.target.files)
                for (let i = 0; i < e.target.files.length; i++) {
                    // arrayImages.push({ 'url': URL.createObjectURL(e.target.files[i]) })
                    setAllImagesSave(allImagesSave => [...allImagesSave, e.target.files[i]])
                    setAllImages(allImages => [...allImages, URL.createObjectURL(e.target.files[i])]);
                    // console.log(updateImage(e.target.files[i]))
                }
            } else {
                // setArrayFiles(e.target.files)
                // setShowButtons(true)
                // setSelectedFile(e.target.files[0])
                // setPreview(URL.createObjectURL(e.target.files[0]))
                // updateImage(e.target.files[0]);
                // console.log(234)
            }
            // console.log(allImages)
        }
    }

    const removeImage = (e: any) => {
        setAllImages(oldValues => {
            return oldValues.filter(image => image !== e)
        })
    }

    useEffect(() => {
        // console.log(allImages)
        setAllImages([])
        setAllImagesSave([])
    }, [])

    return (
        <FooterWrapper>
            {allImages.length > 0 &&
                <PreviewPhotos>
                    {allImages.map((image: any) => (
                        <ContainerImage>
                            <Button onClick={() => { removeImage(image) }}>X</Button>
                            <img src={image} style={{ objectFit: 'cover', width: '100%', height: '100%', borderRadius: '12px' }} />
                        </ContainerImage>
                    ))}
                </PreviewPhotos>
            }
            <Footer>
                <label htmlFor="filePickerModal">
                    <div>
                        <ClickableIcon
                            icon="paperclip"
                            size='xl'
                            color={WHITE}
                        />
                        <input
                            id="filePickerModal"
                            type={"file"}
                            onChange={(e) => handlePhoto(e)}
                            multiple
                        />
                    </div>
                </label>

                {(user.roles.includes('ZAA#STUDENT')) && (
                    <AhaLogo
                        src={"/aHa-logo_w.png"}
                        onClick={() => setShowModalAha(true)}
                    />
                )}
                <MessageInput
                    placeholder={t('chat_typeMessage')}
                    onChange={handleChangeMessage}
                    type="text"
                    value={message}
                    onKeyDown={handleKeyDownMessage}
                    disabled={!available}
                />
                <ClickableIcon
                    icon="paper-plane"
                    size='xl'
                    color={LAVENDER}
                    onClick={() => sendMessage()}
                />
            </Footer>
            <ModalAha postInfo={{
                author: ChatServiceInstance.activeConversation.from.username,
                imageProfile: ChatServiceInstance.activeConversation.from.avatar,
                nameProfile: ChatServiceInstance.activeConversation.from.fullName
            }} show={showModalAha && ChatServiceInstance.activeConversation.from.username !== ""} onHide={() => setShowModalAha(false)}
                ChatServiceInstance={ChatServiceInstance} />
        </FooterWrapper>
    );
}

export default ChatFooter;

const FooterWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 82px;
    background-color: rgb(40, 17, 90);
    position: fixed;
    top: calc(100vh - 82px);
    
`;

const Footer = styled.div`
    margin-left: 20px;
    margin-right: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
`;

const AhaLogo = styled.img`
    width: 47px;
    height: 47px;
    cursor: pointer;
`;

const MessageInput = styled.input`
    box-sizing: border-box;
    width: 85%;
    height: 50px;
    border: 0.5px solid #FFFFFF;
    border-radius: 8px;
    background-color: transparent;
    padding: 10px;
    color: white;
    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-size: 20px;
    ::placeholder {
        color: white;
        font-family: 'Roboto Condensed';
        font-weight: 400;
        font-size: 20px;
    }
`;

const PreviewPhotos = styled.div`
    position: absolute;
    top: -80px;
    left: 0;
    background-color: #28115a;
    width: 100%;
    height: 90px;
    display: -webkit-box;
    overflow-x: auto;
    justify-content: flex-start;
    align-items: center;
`;

const ContainerImage = styled.div`
    width: 65px;
    height: 65px;
    border-radius: 12px;
    margin: 10px;
    position: relative;
`;

const Button = styled.button`
    position: absolute;
    top: -6px;
    right: -6px;
    width: 21px;
    height: 21px;
    background-color: #ffffffb3;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
`;