import { useEffect } from 'react';
import NumericInput from 'react-numeric-input';
import { toast } from 'react-toastify';
import "../../../containers/SetupSchool/styles.scoped.css";
import OrganizationComplete from "../../../interfaces/organization/OrganizationComplete";
import { ClubCreate } from "../Clubs";
import { Advisor } from '../Communities';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../../components/ClubIconsNavBar';
import { useTranslation } from "react-i18next";
import useGender from '../../../hooks/useGender';
import useGrades from '../../../hooks/useGrades';
import TCLYouth from '../../../interfaces/TClubs/TCLYouth/TCLYouth';

interface InfoMembersProps {
  club: ClubCreate,
  setClub: (club: ClubCreate) => void,
  setShowAdvisor: (show: boolean) => void,
  organization?: OrganizationComplete,
  yo?: TCLYouth[]
}

const numericInputStyles: { [key: string]: React.CSSProperties } = {
  input: {
    marginRight: 3,
    paddingRight: 4,
    textAlign: 'left',
    height: 54
  },
  btnUp: {
    display: 'block',
    width: '60px',
    height: '25px',
    boxShadow: 'none',
    top: 1,
    right: 0,
    color: 'white'
  },
  btnDown: {
    display: 'block',
    width: '60px',
    height: '25px',
    boxShadow: 'none',
    top: 28,
    right: 0,
    color: 'white'
  },
  'input:focus': {
    border: '1px inset #69C',
    outline: 'none'
  },
  arrowUp: {
    borderBottomColor: 'white',
    marginBottom: 1,
    display: 'none'
  },
  arrowDown: {
    borderTopColor: 'white',
    marginTop: 1,
    display: 'none'
  }
}

function Index({ club, setClub, organization, setShowAdvisor, yo }: InfoMembersProps) {
  const { t, i18n } = useTranslation();
  const { genders, setCountry: setCountryGenders } = useGender();
  const { grades, setCountry: setCountryGrades } = useGrades();

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryGenders(organization.countryCode);
      setCountryGrades(organization.countryCode);
    }
  }, [])

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryGenders(organization.countryCode);
      setCountryGrades(organization.countryCode);
    }
  }, [organization])

  const handleCheckMemberType = (event: any) => {
    let updateList = [...club.member_type];
    if (event.target.checked) {
      updateList = [...club.member_type, event.target.value];
    } else {
      updateList.splice(club.member_type.indexOf(event.target.value), 1);
    }
    setClub({ ...club, member_type: updateList });
  }

  const handleCheckMemberTypeAll = (event: any) => {
    if (event.target.checked) {
      setClub({
        ...club,
        member_type: ['STUDENT', 'STAFF', 'ALUMNI', 'GUARDIAN']
      });
    } else {
      setClub({
        ...club,
        member_type: []
      });
    }
  }

  const handleOnChangeGenders = (event: any) => {
    let updateList = [...club.gendersAllowed];
    if (event.target.checked) {
      updateList = [...club.gendersAllowed, event.target.value];
    } else {
      updateList.splice(club.gendersAllowed.indexOf(event.target.value), 1);
    }
    setClub({ ...club, gendersAllowed: updateList });
  }

  const handleCheckGenderAll = (event: any) => {
    if (event.target.checked) {
      setClub({
        ...club,
        gendersAllowed: ['F', 'M', 'O', 'P']
      });
    } else {
      setClub({
        ...club,
        gendersAllowed: []
      });
    }
  }

  const handleOnChangeNumberMembers = (value: number | null) => {
    setClub({ ...club, maximumNumberMembers: value || 0 });
  }

  const handleCheckGradesAllowed = (event: any) => {
    let updateList = [...club.gradesAllowed];
    if (event.target.checked) {
      updateList = [...club.gradesAllowed, parseInt(event.target.value)];
    } else {
      updateList.splice(club.gradesAllowed.indexOf(parseInt(event.target.value)), 1);
    }
    setClub({ ...club, gradesAllowed: updateList });
  }

  const handleCheckGradesAll = (event: any, org: any) => {

    const gradesAll = []

    for (let index = org.lowGrade; index <= org.highGrade; index++) {
      gradesAll.push(index)
    }

    if (event.target.checked) {
      setClub({
        ...club,
        gradesAllowed: gradesAll
      });
    } else {
      setClub({
        ...club,
        gradesAllowed: []
      });
    }

  }

  const handleDeleteAdv = (e: any, adv: Advisor) => {
    e.preventDefault()
    if (club.advisors.length <= 1) return

    setClub({
      ...club,
      advisors: club.advisors.filter((item: any) => item.email !== adv.email)
    })
  }

  const updateByYo = (gendersAllowed: string[], gradesAllowed: string[]) => {
    setClub({
      ...club,
      gendersAllowed,
      gradesAllowed: gradesAllowed.map(Number),
    })
  }

  return (
    <div className="infoMembers">
      <div className="selectAffiliated">
        <label htmlFor="">{t('setupSchool__selectAffiliated')}</label>
        <div className="affiliated">
          <select name="" id="" onChange={(event) => {
            if (event.currentTarget.value === '' || !yo) return;
            const selyo = yo.find((y) => y.yo_name === event.currentTarget.value);
            if (!selyo) return;
            updateByYo(selyo.genders_allowed || [], selyo.grades_allowed || [])
          }}>
            <option value="" disabled selected>Select</option>
            {(club.allowInd || !yo || yo?.length <= 0) && <option value="">Independent club</option>}
            {yo && yo.length > 0 && yo.map(({ yo_name }) => <option value={yo_name}>{yo_name}</option>)}
          </select>
        </div>
      </div>
      <div className="membersType">
        <h2>{t('setupSchool__membersType')}</h2>
        <span className="subtitle">{t('setupSchool__youCanMark')}</span>
        <div className="containers-input">
          <div className="option-chechbox">
            <label className="content-input">
              <input
                type="checkbox"
                name="checkbox-STUDENT"
                value="STUDENT"
                onChange={handleCheckMemberType}
                checked={club.member_type.includes('STUDENT')}
              />
              <i></i>
            </label>
            <label htmlFor="students">{t('setupSchool__student')}</label>
          </div>
          <div className="option-chechbox">
            <label className="content-input">
              <input
                type="checkbox"
                name="checkbox-STAFF"
                value="STAFF"
                onChange={handleCheckMemberType}
                checked={club.member_type.includes('STAFF')}
              />
              <i></i>
            </label>
            <label htmlFor="students">{t('setupSchool__staff')}</label>
          </div>
          <div className="option-chechbox">
            <label className="content-input">
              <input
                type="checkbox"
                name="checkbox-ALUMNI"
                value="ALUMNI"
                onChange={handleCheckMemberType}
                checked={club.member_type.includes('ALUMNI')}
              />
              <i></i>
            </label>
            <label htmlFor="students">{t('setupSchool__alumni')}</label>
          </div>
          <div className="option-chechbox">
            <label className="content-input">
              <input
                type="checkbox"
                name="checkbox-GUARDIAN"
                value="GUARDIAN"
                onChange={handleCheckMemberType}
                checked={club.member_type.includes('GUARDIAN')}
              />
              <i></i>
            </label>
            <label htmlFor="students">{t('setupSchool__guardians')}</label>
          </div>
          <div className="option-chechbox">
            <label className="content-input">
              <input
                type="checkbox"
                name="checkbox-all"
                value="All"
                onChange={handleCheckMemberTypeAll}
              // checked={club.member_type.includes('STUDENT')}
              />
              <i></i>
            </label>
            <label htmlFor="students">All</label>
          </div>
        </div>
      </div>
      <div className="maximunMembers">
        <h2>{t('setupSchool__maximumNumber')}</h2>
        <NumericInput
          className="form-control"
          min={0}
          style={numericInputStyles}
          value={club.maximumNumberMembers !== 0 ? club.maximumNumberMembers : undefined}
          onChange={handleOnChangeNumberMembers}
        />
      </div>
      <div className="genders">
        <h2>{t('setupSchool__gender')}</h2>
        <div className="containers-input align-left">
          {genders.map((gender) => (
            <div className="option-chechbox">
              <label className="content-input">
                <input
                  type="checkbox"
                  name="checkbox-F"
                  value={gender.id}
                  checked={club.gendersAllowed.includes(gender.id)}
                  onChange={handleOnChangeGenders}
                />
                <i></i>
              </label>
              <label htmlFor="students">{gender.label}</label>
            </div>
          ))}
          <div className="option-chechbox">
            <label className="content-input">
              <input
                type="checkbox"
                name="checkbox-STUDENT"
                value="STUDENT"
                onChange={handleCheckGenderAll}
              // checked={club.member_type.includes('STUDENT')}
              />
              <i></i>
            </label>
            <label htmlFor="students">All</label>
          </div>
        </div>
      </div>
      <div className="gradesClub">
        <h2>{t('setupSchool__grades')}</h2>
        <span className="subtitle">{t('setupSchool__youCanMark')}</span>
        <div className="containers-input">
          {organization && Array.from(Array(1 + organization.highGrade - organization.lowGrade)).map((_, idx) => (
            <div className="option-chechbox">
              <label className="content-input">
                <input
                  type="checkbox"
                  name={`checkbox-${idx + organization.lowGrade}`}
                  value={idx + organization.lowGrade}
                  checked={club.gradesAllowed.includes(idx + organization.lowGrade)}
                  onChange={handleCheckGradesAllowed}
                />
                <i></i>
              </label>
              <label htmlFor="students">{grades.find((grade) => grade.id === idx + organization.lowGrade)?.listLabel || (idx + organization.lowGrade)}</label>
            </div>
          ))}
          <div className="option-chechbox">
            <label className="content-input">
              <input
                type="checkbox"
                name="checkbox-STUDENT"
                value="STUDENT"
                onChange={(e) => handleCheckGradesAll(e, organization)}
              // checked={club.member_type.includes('STUDENT')}
              />
              <i></i>
            </label>
            <label htmlFor="students">All</label>
          </div>
        </div>
      </div>
      <div className="addAdvisor">
        <label htmlFor="">{t('setupSchool__theAdvisorWill')}</label>
        <div className="staff-inputs club-adv">
          {club?.advisors?.map((adv: Advisor) => (<div className="row">
            <input type="text" value={`${adv.firstName} ${adv.lastName}`} />
            {club.advisors.length > 1 ? (
              <FontAwesomeIcon
                icon="times"
                size='xl'
                color={WHITE}
                className="icon-times-white  "
                onClick={(e) => handleDeleteAdv(e, adv)}
              />
            ) : (<></>)}
          </div>))}
        </div>
        <div id="openAddAdvisor" className="add-advisor-modal" onClick={() => setShowAdvisor(true)}>
          <FontAwesomeIcon
            icon="user-plus"
            size='xl'
            color={WHITE}
            className="icon-user  "
          />
          <p>{t('setupSchool__addAdvisor')}</p>
        </div>
      </div>
    </div>
  );
}

export default Index;
