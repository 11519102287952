export const property1LabelProperty2Open1Data = {
  label: 'First Name',
  typeHereTheInformation: 'Anita',
  className: '',
};

export const property1LabelProperty2Open2Data = {
  label: 'Last Name',
  typeHereTheInformation: 'Ortiz',
  className: 'form-parts-4-1',
};

export const frame61301Data = {
  property1LabelProperty2Open1Props: property1LabelProperty2Open1Data,
  property1LabelProperty2Open2Props: property1LabelProperty2Open2Data,
};

export const property2round1Data = {
  children: 'dot-circle',
};

export const property2round2Data = {
  children: 'circle',
};

export const property1DefaultData = {
  gender: 'Gender',
  female: 'Female',
  male: 'Male',
  property2round1Props: property2round1Data,
  property2round2Props: property2round2Data,
};

export const componentData = {
  mainBackground:
    'https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/6219388fb3094ba2a2c54c20/img/desktop---38@1x.png',
  img1: 'https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/group-4960@2x.png',
  img2: 'https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/group-4959@1x.png',
  img3: 'https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/group-4961@2x.png',
  oneUnifyingPlatfo:
    'One, unifying platform to manage and promote all extracurricular activities ensuring equal awareness and access to opportunity.',
  saveTeachersTimeA: (
    <>
      Save teachers time and receive previously unavailable insight into school
      or system wide engagement.
      <br />
      Protect students, with a safe, invitation-only social-network.
      <br />
      Align student engagement with digital rewards.
    </>
  ),
  overlapGroup1:
    'https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/rectangle-3616@1x.png',
  vector21:
    'https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/vector-21@2x.png',
  number2: '2',
  frame61301Props: frame61301Data,
  property1DefaultProps: property1DefaultData,
};