import "./styles.scoped.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser, getUsersWithLimit } from "../../store/actions/userActions";
import ModalConnections from "../ConnectionSelectBar/ModalConnections";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";

interface EventParticipatingProps{
	searchString: any,
	checkIn: any,
	id: string,
	isChecker: boolean,
	loadingCheckin: boolean,
	userCheckin: string,
	eventRoster: any[],
	ChatServiceInstance: ChatServiceInterface
}

function Index({
	searchString,
	checkIn,
	id,
	isChecker,
	loadingCheckin,
	userCheckin,
	eventRoster,
	ChatServiceInstance
}: EventParticipatingProps) {
	const { t, i18n } = useTranslation();
	const user = useSelector((state: any) => state.getUser.userInfo);
	const userAll = useSelector((state: any) => state.getUser.user);
	const userFriends = useSelector((state: any) => state.getConnection.userFriends || []);
	const userConnections = useSelector((state: any) => state.getConnection.userPossibleConnections || []);

	const [openModal, setOpenModal] = useState<boolean>(false);
	const [typeFiter, setTypeFilter] = useState('1');
	const [setmember, setSetMember] = useState<any>('pass');

	const dispatch = useDispatch();

	useEffect(() => {
		if (!userAll) {
			dispatch<any>(getUser(user.username));
		}
	}, [userAll])

	const handleDots = (member: any, username: string) => {
		setTypeFilter('1');
		setSetMember(member);

		//The connection is a friend?
		if (userFriends.find((user: any) => user.friendUsername === username)) {
			setTypeFilter('2');
		}
		//Is a possible connection?
		else if (userConnections.find((user: any) => user.usernameConnection === username)) {
			setTypeFilter('1');
		}
		//The users cannot be friends
		else {
			setTypeFilter('pass');
		}

		setOpenModal(!openModal);
	}

	return (
		<div className="listMembersClub">
			{eventRoster?.filter((member: any) => searchString === '' || (`${member.fullname}`).includes(searchString))
				.map((member: any) => (
					<div className={`${(isChecker) && ('cardMember')} userInterested userInterestedRoster`} key={member.id} >
						<img src={member.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'} alt="" className="round-avatar" />
						<p>
							{member.fullname}
							{!member.registered &&
								<><br /><p className="unregistered-lavender">{t('label_userUnregistered')}</p></>
							}
						</p>
						{(isChecker) ? (
							(loadingCheckin && userCheckin === member.username) ? (
								<div className="icon-spinner-third buttonSendSignUp" />
							) : (
								<div className="option" onClick={(e) => checkIn(member.username, e, member.unregistered ?? !member.registered)}>
									<label className="content-input">
										<input type="radio" name="role-button" value="" />
										<i></i>
									</label>
									<label htmlFor="students">Check in</label>
								</div>
							)
						)
							: (
								<div>
									<FontAwesomeIcon
										icon="ellipsis-v-alt"
										size='lg'
										color={LAVENDER}
										className="ellipsis-v-alt   cursor"
										onClick={() => handleDots(member, member.username)}
									/>
									{(openModal && (member.username === setmember.username)) && (<ModalConnections connection={setmember} filterType={typeFiter} setOpenModal={setOpenModal} isAdvisor={undefined} officers={undefined} advisors={undefined} username={undefined} reloadClub={undefined} ChatServiceInstance={ChatServiceInstance} />)}
								</div>
							)}
					</div>
					// <div className="cardMember" key={member.id} >
					// 	<img src={member.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'} alt="" className="round-avatar" />
					// 	<p>{member.firstname} {member.lastname}</p>
					// 	<button>
					// 		<div className="ellipsis-v-alt fontawewsome5pro-regular-normal-lavender-20px cursor" onClick={() => handleDots(member, member.username)}>
					// 				ellipsis-v-alt
					// 		</div>
					// 		{(openModal && (member.username === setmember.username)) && (<ModalConnections connection={setmember} filterType={typeFiter} setOpenModal={setOpenModal}/>)}
					// 	</button>
					// </div> 
				))}
		</div>
	);
}

export default Index;