import "./styles.scoped.css";
import Welcome from '../../components/SetupAdvisor/Welcome';
import MyProfile from '../../components/SetupAdvisor/MyProfile';
import Interests from '../../components/SetupAdvisor/Interests';
import Departments from '../../components/SetupAdvisor/Departments';
import Complete from '../../components/SetupAdvisor/Complete';

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClubSuggestionsStaff, getInterestSuggested, getUserOrgSuggestion, SaveInterests } from "../../store/actions/connectionActions";
import { editUser, getUser, saveUserDepartments } from "../../store/actions/userActions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { getOrganizationInfo } from "../../store/actions/orgsActions";
import { InterestInfo } from "../SetupStudent";
import ClubSuggestions from "../../components/SetupAdvisor/ClubSuggestions";

toast.configure()
export interface Interest {
  id: number;
  title: string;
  active: boolean;
}
export interface Department {
  id: number;
  title: string;
  active: boolean;
}

export interface CCT {
  comms: any[];
  clubs: any[];
  teams: any[];
}

function SetupStaff(props: any) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //States
  const [stepActive, setStepActive] = useState(1);
  const [editedUser, setEditerUser] = useState<any>({})
  const [interests, setInterests] = useState<Interest[]>([])
  const [myInterests, setMyInterests] = useState<Interest[]>([]);
  const [departments, setDepartments] = useState<Department[]>([])
  const [myDepartments, setMyDepartments] = useState<Department[]>([])
  const [suggestedDepartments, setSuggestedDepartments] = useState<string[]>([])
  const [showClubsStepAbove, setShowClubsStepAbove] = useState<boolean>(true);
  const [followingClubs, setFollowingClubs] = useState<any[]>([]);
  const [joinedClubs, setJoinedClubs] = useState<any[]>([]);

  //Selectors
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const user = useSelector((state: any) => state.getUser.user);
  const suggestedInterests = useSelector((state: any) => state.getConnection.GetISug);
  const organization = useSelector((state: any) => state.getOrgs.organizationInfo);

  //Effects
  useEffect(() => {
    i18n.changeLanguage(getLanguage(user.preferredLanguage))

    if (organization && "countryCode" in organization && organization.countryCode !== "") {
      dispatch(getInterestSuggested(organization.countryCode));
    }

    dispatch(getUser(userInfo.username));
    Backend(
      MicroServices.Connections,
      `/profile/departments/suggestions`)
      .then((response) => response.json())
      .then((results) => {
        setSuggestedDepartments(results)
      })
  }, []);

  useEffect(() => {
    setEditerUser(user)
    if (user && "organizations" in user && user.organizations.length > 0) {

      if (!organization) {
        dispatch<any>(getOrganizationInfo(user.organizations[0])) //@todo more orgs
      }

      dispatch(
        getClubSuggestionsStaff(user.organizations[0], user.gender, user.roles[0].split('#')[1], user.username) //TODO -> When user has more orgs and roles
      );
      dispatch(getUserOrgSuggestion(user.organizations[0], user.roles[0] !== 'ZAA#STUDENT')); //TODO -> When is alumni or guardian
    }
  }, [user]);

  useEffect(() => {
    if (suggestedInterests.length > 0) {
      setInterests(suggestedInterests.map((item: InterestInfo) => ({
        id: item.id,
        title: item.name,
        active: false,
      })));
    }
  }, [suggestedInterests])

  useEffect(() => {
    setDepartments(suggestedDepartments.map((item: string, idx: number) => {
      return {
        id: idx,
        title: item,
        active: false
      }
    }))
  }, [suggestedDepartments])

  //Handlers
  function handleOnClick(e: any, step: any, save: boolean) {
    if (step < 6) {
      setStepActive(step);
    }
    switch (step) {
      case 2:
        if (!save) setEditerUser(user)
        break
      default:
        break
    }
  }

  const handleFinish = () => {
    dispatch(
      editUser(userInfo.username,
        'OA',
        {
          firstname: editedUser.firstname,
          lastname: editedUser.lastname,
          gender: editedUser.gender,
          birthdate: editedUser.birthdate
        })
    );
    dispatch(
      SaveInterests(
        userInfo.username,
        myInterests.filter((i: any) => i.active).map((i: any) => i.title),
        user.organizations[0]
      )
    );
    dispatch(
      saveUserDepartments(
        userInfo.username,
        myDepartments.map((item: any) => item.title)
      )
    );
    Backend(
      MicroServices.AccessManager,
      `/users/${userInfo.username}/setup-complete-user`, {
      method: "POST",
    }).then((results) => {
      if (results.status === 200) {
        dispatch<any>(getUser(userInfo.username))
          .then(() => {
            props.setShowSetupUser(false);
            navigate("/app");
          });
      }
    });
  };

  function getLanguage(e: string) {
    switch (e) {
      case "spanish":
        return 'es'
        break;
      case "english":
        return 'en'
        break;
      default:
        return '';
        break;
    }
  }

  return (
    <div className="container-setup-advisor">
      <div className="navegatorStep">
        <div className="steps">
          <div className="allSteps">
            {/* <div className="step">
              <div
                className={`${stepActive === 0 ? "active" : ""} ${stepActive > 0 ? "activeStep" : ""
                  } stepIcon step-0`}
                onClick={(e) => handleOnClick(e, 0, true)}
              >
                <div className="circle-setup" />
                <span className="stepTitle">Welcome</span>
              </div>
            </div> */}
            <div className="step">
              <div
                className={`${stepActive === 1 ? "active" : ""} ${stepActive > 1 ? "activeStep" : ""
                  } stepIcon step-1`}
                onClick={(e) => handleOnClick(e, 1, true)}
              >
                <div className="circle-setup" />
                <span className="stepTitle">{t('setupStaff__myProfile')}</span>
              </div>
            </div>
            <div className="step">
              <div
                className={`${stepActive === 2 ? "active" : ""} ${stepActive > 2 ? "activeStep" : ""
                  } stepIcon step-2`}
                onClick={(e) => handleOnClick(e, 2, true)}
              >
                <div className="circle-setup" />
                <span className="stepTitle">{t('setupStaff__interests')}</span>
              </div>
            </div>
            <div className="step">
              <div
                className={`${stepActive === 3 ? "active" : ""} ${stepActive > 3 ? "activeStep" : ""
                  } stepIcon step-3`}
                onClick={(e) => handleOnClick(e, 3, true)}
              >
                <div className="circle-setup" />
                <span className="stepTitle">{t('setupStaff__departments')}</span>
              </div>
            </div>
            <div className="step">
              <div
                className={`${stepActive === 4 ? "active" : ""} ${stepActive > 4 ? "activeStep" : ""
                  } stepIcon step-4`}
                onClick={(e) => handleOnClick(e, 4, true)}
              >
                <div className="circle-setup" />
                <span className="stepTitle">Clubs suggestions</span>
              </div>
            </div>
          </div>
          <div className="step">
            <div
              className={`${stepActive === 5 ? "active" : ""} stepIcon step-5`}
              onClick={(e) => handleOnClick(e, 5, true)}
            >
              <div className="circle-setup" />
              <span className="stepTitle">{t('setupStaff__complete')}</span>
            </div>
          </div>
        </div>
      </div>
      {stepActive == 0 ? (
        // <Welcome handleOnClick={handleOnClick} />
        ''
      ) : stepActive == 1 ? (
        <MyProfile handleOnClick={handleOnClick} user={editedUser} setUser={setEditerUser} />
      ) : stepActive == 2 ? (
        <Interests
          handleOnClick={handleOnClick}
          setMyInterests={setMyInterests}
          myInterests={myInterests}
          setInterests={setInterests}
          interests={interests}
        />
      ) : stepActive == 3 ? (
        <Departments
          handleOnClick={handleOnClick}
          setMyDepartments={setMyDepartments}
          myDepartments={myDepartments}
          setDepartments={setDepartments}
          departments={departments}
          showClubsStepAbove={showClubsStepAbove}
        />
      ) : stepActive == 4 ? (
        <ClubSuggestions
          handleOnClick={handleOnClick}
          followingClubs={followingClubs}
          setFollowingClubs={setFollowingClubs}
          joinedClubs={joinedClubs}
          setJoinedClubs={setJoinedClubs}
          showNewText={''}
        />
      ) : (
        <Complete handleFinish={handleFinish} />
      )}
    </div>
  );
}

export default SetupStaff;
