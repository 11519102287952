import "./styles.scoped.css";
import Welcome from "../../components/SetupSetup/Welcome";
import MySchool from "../../components/SetupSetup/MySchool";
import Communities, {
  CommunityItem,
} from "../../components/SetupSetup/Communities";
import Clubs, { ClubCreate } from "../../components/SetupSetup/Clubs";
import Teams from "../../components/SetupSetup/Teams";
import Complete from "../../components/SetupSetup/Complete";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationInfo } from "../../store/actions/orgsActions";
import { getUser } from "../../store/actions/userActions";
import OrganizationComplete from "../../interfaces/organization/OrganizationComplete";
import { UserResponse } from "../../interfaces/user/UserResponse";
import {
  getTeams,
  getTemplateClubsGrouped,
} from "../../store/actions/templateClubActions";
import { Team } from "../../components/SetupSetup/ModalTeam";
import { getCommunityInvites } from "../../store/actions/inviteActions";
import { useTranslation } from "react-i18next";
import { getTClubsByCountry, getTCommsByCountry, getTTeamsByCountry } from "../../store/actions/TClubsActions";
import TopBar from "../../components/SetupComponents/TopBar";
import StepCircle from "../../components/SetupComponents/StepCircle";

export interface SelectedTeams {
  [key: string]: Team[];
}

export interface Principal {
  email: string;
  name: string;
  lastname: string;
}

function SetupSchool({setShowSetupSchool}:{setShowSetupSchool: any}) {
  const [stepActive, setStepActive] = useState(1);
  const [organization, setOrganization] = useState<
    OrganizationComplete | undefined
  >(undefined);
  const [user, setUser] = useState<UserResponse | undefined>(undefined);
  const [userIsPrincipal, setUserIsPrincipal] = useState(false);
  const [advisors, setAdvisors] = useState<CommunityItem[]>([]);
  const [clubs, setClubs] = useState<ClubCreate[]>([]);
  const [clubsEdit,setClubsEdit] = useState<any[]>([])
  const [clubIdsToDelete,setClubsIdsToDelete] = useState<string[]>([]) //club_id#template_id
  const [teams, setTeams] = useState<ClubCreate[]>([]);
  const [teamsEdit,setTeamsEdit] = useState<{[key:string]: any[]}>({})
  const [teamsCompleteEdit,setTeamsCompleteEdit] = useState<any[]>([])
  const [teamIdsToDelete,setTeamIdsToDelete] = useState<string[]>([]) //club_id#template_id#team_id
  const [savedTeams, setSavedTeams] = useState<string[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<SelectedTeams>({});
  const [principal, setPrincipal] = useState<Principal>({
    email: '',
    name: '',
    lastname: ''
  });
  const { t, i18n } = useTranslation();
  //Persistent info for saved info
  const [dynamoClubs, setDynamoClubs] = useState<any[]>([])
  const [alreadyLoadedSavedInfoClubs,setAlreadyLoadedSavedInfoClubs] = useState<boolean>(false)
  const [dynamoTeams, setDynamoTeams] = useState<any[]>([])
  const [alreadyLoadedSavedInfoTeams,setAlreadyLoadedSavedInfoTeams] = useState<boolean>(false)

  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    reloadOrgInformation();
  }, [userInfo]);

  const reloadOrgInformation = () => {
    const orgId = JSON.parse(userInfo.attributes["custom:organizations"])[0];
    const username = userInfo.username;

    dispatch<any>(getOrganizationInfo(orgId))
      .then((orgResults: any) => {
        setOrganization(orgResults);
        setPrincipal({
          email: orgResults.orgAdmin.email,
          name: orgResults.orgAdmin.firstname,
          lastname: orgResults.orgAdmin.lastname,
        });
        dispatch(getCommunityInvites(orgId,true))
      })
      .catch(console.log);

    dispatch<any>(getUser(username))
      .then((userResults: any) => {
        setUser(userResults);
      })
      .catch(console.log);
  };

  function handleOnClick(e: any, step: number) {
    setStepActive(step);
    window.scrollTo(0, 0);
  }

  const gotoStep = (step: number) => {
    if (step < 1 || step > 5) return;
    setStepActive(step);
    window.scrollTo(0, 0);
  }

  function listAdvisor(e: any) {
    setAdvisors(e);
  }

  useEffect(() => {
    if(organization) dispatch(getCommunityInvites(organization.id, organization.status.startsWith('P')))
    dispatch(getTemplateClubsGrouped());
    dispatch(getTeams());
  }, []);

  useEffect(() => {
    if(organization && organization?.countryCode) {
      dispatch(getTClubsByCountry(organization?.countryCode));
      dispatch(getTCommsByCountry(organization?.countryCode));
      dispatch(getTTeamsByCountry(organization?.countryCode));
    }
  }, [organization]);

  const updateCommInvites = () =>{
    if(organization) dispatch(getCommunityInvites(organization.id, organization.status.startsWith('P')))
  }

  return (
    <div className="container-setup-school">
      <TopBar>
        <StepCircle
          active={stepActive === 1}
          past={stepActive > 1}
          gotoStep={() => gotoStep(1)}
          title={t('setupSchool__mySchool')}
        />
        <StepCircle
          active={stepActive === 2}
          past={stepActive > 2}
          gotoStep={() => gotoStep(2)}
          title={t('setupSchool__communities')}
        />
        <StepCircle
          active={stepActive === 3}
          past={stepActive > 3}
          gotoStep={() => gotoStep(3)}
          title={t('setupSchool__clubs')}
        />
        <StepCircle
          active={stepActive === 4}
          past={stepActive > 4}
          gotoStep={() => gotoStep(4)}
          title={t('setupSchool__teams')}
        />
        <StepCircle
          active={stepActive === 5}
          past={stepActive > 5}
          gotoStep={() => gotoStep(5)}
          title={t('setupSchool__complete')}
          isLastChild
        />
      </TopBar>
      {stepActive == 1 ? (
        <MySchool
          handleOnClick={handleOnClick}
          organization={organization}
          user={user}
          reloadOrgInformation={reloadOrgInformation}
          userIsPrincipal={userIsPrincipal}
          setUserIsPrincipal={setUserIsPrincipal}
        />
      ) : stepActive == 2 ? (
        <Communities
          handleOnClick={handleOnClick}
          organization={organization}
          user={user}
          updateCommInvites={updateCommInvites}
          listAdvisor={listAdvisor}
          listadvisors={advisors}
          principal={principal}
          setPrincipal={setPrincipal}
        />
      ) : stepActive == 3 ? (
        <Clubs
          handleOnClick={handleOnClick}
          organization={organization}
          updateCommInvites={updateCommInvites}
          setClubs={setClubs}
          clubs={clubs}
          setClubsEdit={setClubsEdit}
          clubsEdit={clubsEdit}
          clubsDelete={clubIdsToDelete}
          setClubsDelete={setClubsIdsToDelete}
          alreadyLoaded={{get:alreadyLoadedSavedInfoClubs,set:setAlreadyLoadedSavedInfoClubs}}
          dynamoClubs={{get:dynamoClubs,set:setDynamoClubs}}
        />
      ) : stepActive == 4 ? (
        <Teams
          handleOnClick={handleOnClick}
          organization={organization}
          updateCommInvites={updateCommInvites}
          setTeams={setTeams}
          teams={teams}
          savedTeams={savedTeams}
          setSavedTeams={setSavedTeams}
          setSelectedTeams={setSelectedTeams}
          selectedTeams={selectedTeams}
          setTeamsEdit={setTeamsEdit}
          teamsEdit={teamsEdit}
          teamsCompleteEdit={teamsCompleteEdit}
          setTeamsCompleteEdit={setTeamsCompleteEdit}
          teamsDelete={teamIdsToDelete}
          setTeamsDelete={setTeamIdsToDelete}
          alreadyLoaded={{get:alreadyLoadedSavedInfoTeams,set:setAlreadyLoadedSavedInfoTeams}}
          dynamoClubs={{get:dynamoTeams,set:setDynamoTeams}}
        />
      ) : (
        <Complete organization={organization} setShowSetupSchool={setShowSetupSchool}/>
      )}
    </div>
  );
}

export default SetupSchool;
