import { useEffect, useMemo, useState } from "react";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import INT from "../../interfaces/Interests/INT/INT";

const useInterestsSA = (pageSizeProp: number) => {
    const [pageSize, setPageSize] = useState(pageSizeProp);
    const [ints, setInts] = useState<INT[]>([]);

    const intPages:INT[][] = useMemo(() => {
        const pages:INT[][] = [];

        for (let i = 0; i < ints.length; i+=pageSize) {
            pages.push(ints.slice(i, i+pageSize));
        }

        if (pages.length === 0) {
            pages.push([]);
        }

        return pages;
    }, [pageSize, ints])

    const reloadInterests = () => {
        Backend(
            MicroServices.Connections,
            `/interests`
        )
            .then((response) => response.json())
            .then((results: INT[]) => {
                if (!Array.isArray(results)) {
                    return;
                }
                setInts(results);
            });
    }
    
    useEffect(() => reloadInterests(), []);

    return {
        intPages,
        reloadInterests,
        setPageSize,
    }

}

export default useInterestsSA;