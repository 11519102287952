import "./styles.css";
// import "./styles2.css";
import OrganizationsInfoBar from "../OrganizationsInfoBar"
import UserOrgAdmin from "../UserOrgAdmin"
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import OutsideClickHandler from 'react-outside-click-handler';
import { getUsersWithLimit, getUser } from "../../store/actions/userActions";
import { capitalize } from "../../helpers/letterHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
import { useTranslation } from "react-i18next";

const Index = ({ roleSelected, setRoleSelected, org, users, setUsers }: { roleSelected: string, setRoleSelected: any, org: string, users: any[], setUsers: any }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.getUser.token)

  const usersStatus = useSelector((state: any) => state.getUser.usersStatus);
  const organizationsAll = useSelector((state: any) => state.getOrgs.organizationNames);
  const userRoles = useSelector((state: any) => state.getUser.usersRoles);
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const user = useSelector((state: any) => state.getUser.user);
  const activeCountries = useSelector((state: any) => state.getLocation.activeCountries || []);
  const activeCities = useSelector((state: any) => state.getLocation.activeCities || []);

  const [rolesHeader, setRolesHeader] = useState('');
  const [cityHeader, setCityHeader] = useState('');
  const [countryHeader, setCountryHeader] = useState('');
  const [statusHeader, setStatusHeader] = useState('')

  const rolesOptions = userRoles.map((e: any) => ({
    label: e,
    value: encodeURIComponent(e),
  }));

  rolesOptions.unshift({ label: "All", value: "" });

  const nameOptions = organizationsAll.map((e: any) => ({
    label: e.name,
    value: e.name,
  }));

  nameOptions.unshift({ label: "All", value: "" });


  const statusOptions = usersStatus.map((e: any) => ({
    label: capitalize(e),
    value: e,
  }));

  statusOptions.unshift({ label: "All", value: "" });

  const countryOptions = activeCountries.map((e: any) => {
    return {
      label: e.name,
      value: e.name,
    }
  })

  countryOptions.unshift({ label: "All", value: "" });

  const cityOptions = activeCities.map((e: any) => {
    return {
      label: e.name,
      value: e.name,
    }
  })

  cityOptions.unshift({ label: "All", value: "" });

  const [selected, setSelected] = useState("");
  const [input, setInput] = useState(false);
  const [outside, setOutside] = useState(false);
  const [organizationValue, setOrganizationValue] = useState("");



  const handleChange = (e: any) => {
    setSelected(e.label);
    // console.log(e.label);
  };

  const onInput = (e: any) => {
    setInput(true);
    setOutside(false);
    setOrganizationValue("organizationName");
  };

  const outsideClick = (e: any) => {
    // outside ? e.label = "Search by Name" : <></>
  };

  const customStyles = (value: any, input: any, outside: any) => ({
    option: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#383838',
    }),
    input: (styles: any, state: any) => ({
      ...styles,
      border: 'none',
      color: 'white',
      fontWeight: "normal",
      paddingLeft: "5px",
      "&:focus": { backgroundColor: 'red' },
    }),
    container: (styles: any) => ({
      ...styles,
      padding: 0,
    }),
    valueContainer: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: value && value != 'All' ? '#383838' : 'transparent',
      // display: value && !outside ? 'flex' : 'none',
      padding: 0,
    }),
    menu: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      backgroundColor: "#383838",
      width: "fit-content",
      minWidth: "150px",
      textAlign: "left",
      zIndex: "1",
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      textAlign: "left",
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "50px",
      borderRadius: "6px",
      boxShadow: "none",
      border: 0,
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "bold",
      maxWidth: "200px",
      marginBottom: "5px",
      backgroundColor: input ? '#383838' : 'transparent',
      // textDecoration: outside ? 'underline' : 'none',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: 'white',
      textDecoration: "underline",
      display: input ? 'none' : 'block',
    }),
    indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
    dropdownIndicator: (styles: any) => ({ ...styles, display: "none" }),
    noOptionsMessage: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#383838',
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "normal",
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#383838',
      border: "none",
      paddingLeft: "7px",
      paddingRight: "7px",
      paddingBottom: "7px",
      paddingTop: "7px",
      borderRadius: "4px",
      fontSize: "13px",
      width: "125px",

      // display: input ? 'none' : 'block',

    }),
  })

  const [id, setId] = useState('')
  const [role, setRole] = useState('')
  const [country, setCountry] = useState('')
  const [city, setCity] = useState('')
  const [status, setStatus] = useState('')
  const [date, setDate] = useState('ASC')

  const [name, setName] = useState('')
  const [lastname, setLastName] = useState('')
  const [email, setEmail] = useState('')


  const handleChangeRole = (e: any) => {
    setRole(e.value);
    setRolesHeader(e.value ? `Roles ` : '');
    setRoleSelected(e.value)
    dispatch<any>(getUsersWithLimit({
      'token': token,
      'limit': 100000,
      'page': 0,
      'lastEvaluatedKey': '',
      'id': id,
      'role': e.value,
      'org': org,
      'country': country,
      'city': city,
      'status': status,
      'date': date,
      'roleRequest': userRoles.includes('SUPER') ? 'SA' : 'OA',
      'firstname': name,
      'lastname': lastname,
      'email': email,
    }))
      .then((us: any) => {
        console.log(org, e.value)
        console.log('ESTE 7 :/', us);
        setUsers(us)
      });
  }
  const handleChangeStatus = (e: any) => {
    setStatus(e.value);
    setStatusHeader(`Status `);
    dispatch<any>(getUsersWithLimit({
      'token': token,
      'limit': 100000,
      'page': 0,
      'lastEvaluatedKey': '',
      'id': id,
      'role': role,
      'org': org,
      'country': country,
      'city': city,
      'status': e.value,
      'date': date,
      'roleRequest': userRoles.includes('SUPER') ? 'SA' : 'OA',
      'firstname': name,
      'lastname': lastname,
      'email': email,
    }))
      .then((us: any) => {
        console.log('ESTE 6 :/', us);
        setUsers(us)
      });
  }

  function handleChangeInput(e: any, type: any) {
    if (type === 'firstname') {
      setName(e.target.value);
    } else if (type === 'lastname') {
      setLastName(e.target.value)
    } else if (type === 'email') {
      setEmail(e.target.value)
    }
  }

  const handleKeyChangeName = () => {
    onBlurInput('firstname');
    dispatch<any>(getUsersWithLimit({
      'token': token,
      'limit': 100000,
      'page': 0,
      'lastEvaluatedKey': '',
      'id': id,
      'role': role,
      'org': org,
      'country': country,
      'city': city,
      'status': status,
      'date': date,
      'roleRequest': userRoles.includes('SUPER') ? 'SA' : 'OA',
      'firstname': name,
      'lastname': lastname,
      'email': email,
    }))
      .then((us: any) => {
        console.log('ESTE 5 :/', us);
        setUsers(us)
      });
  }

  const handleKeyChangeLastName = () => {
    onBlurInput('lastname');
    dispatch<any>(getUsersWithLimit({
      'token': token,
      'limit': 100000,
      'page': 0,
      'lastEvaluatedKey': '',
      'id': id,
      'role': role,
      'org': org,
      'country': country,
      'city': city,
      'status': status,
      'date': date,
      'roleRequest': userRoles.includes('SUPER') ? 'SA' : 'OA',
      'firstname': name,
      'lastname': lastname,
      'email': email,
    }))
      .then((us: any) => {
        console.log('ESTE 3 :/', us);
        setUsers(us)
      });
  }

  const handleKeyChangeEmail = () => {
    onBlurInput('email');
    dispatch<any>(getUsersWithLimit({
      'token': token,
      'limit': 100000,
      'page': 0,
      'lastEvaluatedKey': '',
      'id': id,
      'role': role,
      'org': org,
      'country': country,
      'city': city,
      'status': status,
      'date': date,
      'roleRequest': userRoles.includes('SUPER') ? 'SA' : 'OA',
      'firstname': name,
      'lastname': lastname,
      'email': email,
    }))
      .then((us: any) => {
        console.log('ESTE 2 :/', us);
        setUsers(us)
      });
  }

  const [focusName, setFocusName] = useState(false);
  const [focusLastName, setFocusLastName] = useState(false);
  const [focusEmail, setFocusEmail] = useState(false)

  function onFocusInput(e: any) {
    if (e === 'firstname') {
      setFocusName(true)
    } else if (e === 'lastname') {
      setFocusLastName(true);
    } else if (e === 'email') {
      setFocusEmail(true);
    }
  }

  function onBlurInput(e: any) {
    if (e === 'firstname' && name === '') {
      setFocusName(false)
    } else if (e === 'lastname' && lastname === '') {
      setFocusLastName(false)
    } else if (e === 'email' && email === '') {
      setFocusEmail(false);
    }
  }

  useEffect(() => {
    const label_selected = roleSelected.split('%23')[1]
    if (label_selected && org) handleChangeRole({ value: roleSelected });
    else if (org) handleChangeRole({ value: '' })
  }, [roleSelected]);

  return (

    <>
      <table className="listData robotocondensed-bold-white-16px">
        <tr>
          <th className="idTable">
            <div className="id">
              <div className="id-1 robotocondensed-bold-white-16px">{t('login__username')}</div>{/* 
            <FontAwesomeIcon
  icon="sort-amount-down"
  size='lg'
  color={LAVENDER}
  className="sort-amount-down  "
/> */}
            </div>
          </th>
          <th className="nameTable">
            <div>
              <input
                className={`${focusName ? 'filterIsFocused' : 'inputFilter'} `}
                type="text"
                placeholder={t('myschool__firstName')}
                onChange={(e) => handleChangeInput(e, 'firstname')}
                onFocus={() => onFocusInput('firstname')}
                onBlur={() => onBlurInput('firstname')}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    handleKeyChangeName()
                  }
                }}
              />
            </div>
          </th>
          {/* <th>
          <div className="organizationName">
            <OutsideClickHandler
              onOutsideClick={() => {
                setOutside(true);
                setInput(false);
                outsideClick(outside);
              }}
            >
              <Select
                isMulti={false}
                options={nameOptions}
                placeholder={"Organizations"}
                styles={customStyles(selected, input, outside)}
                onChange={handleChangeOrg}
                onFocus={onInput}
              />
            </OutsideClickHandler>
            <FontAwesomeIcon
  icon="chevron-down"
  size='lg'
  color={LAVENDER}
  className="chevron-down  "
/>
          </div>
        </th> */}
          <th className="lastnameTable">
            <div>
              <input
                className={`${focusLastName ? 'filterIsFocused' : 'inputFilter'} `}
                type="text"
                placeholder={t('myschool__lastName')}
                onChange={(e) => handleChangeInput(e, 'lastname')}
                onFocus={() => onFocusInput('lastname')}
                onBlur={() => onBlurInput('lastname')}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    handleKeyChangeLastName()
                  }
                }}
              />
            </div>
          </th>
          <th className="emailTable">
            <div>
              <input
                className={`${focusEmail ? 'filterIsFocused' : 'inputFilter'} `}
                type="text"
                placeholder={t('modalAddAdvisor__email')}
                onChange={(e) => handleChangeInput(e, 'email')}
                onFocus={() => onFocusInput('email')}
                onBlur={() => onBlurInput('email')}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    handleKeyChangeEmail()
                  }
                }}
              />
            </div>
          </th>
          <th className="rolesTable">
            <div className="join roboto-bold-white-16px">{t('manageUser__roles')}</div>
          </th>
          <th className="dateTable">
            <div className="join roboto-bold-white-16px">{t('manageInviteView__createdOn')}</div>
          </th>
          <th className="statusTable">
            <div className={`status roboto-bold-white-16px ${statusHeader ? 'active' : ''}`}>
              <label className="labelType">{statusHeader}</label>
              <Select
                isMulti={false}
                options={statusOptions}
                placeholder={"Status"}
                styles={customStyles(selected, input, outside)}
                onChange={handleChangeStatus}
                onFocus={onInput}
              />
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "
              />
            </div>
          </th>
          <th className="actionsTable">
            <div className="actions robotocondensed-bold-white-16px">{t('manageInvites__actions')}</div>
          </th>
        </tr>
        {/* {console.log(users)} */}
        {users.map((user: any) => (
          <UserOrgAdmin user={user} />
        ))}
      </table>
      {/* <div className="tableUserManage tabla">
      <div className="filter-bar">
      <div className="tableUser id">
        <div className="id-1 robotocondensed-bold-white-16px">Search ID</div>
        <FontAwesomeIcon
  icon="sort-amount-down"
  size='lg'
  color={LAVENDER}
  className="sort-amount-down  "
/>
      </div>
      <div className="tableUser roles">
        <OutsideClickHandler
          onOutsideClick={() => {
            setOutside(true);
            setInput(false);
            outsideClick(outside);
          }}
        >
          <Select
            isMulti={false}
            options={rolesOptions}
            placeholder={"Roles"}
            styles={customStyles(selected, input, outside)}
            onChange={handleChangeRole}
            onFocus={onInput}
          />
        </OutsideClickHandler>
      </div>
      <div className="tableUser organizationName">

        <OutsideClickHandler
          onOutsideClick={() => {
            setOutside(true);
            setInput(false);
            outsideClick(outside);
          }}
        >
          <Select
            isMulti={false}
            options={nameOptions}
            placeholder={"Organizations"}
            styles={customStyles(selected, input, outside)}
            onChange={handleChangeOrg}
            onFocus={onInput}
          />
        </OutsideClickHandler>
        <FontAwesomeIcon
  icon="chevron-down"
  size='lg'
  color={LAVENDER}
  className="chevron-down  "
/>
      </div>
      <div className="tableUser city">
        <OutsideClickHandler
          onOutsideClick={() => {
            setOutside(true);
            setInput(false);
            outsideClick(outside);
          }}
        >
          <Select
            isMulti={false}
            options={[]}
            placeholder={"Search by City"}
            styles={customStyles(selected, input, outside)}
            onChange={handleChange}
            onFocus={onInput}
          />
        </OutsideClickHandler>
        <FontAwesomeIcon
  icon="sort-amount-down"
  size='lg'
  color={LAVENDER}
  className="sort-amount-down  "
/>
      </div>
      <div className="tableUser country roboto-bold-white-16px">
        <OutsideClickHandler
          onOutsideClick={() => {
            setOutside(true);
            setInput(false);
            outsideClick(outside);
          }}
        >
          <Select
            isMulti={false}
            options={[]}
            placeholder={"Search by Country"}
            styles={customStyles(selected, input, outside)}
            onChange={handleChange}
            onFocus={onInput}
          />
        </OutsideClickHandler>
        <FontAwesomeIcon
  icon="sort-amount-down"
  size='lg'
  color={LAVENDER}
  className="sort-amount-down  "
/>
      </div>
      <div className="tableUser join roboto-bold-white-16px">Joined</div>
      <div className="tableUser status">
      <Select
          isMulti={false}
          options={statusOptions}
          placeholder={"Status"}
          styles={customStyles(selected, input, outside)}
          onChange={handleChangeStatus}
          onFocus={onInput}
        />
        <FontAwesomeIcon
  icon="chevron-down"
  size='lg'
  color={LAVENDER}
  className="chevron-down  "
/>
      </div>
      <div className="tableUser actions robotocondensed-bold-white-16px">Actions</div>
    </div> */}

      {/* </div> */}
    </>


  )
};

export default Index;
