import React, { KeyboardEventHandler, useEffect, useState } from "react";
import "./styles.css";
import "../../styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import Select from 'react-select';
import CreatableSelect from "react-select/creatable";
import { ActionMeta, OnChangeValue } from "react-select";
import { TemplateClub, TemplateClubResponseMock } from "../../interfaces/TemplateClub";
import { getTemplateCategories } from "../../store/actions/templateCategoryActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../ClubIconsNavBar";
import { toast } from 'react-toastify'
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { useTranslation } from "react-i18next";

export const MEMBER_MAP = {
  STUDENT: 'Student',
  ALUMNI: 'Alumni',
  STAFF: 'Staff',
  GUARDIAN: 'Guardians',
  //NO_ROLE_ASSIGNED: 'No role assigned', //por ahora, para que no muera la busqueda de clubs despues
}

function CreateTemplateClub() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const categories = useSelector((state: any) => state.getTemplateCategories.TCategories || []);
  const [tClubForm, setTClubForm] = useState<TemplateClub>(TemplateClubResponseMock);
  const [tClubResponse, setTClubFormResponse] = useState<TemplateClub>(TemplateClubResponseMock);
  const [clubCategory, setClubCategory] = useState('');
  const [activeButtons, setActiveButtons] = useState(true);

  const categoriesOptions = categories.map((e: any) => {
    return {
      label: e.name,
      value: e.filterType + `_` + e.name,
    }
  })

  useEffect(() => {
    dispatch<any>(getTemplateCategories())
  }, []);

  // const handleOnChangeUserSelect = (
  //   e: React.ChangeEvent<HTMLSelectElement>,
  //   inputName: string,
  // ) => handleOnChangeSelect(
  //   e,
  //   inputName,
  //   userForm,
  //   setUserForm,
  // )

  // const handleUserSave = () => {
  //   const formToSent = UserToResponse(userResponse, userForm);
  //   dispatch<any>(updateUser(id || '', formToSent))
  //   navigate(`/users/${id}/view`);
  // }

  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<OnChangeValue<Option, true>>([]);
  const [clubTypeSelected, setClubTypeSelected] = useState('')

  const components = {
    DropdownIndicator: null,
  };

  interface Option {
    readonly label: string;
    readonly value: string;
  }

  const createOption = (label: string) => ({
    label,
    value: label,
  });

  const handleChange = (
    value: OnChangeValue<Option, true>,
    actionMeta: ActionMeta<Option>
  ) => {
    console.group('Value Changed');
    console.log(value);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
    setValue(value);
  };

  const handleCheckBox = (event: any) => {

    const { value, checked } = event.target;
    const { member_type } = tClubForm;

    if (checked) {
      setTClubForm({
        ...tClubForm,
        member_type: [...member_type, value],
      });
    } else {
      setTClubForm({
        ...tClubForm,
        member_type: member_type.filter((e) => e !== value),
      });
    }
  }

  const handleInputChange = (inputValue: any) => {
    setInputValue(inputValue);
  };

  const handleOnChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputName: string,
  ) => setTClubForm({
    ...tClubForm,
    [inputName]: e.target.value,
  })

  const handleOnChangeSelect = (
    e: React.ChangeEvent<HTMLSelectElement>,
    inputName: string,
  ) => {
    setTClubForm({
      ...tClubForm,
      [inputName]: e.target.value,
    })
    setClubTypeSelected(e.target.value)
  }

  const handleChangeCategory = (event: any) => {
    if (tClubForm) {
      setClubCategory(event.label)
      setTClubForm({
        ...tClubForm,
        category: event.value.replace('SCHOOL_', ''),
      })
    }
  }

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    const { skills_learned } = tClubForm;
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setInputValue('');
        setValue([...value, createOption(inputValue)])
        event.preventDefault();
    }

    setTClubForm({
      ...tClubForm,
      skills_learned: [...skills_learned, inputValue],
    });
  };

  const handleRadioButton = (event: any) => {
    setTClubForm({
      ...tClubForm,
      grad_year: event.target.value,
    })
  }

  const handleSave = () => {

    const formToSent = { ...tClubResponse, ...tClubForm, role_type: tClubForm.club_type === 'COMM' ? 'ZAA#'+tClubForm.member_type[0]: 'NO_ROLE_ASSIGNED' };

    setActiveButtons(false)

    Backend(
      MicroServices.Club,
      `/template-clubs`, {
      method: "POST",
      body: JSON.stringify(formToSent),
    })
      .then((response) => {
        if (response.status === 204 || response.status === 201 || response.status === 200 ) {
          toast.success(t('toast_creatingClub'))
          navigate(`../template-clubs/`)
        }
        else {
          setActiveButtons(true)
          toast.error(t('toast_errorOccurred'))
        }
      })
      .catch((error) => {
        toast.error(t('toast_errorOccurred'))
        setActiveButtons(true)
      });
  }

  return (

    <div className="content">
      <div className="top-cont">
        <div className="organization robotocondensed-normal-white-16px">Manage Template Clubs</div>
        <div className="text-1 robotocondensed-normal-white-16px">/</div>
        <div className="view-organization robotocondensed-bold-lavender-16px">Create Club</div>
      </div>
      <div className="org-view-cont">
        <div className="title-column">
          <h1 className="title-name">Create Club</h1>
          <div className="buttons-box">
            <div className="button-w">
              <div className="cancel" onClick={() => navigate('../template-clubs/')}>Cancel</div>
            </div>
            <div className="button-p">
              <div className="save roboto-normal-white-16px" onClick={handleSave}>Create</div>
            </div>
          </div>
        </div>
        <div className="view-columns">
          <div className="col-1">
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">Club Title</div>
              <div className="info-field" id="input-color" >
                <input
                  className="info-field-update roboto-normal-white-16px"
                  type="text"
                  name="title"
                  onChange={
                    (e: React.ChangeEvent<HTMLInputElement>) =>
                      handleOnChangeInput(e, 'name')
                  }
                />
              </div>
            </div>
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">Filter Type</div>
              <div className="info-field" id="input-color">
                <select
                  className="info-field-update roboto-normal-white-16px"
                  onChange={
                    (e: React.ChangeEvent<HTMLSelectElement>) =>
                      handleOnChangeSelect(e, 'filter_type')
                  }
                >
                  <option selected disabled>Type Category</option>
                  <option value="ALL">All</option>
                  <option value="SCHOOL">School</option>
                  <option value="ASSOTIATION">Assotiation</option>
                  <option value="SUPERADMIN">Superadmin</option>
                </select>
                {/* TODO: States */}
              </div>
            </div>
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">Club Type</div>
              <div className="info-field" id="input-color">
                <select
                  className="info-field-update roboto-normal-white-16px"
                  onChange={
                    (e: React.ChangeEvent<HTMLSelectElement>) =>
                      handleOnChangeSelect(e, 'club_type')
                  }
                >
                  <option selected disabled>Club Type</option>
                  <option value="CLUB">Club</option>
                  <option value="TEAM">Team</option>
                  {/*<option value="COMM">Community</option>*/}
                </select>
                {/* TODO: States */}
              </div>
            </div>
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">Description</div>
              <div className="info-field" id="input-color" >
                <input
                  className="info-field-update roboto-normal-white-16px"
                  type="text"
                  name="description"
                  onChange={
                    (e: React.ChangeEvent<HTMLInputElement>) =>
                      handleOnChangeInput(e, 'description')
                  }
                />
              </div>
            </div>
            <div className="info-field-column">
              <div className="label-cont-column robotocondensed-bold-lavender-16px">Skills Learned Tags</div>
              <div className="tag-field" id="input-color" >
                <CreatableSelect
                  components={components}
                  inputValue={inputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={handleChange}
                  onInputChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder=""
                  value={value}
                  styles={{
                    singleValue: (base) => ({
                      ...base,
                      display: "flex",
                      alignItems: "center",
                      color: 'white',
                      backgroundColor: 'red',
                    }),
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: 'var(--violent-violet)',
                      borderRadius: "3px",
                      boxShadow: "none",
                      border: 0,
                      minWidth: "50%"
                    }),
                    option: (styles) => ({
                      ...styles,
                      color: 'white',
                      backgroundColor: 'var(--violent-violet)',
                      display: 'flex',
                      flexDirection: 'row',
                    }),
                    placeholder: (styles) => ({
                      ...styles,
                      color: 'white',
                      textAlign: "left"
                    }),
                    container: (styles) => ({
                      ...styles,
                      padding: 0,
                      width: "100%"
                    }),
                    menu: (styles) => ({
                      ...styles,
                      borderRadius: "8px",
                      backgroundColor: "var(--violent-violet)",
                    }),
                    input: (styles: any, state: any) => ({
                      ...styles,
                      border: 'none',
                      color: 'white',
                    }),
                    dropdownIndicator: (styles) => ({ ...styles, display: "none" }),
                    indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
                    multiValue: (styles) => ({
                      ...styles,
                      color: 'white',
                      backgroundColor: 'var(--electric-violet-2)',
                    }),
                    multiValueLabel: (styles) => ({
                      ...styles,
                      color: 'white',
                      fontFamily: 'RobotoCondensed-Regular',
                      fontSize: '16px'
                    }),
                    multiValueRemove: (styles) => ({
                      ...styles,
                      color: 'white',
                      backgroundColor: "transparent"
                    }),
                  }}
                />
              </div>
            </div>
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">Category</div>
              <div className="info-field" id="input-color">
                <Select
                  value={{
                    value: clubCategory,
                    label: clubCategory
                  }}
                  isMulti={false}
                  options={categoriesOptions}
                  placeholder={"View"}
                  onChange={handleChangeCategory}
                  styles={{
                    singleValue: (base) => ({
                      ...base,
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                      fontFamily: "Roboto",
                    }),
                    input: (base) => ({
                      color: 'white',
                      fontFamily: "Roboto",
                    }),
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: 'var(--violent-violet)',
                      borderRadius: "3px",
                      boxShadow: "none",
                      border: 0,
                      minWidth: "100%"
                    }),
                    option: (styles) => ({
                      ...styles,
                      color: 'white',
                      backgroundColor: '#383838',
                      display: 'flex',
                      flexDirection: 'row',
                      fontFamily: 'Roboto'
                    }),
                    placeholder: (styles) => ({
                      ...styles,
                      color: 'white',
                      textAlign: "left",
                      fontFamily: "Roboto",
                    }),
                    container: (styles) => ({
                      ...styles,
                      padding: 0,
                      width: "100%"
                    }),
                    menu: (styles) => ({
                      ...styles,
                      borderRadius: "8px",
                      backgroundColor: "#383838",
                    }),
                    valueContainer: (styles) => ({
                      ...styles,
                      display: "flex",
                    })
                  }}
                  components={{
                    IndicatorSeparator: () => null
                  }}
                />
              </div>
            </div>
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">Official Youth <br /> Organization</div>
              <div className="info-field" id="input-color" >
                <input
                  className="info-field-update roboto-normal-white-16px"
                  type="text"
                  name="org"
                />
              </div>
            </div>
            <div className="info-field-row">
              <div className="label-cont robotocondensed-bold-lavender-16px">Organization <br /> Website</div>
              <div className="info-field" id="input-color" >
                <input
                  className="info-field-update roboto-normal-white-16px"
                  type="text"
                  name="org-web"
                />
              </div>
            </div>
          </div>

          <div className="col-2">
            <div className="info-field-row">
              <div className="robotocondensed-bold-lavender-16px">Club Logo</div>
              <div className="image-select" id="input-color" >
                <FontAwesomeIcon
                  icon="camera"
                  size='lg'
                  color={WHITE}
                  className="camera  "
                />
                <div className="select-file robotocondensed-normal-white-16px">Select File</div>
              </div>
            </div>
            {clubTypeSelected === 'COMM' &&
              <div className="info-field-column">
                <div className="label-cont-templateclub robotocondensed-bold-lavender-16px">Graduation Year</div>
                <div className="select-container-status">
                  {Array.from(Array(5)).map((_value, key) => (
                    <div className="option-status">
                      <label className="content-input">
                        <input
                          type="radio"
                          id={`button${key}`}
                          name={`buttonYear`}
                          value={key + 2022}
                          checked={tClubForm?.grad_year === `${key + 2022}`}
                          onChange={handleRadioButton}
                        />
                        <i></i>
                      </label>
                      <label>{key + 2022}</label>
                    </div>
                  ))
                  }
                </div>
              </div>
            }
            <div className="info-field-column">
              <div className="label-cont-templateclub robotocondensed-bold-lavender-16px">Member Type</div>
              <div className="select-container-status">
                {Object.entries(MEMBER_MAP).map(([memberKey, memberValue]) => (
                  <div className="option-status">
                    <label className="content-input">
                      <input
                        type="checkbox"
                        name="button"
                        value={memberKey}
                        onChange={handleCheckBox}
                      />
                      <i></i>
                    </label>
                    <label htmlFor="students">{memberValue}</label>
                  </div>
                ))}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateTemplateClub;
