import "./styles.scoped.css";
import React, {useState, useEffect} from "react";
import ListPosts from "../../components/Post/ListPosts";
import {useDispatch, useSelector} from "react-redux";
import {toast} from 'react-toastify';
import PostInput from "../PostInput";
import {getMyClubs} from "../../store/actions/clubActions";
import ListPostsSaved from "../../components/Post/ListPostsSaved";
import { useParams } from "react-router-dom";
import { reloadPostsAll } from "../../store/actions/postActions";
import StartPost from "../../components/PostInputLined/StartPost";
import PostModal from "../../components/PostInputLined/PostModal";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";

toast.configure();

interface PostProps {
	//todo -> put types
    whereIs:any;
    eventId:any;
    clubId:any;
    username:any;
    tabActive:any;
	ChatServiceInstance:ChatServiceInterface;
}

function Posts(props: PostProps) {

    const dispatch = useDispatch();

    const [propsBefore, setPropsBefore] = useState<any>({
        whereIs:''
    });

    //userInfo
    const userInfo = useSelector((state: any) => state.getUser.userInfo);
    const user = useSelector((state: any) => state.getUser.user);
    const organization = useSelector(
        (state: any) => state.getOrgs.organizationInfo
    );

    //Lists of posts
    const posts = useSelector((state: any) => state.getPost.getPostsBulletin || []);
    const postsOrg = useSelector((state: any) => state.getPost.getPostsOrg || []);
    const postsFriends = useSelector((state: any) => state.getPost.getPostsFriends || []);
    const postsSave = useSelector((state: any) => state.getPost.getPostsSave || []);
    const postsFeedProfile = useSelector((state: any) => state.getPost.getPostsFeedProfile || []);
    const postsFeedConnections = useSelector((state: any) => state.getPost.getPostsFeedConnections || []);
    const postsEvent = useSelector((state: any) => state.getPost.getPostsEvent || []);
    const postsClub = useSelector((state: any) => state.getPost.getPostsClub || []);
    
    //Loaders postsGet
    const loaderPosts = useSelector((state: any) => state.getPost.loadingGetPostsBulletin);
    const loaderPostsOrg = useSelector((state: any) => state.getPost.loadingGetPostsOrg);
    const loaderPostsFriends = useSelector((state: any) => state.getPost.loadingGetPostsFriends);
    const loaderPostsSave = useSelector((state: any) => state.getPost.loadingGetPostsSave);
    const loaderPostsFeedProfile = useSelector((state: any) => state.getPost.loadingGetPostsFeedProfile);
    const loaderPostsFeedConnections = useSelector((state: any) => state.getPost.loadingGetPostsFeedConnections);
    const loaderPostsEvent = useSelector((state: any) => state.getPost.loadingGetPostsEvent);
    const loaderPostsClub = useSelector((state: any) => state.getPost.loadingGetPostsClub);

    useEffect(() => {
        if (userInfo.username) {
            dispatch<any>(getMyClubs(userInfo.username))
                .then(console.log)
                .catch(console.log);
        }
    }, [userInfo]);

    useEffect(() => {
        if(props.whereIs !== propsBefore.whereIs){
            setPropsBefore(props)
        }
        else{
            if(props.whereIs === 'event'){
                if(props.eventId !== propsBefore.eventId){
                    setPropsBefore(props)
                }
            }

            else if(props.whereIs === 'club'){
                if(props.clubId !== propsBefore.clubId){
                    setPropsBefore(props)
                }
            }

            else if(props.whereIs === 'connection'){
                if(props.username !== propsBefore.username){
                    setPropsBefore(props)
                }
            }
        }
    }, [props]);

    useEffect(() => {
        reloadPosts();
    }, [propsBefore])

    const reloadPosts = () => {
        
        const form = {
            username: userInfo.username || '',
            event_id:'',
            club_id: '',
            org_id:'',
            wall_id: '',
        }

        if(propsBefore.eventId){
            form.event_id = propsBefore.eventId;
        }

        if(propsBefore.clubId){
            form.club_id = propsBefore.clubId;
        }

        if(propsBefore.username){
            form.wall_id = propsBefore.username;
        }

        if(organization.id || (user.organizations && user.organizations[0])){
            form.org_id = organization.id || user.organizations[0] || '';
        }

        dispatch<any>(reloadPostsAll(form));
    }

    const isInputDisplayed = () => {
        if(props.tabActive === 1 || props.tabActive === 2 || props.tabActive === 3){
            return false
        }
        return true
    }

    const perfectLoader = () => {
        if(propsBefore.tabActive === 0 && loaderPosts){
            return true;
        }

        if(propsBefore.tabActive === 1 && loaderPostsOrg){
            return true;
        }

        if(propsBefore.tabActive === 2 && loaderPostsFriends){
            return true;
        }

        if(propsBefore.tabActive === 3 && loaderPostsSave){
            return true;
        }

        if(propsBefore.tabActive === 4 && loaderPostsFeedProfile){
            return true;
        }

        if(propsBefore.tabActive === 5 && loaderPostsEvent){
            return true;
        }

        if(propsBefore.tabActive === 6 && loaderPostsClub){
            return true;
        }

        if(propsBefore.tabActive === 7 && loaderPostsFeedConnections){
            return true;
        }

        else{
            return false;
        }
    }

    return (
        <div className="bulletin">
            <div className="postsBulletin">
                {/*(props.whereIs !== 'connection' && isInputDisplayed()) && (
                <PostInput 
                    clubId={props.clubId} 
                    eventId={props.eventId} 
                    reloadPosts={reloadPosts}
                    whereIs={props.whereIs}
                    activeTab={props.tabActive}
                >
                </PostInput>)*/}

                {/*(props.whereIs !== 'connection' && isInputDisplayed()) && (
                <StartPost 
                    whereIs={props.whereIs}
                    clubId={props.clubId} 
                    eventId={props.eventId} 
                    reloadPosts={reloadPosts}
                />)*/}

                {(props.whereIs !== 'connection' && isInputDisplayed()) && (
                <PostModal 
                    whereIs={props.whereIs}
                    clubId={props.clubId} 
                    eventId={props.eventId} 
                    reloadPosts={reloadPosts}
                />)}

                {(perfectLoader()) && (
                <div className="spinner-container">
                    <div className="loading-spinner">
                    </div>
                </div>
                )}
                <div className="PostWithoutPosting">
                    {(props.tabActive === 3) ? (
                    <ListPostsSaved reloadPosts={reloadPosts} posts={postsSave}/>
                    ) : (
                    <ListPosts posts={
                        props.tabActive === 0 ? posts
                            : props.tabActive === 1 ? postsOrg
                                : props.tabActive === 2 ? postsFriends
                                    : props.tabActive === 4 ? postsFeedProfile
                                        : props.tabActive === 5 ? postsEvent
                                            : props.tabActive === 6 ? postsClub
                                                : props.tabActive === 7 ? postsFeedConnections
                                        : ""}
                               reloadPosts={reloadPosts}
                               ChatServiceInstance={props.ChatServiceInstance}/>)}
                </div>
            </div>
        </div>
    );
}

export default Posts;