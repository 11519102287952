import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { WHITE } from "../../../../components/ClubIconsNavBar";
import ModalDiscardChanges from "../../../../components/SetupSetup/ModalDiscardChanges";
import TCList from "../../../../interfaces/TClubs/TCList/TCList";
import TClubAssignedComplete from "../../../../interfaces/TClubs/TCList/TClubAssignedComplete";
import { CancelButton, ClickableIcon, Row, SaveButton } from "../../components";
import { useTranslation } from "react-i18next";
import TitleEdit from "../../../../components/TitleEdit";
import { Backend, MicroServices } from "../../../../helpers/backendHelper";
import { toast } from "react-toastify";
import ModalBaseProps from "../../../../interfaces/modals/ModalBaseProps";
import TClub from "../../../../interfaces/TClubs/TClub/TClub";
import YODropdown, { YOOption } from "./components/YODropdown";
import useGender from "../../../../hooks/useGender";
import useGrades from "../../../../hooks/useGrades";
import styled from "styled-components";

interface AddYouthModalProps extends ModalBaseProps {
    tclub: TClub;
}

const AddYouthModal = ({ show, onHide, tclub }: AddYouthModalProps) => {
    const { t } = useTranslation();
    const { genders, setCountry: setCountryGenders } = useGender();
    const { grades, setCountry: setCountryGrades } = useGrades();

    const [showDiscard, setShowDiscard] = useState(false);
    const [loadingAssign, setLoadingAssign] = useState(false);
    const [YOSelected, setYOSelected] = useState<YOOption | undefined>();
    const [gendersAllowed, setGendersAllowed] = useState<string[]>([]);
    const [gradesAllowed, setGradesAllowed] = useState<string[]>([]);

    const [showYOError,setShowYOError] = useState(false)
    const [showGendersError,setShowGendersError] = useState(false)
    const [showGradesError,setShowGradesError] = useState(false)


    const cleanForm = () => {
        setYOSelected(undefined);
        setGendersAllowed([]);
        setGradesAllowed([]);
        setShowYOError(false)
        setShowGendersError(false)
        setShowGradesError(false)
    }

    useEffect(() => {
        setCountryGenders("USA");
        setCountryGrades("USA");
    }, []);

    const onCancel = () => setShowDiscard(true);

    const errorsInForm = ()=>{
        let hasErrors = false;

        if(!YOSelected){
            hasErrors = true
            setShowYOError(true)
        }else{
            setShowYOError(false)
        }

        if(!gendersAllowed.length){
            hasErrors = true
            setShowGendersError(true)
        }else{
            setShowGendersError(false)
        }

        if(!gradesAllowed.length){
            hasErrors = true
            setShowGradesError(true)
        }else{
            setShowGradesError(false)
        }

        return hasErrors;
    }

    const onHideDiscard = (type: string) => {
        setShowDiscard(false);
        if (type === "discard") {
            cleanForm();
            onHide();
        }
    };

    const onSaveAssign = () => {
        if(errorsInForm()){
            toast.error(t('toast_errorMissingInformation'))
            return
        }

        setLoadingAssign(true);
        Backend(MicroServices.Club, `/tclubs/${tclub.id || ""}/assign-yo`, {
            method: "POST",
            body: JSON.stringify({
                yo_id: YOSelected?.value,
                genders_allowed: gendersAllowed,
                grades_allowed: gradesAllowed,
            }),
        })
            .then((response) => {
                if (response.status === 201) {
                    toast.success(t('toast_YOAssigned'));
                    cleanForm();
                    onHide();
                    return;
                }
            })
            .catch((error) => toast.error(error))
            .finally(() => setLoadingAssign(false));
    };

    const handleOnChangeGenders = (event: any) => {
        let updateList = [...gendersAllowed];
        if (event.target.checked) {
            updateList = [...gendersAllowed, event.target.value];
        } else {
            updateList.splice(gendersAllowed.indexOf(event.target.value), 1);
        }
        setGendersAllowed(updateList);
    };

    const handleCheckGradesAllowed = (event: any) => {
        let updateList = [...gradesAllowed];
        if (event.target.checked) {
            updateList = [...gradesAllowed, event.target.value];
        } else {
            updateList.splice(gradesAllowed.indexOf(event.target.value), 1);
        }
        setGradesAllowed(updateList);
    };

    return (
        <>
            <Modal
                className="modalInviteMemebers"
                show={show}
                onHide={onCancel}
            >
                <Modal.Header>
                    <Modal.Title>
                        Add Youth Organization Affiliated to {tclub.name}
                    </Modal.Title>
                    <ClickableIcon
                        icon={faTimes}
                        size="xl"
                        color={WHITE}
                        className="icon-times"
                        onClick={onCancel}
                    />
                </Modal.Header>
                <Modal.Body>
                    <FieldRow>
                        <YODropdown
                            YOSelected={YOSelected}
                            setYOSelected={setYOSelected}
                        />
                        {showYOError && <ErrorText>Select Youth Organization</ErrorText>}
                    </FieldRow>
                    <FieldRow>
                        <FieldTitle className="robotocondensed-bold-lavender-16px">
                            {t("setupSchool__gender")}
                        </FieldTitle>
                        <GendersWrapper>
                            {genders.map((gender) => (
                                <GenderWrapper className="option-chechbox">
                                    <label className="content-input">
                                        <input
                                            type="checkbox"
                                            name="checkbox-F"
                                            value={gender.id}
                                            checked={(
                                                gendersAllowed || []
                                            ).includes(gender.id)}
                                            onChange={handleOnChangeGenders}
                                        />
                                        <i />
                                    </label>
                                    <GenderLabel htmlFor="students">
                                        {gender.label}
                                    </GenderLabel>
                                </GenderWrapper>
                            ))}
                        </GendersWrapper>
                        {showGendersError && <ErrorText>Select at least one gender</ErrorText>}
                    </FieldRow>
                    <FieldRow>
                        <FieldTitle className="robotocondensed-bold-lavender-16px">
                            {t("setupSchool__grades")}
                        </FieldTitle>
                        <GendersWrapper>
                            {grades
                                .sort((a, b) => a.id - b.id)
                                .map((grade) => (
                                    <GenderWrapper className="option-chechbox">
                                        <label className="content-input">
                                            <input
                                                type="checkbox"
                                                name="checkbox-F"
                                                value={grade.id}
                                                checked={(
                                                    gradesAllowed || []
                                                ).includes(String(grade.id))}
                                                onChange={
                                                    handleCheckGradesAllowed
                                                }
                                            />
                                            <i />
                                        </label>
                                        <GenderLabel htmlFor="students">
                                            {grade.listLabel}
                                        </GenderLabel>
                                    </GenderWrapper>
                                ))}
                        </GendersWrapper>
                        {showGradesError && <ErrorText>Select at least one grade</ErrorText>}
                    </FieldRow>
                </Modal.Body>
                <Modal.Footer className="modalDiscardChanges-footer">
                    <CancelButton onClick={onCancel}>
                        {t("button__cancel")}
                    </CancelButton>
                    <SaveButton onClick={onSaveAssign}>
                        {loadingAssign ? (
                            <div className="icon-spinner-third buttonSendSignUp" />
                        ) : (
                            t("button__save")
                        )}
                    </SaveButton>
                </Modal.Footer>
            </Modal>
            <ModalDiscardChanges show={showDiscard} onHide={onHideDiscard} />
        </>
    );
};

const GendersWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 15px;
    justify-content: space-between;
`;

const GenderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const GenderLabel = styled.label`
    margin-left: 30px;
    color: white;
    text-align: left;
    font-family: var(--font-family-roboto);
`;

const FieldTitle = styled.h2`
    width: 100%;
    text-align: left;
`;

const FieldRow = styled.div`
    width: 100%;
    padding: 10px 0px;
`;

const ErrorText = styled.span`
    width: 100%;
    text-align: left;
    font-family: var(--font-family-roboto);
    color: white;
    font-size: 12px;
`;

export default AddYouthModal;
