import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import NumericInput from 'react-numeric-input';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { WHITE } from "../ClubIconsNavBar";

function Index(props: any) {

  function handleOnClickOk(e: any, type: any){
    props.onHide(e, type)
  }

  const array = [
      {label: 'Day(s)', value: 'Day(s)'},
      {label: 'Week(s)', value: 'Week(s)'},
      {label: 'Month(s)', value: 'Month(s)'},
  ];

  const [selected, setSelected] = useState("");
  const [input, setInput] = useState<string>('');
  const [inputfocus, setInputFocus] = useState(false);
  const [outside, setOutside] = useState(false);

	const [startDate, setStartDate] = useState(new Date());

  const customStyles = (value: any, input: any, outside: any, inputfocus: any) => ({
    option: (styles: any) => ({
      ...styles,
			color: 'white',
			backgroundColor: "#2C2C2C",
			fontFamily: 'Roboto Condensed',
			"&:hover": {
        backgroundColor: "#7400F9"
      },
    }),
    input: (styles: any, state: any) => ({
      ...styles,
			color: 'white',
    }),
    container: (styles: any) => ({
      ...styles,
      width: '100%',
			padding: '0 5px'
    }),
    valueContainer: (styles: any, state: any) => ({
      ...styles,
			color: 'red',
    }),
    menu: (styles: any) => ({
      ...styles,
			backgroundColor: '#2C2C2C',
			color: 'white',
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      height: '54px',
			backgroundColor: '#2C2C2C',
			border: 0,
			boxShadow: 'none',
			
    }),
    placeholder: (styles: any) => ({
      ...styles,
			color: 'white',
    }),
    indicatorSeparator: (styles: any) => ({ ...styles, display: 'none' }),
    dropdownIndicator: (styles: any) => ({ ...styles, }),
    noOptionsMessage: (styles: any) => ({
      ...styles,
    }),
    singleValue: (styles: any) => ({
      ...styles,
			color: 'white',
			fontFamily: 'Roboto Condensed',
    }),
  });
  
  return (
    <>
        <Modal className="modalDelete" show={props.show} onHide={props.onHide}>
            <Modal.Header>
                <Modal.Title>Temporarily Suspend Poster</Modal.Title>
                <FontAwesomeIcon
                    icon={faTimes}
                    size='xl'
                    color={WHITE}
                    className="icon-times"
                    onClick={(e) => handleOnClickOk(e, 'modal')}
                />
            </Modal.Header>
            <Modal.Body>
                <div className="contentPeriod">
                    <p className="titlePeriod">Period of suspension</p> 
                    <Select 
											isMulti={false}
											options={array}
											styles={customStyles(selected, input === 'selectRole', outside, inputfocus)}
											defaultValue={{ label: 'Week(s)', value: 'Week(s)' }}
                    />
                    <NumericInput className="form-control" min={ 0 } style={{
                        input: {
                            marginRight: 3,
                            paddingRight: 4,
                            textAlign: 'left',  
                            height: 54,
                            backgroundColor: '#2C2C2C',
                            width: '100%',
                            paddingLeft: '15px',
                            color: 'white'
                        },
                        btnUp: {
                            display: 'block',
                            width: '60px',
                            height: '25px',
                            boxShadow: 'none',
                            top: 1,
                            right: 0,
                            color: 'white'
                        },
                        btnDown: {
                            display: 'block',
                            width: '60px',
                            height: '25px',
                            boxShadow: 'none',
                            top: 28,
                            right: 0,
                            color: 'white'
                        },
                        'input:focus' : {
                            border: '1px inset #69C',
                            outline: 'none'
                        },
                        arrowUp: {
                            borderBottomColor: 'white',
                            marginBottom: 1,
                            display: 'none'
                        },
                        arrowDown: {
                            borderTopColor: 'white',
                            marginTop: 1,
                            display: 'none'
                        }
                    }}/>
                </div>
								<div className="dateSelected">
									<DatePicker selected={startDate} onChange={(date:Date) => setStartDate(date)} />
									<p className="textTo">To</p>
									<DatePicker selected={startDate} onChange={(date:Date) => setStartDate(date)} />
								</div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="buttonSendEvent" onClick={(e) => handleOnClickOk(e, 'delete')}>
                    Cancel
                </Button>
                <Button className="buttonSendEvent" onClick={(e) => handleOnClickOk(e, 'delete')}>
                    Suspend poster
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  );
}

export default Index;