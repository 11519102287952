import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import SuperAdminLeftMenu from '../../components/SuperAdminLeftMenu';

function App() {
  const location = useLocation();
  const screen = location.pathname;

  return (
    <div className="container-layout">
      <div className="organizations screen">
        <SuperAdminLeftMenu screen={screen} />
        <Header />
        <Outlet />
      </div>
    </div>
  );
}

export default App;
