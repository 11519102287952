import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TCListSearch from "../../../../components/TClubs/TCList/TCListSearch";
import TableAdmin from "../../../../components/TableAdmin";
import TClub from "../../../../interfaces/TClubs/TClub/TClub";
import useTClubsSA from "../../../../hooks/tclub/useTClubsSA";
import { LAVENDER } from "../../../../components/ClubIconsNavBar";
import {
    MainWrapper,
    ContentWrapper,
    Header,
    Row,
    RowGap,
    ActionWrapper,
    ClickableIcon,
    PhantomElement,
    RowText,
    HeaderSubTitle,
    HeaderText,
    TitleNested,
} from '../../../../components/SuperAdminComponents/basicForSACenter';
import TCList from "../../../../interfaces/TClubs/TCList/TCList";
import { getTCList } from "../../../../helpers/TClubs/TCList";
import AssignModal from "./AssignModal";
import TClubAssignedComplete from "../../../../interfaces/TClubs/TCList/TClubAssignedComplete";
import useInterestsSA from "../../../../hooks/Interests/useInterestsSA";
import INTList from "../../../../interfaces/Interests/INTLists/INTList";
import { getIntList } from "../../../../helpers/Interests/IntList";
import INTAssignedComplete from "../../../../interfaces/Interests/INTLists/INTAssignedComplete";
import styled, { css } from "styled-components";

function IntListDetail() {
    const {id} = useParams();
    const navigate = useNavigate();
    const { intPages, reloadInterests } = useInterestsSA(1000);

    const [intlist, setIntlist] = useState<INTList>();
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [intSelected, setIntSelected] = useState<INTAssignedComplete>()
    const [IntListSearchTerm, setIntListSearchTerm] = useState('');

    const filteredInt = useMemo(
        () =>
            intPages[0]
                .filter((int) => int.name.toLowerCase().includes(IntListSearchTerm.toLowerCase())),
        [intPages[0], IntListSearchTerm]
    )

    const reloadIntList = useCallback(
      () => {
        if (id) {
            getIntList(id)
                .then((list) => setIntlist(list))
                .catch((error) => {navigate(-1)}) // handle error
        }
      },
      [id],
    )

    useEffect(() => reloadIntList(), [id]);

    const openModal = (tclub: INTAssignedComplete) => {
        setIntSelected(tclub);
        setShowAssignModal(true);
    }

    const onHideAssignModal = () => {
        setShowAssignModal(false);
        reloadIntList();
    }

    return (
        <>
            <MainWrapper>
                <Header>
                    <Row>
                        <TitleNested onClickBack={() => navigate('/superadmin/interests')} key={intlist?.name || ''}>{intlist ? intlist.name : ''}</TitleNested>
                    </Row>
                    <RowText>
                        <HeaderSubTitle>Assign Interests to "{intlist ? intlist.name : ''}"</HeaderSubTitle>
                        <HeaderText>
                            From the default interests, you must select and assign an interest to the new list.You can edit the name. If an interest is not found, you may add a new interest,
                        </HeaderText>
                    </RowText>
                    <RowGap>
                        <PhantomElement />
                        <TCListSearch searchTerm={IntListSearchTerm} setSearchTerm={setIntListSearchTerm}/>
                    </RowGap>
                </Header>
                <ContentWrapper>
                    <InterestsWrapper>
                        {filteredInt.map((int) =>
                            (intlist?.interests || []).map(({id}) => id).includes(int.id)
                                ? <AssignedInterestsItem>{int.name}</AssignedInterestsItem>
                                : <InterestsItem onClick={() => openModal(int)}>{int.name}</InterestsItem>
                        )}
                    </InterestsWrapper>
                </ContentWrapper>
            </MainWrapper>

            {/* modals */}
            <AssignModal
                show={showAssignModal}
                interestSelected={intSelected}
                intlist={intlist}
                onHide={onHideAssignModal}
            />
        </>
    );
}

const InterestsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
`;

const AssignedInterestsItem = styled.div`
    padding: 10px;
    color: black;
    font-family: 'Roboto';
    border-radius: 20px;
    background-color: #23933e;
    font-size: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const InterestsItem = styled.button`
    padding: 10px;
    color: black;
    font-family: 'Roboto';
    background-color: gray;
    border-radius: 20px;
    border: none;
    outline: none;
    font-size: 16px;
    cursor: pointer;
`;

export default IntListDetail;