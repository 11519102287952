import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import TabDetailClub from './TabDetailClub';
import TabListsMembers from './TabListsMembers';
import Button from 'react-bootstrap/Button';
import '../../containers/SetupStudent/styles.scoped.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { WHITE } from "../ClubIconsNavBar";
import { toast } from 'react-toastify'
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { getMyPendingClubs } from "../../store/actions/clubActions";

interface ModalClubProps {
  //todo -> put types
  setTabActive: any;
  setShowSetupClub?: any;
  club: any;
  onHide: any;
  show: any;
  tabActive: any;
  typeClub: any;
  pendingClubModal?: any;
  setJoinedClubs?: any;
  setFollowingClubs?: any;
}

function Index(props: ModalClubProps) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [loaderButton, setLoaderButton] = useState(false)
  const [loaderButtonFollow, setLoaderButtonFollow] = useState(false)
  const [loaderButtonJoin, setLoaderButtonJoin] = useState(false)

  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  );

  function handleOnClick(e: any, tab: any) {
    props.setTabActive(tab);
  }

  function followClub() {
    if (!loaderButton && !loaderButtonFollow) {
      setLoaderButton(true)
      setLoaderButtonFollow(true)
      Backend(
        MicroServices.Club,
        `/clubs/${props.club?.club_id}/follower?username=${userInfo.username}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((results) => {
          if (results.status === 201) {
            toast.success(`${t('toast_successSendingFollow')} ${props.club?.name || 'Club'}`);
            if (props.setFollowingClubs && props.club)props.setFollowingClubs((followingClubs: any) => [...followingClubs, props.club]);
            props.onHide()
          }
          else {
            toast.error(t('toast_errorSendingFollow'));
          }
        })
        .catch((error) => {
          toast.error(t('toast_errorSendingFollow'));
          console.log('Error followClub', error);
        })
        .finally(() => {
          setLoaderButton(false)
          setLoaderButtonFollow(false)
          if (props.setShowSetupClub) props.setShowSetupClub(false);
        });
    }
  }

  function JoinClub() {
    if (!loaderButton && !loaderButtonJoin) {
      setLoaderButton(true)
      setLoaderButtonJoin(true)
      Backend(
        MicroServices.Club,
        `/clubs/${props.club?.club_id}/join?username=${userInfo.username}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((results) => {
          if (results.status === 201) {
            toast.success(`${t('toast_successSendingJoin')} ${props.club?.name || 'Club'}`);
            if (props.setJoinedClubs && props.club) props.setJoinedClubs((joinedClubs: any) => [...joinedClubs, props.club]);
            props.onHide()
          }
          else {
            toast.error(t('toast_errorSendingJoin'));
          }
        })
        .catch((error) => {
          toast.error(t('toast_errorSendingJoin'));
          console.log('Error JoinClub', error);
        })
        .finally(() => {
          setLoaderButton(false)
          setLoaderButtonJoin(false)
          if (props.setShowSetupClub) props.setShowSetupClub(false);
        });
    }
  }

  const haveImage = () => {
    return (!props.club?.cover && !organization.logo) ? false : true
  }

  const deleteJoinRequest = () => {
    let error = false
    setLoaderButton(true)
    setLoaderButtonFollow(true)

    Backend(
      MicroServices.Club,
      `/clubs/${props.club?.club_id || props.club?.id}/delete/join?username=${userInfo.username}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          error = true;
        }
        return response.json();
      })
      .then((results) => {
        if (error !== true) {
          toast.success(t('toast_requestSuccessfullyCancelled'))
          props.onHide()
          setLoaderButton(false)
          setLoaderButtonJoin(false)
          dispatch<any>(getMyPendingClubs(userInfo.username))
            .then(console.log)
            .catch(console.error)
        }
      })
      .catch((error) => {

      })
  }

  return (
    <>
      <Modal className="modalDetailClub" show={props.show} onHide={props.onHide} animation={false}>
        <Modal.Header>
          <Modal.Title>
            {(haveImage()) && (<div className="imageCob"><img src={props.club?.cover || organization.logo} alt="" /></div>)}
            <p className="titleModalClub">{props.club?.name}</p>
          </Modal.Title>
          <FontAwesomeIcon
            icon={faTimes}
            size='xl'
            color={WHITE}
            className="icon-times"
            onClick={props.onHide}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="nav">
            <div className={props.tabActive === 0 ? 'active' : ''} onClick={(e) => handleOnClick(e, 0)}>{t('setupSchool__description')}</div>
            <div className={props.tabActive === 1 ? 'active' : ''} onClick={(e) => handleOnClick(e, 1)}>{`${props.club?.membersCount || props.club?.members} ${t('comm__members')}`}</div>
          </div>
          {props.tabActive === 0 ?
            <TabDetailClub club={props.club} />
            : props.tabActive === 1 ?
              <TabListsMembers club={props.club} />
              :
              undefined
          }
        </Modal.Body>
        <Modal.Footer className={props.typeClub === 'CLUB' || props.typeClub === 'TEAM' ? 'tBottons' : 'OBottons'}>

          {props.pendingClubModal === 'Pending' ?
            <Button className={`buttonCancelRequest ${loaderButton && 'btnProcess'}`} onClick={() => deleteJoinRequest()}>
              {loaderButtonFollow ?
                <div className="icon-spinner-third buttonSendSignUp" />
                :
                t('button_cancelRequest')}
            </Button>
            :
            props.typeClub === 'CLUB' ?
              <>
                <Button className={`buttonClose ${loaderButton && 'btnProcess'}`} onClick={() => followClub()}>
                  {loaderButtonFollow ?
                    <div className="icon-spinner-third buttonSendSignUp" />
                    :
                    t('setupStudent__followClub')}
                </Button>
                <Button className={`buttonSend ${loaderButton && 'btnProcess'}`} onClick={() => JoinClub()}>
                  {loaderButtonJoin ?
                    <div className="icon-spinner-third buttonSendSignUp" />
                    :
                    t('setupStudent__joinClub')}
                </Button>
              </>
              :
              props.typeClub === 'TEAM' ?
                <>
                  <Button className={`buttonClose ${loaderButton && 'btnProcess'}`} onClick={() => followClub()}>
                    {loaderButtonFollow ?
                      <div className="icon-spinner-third buttonSendSignUp" />
                      :
                      t('setupStudent__fanTeam')}
                  </Button>
                  {(!props.club?.onlyFan) && (
                    <Button className={`buttonSend ${loaderButton && 'btnProcess'}`} onClick={() => JoinClub()}>

                      {loaderButtonJoin ?
                        <div className="icon-spinner-third buttonSendSignUp" />
                        :
                        t('setupStudent__tryoutClub')}
                    </Button>
                  )}
                </>
                :
                <Button className="buttonClose">
                  {props.typeClub}
                </Button>
          }

        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Index;