import { useState } from "react";
import "./styles.css";
import OptionsSort from "./optionsSort";
import OutsideClickHandler from 'react-outside-click-handler';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";

function Index({ styles, inputSearch, ordenSelected, orderField, isAdmin, isEvents }: any) {
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState("");
  const [searchSelected, setSearchSelected] = useState("");
  const [activeOptions, setActiveOptions] = useState(false);

  const handleChangeSearch = (event: any) => {
    inputSearch(event.target.value);
  };

  const handleOptions = () => {
    setActiveOptions(!activeOptions)
  };

  const handleSort = (e: any, type: string) => {
    ordenSelected(e)
    setActiveOptions(false)
    orderField(type)
  }

  return (
    <div
      className={(isEvents) ? `filter-bar-event` : `filter-bar-club`}
      style={{
        paddingRight: styles === "Left" ? "" : "30px",
        marginTop: styles === "Left" ? "" : "22px",
      }}
    >
      <div className={`inputSearch ${(isEvents) && ('hide')}`}>
        <FontAwesomeIcon
          icon="search"
          size='sm'
          color={WHITE}
          className="btnSort  "
        />
        <input type="text" placeholder={t('comm__search')} onChange={handleChangeSearch} />
      </div>
      {/* <div className={`filterInputSearchClub ${(isEvents) && ('hide')}`}>
        <FontAwesomeIcon
          icon="search"
          size='sm'
          color={WHITE}
          // className="btnSort  "
        />
        <input
          style={{ backgroundColor: styles === "Left" ? "" : "#28115A" }}
          className="inputSearchClub"
          onChange={handleChangeSearch}
          type="text"
          placeholder="Search"
        />
      </div> */}

      {styles === "Left" &&
        <div>
          <img src={"/a-z.png"} alt="a-z" className="a-z" onClick={() => handleOptions()} />
        </div>
      }
      {activeOptions &&
        <div className="containerOptions">
          <OutsideClickHandler
            onOutsideClick={() => {
              setActiveOptions(!activeOptions);
            }}
          >
            <OptionsSort handleSort={handleSort} isAdmin={isAdmin} isEvents={isEvents} />
          </OutsideClickHandler>
        </div>
      }
    </div>
  );
}

export default Index;
