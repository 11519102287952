/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
import React, { useEffect, useState } from 'react';
import Header from './Header';
import ModalClubSuggestions from '../../components/SetupStudent/ModalClub';
import '../../containers/SetupStudent/styles.scoped.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getUserClubSuggestion } from '../../store/actions/connectionActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LAVENDER } from '../ClubIconsNavBar';
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from '../../helpers/backendHelper';
import { toast } from 'react-toastify';

function Index({
    followingClubs,
    setFollowingClubs,
    joinedClubs,
    setJoinedClubs,
    showNewText,
    handleOnClick
}: any) {
    const { t, i18n } = useTranslation();
    const [showSetupClub, setShowSetupClub] = useState(false);
    const [tabActive, setTabActive] = useState(0);
    const [clickedClub, setClickedClub] = useState({});

    const userInfo = useSelector((state: any) => state.getUser.userInfo);

    const [array, setArray] = useState<any[]>([]);
    const [loadingFollow, setLoadingFollow] = useState<boolean>(false);
    const [loadingJoin, setLoadingJoin] = useState<boolean>(false);

    function handleCloseSetupClub() {
        setShowSetupClub(false);
        showNewText(t('setupStudent__therearePlenty'))
    }

    const clubSug = useSelector((state: RootState) => state.getConnection.GetCSug);
    const organization = useSelector(
        (state: any) => state.getOrgs.organizationInfo
    );

    useEffect(() => {
        console.log(organization)
        setArray(clubSug
            .filter((c: any) => !(c.name.startsWith('Class')))
            .map((club: any, id: number) => ({
                id: `${id}`,
                organization: organization.name,
                cover: organization.logo,
                members: [],
                tags: (club.skills_learned || []).map((item: string) => `#${item}`),
                ...club,
            }))
        )
        console.log(clubSug)
    }, [clubSug])

    const user = useSelector((state: any) => state.getUser.user);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserClubSuggestion(followingClubs.concat(joinedClubs), user.roles[0] !== 'ZAA#STUDENT'))
    }, [followingClubs, joinedClubs]);

    function createElements(n: any, arrayCreate: any) {
        const elements = [];
        let i = 0;
        for (i = 0; i < n > arrayCreate.length ? arrayCreate.length : n; i++) {
            elements.push(
                <img src={arrayCreate[i].image} alt={arrayCreate[i].image} />
            );
        }
        return elements;
    }

    const followClub = (club: any) => {
        if (!loadingFollow) {
            setLoadingFollow(true)
            setClickedClub(club)
            Backend(
                MicroServices.Club,
                `/clubs/${club.club_id}/follower?username=${userInfo.username
                }`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            ).then((results) => {
                if (results.status === 201) {
                    setFollowingClubs([...followingClubs, club]);
                }
                else {
                    toast.error(t('toast_errorSendingFollow'));
                }
            })
                .catch((error) => {
                    toast.error(t('toast_errorSendingFollow'));
                    console.log('Error followClub', error);
                })
                .finally(() => {
                    setLoadingFollow(false)
                    setClickedClub({})
                })
        }
    };

    const joinClub = (club: any) => {
        if (!loadingJoin) {
            setClickedClub(club)
            setLoadingJoin(true)
            Backend(
                MicroServices.Club,
                `/clubs/${club.club_id}/join?username=${userInfo.username
                }`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            ).then((results) => {
                if (results.status === 201) {
                    setJoinedClubs([...joinedClubs, club]);
                }
                else {
                    toast.error(t('toast_errorSendingJoin'));
                }
            })
                .catch((error) => {
                    toast.error(t('toast_errorSendingJoin'));
                    console.log('Error JoinClub', error);
                })
                .finally(() => {
                    setLoadingJoin(false)
                    setClickedClub({})
                })
        }
    };

    const [typeClub, setTypeClub] = useState<any>('');

    function clubState(c: any) {
        const club = joinedClubs.find((item: any) => item.club_id === c.club_id)
        const team = followingClubs.find((item: any) => item.club_id === c.club_id)
        if (club !== undefined) {
            setTypeClub(club.club_type === 'TEAM' ? t('setupStudent__tryoutRequested') : t('setupStudent__joinRequested'))
        } else if (team !== undefined) {
            setTypeClub(team.club_type === 'TEAM' ? t('setupStudent__fan') : t('setupStudent__following'))
        } else {
            setTypeClub(c.club_type)
        }
    }

    return (
        <>
            <div className="titles-header">
                <div className="titles">
                    <div className="myProfile-Header">
                        <h1>Zaahah</h1>
                    </div>
                    <span>{t('setupStudent__joinClubs')}</span>
                    <span>{t('setupStudent__getInvolved')}</span>
                </div>
                <div className="myProfile-Buttons myPrfAdv">
                    <button onClick={(e) => handleOnClick(e, 4, true)} className="button-start interest">{t('setupSchool__saveAndContinue')}</button>
                </div>
            </div>
            <div className="connections-staff">
                <div className="cardsClub">
                    {array.map((club: any) => (
                        <>
                            <div className="cardClub">
                                <div className="imageCover">
                                    {club.cover ?
                                        <img src={club.cover} alt="" />
                                        :
                                        <div className="noImageCover"></div>
                                    }
                                </div>
                                <div className="nameClub">
                                    <h2>{club.name}</h2>
                                    <button
                                        className="buttonEye"
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            setClickedClub(club)
                                            setShowSetupClub(true)
                                            clubState(club)
                                            showNewText(t('setupStudent__yourParticipation'))
                                        }}
                                        type="button"
                                    >
                                        <FontAwesomeIcon
                                            icon={'eye'}
                                            color={LAVENDER}
                                            size='lg'
                                            className='eye'
                                        />
                                    </button>
                                </div>
                                <div className="nameOrg">{club.organization}</div>
                                {club.dependents && <div className="dependentsMessage">Encourage {club.dependents.join(',')} to participate</div>}
                                <div className="infoClubMembers">
                                    {createElements(4, club.members)}
                                    {club.members.length > 4 && (
                                        <div className="countClubMembers">
                                            {club.members.length > 4 && `+${club.members.length - 4}`}{' '}
                                        </div>
                                    )}
                                </div>
                                <div className="infoTabs">
                                    {club.tags.map((tag: any) => (
                                        <div>{tag.tag}</div>
                                    ))}
                                </div>
                                <div className="buttonsConnections">
                                    {followingClubs.findIndex((c: any) => c.club_id === club.club_id) !== -1 ? (
                                        <span className='buttonFollowing'>
                                            {club.club_type === 'TEAM' ? t('setupStudent__fan') : t('setupStudent__following')}
                                        </span>
                                    ) : joinedClubs.findIndex((c: any) => c.club_id === club.club_id) !== -1 ? (
                                        <span className='buttonFollowing'>
                                            {club.club_type === 'TEAM' ? t('setupStudent__tryoutRequested') : t('setupStudent__joinRequested')}
                                        </span>
                                    ) : (
                                        <>
                                            <button className="buttonFollow" type="button" onClick={() => followClub(club)}>
                                                {(loadingFollow && club === clickedClub) ? (
                                                    <div className="icon-spinner-third buttonSend-spinner" />
                                                ) : (
                                                    club.club_type === 'TEAM' ? t('setupStudent__fan') : t('setupStudent__follow')
                                                )}
                                            </button>
                                            {(!club.onlyFan && !club.dependents) && (
                                                <button className="buttonJoin" type="button" onClick={() => joinClub(club)}>
                                                    {(loadingJoin && club === clickedClub) ? (
                                                        <div className="icon-spinner-third buttonSendSignUp" />
                                                    ) : (
                                                        club.club_type === 'TEAM' ? t('setupStudent__tryout') : t('setupStudent__join')
                                                    )}
                                                </button>)
                                            }
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    ))}
                    <ModalClubSuggestions
                        show={showSetupClub}
                        onHide={() => handleCloseSetupClub()}
                        club={clickedClub}
                        tabActive={tabActive}
                        setTabActive={setTabActive}
                        typeClub={typeClub}
                    />
                </div>
            </div>

        </>
    );
}

export default Index;
