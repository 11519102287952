import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { LAVENDER } from '../ClubIconsNavBar'
import { Education } from './Education'
import './styles.scoped.css'
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from '../../helpers/backendHelper'
import { toast } from 'react-toastify'

const EducationInfo = ({ educationData, reloadInfo }: { educationData: Education, reloadInfo: any }) => {
  const { t, i18n } = useTranslation();
  const { username = "" } = useParams();

  const [orgName, setOrgName] = useState('')
  const [orgFrom, setOrgFrom] = useState('')
  const [orgTo, setOrgTo] = useState('')

  function handleOnChangeName(e: any) {
    setOrgName(e.target.value)
  }

  function handleOnChangeFrom(e: any) {
    setOrgFrom(e.target.value)
  }

  function handleOnChangeTo(e: any) {
    setOrgTo(e.target.value)
  }

  function saveOrg() {
    Backend(
      MicroServices.Roster,
      `/users/${username}/education`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "org_name": orgName,
          "from": orgFrom,
          "to": orgTo
        }),
      }
    )
      .then((response) => {
        if(response.status === 201){
          toast.success(t('toast_educationInfoUpdated'))
          reloadInfo(true)
        }
        else{
          toast.error(t('toast_somethingWentWrong'))
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  function deleteOrg(e: string) {
    Backend(
      MicroServices.Roster,
      `/users/${username}/account/education?account_id=${e}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
      }
    )
      .then((response) => { 
        if(response.status === 204){
          toast.success(t('toast_educationInfoDeleted'))
          reloadInfo(true)
        }
        else{
          toast.error(t('toast_somethingWentWrong'))
        }
      })
      .catch((error) => { console.log(error); })
  }

  return (
    <div>
      <div className='row row1'>
        <label className='titleInput' htmlFor="">{t('profile__organizationName')}</label>
        <div className="row row2 middle">
          <input type="text" defaultValue={educationData.org_name} onChange={(e) => handleOnChangeName(e)} />
          {educationData.org_name ?
            <FontAwesomeIcon
              icon="trash"
              size='lg'
              color={LAVENDER}
              className="IconTrash pointer"
              onClick={() => deleteOrg(educationData.id)}
            />
            :
            <FontAwesomeIcon
              icon="save"
              size='lg'
              color={LAVENDER}
              className="IconTrash pointer"
              onClick={() => saveOrg()}
            />
          }
        </div>
      </div>

      <div className='row row2'>
        <div className='col leftColumn'>
          <label className='titleInput' htmlFor="">{t('profile__from')}</label>
          <input type="text" defaultValue={educationData.from} onChange={(e) => handleOnChangeFrom(e)} />
        </div>
        <div className='col leftColumn'>
          <label className='titleInput' htmlFor="">{t('profile__to')}</label>
          <input type="text" defaultValue={educationData.to} onChange={(e) => handleOnChangeTo(e)} />
        </div>
      </div>
    </div>
  )
}

export default EducationInfo