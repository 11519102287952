import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { GET_TCLUBS_ERROR, GET_TCLUBS_SUCCESS, GET_TCOMM_ERROR, GET_TCOMM_SUCCESS, GET_TTEAM_ERROR, GET_TTEAM_SUCCESS, START_GET_TCLUBS, START_GET_TCOMM, START_GET_TTEAM } from "../constants/TClubsConstants";

export const getTClubsByCountry = (country: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({type: START_GET_TCLUBS});
    Backend(
        MicroServices.Club,
        `/tclubs/by-country?country=${country}&type=CLUB`
    )
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_TCLUBS_SUCCESS,
          payload: results,
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_TCLUBS_ERROR,
          payload: error,
        });
      })
  });

export const getTCommsByCountry = (country: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({type: START_GET_TCOMM});
    Backend(
        MicroServices.Club,
        `/tclubs/by-country?country=${country}&type=COMM`
    )
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_TCOMM_SUCCESS,
          payload: results,
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_TCOMM_ERROR,
          payload: error,
        });
      })
  });

export const getTTeamsByCountry = (country: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({type: START_GET_TTEAM});
    Backend(
        MicroServices.Club,
        `/tclubs/by-country?country=${country}&type=TEAM`
    )
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_TTEAM_SUCCESS,
          payload: results,
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_TTEAM_ERROR,
          payload: error,
        });
      })
  });
