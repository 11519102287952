/* eslint-disable no-nested-ternary */
import "./styles.scoped.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Welcome from "../../components/SetupStudent/Welcome";
import UploadPhoto from "../../components/SetupStudent/UploadPhoto";
import Interests from "../../components/SetupStudent/Interests";
import JoinClubs from "../../components/SetupStudent/JoinClubs";
import Connections from "../../components/SetupStudent/Connections";
import Review from "../../components/SetupStudent/Review";
import Aha from "../../components/SetupStudent/Aha";
import {
    createEmailRequest,
    getClubSuggestions,
    getInterestSuggested,
    getUnregSug,
    getUserFriends,
    getUserOrgSuggestion,
    getUserPendingRequests,
    SaveInterests,
} from "../../store/actions/connectionActions";
import { getUser } from "../../store/actions/userActions";
import UnregisteredUser from "../../interfaces/user/UnregisteredUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER, WHITE } from "../../components/ClubIconsNavBar";
import { getAhaValueSetup } from "../../store/actions/ahaActions";
import { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { getOrganizationInfo } from "../../store/actions/orgsActions";
import { ContainerSetupStudent, WrapperSetupStudent } from "./components";

export interface Interest {
    id: number;
    title: string;
    active: boolean;
}

export interface InterestInfo {
    INTLists: any[];
    id: string;
    name: string;
}

function SetupStudent(props: any) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [stepActive, setStepActive] = useState(0);
    const userInfo = useSelector((state: any) => state.getUser.userInfo);
    const user = useSelector((state: any) => state.getUser.user);
    const ahaValueSetup = useSelector(
        (state: RootState) => state.getAha.GetAhaSetup || "10.00"
    );
    const [loaderFinish, setLoaderFinish] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    const [myInterests, setMyInterests] = useState<Interest[]>([]);
    const [followingClubs, setFollowingClubs] = useState<any[]>([]);
    const [joinedClubs, setJoinedClubs] = useState<any[]>([]);
    const [friendRequest, setFriendRequest] = useState<any[]>([]);
    const [unregRequests, setUnregRequests] = useState<UnregisteredUser[]>([]);

    const suggestedInterests = useSelector(
        (state: any) => state.getConnection.GetISug
    );
    const organization = useSelector(
        (state: any) => state.getOrgs.organizationInfo
    );

    const navigate = useNavigate();

    function handleOnClickI(list: any) {
        setMyInterests(list);
    }
    useEffect(() => {
        handleOnClick("", 0);
        i18n.changeLanguage(getLanguage(user.preferredLanguage));
    }, []);

    useEffect(() => console.log(followingClubs), [followingClubs]);
    useEffect(() => console.log(joinedClubs), [joinedClubs]);

    const [textAha, setTextAha] = useState<any>(t("setupStudent__earnAha"));
    const [subtextAha, setSubtextAha] = useState<any>(
        t("setupStudent__nowThatsCool")
    );

    function changeText(e: any) {
        setTextAha(e);
        setSubtextAha("");
    }

    function getLanguage(e: string) {
        switch (e) {
            case "spanish":
                return "es";
                break;
            case "english":
                return "en";
                break;
            default:
                return "";
                break;
        }
    }

    function handleOnClick(e: any, step: any) {
        if (step !== 6) {
            setStepActive(step);
        }
        if (step === 0) {
            setTextAha(t("setupStudent__earnAha"));
            setSubtextAha(t("setupStudent__nowThatsCool"));
        } else if (step === 1) {
            setTextAha(
                `${t("setupStudent__earn")} ${parseFloat(ahaValueSetup)} ${t(
                    "setupStudent__ahaDigital"
                )}`
            );
            setSubtextAha("");
        } else if (step === 2) {
            setTextAha(t("setupStudent__youCanExplore"));
            setSubtextAha("");
        } else if (step === 3) {
            setTextAha(t("setupStudent__exploreTheClubs"));
            setSubtextAha("");
        } else if (step === 4) {
            setTextAha(t("setupStudent__connectWith"));
            setSubtextAha("");
        } else if (step === 5) {
            setTextAha(t("setupStudent__aha"));
            setSubtextAha("");
        }
    }

    useEffect(() => {
        if (
            organization &&
            "countryCode" in organization &&
            organization.countryCode !== ""
        ) {
            dispatch(getInterestSuggested(organization.countryCode));
        }

        dispatch(getUser(userInfo.username));
        dispatch(getAhaValueSetup());
    }, []);

    useEffect(() => {
        if (user && "organizations" in user && user.organizations.length > 0) {
            console.log("USER", user);

            if (!organization) {
                dispatch<any>(getOrganizationInfo(user.organizations[0])); //@todo more orgs
            }

            dispatch(
                getClubSuggestions(
                    user.organizations[0],
                    user.grade,
                    user.gender,
                    user.roles[0].split("#")[1],
                    user.username
                ) //TODO -> When user has more orgs and roles
            );
            dispatch(
                getUserOrgSuggestion(
                    user.organizations[0],
                    user.roles[0] !== "ZAA#STUDENT"
                )
            ); //TODO -> When is alumni or guardian
            if (user.clubs.length >= 1) {
                dispatch(getUnregSug(user.organizations[0], user.roles[0]));
            }
            dispatch(getUserPendingRequests(userInfo.username));
            dispatch<any>(getUserFriends(userInfo.username));
        }
    }, [user]);

    useEffect(() => {
        if (suggestedInterests.length > 0) {
            const array = suggestedInterests.map((item: InterestInfo) => ({
                id: item.id,
                title: item.name,
                active: false,
            }));
            setMyInterests(array);
        }
    }, [suggestedInterests]);

    const handleFinish = () => {
        setLoaderFinish(true);
        setDisableButton(true);
        dispatch(
            SaveInterests(
                userInfo.username,
                myInterests
                    .filter((i: any) => i.active)
                    .map((i: any) => i.title),
                user.organizations[0]
            )
        );

        friendRequest.map((friend: any) =>
            Backend(
                MicroServices.Connections,
                `/requests?usernameFrom=${userInfo.username}&usernameTo=${
                    friend.username
                }`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
        );

        followingClubs.map((club: any) =>
            Backend(
                MicroServices.Club,
                `/clubs/${club.club_id}/follower?username=${userInfo.username}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
        );

        joinedClubs.map((club: any) =>
            Backend(
                MicroServices.Club,
                `/clubs/${club.club_id}/join?username=${userInfo.username}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
        );

        unregRequests.map((req) =>
            dispatch(createEmailRequest(userInfo.username, req.email))
        );

        Backend(
            MicroServices.AccessManager,
            `/users/${userInfo.username}/setup-complete-user`,
            {
                method: "POST",
            }
        ).then((results) => {
            if (results.status === 200) {
                /*dispatch<any>(getUser(userInfo.username))
          .then(() => {
            setLoaderFinish(false);
            setDisableButton(false);
            props.setShowSetupUser(false);
            navigate("/app");
          });*/
                setLoaderFinish(false);
                setDisableButton(false);
                props.setShowSetupUser(false);
                navigate("/app");
            }
        });
    };

  return (
    <div className="container-setup-student">
      <div className="containerSetupStudent">
        <div className="containerColumns">
          <div className="setupStudent">
            <div className="beginSetup">
              {stepActive === 0 ? (
                <Welcome />
              ) : stepActive === 1 ? (
                <UploadPhoto />
              ) : stepActive === 2 ? (
                <Interests
                  handleOnClickI={handleOnClickI}
                  list={myInterests}
                  canGoNext={
                    myInterests.filter((i: any) => i.active).length >= 5
                  }
                />
              ) : stepActive === 3 ? (
                <JoinClubs
                  followingClubs={followingClubs}
                  setFollowingClubs={setFollowingClubs}
                  joinedClubs={joinedClubs}
                  setJoinedClubs={setJoinedClubs}
                  showNewText={changeText}
                />
              ) : stepActive === 4 ? (
                <Connections
                  friendRequest={friendRequest}
                  setFriendRequest={setFriendRequest}
                  unregRequests={unregRequests}
                  setUnregRequests={setUnregRequests}
                />
              ) : (
                <Review
                  myInterests={myInterests
                    .filter((i: any) => i.active)
                    .map((i: any) => i.title)}
                  followingClubs={followingClubs}
                  joinedClubs={joinedClubs}
                  friendRequest={friendRequest}
                  unregRequests={unregRequests}
                  handleOnClick={(e: any, step: number) =>
                    handleOnClick(e, step)
                  }
                />
              )}
            </div>
            <div className="steps">
              <div className="dummy-button"/>
              <div className="studentSetup__containersButton">
                {stepActive !== 0 && (
                  <button
                    className="buttonPrev"
                    onClick={(e) => handleOnClick(e, stepActive - 1)}
                    type="button"
                  >
                    <FontAwesomeIcon
                      icon="chevron-left"
                      size="xl"
                      color={LAVENDER}
                      className="chevron  "
                    />
                    {t('setupStudent__previousStep')}
                  </button>
                )}
              </div>
              <div className="nagSteps">
                <button
                  className={`${stepActive === 1 ? "active" : ""} ${
                    stepActive > 1 ? "activeStep" : ""
                  }`}
                  type="button"
                  aria-label="Step 1"
                  onClick={(e) => handleOnClick(e, 1)}
                />
                <button
                  className={`${stepActive === 2 ? "active" : ""} ${
                    stepActive > 2 ? "activeStep" : ""
                  }`}
                  onClick={(e) => handleOnClick(e, 2)}
                  type="button"
                  aria-label="Step 2"
                />
                <button
                  className={`${stepActive === 3 ? "active" : ""} ${
                    stepActive > 3 ? "activeStep" : ""
                  }`}
                  onClick={(e) => handleOnClick(e, 3)}
                  type="button"
                  aria-label="Step 3"
                />
                <button
                  className={`${stepActive === 4 ? "active" : ""} ${
                    stepActive > 4 ? "activeStep" : ""
                  }`}
                  type="button"
                  aria-label="Step 4"
                  onClick={(e) => handleOnClick(e, 4)}
                />
              </div>
              <div className="dummy-button"/>
              <div className="studentSetup-containersButton">
                {(stepActive !== 2 ||
                  myInterests.filter((i: any) => i.active).length >= 5) && (
                  <button
                    className="buttonNext"
                    onClick={(e) =>
                      stepActive !== 5
                        ? handleOnClick(e, stepActive + 1)
                        : handleFinish()
                    }
                    type="button"
                    disabled={disableButton}
                  >
                    {loaderFinish ? (
                      <div className="icon-spinner-third buttonSendSignUp" />
                    ) : stepActive < 5 ? (
                      t('setupStudent__nextStep')
                    ) : (
                      t('setupStudent__finishSetup')
                    )}
                    <FontAwesomeIcon
                      icon="chevron-right"
                      size="xl"
                      color={WHITE}
                      className="chevron  "
                    />
                  </button>
                )}
              </div>
            </div>
            <div className="studentSetup__containersButton--mobile">
              <div className="studentSetup__containersButton--col">
                {stepActive !== 0 && (
                  <button
                    className="buttonPrev"
                    style={{margin: 0, width: '100%'}}
                    onClick={(e) => handleOnClick(e, stepActive - 1)}
                    type="button"
                  >
                    <FontAwesomeIcon
                      icon="chevron-left"
                      size="xl"
                      color={LAVENDER}
                      className="chevron  "
                    />
                    {t('setupStudent__previousStep')}
                  </button>
                )}
              </div>
              <div className="studentSetup__containersButton--col">
                {(stepActive !== 2 ||
                  myInterests.filter((i: any) => i.active).length >= 5) && (
                  <button
                    className="buttonNext"
                    onClick={(e) =>
                      stepActive !== 5
                        ? handleOnClick(e, stepActive + 1)
                        : handleFinish()
                    }
                    style={{margin: 0, width: '100%'}}
                    type="button"
                    disabled={disableButton}
                  >
                    {loaderFinish ? (
                      <div className="icon-spinner-third buttonSendSignUp" />
                    ) : stepActive < 5 ? (
                      t('setupStudent__nextStep')
                    ) : (
                      t('setupStudent__finishSetup')
                    )}
                    <FontAwesomeIcon
                      icon="chevron-right"
                      size="xl"
                      color={WHITE}
                      className="chevron  "
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="containerInfoAha">
            <Aha text={textAha} subtext={subtextAha} step={stepActive}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetupStudent;
