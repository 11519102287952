import "../../containers/SetupSchool/styles.scoped.css";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InfoMembers from "./Tabs/InfoMembers";
import Description from "./Tabs/Description";
import Meeting from "./Tabs/Meeting";
import { ClubCreate } from "./Clubs";
import ModalAddAdvisor from "./ModalAddAdvisor";
import OrganizationComplete from "../../interfaces/organization/OrganizationComplete";
import { useDispatch } from "react-redux";
import { getTimezones } from "../../store/actions/locationActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalDiscardChanges from "./ModalDiscardChanges";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";

toast.configure();
interface ModalClubProps {
  staffUsers?: any[];
  updateStaffUsers?: () => void;
  updateCommInvites?: () => void;
  onHide: (club?: ClubCreate) => void;
  organization?: OrganizationComplete;
  title: string;
  show: boolean;
  clubToEdit: any | undefined;
  modalType: "Create" | "Edit" | "SaveEdit";
}

function Index({
  staffUsers,
  updateStaffUsers,
  updateCommInvites,
  onHide,
  organization,
  title,
  show,
  clubToEdit,
  modalType,
}: ModalClubProps) {
  const { t, i18n } = useTranslation();
  const [showAdvisor, setShowAdvisor] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showError, setShowError] = useState(false);
  const [addedAdvisors, setAddedAdvisors] = useState<string[]>([]);
  const [updatingTitle, setUpdatingTitle] = useState(false);
  const [showDiscard, setShowDiscard] = useState(false);

  function handleOnClickSendMembers(e: any) {
    const ok = checkMinimunData();
    if (ok) {
      onHide({
        ...club,
        template_id: title,
      });
      setShowError(false);
      setTabActive(0);
    }
  }

  const checkMinimunData = () => {
    if (club.member_type.length === 0) {
      toast.error(t('toast_pleaseSelectMembers'));
      return false;
    }

    if (club.gendersAllowed.length === 0) {
      toast.error(t('toast_pleaseSelectGenders'));
      return false;
    }

    if (
      club.member_type.indexOf("STUDENT") !== -1 &&
      club.gradesAllowed.length === 0
    ) {
      toast.error(t('toast_pleaseSelectGrades'));
      return false;
    }

    if (club.advisors && club.advisors.length === 0) {
      setErrorMsg(t('toast_theClubMustHave'));
      toast.error(t('toast_pleaseSelectAddAdv'));
      setShowError(true);
      return false;
    }

    if (club.name?.trim().length === 0){
      toast.error(t('toast_teamNameEmpty'));
      return false;
    }

    return true;
  };

  const [tabActive, setTabActive] = useState<number>(0);

  const [club, setClub] = useState<any>({
    name: title,
    template_id: "",
    member_type: [],
    maximumNumberMembers: 0,
    gendersAllowed: [],
    gradesAllowed: [],
    advisors: [],
    description: "",
    created: false,
    skills: [],
    international: false,
    national: false,
    regional: false,
    local: false,
    applicationRequired: false,
    clubDuesRequired: false,
    meetingDays: [],
    meetingStart: "",
    meetingEnd: "",
    timezone: "",
    meetingFrequency: "",
    meetingLocation: "",
  });

  useEffect(() => {
    setClub(
      clubToEdit
        ? {
            ...clubToEdit,
            name: clubToEdit.name || "",
            template_id: clubToEdit.template_id || "",
            member_type: clubToEdit.member_type || [],
            maximumNumberMembers: clubToEdit.maximumNumberMembers || 0,
            gendersAllowed: clubToEdit.gendersAllowed || [],
            gradesAllowed: clubToEdit.gradesAllowed || [],
            advisors: clubToEdit.advisors || [],
            description: clubToEdit.description || "",
            created: false,
            skills: clubToEdit.skills || clubToEdit.skills_learned || [],
            international:
              clubToEdit.international ||
              clubToEdit.participateInternationalCompetitions ||
              false,
            national:
              clubToEdit.national ||
              clubToEdit.participateNationalCompetitions ||
              false,
            regional:
              clubToEdit.regional ||
              clubToEdit.participateRegionalCompetitions ||
              false,
            local:
              clubToEdit.local ||
              clubToEdit.participateLocalCompetitions ||
              false,
            applicationRequired: clubToEdit.applicationRequired || false,
            clubDuesRequired: clubToEdit.clubDuesRequired || false,
            meetingDays: clubToEdit.meetingDays || [],
            meetingStart:
              clubToEdit.meetingStart || clubToEdit.meetingStartTime || "",
            meetingEnd:
              clubToEdit.meetingEnd || clubToEdit.meetingEndTime || "",
            timezone: clubToEdit.timezone || "",
            meetingFrequency: clubToEdit.meetingFrequency || "",
            meetingLocation: clubToEdit.meetingLocation || "",
          }
        : {
            name: title,
            template_id: "",
            member_type: [],
            maximumNumberMembers: 0,
            gendersAllowed: [],
            gradesAllowed: [],
            advisors: [],
            description: "",
            created: false,
            skills: [],
            international: false,
            national: false,
            regional: false,
            local: false,
            applicationRequired: false,
            clubDuesRequired: false,
            meetingDays: [],
            meetingStart: "",
            meetingEnd: "",
            timezone: "",
            meetingFrequency: "",
            meetingLocation: "",
          }
    );
  }, [show]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (updateStaffUsers) updateStaffUsers();
    if (updateCommInvites) updateCommInvites();
    dispatch(getTimezones());
  }, []);

  function handleOnClick(e: any, tab: any) {
    const ok = checkMinimunData();
    if (ok) {
      setTabActive(tab);
    }
  }

  function handleCloseAdv(
    e: any,
    step: any,
    id: any,
    type: any,
    firstName?: string,
    lastName?: string,
    email?: string
  ) {
    if (step === "btnSave" && firstName && lastName && email) {
      setClub({
        ...club,
        advisors: [...(club.advisors || []), { firstName, lastName, email }],
      });
      setShowError(false);
    }
    setShowAdvisor(false);
  }

  function cancelModal() {
    setShowError(false);
    setTabActive(0);
    onHide();
  }

  function onHideModal() {
    setClub({
      name: "",
      template_id: "",
      member_type: [],
      maximumNumberMembers: 0,
      gendersAllowed: [],
      gradesAllowed: [],
      advisors: [],
      description: "",
      created: false,
      skills: [],
      international: false,
      national: false,
      regional: false,
      local: false,
      applicationRequired: false,
      clubDuesRequired: false,
      meetingDays: [],
      meetingStart: "",
      meetingEnd: "",
      timezone: "",
      meetingFrequency: "",
      meetingLocation: "",
    });
    setShowError(false);
    setTabActive(0);
    onHide();
    setUpdatingTitle(false);
  }

  const onHideDiscard = (type: string) => {
    setShowDiscard(false);
    if (type === "discard") {
      onHideModal();
    }
  };

  const nextTab = (e: any, tabActive: any) => {
    if (tabActive === 2) {
      handleOnClickSendMembers(e);
      return;
    } else {
      const ok = checkMinimunData();
      if (ok) {
        handleOnClick(e, tabActive + 1);
      }
      return;
    }
  };

  useEffect(() => {
    setAddedAdvisors(club.advisors.map((item: any) => item.email));
  }, [club]);

  return (
    <>
      <Modal className="modalCreateClub" show={show}>
        <Modal.Header>
          <Modal.Title className="ModalCreateClub__title">
            {updatingTitle ? (
              <input
                type="text"
                className="ModalCreateClub__titleInput"
                value={club.name}
                autoFocus
                onBlur={() => setUpdatingTitle(false)}
                onChange={(e) => setClub({ ...club, name: e.target.value })}
              />
            ) : (
              <div
                className="ModalCreateClub__headerWrapper"
                onClick={() => setUpdatingTitle(true)}
              >
                {club.name}
                <FontAwesomeIcon
                  icon="pen"
                  size="sm"
                  color={WHITE}
                  className="iconReactionLeft"
                />
              </div>
            )}
          </Modal.Title>
          <FontAwesomeIcon
            className="icon-times"
            icon="times"
            size="xl"
            color={WHITE}
            onClick={() => setShowDiscard(true)}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="nav">
            <div
              className={tabActive === 0 ? "active" : ""}
              onClick={(e) => handleOnClick(e, 0)}
            >
              Members
            </div>
            <div
              className={tabActive === 1 ? "active" : ""}
              onClick={(e) => handleOnClick(e, 1)}
            >
              Description
            </div>
            <div
              className={tabActive === 2 ? "active" : ""}
              onClick={(e) => handleOnClick(e, 2)}
            >
              Meeting
            </div>
          </div>
          {tabActive === 0 ? (
            <InfoMembers
              club={club}
              setClub={setClub}
              organization={organization}
              setShowAdvisor={setShowAdvisor}
            />
          ) : tabActive === 1 ? (
            <Description isTeam={false} club={club} setClub={setClub} />
          ) : tabActive === 2 ? (
            <Meeting club={club} setClub={setClub} />
          ) : (
            ""
          )}
          {showError && (
            <div className="row">
              <p className="roboto-normal-white-16px">{errorMsg}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="buttonClose" onClick={() => setShowDiscard(true)}>
            Cancel
          </Button>
          <Button
            name="btn-AddClub"
            className="buttonSend"
            onClick={(e: any) => nextTab(e, tabActive)}
          >
            {tabActive === 0
              ? "Next"
              : tabActive === 1
              ? "Next"
              : tabActive === 2
              ? "Save"
              : ""}
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalAddAdvisor
        show={showAdvisor}
        onHide={handleCloseAdv}
        staffUsers={staffUsers}
        updateCommInvites={updateCommInvites}
        clubName={title}
        addedAdvisors={addedAdvisors}
      />
      <ModalDiscardChanges show={showDiscard} onHide={onHideDiscard} />
    </>
  );
}

export default Index;
