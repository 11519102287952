import styled from "styled-components";
import { ClickableIcon } from "../../SuperAdminComponents/basicForSACenter";

export const TitleLeftPanel = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--lavender);
    text-overflow: ellipsis;
`;

export const ChatLeftWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding-left: 5%;
    height: 100%;
    overflow-y: auto;
    gap: 8px;
`;

export const SearchSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding-right: 15px;
    gap: 5px;
`;

export const ChatStatusWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    gap: 5px;
`;

export const ChatSearchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    background-color: var(--violet);
    border-radius: 5px;
    width: 100%;
    justify-items: space-between;
    align-items: center;
    padding: 5px;
`;

export const InputSearch = styled.input`
    box-sizing: border-box;
    width: 100%;
    height: 25px;
    background-color: transparent;
    padding-left: 10px;
    color: white;
    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-size: 16px;
    outline: none;
    border: 0px;

    ::placeholder {
        color: white;
        font-family: 'Roboto Condensed';
        font-weight: 400;
        font-size: 16px;
    }
`;

export const ChatsSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    overflow: auto; 
    overflow-x: hidden;

    :hover::-webkit-scrollbar{
        display: block;
    }

    ::-webkit-scrollbar {
        width: 5px;
        display: none;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--lavender); 
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: var(--violet); 
    }
`;

export const RowFlex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center;
    width: 100%;
    //padding-right: 20px;
`;

export const EllipsisSelector = styled(ClickableIcon)`
    width: 100px;
`;

interface NewChatIconProps {
    active: boolean;
}

export const NewChatIcon = styled.div`
    width: 50px;
    height: 35px;
    padding-top: 8px;
    text-align: center;
    background-color: ${(props: NewChatIconProps) => props.active ? "var(--lavender)" : "rgb(40,17,90)"};
    border-radius: 5px;
`;

interface CreateGroupProps {
    active: boolean;
}

export const CreateGroup = styled.div`
    width: 70px;
    height: 35px;
    padding-top: 8px;
    text-align: center;
    background-color: ${(props: CreateGroupProps) => props.active ? "var(--violet)" : "transparent"};
    border-radius: 5px 0px 0px 5px;
`;

interface ConnectionSelector {
    name: string;
    label: string;
    amount: number;
}
interface SelectorConnectionsProps {
    options: ConnectionSelector[];
    optionSelected: string;
    onClick: (value: any) => void;
}

export const SelectorConnections = ({
    options,
    optionSelected,
    onClick
}: SelectorConnectionsProps) => {
    return (
        <SelectorWrapper>
            {options.map((option: ConnectionSelector) => (
                <SelectorOptionWrapper
                    onClick={() => onClick(option.name)}
                >
                    <ConnectionLabel active={optionSelected === option.name}>
                        {option.label} {option.amount}
                    </ConnectionLabel>
                    <LineConnections active={optionSelected === option.name}/>
                </SelectorOptionWrapper>
            ))}
        </SelectorWrapper>
    )
}

const SelectorWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    color: #FFFFFF;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    padding:5px 15px 0px 0px;

    width: 100%;
`;

const SelectorOptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

interface LineConnectionsProps{
    active: boolean;
}

const LineConnections = styled.div`
    display: ${(props: LineConnectionsProps) => props.active ? "block": "none"};
    background-color: var(--lavender);
    width: 100%;
    height: 2px;
    margin-top: 15px;
`;

const ConnectionLabel = styled.div`
    color: ${(props: LineConnectionsProps) => props.active ? "var(--lavender)" : "var(--white)"};
    cursor: pointer;
`;


export const TextBold = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const TextLight = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const ColumnFlexNoChat = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;