import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../containers/SetupSchool/styles.scoped.css";
import { WHITE } from "../ClubIconsNavBar";

interface CategoriesProps {
    selectedCategory: string;
    setSelectedCategory: (i: string) => void;
    categories: string[];
}

const Categories = ({
    selectedCategory,
    setSelectedCategory,
    categories,
}: CategoriesProps) => (
    <ul>
        <li className={selectedCategory === 'All' ? "active" : ''} onClick={() => setSelectedCategory('All')}>
            All
            <FontAwesomeIcon
                icon={faChevronRight}
                size='xl'
                color={WHITE}
            />
        </li>
        {categories.map((key) => (
            <li onClick={() => setSelectedCategory(key)} className={selectedCategory === key ? "active" : ''}>
                {key}
                <FontAwesomeIcon
                    icon={faChevronRight}
                    size='xl'
                    color={WHITE}
                />
            </li>
        ))}
    </ul>
);

export default Categories;
