import { useEffect } from "react";
import "../../containers/SetupSchool/styles.scoped.css";
import { Team } from "./ModalTeam";
import { castTeamDistinguisherToID, castTeamIdToDistinguisher } from "./Teams";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from "../../components/ClubIconsNavBar";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

function Index(props: any) {

  function handleOnClickAddClub(e: any, title: any, team: Team) {
    if (
      props.teamsDelete.find(
        (item: string) =>
          item.split("#")[1] === team.template_id &&
          item.includes(castTeamIdToDistinguisher(team.id))
      )
    ) {
      props.handleOnClickClub(e, title, team, "ReCreate");
    } else {
      props.handleOnClickClub(e, title, team, "Create");
    }
  }

  function handleOnClickEditClub(e: any, title: any, team: Team) {
    props.handleOnClickClub(e, title, team, "Edit");
  }

  function handleOnClickSaveEditClub(e: any, title: any, team: Team) {
    props.handleOnClickClub(e, title, team, "SaveEdit");
  }

  function handleOnClickDeleteClub(e: any, title: any, team: Team) {
    e.stopPropagation();
    props.handleOnClickClub(e, title, team, "Delete");
  }

  function handleOnClickSaveDeleteClub(e: any, title: any, team: Team) {
    e.stopPropagation();
    props.handleOnClickClub(e, title, team, "SaveDelete");
  }

  const findSaved = (): boolean => {
    return props.dynamoClubs?.filter(
      (club: any) =>
        !props.teamsDelete.includes(`${club.id}#${club.template_id}#${club.team_distinguisher}`))
      .find(
        (club: any) =>
          club.template_id === props.team.template_id &&
          props.team.id.includes(castTeamDistinguisherToID(club.team_distinguisher)))
      !== undefined
  }

  const findCreated = (): boolean => {
    return props.teams.find(
      (club: any) =>
        club.template_id === props.team.template_id &&
        props.team.id.includes(
          castTeamDistinguisherToID(club.team_distinguisher)
        ))
      !== undefined
  }

  return (
    <div
      onClick={(e) => {
        if (findSaved()) {
          handleOnClickSaveEditClub(e, props.title, props.team);
        } else if (!findCreated()) {
          handleOnClickAddClub(e, props.title, props.team);
        } else {
          handleOnClickEditClub(e, props.title, props.team);
        }
      }}
      className={
        findSaved() ? "savedTemplate"
          : findCreated() ? "greyTemplate"
            : undefined
      }
    >
      {props.title}
      {findSaved() ? (
        <FontAwesomeIcon
          className='icon-times-2 '
          icon="times"
          size='xl'
          color={WHITE}
          onClick={(e) =>
            handleOnClickSaveDeleteClub(e, props.title, props.team)
          }
        />
      ) :
        findCreated() ? (
          <FontAwesomeIcon
            className='icon-times-2 '
            icon="times"
            size='xl'
            color={WHITE}
            onClick={(e) => handleOnClickDeleteClub(e, props.title, props.team)}
          />
        ) : (
          <FontAwesomeIcon
            icon="plus"
            size="xl"
            color={WHITE}
            className="icon-plus  "
          />
        )}
    </div>
  );
}

export default Index;
