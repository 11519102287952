import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { LAVENDER } from "../ClubIconsNavBar";
import "./styles.scoped.css";

const Index = () => {
  return (
    <tr>
      <td>
        <div>
          1
        </div>
      </td>
      <td>
        <div>
          School test
        </div>
      </td>
      <td>
        <div>
          not set
        </div>
      </td>
      <td>
        <div>
          not set
        </div>
      </td>
      <td>
        <div>
          not set
        </div>
      </td>
      <td>
        <div>
          not set
        </div>
      </td>
      <td>
        <div>
          not set
        </div>
      </td>
      <td>
        <div className=" actions">
          <FontAwesomeIcon
  icon="eye"
  size='lg'
  color={LAVENDER}
  className="view-user  "
  
/>
          <FontAwesomeIcon
  icon="pen"
  size='lg'
  color={LAVENDER}
  className="update-user  "
  
/>
          <FontAwesomeIcon
  icon="trash-alt"
  size='lg'
  color={LAVENDER}
  className="paper-plane  "
  
/>
        </div>
      </td>
    </tr>
  );
};

export default Index;
