import React, { useState } from "react";
import styled from "styled-components";
import {
  RobotocondensedRegularNormalWhite16,
  Fontawesome5proRegularNormalLavende
} from "../../styledMixins";
import "./styles.scoped.css";
import Select, { SingleValue } from 'react-select';
import {  useSelector } from "react-redux";
import PageNumber from "../PageNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import YoungOrganizationsTable from "../YoungOrganizationsTable";

const PAGE_SIZES: pageSize[] = [10, 50];
export type pageSize = 10 | 50 | 10000;

function YoungOrganizations() {

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<pageSize>(PAGE_SIZES[0]);

  const organizations = useSelector((state: any) => state.getOrgs.organizationNames);
  const handleSetPageSize = (newValue: SingleValue<{ label: string; value: pageSize; }>) => {
    setPageSize(newValue?.value || 10)
  }

  const customStyles = () => ({
    option: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#383838',
    }),
    input: (styles: any, state: any) => ({
      ...styles,
      border: 'none',
      color: 'white',
      fontWeight: "normal",
      paddingLeft: "5px",
      "&:focus": { backgroundColor: 'red' },
    }),
    container: (styles: any) => ({
      ...styles,
      padding: 0,
    }),
    valueContainer: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: 'transparent',
      padding: 0,
    }),
    menu: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      backgroundColor: "#383838",
      fontFamily: "var(--font-family-roboto_condensed)",
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      textAlign: "left",
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "118px",
      borderRadius: "0px",
      boxShadow: "none",
      border: 0,
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "bold",
      maxWidth: "200px",
      marginBottom: "5px",
      backgroundColor: 'transparent'
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: 'white',
      textDecoration: "underline",
      display: 'block',
    }),
    indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
    dropdownIndicator: (styles: any) => ({ ...styles, display: "none" }),
    noOptionsMessage: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: 'grey',
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "normal",
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: 'transparent',
      border: "none",
      paddingLeft: "5px",
      paddingRight: "5px"
    }),
  })

  const viewingOptions = PAGE_SIZES.map((item) => ({
    label: `${item} results`,
    value: item,
  }))

  const canGoBack = () => page !== 0;
  const canGoNext = () => true;

  const goBack = () => {
    setPage(page - 1);
  };

  const goNext = () => {
    setPage(page + 1);
  };

  return (
    <>
      <div className="contentYoungOrganization">
        <div className="headerYoung">
          <div className="titleYoung">
            <h1>Youth Organization</h1>
            <Showing112Of864Items>
              <span className="robotocondensed-normal-white-16px">
                Showing{' '}
              </span>
              <span className="robotocondensed-bold-white-16px">
                {(pageSize * page) + 1}-{pageSize * (page + 1)}{' '}
              </span>
              <span className="robotocondensed-normal-white-16px">
                of{' '}
              </span>
              <span className="robotocondensed-bold-white-16px">
                {organizations.length}{' '}
              </span>
              <span className="robotocondensed-normal-white-16px">
                youth organizations
              </span>
            </Showing112Of864Items>
          </div>
          <div className="viewingYoung">
            <Viewing1>Viewing</Viewing1>
            <Box>
              <Select
                value={{ value: pageSize, label: `${pageSize} results` }}
                isMulti={false}
                options={viewingOptions}
                placeholder={"View"}
                styles={customStyles()}
                onChange={handleSetPageSize}
              />
              <ChevronDown />
            </Box>
          </div>
        </div>
        <YoungOrganizationsTable pageSize={pageSize} page={page} />
        <PageNumber
          page={page}
          canGoBack={canGoBack()}
          canGoNext={canGoNext()}
          goBack={goBack}
          goNext={goNext}
        />
      </div>
    </>
  );
}

const Showing112Of864Items = styled.p`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  align-self: flex-end;
  margin-top: 30px;
  letter-spacing: 0;
`;

const Viewing1 = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  min-width: 50px;
  letter-spacing: 0;
  font-family: var(--font-family-roboto_condensed);
`;

const Box = styled.div`
  height: 40px;
  margin-left: 17px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  min-width: 130px;
  background-color: var(--violent-violet);
`;

const ChevronDownWrapper = styled.div`
  ${Fontawesome5proRegularNormalLavende}
  min-height: 20px;
  margin-left: 27px;
  min-width: 18px;
  letter-spacing: 0;
`;

const ChevronDown = () => <ChevronDownWrapper>
  <FontAwesomeIcon icon="chevron-down" color="#FFFFFF" size="sm" />
</ChevronDownWrapper>

export default YoungOrganizations;
