import "./styles.css";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteInviteAdmin, resentInviteAdmin } from "../../store/actions/inviteActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GRAY, LAVENDER } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";

function Index(props: any) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [listInvites, setListInvites] = useState([])
    const [listChange, setListChange] = useState(false);
    const [valueFilter, setValueFilter] = useState("");

    useEffect(() => {
        if (props.infoClub && props.infoClub.org_id && props.infoClub.id && props.infoClub.status) {
            Backend(
                MicroServices.Invite,
                `/invites?pending=${(props.infoClub.status === 'PENDING') ? true : false}&club_id=${props.infoClub.id}&org_id=${props.infoClub.org_id}&light=true`)
                .then((response) => response.json())
                .then((results) => {
                    const openInvites = results.filter((invite: any) => invite.status !== 'USED' && invite.status !== 'NEW')
                    setListInvites(openInvites);
                })
        }

        if(props.invitesAdded){
            props.setInvitesAdded(false);
        }
        
    }, [props.infoClub, listChange, props.invitesAdded])

    const deleteOpenInvite = (invite: any) => {
        dispatch<any>(deleteInviteAdmin(formatId(invite)))
            .then(() => setListChange(!listChange));
    }

    const formatId = (invite: any) => {
        return (invite.role === "ZAA#SECOND#ADVISOR" || invite.role === "ZAA#STAFF#ADVISOR") ?
            `Advisor#${invite.club_id}#${invite.email}` : `End_User#${invite.club_id}#${invite.email}`;
    }

    const handleChangeName = (e: any) => {
        setValueFilter(e);
    }

    const resentInvite = (invite:any) => {
        dispatch<any>(resentInviteAdmin(formatId(invite)));
    };

    return (
        <div className="content-clubInvites">
            <div className="containerInputSearch">
                <label className="labelListInvites">
                    {listInvites.length} {t('infoInvites__title')}
                </label>
                <div className="inputSearchM">
                    <FontAwesomeIcon
                        icon="search"
                        size='lg'
                        color={LAVENDER}
                        className="search  "
                    />
                    <input type="text" className="inputSearchM" onChange={(e) => handleChangeName(e.target.value)} />
                </div>
            </div>

            <div className="listInvitesClub">
                {listInvites.filter((item: any) => {
                    if (!valueFilter) return true;
                    if (item.firstName.toUpperCase().includes(valueFilter) ||
                        item.firstName.toLowerCase().includes(valueFilter) ||
                        item.lastName.toUpperCase().includes(valueFilter) ||
                        item.lastName.toLowerCase().includes(valueFilter) ||
                        item.firstName.includes(valueFilter) ||
                        item.lastName.includes(valueFilter)
                    ) {
                        return true;
                    }
                    return false;
                }).map((invite: any) => (
                    <div className="cardInvite" key={invite.id} >
                        <div className="nameInvite">
                            <img src={invite.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" />
                            <p>{invite.firstName} {invite.lastName}</p>
                        </div>
                        <div className="isOpen">
                            <p>{t('label_nonRegisteredUser')}</p>
                            <span>{t('label_openInvite')}{(invite.open_invites) && ('s # ' + invite.open_invites)}</span>
                        </div>
                        <div className="openInviteActions">
                            <button onClick={() => resentInvite(invite)}
                                    disabled={invite.status === 'USED' || invite.status === 'NEW'}
                                    className="wrapButton-disabled-paperPlane"
                            >
                                <FontAwesomeIcon
                                    icon="paper-plane"
                                    size='lg'
                                    color={(invite.status === 'USED' || invite.status === 'NEW') ? 
                                                (GRAY): (LAVENDER)}
                                    className={`paper-plane-invite-club ${(invite.status !== 'USED' && invite.status !== 'NEW') && 
                                    ("pointer")}`}
                                />
                            </button>
                            
                            <FontAwesomeIcon
                                icon="trash"
                                size='lg'
                                color={LAVENDER}
                                className="trash  pointer"
                                onClick={() => deleteOpenInvite(invite)}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Index;