import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { capitalize } from '../../helpers/letterHelper';
import { UserResponsePII } from '../../interfaces/user/UserResponse';
import { getTimezones } from '../../store/actions/locationActions';
import { RootState } from '../../store/store';
import ImageProfile from './ImageProfile'
import './styles.scoped.css'
import { useTranslation } from "react-i18next";
import { editUser } from "../../store/actions/userActions";
import { Backend, MicroServices } from '../../helpers/backendHelper';

const Preferences = ({ user }: { user: UserResponsePII }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [timezone, setTimezone] = useState("America#Bogota#-05:00");
  const [language, setLanguage] = useState(user.preferredLanguage);
  const userInfo = useSelector((state: any) => state.getUser.userInfo);

  const timezones = useSelector((state: RootState) => state.getLocation.timezones || [{
    continent: "America",
    city: 'Bogota',
    country: 'CO',
    DST: '-05:00',
    STD: '-05:00'
  }]);

  useEffect(() => {
    dispatch(getTimezones())
  }, [])

  useEffect(() => {
    setTimezone(getCurrentTimezone());
  }, [timezones])

  const getCurrentTimezone = (): string => {
    const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const continent = zone.split("/")[0];
    const city = zone.split("/")[1];
    const tz = timezones.find(
      (item: any) => item.continent === continent && item.city === city
    );
    if (tz) return `(UTC ${tz.STD}) ${tz.continent}/${tz.city}`;
    else return "(UTC -05:00) America/Bogota";
  };

  const handleLanguage = (e: any) => {
    setLanguage(e.target.value)
  };

  const handleSave = () => {
    Backend(
      MicroServices.Admin,
      `/users/${userInfo.username}?roleRequest=AO`,
      {
        method: 'PUT',
        body: JSON.stringify({
          preferredLanguage: language
        }),
      },
    )
      .then((response) => response.json())
      .then((results) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error)
      })
  };

  return (
    <div className='containerMyAccount'>
      {/*<ImageProfile />*/}
      <div className='row row2'>

        <div className='col'>
          <label className='titleInput' htmlFor="">{t('profile__localTimezone')}</label>
          <span>{timezone}</span>
        </div>

        <div className='col'>
          <label className='titleInput' htmlFor="">{t('profile__preferredLanguage')}</label>
          <select className="roboto-normal-white-16px selectMediaType" onChange={(e) => handleLanguage(e)}>
            <option value="english"
              selected={user.preferredLanguage === 'english'}
            >
              English
            </option>
            <option value="spanish"
              selected={user.preferredLanguage === 'spanish'}
            >
              Spanish
            </option>
          </select>
        </div>
      </div>
      <div className='row row2'>
        <div className='col'>
          <button className='btnCancel'>{t('button__cancel')}</button>
        </div>
        <div className='col'>
          <button className='btnSave' onClick={handleSave}>{t('profile__updateLanguage')}</button>
        </div>
      </div>
      {/*<div className='row row2'>
        <div className='col'>
          <label className='titleInput' htmlFor="">{t('profile__timeFormat')}</label>
          <input type="text" />
        </div>
        <div className='col'>
          <label className='titleInput' htmlFor="">{t('profile__preferredLanguage')}</label>
          <select className="roboto-normal-white-16px selectMediaType">
              <option value="english" 
                      selected={user.preferredLanguage === 'english'}
              >
                English
              </option>
              <option value="spanish"
                      selected={user.preferredLanguage === 'spanish'}
              >
                Spanish
              </option>
          </select>
        </div>
      </div>
      <div className='row row2'>
        <div className='col'>
          <button className='btnCancel'>{t('button__cancel')}</button>
        </div>
        <div className='col'>
          <button className='btnSave'>{t('button__save')}</button>
        </div>
      </div>*/}

    </div>
  )
}

export default Preferences