import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOrganizationInfo } from "../../store/actions/orgsActions";
import { getUser } from "../../store/actions/userActions";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import './styles.scoped.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../ClubIconsNavBar";
import useGrades from "../../hooks/useGrades";
import useActiveCountry from "../../hooks/useActiveCountry";

const Index = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { activeCountriesReactSelect } = useActiveCountry();

    const userInfo = useSelector((state: any) => state.getUser.userInfo);
    const org = useSelector((state: any) => state.getOrgs.organizationInfo);

    useEffect(() => {
        if (org && org?.countryCode) {
            setCountry(org?.countryCode);
        }
    }, [org]);

    const { grades, setCountry } = useGrades();

    const navigate = useNavigate();

    const ages = [
        { value: 13, label: '13' },
        { value: 14, label: '14' },
        { value: 15, label: '15' },
        { value: 16, label: '16' },
        { value: 17, label: '17' },
        { value: 18, label: '18' },
        { value: 19, label: '19' },
        { value: 20, label: '20' },
        { value: 21, label: '21' },
        { value: 22, label: '22' },
    ]

    useEffect(() => {
        if (userInfo.username) {
            dispatch<any>(getUser(userInfo.username))
                .then((results: any) => {
                    dispatch<any>(getOrganizationInfo(results?.organizations[0]))
                })
        }
    }, []);

    return (
        <div className="content">
            <div className="org-view-cont">
                <div className="title-column">
                    <h1 className="title-name">{(org.type !== 'YOUTHORG') ? t('myschool__title') : 'My Organization'}</h1>
                    <div className="buttons-box">
                        <div className={`button-p`} onClick={() => navigate('./update')}>
                            <div className="save roboto-normal-white-16px">{t('button__update')}</div>
                        </div>
                    </div>
                </div>
                <div className="view-columns">
                    <div className="col-1">
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{(org.type !== 'YOUTHORG') ? t('myschool__schoolName') : 'Organization Name'}</div>
                            <div className="info-field">
                                <div className="roboto-normal-white-16px ellipses">{org.name}</div>
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{(org.type !== 'YOUTHORG') ? t('myschool__schoolWebsite') : 'Organization Website'}</div>
                            <div className="info-field" >
                                <div className="roboto-normal-white-16px ellipses">{org?.website || ''}</div>
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__Principal')}</div>
                            <div className="columns-inputs">
                                <p className="text-ext robotocondensed-bold-lavender-16px">{t('myschool__firstName')}</p>
                                <div className="info-field" >
                                    <div className="roboto-normal-white-16px ellipses">{org?.orgAdmin?.firstname || ''}</div>
                                </div>
                                <p className="text-ext text-center robotocondensed-bold-lavender-16px">{t('myschool__lastName')}</p>
                                <div className="info-field" >
                                    <div className="roboto-normal-white-16px ellipses">{org?.orgAdmin?.lastname || ''}</div>
                                </div>
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__PrincipalPhone')}</div>
                            <div className="info-field" >
                                <div className="roboto-normal-white-16px ellipses">{org?.orgAdmin?.phoneNumber || ''}</div>
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__Grades')}</div>
                            <div className="columns-inputs">
                                <p className="text-ext robotocondensed-bold-lavender-16px">{t('myschool__low')}</p>
                                <div className="info-field" >
                                    <div className="roboto-normal-white-16px ellipses">
                                        {grades.find((grade) => grade.id === org?.lowGrade)?.listLabel || org?.lowGrade}
                                    </div>
                                </div>
                                <p className="text-ext text-center robotocondensed-bold-lavender-16px">High</p>
                                <div className="info-field" >
                                    <div className="roboto-normal-white-16px ellipses">
                                      {grades.find((grade) => grade.id === org?.highGrade)?.listLabel || org?.highGrade}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*(org.type === 'YOUTHORG') && (<div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">Ages</div>
                            <div className="columns-inputs">
                                <p className="text-ext robotocondensed-bold-lavender-16px">Low</p>
                                <div className="info-field" >
                                    <div className="roboto-normal-white-16px ellipses">
                                        {ages.map((grade) => (
                                            grade.value === org?.lowAge &&
                                            grade.label
                                        ))}
                                    </div>
                                </div>
                                <p className="text-ext text-center robotocondensed-bold-lavender-16px">{t('myschool__high')}</p>
                                <div className="info-field" >
                                    <div className="roboto-normal-white-16px ellipses">
                                        {ages.map((grade) => (
                                            grade.value === org?.highAge &&
                                            grade.label
                                        ))}
                                    </div>
                                </div>
                            </div>
                                        </div>)*/}

                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__createdBy')}</div>
                            <div className="info-field">
                                <div className="roboto-normal-white-16px">{`${org?.orgAdmin?.firstname} ${org?.orgAdmin?.lastname} `}</div>
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__country')}</div>
                            <div className="info-field" >
                                <div className="roboto-normal-white-16px ellipses">{(activeCountriesReactSelect.find((country) => country.value === org?.countryCode)?.label) || ''}</div>
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__city')}</div>
                            <div className="info-field" >
                                <div className="roboto-normal-white-16px ellipses">{org?.city || ''}</div>
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__state')}</div>
                            <div className="info-field">
                                <div className="roboto-normal-white-16px">{org?.state || ''}</div>
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__address')}</div>
                            <div className="info-field">
                                <div className="roboto-normal-white-16px">{org?.address || ''}</div>
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__postalCode')}</div>
                            <div className="info-field">
                                <div className="roboto-normal-white-16px">{org?.zipcode || ''}</div>
                            </div>
                        </div>

                        {(org.type !== 'YOUTHORG') && (<>
                            <div className="info-field-row">
                                <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__yearBook')}</div>
                                <div className="info-field">
                                    <div className="roboto-normal-white-16px">{org?.yearbook || ''}</div>
                                </div>
                            </div>
                            {/*<div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">Newspaper</div>
                            <div className="info-field">
                                <div className="roboto-normal-white-16px">{org?.newspaper || ''}</div>
                            </div>
                        </div>*/}
                        </>)}

                    </div>
                    <div className="col-2">
                        <div className="info-field-row">
                            <div className="label-cont labelLogo robotocondensed-bold-lavender-16px">{t('myschool__Logo')}</div>
                            <div className={`containerLogo`}>
                                <div className={`divLogo`}>
                                    {org?.logo ? (
                                        <img src={org?.logo} width="150px" />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon="camera"
                                            size="xl"
                                            color={WHITE}
                                            className="icon-camera  "
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <h1 className="title-col-2">{t('myschool__Information')}</h1>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__tagLine')}</div>
                            <div className="info-field">
                                <div className="roboto-normal-white-16px"></div>
                            </div>
                        </div>



                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__districtOrSystem')}</div>
                            <div className="info-field" >
                                <div className="roboto-normal-white-16px"></div>
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px">{t('myschool__districtOrSystemWebsite')}</div>
                            <div className="info-field" >
                                <div className="roboto-normal-white-16px"></div>
                            </div>
                        </div>

                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px"> {t('myschool__primaryContact')}</div>
                            <div className="info-field">
                                <div className="roboto-normal-white-16px ellipses">{org?.primaryContact?.name}</div>
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px"> {t('myschool__primaryContactPhone')}</div>
                            <div className="info-field">
                                <div className="roboto-normal-white-16px ellipses">{org?.primaryContact?.phoneNumber}</div>
                            </div>
                        </div>
                        <div className="info-field-row">
                            <div className="label-cont robotocondensed-bold-lavender-16px"> {t('myschool__primaryContactEmail')}</div>
                            <div className="info-field">
                                <div className="roboto-normal-white-16px ellipses">{org?.primaryContact?.email}</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
};

export default Index;
