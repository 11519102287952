import "../styles.scoped.css";
import '../styles.css';
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Advisor, CommunityItem, ORDINAL } from "../../SetupSetup/Communities";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import CardCommunity from "./CardCommunity";
import { getOrgCommSetup, getOrgCommunities } from "../../../store/actions/clubActions";
import ClubInterface from "../../../interfaces/ClubExpanded";
import { getStaffOrg } from "../../../helpers/setupHelper";
import { Backend, MicroServices } from "../../../helpers/backendHelper";
import { CommunityYouthItem } from "./CommunitiesOwnYouth";
import OrganizationComplete from "../../../interfaces/organization/OrganizationComplete";
import AddCardCommunity from "./AddCardCommunity";
import ModalAddCommunity from "./modals/ModalAddCommunity";

export const YO_OWN_COMMUNITY_TYPE: string[] = [
    'Staff',
    'Advisors',
    'Board',
    'Students',
]

function CardCommunitiesYouth({ organization }: { organization: OrganizationComplete }) {

    const [activeCards, setActiveCards] = useState(false);
    const [list, setList] = useState<CommunityYouthItem[]>([]);
    // const [staffMembers, setStaffMembers] = useState([]);
    const [communities, setCommunities] = useState<ClubInterface[]>([]);
    const [loadingCommunities, setLoadingCommunities] = useState(false);

    const tcomms = useSelector((state: RootState) => state.getTClubs.TComm);

    const generateCommunities = (): CommunityYouthItem[] => {
        return [
            // {
            //     id: 1,
            //     title: "Staff",
            //     icon: 'star',
            //     desc: '',
            //     btnActive: true,
            //     active: true,
            //     invitesSend: 0,
            //     advisors: [administratorData()],
            //     club_id: undefined
            // },
            // {
            //     id: 201,
            //     title: "Guardians",
            //     icon: 'star',
            //     desc: '',
            //     btnActive: false,
            //     active: activeCards,
            //     advisors: [],
            //     invitesSend: 0,
            //     club_id: undefined
            // },
            // {
            //     id: 202,
            //     title: "Alumni",
            //     icon: 'star',
            //     desc: '',
            //     btnActive: false,
            //     active: activeCards,
            //     advisors: [],
            //     invitesSend: 0,
            //     club_id: undefined
            // },
        ]
    }

    const administratorData = () => {
        if (organization.orgAdmin && organization.orgAdmin.firstname !== '') {
            return {
                firstName: organization.orgAdmin.firstname,
                lastName: organization.orgAdmin.lastname,
                email: organization.orgAdmin.email,
            }
        }

        if (organization.principal && organization.principal.name !== '') {
            return {
                firstName: organization.principal.name.split(' ')[0],
                lastName: organization.principal.name.split(' ')[1],
                email: organization.principal.email,
            }
        }

        if (organization.primaryContact && organization.primaryContact.name !== '') {
            return {
                firstName: organization.primaryContact.name.split(' ')[0],
                lastName: organization.primaryContact.name.split(' ')[1],
                email: organization.primaryContact.email,
            }
        }

        return {
            firstName: '',
            lastName: '',
            email: organization.created_by || '',
        }
    }

    const getCommunities = () => {
        setLoadingCommunities(true);
        Backend(
            MicroServices.Club,
            `/clubs?pending=${organization.status.startsWith('P')}&org_id=${organization.id}&club_type=COMM`)
            .then((response) => response.json())
            .then(({ clubs }) => {
                setCommunities(clubs);
                setLoadingCommunities(false);
                setList([])
                const communitiesCreated: any = []

                // console.log(clubs)

                clubs.sort(function (a: any, b: any) {
                    if (a.id > b.id) {
                        return 1;
                    }
                    if (a.id < b.id) {
                        return -1;
                    }
                    return 0;
                }).forEach((comm: ClubInterface) => {
                    communitiesCreated.push({
                        id: 1,
                        title: comm.name,
                        icon: 'star',
                        desc: '',
                        btnActive: false,
                        active: true,
                        invitesSend: comm.openInvites || 0,
                        advisors: [administratorData()],
                        club_id: comm.id,
                        type: comm.template_id,
                        templateName: tcomms.find(({id}) => id===comm.template_id)?.name
                    })
                })
                setList(communitiesCreated)

            })
            .catch((error) => {
                console.log(error);
                setLoadingCommunities(false);
            })
    }

    const createCommunity = (community: any) =>
        new Promise((resolve, reject) => {
            Backend(
                MicroServices.Club,
                `/clubs?pending=${organization?.status.startsWith('PEND')}`,
                {
                    method: 'POST',
                    body: JSON.stringify(community),
                }
            ).
                then(resolve).catch(reject)
        })

    const createCommunities = () => new Promise<any>((resolve, reject) => {
        Promise.all(
            [...list]
                .map((item) => ({
                    "org_id": organization?.id,
                    // TODO: Check
                    "template_id": item.title === "Staff" ? 'YO Staff' : item.title,
                    "status": organization?.status.startsWith('PEND') ? 'PENDING' : 'ACTIVE',
                    // TODO: Check
                    // "member_type": item.memberType,
                    "name": item.title,
                    "gendersAllowed": ["M", "F", "O", "P"],
                }))
                .map((body) => Backend(
                    MicroServices.Club,
                    `/clubs?pending=${organization?.status.startsWith('PEND')}`,
                    {
                        method: 'POST',
                        body: JSON.stringify(body),
                    }
                ))
        )
            .then((responses) => {
                resolve(responses);
                // console.log(responses);
                getCommunities();
            })
            .catch(reject)
    })

    const [staffMembers, setStaffMembers] = useState([]);

    const reloadStaff = () => {
        getStaffOrg(organization, setStaffMembers);
    }

    useEffect(() => {
        getCommunities();
    }, []);

    const [showCreateCommunity, setShowCreateCommunity] = useState(false);
    const [communityTypeSelected, setCommunityTypeSelected] = useState<string>('');

    const getCommunityObj = (type: string): any => {
        switch (type) {
            case 'Staff':
                return {
                    id: 1,
                    title: "Staff",
                    icon: 'star',
                    desc: '',
                    btnActive: true,
                    active: true,
                    invitesSend: 0,
                    advisors: [administratorData()],
                    club_id: undefined,
                    type: tcomms.find(({name}) => name==='YO Staff')?.id,
                    templateName: 'Staff',
                }
            case 'Board':
                return {
                    id: 203,
                    title: "Board",
                    icon: 'star',
                    desc: '',
                    btnActive: true,
                    active: true,
                    invitesSend: 0,
                    advisors: [administratorData()],
                    club_id: undefined,
                    type: tcomms.find(({name}) => name==="Board")?.id,
                    templateName: "Board",
                }
            case 'Advisors':
                return {
                    id: 204,
                    title: "Advisors",
                    icon: 'star',
                    desc: '',
                    btnActive: true,
                    active: true,
                    invitesSend: 0,
                    advisors: [administratorData()],
                    club_id: undefined,
                    type: tcomms.find(({name}) => name==="Advisors")?.id,
                    templateName: "Advisor",
                }
            case 'Students':
                return {
                    id: 205,
                    title: "Students",
                    icon: 'star',
                    desc: '',
                    btnActive: true,
                    active: true,
                    invitesSend: 0,
                    advisors: [administratorData()],
                    club_id: undefined,
                    type: tcomms.find(({name}) => name==="Students")?.id,
                    templateName: "Student",
                }
            default:
                console.log('wrong community type')
                break;
        }
    }

    const [ownCommunityTypes, setOwnCommunityTypes] = useState(YO_OWN_COMMUNITY_TYPE);

    useEffect(() => {
        // if (['Staff', 'Board', 'Advisors'].includes(communityTypeSelected)) {
        //     const newOwnCommunityTypes = [...ownCommunityTypes]
        //     const index = newOwnCommunityTypes.indexOf(communityTypeSelected)
        //     newOwnCommunityTypes.splice(index, 1)
        //     setOwnCommunityTypes(newOwnCommunityTypes)
        // }
        if (communityTypeSelected !== '') setList([...list, getCommunityObj(communityTypeSelected)])
        setCommunityTypeSelected('')

    }, [communityTypeSelected])


    const onAddCommunitiesClick = () => setShowCreateCommunity(true);

    const onCommunityTypeButtonClick = (e: any) => {
        setCommunityTypeSelected(e.target.value);
        const cleanTCommName = e.target.value === "Staff" ? 'YO Staff' : e.target.value;
        const TCommId = tcomms.find(({name}) => name===cleanTCommName)?.id;
        setShowCreateCommunity(false);
        Backend(
            MicroServices.Club,
            `/clubs?pending=${organization.status.startsWith('PEND')}`, {
            headers: {
                "Content-Type": "application/json",
            },
            method: 'POST',
            body: JSON.stringify({
                "org_id": organization.id,
                "template_id": TCommId,
                "status": organization.status.startsWith('PEND') ? 'PENDING' : 'ACTIVE',
                "member_type": ["STAFF"],
                "name": e.target.value,
                "gendersAllowed": ["M", "F", "O", "P"],
            }),
        })
            .then((response) => {
                getCommunities()
            })
    }

    useEffect(() => {
        // console.log(list)
        // console.log(list)
        // if (communities.length !== 0) {
        //     const communitiesCreated: any = []
        //     communities.forEach((comm: ClubInterface) => {
        //         communitiesCreated.push({
        //             id: 1,
        //             title: comm.name,
        //             icon: 'star',
        //             desc: '',
        //             btnActive: false,
        //             active: true,
        //             invitesSend: comm.openInvites || 0,
        //             advisors: [administratorData()],
        //             club_id: comm.id
        //         })
        //     })
        //     setList(communitiesCreated)
        // }

    }, [communities])

    return (
        <>

            <div className="container-school-communities">
                {/* <h1 className="school-name-card">
                    {organization.name || ''}
                </h1> */}
                {(loadingCommunities) && (<div className="icon-spinner-third buttonSendSignUp spinner-centered-youth" />)}
                <div className="communities-school">
                    <AddCardCommunity onAddCommunitiesClick={onAddCommunitiesClick} />
                    <ModalAddCommunity ownCommunityTypes={ownCommunityTypes} onCommunityTypeButtonClick={onCommunityTypeButtonClick} show={showCreateCommunity} setShow={setShowCreateCommunity} />
                    {list.map((card) => (
                        <CardCommunity
                            community={card}
                            organization={organization}
                            staffMembers={staffMembers}
                            createCommunities={createCommunities}
                            reloadStaff={reloadStaff}
                            getCommunities={getCommunities}
                        />
                    ))}
                </div>

            </div>

        </>

    );
}

export default CardCommunitiesYouth;