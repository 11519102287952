import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { capitalize } from "../../helpers/letterHelper";
import { uploadImage } from "../../store/actions/orgsActions";
import { uploadAsset } from "../../store/actions/userActions";
import { LAVENDER, WHITE } from "../ClubIconsNavBar";
import PostEmbedPreview from "../PostEmbedPreview";
import PostLinkPreview from "../PostLinkPreview";
import ModalDiscardChanges from "../SetupSetup/ModalDiscardChanges";
import CreatePostImage from "./CreatePostImage";
import CreatePostLink from "./CreatePostLink";
import CreatePostPhoto from "./CreatePostPhoto";
import CreatePostText from "./CreatePostText";
import DropdownReachPost from "./DropdownReachPost";
import SelectPostType from "./SelectPostType";
import { useTranslation } from "react-i18next";
import './styles.css'

function StartPost({ whereIs, clubId, eventId, reloadPosts }:
  { whereIs: string, clubId: string, eventId: string, reloadPosts: any }) {

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const organization = useSelector((state: any) => state.getOrgs.organizationInfo);
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const userRoles = useSelector((state: any) => state.getUser.userRoles);

  const user = useSelector((state: any) => state.getUser.user);

  const [showPType, setShowPType] = useState(false);

  const [showDiscard, setShowDiscard] = useState<boolean>(false);
  const [step, setStep] = useState<1 | 2>(1);
  const [loadingPost, setLoadingPost] = useState(false);
  const [imgSrc, setImgSrc] = useState<string | null | undefined>(null);
  const [discardBy1, setDiscardBy1] = useState(false);
  const [takePictureOk, setTakePictureOk] = useState(true);
  const [loadingPicture, setLoadingPicture] = useState(false);

  const [textLink, setTextLink] = useState<boolean | string>(false);
  const [embedLink, setEmbedLink] = useState<boolean | string>(false);
  const [type, setType] = useState<string>("");

  const [postType, setPostType] = useState('');
  const [postText, setPostText] = useState('');
  const [linkHTML, setLinkHTML] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [fileType, setFileType] = useState<string>("");
  const [fileLink, setFileLink] = useState<string>("");
  const [postImages, setPostImages] = useState<string[]>([]);
  const [typePostSelector, setTypePostSelector] = useState<'PHOTO' | 'VIDEO' | 'IMAGE' | 'TEXT' | 'LINK'>();
  const [source, setSource] = useState<any>('');

  const onHide = (type?: string) => {
    setShowDiscard(false)

    if (type === 'cancel') {
      return
    }

    if (type === 'discard') {
      setStep(1);
      setTypePostSelector(undefined);
      setPostText("");
      setTextLink(false);
      setEmbedLink(false);
      setFileLink("");
      setSource("");
      setPostImages([]);
      setLoadingPost(false);
      setPostType('');
      setImgSrc(null);
      setLinkHTML("");
      if (!discardBy1) {
        setShowPType(false);
      }
      setDiscardBy1(false);
      return
    }
  }

  const handleGoNext = () => {
    setStep(2);
  }

  const handleSavePost = () => {

    if (takePictureOk === true && typePostSelector === 'PHOTO') {
      toast.error(t('toast_pleaseCheckPhoto'));
      return;
    }

    if (postType === '' && whereIs === 'bulletin') {
      toast.error(t('toast_pleaseSelectWhere'));
      return;
    }

    if (postText === '' && fileLink === '' &&
      source === '' && postImages.length === 0 &&
      !embedLink && !textLink && !imgSrc) {
      toast.error(t('toast_pleaseAddContent'));
      return;
    }

    handleSubmitPost();
  }

  const postTypeContinue = () => {
    switch (typePostSelector) {
      case "PHOTO":
        return <CreatePostPhoto
          imgSrc={imgSrc}
          setImgSrc={setImgSrc}
          setTakePictureOk={setTakePictureOk}
          loadingPicture={loadingPicture}
          handleSaveImage={handleSaveImage}
        />;
        break;
      case "IMAGE":
        return <CreatePostImage
          postImages={postImages}
          setPostImages={setPostImages}
          type={type}
          setType={setType}
          fileLink={fileLink}
          postText={postText}
          setPostText={setPostText}
        />;
        break;
      case "VIDEO":
        return <>
          <CreatePostLink
            linkType='video'
            postText={postText}
            setPostText={setPostText}
            setLinkHTML={setLinkHTML}
            setTextLink={setTextLink}
            setEmbedLink={setEmbedLink}
            linkHTML={linkHTML}
          />
        </>
        break;
      case "TEXT":
        return <CreatePostText
          postText={postText}
          setPostText={setPostText}
        />;
        break;
      case "LINK":
        return <>
          <CreatePostLink
            linkType='other'
            postText={postText}
            setPostText={setPostText}
            setLinkHTML={setLinkHTML}
            setTextLink={setTextLink}
            setEmbedLink={setEmbedLink}
            linkHTML={linkHTML}
          />
        </>
        break;
      default:
        return <></>;
        break;
    }
  }

  const selectType = () => {
    if (textLink) {
      return "LINK";
    }

    if (embedLink) {
      return "EMBED"
    }

    if ((postText !== '' && postImages.length > 0) || (postText !== '' && fileLink)) {
      return 'MIXED';
    }

    if ((postText === '' && postImages.length > 0) || (postText === '' && fileLink)) {
      return 'IMG';
    }

    return 'TEXT';
  }

  const postTypeSure = () => {
    if (whereIs === 'club') {
      setPostType('CLUB');
      return 'CLUB';
    }

    else if (whereIs === 'event') {
      setPostType('EVENT')
      return 'EVENT';
    }

    else if (whereIs === 'profile') {
      setPostType('WALL')
      return 'WALL'
    }
  }

  const handleSubmitPost = () => {
    setLoadingPost(true);

    const postAux = (postType === '' ? postTypeSure() : postType) || '';

    const formData: FormData = new FormData();
    formData.append("author", userInfo.username);
    formData.append("type", selectType());
    formData.append("org_id", organization.id);
    formData.append("content", postText);
    switch (postAux) {
      case 'WALL':
        formData.append("reach", postAux);
        formData.append("reach_id", userInfo.username);
        break
      case 'ORG':
        formData.append("reach", postAux);
        formData.append("reach_id", organization.id);
        break
      case 'ALL':
        formData.append("reach", postAux);
        formData.append("reach_id", organization.id); //@todo
        break
      case 'EVENT':
        formData.append("reach", 'EVENT');
        formData.append("reach_id", eventId);
        break
      case 'CLUB':
        formData.append("reach", 'CLUB');
        formData.append("reach_id", clubId);
        break
      default:
        formData.append("reach", 'CLUB');
        formData.append("reach_id", postAux);
        break
    }

    (textLink || embedLink) ? formData.append("link", linkHTML) : null;

    Backend(
      MicroServices.Posts,
      `/post`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((results) => {

        if (results.post_id && postImages.length > 0) {

          const idPost = results.post_id;

          postImages.map((request: any) => {

            dispatch<any>(uploadAsset(
              request.type,
              request.name,
              "post_asset"
            ))
              .then((results: any) => {
                if (results.body) {
                  dispatch<any>(uploadImage(
                    results.body,
                    request.type,
                    request,
                  ))
                    .then((result: any) => {

                      if (result.status === 200 || result.status === 201) {

                        const urlImage = results.body.split('?')[0];
                        Backend(
                          MicroServices.Posts,
                          `/media`, {
                          method: "POST",
                          headers: {
                            'Content-Type': 'application/json'
                          },
                          body: JSON.stringify({
                            "post_id": idPost.toString(),
                            "file_name": request.name,
                            "file_type": request.type,
                            "file_link": urlImage
                          }),
                        })
                          .then((response) => {
                            setPostText("");
                            setTextLink(false);
                            setEmbedLink(false);
                            setFileLink("");
                            setLinkHTML("");
                            setSource("");
                            reloadPosts();
                            setPostImages([]);
                            setLoadingPost(false);
                            setPostType('');
                            setShowPType(false);
                            setStep(1);
                            setImgSrc(null);
                            setTypePostSelector(undefined);
                          })
                          .catch((error) => {
                            toast.error(t('toast_errorPosting'))
                            console.error(error);
                          });
                      }
                    })
                    .catch((error: any) => {
                      toast.error(t('toast_errorPosting'))
                      console.log(error)
                    })
                }
              })
              .catch((error: any) => {
                toast.error(t('toast_errorPosting'))
                console.log(error)
              })

          })
        } else if (fileLink && results.post_id) {
          Backend(
            MicroServices.Posts,
            `/media`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              "post_id": results.post_id.toString(),
              "file_name": fileName,
              "file_type": fileType,
              "file_link": fileLink
            }),
          })
            .then((response) => {
              setPostText("");
              setTextLink(false);
              setEmbedLink(false);
              setFileLink("");
              setLinkHTML("");
              setSource("");
              reloadPosts();
              setPostImages([]);
              setLoadingPost(false);
              setPostType('');
              setShowPType(false);
              setStep(1);
              setImgSrc(null);
              setTypePostSelector(undefined);
            })
            .catch((error) => {
              toast.error(t('toast_errorPosting'))
              console.error(error);
            });
        }
        else if (results.post_id) {
          reloadPosts();
          setLoadingPost(false);
          setShowPType(false);
          setStep(1);
          setTypePostSelector(undefined);
          setPostType('');
          setLinkHTML("");
          console.log('Only Text succesfully posted. Id:', results.post_id)
        }
        else {
          toast.error(t('toast_errorPosting'));
          setLoadingPost(false);
        }
      })
      .catch((error) => {
        toast.error(t('toast_errorPosting'))
        console.error(error);
      })
      .finally(() => {
        setPostText("");
        setTextLink(false);
        setEmbedLink(false);
      })
  };

  const handleSaveImage = (e: any) => {
    setLoadingPicture(true);
    fetch(e)
      .then(res => res.blob())
      .then(blob => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();

        const contentType = e.split(';')[0].split(':')[1];
        const extension = contentType.split('/')[1]
        const file = new File([blob], 'imagePost_' + timestamp, { type: contentType });

        if (file) {

          setFileName(file.name + '.' + extension)
          setFileType(file.type)

          dispatch<any>(uploadAsset(
            file.type,
            file.name + '.' + extension,
            "post_asset"
          ))
            .then((results: any) => {
              if (results.body) {
                dispatch<any>(uploadImage(
                  results.body,
                  contentType,
                  file,
                ))
                  .then((result: any) => {
                    if (result.status === 200) {
                      const urlImage = results.body.split('?')[0];
                      setFileLink(urlImage)
                      setType('IMG')
                      setLoadingPicture(false);
                      setTakePictureOk(false);
                      toast.success(t('toast_imageProcessed'))
                    }
                  })
                  .catch((error: any) => {
                    console.log(error)
                  })
              }
            })
            .catch((error: any) => {
              console.log(error)
            })
        }

      })
  };

  const handleCloseClick = () => {
    setTextLink(false);
    setEmbedLink(false);
    setLinkHTML("");
  }

  const choosePostType = (postType: 'PHOTO' | 'VIDEO' | 'IMAGE' | 'TEXT' | 'LINK') => {
    setTypePostSelector(postType);
    setStep(2);
  }

  const chooseIcon = () => {
    if (typePostSelector === 'PHOTO') {
      return 'camera'
    }
    if (typePostSelector === 'VIDEO') {
      return 'video'
    }
    if (typePostSelector === 'TEXT') {
      return 'input-text'
    }
    if (typePostSelector === 'LINK') {
      return 'link'
    }
    return 'images'
  }

  return (
    <>
      <div className="startPost-container">

        <div className="PostModal-ButtonsContainer">
          <div className="PostModal-Exit">

            <div className="PostModal-LabelContainer">
              <img
                className="PostModal-round-avatar-create-post"
                src={user.avatar || 'https://htmlcolorcodes.com/assets/images/colors/dark-gray-color-solid-background-1920x1080.png'}
                alt="User Avatar"
              />
              <label className="PostModal-LabelGeneral">{t('post__shareSomething')}</label>
            </div>
            {(step !== 1) && (
              <FontAwesomeIcon
                icon="close"
                size='lg'
                color={WHITE}
                className="pointer"
                onClick={() => {
                  setDiscardBy1(true);
                  setShowDiscard(true)
                }}
              />)}
          </div>


          {(step === 1) ?
            (<>
              <div className="createPost__selectTypeOptions PostModal-upper-buttons">

                {/*<div className={`PostModal-createPost__selectTypeOption ${typePostSelector === 'PHOTO' ? 'active' : ''}`} 
                   onClick={() => choosePostType('PHOTO')}>
                <span className="tiptext">Take a picture</span>
                <FontAwesomeIcon
                  icon="camera"
                  size='lg'
                  color={WHITE}
                  className=" "
                />
                <span>Photo</span>
              </div>*/}

                <div className={`PostModal-createPost__selectTypeOption ${typePostSelector === 'IMAGE' ? 'active' : ''}`}
                  onClick={() => choosePostType('IMAGE')}>
                  {/*<span className="tiptext">Add one or more images</span>*/}
                  <FontAwesomeIcon
                    icon="images"
                    size='lg'
                    color={WHITE}
                    className=" "
                  />
                  <span>{t('post__image')}</span>
                </div>

                <div className={`PostModal-createPost__selectTypeOption ${typePostSelector === 'VIDEO' ? 'active' : ''}`}
                  onClick={() => choosePostType('VIDEO')}>
                  {/*<span className="tiptext">Add embed video or URL</span>*/}
                  <FontAwesomeIcon
                    icon="video"
                    size='lg'
                    color={WHITE}
                    className=" "
                  />
                  <div>{t('post__video')}</div>
                </div>

                <div className={`PostModal-createPost__selectTypeOption ${typePostSelector === 'TEXT' ? 'active' : ''}`}
                  onClick={() => choosePostType('TEXT')}>
                  {/*<span className="tiptext">Add text</span>*/}
                  <FontAwesomeIcon
                    icon="input-text"
                    size='lg'
                    color={WHITE}
                    className=" "
                  />
                  <span>{t('post__text')}</span>
                </div>

                <div className={`PostModal-createPost__selectTypeOption ${typePostSelector === 'LINK' ? 'active' : ''}`}
                  onClick={() => choosePostType('LINK')}>
                  {/*<span className="tiptext">Add link</span>*/}
                  <FontAwesomeIcon
                    icon="link"
                    size='lg'
                    color={WHITE}
                    className=" "
                  />
                  <span>{t('post__link')}</span>
                </div>
              </div>

            </>)
            :
            (
              <>
                <div className={`PostModal-IntroduceInfoContainer ${(typePostSelector === 'TEXT') && ('text')}`}>
                  <div className={`PostModal-createPost__selectTypeOption active widthResponsive`}>
                    <FontAwesomeIcon
                      icon={chooseIcon()}
                      size='lg'
                      color={WHITE}
                      className=" "
                    />
                    <span>{capitalize(typePostSelector || '')}</span>
                  </div>
                  {postTypeContinue()}
                </div>

                {textLink && (
                  <PostLinkPreview
                    showX url={textLink as string}
                    setTextLink={setTextLink}
                    onClose={handleCloseClick}></PostLinkPreview>)}
                {embedLink &&
                  <PostEmbedPreview
                    showX
                    setEmbedLink={setEmbedLink}
                    onClose={handleCloseClick}
                    embedLink={embedLink}></PostEmbedPreview>}
                <div className={`PostModal-ActionsContainer ${(whereIs === 'bulletin') && ('bulletin')}`}>
                  {(whereIs === 'bulletin') && (
                    <DropdownReachPost
                      postType={postType}
                      setPostType={setPostType}
                    />
                  )}
                  <button
                    className={`PostModal-buttonSavePost pointer ${(loadingPost) && ('activeLoading')}`}
                    onClick={handleSavePost}
                    disabled={loadingPost}
                  >
                    {loadingPost ? (
                      <div className="icon-spinner-third buttonSendSignUp" />
                    ) : (
                      t('button__save')
                    )}
                  </button>
                </div>
              </>
            )}

        </div>
      </div>
      <ModalDiscardChanges show={showDiscard} onHide={onHide} />
    </>
  );
}

export default StartPost;