import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SelectedTeams } from "../../../containers/SetupSchool";
import "../../../containers/SetupSchool/styles.scoped.css";
import ClubCreate from "../../../interfaces/club/ClubCreate";
import TemplateTeam from "../../../interfaces/template-clubs/templateTeam";
import { RootState } from "../../../store/store";
import { WHITE } from "../../ClubIconsNavBar";
import ModalKillClub from "../ModalKillClub";
import { Team } from "../ModalTeam";
import { castTeamDistinguisherToID, castTemplateIdToTemplateName, getSavedToShow } from "../Teams";

interface SelectedTeamsListProps {
  setChange: (e: any) => void,
  selectedTeams: SelectedTeams;
  setSelectedTeams: (e: any) => void,
  deleteTemplate: (templateid: string) => void,
  dynamoClubs: {
    get: any[];
    set: any;
  },
  teamsDelete: string[],
  teams: ClubCreate[],
  handleOnClickClub:(e: any, title: any, team: Team, type: string, customName?: string) => void
}

const SelectedTeamsList = ({
  setChange,
  selectedTeams,
  setSelectedTeams,
  deleteTemplate,
  dynamoClubs,
  teamsDelete,
  teams,
  handleOnClickClub
}: SelectedTeamsListProps) => {

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [deleteModalText, setDeleteModalText] = useState<{ message: string, title: string, template_id: string }>({ message: '', title: '', template_id: '' })
  const closeModalDelete = (del: boolean) => {
    setShowDeleteModal(false)
    if (del) {
      //SaveDelete
      deleteTemplate(deleteModalText.template_id)
    }
  }

  const handleDelete = (e: any, saved: boolean, team: string, template_id: string) => {
    e.stopPropagation()
    setChange(true)
    if (saved) {
      setShowDeleteModal(true)
      setDeleteModalText({
        title: `${team}`,
        message: `Are you sure you want to delete all teams of ${team}?`,
        template_id
      })
    } else {
      const selectedTeamsCopy = { ...selectedTeams }
      delete selectedTeamsCopy[template_id]
      setSelectedTeams(selectedTeamsCopy)
    }
  }

  const tteams = useSelector((state: RootState) => state.getTClubs.TTeam);

  return (
    <>
      <div className="selected-clubs">
        {
          Object.keys(selectedTeams).map((template_id) => (
            <div className={selectedTeams[template_id]?.[0]?.active === null ? "saved-club" : "selected-club"}>
              <p>
                {`${selectedTeams[template_id].length} ${castTemplateIdToTemplateName(template_id, tteams)} ${selectedTeams[template_id].length === 1 ? 'Team' : 'Teams'}`}
              </p>
              {selectedTeams[template_id]?.[0]?.active === null ? (
                <FontAwesomeIcon
                  className='icon-times-2 '
                  icon="times"
                  size='xl'
                  color={WHITE}
                  onClick={(e) => handleDelete(e, true, castTemplateIdToTemplateName(template_id, tteams), template_id)}
                />
              ) : (
                <FontAwesomeIcon
                  className='icon-times-2 '
                  icon="times"
                  size='xl'
                  color={WHITE}
                  onClick={(e) => handleDelete(e, false, castTemplateIdToTemplateName(template_id, tteams), template_id)}
                />
              )}
            </div>
          ))
        }
        {
          getSavedToShow(dynamoClubs.get, teamsDelete)
          .filter((club)=>club.team_distinguisher === 'Team-Custom')
          .map((club) => (
            <div className="saved-club">
              <p>{club.name}</p>
              <FontAwesomeIcon
                className='icon-times-2 '
                icon="times"
                size='xl'
                color={WHITE}
                onClick={(e) => handleOnClickClub(e, club.name, {
                  id: `${club.template_id}${castTeamDistinguisherToID(club.team_distinguisher)}`,
                  title: club.name,
                  template_id: club.template_id,
                  active: null,
                  gendersAllowed: club.gendersAllowed,
                  gradesAllowed: club.gradesAllowed,
                }, "SaveDelete", club.name)}
              />
            </div>
          ))}
        {
          teams
          .filter((team)=>team.team_distinguisher === 'None-Unknown')
          .map((team) => (
            <div className="selected-club">
              <p>{team.name}</p>
              <FontAwesomeIcon
                className='icon-times-2 '
                icon="times"
                size='xl'
                color={WHITE}
                onClick={(e) => handleOnClickClub(e, '', {
                  id: `${team.template_id}${castTeamDistinguisherToID(team.team_distinguisher)}`,
                  title: "",
                  template_id: team.template_id,
                  active: true,
                  gendersAllowed: [],
                  gradesAllowed: []
                }, "Delete", team.name)}
              />
            </div>
          ))}
      </div>
      <ModalKillClub
        show={showDeleteModal}
        message={deleteModalText.message}
        title={deleteModalText.title}
        onHide={closeModalDelete}
      /></>

  );
}

export default SelectedTeamsList;
