import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    MainWrapper,
    Header,
    Title,
    Row,
    RowGap,
    CreateBtn,
    ContentWrapper,
} from '../../components/SuperAdminComponents/basicForSACenter';
import CreateListModal from "./IntList/Create/CreateModal";
import CreateIntModal from "./Int/Create/CreateModal";
import useInterestsSA from "../../hooks/Interests/useInterestsSA";
import INT from "../../interfaces/Interests/INT/INT";
import IntListDropdown from "./IntList/Dropdown/IntListDropdown";
import TCListSearch from "../../components/TClubs/TCList/TCListSearch";
import styled from "styled-components";
// import ModalDeleteTClub from "../../components/TemplateClubsTable/ModalDeleteTemplateClub";

const Interests = () => {
    const navigate = useNavigate();
    const { intPages, reloadInterests } = useInterestsSA(1000);

    const [INTListSelected, setINTListSelected] = useState<string>();
    const [INTListSearchTerm, setINTListSearchTerm] = useState('');
    const [showCreateList, setShowCreateList] = useState<boolean>(false);
    const [showCreateInterest, setShowCreateInterest] = useState<boolean>(false);
    const [reloadINTListDropdown, setReloadINTListDropdown] = useState(false);
    const [toDeleteTClub, settoDeleteTClub] = useState<INT>();
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const filteredInt = useMemo(
        () =>
            intPages[0]
                .filter((int) => int.name.toLowerCase().includes(INTListSearchTerm.toLowerCase())),
        [intPages[0], INTListSearchTerm]
    )

    useEffect(() => {
        if (!INTListSelected || INTListSelected === 'ALL') {
            return;
        }

        if (INTListSelected === 'CREATE') {
            setShowCreateList(true);
            setINTListSelected('ALL');
            return;
        }

        navigate(`./intlists/${INTListSelected}`)
    }, [INTListSelected]);

    const onHideCreateListModal = () => {
        setShowCreateList(false);
        setReloadINTListDropdown(true);
    }

    const onHideCreateIntModal = () => {
        setShowCreateInterest(false);
        reloadInterests();
    }

    const onLoaded = () => {
        setReloadINTListDropdown(false);
    }

    return (
        <>
            <MainWrapper>
                <Header>
                    <Row>
                        <Title>Interests</Title>
                        <CreateBtn onClick={() => setShowCreateInterest(true)}>
                            Create Interest
                        </CreateBtn>
                    </Row>
                    <RowGap>
                        <IntListDropdown
                            setSelected={setINTListSelected}
                            reload={reloadINTListDropdown}
                            onLoaded={onLoaded}
                        />
                        <TCListSearch searchTerm={INTListSearchTerm} setSearchTerm={setINTListSearchTerm}/>
                    </RowGap>
                </Header>
                <ContentWrapper>
                    <InterestsWrapper>
                        {filteredInt.map((int) => <InterestsItem>#{int.name}</InterestsItem>)}
                    </InterestsWrapper>
                </ContentWrapper>
            </MainWrapper>
            <CreateListModal
                show={showCreateList}
                onHide={onHideCreateListModal}
            />
            <CreateIntModal
                show={showCreateInterest}
                onHide={onHideCreateIntModal}
            />
            {/* TODO: Delete modal <ModalDeleteTClub
                show={showDeleteModal}
                onHide={() => {
                    setShowDeleteModal(false);
                }}
                id={toDeleteTClub?.id}
                title={toDeleteTClub?.name}
            /> */}
        </>
    );
}

const InterestsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
`;

const InterestsItem = styled.div`
    padding: 10px;
    color: black;
    font-family: 'Roboto';
    background-color: gray;
    border-radius: 20px;
`;

export default Interests;
