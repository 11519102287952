import { MouseEvent, MouseEventHandler, useMemo } from "react";
import styled, { css } from "styled-components";
import { ClickableIcon } from "../../containers/TClubs/components";
import TClub from "../../interfaces/TClubs/TClub/TClub";
import { LAVENDER } from "../ClubIconsNavBar";
import { ACTION_TYPE, DELETE_TYPE } from "./Clubs";

const ACTION_CLASS_MAP: {[key in ACTION_TYPE]: string | undefined} = {
    'SAVE_EDIT': 'savedTemplate',
    'ADD': undefined,
    'EDIT': 'greyTemplate',
}

interface ListedTClubProps {
    isInDB: boolean;
    isReadyToCreate: boolean;
    isReadyToDelete: boolean;
    tclub: TClub;
    handleClick: (type: ACTION_TYPE) => void;
    handleClickDelete: (type: DELETE_TYPE) => void;
}

const ListedTClub = ({
    isInDB,
    isReadyToCreate,
    isReadyToDelete,
    tclub,
    handleClick,
    handleClickDelete,
}: ListedTClubProps) => {
    // const actionType: ACTION_TYPE = useMemo(
    //     () => {
    //         if (isInDB && !isReadyToDelete) return 'SAVE_EDIT';
    //         if (!isReadyToCreate) return 'ADD';
    //         return 'EDIT'
    //     },
    //     [
    //         isInDB,
    //         isReadyToCreate,
    //         isReadyToDelete,
    //     ],
    // );
    const actionType: ACTION_TYPE = 'ADD';

    // const deleteType: DELETE_TYPE = useMemo(
    //     () => {
    //         if (actionType === 'SAVE_EDIT') return 'SAVE_DELETE';
    //         if (actionType === 'ADD') return 'NO_DELETE';
    //         return 'DELETE';
    //     },
    //     [actionType]
    // )

    const deleteType: DELETE_TYPE = 'NO_DELETE';

    const onClick: MouseEventHandler<HTMLDivElement> = (event) => {
        event.stopPropagation();
        handleClick(actionType);
    }

    const onClickIcon: MouseEventHandler<SVGSVGElement> = (event) => {
        event.stopPropagation();
        if (deleteType === 'NO_DELETE') return;
        handleClickDelete(deleteType)
    }

    return (
        <ItemWrapper
            type={actionType}
            onClick={onClick}
            className={ACTION_CLASS_MAP[actionType]}
        >
            {tclub.TCLists?.[0]?.assignedName ||  tclub.name}
            <ClickableIcon
                className={actionType === 'ADD' ? "icon-plus" : "icon-times-2"}
                icon={actionType === 'ADD' ? "plus" : "times"}
                size='1x'
                color={actionType === 'ADD' ? LAVENDER : '#220e41'}
                onClick={onClickIcon}
            />
        </ItemWrapper>
    );
}

interface ItemWrapperProps {
    type: ACTION_TYPE
}

const ItemWrapper = styled.div`
    cursor: pointer;
    ${({type}: ItemWrapperProps) => type ==='EDIT' ? css`
        background: #7400f9!important;
        color: #fff!important;
    `: ''}
    ${({type}: ItemWrapperProps) => type ==='SAVE_EDIT' ? css`
        background: #444!important;
    `: ''}
`;

export default ListedTClub;