import { useEffect, useState } from "react"
import { Backend, MicroServices } from "../helpers/backendHelper";
import { PostalCodeClean } from "../interfaces/personalization/postalCodes";
import SelectItem from "../interfaces/SelectItem";

const usePostalCodes = () => {
  const [postalCodes, setPostalCodes] = useState<PostalCodeClean>();

  // useEffect(() => {
  //   Backend(
  //     MicroServices.Roster,
  //     '/p13n/postal-codes'
  //   )
  //     .then((response) => response.json())
  //     .then((postalCodes) => setPostalCodes(postalCodes))
  // }, [])
  
  const setCountry = (country: string) => {
    if (!country) return;
    Backend(
      MicroServices.Roster,
      `/p13n/postal-codes?country=${country}`
    )
      .then((response) => response.json())
      .then((postalCodes) => setPostalCodes(postalCodes))
  }

  return {
    postalCodes,
    setCountry,
  }
}

export default usePostalCodes;