import React, { useState, useEffect } from 'react';
import './styles.css';
import './SignUp2.css';
import './styles.scoped.css';
import DatePicker from 'react-datepicker';
import { useNavigate, useParams } from 'react-router-dom';
import { getInvitesByToken, createGeneralUserByInvite } from "../../store/actions/inviteActions";
import { getOrganizationInfo } from "../../store/actions/orgsActions";
import { useDispatch, useSelector } from "react-redux";
import { getCity, getCountry, getLocation } from '../../store/actions/locationActions';
import { cityIdFormat, countryIdFormat } from '../../helpers/locationHelper';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { dateToUpdate, checkBirth } from '../../helpers/dateHelper';
import { userAsAdvisor, userAsMember } from '../../store/actions/clubActions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
import { isValidUsername } from '../../helpers/userHelper';
import { faBars, faClock, faMedal, faUserShield } from '@fortawesome/pro-regular-svg-icons';
import MenuWrp from '../../components/MenuWrp';
import useGender from '../../hooks/useGender';
import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";

toast.configure()

const property1LabelProperty2Open1Data = {
  label: 'First Name',
  typeHereTheInformation: 'Anita',
  className: '',
};

const property1LabelProperty2Open2Data = {
  label: 'Last Name',
  typeHereTheInformation: 'Ortiz',
  className: 'form-parts-4-1',
};

const frame61301Data = {
  property1LabelProperty2Open1Props: property1LabelProperty2Open1Data,
  property1LabelProperty2Open2Props: property1LabelProperty2Open2Data,
};

const property2round1Data = {
  children: 'dot-circle',
};

const property2round2Data = {
  children: 'circle',
};

const property1DefaultData = {
  gender: 'Gender',
  female: 'Female',
  male: 'Male',
  property2round1Props: property2round1Data,
  property2round2Props: property2round2Data,
};

const componentData = {
  mainBackground:
    'https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/6219388fb3094ba2a2c54c20/img/desktop---38@1x.png',
  img1: 'https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/group-4960@2x.png',
  img2: 'https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/group-4959@1x.png',
  img3: 'https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/group-4961@2x.png',
  oneUnifyingPlatfo:
    'One, unifying platform to manage and promote all extracurricular activities ensuring equal awareness and access to opportunity.',
  saveTeachersTimeA: (
    <>
      Save teachers time and receive previously unavailable insight into school
      or system wide engagement.
      <br />
      Protect students, with a safe, invitation-only social-network.
      <br />
      Align student engagement with digital rewards.
    </>
  ),
  overlapGroup1:
    'https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/rectangle-3616@1x.png',
  vector21:
    'https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/vector-21@2x.png',
  number2: '2',
  frame61301Props: frame61301Data,
  property1DefaultProps: property1DefaultData,
};

export default function SignUpUserGeneral() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const invite = useSelector((state: any) => state.getInvites.inviteByToken);
  const location = useSelector((state: any) => state.getLocation.location);
  const city = useSelector((state: any) => state.getLocation.cities);
  const country = useSelector((state: any) => state.getLocation.countries);
  const loadingCreateUserInvite = useSelector((state: any) => state.getUser.loadingCreateUserInvite || false);

  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [gender, setGender] = useState('');
  const [myBirthday, setMyBirthday] = useState(new Date());
  const [password, setPassword] = useState('');
  const [preferedLanguage, setPreferedLanguage] = useState('english');
  const [countryCode, setCountryCode] = useState();

  const [club, setClub] = useState('');
  const [organization, setOrganization] = useState([]);
  const [organizationsNames, setOrganizationsNames] = useState('');
  const [role, setRole] = useState('');
  const [zipcode, setZipCode] = useState('');
  const [tokenUser, setTokenUser] = useState('');

  const [isActive, setActive] = useState(false);
  const [activePopup, setActivePopup] = useState(false);

  const handleToggle = (e: any) => {
    e.preventDefault();
    setActive(!isActive);
  };

  useEffect(() => console.log('countryCode', countryCode), [countryCode])
  const { token } = useParams();

  const {
    mainBackground,
    img1,
    img2,
    img3,
    oneUnifyingPlatfo,
    saveTeachersTimeA,
    overlapGroup1,
  } = componentData;

  const { genders, setCountry: setCountryGender } = useGender();

  const handleSubmit = () => {

    const correct = dataCorrect();

    if (correct === 'Success') {
      createUser();
    }

  };

  const dataCorrect = () => {

    //Check gender entered
    if (gender === '') {
      console.log(gender)
      toast.error(t('toast_pleaseSelectGender'));
      return;
    }

    //Check firstname entered
    if (firstName === '') {
      toast.error(t('toast_pleaseFillFirstName'));
      return;
    }

    //Check lastname entered
    if (lastName === '') {
      toast.error(t('toast_pleaseFillLastName'));
      return;
    }

    //Check username entered
    if (!isValidUsername(userName)) {
      toast.error(t('toast_pleaseCheckUsername'));
      return;
    }

    //Check password entered
    if (password === '') {
      toast.error(t('toast_pleaseFillPassword'));
      return;
    }

    return 'Success';
  };

  const createUser = () => {

    const formToSend: any = {
      givenName: firstName,
      familyName: lastName,
      gender: gender,
      email: email,
      userName: userName,
      zipcode: zipcode,
      organizations: organization,
      organizationsNames: organizationsNames,
      roles: role,
      password: password,
      preferredLanguage: preferedLanguage,
      location: location,
      city: city,
      country: country,
      countryCode,
      clubs: club ? [club] : []
    };

    const birthChecked = checkBirth(dateToUpdate(myBirthday));
    if (birthChecked) {
      formToSend.birthdate = dateToUpdate(myBirthday);
    }

    dispatch<any>(createGeneralUserByInvite(tokenUser, formToSend))
      .then((results: boolean) => {
        if (results) {
          toast.success(t('toast_userCreated'));
          navigate('/');
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  const handleChangeRadio = (event: any) => {
    setGender(event.target.value);
  }

  const handleChange = (event: any) => {
    setPreferedLanguage(event.target.value)
  };

  function handleMenu(e: any) {
    setActivePopup(true);
  }

  function handleCloseMenu() {
    setActivePopup(false);
  }

  useEffect(() => {
    dispatch(getInvitesByToken({
      'token': token
    }));
  }, [])

  useEffect(() => {
    if (location && typeof location === 'string') {
      dispatch<any>(getCountry(countryIdFormat(location)))
        .then((results: any) => { })
        .catch(console.log)

      dispatch<any>(getCity(cityIdFormat(location)))
        .then((results: any) => { })
        .catch(console.log)
    }
  }, [location])

  useEffect(() => {

    if (invite.status === 'USED') {
      navigate('/');
      toast.error(t('toast_thisUserIsAlready'));
    }

    if (invite.status === 'DELETED') {
      navigate('/');
      toast.error(t('toast_thisInviteIsDeleted'));
    }

    else if ('firstName' in invite) {
      setFirstName(invite.firstName);
      setLastName(invite.lastName);
      setEmail(invite.email);

      setClub(invite.club_id);
      setOrganization(invite.org_id);
      setRole(invite.role);
      setTokenUser(invite.token);

      if ('dateOfBirth' in invite) {
        setMyBirthday(new Date(invite.dateOfBirth));
      }

      if ('gender' in invite) {
        setGender(invite.gender);
      }

      dispatch<any>(getOrganizationInfo(invite.org_id, false))
        .then((results: any) => {
          setCountryGender(results.countryCode);
          setCountryCode(results.countryCode);
          setOrganizationsNames(results.name);

          setZipCode(results.zipcode);
          if (!('zipcode' in invite)) {
            dispatch<any>(getLocation(results.zipcode))
              .then((results: any) => { })
              .catch(console.log)
          }

        })
        .catch(console.log);

      if ('zipcode' in invite) {
        setZipCode(invite.zipcode);
        dispatch<any>(getLocation(invite.zipcode))
          .then((results: any) => { })
          .catch(console.log)
      }
    }
  }, [invite])

  return (
    <div className="containerSignUp">
      {/*<div
        className="background screen"
        style={{
          backgroundImage: `url(${mainBackground}) no-repeat center center fixed`,
        }}
      >
        <div className="frame-6129">
          <div className="group-container">
            <img className="group-4960" alt="group-4960" src={img1} />
            <img className="group-4959" alt="group-4959" src={img2} />
            <img className="group-4961" alt="group-4961" src={img3} />
          </div>
          <div className="frame-6128">
            <div className="schools-and-school-systems valign-text-middle robotocondensed-bold-white-24px">
              Schools and School Systems
            </div>
            <p className="one-unifying-platfo valign-text-middle robotocondensed-normal-white-16px">
              {oneUnifyingPlatfo}
            </p>
            <p className="save-teachers-time-a robotocondensed-normal-white-16px">
              {saveTeachersTimeA}
            </p>
          </div>
        </div>
        <div
          className="overlap-group1"
          style={{ backgroundImage: `url(${overlapGroup1})` }}
        >
          
        </div>
      </div>*/}

      <div className={`overlap ${activePopup && 'active'}`} onClick={handleCloseMenu}>
        <MenuWrp activePopup={activePopup} />
      </div>
      <div className='headerSignUp'>
        <div className="wrapContainer">
          <div className='logoSignUp'>
            <img src="https://possibilitycompany.com/wp-content/uploads/2020/02/Possibility-Color_Logotype.png" alt="" />
          </div>
          <div className='buttonMenu'>
            <button className="loginSignUp" onClick={() => navigate('/')}>Login</button>
            <button className="signUpSignUp" onClick={() => navigate('/signup')}>Sign Up</button>
            <button className="menuSignUp">
              <FontAwesomeIcon
                icon={faBars}
                size='xl'
                color={"white"}
                onClick={(e) => handleMenu(e)}
                className="buttonMenu"
              />
            </button>
          </div>
        </div>
      </div>

      <div className="containerSection containerPanelPrincipal">

        <div className="col col1">
          <div className='grayFilter'></div>
          <div className="signUpInfo">
            <h2>Schools and School Systems</h2>
            <div className='textInfoFirst'>
              One, unifying platform to manage and promote all extracurricular activities ensuring equal awareness and access to opportunity.
            </div>
            <div className='textInfo'>
              <FontAwesomeIcon
                icon={faClock}
                size='xl'
                color={"white"}
              />
              <p>Save teachers time and receive previously unavailable insight into school or system wide engagement, i.e., by school, gender, ethnicity, class year and socioeconomic status.</p>
            </div>
            <div className='textInfo'>
              <FontAwesomeIcon
                icon={faUserShield}
                size='xl'
                color={"white"}
              />
              <p>Protect students, with a safe, invitation-only social-network that promotes student exploration of their interests through participation in real-life activities.</p>
            </div>
            <div className='textInfo'>
              <FontAwesomeIcon
                icon={faMedal}
                size='xl'
                color={"white"}
              />
              <p>Align student engagement with digital rewards – supporting contextual financial literacy and the opportunity for all student to earn micro-scholarships.</p>
            </div>
          </div>
        </div>

        <div className="col col2">

          <h1 className="valign-text-middle robotocondensed-bold-white-48px">
            Zaahah Sign up
          </h1>

          <div
            className="overlap-group1"
            style={{ backgroundImage: `url(${overlapGroup1})` }}
          >
            <form className="signup-form">
              <div className="form-container">
                <div className="input-names">
                  <div className="form-parts-3-1">
                    {/*<div className="label robotocondensed-bold-lavender-14px">
                      First Name
                    </div>*/}
                    <div className="frame-5448">
                      <input
                        type="text"
                        className="type-here-the-information roboto-normal-white-16px"
                        value={firstName}
                        placeholder="First Name*"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <span className="roboto-bold-white-16px error-text" />
                  </div>
                  <div className="form-parts-3-1 form-parts-4-1">
                    {/*<div className="label robotocondensed-bold-lavender-14px">
                      Last Name
                    </div>*/}
                    <div className="frame-5448">
                      <input
                        type="text"
                        className="type-here-the-information roboto-normal-white-16px"
                        value={lastName}
                        placeholder="Last Name*"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                    <span className="roboto-bold-white-16px error-text" />
                  </div>
                </div>

                <div className="select">
                  <div className="input-names">
                    <div className="form-parts-3-1">
                      {/*<div className="label robotocondensed-bold-lavender-14px">
                        Email
                      </div>*/}
                      <div className="frame-5448 isDisabled">
                        <input
                          type="email"
                          className="type-here-the-information roboto-normal-white-16px"
                          value={email}
                          placeholder='Email*'
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <span className="roboto-bold-white-16px error-text" />
                    </div>
                    <div className="form-parts-3-1 form-parts-4-1">
                      {/*<div className="label robotocondensed-bold-lavender-14px">
                        Username
                      </div>*/}
                      <div className="frame-5448">
                        <input
                          type="text"
                          className="type-here-the-information roboto-normal-white-16px"
                          placeholder="Username*"
                          onChange={(e) => setUserName(e.target.value)}
                        />
                      </div>
                      <span className="roboto-bold-white-16px error-text" />
                    </div>
                  </div>

                  <div className="Gender-container-signup">
                    <div className="genderLabel robotocondensed-bold-violent-violet-14px">
                      Gender*
                    </div>
                    <div className="frame-5422-signup">
                      {genders.map(({ id: genderKey, label: genderValue }) => (
                        <div className="option-status" onChange={handleChangeRadio}>
                          <label className="content-input">
                            <input
                              type="radio"
                              name="button"
                              value={genderKey}
                              checked={gender === genderKey}
                            />
                            <i></i>
                          </label>
                          <label htmlFor="students" className="roboto-normal-white-14px">{genderValue}</label>
                        </div>
                      ))}
                    </div>
                    <span className="roboto-bold-white-16px error-text" />
                  </div>
                </div>

                <div className="input-names">
                  <div className="form-parts-3-1">
                    {/*<div className="label robotocondensed-bold-lavender-14px">
                      My Birthday
                    </div>*/}
                    <div className="frame-5448 myBirthdayContainer">
                      <FontAwesomeIcon
                        icon="calendar-alt"
                        size='lg'
                        color={WHITE}
                        className="calendar-alt inputIcon  "
                      />
                      <DatePicker
                        dateFormat="MM-dd-yyyy"
                        selected={myBirthday}
                        onChange={(date: Date) => setMyBirthday(date)}
                        className='inputDirthday'
                      />
                    </div>
                    <span className="roboto-bold-white-16px error-text" />
                  </div>
                  <div className="form-parts-3-1 form-parts-4-1">
                    {/*<div className="label robotocondensed-bold-lavender-14px">
                      Password
                  </div>*/}
                    <div className="frame-5448 inputPassword">
                      <input
                        type={isActive ? 'text' : 'password'}
                        className="type-here-the-information roboto-normal-white-16px"
                        placeholder='Password*'
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button
                        onClick={(e) => handleToggle(e)}
                        className="buttonEye"
                        type="button"
                      >
                        <FontAwesomeIcon
                          icon={isActive ? 'eye-slash' : 'eye'}
                          color={LAVENDER}
                          size='lg'
                          className='eye'
                        />
                      </button>
                    </div>
                    <span className="roboto-bold-white-16px error-text" />
                  </div>
                </div>

                {/*<div className="preferedLanguage robotocondensed-bold-lavender-14px">
                  Prefered Language
                </div>*/}

                <div className="preferedLanguageSelector roboto-normal-white-16px">

                  <select className="preferedLanguageSelect roboto-normal-white-16px"
                    onChange={(e) => handleChange(e)}
                  >
                    <option disabled>Prefered Language*</option>
                    <option value="spanish">Spanish</option>
                    <option value="english" selected>English</option>
                  </select>

                  <FontAwesomeIcon
                    icon="chevron-down"
                    size='lg'
                    color={LAVENDER}
                    className="chevron-down  "

                  />

                </div>

                <span className="textTerms">
                  By clicking Send, you agree to our{" "}
                  <a href="https://possibilitycompany.com/terms-of-service/">
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a href="https://possibilitycompany.com/privacy-policy/">
                    Privacy Policy.
                  </a>
                </span>

                {/* <div className="imnotRobot info-field-column">
                    <div className="select-container-status">
                      <div className="option-status">
                        <span className="content-input-white">
                          <input type="checkbox" name="button" value="Active" />
                          <i />
                        </span>
                        <span>I&apos;m not a robot</span>
                      </div>
                    </div>
                  </div>
                  <span className="PrivacyTerms">Privacy Terms</span> */}

                <div className="frame-5355">
                  <button className={`buttonsSend ${loadingCreateUserInvite && 'inProcess'}`} type="button" onClick={handleSubmit}>
                    {loadingCreateUserInvite ?
                      <div className="icon-spinner-third buttonSendSignUp"></div>
                      :
                      'Send'
                    }
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

