import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './styles.scoped.css';
import { useDispatch, useSelector } from "react-redux";

import "../../containers/SetupSchool/styles.scoped.css";
import { useState, useCallback, useEffect } from "react";

import { useDropzone } from "react-dropzone";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createInviteRoster, validateInviteRoster } from '../../store/actions/inviteActions';
import { getClubNoIdeaPending } from '../../store/actions/clubActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { WHITE } from '../ClubIconsNavBar';
import { useTranslation } from "react-i18next";
import usePostalCodes from '../../hooks/usePostalCodes';

toast.configure()
const ACCEPTED_FILES = {
  'text/csv': ['.csv']
}

const modalInviteTitle = (club:any):string=>{
  const { t, i18n } = useTranslation();
  let type = t('list__inviteTypeMember')
  switch (club?.type || ''){
    case 'ZAA#STAFF':
      type = t('list__inviteTypeStaff')
      break
    case 'ZAA#STUDENT':
      type = t('list__inviteTypeStudents')
      break
    case 'ZAA#ALUMNI':
      type = t('list__inviteTypeAlumni')
      break
    case 'ZAA#GUARDIAN':
      type = t('list__inviteTypeGuardians')
      break
    default:
      type = t('list__inviteTypeMember')
      break
  }
  return `${t('list__invite')} ${type}`
}

function Index(props: any) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch()
  const { postalCodes: postalCodesConfig, setCountry: setCountryPostal } = usePostalCodes();

  const [fileDenied, setFileDenied] = useState(false);
  const [checkingFile, setCheckingFile] = useState(false);
  const [fileAccepted, setFileAccepted] = useState(false);
  const [fileRejected, setFileRejected] = useState(false);
  const [notAcceptedMsg, setNotAcceptedMsg] = useState('');
  const [base64file, setBase64file] = useState('');
  const [loadingInvites, setLoadingInvites] = useState(false);
  const [invitedPeople, setInvitedPeople] = useState<number>(0);
  const [sendInactive, setSendInactive] = useState(true);

  const responseValidate = useSelector((state: any) => state.getInvites.validateInviteRoster || {});
  const club = useSelector((state: any) => state.getClub.getClub);
  const organization = useSelector((state: any) => state.getOrgs.organizationInfo);

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryPostal(organization?.countryCode);
    }
  }, [])

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryPostal(organization?.countryCode);
    }
  }, [organization])

  const convertToBase64 = (file: File) => new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject('Error: ' + error);
    };
  });

  useEffect(() => {
    dispatch<any>(getClubNoIdeaPending(props.club_id));
  }, [])

  const onHide = () => {
    setFileDenied(false);
    setCheckingFile(false);
    setFileAccepted(false);
    setFileRejected(false);
    setBase64file('');
    setLoadingInvites(false);
    setSendInactive(true);
    props.onHide();
  }

  function handleOnClickSendMembers(e: any){

    setLoadingInvites(true);

    dispatch<any>(createInviteRoster(base64file, props.club_id, club.org_id))
      .then((response: any) => {
        setLoadingInvites(false);
        if (response === 200) {
          toast.success(t('toast_invitesCreated'));
          setFileDenied(false);
          setCheckingFile(false);
          setFileAccepted(false);
          setFileRejected(false);
          setBase64file('');
          setSendInactive(true);
          props.setInvitesAdded(true);
          props.onHide(e, 'btnSave', props.idCard, props.type);
        } else {
          toast.error(t('toast_errorSendInvites'));
          setLoadingInvites(false);
        }
      })
      .catch((error: any) => {
        console.error('Error creating invites', error)
        toast.error(t('toast_errorSendInvites'));
        setLoadingInvites(false);
      })
  }

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length === 0) {
      setFileDenied(true);
      return;
    }
    setFileDenied(false);
    setCheckingFile(true);
    convertToBase64(acceptedFiles[0])
      .then((bareBase64: string | ArrayBuffer | null) => {
        if (!bareBase64) {
          console.log(t('toast_ThereWasError'))
          return;
        }
        const toSendBase64 = (bareBase64 as string).split('base64,')[1]
        console.log(toSendBase64, acceptedFiles, acceptedFiles[0]);

        if(toSendBase64){
          dispatch<any>(validateInviteRoster(toSendBase64, club?.status?.startsWith("PEND"), postalCodesConfig?.minLength))
          .then((response : any) => {
            if (response === 200) {
              setBase64file(toSendBase64);
              setCheckingFile(false);
              setFileAccepted(true);
              setFileRejected(false);
              setSendInactive(false);
            } else if (response === 400) {
              setCheckingFile(false);
              setFileAccepted(false);
              setFileRejected(true);
              setSendInactive(true);
            }
          })
          .catch(console.error)
        }
        else{
          console.error('File undefined.');
        }
        
      })
  }, [base64file]);

  useEffect(() => {
    setNotAcceptedMsg(responseValidate?.message || '');
    setInvitedPeople(responseValidate?.invites || 0);
  }, [responseValidate])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ACCEPTED_FILES,
  });

  const fileExampleFile = 'data:text/csv;charset=utf-8,' + encodeURIComponent('Email,First name,Last name,Gender,Date of birth,Zip code')

  useEffect(() => {
    setInvitedPeople(0);
  }, [props])

  return (
    <>

      <Modal className="modalInviteMemebers" show={props.show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>{modalInviteTitle(club)}</Modal.Title>
          <FontAwesomeIcon
            icon={faTimes}
            size='xl'
            color={WHITE}
            className="icon-times"
            onClick={onHide}
          />
        </Modal.Header>
        <Modal.Body {...getRootProps()}>
          <p className="text-invites">{t('modalInviteMembers__Upload')}</p>
          <p className="subtext-invites">{t('modalInviteMembers__useFollowing')}</p>
          <p className="subtext-invites">{t('modalInviteMembers__theGender')}</p>
          <p className="subtext-invites">{`${t('modalInviteMembers__theDate')} ${props.type === 'Staff' ? t('modalInviteMembers__optional') : ''}`}</p>
          <p className="subtext-invites">{t('modalInviteMembers__theZipCode').replace('5', String(postalCodesConfig?.minLength || 5))}</p>
          <a href={fileExampleFile} download="rosterExample.csv">{t('modalInviteMembers__fileExmaple')}</a>
          <input className="dropzone-input" {...getInputProps()} />
          <div className="container-drag">
            {isDragActive ? (
              <>
                <p>{t('modalInviteMembers__releaseToDrop')}</p>
              </>
            ) : fileDenied ? (
              <>
                <p>{t('modalInviteMembers__thisFile')}</p> {t('modalInviteMembers__or')} <span>{t('modalInviteMembers__browse')}</span>
              </>
            ) : checkingFile ? (
              <>
                <p>{t('modalInviteMembers__checkingCSV')}</p>
              </>
            ) : fileAccepted ? (
              <>
                <p>{t('modalInviteMembers__yourFile')}</p>
              </>
            ) : fileRejected ? (
              <>
                <p>{t('modalInviteMembers__thisCSV')}</p>
                <p>{notAcceptedMsg}</p>
                <p>{t('modalInviteMembers__dragCSV')}</p> {t('modalInviteMembers__or')} <span>{t('modalInviteMembers__browse')}</span>
              </>
            ) : (
              <>
                <p>{t('modalInviteMembers__dragCSV')}</p> {t('modalInviteMembers__or')} <span>{t('modalInviteMembers__browse')}</span>
              </>
            )}
          </div>
          <div className="" style={{marginTop: '15px'}}>
            {invitedPeople > 0 ? (
              <span className="text-invites">{invitedPeople} {t('modalInviteMembers__people')}</span>
            ) : undefined}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="buttonClose" onClick={onHide}>
            {t('button__cancel')}
          </Button>
          <Button name="btn-sendInviteMember" 
            className={`buttonSend ${sendInactive && 'inProcess'}`} 
            onClick={handleOnClickSendMembers} disabled={sendInactive}>
            {loadingInvites ? (
              <div className="icon-spinner-third buttonSendSpinner"></div>
            ) : t('button__sendInvites') }
          </Button>
        </Modal.Footer>
      </Modal>
    </>


  );
}

export default Index;
