import React, { useEffect, useState } from 'react';
import "../ClubViewInformation/styles.scoped.css";

interface BooleanSelectProps {
  value: boolean;
  setValue: (p: boolean) => void;
  label: string;
};

const BooleanSelect = ({
  value,
  setValue,
  label,
}: BooleanSelectProps) => {
  const [selectValue, setSelectValue] = useState<'yes' | 'no'>('yes');

  useEffect(() => {
    setSelectValue(value ? 'yes' : 'no');
  }, [value]);

  const handleOnChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (!['yes', 'no'].includes(event.target.value)) {
      console.log('error');
      return;
    }

    setSelectValue(event.target.value as 'yes' | 'no');
    setValue(event.target.value === 'yes');
  };

  return (
    <div className="col1">
      <div className="labelTitle">{label}</div>
      <div className='selectInformation'>
        <select
          name={`${label}-select`}
          id=""
          value={selectValue}
          onChange={handleOnChangeSelect}
        >
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </div>
    </div>
  );
};

export default BooleanSelect;