import React, { KeyboardEventHandler, useEffect, useState, useMemo, useRef } from "react";
import CreatableSelect from "react-select/creatable";
import { ActionMeta, OnChangeValue } from "react-select";
import JoditEditor from "jodit-react";
import '../styles.scoped.css';
import ModalInviteMembers from "../ModalInviteMembers";
import { useTranslation } from "react-i18next";
interface Option {
    readonly label: string;
    readonly value: string;
}

function Index(props: {
    comm: any;
    setComm: any;
}) {
    const { t, i18n } = useTranslation();
    const createOption = (label: string) => ({
        label,
        value: label,
    });
    
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState<OnChangeValue<Option, true>>(props.comm.skills_learned?.map((item: any) => createOption(item)) || []);
    const [showInviteMembers, setShowInviteMembers] = useState(false);

    function handleCloseInvite(e: any, step: any, id: any, type: any) {
        setShowInviteMembers(false)
    };
    const handleOnClickInvite = (e: any) => { setShowInviteMembers(true) };

    const components = {
        DropdownIndicator: null,
    };

    const handleChange = (
        value: OnChangeValue<Option, true>,
        actionMeta: ActionMeta<Option>
    ) => {
        setValue(value);
        props.setComm({ ...props.comm, skills_learned: value.map((item) => item.value) })
    };

    const handleInputChange = (inputValue: any) => {
        setInputValue(inputValue);
    };

    const handleOnBlurDescription = (e: any) => {
        props.setComm({...props.comm, description: e})
      };

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setInputValue('');
                if (!value.map((item) => item.value).includes(inputValue)) setValue([...value, createOption(inputValue)]);
                props.setComm({ ...props.comm, skills_learned: [...props.comm.skills_learned || [], inputValue] })
                event.preventDefault();
        }
    };

    const editor = useRef(null)
    const [content, setContent] = useState('')

    return (
        <div className="infoMembers">
            <div className="membersType">
                <h2>{t('setupSchool__description')}</h2>
                <JoditEditor
                    value={props.comm.description}
                    onBlur={handleOnBlurDescription}
                    config={{
                        buttons: ['bold', 'italic', 'underline'],
                        readonly: false,
                        toolbarAdaptive: false,
                        showPlaceholder: false,
                    }}
                />
            </div>
            <div className="membersType">
                <h2>{t('setupSchool__skillsLearningTags')}</h2>
                <CreatableSelect
                    components={components}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder=""
                    value={value}
                    styles={{
                        singleValue: (base) => ({
                            ...base,
                            display: "flex",
                            alignItems: "center",
                            color: 'white',
                            backgroundColor: 'red',
                            fontFamily: 'Roboto Condensed',
                        }),
                        control: (styles) => ({
                            ...styles,
                            backgroundColor: '#2c2c2c',
                            borderRadius: "3px",
                            boxShadow: "none",
                            border: 0,
                            minWidth: "50%"
                        }),
                        option: (styles) => ({
                            ...styles,
                            color: 'white',
                            backgroundColor: 'var(--violent-violet)',
                            display: 'flex',
                            flexDirection: 'row',
                        }),
                        placeholder: (styles) => ({
                            ...styles,
                            color: 'white',
                            textAlign: "left",
                            fontFamily: 'Roboto Condensed',
                        }),
                        container: (styles) => ({
                            ...styles,
                            padding: 0,
                            width: "100%"
                        }),
                        menu: (styles) => ({
                            ...styles,
                            borderRadius: "8px",
                            backgroundColor: "var(--violent-violet)",
                        }),
                        input: (styles: any, state: any) => ({
                            ...styles,
                            border: 'none',
                            color: 'white',
                            fontFamily: 'Roboto Condensed',
                        }),
                        dropdownIndicator: (styles) => ({ ...styles, display: "none" }),
                        indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
                        multiValue: (styles) => ({
                            ...styles,
                            color: 'white',
                            backgroundColor: 'var(--electric-violet-2)',
                        }),
                        multiValueLabel: (styles) => ({
                            ...styles,
                            color: 'white',
                            fontFamily: 'Roboto Condensed',
                            fontSize: '16px'
                        }),
                        multiValueRemove: (styles) => ({
                            ...styles,
                            color: 'white',
                            backgroundColor: "transparent"
                        }),
                    }}
                />
            </div>
            <ModalInviteMembers show={showInviteMembers} onHide={handleCloseInvite} club_id={props.comm.id}/>
            <button onClick={handleOnClickInvite} className="inviteButton">
            {t('setupAdvisor__inviteStudents')}
            </button>
        </div>
    );
}

export default Index;
