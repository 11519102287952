export const cityIdFormat = (locationId : string) => {
    //City id formated to send on roster endpoind to get city name
    const loc = locationId.split("#");
    return loc[0]+'_'+ loc[1]+'_'+ loc[2]+'_'+ loc[3]+'_'+ loc[4]
}

export const countryIdFormat = (locationId : string) => {
    //Country id formated to send on roster endpoind to get city name
    const loc = locationId.split("#");
    return loc[0]+'_'+ loc[1]+'_'+ loc[2]
}