import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { ChatServiceInterface } from '../../interfaces/chat/ChatServiceInterface';
import { LAVENDER, WHITE } from '../ClubIconsNavBar';
import ModalConnections from "../ConnectionSelectBar/ModalConnections";

interface ProfileConnectionProps {
  member: any;
  typeFiter: string;
  openModal: boolean;
  setmember: any;
  handleDots: (p: any, username: string) => void;
  rejectRequest: (p: string) => void;
  acceptFriend: (p: string) => void;
  setOpenModal: any;
  ChatServiceInstance: ChatServiceInterface
}

const ProfileConnection = ({
  member,
  typeFiter,
  openModal,
  setmember,
  handleDots,
  rejectRequest,
  acceptFriend,
  setOpenModal,
  ChatServiceInstance
}: ProfileConnectionProps) => {
  useEffect(() => {
    console.log({ openModal, 'member.username': member.username, 'setmember.username': setmember.username })
  }, [openModal])

  return (
    <div className="cardMember" key={member.username}>
      <img src={member.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" className='round-avatar' />
      <p>{member.fullname || member.firstname + ' ' + member.lastname} </p>

      {(typeFiter !== '0') ? (
        <button>
          <FontAwesomeIcon
            icon="ellipsis-v-alt"
            size='lg'
            color={LAVENDER}
            className="ellipsis-v-alt   cursor"
            onClick={() => handleDots(member, member.username)}
          />
          {(openModal && (member.username === setmember.username)) && (<ModalConnections connection={setmember} filterType={typeFiter} setOpenModal={setOpenModal} isAdvisor={undefined} officers={undefined} advisors={undefined} username={undefined} reloadClub={undefined} ChatServiceInstance={ChatServiceInstance} />)}
        </button>
      )
        :
        (
          <div className="buttonGridContainer">
            <div className="iconOptions buttonSaveFriendRequest" onClick={() => rejectRequest(member.usernameFrom)}>
              <FontAwesomeIcon
                icon="times"
                color={LAVENDER}
                size="lg"
              />
            </div>
            <div className="iconOptions buttonCancelFriendRequest" onClick={() => acceptFriend(member.usernameFrom)}>
              <FontAwesomeIcon
                icon="check"
                color={WHITE}
                size="lg"
              />
            </div>
          </div>
        )}

    </div>
  );
};

export default ProfileConnection;