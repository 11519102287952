import "./styles.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { rejectFriendRequest, saveNewFriend } from "../../store/actions/connectionActions";
import ProfileConnection from "../ProfileConnection/ProfileConnection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER } from "../ClubIconsNavBar";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";

interface ProfileConnectionsProps{
  //todo -> put types
  isUserProfile:any;
  ChatServiceInstance:ChatServiceInterface;
}

function Index(props: ProfileConnectionsProps) {
  const [members, setMembers] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [typeFiter, setTypeFilter] = useState('1');
  const [setmember, setSetMember] = useState<any>('pass');

  const userFriends = useSelector((state: any) => state.getConnection.userFriends || []);
  const userPendingRequest = useSelector((state: any) => state.getConnection.userPendingRequests || []);
  const userConnections = useSelector((state: any) => state.getConnection.userPossibleConnections || []);
  const [valueFilter, setValueFilter] = useState("");

  const { username } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.isUserProfile) {
      setMembers(userPendingRequest);
      setTypeFilter('0');
    }
    else {
      setTypeFilter('1');
      Backend(
        MicroServices.Connections,
        `/get-user-connections?username=${username}`)
        .then((response) => response.json())
        .then((results) => {

          setMembers(results.map((r: any) => ({ ...r, username: r.usernameConnection })));
        })
        .catch(console.error);
    }
  }, [username]);

  const rejectRequest = (usernameFrom: string) => {
    console.log('Request to reject from', usernameFrom);
    dispatch<any>(rejectFriendRequest(usernameFrom, username || ''));
  }

  const acceptFriend = (usernameFrom: string) => {
    console.log('Request to accept from', usernameFrom);
    dispatch<any>(saveNewFriend(username || '', usernameFrom));
  }

  const handleDots = (member: any, username: string) => {
    setTypeFilter('1');
    setSetMember(member);

    //The connection is a friend?
    if (userFriends.find((user: any) => user.friendUsername === username)) {
      setTypeFilter('2');
    }
    //Is a possible connection?
    else if (userConnections.find((user: any) => user.usernameConnection === username)) {
      setTypeFilter('1');
    }
    //The users cannot be friends
    else {
      setTypeFilter('pass');
    }

    setOpenModal(!openModal);
  }

  const handleChangeName = (e: any) => {
    setValueFilter(e);
  }

  return (
    <div className="container-members-club">
      {/* <div className="filterInputSearchEventClub">
        <input
          className="inputSearchEventClub"
          type="text"
          placeholder="Search Members"
        />
      </div> */}
      <div className="membersClubHeader">
        <div className="count-members">
          <div className="containerInputSearch">
            <p>{members.length} {(!props.isUserProfile) ? 'Connections' : 'Friend Requests'}</p>
            <div className="inputSearchM">
              <FontAwesomeIcon
                icon="search"
                size='lg'
                color={LAVENDER}
                className="search  "
              />
              <input type="text" className="inputSearchM" onChange={(e) => handleChangeName(e.target.value)} />
            </div>
          </div>
          {/* <button className="filterButton">
            <FontAwesomeIcon
              icon="filter"
              size='lg'
              color={LAVENDER}
              className="filter  "
            />
          </button> */}
        </div>
      </div>
      <div className="listMembersClub">
        {members.filter((item: any) => {
          if (!valueFilter) return true;
          if (item.fullname.toUpperCase().includes(valueFilter) ||
              item.fullname.toLowerCase().includes(valueFilter) || 
              item.fullname.includes(valueFilter)
          ) {
            return true;
          }
          return false;
        }).map((member: any) => (
          <ProfileConnection
            member={member}
            typeFiter={typeFiter}
            openModal={openModal}
            setmember={setmember}
            handleDots={handleDots}
            rejectRequest={rejectRequest}
            acceptFriend={acceptFriend}
            setOpenModal={setOpenModal}
            ChatServiceInstance={props.ChatServiceInstance}
          />
        ))}
      </div>
    </div>
  );
}

export default Index;
