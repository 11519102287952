import styled, { css } from "styled-components";

export const ContainerSignup = styled.div`
    width: 100%;
`;

export const RobotocondensedBoldLavender16px = css`
    color: var(--lavender);
    font-family: var(--font-family-roboto_condensed);
    font-size: var(--font-size-l2);
    font-style: normal;
    font-weight: 700;
`;
// FORM
export const InputWrapperSignup = styled.div`
    margin-top: 25px;
    background-color: transparent;
    position: relative;
`;

export const InputLabelSignup = styled.label`
    color: var(--violet) !important;
    ${RobotocondensedBoldLavender16px}
    text-align: left;
    width: 100%;
    display: block;
`;

export const InputBase = styled.input`
    outline: none;
`;

export const InputSignup = styled(InputBase)`
    display: block;
    width: 100%;
    height: 35px;
    margin: 5px 0;
    background-color: transparent;
    border: 0;
    color: white;
    border-bottom: 1px solid var(--violet);
    border-radius: 0.3rem;
`;

export const PassEyeBtn = styled.button`
    position: absolute;
    bottom: 5px;
    right: 0;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    & svg {
        color: var(--violet);
    }
`;

// OVERLAP
interface OverlapProps {
    active: boolean;
}

export const Overlap = styled.div`
    position: absolute;
    background-color: #000000bd;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    display: block;
    z-index: -111;
    overflow: hidden;

    ${({ active }: OverlapProps) =>
        active
            ? css`
                  z-index: 111;
                  overflow: auto;
              `
            : ""}
`;

export const HeaderLogin = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: gray;
    position: fixed;
    z-index: 4;
    /* width: 1525px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 125px;
`;

const HeaderLogoImg = styled.img`
    width: 100%;
    height: 45px;
    object-fit: contain;
    padding-left: 20px;
`;

export const HeaderLogo = () => (
    <div>
        <a href="https://possibilitycompany.com/">
            <HeaderLogoImg
                src="/TPC_Logotype.png"
                alt="Possibility Logo"
            />
        </a>
    </div>
);

export const HeaderActionButtons = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 480px) {
        display: none !important;
    }
`;

export const HeaderActions = styled.div`
    display: flex;
    flex-direction: row;
`;

// CONTENT

export const ContentContainer = styled.div`
    background-color: gray;
    /* position: relative; */
    padding-top: 125px;
    display: flex;
`;

const ContentColumn = css`
    width: 50%;
    margin-top: 0px;
`;

export const LeftContainer = styled.div`
    ${ContentColumn}
    height: 1180px;
    background-image: url(https://possibilitycompany.com/wp-content/uploads/2020/02/School-zaaha.jpg);
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0 165px 0 0;
    position: relative;
    @media (max-width: 480px) {
        display: none !important;
    }
`;

export const RightContainer = styled.div`
    ${ContentColumn}
    padding: 0 75px;
    & h1 {
        color: #fff;
        font-family: Poppins,Sans-serif;
        font-size: 52px;
        font-weight: 600;
        line-height: 1.1em;
        text-align: left;
    }
    @media (max-width: 480px) {
        width: 100% !important;
        height: 100%;
        padding: 0 50px 20px 0;

        & .login-button-GoCircle {
            font-size: 12px !important;
        }
    }
`;