import { useEffect, useState } from "react";
import NumericInput from 'react-numeric-input';
import useGender from "../../../hooks/useGender";
import useGrades from "../../../hooks/useGrades";
import ModalMembersRegistered from "../../ClubMembers/ModalMembersRegistered";
import ModalInviteMembers from '../ModalInviteMembers';
import '../styles.scoped.css';
import { useTranslation } from "react-i18next";

function Index(props: {
    club: any;
    setClub: any;
    organization?: any;
}) {
    const { t, i18n } = useTranslation();
    const [showInviteMembers, setShowInviteMembers] = useState(false);

    const { genders, setCountry: setCountryGenders } = useGender();
    const { grades, setCountry: setCountryGrades } = useGrades();

    useEffect(() => {
        if (props.organization && props.organization?.countryCode) {
            setCountryGenders(props.organization.countryCode);
            setCountryGrades(props.organization.countryCode);
        }
    }, [])

    useEffect(() => {
        if (props.organization && props.organization?.countryCode) {
            setCountryGenders(props.organization.countryCode);
            setCountryGrades(props.organization.countryCode);
        }
    }, [props.organization])

    function handleCloseInvite(e: any, step: any, id: any, type: any) {
        setShowInviteMembers(false)
    };
    const handleOnClickInvite = (e: any) => { setShowInviteMembers(true) };

    //Handlers
    const handleCheckMemberType = (event: any) => {
        let updateList = [...props.club.member_type];
        if (event.target.checked) {
            updateList = [...props.club.member_type, event.target.value];
        } else {
            updateList.splice(props.club.member_type.indexOf(event.target.value), 1);
        }
        props.setClub({ ...props.club, member_type: updateList });
    }

    const handleOnChangeGenders = (event: any) => {
        let updateList = [...props.club.gendersAllowed];
        if (event.target.checked) {
            updateList = [...props.club.gendersAllowed, event.target.value];
        } else {
            updateList.splice(props.club.gendersAllowed.indexOf(event.target.value), 1);
        }
        props.setClub({ ...props.club, gendersAllowed: updateList });
    }

    const handleOnChangeNumberMembers = (value: number | null) => {
        props.setClub({ ...props.club, maximumNumberMembers: value || 0 });
    }

    const handleCheckGradesAllowed = (event: any) => {
        let updateList = [...props.club.gradesAllowed];
        if (event.target.checked) {
            updateList = [...props.club.gradesAllowed, event.target.value];
        } else {
            updateList.splice(props.club.gradesAllowed.indexOf(event.target.value), 1);
        }
        props.setClub({ ...props.club, gradesAllowed: updateList });
    }

    return (
        <div className="infoMembers">
            <div className="selectAffiliated">
                <label htmlFor="">{t('setupSchool__selectAffiliated')}</label>
                <div className="affiliated">
                    <select name="" id="">
                        <option value="">Independent club</option>
                    </select>
                </div>
            </div>
            <div className="membersType">
                <h2>{t('setupSchool__membersType')}</h2>
                <span className="subtitle">{t('setupSchool__youCanMark')}</span>
                <div className="containers-input">
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input
                                type="checkbox"
                                name="checkbox-STUDENT"
                                value="STUDENT"
                                onChange={handleCheckMemberType}
                                checked={props.club.member_type.includes('STUDENT')}
                            />
                            <i></i>
                        </label>
                        <label htmlFor="students">{t('setupSchool__student')}</label>
                    </div>
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input
                                type="checkbox"
                                name="checkbox-STAFF"
                                value="STAFF"
                                onChange={handleCheckMemberType}
                                checked={props.club.member_type.includes('STAFF')}
                            />
                            <i></i>
                        </label>
                        <label htmlFor="students">{t('setupSchool__staff')}</label>
                    </div>
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input
                                type="checkbox"
                                name="checkbox-ALUMNI"
                                value="ALUMNI"
                                onChange={handleCheckMemberType}
                                checked={props.club.member_type.includes('ALUMNI')}
                            />
                            <i></i>
                        </label>
                        <label htmlFor="students">{t('setupSchool__alumni')}</label>
                    </div>
                    <div className="option-chechbox">
                        <label className="content-input">
                            <input
                                type="checkbox"
                                name="checkbox-GUARDIAN"
                                value="GUARDIAN"
                                onChange={handleCheckMemberType}
                                checked={props.club.member_type.includes('GUARDIAN')}
                            />
                            <i></i>
                        </label>
                        <label htmlFor="students">{t('setupSchool__guardians')}</label>
                    </div>
                </div>
            </div>
            <div className="maximunMembers">
                <h2>{t('setupSchool__maximumNumber')}</h2>
                <NumericInput
                    className="form-control"
                    min={0}
                    value={props.club.maximumNumberMembers !== 0 ? props.club.maximumNumberMembers : undefined}
                    onChange={handleOnChangeNumberMembers}
                    style={{
                        input: {
                            marginRight: 3,
                            paddingRight: 4,
                            textAlign: 'left',
                            height: 54
                        },
                        btnUp: {
                            display: 'block',
                            width: '60px',
                            height: '25px',
                            boxShadow: 'none',
                            top: 1,
                            right: 0,
                            color: 'white'
                        },
                        btnDown: {
                            display: 'block',
                            width: '60px',
                            height: '25px',
                            boxShadow: 'none',
                            top: 28,
                            right: 0,
                            color: 'white'
                        },
                        'input:focus': {
                            border: '1px inset #69C',
                            outline: 'none'
                        },
                        arrowUp: {
                            borderBottomColor: 'white',
                            marginBottom: 1,
                            display: 'none'
                        },
                        arrowDown: {
                            borderTopColor: 'white',
                            marginTop: 1,
                            display: 'none'
                        }
                    }} />
            </div>
            <div className="genders">
                <h2>{t('setupSchool__gender')}</h2>
                <div className="containers-input align-left">
                    {genders.map(({id: genderKey, label: genderLabel}) => (
                        <div className="option-chechbox">
                            <label className="content-input">
                                <input
                                    type="checkbox"
                                    name={`checkbox-${genderKey}`}
                                    value={genderKey}
                                    checked={props.club.gendersAllowed.includes(genderKey)}
                                    onChange={handleOnChangeGenders}
                                />
                                <i></i>
                            </label>
                            <label htmlFor="students">{genderLabel}</label>
                        </div>
                    ))}
                </div>
            </div>
            <div className="gradesClub">
                <h2>{t('setupSchool__grades')}</h2>
                <span className="subtitle">{t('setupSchool__youCanMark')}</span>
                <div className="containers-input">
                    {props.organization && Array.from(Array(1 + props.organization.highGrade - props.organization.lowGrade)).map((_, idx) => (
                        <div className="option-chechbox">
                            <label className="content-input">
                                <input
                                    type="checkbox"
                                    name={`checkbox-${idx + props.organization.lowGrade}`}
                                    value={idx + props.organization.lowGrade}
                                    checked={props.club.gradesAllowed.includes(`${idx + props.organization.lowGrade}`)}
                                    onChange={handleCheckGradesAllowed}
                                />
                                <i></i>
                            </label>
                            <label htmlFor="students">{grades.find((grade) => grade.id === idx + props.organization.lowGrade)?.listLabel || (idx + props.organization.lowGrade)}</label>
                        </div>
                    ))}
                </div>
            </div>
            <ModalMembersRegistered show={showInviteMembers} onHide={handleCloseInvite} club_id={props.club.id} />
            {/*<ModalInviteMembers show={showInviteMembers} onHide={handleCloseInvite} club_id={props.club.id}/>*/}
            <button onClick={handleOnClickInvite} className="inviteButton">
                {t('options__inviteMembers')}
            </button>
        </div>
    );
}

export default Index;
