import "../../styles.scoped.css";
import '../../styles.css';
import { capitalize } from "../../../../helpers/letterHelper";
import { useSelector } from "react-redux";
import useGrades from "../../../../hooks/useGrades";
import SelectItem from "../../../../interfaces/SelectItem";
import { useEffect, useState } from "react";

function CardSchool(props: any) {

    const { school } = props;

    const organization = useSelector(
        (state: any) => state.getOrgs.organizationInfo
    );

    const { grades, setCountry: setCountryGrades } = useGrades();
    
    useEffect(() => {
      if (organization && organization?.countryCode) {
        setCountryGrades(organization?.countryCode);
      }
    }, [])
  
    useEffect(() => {
      if (organization && organization?.countryCode) {
        setCountryGrades(organization?.countryCode);
      }
    }, [organization])

    const administratorData =() => {
        if(school.orgadmin && school.orgadmin.firstname !== ''){
            return `${school.orgadmin.firstname} ${school.orgadmin.lastname}`
        }

        if(school.principal && school.principal.name !== ''){
            return school.principal.name
        }

        if(school.primaryContact && school.primaryContact.name !== ''){
            return school.primaryContact.name
        }

        return school.created_by || '';
    }

    return (
        <>
            <div className="createSchool-card" id={school.id || ''}>
                <div className="card-content">
                    <h1 className="school-name-card">
                        {school.name || ''}
                    </h1>
                    <h2 className="school-type-card">
                        {capitalize(school.type || '')}
                    </h2>
                    <p className="school-grades-card">
                        {`${grades.find((grade) => grade.id === school.lowGrade)?.listLabel || ''} to ${grades.find((grade) => grade.id === school.highGrade)?.listLabel || ''}`}
                    </p>
                    <p className="text-ellipsis">
                        {administratorData()}
                    </p>
                </div>
            </div>

        </>

    );
}

export default CardSchool;
