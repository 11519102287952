import React, { useEffect } from "react";
import { Team } from "../ModalTeam";
import "../../../containers/SetupSchool/styles.scoped.css";
import TeamSelect, { Grade } from "./TeamSelect";
import { SelectedTeams } from "../../../containers/SetupSchool";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";

interface TeamsSelectProps {
  teams: Team[];
  setTeams: (p: Team[]) => void;
  selectedTeams: SelectedTeams;
  setSelectedTeams:(e: any) => void,
  modalType: 'Edit' | 'Create' | 'SaveEdit';
  dynamoTeams:any[],
  setTeamsEdit:(e: any) => void,
  teamsEdit:{[key:string]: any[]},
  teamsDelete:string[],
  setTeamsDelete:(e: any) => void,
  grades: Grade[],
}

const TeamsSelect = ({ 
  teams, 
  setTeams,
  selectedTeams,
  setSelectedTeams,
  modalType,
  dynamoTeams,
  setTeamsEdit,
  teamsEdit,
  teamsDelete,
  setTeamsDelete,
  grades,
 }: TeamsSelectProps) => {
  const setTeam = (id: string, changes: Partial<Team>) => {
    const teamsCopy = [...teams];
    const index = teamsCopy.findIndex((team) => team.id === id);
    teamsCopy[index] = {
      ...teamsCopy[index],
      ...changes,
    };
    setTeams(teamsCopy);
  };

  return (
    <div className="container-team">
      {teams.map((team) => (
        <TeamSelect
          team={team}
          setTeam={(changes: Partial<Team>) => setTeam(team.id, changes)}
          key={team.id}
          selectedTeams={selectedTeams}
          setSelectedTeams={setSelectedTeams}
          modalType={modalType}
          dynamoTeams={dynamoTeams}
          setTeamsEdit={setTeamsEdit}
          teamsEdit={teamsEdit}
          teamsDelete={teamsDelete}
          setTeamsDelete={setTeamsDelete}
          grades={grades}
        />
      ))}
    </div>
  );
};
export default TeamsSelect;
