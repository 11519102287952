import { Backend, MicroServices } from "./backendHelper";
import { capitalize } from "./letterHelper";

export const inviteRoleZAA = (role: string) => {
    if (role === ' ' || role === '' || role === undefined) {
        return ''
    }

    const array = role.split('#');
    if (array.length > 2) {
        return capitalize(array[1]) + ' ' + capitalize(array[2])
    }
    else if (array.length === 1) {
        return ''
    }
    else {
        return capitalize(array[1]);
    }
};

export const rolesArray = (roles: any) => {
    const result: any = [];
    try {
        roles.forEach((role: string) => {
            result.push(inviteRoleZAA(role))
        });
    } catch (error) {
        console.error
    }
    return result;
};

export const onlyZAAroles = (roles: any) => {
    const result: any = [];
    try {
        roles.forEach((role: string) => {
            if (role.startsWith('ZAA')) {
                result.push([role, inviteRoleZAA(role)]);
            }
        });
    } catch (error) {
        console.error
    }
    return result;
};

export const onlyZAArolesMain = (roles: any) => {
    const result: any = [];
    try {
        roles.forEach((role: string) => {
            if (role.startsWith('ZAA') && (role !== 'ZAA#SECOND#ADVISOR') && (role !== 'ZAA#STAFF#ADVISOR') && (role !== 'ZAA#SUPER')) {
                result.push([role, inviteRoleZAA(role)]);
            }
        });
    } catch (error) {
        console.error
    }
    console.log('User Roles', result);
    return result;
};

export const getUserRoles = (email: string, org_id: string) => new Promise<any>((resolve, reject) => {
    Backend(
        MicroServices.Admin,
        `/users?email=${email}&org=${org_id}`)
        .then((response) => response.json())
        .then((results) => {

            if(results.users.length > 0) {
                resolve(results.users[0].roles);
            }
            else{
                resolve([])
            }
        })
        .catch((error) => {
            console.log('Error getting user roles', error)
            reject(error);
        });
})