import "./styles.css";
import { useNavigate } from "react-router-dom";
import { capitalize } from "../../helpers/letterHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GRAY, LAVENDER, ZERO } from "../ClubIconsNavBar";

const ORGSTATUS: {[key: string]: string} = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  PENDING_APPROVAL: "Pending approval",
  PENDING_CONFIRMATION: "Pending confirmation",
  DELETED: "Inactive"
}

const Index = ({organizations, handleClickId}: any) => {

    const navigate = useNavigate();
    
    return (
    <tr className="rowOrganization robotocondensed-normal-white-16px">
      <td>
        <div className="idField">
          {organizations.id}
        </div>  
      </td>
      <td>
        <p className="nameField">
          {organizations.name}
        </p>
      </td>
      <td>
        <div className="typeField">
          {capitalize(organizations.type)}
        </div>
      </td>
      <td>
        <div className="cityField">
          {organizations.city}
        </div>
      </td>
      <td>
        <div className="countryField">
          {organizations.country}
        </div>
      </td>
      <td>
        <div className={"statusField status-"+organizations.status || ''}>
          {!!ORGSTATUS[((organizations.status || '') + '') || ''] && (
            <>
              <FontAwesomeIcon
                icon={["fas", "circle"]}
                color={ZERO}
                className="place valign-text-middle"
                size="xs"
              />
              <div className="status-field robotocondensed-normal-absolute-16px">
                {ORGSTATUS[((organizations.status || '') + '') || '']}
              </div>
            </>
          )}
        </div>
      </td>
      <td>
        <div className="typeField">
          {organizations.setupComplete ? 'Completed' : 'Not completed'}
        </div>
      </td>
      <td>
        <div className="actionsButtons">
          <FontAwesomeIcon
            icon="eye"
            size='lg'
            color={LAVENDER}
            className="org-view  "
            onClick={() => navigate(`./${organizations.id}/view`)}
          />
          <FontAwesomeIcon
            icon="pen"
            size='lg'
            color={LAVENDER}
            className="org-update  "
            onClick={() => navigate(`./${organizations.id}/update`)}
          />
          <FontAwesomeIcon 
            icon="trash-alt"
            className="trash-alt"
            size="lg"
            color={
              organizations.status !== "DELETED" ? LAVENDER : GRAY
            }
            onClick={organizations.status !== "DELETED" ? ()=>handleClickId(organizations.id) : ()=>{}}
          />
        </div>
      </td>
    </tr>
    )
}

export default Index;