import "../styles.scoped.css";
import '../styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const WHITE = '#FFFFFF';

function AddCardCommunity(props: any) {

  const { onAddCommunitiesClick } = props;

  return (
    <>
      <div className={`card card-active add-own-comm`}>
        <div className="create-comm-card" onClick={() => { }}>
          {/* <span>Please add a community to your organization</span> */}
          <FontAwesomeIcon
            icon="plus"
            size='lg'
            color={WHITE}
            onClick={onAddCommunitiesClick}
          />
          {/* <span>You must create a staff community before progressing</span> */}
        </div>
      </div>
    </>
  );
}

export default AddCardCommunity;
