import "../../styles.scoped.css";
import '../../styles.css';
import { Dispatch, SetStateAction } from "react";
import { capitalize } from "../../../../helpers/letterHelper";
import TClub from "../../../../interfaces/TClubs/TClub/TClub";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../../../ClubIconsNavBar";

function CardClub(props: {
    club: TClub,
    setTClubToEdit: Dispatch<SetStateAction<TClub | undefined>>,
    setTClubToDelete: Dispatch<SetStateAction<TClub | undefined>>
}) {

    const { club, setTClubToEdit, setTClubToDelete } = props;

    return (
        <>
            {/* id={club.id || ''}*/}
            <div className="createSchool-card">
                <FontAwesomeIcon
                    style={{ cursor: 'pointer' }}
                    icon={faTimes}
                    size='xl'
                    color={WHITE}
                    onClick={() => setTClubToDelete(club)}
                />
                <div className="card-content" onClick={() => setTClubToEdit(club)}>
                    <h1 className="school-name-card">
                        {club.name || ''}
                    </h1>
                    <h2 className="school-type-card">
                        {capitalize(club.club_type || '')}
                    </h2>
                    <p className="school-grades-card">
                        {club.description || ''}
                    </p>
                    <p className="text-ellipsis">
                        {(club.member_types || []).map((m: string) => capitalize(m)).join(', ')}
                    </p>
                </div>
            </div>

        </>

    );
}

export default CardClub;
