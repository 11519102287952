import React, {
  KeyboardEventHandler,
  useState,
  useRef,
  useEffect,
} from "react";
import "./styles.css";
import "./styles.scoped.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../store/actions/userActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from "../../components/ClubIconsNavBar";
import { Backend, MicroServices } from "../../helpers/backendHelper";

interface Option {
  readonly label: string;
  readonly value: string;
}

function Index(props: any) {
  const { username } = useParams();
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const listClubs = useSelector((state: any) => state.getClub.getMyClubs);
  const userAll = useSelector((state: any) => state.getUser.user);
  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  );

  const [clubUp, setClubUp] = useState([]);
  const [clubDown, setClubDown] = useState([]);
  const [user, setUser] = useState(userAll);

  const dispatch = useDispatch();

  const clubFilter = (clubs: any) => {
    if (props.isUserProfile) {
      const following = clubs.filter(
        (club: any) => club.role === "FOLLOWER" || club.role === "FAN"
      );
      const myClubs = clubs.filter(
        (club: any) => !(club.role === "FOLLOWER" || club.role === "FAN")
      );
      setClubUp(myClubs);
      setClubDown(following);
    } else {
      Backend(
        MicroServices.Roster,
        `/users/common-clubs?username1=${username}&username2=${
          userInfo.username
        }`
      )
        .then((response) => response.json())
        .then((results) => {
          setClubUp(results);
          const ownClubs = clubs.filter(
            (club: any) => !results.some((cb: any) => cb.id === club.club_id)
          );
          setClubDown(ownClubs);
        })
        .catch(console.error);
    }
  };

  useEffect(() => {
    if (props.isUserProfile && !userAll) {
      clubFilter(listClubs);
      dispatch<any>(getUser(userInfo.username)).then((results: any) =>
        setUser(results)
      );
    } else {
      Backend(
        MicroServices.Admin,
        `/users/${username}?roleRequest=OA`)
        .then((response) => response.json())
        .then((results) => {
          setUser(results);
        })
        .catch(console.error);

      Backend(
        MicroServices.Roster,
        `/users/user-clubs?username=${username}`)
        .then((response) => response.json())
        .then((results) => {
          clubFilter(results);
        })
        .catch(console.error);
    }
  }, [username]);

  useEffect(() => {
    console.log("Club Up", clubUp);
    console.log("Club Down", clubDown);
  }, [clubUp, clubDown]);

  return (
    <>
      {/*<div className="infoProfile">
        <div className="imageProfile">
          <img
            src={
              user.avatar
                ? user.avatar
                : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
            }
            alt=""
          />
        </div>
        <div>
          <p className="nameProfile">
            {user.firstname} {user.lastname}
          </p>
          <ul>
            {user.organizationsNames.map((request: any) => (
              <li className="listOrgs">{request}</li>
            ))}
          </ul>
        </div>
      </div>*/}

      <div className="containerT">
        <h2 className="titleContainer">
          {props.isUserProfile ? "My Clubs" : "Common Clubs"} {clubUp.length}
        </h2>
      </div>
      <div className="listClubTeams">
        {clubUp.map((request: any) => (
          <div className="cardClub">
            <div className="headerCard">
              <div className="containerTitle">
                <p className="titleClub">{request.name}</p>
                {/*<span className="countMembers">
                  {request.membersCount || request.members || 0} Members
                </span>*/}
              </div>
              <img
                src={
                  request.logo ||
                  organization.logo ||
                  "https://htmlcolorcodes.com/assets/images/colors/dark-gray-color-solid-background-1920x1080.png"
                }
                alt=""
              />
            </div>
            <div className="countIcon">
              <div className="counts">
                <FontAwesomeIcon
                  icon="list-alt"
                  size="lg"
                  color={WHITE}
                  className="cIcon  "
                />
                <span className="roboto-normal-white-16px">
                  {request.postCount || request.posts || 0}
                </span>
              </div>
              <div className="counts">
                <FontAwesomeIcon
                  icon="calendar-alt"
                  size="lg"
                  color={WHITE}
                  className="cIcon calendar"
                />
                <span className="roboto-normal-white-16px">
                  {request.eventsCount || request.events || 0}
                </span>
              </div>
              <div className="counts">
                <FontAwesomeIcon
                  icon="user-group"
                  size="lg"
                  color={WHITE}
                  className="cIcon  "
                />
                <span className="roboto-normal-white-16px">
                  {request.membersCount || request.members || 0}
                </span>
              </div>
            </div>
            {/*<div className='containerButton'>
              {request.isRequest ?
                <>
                  <button className='btnWhite btnCancelRequest'>Cancel request</button>
                </>
                :
                <div className='tButtons'>
                  <button className='btnWhite btnFollow'>{request.type === 'TEAM' ? 'Fan' : 'Follow'}</button>
                  <button className='btnPurple btnFollow'>{request.type === 'TEAM' ? 'Tryout' : 'Join'}</button>
                </div>
              }
            </div>*/}
          </div>
        ))}
      </div>
      <div className="containerT">
        <h2 className="titleContainer">
          {props.isUserProfile ? "Following" : `${user.firstname}'s Clubs`}{" "}
          {clubDown.length}
        </h2>
      </div>
      <div className="listClubTeams">
        {clubDown.map((request: any) => (
          <div className="cardClub">
            <div className="headerCard">
              <div className="containerTitle">
                <p className="titleClub">{request.name}</p>
                {/*<span className="countMembers">
                  {request.membersCount || request.members || 0} Members
                </span>*/}
              </div>
              <img
                src={
                  request.logo ||
                  organization.logo ||
                  "https://htmlcolorcodes.com/assets/images/colors/dark-gray-color-solid-background-1920x1080.png"
                }
                alt=""
              />
            </div>
            <div className="countIcon">
              <div className="counts">
                <FontAwesomeIcon
                  icon="list-alt"
                  size="lg"
                  color={WHITE}
                  className="cIcon  "
                />
                <span className="roboto-normal-white-16px">
                  {request.postCount || request.posts || 0}
                </span>
              </div>
              <div className="counts">
                <FontAwesomeIcon
                  icon="calendar-alt"
                  size="lg"
                  color={WHITE}
                  className="cIcon calendar"
                />
                <span className="roboto-normal-white-16px">
                  {request.eventsCount || request.events || 0}
                </span>
              </div>
              <div className="counts">
                <FontAwesomeIcon
                  icon="user-group"
                  size="lg"
                  color={WHITE}
                  className="cIcon  "
                />
                <span className="roboto-normal-white-16px">
                  {request.membersCount || request.members || 0}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Index;
