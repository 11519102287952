import "./styles.scoped.css";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../ClubIconsNavBar";

interface RemoveStudentProps {
    show: boolean;
    setShowModal: any;
    username: string;
    name: string;
    reloadClub: any;
}

function Index(props: RemoveStudentProps) {
    const { t, i18n } = useTranslation();
    const organization = useSelector(
        (state: any) => state.getOrgs.organizationInfo
    );

    const [reason, setReason] = useState('');
    const { id } = useParams();

    function handleOnClickCancel() {
        setReason('');
        props.setShowModal(false)
    }

    function handleOnClickRemove() {
        if (reason.trim() === '') {
            toast.error(t('toast_addReason'))
            return;
        }
        removeUserFromClass(reason)
        handleOnClickCancel();
    }

    const removeUserFromClass = (reason: string) => {
        Backend(
            MicroServices.Admin,
            `/users/${props.username}/remove-class?org_id=${organization.id}&reason=${reason}`,
            {
                method: 'DELETE',
            }
        )
            .then((response) => {
                if (response.status === 201) {
                    toast.success(t('toast_studentRemoved'))
                    props.reloadClub()
                }
                else {
                    toast.error(t('toast_ThereWasError'))
                }
            })
            .catch((error) => {
                toast.error(t('toast_ThereWasError'))
                console.log('Error removing user:', error)
            })
    };

    return (
        <>
            <Modal className="modalRepostPost" show={props.show} onHide={handleOnClickCancel}>
                <Modal.Header>
                    <h2 className="modal-title">{`${t('title_removeStudent')} ${props.name}`}</h2>
                </Modal.Header>
                <Modal.Body>
                    <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size='2xl'
                        color={WHITE}
                    />
                    <div className="textReport">
                        {t('message_removeStudent')}
                    </div>
                    <div className='emailOfficerAdd'>
                        {t('text_reasonRemove')}
                        <div className='inputContainerOfficer'>
                            <input type="text" value={reason} onChange={(e) => setReason(e.target.value)} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="buttonClose" onClick={() => handleOnClickCancel()}>
                        {t('button__cancel')}
                    </Button>
                    <Button className="buttonSend" onClick={() => handleOnClickRemove()}>
                        {t('button__delete')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Index;