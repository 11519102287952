import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Modal from "react-bootstrap/Modal";
import "../../containers/SetupSchool/styles.scoped.css";
import { WHITE } from "../ClubIconsNavBar";

interface ModalHeaderProps {
  title: string;
  hideModal: () => void;
}

const ModalHeader = ({ title, hideModal }: ModalHeaderProps) => (
  <Modal.Header>
    <Modal.Title>{title}</Modal.Title>
    <FontAwesomeIcon
  icon="times"
  size='xl'
  color={WHITE}
  className="icon-times  "
  onClick={hideModal}
/>
  </Modal.Header>
);

export default ModalHeader;
