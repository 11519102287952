import OutsideClickHandler from "react-outside-click-handler";
import Select from "react-select";
import "./styles.scoped.css";
import SchoolDatabase from "../SchoolDatabase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER } from "../ClubIconsNavBar";

const Index = () => {

  return (
    <>
      <table className="tableSchools robotocondensed-bold-white-16px">
        <tr>
          <th>
            <div>
              ID
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "

              />
            </div>
          </th>
          <th>
            <div>
              Name
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "

              />
            </div>
          </th>
          <th>
            <div>
              Address
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "

              />
            </div>
          </th>
          <th>
            <div>
              City
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "

              />
            </div>
          </th>
          <th>
            <div>
              State
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "

              />
            </div>
          </th>
          <th>
            <div>
              Zip
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "

              />
            </div>
          </th>
          <th>
            <div>
              Country
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "

              />
            </div>
          </th>
          <th className="btnActions">Actions</th>
        </tr>
        <SchoolDatabase />
      </table>
    </>
  );
};

export default Index;
