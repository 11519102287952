import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { WHITE, LAVENDER } from "../ClubIconsNavBar";

function Index(props: any) {

  function handleOnClickOk(e: any, type: any){
    props.onHide(e, type)
  }
  
  return (
    <>
        <Modal className="modalSaveEvent" show={props.show} onHide={props.onHide}>
            <Modal.Header>
                <Modal.Title></Modal.Title>
                <FontAwesomeIcon
                    icon={faTimes}
                    size='xl'
                    color={WHITE}
                    className="icon-times"
                    onClick={(e) => handleOnClickOk(e, 'modal')}
                />
            </Modal.Header>
            <Modal.Body>
                <FontAwesomeIcon
  icon="check"
  size='lg'
  color={LAVENDER}
  className="chevron-down iconCheckOk  "
/>
                <span className="labelEventCreated"> {props.title} </span>
            </Modal.Body>
            <Modal.Footer>
                <Button className="buttonSendEvent" onClick={(e) => handleOnClickOk(e, 'delete')}>
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  );
}

export default Index;