import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser, reloadSession, reloadUserRoles } from '../store/actions/userActions';
import { RootState, useAppDispatch } from '../store/store';
import { useTranslation } from "react-i18next";
import LoginWrp from '../containers/LoginWrp';
import SignupWrp from '../containers/SignupWrp';
import SignUpStudent from '../containers/SignUpStudent';
import NotFound from '../containers/NotFound';
import ForgotPasswordWrp from '../containers/ForgotPasswordWrp';
import RestorePasswordWrp from '../containers/RestorePasswordWrp';
import SetupSchool from '../containers/SetupSchool';
import SetupAdvisor from '../containers/SetupAdvisor';
import SetupStudent from '../containers/SetupStudent';
import Organizations from '../components/Organizations';
import Users from '../components/Users';
import OrganizationsView from '../components/OrganizationsView';
import OrganizationsUpdate from '../components/OrganizationsUpdate';
import UserView from '../components/UserView';
import UserUpdate from '../components/UserUpdate';
import AdminUserView from '../components/AdminUserView';
import AdminUserUpdate from '../components/AdminUserUpdate';
import AdminUsers from '../components/AdminUsers';
import ClubsManageClubsAdmin from '../components/ClubsManageClubsAdmin';
import SuperAdminLayout from '../containers/SuperAdminLayout';
import AdminLayout from '../containers/AdminLayout';
import Dashboard from '../components/Dashboard';
import AdminInvites from '../components/AdminInvites';
import TemplateClubs from '../components/TemplateClubs';
import ViewInvitesAdmin from '../components/ViewInvitesAdmin';
import TemplateCategories from '../components/TemplateCategories';
import CreateCategory from '../components/CreateCategory';
import ViewCategory from '../components/ViewCategory';
import UpdateCategory from '../components/UpdateCategory';
import CreateTemplateClub from '../components/CreateTemplateClub';
import ViewTemplateClub from '../components/ViewTemplateClub';
import UpdateTemplateClub from '../components/UpdateTemplateClub';
import MySchool from '../components/MySchool';
import MySchoolUpdate from '../components/MySchoolUpdate';
import Club from '../containers/Club';
import Event from '../containers/Event';
import Bulletin from '../containers/Bulletin';
import PostDetail from '../containers/PostDetail';
import AppLayout from '../containers/AppLayout';
import SignUpCode from '../containers/SignUpCode';
import SignUpUserGeneral from '../containers/SignUpUserGeneral';
import Profile from '../components/Profile';
import SetupStaff from '../containers/SetupStaff';
import Loading from '../containers/Loading';
import Flagged from '../components/Flagged';
import SetupGuardian from '../containers/SetupGuardian';
import MyAccount from '../containers/MyAccount';
import SchoolDistricts from '../components/SchoolDistricts';
import SchoolDatabases from '../components/SchoolDatabases';
import AdminCreateUser from '../containers/AdminCreateUser';
import YoungOrganizations from '../components/YoungOrganizations';

import { getMyEventsComplete } from '../store/actions/eventActions';
import EventAdmin from '../components/EventAdmin';
import SetupYouth from '../containers/SetupYouth';
import MySchoolsYouth from '../components/MySchoolsYouth';

import jwt_decode from "jwt-decode";
import { refreshTokens } from '../store/actions/tokenActions';
import { getEventTypes } from '../store/actions/ahaActions';
import TClubs from '../containers/TClubs';
import TCListDetail from '../containers/TClubs/TCLists/Detail';
import CreateTClub from '../containers/TClubs/TClub/Create';
import YOTable from '../containers/YOTable';
import CountryTable from '../containers/CountryTable';
import UpdateTClub from '../containers/TClubs/TClub/Update';
import CreateYO from '../containers/CreateYO';
import Interests from '../containers/Interests';
import IntListDetail from '../containers/Interests/IntList/Detail';
import Chat from '../containers/Chat/Chat';
import NoChatSelected from '../components/chat/ChatCenter/NoChatSelected';
import CreateChatGroup from '../components/chat/ChatCenter/GroupChat/CreateChatGroup';
import ChatUser from '../components/chat/ChatCenter/components/ChatUser';
import ChatService from '../hooks/Chat/ChatService';
import LangTable from '../containers/LangTable';

function RootStackComponent() {

  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  //Chat Hook
  const ChatServiceInstance = ChatService();

  //States to control where to go when logging in
  const [showSetupSchool, setShowSetupSchool] = useState<boolean | undefined>(undefined);
  const [showSetupUser, setShowSetupUser] = useState<boolean | undefined>(undefined);
  const [goBulletin, setGoBulletin] = useState<boolean | undefined>(undefined);
  const [postsOk, setPostsOk] = useState<boolean | undefined>(undefined);
  const [eventsOk, setEventsOk] = useState<boolean | undefined>(undefined);
  const [loadingMsj, setLoadingMsj] = useState<string>(t('text__loading'));

  //LOGIN_SUCCESS Selectors
  const isLoggedIn = useSelector((state: RootState | any) => state.getUser.isLoggedIn);
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const userRoles = useSelector((state: any) => state.getUser.userRoles);
  const organization = useSelector((state: RootState | any) => state.getOrgs.organizationInfo);
  const token = useSelector((state: RootState) => state.getToken.IDToken)

  //Selectors from dispatchs run here!
  const user = useSelector((state: any) => state.getUser.user);
  const events = useSelector((state: any) => state.getEvent.getCompleteEvents)

  const everythingIsLoaded = (): boolean => (
    showSetupSchool !== undefined &&
    showSetupUser !== undefined &&
    goBulletin !== undefined &&
    postsOk !== undefined &&
    eventsOk !== undefined &&
    isLoggedIn !== undefined &&
    userInfo !== undefined &&
    userRoles !== undefined &&
    organization !== undefined &&
    user !== undefined &&
    events !== undefined
  )

  const setupInfoLoaded = (): boolean => (
    showSetupSchool !== undefined &&
    showSetupUser !== undefined &&
    userRoles !== undefined
  )

  useEffect(() => {
    //Execute LOGIN_SUCCESS related dispatch
    dispatch<any>(reloadSession());
  }, []);

  useEffect(() => {
    if (user && user.username !== '' && !ChatServiceInstance.chatConnected) ChatServiceInstance.connect({
      username: user?.username || '',
      fullName: `${user?.firstname} ${user?.lastname}`,
      avatar: user?.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png',
      status: 'available'
    })
  }, [JSON.stringify(user)])

  useEffect(() => {
    if (ChatServiceInstance.chatConnected) ChatServiceInstance.getChatList()
  }, [ChatServiceInstance.chatConnected])

  useEffect(() => {
    if (token) {
      dispatch<any>(getEventTypes());
      const decodedToken = jwt_decode(token) as any
      if (`${decodedToken.exp}000` < new Date().valueOf().toString()) {
        //Token expired
        dispatch<any>(refreshTokens())
      }
    }
  }, [token])

  useEffect(() => {
    if (organization && 'setupComplete' in organization) {
      setShowSetupSchool(!organization.setupComplete);
    } else {
      setShowSetupSchool(undefined)
    }
  }, [organization]);

  useEffect(() => {
    if (userInfo) {
      dispatch<any>(getUser(userInfo.username));
      dispatch<any>(getMyEventsComplete(userInfo.username, true, undefined, events))
        .then((envt: any) => {
          setEventsOk(true)
        })
        .catch((error: any) => {
          setEventsOk(false)
          setLoadingMsj("Couldn't get your events. Please, reload the page!")
          console.error(error, 'Events error')
        })
      setPostsOk(true)
    } else if (userInfo === undefined) {
      setLoadingMsj(t('text__loading'))
      setShowSetupSchool(undefined)
      setShowSetupUser(undefined)
      setGoBulletin(undefined)
      setPostsOk(undefined)
      setEventsOk(undefined)
    }
  }, [userInfo]);

  useEffect(() => {
    if (eventsOk && postsOk) {
      setGoBulletin(true);
    } else if (eventsOk !== undefined && postsOk !== undefined) {
      setGoBulletin(false);
    }
  }, [eventsOk, postsOk])

  useEffect(() => {
    if (user && typeof user.isSetup === 'boolean') {
      setShowSetupUser(!user.isSetup);
    } else {
      setShowSetupUser(undefined)
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const isAdmin = () => {
    return (userRoles.includes('SUPER')) ?
      'SUPER' :
      (userRoles.includes('ZAA#ORGADMIN')) ?
        'ORGADMIN' : 'ELSE';
  }

  if (!isLoggedIn) {
    //Login Routes
    return (
      <Routes>
        <Route path="/" element={<LoginWrp />} />
        <Route path="signup" element={<SignupWrp />} />
        <Route path="signup-student/:token" element={<SignUpStudent />} />
        <Route path="signup-code/:code" element={<SignUpCode />} />
        <Route path="signup/:token" element={<SignUpUserGeneral />} />
        <Route path="forgot-password" element={<ForgotPasswordWrp />} />
        <Route path="restore-password" element={<RestorePasswordWrp />} />
        <Route path="*" element={<NotFound isLoggedIn={isLoggedIn} />} />
      </Routes>
    );
  }

  if (setupInfoLoaded()) {

    //Setup School
    if (showSetupSchool && userRoles.includes("ZAA#ORGADMIN") && organization.type !== "YOUTHORG") {
      return (
        <Routes>
          <Route path="setup-school" element={<SetupSchool setShowSetupSchool={setShowSetupSchool} />} />
          <Route path="*" element={<Navigate to="/setup-school" />} />
        </Routes>
      );
    }

    //Setup School
    if (showSetupSchool && userRoles.includes("ZAA#ORGADMIN") && organization.type === "YOUTHORG") {
      return (
        <Routes>
          <Route path="setup-youth" element={<SetupYouth setShowSetupYouth={setShowSetupSchool} />} />
          <Route path="*" element={<Navigate to="/setup-youth" />} />
        </Routes>
      );
    }

    //Setup Users
    if (showSetupUser && userRoles.includes("ZAA#STAFF#ADVISOR") && !userRoles.includes("ZAA#ORGADMIN")) {
      return (
        <Routes>
          <Route path="setup-advisor" element={<SetupAdvisor setShowSetupUser={setShowSetupUser} />} />
          <Route path="*" element={<Navigate to="/setup-advisor" />} />
        </Routes>
      );
    }

    if (showSetupUser && userRoles.includes("ZAA#STAFF") && !userRoles.includes("ZAA#ORGADMIN")) {
      return (
        <Routes>
          <Route path="setup-staff" element={<SetupStaff setShowSetupUser={setShowSetupUser} />} />
          <Route path="*" element={<Navigate to="/setup-staff" />} />
        </Routes>
      );
    }

    if (showSetupUser && (userRoles.includes("ZAA#ALUMNI") || userRoles.includes("ZAA#GUARDIAN")) && !userRoles.includes("ZAA#ORGADMIN")) {
      return (
        <Routes>
          <Route path="setup-user" element={<SetupGuardian setShowSetupUser={setShowSetupUser} />} />
          <Route path="*" element={<Navigate to="/setup-user" />} />
        </Routes>
      );
    }

    if (showSetupUser && userRoles.includes("ZAA#STUDENT") && !userRoles.includes("ZAA#ORGADMIN")) {
      return (
        <Routes>
          <Route path="setup-student" element={<SetupStudent setShowSetupUser={setShowSetupUser} />} />
          <Route path="*" element={<Navigate to="/setup-student" />} />
        </Routes>
      );
    }
  }

  //Go to bulletin if ready to go or if SA
  if ((everythingIsLoaded() && goBulletin) || (userRoles && userRoles.includes('SUPER'))) {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/app" />} />
        <Route path="/app" element={<AppLayout isAdmin={isAdmin()} existEvents={events && events.length > 0} ChatServiceInstance={ChatServiceInstance}/>}>
          <Route index element={<Bulletin ChatServiceInstance={ChatServiceInstance}/>} />
          <Route path="clubs/:id" element={<Club ChatServiceInstance={ChatServiceInstance}/>} />
          <Route path="events/:id" element={<Event ChatServiceInstance={ChatServiceInstance}/>} />
          <Route path="connections/:username" element={<Profile ChatServiceInstance={ChatServiceInstance}/>} />
          <Route path="userProfile/:username" element={<Profile ChatServiceInstance={ChatServiceInstance}/>} />
        </Route>
        <Route path="post/:id" element={<PostDetail />} />
        {userRoles.includes('SUPER') && (
          <>
            <Route path="/superadmin" element={<SuperAdminLayout />}>
              <Route index element={<Dashboard isSuper={true} />} />
              <Route path="users" element={<Users />} />
              <Route path="users/:id/view" element={<UserView />} />
              <Route path="users/:id/update" element={<UserUpdate />} />
              <Route path="organizations" element={<Organizations />} />
              <Route path="youth-organizations" element={<YoungOrganizations />} />
              <Route
                path="organizations/:id/view"
                element={<OrganizationsView />}
              />
              <Route
                path="organizations/:id/update"
                element={<OrganizationsUpdate />}
              />
              <Route path="clubs" element={<ClubsManageClubsAdmin clubType="CLUB" />} />
              <Route path="teams" element={<ClubsManageClubsAdmin clubType="TEAM" />} />
              <Route path="communities" element={<ClubsManageClubsAdmin clubType="COMM" />} />
              <Route path="countries" element={<CountryTable />} />
              <Route path="lang" element={<LangTable />} />
              <Route path="tclubs" element={<TClubs />} />
              <Route path="tclubs/create" element={<CreateTClub />} />
              <Route path="tclubs/:id/update" element={<UpdateTClub />} />
              <Route path="tclubs/tclists/:id" element={<TCListDetail />} />
              <Route path="yotable" element={<YOTable />} />
              <Route path="interests" element={<Interests />} />
              <Route path="interests/intlists/:id" element={<IntListDetail />} />
              <Route path="yotable/create" element={<CreateYO />} />
              <Route path="school-districts" element={<SchoolDistricts />} />
              <Route path="school-database" element={<SchoolDatabases />} />
              {/*
              <Route path="template-clubs" element={<TemplateClubs />} />
              <Route
                path="template-clubs/create"
                element={<CreateTemplateClub />}
              />
              <Route
                path="template-clubs/:id/view"
                element={<ViewTemplateClub />}
              />
              <Route
                path="template-clubs/:id/update"
                element={<UpdateTemplateClub />}
              />
              <Route
                path="template-categories"
                element={<TemplateCategories />}
              />
              <Route
                path="template-categories/create"
                element={<CreateCategory />}
              />
              <Route
                path="template-categories/:id/view"
                element={<ViewCategory />}
              />
              <Route
                path="template-categories/:id/update"
                element={<UpdateCategory />}
              />*/}
            </Route>
            <Route path="setup-school" element={<SetupSchool setShowSetupSchool={setShowSetupSchool} />} />
            <Route path="setup-advisor" element={<SetupAdvisor setShowSetupUser={setShowSetupUser} />} />
            <Route path="setup-student" element={<SetupStudent setShowSetupUser={setShowSetupUser} />} />
          </>
        )}
        {userRoles.includes('ZAA#ORGADMIN') && (
          <>
            <Route path="/admin" element={<AdminLayout />}>
              <Route index element={<Dashboard isSuper={false} />} />
              <Route path="users" element={<AdminUsers />} />
              <Route path="users/:id/view" element={<AdminUserView />} />
              <Route path="users/:id/update" element={<AdminUserUpdate />} />
              <Route path="users/create" element={<AdminCreateUser />} />
              <Route path="clubs" element={<ClubsManageClubsAdmin clubType="CLUB" />} />
              <Route path="teams" element={<ClubsManageClubsAdmin clubType="TEAM" />} />
              <Route path="communities" element={<ClubsManageClubsAdmin clubType="COMM" />} />
              <Route path="invites" element={<AdminInvites />} />
              <Route path="invites/:token/view" element={<ViewInvitesAdmin />} />
              <Route path="setup-advisor" element={<SetupAdvisor />} />
              <Route path="setup-student" element={<SetupStudent />} />
              <Route path="my-school" element={<MySchool />} />
              <Route path="flagged" element={<Flagged />} />
              <Route path="events" element={<EventAdmin />} />
              <Route path="my-school/update" element={<MySchoolUpdate />} />
              <Route path="schools-youth" element={<MySchoolsYouth />} />
            </Route>
            <Route path="setup-school" element={<Navigate to="/app" />} />
          </>
        )}
        {userRoles.includes("ZAA#STUDENT") && (
          <Route path="setup-student" element={<Navigate to="/app" />} />
        )}
        {(userRoles.includes("ZAA#STAFF#ADVISOR") || userRoles.includes("ZAA#STAFF")) && (
          <Route path="setup-advisor" element={<Navigate to="/app" />} />
        )}
        <Route path="/chat" element={<Chat ChatServiceInstance={ChatServiceInstance}/>}>
          <Route index element={<NoChatSelected />} />
          <Route path="conversation/:id" element={<ChatUser ChatServiceInstance={ChatServiceInstance}/>} />
          <Route path="conversation/:id/:idGroup" element={<ChatUser ChatServiceInstance={ChatServiceInstance}/>} />
          <Route path="group/create/:clubId" element={<CreateChatGroup ChatServiceInstance={ChatServiceInstance}/>} />
          <Route path="group/create" element={<CreateChatGroup ChatServiceInstance={ChatServiceInstance}/>} />
          <Route path="*" element={<Navigate to="/chat" />} />
        </Route>
        <Route path="my-account" element={<MyAccount ChatServiceInstance={ChatServiceInstance}/>} />
        <Route path="signup-student/:token" element={<Navigate to="/app?signupLogged=true" />} />
        <Route path="signup/:token" element={<Navigate to="/app?signupLogged=true" />} />
        <Route path="*" element={<NotFound isLoggedIn={isLoggedIn} />} />
      </Routes>
    );
  }

  //Default view after logging in is a message (Loading...)
  return (
    <Routes>
      <Route path="*" element={<Loading message={loadingMsj} />} />
    </Routes>
  );
}

export default RootStackComponent;

