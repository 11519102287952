import React, { useEffect, useState } from "react";
import "./styles.css";
import "../../styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";
import { unixToDateFormatted } from "../../helpers/dateHelper";
import { deleteInviteAdmin, getInvitesByToken, resentInviteAdmin } from "../../store/actions/inviteActions";
import { getOrganizationInfo } from "../../store/actions/orgsActions";
import { getClub } from "../../store/actions/clubActions";
import { capitalize } from "../../helpers/letterHelper";
import { useTranslation } from "react-i18next";

function Invite() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const invite = useSelector((state: any) => state.getInvites.inviteByToken);
  const org = useSelector((state: any) => state.getOrgs.organizationInfo);
  const club = useSelector((state: any) => state.getClub.getClub);
  const loadingDeleteInvite = useSelector((state: any) => state.getInvites.loadingDeleteInvite || false);
  const loadingResentInvite = useSelector((state: any) => state.getInvites.loadingResentInvite || false);


  const { token } = useParams();

  //@todo sender profile is the username of the creator and create by is the name of the creator

  useEffect(() => {
    dispatch<any>(getInvitesByToken({
      'token':token
    }))
  }, []);
  
  useEffect(() => {
    dispatch<any>(getOrganizationInfo(invite.org_id));
  }, [invite]);

  useEffect(() => {
    dispatch<any>(getClub(invite.club_id, org?.status?.startsWith("PEND")));
  }, [org]);

  const resentInvite = () => {
    dispatch<any>(resentInviteAdmin(formatId()));
  };

  const deleteInvite = () => {
    dispatch<any>(deleteInviteAdmin(formatId()))
      .then(() => navigate('/admin/invites'));
  };

  const formatId = () => {
    return (invite.role === "ZAA#SECOND#ADVISOR" || invite.role === "ZAA#STAFF#ADVISOR") ?
    `Advisor#${invite.club_id}#${invite.email}` : `End_User#${invite.club_id}#${invite.email}`;
  }

  return (
    
        <div className="content-invite">
            <div className="org-view-cont">
                <h1 className="title-name-1">{t('manageInviteView__manageInvite')}</h1>
                <div className="view-columns-invites">
                    <div className="col-1-invites">
                    <div className="info-field-row">
                        <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageInvites__inviteToken')}</div>
                        <div className="info-field">
                          <div className="roboto-normal-white-16px ellipses">{invite.token || ''}</div>
                        </div>
                    </div>
                    <div className="info-field-row">
                        <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageInvites__email')}</div>
                        <div className="info-field">
                          <div className="roboto-normal-white-16px ellipses">{invite.email || ''}</div>
                        </div>
                    </div>
                    
                    </div>
                    <div className="col-2-invites">
                    

                    <div className="info-field-row">
                        <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageInviteView__organization')}</div>
                        <div className="info-field">
                         <div className="roboto-normal-white-16px ellipses">{org?.name || ''}</div>
                        </div>
                    </div>
                    <div className="info-field-row">
                        <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageInviteView__club')}</div>
                        <div className="info-field">
                          <div className="roboto-normal-white-16px ellipses">{club?.name || ''}</div>
                        </div>
                    </div>

                    </div>
                </div>
                <div className="info-field-column-center">
                    <div className="label-cont robotocondensed-bold-lavender-16px" id="label-text">{t('manageInviteView__text')}</div>
                    <div className="info-field-text">
                        <div className="roboto-normal-white-16px">{''}</div>
                    </div>
                </div>
                <h1 className="title-name-1">{t('manageInviteView__createdInformation')}</h1>
                <div className="view-columns-invites">
                    <div className="col-1-invites">
                    <div className="info-field-row">
                        <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageInviteView__createdBy')}</div>
                        <div className="info-field">
                         <div className="roboto-normal-white-16px ellipses">{''}</div>
                        </div>
                    </div>
                    <div className="info-field-row">
                        <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageInviteView__type')}</div>
                        <div className="info-field">
                          <div className="roboto-normal-white-16px">{''}</div>
                        </div>
                    </div>
                    <div className="info-field-row">
                        <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageInviteView__sentOn')}</div>
                        <div className="info-field">
                          <div className="roboto-normal-white-16px">{''}</div>
                        </div>
                    </div>
                    <div className="info-field-row">
                        <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageInviteView__resentOn')}</div>
                        <div className="info-field">
                          <div className="roboto-normal-white-16px">{unixToDateFormatted(invite.resent_at || '') || ''}</div>
                        </div>
                    </div>
                    
                    </div>
                    <div className="col-2-invites">
                    

                    <div className="info-field-row">
                        <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageInviteView__senderProfile')}</div>
                        <div className="info-field">
                         <div className="roboto-normal-white-16px ellipses">{''}</div>
                        </div>
                    </div>
                    <div className="info-field-row">
                        <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageInviteView__status')}</div>
                        <div className="info-field">
                          <div className="roboto-normal-white-16px">{capitalize(invite.status || '') || ''}</div>
                        </div>
                    </div>
                    <div className="info-field-row">
                        <div className="label-cont robotocondensed-bold-lavender-16px">{t('manageInviteView__createdOn')}</div>
                        <div className="info-field">
                          <div className="roboto-normal-white-16px">{unixToDateFormatted(invite.timestamp) || ''}</div>
                        </div>
                    </div>

                    </div>
                </div>

                <div className="bottom-column">
                    <div className="buttons-box">
                    <div className={`button-w ${loadingDeleteInvite && 'inProcess'}`} onClick={deleteInvite}>
                        {loadingDeleteInvite ? 
                            <div className="icon-spinner-third buttonSendSignUp"></div>
                            :
                            <div className="cancel roboto-normal-white-16px">{t('button__delete')}</div>
                        }
                    </div>
                    <div className={`button-p ${loadingResentInvite && 'inProcess'}`} onClick={resentInvite}>
                        {loadingResentInvite ? 
                            <div className="icon-spinner-third buttonSendSignUp"></div>
                            :
                            <div className="save roboto-normal-white-16px">{t('button__resend')}</div>
                        }
                    </div>
                    </div>
                </div>
            </div>
        </div>
  );
}

export default Invite;
