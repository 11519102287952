import { useEffect, useState } from "react";
import { Backend, MicroServices } from "../helpers/backendHelper";
import SelectItem from "../interfaces/SelectItem";

export interface CountryOption extends SelectItem {};

const useCountry = () => {
  const [countries, setCountries] = useState<string[]>([]);
  const [countriesReactSelect, setCountriesReactSelect] = useState<CountryOption[]>([])

  useEffect(() => {
    Backend(
      MicroServices.Roster,
      `/location/activeCountries`
    )
      .then((response) => response.json())
      .then((results) => {
        if (!Array.isArray(results)) {
          return;
        }
        setCountries(results.map((country) => country.name));
        setCountriesReactSelect(
          results.map((country) => ({value: country.location, label: country.name}))
        )
      })
  }, []);

  return {countries, countriesReactSelect};
}

export default useCountry;