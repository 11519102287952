import styled from "styled-components";

export const TitleWrapper = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    font-family: var(--font-family-roboto);
    font-weight: 700;
    color: var(--white);
    font-size: var(--font-size-l);
    letter-spacing: 0;
`;

export const TitleInput = styled.input`
    background-color: #383838;
    border: none;
    font-family: var(--font-family-roboto);
    font-style: normal;
    font-weight: 700;
    font-size: var(--font-size-l);
    line-height: 28px;
    color: var(--white);
    width: 100%;
    text-align: center;
    border-bottom: 1px solid transparent;
    transition: 0.3s;

    &:focus {
        border-bottom: 1px solid white;
    }
`;