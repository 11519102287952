import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InfoMembersTeam from './Tabs/InfoMembersTeam';
import Description from './Tabs/Description';
import Meeting from './Tabs/Meeting';
import './styles.scoped.css';
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";

function Index(props: {
  show: boolean;
  onHide: any;
  team: any;
  setTeam: any;
}) {
  const { t, i18n } = useTranslation();
  //States
  const [tabActive, setTabActive] = useState(0);

  //Selectors
  const organization = useSelector((state: any) => state.getOrgs.organizationInfo);

  //Handlers
  function handleClose(e: any) {
    props.onHide(false)
  }
  function handleSave(e: any) {
    props.onHide(true)
  }

  function handleOnClickTab(e: any, tab: any) {
    setTabActive(tab);
  }

  return (
    <>
      <Modal className="modalCreateClub" show={props.show} onHide={() => handleClose}>
        <Modal.Header>
          <Modal.Title>{props.team?.name || ''}</Modal.Title>
          <FontAwesomeIcon
            icon="times"
            size='xl'
            color={WHITE}
            className="icon-times  "
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="nav">
            <div className={tabActive == 0 ? 'active' : ''} onClick={(e) => handleOnClickTab(e, 0)}>{t('infoClub__members')}</div>
            <div className={tabActive == 1 ? 'active' : ''} onClick={(e) => handleOnClickTab(e, 1)}>{t('setupSchool__description')}</div>
            <div className={tabActive == 2 ? 'active' : ''} onClick={(e) => handleOnClickTab(e, 2)}>{t('setupSchool__meeting')}</div>
          </div>
          {tabActive == 0 ?
            <InfoMembersTeam team={props.team} setTeam={props.setTeam} organization={organization} />
            : tabActive == 1 ?
              <Description club={props.team} setClub={props.setTeam} />
              : tabActive == 2 ?
                <Meeting club={props.team} setClub={props.setTeam} />
                : ''
          }
        </Modal.Body>
        <Modal.Footer>
          <Button className="buttonClose" onClick={handleClose}>
            {t('button__cancel')}
          </Button>
          <Button className="buttonSend" onClick={handleSave}>
            {t('button__saveDetails')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Index;