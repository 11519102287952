import { useEffect, useState } from "react";
import "./styles.scoped.css";
import { useNavigate } from "react-router-dom";
import OutsideClickHandler from 'react-outside-click-handler';
import ListEmojis, { emojiColor, reactionToEmoji } from './ListEmojis';
import GridImages from "../Post/GridImages";
import ModalReportPost from "./ModalReportPost"
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalAha from './ModalAha';
import { perfectSendAha } from "../../helpers/aHaHelper";
import ModalNoAha from "./ModalNoAha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
import { timeDelay } from "../../helpers/dateHelper";
import { useTranslation } from "react-i18next";
import { getUserPossibleConnections } from "../../store/actions/connectionActions";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";
toast.configure();

interface PostImageProps {
    //todo -> put types
    reloadPosts: any;
    post: any;
    listAdv: any;
    reasons: any;
    emoji: any;
    activeComments: any;
    ChatServiceInstance: ChatServiceInterface
}

function Index(props: PostImageProps) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector((state: any) => state.getUser.userInfo);
    const userRoles = useSelector((state: any) => state.getUser.userRoles);
    const userConnections = useSelector((state: any) => state.getConnection.userPossibleConnections || []);

    const [selectedItemId, setSelectedItemId] = useState(0);
    const [selectedItemState, setSelectedItemState] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalAha, setShowModalAha] = useState(false);
    const [spinnerFlag, setSpinnerFlag] = useState(false);

    function handleCloseModal(data: any, type: any, reason: any) {
        if (type === 'save') {
            setSpinnerFlag(true);

            Backend(
                MicroServices.Posts,
                `/flag`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "usernamePoster": data.author,
                    "postId": data.post_id,
                    "usernameFlagger": userInfo.username,
                    "reach": data.reach,
                    "reachId": data.reach === 'WALL' ? data.author : data.reach_id,
                    "reason": reason
                }),
            })
                .then((response) => {
                    toast.success(t('toast_postFlagged'));
                    props.reloadPosts();
                    setShowModal(false);
                    setSpinnerFlag(false);
                })
                .catch(console.log);
        }
        else {
            setShowModal(false);
        }
    };

    function handleCloseModalAha(data: any) {
        setShowModalAha(false)
    };

    function savePost(postInfo: any) {
        Backend(
            MicroServices.Posts,
            `/post/save?usernamePoster=${postInfo.author}&postId=${postInfo.post_id}&usernameSaver=${userInfo.username}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    toast.success(t('toast_postSaved'));
                    props.reloadPosts();
                }
            })
            .catch(console.log);
        setSelectedItemState(false);
    }

    const toggleClick = (id: any) => {
        if (id === selectedItemId) {
            setSelectedItemState(!selectedItemState)
        } else {
            setSelectedItemId(id);
            setSelectedItemState(true);
        }
    };

    const deleteContent = (postInfo: any) => {

        Backend(
            MicroServices.Posts,
            `/post?id=${postInfo.post_id}&poster=${postInfo.author}`, {
            method: "DELETE"
        })
            .then((response) => {
                if (response.status === 204) {
                    toast.success(t('toast_contentDeleted'));
                    props.reloadPosts();
                }
                else {
                    toast.error(t('toast_errorDeleting'));
                }
            })
            .catch((error) => {
                toast.error(t('toast_errorTryAgain'))
                console.error(error)
            });
    }

    const promotePost = (postInfo: any) => {

        Backend(
            MicroServices.Posts,
            `/posts/promote?postId=${postInfo.post_id}`, {
            method: "POST"
        })
            .then((response) => {
                if (response.status === 200) {
                    toast.success(t('toast_promotePost'));
                    props.reloadPosts();
                }
                else {
                    toast.error(t('toast_error'));
                }
            })
            .catch((error) => {
                toast.error(t('toast_errorTryAgain'))
                console.error(error)
            });
    }

    useEffect(() => {
        dispatch<any>(getUserPossibleConnections(userInfo.username));
    }, [])

    return (
        <div className={`itemPost postImage ${props.post.infoPost?.is_flagged && 'postFlag'}`}>
            {props.post.infoPost?.is_flagged &&
                <div className="postBlur">
                    {t('post__postFlagged')}
                    <span>{t('post__reason')} {props.post.infoPost?.reason} </span>
                </div>
            }
            <div className="titlePost">
                <button className="btnBots" onClick={() => toggleClick(props.post.id)}>
                    <FontAwesomeIcon
                        icon="ellipsis-v"
                        size='lg'
                        color={WHITE}
                        className=" "
                    />
                </button>
                <div className="infoPostHeader">
                    <div className="contImage">
                        <img className="round-avatar" src={props.post.imageProfile || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" />
                    </div>
                    <div
                        className="infoProfile pointer"
                        onClick={() => userConnections.find((user: any) => user.usernameConnection === props.post.author) &&
                            navigate('../connections/' + props.post.author)}
                    >
                        {props.post.nameProfile}
                    </div>
                    <span className="infoWhere">{`${props.post.nameReach || ''} - ${timeDelay(props.post.infoPost.created_at)}`}</span>
                </div>
                <OutsideClickHandler
                    onOutsideClick={() => {
                        setSelectedItemState(false);
                    }}
                >
                    <div className={`${props.post.id === selectedItemId && selectedItemState ? "active" : ""} listOptionEvents`} key={props.post.id}>
                        <div className="titleOptions"> <FontAwesomeIcon
                            icon="arrow-left"
                            size='lg'
                            color={WHITE}
                            className="iconOptions  "
                        /> {t('options__whatWouldYou')}</div>
                        <ul className="listOptionsPost">
                            <li onClick={() => savePost(props.post.infoPost)}><FontAwesomeIcon
                                icon="bookmark"
                                size='lg'
                                color={WHITE}
                                className="iconOptions  "
                            />{t('button__save')}</li>
                            {/*<li><FontAwesomeIcon
  icon="bell"
  size='lg'
  color={WHITE}
  className="iconOptions  "
/>Turn on notifications for this post</li>
                            <li><FontAwesomeIcon
  icon="share"
  size='lg'
  color={WHITE}
  className="iconOptions  "
/>Share</li>*/}
                            {(userInfo.username !== props.post.author) ? (
                                <li onClick={() => setShowModalAha(true)}>
                                    <img className="aha-logo" src={"/aHa-logo_w.png"} alt="aha-logo" />
                                    {t('options__sendAha')}
                                </li>
                            ) : (
                                <li onClick={() => deleteContent(props.post.infoPost)}>
                                    <FontAwesomeIcon
                                        icon="trash"
                                        size='lg'
                                        color={WHITE}
                                        className="iconOptions  "
                                    /> {t('options__deleteContent')}
                                </li>
                            )}
                            <li onClick={() => setShowModal(true)}>
                                <FontAwesomeIcon
                                    icon="flag"
                                    size='lg'
                                    color={WHITE}
                                    className="iconOptions  "
                                />{t('options__reportThisPost')}
                            </li>
                            {/* <li><FontAwesomeIcon
  icon="user-minus"
  size='lg'
  color={WHITE}
  className="iconOptions  "
/>Unfriend</li> */}
                            {((
                                userRoles.includes("ZAA#ORGADMIN") ||
                                (userRoles.includes("ZAA#STAFF#ADVISOR") && props.listAdv.findIndex((e: any) => e.id === props.post.infoPost.reach_id) !== -1)) &&
                                props.post.infoPost.reach_name != "School Bulletin") &&
                                <li onClick={() => promotePost(props.post.infoPost)}>
                                    <FontAwesomeIcon
                                        icon="megaphone"
                                        size='lg'
                                        color={WHITE}
                                        className="iconOptions"
                                    />{t('options__promoteSchool')}
                                </li>
                            }
                        </ul>
                    </div>
                </OutsideClickHandler>
                <ModalReportPost reasons={props.reasons} show={showModal} onHide={handleCloseModal} id={props.post.infoPost} spinnerFlag={spinnerFlag} />
            </div>
            {(props.post.infoPost && props.post.infoPost.files && props.post.infoPost.files.length > 0) ?
                <div className="filePost">
                    {/* <img src={props.post.infoPost.files[0].link} alt="" onClick={() => navigate('../../post/' + props.post.id)} /> */}
                    <GridImages images={props.post.infoPost.files} post={'detail'} />

                    {!props.post.infoPost.dependent && <div className="btnReactions">
                        <button className="iconReaction emoji pointer">
                            <FontAwesomeIcon
                                icon={['fas', reactionToEmoji(props.emoji.emojis.reaction)]}
                                size="lg"
                                color={emojiColor(props.emoji.emojis.reaction)}
                                className="iconReaction"
                            />
                            {props.emoji.emojis.count}
                        </button>
                        <ListEmojis post_id={props.post.id} manageEmoji={props.emoji.manageEmoji} />
                        <button onClick={() => props.activeComments(props.post.id)} className="comments pointer">
                            <FontAwesomeIcon
                                icon="comment"
                                size='lg'
                                color={WHITE}
                                className="iconReaction"
                            />
                            {props.post.comments}
                        </button>
                        {(userInfo.username !== props.post.author) && (
                            <img onClick={() => setShowModalAha(true)} className="aha-logo-post" src={"/aHa-logo_w.png"} alt="aha-logo">
                            </img>)}
                    </div>}

                </div>
                :
                ''
            }
            {props.post.textPost &&
                <div className="infoPost">
                    <p className="textPost">
                        {props.post.textPost}
                    </p>
                </div>
            }
            {(perfectSendAha(props.post.authorRole, userRoles)) ? (
                <ModalAha postInfo={props.post} show={showModalAha} onHide={handleCloseModalAha} ChatServiceInstance={props.ChatServiceInstance} />
            ) : (
                <ModalNoAha profile={props.post.nameProfile} show={showModalAha} onHide={handleCloseModalAha} />
            )}
        </div>
    );
}

export default Index;
