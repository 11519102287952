import styled, { css } from "styled-components";

interface StepCircleProps {
    active: boolean;
    past: boolean;
    gotoStep: () => void;
    title: string;
    isLastChild?: boolean
}

const StepCircle = ({
    active,
    past,
    gotoStep,
    title,
    isLastChild,
}: StepCircleProps) => (
    <StepWrapper  past={past} isLastChild={!!isLastChild}>
        <StepIcon onClick={gotoStep}>
            <CircleSetup active={active} past={past} />
            <StepTitle active={active || past}>{title}</StepTitle>
        </StepIcon>
    </StepWrapper>
);

interface StepWrapperProps {
    past: boolean;
    isLastChild: boolean;
}

const StepWrapper = styled.div`
    align-items: baseline;
    display: flex;
    flex-flow: column;
    position: relative;

    ${({past}: StepWrapperProps) => past
        ? css`
            &::before {
                background-color: #f1e0ff;
                border-radius: 12px;
                content: "";
                display: block;
                height: 5px;
                position: relative;
                right: -100px;
                top: 28px;
                width: calc(100% - 70px);
            }
        `
        : ''
    }
    ${({isLastChild}: StepWrapperProps) => !isLastChild
        ? css`
            width: 20%;
        `
        : ''
    }
`;

interface StepTitleProps {
    active: boolean;
}

const StepTitle = styled.span`
    display: block;
    padding-top: 6px;
    ${(props: StepTitleProps) => props.active ? css`color: white;` : ''}
`;

interface CircleSetupProps {
    active: boolean;
    past: boolean;
}

const CircleSetup = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 4px solid #a885d7;
    position: relative;
    cursor: pointer;
    margin: 10px auto;

    ${({active, past}: CircleSetupProps) => active || past
        ? css`
            border: 4px solid #f1e0ff;
            background-color: #f1e0ff;
        `
        : ''
    }

    ${({active}: CircleSetupProps) => active
        ? css`
            &::before {
                content: "";
                width: 36px;
                height: 36px;
                display: block;
                border: 4px solid #f1e0ff;
                border-radius: 50%;
                position: absolute;
                top: -11px;
                right: -11px;
            }
        `
        : ''
    }
`;

const StepIcon = styled.div`
    width: 100px;
    display: block;
    cursor: pointer;
`;

export default StepCircle;