import "./styles.css";
import "./styles2.css";
import Organization from "../Organization"
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import OutsideClickHandler from 'react-outside-click-handler';
import { getOrganizationsWithLimit } from "../../store/actions/orgsActions";
import { pageSize } from "../Organizations";
import { getConstantValue } from "typescript";
import { capitalize, capitalizeStatus } from "../../helpers/letterHelper";
import { orgTypesZaahah } from "../../helpers/orgTypeHelper";
import ModalDelete from './ModalDelete';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
const Index = ({ pageSize, page }: { pageSize: pageSize, page: number }) => {
  const organizations = useSelector((state: any) => state.getOrgs.organizationsLimit);
  const organizationsAll = useSelector((state: any) => state.getOrgs.organizationNames);
  const orgStatus = useSelector((state: any) => state.getOrgs.organizationStatus);
  const orgT = useSelector((state: any) => state.getOrgs.organizationTypes);
  const activeCountries = useSelector((state: any) => state.getLocation.activeCountries || []);
  const activeCities = useSelector((state: any) => state.getLocation.activeCities || []);

  const selectName = useRef<any>(null);
  const selectType = useRef<any>(null);
  const selectCity = useRef<any>(null);
  const selectCountry = useRef<any>(null);
  const selectStatus = useRef<any>(null);

  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.getUser.token);

  const nameOptions = organizationsAll.map((e: any) => {
    return {
      label: e.name,
      value: e.name,
    }
  })

  nameOptions.unshift({ label: "All", value: "" });

  const orgTypes = orgTypesZaahah(orgT);

  const typeOptions = orgTypes.map(([value, label]: [string, string]) => {
    return {
      label: label,
      value: value,
    }
  })

  typeOptions.unshift({ label: "All", value: "" });

  const countryOptions = activeCountries.map((e: any) => {
    return {
      label: e.name,
      value: e.name,
      value1: e.location
    }
  })

  countryOptions.unshift({ label: "All", value: "" });

  console.log(activeCities)

  const cityOptions = activeCities.map((e: any) => {
    return {
      label: e.name,
      value: e.name,
      value1: e.location
    }
  })

  cityOptions.unshift({ label: "All", value: "" });

  const statusOptions = orgStatus.map((e: any) => {
    return {
      label: capitalizeStatus(e),
      value: e,
    }
  })

  statusOptions.unshift({ label: "All", value: "" });

  const [selected, setSelected] = useState("");

  const [input, setInput] = useState<string>('');
  const [inputfocus, setInputFocus] = useState(false);

  const [outside, setOutside] = useState(true);
  const [organizationValue, setOrganizationValue] = useState("");
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [status, setStatus] = useState('');
  const [location, setLocation] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');

  const [typeHeader, setTypeHeader] = useState('');
  const [statusHeader, setStatusHeader] = useState('');
  const [cityHeader, setCityHeader] = useState('');
  const [countryHeader, setCountryHeader] = useState('')

  const [defaultValueName, setDefaultValueName] = useState<{ label: string, value: string | number } | null>(null)
  const [defaultValueType, setDefaultValueType] = useState<{ label: string, value: string | number } | null>(null)
  const [defaultValueCity, setDefaultValueCity] = useState<{ label: string, value: string | number } | null>(null)
  const [defaultValueCountry, setDefaultValueCountry] = useState<{ label: string, value: string | number } | null>(null)
  const [defaultValueStatus, setDefaultValueStatus] = useState<{ label: string, value: string | number } | null>(null)

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [orgId, setOrgId] = useState('');
  function handleCloseModalDelete(e: any) {
    setShowModalDelete(false)
  };

  const handleClickid = (e: any) => {
    setOrgId(e)
    setShowModalDelete(true)
  }

  const handleChangeName = (e: any) => {
    setName(e.value);
    setDefaultValueName({ 'label': e.value ? e.value : 'All', 'value': 0 })
    dispatch(getOrganizationsWithLimit(token, 1000000, 0, '', e.value, type, status, location))
  };
  const handleChangeType = (e: any) => {
    setTypeHeader(`Type`);
    setType(e.value);
    setDefaultValueType({ 'label': e.value ? e.label : 'All', 'value': 0 })
    if (e.value === '') {
      selectType?.current?.clearValue();
    }
    dispatch(getOrganizationsWithLimit(token, 1000000, 0, '', name, e.value, status, location))
  };
  const handleChangeStatus = (e: any) => {
    setStatusHeader(`Status `);
    setStatus(e.value);
    setDefaultValueStatus({ 'label': e.value ? e.label : 'All', 'value': 0 })
    dispatch(getOrganizationsWithLimit(token, 1000000, 0, '', name, type, e.value, location))
  };
  const handleChangeCity = (e: any) => {
    setCityHeader('City ');
    setCity(e.value);
    setDefaultValueCity({ 'label': e.value ? e.value : 'All', 'value': 0 })
    if (e.value === '') {
      setLocation(country);
      dispatch(getOrganizationsWithLimit(token, 1000000, 0, '', name, type, status, country))
    } else {
      setLocation(e.value);
      dispatch(getOrganizationsWithLimit(token, 1000000, 0, '', name, type, status, e.value === "" ? '': encodeURIComponent(e.value1)))
    }
  };
  const handleChangeCountry = (e: any) => {
    setCountryHeader('Country ');
    setCountry(e.value);
    setDefaultValueCountry({ 'label': e.value ? e.value : 'All', 'value': 0 })
    if (e.value === '') {
      setLocation(e.value);
      setCity('');
      dispatch(getOrganizationsWithLimit(token, 1000000, 0, '', name, type, status, e.value === "" ? '': encodeURIComponent(e.value1),))
    } else {
      setLocation(e.value);
      dispatch(getOrganizationsWithLimit(token, 1000000, 0, '', name, type, status, e.value === "" ? '': encodeURIComponent(e.value1),))
    }
  };

  const onInput = (e: any) => {
    setInput(e.target.className.split('__input')[0]);
    setOutside(false);
    setInputFocus(true);
    //setOrganizationValue("organizationName");
  };

  const outsideClick = (e: any) => {
    // outside ? e.label = "Search by Name" : <></>;
  };

  const onInputBlur = (e: any) => {
    setOutside(true);
    setInputFocus(false);
  };

  const onKeyDown = (e: any, typeInput: any) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      dispatch(getOrganizationsWithLimit(token, 1000000, 0, '', name, type, status, location))

      if (typeInput === 'selectNameOrg') {
        setDefaultValueName({ 'label': name, 'value': 0 })
        selectName.current.blur();
      } else if (typeInput === 'selectType') {
        setDefaultValueType({ 'label': type, 'value': 0 })
        selectType.current.blur();
      } else if (typeInput === 'selectCity') {
        setDefaultValueCity({ 'label': city, 'value': 0 })
        selectCity.current.blur();
      } else if (typeInput === 'selectCountry') {
        setDefaultValueCountry({ 'label': country, 'value': 0 })
        selectCountry.current.blur();
      } else if (typeInput === 'selectStatus') {
        setDefaultValueStatus({ 'label': status, 'value': 0 })
        selectStatus.current.blur();
      }
    }
  };

  const customStyles = (value: any, input: any, outside: any, inputfocus: any) => ({
    option: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#383838',
    }),
    input: (styles: any, state: any) => ({
      ...styles,
      border: 'none',
      color: 'white',
      fontWeight: "normal",
      paddingLeft: "5px",
      "&:focus": { backgroundColor: 'red' },
    }),
    container: (styles: any) => ({
      ...styles,
      padding: 0,
    }),
    valueContainer: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: value && value != 'All' ? '#383838' : 'transparent',
      // display: value && !outside ? 'flex' : 'none',
      padding: 0,
    }),
    menu: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      backgroundColor: "#383838",
      width: "fit-content",
      minWidth: "150px",
      textAlign: "left",
      zIndex: "1",
      display: inputfocus ? 'block' : 'none',
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      textAlign: "left",
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      //minWidth: "50px",
      borderRadius: "6px",
      boxShadow: "none",
      border: 0,
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "bold",
      maxWidth: "200px",
      marginBottom: "5px",
      marginTop: "5px",
      backgroundColor: outside ? 'transparent' : input ? '#383838' : 'transparent',
      minWidth: inputfocus && input ? '100px' : '50px'
      // textDecoration: outside ? 'underline' : 'none',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: '#a885d7',
      fontFamily: "var(--font-family-roboto_condensed)",
      display: outside ? 'block' : input ? 'none' : 'block',
      // display: input ? 'none' : 'block',
      // backgroundColor: outside ? 'red' : 'blue'
    }),
    indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
    dropdownIndicator: (styles: any) => ({ ...styles, display: "none" }),
    noOptionsMessage: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#383838',
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "normal",
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#383838',
      border: "none",
      paddingLeft: "7px",
      paddingRight: "7px",
      paddingBottom: "7px",
      paddingTop: "7px",
      borderRadius: "4px",
      fontSize: "13px",
      width: "125px",
      // display: input ? 'none' : 'block',

    }),
  });

  let organizationsFiltered = organizations.filter((org: any) => org[organizationValue] === selected);

  const handleInputChange = (characterEntered: any, typeInput: any) => {
    if (typeInput === 'selectNameOrg') {
      setName(characterEntered);
    } else if (typeInput === 'selectType') {
      setType(characterEntered);
    } else if (typeInput === 'selectCity') {
      setLocation(characterEntered);
      setCity(characterEntered)
    } else if (typeInput === 'selectCountry') {
      setLocation(characterEntered);
      setCountry(characterEntered)
    } else if (typeInput === 'selectStatus') {
      setStatus(characterEntered);
    }
  };

  return (
    <>
      <table className="listData tableManageOrganization robotocondensed-bold-white-16px">
        <tr>
          <th className="field-id">
            <div className="idRow">
              <div className="idTitle">ID</div>
              <FontAwesomeIcon
                icon="sort-amount-down"
                size='lg'
                color={LAVENDER}
                className="sort-amount-down  "
              />
            </div>
          </th>
          <th className="th-name">
            <div className="nameRow">
              <Select className={`selectNameOrg`} classNamePrefix="selectNameOrg"
                ref={selectName}
                isMulti={false}
                options={nameOptions}
                placeholder={"Search by Name"}
                styles={customStyles(selected, input === 'selectNameOrg', outside, inputfocus)}
                onChange={handleChangeName}
                onFocus={onInput}
                onBlur={onInputBlur}
                onKeyDown={(e) => onKeyDown(e, 'selectNameOrg')}
                onInputChange={(e) => handleInputChange(e, 'selectNameOrg')}
                value={defaultValueName}
              />
              <FontAwesomeIcon
                icon="sort-amount-down"
                size='lg'
                color={LAVENDER}
                className="sort-amount-down  "
              />
            </div>
          </th>
          <th className="th-type">
            <div className={`type ${typeHeader ? 'active' : ''}`}>
              <label className="labelType">{typeHeader}</label>
              <Select className={`selectType`} classNamePrefix="selectType"
                ref={selectType}
                isMulti={false}
                options={typeOptions}
                placeholder={"Type"}
                styles={customStyles(selected, input === 'selectType', outside, inputfocus)}
                onChange={handleChangeType}
                onFocus={onInput}
                onBlur={onInputBlur}
                onKeyDown={(e) => onKeyDown(e, 'selectType')}
                onInputChange={(e) => handleInputChange(e, 'selectType')}
                value={defaultValueType}
              />
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "
              />
            </div>
          </th>
          <th className="th-city">
            <div className={`type ${cityHeader ? 'active' : ''}`}>
              <label className="labelType">{cityHeader}</label>
              <Select className={`selectCity`} classNamePrefix="selectCity"
                ref={selectCity}
                isMulti={false}
                options={cityOptions}
                placeholder={"City"}
                styles={customStyles(selected, input === 'selectCity', outside, inputfocus)}
                onChange={handleChangeCity}
                onFocus={onInput}
                onBlur={onInputBlur}
                onKeyDown={(e) => onKeyDown(e, 'selectCity')}
                onInputChange={(e) => handleInputChange(e, 'selectCity')}
                value={defaultValueCity}
              />
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "
              />
            </div>
          </th>
          <th className="th-country">
            <div className={`type ${countryHeader ? 'active' : ''}`}>
              <label className="labelType">{countryHeader}</label>
              <Select className={`selectCountry`} classNamePrefix="selectCountry"
                ref={selectCountry}
                isMulti={false}
                options={countryOptions}
                placeholder={"Country"}
                styles={customStyles(selected, input === 'selectCountry', outside, inputfocus)}
                onChange={handleChangeCountry}
                onFocus={onInput}
                onBlur={onInputBlur}
                onKeyDown={(e) => onKeyDown(e, 'selectCountry')}
                onInputChange={(e) => handleInputChange(e, 'selectCountry')}
                value={defaultValueCountry}
              />
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "
              />
            </div>
          </th>
          <th className="th-status">
            <div className={`field-status ${statusHeader ? 'active' : ''}`}>
              <label className="labelType">{statusHeader}</label>
              <Select className={`selectStatus`} classNamePrefix="selectStatus"
                ref={selectStatus}
                isMulti={false}
                options={statusOptions}
                placeholder={"Status"}
                styles={customStyles(selected, input === 'selectStatus', outside, inputfocus)}
                onChange={handleChangeStatus}
                onFocus={onInput}
                onBlur={onInputBlur}
                onKeyDown={(e) => onKeyDown(e, 'selectStatus')}
                onInputChange={(e) => handleInputChange(e, 'selectStatus')}
                value={defaultValueStatus}
              />
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "
              />
            </div>
          </th>
          <th className="field-actions">
            <div className="actions robotocondensed-bold-white-16px">Setup</div>
          </th>
          <th className="field-actions">
            <div className="actions robotocondensed-bold-white-16px">Actions</div>
          </th>
        </tr>
        {selected === "" || selected === "All" ? (organizations.map((organization: any, index: any) => (
          <Organization organizations={organization} key={index} handleClickId={handleClickid} />
        )))
          :
          (organizationsFiltered.map((organizationFiltered: any, index: any) => (
            <Organization organizations={organizationFiltered} key={index} handleClickId={handleClickid} />
          )))}
        <ModalDelete show={showModalDelete} onHide={handleCloseModalDelete} orgId={orgId} />
      </table>
    </>
  )
};

export default Index;
