import { Backend, MicroServices } from "../backendHelper"

export const getClubRole = (club_id: string): Promise<string> => new Promise((resolve, reject) => {
  Backend(
    MicroServices.Club,
    `/clubs/${club_id}`
  )
    .then((response) => response.json())
    .then((results) => {
      if ('type' in results) {
        resolve(results.type);
        return;
      }
      reject()
    })
    .catch(reject)
})