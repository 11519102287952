import { useEffect, useState } from "react";
import Property1AdminProperty2NoProperty3W from "../Property1AdminProperty2NoProperty3W";
import "./styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faUser, faUsers } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from "react-i18next";

export const LAVENDER = '#a885d7';
export const WHITE = '#FFFFFF';
export const VIOLET = '#590cf6';
export const GRAY = '#969696';
export const ZERO = '#003ddb';
export const DARK_VIOLET = '#28115a';

function Index(props:any) {
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState(props.selected);

  function handleOnClick(type: any, id: any){
    props.handleOnClick(id);
    setSelected(type)
  }

  useEffect(() => {
    setSelected(props.selected)
  }, [props.selected])

  return (
    <div className="icons-navbar">
      <div
        className={
          selected === "Events" ? "icons-column-selected" : "icons-column"
        }
        onClick={() => handleOnClick("Events", 0)}
      >
        <FontAwesomeIcon
          icon={faCalendar}
          size='lg'
          color={
            selected === "Events"
              ? LAVENDER
              : WHITE
          }
        />
        <div
          className={
            selected === "Events"
              ? "icon-label robotocondensed-bold-lavender-16px"
              : "icon-label robotocondensed-normal-white-16px"
          }
        >
          {t('navbar__events')}
        </div>
      </div>
      <div
        className={
          selected === "Clubs" ? "icons-column-selected" : "icons-column"
        }
        onClick={() => handleOnClick("Clubs", 1)}
      >
        <FontAwesomeIcon
          icon={faUsers}
          size='lg'
          color={
            selected === "Clubs"
              ? LAVENDER
              : WHITE
          }
        />
        <div
          className={
            selected === "Clubs"
              ? "icon-label robotocondensed-bold-lavender-16px"
              : "icon-label robotocondensed-normal-white-16px"
          }
        >
          {t('navbar__clubs')}
        </div>
      </div>
      <div
        className={
          selected === "Connections" ? "icons-column-selected" : "icons-column"
        }
        onClick={() => handleOnClick("Connections", 2)}
      >
        <FontAwesomeIcon
          icon={faUser}
          size='lg'
          color={
            selected === "Connections"
              ? LAVENDER
              : WHITE
          }
        />
        <div
          className={
            selected === "Connections"
              ? "icon-label robotocondensed-bold-lavender-16px"
              : "icon-label robotocondensed-normal-white-16px"
          }
        >
          {t('navbar__connections')}
        </div>
      </div>
    </div>
  );
}

export default Index;
