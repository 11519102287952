import "./styles.scoped.css";
import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useTranslation } from "react-i18next";

function Index(props: any) {
    const { t, i18n } = useTranslation();
    const [reason, setReason] = useState('Bullying')

    function handleOnClickSave(id = null, e: any) {
        props.onHide(id, e, reason)
    }

    function selectedReason(e: any) {
        setReason(e.target.value)
    }

    return (
        <>
            <Modal className="modalRepostPost" show={props.show} onHide={props.onHide}>
                <Modal.Header>
                    <h2 className="modal-title">{t('label_reportThisPost')}</h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="textReport">
                        {t('label_theReason')}
                        <p>{t('label_postWillBe')}</p>
                    </div>
                    <div className="selectOptionsReport">
                        <select onChange={(e) => selectedReason(e)}>
                            {(props.reasons || []).map((type: any) => (
                                <option value={type}>{type}</option>
                            ))}
                        </select>
                    </div>
                    {/* <div className="optionsReport">
                        <div className="option-status">
                            <label className="content-input">
                                <input type="radio" name="button" value="1" />
                                <i></i>
                            </label>
                            <label className="labelChechbox" htmlFor="">Inappropiate content</label>
                        </div>
                        <div className="option-status">
                            <label className="content-input">
                                <input type="radio" name="button" value="2" />
                                <i></i>
                            </label>
                            <label className="labelChechbox" htmlFor="">Other</label>
                        </div>
                    </div> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="buttonClose" onClick={() => handleOnClickSave(props.id, 'cancel')}>
                        {t('button__cancel')}
                    </Button>
                    <Button className="buttonSend" onClick={() => handleOnClickSave(props.id, 'save')}>
                        {props.spinnerFlag ? (
                        <div className="icon-spinner-third buttonSendSignUp" />
                        ) : t('button__save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Index;