import "./styles.css";
import InviteAdmin from "../InviteAdmin"
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvitesWithLimit } from "../../store/actions/inviteActions";
import { getOrganizationInfo } from "../../store/actions/orgsActions";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";

const Index = ({ searchSelected, typeSelected, statusSelected }: { searchSelected: string, typeSelected: string, statusSelected: string }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.getUser.token)
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const org = useSelector((state: any) => state.getOrgs.organizationInfo);

  const [selected, setSelected] = useState("");

  const [invites, setInvites] = useState([])

  const handleChange = (e: any) => {
    setSelected(e.label);
    console.log(e.label);
  };

  useEffect(() => {
    const orgId = JSON.parse(userInfo.attributes['custom:organizations'])[0];
    dispatch<any>(getOrganizationInfo(orgId))
  }, []);

  useEffect(() => {
    if (org && 'status' in org) {
      const orgId = JSON.parse(userInfo.attributes['custom:organizations'])[0];
      Backend(
        MicroServices.Invite,
        `/invites?pending=${(org.status as string).startsWith('P')}&org_id=${orgId}&light=true`)
        .then((response) => response.json())
        .then((results) => {
          setInvites(results)
        })
    }
  }, [org])

  const [id, setId] = useState('')
  const [email, setEmail] = useState('')
  const [club, setClub] = useState('')
  const [inviteToken, setinviteToken] = useState('')
  const [status, setStatus] = useState('')
  const [type, setType] = useState('')

  const handleChangeId = (e: any) => {
    setId(e.value);
    dispatch(getInvitesWithLimit({
      'token': token,
      'limit': 100000,
      'page': 0,
      'lastEvaluatedKey': '',
      'id': e.value,
      'status': status
    }));
  }

  const handleChangeStatus = (e: any) => {
    setStatus(e.value);
    dispatch(getInvitesWithLimit({
      'token': token,
      'limit': 100000,
      'page': 0,
      'lastEvaluatedKey': '',
      'id': id,
      'status': e.value,
    }));
  }

  console.log(searchSelected, typeSelected, statusSelected)

  // console.log(invites)

  // console.log(invites.filter((hotel: any) => {
  //     if (hotel.status !== 'all') {
  //       if (hotel.status === statusSelected) {
  //         return hotel
  //       }
  //     }
  //   }))

  // useEffect(() => {
  //   const orgId = JSON.parse(userInfo.attributes['custom:organizations'])[0];
  //   fetch(`${InviteUrl}/v1/invites?pending=${(org.status as string).startsWith('P')}&org_id=${orgId}&light=true&email=29st201hp`)
  //     .then((response) => response.json())
  //     .then((results) => {
  //       setInvites(results)
  //     })
  // }, [searchSelected])

  return (

    <>
      <table className="tableManageUser robotocondensed-bold-white-16px">
        <tr>
          <th>
            <div className="club">
              <div className="club-1 robotocondensed-bold-white-16px">{t('manageInvites__name')}</div>
            </div>
          </th>
          <th>
            <div className="email">
              <div className="email-1 robotocondensed-bold-white-16px">{t('manageInvites__email')}</div>
            </div>
          </th>
          <th>
            <div className="inviteToken">
              <div className="id-1 robotocondensed-bold-white-16px">{t('manageInvites__inviteToken')}</div>
            </div>
          </th>
          <th>
            <div className="Status">
              <div className="Status-1 robotocondensed-bold-white-16px">{t('manageInvites__status')}</div>
            </div>
          </th>
          <th>
            <div className="type">
              <div className="id-1 robotocondensed-bold-white-16px">{t('manageInvites__type')}</div>
            </div>
          </th>
          <th>
            <div className="actions robotocondensed-bold-white-16px">{t('manageInvites__actions')}</div>
          </th>
        </tr>
        {invites.filter((item: any) => {
          if(item.role.includes(typeSelected) && (item.email.toUpperCase().includes(searchSelected) || item.email.toLowerCase().includes(searchSelected)) && item.status.includes(statusSelected)) 
          {
            return true;
          }
          return false;
        }).map((invite: any) => (
          <InviteAdmin invite={invite} />
        ))}
      </table>
    </>
  )
};

export default Index;
