import React, { useEffect, useState } from "react";
import "./styles.css";
import ClubFilterBar from "../../components/ClubFilterBar";
import ListMyEvents from "./ListMyEvents";
import DatePicker from "react-datepicker";
import { connect, useSelector, useDispatch } from "react-redux";
import ModalCreateEvent from "./ModalCreateEvent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../store/store";
import {
  getMyEvent,
  getEventInfo,
  getMyEventsComplete,
  getMyEventsRSVP,
  getMyFriendsEvents,
} from "../../store/actions/eventActions";
import moment, { Moment } from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from "../../components/ClubIconsNavBar";
import { dateToLimitForEvents } from "../../helpers/dateHelper";
import { useTranslation } from "react-i18next";

const sortAlphabeticallyOnProperty = (
  list: any[],
  property: string,
  ordSelected: string = "ASC"
) => {
  if (ordSelected === "DESC") {
    return list.sort((b, a) =>
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    );
  }
  return list.sort((a, b) =>
    a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
  );
};

function Index(props: any) {
  const { t, i18n } = useTranslation();
  const { id = "" } = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const today = new Date();
  const [textToday, setTextToday] = useState<any>(today);
  //console.log(today)

  const [selected, setSelected] = useState("My events");
  const [pickedDate, setPickedDate] = useState<Date>(new Date());
  const [eventActive, setEventActive] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [activeCalendar, setActiveCalendar] = useState(false);
  const [valueFilter, setValueFilter] = useState("");

  const userRoles = useSelector((state: any) => state.getUser.userRoles);
  const user = useSelector((state: any) => state.getUser.userInfo);
  const allEvents = useSelector(
    (state: any) => state.getEvent.getCompleteEvents || []
  );
  const friendEvents = useSelector(
    (state: any) => state.getEvent.getMyFriendsEvents || []
  );
  const myEvents = useSelector(
    (state: any) => state.getEvent.getMyEventsRSVP || []
  );

  const [ordSelected, setOrdSelected] = useState("ASC");
  const [orderField, setOrderField] = useState("start");
  const [isAdmin, setIsAdmin] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    dispatch<any>(getMyEventsComplete(user.username, true, dateToLimitForEvents(pickedDate)));
  };

  function handleClick(event: any) {
    setEventActive(event);
  }

  function handleChangeSearch(e: any) {
    setValueFilter(e);
  }

  const loadAllEvents = () => {
    setSelected("My events");
    dispatch<any>(getMyEventsComplete(user.username, true, dateToLimitForEvents(pickedDate)));
  };

  const loadMyEvents = () => {
    setSelected("School");
    dispatch<any>(getMyEventsRSVP(user.username, true, dateToLimitForEvents(pickedDate)));
  };

  const loadFriendsEvents = () => {
    setSelected("Friends");
    dispatch<any>(getMyFriendsEvents(user.username, true, dateToLimitForEvents(pickedDate)));
  };

  const onChangeDate = (date: Date | null) => {
    setTextToday(moment(date).format("dddd, MMMM DD"))
    if (date !== null) {
      setPickedDate(date)
    }
  };

  useEffect(() => {
    dispatch<any>(getMyEventsComplete(user.username, true));
    dispatch<any>(getMyEventsRSVP(user.username, true));
    dispatch<any>(getMyFriendsEvents(user.username, true));
  }, [])

  useEffect(() => {
    switch (selected) {
      case 'My events':
        loadAllEvents()
        break
      case 'School':
        loadMyEvents()
        break
      case 'Friends':
        loadFriendsEvents()
        break
      default:
        break
    }
  }, [pickedDate])

  useEffect(() => {
    eventsLeftPanel();
  }, [location, allEvents, friendEvents, myEvents])

  const eventsLeftPanel = () => {

    const evn = (location.pathname || '').split('/');

    if (allEvents && allEvents.length > 0) {
      const isAll = allEvents.some((event: any) => (event.event_id === evn[evn.length - 1]))
      if (isAll) {
        setSelected("My events");
        return;
      }
    }

    if (friendEvents && friendEvents.length > 0) {
      const isFriend = friendEvents.some((event: any) => (event.event_id === evn[evn.length - 1]))
      if (isFriend) {
        setSelected("Friends");
        return;
      }
    }

    if (myEvents && myEvents.length > 0) {
      const isMy = myEvents.some((event: any) => (event.event_id === evn[evn.length - 1]))
      if (isMy) {
        setSelected("School");
        return;
      }
    }
  }

  const getFilteredEvents = (listName: any[]) => { 
    return listName.filter((item: any) => {
        if (!valueFilter) return true;
        if (item.name.toUpperCase().includes(valueFilter) ||
            item.name.toLowerCase().includes(valueFilter) ||
            item.name.includes(valueFilter)
        ) {
            return true;
        }
        return false;
    })
  }

  return (
    <>
      <div className="section-navbar">
        <div
          className={
            selected === "My events"
              ? "section-column-selected"
              : "section-column"
          }
          id="left-rad"
          onClick={() => loadAllEvents()}
        >
          <div
            style={{
              backgroundColor:
                selected === "My events" ? "var(--lavender)" : "transparent",
            }}
            className={"section-label robotocondensed-normal-white-16px"}
          >
            {t('bulletin__allEvents')}
          </div>
        </div>
        <div
          className={
            selected === "School" ? "section-column-selected" : "section-column"
          }
          onClick={() => loadMyEvents()}
        >
          <div className={"section-label robotocondensed-normal-white-16px"}>
            {t('bulletin__myEvents')}
          </div>
        </div>
        <div
          className={
            selected === "Friends"
              ? "section-column-selected"
              : "section-column"
          }
          id="right-rad"
          onClick={() => loadFriendsEvents()}
        >
          <div className={"section-label robotocondensed-normal-white-16px"}>
          {t('bulletin__friendsEvents')}
          </div>
        </div>
      </div>
      <ClubFilterBar
        styles="Left"
        inputSearch={handleChangeSearch}
        isEvents={true}
        ordenSelected={setOrdSelected}
        orderField={setOrderField}
        isAdmin={isAdmin}
      />
      <div className="dateToday">
        {moment(textToday).locale(i18n.language).format("dddd, MMMM DD")}
        {activeCalendar ? (
          <button onClick={() => {
            //setPickedDate(today)
            setActiveCalendar(false)
          }}>
            <FontAwesomeIcon
              icon="chevron-down"
              size="lg"
              color={LAVENDER}
              className=" "
            />
          </button>
        ) : (
          <button onClick={() => setActiveCalendar(true)}>
            <FontAwesomeIcon
              icon="chevron-up"
              size="lg"
              color={LAVENDER}
              className=" "
            />
          </button>
        )}
      </div>
      {activeCalendar && (
        <div className="dateEvent">
          <DatePicker
            selected={pickedDate}
            onChange={onChangeDate}
            inline />
        </div>
      )}

      {selected === "My events" ? (
        <>
          <div className="containerButtonCreate">
            {(userRoles.includes("ZAA#ORGADMIN") ||
              userRoles.includes("ZAA#STAFF#ADVISOR") ||
              userRoles.includes("ZAA#SECOND#ADVISOR")) && (
                <>
                  <button
                    className="btnCreateEvent"
                    onClick={() => setShowModal(true)}
                  >
                    {t('bulletin__createEvent')}
                  </button>
                  <ModalCreateEvent show={showModal} onHide={handleCloseModal} />
                </>
              )}
          </div>
          <div className="listMyEvents">
            <div className="containerEvents">
              {sortAlphabeticallyOnProperty(
                getFilteredEvents(allEvents),
                orderField,
                ordSelected
              ).map((event: any) => (
                <>
                  {/* <div className="titleDate">
                      {event.name}
                    </div> */}
                  <ListMyEvents
                    events={event}
                    id={id}
                    userRoles={userRoles}
                    user={user}
                  />
                </>
              ))}

              {getFilteredEvents(allEvents).map((event: any) => (
                  <></>
                ))}
            </div>
          </div>
        </>
      ) : selected === "School" ? (
        <div className="listMyEvents">
          <div className="containerEvents">
            {sortAlphabeticallyOnProperty(
              getFilteredEvents(myEvents),
              orderField,
              ordSelected
            ).map((event: any) => (
              <>
                {/* <div className="titleDate">
                    {event.name}
                  </div> */}
                <ListMyEvents
                  events={event}
                  id={id}
                  userRoles={userRoles}
                  user={user}
                />
              </>
            ))}

            {getFilteredEvents(myEvents)
              .map((event: any) => (
                <></>
              ))}
          </div>
        </div>
      ) : selected === "Friends" ? (
        <div className="listMyEvents">
          <div className="containerEvents">
            {sortAlphabeticallyOnProperty(
              getFilteredEvents(friendEvents),
              orderField,
              ordSelected
            ).map((event: any) => (
              <>
                {/* <div className="titleDate">
                      {event.name}
                    </div> */}
                <ListMyEvents
                  events={event}
                  id={id}
                  userRoles={userRoles}
                  user={user}
                />
              </>
            ))}

            {getFilteredEvents(friendEvents)
              .map((event: any) => (
                <></>
              ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Index;
