import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './styles.scoped.css';
import { responseToUser } from "../../helpers/userHelper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from "../../components/ClubIconsNavBar";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { useTranslation } from "react-i18next";


toast.configure();

function Index(props: any) {
    const { t, i18n } = useTranslation();
    function handleOnClickOk(e: any, del: boolean) {
        if (del) {
            //Delete org
            if (props.youthOrg) {

                Backend(
                    MicroServices.Admin,
                    `/yo/${props.orgId}`,
                    {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                        }
                    })
                    .then((response) => {
                        return {
                            status: response.status,
                            response: response.json()
                        }
                    })
                    .then((results) => {
                        if (results.status === 201 || results.status === 200) {
                            toast.success(t('toast_yoSuccessDelete'))
                        } 
                        else if (results.status === 405) {
                            toast.error(t('toast_yoErrorDelete'))
                        }
                        else {
                            toast.error(t('toast_anErrorOcurred'))
                        }
                    })
                    .catch((error) => toast.error(t('toast_anErrorOcurred')))
            }
            else {

                Backend(
                    MicroServices.Admin,
                    `/organizations/${props.orgId}`,
                    {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                        }
                    })
                    .then((response) => {
                        return {
                            status: response.status,
                            response: response.json()
                        }
                    })
                    .then((results) => {
                        if (results.status === 201) {
                            toast.success(t('toast_yoSuccessDelete'))
                        } else {
                            toast.error(t('toast_anErrorOcurred'))
                        }
                    })
                    .catch((error) => toast.error(t('toast_anErrorOcurred')))
            }
        }
        props.onHide(e)
    }

    return (
        <>
            <Modal className="modalDeleteOrg" show={props.show} onHide={props.onHide}>
                <Modal.Header>
                    <Modal.Title></Modal.Title>
                    <FontAwesomeIcon
                        icon="times"
                        size='xl'
                        color={WHITE}
                        className="icon-times  "
                        onClick={(e) => handleOnClickOk(e, false)}
                    />
                </Modal.Header>
                <Modal.Body>
                    <FontAwesomeIcon
                        icon="exclamation"
                        size='xl'
                        color={WHITE}
                        className="chevron-down iconCheckOk  "
                    />
                    <span className="labelEventCreated"> Do you want to delete Organization {props.orgId}?</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="buttonCancel" onClick={(e) => handleOnClickOk(e, false)}>
                        Cancel
                    </Button>
                    <Button className="buttonDelete" onClick={(e) => handleOnClickOk(e, true)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Index;