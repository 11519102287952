import styled from "styled-components";
import { ErrorField } from "../../../components";
import CheckboxOption from "./CheckboxOption";

interface CheckboxListProps {
    title: string;
    options: {
        label: string;
        value: string;
    }[];
    valuesSelected: string[];
    showError: boolean;
    error: string;
    onChangeValue: (value: string) => void;
}

const CheckboxList = ({
    title,
    options,
    valuesSelected,
    showError,
    error,
    onChangeValue,
}: CheckboxListProps) => {
    return (
        <CheckboxListWrapper>
            <CheckboxListTitle>{title}</CheckboxListTitle>
            <CheckboxListOptions>
                {options.map((props) => (
                    <CheckboxOption
                        checked={valuesSelected.includes(props.value)}
                        onChange={() => onChangeValue(props.value)}
                        {...props}
                    />
                ))}
            </CheckboxListOptions>
            {(showError) && (<ErrorField>{error}</ErrorField>)}
        </CheckboxListWrapper>
    )
}

const CheckboxListWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const CheckboxListOptions = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const CheckboxListTitle = styled.h2`
    color: #FFFFFF77;
    font-size: 16px;
    font-family: 'Roboto';
    text-align: left;
`;

export default CheckboxList;