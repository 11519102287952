// Get organizations
export const START_GET_ORGS = 'START_GET_ORGS';
export const GET_ORGS_SUCCESS = 'GET_ORGS_SUCCESS';
export const GET_ORGS_ERROR = 'GET_ORGS_ERROR';

export const START_GET_ORGS_LIMIT = 'START_GET_ORGS_LIMIT';
export const GET_ORGS_LIMIT_SUCCESS = 'GET_ORGS_LIMIT_SUCCESS';
export const GET_ORGS_LIMIT_ERROR = 'GET_ORGS_LIMIT_ERROR';

export const START_ORG_STATUS = 'START_ORG_STATUS';
export const ORG_STATUS_SUCCESS = 'ORG_STATUS_SUCCESS';
export const ORG_STATUS_ERROR = 'ORG_STATUS_ERROR';

export const START_ORG_TYPE = 'START_ORG_TYPE';
export const ORG_TYPE_SUCCESS = 'ORG_TYPE_SUCCESS';
export const ORG_TYPE_ERROR = 'ORG_TYPE_ERROR';

export const START_ORG_TITLE = 'START_ORG_TITLE';
export const ORG_TITLE_SUCCESS = 'ORG_TITLE_SUCCESS';
export const ORG_TITLE_ERROR = 'ORG_TITLE_ERROR';

export const START_ORG_NAMES = 'START_ORG_NAMES';
export const ORG_NAMES_SUCCESS = 'ORG_NAMES_SUCCESS';
export const ORG_NAMES_ERROR = 'ORG_NAMES_ERROR';

export const START_GET_ORG = 'START_GET_ORG';
export const GET_ORG_SUCCESS = 'GET_ORG_SUCCESS';
export const GET_ORG_ERROR = 'GET_ORG_ERROR';

export const START_APPROVE_ORG = 'START_APPROVE_ORG';
export const APPROVE_ORG_SUCCESS = 'APPROVE_ORG_SUCCESS';
export const APPROVE_ORG_ERROR = 'APPROVE_ORG_ERROR';

export const START_UPDATE_ORG = 'START_UPDATE_ORG';
export const UPDATE_ORG_SUCCESS = 'UPDATE_ORG_SUCCESS';
export const UPDATE_ORG_ERROR = 'UPDATE_ORG_ERROR';

export const START_CLUB_NAMES = 'START_CLUB_NAMES';
export const CLUB_NAMES_SUCCESS = 'CLUB_NAMES_SUCCESS';
export const CLUB_NAMES_ERROR = 'CLUB_NAMES_ERROR';

export const START_CITY_NAMES = 'START_CITY_NAMES';
export const CITY_NAMES_SUCCESS = 'CITY_NAMES_SUCCESS';
export const CITY_NAMES_ERROR = 'CITY_NAMES_ERROR';

export const START_UPLOAD_ASSET_ORG = 'START_UPLOAD_ASSET_ORG';
export const UPLOAD_ASSET_ORG_SUCCESS = 'UPLOAD_ASSET_ORG_SUCCESS';
export const UPLOAD_ASSET_ORG_ERROR = 'UPLOAD_ASSET_ORG_ERROR';

export const START_UPLOAD_LOGO_ORG = 'START_UPLOAD_LOGO_ORG';
export const UPLOAD_LOGO_ORG_SUCCESS = 'UPLOAD_LOGO_ORG_SUCCESS';
export const UPLOAD_LOGO_ORG_ERROR = 'UPLOAD_LOGO_ORG_ERROR';

export const START_UPDATE_LOGO_ORG = 'START_UPDATE_LOGO_ORG';
export const UPDATE_LOGO_ORG_SUCCESS = 'UPDATE_LOGO_ORG_SUCCESS';
export const UPDATE_LOGO_ORG_ERROR = 'UPDATE_LOGO_ORG_ERROR';

export const START_GET_STAFF_USERS = 'START_GET_STAFF_USERS';
export const GET_STAFF_USERS_SUCCESS = 'GET_STAFF_USERS_SUCCESS';
export const GET_STAFF_USERS_ERROR = 'GET_STAFF_USERS_ERROR';

export const START_YOUTH_SCHOOLS = 'START_YOUTH_SCHOOLS';
export const YOUTH_SCHOOLS_SUCCESS = 'YOUTH_SCHOOLS_SUCCESS';
export const YOUTH_SCHOOLS_ERROR = 'YOUTH_SCHOOLS_ERROR';

export const LOGOUT_ORG = 'LOGOUT_ORG';