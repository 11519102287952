import React, {
  KeyboardEventHandler,
  useState,
  useEffect
} from 'react';
import "./styles.css";
import "./styles.scoped.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser, updateProfilePhoto, uploadAsset, uploadImage } from '../../store/actions/userActions';
import { cancelFriendRequest, createFriendRequest, saveNewFriend } from '../../store/actions/connectionActions';
import CreatableSelect from 'react-select/creatable';
import { GroupBase, MultiValue, OnChangeValue, PropsValue, StylesConfig } from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalImageEditor from '../ModalImageEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WHITE } from '../ClubIconsNavBar';
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from '../../helpers/backendHelper';

toast.configure();
interface Option {
  readonly label: string;
  readonly value: string;
}

function Index(props: any) {
  const { t, i18n } = useTranslation();
  const { username } = useParams();
  const dispatch = useDispatch();

  const createOption = (label: string) => ({
    label,
    value: label,
  });

  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const userAll = useSelector((state: any) => state.getUser.user);
  const userFriends = useSelector((state: any) => state.getConnection.userFriends || []);
  const userConnections = useSelector((state: any) => state.getConnection.userPossibleConnections || []);
  const userPendingRequest = useSelector((state: any) => state.getConnection.userPendingRequests || []);
  const userSentRequest = useSelector((state: any) => state.getConnection.userSentRequests || []);
  const userRoles = useSelector((state: any) => state.getUser.userRoles);

  const [typeFiter, setTypeFilter] = useState('1');
  const [user, setUser] = useState(userAll);
  //const [editVisible, setEditVisible] = useState(false);
  const [biography, setBiography] = useState('');
  const [userInterests, setUserInterests] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [userProfile, setUserProfile] = useState('');
  const [loadingEditProfile, setLoadingEditProfile] = useState(false);

  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<OnChangeValue<Option, true>>(userInterests.map((item: any) => createOption(item)));
  const [textSendRequest, setTextSendRequest] = useState<any>(t('infoConnections__sendRequest'));
  const [textAcceptRequest, setTextAcceptRequest] = useState(false);

  const skillsLearnedStyles: StylesConfig<Option, boolean, GroupBase<Option>> = {
    singleValue: base => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      backgroundColor: 'red',
      fontFamily: 'Roboto Condensed',
    }),
    control: styles => ({
      ...styles,
      backgroundColor: '#28115A',
      borderRadius: '3px',
      boxShadow: 'none',
      border: 0,
      minWidth: '50%',
    }),
    option: styles => ({
      ...styles,
      color: 'white',
      backgroundColor: 'var(--violent-violet)',
      display: 'flex',
      flexDirection: 'row',
      fontFamily: 'Roboto Condensed',
    }),
    placeholder: styles => ({
      ...styles,
      color: 'white',
      textAlign: 'left',
      fontFamily: 'Roboto Condensed',
    }),
    container: styles => ({
      ...styles,
      padding: 0,
      width: '100%',
    }),
    menu: styles => ({
      ...styles,
      borderRadius: '8px',
      backgroundColor: 'var(--violent-violet)',
    }),
    input: (styles: any, state: any) => ({
      ...styles,
      border: 'none',
      color: 'white',
      fontFamily: 'Roboto Condensed',
    }),
    dropdownIndicator: styles => ({ ...styles, display: 'none' }),
    indicatorSeparator: (styles: any) => ({ ...styles, display: 'none' }),
    multiValue: styles => ({
      ...styles,
      color: 'white',
      backgroundColor: 'var(--electric-violet-2)',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
      fontFamily: 'Roboto Condensed',
      fontSize: '16px',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      backgroundColor: 'transparent',
    }),
  };

  useEffect(() => {
    if (props.isUserProfile && !userAll) {
      dispatch<any>(getUser(userInfo.username))
        .then((results: any) => {
          setUser(results);
          setUserProfile(results.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png');
        });
    }
    else if (props.isUserProfile && userAll) {
      setUser(userAll);
      setUserProfile(userAll.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png');
    }
    else {
      Backend(
        MicroServices.Admin,
        `/users/${username}?roleRequest=OA`)
        .then((response) => response.json())
        .then((results) => {
          setUser(results);
          setUserProfile((results.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'));
        })
        .catch(console.error);
    }

    handleFriendButton(username || '');
    getInterests();
  }, [username, props.isUserProfile]);

  useEffect(() => {
    setValue(userInterests.map((item: any) => createOption(item)));
  }, [userInterests])

  useEffect(() => {
    console.log(user);
  }, [user])

  const getInterests = () => {
    Backend(
      MicroServices.Connections,
      `/profile/interests/user?username=${username}`)
      .then((response) => response.json())
      .then((results) => {
        setUserInterests(results);
      })
      .catch(console.error);
  }

  const handleFriendButton = (username: string) => {
    setTypeFilter('4');
    console.log(userFriends)
    console.log(userPendingRequest)
    console.log(userConnections)
    console.log(userSentRequest)

    //Is The connection a friend?
    if (userFriends.find((user: any) => user.friendUsername === username)) {
      setTypeFilter('1');
    }
    //Is the connection a pending friend?
    else if (userPendingRequest.find((user: any) => user.usernameFrom === username)) {
      setTypeFilter('2');
    }
    //Is the connection a sent friend?
    else if (userSentRequest.find((user: any) => user.usernameTo === username)) {
      setTypeFilter('3');
    }
    //Is a possible connection?
    else if (userConnections.find((user: any) => user.usernameConnection === username)) {
      setTypeFilter('4');
    }
    //The users cannot be friends
    else {
      setTypeFilter('pass');
    }
  }

  const cancelRequest = () => {
    console.log('Request to cancel to', username);
    dispatch<any>(cancelFriendRequest(userInfo.username, username || ''))
      .then(() => setTypeFilter('4'));
  }

  const acceptFriend = () => {
    console.log('Request to accept from', username);
    setTextAcceptRequest(true)
    dispatch<any>(saveNewFriend(userInfo.username, username || ''))
      .then(() => setTypeFilter('1'));
  }

  const newFriendRequest = () => {
    console.log('A new friend request has been sent to', username);
    setTextSendRequest('Friend Request Sent')
    dispatch<any>(createFriendRequest(userInfo.username, username || ''))
      .then(() => setTypeFilter('3'));
  }

  const components = {
    DropdownIndicator: null,
  };

  const handleChange = (
    value: PropsValue<Option>,
  ) => {
    if (value) {
      setValue(value as MultiValue<Option>);
    }
  };

  const handleInputChange = (inputValue: any) => {
    setInputValue(inputValue);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = event => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setInputValue('');
        if (!value.map((item) => item.value).includes(inputValue)) setValue([...value, createOption(inputValue)]);
        //setClub({...club,skills:[...club.skills || [], inputValue]})
        event.preventDefault();
    }
  };

  const saveEditProfile = () => {
    setLoadingEditProfile(true);
    console.log('new Biography', biography);
    console.log('new Interests', value);

    Backend(
      MicroServices.Roster,
      `/users/${username}/bio`,
      {
        method: "PUT",
        body: JSON.stringify({
          bio: biography
        })
      }
    )
      .then((response) => {
        if (response.status === 201) {
          return Backend(
            MicroServices.Connections,
            `/profile/interests`,
            {
              method: "PUT",
              body: JSON.stringify({
                username: username,
                interests: formatInterests(value),
                organizationId: user.organizations[0]
              })
            }
          );
        }
        return Promise.reject();
      })
      .then((results) => {
        if (results.status === 201) {
          props.setEditVisible(false);
          toast.success(t('toast_informationUpdated'));
          getInterests();
          dispatch<any>(getUser(userInfo.username))
            .then((results: any) => {
              setUser(results);
            });
        }
      })
      .catch(console.error)
      .finally(() => { setLoadingEditProfile(false) });
  }

  const formatInterests = (value: any) => {
    const interests: any = [];

    value.forEach((element: any) => {
      interests.push(element.value);
    });

    return interests;
  }

  const cancelEditProfile = () => {
    props.setEditVisible(false);
    setBiography(user.biography);
    setValue(userInterests.map((item: any) => createOption(item)));
  }

  function handleSaveImage(e: any) {

    fetch(e)
      .then(res => res.blob())
      .then(blob => {
        const contentType = e.split(';')[0].split(':')[1];
        const extension = contentType.split('/')[1]
        const file = new File([blob], 'profile' + userInfo.username, { type: contentType });

        if (file) {
          dispatch<any>(uploadAsset(
            file.type,
            file.name + '.' + extension,
            "profile_photo"
          ))
            .then((results: any) => {
              if (results.body) {
                dispatch<any>(uploadImage(
                  results.body,
                  contentType,
                  file,
                ))
                  .then((result: any) => {
                    if (result.status === 200) {
                      const urlImage = results.body.split('?')[0];
                      setUserProfile(urlImage);
                      dispatch<any>(updateProfilePhoto(
                        urlImage,
                        userInfo.username,
                      ))
                        .then((result: any) => {
                          if (result.status === 200) {
                            setUserProfile(urlImage);
                            setShowModal(false);
                          }
                          else {
                            setShowModal(false);
                          }
                        })
                        .catch((error: any) => {
                          console.log(error)
                        })
                    }
                  })
                  .catch((error: any) => {
                    console.log(error)
                  })

              }
              else {
                toast.error(t('toast_errorImageUrl'));
              }
            })
            .catch((error: any) => {
              console.log(error)
            })
        }
        else {
          toast.error(t('toast_addFile'));
        }

      })
  };

  const closeModal = (e?: any, type?: any) => {
    setShowModal(false);
    console.log('closed modal edit profile photo -> Profile');
  }

  return (
    <>
      {/*<div className="infoProfile">

        <div className='imageProfile'>
          {(showModal) && (<ModalImageEditor handleSaveImage={handleSaveImage} show={showModal} onHide={closeModal} isProfilePhoto={true}/>)}
          <img src={userProfile !== '' ? userProfile : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'} alt="" onClick={() => setShowModal(true)}/>
        </div>

        <div>
          <p className="nameProfile">{user.firstname} {user.lastname}</p>
          <ul>
            {user.organizationsNames.map((request: any) => (
              <li className='listOrgs'>{request}</li>
            ))}
          </ul>
        </div>

        {(props.isUserProfile && !editVisible) && 
        (
        <div>
          <FontAwesomeIcon
            icon="pen"
            size="sm"
            color={WHITE}
            className="iconEditInfo pointer"
            onClick={() => setEditVisible(true)}
          />

          <FontAwesomeIcon
            icon="gear"
            size="sm"
            color={WHITE}
            className="iconEditInfo pointer"
            onClick={() => setEditVisible(true)}
          />
        </div>
        )}

      </div>*/}

      {(!props.editVisible) &&
        (<div className="edit">
          <div className="aboutProfile">
            <h2 className='titleContainer'>
              {(props.isUserProfile) ? (t('profile__aboutMe')) : (`${t('profile__about')} ${user.firstname}`)}
            </h2>
            <span>
              {user.biography || t('profile__noInformationYet')}
            </span>
          </div>
          <div className="interestProfile">
            <h2 className='titleContainer'>{t('profile__Interests')}</h2>
            <ul className='listInterest'>
              {userInterests.map((request: any) => (
                <li className='itemInterest'>{request}</li>
              ))}
            </ul>
          </div>
        </div>)}

      {(props.isUserProfile && props.editVisible) &&
        (<div className="btnProfile">

          <div className="titleContainer">
            {(props.isUserProfile) ? (t('profile__aboutMe')) : (`${t('profile__about')} ${user.firstname}`)}
          </div>
          <div className="inputInfo">
            <textarea name="" id="" placeholder={user.biography || t('profile__tellUs')} onChange={(e: any) => setBiography(e.target.value)}></textarea>
          </div>

          <div className="titleContainer">{t('profile__Interests')}</div>
          <div className="inputInfoInterests">
            <CreatableSelect
              components={components}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={handleChange}
              onInputChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder=""
              value={value}
              styles={skillsLearnedStyles}
            />
          </div>

          <div className="row btnsProfile noPadding">
            <button className='btnEditProfile' onClick={cancelEditProfile}>{t('button__cancel')}</button>
            <button className='btnFriendsProfile' onClick={saveEditProfile}>
              {loadingEditProfile ?
                <div className="icon-spinner-third buttonApproveOrg"></div>
                :
                t('button__save')
              }
            </button>

          </div>
        </div>
        )}

      {(!props.isUserProfile && typeFiter !== 'pass') &&
        (<div className="row btnProfile">
          {/*<button className='btnEditProfile'>Start a Conversation</button>*/}
          {(typeFiter === '1') &&
            (<div className="rowButtonFriends btnFriendsProfile">
              <FontAwesomeIcon
                className="iconOptions icon-no-border"
                icon="star"
                size="sm"
                color={WHITE}
              />
              <span className="roboto-normal-white-16px">{t('profile__youAreFriends')}</span>
            </div>
            )}
          {(typeFiter === '2') && (
            <button className='btnFriendsProfile' onClick={acceptFriend}>
              {!textAcceptRequest ?
                'Accept Friend Request'
                :
                <div className="rowButtonFriends btnFriendsProfile">
                  <FontAwesomeIcon
                    className="iconOptions icon-no-border"
                    icon="star"
                    size="sm"
                    color={WHITE}
                  />
                  <span className="roboto-normal-white-16px">{t('profile__youAreFriends')}</span>
                </div>
              }

            </button>)}
          {(typeFiter === '3') && (<button className='btnFriendsProfile' onClick={cancelRequest}>{t('profile__cancelFriendRequest')}</button>)}
          {(typeFiter === '4') && (!(!userRoles.includes('ZAA#STUDENT') && user.roles.includes('ZAA#STUDENT')) &&
            <button className='btnFriendsProfile' onClick={newFriendRequest}>
              {textSendRequest}
            </button>
          )}
        </div>
        )}

    </>

  );
}

export default Index;
