import "../../containers/SetupSchool/styles.scoped.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationInfo } from "../../store/actions/orgsActions";
import { WHITE } from "../ClubIconsNavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";

const checkCommunitySetup = (club_id: string, name: string, pending = "true", username = ''): Promise<boolean> =>
  new Promise((resolve, reject) => {
    if (name === 'Alumni' || name === 'Guardians') {
      Backend(
        MicroServices.Invite,
        `/invites?pending=${pending}&club_id=${club_id}`
      )
        .then((response) => response.json())
        .then((results) => {
          if (results.find((invite: any) => invite.role.includes('ADVISOR'))) {
            resolve(true);
            return;
          }
          else {
            Backend(
              MicroServices.Club,
              `/clubs/${club_id}`,
              {
                method: 'PUT',
                body: JSON.stringify({
                  "is_setup": false
                }),
              }
            )
              .then((response) => {
                if (response.status === 200) {
                  resolve(true);
                } else {
                  resolve(false);
                }
                return;
              })
          }
        })
        .catch((e) => reject(e))
    }
    else {
      Promise.all([
        Backend(
          MicroServices.Invite,
          `/invites?pending=${pending}&club_id=${club_id}`
        ),
        Backend(
          MicroServices.Club,
          `/clubs/${club_id}/code`
        ),
        Backend(
          MicroServices.Club,
          `/clubs/${club_id}?pending=true`
        )
      ])
        .then((responses) => Promise.all(responses.map((response) => response.json())))
        .then(([results, resultsClubCode, resultsClub]) => {
          if ((
              results.find((invite: any) => !invite.role.includes('ADVISOR')) ||
              ('code' in resultsClubCode)
            ) && results.find((invite: any) => invite.role.includes('ADVISOR'))
          ) {
            resolve(true) 
          } else if (resultsClub.role_type == 'ZAA#STAFF' && (
            results.find((invite: any) => !invite.role.includes('ADVISOR')) ||
            ('code' in resultsClubCode)
          )) {
            Backend(
              MicroServices.Club,
              `/clubs/${club_id}/advisor?username=${username}`, 
              {
                  method: "POST",
                  headers: {
                  "Content-Type": "application/json",
                  }
              })
              .then((response) => response.json())
              .then((results) => {
                resolve(true);
              })
              .catch((error) => {
                resolve(false);
              })
          }
          else { 
            resolve(false)
          }
          return;
        })
        .catch((e) => {
          reject(e)
        })
    }
  })

function Index(props: any) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [goodSetup, setGoodSetup] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const isOrgPending = (orgStatus: string): string => {
    return (orgStatus.startsWith('PEND')) ? 'true' : 'false';
  }

  const myUsername = useSelector((state: any) => state.getUser.userInfo.username);

  useEffect(() => {
    if (props.organization) {
      Backend(
        MicroServices.Club,
        `/clubs?org_id=${props.organization.id}`)
        .then((response) => response.json())
        .then((results) => {
          const dynamo = results.clubs
            .filter((club: any) => club.club_type === 'COMM')
          return dynamo
        })
        .then((dynamo) => {
          if (dynamo.length < 4) {
            setGoodSetup(false)
            setLoading(false)
            return
          }
          const promises: Promise<boolean>[] = dynamo.map((comm: any) => checkCommunitySetup(comm.id, comm.name, isOrgPending(props.organization.status), myUsername))
          Promise.all(promises)
            .then((results: boolean[]) => {
              setGoodSetup(results.reduce((acc, curr) => acc && curr, true))
              setLoading(false)
            })
            .catch((e) => {
              console.log(e)
              setLoading(false)
            })
        })
    }
  }, [])

  useEffect(() => {
    if (goodSetup) Backend(
      MicroServices.AccessManager,
      `/organizations/${props.organization.id}/setup-complete`, { method: 'POST' })
      .then((results) => {

        dispatch<any>(getOrganizationInfo(props.organization.id))
          .then(() => {
            props.setShowSetupSchool(false);
          })
      })
  }, [goodSetup])

  return (
    <div className="container-complete">
      <div className="complete-text">
        <h1>{loading ? `${props.organization.name} is ready to join into Zaahah!` : goodSetup ? `${props.organization.name} is ready to join into Zaahah!` : 'Missing steps!'}</h1>
        <h2>{loading ?
          `Loading ${props.organization.type === 'YOUTHORG' ? 'Organization' : 'School'} Bulletin...`
          :
          goodSetup ?
            `Loading ${props.organization.type === 'YOUTHORG' ? 'Organization' : 'School'} Bulletin` :
            'Please finish setting up your communities. Remember to invite staff, class advisors and students.'}
        </h2>
        {loading || goodSetup ? (
          <FontAwesomeIcon
            className='icon-spinner-third fontawesome5pro-rotate'
            icon="spinner-third"
            size='xl'
            color={WHITE}
          />
        ) : (<></>)}
      </div>
    </div>
  );
}

export default Index;
