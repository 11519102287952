import "../styles.scoped.css";
import '../styles.css';
import CardCommunities from "./CardCommunitites";
import OrganizationComplete from "../../../interfaces/organization/OrganizationComplete";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { Advisor } from "../../SetupSetup/Communities";
export interface CommunityYouthItem {
    id: number;
    title: string;
    name: string;
    icon: string;
    desc: string;
    btnActive: boolean;
    active: boolean;
    advisors: Advisor[],
    invitesSend: number;
    club_id?: string;
}

function CommunitiesYouth(props: any) {

    const schools = useSelector((state: RootState) => state.getOrgs.schoolsYouth);
    const organization = useSelector(
        (state: any) => state.getOrgs.organizationInfo
    );

    return (
        <>
            <div className="container-myschool">
                <h1>Zaahah</h1>
                <span>Invite members and advisors that are related with the schools that belong to {organization?.name}.</span>
                <div className="btnWrapper">
                    <button
                        name="btn-Step2-Save"
                        className="button-save"
                        onClick={(e) => props.handleOnClick(e, 6)}>
                        Continue
                    </button>
                </div>
            </div>

            <div className="communities-schools">
                {schools.map((school: OrganizationComplete) => (
                    <CardCommunities
                        organization={school}
                    />
                ))}
            </div>



        </>

    );
}

export default CommunitiesYouth;
