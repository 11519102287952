import { useEffect, useMemo, useState } from "react";
import { render } from "react-dom";
import "./styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteMyJoinRequest, getClub, getMyPendingClubs } from "../../store/actions/clubActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons";
import { WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";

const HoverableDiv = ({
  handleMouseEnter,
  handleMouseLeave,
  selected,
  infoClub
}: any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  );
  const userRoles = useSelector((state: any) => state.getUser.userRoles);

  const correctId = () => {
    return (infoClub.id !== undefined) ? infoClub.id : infoClub.club_id;
  }

  const correctCount = () => {
    return (infoClub.members !== undefined) ? infoClub.members : infoClub.membersCount;
  }

  return (
    <div className={`mainInfo ${!infoClub.is_setup && 'clubPendingSetup'}`} onClick={() => navigate('../app/clubs/' + correctId())}>
      <div
        className="clubrow-container"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >

        <img
          className="club-image"
          src={infoClub.logo || organization.logo || 'https://htmlcolorcodes.com/assets/images/colors/dark-gray-color-solid-background-1920x1080.png'}
          alt="club-image"
        />
        <div className="club-column">
          <h1 className="robotocondensed-bold-white-16px clubNameText">{infoClub.name}</h1>
          {(organization.type === 'YOUTHORG' || userRoles.includes("SUPER")) &&
            (<span className="robotocondensed-normal-lavender-16px">{infoClub.org_name}</span>)}
          <div className="club-school robotocondensed-normal-white-14px">
            {correctCount()} {t('comm__members')}
          </div>
        </div>
      </div>
    </div>
  );
};

const EmptyDiv = ({ handleMouseEnter, handleMouseLeave, onClick, infoClub, detailModal }: any) => {

  const club_id = useMemo(() => {
    return (infoClub.id !== undefined) ? infoClub.id : infoClub.club_id;
  }, [infoClub])

  const navigate = useNavigate();

  const correctCountEvents = () => {
    return (infoClub.events !== undefined) ? infoClub.events : infoClub.eventsCount;
  }

  const correctCountPosts = () => {
    return (infoClub.posts !== undefined) ? infoClub.posts :
      (infoClub.postCount !== undefined ? infoClub.postCount : infoClub.postsCount);
  }

  const correctCountMembers = () => {
    return (infoClub.members !== undefined) ? infoClub.members : infoClub.membersCount;
  }

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      <div className="clubrow-container-empty" onClick={() => detailModal ? '' : navigate('../app/clubs/' + club_id)}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {(infoClub.is_setup) ? (
            <>
              <div className="row-hover">
                <FontAwesomeIcon
                  icon="list-alt"
                  size='lg'
                  color={WHITE}
                  className="iconMenu iconUsers  "
                />
                <div
                  style={{ marginLeft: "6px" }}
                  className="robotocondensed-normal-white-16px"
                >
                  {correctCountPosts()}
                </div>
              </div>
              <div className="row-hover">
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  size='lg'
                  color={WHITE}
                  className="iconMenu iconUsers"
                />
                <div
                  style={{ marginLeft: "6px" }}
                  className="robotocondensed-normal-white-16px"
                >
                  {correctCountEvents()}
                </div>
              </div>
              <div className="row-hover">
                <FontAwesomeIcon
                  icon="user-group"
                  size='lg'
                  color={WHITE}
                  className="iconMenu iconUsers  "
                />
                <div
                  style={{ marginLeft: "6px" }}
                  className="robotocondensed-normal-white-16px"
                >
                  {correctCountMembers()}
                </div>
              </div>
            </>
          ) : (
            <span className="HoveringClub-LeftPanel-Pending">Setup Pending</span>
          )}
        </div>
      </div>
    </div>
  );
};

function Index({ infoClub, screen, isAdmin, detailModal, idClub, pendingClubModal }: any) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id = '' } = useParams();
  const { t, i18n } = useTranslation();

  const [selected, setSelected] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const [loaderButtonCancel, setLoaderButtonCancel] = useState(false)

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseEnterEmpty = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const deleteJoinRequest = (club_id: string) => {

    setIsDisabled(true);
    setLoaderButtonCancel(true)

    dispatch<any>(deleteMyJoinRequest(club_id, userInfo.username))
      .then((results: any) => {
        setIsDisabled(false);
        setLoaderButtonCancel(false)
        navigate('/app')
        dispatch<any>(getMyPendingClubs(userInfo.username))
          .then(console.log)
          .catch(console.error)
      })
      .catch((error: any) => {
        setIsDisabled(false);
        console.error(error)
      });
  }

  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  );

  const correctId = () => {
    return (infoClub.id !== undefined) ? infoClub.id : infoClub.club_id;
  }

  const handleClickClub = (e: any, type: any) => {
    setSelected(true)
    if (e.club_id) dispatch(getClub(e.club_id))
    if (detailModal) {
      idClub(e)
      pendingClubModal(type)
    }
  }

  if (screen === "Pending" && !isAdmin) {
    return (
      <div style={{ width: "100%" }}>
        <div className="itemClubs pendingclub" id={correctId() === id ? "color" : "transparent"}>
          <HoverableDiv
            handleMouseEnter={handleMouseEnter}
            selected={selected}
            infoClub={infoClub}
          />
          <EmptyDiv
            onClick={() => handleClickClub(infoClub, 'Pending')}
            handleMouseEnter={handleMouseEnterEmpty}
            handleMouseLeave={handleMouseLeave}
            infoClub={infoClub}
            detailModal={detailModal}

          />
          {/* {isHovering ? (

          ): (

            )} */}
          <button className={`cancel-club robotocondensed-normal-white-16px ${loaderButtonCancel && 'btnLoaderCancel'}`}
            onClick={() => deleteJoinRequest(correctId())}
            disabled={isDisabled}>
            {loaderButtonCancel ?
              <div className="icon-spinner-third buttonSendSignUp" />
              : t('button__cancel')}
          </button>
        </div>
      </div>
    );
  }
  else {
    return (
      <div style={{ width: "100%" }} onClick={() => handleClickClub(infoClub, 'Discover')}>
        <div className="itemClubs" id={correctId() === id ? "color" : "transparent"}>
          <HoverableDiv
            handleMouseEnter={handleMouseEnter}
            selected={selected}
            infoClub={infoClub}
          />
          <EmptyDiv
            onClick={() => setSelected(true)}
            handleMouseEnter={handleMouseEnterEmpty}
            handleMouseLeave={handleMouseLeave}
            infoClub={infoClub}
            detailModal={detailModal}
          />
          {/* {isHovering ? (
          
        ) : (
          
        )} */}
        </div>
      </div>
    );
  }
}

export default Index;
