import "./styles.scoped.css";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { isEmail } from "../../guards/SignupOrg";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { useTranslation } from "react-i18next";

toast.configure();

function Index(props: any) {
  const { t, i18n } = useTranslation();
  const [tabActive, setTabActive] = useState(0);
  const [emailString, setEmailString] = useState('');
  const [loadingAtteendees, setLoadingAttendees] = useState(false);

  const { id = '' } = useParams();

  const cancelInvites = () => {
    setEmailString('');
    props.onHide()
    setLoadingAttendees(false);
  }

  const addMemberNoRoleAssigendClub = (email: string) => new Promise((resolve, reject) => {
    Backend(
      MicroServices.Club,
      `/clubs/${id || props.club_id || ''}/no-role-signed`,
      {
        method: "POST",
        body: JSON.stringify({
          email: email
        }),
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then((response) => {
        if (response.status === 404) {
          toast.error(`${email} ${t('toast_emailIsNotRegistered')}`);
          return reject(false);
        }

        if (response.status === 405) {
          toast.error(`${email} ${'toast_isAlreadyClub'}`);
          return reject(false);
        }

        if (response.status !== 201) {
          toast.error(t('toast_ThereWasError'));
          return reject(false);
        }
        else {
          return resolve(true);
        }

      })
      .catch((error) => {
        console.log(error)
      })
  })

  const inviteAtteendees = () => {
    setLoadingAttendees(true);

    const emails = emailString.split(",");

    const toSend: string[] = [];

    for (let email of emails) {
      const e = email.replace(/\s+/g, '');
      if (!isEmail(e)) {
        toast.error(`${email} ${t('toast_isNotValidEmail')}`);
        setLoadingAttendees(false);
        return;
      }
      else {
        toSend.push(e);
      }
    }

    Promise.all(
      toSend.map((email: string) => addMemberNoRoleAssigendClub(email)
      ))
      .then((results) => {
        toast.success(t('toast_membersAdded'));
        cancelInvites();
        props.reloadMembers();
      })
      .catch((error) => {
        cancelInvites();
        console.error(error);
      });
  }

  return (
    <>
      <Modal className="modalInviteAttendees" show={props.show} onHide={cancelInvites}>
        <Modal.Header>
          <p className="titleModal">{t('options__inviteMembers')}</p>
          <p className="modal-header">{t('setupStaff__enterEmail')}</p>
          <textarea name="" id="" placeholder={emailString} onChange={(e: any) => setEmailString(e.target.value)}></textarea>
        </Modal.Header>
        <Modal.Footer>
          <Button className="buttonClose" onClick={cancelInvites}>
            {t('button__cancel')}
          </Button>
          <Button name="btn-sendInviteMember"
            className={`buttonSend ${loadingAtteendees && 'inProcess'}`}
            onClick={inviteAtteendees}>
            {loadingAtteendees ? (
              <div className="icon-spinner-third buttonSendSpinner"></div>
            ) : t('button__sendInvites')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Index;