import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import React, { useState } from "react";
import "../containers/Login/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LAVENDER, VIOLET, WHITE } from "./ClubIconsNavBar";
import {
    InputLabelSignup,
    InputSignup,
    InputWrapperSignup,
    PassEyeBtn,
} from "../containers/LoginWrp/components";

interface LoginInputProps {
    placeholder: string;
    handleChange: (text: string) => void;
    extraStyles?: { [key: string]: string | number };
    password?: boolean;
}

const LoginInput = ({
    placeholder,
    handleChange,
    extraStyles,
    password = false,
}: LoginInputProps) => {
    const [isActive, setActive] = useState(false);
    const handleToggle = (e: any) => {
        e.preventDefault();
        setActive(!isActive);
    };

    return (
        <InputWrapperSignup>
            <InputLabelSignup>{placeholder}</InputLabelSignup>
            <InputSignup
                type={password && !isActive ? "password" : "text"}
                name="uname"
                required
                onChange={(event) =>
                    handleChange(
                        password
                            ? event.target.value
                            : event.target.value.replace(/\s/g, "").trim()
                    )
                }
            />
            {password && (
                <PassEyeBtn onClick={(e) => handleToggle(e)} type="button">
                    <FontAwesomeIcon
                        icon={isActive ? faEyeSlash : faEye}
                        size="lg"
                    />
                </PassEyeBtn>
            )}
        </InputWrapperSignup>
    );
};

export default LoginInput;
