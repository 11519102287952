import { useEffect, useMemo, useState } from "react";
import { Backend, MicroServices } from "../helpers/backendHelper";

export interface ILanguage {
    code: string
    engName: string
    active: boolean
}

const useLanguagesSA = (pageSizeProp: number) => {
    const [pageSize, setPageSize] = useState(pageSizeProp);
    const [languages, setLanguages] = useState<ILanguage[]>([]);

    const languagePages: ILanguage[][] = useMemo(() => {
        const pages: ILanguage[][] = [];

        for (let i = 0; i < languages.length; i += pageSize) {
            pages.push(languages.slice(i, i + pageSize));
        }

        if (pages.length === 0) {
            pages.push([]);
        }

        return pages;
    }, [pageSize, languages]);

    const reloadLanguages = () => {
        Backend(MicroServices.Admin, `/langs`)
            .then((response) => response.json())
            .then((results) => {
                if (!Array.isArray(results)) {
                    return;
                }
                setLanguages(results);
            });
    };

    useEffect(() => {
        reloadLanguages();
    }, []);

    return { languages, setPageSize, languagePages, reloadLanguages };
};

export default useLanguagesSA;
