import "./styles.css";
import ClubSuperAdmin from "../ClubSuperAdmin"
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import OutsideClickHandler from 'react-outside-click-handler';
import { getOrganizationNames, getClubNames } from "../../store/actions/orgsActions";
import { getActiveCities } from "../../store/actions/locationActions";
import { getClubs } from "../../store/actions/clubActions";
import { pageSize } from "../Organizations";
import ClubRowTableSuperAdmin from "../ClubRowTableSuperAdmin";
import './styles.scoped.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
interface FilterUsersAdminProps {
  clubType: string;
}

const Index = ({ clubType }: FilterUsersAdminProps) => {

  const dispatch = useDispatch();

  const clubs = useSelector((state: any) => state.getClub.getClubsOrg || []);
  const organizationsAll = useSelector((state: any) => state.getOrgs.organizationNames);
  const clubsAll = useSelector((state: any) => state.getOrgs.clubNames);
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const userRoles = useSelector((state: any) => state.getUser.userRoles);
  const activeCountries = useSelector((state: any) => state.getLocation.activeCountries || []);
  const activeCities = useSelector((state: any) => state.getLocation.activeCities || []);
  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  )

  const [selected, setSelected] = useState("");
  const [input, setInput] = useState(false);
  const [outside, setOutside] = useState(false);
  const [valueFilter, setValueFilter] = useState("");

  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [memberType, setMemberType] = useState('')
  const [addedOn, setAddedOn] = useState('')
  const [orgHeader, setOrgHeader] = useState('')

  const [focusName, setFocusName] = useState(false);
  const [focusType, setFocusType] = useState(false);
  const [focusMemberType, setFocusMemberType] = useState(false);
  const [focusAddedOn, setFocusAddedOn] = useState(false);
  const [focusOrg, setFocusOrg] = useState(false);

  const [clubOptions, setClubOptions] = useState<{label: string, value: string}[]>([]);

  useEffect(() => {
    dispatch(getOrganizationNames());
    dispatch(getClubNames());
    dispatch(getActiveCities());
    dispatch<any>(getClubs({ club_type: clubType, limit: '100' }))
      .then((results: any) => console.log(results))
      .catch((error: any) => console.error(error));
  }, []);


  useEffect(() => {
    if (userRoles.includes('SUPER')) {

      console.log(orgHeader);

      if (orgHeader) {
        dispatch(getClubs({
          org_id: orgHeader,
          club_type: clubType,
          name: name,
          type: type,
          member_roles: memberType,
        }))
      } else {
        dispatch(getClubs({
          club_type: clubType,
          name: name,
          type: type,
          member_roles: memberType,
        }))
      }
    }
    else {
      dispatch(getClubs({
        org_id: orgHeader,
        club_type: clubType,
        name: name,
        type: type,
        member_roles: memberType,
        youthOrg: (organization.type ==='YOUTHORG') ? "true": "false"
      }))
    }
  }, [orgHeader, type, memberType, /*addedOn*/])

  const types: { [key: string]: string } = {
    ALL: 'All',
    'ZAA#STUDENT': 'Student',
    'ZAA#STAFF': 'Staff',
    'ZAA#GUARDIAN': 'Guardian',
    'ZAA#ALUMNI': 'Alumni',
  };

  const typesOptions = Object.keys(types).map((key) => ({
    label: types[key],
    value: encodeURIComponent(key),
  }));

  const orgOptions = organizationsAll.map((e: any) => ({
    label: e.name,
    value: e.id,
  }));

  orgOptions.unshift({ label: "All", value: "" });

  useEffect(() =>{
    if(Array.isArray(clubsAll)){
      setClubOptions(clubsAll.map((e: any) => ({
        label: e.name,
        value: e.name,
      })))
    }
  },[clubsAll])

  clubOptions.unshift({ label: "All", value: "" });

  const memberTypes = [
    ['STUDENT', 'Student'],
    ['STAFF', 'Staff'],
    ['GUARDIAN', 'Guardian'],
    ['ALUMNI', 'Alumni']
  ];

  const rolesOptions = memberTypes.map((e: any) => ({
    label: e[1],
    value: encodeURIComponent(e[0]),
  }));

  rolesOptions.unshift({ label: "All", value: "" });

  const countryOptions = activeCountries.map((e: any) => {
    return {
      label: e.name,
      value: e.name,
    }
  })

  countryOptions.unshift({ label: "All", value: "" });

  const cityOptions = activeCities.map((e: any) => {
    return {
      label: e.name,
      value: e.name,
    }
  })

  cityOptions.unshift({ label: "All", value: "" });

  const handleChange = (e: any) => {
    setSelected(e.label);
    console.log(e.label);
  };

  const onInput = (e: any) => {
    setInput(true);
    setOutside(false);
  };

  const outsideClick = (e: any) => {
    // outside ? e.label = "Search by Name" : <></>
  };

  const customStyles = (value: any, input: any, outside: any) => ({
    option: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#383838',
    }),
    input: (styles: any, state: any) => ({
      ...styles,
      border: 'none',
      color: 'white',
      fontWeight: "normal",
      paddingLeft: "5px",
      "&:focus": { backgroundColor: 'red' },
    }),
    container: (styles: any) => ({
      ...styles,
      padding: 0,
    }),
    valueContainer: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: value && value != 'All' ? '#383838' : 'transparent',
      // display: value && !outside ? 'flex' : 'none',
      padding: 0,
    }),
    menu: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      backgroundColor: "#383838",
      width: "fit-content",
      minWidth: "150px",
      textAlign: "left",
      zIndex: "1",
      fontFamily: 'Roboto Condensed'
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      textAlign: "left",
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "50px",
      borderRadius: "6px",
      boxShadow: "none",
      border: 0,
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "bold",
      maxWidth: "200px",
      marginBottom: "5px",
      backgroundColor: input ? '#383838' : 'transparent',
      // textDecoration: outside ? 'underline' : 'none',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: 'white',
      display: input ? 'none' : 'block',
    }),
    indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
    dropdownIndicator: (styles: any) => ({ ...styles, display: "none" }),
    noOptionsMessage: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#383838',
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "normal",
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#383838',
      border: "none",
      paddingLeft: "7px",
      paddingRight: "7px",
      paddingBottom: "7px",
      paddingTop: "7px",
      borderRadius: "4px",
      fontSize: "13px",
      width: "125px",

      // display: input ? 'none' : 'block',

    }),
  })

  const handleChangeName = (e: any) => {
    setName(e);
    setFocusName(e.value !== 'ALL');
    setValueFilter(e);
  }

  const handleChangeType = (e: any) => {
    setType((e.value === 'ALL') ? '' : e.value);
    setFocusType(e.value !== 'ALL')
  }

  const handleChangeOrg = (e: any) => {
    setOrgHeader((e.value === 'ALL') ? '' : e.value);
    setFocusOrg(e.value !== 'ALL')
  }

  const handleChangeMemberType = (e: any) => {
    setMemberType((e.value === 'ALL') ? '' : e.value);
    setFocusMemberType(e.value !== 'ALL')
  }

  const handleChangeAddedOn = (e: any) => {
    setAddedOn(e);
    setFocusAddedOn(e.value !== 'ALL')
  }

  return (

    <>
      <table className="tableManageClubs">
        <tr>

          {/*<th>
            <div className="">
              <div className="clubs-check">
                <label className="content-input-white">
                  <input type="checkbox" name="button" value="Active" />
                  <i></i>
                </label>
              </div>
            </div>
          </th>*/}

          <th>
            <div className="robotocondensed-bold-white-16px">Category</div>
          </th>
          {/*<th className="clubName">
            <div className={`withFilter ${focusName ? 'filterIsFocused' : 'inputFilter'} `}>
              <label className="labelName">{focusName}</label>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setOutside(true);
                  setInput(false);
                  outsideClick(outside);
                }}
              >
                <Select
                  isMulti={false}
                  placeholder={"Name"}
                  styles={customStyles(selected, input, outside)}
                  onChange={(e: any) => handleChangeName(e.target.value)}
                  onFocus={onInput}
                  options={clubOptions}
                />
              </OutsideClickHandler>
              <FontAwesomeIcon
  icon="sort-amount-down"
  size='lg'
  color={LAVENDER}
  className="sort-amount-down  "
/>
            </div>
          </th>*/}

          <th className="clubName">
            <div>
              <input
                className={`withFilter ${focusName ? 'filterIsFocused' : 'inputFilter'} `}
                type="text"
                placeholder="Name"
                onChange={(e) => handleChangeName(e.target.value)}
              />
            </div>
          </th>

          <th>
            <div className="withFilter organizationName">
              <OutsideClickHandler
                onOutsideClick={() => {
                  setOutside(true);
                  setInput(false);
                  outsideClick(outside);
                }}
              >
                <Select
                  isMulti={false}
                  placeholder={"Organizations"}
                  options={orgOptions}
                  styles={customStyles(selected, input, outside)}
                  onFocus={onInput}
                  onChange={handleChangeOrg}
                />
              </OutsideClickHandler>
              <FontAwesomeIcon
                icon="sort-amount-down"
                size='lg'
                color={LAVENDER}
                className="sort-amount-down  "
              />
            </div>
          </th>

          {/* {(clubType === 'COMM') ? (
            <th>
              <div className={`${focusType ? 'active' : ''}`}>
                <label className="labelType">{focusType}</label>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setOutside(true);
                    setInput(false);
                    outsideClick(outside);
                  }}
                >
                  <Select
                    isMulti={false}
                    options={typesOptions}
                    placeholder={"Type"}
                    styles={customStyles(selected, input, outside)}
                    onChange={handleChangeType}
                    onFocus={onInput}
                  />
                </OutsideClickHandler>
                <FontAwesomeIcon
  icon="sort-amount-down"
  size='lg'
  color={LAVENDER}
  className="sort-amount-down  "
/>
              </div>
            </th>
          ) :
            (
              <th>
                <div className="robotocondensed-bold-white-16px">Type</div>
              </th>
            )} */}

          <th>
            <div className={`withFilter ${focusMemberType ? 'active' : ''}`}>
              <label className="labelType">{focusMemberType}</label>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setOutside(true);
                  setInput(false);
                  outsideClick(outside);
                }}
              >
                <Select
                  isMulti={false}
                  options={rolesOptions}
                  placeholder={"Membership"}
                  styles={customStyles(selected, input, outside)}
                  onChange={handleChangeMemberType}
                  onFocus={onInput}
                />
              </OutsideClickHandler>
              <FontAwesomeIcon
                icon="sort-amount-down"
                size='lg'
                color={LAVENDER}
                className="sort-amount-down  "
              />
            </div>
          </th>
          {/*<th>
            <div className={`withFilter roboto-bold-white-16px ${focusAddedOn ? 'active' : ''}`}>
              <label className="labelType">{focusAddedOn}</label>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setOutside(true);
                  setInput(false);
                  outsideClick(outside);
                }}
              >
                <Select
                  isMulti={false}
                  placeholder={"Date Added"}
                  styles={customStyles(selected, input, outside)}
                  onChange={handleChangeAddedOn}
                  onFocus={onInput}
                />
              </OutsideClickHandler>
              <FontAwesomeIcon
  icon="sort-amount-down"
  size='lg'
  color={LAVENDER}
  className="sort-amount-down  "
/>
            </div>
          </th>*/}
          <th>
            <div className="roboto-bold-white-16px">Date Added</div>
          </th>
          <th>
            <div className="roboto-bold-white-16px">Last Post</div>
          </th>
          <th>
            <div className="roboto-bold-white-16px">Setup</div>
          </th>
          <th>
            <div className="robotocondensed-bold-white-16px">Members</div>
          </th>
          <th>
            <div className="robotocondensed-bold-white-16px">Posts</div>
          </th>
          <th>
            <div className="robotocondensed-bold-white-16px">Events</div>
          </th>
          <th>
            <div className="robotocondensed-bold-white-16px">Open invites</div>
          </th>
          <th>
            <div className="robotocondensed-bold-white-16px"></div>
          </th>
        </tr>

        {clubs.filter((item: any) => {
          if (!valueFilter) return true;
          if (item.name.toUpperCase().includes(valueFilter) || 
              item.name.toLowerCase().includes(valueFilter) || 
              item.name.includes(valueFilter)
          ) {
            return true;
          }
          return false;
        }).map((club: any) => (
          <ClubRowTableSuperAdmin club={club} />
        ))}
      </table>
    </>


  )
};

export default Index;