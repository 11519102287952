import React, {
  KeyboardEventHandler,
  useState,
  useRef,
  FormEvent,
} from 'react';
import CreatableSelect from 'react-select/creatable';
import {
  ActionMeta,
  GroupBase,
  MultiValue,
  OnChangeValue,
  PropsValue,
  StylesConfig,
} from 'react-select';
import JoditEditor from 'jodit-react';
import '../../../containers/SetupSchool/styles.scoped.css';
import { ClubCreate } from '../Clubs';
import { useTranslation } from "react-i18next";

interface Option {
  readonly label: string;
  readonly value: string;
}

interface DescriptionProps {
  club: ClubCreate;
  setClub: (club: ClubCreate) => void;
  isTeam: boolean;
  // organization?: OrganizationComplete,
}

const skillsLearnedStyles: StylesConfig<Option, boolean, GroupBase<Option>> = {
  singleValue: base => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    backgroundColor: 'red',
    fontFamily: 'Roboto Condensed',
  }),
  control: styles => ({
    ...styles,
    backgroundColor: '#2c2c2c',
    borderRadius: '3px',
    boxShadow: 'none',
    border: 0,
    minWidth: '50%',
  }),
  option: styles => ({
    ...styles,
    color: 'white',
    backgroundColor: 'var(--violent-violet)',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Roboto Condensed',
  }),
  placeholder: styles => ({
    ...styles,
    color: 'white',
    textAlign: 'left',
    fontFamily: 'Roboto Condensed',
  }),
  container: styles => ({
    ...styles,
    padding: 0,
    width: '100%',
  }),
  menu: styles => ({
    ...styles,
    borderRadius: '8px',
    backgroundColor: 'var(--violent-violet)',
  }),
  input: (styles: any, state: any) => ({
    ...styles,
    border: 'none',
    color: 'white',
    fontFamily: 'Roboto Condensed',
  }),
  dropdownIndicator: styles => ({ ...styles, display: 'none' }),
  indicatorSeparator: (styles: any) => ({ ...styles, display: 'none' }),
  multiValue: styles => ({
    ...styles,
    color: 'white',
    backgroundColor: 'var(--electric-violet-2)',
  }),
  multiValueLabel: styles => ({
    ...styles,
    color: 'white',
    fontFamily: 'Roboto Condensed',
    fontSize: '16px',
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: 'white',
    backgroundColor: 'transparent',
  }),
};

function Index({ club, setClub, isTeam }: DescriptionProps) {
  const { t, i18n } = useTranslation();
  const createOption = (label: string) => ({
    label,
    value: label,
  });


  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<OnChangeValue<Option, true>>(club.skills?.map((item)=>createOption(item)) || []);

  const components = {
    DropdownIndicator: null,
  };

  const handleChange = (
    value: PropsValue<Option>,
  ) => {
    if (value) {
      const newValue = value as MultiValue<Option>;
      setValue(newValue);
      setClub({...club,skills:newValue.map((item)=>item.value)})
    }
  };

  const handleInputChange = (inputValue: any) => {
    setInputValue(inputValue);
  };

  const handleOnBlurDescription = (e: any) => {
    setClub({...club, description: e})
  };

  const handleChangeName = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setClub({
      ...club,
      name: e.currentTarget.value,
    })
  }

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = event => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setInputValue('');
        if(!value.map((item)=>item.value).includes(inputValue)) setValue([...value, createOption(inputValue)]);
        setClub({...club,skills:[...club.skills || [], inputValue]})
        event.preventDefault();
    }
  };

  const editor = useRef(null);
  const [content, setContent] = useState('');

  return (
    <div className="infoMembers">
      {/* <div className="membersType">
        <h2>Club Name</h2>
        <input
          type="text"
          className='SetupCreateClub__clubName'
          value={club.name}
          onChange={handleChangeName}
        />
      </div> */}
      <div className="membersType">
        <h2>{t('setupSchool__description')}</h2>
        <JoditEditor
          value={club.description}
          onBlur={handleOnBlurDescription}
          config={{
            buttons: ['bold', 'italic', 'underline'],
            readonly: false,
            toolbarAdaptive: false,
            showPlaceholder: false,
          }}
        />
      </div>
      <div className="membersType">
        <h2>{t('setupSchool__skillsLearningTags')}</h2>
        <CreatableSelect
          components={components}
          inputValue={inputValue}
          isClearable
          isMulti
          menuIsOpen={false}
          onChange={handleChange}
          onInputChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder=""
          value={value}
          styles={skillsLearnedStyles}
        />
      </div>
      <div className="genders">
        <div className="containers-input">
          <div className="option-chechbox">
            <label className="content-input">
              <input type="checkbox" name="principal-button" value="" defaultChecked={club.applicationRequired} onClick={(e:any)=>setClub({...club,applicationRequired:e.target.checked})} />
              <i />
            </label>
            <label htmlFor="students">{isTeam ? t('setupSchool__tryout') : t('setupSchool__application')} {t('setupSchool__requiredToJoin')}</label>
          </div>
        </div>
      </div>
      <div className="genders">
        <div className="containers-input">
          <div className="option-chechbox">
            <label className="content-input">
              <input type="checkbox" name="principal-button" value="" defaultChecked={club.clubDuesRequired} onClick={(e:any)=>setClub({...club,clubDuesRequired:e.target.checked})}/>
              <i />
            </label>
            <label htmlFor="students">{isTeam ? t('setupSchool__team') : t('setupSchool__club')} {t('setupSchool__duesRequired')}</label>
          </div>
        </div>
      </div>
      <div className="genders">
        <h2>{t('setupSchool__participate')}</h2>
        <div className="containers-input">
          <div className="option-chechbox">
            <label className="content-input">
              <input type="checkbox" name="principal-button" value="" defaultChecked={club.local} onClick={(e:any)=>setClub({...club,local:e.target.checked})}/>
              <i />
            </label>
            <label htmlFor="students">{t('setupSchool__local')}</label>
          </div>
          <div className="option-chechbox">
            <label className="content-input">
              <input type="checkbox" name="principal-button" value="" defaultChecked={club.regional} onClick={(e:any)=>setClub({...club,regional:e.target.checked})}/>
              <i />
            </label>
            <label htmlFor="students">{t('setupSchool__regional')}</label>
          </div>
          <div className="option-chechbox">
            <label className="content-input">
              <input type="checkbox" name="principal-button" value="" defaultChecked={club.national} onClick={(e:any)=>setClub({...club,national:e.target.checked})} />
              <i />
            </label>
            <label htmlFor="students">{t('setupSchool__national')}</label>
          </div>
          <div className="option-chechbox">
            <label className="content-input">
              <input type="checkbox" name="principal-button" value="" defaultChecked={club.international} onClick={(e:any)=>setClub({...club,international:e.target.checked})}/>
              <i />
            </label>
            <label htmlFor="students">{t('setupSchool__international')}</label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
