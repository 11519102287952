import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import TCListSearch from "../../components/TClubs/TCList/TCListSearch";
import TableAdmin from "../../components/TableAdmin";
import { LAVENDER } from "../../components/ClubIconsNavBar";
import {
    MainWrapper,
    Header,
    Title,
    Row,
    RowGap,
    CreateBtn,
    ActionWrapper,
    ClickableIcon,
    ContentWrapper,
    PhantomElement,
} from '../../components/SuperAdminComponents/basicForSACenter';
import useYOListSA from "../../hooks/YOTable/useYOListSA";
import YO from "../../interfaces/YOTable/YO/YO";
import ModalDelete from "../../components/OrganizationsTable/ModalDelete";

const YOTable = () => {
    const navigate = useNavigate();
    const { yoPages, reloadYOList } = useYOListSA(100);

    const [SearchTerm, setSearchTerm] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [orgId, setOrgId] = useState('');

    const filteredYO = useMemo(
        () =>
            yoPages[0]
                .filter((yo) => yo.name.toLowerCase().includes(SearchTerm.toLowerCase())),
        [yoPages[0], SearchTerm]
    )

    function handleCloseModalDelete(e:any) {
        reloadYOList();
        setShowDelete(false)
        setOrgId('')
    };

    function handleOpenModalDelete(id: string) {
        setShowDelete(true)
        setOrgId(id)
    };

    return (
        <>
            <MainWrapper>
                <Header>
                    <Row>
                        <Title>Youth Organizations</Title>
                        <CreateBtn onClick={() => navigate("./create")}>
                            Create Youth Organization
                        </CreateBtn>
                    </Row>
                    <RowGap>
                        <PhantomElement />
                        <TCListSearch searchTerm={SearchTerm} setSearchTerm={setSearchTerm} />
                    </RowGap>
                </Header>
                <ContentWrapper>
                    <TableAdmin
                        headers={[
                            {
                                label: 'Name',
                            },
                            {
                                label: 'Website',
                            },
                            {
                                label: 'Actions',
                                right: true,
                            },
                        ]}
                        items={filteredYO}
                        renderItem={(item: YO) => ([
                            item.name,
                            item.website,
                            <ActionWrapper>
                                <ClickableIcon
                                    icon="eye"
                                    size='lg'
                                    color={LAVENDER}
                                    onClick={() => navigate(`../organizations/${item.zaahahOrgId || item.id}/view`)}
                                />
                                <ClickableIcon
                                    icon="pen"
                                    size='lg'
                                    color={LAVENDER}
                                    onClick={() => navigate(`../organizations/${item.zaahahOrgId || item.id}/update`)}
                                />
                                <ClickableIcon
                                    icon="trash-alt"
                                    className="trash-alt-super"
                                    size="1x"
                                    color={LAVENDER}
                                    onClick={() => handleOpenModalDelete(item.id)}
                                />
                            </ActionWrapper>
                        ])}
                        keyExtractor={({ id }: YO) => id}
                    />
                </ContentWrapper>
            </MainWrapper>
            <ModalDelete show={showDelete} onHide={handleCloseModalDelete} orgId={orgId} youthOrg={true}/>
        </>
    );
}

export default YOTable;
