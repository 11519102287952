import { AnyAction } from "@reduxjs/toolkit";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import i18n from '../../i18n';

import {
    GET_CLUBS_ORG_ERROR, GET_CLUBS_ORG_SUCCESS, START_GET_CLUBS_ORG,
    START_GET_MY_CLUBS, GET_MY_CLUBS_SUCCESS, GET_MY_CLUBS_ERROR,
    START_POST_USER_AS_MEMBER, POST_USER_AS_MEMBER_SUCCESS,
    POST_USER_AS_MEMBER_ERROR,
    START_POST_USER_AS_ADVISOR,
    POST_USER_AS_ADVISOR_SUCCESS,
    POST_USER_AS_ADVISOR_ERROR,
    START_POST_USER_AS_FAN,
    POST_USER_AS_FAN_SUCCESS,
    POST_USER_AS_FAN_ERROR,
    START_POST_USER_AS_FOLLOWER,
    POST_USER_AS_FOLLOWER_SUCCESS,
    POST_USER_AS_FOLLOWER_ERROR,
    START_POST_USER_AS_JOIN_REQUESTED,
    POST_USER_AS_JOIN_REQUESTED_SUCCESS,
    POST_USER_AS_JOIN_REQUESTED_ERROR,
    START_POST_USER_AS_QUIT_REQUESTED,
    POST_USER_AS_QUIT_REQUESTED_SUCCESS,
    POST_USER_AS_QUIT_REQUESTED_ERROR,
    START_GET_USER_PENDING_CLUBS,
    GET_USER_PENDING_CLUBS_SUCCESS,
    GET_USER_PENDING_CLUBS_ERROR,
    START_DELETE_JOIN,
    DELETE_JOIN_SUCCESS,
    DELETE_JOIN_ERROR,
    START_GET_COMMUNITIES,
    GET_COMMUNITIES_SUCCESS,
    GET_COMMUNITIES_ERROR,
    START_GET_CLUB,
    GET_CLUB_SUCCESS,
    GET_CLUB_ERROR,
    ORGADMIN_ADVISOR_ERROR,
    ORGADMIN_ADVISOR_SUCCESS,
    START_ORGADMIN_ADVISOR,
    START_CLUBS_IM_ADV,
    CLUBS_IM_ADV_SUCCESS,
    CLUBS_IM_ADV_ERROR,
    START_GET_COMM,
    GET_COMM_SUCCESS,
    GET_COMM_ERROR,
    START_GET_CLUB_MEMBERS,
    GET_CLUB_MEMBERS_ERROR,
    GET_CLUB_MEMBERS_SUCCESS,
    START_EDIT_CLUB,
    EDIT_CLUB_SUCCESS,
    EDIT_CLUB_ERROR,
    GET_COMM_ORG_SETUP_ERROR,
    GET_COMM_ORG_SETUP_SUCCESS,
    START_GET_COMM_ORG_SETUP
} from "../constants/clubConstants";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backend, MicroServices } from "../../helpers/backendHelper";

toast.configure();

export const getOrgCommunities = (org_id: string, pending = false): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_COMM });
        Backend(
            MicroServices.Club,
            `/clubs?pending=${pending}&org_id=${org_id}&club_type=COMM`)
            .then((response) => response.json())
            .then(({clubs}) => {
                dispatch({
                    type: GET_COMM_SUCCESS,
                    payload: clubs,
                })
                resolve(clubs);
            })
            .catch((error) => {
                dispatch({
                    type: GET_COMM_ERROR,
                    payload: error,
                });
            })
    });

export const getOrgCommSetup = (org_id: string, pending = false): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_COMM_ORG_SETUP });
        Backend(
            MicroServices.Club,
            `/clubs?pending=${pending}&org_id=${org_id}&club_type=COMM&school_setup=true`)
            .then((response) => response.json())
            .then(({clubs}) => {
                dispatch({
                    type: GET_COMM_ORG_SETUP_SUCCESS,
                    payload: clubs,
                })
                resolve(clubs);
            })
            .catch((error) => {
                dispatch({
                    type: GET_COMM_ORG_SETUP_ERROR,
                    payload: error,
                });
            })
    });

export const getClubs = ({
    pending = false,
    deleted = false,
    youthOrg = '',
    org_id = '',
    club_type = '',
    name = '',
    type = '',
    member_roles = '',
    city = '',
    country = '',
    limit = '',
    lastEvaluatedKey = ''
}): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_CLUBS_ORG });

        let params = '';

        params += org_id === '' ? '' : `&org_id=${org_id}`;
        params += youthOrg === '' ? '' : `&youthOrg=${youthOrg}`;
        params += club_type === '' ? '' : `&club_type=${club_type}`;
        params += name === '' ? '' : `&name=${name}`;
        params += type === '' ? '' : `&type=${type}`;
        params += member_roles === '' ? '' : `&member_roles=${member_roles.split('#').join('_')}`;
        params += city === '' ? '' : `&city=${city}`;
        params += country === '' ? '' : `&country=${country}`;
        params += limit === '' ? '' : `&limit=${limit}`;
        params += lastEvaluatedKey === '' ? '' : `&lastEvaluatedKey=${lastEvaluatedKey}`;

        Backend(
            MicroServices.Club,
            `/clubs?pending=${pending}&deleted=${deleted}${params}`)
            .then((response) => response.json())
            .then(({clubs}) => {
                dispatch({
                    type: GET_CLUBS_ORG_SUCCESS,
                    payload: clubs,
                })
                resolve(clubs);
            })
            .catch((error) => {
                console.log(error, 'ERROR getClubs why')
                dispatch({
                    type: GET_CLUBS_ORG_ERROR,
                    payload: error,
                });
            })
    });

export const getCommunities = (org_id: string, pending = false): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({type: START_GET_COMMUNITIES});

        Backend(
            MicroServices.Club,
            `/clubs?pending=${pending}&org_id=${org_id}&club_type=COMM`)
            .then((response) => response.json())
            .then(({clubs}) => {

                dispatch({
                    type: GET_COMMUNITIES_SUCCESS,
                    payload: clubs,
                })
            })
            .catch((error) => {
                dispatch({
                    type: GET_COMMUNITIES_ERROR,
                    payload: error,
                });
            })
    })

export const getClub = (club_id: string, pending = false): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_CLUB });
        if(!club_id){
            dispatch({
                type: GET_CLUB_ERROR,
                payload: 'Undefined club_id',
            });
            return
        }
        
        Backend(
            MicroServices.Club,
            `/clubs/${club_id}?pending=${pending}`)
            .then((response) => response.json())
            .then((club) => {
                dispatch({
                    type: GET_CLUB_SUCCESS,
                    payload: club,
                })
            })
            .catch((error) => {
                dispatch({
                    type: GET_CLUB_ERROR,
                    payload: error,
                });
            })
    });

export const getClubsImAdv = (username: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({type: START_CLUBS_IM_ADV});
        Backend(
            MicroServices.Club,
            `/users/clubsofadvisor?username=${username}`)
            .then((response) => response.json())
            .then((clubs) => {
                dispatch({
                    type: CLUBS_IM_ADV_SUCCESS,
                    payload: clubs,
                })
                resolve(clubs)
            })
            .catch((error) => {
                dispatch({
                    type: CLUBS_IM_ADV_ERROR,
                    payload: error,
                });
            })
    });

export const getClubNoIdeaPending = (club_id: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_CLUB });

        if(!club_id){
            dispatch({
                type: GET_CLUB_ERROR,
                payload: 'Undefined club_id',
            });
            return
        }

        Backend(
            MicroServices.Club,
            `/clubs/${club_id}?pending=${false}`)
            .then(async(response) => {
                if (response.status === 404) {
                    const responseNotPending = await Backend(
                        MicroServices.Club,
                        `/clubs/${club_id}?pending=${true}`)
                    return responseNotPending.json();
                }
                else{
                    return response.json();
                }
            })
            .then((club) => {
                dispatch({
                    type: GET_CLUB_SUCCESS,
                    payload: club,
                })
            })
            .catch((error) => {
                dispatch({
                    type: GET_CLUB_ERROR,
                    payload: error,
                });
            })
    });

export const getMyClubs = (username: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_MY_CLUBS });
        Backend(
            MicroServices.Roster,
            `/users/user-clubs?username=${username}`)
            .then((response) => response.json())
            .then((results) => {
                dispatch({
                    type: GET_MY_CLUBS_SUCCESS,
                    payload: results,
                })
            })
            .catch((error) => {
                dispatch({
                    type: GET_MY_CLUBS_ERROR,
                    payload: error,
                });
            })
    });

export const userAsMember = (clubId: string, username: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_POST_USER_AS_MEMBER });
        const {t} = i18n;
        Backend(
            MicroServices.Club,
            `/clubs/${clubId}/member?username=${username}`, 
            {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                }
            })
            .then((response) => {

                if(response.status === 201){
                    dispatch({
                        type: POST_USER_AS_MEMBER_SUCCESS,
                    });
                    resolve(true);
                    return;
                }
                else if (response.status === 400){
                    dispatch({
                        type: POST_USER_AS_MEMBER_ERROR,
                    });
                    console.error('Guard failed');
                    toast.error(t('toast_ThereWasErrorTry'));
                    return Promise.reject();
                }
                else if (response.status === 404){
                    return response.json();
                }

                toast.error(t('toast_ThereWasErrorTry'));
                console.error(response);
                return Promise.reject();
            })
            .then((response) => {
                console.error(response.message);
                dispatch({
                    type: POST_USER_AS_MEMBER_ERROR,
                    payload: response.message,
                });
                toast.error(`${t('toast_ThereWasError')} ${response.message}`);
                return Promise.reject();
            })
            .catch((error) => {
                dispatch({
                    type: POST_USER_AS_MEMBER_ERROR,
                    payload: error,
                });
            })
    });

export const userAsAdvisor = (clubId: string, username: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_POST_USER_AS_ADVISOR });
        const {t} = i18n;
        Backend(
            MicroServices.Club,
            `/clubs/${clubId}/advisor?username=${username}`, 
            {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                }
            })
            .then((response) => {

                if(response.status === 201){
                    dispatch({
                        type: POST_USER_AS_ADVISOR_SUCCESS,
                    });
                    resolve(true);
                    return;
                }
                else if (response.status === 400){
                    dispatch({
                        type: POST_USER_AS_ADVISOR_ERROR,
                    });
                    console.error('Guard failed');
                    toast.error(t('toast_ThereWasErrorTry'));
                    return Promise.reject();
                }
                else if (response.status === 404){
                    return response.json();
                }

                toast.error(t('toast_ThereWasErrorTry'));
                console.error(response);
                return Promise.reject();
            })
            .then((response) => {
                console.error(response.message);
                dispatch({
                    type: POST_USER_AS_ADVISOR_ERROR,
                    payload: response.message,
                });
                toast.error(`${t('toast_ThereWasError')} ${response.message}`);
                return Promise.reject();
            })
            .catch((error) => {
                dispatch({
                    type: POST_USER_AS_ADVISOR_ERROR,
                    payload: error,
                });
            })
    });

export const userAsFan = (clubId: string, username: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_POST_USER_AS_FAN });
        const {t} = i18n;
        Backend(
            MicroServices.Club,
            `/clubs/${clubId}/fan?username=${username}`, 
            {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                }
            })
            .then((response) => {

                if(response.status === 201){
                    dispatch({
                        type: POST_USER_AS_FAN_SUCCESS,
                    });
                    resolve(true);
                    return;
                }
                else if (response.status === 400){
                    dispatch({
                        type: POST_USER_AS_FAN_ERROR,
                    });
                    console.error('Guard failed');
                    toast.error(t('toast_ThereWasErrorTry'));
                    return Promise.reject();
                }
                else if (response.status === 404){
                    return response.json();
                }

                toast.error(t('toast_ThereWasErrorTry'));
                console.error(response);
                return Promise.reject();
            })
            .then((response) => {
                console.error(response.message);
                dispatch({
                    type: POST_USER_AS_FAN_ERROR,
                    payload: response.message,
                });
                toast.error(`${t('toast_ThereWasError')} ${response.message}`);
                return Promise.reject();
            })
            .catch((error) => {
                dispatch({
                    type: POST_USER_AS_FAN_ERROR,
                    payload: error,
                });
            })
    });

export const userAsFollower = (clubId: string, username: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_POST_USER_AS_FOLLOWER });
        const {t} = i18n;
        Backend(
            MicroServices.Club,
            `/clubs/${clubId}/follower?username=${username}`, 
            {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                }
            })
            .then((response) => {

                if(response.status === 201){
                    dispatch({
                        type: POST_USER_AS_FOLLOWER_SUCCESS,
                    });
                    resolve(true);
                    return;
                }
                else if (response.status === 400){
                    dispatch({
                        type: POST_USER_AS_FOLLOWER_ERROR,
                    });
                    console.error('Guard failed');
                    toast.error(t('toast_ThereWasErrorTry'));
                    return Promise.reject();
                }
                else if (response.status === 404){
                    return response.json();
                }

                toast.error(t('toast_ThereWasErrorTry'));
                console.error(response);
                return Promise.reject();
            })
            .then((response) => {
                console.error(response.message);
                dispatch({
                    type: POST_USER_AS_FOLLOWER_ERROR,
                    payload: response.message,
                });
                toast.error(`${t('toast_ThereWasError')} ${response.message}`);
                return Promise.reject();
            })
            .catch((error) => {
                dispatch({
                    type: POST_USER_AS_FOLLOWER_ERROR,
                    payload: error,
                });
            })
    });

export const userAsJoinRequested = (clubId: string, username: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_POST_USER_AS_JOIN_REQUESTED });
        const {t} = i18n;
        Backend(
            MicroServices.Club,
            `/clubs/${clubId}/join?username=${username}`, 
            {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                }
            })
            .then((response) => {

                if(response.status === 201){
                    dispatch({
                        type: POST_USER_AS_JOIN_REQUESTED_SUCCESS,
                    });
                    resolve(true);
                    return;
                }
                else if (response.status === 400 || response.status === 404){

                    return response.json();
                }

                toast.error(t('toast_ThereWasErrorTry'));
                console.error(response);
                return Promise.reject();
            })
            .then((response) => {
                console.error(response.message);
                dispatch({
                    type: POST_USER_AS_JOIN_REQUESTED_ERROR,
                    payload: response.message,
                });
                toast.error(`${t('toast_ThereWasError')} ${response.message}`);
                return Promise.reject();
            })
            .catch((error) => {
                dispatch({
                    type: POST_USER_AS_JOIN_REQUESTED_ERROR,
                    payload: error,
                });
            })
    });

export const userAsQuitRequested = (clubId: string, username: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_POST_USER_AS_QUIT_REQUESTED });
        const {t} = i18n;
        Backend(
            MicroServices.Club,
            `/clubs/${clubId}/join?username=${username}`, 
            {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                }
            })
            .then((response) => {

                if(response.status === 201){
                    dispatch({
                        type: POST_USER_AS_QUIT_REQUESTED_SUCCESS,
                    });
                    resolve(true);
                    return;
                }
                else if (response.status === 400 || response.status === 404){

                    return response.json();
                }

                toast.error(t('toast_ThereWasErrorTry'));
                console.error(response);
                return Promise.reject();
            })
            .then((response) => {
                console.error(response.message);
                dispatch({
                    type: POST_USER_AS_QUIT_REQUESTED_ERROR,
                    payload: response.message,
                });
                toast.error(`${t('toast_ThereWasError')} ${response.message}`);
                return Promise.reject();
            })
            .catch((error) => {
                dispatch({
                    type: POST_USER_AS_QUIT_REQUESTED_ERROR,
                    payload: error,
                });
            })
    });

export const getMyPendingClubs = (username: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_USER_PENDING_CLUBS });

        Backend(
            MicroServices.Club,
            `/users/pending-requests?username=${username}`)
            .then((response) => response.json())
            .then((results) => {
                dispatch({
                    type: GET_USER_PENDING_CLUBS_SUCCESS,
                    payload: results,
                })
            })
            .catch((error) => {
                dispatch({
                    type: GET_USER_PENDING_CLUBS_ERROR,
                    payload: error,
                });
            })
    });

export const deleteMyJoinRequest = (club_id: string, username: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_DELETE_JOIN });

        Backend(
            MicroServices.Club,
            `/clubs/${club_id}/delete/join?username=${username}`, 
            {
                method: "DELETE",
                headers: {
                "Content-Type": "application/json",
                }
            })
            .then((response) => response.json())
            .then((results) => {
                toast.success(results.message);
                dispatch({
                    type: DELETE_JOIN_SUCCESS,
                    payload: results.results,
                })
                resolve(true)
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_JOIN_ERROR,
                    payload: error,
                });
                resolve(false)
            })
    });

export const addOrgAdminAsAdvisor = (club_id: string, username: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_ORGADMIN_ADVISOR });

        Backend(
            MicroServices.Club,
            `/clubs/${club_id}/advisor?username=${username}`, 
            {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                }
            })
            .then((response) => response.json())
            .then((results) => {
                dispatch({
                    type: ORGADMIN_ADVISOR_SUCCESS,
                })
            })
            .catch((error) => {
                dispatch({
                    type: ORGADMIN_ADVISOR_ERROR,
                    payload: error,
                });
            })
    });

export const getClubMembers = (club_id: string, username: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
        dispatch({ type: START_GET_CLUB_MEMBERS });

        Backend(
            MicroServices.Club,
            `/clubs/${club_id}/members?username=${username}`)
            .then((response) => response.json())
            .then((results) => {
                if (Array.isArray(results)) {
                    resolve(results);
                    dispatch({
                        type: GET_CLUB_MEMBERS_SUCCESS,
                        payload: results,
                    })
                } else {
                    resolve([]);
                    dispatch({
                        type: GET_CLUB_MEMBERS_SUCCESS,
                        payload: [],
                    })
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_CLUB_MEMBERS_ERROR,
                    payload: error,
                });
            });
    });

export const editClub = (club:any): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
(dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_EDIT_CLUB });

    Backend(
        MicroServices.Club,
        `/clubs/${club.id}`, 
        {
            method: "PUT",
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify(club),
        })
        .then((response) => response.json())
        .then((results) => {
            dispatch({
                type: EDIT_CLUB_SUCCESS,
            })
            resolve(true)
        })
        .catch((error) => {
            dispatch({
                type: EDIT_CLUB_ERROR,
                payload: error,
            });
            resolve(false)
        })
});