import React, {
  useState,
  useEffect
} from 'react';
import "./styles.css";
import "./styles.scoped.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser, updateProfilePhoto, uploadAsset, uploadImage } from '../../store/actions/userActions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalImageEditor from '../ModalImageEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WHITE } from '../ClubIconsNavBar';
import { Backend, MicroServices } from '../../helpers/backendHelper';
import { useTranslation } from "react-i18next";

toast.configure();

function Index({ editVisible, settingsVisible, isUserProfile, setEditVisible, setSettingsVisible }:
  { editVisible: boolean, settingsVisible: boolean, isUserProfile: boolean, setEditVisible: any, setSettingsVisible: any }) {
  const { t, i18n } = useTranslation();
  const { username } = useParams();
  const dispatch = useDispatch();

  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const userAll = useSelector((state: any) => state.getUser.user);

  const [user, setUser] = useState(userAll);
  const [showModal, setShowModal] = useState(false);
  const [userProfile, setUserProfile] = useState('');

  useEffect(() => {
    if (isUserProfile && !userAll) {
      dispatch<any>(getUser(userInfo.username))
        .then((results: any) => {
          setUser(results);
          setUserProfile(results.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png');
        });
    }
    else if (isUserProfile && userAll) {
      setUser(userAll);
      setUserProfile(userAll.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png');
    }
    else {
      Backend(
        MicroServices.Admin,
        `/users/${username}?roleRequest=OA`)
        .then((response) => response.json())
        .then((results) => {
          setUser(results);
          setUserProfile((results.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'));
        })
        .catch(console.error);
    }
  }, [username, isUserProfile]);

  function handleSaveImage(e: any) {

    fetch(e)
      .then(res => res.blob())
      .then(blob => {
        const contentType = e.split(';')[0].split(':')[1];
        const extension = contentType.split('/')[1]
        const file = new File([blob], 'profile' + userInfo.username, { type: contentType });

        if (file) {
          dispatch<any>(uploadAsset(
            file.type,
            file.name + '.' + extension,
            "profile_photo"
          ))
            .then((results: any) => {
              if (results.body) {
                dispatch<any>(uploadImage(
                  results.body,
                  contentType,
                  file,
                ))
                  .then((result: any) => {
                    if (result.status === 200) {
                      const urlImage = results.body.split('?')[0];
                      setUserProfile(urlImage);
                      dispatch<any>(updateProfilePhoto(
                        urlImage,
                        userInfo.username,
                      ))
                        .then((result: any) => {
                          if (result.status === 200) {
                            setUserProfile(urlImage);
                            setShowModal(false);
                          }
                          else {
                            setShowModal(false);
                          }
                        })
                        .catch((error: any) => {
                          console.log(error)
                        })
                    }
                  })
                  .catch((error: any) => {
                    console.log(error)
                  })

              }
              else {
                toast.error(t('toast_errorImageUrl'));
              }
            })
            .catch((error: any) => {
              console.log(error)
            })
        }
        else {
          toast.error(t('toast_addFile'));
        }

      })
  };

  const closeModal = (e?: any, type?: any) => {
    setShowModal(false);
  }

  return (
    <div className="ProfileAccessPhoto">
      <div className="infoProfileAccess">

        <div className='imageProfileAccess'>
          {(showModal) && (<ModalImageEditor handleSaveImage={handleSaveImage} show={showModal} onHide={closeModal} isProfilePhoto={true} />)}
          <img src={userProfile !== '' ? userProfile : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'}
            alt=""
            onClick={() => isUserProfile && setShowModal(true)} />
        </div>

        <div>
          {(isUserProfile) ? (
            <p className="nameProfile pointer"
              onClick={() => {
                setSettingsVisible(false)
                setEditVisible(false)
              }}>
              {user.firstname} {user.lastname}
            </p>
          )
            :
            (
              <p className="nameProfile">
                {user.firstname} {user.lastname}
              </p>
            )}

          <ul>
            {user.organizationsNames.map((request: any) => (
              <li className='listOrgs'>{request}</li>
            ))}
          </ul>
        </div>
      </div>

      {(isUserProfile) &&
        (
          <div className="ProfileEditIcons">
            <FontAwesomeIcon
              icon="pen"
              size="sm"
              color={WHITE}
              className="iconEditInfo pointer"
              onClick={() => setEditVisible(!editVisible)}
            />

            <FontAwesomeIcon
              icon="gear"
              size="sm"
              color={WHITE}
              className="iconEditInfo pointer"
              onClick={() => setSettingsVisible(!settingsVisible)}
            />
          </div>
        )}

    </div>

  );
}

export default Index;
