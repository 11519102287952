import React from 'react'
import { useNavigate } from "react-router-dom";
import './styles.scoped.css'

const index = (props: any) => {
    const navigate = useNavigate();
    return (
        <div className={`menuPopup ${props.activePopup && 'activeMenu'}`}>
            <div className="buttonsMenu">
                <button className="loginSignUp" onClick={() => navigate('/')}>Login</button>
                <button className="signUpSignUp" onClick={() => navigate('/signup')}>Sign Up</button>
            </div>
            <div className="listMenuW">
                <p>Explore</p>
                <ul className="itemsMenu">
                    <li><a href='https://possibilitycompany.com/#do'>What We Do</a></li>
                    <li><a href='https://possibilitycompany.com/zaahah/'>zaahah</a></li>
                    <li><a href='https://possibilitycompany.com/aha-digital-wallet-and-rewards/'>aHa Digital Rewards</a></li>
                    <li><a href='https://possibilitycompany.com/faqs/'>FAQs</a></li>
                    <li><a href='https://possibilitycompany.com/contact-us/'>Contact Us</a></li>
                </ul>
                <ul className="itemsMenuPrivacy">
                    <li><a href='https://possibilitycompany.com/privacy-policy/'>Privacy Policy</a></li>
                    <li><a href='https://possibilitycompany.com/terms-of-service/'>Terms of Service</a></li>
                </ul>
            </div>
        </div>
    )
}

export default index