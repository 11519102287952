import { useNavigate, useParams } from "react-router-dom";

import AuthLayout from "../AuthLayout";
import { useClubCode, useRegisterCodeForm } from "./hooks";
import { BirthdayInput, GenderSelect, SignupTextInput, LanguageDropdown } from "../../components/SignUpInputs";

import "../LoginWrp/styles.scoped.css";
import './styles.scoped.css'
import SignUpHeader from "../../components/SignUpHeader";
import useGender from "../../hooks/useGender";
import { useEffect } from "react";

const SignUpCode = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const { loadingClubInfo, errorClubInfo, clubInfo, countryCode } = useClubCode(code || "");
  const {
    form,
    showErrors,
    setFormValue,
    setFormValueString,
    getError,
    submitForm,
    loading,
  } = useRegisterCodeForm(code || '', () => navigate('/'));
  const { genders, setCountry: setCountryGender} = useGender();

  useEffect(() => {
    countryCode && setCountryGender(countryCode);
  }, [countryCode]);

  if (loadingClubInfo) {
    return <AuthLayout>
      <div className="login-form login-form-cont">
        <SignUpHeader title="" subtitle="Loading"/>
      </div>
    </AuthLayout>;
  }

  if (errorClubInfo) {
    return <AuthLayout>
      <div className="login-form login-form-cont">
        <SignUpHeader title="" subtitle={`Error: ${errorClubInfo.message}`}/>
      </div>
    </AuthLayout>;
  }

  return (
    <AuthLayout>
      <div className="login-form login-form-cont">
        <SignUpHeader title="" subtitle={`You are registering to ${clubInfo?.club_name} of ${clubInfo?.org_name}`}/>
        <div className="input-two-cols" >
          <SignupTextInput
            placeholder='First Name*'
            onChange={(e) => setFormValue('givenName', e)}
            value={form.givenName}
            showErrors={showErrors}
            error={() => getError('givenName')}
          />
          <SignupTextInput
            placeholder='Last Name*'
            onChange={(e) => setFormValue('familyName', e)}
            value={form.familyName}
            showErrors={showErrors}
            error={() => getError('familyName')}
          />
        </div>
        <GenderSelect
          value={form.gender}
          genders={genders}
          onChange={(e) => setFormValue('gender', e)}
          showErrors={showErrors}
          error={getError('gender')}
        />
        <div className="input-two-cols marginRow" >
          <BirthdayInput value={form.birthdate} onChange={(date) => setFormValueString('birthdate', date)}/>
          <SignupTextInput
            placeholder='Email'
            onChange={(e) => setFormValue('email', e)}
            value={form.email}
            showErrors={showErrors}
            error={() => getError('email')}
          />
        </div>
        <div className="input-two-cols" >
          <SignupTextInput
            placeholder='Username*'
            onChange={(e) => setFormValue('userName', e)}
            value={form.userName}
            showErrors={showErrors}
            error={() => getError('userName')}
          />
          <SignupTextInput
            placeholder='Password*'
            onChange={(e) => setFormValue('password', e)}
            value={form.password}
            showErrors={showErrors}
            error={() => getError('password')}
          />
        </div>
        <LanguageDropdown value={form.preferredLanguage} onChange={(e) => setFormValue('preferredLanguage', e)}/>
        <div className="frame-5432" >
          <div className="frame-5355 buttonNexSignUp" >
            <button className="buttons-1 buttonRegister" type="button" onClick={submitForm}>
            {loading ? (
              <div className="center-items">
                <div className="icon-spinner-third buttonSendSignUp" />
              </div>
            ) : (
              <div className="text-button roboto-normal-black-16px">
                Send
              </div>
            )}
            </button>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default SignUpCode;
