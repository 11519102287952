import React, { useEffect, useState } from 'react'
import WorkInfo from './WorkInfo';
import EducationInfo from './EducationInfo';
import ImageProfile from './ImageProfile'
import './styles.scoped.css'
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from '../../helpers/backendHelper';
import { toast } from 'react-toastify';
export interface Education {
  id: string;
  org_name: string;
  from: string;
  to: string;
}

export interface Work {
  id: string;
  org_name: string;
  from: string;
  to: string;
  position: string;
}

const Education = () => {
  const { t, i18n } = useTranslation();
  const { username = "" } = useParams();

  const [educations, setEducations] = useState<Education[]>([]);
  const [works, setWorks] = useState<Work[]>([]);

  const addNewEducation = () => {
    setEducations([...educations, {
      id: '',
      org_name: '',
      from: '',
      to: ''
    }])
  }

  const addNewWork = () => {
    setWorks([...works, {
      id: '',
      org_name: '',
      from: '',
      to: '',
      position: ''
    }])
  }

  function reloadInfo() {

    let updated = 0;

    Backend(
      MicroServices.Roster,
      `/users/${username}/account-education`
    )
      .then((response) => response.json())
      .then((results) => {
        setEducations(results.educationList);
      })
      .catch((error) => {
      })

    Backend(
      MicroServices.Roster,
      `/users/${username}/account-work`
    )
      .then((response) => response.json())
      .then((results) => {
        setWorks(results.workList);
      })
      .catch((error) => {
      })
  }

  useEffect(() => {
    Backend(
      MicroServices.Roster,
      `/users/${username}/account-education`
    )
      .then((response) => response.json())
      .then((results) => {
        setEducations(results.educationList);
      })
      .catch((error) => {
      })
  }, [])

  useEffect(() => {
    Backend(
      MicroServices.Roster,
      `/users/${username}/account-work`
    )
      .then((response) => response.json())
      .then((results) => {
        setWorks(results.workList);
      })
      .catch((error) => {
      })
  }, [])

  return (
    <div className='containerMyAccount'>
      {/*<ImageProfile />*/}

      <div className='row row2'>
        <label className='titleInput' htmlFor="">{t('profile__education')}</label>
        <button className='btnAddSchool pointer'
          onClick={() => addNewEducation()}
        >
          {t('profile__addSchool')}
        </button>
      </div>

      {educations.map((mediaLink: Education) => (
        <EducationInfo educationData={mediaLink} reloadInfo={reloadInfo} />
      ))}

      <div className='row row2'>
        <label className='titleInput' htmlFor="">{t('profile__workHistory')}</label>
        <button className='btnAddWork pointer'
          onClick={() => addNewWork()}
        >
          {t('profile__addPosition')}
        </button>
      </div>

      {works.map((mediaLink: Work) => (
        <WorkInfo workData={mediaLink} reloadInfo={reloadInfo} />
      ))}

      {/* <div className='row row2'>
        <div className='col'>
          <button className='btnCancel pointer'>Cancel</button>
        </div>
        <div className='col'>
          <button className='btnSave pointer'>Save</button>
        </div>
      </div> */}

    </div>
  )
}

export default Education