import "./styles.scoped.css";
import React, { KeyboardEventHandler, useState, useEffect } from "react";
import Select from 'react-select';
import { ActionMeta, OnChangeValue } from "react-select";
import ModalSave from './ModalSave';
import CreatableSelect from "react-select/creatable";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from "../../store/store";
import OrganizationComplete from "../../interfaces/organization/OrganizationComplete";

toast.configure();

function SchoolListYouth({valueFilter} : {valueFilter:any }) {
    
    const schools = useSelector((state: RootState) => state.getOrgs.schoolsYouth);

    return (
        <div className="content-clubEvent">
            <table className="tableListClubEvents robotocondensed-bold-white-16px">
                <tr>
                    <th>
                        <div className="robotocondensed-bold-white-16px">School Name</div>
                    </th>
                    <th>
                        <div className="robotocondensed-bold-white-16px">School Website</div>
                    </th>
                    <th>
                        <div className="robotocondensed-bold-white-16px">City</div>
                    </th>
                    <th>
                        <div className="robotocondensed-bold-white-16px">State</div>
                    </th>
                    <th>
                        <div className="robotocondensed-bold-white-16px">District</div>
                    </th>
                </tr>

                {schools.filter((item: any) => {
                    if (!valueFilter) return true;
                    if (item.name.toUpperCase().includes(valueFilter) || 
                        item.name.toLowerCase().includes(valueFilter) || 
                        item.name.includes(valueFilter)
                    ) {
                        return true;
                    }
                    return false;
                }).map((event: OrganizationComplete) => (
                    <tr key={event.id} className="robotocondensed-normal-white-16px">
                        <td>
                            <div className="robotocondensed-normal-white-16px">
                                {event.name}
                            </div>
                        </td>
                        <td>
                            <div className="robotocondensed-normal-white-16px">
                                {event.website}
                            </div>
                        </td>
                        <td>
                            <div className="robotocondensed-normal-white-16px">
                                {event.city}
                            </div>
                        </td>
                        <td>
                            <div className="robotocondensed-normal-white-16px">
                                {event.state}
                            </div>
                        </td>
                        <td>
                            <div className="robotocondensed-normal-white-16px">
                                {event.district}
                            </div>
                        </td>

                    </tr>
                ))}
            </table>
        </div>
    );
}

export default SchoolListYouth;