import styled from "styled-components";

interface CheckboxOptionProps {
    label: string;
    value: string;
    checked: boolean;
    onChange: () => void
}

const CheckboxOption = ({
    label,
    value,
    checked,
    onChange,
}: CheckboxOptionProps) => (
    <CheckboxOuterWrapper onClick={onChange}>
        <CheckboxWrapper>
            <input
                type="checkbox"
                name={`checkbox-${value}`}
                value={value}
                checked={checked}
            />
            <i></i>
        </CheckboxWrapper>
        <CheckboxText>{label}</CheckboxText>
    </CheckboxOuterWrapper>
)

const CheckboxOuterWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* align-items: center; */
    gap: 35px;
    padding: 10px 0;
    cursor: pointer;
`;

const CheckboxWrapper = styled.div`
    position: relative;
    margin-bottom: 15px;
    padding: 0; /* Damos un padding de 60px para posicionar el elemento <i> en este espacio*/
    display: block;


    & input {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    & input {
        visibility: hidden;
        position: absolute;
        right: 0;
    }

    & input + i {
        background: transparent;
        border: 2px solid var(--lavender);
        position: absolute;
        left: 0;
        top: 0;
    }

    & input[type="checkbox"] + i {
        height: 18px;
        width: 18px;
        border-radius: 0%;
        left: 3px;
    }

    & input[type="checkbox"] + i:before {
        content: "";
        display: block;
        height: 6px;
        width: 6px;
        border-radius: 10%;
        position: absolute;
        z-index: 1;
        top: 4px;
        left: 4px;
        background: var(--lavender);
        transition: all 0.25s ease; /* Todas las propiedades | tiempo | tipo movimiento */
        transform: scale(0) /* Lo reducimos a 0*/;
        opacity: 0; /* Lo ocultamos*/
    }

    & input[type="checkbox"]:checked + i:before {
        transform: scale(1);
        opacity: 1;
    }

    &:hover input[type="checkbox"]:not(:checked) + i {
        background: rgba(85, 51, 120, 0.68);
    }
`;

const CheckboxText = styled.span`
    color: var(--white);
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    align-self: flex-start;
    width: 100%;
    text-align: left;
    padding-top: 2px;
    padding-bottom: 2px;
`;

export default CheckboxOption;