import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import TCListSearch from "../../components/TClubs/TCList/TCListSearch";
import TableAdmin from "../../components/TableAdmin";
import { LAVENDER, WHITE } from "../../components/ClubIconsNavBar";
import {
    MainWrapper,
    Header,
    Title,
    Row,
    RowGap,
    ActionWrapper,
    ClickableIcon,
    ContentWrapper,
    PhantomElement,
    Loader,
} from "../../components/SuperAdminComponents/basicForSACenter";
import useCountriesSA, { Country } from "../../hooks/useCountriesSA";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import useLanguagesSA, { ILanguage } from "../../hooks/useLanguagesSA";

const LangTable = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { languagePages, reloadLanguages } = useLanguagesSA(1000);

    const [SearchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentLang, setCurrentLang] = useState("");

    const filteredLanguages = useMemo(
        () =>
            languagePages[0].filter((lang) =>
                (lang.engName || "")
                    .toLowerCase()
                    .includes(SearchTerm.toLowerCase())
            ),
        [languagePages[0], SearchTerm]
    );

    const activateLanguage = (langCode: string) => {
        setLoading(true);
        setCurrentLang(langCode);
        Backend(
            MicroServices.Admin,
            `/lang/${langCode}/activate`,
            {
                method: "POST",
            }
        )
            .then((results) => {
                if (results.status === 201) {
                    toast.success(t('toast_countryActivated'));
                    reloadLanguages();
                } else {
                    toast.error('There was an error');
                }
            })
            .finally(() => {
                setLoading(false);
                setCurrentLang("");
            });
    };

    const deactivateLanguage = (langCode: string) => {
        setLoading(true);
        setCurrentLang(langCode);
        Backend(
            MicroServices.Admin,
            `/lang/${langCode}/deactivate`,
            {
                method: "POST",
            }
        )
            .then((results) => {
                if (results.status === 201) {
                    toast.success(t('toast_countryDesactivated'));
                    reloadLanguages();
                } else {
                    toast.error('There was an error');
                }
            })
            .finally(() => {
                setLoading(false);
                setCurrentLang("");
            });
    };

    return (
        <>
            <MainWrapper>
                <Header>
                    <Row>
                        <Title>Languages</Title>
                    </Row>
                    <RowGap>
                        <PhantomElement />
                        <TCListSearch
                            searchTerm={SearchTerm}
                            setSearchTerm={setSearchTerm}
                        />
                    </RowGap>
                </Header>
                <ContentWrapper>
                    <TableAdmin
                        headers={[
                            {
                                label: "Name",
                            },
                            {
                                label: "Active",
                            },
                            {
                                label: "Actions",
                                right: true,
                            },
                        ]}
                        items={filteredLanguages}
                        renderItem={(item: ILanguage) => [
                            item.engName,
                            <div>
                                {item.active ? (
                                    <FontAwesomeIcon
                                        icon="check"
                                        size="sm"
                                        color={WHITE}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon="times"
                                        size="sm"
                                        color={WHITE}
                                    />
                                )}
                            </div>,
                            <ActionWrapper>
                                {item.active ? (
                                    loading && item.code === currentLang ? (
                                        <Loader />
                                    ) : (
                                        <ClickableIcon
                                            icon="times"
                                            size="lg"
                                            color={LAVENDER}
                                            onClick={() =>
                                                deactivateLanguage(item.code)
                                            }
                                        />
                                    )
                                ) : loading && item.code === currentLang ? (
                                    <Loader />
                                ) : (
                                    <ClickableIcon
                                        icon="check"
                                        size="lg"
                                        color={LAVENDER}
                                        onClick={() =>
                                            activateLanguage(item.code)
                                        }
                                    />
                                )}
                            </ActionWrapper>,
                        ]}
                        keyExtractor={({ code }: ILanguage) => code}
                    />
                </ContentWrapper>
            </MainWrapper>
        </>
    );
};

export default LangTable;
