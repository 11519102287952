import "./styles.css";
import ClubFilterBar from "../../components/ClubFilterBar";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VIOLET, WHITE } from "../ClubIconsNavBar";

function Index(props: any) {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const userInfo = useSelector((state: any) => state.getUser.userInfo);

  const [selected, setSelected] = useState("Join");
  const [joinRequests, setJoinRequests] = useState<any[]>([]);
  const [quitRequest, setQuitRequest] = useState<any[]>([]);
  const [valueFilter, setValueFilter] = useState("");
  const [loadingResponseYes, setLoadingResposeYes] = useState(false);
  const [loadingResponseNo, setLoadingResposeNo] = useState(false);
  const [usernameResponse, setUsernameResponse] = useState("");

  const getJoinRequest = () => {
    Backend(
      MicroServices.Club,
      `/clubs/${id}/join-requests?username=${userInfo.username}`
    )
      .then((response) => response.json())
      .then((results) => {
        if (Array.isArray(results)) {
          setJoinRequests(results);
        } else {
          setJoinRequests([]);
        }
      });
  };

  const getQuitRequest = () => {
    Backend(
      MicroServices.Club,
      `/clubs/${id}/quit-requests?username=${userInfo.username}`
    )
      .then((response) => response.json())
      .then((results) => {
        if (Array.isArray(results)) {
          setQuitRequest(results);
        } else {
          setQuitRequest([]);
        }
      });
  };

  const acceptJoinRequest = (username: string) => {
    setLoadingResposeYes(true);
    setUsernameResponse(username);
    Backend(
      MicroServices.Club,
      `/clubs/${id}/join-accepted?username=${username}`,
      {
        method: 'POST'
      }
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(t('toast_joinRequest'))
          getJoinRequest();
          setUsernameResponse('');
          props.reloadClub();
        }
        setLoadingResposeYes(false);
      })
      .catch((err) => {
        setLoadingResposeYes(false);
        console.error(err);
      })
  }
  const denyJoinRequest = (username: string) => {
    setLoadingResposeNo(true);
    setUsernameResponse(username);
    Backend(
      MicroServices.Club,
      `/clubs/${id}/deny/join?username=${username}`,
      {
        method: 'DELETE'
      }
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success(t('toast_joinRequestDenied'))
          getJoinRequest()
          setUsernameResponse('');
          props.reloadClub();
        }
        setLoadingResposeNo(false);
      })
      .catch((err) => {
        setLoadingResposeNo(false);
        console.error(err);
      })
  }

  const acceptQuitRequest = (username: string) => {
    setLoadingResposeYes(true);
    setUsernameResponse(username);
    Backend(
      MicroServices.Club,
      `/clubs/${id}/quit-accepted?username=${username}`,
      {
        method: 'POST'
      }
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(t('toast_joinRequest'))
          getQuitRequest()
          setUsernameResponse('');
          props.reloadClub();
        }
        setLoadingResposeYes(false);
      })
      .catch((err) => {
        setLoadingResposeYes(false);
        console.error(err);
      })
  }

  const denyQuitRequest = (username: string) => {
    setLoadingResposeNo(true);
    setUsernameResponse(username);
    Backend(
      MicroServices.Club,
      `/clubs/${id}/deny/quit?username=${username}`,
      {
        method: 'DELETE'
      }
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success(t('toast_quitRequestDenied'))
          getQuitRequest()
          setUsernameResponse('');
          props.reloadClub();
        }
        setLoadingResposeNo(false);
      })
      .catch((err) => {
        setLoadingResposeNo(false);
        console.error(err);
      })
  }

  function inputSearch(e: any) {
    setValueFilter(e);
  }

  useEffect(() => {
    getJoinRequest();
    getQuitRequest();
  }, [selected]);

  useEffect(() => {
    getJoinRequest();
    getQuitRequest();
  }, [id]);

  return (
    <>
      <ClubFilterBar styles="Center" inputSearch={inputSearch} />
      <div className="requests-bar">
        <div
          className={
            selected === "Join" ? "request-section-selected" : "request-section"
          }
          onClick={() => setSelected("Join")}
        >
          <div
            className={
              selected === "Join"
                ? "robotocondensed-bold-lavender-16px"
                : "robotocondensed-normal-white-16px"
            }
          >
            {joinRequests.length || 0}
          </div>
          <div
            className={
              selected === "Join"
                ? "request-section-label robotocondensed-bold-lavender-16px"
                : "request-section-label robotocondensed-normal-white-14px"
            }
          >
            {(props?.infoClub?.club_type === 'TEAM') ? t('infoRequest__tryout') : t('infoRequest__join') || t('infoRequest__join')}
          </div>
        </div>
        <div
          className={
            selected === "Quit" ? "request-section-selected" : "request-section"
          }
          onClick={() => setSelected("Quit")}
        >
          <div
            className={
              selected === "Quit"
                ? "robotocondensed-bold-lavender-16px"
                : "robotocondensed-normal-white-16px"
            }
          >
            {quitRequest.length || 0}
          </div>
          <div
            className={
              selected === "Quit"
                ? "request-section-label robotocondensed-bold-lavender-16px"
                : "request-section-label robotocondensed-normal-white-14px"
            }
          >
            {t('infoRequest__quit')}
          </div>
        </div>
      </div>
      {selected === "Join" ? (
        <div className="request-main-row">

          {joinRequests.filter((item: any) => {
            if (!valueFilter) return true;
            if (item.fullname.toUpperCase().includes(valueFilter) || 
                item.fullname.toLowerCase().includes(valueFilter) || 
                item.fullname.includes(valueFilter)
            ) {
              return true;
            }
            return false;
          }).map((request: any) => (
            <div className="single-request">
              <div className="single-request-right">
                <img
                  className="request-avatar"
                  src={request.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"}
                  alt="advisor"
                />
                <div className="request-name robotocondensed-normal-white-16px">
                  {request.fullname}
                </div>
              </div>
              <div className="request-icons">
                <div className="Requests-btnRequest">
                  <button
                    className="Requests-buttonCancelRequest pointer"
                    onClick={() => denyJoinRequest(request.username)}
                    key={`${request.username} NO`}
                  >
                    {(loadingResponseNo && usernameResponse === request.username) ? (
                      <div className="icon-spinner-third buttonSendSignUp lavanda" />
                    ) : (
                      <FontAwesomeIcon
                        icon="times"
                        size="1x"
                        color={VIOLET}
                        className=" "
                      />
                    )}
                  </button>
                  <button
                    className="Requests-buttonAcceptRequest pointer"
                    onClick={() => acceptJoinRequest(request.username)}
                    key={`${request.username} YES`}
                  >
                    {(loadingResponseYes && usernameResponse === request.username) ? (
                      <div className="icon-spinner-third buttonSendSignUp" />
                    ) : (
                      <FontAwesomeIcon
                        icon="check"
                        size="1x"
                        color={WHITE}
                        className=" "
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="request-main-row">
          {quitRequest.filter((item: any) => {
            if (!valueFilter) return true;
            if (item.fullname.toUpperCase().includes(valueFilter) || 
                item.fullname.toLowerCase().includes(valueFilter) || 
                item.fullname.includes(valueFilter)
            ) {
              return true;
            }
            return false;
          }).map((request: any) => (
            <div className="single-request">
              <div className="single-request-right">
                <img
                  className="request-avatar"
                  src={request.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"}
                  alt="advisor"
                />
                <div className="request-name robotocondensed-normal-white-16px">
                  {request.fullname}
                </div>
              </div>
              <div className="request-icons">
                <div className="Requests-btnRequest">
                  <button
                    className="Requests-buttonCancelRequest pointer"
                    onClick={() => denyQuitRequest(request.username)}
                    key={`${request.username} NO`}
                  >
                    {(loadingResponseNo && usernameResponse === request.username) ? (
                      <div className="icon-spinner-third buttonSendSignUp lavanda" />
                    ) : (
                      <FontAwesomeIcon
                        icon="times"
                        size="1x"
                        color={VIOLET}
                        className=" "
                      />
                    )}
                  </button>
                  <button
                    className="Requests-buttonAcceptRequest pointer"
                    onClick={() => acceptQuitRequest(request.username)}
                    key={`${request.username} YES`}
                  >
                    {(loadingResponseYes && usernameResponse === request.username) ? (
                      <div className="icon-spinner-third buttonSendSignUp" />
                    ) : (
                      <FontAwesomeIcon
                        icon="check"
                        size="1x"
                        color={WHITE}
                        className=" "
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default Index;
