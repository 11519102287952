import React from "react";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './MySchool/styles.css';
import ClubInterface from "../../interfaces/ClubExpanded";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../ClubIconsNavBar";

interface DeleteClubProps {
    show: boolean;
    message: string;
    title:string;
    onHide:(del:boolean)=>void
}
function Index(props: DeleteClubProps) {

    function onHide(del:boolean) {
        props.onHide(del)
    }

    return (
        <>
            <Modal className="modalDelete" show={props.show} onHide={()=>onHide(false)}>
                <Modal.Header>
                    <Modal.Title>{`Delete ${props.title}`}</Modal.Title>
                    <FontAwesomeIcon
                        icon="times"
                        size='xl'
                        color={WHITE}
                        className="icon-times  "
                        onClick={() => onHide(false)}
                    />
                </Modal.Header>
                <Modal.Body>
                    {/* <FontAwesomeIcon
  icon="exclamation-triangle"
  size='lg'
  color={LAVENDER}
  className="chevron-down iconCheckOk  "
/> */}
                    <span className="labelEventCreated"> {props.message}</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="buttonModalDelete bCancel" onClick={() => onHide(false)}>
                        Cancel
                    </Button>
                    <Button className="buttonModalDelete bDelete" onClick={() => onHide(true)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Index;