import EventViewNavBar from "../../components/EventViewNavBar";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";

import "./styles.scoped.css";

const Event = ({ChatServiceInstance}:{ChatServiceInstance:ChatServiceInterface}) => {
  return (
    <>
      <EventViewNavBar ChatServiceInstance={ChatServiceInstance}/>
    </>
  );
}

export default Event;
