import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
    BaseInput,
    BaseTextArea,
    CancelButton,
    ContentWrapper,
    Header,
    MainWrapper,
    Row,
    SaveButton,
    Title,
} from "../../components/SuperAdminComponents/basicForSACenter";
import TwoColumns from "../../components/TwoColumns";
import { customStylesReactSelect, customStylesRSMulti, DropdownIndicator } from "../../helpers/selectHelper";
import useGender from "../../hooks/useGender";
import useGrades from "../../hooks/useGrades";
import SelectItem from "../../interfaces/SelectItem";
import { BaseFieldState, getFieldState } from "../TClubs/utils";
import Select from 'react-select';
import useAges from "../../hooks/useAges";
import useActiveCountry, { CountryOption } from "../../hooks/useActiveCountry";
import ModalDiscardChanges from "../../components/SetupSetup/ModalDiscardChanges";
import { useNavigate } from "react-router-dom";
import { Backend, MicroServices } from "../../helpers/backendHelper";

const CreateYO = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { genders, setCountry: setCountryGenders } = useGender();
    const { gradesSelect, setCountry: setCountryGrades } = useGrades();
    const { activeCountriesReactSelect } = useActiveCountry();
    const { agesSelect } = useAges();


    const [loadingForm, setLoadingForm] = useState(false);
    const [showDiscard, setShowDiscard] = useState(false);
    const [showErrors, setShowErrors] = useState(false);

    const [YOName, setYOName] = useState(getFieldState(''));
    const [YOWebsite, setYOWebsite] = useState(getFieldState(''));
    const [YOMission, setYOMission] = useState(getFieldState(''));
    const [YOGenders, setYOGenders] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [YOLowGrade, setYOLowGrade] = useState<BaseFieldState<SelectItem>>(getFieldState());
    const [YOHighGrade, setYOHighGrade] = useState<BaseFieldState<SelectItem>>(getFieldState());
    const [YOLowAge, setYOLowAge] = useState<BaseFieldState<SelectItem>>(getFieldState());
    const [YOHighAge, setYOHighAge] = useState<BaseFieldState<SelectItem>>(getFieldState());
    const [YONationServed, setYONationServed] = useState<BaseFieldState<CountryOption[]>>(getFieldState([]));

    useEffect(() => {
        setCountryGenders('USA');
        setCountryGrades('USA');
    }, []);

    useEffect(()=>{
        //Restriction of grades and genders to Nigeria
        if(YONationServed.value?.find((yo)=>yo.value==='NGA')){
            setCountryGenders('NGA');
            setCountryGrades('NGA');
        }else{
            setCountryGenders('USA');
            setCountryGrades('USA');
        }
    },[YONationServed])

    const cleanForm = () => {
        setYOName(getFieldState(''));
        setYOWebsite(getFieldState(''));
        setYOMission(getFieldState(''));
        setYOGenders(getFieldState([]));
        setYOLowGrade(getFieldState());
        setYOHighGrade(getFieldState());
        setYOLowAge(getFieldState());
        setYOHighAge(getFieldState());
        setYONationServed(getFieldState([]));
    }

    const onHideDiscard = (type: string) => {
        setShowDiscard(false);
        if (type === 'discard') {
            cleanForm();
            navigate(-1);
        }
    };

    const errorsInForm = () => {
        let hasErrors = false;

        if (!YOName.value || YOName.value.length <= 0) {
            hasErrors = true;
            setYOName({
                ...YOName,
                showError: true,
                error: 'Name is required',
            });
        } else {
            setYOName(getFieldState(YOName.value));
        }

        if (!YOWebsite.value || YOWebsite.value.length <= 0) {
            hasErrors = true;
            setYOWebsite({
                ...YOWebsite,
                showError: true,
                error: 'Website is required',
            });
        } else {
            setYOWebsite(getFieldState(YOWebsite.value));
        }

        if (!YOMission.value || YOMission.value.length <= 0) {
            hasErrors = true;
            setYOMission({
                ...YOMission,
                showError: true,
                error: 'Mission is required',
            });
        } else {
            setYOMission(getFieldState(YOMission.value));
        }

        if (!YONationServed.value || YONationServed.value.length <= 0) {
            hasErrors = true;
            setYONationServed({
                ...YONationServed,
                showError: true,
                error: 'At least on nation is required',
            });
        } else {
            setYONationServed(getFieldState(YONationServed.value));
        }

        if (!YOGenders.value || YOGenders.value.length <= 0) {
            hasErrors = true;
            setYOGenders({
                ...YOGenders,
                showError: true,
                error: 'At least on gender is required',
            });
        } else {
            setYOGenders(getFieldState(YOGenders.value));
        }

        if (!YOLowGrade.value || !YOLowGrade.value.value) {
            hasErrors = true;
            setYOLowGrade({
                ...YOLowGrade,
                showError: true,
                error: 'Low grade is required',
            });
        } else {
            setYOLowGrade(getFieldState(YOLowGrade.value));
        }

        if (!YOHighGrade.value || !YOHighGrade.value.value) {
            hasErrors = true;
            setYOHighGrade({
                ...YOHighGrade,
                showError: true,
                error: 'High grade is required',
            });
        } else if (YOLowGrade.value && Number(YOLowGrade.value.value) > Number(YOHighGrade.value.value)) {
            hasErrors = true;
            setYOHighGrade({
                ...YOHighGrade,
                showError: true,
                error: 'Low grade must be lower than High grade',
            });
        } else {
            setYOHighGrade(getFieldState(YOHighGrade.value));
        }

        if (!YOLowAge.value || !YOLowAge.value.value) {
            hasErrors = true;
            setYOLowAge({
                ...YOLowAge,
                showError: true,
                error: 'Low Age is required',
            });
        } else {
            setYOLowAge(getFieldState(YOLowAge.value));
        }

        if (!YOHighAge.value || !YOHighAge.value.value) {
            hasErrors = true;
            setYOHighAge({
                ...YOHighAge,
                showError: true,
                error: 'High Age is required',
            });
        } else if (YOLowAge.value && Number(YOLowAge.value.value) > Number(YOHighAge.value.value)) {
            hasErrors = true;
            setYOHighAge({
                ...YOHighAge,
                showError: true,
                error: 'Low Age must be lower than High Age',
            });
        } else {
            setYOHighAge(getFieldState(YOHighAge.value));
        }

        return hasErrors
    }

    const onCreate = () => {
        if (errorsInForm()) {
            // toast.error(t('toast_errorCreatingYO'));
            setShowErrors(true);
            return;
        }
        setLoadingForm(true);
        Backend(
            MicroServices.Admin,
            `/yo`,
            {
                method: 'POST',
                body: JSON.stringify({
                    type: 'I',
                    name: YOName.value,
                    mission: YOMission.value,
                    website: YOWebsite.value,
                    genders: YOGenders.value,
                    highAge: Number(YOHighAge.value?.value),
                    lowAge: Number(YOLowAge.value?.value),
                    highGrade: Number(YOHighGrade.value?.value),
                    lowGrade: Number(YOLowGrade.value?.value),
                    countries: (YONationServed.value || []).map(({value}) => value)
                })
            }
        )
            .then((response) => {
                if (response.status === 201) {
                    toast.success(t('toast_YOOrgCreated'));
                    cleanForm();
                    navigate(-1);
                    return;
                }
                toast.error(t('toast_errorCreatingYO'));
            })
            .catch(() => toast.error(t('toast_errorCreatingYO')))
            .finally(() => setLoadingForm(false));
    }

    const onCancel = () => {
        setShowDiscard(true);
    }

    const onChangeYOName = (event: React.FormEvent<HTMLInputElement>) => {
        setYOName({
            ...YOName,
            value: event?.currentTarget.value
        });
    }

    const onChangeYOWebsite = (event: React.FormEvent<HTMLInputElement>) => {
        setYOWebsite({
            ...YOWebsite,
            value: event?.currentTarget.value
        });
    }

    const onChangeYOMission = (event: React.FormEvent<HTMLTextAreaElement>) => {
        setYOMission({
            ...YOMission,
            value: event?.currentTarget.value
        });
    }

    const onChangeYOGenders = (event: any) => {
        let updateList = [...(YOGenders.value || [])];
        if (event.target.checked) {
          updateList = [...(YOGenders.value || []), event.target.value];
        } else {
          updateList.splice((YOGenders.value || []).indexOf(event.target.value), 1);
        }
        setYOGenders({
            ...YOGenders,
            value: updateList
        });
      }
    
    const onChangeYOLowGrade = (event: any) => {
        setYOLowGrade({
            ...YOLowGrade,
            value: event,
        });
    }
    
    const onChangeYOHighGrade = (event: any) => {
        setYOHighGrade({
            ...YOHighGrade,
            value: event,
        });
    }
    
    const onChangeYOLowAge = (event: any) => {
        setYOLowAge({
            ...YOLowAge,
            value: event,
        });
    }
    
    const onChangeYOHighAge = (event: any) => {
        setYOHighAge({
            ...YOHighAge,
            value: event,
        });
    }

    const onChangeYONationServed = (updatedList: any) => {
        setYONationServed({
            ...YONationServed,
            value: updatedList,
        })
    }

    return (
        <>
            <MainWrapper>
                <Header>
                    <Row>
                        <Title>Create Youth Organization</Title>
                    </Row>
                </Header>
                <ContentWrapper>
                    <TwoColumns
                        left={
                            <FormWrapper>
                                <BaseInput
                                    placeholder="Name *"
                                    value={YOName.value}
                                    onChange={onChangeYOName}
                                />
                                {showErrors && YOName.showError && <ErrorText>{YOName.error}</ErrorText>}
                                <BaseInput
                                    placeholder="Website *"
                                    value={YOWebsite.value}
                                    onChange={onChangeYOWebsite}
                                />
                                {showErrors && YOWebsite.showError && <ErrorText>{YOWebsite.error}</ErrorText>}
                                <BaseTextArea
                                    placeholder="Mission *"
                                    value={YOMission.value}
                                    onChange={onChangeYOMission}
                                />
                                {showErrors && YOMission.showError && <ErrorText>{YOMission.error}</ErrorText>}
                                <Select
                                    options={activeCountriesReactSelect}
                                    styles={customStylesRSMulti()}
                                    isMulti
                                    value={YONationServed.value}
                                    onChange={onChangeYONationServed}
                                    placeholder="Nations Served *"
                                />
                                {showErrors && YONationServed.showError && <ErrorText>{YONationServed.error}</ErrorText>}
                            </FormWrapper>
                        }
                        right={
                            <FormWrapper>
                                <div>
                                    <FieldTitle
                                        className="robotocondensed-bold-lavender-16px"
                                    >
                                        {t('setupSchool__gender')}
                                    </FieldTitle>
                                    <GendersWrapper>
                                        {genders.map((gender) => (
                                            <GenderWrapper className="option-chechbox">
                                                <label className="content-input">
                                                    <input
                                                        type="checkbox"
                                                        name="checkbox-F"
                                                        value={gender.id}
                                                        checked={(YOGenders.value || []).includes(gender.id)}
                                                        onChange={onChangeYOGenders}
                                                    />
                                                    <i></i>
                                                </label>
                                                <GenderLabel htmlFor="students">{gender.label}</GenderLabel>
                                            </GenderWrapper>
                                        ))}
                                    </GendersWrapper>
                                    {showErrors && YOGenders.showError && <ErrorText>{YOGenders.error}</ErrorText>}
                                </div>
                                <div>
                                    <FieldTitleGap
                                        className="robotocondensed-bold-lavender-16px"
                                    >
                                        {t('setupSchool__grades')}
                                    </FieldTitleGap>
                                    <TwoColumns
                                        left={<>
                                            <Select
                                                options={gradesSelect}
                                                styles={customStylesReactSelect()}
                                                components={{
                                                    DropdownIndicator,
                                                }}
                                                value={YOLowGrade.value}
                                                onChange={onChangeYOLowGrade}
                                                placeholder="Low Grade"
                                            />
                                            {showErrors && YOLowGrade.showError && <ErrorText>{YOLowGrade.error}</ErrorText>}
                                        </>}
                                        right={<>
                                            <Select
                                                options={gradesSelect}
                                                styles={customStylesReactSelect()}
                                                components={{
                                                    DropdownIndicator,
                                                }}
                                                value={YOHighGrade.value}
                                                onChange={onChangeYOHighGrade}
                                                placeholder="High Grade"
                                            />
                                            {showErrors && YOHighGrade.showError && <ErrorText>{YOHighGrade.error}</ErrorText>}
                                        </>}
                                    />
                                </div>
                                <div>
                                    <FieldTitleGap
                                        className="robotocondensed-bold-lavender-16px"
                                    >
                                        Ages *
                                    </FieldTitleGap>
                                    <TwoColumns
                                        left={<>
                                            <Select
                                                options={agesSelect}
                                                styles={customStylesReactSelect()}
                                                components={{
                                                    DropdownIndicator,
                                                }}
                                                value={YOLowAge.value}
                                                onChange={onChangeYOLowAge}
                                                placeholder="Low Age"
                                            />
                                            {showErrors && YOLowAge.showError && <ErrorText>{YOLowAge.error}</ErrorText>}
                                        </>}
                                        right={<>
                                            <Select
                                                options={agesSelect}
                                                styles={customStylesReactSelect()}
                                                components={{
                                                    DropdownIndicator,
                                                }}
                                                value={YOHighAge.value}
                                                onChange={onChangeYOHighAge}
                                                placeholder="High Age"
                                            />
                                            {showErrors && YOHighAge.showError && <ErrorText>{YOHighAge.error}</ErrorText>}
                                        </>}
                                    />
                                </div>
                            </FormWrapper>
                        }
                    />
                    <ActionWrapper>
                        <CancelButton onClick={onCancel}>
                            {t('button__cancel')}
                        </CancelButton>
                        <SaveButton onClick={onCreate}>
                            {loadingForm
                                ? (
                                    <div className="icon-spinner-third buttonSendSignUp"></div>
                                )
                                : t('button__save')
                            }
                        </SaveButton>
                    </ActionWrapper>
                </ContentWrapper>
            </MainWrapper>
            <ModalDiscardChanges
                show={showDiscard}
                onHide={onHideDiscard}
            />
        </>
    );
};

const FormWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const ActionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 15px;
    justify-content: flex-end;
`;

const GendersWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 15px;
    justify-content: space-between;
`;

const GenderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const GenderLabel = styled.label`
    margin-left: 30px;
    color: white;
    text-align: left;
    font-family: var(--font-family-roboto);
`;

const FieldTitle = styled.h2`
    width: 100%;
    text-align: left;
`

const FieldTitleGap = styled(FieldTitle)`
    margin-bottom: 10px;
`;

const ErrorText = styled.span`
    width: 100%;
    text-align: left;
    font-family: var(--font-family-roboto);
    color: #ff0000bb;
    font-size: 12px;
`;

export default CreateYO;
