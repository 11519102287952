import { useEffect, useState } from "react";
import { Backend, MicroServices } from "../helpers/backendHelper";
import SelectItem from "../interfaces/SelectItem";

export interface CountryOption extends SelectItem {};

const useActiveCountry = () => {
  const [activeCountries, setActiveCountries] = useState<string[]>([]);
  const [activeCountriesReactSelect, setActiveCountriesReactSelect] = useState<CountryOption[]>([])

  useEffect(() => {
    Backend(
      MicroServices.Roster,
      `/location/activeCountries`
    )
      .then((response) => response.json())
      .then((results) => {
        if (!Array.isArray(results)) {
          return;
        }
        setActiveCountries(results.map((country) => country.name));
        setActiveCountriesReactSelect(
          results.map((country) => ({value: country.location.split('#').at(-1), label: country.name}))
        )
      })
  }, []);

  return {activeCountries, activeCountriesReactSelect};
}

export default useActiveCountry;