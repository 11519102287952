import { useState, useEffect } from "react";
import Header from "./Header";
import "../../containers/SetupStudent/styles.scoped.css";
import { useSelector } from "react-redux";
import useCountWithFilter from "../../hooks/useCountWithFilter";
import { Interest } from "../../containers/SetupStudent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

const filterActiveInterests = (interests: Interest[]) =>
  interests.filter((i) => i.active);

function Index(props: {
  list: Interest[];
  handleOnClickI: (l: string[]) => void;
  canGoNext: boolean;
}) {
  const { t, i18n } = useTranslation();
  const interestsCount = useCountWithFilter(props.list, filterActiveInterests);
  const handleUpdateInterests = (index: number, active: boolean) => {
    const updateListInterests = props.list.map((interest: any) => {
      if (interest.id === index) {
        return {
          ...interest,
          active: active,
        };
      }
      return interest;
    });
    props.handleOnClickI(updateListInterests);
  };

  return (
    <div className="containerPhotoSetup">
      <Header
        text={t('setupStudent__myInterests')}
        subtext={t('setupStudent__selectInterests')}
        step={2}
      />
      <span className="interest-count">{interestsCount}/5</span>
      <div className="listInterestsStudents">
        {props.list.map((interest: any) => (
          <button
            key={interest.id}
            className={interest.active ? "active" : ""}
            onClick={() => handleUpdateInterests(interest.id, !interest.active)}
          >
            {interest.title}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Index;
