import React from 'react';
import './App.css';
import RootNavigation from './routes/RootNavigation';
import Amplify from 'aws-amplify';
import {region, userPoolId, userPoolWebClientId} from './AppConfig';

Amplify.configure({
    Auth: {
        region,
        userPoolId,
        userPoolWebClientId,
        mandatorySignIn: false,
        // authenticationFlowType: 'USER_PASSWORD_AUTH',
    }
});

function App() {
  return (
    <div className="App">
      <RootNavigation/>
    </div>
  );
}

export default App;
