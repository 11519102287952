import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import GridImages from "../Post/GridImages";
import CreatePostText from "./CreatePostText";
import { useTranslation } from "react-i18next";
import './styles.css'

function CreatePostImage({postImages, setPostImages, type, setType, fileLink, postText, setPostText}: 
  {postImages:string[],setPostImages: any, type:string, setType: any, fileLink: string,postText:string, setPostText: any }) {

  const onDrop = (acceptedFiles: any) => {
    const newPostsImage = postImages.concat(acceptedFiles)
    setPostImages(newPostsImage)
    setType('IMG')
  }

  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, noClick: true })
  const { t, i18n } = useTranslation();

  return (
    <>
        <div className="containerPostCreateImage">
          <div className="paddingCreateText">
            <CreatePostText
              postText={postText}
              setPostText={setPostText}
            />
          </div>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {(postImages.length === 0) &&
              <p onClick={open} className="textDragPostCreate pointer">
                {t('label_dropFilesPost')}
              </p>
            }
            {postImages.length > 0 &&
              <GridImages images={postImages} post='create' />
            }
          </div>
          {(fileLink && type === 'IMG') && (
            <img className=""
              src={fileLink}
            />
          )}
        </div>
    </>
  );
}

export default CreatePostImage;