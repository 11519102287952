import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import TCListsDropdown from "../../components/TClubs/TCList/TCListsDropdown";
import TCListSearch from "../../components/TClubs/TCList/TCListSearch";
import TableAdmin from "../../components/TableAdmin";
import TClub from "../../interfaces/TClubs/TClub/TClub";
import useTClubsSA from "../../hooks/tclub/useTClubsSA";
import { LAVENDER } from "../../components/ClubIconsNavBar";
import {
    MainWrapper,
    Header,
    Title,
    Row,
    RowGap,
    CreateBtn,
    ActionWrapper,
    ClickableIcon,
    ContentWrapper,
} from './components';
import CreateModal from "./TCLists/Create/CreateModal";
import ModalDeleteTClub from "../../components/TemplateClubsTable/ModalDeleteTemplateClub";

const CLUB_TYPE_MAP = {
    'CLUB': 'Club',
    'TEAM': 'Team',
    'COMM': 'Community',
};

const TClubs = () => {
    const navigate = useNavigate();
    const { tclubPages, reloadTClubs } = useTClubsSA(100);

    const [TCListSelected, setTCListSelected] = useState<string>();
    const [TCListSearchTerm, setTCListSearchTerm] = useState('');
    const [showCreate, setShowCreate] = useState<boolean>(false);
    const [reloadTCListDropdown, setReloadTCListDropdown] = useState(false);
    const [toDeleteTClub, settoDeleteTClub] = useState<TClub>();
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const filteredTClubs = useMemo(
        () =>
            tclubPages[0]
                .filter((tclub) => tclub.name.toLowerCase().includes(TCListSearchTerm.toLowerCase())),
        [tclubPages[0], TCListSearchTerm]
    )

    useEffect(() => {
        if (!TCListSelected || TCListSelected === 'ALL') {
            return;
        }

        if (TCListSelected === 'CREATE') {
            setShowCreate(true);
            setTCListSelected('ALL');
            return;
        }

        navigate(`./tclists/${TCListSelected}`)
    }, [TCListSelected]);

    const onHideCreateModal = () => {
        setShowCreate(false);
        setReloadTCListDropdown(true);
    }

    const onLoaded = () => {
        setReloadTCListDropdown(false);
    }

    return (
        <>
            <MainWrapper>
                <Header>
                    <Row>
                        <Title>Template Clubs</Title>
                        <CreateBtn onClick={() => navigate("./create")}>
                            Create Template Club
                        </CreateBtn>
                    </Row>
                    <RowGap>
                        <TCListsDropdown
                            setSelected={setTCListSelected}
                            reload={reloadTCListDropdown}
                            onLoaded={onLoaded}
                        />
                        <TCListSearch searchTerm={TCListSearchTerm} setSearchTerm={setTCListSearchTerm}/>
                    </RowGap>
                </Header>
                <ContentWrapper>
                    <TableAdmin
                        headers={[
                            {
                                label: 'Template Club',
                            },
                            {
                                label: 'Type',
                            },
                            {
                                label: 'Tags',
                            },
                            {
                                label: 'Actions',
                                right: true,
                            },
                        ]}
                        items={filteredTClubs}
                        renderItem={(item: TClub) => ([
                            item.name,
                            CLUB_TYPE_MAP[item.club_type],
                            item.tags.join(', '),
                            <ActionWrapper>
                                <ClickableIcon
                                    icon="pen"
                                    size='lg'
                                    color={LAVENDER}
                                    onClick={() => navigate(`./${item.id}/update`)}
                                />
                                <ClickableIcon
                                    icon="trash-alt"
                                    className="trash-alt"
                                    size="1x"
                                    color={LAVENDER}
                                    onClick={() => {
                                        settoDeleteTClub(item);
                                        setShowDeleteModal(true);
                                    }}
                                />
                            </ActionWrapper>
                        ])}
                        keyExtractor={({id}: TClub) => id}
                    />
                </ContentWrapper>
            </MainWrapper>
            <CreateModal
                show={showCreate}
                onHide={onHideCreateModal}
            />
            <ModalDeleteTClub
                show={showDeleteModal}
                onHide={() => {
                    setShowDeleteModal(false);
                }}
                id={toDeleteTClub?.id}
                title={toDeleteTClub?.name}
                reload={reloadTClubs}
            />
        </>
    );
}

export default TClubs;
