import React, { useEffect } from 'react';
import "./styles.css";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Schedule from './Schedule';
import Description from './Description';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getClubs, getClubsImAdv } from "../../store/actions/clubActions";
import { saveEvent } from '../../store/actions/eventActions';
import { getTimezones } from '../../store/actions/locationActions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
import ModalDiscardChanges from '../SetupSetup/ModalDiscardChanges';
import { useTranslation } from "react-i18next";

toast.configure();

export interface EventInterface {
  org_id: string;
  club_id?: string;
  club_name?: string;
  name: string;
  start: string;
  end: string;
  location: string;
  meeting_place: string;
  description: string;
  max_event_capacity: number;
  creator: string;
  type?: string;
  category: string;
  reach: string[];
  timezone: string;
  club_type?: string;
}

export interface FormError {
  hasError: boolean;
  errorMsg: string;
}

const BASE_ERROR: FormError = {
  hasError: false,
  errorMsg: ''
}

export interface EventFormInterface {
  org_id: FormError;
  club_id: FormError;
  club_name: FormError;
  name: FormError;
  start: FormError;
  end: FormError;
  location: FormError;
  meeting_place: FormError;
  description: FormError;
  max_event_capacity: FormError;
  creator: FormError;
  type: FormError;
  category: FormError;
  reach: FormError;
  timezone: FormError;
}

const BASE_FORM_ERRORS: EventFormInterface = {
  org_id: { ...BASE_ERROR },
  club_id: { ...BASE_ERROR },
  club_name: { ...BASE_ERROR },
  name: { ...BASE_ERROR },
  start: { ...BASE_ERROR },
  end: { ...BASE_ERROR },
  location: { ...BASE_ERROR },
  meeting_place: { ...BASE_ERROR },
  description: { ...BASE_ERROR },
  max_event_capacity: { ...BASE_ERROR },
  creator: { ...BASE_ERROR },
  type: { ...BASE_ERROR },
  category: { ...BASE_ERROR },
  reach: { ...BASE_ERROR },
  timezone: { ...BASE_ERROR },
}

function Index(props: any) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [step, setStep] = useState<1 | 2>(1);
  const [type, setType] = useState<'school' | 'club'>()
  const [tabActive, setTabActive] = useState(0);
  const [onFocus, setOnFocus] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isOrgadmin, setIsOrgadmin] = useState(false);

  const org = useSelector((state: RootState) => state.getOrgs.organizationInfo);
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const userRoles = useSelector((state: any) => state.getUser.userRoles);
  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  )

  const [event, setEvent] = useState<EventInterface>({
    org_id: org.id,
    club_id: props.club ? props.club.id : '0',
    name: "",
    start: '',
    end: '',
    location: org.location,
    meeting_place: "",
    description: "",
    max_event_capacity: 0,
    creator: userInfo.username,
    type: undefined,
    category: props.club ? (props.club.club_type === 'TEAM' ? 'TEAM' : 'CLUB') : "SCHOOL",
    reach: [],
    timezone: ""
  })

  const [eventFormError, setEventFormError] = useState<EventFormInterface>({ ...BASE_FORM_ERRORS });

  const [showDiscard, setShowDiscard] = useState<boolean>(false);

  const handleGoNext = () => {
    setStep(2);
  };

  // useEffect(()=>console.log(event),[event])

  useEffect(() => {
    if ('status' in org && userRoles.includes("ZAA#ORGADMIN")) {
      dispatch(getClubs({
        pending: org.status.startsWith('PEND'),
        org_id: org.id,
        youthOrg: (organization.type ==='YOUTHORG') ? "true": "false"
      }))
    }
    dispatch(getTimezones())
  }, [org]);

  const isOrgadminEvent = () => {
    if (userRoles.includes("ZAA#ORGADMIN") && !props.isInsideClub){
      setIsOrgadmin(true);
      setType(undefined);
      setStep(1);
    }
    else if(props.isInsideClub){
      setType('club');
      setStep(2);
      setIsOrgadmin(false);
    }
    else{
      setType('club');
      setStep(2);
      setIsOrgadmin(false);
    }
  }

  useEffect(() => {
    isOrgadminEvent();
  }, [])

  useEffect(() => {
    isOrgadminEvent();
  }, [userRoles])

  const isFormCorrect = (): boolean => {
    const errors: EventFormInterface = { ...BASE_FORM_ERRORS };
    if (event.category !== 'SCHOOL') {
      if (!event.club_id || event.club_id === '0') {
        errors.club_id = {
          hasError: true,
          errorMsg: 'Club is required',
        }
      } else {
        errors.club_id = {
          hasError: false,
          errorMsg: '',
        }
      }
    }

    if (event.name === '') {
      errors.name = {
        hasError: true,
        errorMsg: 'Name is required',
      }
    } else {
      errors.name = {
        hasError: false,
        errorMsg: '',
      }
    }

    if (event.start === '') {
      errors.start = {
        hasError: true,
        errorMsg: 'Start datetime is required',
      }
    } else {
      errors.start = {
        hasError: false,
        errorMsg: '',
      }
    }

    if (event.end === '') {
      errors.end = {
        hasError: true,
        errorMsg: 'End datetime is required',
      }
    } else {
      errors.end = {
        hasError: false,
        errorMsg: '',
      }
    }

    if (event.meeting_place === '') {
      errors.meeting_place = {
        hasError: true,
        errorMsg: 'Meeting place is required',
      }
    } else {
      errors.meeting_place = {
        hasError: false,
        errorMsg: '',
      }
    }

    if (event.description === '') {
      errors.description = {
        hasError: true,
        errorMsg: 'Description is required',
      }
    } else {
      errors.description = {
        hasError: false,
        errorMsg: '',
      }
    }

    if (!event.type || event.type === '') {
      errors.type = {
        hasError: true,
        errorMsg: 'Event type is required',
      }
    } else {
      errors.type = {
        hasError: false,
        errorMsg: '',
      }
    }

    if (event.reach.length === 0) {
      errors.reach = {
        hasError: true,
        errorMsg: 'Reach is required',
      }
    } else {
      errors.reach = {
        hasError: false,
        errorMsg: '',
      }
    }

    setEventFormError(errors)

    return !Object.values(errors).reduce((acc, curr) => acc || curr.hasError, false);
  }

  function handleOnClickSave() {

    const dateStart = event.start;
    const dateStartNew = dateStart.split("-").join('');

    const dateEnd = event.end;
    const dateEndNew = dateEnd.split("-").join('');

    if (dateStartNew > dateEndNew) {
      toast.error(t('toast_pleaseCheckSelected'));
      return;
    }

    setIsDisable(true);

    if (!isFormCorrect()) {
      setIsDisable(false);
      return;
    }

    setEventFormError({ ...BASE_FORM_ERRORS });

    let newEvent: EventInterface = event;
    if (event.club_id === '0') {
      const { club_id: _, ...eventWOClubId } = event;
      newEvent = eventWOClubId;
    }

    if(newEvent.reach.includes('SCHOOL')){
      newEvent = {
        ...newEvent,
        reach: organization.type === 'YOUTHORG' ? ['YO'] : ['SCHOOL']
      }
    }

    dispatch<any>(saveEvent(newEvent, userInfo.username))
      .then((results: any) => {

        if (results.message === "Event created successfully.") {
          onHide();
          setIsDisable(false);
          toast.success(results.message);
        }
        else {
          setIsDisable(false);
          toast.error(t('toast_ThereWasError'));
        }

      })
      .catch((error: any) => {
        console.error(error);
        toast.error(t('toast_ThereWasError'));
      })
  }

  function handleOnClick(e: any, tab: any) {
    setOnFocus(event.name !== '')
    setTabActive(tab);
  }

  const onHide = (type?: string) => {

    setShowDiscard(false)

    if (type === 'cancel') {
      return
    }

    isOrgadminEvent()

    setEventFormError({ ...BASE_FORM_ERRORS });
    setEvent({
      org_id: org.id,
      club_id: props.club ? props.club.id : '0',
      name: "",
      start: '',
      end: '',
      location: org.location,
      meeting_place: "",
      description: "",
      max_event_capacity: 0,
      creator: userInfo.username,
      type: undefined,
      category: props.club ? (props.club.club_type === 'TEAM' ? 'TEAM' : 'CLUB') : "SCHOOL",
      reach: [],
      timezone: ""
    })
    setTabActive(0)
    props.onHide();
  }

  useEffect(() => {
    setEvent({ ...event, club_id: type === 'club' ? (props.club ? props.club.id : undefined) : '0', category: (type || '').toUpperCase() })
  }, [type]);

  // useEffect(() => console.log('event', event), [event])

  return (
    <>
      <Modal className="modalCreateEvent" show={props.show} onHide={() => setShowDiscard(true)} >
        <Modal.Header>
          <h4 className="titleModal">{step === 2 ? (type === 'school' ? t('modalCreateEvent__title1') : t('modalCreateEvent__title2')) : t('modalCreateEvent__title3')}</h4>
          {(isOrgadmin) && (<div className="modalCreateEventHeader__wrapper">
            <div className={`modalCreateEventHeader__number ${step === 1 ? 'active' : ''}`}>1</div>
            <div className={`modalCreateEventHeader__number ${step === 2 ? 'active' : ''}`}>2</div>
          </div>)}
        </Modal.Header>
        <Modal.Body>
          {step === 1 ? (
            <>
              <span className="createEvent__selectTypeLabel">{t('modalCreateEvent__selectOption')}</span>
              <div className="createEvent__selectTypeOptions">
                <div className={`createEvent__selectTypeOption ${type === 'school' ? 'active' : ''}`} onClick={() => setType('school')}>
                  <FontAwesomeIcon
                    icon="building"
                    size='lg'
                    color={WHITE}
                    className=" "
                  />
                  <span>{t('modalCreateEvent__schoolEvent')}</span>
                </div>
                <div className={`createEvent__selectTypeOption ${type === 'club' ? 'active' : ''}`} onClick={() => setType('club')}>
                  <FontAwesomeIcon
                    icon="users"
                    size='lg'
                    color={WHITE}
                    className=" "
                  />
                  <span>{t('modalCreateEvent__clubActivity')}</span>
                </div>
              </div>
              <div className="createEvent__selectTypeActions">
                <Button className="buttonClose createEvent__btn" onClick={() => setShowDiscard(true)}>
                  {t('button__cancel')}
                </Button>
                <Button
                  className="buttonSend createEvent__btn"
                  onClick={handleGoNext}
                  disabled={type === undefined}
                >
                  {t('button__continue')}
                </Button>
              </div>
            </>
          ) : (
            <Description
              onFocus={onFocus}
              setOnFocus={setOnFocus}
              event={event}
              setEvent={setEvent}
              eventFormError={eventFormError}
              onHide={() => setShowDiscard(true)}
              handleOnClickSave={handleOnClickSave}
              isDisable={isDisable}
              isInsideClub={props.isInsideClub}
              clubInside={props.club}
            />
          )}
        </Modal.Body>
        {/*   <div className="nav">
            <div className={tabActive == 0 ? 'active' : ''} onClick={(e) => handleOnClick(e, '0')}>Description</div>
            <div className={tabActive == 1 ? 'active' : ''} onClick={(e) => handleOnClick(e, '1')}>Schedule</div>
          </div>
          {tabActive == 0 ?
            <Description
              onFocus={onFocus}
              setOnFocus={setOnFocus}
              event={event}
              setEvent={setEvent}
              eventFormError={eventFormError}
            />
            : tabActive == 1 ?
              <Schedule
                event={event}
                setEvent={setEvent}
                eventFormError={eventFormError}
              />
              : ''
          }
        
        <Modal.Footer>
          <Button className="buttonClose" onClick={onHide}>
            Cancel
          </Button>
          <Button 
            className="buttonSend" 
            onClick={handleOnClickSave}
            disabled={isDisable}
            >
            Save
          </Button>
        </Modal.Footer> */}
      </Modal>
      <ModalDiscardChanges show={showDiscard} onHide={onHide} />
    </>
  );
}

export default Index;
