import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import {
  GET_YO_REP_ERROR,
  GET_YO_REP_SUCCESS,
  GET_YO_TYPES_ERROR,
  GET_YO_TYPES_SUCCESS,
  START_GET_YO_REP,
  START_GET_YO_TYPES,
} from "../constants/yoconstants";

export const getYOTypes = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({type: START_GET_YO_TYPES});
    Backend(
      MicroServices.Roster,
      `/yo/types`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_YO_TYPES_SUCCESS,
          payload: results.YOTypes,
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_YO_TYPES_ERROR,
          payload: error,
        });
      })
  });

export const getYORep = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({type: START_GET_YO_REP});
    Backend(
      MicroServices.Roster,
      `/yo/rep`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_YO_REP_SUCCESS,
          payload: results.YORep,
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_YO_REP_ERROR,
          payload: error,
        });
      })
  });
