import React, { useEffect, useState } from "react";
import Header from "../Header";
import styled from "styled-components";
import {
  RobotocondensedRegularNormalWhite16,
  Fontawesome5proRegularNormalLavende,
  RobotocondensedBoldLavender16px,
} from "../../styledMixins";
import "./styles.css";
import SuperAdminLeftMenu from "../SuperAdminLeftMenu";
import AdminLeftMenu from "../AdminLeftMenu";

import "../../styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../store/store";
import { getOrganizationInfo, getOrganizationStatus, getOrganizationType, updateOrg } from "../../store/actions/orgsActions";
import { useSelector } from "react-redux";
import { unixToDateFormatted } from "../../helpers/dateHelper";
import { getActiveCities, getActiveCountries } from "../../store/actions/locationActions";
import OrgUpdateForm, { OrgUpdateFormMock } from "../../interfaces/organization/OrgUpdateForm";
import { orgTypesZaahah } from "../../helpers/orgTypeHelper";
import { ZERO } from "../ClubIconsNavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import YO from "../../interfaces/YOTable/YO/YO";

const ORGSTATUS: { [key: string]: string } = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  PENDING_APPROVAL: "Pending Approval",
  PENDING_CONFIRMATION: "Pending Confirmation",
}

function OrganizationsUpdate() {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const organization = useSelector((state: any) => state.getOrgs.organizationInfo || {});
  const [orgForm, setOrgForm] = useState<OrgUpdateForm>({ ...OrgUpdateFormMock });
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [idToUpdate, setIdToUpdate] = useState('');

  const [org, setOrg] = useState<YO>({
    id: '',
    type: 'I',
    name: '',
    mission: '',
    genders: [''],
    highAge: 0,
    lowAge: 0,
    highGrade: 0,
    lowGrade: 0,
    website: '',
    countries: [''],
  });

  useEffect(() => {
    dispatch<any>(getOrganizationType());
    dispatch<any>(getOrganizationStatus());
    dispatch<any>(getActiveCountries());
    dispatch<any>(getActiveCities());
  }, []);

  useEffect(() => {
    const locationSplit: string[] = (organization.location || '').split('#');
    setOrgForm({
      ...OrgUpdateFormMock,
      name: organization?.name || org?.name || '',
      type: organization?.type || (org.name !== '' ? 'YOUTHORG' : ''),
      website: organization?.website || org?.website || '',
      contactName: `${organization?.orgAdmin?.firstname || ''
        } ${organization?.orgAdmin?.lastname || ''
        }`,
      contactNumber: organization?.orgAdmin?.phoneNumber || '',
      contactNumberExt: organization?.orgAdmin?.phoneNumberExt || '',
      parentOrgName: organization?.parentOrg?.name || '',
      logo: organization?.logo,
      address: organization?.address || '',
      city: organization?.city || '',
      state: organization?.state || '',
      country: organization?.country || '',
      postalCode: locationSplit[locationSplit.length - 1],
      primaryContact: organization?.primaryContact,
    });

    if (organization.type === 'YOUTHORG') {
      setIdToUpdate(organization.YOTableId || '')
    }
  }, [organization, org]);

  useEffect(() => {
    if (id === '') {
      // TODO: Send to 404
      return;
    }
    dispatch<any>(getOrganizationInfo(id))
      .then((results: any) => {
        if (results.error === 'No org') {
          getYo();
        }
      })
      .catch(console.log)
  }, []);

  const getYo = () => {
    Backend(
      MicroServices.Admin,
      `/yo/${id}`,
      {
        method: 'GET'
      }
    )
      .then((response) => response.json())
      .then((results: YO) => {
        setOrg(results);
        setIdToUpdate(results.id);
      });
  }

  const handleOnChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputName: string,
  ) => setOrgForm({
    ...orgForm,
    [inputName]: e.target.value,
  })

  const handleOnChangeSelect = (
    e: React.ChangeEvent<HTMLSelectElement>,
    inputName: string,
  ) => setOrgForm({
    ...orgForm,
    [inputName]: e.target.value,
  });

  const handleOnChangePrimary = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputName: string,
  ) => setOrgForm({
    ...orgForm,
    primaryContact: {
      ...orgForm.primaryContact,
      [inputName]: e.target.value,
    }
  });

  const reloadOrg = () => {
    if (id === '') {
      // TODO: Send to 404
      return;
    }
    dispatch<any>(getOrganizationInfo(id))
      .then((results: any) => {
        if (results.error === 'No org') {
          getYo();
        }
      })
      .catch(console.log)
  };

  const updateYo = (formToSend: any) => {
    setLoadingUpdate(true)
    Backend(
      MicroServices.Admin,
      `/yo/${idToUpdate}?pending=${(organization.status || '').startsWith('P') ? 'true' : 'false'}`,
      {
        method: 'POST',
        body: JSON.stringify(formToSend)
      }
    )
      .then((response) => response.json())
      .then((results) => {
        reloadOrg();
        setLoadingUpdate(false);
        navigate(`../organizations/${id}/view`);
      })
      .catch(console.error);
  }

  const handleSubmit = () => {
    setLoadingUpdate(true);
    const formToSend = {
      zipcode: orgForm.postalCode,
      type: orgForm.type,
      website: orgForm.website,
      name: orgForm.name,
      primaryContact: orgForm.primaryContact,
      country: orgForm.country
    };
    if (organization.type === "YOUTHORG" || org.name !== '') {
      updateYo(formToSend)
    }
    else {
      dispatch<any>(updateOrg(formToSend, id, organization.status.startsWith('P')))
        .then(() => {
          reloadOrg();
          setLoadingUpdate(false);
          navigate(`../organizations/${id}/view`);
        })
        .catch(console.log);
    }
  };

  const orgT = useSelector((state: any) => state.getOrgs.organizationTypes);
  const orgTypes = orgTypesZaahah(orgT);

  const activeCountries = useSelector(
    (state: any) => state.getLocation.activeCountries || [],
  );
  const activeCities = useSelector(
    (state: any) => state.getLocation.activeCities || [],
  );

  const typeOptions = orgTypes.map(([value, label]: [string, string]) => {
    return {
      label: label,
      value: value,
    }
  })

  const countryOptions = activeCountries.map((e: any) => {
    return {
      label: e.name,
      value: e.location
    }
  });

  const cityOptions = activeCities.map((e: any) => {
    return {
      label: e.name,
      value: e.location
    }
  });

  const userRoles = useSelector((state: any) => state.getUser.userRoles)

  return (
    <div className="container-center-horizontal">
      <div className="organizations screen">
        {userRoles.includes('SUPER') ?
          <SuperAdminLeftMenu screen="Organizations" />
          :
          <AdminLeftMenu screen="Users" />
        }
        <Header />
        <div className="content">
          <div className="top-cont">
            <div className="organization robotocondensed-normal-white-16px">
              Organization
            </div>
            <div className="text-1 robotocondensed-normal-white-16px">/</div>
            <div className="view-organization robotocondensed-bold-lavender-16px">
              Update Organization
            </div>
          </div>
          <div className="org-view-cont">
            <div className="title-column">
              <h1 className="title-name-1">{organization?.name || org.name || ''}</h1>
              <div className="buttons-box">
                <div className="button-w">
                  <div
                    className="cancel"
                    onClick={() => navigate(`../organizations/${id}/view`)}>
                    Cancel
                  </div>
                </div>
                <div className="button-p">
                  <div className="save roboto-normal-white-16px" onClick={handleSubmit}>
                    {loadingUpdate ?
                      <div className="icon-spinner-third buttonSendSignUp"></div>
                      :
                      'Save'
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="view-columns">
              <div className="col-1">
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">
                    ID
                  </div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px">{id}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">
                    Name
                  </div>
                  <div className="info-field" id="input-color">
                    <input
                      className="roboto-normal-white-16px"
                      type="text"
                      name="name"
                      onChange={
                        (e: React.ChangeEvent<HTMLInputElement>) =>
                          handleOnChangeInput(e, 'name')
                      }
                      value={orgForm.name}
                    />
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">
                    Type
                  </div>
                  <div className="info-field" id="input-color">
                    <select
                      className="roboto-normal-white-16px"
                      value={orgForm.type}
                      onChange={
                        (e: React.ChangeEvent<HTMLSelectElement>) =>
                          handleOnChangeSelect(e, 'type')
                      }
                    >
                      {typeOptions.map((type: any) =>
                        <option value={type.value}>
                          {type.label}
                        </option>
                      )}
                    </select>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">
                    Website
                  </div>
                  <div className="info-field" id="input-color">
                    <input
                      className="roboto-normal-white-16px"
                      type="text"
                      name="website"
                      value={orgForm.website}
                      onChange={
                        (e: React.ChangeEvent<HTMLInputElement>) =>
                          handleOnChangeInput(e, 'website')
                      }
                    />
                  </div>
                </div>

                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">
                    Organization <br />Administrator
                  </div>
                  <div className="info-field" id="input-color">
                    <input
                      className="roboto-normal-white-16px"
                      type="text"
                      name="prim-contact"
                      value={orgForm.contactName}
                      onChange={
                        (e: React.ChangeEvent<HTMLInputElement>) =>
                          handleOnChangeInput(e, 'contactName')
                      }
                    />
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">
                    Administrator <br />Contact Phone
                  </div>
                  <div className="info-field" id="input-color">
                    <input
                      className="roboto-normal-white-16px"
                      type="text"
                      name="phone"
                      value={orgForm.contactNumber}
                      onChange={
                        (e: React.ChangeEvent<HTMLInputElement>) =>
                          handleOnChangeInput(e, 'contactNumber')
                      }
                    />
                  </div>
                </div>

                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">
                    Created by
                  </div>
                  <div className="info-field-medium1 input-color">
                    <div
                      className="roboto-normal-white-16px"
                    >
                      {organization?.orgAdmin?.firstname}{' '}
                      {organization?.orgAdmin?.lastname}
                    </div>
                  </div>
                  <div className="info-field-medium2">
                    <div className="roboto-normal-white-16px ellipses">
                      {organization?.orgAdmin?.email}
                    </div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">
                    Created
                  </div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px">
                      {unixToDateFormatted(organization?.created_at) || ''}
                    </div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">
                    Updated
                  </div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px">
                      {unixToDateFormatted(organization?.update_at) || ''}
                    </div>
                  </div>
                </div>

                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">Status</div>
                  <div className="status-column">
                    <div className={"statusField status-" + organization?.status || ''}>
                      {!!ORGSTATUS[((organization?.status || '') + '') || ''] && (
                        <>
                          <FontAwesomeIcon
                            icon={["fas", "circle"]}
                            color={ZERO}
                            className="place valign-text-middle"
                            size="xs"
                          />
                          <div className="status-field robotocondensed-normal-absolute-16px">
                            {ORGSTATUS[((organization?.status || '') + '') || '']}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">
                    Parent<br />Organization
                  </div>
                  <div className="info-field-medium1" id="input-color">
                    <input
                      className="roboto-normal-white-16px"
                      type="text"
                      name="parent-name"
                      value={orgForm.parentOrgName}
                      onChange={
                        (e: React.ChangeEvent<HTMLInputElement>) =>
                          handleOnChangeInput(e, 'parentOrgName')
                      }
                    />
                  </div>
                  <div className="info-field-medium2" id="input-color">
                    <input
                      className="roboto-normal-white-16px"
                      type="text"
                      name="parent-email"
                      defaultValue="martindfrancisco@whtvr.com"
                    />
                  </div>
                </div>

                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">
                    Address
                  </div>
                  <div className="info-field" id="input-color">
                    <input
                      className="roboto-normal-white-16px"
                      type="text"
                      name="address"
                      value={orgForm.address}
                      onChange={
                        (e: React.ChangeEvent<HTMLInputElement>) =>
                          handleOnChangeInput(e, 'address')
                      }
                    />
                  </div>
                </div>

              </div>
              <div className="col-2">
                <div className="logo-cont">
                  <div className="logo robotocondensed-bold-lavender-16px">
                    Logo
                  </div>
                  <div className="image-wrapper">
                    <img className="organization-logo"
                      src={organization?.logo || 'https://picsum.photos/200/200'}
                      alt="org-img"
                    />
                  </div>
                </div>

                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">
                    City
                  </div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px">{organization?.city || ''}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">
                    State
                  </div>
                  <div className="info-field">
                    <div className="roboto-normal-white-16px">{organization?.state || ''}</div>
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">
                    Country
                  </div>
                  <div className="info-field">
                    <select
                      className="fieldCountry"
                      onChange={
                        (e: React.ChangeEvent<HTMLSelectElement>) =>
                          handleOnChangeSelect(e, 'country')
                      }
                    >
                      {countryOptions.map((country: any) => (
                        <option selected={organization?.country === country.value} value={country.value}>{country.label}</option>
                      ))}
                    </select>
                    {/* <div className="roboto-normal-white-16px">{organization?.country || ''}</div> */}
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">
                    Postal Code
                  </div>
                  <div className="info-field" id="input-color">
                    <input
                      className="roboto-normal-white-16px"
                      type="text"
                      name="address"
                      value={orgForm.postalCode}
                      onChange={
                        (e: React.ChangeEvent<HTMLInputElement>) =>
                          handleOnChangeInput(e, 'postalCode')
                      }
                    />
                  </div>
                </div>

                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">
                    Primary <br />Contact
                  </div>
                  <div className="info-field" id="input-color">
                    <input
                      className="roboto-normal-white-16px"
                      type="text"
                      name="name-primary"
                      value={orgForm.primaryContact?.name}
                      onChange={
                        (e: React.ChangeEvent<HTMLInputElement>) =>
                          handleOnChangePrimary(e, 'name')
                      }
                    />
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">
                    Primary <br />Contact Phone
                  </div>
                  <div className="info-field" id="input-color">
                    <input
                      className="roboto-normal-white-16px"
                      type="text"
                      name="phone-primary"
                      value={orgForm.primaryContact?.phoneNumber}
                      onChange={
                        (e: React.ChangeEvent<HTMLInputElement>) =>
                          handleOnChangePrimary(e, 'phoneNumber')
                      }
                    />
                  </div>
                </div>
                <div className="info-field-row">
                  <div className="label-cont robotocondensed-bold-lavender-16px">
                    Primary <br />Contact Email
                  </div>
                  <div className="info-field" id="input-color">
                    <input
                      className="roboto-normal-white-16px"
                      type="text"
                      name="email-primary"
                      value={orgForm.primaryContact?.email}
                      onChange={
                        (e: React.ChangeEvent<HTMLInputElement>) =>
                          handleOnChangePrimary(e, 'email')
                      }
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ContentOrganizations = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  width: 100%;
  z-index: 1;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  padding: 30px 1000px;
  align-items: center;
  min-height: 944px;
  background-color: var(--violet);
`;

const ContentUsers = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  width: 1298px;
  z-index: 1;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  align-items: center;
  min-height: 944px;
  background-color: var(--violet);
`;

const FlexRow = styled.div`
  height: 124px;
  display: flex;
  align-items: flex-start;
  min-width: 1250px;
`;

const FlexCol = styled.div`
  width: 182px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 93px;
`;

const Title = styled.h1`
  min-height: 28px;
  font-family: var(--font-family-roboto_condensed);
  font-weight: 700;
  color: var(--lavender);
  font-size: var(--font-size-xxxl);
  letter-spacing: 0;
  padding-bottom: 30px;
`;

const Showing112Of864Items = styled.p`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  align-self: flex-end;
  margin-top: 46px;
  min-width: 198px;
  letter-spacing: 0;
  padding-left: 14px;
`;

const FlexCol1 = styled.div`
  width: 200px;
  position: relative;
  margin-left: 868px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 104px;
  justify-content: flex-end;
`;

const Viewing = styled.div`
  margin-left: 3px;
  display: flex;
  align-items: center;
  min-width: 197px;
  align-self: flex-end;
`;

const Viewing1 = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  min-width: 50px;
  letter-spacing: 0;
`;

const Box = styled.select`
${RobotocondensedBoldLavender16px}
  height: 40px;
  margin-left: 17px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  min-width: 130px;
  background-color: var(--violent-violet);
  border: none;
`;

const Address = styled.div`
  ${RobotocondensedBoldLavender16px}
  min-height: 19px;
  min-width: 65px;
  letter-spacing: 0;
`;

/* const PgNumber = styled.div`
  margin-top: 438px;
  display: flex;  
  padding: 0 30px;
  align-items: center;
  min-width: 360px;
`; */

export default OrganizationsUpdate;
