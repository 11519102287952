import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { GET_AHA_EVENTS_ERROR, GET_AHA_EVENTS_SUCCESS, GET_AHA_SETUP_ERROR, GET_AHA_SETUP_SUCCESS, START_GET_AHA_EVENTS, START_GET_AHA_SETUP } from "../constants/ahaConstants";

export const getAhaValueSetup = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_AHA_SETUP });
    Backend(
      MicroServices.AHA,
      '/earning-opportunity/creation-value'
    )
      .then((r) => {
        if (r.status === 200) {
          return r.json()
        } else {
          dispatch({
            type: GET_AHA_SETUP_ERROR,
            payload: r,
          });
        }
      })
      .then((results) => {
        dispatch({
          type: GET_AHA_SETUP_SUCCESS,
          payload: results.amount,
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_AHA_SETUP_ERROR,
          payload: error,
        });
      })
  });

export const getEventTypes = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_AHA_EVENTS });
    Backend(
      MicroServices.AHA,
      '/earning-opportunity/zaahah-events'
    )
      .then((r) => {
        if (r.status === 200) {
          return r.json()
        } else {
          dispatch({
            type: GET_AHA_EVENTS_ERROR,
            payload: r,
          });
        }
      })
      .then((results) => {
        dispatch({
          type: GET_AHA_EVENTS_SUCCESS,
          payload: results,
        })
      })
      .catch((e) => {
        dispatch({
          type: GET_AHA_EVENTS_ERROR,
          payload: e,
        });
      })
  });
