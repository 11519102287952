import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { capitalize } from "../../helpers/letterHelper";
import { inviteRoleZAA } from "../../helpers/roleHelper";
import { resentInviteAdmin, deleteInviteAdmin } from "../../store/actions/inviteActions";
import { GRAY, LAVENDER } from "../ClubIconsNavBar";
import "./styles.css";

const Index = ({ invite }: any) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resentInvite = () => {
    dispatch<any>(resentInviteAdmin(formatId()));
  };

  const deleteInvite = () => {
    dispatch<any>(deleteInviteAdmin(formatId()))
      .then(() => navigate('/admin/invites'));
  };

  const formatId = () => {
    return (invite.role === "ZAA#SECOND#ADVISOR" || invite.role === "ZAA#STAFF#ADVISOR") ?
      `Advisor#${invite.club_id}#${invite.email}` : `End_User#${invite.club_id}#${invite.email}`;
  }

  return (
    <tr className="rowOrganization robotocondensed-normal-white-16px">
      {/* <td>
        <div className="id-1 robotocondensed-normal-white-16px">
          1
        </div>
      </td> */}
      <td>
        <div className="club robotocondensed-normal-white-16px">
          {invite.firstName} {invite.lastName}
        </div>
      </td>
      <td>
        <div className="email robotocondensed-normal-white-16px">
          {invite.email}
        </div>
      </td>
      <td>
        <p className="inviteToken robotocondensed-normal-white-16px">
          {invite.token.length > 30 ? `${invite.token.substring(0, 27)}...` : invite.token}
        </p>
      </td>
      <td>
        <div className="status robotocondensed-normal-white-16px">
          {capitalize(invite.status)}
        </div>
      </td>
      <td>
        <div className="type robotocondensed-normal-white-16px">
          {inviteRoleZAA(invite.role)}
        </div>
      </td>
      <td>
        <div className=" actions">
          <FontAwesomeIcon
            icon="eye"
            size='lg'
            color={LAVENDER}
            className="view-user  "
            onClick={() => navigate(`./${invite.token}/view`)}
          />
          <FontAwesomeIcon
            icon="trash"
            size='lg'
            color={LAVENDER}
            className="update-user  "
            onClick={() => deleteInvite()}
          />
          <button onClick={() => resentInvite()}
                  disabled={invite.status === 'USED' || invite.status === 'NEW'}
                  className="wrapButton-disabled-paperPlane-admin"
          >
              <FontAwesomeIcon
                  icon="paper-plane"
                  size='lg'
                  color={(invite.status === 'USED' || invite.status === 'NEW') ? 
                              (GRAY): (LAVENDER)}
                  className={`paper-plane-invite ${(invite.status !== 'USED' && invite.status !== 'NEW') && 
                  ("pointer")}`}
              />
          </button>
        </div>
      </td>
    </tr>
  )
};

export default Index;