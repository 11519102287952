import { useEffect, useState } from "react";
import TwoColumns from "../../../../components/TwoColumns";
import { BaseInput, BaseInputWrapper, BaseTextArea, BaseTextAreaWrapper, CancelButton, ContentWrapper, CreatableSelectWrapper, Header, MainWrapper, Row, SaveButton, SelectWrapper, Title } from "../../components";
import { BaseFieldState, getFieldState } from "../../utils";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { customStylesRSLeft, customStylesRSMulti, DropdownIndicator } from "../../../../helpers/selectHelper";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ModalDiscardChanges from "../../../../components/SetupSetup/ModalDiscardChanges";
import { useNavigate } from "react-router-dom";
import { Backend, MicroServices } from "../../../../helpers/backendHelper";
import CheckboxList from "./components/CheckboxList";
import { toast } from "react-toastify";
import TableAdmin from "../../../../components/TableAdmin";
import TCLYouth from "../../../../interfaces/TClubs/TCLYouth/TCLYouth";
import AddYouthCreateModal from "./AddYouthCreateModal";
import { CheckboxWrapper } from "./components/CheckboxOption";

type ClubType = 'CLUB' | 'TEAM' | 'COMM';
const CLUB_TYPE = ['CLUB', 'TEAM', 'COMM'];
const CLUB_TYPE_OPTIONS: {label: string, value: ClubType}[] = [
    {
        value: 'CLUB',
        label: 'Club',
    },
    {
        value: 'TEAM',
        label: 'Team',
    },
    {
        value: 'COMM',
        label: 'Community',
    },
];

type TClubAccessType = 'PUBLIC' | 'INVITE_ONLY' | 'PRIVATE';
const PRIVACY_TYPE = ['PUBLIC', 'INVITE_ONLY', 'PRIVATE'];
const PRIVACY_TYPE_OPTIONS: {label: string, value: TClubAccessType}[] = [
    {
        value: 'PUBLIC',
        label: 'Public',
    },
    {
        value: 'INVITE_ONLY',
        label: 'Invite Only',
    },
    {
        value: 'PRIVATE',
        label: 'Private',
    },
];

const MEMBER_TYPE = [
    {
        value: 'STUDENT',
        label: 'Student',
    },
    {
        value: 'ALUMNI',
        label: 'Alumni',
    },
    {
        value: 'STAFF',
        label: 'Staff',
    },
    {
        value: 'GUARDIAN',
        label: 'Guardian',
    },
];

const genderList: any = {
    'M': 'Male',
    'F': 'Female',
    'O': 'Non binary', //'Non binary',
    'P': 'Prefer not to disclose', //'Prefer not to disclose',
};

interface TCLYouthToSend extends Omit<TCLYouth, 'tc_id'> {};

const CreateTClub = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [TClubName, setTClubName] = useState<BaseFieldState<string>>(getFieldState(''));
    const [TClubType, setTClubType] = useState<BaseFieldState<ClubType>>(getFieldState());
    const [TClubSkills, setTClubSkills] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [TClubDescription, setTClubDescription] = useState<BaseFieldState<string>>(getFieldState(''));
    const [TClubMemberType, setTClubMemberType] = useState<BaseFieldState<string[]>>(getFieldState([]));
    const [TClubPrivacy, setTClubPrivacy] = useState<BaseFieldState<TClubAccessType>>(getFieldState());

    const [TCAllowInd, setTCAllowInd] = useState<BaseFieldState<boolean>>(getFieldState(false));
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [showDiscard, setShowDiscard] = useState(false);
    const [showAddYouth, setShowAddYouth] = useState(false);
    const [youth_orgs, setYouth_orgs] = useState<TCLYouthToSend[]>([])
    
    const [TClubTypeOption, setTClubTypeOption] = useState();
    const [TClubPrivacyOption, setTClubPrivacyOption] = useState();
    const [TClubSkillsOptions, setTClubSkillsOptions] = useState([]);

    const cleanForm = () => {
        setTClubName(getFieldState(''));
        setTClubType(getFieldState());
        setTClubSkills(getFieldState([]));
        setTClubDescription(getFieldState(''));
        setTClubMemberType(getFieldState([]));
        setLoadingCreate(false);
        setShowDiscard(false);
        setTClubTypeOption(undefined);
        setTClubSkillsOptions([]);
    }

    const onChangeTClubName = (event: React.FormEvent<HTMLInputElement>) => {
        if (event) {
            setTClubName({
                ...TClubName,
                value: event?.currentTarget.value
            });
        }
    }
    const onChangeTClubDescription = (event: React.FormEvent<HTMLTextAreaElement>) => {
        if (event) {
            setTClubDescription({
                ...TClubDescription,
                value: event?.currentTarget.value
            });
        }
    }

    const onChangeTClubType = (element: any) => {
        setTClubTypeOption(element);
        setTClubType({
            ...TClubType,
            value: element.value,
        })
    }

    const onChangeTClubPrivacy = (element: any) => {
        setTClubPrivacyOption(element);
        setTClubPrivacy({
            ...TClubPrivacy,
            value: element.value,
        })
    }

    const onChangeTClubSkills = (updatedList: any) => {
        setTClubSkillsOptions(updatedList);
        setTClubSkills({
            ...TClubSkills,
            value: updatedList.map(({label}: any) => label),
        })
    }

    const errorsInForm = () => {
        let hasErrors = false;

        if (!TClubName.value || TClubName.value.length <= 0) {
            hasErrors = true;
            setTClubName({
                ...TClubName,
                showError: true,
                error: 'Name is required',
            })
            console.log('Name is required');
        } else {
            setTClubName(getFieldState(TClubName.value));
        }

        if (!TClubType.value) {
            hasErrors = true;
            setTClubType({
                ...TClubType,
                showError: true,
                error: 'Club Type is required',
            })
            console.log('Club Type is required.');
        } else if (!CLUB_TYPE.includes(TClubType.value)) {
            hasErrors = true;
            setTClubType({
                ...TClubType,
                showError: true,
                error: 'Club Type must be either Club, Team or Community',
            })
            console.log('Club Type must be either Club, Team or Community');
        } else {
            setTClubType(getFieldState(TClubType.value));
        }

        if (!TClubMemberType.value || TClubMemberType.value.length <= 0) {
            hasErrors = true;
            setTClubMemberType({
                ...TClubMemberType,
                showError: true,
                error: 'Select at least one member type',
            })
            console.log('Select at least one member type');
        } else {
            setTClubMemberType(getFieldState(TClubMemberType.value));
        }


        if (!TClubDescription.value || TClubDescription.value.length <= 0) {
            hasErrors = true;
            setTClubDescription({
                ...TClubDescription,
                showError: true,
                error: 'Description is required',
            })
            console.log('Description is required');
        } else {
            setTClubDescription(getFieldState(TClubDescription.value));
        }

        if(youth_orgs.some((yo)=>!yo.genders_allowed?.length || !yo.grades_allowed?.length || !yo.yo_name)){
            //Should not happen because youth_orgs check is done inside AddYouthCreateModal (leave for extra protection)
            hasErrors = true;
        }

        return hasErrors;
    }

    const onHideDiscard = (type: string) => {
        setShowDiscard(false);
        if (type === 'discard') {
            cleanForm();
            navigate(-1);
        }
    };

    const onCancel = () => {
        setShowDiscard(true);
    }

    const onCreate = () => {
        if (errorsInForm()) {
            toast.error(t('toast_errorMissingInformation'));
            return;
        }
        setLoadingCreate(true);
        Backend(
            MicroServices.Club,
            '/tclubs',
            {
                method: 'POST',
                body: JSON.stringify({
                    name: TClubName.value,
                    club_type: TClubType.value,
                    tags: TClubSkills.value,
                    description: TClubDescription.value,
                    member_types: TClubMemberType.value,
                    role_assigned: 'NO_ROLE_ASSIGNED',
                    youth_orgs,
                    allowInd: TCAllowInd.value,
                    privacy: TClubPrivacy.value,
                })
            }
        )
            .then((response) => {
                if (response.status === 201) {
                    toast.success(t('toast_templateClubCreated'));
                    cleanForm();
                    navigate(-1);
                    return;
                }
                toast.error(t('toast_errorCreatingTemplate'));
            })
            .catch(() => toast.error(t('toast_errorCreatingTemplate')))
            .finally(() => setLoadingCreate(false));
    }

    const onChangeMemberTypes = (value: string) => {
        console.log(value)
        if (TClubMemberType.value?.includes(value)) {
            const list = [...TClubMemberType.value];
            list.splice(list.indexOf(value), 1)
            setTClubMemberType({
                ...TClubMemberType,
                value: list,
            })
        } else {
            setTClubMemberType({
                ...TClubMemberType,
                value: [...(TClubMemberType.value || []), value]
            })
        }
    }

    const onHideAddYouth = (yo?: TCLYouthToSend) => {
        setShowAddYouth(false);
        if (!yo) {
            return;
        }
        const index = youth_orgs.findIndex((y)=>y.yo_id===yo.yo_id)
        let newYOlist = [...youth_orgs]
        index===-1 ? newYOlist = newYOlist.concat([yo]) : newYOlist[index] = yo
        setYouth_orgs(newYOlist)
    }

    return (
        <>
            <MainWrapper>
                <Header>
                    <Row>
                        <Title>Create Template Club</Title>
                    </Row>
                </Header>
                <ContentWrapper>
                    <TwoColumns
                        left={<FormWrapper>
                            <BaseInputWrapper
                                placeholder="Club Name *"
                                value={TClubName.value || ''}
                                onChangeValue={onChangeTClubName}
                                showError={TClubName.showError || false}
                                error={TClubName.error || ''}
                            />
                            <SelectWrapper
                                options={CLUB_TYPE_OPTIONS}
                                styles={customStylesRSLeft()}
                                components={{
                                    DropdownIndicator
                                }}
                                value={TClubTypeOption || ''}
                                onChangeValue={onChangeTClubType}
                                placeholder="Club Type *"
                                showError={TClubType.showError || false}
                                error={TClubType.error || ''}
                            />
                            <SelectWrapper
                                options={PRIVACY_TYPE_OPTIONS}
                                styles={customStylesRSLeft()}
                                components={{
                                    DropdownIndicator
                                }}
                                value={TClubPrivacyOption || ''}
                                onChangeValue={onChangeTClubPrivacy}
                                placeholder="Privacy"
                                showError={TClubMemberType.showError || false}
                                error={TClubMemberType.error || ''}
                            />
                            <CheckboxList
                                title="Member Type *"
                                options={MEMBER_TYPE}
                                valuesSelected={TClubMemberType.value || []}
                                showError={TClubMemberType.showError || false}
                                error={TClubMemberType.error || ''}
                                onChangeValue={onChangeMemberTypes}
                            />
                            <InlineField>
                                <FieldTitle>Allow Independent</FieldTitle>
                                <CheckboxWrapper onClick={() => setTCAllowInd({...TCAllowInd, value: !TCAllowInd.value})}>
                                    <input
                                        type="checkbox"
                                        name={`checkbox-allowed`}
                                        checked={TCAllowInd.value}
                                    />
                                    <i></i>
                                </CheckboxWrapper>
                            </InlineField>
                        </FormWrapper>}
                        right={<FormWrapper>
                            <CreatableSelectWrapper
                                // options={activeCountriesReactSelect}
                                styles={customStylesRSMulti()}
                                value={TClubSkillsOptions}
                                onChangeValue={onChangeTClubSkills}
                                placeholder="Skills Learned"
                                showError={TClubSkills.showError || false}
                                error={TClubSkills.error || ''}
                            />
                            <BaseTextAreaWrapper
                                placeholder="Club Description *"
                                value={TClubDescription.value || ''}
                                onChangeValue={onChangeTClubDescription}
                                showError={TClubDescription.showError || false}
                                error={TClubDescription.error || ''}
                            />
                        </FormWrapper>}
                    />
                    <RowLeft>
                        <AddYOBtn onClick={() => setShowAddYouth(true)}>
                            Add Youth Organization Affiliated
                        </AddYOBtn>
                    </RowLeft>
                    <TableAdmin
                        headers={[
                            {
                                label: 'Youth Organization',
                            },
                            {
                                label: 'Genders Allowed',
                            },
                            {
                                label: 'Grades  Allowed',
                            },
                        ]}
                        items={youth_orgs}
                        renderItem={(item: TCLYouthToSend) => ([
                            item.yo_name || item.yo_id,
                            item.genders_allowed?.map((g) => genderList[g])?.join(', '),
                            item.grades_allowed?.join(', ')
                        ])}
                        keyExtractor={({yo_id}: TCLYouthToSend) => yo_id}
                    />
                    <ActionWrapper>
                        <CancelButton onClick={onCancel}>
                            {t('button__cancel')}
                        </CancelButton>
                        <SaveButton onClick={onCreate}>
                            {loadingCreate
                                ? (
                                    <div className="icon-spinner-third buttonSendSignUp"></div>
                                )
                                : t('button__save')
                            }
                        </SaveButton>
                    </ActionWrapper>
                </ContentWrapper>
            </MainWrapper>
            <ModalDiscardChanges
                show={showDiscard}
                onHide={onHideDiscard}
            />
            <AddYouthCreateModal
                show={showAddYouth}
                onHide={onHideAddYouth}
            />
        </>
    )
}

const FormWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const ActionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 15px;
    justify-content: flex-end;
`;

const RowLeft = styled(Row)`
    justify-content: flex-end;
`;

const AddYOBtn = styled.button`
    align-items: center;
    background-color: var(--electric-violet);
    border-radius: 12px;
    display: flex;
    height: 33px;
    padding: 18px;
    margin-left: 13px;
    border: none;
    cursor: pointer;
    /* Find a better way */
    color: var(--white);
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
`;

const FieldTitle = styled.h2`
    color: #FFFFFF77;
    font-size: 16px;
    font-family: 'Roboto';
    text-align: left;
`;

const InlineField = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
`;

export default CreateTClub;