import { resolve } from 'dns';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Backend, MicroServices } from '../../helpers/backendHelper';
import i18n from '../../i18n';
import {
  CANCEL_FRIEND_REQUEST_ERROR,
  CANCEL_FRIEND_REQUEST_SUCCESS,
  CREATE_EMAIL_REQ_ERROR,
  CREATE_EMAIL_REQ_SUCCESS,
  DELETE_FRIEND_ERROR,
  DELETE_FRIEND_SUCCESS,
  GET_CLUB_SUG_ERROR,
  GET_CLUB_SUG_SUCCESS,
  GET_C_SUG_ERROR,
  GET_C_SUG_SUCCESS,
  GET_I_SUG_ERROR,
  GET_I_SUG_SUCCESS,
  GET_UNREG_SUG_ERROR,
  GET_UNREG_SUG_SUCCESS,
  GET_USER_BIRTHDAY_CONNECTIONS_ERROR,
  GET_USER_BIRTHDAY_CONNECTIONS_SUCCESS,
  GET_USER_FRIENDS_ERROR,
  GET_USER_FRIENDS_SUCCESS,
  GET_USER_PENDING_REQUESTS_ERROR,
  GET_USER_PENDING_REQUESTS_SUCCESS,
  GET_USER_POSSIBLE_CONNECTIONS_ERROR,
  GET_USER_POSSIBLE_CONNECTIONS_SUCCESS,
  GET_USER_SENT_REQUESTS_ERROR,
  GET_USER_SENT_REQUESTS_SUCCESS,
  GET_U_SUG_ERROR,
  GET_U_SUG_SUCCESS,
  REJECT_FRIEND_REQUEST_ERROR,
  REJECT_FRIEND_REQUEST_SUCCESS,
  SAVE_I_ERROR,
  SAVE_I_SUCCESS,
  SAVE_NEW_FRIEND_ERROR,
  SAVE_NEW_FRIEND_SUCCESS,
  START_CANCEL_FRIEND_REQUEST,
  START_CREATE_EMAIL_REQ,
  START_DELETE_FRIEND,
  START_GET_CLUB_SUG,
  START_GET_C_SUG,
  START_GET_I_SUG,
  START_GET_UNREG_SUG,
  START_GET_USER_BIRTHDAY_CONNECTIONS,
  START_GET_USER_FRIENDS,
  START_GET_USER_PENDING_REQUESTS,
  START_GET_USER_POSSIBLE_CONNECTIONS,
  START_GET_USER_SENT_REQUESTS,
  START_GET_U_SUG,
  START_REJECT_FRIEND_REQUEST,
  START_SAVE_I,
  START_SAVE_NEW_FRIEND,
} from '../constants/connectionsConstants';

export const getInterestSuggested =
  (countryCode: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise(() => {
        dispatch({ type: START_GET_I_SUG });
        Backend(
          MicroServices.Connections,
          `/interests/by-country?country=${countryCode}`)
          .then((response) => response.json())
          .then((results) => {
            dispatch({
              type: GET_I_SUG_SUCCESS,
              payload: results,
            });
          })
          .catch((error) => {
            dispatch({
              type: GET_I_SUG_ERROR,
              payload: error,
            });
          });
      });

export const SaveInterests =
  (
    username: string,
    interests: string[],
    organizationId: string,
  ): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve) => {
        dispatch({ type: START_SAVE_I });
        Backend(
          MicroServices.Connections,
          `/profile/interests`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username,
            interests,
            organizationId,
          }),
        })
          .then((response) => response.json())
          .then((results) => {
            resolve(true);
            dispatch({
              type: SAVE_I_SUCCESS,
              payload: results,
            });
          })
          .catch((error) => {
            dispatch({
              type: SAVE_I_ERROR,
              payload: error,
            });
          });
      });

export const getClubSuggestions =
  (
    orgId: string,
    grade: number,
    gender: string,
    role: string,
    username: string
  ): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise(() => {
        dispatch({ type: START_GET_C_SUG });
        Backend(
          MicroServices.Connections,
          `/club-suggestions?org_id=${orgId}&grade=${grade}&gender=${gender}&role=${role}&username=${username}`
        )
          .then((response) => response.json())
          .then((results) => {
            // dispatch({
            //   type: GET_C_SUG_SUCCESS,
            //   payload: results,
            // });
            //results.map((id: string) => dispatch(getClubSuggested(id)))
            //const clubs = await Promise.all(results.map(async (id: string) => dispatch(getClubSuggested(id))))
            dispatch({
              type: GET_C_SUG_SUCCESS,
              payload: results,
            });
          })
          .catch((error) => {
            dispatch({
              type: GET_C_SUG_ERROR,
              payload: error,
            });
          });
      });

export const getClubSuggestionsStaff =
  (
    orgId: string,
    gender: string,
    role: string,
    username: string
  ): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise(() => {
        dispatch({ type: START_GET_C_SUG });
        Backend(
          MicroServices.Connections,
          `/club-suggestions?org_id=${orgId}&gender=${gender}&role=${role}&username=${username}`
        )
          .then((response) => response.json())
          .then((results) => {
            console.log('getClubSuggestionsStaff', results)
            dispatch({
              type: GET_C_SUG_SUCCESS,
              payload: results,
            });
          })
          .catch((error) => {
            dispatch({
              type: GET_C_SUG_ERROR,
              payload: error,
            });
          });
      });

export const getClubSuggested = (id: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_CLUB_SUG });
    Backend(
      MicroServices.Club,
      `/clubs/${id}`)
      .then((response) => response.json())
      .then((results) => {
        resolve(results);
        // dispatch({
        //   type: GET_CLUB_SUG_SUCCESS,
        //   payload: results,
        // })
      })
      .catch((error) => {
        dispatch({
          type: GET_CLUB_SUG_ERROR,
          payload: error,
        });
      })
  });

export const getUserClubSuggestion =
  (clubIds: string[], is_advisor: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise(() => {
        dispatch({ type: START_GET_U_SUG });
        let query = "?"
        for (let club_id of clubIds) {
          query += `clubs[]=${club_id}&`
        }
        Backend(
          MicroServices.Connections,
          `/users-by-club${query}is_advisor=${is_advisor}`)
          .then((response) => response.json())
          .then((results) => {
            dispatch({
              type: GET_U_SUG_SUCCESS,
              payload: results,
            });
          })
          .catch((error) => {
            dispatch({
              type: GET_U_SUG_ERROR,
              payload: error,
            });
          });
      });

export const getUserOrgSuggestion =
  (orgId: string, is_advisor: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise(() => {
        dispatch({ type: START_GET_U_SUG });
        Backend(
          MicroServices.Connections,
          `/users?org_id=${orgId}&is_advisor=${is_advisor}`)
          .then((response) => response.json())
          .then((results) => {
            dispatch({
              type: GET_U_SUG_SUCCESS,
              payload: results,
            });
            console.log(results)
          })
          .catch((error) => {
            dispatch({
              type: GET_U_SUG_ERROR,
              payload: error,
            });
          });
      });

export const getUserFriends =
  (username: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_GET_USER_FRIENDS });
        Backend(
          MicroServices.Connections,
          `/friends?username=${username}`)
          .then((response) => response.json())
          .then((results) => {
            dispatch({
              type: GET_USER_FRIENDS_SUCCESS,
              payload: results,
            });
            console.log('user Friends', results);
            resolve(results);
          })
          .catch((error) => {
            dispatch({
              type: GET_USER_FRIENDS_ERROR,
              payload: error,
            });
          });
      });

export const getUserPossibleConnections =
  (username: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_GET_USER_POSSIBLE_CONNECTIONS });
        Backend(
          MicroServices.Connections,
          `/get-user-connections?username=${username}`)
          .then((response) => response.json())
          .then((results) => {
            dispatch({
              type: GET_USER_POSSIBLE_CONNECTIONS_SUCCESS,
              payload: results,
            });
            console.log('user Possible Connections', results);
            resolve(results);
          })
          .catch((error) => {
            dispatch({
              type: GET_USER_POSSIBLE_CONNECTIONS_ERROR,
              payload: error,
            });
          });
      });

export const getUserBirthdayConnections =
  (username: string, date?: { month: number, day: number }): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_GET_USER_BIRTHDAY_CONNECTIONS });

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const timezoneParam = timezone ? `&timezone=${timezone}` : '';

        const dateParam = date ? `&month=${date.month}&day=${date.day}` : ''

        Backend(
          MicroServices.Connections,
          `/get-user-connections-bd?username=${username}${dateParam}${timezoneParam}`)
          .then((response) => response.json())
          .then((resultsRaw) => {
            const results = resultsRaw.filter((r: any) => r.usernameConnection !== username);
            dispatch({
              type: GET_USER_BIRTHDAY_CONNECTIONS_SUCCESS,
              payload: results,
            });
            resolve(results);
          })
          .catch((error) => {
            dispatch({
              type: GET_USER_BIRTHDAY_CONNECTIONS_ERROR,
              payload: error,
            });
          });
      });

export const getUserPendingRequests =
  (username: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_GET_USER_PENDING_REQUESTS });
        Backend(
          MicroServices.Connections,
          `/requests?username=${username}`)
          .then((response) => response.json())
          .then((results) => {
            dispatch({
              type: GET_USER_PENDING_REQUESTS_SUCCESS,
              payload: results,
            });
            console.log('user Pending Friend Requests', results);
            resolve(results);
          })
          .catch((error) => {
            dispatch({
              type: GET_USER_PENDING_REQUESTS_ERROR,
              payload: error,
            });
          });
      });

export const getUserSentRequests =
  (username: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {

        let sentRequestsA: any = [];

        dispatch({ type: START_GET_USER_SENT_REQUESTS });
        Backend(
          MicroServices.Connections,
          `/requests/sent?username=${username}`)
          .then((response) => response.json())
          .then((results) => {
            if (Array.isArray(results)) {
              sentRequestsA = results
            }
            return Backend(
              MicroServices.Connections,
              `/requests/email/sent?username=${username}`);
          })
          .then((response) => response.json())
          .then((results) => {
            if (Array.isArray(results)) {
              sentRequestsA = sentRequestsA.concat(results);
            }

            dispatch({
              type: GET_USER_SENT_REQUESTS_SUCCESS,
              payload: sentRequestsA,
            });
            console.log('user Sent Friends Requests', sentRequestsA);
            resolve(sentRequestsA);
          })
          .catch((error) => {
            dispatch({
              type: GET_USER_SENT_REQUESTS_ERROR,
              payload: error,
            });
          });
      });

export const saveNewFriend =
  (username: string, newFriendUsername: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_SAVE_NEW_FRIEND });
        const { t } = i18n;
        Backend(
          MicroServices.Connections,
          `/friends?username=${username}&friendUsername=${newFriendUsername}`,
          {
            method: 'PUT',
          }
        )
          .then((response) => {
            if (response.status === 201) {
              toast.success(t('toast_newFriendAdded'));
            }
            return response.json()
          })
          .then((results) => {
            dispatch({
              type: SAVE_NEW_FRIEND_SUCCESS,
              payload: results,
            });
            resolve;
          })
          .catch((error) => {
            dispatch({
              type: SAVE_NEW_FRIEND_ERROR,
              payload: error,
            });
          });
      });

export const cancelFriendRequest =
  (usernameFrom: string, usernameTo: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_CANCEL_FRIEND_REQUEST });
        const { t } = i18n;
        Backend(
          MicroServices.Connections,
          `/requests/delete?user1=${usernameFrom}&user2=${usernameTo}`,
          {
            method: 'DELETE',
          }
        )
          .then((response) => {
            if (response.status === 200) {
              toast.success(t('toast_friendRequestCanceled'));
            }
            return response.json()
          })
          .then((results) => {
            dispatch({
              type: CANCEL_FRIEND_REQUEST_SUCCESS,
              payload: results,
            });
            resolve;
          })
          .catch((error) => {
            dispatch({
              type: CANCEL_FRIEND_REQUEST_ERROR,
              payload: error,
            });
          });
      });

export const cancelFriendRequestEmail =
  (usernameFrom: string, emailTo: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_CANCEL_FRIEND_REQUEST });
        const { t } = i18n;
        Backend(
          MicroServices.Connections,
          `/requests/email/delete`,
          {
            method: 'DELETE',
            body: JSON.stringify({
              usernameFrom: usernameFrom,
              emailTo: emailTo
            })
          }
        )
          .then((response) => {
            if (response.status === 200) {
              toast.success(t('toast_friendRequestCanceled'));
            }
            return response.json()
          })
          .then((results) => {
            dispatch({
              type: CANCEL_FRIEND_REQUEST_SUCCESS,
              payload: results,
            });
            resolve;
          })
          .catch((error) => {
            dispatch({
              type: CANCEL_FRIEND_REQUEST_ERROR,
              payload: error,
            });
          });
      });

export const rejectFriendRequest =
  (usernameFrom: string, usernameTo: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_REJECT_FRIEND_REQUEST });
        const { t } = i18n;
        Backend(
          MicroServices.Connections,
          `/requests/reject?user1=${usernameFrom}&user2=${usernameTo}`,
          {
            method: 'DELETE',
          }
        )
          .then((response) => {
            if (response.status === 200) {
              toast.success(t('toast_friendRequestRejected'));
            }
            return response.json()
          })
          .then((results) => {
            dispatch({
              type: REJECT_FRIEND_REQUEST_SUCCESS,
              payload: results,
            });
            resolve(results);
          })
          .catch((error) => {
            dispatch({
              type: REJECT_FRIEND_REQUEST_ERROR,
              payload: error,
            });
          });
      });

export const createFriendRequest =
  (usernameFrom: string, usernameTo: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_REJECT_FRIEND_REQUEST });
        const { t } = i18n;
        Backend(
          MicroServices.Connections,
          `/requests?usernameFrom=${usernameFrom}&usernameTo=${usernameTo}`,
          {
            method: 'POST',
          }
        )
          .then((response) => {
            if (response.status === 201) {
              toast.success(t('toast_newFriendRequest'));
            }
            return response.json()
          })
          .then((results) => {
            dispatch({
              type: REJECT_FRIEND_REQUEST_SUCCESS,
              payload: results,
            });
            resolve;
          })
          .catch((error) => {
            dispatch({
              type: REJECT_FRIEND_REQUEST_ERROR,
              payload: error,
            });
          });
      });

export const createEmailRequest =
  (usernameFrom: string, emailTo: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_CREATE_EMAIL_REQ });
        Backend(
          MicroServices.Connections,
          `/requests/email`,
          {
            method: 'POST',
            body: JSON.stringify({ usernameFrom, emailTo })
          }
        )
          .then((response) => {
            // if(response.status === 201){
            //   toast.success('New Friend Request Sent!');
            // }
            return response.json()
          })
          .then((results) => {
            dispatch({
              type: CREATE_EMAIL_REQ_SUCCESS,
              payload: results,
            });
            resolve;
          })
          .catch((error) => {
            dispatch({
              type: CREATE_EMAIL_REQ_ERROR,
              payload: error,
            });
          });
      });

export const deleteFriend =
  (username: string, friendUsername: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_DELETE_FRIEND });
        const { t } = i18n;
        Backend(
          MicroServices.Connections,
          `/friends/delete?user1=${username}&user2=${friendUsername}`,
          {
            method: 'DELETE',
          }
        )
          .then((response) => {
            if (response.status === 200) {
              toast.success(t('toast_connectionDeleted'));
            }
            return response.json()
          })
          .then((results) => {
            dispatch({
              type: DELETE_FRIEND_SUCCESS,
              payload: results,
            });
            resolve;
          })
          .catch((error) => {
            dispatch({
              type: DELETE_FRIEND_ERROR,
              payload: error,
            });
          });
      });

export const getUnregSug = (orgId: string, role: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_UNREG_SUG });
    Backend(
      MicroServices.Connections,
      `/get-unregistered-connections?orgId=${orgId}&role=${role.replace('#', '_')}`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_UNREG_SUG_SUCCESS,
          payload: results,
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_UNREG_SUG_ERROR,
          payload: error,
        });
      })
  });
