import "../../containers/SetupSchool/styles.scoped.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { SingleValue } from 'react-select';
import OrganizationComplete from "../../interfaces/organization/OrganizationComplete";
import {UserResponse} from "../../interfaces/user/UserResponse";
import { updateOrg, updateLogoOrg, uploadImage } from "../../store/actions/orgsActions";
import './MySchool/styles.css';
import ModalImageEditor from '../ModalImageEditor';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
import useGrades from "../../hooks/useGrades";
import SelectItem from "../../interfaces/SelectItem";
import { customStylesReactSelect, DropdownIndicator } from "../../helpers/selectHelper";
import { useTranslation } from "react-i18next";

function Index(props: {
    organization?: OrganizationComplete,
    user?: UserResponse,
    handleOnClick: (e: any, step: number) => void,
    reloadOrgInformation: () => void,
    userIsPrincipal: boolean,
    setUserIsPrincipal: (value: boolean) => void,
}) {
    const {organization, user, reloadOrgInformation, userIsPrincipal, setUserIsPrincipal} = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [namePrincipal, setNamePrincipal] = useState('');
    const [emailPrincipal, setEmailPrincipal] = useState('');
    const [yearbook, setYearbook] = useState('');
    const [newspaper, setNewspaper] = useState('');
    const [website, setWebsite] = useState('');
    const [schoolLogo, setSchoolLogo] = useState('');
    const [skip, setSkip] = useState(true);
    const [lowGrade, setLowGrade] = useState<string>();
    const [highGrade, setHighGrade] = useState<string>();
    const [saveOrgChanges, setSaveOrgChanges] = useState<boolean>(false);
    const [waitingForOrg, setWaitingForOrg] = useState<boolean>(false);
    const [logoOrg, setLogoOrg] = useState('');
    const [loadingPhoto, setLoadingPhoto] = useState<boolean>(false);

    const { gradesSelect, setCountry } = useGrades();
    const [lowGradeSelect, setLowGradeSelect] = useState<SelectItem>();
    const [highGradeSelect, setHighGradeSelect] = useState<SelectItem>();
  
    useEffect(() => {
        if (organization) {
            setLowGradeSelect(gradesSelect.find((grade) => grade.value == organization.lowGrade.toString()));
            setHighGradeSelect(gradesSelect.find((grade) => grade.value == organization.highGrade.toString()));
        }
    }, [gradesSelect])
    
    useEffect(() => {
        if (organization && organization?.countryCode) {
            setCountry(organization?.countryCode);
        }
    }, [])

    useEffect(() => {
        if (organization && organization?.countryCode) {
            setCountry(organization?.countryCode);
        }
    }, [organization])

    const loadingUploadLogoOrg = useSelector((state: any) => state.getOrgs.loadingUploadLogoOrg || false);

    useEffect(() => {
        if (organization && organization?.countryCode) {
            setCountry(organization?.countryCode);
        }
        if (organization && user) {
            setWebsite(organization.website);
            setLowGrade(String(organization.lowGrade));
            setHighGrade(String(organization.highGrade));
            setYearbook(organization.yearbook || '');
            setNewspaper(organization.newspaper || '');
            setLogoOrg(organization.logo || '');
            if(user && organization.principal?.email === user?.email) setUserIsPrincipal(true)
            setNamePrincipal(organization.principal?.name || '')
            setEmailPrincipal(organization.principal?.email || '')
        }
    }, [organization, user]);

    const disableSkip = () => {
        if (skip) {
            setSkip(false);
        }
    }

    const handleChangeRadio = (event: any) => {
        if (user && !userIsPrincipal) {
            disableSkip();
            setUserIsPrincipal(true);
            setNamePrincipal(`${user.firstname} ${user.lastname}`);
            setEmailPrincipal(user.email || '');
        } else if (userIsPrincipal) {
            disableSkip();
            setUserIsPrincipal(false);
            setNamePrincipal('');
            setEmailPrincipal('');
        }
    }

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        disableSkip();
        setUserIsPrincipal(false)
        setNamePrincipal(event.target.value);
    }
    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        disableSkip();
        setUserIsPrincipal(false)
        setEmailPrincipal(event.target.value);
    }
    const handleChangeYearbook = (event: React.ChangeEvent<HTMLInputElement>) => {
        disableSkip();
        setYearbook(event.target.value);
    }
    const handleChangeNewspaper = (event: React.ChangeEvent<HTMLInputElement>) => {
        disableSkip();
        setNewspaper(event.target.value);
    }
    const handleChangeWebsite = (event: React.ChangeEvent<HTMLInputElement>) => {
        disableSkip();
        setWebsite(event.target.value);
    }
    const handleChangeLowGrade = (e: any)=> {
        disableSkip();
        setLowGrade(e.value);
        setLowGradeSelect(e);
    }
    const handleChangeHighGrade = (e: any)=> {
        disableSkip();
        setHighGrade(e.value);
        setHighGradeSelect(e);
    }

    useEffect(() => {
        if (waitingForOrg) {
            setSaveOrgChanges(false);
            setWaitingForOrg(false);
            props.handleOnClick(undefined, 2);
        }
    }, [organization]);

    const handleSave = (e: any) => {

        if (organization) {
            setSaveOrgChanges(true);
            let orgUpdate:any = {
                website,
                lowGrade: Number(lowGrade),
                highGrade: Number(highGrade),
                yearbook,
                newspaper,
                logo: logoOrg,
            }
            if(namePrincipal !== '' && emailPrincipal !== ''){
                orgUpdate = {
                    ...orgUpdate,
                    principal: {
                        name:namePrincipal,
                        email:emailPrincipal,
                        phoneNumber:3000000000
                    },
                    primaryContact:{
                        name:namePrincipal,
                        email:emailPrincipal,
                        phoneNumber:3000000000
                    }
                }
            }else{
                orgUpdate = {
                    ...orgUpdate,
                    principal: {
                        name:'',
                        email:organization.primaryContact.email,
                        phoneNumber:3000000000
                    },
                    primaryContact:organization.primaryContact
                }
            }
            dispatch<any>(updateOrg(
                orgUpdate,
                organization.id,
                organization.status.startsWith('PEND'),
            ))
                .then((results: any) => {
                    setWaitingForOrg(true);
                    reloadOrgInformation();
                })
                .catch((error: any) => {
                    console.log(error)
                })
        }
    }

    const handleDiscard = (e: any, step: number) => {
        userIsPrincipal ? setUserIsPrincipal(false) : setUserIsPrincipal(true);
        setSaveOrgChanges(false);
        props.handleOnClick(e, step);
    }

    const customStyles = () => ({
        container: (styles: any) => ({
            ...styles,
            width: '100%',
            backgroundColor: 'transparent',
            height: 40,
        }),
        indicatorsContainer: (styles: any) => ({
            ...styles,
            display: 'none',
            
        }),
        control: (styles: any) => ({
            backgroundColor: '#28115a',
            border: 0,
            height: 40,
        }),
        valueContainer:  (styles: any) => ({
            height: 40,
        }),
        singleValue:  (styles: any) => ({
            color: 'white',
            padding: 12,
        }),
        option:  (styles: any) => ({
            color: 'white',
            padding: 12,
            width: 40,
            height: 40,
            backgroundColor: '#2C2C2C',
            display: 'inline-block',
            margin: 5,
            cursor: 'pointer',
        }),
        menu:  (styles: any) => ({
            width: 220,
            padding: '0 10px',
            borderRadius: 7,
            backgroundColor: '#383838; position:absolute; top: -115px;',
        }),
    });

    const handleMousedown = () => {
        console.log('Mouse button is downed!');
    };

    const [showModal, setShowModal] = useState(false);

    function handleCloseModal(e: any, type: any) {
        setShowModal(false);
    };

    function handleSaveImage(e: any) {
        setSchoolLogo(e);
        setLoadingPhoto(true);
        fetch(e)
            .then(res => res.blob())
            .then(blob => {
                const contentType = e.split(';')[0].split(':')[1];
                const extension = contentType.split('/')[1]
                const file = new File([blob], 'Logo'+organization?.id,{ type: contentType });  
 
                if(file){
                    dispatch<any>(updateLogoOrg(
                        file.type,
                        file.name+'.'+extension,
                        "school_logo"
                    ))
                        .then((results: any) => {
                            if(results.body){

                                console.log(file)

                                dispatch<any>(uploadImage(
                                    results.body,
                                    contentType, 
                                    file,
                                ))
                                    .then((result: any) => {
                                        if (result.status === 200) {
                                            const urlImage = results.body.split('?')[0];
                                            setLogoOrg(urlImage) 
                                            setSkip(false);
                                        }
                                        setLoadingPhoto(false);
                                    })
                                    .catch((error: any) => {
                                        console.log(error)
                                        setLoadingPhoto(false);
                                    })  

                            }
                        })
                        .catch((error: any) => {
                            console.log(error)
                            setLoadingPhoto(false);
                        })
                }
                
            })
    };

  return (
    <>
        <div className="container-myschool">

            <h1>Congratulations!</h1>

            <span>{organization?.name || 'Your organization'} is on Zaahah! Please add your school communities and all extracurricular clubs and teams.</span>
            
            <div className="btnWrapper">
                {skip || loadingUploadLogoOrg ? (
                    <button
                        className="button-skip"
                        onClick={(e) => props.handleOnClick(e, 2)}>
                        Skip
                    </button>
                ) : (
                    <>
                        <button
                            className="button-skip"
                            onClick={(e) => handleDiscard(e, 2)}
                            disabled={saveOrgChanges}
                        >
                            Discard changes
                        </button>
                        <button
                            className="button-save"
                            name="btn-Step1-Save"
                            onClick={handleSave}
                            disabled={saveOrgChanges}
                        >
                            {saveOrgChanges ? (
                                <div className="btn-center">
                                    <div className="icon-spinner-third buttonSendSignUp lilSendSignUp"></div>
                                </div>
                            ) : 'Save and continue'}
                        </button>
                    </>
                )}
            </div>
        </div>
        <div className="myschool-inputs">
            <ModalImageEditor handleSaveImage={handleSaveImage} show={showModal} onHide={handleCloseModal}/>
            
            <label onClick={() => setShowModal(true)} className="photo" htmlFor="filePicker">
                <div>
                    <div className="logo-photo">
                        {schoolLogo || logoOrg ?
                            <img src={schoolLogo || logoOrg} className="imageSchool" />
                        :
                        (loadingPhoto) ? (
                            <div className="icon-spinner-third buttonSendSignUp" />
                          ) :
                          (<FontAwesomeIcon
                            icon="camera"
                            size="xl"
                            color={WHITE}
                            className="icon-camera  "
                          />)
                        }
                        
                    </div>      
                    <p>Select School Logo</p>
                    <a href="#">Upload file</a>
                </div>
            </label>

            {/* <input id="filePicker" style={{display:"none"}} type={"file"}></input> */}

            <div className="inputs">
                <div className="row">
                    <label className="title-input" htmlFor="i-principal">
                        Administrator
                    </label>
                    <div className="containers-input">
                        <input
                            type="text"
                            value={namePrincipal}
                            onChange={handleChangeName}
                            disabled={saveOrgChanges}
                        />
                        <div className="option">
                            <label className="content-input">
                                <input
                                    type="checkbox"
                                    name="principal-button"
                                    value=""
                                    checked={userIsPrincipal}
                                    onChange={handleChangeRadio}
                                    onClick={handleChangeRadio}
                                    disabled={saveOrgChanges}
                                />
                                <i></i>
                            </label>
                            <label htmlFor="students">I am the principal</label>
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <label className="title-input" htmlFor="i-principal">
                        eMail
                    </label>
                    <div className="containers-input">
                        <input
                            type="text"
                            value={emailPrincipal}
                            disabled={saveOrgChanges}
                            onChange={handleChangeEmail}
                        />
                    </div>
                </div>
                <div className="row">
                    <label className="title-input" htmlFor="i-principal">
                        Yearbook Title
                    </label>
                    <div className="containers-input">
                        <input
                            name="yearbookTitle"
                            type="text"
                            value={yearbook}
                            disabled={saveOrgChanges}
                            onChange={handleChangeYearbook}
                        />
                    </div>
                </div>
                <div className="row">
                    <label className="title-input" htmlFor="i-principal">
                        School Website
                    </label>
                    <div className="containers-input">
                        <input
                            type="text"
                            value={website}
                            disabled={saveOrgChanges}
                            onChange={handleChangeWebsite}
                        />
                    </div>
                </div>
                <div className="row">
                    <label className="title-input" htmlFor="i-principal">
                        Grade Level
                    </label>
                    <div className="grades">
                        <div>
                            <Select
                              options={gradesSelect}
                              styles={customStylesReactSelect()}
                              components={{
                                DropdownIndicator,
                              }}
                              value={lowGradeSelect}
                              onChange={handleChangeLowGrade}
                              placeholder="Low Grade"
                            />
                            {/* <select name="lowGrade" className="select-the-information-1 roboto-normal-white-16px" onChange={handleChangeLowGrade} value={lowGrade}>
                                <option disabled value="0">Select Grade</option>
                                {getOptions()}
                            </select> */}
                            {/* <Select
                                className="selectGrade"
                                menuPlacement="top"
                                options={grades} 
                                value={lowGrade}
                                styles={customStyles()}
                                onChange={handleChangeLowGrade}
                            /> */}
                            {/* <FontAwesomeIcon
                                icon="chevron-up"
                                size='lg'
                                color={LAVENDER}
                                className="chevron-up  "
                            /> */}
                        </div>
                        
                        
                        <label className="label-to" htmlFor="i-principal">
                            To
                        </label>

                        <div>
                            <Select
                              options={gradesSelect}
                              styles={customStylesReactSelect()}
                              components={{
                                DropdownIndicator,
                              }}
                              value={highGradeSelect}
                              onChange={handleChangeHighGrade}
                              placeholder="High Grade"
                            />
                            {/* <select name="highGrade" className="select-the-information-1 roboto-normal-white-16px" onChange={handleChangeHighGrade} value={highGrade}>
                                <option disabled value="0">Select Grade</option>
                                {getOptions()}
                            </select>
                            <FontAwesomeIcon
                                icon="chevron-up"
                                size='lg'
                                color={LAVENDER}
                                className="chevron-up  "
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    
  );
}

export default Index;
