import React from 'react'
import ImageProfile from './ImageProfile'
import './styles.scoped.css'

const ChangePasword = () => {
  return (
    <div className='containerMyAccount'>
      <ImageProfile />
      <div className='row row2'>
        <div className='col'>
          <label className='titleInput' htmlFor="">Current Password</label>
          <input type="password" />
        </div>
        <div className='col'>
          <label className='titleInput' htmlFor="">New Password</label>
          <input type="password" />
        </div>
      </div>
      <div className='row row1'>
        <label className='titleInput' htmlFor="">Repeat the New Password</label>
        <input type="password" />
      </div>
    </div>
  )
}

export default ChangePasword