import "./styles.css";
// import "./styles2.css";
import OrganizationsInfoBar from "../OrganizationsInfoBar";
import User from "../User";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import OutsideClickHandler from "react-outside-click-handler";
import { getUsersWithLimit } from "../../store/actions/userActions";
import { capitalize } from "../../helpers/letterHelper";
import { onlyZAAroles } from "../../helpers/roleHelper";
import ModalDelete from './ModalDelete';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';

const Index = ({ roleSelected, setRoleSelected, handleChangeRadioConnection }: { roleSelected: string, setRoleSelected: any, handleChangeRadioConnection: any }) => {
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.getUser.token);

  const users = useSelector((state: any) => state.getUser.usersLimit || []);
  const usersStatus = useSelector((state: any) => state.getUser.usersStatus);
  const organizationsAll = useSelector(
    (state: any) => state.getOrgs.organizationNames
  );
  const userRoles = useSelector((state: any) => state.getUser.usersRoles);
  const activeCountries = useSelector(
    (state: any) => state.getLocation.activeCountries || []
  );
  const activeCities = useSelector(
    (state: any) => state.getLocation.activeCities || []
  );

  const selectRoles = useRef<any>(null);
  const selectOrg = useRef<any>(null);
  const selectCity = useRef<any>(null);
  const selectCountry = useRef<any>(null);
  const selectStatus = useRef<any>(null);

  const [rolesHeader, setRolesHeader] = useState("");
  const [cityHeader, setCityHeader] = useState("");
  const [countryHeader, setCountryHeader] = useState("");
  const [statusHeader, setStatusHeader] = useState("");

  const [defaultValueRole, setDefaultValueRole] = useState<{ label: string, value: string | number } | null>(null)
  const [defaultValueOrg, setDefaultValueOrg] = useState<{ label: string, value: string | number } | null>(null)
  const [defaultValueCity, setDefaultValueCity] = useState<{ label: string, value: string | number } | null>(null)
  const [defaultValueCountry, setDefaultValueCountry] = useState<{ label: string, value: string | number } | null>(null)
  const [defaultValueStatus, setDefaultValueStatus] = useState<{ label: string, value: string | number } | null>(null)

  const roles = onlyZAAroles(userRoles);
  const rolesOptions = roles.map((e: any) => ({
    label: e[1],
    value: encodeURIComponent(e[0]),
  }));

  rolesOptions.unshift({ label: "All", value: "" });

  const nameOptions = organizationsAll.map((e: any) => ({
    label: e.name,
    value: e.name,
    value1: e.id,
  }));

  nameOptions.unshift({ label: "All", value: "" });

  const statusOptions = usersStatus.map((e: any) => ({
    label: capitalize(e),
    value: e,
  }));

  statusOptions.unshift({ label: "All", value: "" });

  const countryOptions = activeCountries.map((e: any) => {
    return {
      label: e.name,
      value: e.name,
      value1: e.location
    };
  });

  countryOptions.unshift({ label: "All", value: "" });

  const cityOptions = activeCities.map((e: any) => {
    return {
      label: e.name,
      value: e.name,
    };
  });

  cityOptions.unshift({ label: "All", value: "" });

  const [selected, setSelected] = useState("");
  const [input, setInput] = useState<string>('');
  const [inputfocus, setInputFocus] = useState(false);
  const [outside, setOutside] = useState(false);
  const [organizationValue, setOrganizationValue] = useState("");

  useEffect(() => {
    // dispatch(
    //   getUsersWithLimit({
    //     token: token,
    //     limit: 12,
    //     page: 0,
    //     lastEvaluatedKey: "",
    //   })
    // );
  }, []);

  const handleChange = (e: any) => {
    setSelected(e.label);
    //console.log(e.label);
  };

  const onInput = (e: any) => {
    setInput(e.target.className.split('__input')[0]);
    setOutside(false);
    setInputFocus(true);
    //setOrganizationValue("organizationName");
  };

  const onInputBlur = (e: any) => {
    setOutside(true);
    setInputFocus(false);
  };

  const onKeyDown = (e: any, typeInput: any) => {
    if (e.keyCode === 13) {
      e.preventDefault();

      dispatch(
        getUsersWithLimit({
          token: token,
          limit: 100000,
          page: 0,
          lastEvaluatedKey: "",
          id: id,
          role: role,
          org: org,
          country: country,
          city: city,
          status: status,
          date: date,
        })
      );

      if (typeInput === 'selectRole') {
        setDefaultValueRole({ 'label': role, 'value': 0 })
        selectRoles.current.blur();
      } else if (typeInput === 'selectOrg') {
        setDefaultValueOrg({ 'label': org, 'value': 0 })
        selectOrg.current.blur();
      } else if (typeInput === 'selectCity') {
        setDefaultValueCity({ 'label': city, 'value': 0 })
        selectCity.current.blur();
      } else if (typeInput === 'selectCountry') {
        setDefaultValueCountry({ 'label': country, 'value': 0 })
        selectCountry.current.blur();
      } else if (typeInput === 'selectStatus') {
        setDefaultValueStatus({ 'label': status, 'value': 0 })
        selectStatus.current.blur();
      }
    }
  };

  const handleInputChange = (characterEntered: any, typeInput: any) => {
    if (typeInput === 'selectRole') {
      setRole(characterEntered);
    } else if (typeInput === 'selectOrg') {
      setOrg(characterEntered);
    } else if (typeInput === 'selectCity') {
      setCity(characterEntered)
    } else if (typeInput === 'selectCountry') {
      setCountry(characterEntered)
    } else if (typeInput === 'selectStatus') {
      setStatus(characterEntered);
    }
  };

  const outsideClick = (e: any) => {
    // outside ? e.label = "Search by Name" : <></>
  };

  const customStyles = (value: any, input: any, outside: any, inputfocus: any) => ({
    option: (styles: any) => ({
      ...styles,
      color: "white",
      backgroundColor: "#383838",
    }),
    input: (styles: any, state: any) => ({
      ...styles,
      border: "none",
      color: "white",
      fontWeight: "normal",
      paddingLeft: "5px",
      "&:focus": { backgroundColor: "red" },
    }),
    container: (styles: any) => ({
      ...styles,
      padding: 0,
      width: "calc(100% - 30px)",
    }),
    valueContainer: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: value && value != "All" ? "#383838" : "transparent",
      // display: value && !outside ? 'flex' : 'none',
      padding: 0,
    }),
    menu: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      backgroundColor: "#383838",
      width: "fit-content",
      minWidth: "150px",
      textAlign: "left",
      zIndex: "1",
      display: inputfocus ? 'block' : 'none',
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      textAlign: "left",
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      //minWidth: "50px",
      borderRadius: "6px",
      boxShadow: "none",
      border: 0,
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "bold",
      maxWidth: '100%',
      marginBottom: "5px",
      backgroundColor: outside ? 'transparent' : input ? '#383838' : 'transparent',
      minWidth: inputfocus && input ? '100px' : '50px'
      // textDecoration: outside ? 'underline' : 'none',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: "white",
      textDecoration: "underline",
      display: outside ? 'block' : input ? 'none' : 'block',
      // display: input ? 'none' : 'block',
      // backgroundColor: outside ? 'red' : 'blue'
    }),
    indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
    dropdownIndicator: (styles: any) => ({ ...styles, display: "none" }),
    noOptionsMessage: (styles: any) => ({
      ...styles,
      color: "white",
      backgroundColor: "#383838",
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "normal",
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: "white",
      backgroundColor: "#383838",
      border: "none",
      paddingLeft: "7px",
      paddingRight: "7px",
      paddingBottom: "7px",
      paddingTop: "7px",
      borderRadius: "4px",
      fontSize: "13px",
      width: "100%",
      maxWidth: '100%',
      // display: input ? 'none' : 'block',
    }),
  });

  const [id, setId] = useState("");
  const [role, setRole] = useState("");
  const [org, setOrg] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [status, setStatus] = useState("");
  const [date, setDate] = useState("ASC");
  const handleChangeId = (e: any) => {
    setId(e.value);
    dispatch(
      getUsersWithLimit({
        token: token,
        limit: 100000,
        page: 0,
        lastEvaluatedKey: "",
        id: e.value,
        role: role,
        org: org,
        country: country,
        city: city,
        status: status,
        date: date,
      })
    );
  };
  const handleChangeRole = (e: any) => {
    // console.log(e)
    setRole(e.value);
    setRoleSelected(e.value)
    handleChangeRadioConnection(e.value)
    setRolesHeader(e.value ? `Roles ` : "");
    setDefaultValueRole({ 'label': e.value ? e.label : 'All', 'value': 0 })
    // console.log({
    //   token: token,
    //   limit: 100000,
    //   page: 0,
    //   lastEvaluatedKey: "",
    //   id: id,
    //   role: e.value,
    //   org: org,
    //   country: country,
    //   city: city,
    //   status: status,
    //   date: date,
    // })
    dispatch(
      getUsersWithLimit({
        token: token,
        limit: 100000,
        page: 0,
        lastEvaluatedKey: "",
        id: id,
        role: e.value,
        org: org,
        country: country,
        city: city,
        status: status,
        date: date,
      })
    );
  };
  const handleChangeOrg = (e: any) => {
    setOrg(e.value);
    setDefaultValueOrg({ 'label': e.value ? e.value : 'All', 'value': 0 })
    dispatch(
      getUsersWithLimit({
        token: token,
        limit: 100000,
        page: 0,
        lastEvaluatedKey: "",
        id: id,
        role: role,
        org: e.value === "" ? '' : e.value1,
        country: country,
        city: city,
        status: status,
        date: date,
      })
    );
  };
  const handleChangeCountry = (e: any) => {
    setCountry(e.value);
    setCountryHeader(`Country `);
    setDefaultValueCountry({ 'label': e.value ? e.value : 'All', 'value': 0 })
    dispatch(
      getUsersWithLimit({
        token: token,
        limit: 100000,
        page: 0,
        lastEvaluatedKey: "",
        id: id,
        role: role,
        org: org,
        country: e.value === "" ? '' : encodeURIComponent(e.value1),
        city: city,
        status: status,
        date: date,
      })
    );
  };
  const handleChangeCity = (e: any) => {
    setCity(e.value);
    setCityHeader(`City `);
    setDefaultValueCity({ 'label': e.value ? e.value : 'All', 'value': 0 })
    dispatch(
      getUsersWithLimit({
        token: token,
        limit: 100000,
        page: 0,
        lastEvaluatedKey: "",
        id: id,
        role: role,
        org: org,
        country: country,
        city: e.value,
        status: status,
        date: date,
      })
    );
  };
  const handleChangeStatus = (e: any) => {
    setStatus(e.value);
    setStatusHeader(`Status `);
    setDefaultValueStatus({ 'label': e.value ? e.value : 'All', 'value': 0 })
    dispatch(
      getUsersWithLimit({
        token: token,
        limit: 100000,
        page: 0,
        lastEvaluatedKey: "",
        id: id,
        role: role,
        org: org,
        country: country,
        city: city,
        status: e.value,
        date: date,
      })
    );
  };
  const handleChangeDate = (e: any) => {
    setDate(e.value);
    dispatch(
      getUsersWithLimit({
        token: token,
        limit: 100000,
        page: 0,
        lastEvaluatedKey: "",
        id: id,
        role: role,
        org: org,
        country: country,
        city: city,
        status: status,
        date: e.value,
      })
    );
  };

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [userId, setUserId] = useState('');
  function handleCloseModalDelete(e: any) {
    setShowModalDelete(false)
  };

  const handleClickid = (e: any) => {
    //console.log(e)
    setUserId(e)
    setShowModalDelete(true)
  }

  useEffect(() => {
    const label_selected = roleSelected.split('%23')[1]
    if (label_selected) handleChangeRole({ value: roleSelected, label: `${label_selected.slice(0, 1)}${label_selected.slice(1).toLowerCase()}` });
  }, [roleSelected]);

  return (
    <>
      <table className="listData robotocondensed-bold-white-16px">
        <tr>
          <th>
            <div className="id">
              <div className="id-1 robotocondensed-bold-white-16px">
                Search ID
              </div>
              {/*<FontAwesomeIcon
  icon="sort-amount-down"
  size='lg'
  color={LAVENDER}
  className="sort-amount-down  "
/>*/}
            </div>
          </th>
          <th>
            <div className={`roles ${rolesHeader ? "active" : ""}`}>
              {/* <label className="labelType">{rolesHeader}</label> */}
              <Select className={`selectRole`} classNamePrefix="selectRole"
                ref={selectRoles}
                isMulti={false}
                options={rolesOptions}
                placeholder={"Roles"}
                styles={customStyles(selected, input === 'selectRole', outside, inputfocus)}
                onChange={handleChangeRole}
                onFocus={onInput}
                onBlur={onInputBlur}
                onKeyDown={(e) => onKeyDown(e, 'selectRole')}
                onInputChange={(e) => handleInputChange(e, 'selectRole')}
                value={defaultValueRole}
              />
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "
              />
            </div>
          </th>
          <th>
            <div className="organizationName">
              <Select className={`selectOrg`} classNamePrefix="selectOrg"
                ref={selectOrg}
                isMulti={false}
                options={nameOptions}
                placeholder={"Organizations"}
                styles={customStyles(selected, input === 'selectOrg', outside, inputfocus)}
                onChange={handleChangeOrg}
                onFocus={onInput}
                onBlur={onInputBlur}
                onKeyDown={(e) => onKeyDown(e, 'selectOrg')}
                onInputChange={(e) => handleInputChange(e, 'selectOrg')}
                value={defaultValueOrg}
              />
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "
              />
            </div>
          </th>
          <th>
            <div className={`city ${cityHeader ? "active" : ""}`}>
              {/* <label className="labelType">{cityHeader}</label> */}
              <Select className={`selectCity`} classNamePrefix="selectCity"
                ref={selectCity}
                isMulti={false}
                options={cityOptions}
                placeholder={"City"}
                styles={customStyles(selected, input === 'selectCity', outside, inputfocus)}
                onChange={handleChangeCity}
                onFocus={onInput}
                onBlur={onInputBlur}
                onKeyDown={(e) => onKeyDown(e, 'selectCity')}
                onInputChange={(e) => handleInputChange(e, 'selectCity')}
                value={defaultValueCity}
              />
              <FontAwesomeIcon
                icon="sort-amount-down"
                size='lg'
                color={LAVENDER}
                className="sort-amount-down  "
              />
            </div>
          </th>
          <th>
            <div
              className={`country roboto-bold-white-16px ${countryHeader ? "active" : ""
                }`}
            >
              {/* <label className="labelType">{countryHeader}</label> */}
              <Select className={`selectCountry`} classNamePrefix="selectCountry"
                ref={selectCountry}
                isMulti={false}
                options={countryOptions}
                placeholder={"Country"}
                styles={customStyles(selected, input === 'selectCountry', outside, inputfocus)}
                onChange={handleChangeCountry}
                onFocus={onInput}
                onBlur={onInputBlur}
                onKeyDown={(e) => onKeyDown(e, 'selectCountry')}
                onInputChange={(e) => handleInputChange(e, 'selectCountry')}
                value={defaultValueCountry}
              />
              <FontAwesomeIcon
                icon="sort-amount-down"
                size='lg'
                color={LAVENDER}
                className="sort-amount-down  "
              />
            </div>
          </th>
          <th>
            <div className="join roboto-bold-white-16px">Joined</div>
          </th>
          <th>
            <div
              className={`status roboto-bold-white-16px ${statusHeader ? "active" : ""
                }`}
            >
              {/* <label className="labelType">{statusHeader}</label> */}
              <Select className={`selectStatus`} classNamePrefix="selectStatus"
                ref={selectStatus}
                isMulti={false}
                options={statusOptions}
                placeholder={"Status"}
                styles={customStyles(selected, input === 'selectStatus', outside, inputfocus)}
                onChange={handleChangeStatus}
                onFocus={onInput}
                onBlur={onInputBlur}
                onKeyDown={(e) => onKeyDown(e, 'selectStatus')}
                onInputChange={(e) => handleInputChange(e, 'selectStatus')}
                value={defaultValueStatus}
              />
              <FontAwesomeIcon
                icon="chevron-down"
                size='lg'
                color={LAVENDER}
                className="chevron-down  "
              />
            </div>
          </th>
          <th className="actionsTable">
            <div className="actions robotocondensed-bold-white-16px">
              Actions
            </div>
          </th>
        </tr>
        {/*console.log(users)*/}
        {users.map((user: any) => (
          <User user={user} handleClickId={handleClickid} />
        ))}
        <ModalDelete show={showModalDelete} onHide={handleCloseModalDelete} userId={userId} />
      </table>
    </>
  );
};

export default Index;
