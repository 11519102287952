import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import { WHITE } from "../../../../components/ClubIconsNavBar";
import ModalBaseProps from "../../../../interfaces/modals/ModalBaseProps";
import { BaseInput, CancelButton, ClickableIcon, Row, SaveButton } from "../../../../components/SuperAdminComponents/basicForSACenter";
import ModalDiscardChanges from '../../../../components/SetupSetup/ModalDiscardChanges';
import { Backend, MicroServices } from "../../../../helpers/backendHelper";
import { toast } from "react-toastify";
import { getFieldState } from "../../../TClubs/utils";

interface CreateModalProps extends ModalBaseProps {
}


const CreateModal = ({
    show,
    onHide,
}: CreateModalProps) => {
    const { t } = useTranslation();

    const [showDiscard, setShowDiscard] = useState(false);
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [intName, setIntName] = useState(getFieldState(''));

    const onCancel = () => setShowDiscard(true);

    const onCreate = () => {
        if (errorsInForm()) {
            return;
        }
        setLoadingCreate(true);

        Backend(
            MicroServices.Connections,
            '/interests',
            {
                method: 'POST',
                body: JSON.stringify({
                    name: intName.value,
                })
            }
        )
            .then((response) => {
                if (response.status === 201) {
                    toast.success(t('toast_interestCreated'));
                    cleanForm();
                    onHide();
                    return;
                }
                toast.error(t('toast_errorAnError'));
            })
            .finally(() => setLoadingCreate(false))
    }

    const onHideDiscard = (type: string) => {
        setShowDiscard(false);
        if (type === 'discard') {
            onHide();
            cleanForm();
        }
    };

    const cleanForm = () => {
        setIntName(getFieldState(''));
    }

    const errorsInForm = () => {
        let errors = false;

        if (!intName.value || intName.value.length <= 0) {
            errors = true;
            setIntName({
                ...intName,
                showError: true,
                error: 'Name is required',
            })
        } else {
            setIntName(getFieldState(intName.value));
        }

        return errors;
    }

    const onChangeIntName = (event: React.FormEvent<HTMLInputElement>) => {
        if (event) {
            setIntName({
                ...intName,
                value: event?.currentTarget.value
            });
        }
    }

    return (
        <>
            <Modal className="modalInviteMemebers" show={show} onHide={onCancel}>
                <Modal.Header>
                    <Modal.Title>
                        Create New Interest
                    </Modal.Title>
                    <ClickableIcon
                        icon={faTimes}
                        size='xl'
                        color={WHITE}
                        className="icon-times"
                        onClick={onCancel}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <BaseInput
                            placeholder="Name"
                            onChange={onChangeIntName}
                            value={intName.value}
                        />
                    </Row>
                </Modal.Body>
                <Modal.Footer className='modalDiscardChanges-footer'>
                    <CancelButton onClick={onCancel}>
                        {t('button__cancel')}
                    </CancelButton>
                    <SaveButton onClick={onCreate}>
                        {loadingCreate
                            ? (
                                <div className="icon-spinner-third buttonSendSignUp"></div>
                            )
                            : t('button__save')
                        }
                    </SaveButton>
                </Modal.Footer>
            </Modal>
            <ModalDiscardChanges
                show={showDiscard}
                onHide={onHideDiscard}
            />
        </>
    );
};

export default CreateModal;