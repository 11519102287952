import "../../styles.scoped.css";
import '../../styles.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WHITE } from '../../../ClubIconsNavBar';
import { useTranslation } from "react-i18next";

function ModalDiscardChanges(props: { show: boolean, onHide: (type: string) => void }) {
    
    const { t, i18n } = useTranslation();

    return (
        <>
            <Modal className="modalDiscardChanges" show={props.show} onHide={() => props.onHide('cancel')}>
                <Modal.Header>
                    <Modal.Title className="modalDiscardChangesTitle">{t("modaldiscardChanges__text")}</Modal.Title>
                    <FontAwesomeIcon
                        icon="times"
                        size='xl'
                        color={WHITE}
                        className="icon-times  "
                        onClick={(e) => props.onHide('cancel')}
                    />
                </Modal.Header>
                <Modal.Footer className='modalDiscardChanges-footer'>
                    <Button className="buttonClose" onClick={(e) => props.onHide('cancel')}>
                        {t("button__cancel")}
                    </Button>
                    <Button className="buttonSend" onClick={(e) => props.onHide('discard')}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalDiscardChanges;