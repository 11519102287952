import React, { useEffect, useState } from "react";
import TemplateTeam from "../../interfaces/template-clubs/templateTeam";
import ModalTeam, { Team } from "./ModalTeam";
import "../../containers/SetupSchool/styles.scoped.css";
import { SelectedTeams } from "../../containers/SetupSchool";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
import ModalKillClub from "./ModalKillClub";
import useGrades from "../../hooks/useGrades";
import { useSelector } from "react-redux";
import useGradesList from "../../hooks/useGradesList";
import { Grade } from "./Tabs/TeamSelect";
import TClub from "../../interfaces/TClubs/TClub/TClub";
import { castTemplateIdToTemplateName } from "./Teams";
import { RootState } from "../../store/store";

interface ListedTeamProps {
  setChange: (e: any) => void,
  tteam: TClub;
  addSelectedTeams: (templateName: string, teams: Team[], type: 'Create' | 'Edit' | 'SaveEdit') => void;
  selectedTeams: SelectedTeams;
  setSelectedTeams: (e: any) => void,
  isSelected: boolean;
  isSaved: boolean;
  dynamoTeams: any[],
  setTeamsEdit: (e: any) => void,
  teamsEdit: { [key: string]: any[] },
  teamsDelete: string[],
  setTeamsDelete: (e: any) => void,
  deleteTemplate: (templateid:string)=>void,
}

const ListedTeam = ({
  setChange,
  tteam,
  addSelectedTeams,
  selectedTeams,
  setSelectedTeams,
  isSelected,
  isSaved,
  dynamoTeams,
  setTeamsEdit,
  teamsEdit,
  teamsDelete,
  setTeamsDelete,
  deleteTemplate,
}: ListedTeamProps) => {
  const { grades: gradesHook, setCountry: setCountryHook } = useGrades();
  const tteams = useSelector((state: RootState) => state.getTClubs.TTeam);

  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  );

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryHook(organization?.countryCode);
    }
  }, [])

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryHook(organization?.countryCode);
    }
  }, [organization])

  const [grades, setGrades] = useState<Grade[]>([]);

  const gradesList = useGradesList();

  useEffect(() => {
    if (grades.filter(grade => grade.active).length === 0) {
      setGrades(gradesList.map((gradeStr) => ({
        value: gradeStr,
        label: gradesHook.find((gradeHook) => gradeHook.id === Number(gradeStr))?.listLabel || gradeStr,
        active: false,
      })));
    }
  }, [gradesHook, gradesList])

  const [showModal, setShowModal] = useState<boolean>(false);

  const hideModal = () => setShowModal(false);
  const displayModal = () => setShowModal(true);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [deleteModalText, setDeleteModalText] = useState<{ message: string, title: string }>({ message: '', title: '' })
  const closeModalDelete = (del: boolean) => {
    setShowDeleteModal(false)
    if (del) {
      //SaveDelete
      deleteTemplate(tteam.id)
    }
  }

  const handleDelete = (e: any, saved: boolean) => {
    e.stopPropagation()
    setChange(true)
    if (saved) {
      setShowDeleteModal(true)
      setDeleteModalText({
        title: `${castTemplateIdToTemplateName(tteam.id,tteams)}`,
        message: `Are you sure you want to delete all teams of ${castTemplateIdToTemplateName(tteam.id,tteams)}?`
      })
    } else {
      const selectedTeamsCopy = { ...selectedTeams }
      delete selectedTeamsCopy[tteam.id]
      setSelectedTeams(selectedTeamsCopy)
    }
  }

  return (
    <>
      <div
        onClick={displayModal}
        className={isSaved ? "savedTemplate" : (isSelected ? "greyTemplate" : "selectTemplate")}
      >
        {castTemplateIdToTemplateName(tteam.id,tteams)}
        {isSaved ? (
          <FontAwesomeIcon
            className='icon-times-2 '
            icon="times"
            size='xl'
            color={WHITE}
            onClick={(e) => handleDelete(e, true)}
          />
        ) : isSelected ? (
          <FontAwesomeIcon
            className='icon-times-2 '
            icon="times"
            size='xl'
            color={WHITE}
            onClick={(e) => handleDelete(e, false)}
          />
        ) : (
          <FontAwesomeIcon
            icon="plus"
            size='xl'
            color={WHITE}
            className="icon-plus  "
          />
        )}
      </div>
      <ModalTeam
        tteamID={tteam.id}
        showModal={showModal}
        hideModal={hideModal}
        addSelectedTeams={addSelectedTeams}
        selectedTeams={selectedTeams}
        setSelectedTeams={setSelectedTeams}
        modalType={isSaved ? 'SaveEdit' : isSelected ? 'Edit' : 'Create'}
        dynamoTeams={dynamoTeams}
        setTeamsEdit={setTeamsEdit}
        teamsEdit={teamsEdit}
        teamsDelete={teamsDelete}
        setTeamsDelete={setTeamsDelete}
        grades={grades}
      />

      <ModalKillClub
        show={showDeleteModal}
        message={deleteModalText.message}
        title={deleteModalText.title}
        onHide={closeModalDelete}
      />
    </>
  );
};

export default ListedTeam;
