import React, { useEffect, useState } from "react"
import { isClubByCode } from "../../guards/clubs/ClubByCode";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { checkString, defaultFieldError, FieldError } from "../../helpers/formHelper";
import ClubByCode from "../../interfaces/club/ClubByCode";
import { toast } from 'react-toastify';
import OrganizationComplete from "../../interfaces/organization/OrganizationComplete";
import Organization from "../../helpers/orgHelper";
import { isValidUsername } from "../../helpers/userHelper";
import { useTranslation } from "react-i18next";

export const useClubCode = (clubCode: string) => {
  const [loadingClubInfo, setLoadingClubInfo] = useState<boolean>(false);
  const [errorClubInfo, setErrorClubInfo] = useState<Error>();
  const [clubInfo, setClubInfo] = useState<ClubByCode>();
  const [countryCode, setCountryCode] = useState<string>()

  useEffect(() => {
    setLoadingClubInfo(true);

    Backend(MicroServices.Club, `/clubs/codes/${clubCode}`)
      .then((response) => response.json())
      .then((results) => {
        if (!isClubByCode(results)) {
          return Promise.reject(results);
        }
        setClubInfo(results);
        return Organization.getOrgById(results.org_id);
      })
      .then((orgResults) => {
        setCountryCode(orgResults?.countryCode);
      })
      .catch((error) => {console.log((error));setErrorClubInfo(error);})
      .finally(() => setLoadingClubInfo(false))

    return () => setLoadingClubInfo(false);
  }, [clubCode]);

  return {
    loadingClubInfo,
    errorClubInfo,
    clubInfo,
    countryCode,
  }
}

type RegisterCodeFormKeys =
  'givenName' |
  'familyName' |
  'gender' |
  'email' |
  'userName' |
  'password' |
  'preferredLanguage' |
  'birthdate';

export const useRegisterCodeForm = (clubCode: string, onSuccess: () => void) => {
  const { t, i18n } = useTranslation();
  const [form, setForm] = useState<{[key in RegisterCodeFormKeys]: string}>({
    givenName: '',
    familyName: '',
    gender: '',
    email: '',
    userName: '',
    password: '',
    preferredLanguage: '',
    birthdate: '',
  });

  const [formErrors, setFormErrors] = useState<{[key in RegisterCodeFormKeys]: FieldError}>({
    givenName: defaultFieldError(),
    familyName: defaultFieldError(),
    gender: defaultFieldError(),
    email: defaultFieldError(),
    userName: defaultFieldError(),
    password: defaultFieldError(),
    preferredLanguage: defaultFieldError(),
    birthdate: defaultFieldError(),
  });

  const [showErrors, setShowErrors] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const setFormValue = (key: RegisterCodeFormKeys, e: React.FormEvent<HTMLSelectElement | HTMLInputElement>) => setForm({
    ...form,
    [key]: e.currentTarget.value,
  });

  const setFormValueString = (key: RegisterCodeFormKeys, value: string) => setForm((old) => ({
    ...old,
    [key]: value,
  }));

  const setError = (key: RegisterCodeFormKeys, message: string) => setFormErrors((old) => ({
    ...old,
    [key]: {
      hasError: true,
      message,
    }
  }));

  const cleanError = (key: RegisterCodeFormKeys) => setFormErrors({
    ...formErrors,
    [key]: {
      hasError: false,
      message: '',
    }
  })

  const getError = (key: RegisterCodeFormKeys): string => (formErrors[key].hasError) ? formErrors[key].message : '';

  const checkForm = () => {
    const errors: string[] = [];
    if (!checkString(form.givenName)) {
      setError('givenName', 'First Name can\'t be empty.');
      errors.push('First Name can\'t be empty.');
    } else {
      cleanError('givenName');
    }
    
    if (!checkString(form.familyName)) {
      setError('familyName', 'Last Name can\'t be empty.');
      errors.push('Last Name can\'t be empty.');
    } else {
      cleanError('familyName');
    }

    if (!checkString(form.gender)) {
      setError('gender', 'Gender can\'t be empty.');
      errors.push('Gender can\'t be empty.');
    } else {
      cleanError('gender');
    }
    if (!isValidUsername(form.userName)) {
      setError('userName', 'Username can\'t be empty. Use max 15 characters (letters, numbers or _)');
      errors.push('Username can\'t be empty. Use max 15 characters (letters, numbers or _)');
    } else {
      cleanError('userName');
    }
    if (!checkString(form.password)) {
      setError('password', 'Password can\'t be empty.');
      errors.push('Password can\'t be empty.');
    } else {
      cleanError('password');
    }
    return errors;
  };

  const submitForm = () => {
    if (checkForm().length > 0) {
      setShowErrors(true);
      return;
    }
    setShowErrors(false);
    setLoading(true);

    let success = false;

    Backend(
      MicroServices.AccessManager,
      `/auth/sign-up-code?code=${clubCode}`,
      {
        method: 'POST',
        body: JSON.stringify({
          user: {
            ...form
          }
        })
      }
    )
    .then((response) => {
      if(response.status === 201){
        success = true;

        if(form.email !== ''){
          toast.success(t('toast_userRegisteredCheck'));
        }
        else{
          toast.success(t('toast_userRegistered'));
        }
        
        onSuccess();
      }
      return response.json();
    })
    .then((results) => {
      if(!success){
        toast.error(results.message || t('toast_ThereWasError'));
      }
    })
    .catch(() => {
      toast.error(t('toast_ThereWasError'))
    })
    .finally(() => setLoading(false))
  };

  return {
    form,
    formErrors,
    showErrors,
    setFormValue,
    setFormValueString,
    getError,
    submitForm,
    loading,
  };
}
