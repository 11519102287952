import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import LoginBtn from '../../components/LoginBtn';
import LoginInput from '../../components/LoginInput';
import { userForgotPassword } from '../../store/actions/userActions';
import MenuWrp from '../../components/MenuWrp'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClock, faUserShield, faMedal } from "@fortawesome/pro-regular-svg-icons";
import Footer from '../../components/Footer'
import './styles.scoped.css';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const loadingForgot = useSelector((state: any) => state.getUser.loadingForgot || false);
    const [activePopup, setActivePopup] = useState(false);

    const handleForgot = () => {
        dispatch<any>(userForgotPassword(username))
            .then((results: any) => {
                navigate('/restore-password');
            })
            .catch(console.log)
    };

    function handleMenu(e: any) {
        setActivePopup(true);
    }

    function handleCloseMenu() {
        setActivePopup(false);
    }

    const {
        mainBackground,
        img1,
        img2,
        img3,
        oneUnifyingPlatfo,
        saveTeachersTimeA,
        overlapGroup1,
    } = componentData;

    return (
        <>
            <div className='containerSignUp'>
                <div className={`overlap ${activePopup && 'active'}`} onClick={handleCloseMenu}>
                    <MenuWrp activePopup={activePopup} />
                </div>
                <div className='headerSignUp'>
                    <div className="wrapContainer">
                        <div className='logoSignUp'>
                            <a href="https://possibilitycompany.com/">
                                <img src="https://possibilitycompany.com/wp-content/uploads/2020/02/Possibility-Color_Logotype.png" alt="" />
                            </a>
                        </div>
                        <div className='buttonMenu'>
                            <button className="loginSignUp" onClick={() => navigate('/')}>Login</button>
                            <button className="signUpSignUp" onClick={() => navigate('/signup')}>Sign Up</button>
                            <button className="menuSignUp">
                                <FontAwesomeIcon
                                    icon={faBars}
                                    size='xl'
                                    color={"white"}
                                    onClick={(e) => handleMenu(e)}
                                    className="buttonMenu"
                                />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="containerSection">
                    <div className="col col1">
                        <div className='grayFilter'></div>
                        <div className="signUpInfo">
                            <h2>Schools and School Systems</h2>
                            <div className='textInfoFirst'>
                                One, unifying platform to manage and promote all extracurricular activities ensuring equal awareness and access to opportunity.
                            </div>
                            <div className='textInfo'>
                                <FontAwesomeIcon
                                    icon={faClock}
                                    size='xl'
                                    color={"white"}
                                />
                                <p>Save teachers time and receive previously unavailable insight into school or system wide engagement, i.e., by school, gender, ethnicity, class year and socioeconomic status.</p>
                            </div>
                            <div className='textInfo'>
                                <FontAwesomeIcon
                                    icon={faUserShield}
                                    size='xl'
                                    color={"white"}
                                />
                                <p>Protect students, with a safe, invitation-only social-network that promotes student exploration of their interests through participation in real-life activities.</p>
                            </div>
                            <div className='textInfo'>
                                <FontAwesomeIcon
                                    icon={faMedal}
                                    size='xl'
                                    color={"white"}
                                />
                                <p>Align student engagement with digital rewards – supporting contextual financial literacy and the opportunity for all student to earn micro-scholarships.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col col2">
                        <div
                            className="login-blur-container"
                            style={{ backgroundImage: `url(${overlapGroup1})` }}
                        >
                            <form className="forgotPss-form" >
                                <div className="forgotPss-form-cont" >
                                    <h1 className="titleLogin robotocondensed-bold-white-48px" >
                                        Reset your password
                                    </h1>
                                    <h2 className="subtitleLogin robotocondensed-bold-white-24px" >
                                        Please provide an email
                                    </h2>
                                    <div className="form-container" >

                                        <div className="robotocondensed-bold-lavender-14px">
                                            <form style={{ alignItems: "flex-start" }}>
                                                <LoginInput placeholder='Email address' handleChange={setUsername} />
                                                <br />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <span className="textTerms">
                                    By clicking Send, you agree to our{" "}
                                    <a href="https://possibilitycompany.com/terms-of-service/">
                                        Terms of Service
                                    </a>{" "}
                                    and{" "}
                                    <a href="https://possibilitycompany.com/privacy-policy/">
                                        Privacy Policy.
                                    </a>
                                </span>
                                <br />
                                <div className="frame-5432" >
                                    <div className="frame-5355" >
                                        <button className={`forgotPss-button ${loadingForgot && 'inProcess'}`} type="button" onClick={handleForgot}>
                                            {loadingForgot ?
                                                <div className="icon-spinner-third buttonSendSignUp"></div>
                                                :
                                                'Send'
                                            }
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            {/* <div className="container-center-horizontal">
                <div className="background screen" style={{ backgroundImage: `url(${mainBackground}) no-repeat center center fixed` }} >
                    <div className="frame-6129" >
                        <div className="group-container" >
                            <img className="group-4960" src={img1} />
                            <img className="group-4959" src={img2} />
                            <img className="group-4961" src={img3} />
                        </div>
                        <div className="frame-6128" >
                            <div
                                className="schools-and-school-systems valign-text-middle robotocondensed-bold-white-24px"
                            >
                                Schools and School Systemsm asudhiuad
                            </div>
                            <p className="one-unifying-platfo valign-text-middle robotocondensed-normal-white-16px" >
                                {oneUnifyingPlatfo}
                            </p>
                            <p className="save-teachers-time-a robotocondensed-normal-white-16px" >
                                {saveTeachersTimeA}
                            </p>
                        </div>
                    </div>

                    <div
                        className="forgotPss-blur-container"
                        style={{ backgroundImage: `url(${overlapGroup1})` }}
                    >
                        <form className="forgotPss-form" >
                            <div className="forgotPss-form-cont" >
                                <h1 className="titleLogin robotocondensed-bold-white-48px" >
                                    Reset your password
                                </h1>
                                <h2 className="subtitleLogin robotocondensed-bold-white-24px" >
                                    Please provide an email
                                </h2>
                                <div className="form-container" >

                                    <div className="robotocondensed-bold-lavender-14px">
                                        <form style={{ alignItems: "flex-start" }}>
                                            <LoginInput placeholder='Email address' handleChange={setUsername} />
                                            <br />
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <span className="textPrivacyPolicy">By clicking Send, you agree to our Terms of Service and Privacy Policy.</span>
                            <br />
                            <div className="frame-5432" >
                                <div className="frame-5355" >
                                    <button className={`forgotPss-button ${loadingForgot && 'inProcess'}`} type="button" onClick={handleForgot}>
                                        {loadingForgot ?
                                            <div className="icon-spinner-third buttonSendSignUp"></div>
                                            :
                                            'Send'
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div> */}

            {/* <main style={{ color: 'white' }}>
                <h2>Forgot password</h2>
                <div className="form">
                    <form>
                        <LoginInput placeholder='Email address' handleChange={setUsername} />
                        <br />
                        <LoginBtn onClick={handleForgot} message='Send' />
                    </form>
                </div>
            </main>
            <br />
            <nav>
                <Link to="/" style={{ color: 'white' }}>Login</Link>
            </nav>
            <nav>
                <Link to="/restore-password" style={{ color: 'white' }}>Got a code?</Link>
            </nav> */}
        </>
    );
};

const componentData = {
    mainBackground: "https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/6219388fb3094ba2a2c54c20/img/desktop---38@1x.png",
    img1: "https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/group-4960@2x.png",
    img2: "https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/group-4959@1x.png",
    img3: "https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/group-4961@2x.png",
    oneUnifyingPlatfo: "One, unifying platform to manage and promote all extracurricular activities ensuring equal awareness and access to opportunity.",
    saveTeachersTimeA: <>Save teachers time and receive previously unavailable insight into school or system wide engagement.<br />Protect students, with a safe, invitation-only social-network.<br />Align student engagement with digital rewards.</>,
    overlapGroup1: "https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/rectangle-3616@1x.png",
    vector21: "https://anima-uploads.s3.amazonaws.com/projects/621922aa4f9f7a9fa82bf142/releases/621d10851ca9e01b59e786f2/img/vector-21@2x.png",
    number2: "2",
};

export default ForgotPassword;
