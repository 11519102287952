import styled from "styled-components";

export const Wrapper = styled.div`
    width: calc(100% - 350px);
    font-family: Roboto;
    margin: 0 auto;
    color: white;
    display: flex;
    position: relative;
`;

export const TagsWrapper = styled.div`
    width: 310px;
    font-family: Roboto;
    margin: 0 auto;
    color: white;
    text-align: left;
    overflow: scroll;

    & {
        width: 310px;
        font-family: Roboto;
        margin: 0 auto;
        color: white;
        text-align: left;
        overflow: scroll;
    }

    & ul{
        position: fixed;
        overflow-y: scroll;
        width: 310px;
        max-height: 100%;
        padding-bottom: 550px;
    }

    & li {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        height: 40px;
        background: #250D44;
        border-radius: 100px;
        margin: 16px 0px;
        width: 95%;
        justify-content: space-between;
    }

    & ul > li {
        margin-top: 0px !important;
    }

    & ul > li.active {
        border-radius: 100px 0px 0px 100px;
        background-color: #34266b;
        width: 100%;
    }

    & ul > li.active .icon-chevron-right {
        display: none;
    }

    & ul::-webkit-scrollbar {
        display: block;
        width: 5px;
    }

    & ul::-webkit-scrollbar-thumb{
        background: var(--lavender);
        border-radius: 4px;
    }

    & ul::-webkit-scrollbar-thumb:active {
    /* background-color: red; */
    }

    & ul::-webkit-scrollbar-thumb:hover{
        background: var(--lavender);
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    }


    & ul::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 4px;
    }

    & ul::-webkit-scrollbar-track:hover, 
    & ul::-webkit-scrollbar-track:active {
        background: transparent;
    }
`;

export const TClubsWrapper = styled.div`
    width: calc(100% - 310px);
    font-family: Roboto;
    margin: 0 auto;
    color: white;
    background-color: #34266b;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    padding-right: 0;

    & > div {
        margin-right: auto;
        display: flex;
        align-items: center;
        padding: 10px;
        height: 44px;
        background: #250D44;
        border-radius: 8px;
        width: 30%;
        margin-bottom: 15px;
        color: #a885d7;
        justify-content: space-between;
    }
`;
