import { useState } from "react";

const useGender = () => {
  // Get genders by API
  const [genderList, setGenderList] = useState<{[key: string]: string}>({
    M: 'Male',
    F: 'Female',
    O: 'Non binary',
    P: 'Prefer not to disclose',
  });

  return genderList;
}

export default useGender;