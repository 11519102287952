import React, {
    useState,
    useEffect
} from 'react';
import './styles.scoped.css'
import CreatableSelect from 'react-select/creatable';
import { GroupBase, OnChangeValue, StylesConfig } from 'react-select';
import ImageProfile from './ImageProfile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SocialMedia from './SocialMedia';
import { UserResponsePII } from '../../interfaces/user/UserResponse';
import { UserResponse, UserResponseMock } from "../../interfaces/user/UserResponse";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../store/store";
import { LAVENDER, WHITE } from "../ClubIconsNavBar";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from '../../helpers/backendHelper';
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux';
import useGender from '../../hooks/useGender';

interface Option {
    readonly label: string;
    readonly value: string;
}
export interface SocialMediaLink {
    id: string;
    type: string;
    link: string;
}

const GeneralInfo = ({ user, backInfo }: { user: UserResponsePII, backInfo: any }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { username } = useParams();
    const dispatch = useAppDispatch();

    const [userForm, setUserForm] = useState<UserResponse>(UserResponseMock);
    const [userResponse, setUserResponse] = useState<UserResponse>(UserResponseMock);

    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);

    const [gender, setGender] = useState(user.gender || '')
    const [zipcode, setZipCode] = useState(user.zipcode || '')
    const [phoneNumber, setPhoneNumber] = useState(`${user.phoneNumber}` || '')
    const [address, setAddress] = useState(user.address || '')
    
    const organization = useSelector((state: any) => state.getOrgs.organizationInfo);
    const { genders, setCountry: setCountryGender} = useGender();

    useEffect(() => {
        if (organization && organization?.countryCode) {
            setCountryGender(organization.countryCode);
        }
    }, [])
    useEffect(() => {
        if (organization && organization?.countryCode) {
            setCountryGender(organization.countryCode);
        }
    }, [organization])

    const [socialMediaLinks, setSocialMediaLinks] = useState<SocialMediaLink[]>([]);

    useEffect(() => {
        setUserForm({
            ...userForm,
            gender: user.gender,
        });

        if (user.socialMedia) {
            setSocialMediaLinks(user.socialMedia);
        }
        else {
            setSocialMediaLinks([])
        }

        setAddress(user.address || '');
        setZipCode(user.zipcode || '');
        setPhoneNumber(`${user.phoneNumber}` || '');
    }, [user])

    const addNewLink = () => {
        setSocialMediaLinks([...socialMediaLinks, {
            id: '',
            type: '',
            link: ''
        }])
    }

    const handleChangeGender = (e: any) => {
        setGender(e)
        setUserForm({
            ...userForm,
            gender: e,
        });
    };

    const handleOnChangeInput = (e: any) => setZipCode(e.target.value)

    function updateUserInfo() {
        setLoadingUpdate(true);
        Backend(
            MicroServices.Admin,
            `/users/${username}?roleRequest=OA`, {
            method: 'PUT',
            body: JSON.stringify({
                gender: gender,
                zipcode: zipcode,
                phoneNumber: parseInt(phoneNumber),
                address: address,
            }),
        })
            .then((response) => {
                if(response.status === 200){
                    setLoadingUpdate(false);
                    navigate(`../userProfile/${username}`);
                    toast.success(t('toast_informationUpdated'))
                }
                setLoadingUpdate(false);
            })
            .catch(console.error);
    }


    /*const [value, setValue] = useState<OnChangeValue<Option, true>>(userInterests.map((item: any) => createOption(item)));
    const [textAboutMe, setTextAboutMe] = useState('Lorem inpsum');
    const [userInterests, setUserInterests] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const components = {
        DropdownIndicator: null,
    };

    const createOption = (label: string) => ({
        label,
        value: label,
    });

    const handleChange = (
        value: OnChangeValue<Option, true>,
    ) => {
        setValue(value);
    };

    const handleInputChange = (inputValue: any) => {
        setInputValue(inputValue);
    };

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = event => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setInputValue('');
                if (!value.map((item) => item.value).includes(inputValue)) setValue([...value, createOption(inputValue)]);
                //setClub({...club,skills:[...club.skills || [], inputValue]})
                event.preventDefault();
        }
    };

    const skillsLearnedStyles: StylesConfig<Option, boolean, GroupBase<Option>> = {
        singleValue: base => ({
            ...base,
            display: 'flex',
            alignItems: 'center',
            color: 'white',
            backgroundColor: 'red',
            fontFamily: 'Roboto Condensed',
        }),
        control: styles => ({
            ...styles,
            backgroundColor: '#28115A',
            borderRadius: '3px',
            boxShadow: 'none',
            border: 0,
            minWidth: '50%',
        }),
        option: styles => ({
            ...styles,
            color: 'white',
            backgroundColor: 'var(--violent-violet)',
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'Roboto Condensed',
        }),
        placeholder: styles => ({
            ...styles,
            color: 'white',
            textAlign: 'left',
            fontFamily: 'Roboto Condensed',
        }),
        container: styles => ({
            ...styles,
            padding: 0,
            width: '100%',
        }),
        menu: styles => ({
            ...styles,
            borderRadius: '8px',
            backgroundColor: 'var(--violent-violet)',
        }),
        input: (styles: any, state: any) => ({
            ...styles,
            border: 'none',
            color: 'white',
            fontFamily: 'Roboto Condensed',
        }),
        dropdownIndicator: styles => ({ ...styles, display: 'none' }),
        indicatorSeparator: (styles: any) => ({ ...styles, display: 'none' }),
        multiValue: styles => ({
            ...styles,
            color: 'white',
            backgroundColor: 'var(--electric-violet-2)',
        }),
        multiValueLabel: styles => ({
            ...styles,
            color: 'white',
            fontFamily: 'Roboto Condensed',
            fontSize: '16px',
        }),
        multiValueRemove: styles => ({
            ...styles,
            color: 'white',
            backgroundColor: 'transparent',
        }),
    };*/

    return (
        <div className='containerMyAccount'>
            {/*<ImageProfile />
            <div className='row row1'>
                <label className='titleInput' htmlFor="">Username</label>
                <input type="text" />
            </div>*/}
            <div className='row row1'>
                <label className='titleInput' htmlFor="">{t('profile__nickname')}</label>
                <input type="text" />
            </div>
            <div className='row row1'>
                <label className='titleInput' htmlFor="">{t('profile__gender')}</label>
                <div className='listRadioButtons'>
                    {genders.map(({id: genderKey, label: genderValue}) => (
                        <div className="option-status" onChange={() => handleChangeGender(genderKey)}>
                            <label className="content-input">
                                <input
                                    type="radio"
                                    name="button"
                                    defaultValue={gender}
                                    checked={userForm.gender === genderKey}
                                />
                                <i></i>
                            </label>
                            <label htmlFor="students">{genderValue}</label>
                        </div>
                    ))}
                </div>
            </div>
            {/*<div className='row row1'>
                <label className='titleInput' htmlFor="">Interest</label>
                <div className="containerInterest">
                    <CreatableSelect
                        components={components}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder=""
                        value={value}
                        styles={skillsLearnedStyles}
                    />
                </div>
            </div>

            <div className='row row1'>
                <label className='titleInput' htmlFor="">About me</label>
                <div className='containerAboutMe'>
                    <textarea
                        name=""
                        id=""
                        placeholder={"Let's share something!"}
                        maxLength={2200}
                    >{textAboutMe}</textarea>
                    {textAboutMe.length > 0 && <div className="CharCounter__wrapper">
                        <span className="CharCounter__text">{textAboutMe.length}/2200</span>
                    </div>}
                </div>
            </div>*/}

            <div className='row row2'>
                <div className='col'>
                    <label className='titleInput' htmlFor="">{t('profile__address')}</label>
                    <input type="text" value={address} onChange={(e:any) => setAddress(e.target.value)}/>
                </div>
                <div className='col'>
                    <label className='titleInput' htmlFor="">{t('profile__phoneNumber')}</label>
                    <input type="text" value={phoneNumber === 'undefined' || phoneNumber === 'null' ? '' : phoneNumber} onChange={(e:any) => setPhoneNumber(e.target.value)}/>
                </div>
            </div>
            <div className='row row2'>
                <div className='col leftColumn'>
                    <label className='titleInput' htmlFor="">{t('profile__city')}</label>
                    <input type="text" className='inputAddress disabled' value={user.city || ''} disabled />
                </div>
                <div className='col leftColumn'>
                    <label className='titleInput' htmlFor="">{t('profile__country')}</label>
                    <input type="text" className='inputAddress disabled' value={user.country || ''} disabled />
                </div>
                <div className='col leftColumn'>
                    <label className='titleInput' htmlFor="">{t('profile__zipcode')}</label>
                    <input type="text" defaultValue={zipcode} onChange={(e) => handleOnChangeInput(e)} />
                </div>
            </div>

            <div className='row row2 middle'>
                <label className='titleInput' htmlFor="">{t('profile__socialMedia')}</label>
                <button className='btnAddNewLink pointer'
                    onClick={() => addNewLink()}
                >
                    {t('profile__addNewLink')}
                </button>
            </div>

            {socialMediaLinks.map((mediaLink: SocialMediaLink) => (
                <SocialMedia mediaData={mediaLink} username={username} />
            ))}

            <div className='row row2'>
                <div className='col'>
                    <button onClick={() => backInfo()} className='btnCancel pointer'>
                    {t('button__cancel')}
                    </button>
                </div>
                <div className='col'>
                    <button onClick={() => updateUserInfo()} className='btnSave pointer'>
                        {(loadingUpdate) ? (
                            <div className="icon-spinner-third buttonSendSignUp" />
                        ) : (
                            t('button__save')
                        )}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default GeneralInfo