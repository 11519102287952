import "../styles.scoped.css";
import '../styles.css';
import CardCommunities from "./CardCommunitites";
import CardCommunitiesYouth from "./CardCommunititesYouth";
import OrganizationComplete from "../../../interfaces/organization/OrganizationComplete";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { Advisor } from "../../SetupSetup/Communities";
export interface CommunityYouthItem {
    id: number;
    title: string;
    icon: string;
    desc: string;
    btnActive: boolean;
    active: boolean;
    advisors: Advisor[],
    invitesSend: number;
    club_id?: string;
}

function CommunitiesOwnYouth(props: any) {

    const organization = useSelector(
        (state: any) => state.getOrgs.organizationInfo
    );


    return (
        <>
            <div className="container-myschool">
                <h1>Zaahah</h1>
                <span>Please build the core communities of {organization?.name}.</span>
                <div className="btnWrapper">
                    <button
                        name="btn-Step2-Save"
                        className="button-save"
                        onClick={(e) => props.handleOnClick(e, 3)}>
                        Continue
                    </button>
                </div>
            </div>

            <div className="communities-schools">
                <CardCommunitiesYouth organization={organization} />
            </div>

        </>

    );
}

export default CommunitiesOwnYouth;
