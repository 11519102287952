import "./styles.css";
import React, {
  useState,
  useEffect
} from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser, updateProfilePhoto, uploadAsset, uploadImage, userLogout } from "../../store/actions/userActions";
import { getNameUser } from "../../helpers/userHelper";
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalImageEditor from '../ModalImageEditor';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
import { useTranslation } from "react-i18next";
toast.configure();

const Menu = (props: any) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const user = useSelector((state: any) => state.getUser.user);

  const [showModal, setShowModal] = useState(false);
  const [userProfile, setUserProfile] = useState('');

  useEffect(() => {
    setUserProfile(user.avatar || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png')
  }, [user])

  function handleOnClickOk() {
    props.onHide(false);
  }

  const handleLogOut = () => {
    dispatch(userLogout());
    props.ChatServiceInstance.disconnect();
    navigate("/");
  };

  const showNotification = () => {
    if (location.pathname.includes('admin') || location.pathname.includes('chat')) {
      navigate(`/app?notifications=true`);
    }
    props.activeNotification();
    props.onHide(false);
  }

  const viewProfile = () => {
    if (location.pathname.includes('admin') || location.pathname.includes('chat')) {
      navigate(`/app/userProfile/${userInfo.username}`);
    }
    else {
      navigate(`./userProfile/${userInfo.username}`);
    }
    handleOnClickOk();
  };

  function handleSaveImage(e: any) {

    fetch(e)
      .then(res => res.blob())
      .then(blob => {
        const contentType = e.split(';')[0].split(':')[1];
        const extension = contentType.split('/')[1]
        const file = new File([blob], 'profile' + userInfo.username, { type: contentType });

        if (file) {
          dispatch<any>(uploadAsset(
            file.type,
            file.name + '.' + extension,
            "profile_photo"
          ))
            .then((results: any) => {
              if (results.body) {
                dispatch<any>(uploadImage(
                  results.body,
                  contentType,
                  file,
                ))
                  .then((result: any) => {
                    if (result.status === 200) {
                      const urlImage = results.body.split('?')[0];
                      setUserProfile(urlImage);
                      dispatch<any>(updateProfilePhoto(
                        urlImage,
                        userInfo.username,
                      ))
                        .then((result: any) => {
                          if (result.status === 200) {
                            setUserProfile(urlImage);
                            setShowModal(false);
                            dispatch<any>(getUser(userInfo.username));
                          }
                          else {
                            setShowModal(false);
                          }
                        })
                        .catch((error: any) => {
                          console.log(error)
                        })
                    }
                  })
                  .catch((error: any) => {
                    console.log(error)
                  })

              }
              else {
                toast.error(t('toast_errorImageUrl'));
              }
            })
            .catch((error: any) => {
              console.log(error)
            })
        }
        else {
          toast.error(t('toast_addFile'));
        }

      })
  };

  function handleCloseModal(e: any, type: any) {
    setShowModal(() => false);
    console.log('close modal');
  };

  return (
    <>
      <div
        onClick={handleOnClickOk}
        className={`overlap ${props.show ? "active" : ""}`}
        id="activate-menu"
      ></div>
      <div id={`sidebar`} className={`${props.show ? "active" : ""}`}>
        <button className="buttonCloseMenu" onClick={handleOnClickOk}>
          <FontAwesomeIcon
            icon="times"
            size='lg'
            color={WHITE}
            className=" "
          />
        </button>
        <div className="photoProfile">
          <div className="containerImagProfile">
            <ModalImageEditor handleSaveImage={handleSaveImage} show={showModal} onHide={handleCloseModal} isProfilePhoto={true} />
            <img
              src={userProfile !== '' ? userProfile : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'}
              onClick={() => setShowModal(true)}
              alt=""
            />
          </div>

          <span>{getNameUser(user, userInfo, props.user)}</span>

        </div>
        <ul className="listMenu">
          {props.user.includes("SUPER") && (
            <>
              <li onClick={() => navigate("/superadmin")}>
                <div className="letterMenuL letterA"> A </div>
                Superadmin center
              </li>
            </>
          )}

          {props.user.includes("ZAA#ORGADMIN") && (
            <>
              <li onClick={() => navigate("/admin")}>
                <div className="letterMenuL letterA"> A </div>
                {t('menu__adminCenter')}
              </li>
            </>
          )}

          {(!props.user.includes("SUPER")) && (
            <li onClick={viewProfile}>
              <FontAwesomeIcon
                icon="user"
                size='lg'
                color={WHITE}
                className="iconMenuL  "

              />
              {t('menu__viewProfile')}
            </li>
          )}

          <li onClick={() => showNotification()}>
            <FontAwesomeIcon
              icon="bell"
              size='lg'
              color={WHITE}
              className="iconMenuL"
            />
            {t('menu__notifications')}
          </li>

          {/*(!props.user.includes("SUPER")) && (
            <li onClick={() => navigate("/my-account")}>
              <FontAwesomeIcon
                icon="cog"
                size='lg'
                color={WHITE}
                className="iconMenuL  "

              />
              My Account
            </li>
          )*/}

          <li>
            <FontAwesomeIcon
              icon="cog"
              size='lg'
              color={WHITE}
              className="iconMenuL  "

            />
            {/* {t('menu__termsPrivacy')} */}
            <a className="linkTermsPrivacy" href="https://possibilitycompany.com/terms-of-service/">{t('label_terms')}</a>
            &
            <a className="linkTermsPrivacy" href="https://possibilitycompany.com/privacy-policy/">{t('label_privacy')}</a>
          </li>

          <li onClick={() => handleLogOut()} id="logout-btn">
            <FontAwesomeIcon
              icon="sign-out-alt"
              size='lg'
              color={WHITE}
              className="iconMenuL  "

            />
            {t('menu__logOut')}
          </li>

        </ul>
      </div>
    </>
  );
};

export default Menu;
