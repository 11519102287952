import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import TCListSearch from "../../components/TClubs/TCList/TCListSearch";
import TableAdmin from "../../components/TableAdmin";
import { LAVENDER, WHITE } from "../../components/ClubIconsNavBar";
import {
    MainWrapper,
    Header,
    Title,
    Row,
    RowGap,
    ActionWrapper,
    ClickableIcon,
    ContentWrapper,
    PhantomElement,
    Loader,
} from "../../components/SuperAdminComponents/basicForSACenter";
import useCountriesSA, { Country } from "../../hooks/useCountriesSA";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const CountryTable = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { countryPages, reloadCountries } = useCountriesSA(1000);

    const [SearchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const [actCountry, setActCountry] = useState("");

    const filteredCountries = useMemo(
        () =>
            countryPages[0].filter((country) =>
                (country.name || "")
                    .toLowerCase()
                    .includes(SearchTerm.toLowerCase())
            ),
        [countryPages[0], SearchTerm]
    );

    const activateCountry = (countryCode: string) => {
        setLoading(true);
        setActCountry(countryCode);
        Backend(
            MicroServices.Roster,
            `/location/countries/activate?countryCode=${countryCode}`,
            {
                method: "POST",
            }
        )
            .then((response) => response.json())
            .then((results) => {
                if (results.message === "Activated") {
                    toast.success(t('toast_countryActivated'));
                    reloadCountries();
                } else {
                    toast.error(results.message);
                }
            })
            .finally(() => {
                setLoading(false);
                setActCountry("");
            });
    };

    const deactivateCountry = (countryCode: string) => {
        setLoading(true);
        setActCountry(countryCode);
        Backend(
            MicroServices.Roster,
            `/location/countries/deactivate?countryCode=${countryCode}`,
            {
                method: "POST",
            }
        )
            .then((response) => response.json())
            .then((results) => {
                if (results.message === "Deactivated") {
                    toast.success(t('toast_countryDesactivated'));
                    reloadCountries();
                } else {
                    toast.error(results.message);
                }
            })
            .finally(() => {
                setLoading(false);
                setActCountry("");
            });
    };

    return (
        <>
            <MainWrapper>
                <Header>
                    <Row>
                        <Title>Countries</Title>
                    </Row>
                    <RowGap>
                        <PhantomElement />
                        <TCListSearch
                            searchTerm={SearchTerm}
                            setSearchTerm={setSearchTerm}
                        />
                    </RowGap>
                </Header>
                <ContentWrapper>
                    <TableAdmin
                        headers={[
                            {
                                label: "Name",
                            },
                            {
                                label: "Active",
                            },
                            {
                                label: "Actions",
                                right: true,
                            },
                        ]}
                        items={filteredCountries}
                        renderItem={(item: Country) => [
                            item.name,
                            <div>
                                {item.active ? (
                                    <FontAwesomeIcon
                                        icon="check"
                                        size="sm"
                                        color={WHITE}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon="times"
                                        size="sm"
                                        color={WHITE}
                                    />
                                )}
                            </div>,
                            <ActionWrapper>
                                {item.active ? (
                                    loading && item.location === actCountry ? (
                                        <Loader />
                                    ) : (
                                        <ClickableIcon
                                            icon="times"
                                            size="lg"
                                            color={LAVENDER}
                                            onClick={() =>
                                                deactivateCountry(item.location)
                                            }
                                        />
                                    )
                                ) : loading && item.location === actCountry ? (
                                    <Loader />
                                ) : (
                                    <ClickableIcon
                                        icon="check"
                                        size="lg"
                                        color={LAVENDER}
                                        onClick={() =>
                                            activateCountry(item.location)
                                        }
                                    />
                                )}
                            </ActionWrapper>,
                        ]}
                        keyExtractor={({ location }: Country) => location}
                    />
                </ContentWrapper>
            </MainWrapper>
        </>
    );
};

export default CountryTable;
