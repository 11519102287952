import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getStatusColor, getStatusName } from "../../../../helpers/chat/statusHelper";
import { ChatServiceInterface } from "../../../../interfaces/chat/ChatServiceInterface";
import { getUserStatus } from "../../../../store/actions/chatActions";
import { RootState } from "../../../../store/store";
import { WHITE } from "../../../ClubIconsNavBar";
import { ClickableIcon } from "../../../SuperAdminComponents/basicForSACenter";
import OutsideClickModalStatus, { CircleStatus, StatusLabel } from "./OutsideClickModalStatus";

const SelectStatus = ({ChatServiceInstance}:{ChatServiceInstance:ChatServiceInterface}) => {
    const dispatch = useDispatch()

    const userInfo = useSelector((state: any) => state.getUser.userInfo);

    const [statuses, setStatuses] = useState<string[]>(['available', 'invisible', 'busy']); //@todo status from database
    const [showModalStatus, setShowModalStatus] = useState<boolean>(false);

    // useEffect(() => {
    //     dispatch(getUserStatus(userInfo.username));
    // }, [])

    return (
        <SelectStatusWrapper>
            {(showModalStatus) && <OutsideClickModalStatus setShowModal={setShowModalStatus} status={statuses} ChatServiceInstance={ChatServiceInstance} />}
            <SelectStatusOutside
                onClick={() => setShowModalStatus(!showModalStatus)}
            >
                <StatusOptionSelected>
                    <CircleStatus status={ChatServiceInstance.connectedUser.status} />
                    <StatusLabel>{getStatusName(ChatServiceInstance.connectedUser.status)}</StatusLabel>
                </StatusOptionSelected>
                <ClickableIcon
                    icon="chevron-down"
                    size='sm'
                    color={WHITE}
                />
            </SelectStatusOutside>
        </SelectStatusWrapper>
    );
}

export default SelectStatus;

const SelectStatusWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    position: relative;
`;

const SelectStatusOutside = styled.div`
    display: flex;
    flex-direction: row;
    background-color: rgb(40,17,90);
    width: 100%;
    align-items: center;
    padding: 5px 5px 5px 5px;
    border-radius: 5px;
`;

const StatusOptionSelected = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    width: 100%;
    height: 25px;
`;