import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UserResponsePII } from '../../interfaces/user/UserResponse'
import { changePassword } from '../../store/actions/userActions'
import ImageProfile from './ImageProfile'
import './styles.scoped.css'
import { useTranslation } from "react-i18next";

const AccountInfo = ({ user }: { user: UserResponsePII }) => {
  const { t, i18n } = useTranslation();
  const loadingPassword = useSelector((state: any) => state.getUser.loadingChangePassword || false);
  const dispatch = useDispatch();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const handleChange = () => {
    dispatch<any>(changePassword(oldPassword, newPassword))
      .then(() => {
        setOldPassword('');
        setNewPassword('');
      });
  };

  const handleCancel = () => {
    setOldPassword('');
    setNewPassword('');
  };

  return (
    <div className='containerMyAccount'>
      {/*<ImageProfile />*/}

      <div className='row row2'>
        <div className='col'>
          <label className='titleInput leftColumn' htmlFor="">{t('profile__email')}</label>
          <p className='inputDisabledAccount'>
            {user.email}
          </p>
        </div>
        <div className='col'>
          <label className='titleInput leftColumn' htmlFor="">{t('profile__username')}</label>
          <p className='inputDisabledAccount'>
            {user.username}
          </p>
        </div>
      </div>

      <div className='row row2'>
        <div className='col'>
          <label className='titleInput' htmlFor="">{t('profile__currentPassword')}</label>
          <input type="password" value={oldPassword} onChange={(e: any) => setOldPassword(e.target.value)} />
        </div>
        <div className='col'>
          <label className='titleInput' htmlFor="">{t('profile__newPassword')}</label>
          <input type="password" value={newPassword} onChange={(e: any) => setNewPassword(e.target.value)} />
        </div>
      </div>

      <div className='row row2'>
        <div className='col'>
          <button 
            className='btnCancel pointer'             
            onClick={handleCancel}
          >
            {t('button__cancel')}
          </button>
        </div>
        <div className='col'>
          <button 
            className='btnSave pointer'
            onClick={handleChange}
          >
            {(loadingPassword) ? (
              <div className="icon-spinner-third buttonSendSignUp" />
            ) : (
              t('profile__updatePassword')
            )}
          </button>
        </div>
      </div>

    </div>
  )
}

export default AccountInfo