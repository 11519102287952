import "./styles.css"
import {LinkPreview} from "@dhaiwat10/react-link-preview";
import React from "react";
import {toast} from "react-toastify";
import { Backend, MicroServices } from "../../helpers/backendHelper";

//TODO: IMPORTANT! In production we must deploy the heroku proxy to our own servers

function Index(props: any) {

    const handleCloseClick = () => {
        props.onClose();
    }
    return (
        <div className="container-post-link-preview">
            {props.showX && (<span className="close" onClick={handleCloseClick}>&times;</span>)}
            <LinkPreview className="input-link" url={props.url} width='400px'
                         // fallback={
                         //     (
                         //         <>
                         //             <script>{toast.error("Unable to load link preview, please try again later")}</script>
                         //             <script>{props.setTextLink(false)}</script>
                         //         </>
                         //     )
                         //
                         // }
                         fetcher={(): any =>
                             new Promise((resolve, reject) => {
                                 Backend(
                                    MicroServices.Posts,
                                    `/post/preview-link-proxy?url=${props.url}`)
                                     .then(results => results.json())
                                     .then(results => resolve(results.metadata))
                                     .catch(error => reject(error))
                             })
                         }
            />
        </div>
    )
}

export default Index;