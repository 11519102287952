import "../styles.scoped.css";
import '../styles.css';
import { useState, useCallback, useEffect, useMemo } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { useDropzone } from "react-dropzone";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { faTimes, faCopy } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Advisor } from "../../SetupSetup/Communities";
import { WHITE } from "../../ClubIconsNavBar";
import { Backend, MicroServices } from "../../../helpers/backendHelper";
import { createInviteRoster } from "../../../store/actions/inviteActions";
import usePostalCodes from "../../../hooks/usePostalCodes";
import { RootState } from "../../../store/store";
import { useTranslation } from "react-i18next";
import { frontendUrl } from "../../../AppConfig";
import { ClubCode, ClubCodeDTO } from "../../../interfaces/club/ClubCode";
import { getOrgCommunities } from "../../../store/actions/clubActions";

toast.configure()
const ACCEPTED_FILES = {
  'text/csv': ['.csv']
}

const modalInviteTitle = (type: string): string => {
  return `Invite ${type.includes('Class') ? 'Students' : (type.length > 0 ? type : 'Members')}${type === 'Staff' ? '' : ` for ${type}`}`
}

const toastInviteMessage = (type: string): string => {
  return `${(type.length > 0 ? (type.slice(type.length - 1) === 's' ? type.substring(0, type.length - 1) : type) : 'Member')} invites created`;
}

function Index(props: any) {
  const { t, i18n } = useTranslation();
  const tcomms = useSelector((state: RootState) => state.getTClubs.TComm);
  const { postalCodes: postalCodesConfig, setCountry: setCountryPostal } = usePostalCodes();

  const [fileDenied, setFileDenied] = useState(false);
  const [checkingFile, setCheckingFile] = useState(false);
  const [fileAccepted, setFileAccepted] = useState(false);
  const [fileRejected, setFileRejected] = useState(false);
  const [notAcceptedMsg, setNotAcceptedMsg] = useState('');
  const [base64file, setBase64file] = useState('');
  const [loadingInvites, setLoadingInvites] = useState(false);
  const [invitedPeople, setInvitedPeople] = useState<number>(0);
  const [sendInactive, setSendInactive] = useState(true);

  const [createCommunitiesResponse, setCreateCommunitiesResponse] = useState(undefined);
  const organization = useSelector((state: any) => state.getOrgs.organizationInfo);
  const communities: any[] = useSelector((state: RootState) => state.getClub.GetComm);

  const [showCode, setShowCode] = useState(false);
  const [canShowCode, setCanShowCode] = useState(false);
  const [codeGenerated, setCodeGenerated] = useState(false);
  const [clubCode, setClubCode] = useState<ClubCode>();
  const [members, setMembers] = useState<number>(0);
  const [membersHasError, setMembersHasError] = useState<boolean>(false);
  const [clubCodeHasError, setClubCodeHasError] = useState<boolean>(false);

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryPostal(organization?.countryCode);
    }
  }, [])

  useEffect(() => {
    if (organization && organization?.countryCode) {
      setCountryPostal(organization?.countryCode);
    }
  }, [organization])


  const convertToBase64 = (file: File) => new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject('Error: ' + error);
    };
  })

  const onHide = () => {
    setFileDenied(false);
    setCheckingFile(false);
    setFileAccepted(false);
    setFileRejected(false);
    setBase64file('');
    setLoadingInvites(false);
    setSendInactive(true);
    props.onHide();
  }

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length === 0) {
      setFileDenied(true);
      return;
    }
    setFileDenied(false);
    setCheckingFile(true);
    convertToBase64(acceptedFiles[0])
      .then((bareBase64: string | ArrayBuffer | null) => {
        if (!bareBase64) {
          console.log(t('toast_ThereWasError'))
          return;
        }
        const toSendBase64 = (bareBase64 as string).split('base64,')[1]
        //console.log(toSendBase64);
        Backend(
          MicroServices.Invite,
          `/invites/validate?staff=${!props.type.includes('Class')}&postalCodeLength=${postalCodesConfig?.minLength}`, {
          method: 'POST',
          body: JSON.stringify({
            file: toSendBase64,
          })
        })
          .then((response) => {
            if (response.status === 200) {
              setBase64file(toSendBase64);
              setCheckingFile(false);
              setFileAccepted(true);
              setFileRejected(false);
              setSendInactive(false);
            } else if (response.status === 400) {
              setCheckingFile(false);
              setFileAccepted(false);
              setFileRejected(true);
              setSendInactive(true);
            }
            return response.json();
          })
          .then((response) => {
            //console.log(response);
            setNotAcceptedMsg(response.message || '');
            setInvitedPeople(response.invites || 0);
          })
      })
  }, [props.type, postalCodesConfig]);

  const dispatch = useDispatch()

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ACCEPTED_FILES,
  });

  const fileExampleFile = 'data:text/csv;charset=utf-8,' + encodeURIComponent('Email,First name,Last name,Gender,Date of birth,Zip code')

  function sendInvitesMembers() {

    let clubId = '';

    if (!props.club_id && props.type === 'Staff') {
      Backend(
        MicroServices.Club,
        `/clubs?pending=${props.organization.status.startsWith('P')}&org_id=${props.organization.id}&club_type=COMM&type=${encodeURIComponent('ZAA#STAFF')}`)
        .then((response) => response.json())
        .then(({ clubs }) => {
          clubId = clubs[0].id;
          sendInviteDispatch(clubId);
        })
        .catch((error) => {
          console.log(error);
        })
    }
    else {
      sendInviteDispatch(props.club_id);
    }
  }

  const TCId = useMemo(() => tcomms.find(({ name }) => name === props.type)?.id || '', [tcomms, props.type]);

  const generateClubCode = () => {
    

    if (isNaN(members) || members <= 0) {
      setMembersHasError(true);
      return;
    }
    const community = communities.find((comm) => comm.template_id === TCId)
    // console.log(community)
    if (!community) {
      setClubCodeHasError(true);
      return;
    }

    setClubCodeHasError(false);
    setMembersHasError(false);
    Backend(
      MicroServices.Club,
      `/clubs/${community.id}/code`,
      {
        method: 'POST',
        body: JSON.stringify({
          max_members: members,
          members_left: 0,
        } as ClubCodeDTO)
      }
    )
      .then((response) => response.json())
      .then((results) => {
        setClubCode(results);
      })
  };

  const sendInviteDispatch = (clubId: string) => {
    dispatch<any>(createInviteRoster(base64file, clubId, props.organization.id))
      .then((response: any) => {
        if (response === 200) {
          toast.success(t('toast_invitesCreated'));
          setFileDenied(false);
          setCheckingFile(false);
          setFileAccepted(false);
          setFileRejected(false);
          setBase64file('');
          setSendInactive(true);
          props.setShowInviteMembers(false);
          props.getCommunities();

          if (props.type === 'Staff') {
            props.reloadStaff();
          }

          setLoadingInvites(false);

        } else {
          toast.error(t('toast_errorSendInvites'));
          setLoadingInvites(false);
        }
      })
      .catch((error: any) => {
        console.error('Error creating invites', error)
        toast.error(t('toast_errorSendInvites'));
        setLoadingInvites(false);
      })
  }

  const checkStaffClubExists = () => new Promise((resolve, reject) => {
    Backend(
      MicroServices.Club,
      `/clubs?org_id=${props.organization.id}&pending=true`)
      .then((response) => response.json())
      .then(({ clubs }) => {
        const staffClub = clubs.find((club: any) => club.template_id === 'Staff');
        if (staffClub && 'id' in staffClub) {
          resolve(true);
          return;
        }
        else {
          resolve(false)
        }
      })
      .catch((error) => {
        reject(`Error getting staff club.`)
      })
  })

  const btnSendCardStaff = () => {
    checkStaffClubExists()
      .then((exists) => new Promise((resolve, reject) => {
        if (exists) {
          resolve({});
          return;
        }

        Backend(
          MicroServices.Club,
          `/clubs?pending=${props.organization.status.startsWith('PEND')}`, {
          headers: {
            "Content-Type": "application/json",
          },
          method: 'POST',
          body: JSON.stringify({
            "org_id": props.organization.id,
            "template_id": tcomms.find(({ name }) => name === "Staff")?.id || '',
            "status": props.organization.status.startsWith('PEND') ? 'PENDING' : 'ACTIVE',
            "member_type": ["STAFF"],
            "name": "Staff",
            "gendersAllowed": ["M", "F", "O", "P"],
          }),
        })
          .then((response) => {
            if (response && response.status === 201) {
              console.log('Staff community created');
              props.createCommunities()
                .then((responseCommunities: any) => {
                  setCreateCommunitiesResponse(responseCommunities);
                  resolve(responseCommunities)
                })
                .catch(reject)

            } else {
              setLoadingInvites(false);
              console.log(`Error creating staff club.`);
              reject();
            }
          })

      }))
      .then((response) => {
        console.log(`Communities created to school ${props.organization.id}`)
        sendInvitesMembers();
      })
      .catch((error) => {
        console.log(`Error creating staff club. Error: ${error}`);
        setLoadingInvites(false);
      })
  }

  const handleOnClickSendMembers = (e: any) => {

    setLoadingInvites(true);

    if (props.type !== 'Staff') {
      sendInvitesMembers();
    }
    else {
      btnSendCardStaff();
    }
  }

  useEffect(() => {
    setInvitedPeople(0);
    dispatch<any>(getOrgCommunities(props.organization.id, props.organization.status.startsWith('PEND')));
  }, [props])

  useEffect(() => {
    console.log(props)
    setCanShowCode(props.type !== 'Staff');
  }, [props.type])

  return (
    <>

      <Modal className="modalInviteMemebers" show={props.show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>{modalInviteTitle(props.type)}</Modal.Title>
          <FontAwesomeIcon
            icon={faTimes}
            size='xl'
            color={WHITE}
            className="icon-times"
            onClick={onHide}
          />
        </Modal.Header>
        <Modal.Body>
          {canShowCode && (
            <div className="InviteMembers--selectionContainer">
              <div
                className={`InviteMembers--selectionChip roboto-normal-white-16px ${!showCode ? 'active' : ''}`}
                onClick={() => setShowCode(false)}
              >
                Roster File
              </div>
              <div
                className={`InviteMembers--selectionChip roboto-normal-white-16px ${showCode ? 'active' : ''}`}
                onClick={() => setShowCode(true)}>Invite Link</div>
            </div>
          )}
          {showCode ? (
            <div className="InviteMembers--codeWrapper">
              <div className="InviteMembers--row InviteMembers--formControl">
                <span className="robotocondensed-bold-lavender-16px">Number of Members</span>
                <input
                  type="number"
                  className="InviteMembers--formInput roboto-normal-white-16px"
                  disabled={codeGenerated}
                  value={members}
                  onChange={(e) => setMembers(parseInt(e.target.value))}
                />
              </div>
              <div className="InviteMembers--row InviteMembers--formControl">
                <span className="robotocondensed-bold-lavender-16px">Link</span>
                <div className="InviteMembers--inputDisabled roboto-normal-white-16px">
                  <span>{clubCode?.code ? `${frontendUrl}/signup-code/${clubCode.code}` : ''}</span>
                  <button onClick={() => { navigator.clipboard.writeText(`${frontendUrl}/signup-code/${clubCode?.code}`); toast.success(t('toast_linkCopied')); }}>
                    <FontAwesomeIcon
                      icon={faCopy}
                      color={WHITE}
                    />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <p className="text-invites">Upload invite list file (csv accepted file types)</p>
              <p className="subtext-invites">Use following format: Email, First name, Last name, Gender, Date of birth, Zip code.</p>
              <p className="subtext-invites">The gender must be one of the following: M, F, P or O.</p>
              <p className="subtext-invites">{`The Date of Birth must be formatted this way: MM-DD-YYYY e.g. 01-01-2001. ${props.type === 'Staff' ? '(Optional)' : ''}`}</p>
              <p className="subtext-invites">The Zip Code must have {postalCodesConfig?.maxLength} digits.</p>
              <a href={fileExampleFile} download="rosterExample.csv">File example</a>
              <input className="dropzone-input" {...getInputProps()} />
              <div className="container-drag" {...getRootProps()}>
                {isDragActive ? (
                  <>
                    <p>Release to drop</p>
                  </>
                ) : fileDenied ? (
                  <>
                    <p>This file is not a CSV. Drag CSV files here</p> or <span>Browse</span>
                  </>
                ) : checkingFile ? (
                  <>
                    <p>Checking CSV</p>
                  </>
                ) : fileAccepted ? (
                  <>
                    <p>Your file has the correct format. Go and send invites</p>
                  </>
                ) : fileRejected ? (
                  <>
                    <p>This CSV format is not correct</p>
                    <p>{notAcceptedMsg}</p>
                    <p>Drag CSV files here</p> or <span>Browse</span>
                  </>
                ) : (
                  <>
                    <p>Drag CSV files here</p> or <span>Browse</span>
                  </>
                )}
              </div>
              <div className="" style={{ marginTop: '15px' }}>
                {invitedPeople > 0 ? (
                  <span className="text-invites">{invitedPeople} people will be invited</span>
                ) : undefined}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="buttonClose" onClick={onHide}>
            Cancel
          </Button>
          {(showCode) ?
            (
              <button
                className="btn-sendInviteMember buttonSend roboto-normal-white-14px"
                disabled={codeGenerated}
                onClick={generateClubCode}
              >
                Generate
              </button>
            ) : (
              <Button name="btn-sendInviteMember"
                className={`buttonSend ${(sendInactive || loadingInvites) && 'inProcess'}`}
                onClick={handleOnClickSendMembers} disabled={sendInactive}>
                {loadingInvites ? (
                  <div className="icon-spinner-third buttonSendSpinner"></div>
                ) : 'Send invites'}
              </Button>
            )}
          {/* <Button name="btn-sendInviteMember"
            className={`buttonSend ${sendInactive && 'inProcess'}`}
            onClick={handleOnClickSendMembers} disabled={sendInactive}>
            {loadingInvites ? (
              <div className="icon-spinner-third buttonSendSpinner"></div>
            ) : 'Send invites'}
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>


  );
}

export default Index;
