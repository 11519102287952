import React, { SyntheticEvent, useEffect, useState } from "react";
import Header from "../Header";
import styled from "styled-components";
import {
  RobotocondensedRegularNormalWhite16,
  Fontawesome5proRegularNormalLavende,
  RobotocondensedBoldLavender16px,
} from "../../styledMixins";
import "./styles.css";
import SuperAdminLeftMenu from "../SuperAdminLeftMenu";
import AdminLeftMenu from "../AdminLeftMenu";

import UsersTable from "../UsersTable";
import "../../styles.css";
import Select, { SingleValue } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsers,
  getUsersRoles,
  getUsersStatus,
  getUsersWithLimit,
} from "../../store/actions/userActions";
import { getOrganizationNames } from "../../store/actions/orgsActions";
import {
  getActiveCities,
  getActiveCountries,
} from "../../store/actions/locationActions";
import PageNumber from "../PageNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PAGE_SIZES: pageSize[] = [10, 50];
export type pageSize = 10 | 50 | 10000;

function Users() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<pageSize>(PAGE_SIZES[0]);

  const usersAll = useSelector((state: any) => state.getUser.usersLimit || [])
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.getUser.token);
  const lastEvaluatedkeys = useSelector(
    (state: any) => state.getOrgs.lastEvaluatedkeys
  );

  useEffect(() => {
    //dispatch(getUsers());
    dispatch(getUsersStatus());
    //dispatch(getUsersWithLimit({ token: token, limit: pageSize }));
    dispatch(getOrganizationNames());
    dispatch(getUsersRoles());
    dispatch(getActiveCities());
    dispatch(getActiveCountries());
  }, []);

  useEffect(() => {
    if (page === 0) {
      dispatch(
        getUsersWithLimit({ token: token, limit: pageSize, page: page })
      );
    } else {
      dispatch(
        getUsersWithLimit({
          token: token,
          limit: pageSize,
          page: page,
          lastEvaluatedKey: lastEvaluatedkeys[page],
        })
      );
    }
  }, [pageSize, page]);

  const handleSetPageSize = (
    newValue: SingleValue<{ label: string; value: pageSize }>
  ) => {
    setPageSize(newValue?.value || 10);
  };

  const customStyles = () => ({
    option: (styles: any) => ({
      ...styles,
      color: "white",
      backgroundColor: "#383838",
    }),
    input: (styles: any, state: any) => ({
      ...styles,
      border: "none",
      color: "white",
      fontWeight: "normal",
      paddingLeft: "5px",
      "&:focus": { backgroundColor: "red" },
    }),
    container: (styles: any) => ({
      ...styles,
      padding: 0,
    }),
    valueContainer: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: "transparent",
      padding: 0,
    }),
    menu: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      backgroundColor: "#383838",
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      textAlign: "left",
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "118px",
      borderRadius: "0px",
      boxShadow: "none",
      border: 0,
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "bold",
      maxWidth: "200px",
      marginBottom: "5px",
      backgroundColor: "transparent",
      // textDecoration: outside ? 'underline' : 'none',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: "white",
      textDecoration: "underline",
      display: "block",
    }),
    indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
    dropdownIndicator: (styles: any) => ({ ...styles, display: "none" }),
    noOptionsMessage: (styles: any) => ({
      ...styles,
      color: "white",
      backgroundColor: "grey",
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "normal",
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: "white",
      backgroundColor: "transparent",
      border: "none",
      paddingLeft: "5px",
      paddingRight: "5px",
      // display: input ? 'none' : 'block',
    }),
  });

  const [roleSelected, setRoleSelected] = useState("");

  const viewingOptions = PAGE_SIZES.map((item) => ({
    label: `${item} results`,
    value: item,
  }));

  const canGoBack = () => page !== 0;
  const canGoNext = () => true;

  const goBack = () => {
    setPage(page - 1);
  };

  const goNext = () => {
    setPage(page + 1);
  };

  const [rolesChecked,setRolesChecked] = useState({
    "":false,
    "ZAA%23ORGADMIN":false,
    "ZAA%23STUDENT":false,
    "ZAA%23STAFF":false,
    "ZAA%23GUARDIAN":false,
    "ZAA%23ALUMNI":false
  })

  const handleChangeRadio = (event: any) => {
    setRoleSelected(event.target.value);
    const roles:any = {
      "":false,
    "ZAA%23ORGADMIN":false,
    "ZAA%23STUDENT":false,
    "ZAA%23STAFF":false,
    "ZAA%23GUARDIAN":false,
    "ZAA%23ALUMNI":false
    }
    roles[event.target.value] = true
    setRolesChecked(roles)
  };

  const handleChangeRadioConnection = (e:any) => {
    setRoleSelected(e);
    const roles:any = {
      "":false,
    "ZAA%23ORGADMIN":false,
    "ZAA%23STUDENT":false,
    "ZAA%23STAFF":false,
    "ZAA%23GUARDIAN":false,
    "ZAA%23ALUMNI":false
    }
    roles[e] = true
    setRolesChecked(roles)
  }

  const userRoles = useSelector((state: any) => state.getUser.userRoles);

  return (
    <div className="content-org">
      <TopColumn>
        <ManageUsersCol>
          <Title>Manage Users</Title>
          <div className="select-container" onChange={handleChangeRadio}>
            <div className="option">
              <label className="content-input">
                <input type="radio" name="role-button" value="" checked={rolesChecked[""]}/>
                <i></i>
              </label>
              <label htmlFor="students">All</label>
            </div>
            <div className="option">
              <label className="content-input">
                <input type="radio" name="role-button" value="ZAA%23ORGADMIN" checked={rolesChecked["ZAA%23ORGADMIN"]}/>
                <i></i>
              </label>
              <label htmlFor="students">Organization Admins</label>
            </div>
            <div className="option">
              <label className="content-input">
                <input type="radio" name="role-button" value="ZAA%23STUDENT" checked={rolesChecked["ZAA%23STUDENT"]}/>
                <i></i>
              </label>
              <label htmlFor="students">Students</label>
            </div>
            <div className="option">
              <label className="content-input">
                <input type="radio" name="role-button" value="ZAA%23STAFF" checked={rolesChecked["ZAA%23STAFF"]}/>
                <i></i>
              </label>
              <label htmlFor="students">Staff</label>
            </div>
            <div className="option">
              <label className="content-input">
                <input type="radio" name="role-button" value="ZAA%23GUARDIAN" checked={rolesChecked["ZAA%23GUARDIAN"]}/>
                <i></i>
              </label>
              <label htmlFor="students">Guardians</label>
            </div>
            <div className="option">
              <label className="content-input">
                <input type="radio" name="role-button" value="ZAA%23ALUMNI" checked={rolesChecked["ZAA%23ALUMNI"]}/>
                <i></i>
              </label>
              <label htmlFor="students">Alumni</label>
            </div>
          </div>
          <div className="shwo-view-row">
            <ShowingItems>
              <span className="robotocondensed-normal-white-16px">
                Showing{" "}
              </span>
              <span className="robotocondensed-bold-white-16px">
                {pageSize * page + 1}-{pageSize * (page + 1)}{" "}
              </span>
              <span className="robotocondensed-normal-white-16px">of </span>
              <span className="robotocondensed-bold-white-16px">
                {usersAll.length}{" "}
              </span>
              <span className="robotocondensed-normal-white-16px">users</span>
            </ShowingItems>
            <Viewing>
              <Viewing1>Viewing</Viewing1>
              <Box>
                <Select
                  value={{ value: pageSize, label: `${pageSize} results` }}
                  isMulti={false}
                  options={viewingOptions}
                  placeholder={"View"}
                  styles={customStyles()}
                  onChange={handleSetPageSize}
                />
                <ChevronDown />
              </Box>
            </Viewing>
          </div>
        </ManageUsersCol>
      </TopColumn>
      <UsersTable 
      roleSelected={roleSelected}
      setRoleSelected={setRoleSelected}
      handleChangeRadioConnection={handleChangeRadioConnection}/>
      <PageNumber
        page={page}
        canGoBack={canGoBack()}
        canGoNext={canGoNext()}
        goBack={goBack}
        goNext={goNext}
      />
    </div>
  );
}

const ContentUsers = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  width: 100%;
  z-index: 1;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  align-items: center;
  min-height: 944px;
  background-color: var(--violet);
  padding-left: 152px;
  padding-right: 20px;
`;

const TopColumn = styled.div`
  height: 154px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
  justify-content: space-between;
`;

const ManageUsersCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.h1`
  min-height: 28px;
  font-family: var(--font-family-roboto_condensed);
  font-weight: 700;
  color: var(--lavender);
  font-size: var(--font-size-xxxl);
  letter-spacing: 0;
  padding-bottom: 25px;
`;

const ShowingItems = styled.p`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  align-self: flex-start;
  margin-top: 5px;
  letter-spacing: 0;
  align-self: center;
  text-align: center;
`;

const Viewing = styled.div`
  margin-left: 3px;
  display: flex;
  align-items: center;
  min-width: 197px;
  align-self: flex-end;
  font-family: var(--font-family-roboto_condensed);
`;

const Viewing1 = styled.div`
  ${RobotocondensedRegularNormalWhite16}
  min-height: 19px;
  min-width: 50px;
  letter-spacing: 0;
`;

const Box = styled.div`
  height: 40px;
  margin-left: 17px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  min-width: 130px;
  background-color: var(--violent-violet);
`;

const ChevronDownWrapper = styled.div`
  ${Fontawesome5proRegularNormalLavende}
  min-height: 20px;
  margin-left: 27px;
  min-width: 18px;
  letter-spacing: 0;
`;

const ChevronDown = () => <ChevronDownWrapper>
  <FontAwesomeIcon icon="chevron-down" color="#FFFFFF" size="sm"/>
</ChevronDownWrapper>

/* const PgNumber = styled.div`
  margin-top: 438px;
  display: flex;  
  padding: 0 30px;
  align-items: center;
  min-width: 360px;
`; */

export default Users;
