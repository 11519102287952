// Get invites

export const START_GET_INVITES_LIMIT = 'START_GET_INVITES_LIMIT';
export const GET_INVITES_LIMIT_SUCCESS = 'GET_INVITES_LIMIT_SUCCESS';
export const GET_INVITES_LIMIT_ERROR = 'GET_INVITES_LIMIT_ERROR';

export const START_INVITE_TYPE = 'START_INVITE_TYPE';
export const INVITE_TYPE_SUCCESS = 'INVITE_TYPE_SUCCESS';
export const INVITE_TYPE_ERROR = 'INVITE_TYPE_ERROR';

export const START_GET_INVITE_BY_TOKEN = 'START_GET_INVITE_BY_TOKEN';
export const INVITE_BY_TOKEN_SUCCESS = 'INVITE_BY_TOKEN_SUCCESS';
export const INVITE_BY_TOKEN_ERROR = 'INVITE_BY_TOKEN_ERROR';

export const START_CREATE_USER_BY_INVITE = 'START_CREATE_USER_BY_INVITE';
export const CREATE_USER_BY_INVITE_SUCCESS = 'CREATE_USER_BY_INVITE_SUCCESS';
export const CREATE_USER_BY_INVITE_ERROR = 'CREATE_USER_BY_INVITE_ERROR';

export const START_GET_ORG_INVITES = 'START_GET_ORG_INVITES';
export const GET_ORG_INVITES_SUCCESS = 'GET_ORG_INVITES_SUCCESS';
export const GET_ORG_INVITES_ERROR = 'GET_ORG_INVITES_ERROR';

export const START_DELETE_INVITE = 'START_DELETE_INVITE';
export const DELETE_INVITE_SUCCESS = 'DELETE_INVITE_SUCCESS';
export const DELETE_INVITE_ERROR = 'DELETE_INVITE_ERROR';

export const START_RESEND_INVITE = 'START_RESEND_INVITE';
export const RESEND_INVITE_SUCCESS = 'RESEND_INVITE_SUCCESS';
export const RESEND_INVITE_ERROR = 'RESEND_INVITE_ERROR';

export const START_VALIDATE_INVITE_ROSTER = 'START_VALIDATE_INVITE_ROSTER';
export const VALIDATE_INVITE_ROSTER_SUCCESS = 'VALIDATE_INVITE_ROSTER_SUCCESS';
export const VALIDATE_INVITE_ROSTER_ERROR = 'VALIDATE_INVITE_ROSTER_ERROR';

export const START_CREATE_INVITE_ROSTER = 'START_CREATE_INVITE_ROSTER';
export const CREATE_INVITE_ROSTER_SUCCESS = 'CREATE_INVITE_ROSTER_SUCCESS';
export const CREATE_INVITE_ROSTER_ERROR = 'CREATE_INVITE_ROSTER_ERROR';

export const START_CREATE_INVITE = 'START_CREATE_INVITE';
export const CREATE_INVITE_SUCCESS = 'CREATE_INVITE_SUCCESS';
export const CREATE_INVITE_ERROR = 'CREATE_INVITE_ERROR';

export const START_GET_COMM_INVITES = 'START_GET_COMM_INVITES';
export const GET_COMM_INVITES_SUCCESS = 'GET_COMM_INVITES_SUCCESS';
export const GET_COMM_INVITES_ERROR = 'GET_COMM_INVITES_ERROR';

export const LOGOUT_INVITES = 'LOGOUT_INVITES';