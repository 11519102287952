import React from "react";
import "../../containers/SetupSchool/styles.scoped.css";

export interface Tab {
  id: number;
  label: string;
}

interface TabsProps {
  tabs: Tab[];
  activeTab: number;
  setActiveTab: (activeTab: number) => void;
}

const Tabs = ({ tabs, activeTab, setActiveTab }: TabsProps) => (
  <div className="nav">
    {tabs.map((tab: Tab) => (
      <div
        className={activeTab == tab.id ? "active" : ""}
        onClick={() => setActiveTab(tab.id)}
        key={tab.id}
      >
        {tab.label}
      </div>
    ))}
  </div>
);

export default Tabs;
