import { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faComment } from "@fortawesome/pro-solid-svg-icons";
import { WHITE } from "../ClubIconsNavBar";
import './styles.scoped.css'
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { useDispatch, useSelector } from "react-redux";
import { cancelFriendRequest, createFriendRequest, saveNewFriend, rejectFriendRequest } from '../../store/actions/connectionActions';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useGrades from "../../hooks/useGrades";
import { HiOutlineChatAlt2 } from "react-icons/hi";

function Index(props: any) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { grades, setCountry: setCountryGrades } = useGrades();

    const [typeFiter, setTypeFilter] = useState('0');
    const userSelector = useSelector((state: any) => state.getUser.user);
    const userInfo = useSelector((state: any) => state.getUser.userInfo);
    const userAll = useSelector((state: any) => state.getUser.user);
    const userFriends = useSelector((state: any) => state.getConnection.userFriends || []);
    const userConnections = useSelector((state: any) => state.getConnection.userPossibleConnections || []);
    const userPendingRequest = useSelector((state: any) => state.getConnection.userPendingRequests || []);
    const userSentRequest = useSelector((state: any) => state.getConnection.userSentRequests || []);
    const userRoles = useSelector((state: any) => state.getUser.userRoles);
    const organization = useSelector((state: any) => state.getOrgs.organizationInfo);

    const [textSendRequest, setTextSendRequest] = useState<any>(t("button_addFriend"));
    const [textAcceptRequest, setTextAcceptRequest] = useState(false);
    const [user, setUser] = useState(userAll);
    const [listInterest, setListInterest] = useState([]);
    const [otherInfo, setOtherInfo] = useState<any>([]);

    useEffect(() => {
        if (organization && organization?.countryCode) {
            setCountryGrades(organization.countryCode);
        }
    }, [organization]);

    function handleOnClickOk(e: any, type: any) {
        props.onHide(e, type)
    }

    const handleFriendButton = (username: string) => {
        setTypeFilter('4');

        //Is The connection a friend?
        if (userFriends.find((user: any) => user.friendUsername === username)) {
            setTypeFilter('1');
        }
        //Is the connection a pending friend?
        else if (userPendingRequest.find((user: any) => user.usernameFrom === username)) {
            setTypeFilter('2');
        }
        //Is the connection a sent friend?
        else if (userSentRequest.find((user: any) => user.usernameTo === username)) {
            setTypeFilter('3');
        }
        //Is a possible connection?
        else if (userConnections.find((user: any) => user.usernameConnection === username)) {
            setTypeFilter('4');
        }
        //The users cannot be friends
        else {
            setTypeFilter('pass');
        }
    }

    const acceptFriend = () => {
        setTextAcceptRequest(true)
        dispatch<any>(saveNewFriend(userInfo.username, props.info.usernameConnection || ''))
            .then(() => setTypeFilter('1'));
    }

    const rejectFriend = () => {
        dispatch<any>(rejectFriendRequest(props.info.usernameConnection, userInfo.username))
            .then(() => setTypeFilter('4'));
    }

    const cancelRequest = () => {
        dispatch<any>(cancelFriendRequest(userInfo.username, props.info.usernameConnection || ''))
            .then(() => setTypeFilter('4'));
    }

    const newFriendRequest = () => {
        setTextSendRequest('Friend Request Sent')
        dispatch<any>(createFriendRequest(userInfo.username, props.info.usernameConnection || ''))
            .then(() => setTypeFilter('3'));
    }

    const getInterests = (e: any) => {
        Backend(
            MicroServices.Connections,
            `/profile/interests/user?username=${e}`)
            .then((response) => response.json())
            .then((results) => {
                setListInterest(results);
            })
            .catch(console.error);
    }

    const onClickMyConnections = (connection: any) => {
        // setSelectedConnection(connection.usernameConnection);
        if (!props.noRedirect) {
            navigate(`./connections/${connection}`);
            props.onHide('', 'modal')
        }

    }

    function handleOnClickChat(e: any) {
        var username = ''
        if (e.info.friendUsername) {
            username = e.info.friendUsername
        } else if (e.info.usernameFrom) {
            username = e.info.usernameFrom
        } else if (e.info.usernameTo) {
            username = e.info.usernameTo
        } else if (e.info.usernameConnection) {
            username = e.info.usernameConnection
        } else {
            username = e.info.username
        }
        navigate(`/chat/conversation/${username}`);
    }

    useEffect(() => {
        var username = ''
        setListInterest([])
        if (props.info.friendUsername) {
            username = props.info.friendUsername
        } else if (props.info.usernameFrom) {
            username = props.info.usernameFrom
        } else if (props.info.usernameTo) {
            username = props.info.usernameTo
        } else if (props.info.usernameConnection) {
            username = props.info.usernameConnection
        } else {
            username = props.info.username
        }

        if (username) {
            Backend(
                MicroServices.Admin,
                `/users/${username}?roleRequest=OA`)
                .then((response) => response.json())
                .then((results) => {
                    setOtherInfo(results)
                    getInterests(username)
                    handleFriendButton(username);
                })
                .catch(console.error);
        }
    }, [props.info.friendUsername, props.info.usernameConnection, props.info.usernameFrom, props.info.username]);

    return (
        <>
            <Modal className="modalConnection" show={props.show} onHide={props.onHide}>
                <Modal.Body>
                    <FontAwesomeIcon
                        icon={faTimes}
                        size='xl'
                        color={WHITE}
                        className="icon-times"
                        onClick={(e) => handleOnClickOk(e, 'modal')}
                    />
                    <HiOutlineChatAlt2 color={"white"} size="30px" className="icon-comment" onClick={(e) => handleOnClickChat(props)} />
                    <div className="imageProfile">
                        <img className="round-avatar" src={props.info.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" />
                    </div>
                    <div className="nameFriend" onClick={() => onClickMyConnections(props.info.usernameConnection || props.info.friendUsername)}>
                        <h2>{props.info.fullname || `${props.info.firstname} ${props.info.lastname}`}</h2>
                    </div>
                    <div className="nameClass">
                        {otherInfo?.classYear &&
                            grades.find((grade) => grade.id === user.grade + (Number(otherInfo.classYear) - userSelector.classYear))?.communityLabel?.replace('{{grad_year}}', otherInfo.classYear || '') || ''
                        }
                    </div>
                    <div className="infoCommonClub">
                        {props.info.common ?
                            <h2>{`${props.info.common} ${t('label_common')} ${props.info.common > 1 ? t('label_commonClubP') : t('label_commonClubS')}`}</h2>
                            : `0 ${t('label_commonClubP')}`}
                    </div>
                    {listInterest.length > 0 &&
                        <div className="infoTabs">
                            {listInterest.map((tag: any) => (
                                <span>#{tag} </span>
                            ))}
                        </div>
                    }
                    <div className="buttonsConnections">
                        {(typeFiter === '1') &&
                            (<div className="rowButtonFriends buttonFriends">
                                <FontAwesomeIcon
                                    className="iconOptions icon-no-border"
                                    icon="star"
                                    size="sm"
                                    color={WHITE}
                                />
                                <span className="roboto-normal-white-16px">{t('profile__youAreFriends')}</span>
                            </div>
                            )}
                        {(typeFiter === '2') && (
                            <>
                                <button className={textAcceptRequest ? 'buttonFriends' : 'buttonAcceptFriend'} onClick={acceptFriend}>
                                    {!textAcceptRequest ?
                                        t('setupStudent__accept')
                                        :
                                        <div className="rowButtonFriends btnFriendsProfile">
                                            <FontAwesomeIcon
                                                className="iconOptions icon-no-border"
                                                icon="star"
                                                size="sm"
                                                color={WHITE}
                                            />
                                            <span className="roboto-normal-white-16px">{t('profile__youAreFriends')}</span>
                                        </div>
                                    }
                                </button>
                                {!textAcceptRequest &&
                                    <button className='buttonRejectFriend' onClick={rejectFriend}>
                                        {!textAcceptRequest ?
                                            t('setupStudent__reject')
                                            :
                                            <div className="rowButtonFriends btnFriendsProfile">
                                                <FontAwesomeIcon
                                                    className="iconOptions icon-no-border"
                                                    icon="star"
                                                    size="sm"
                                                    color={WHITE}
                                                />
                                                <span className="roboto-normal-white-16px">{t('profile__youAreFriends')}</span>
                                            </div>
                                        }

                                    </button>
                                }
                            </>
                        )}

                        {(typeFiter === '3') && (<button className='buttonCancelRequest' onClick={cancelRequest}>{t('profile__cancelFriendRequest')}</button>)}
                        {(typeFiter === '4') && (!(!userRoles.includes('ZAA#STUDENT') && user.roles.includes('ZAA#STUDENT')) &&
                            <button className='buttonAddRequest' onClick={newFriendRequest}>
                                {textSendRequest}
                            </button>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Index;

function dispatch<T>(arg0: any) {
    throw new Error("Function not implemented.");
}
