import { useState } from 'react'
import "./styles.css"
import { useTranslation } from "react-i18next";

const index = ({ clubType, handleClickTab }: { clubType: string, handleClickTab: any}) => {
    const { t, i18n } = useTranslation();
    const [tabSelected, setTabSelected] = useState(0);

    function handleClickTabClub(e: number){
        handleClickTab(e)
        setTabSelected(e)
    }

    const perfectClubType = () => {
        if(clubType === 'CLUB'){
            return t('clubs__title') 
        }

        if(clubType === 'TEAM'){
            return t('teams__title') 
        }

        return t('comm__title') 
    }

    return (
        <div className="navbar-container">
            <div className={`navbar-section ${tabSelected === 0 && 'tabActiveClub'}`} 
                 onClick={() => handleClickTabClub(0)}
            >
                {`${t('comm__manage')} ${perfectClubType()}`}
                {/* <span className="number">120</span> */}
            </div>
            {/* <div className={`navbar-section ${tabSelected === 1 && 'tabActiveClub'}`} onClick={() => handleClickTabClub(1)}>Club link requests <span className="number">120</span></div> */}
            {(clubType !== 'COMM') && (<div className={`navbar-section ${tabSelected === 2 && 'tabActiveClub'}`} 
                 onClick={() => handleClickTabClub(2)}
            >
                {`${t('comm__add')} ${perfectClubType()}`}
                {/* <span className="number">120</span> */}
            </div>)}
        </div>
    )
};

export default index;