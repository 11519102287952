import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserBirthdayConnections } from "../../store/actions/connectionActions";
import { RootState } from "../../store/store";
import BirthdayTile from "../BirthdayTile/BirthdayTile";
import Property1AdminProperty2NoProperty3W from "../Property1AdminProperty2NoProperty3W";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
import ModalDownloadWallet from "../ModalDownloadWallet/ModalDownloadWallet";
import Notifications from '../Notifications'
import { getNotifications } from "../../store/actions/notificationActions";
import { getBalance } from "../../store/actions/userActions";
import moment from "moment";
import { useTranslation } from "react-i18next";
import 'moment/locale/es';
import { RightPanel } from "../../containers/AppLayout/components";

const RightSection = (props: any) => {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const ahaBalance = useSelector((state: RootState) => state.getUser.GetAhaBalance);
  const ahaBalanceError = useSelector((state: RootState) => state.getUser.GetAhaBalanceError);
  const userInfo = useSelector((state: any) => state.getUser.userInfo);
  const userBirthdayConnections = useSelector((state: RootState) => state.getConnection.userBirthdayConnections || [])
  const userRoles = useSelector((state: any) => state.getUser.userRoles);

  const [birthdayDate, setBirthdayDate] = useState<Date>(new Date())

  const [showModalDownload, setShowModalDownload] = useState(false);

  console.log(userRoles)

  useEffect(() => {
    dispatch(getBalance(userInfo.username))
  }, []);

  useEffect(() => {
    dispatch(getUserBirthdayConnections(userInfo.username,
      { month: birthdayDate.getMonth() + 1, day: birthdayDate.getDate() }));
  }, [birthdayDate])

  function handleCloseNtf() {
    props.handleActiveNotification(false)
  }

  const reloadNotifications = (username: string) => {
    dispatch(getNotifications(username));
  }

  const getDateText = () => {
    const today = new Date()
    const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
    const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
    if (
      birthdayDate.getFullYear() === today.getFullYear() &&
      birthdayDate.getMonth() === today.getMonth() &&
      birthdayDate.getDate() === today.getDate()
    ) {
      return t("hbdToday")
    } else if (
      birthdayDate.getFullYear() === tomorrow.getFullYear() &&
      birthdayDate.getMonth() === tomorrow.getMonth() &&
      birthdayDate.getDate() === tomorrow.getDate()
    ) {
      return t("hbdTomorrow")
    } else if (
      birthdayDate.getFullYear() === yesterday.getFullYear() &&
      birthdayDate.getMonth() === yesterday.getMonth() &&
      birthdayDate.getDate() === yesterday.getDate()
    ) {
      return t("hbdYesterday")
    } else {
      return `${moment(birthdayDate).locale(i18n.language).format('MMMM DD')}`
    }
  };

  return (
    <RightPanel>
      {props.activeNotification ?
        <Notifications
          handleCloseNtf={handleCloseNtf}
          reloadNotifications={reloadNotifications}
        />
        :
        <>
          {
            (userRoles.includes('ZAA#STUDENT')) &&



            (ahaBalance !== undefined && !ahaBalanceError) && (
              <div className="club-right-container pointer" onClick={() => setShowModalDownload(true)}>
                <div className="club-right-title robotocondensed-bold-white-24px">
                  {t("ahaRewardsBalance")}
                </div>
                <div className="club-balance-row">
                  {/* <div className="balance">{ahaProfile.balance.aha_amount + (ahaProfile.balance.aha_amount_decimal === 0 ? '' : `.${ahaProfile.balance.aha_amount_decimal}`)}</div> */}
                  <div className="balance">{ahaBalance}</div>
                  <img className="aha-logo" src={"/aHa-logo_w.png"} alt="aha-logo" />
                </div>

                {/*<div className="club-wallet-row">
          <div className="go-to-wallet robotocondensed-normal-lavender-16px">
            Go to your wallet
          </div>
          <FontAwesomeIcon
            icon="arrow-right"
            size='lg'
            color={LAVENDER}
            className=" "
          />
        </div>*/}

              </div>)}
          <div className="hbdToday">
            <button onClick={() => setBirthdayDate(
              new Date(
                birthdayDate.getFullYear(),
                birthdayDate.getMonth(),
                birthdayDate.getDate() - 1)
            )}>
              <FontAwesomeIcon
                icon="chevron-left"
                size="lg"
                color={LAVENDER}
                className=" "
              />
            </button>
            {getDateText()}
            <button onClick={() => setBirthdayDate(
              new Date(
                birthdayDate.getFullYear(),
                birthdayDate.getMonth(),
                birthdayDate.getDate() + 1)
            )}>
              <FontAwesomeIcon
                icon="chevron-right"
                size="lg"
                color={LAVENDER}
                className=" "
              />
            </button>
          </div>
          {userBirthdayConnections.length ? userBirthdayConnections.map((user: any) =>
            <BirthdayTile
              name={user.firstname}
              lastname={user.lastname}
              avatar={user.avatar}
              username={user.usernameConnection}
              unregistered={user.unregistered}
              email={user.email}
              comm={user.community}
            />
          ) : (
            <div className="club-right-title robotocondensed-bold-white-24px">
              {t("hbdNo")}
            </div>
          )}
          <ModalDownloadWallet show={showModalDownload} onHide={setShowModalDownload} />
        </>
      }
    </RightPanel>
  );
};

export default RightSection;
