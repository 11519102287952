import { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Backend, MicroServices } from "../../../../helpers/backendHelper";
import { getRelativeTime } from "../../../../helpers/chat/timeChatHelper";
import { ChatServiceInterface } from "../../../../interfaces/chat/ChatServiceInterface";
import { Message } from "../../../../interfaces/chat/Message";
import { UserChat } from "../../../../interfaces/chat/UserChat";
import { RootState } from "../../../../store/store";
import { SubtitleCenterChat, TitleCenterChat } from "../NoChatSelected";
import { AhaIncomingMessage, AhaOutgoingMessage, AvatarNoMessages, ChatUserWrapper, IncomingMessage, NoMessagesWrapper, OutgoingMessage } from "./components";

export const maxCharacters = 450;

const ChatUser = ({ ChatServiceInstance }: { ChatServiceInstance: ChatServiceInterface }) => {
    const { t, i18n } = useTranslation();
    const { id, idGroup } = useParams();

    const userInfo = useSelector((state: RootState) => state.getUser.userInfo);
    const organization = useSelector(
        (state: RootState) => state.getOrgs.organizationInfo
    );

    const [userInformation, setUserInformation] = useState<UserChat>({
        fullName: '',
        username: id || '',
        avatar: '',
        status: 'available',
    });

    const chatCenterEndRef: any = useRef(null);

    useEffect(() => {
        chatCenterEndRef.current?.scrollIntoView()
    }, [ChatServiceInstance.activeConversation.messages])

    useEffect(() => {
        chatCenterEndRef.current?.scrollIntoView();
        correctUserInformation();
    }, [])

    useEffect(() => {
        if (id && id !== '' && ChatServiceInstance.chatConnected) ChatServiceInstance.getConversation(id, idGroup)
    }, [id, idGroup, ChatServiceInstance.chatConnected]);

    useEffect(() => {
        correctUserInformation()
    }, [ChatServiceInstance.activeConversation.id, ChatServiceInstance.activeConversation.from.username])

    const correctUserInformation = () => {
        if (!ChatServiceInstance.activeConversation.from.fullName && !idGroup && id) {
            Backend(
                MicroServices.Admin,
                `/users/${id}?roleRequest=OA`)
                .then((response) => response.json())
                .then((results) => {
                    setUserInformation({
                        fullName: `${results.firstname} ${results.lastname}`,
                        username: id || '',
                        avatar: results.avatar || '',
                        status: results.status || 'available'
                    })
                })
                .catch(console.error);
            return;
        }

        if (idGroup) {
            Backend(
                MicroServices.Club,
                `/clubs/${id}?pending=${false}`)
                .then((response) => response.json())
                .then((club) => {
                    setUserInformation({
                        fullName: `${club.name} - ${ChatServiceInstance.activeConversation.from.fullName || ''}`,
                        username: ChatServiceInstance.activeConversation.from.username,
                        avatar: ChatServiceInstance.activeConversation.from.avatar || organization.logo || '',
                        status: ChatServiceInstance.activeConversation.from.status || "available"
                    })
                })
                .catch(console.error)
            return;
        }

        setUserInformation(ChatServiceInstance.activeConversation.from);
    }

    const chatHistory = () => {
        if (userInfo) {
            return ChatServiceInstance.activeConversation.messages.map((message: Message) => {
                
                if (message.from.username === userInfo.username) {
                    return (
                        (message.type !== 'aha') ? (
                            <OutgoingMessage
                                timeName={getRelativeTime(message.id)}
                                textContent={message.content}
                                key={message.id}
                                readMore={message.content.length > maxCharacters}
                            />
                        ) : (
                            <AhaOutgoingMessage
                                timeName={getRelativeTime(message.id)}
                                textContent={message.content}
                                key={message.id}
                                ahaValue={message.ahaValue || 0}
                                readMore={message.content.length > maxCharacters}
                            />
                        ))
                }
                return (
                    (message.type !== 'aha') ? (
                        <IncomingMessage
                            avatar={message.from.avatar}
                            fullName={message.from.fullName}
                            timeName={getRelativeTime(message.id)}
                            textContent={message.content}
                            key={message.id}
                            readMore={message.content.length > maxCharacters}
                        />
                    ) : (
                        <AhaIncomingMessage
                            avatar={message.from.avatar}
                            fullName={message.from.fullName}
                            timeName={getRelativeTime(message.id)}
                            textContent={message.content}
                            key={message.id}
                            ahaValue={message.ahaValue || 0}
                            readMore={message.content.length > maxCharacters}
                        />
                    ))
            })
        }
        return (<></>)
    }

    return (
        <ChatUserWrapper key={ChatServiceInstance.getStringRepresentation()}>
            {(ChatServiceInstance.activeConversation.messages.length === 0) ? (
                <NoMessagesWrapper key={userInformation.username}>
                    <AvatarNoMessages src={userInformation.avatar || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} />
                    <TitleCenterChat>{t('chat_StartConversation')}</TitleCenterChat>
                    <SubtitleCenterChat>{`${t('chat_SayHiTo')} ${userInformation.fullName || t('chat_YourFriends')}`}</SubtitleCenterChat>
                </NoMessagesWrapper>
            ) : (
                chatHistory()
            )}
            <div ref={chatCenterEndRef} />
        </ChatUserWrapper>
    );
}

export default ChatUser;