import { useEffect, useState } from "react";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import INTList from "../../interfaces/Interests/INTLists/INTList";

const useIntListsSA = () => {
    const [intlists, setIntlists] = useState<INTList[]>([]);

    const getAllIntLists = () => {
        Backend(
            MicroServices.Connections,
            `/interests/intlists`
        )
            .then((response) => response.json())
            .then((results: INTList[]) => {
                if (!Array.isArray(results)) {
                    return;
                }
                setIntlists(results);
            })
    };

    useEffect(() => getAllIntLists(), []);

    return {
        intlists,
        reload: getAllIntLists,
    }
}

export default useIntListsSA;