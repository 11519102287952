import { Outlet, useLocation, useRoutes } from "react-router-dom";
import Header from "../../components/Header";
import AdminLeftMenu from "../../components/AdminLeftMenu";

function AdminLayout() {
  const location = useLocation();
  let screen = location.pathname;

  return (
    <div className="container-layout">
      <div className="organizations screen">
        <AdminLeftMenu screen={screen} />
        <Header />
        <Outlet />
      </div>
    </div>
  );
}

export default AdminLayout;
