import { IPrimaryContact } from "./IPrimaryContact";

export default interface OrgUpdateForm {
    name: string;
    type: string;
    website: string;
    contactName: string;
    contactNumber?: number;
    contactNumberExt?: number;
    status: string;
    parentOrganization: string;
    logo: string;
    postalCode: string;
    address: string;
    country: string;
    state: string;
    city: string;
    parentOrgName?: string;
    parentOrgId?: string;
    primaryContact?: IPrimaryContact;
};

export const OrgUpdateFormMock: OrgUpdateForm = {
    name: '',
    type: '',
    website: '',
    contactName: '',
    contactNumber: 0,
    contactNumberExt: 0,
    status: '',
    parentOrganization: '',
    logo: '',
    postalCode: '',
    address: '',
    country: '',
    state: '',
    city: '',
    primaryContact: {
        name: '',
        email: '',
        phoneNumber: 3000000000
    }
};