import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { capitalize } from "../../helpers/letterHelper";
import { inviteRoleZAA, rolesArray } from "../../helpers/roleHelper";
import { LAVENDER } from "../ClubIconsNavBar";
import "./styles.css";

const Index = ({ user }: any) => {

  const navigate = useNavigate();

  let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(user.creationDate) // 01/11/2021

  return (
    <tr>
      <td className="idTable">
        <div className="id robotocondensed-normal-white-16px">
          {user.username}
        </div>
      </td>
      <td className="nameTable">
        <div className="city robotocondensed-normal-white-16px">
          {user.firstname}
        </div>
      </td>
      <td className="lastnameTable">
        <div className="roles robotocondensed-normal-white-16px">
          {user.lastname}
        </div>
      </td>

      <td className="emailTable">
        <div className="roles robotocondensed-normal-white-16px">
          {user.email}
        </div>
      </td>
      <td className="rolesTable">
        <div className="country robotocondensed-normal-white-16px">
          {/* {rolesArray(user.roles)} */}
          <ul className="listUsers">
            {user.roles.map((role: string) => (
              <li>{inviteRoleZAA(role || '')}</li>
            ))}
          </ul>
        </div>
      </td>
      <td className="dateTable">
        <div className="join  robotocondensed-normal-white-16px">
          {date}
        </div>
      </td>
      <td className="statusTable">
        <div className="status robotocondensed-normal-white-16px">
          {capitalize(user.status)}
        </div>
      </td>
      <td className="actionsTable">
        <div className=" actions">
          <FontAwesomeIcon
            icon="eye"
            size='lg'
            color={LAVENDER}
            className="view-user  "
            onClick={() => navigate('./' + user.username + '/view')}
          />
          <FontAwesomeIcon
            icon="pen"
            size='lg'
            color={LAVENDER}
            className="update-user  "
            onClick={() => navigate('./' + user.username + '/update')}
          />
        </div>
      </td>
    </tr>
  )
};

export default Index;