import styled from "styled-components";

const AdminTitle = styled.h1`
  min-height: 28px;
  font-family: var(--font-family-roboto_condensed);
  font-weight: 700;
  color: var(--lavender);
  font-size: var(--font-size-xxxl);
  letter-spacing: 0;
`;

export default AdminTitle;
