import { AnyAction } from "@reduxjs/toolkit";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { 
  GET_TCATEGORIES_ERROR, 
  GET_TCATEGORIES_SUCCESS, 
  START_GET_TCATEGORIES,
  START_GET_CATEGORY,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_ERROR,
  START_UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERROR,
  START_DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_ERROR
} from "../constants/templateCategoryConstants";

export const getTemplateCategories = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({type: START_GET_TCATEGORIES});
    Backend(
      MicroServices.Club,
      `/template-categories`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_TCATEGORIES_SUCCESS,
          payload: results,
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_TCATEGORIES_ERROR,
          payload: error,
        });
      })
  });

export const getCategoryInfo = (id: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({type: START_GET_CATEGORY});
    Backend(
      MicroServices.Club,
      `/template-categories/${id}`)
      .then(async (response) => {
            return response.json()
        })
      .then((results) => {
        dispatch({
          type: GET_CATEGORY_SUCCESS,
          payload: results,
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
            type: GET_CATEGORY_ERROR,
            payload: error,
        })
      })
  });

export const updateCategory =
  (id: string, form: any): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
    new Promise((resolve, reject) => {
      dispatch({ type: START_UPDATE_CATEGORY });
      Backend(
        MicroServices.Club,
        `/template-categories/${id}`, {
        method: "PUT",
        body: JSON.stringify(form),
      })
        .then((response) => response.json())
        .then((results) => {
          dispatch({
            type: UPDATE_CATEGORY_SUCCESS,
            payload: results,
          });
        })
        .catch((error) => {
          dispatch({
            type: UPDATE_CATEGORY_ERROR,
            payload: error,
          });
        });
    });

export const deleteCategory =
    (id: string): ThunkAction<Promise<boolean>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        dispatch({ type: START_DELETE_CATEGORY });
        Backend(
          MicroServices.Club,
          `/template-categories/${id}`, {
          method: "DELETE",
        })
          .then((response) => {
            if (response.status === 204) {
              resolve(true)
            }
            return response.json();
          })
          .then((results) => {
            dispatch({
              type: DELETE_CATEGORY_SUCCESS,
              payload: results,
            });
          })
          .catch((error) => {
            dispatch({
              type: DELETE_CATEGORY_ERROR,
              payload: error,
            });
          });
      });