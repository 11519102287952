
import { useEffect, useState } from "react";
import "./styles.scoped.css";
import { pageSize } from "../Organizations";
import { Backend, MicroServices } from '../../helpers/backendHelper';

const Index = ({ pageSize, page }: { pageSize: pageSize, page: number }) => {

  const [listYoungOrg, setListYoungOrg] = useState<any>([])

  useEffect(() => {
    Backend(
      MicroServices.Admin, `/organizations/yo-list`
    ).then((results) => results.json())
      .then((results) => {
        setListYoungOrg(results)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => console.log('listYoungOrg', listYoungOrg), [listYoungOrg])

  return (
    <>
      <table className="listData tableManageOrganization robotocondensed-bold-white-16px">
        <thead>
          <tr>
            <th>Name</th>
            <th>Website</th>
            <th>International, Global or National</th>
            <th>Genders</th>
            <th>Grade level</th>
            <th>Address</th>
            <th>Setup</th>
          </tr>
        </thead>
        <tbody>
          {listYoungOrg.map((youngOrg: any) => (
            <tr>
              <td>
                {youngOrg.name}
              </td>
              <td>{youngOrg.website}</td>
              <td>{youngOrg.rep}</td>
              <td>
                {youngOrg.genders.join(', ')}
              </td>
              <td>{youngOrg.lowGrade} - {youngOrg.highGrade}</td>
              <td>
                {youngOrg.address && `${youngOrg.address}, `}
                {youngOrg.city && `${youngOrg.city}, `}
                {youngOrg.state && `${youngOrg.state}, `}
                {youngOrg.country && `${youngOrg.country}, `}
                {youngOrg.postalCode && `${youngOrg.postalCode}, `}
              </td>
              <td>{youngOrg.setupComplete ? 'Completed' : 'Not completed'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
};

export default Index;
