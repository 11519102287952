import React, { useState, useEffect } from "react";
import "../../../containers/SetupSchool/styles.scoped.css";
import { Team } from "../ModalTeam";
import useGradesList from "../../../hooks/useGradesList";
import { SelectedTeams } from "../../../containers/SetupSchool";
import { useTranslation } from "react-i18next";
import useGrades from "../../../hooks/useGrades";
import OrganizationComplete from "../../../interfaces/organization/OrganizationComplete";
import { useSelector } from "react-redux";

interface TeamSelectProps {
  team: Team;
  setTeam: (changes: Partial<Team>) => void;
  key: string;
  selectedTeams: SelectedTeams;
  setSelectedTeams: (e: any) => void,
  modalType: 'Edit' | 'Create' | 'SaveEdit';
  dynamoTeams: any[],
  setTeamsEdit: (e: any) => void,
  teamsEdit: { [key: string]: any[] },
  teamsDelete: string[],
  setTeamsDelete: (e: any) => void,
  grades: Grade[],
}

export interface Grade {
  value: string;
  label: string;
  active: boolean;
};

const TeamSelect = ({
  team,
  setTeam,
  selectedTeams,
  setSelectedTeams,
  modalType,
  dynamoTeams,
  setTeamsEdit,
  teamsEdit,
  teamsDelete,
  grades,
  setTeamsDelete }: TeamSelectProps) => {

  const { t, i18n } = useTranslation();

  const [gradesSelect, setGradesSelect] = useState<Grade[]>([])

  useEffect(() => {
    const gradesTeamed: Grade[] = grades.map((grade) => {
      return {
        value: grade.value,
        label: grade.label,
        active: team?.gradesAllowed?.find((gr) => gr.toString() === grade.value) !== undefined
      }
    })
    setGradesSelect([...gradesTeamed]);
  }, [])

  useEffect(() => {
    setTeam({
      gradesAllowed: gradesSelect
        .filter((grade) => grade.active)
        .map((grade) => parseInt(grade.value))
    })
  }, [gradesSelect])

  const toggleActiveTeam = (e: React.MouseEvent<HTMLLabelElement>) => {
    e.preventDefault();
    if (modalType === 'SaveEdit') {
      setTeam({
        active: team.active === null ? false : null,
      })
    } else {
      setTeam({
        active: !team.active,
      })
    }
  }

  const toggleGrade = (gradeLabel: string) => {
    setTeam({
      active: true,
    })
    const gradesCopy = [...gradesSelect];
    const gradeIndex = gradesCopy.findIndex((grade) => grade.label === gradeLabel);
    gradesCopy[gradeIndex] = {
      ...gradesCopy[gradeIndex],
      active: !gradesCopy[gradeIndex].active,
    };
    setGradesSelect(gradesCopy);
  };

  function toggleAllGrade(e: any) {
    if (e.target.checked) {
      setTeam({ active: true, })
      const gradesTeamed: Grade[] = grades.map((grade) => {
        return {
          value: grade.value,
          label: grade.label,
          active: true
        }
      })
      setGradesSelect([...gradesTeamed]);
    } else {
      setTeam({ active: false, })
      const gradesTeamed: Grade[] = grades.map((grade) => {
        return {
          value: grade.value,
          label: grade.label,
          active: false
        }
      })
      setGradesSelect([...gradesTeamed]);
    }
    
    
  }

  useEffect(() => {
    setTeam({
      gradesAllowed: grades
        .filter((grade) => grade.active)
        .map((grade) => parseInt(grade.value))
    })
  }, [grades])

  return (
    <div className="container-grades-team">
      <div className="chechbox-input">
        <label
          className="switch"
          onClick={toggleActiveTeam}
        >
          <input type="checkbox" checked={team.active === null ? true : team.active} readOnly />
          <span className="slider round-chechbox"></span>
        </label>
        <span>{t('setupSchool__add')} {team.title}</span>
      </div>
      <p className="subtitle">{t('setupSchool__youCanMark')}.</p>
      <div className="grades-team">
        <h2>{t('myschool__Grades')}</h2>
        <div className="containers-input" key={JSON.stringify(grades)}>
          {gradesSelect.map((grade) => (
            <div className="option-chechbox" key={grade.label}>
              <label className="content-input">
                <input
                  type="checkbox"
                  name="principal-button"
                  value={grade.value}
                  checked={grade.active}
                  onChange={() => toggleGrade(grade.label)}
                />
                <i></i>
              </label>
              <label htmlFor="students">{grade.label}</label>
            </div>
          ))}
          <div className="option-chechbox" key={"All"}>
            <label className="content-input">
              <input
                type="checkbox"
                name="principal-button"
                value={'All'}
                onChange={(e) => toggleAllGrade(e)}
              />
              <i></i>
            </label>
            <label htmlFor="students">All</label>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TeamSelect;
