import React from 'react'
import { useTranslation } from "react-i18next";

const OptionsSort = (props: any) => {
    const { t, i18n } = useTranslation();
    function handleClick(e: any, type: string) {
        props.handleSort(e.target.value, type)
    }

    return (
        <div className="optionsSort">
            <h2>{t('options__sort')}</h2>
            {!props.isEvents &&
                <div className="option-status">
                    <label className="content-input">
                        <input type="radio" name="button" id={'az'} value={'ASC'} onClick={(e) => handleClick(e, 'name')} />
                        <i></i>
                    </label>
                    <label className="labelChechbox" htmlFor="az">{t('options__az')}</label>
                </div>
            }
            {!props.isEvents &&
                <div className="option-status">
                    <label className="content-input">
                        <input type="radio" name="button" id={'za'} value={'DESC'} onClick={(e) => handleClick(e, 'name')} />
                        <i></i>
                    </label>
                    <label className="labelChechbox" htmlFor="za">{t('options__za')}</label>
                </div>
            }
            <div className="option-status">
                <label className="content-input">
                    <input type="radio" name="button" id={'sotn'} value={'ASC'} onClick={(e) => handleClick(e, props.isEvents ? 'event_id': 'club_id')} />
                    <i></i>
                </label>
                <label className="labelChechbox" htmlFor="sotn">{t('options__oldestToNewest')}</label>
            </div>
            <div className="option-status">
                <label className="content-input">
                    <input type="radio" name="button" id={'snto'} value={'DESC'} onClick={(e) => handleClick(e, props.isEvents ? 'event_id': 'club_id')} />
                    <i></i>
                </label>
                <label className="labelChechbox" htmlFor="snto">{t('options__newestToOldest')}</label>
            </div>
            {(!props.isAdmin && !props.isEvents) &&
                <div className="option-status">
                    <label className="content-input">
                        <input type="radio" name="button" id={'scf'} value={'ASC'} onClick={(e) => handleClick(e, 'club_type')} />
                        <i></i>
                    </label>
                    <label className="labelChechbox" htmlFor="scf">{t('options__sortClubs')}</label>
                </div>
            }
            {(!props.isAdmin && !props.isEvents) &&
                <div className="option-status">
                    <label className="content-input">
                        <input type="radio" name="button" id={'stf'} value={'DESC'} onClick={(e) => handleClick(e, 'club_type')} />
                        <i></i>
                    </label>
                    <label className="labelChechbox" htmlFor="stf">{t('options__sortTeams')}</label>
                </div>
            }
        </div>

    )
}

export default OptionsSort