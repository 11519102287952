import "../styles.scoped.css";
import '../styles.css';
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Advisor, CommunityItem, ORDINAL } from "../../SetupSetup/Communities";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import CardCommunity from "./CardCommunity";
import { getOrgCommSetup, getOrgCommunities } from "../../../store/actions/clubActions";
import ClubInterface from "../../../interfaces/ClubExpanded";
import { getStaffOrg } from "../../../helpers/setupHelper";
import { Backend, MicroServices } from "../../../helpers/backendHelper";
import { CommunityYouthItem } from "./CommunitiesYouth";
import OrganizationComplete from "../../../interfaces/organization/OrganizationComplete";
import useGrades from "../../../hooks/useGrades";

function CardCommunities({organization} : {organization: OrganizationComplete}) {
    const tcomms = useSelector((state: RootState) => state.getTClubs.TComm);

    const dispatch = useDispatch();

    const [activeCards, setActiveCards] = useState(false);
    const [list, setList] = useState<CommunityYouthItem[]>([]);
    const [staffMembers, setStaffMembers] = useState([]);
    const [communities, setCommunities] = useState<ClubInterface[]>([]);
    const [loadingCommiunities, setLoadingCommiunities] =useState(false);

    const { grades, setCountry } = useGrades();

    useEffect(() => {
        if (organization && organization?.countryCode) {
            setCountry(organization?.countryCode);
        }
    }, [])

    useEffect(() => {
        if (organization && organization?.countryCode) {
            setCountry(organization?.countryCode);
        }
    }, [organization])

    useEffect(() => console.log(grades), [grades]);
    
    const generateClasses = (lowGrade: number, highGrade: number): CommunityYouthItem[] => {
        const gradesCount = 1 + Number(highGrade) - Number(lowGrade);
        const today = new Date();
        const deadline = new Date(today.getFullYear(), 6, 1); // July the 1st of the current year
        let year = today.getFullYear()
        if (today > deadline) {
            year += 1;
        }
        return Array.from(Array(gradesCount)).map((_, idx) => ({
            id: 100 + idx,
            title: `Class of ${year + idx}`,
            name: grades.find((grade) => grade.id === highGrade - idx)?.communityLabel?.replace('{{grad_year}}', (year + idx).toString()) || '',
            icon: `${highGrade - idx}`,
            desc: `${ORDINAL[highGrade - idx]} grade`,
            btnActive: false,
            active: activeCards,
            advisors: [],
            invitesSend: 0,
            club_id: undefined
        }))
    };

    const generateCommunities = (): CommunityYouthItem[] => {
        return [
            {
                id: 1,
                title: "Staff",
                name: "Staff",
                icon: 'star',
                desc: '',
                btnActive: true,
                active: true,
                invitesSend: 0,
                advisors: [administratorData()],
                club_id: undefined
            },
            ...generateClasses(organization.lowGrade, organization.highGrade),
            {
                id: 201,
                title: "Guardians",
                name: "Guardians",
                icon: 'star',
                desc: '',
                btnActive: false,
                active: activeCards,
                advisors: [],
                invitesSend: 0,
                club_id: undefined
            },
            {
                id: 202,
                title: "Alumni",
                name: "Alumni",
                icon: 'star',
                desc: '',
                btnActive: false,
                active: activeCards,
                advisors: [],
                invitesSend: 0,
                club_id: undefined
            },
        ]
    }

    const administratorData = () => {
        if(organization.orgAdmin && organization.orgAdmin.firstname !== ''){
            return {
                firstName: organization.orgAdmin.firstname,
                lastName: organization.orgAdmin.lastname,
                email: organization.orgAdmin.email,
            }
        }

        if(organization.principal && organization.principal.name !== ''){
            return {
                firstName: organization.principal.name.split(' ')[0],
                lastName: organization.principal.name.split(' ')[1],
                email: organization.principal.email,
            }
        }

        if(organization.primaryContact && organization.primaryContact.name !== ''){
            return {
                firstName: organization.primaryContact.name.split(' ')[0],
                lastName: organization.primaryContact.name.split(' ')[1],
                email: organization.primaryContact.email,
            }
        }

        return {
            firstName: '',
            lastName: '',
            email: organization.created_by || '',
        }
    }

    const getGrade = (community: ClubInterface) => {

        const HG = organization.highGrade || 6
        const CE_month = parseInt(organization.calendarEnd.split("-")[0])
        const CY = community.classYear || 2023
        const now = new Date();
        const now_month = now.getMonth() + 1;
        const now_year = now.getFullYear();
        const month_difference = (Math.abs(CY - now_year) * 12) + Math.abs(CE_month - now_month)
        
        return HG - Math.floor(Math.abs(month_difference) / 12)
        //return (HG - Math.floor(Math.abs(month_difference) / 12)) + 2
        
    }

    const organiceCommunities = (communities: CommunityYouthItem[]) : CommunityYouthItem[] => {
        const organized : CommunityYouthItem[] = [];

        communities.forEach((comm) => {
            if(comm.title === 'Staff'){
                organized[0] = comm;
            }
            else if(comm.title === 'Alumni'){
                organized[communities.length - 1] = comm;
            }
            else if(comm.title === 'Guardians'){
                organized[communities.length - 2] = comm;
            }
            else {
                organized[communities.findIndex((template) => comm.title === template.title)] = comm;
            }
        })

        return organized;
    }

    const generateMissingCommunities = (): CommunityYouthItem[] => {

        const actualCommunities = [...communities];

        return organiceCommunities(actualCommunities.map((community) => ({
            id: 201 + Math.random(),
            title: community.name || '',
            name: community.name,
            icon: (((tcomms.find(({id}) => id === community.template_id)?.name || '').includes('Class')) ? `${getGrade(community)}`: 'star'),
            desc: (((tcomms.find(({id}) => id === community.template_id)?.name || '').includes('Class')) ? `${ORDINAL[getGrade(community)]} grade`: '' ),
            btnActive: true,
            active: true,
            advisors: ((tcomms.find(({name}) => name === 'Staff')?.id === community.template_id) ? [administratorData()
                , ...community.advInvites as Advisor[]]: 
                community.advInvites as Advisor[]),
            invitesSend: ((community.openInvites || 0) > 0) ? community.openInvites || 0 : 0,
            club_id: community.id
        })));

        /*const totalComm = generateCommunities();
        const commToDisplay:CommunityItem[] = [];
        totalComm.forEach((template) => {
            const community = communities.find((comm) => comm.template_id === template.title);
            if(community === undefined){
                commToDisplay.push(template);
            }
            else{
                commToDisplay.push({
                    id: 201 + Math.random(),
                    title: community.template_id || '',
                    icon: 'star', //@todo sorry :D
                    desc: '',
                    btnActive: true,
                    active: activeCards,
                    advisors: community.advInvites as Advisor[],
                    invitesSend: (community.openInvites || 0 > 0) ? true : false,
                    club_id: community.id
                });
            }
        })

        return commToDisplay;*/
    }

    const getCommunities = () => {
        setLoadingCommiunities(true);
        Backend(
            MicroServices.Club,
            `/clubs?pending=${organization.status.startsWith('P')}&org_id=${organization.id}&club_type=COMM`)
            .then((response) => response.json())
            .then(({clubs}) => {
                setCommunities(clubs);
                setLoadingCommiunities(false);
            })
            .catch((error) => {
                console.log(error);
                setLoadingCommiunities(false);
            })
    }

    const createCommunities = () => new Promise<any>((resolve, reject) => {
        Promise.all(
            [...list]
                .filter((item) => communities.findIndex((comm) => comm.template_id === item.title) == -1 && item.title !== 'Staff')
                .map((item) => ({
                    "org_id": organization?.id,
                    "template_id": tcomms.find(({name}) => name === item.title)?.id || '',
                    "status": organization?.status.startsWith('PEND') ? 'PENDING' : 'ACTIVE',
                    "member_type": (item.title as string).startsWith('Class') ? ["STUDENT"] : (item.title as string).startsWith('Gua') ? ['GUARDIAN'] : ["ALUMNI"],
                    "name": item.name,
                    "gendersAllowed": ["M", "F", "O", "P"],
                }))
                .map((body) => Backend(
                    MicroServices.Club,
                    `/clubs?pending=${organization?.status.startsWith('PEND')}`,
                    {
                        method: 'POST',
                        body: JSON.stringify(body),
                    }
                ))
        )
            .then((responses) => {
                resolve(responses);
                console.log(responses);
                getCommunities();
            })
            .catch(reject)
    })

    const reloadStaff = () => {
        getStaffOrg(organization, setStaffMembers);
    }
       
    useEffect(() => {
        getCommunities();
    }, [organization]);

    useEffect(() => {
        if(communities.length === 0){
            setList(generateCommunities());
        }
        else{
            reloadStaff();
            setList(generateMissingCommunities())
        }
    }, [communities, grades])

    return (
        <>

            <div className="container-school-communities">
                <h1 className="school-name-card">
                    {organization.name || ''}
                </h1>
                {(loadingCommiunities)&&(<div className="icon-spinner-third buttonSendSignUp spinner-centered-youth" />)}
                <div className="communities-school">
                    {list.map((card) => (
                        <CardCommunity
                            community={card}
                            organization={organization}
                            staffMembers={staffMembers}
                            createCommunities={createCommunities}
                            reloadStaff={reloadStaff}
                            getCommunities={getCommunities}
                        />
                    ))}
                </div>
                
            </div>

        </>

    );
}

export default CardCommunities;