import React, { useState } from 'react'
import './styles.scoped.css';

const GridImages = (props: any) => {

    return (
        <div className=''>
            {props.images.length === 1 &&
                <div className='oneImages'>
                    {props.images.map((request: any, index: any) => (
                        <div className='column'>
                            <div key={index} className='contImages'>
                                <img className=""
                                    src={props.post === 'create' ? URL.createObjectURL(request) : request.link}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            }
            {props.images.length === 2 ?
                <div className='twoImages'>
                    {props.images.map((request: any, index: any) => (
                        <div className='column'>
                            <div key={index} className='contImages'>
                                <img className=""
                                    src={props.post === 'create' ? URL.createObjectURL(request) : request.link}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                :
                (props.images.length >= 3 && props.images.length < 5) ?
                    <div className='threeImages'>
                        <div className='column'>
                            <div key={0} className='contImages'>
                                <img className=""
                                    src={props.post === 'create' ? URL.createObjectURL(props.images[0]) : props.images[0].link}
                                />
                            </div>
                        </div>
                        <div className='column col2'>
                            {props.images.map((request: any, index: any) => (
                                index !== 0 &&
                                <div key={index} className='contImages'>
                                    <img className=""
                                        src={props.post === 'create' ? URL.createObjectURL(request) : request.link}
                                    />
                                </div>
                            ))}
                        </div>

                    </div>
                    : props.images.length >= 5 ?
                        <div className='moreImages'>
                            <div className='column'>
                                <div key={0} className='contImages'>
                                    <img className=""
                                        src={props.post === 'create' ? URL.createObjectURL(props.images[0]) : props.images[0].link}
                                    />
                                </div>
                                <div key={1} className='contImages'>
                                    <img className=""
                                        src={props.post === 'create' ? URL.createObjectURL(props.images[1]) : props.images[1].link}
                                    />
                                </div>
                            </div>
                            <div className='column col2'>
                                <div key={2} className='contImages'>
                                    <img className=""
                                        src={props.post === 'create' ? URL.createObjectURL(props.images[2]) : props.images[2].link}
                                    />
                                </div>
                                <div key={3} className='contImages'>
                                    <img className=""
                                        src={props.post === 'create' ? URL.createObjectURL(props.images[3]) : props.images[3].link}
                                    />
                                </div>
                                <div key={4} className='contImages lastImage'>
                                    {props.images.length > 5 &&
                                        <p>+{props.images.length - 5}</p>
                                    }
                                    <img className=""
                                        src={props.post === 'create' ? URL.createObjectURL(props.images[4]) : props.images[4].link}
                                    />
                                </div>
                            </div>
                        </div>
                        :
                        ''
            }
        </div>
    )
}

export default GridImages