import OrganizationComplete from "../interfaces/organization/OrganizationComplete";
import { Backend, MicroServices } from "./backendHelper";

const administratorData = (organization: OrganizationComplete) => {
    if(organization.orgAdmin && organization.orgAdmin.firstname !== ''){
        return {
            firstName: organization.orgAdmin.firstname,
            lastName: organization.orgAdmin.lastname,
            email: organization.orgAdmin.email,
        }
    }

    if(organization.principal && organization.principal.name !== ''){
        return {
            firstName: organization.principal.name.split(' ')[0],
            lastName: organization.principal.name.split(' ')[1],
            email: organization.principal.email,
        }
    }

    if(organization.primaryContact && organization.primaryContact.name !== ''){
        return {
            firstName: organization.primaryContact.name.split(' ')[0],
            lastName: organization.primaryContact.name.split(' ')[1],
            email: organization.primaryContact.email,
        }
    }

    return {
        firstName: '',
        lastName: '',
        email: organization.created_by || '',
    }
}

export const getStaffOrg = (organization: OrganizationComplete, setList: any) => { 
  Backend(
    MicroServices.Invite,
    `/invites?pending=${organization.status.startsWith("P") ? "true" : "false"
    }&org_id=${organization.id}&role=ZAA%23STAFF`
  )
    .then((response) => response.json())
    .then((results) => {
      setList([administratorData(organization), ...results.map((invite: any) => {
        return {
          email: invite.email,
          name: invite.firstName,
          lastname: invite.lastName,
        };
      })]);
    })
    .catch((error) => {
      console.log(`Error getting staff members of org ${organization.id}`)
    });
}