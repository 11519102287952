import "../styles.scoped.css";
import '../styles.css';
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import OrganizationComplete from "../../../interfaces/organization/OrganizationComplete";
import CardSchool from "./cards/CardSchool";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE } from "../../ClubIconsNavBar";
import ModalAddSchool from "./modals/ModalAddSchool";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { getSchoolsYouth } from "../../../store/actions/orgsActions";
import { getTemplateClubs } from "../../../store/actions/templateClubActions";

function SchoolsYouth(props: any) {

    const dispatch = useDispatch();

    const [skip, setSkip] = useState(true);
    const [showCreateSchool,setShowCreateSchool] = useState(false);

    const schools = useSelector((state: RootState) => state.getOrgs.schoolsYouth);
    const loadingSchools = useSelector((state: RootState) => state.getOrgs.loadingSchoolsYouth);

    const organization = useSelector(
        (state: any) => state.getOrgs.organizationInfo
    );

    useEffect(() => {
        dispatch(getTemplateClubs(organization.id));
    }, [])
    

    return (
        <>
            <div className="container-myschool content-MySchool">
                <h1>Zaahah</h1>
                <span>Add schools supported by {organization?.name || 'your Youth Organization'}.</span>
                <div className="btnWrapper">
                    {skip ? (
                        <button
                            className="button-skip"
                            onClick={(e) => props.handleOnClick(e, 5)}>
                            Skip
                        </button>
                    ) : (
                        <>
                            <button
                                name="btn-Step2-Save"
                                className="button-save"
                                onClick={(e) => props.handleOnClick(e, 5)}>
                                Continue
                            </button>
                        </>
                    )}
                </div>
            </div>

            {(loadingSchools)&&(<div className="icon-spinner-third buttonSendSignUp spinner-centered-youth" />)}

            <div className="schools">
                {schools.map((school:any) => (<CardSchool school={school}/>))}

                <div className="createSchool-card plus"
                     onClick={() => {
                            setShowCreateSchool(true);
                            setSkip(false);
                        }}
                >
                    <FontAwesomeIcon
                        icon="plus"
                        size='lg'
                        color={WHITE}
                    />
                </div>
            </div>

            <ModalAddSchool show={showCreateSchool} setShow={setShowCreateSchool}/>

        </>

    );
}

export default SchoolsYouth;
