import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { store } from "../store";
import {
  START_GET_TEMPLATE_CLUB,
  GET_TEMPLATE_CLUB_SUCCESS,
  GET_TEMPLATE_CLUB_ERROR,
  START_GET_TEMPLATE_CLUB_LIMIT,
  GET_TEMPLATE_CLUB__LIMIT_ERROR,
  GET_TEMPLATE_CLUB__LIMIT_SUCCESS,
  START_GET_TEMPLATE_CLUB_INFO,
  GET_TEMPLATE_CLUB_INFO_SUCCESS,
  GET_TEMPLATE_CLUB_INFO_ERROR,
  START_UPDATE_TCLUB,
  UPDATE_TCLUB_SUCCESS,
  UPDATE_TCLUB_ERROR
} from "../constants/clubConstants";
import geTemplateClubsParams from "../../interfaces/template-clubs/getTemplateClubParams";
import { GET_GROUPED_TCLUB_ERROR, GET_GROUPED_TCLUB_SUCCESS, GET_TEAMS_ERROR, GET_TEAMS_SUCCESS, START_GET_GROUPED_TCLUB, START_GET_TEAMS } from "../constants/templateClubConstants";
import { Backend, MicroServices } from "../../helpers/backendHelper";

export const getTemplateClubs =
  (yoId?: string | undefined): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      dispatch({ type: START_GET_TEMPLATE_CLUB });
      Backend(
        MicroServices.Club,
        `/template-clubs${yoId ? `?yoId=${yoId}` : ''}`)
        .then((r) => r.json())

        .then((results) => {
          dispatch({
            type: GET_TEMPLATE_CLUB_SUCCESS,
            payload: results,
          });
        })

        .catch((error) => {
          dispatch({
            type: GET_TEMPLATE_CLUB_ERROR,
            payload: error,
          });
        });
    };

export const getTemplateClubsWithLimit =
  ({
    page = 0,
    lastEvaluatedKey = "",
    name = "",
    category = "",
    logo = "",
    limit = 10000,
  }: geTemplateClubsParams): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
      new Promise((resolve, reject) => {
        let params = "";
        params += page === 0 ? "" : `&lastEvaluatedKey=${lastEvaluatedKey}`;
        params += name === "" ? "" : `&name=${name}`;
        params += category === "" ? "" : `&category=${category}`;
        params += logo === "" ? "" : `&logo=${logo}`;
        dispatch({ type: START_GET_TEMPLATE_CLUB_LIMIT });
        Backend(
          MicroServices.Club,
          `/template-clubs?limit=${limit}${params}`)
          .then((response) => response.json())
          .then((results) => {
            dispatch({
              type: GET_TEMPLATE_CLUB__LIMIT_SUCCESS,
              payload: {
                templateClubsLimit: results,
                lastEvaluatedKey: results.lastEvaluatedKey,
                page,
              },
            });
          })
          .catch((error) => {
            dispatch({
              type: GET_TEMPLATE_CLUB__LIMIT_ERROR,
              payload: error,
            });
          });
      });


export const getTemplateClubsGrouped = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({ type: START_GET_GROUPED_TCLUB });
    Backend(
      MicroServices.Club,
      `/template-categories/clubs`)
      .then((response) => response.json())
      .then((results) => {
        const cleanedTemplates = Object.keys(results).reduce((acc: any, key: string) => {
          if (results[key].templateClubs.length > 0 && (key !== 'Suggested Clubs' && key !== 'Intramurals')) {
            return {
              ...acc,
              [key]: results[key],
            }
          }
          return acc
        }, {})
        cleanedTemplates['All'] = {
          name: 'All',
          filterType: 'ALL',
          templateClubs: Object.entries(cleanedTemplates).reduce((acc: any[], curr: any) => {
            return [
              ...acc,
              ...curr[1].templateClubs,
            ]
          }, [])
        }
        //console.log('cleanedTemplates', cleanedTemplates);
        
        dispatch({
          type: GET_GROUPED_TCLUB_SUCCESS,
          payload: cleanedTemplates,
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_GROUPED_TCLUB_ERROR,
          payload: error,
        });
      })
  });


export const getTeams = (): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({type: START_GET_TEAMS});
    Backend(
      MicroServices.Club,
      `/template-clubs/teams`)
      .then((response) => response.json())
      .then((results) => {
        dispatch({
          type: GET_TEAMS_SUCCESS,
          payload: results,
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_TEAMS_ERROR,
          payload: error,
        });
      })
  });

export const getTemplateClubInfo = (id: string): ThunkAction<Promise<any>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => new Promise((resolve, reject) => {
    dispatch({type: START_GET_TEMPLATE_CLUB_INFO});
    Backend(
      MicroServices.Club,
      `/template-clubs/${id}`)
      .then(async (response) => {
            return response.json()
        })
      .then((results) => {
        dispatch({
          type: GET_TEMPLATE_CLUB_INFO_SUCCESS,
          payload: results,
        })
        resolve(results)
      })
      .catch((error) => {
        dispatch({
            type: GET_TEMPLATE_CLUB_INFO_ERROR,
            payload: error,
        })
      })
  });

export const updateTClub =
  (id: string, form: any): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
    new Promise((resolve, reject) => {
      dispatch({ type: START_UPDATE_TCLUB });
      Backend(
        MicroServices.Club,
        `/template-club/${id}`, {
        method: "PUT",
        body: JSON.stringify(form),
      })
        .then((response) => response.json())
        .then((results) => {
          dispatch({
            type: UPDATE_TCLUB_SUCCESS,
            payload: results,
          });
        })
        .catch((error) => {
          dispatch({
            type: UPDATE_TCLUB_ERROR,
            payload: error,
          });
        });
    });