import { useEffect, useMemo, useState } from "react";
import { Backend, MicroServices } from "../helpers/backendHelper";

export interface Country {
    location: string;
    active: boolean;
    name: string;
}

const useCountriesSA = (pageSizeProp: number) => {
    const [pageSize, setPageSize] = useState(pageSizeProp);
    const [countries, setCountries] = useState<Country[]>([]);

    const countryPages: Country[][] = useMemo(() => {
        const pages: Country[][] = [];

        for (let i = 0; i < countries.length; i += pageSize) {
            pages.push(countries.slice(i, i + pageSize));
        }

        if (pages.length === 0) {
            pages.push([]);
        }

        return pages;
    }, [pageSize, countries]);

    const reloadCountries = () => {
        Backend(MicroServices.Roster, `/location/countries`)
            .then((response) => response.json())
            .then((results) => {
                if (!Array.isArray(results)) {
                    return;
                }
                setCountries(results);
            });
    };

    useEffect(() => {
        reloadCountries();
    }, []);

    return { countries, setPageSize, countryPages, reloadCountries };
};

export default useCountriesSA;
