import {AnyAction} from 'redux';
import {
  START_GET_EVENT,
  GET_EVENT_SUCCESS,
  GET_EVENT_ERROR,

  START_GET_MY_EVENT,
  GET_MY_EVENT_SUCCESS,
  GET_MY_EVENT_ERROR,

  SAVE_EVENT_ERROR,
  SAVE_EVENT_SUCCESS,
  START_SAVE_EVENT,

  START_GET_CHECK_EVENT,
  GET_CHECK_EVENT_SUCCESS,
  GET_CHECK_EVENT_ERROR,
  LOGOUT_EVENT,
  START_GET_COMPLETE_EVENTS,
  GET_COMPLETE_EVENTS_ERROR,
  GET_COMPLETE_EVENTS_SUCCESS,
  GET_MY_EVENTS_RSVP_ERROR,
  GET_MY_EVENTS_RSVP_SUCCESS,
  GET_MY_FRIENDS_EVENTS_ERROR,
  GET_MY_FRIENDS_EVENTS_SUCCESS,
  START_GET_MY_EVENTS_RSVP,
  START_GET_MY_FRIENDS_EVENTS,
} from "../constants/eventConstants";
import EventStateInterface from "../../interfaces/store/eventState";

const initialState: EventStateInterface = {
  event: [],
  loadingEvent: false,

  loadingSaveEvent: false,
  SaveEventError: undefined,
  SaveEvent: undefined,

  loadingGetCompleteEvents: false,
  getCompleteEventsError: undefined,
  getCompleteEvents: undefined,

  loadingMyEvent: false,
  myEventError: undefined,
  myEvent: undefined,

  loadingCheckEvent: false,
  checkEventError: undefined,
  checkEvent: undefined,

  loadingGetMyFriendsEvents: false,
  getMyFriendsEventsError: undefined,
  getMyFriendsEvents: undefined,

  loadingGetMyEventsRSVP: false,
  getMyEventsRSVPError: undefined,
  getMyEventsRSVP: undefined,
};

export const getEventReducer = (state = initialState, action: AnyAction) : EventStateInterface => {
  switch (action.type) {
    case START_SAVE_EVENT:
        return {
            ...state,
            loadingSaveEvent: true,
        }
    case SAVE_EVENT_SUCCESS:
        return {
            ...state,
            loadingSaveEvent: false,
            SaveEvent: action.payload,
        }
    case SAVE_EVENT_ERROR:
        return {
            ...state,
            loadingSaveEvent: false,
            SaveEventError: action.payload,
        }


    case START_GET_EVENT:
      return {
        ...state,
        loadingEvent: true,
      };
    case GET_EVENT_SUCCESS:
      return {
        ...state,
        event: action.payload,
        loadingEvent: false,
      };
    case GET_EVENT_ERROR:
      return {
        ...state,
        eventError: action.payload,
        loadingEvent: false,
      };   
    case START_GET_MY_EVENT:
      return {
        ...state,
        loadingMyEvent: true,
      };
    case GET_MY_EVENT_SUCCESS:
      return {
        ...state,
        myEvent: action.payload,
        loadingMyEvent: false,
      };
    case GET_MY_EVENT_ERROR:
      return {
        ...state,
        myEventError: action.payload,
        loadingMyEvent: false,
      };
    case START_GET_CHECK_EVENT:
      return {
        ...state,
        loadingCheckEvent: true,
      };
    case GET_CHECK_EVENT_SUCCESS:
      return {
        ...state,
        checkEvent: action.payload,
        loadingCheckEvent: false,
      };
    case GET_CHECK_EVENT_ERROR:
      return {
        ...state,
        checkEventError: action.payload,
        loadingCheckEvent: false,
      }; 
      
    case START_GET_COMPLETE_EVENTS:
      return {
        ...state,
        loadingGetCompleteEvents: true,
      };
    case GET_COMPLETE_EVENTS_SUCCESS:
      return {
        ...state,
        getCompleteEvents: action.payload,
        loadingGetCompleteEvents: false,
      };
    case GET_COMPLETE_EVENTS_ERROR:
      return {
        ...state,
        getCompleteEventsError: action.payload,
        loadingGetCompleteEvents: false,
      }; 

    case START_GET_MY_FRIENDS_EVENTS:
      return {
        ...state,
        loadingGetMyFriendsEvents: true,
      };
    case GET_MY_FRIENDS_EVENTS_SUCCESS:
      return {
        ...state,
        getMyFriendsEvents: action.payload,
        loadingGetMyFriendsEvents: false,
      };
    case GET_MY_FRIENDS_EVENTS_ERROR:
      return {
        ...state,
        getMyFriendsEventsError: action.payload,
        loadingGetMyFriendsEvents: false,
      }; 

    case START_GET_MY_EVENTS_RSVP:
      return {
        ...state,
        loadingGetMyEventsRSVP: true,
      };
    case GET_MY_EVENTS_RSVP_SUCCESS:
      return {
        ...state,
        getMyEventsRSVP: action.payload,
        loadingGetMyEventsRSVP: false,
      };
    case GET_MY_EVENTS_RSVP_ERROR:
      return {
        ...state,
        getMyEventsRSVPError: action.payload,
        loadingGetMyEventsRSVP: false,
      }; 

    case LOGOUT_EVENT:
      return initialState;

    default:
      return state;
  }
};
