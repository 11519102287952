import "../../containers/SetupSchool/styles.scoped.css";
import { useState, useEffect } from "react";
import CardCommunity from "./CardCommunity";
import ModalInviteMembers from './ModalInviteMembers';
import ModalAddAdvisor from './ModalAddAdvisor';
import OrganizationComplete from "../../interfaces/organization/OrganizationComplete";
import { UserResponse } from "../../interfaces/user/UserResponse";
import { useDispatch, useSelector } from "react-redux";
import { getOrgCommunities } from "../../store/actions/clubActions";
import { RootState } from "../../store/store";
import { createInvite } from "../../store/actions/inviteActions";
import { Principal } from "../../containers/SetupSchool";
import ModalLoading from "./ModalLoading";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import useGrades from "../../hooks/useGrades";
import SelectItem from "../../interfaces/SelectItem";

export const ORDINAL: { [key: number]: string } = {
    5: 'Fifth',
    6: 'Sixth',
    7: 'Seventh',
    8: 'Eighth',
    9: 'Ninth',
    10: 'Tenth',
    11: 'Eleventh',
    12: 'Twelfth',
    13: 'Thirteenth',
    14: 'Fourteenth',
    15: 'Fifteenth',
}

export interface Advisor {
    firstName: string;
    lastName: string;
    email: string;
}

export interface CommunityItem {
    id: number;
    title: string;
    name: string;
    icon: string;
    desc: string;
    btnActive: boolean;
    active: boolean;
    advisors: Advisor[],
    invitesSend: boolean;
    club_id?: string;
    template_id: string;
}

interface CommunitiesProps {
    organization?: OrganizationComplete,
    user?: UserResponse,
    handleOnClick: (e: any, step: number) => void,
    updateCommInvites: () => void,
    listAdvisor: (e: any) => void,
    listadvisors: CommunityItem[],
    principal: Principal,
    setPrincipal: (e: any) => void,
}

function Communities(props: CommunitiesProps) {
    const { t, i18n } = useTranslation();
    const { organization, user, updateCommInvites, listAdvisor, listadvisors, principal, setPrincipal } = props;
    const [namePrincipal, setNamePrincipal] = useState(principal.name || '');
    const [lastnamePrincipal, setLastNamePrincipal] = useState(principal.lastname || '');
    const [emailPrincipal, setEmailPrincipal] = useState(principal.email || '');
    const [activeCards, setActiveCards] = useState(false);
    const [idCard, setIdCard] = useState('');
    const [typeCard, setTypeCard] = useState('');
    const [nameCard, setNameCard] = useState('');
    const [advisors, setAdvisors] = useState<Advisor[]>([]);
    const [list, setList] = useState<CommunityItem[]>([]);
    const [skip, setSkip] = useState(true);
    const [isInfoSaved, setIsInfoSaved] = useState(false);
    const [loadingAdvisor, setLoadingAdvisor] = useState(false)
    const [commsInivtedAdvisors, setCommsInvitedAdvisors] = useState<{ [comm_id: string]: { email: string, firstName: string, lastName: string }[] }>({})
    const [addedAdvisors, setAddedAdvisors] = useState<string[]>([])
    const invitesCount = useSelector((state:RootState)=>state.getInvites.communityInvites?.invitesCount || {})
    const [loading,setLoading] = useState<boolean>(true)

    const communities: any[] = useSelector((state: RootState) => state.getClub.GetComm);
    const tcomms = useSelector((state: RootState) => state.getTClubs.TComm);
    const getCommIndex = (title:string)=>{
        return (communities || []).findIndex((comm) => comm.template_id === title)
    }

    const dispatch = useDispatch();

    const getSavedInfo = () => {
        if (organization) {
            Backend(
                MicroServices.Admin,
                `/organizations/${organization.id}/saved-info?type=COMM`)
                .then((response) => response.json())
                .then((results) => {
                    setCommsInvitedAdvisors(results)
                    if(Object.keys(results).length !== 0){
                        setIsInfoSaved(true);
                    }
                })
                .catch(()=>toast.error(t('toast_errorLoadingSaved')))
                .finally(()=>setLoading(false))
        }
    };

    const { grades, setCountry } = useGrades();
    
    useEffect(() => {
        if (organization && organization?.countryCode) {
            setCountry(organization?.countryCode);
        }
    }, [])

    useEffect(() => {
        if (organization && organization?.countryCode) {
            setCountry(organization?.countryCode);
        }
    }, [organization])

    useEffect(() => {
        const listCopy = [...list]
        listCopy.forEach((card) => {
            card.advisors = commsInivtedAdvisors[card.club_id || ''] || []
        })
        setList(listCopy)
    }, [commsInivtedAdvisors])

    useEffect(() => {
        props.updateCommInvites()
        getSavedInfo()
        setList(listadvisors)
    }, []);

    useEffect(() => {
        setNamePrincipal(user?.firstname || principal.name || '');
        setLastNamePrincipal(user?.lastname || principal.lastname || '');
        setEmailPrincipal(user?.email || principal.email || '');
    }, [user]);

    const generateCommunities = (lowGrade: number, highGrade: number): CommunityItem[] => {
        const gradesCount = 1 + Number(highGrade) - Number(lowGrade);
        const today = new Date();
        const deadline = new Date(today.getFullYear(), 6, 1); // July the 1st of the current year
        let year = today.getFullYear()
        if (today > deadline) {
            year += 1;
        }
        return Array.from(Array(gradesCount)).map((_, idx) => {
            const tcommId = tcomms.find(({name}) => name === `Class of ${year + idx}`)?.id || '';
            return ({
                id: 100 + idx,
                title: `Class of ${year + idx}`,
                name: grades.find((grade) => grade.id === highGrade - idx)?.communityLabel?.replace('{{grad_year}}', (year + idx).toString()) || '',
                icon: `${highGrade - idx}`,
                desc: `${ORDINAL[highGrade - idx]} grade`,
                btnActive: false,
                active: activeCards,
                advisors: [],
                invitesSend: false,
                club_id: getCommIndex(tcommId) !== -1 ? communities[getCommIndex(tcommId)]?.id : undefined,
                template_id: tcommId,
            })
        })
    };

    useEffect(() => {
        if (organization && namePrincipal && list.length === 0 && grades.length > 0) {
            dispatch<any>(getOrgCommunities(organization.id, organization.status.startsWith('PEND')));
            const communitiesList = generateCommunities(organization.lowGrade, organization.highGrade);
            console.log('communitiesList', communitiesList);
            
            setList([
                {
                    id: 1,
                    title: "Staff",
                    name: "Staff",
                    icon: 'star',
                    desc: '',
                    btnActive: true,
                    active: true,
                    invitesSend: false,
                    advisors: [
                        {
                            firstName: namePrincipal,
                            lastName: lastnamePrincipal,
                            email: emailPrincipal,
                        },
                    ],
                    club_id: getCommIndex(tcomms.find(({name}) => name === "Staff")?.id || '') !== -1 ? communities[getCommIndex(tcomms.find(({name}) => name === "Staff")?.id || '')]?.id : undefined,
                    template_id: tcomms.find(({name}) => name === "Staff")?.id || '',
                },
                ...communitiesList,
                {
                    id: 201,
                    title: "Guardians",
                    name: "Guardians",
                    icon: 'star',
                    desc: '',
                    btnActive: false,
                    active: activeCards,
                    advisors: [],
                    invitesSend: false,
                    club_id: getCommIndex(tcomms.find(({name}) => name === "Guardians")?.id || '') !== -1 ? communities[getCommIndex(tcomms.find(({name}) => name === "Guardians")?.id || '')]?.id : undefined,
                    template_id: tcomms.find(({name}) => name === "Guardians")?.id || '',
                },
                {
                    id: 202,
                    title: "Alumni",
                    name: "Alumni",
                    icon: 'star',
                    desc: '',
                    btnActive: false,
                    active: activeCards,
                    advisors: [],
                    invitesSend: false,
                    club_id: getCommIndex(tcomms.find(({name}) => name === "Alumni")?.id || '') !== -1 ? communities[getCommIndex(tcomms.find(({name}) => name === "Alumni")?.id || '')]?.id : undefined,
                    template_id: tcomms.find(({name}) => name === "Alumni")?.id || '',
                },
            ])
        }
    }, [organization, namePrincipal, communities, grades, tcomms]);

    useEffect(() => {
        if (list.length > 0) {
            setList(list.map((commCard) => {
                return {
                    ...commCard,
                    name: communities[getCommIndex(commCard.template_id)]?.name.length > 0 ? communities[getCommIndex(commCard.template_id)]?.name : commCard.name,
                    active: commCard.title === 'Staff' || getCommIndex(commCard.template_id) !== -1,
                    club_id: getCommIndex(commCard.template_id) !== -1 ? communities[getCommIndex(commCard.template_id)]?.id : undefined
                }
            }))
        }
    }, [communities])

    const [showInvite, setShowInvite] = useState(false);

    const createCommunities = () => {
        Promise.all(
            [...list]
                .filter((item) => communities.findIndex((comm) => comm.template_id === item.template_id) == -1 && item.title !== 'Staff')
                .map((item) => ({
                    "org_id": organization?.id,
                    "template_id": item.template_id,
                    "status": organization?.status.startsWith('PEND') ? 'PENDING' : 'ACTIVE',
                    "member_type": (item.title as string).startsWith('Class') ? ["STUDENT"] : (item.title as string).startsWith('Gua') ? ['GUARDIAN'] : ["ALUMNI"],
                    "name": item.name,
                    "gendersAllowed": ["M", "F", "O", "P"],
                }))
                .map((body) => Backend(
                    MicroServices.Club,
                    `/clubs?pending=${organization?.status.startsWith('PEND')}`,
                    {
                        method: 'POST',
                        body: JSON.stringify(body),
                    }
                ))
        )
            .then((responses) => {
                dispatch<any>(getOrgCommunities(organization!.id, organization!.status.startsWith('PEND')));
            })
    }

    const updateBtnActive = (id: any, btnActive: any) => {
        setList(list.map((item) => { if (item.id === id) { item.btnActive = btnActive } return item }).map((item)=>{
            return {
                ...item,
                club_id: getCommIndex(item.template_id) !== -1 ? communities[getCommIndex(item.template_id)]?.id : undefined
            }
        }))
    };

    const updateActiveCards = (active: any) => {
        setList(list.map((item) => { item.active = active; return item }).map((item)=>{
            return {
                ...item,
                club_id: getCommIndex(item.template_id) !== -1 ? communities[getCommIndex(item.template_id)]?.id : undefined
            }
        }))
    };

    function handleClose(e: any, step: any, id: any, type: any, inviteSent = false) {

        getSavedInfo()

        if (type == 'Staff') {
            updateActiveCards(true)
            updateBtnActive(id, true)
        }

        if (step == 'btnSave') {
            setActiveCards(true);
            setList(list.map((item)=>{
                return {
                    ...item,
                    club_id: getCommIndex(item.template_id) !== -1 ? communities[getCommIndex(item.template_id)]?.id : undefined
                }
            }))
        }

        if (inviteSent) {
            for (let comm of list) {
                if (comm.id === id) {
                    comm.invitesSend = true;
                    break;
                }
            }
        }

        setShowInvite(false)
    }

    const handleOnClick = (e: any, step: any, id: any, name: any) => {
        setIdCard(id);
        setShowInvite(true);
        setTypeCard(step);
        setNameCard(name);
        setAdvisors((list.find((item) => item.id === id))?.advisors || [])
    };

    const [showAdvisor, setShowAdvisor] = useState(false);

    function handleCloseAdv(
        e: any,
        step: any,
        id: any,
        type: any,
        firstName?: string,
        lastName?: string,
        email?: string,
        sendEmail?: boolean
    ) {
        if (step === 'btnSave') {
            if (firstName && lastName && email) {
                if (email !== principal.email) {
                    setLoadingAdvisor(true)
                    const community = communities.find((comm) => comm.template_id === tcomms.find(({name}) => name === type)?.id || '')
                    dispatch<any>(createInvite({
                        email,
                        org_id: organization!.id,
                        club_id: community.id,
                        is_advisor: true,
                        firstName,
                        lastName,
                    }))
                        .then(() => {
                            setShowAdvisor(false);
                            setLoadingAdvisor(false);
                        })
                }
                else {
                    setShowAdvisor(false);
                }
                setList(list.map((item) => {
                    if (item.id === id) {
                        item.advisors.push({ firstName, lastName, email })
                    }
                    return item
                }));
                listAdvisor(list)
            }
            updateBtnActive(id, true);
        } else {
            setShowAdvisor(false)
        }
    };

    const handleOnClickAdv = (e: any, step: any, id: any, name: any) => {
        setTypeCard(step);
        setIdCard(id);
        setNameCard(name)
        setShowAdvisor(true)
        setAddedAdvisors((list.find((item) => item.id === id))?.advisors.map((item) => item.email) || [])
    };

    const updateAdvisors = (comm_id: number, advisors: Advisor[]) => {
        const listCopy = [...list]
        listCopy[listCopy.findIndex((item) => item.id === comm_id)].advisors = advisors
        setList(listCopy.map((item)=>{
            return {
                ...item,
                club_id: getCommIndex(item.template_id) !== -1 ? communities[getCommIndex(item.template_id)]?.id : undefined
            }
        }))
    }

    const updateNameOfComm = (name: string, id: number) => {
        const listCopy = [...list];
        const index = listCopy.findIndex((item) => item.id === id);
        if (index !== -1) {
            listCopy[index].name = name;
            setList(() => listCopy)
        }
        setIsInfoSaved(true);
    }

    const saveNames = (e: any) => {
        Promise.all(list.filter((comm) => !!comm.club_id).map((comm) => 
            Backend(
                MicroServices.Club,
                `/clubs/${comm.club_id}`,
                {
                    method: 'PUT',
                    body: JSON.stringify({
                        name: comm.name,
                        ...(organization?.status.startsWith('PE') ? {status: 'PENDING'} : {})
                    })
                }
            )
        ))
            .finally(() => props.handleOnClick(e, 3))
    }

    useEffect(() => console.log('list', list), [list]);

    return (
        <>
            <div className="container-myschool">
                <h1>Zaahah</h1>
                <span>{t('setupSchool__theFollowing')} {organization?.name}. {t('setupSchool__pleaseInvite')}</span>
                <div className="btnWrapper">
                    {(skip && !isInfoSaved) && (
                        <button
                            className="button-skip"
                            onClick={(e) => props.handleOnClick(e, 3)}>
                            {t('setupSchool__skip')}
                        </button>
                    )}

                    {(!skip || isInfoSaved) && (
                        <>
                            <button
                                className="button-skip"
                                onClick={(e) => props.handleOnClick(e, 3)}>
                                {t('setupSchool__discardChanges')}
                            </button>
                            <button
                                name="btn-Step2-Save"
                                className="button-save"
                                onClick={(e) => saveNames(e)}>
                                {t('setupSchool__saveAndContinue')}
                            </button>
                        </>
                    )}
                </div>
            </div>

            <div className="communities-cards">
                {list.map((card) => (
                    <CardCommunity
                        id={card.id}
                        handleOnClick={handleOnClick}
                        handleOnClickAdv={handleOnClickAdv}
                        title={card.title}
                        name={card.name}
                        icon={card.icon}
                        desc={card.desc}
                        active={card.active}
                        advisor={namePrincipal}
                        advisorLastname={lastnamePrincipal}
                        email={emailPrincipal}
                        btnActive={card.advisors.length > 0}
                        key={card.id}
                        advisors={card.advisors}
                        type={typeCard}
                        invitesSend={card.invitesSend}
                        club_id={card.club_id}
                        updateAdvisors={updateAdvisors}
                        principal={principal}
                        updateName={(name: string) => updateNameOfComm(name, card.id)}
                        setPrincipal={setPrincipal}
                        invitesCount={invitesCount[card.club_id || ''] || 0}
                    />
                ))}
            </div>

            <ModalInviteMembers
                show={showInvite}
                onHide={handleClose}
                createCommunities={createCommunities}
                idCard={idCard}
                type={typeCard}
                clubName={nameCard}
                organization={organization}
                advisors={advisors}
                updateCommInvites={updateCommInvites}
                setSkip={setSkip}
                list={list}
            />
            <ModalAddAdvisor
                show={showAdvisor}
                onHide={handleCloseAdv}
                idCard={idCard}
                type={typeCard}
                updateCommInvites={updateCommInvites}
                loadingAdvisor={loadingAdvisor}
                clubName={nameCard}
                addedAdvisors={addedAdvisors}
            />

            <ModalLoading show={loading} message={t('setupSchool__loadingInformation')}/>

        </>

    );
}

export default Communities;
