import React from 'react';
import '../../containers/SetupStudent/styles.scoped.css';

function Index({ text, subtext, step }: { text: string; subtext: string; step: number}) {
  return (
    <div className={`container-aha ${(step === 5) && ("step5")}`}>
      <h1 className="">aha</h1>
      <p dangerouslySetInnerHTML={{ __html: text }}>{}</p>
      <span>{subtext}</span>
    </div>
  );
}

export default Index;
