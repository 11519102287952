import Advisor from "../../interfaces/club/Advisor"
import ClubCreate from "../../interfaces/club/ClubCreate"
import ClubEdit from "../../interfaces/club/ClubEdit"
import { Backend, MicroServices } from "../backendHelper"

export default class ClubSetupHelper {
    public static deleteAdv = (club_id: string, adv: Advisor): Promise<boolean> =>
        new Promise((resolve, reject) => {
            Backend(
                MicroServices.Invite,
                `/invites/kill`,
                {
                    method: "DELETE",
                    body: JSON.stringify({
                        club_id: club_id,
                        email: adv.email
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                .then((response) => {
                    if (response.status === 201) {
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                })
                .catch((error) => {
                    resolve(false)
                    console.log(error)
                })
        })

    public static createAdv = (club_id: string, adv: Advisor, org_id: string): Promise<boolean> =>
        new Promise((resolve, reject) => {
            Backend(
                MicroServices.Invite,
                `/invites`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        email: adv.email,
                        org_id,
                        club_id: club_id,
                        is_advisor: true,
                        firstName: adv.firstName,
                        lastName: adv.lastName,
                    })
                }
            )
                .then((results) => {
                    resolve(results.status === 201)
                })
                .catch((error) => {
                    resolve(false)
                    console.log(error)
                })
        })

    public static updateAdvisors = (club_id: string, prevAdv: Advisor[], newAdv: Advisor[], org_id: string): Promise<boolean> =>
        new Promise((resolve, reject) => {
            const advisorsToDelete = prevAdv.filter((adv) => newAdv.find((item) => adv.email === item.email) === undefined)
            const advisorsToCreate = newAdv.filter((adv) => prevAdv.find((item) => adv.email === item.email) === undefined)
            const promises: Promise<boolean>[] = []

            advisorsToCreate.forEach((adv) => promises.push(ClubSetupHelper.createAdv(club_id, adv, org_id)))
            advisorsToDelete.forEach((adv) => promises.push(ClubSetupHelper.deleteAdv(club_id, adv)))

            Promise.all(promises)
                .then((results) => {
                    resolve(results.reduce((acc, curr) => acc && curr, true))
                })
                .catch((error) => reject(error))
        })

    public static editClub = (club: ClubEdit, org_id: string): Promise<boolean> =>
        new Promise((resolve, reject) => {
            Backend(
                MicroServices.Club,
                `/clubs/${club.id}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        id: club.id,
                        org_id,
                        name: club.name,
                        template_id: club.template_id,
                        member_type: club.member_type,
                        maximumNumberMembers: club.maximumNumberMembers,
                        gendersAllowed: club.gendersAllowed,
                        gradesAllowed: club.gradesAllowed,
                        //advisors: club.advisors.map((item)=>item.email),
                        description: club.description,
                        //created: true,
                        skills_learned: club.skills,
                        participateInternationalCompetitions: club.international,
                        participateNationalCompetitions: club.national,
                        participateRegionalCompetitions: club.regional,
                        participateLocalCompetitions: club.local,
                        applicationRequired: club.applicationRequired,
                        clubDuesRequired: club.clubDuesRequired,
                        meetingDays: club.meetingDays,
                        meetingStartTime: club.meetingStart,
                        meetingEndTime: club.meetingEnd,
                        timezone: club.timezone,
                        meetingFrequency: club.meetingFrequency,
                        meetingLocation: club.meetingLocation,
                        // status: club.status,
                        // privacy: club.privacy,
                    }),
                })
                .then((response) => response.json())
                .then((results) => {
                    resolve(results !== undefined)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })

    public static deleteClub = (club_id: string): Promise<boolean> =>
        new Promise((resolve, reject) => {
            Backend(
                MicroServices.Club,
                `/clubs/${club_id}/kill`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                .then((response) => resolve(response.status === 201))
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })

    public static saveClubAndAdv = (club: ClubCreate, org_id: string, pending: boolean): Promise<boolean> =>
        new Promise((resolve, rejects) => {
            Backend(
                MicroServices.Club,
                `/clubs?pending=${pending}`, {
                method: 'POST',
                body: JSON.stringify({
                    org_id,
                    name: club.name,
                    template_id: club.template_id,
                    member_type: club.member_type,
                    maximumNumberMembers: club.maximumNumberMembers,
                    gendersAllowed: club.gendersAllowed,
                    gradesAllowed: club.gradesAllowed,
                    //advisors: club.advisors.map((item)=>item.email),
                    description: club.description,
                    //created: true,
                    skills_learned: club.skills,
                    participateInternationalCompetitions: club.international,
                    participateNationalCompetitions: club.national,
                    participateRegionalCompetitions: club.regional,
                    participateLocalCompetitions: club.local,
                    applicationRequired: club.applicationRequired,
                    clubDuesRequired: club.clubDuesRequired,
                    meetingDays: club.meetingDays,
                    meetingStartTime: club.meetingStart,
                    meetingEndTime: club.meetingEnd,
                    timezone: club.timezone,
                    meetingFrequency: club.meetingFrequency,
                    meetingLocation: club.meetingLocation,
                    //"status": props?.organization?.status.startsWith('PEND') ? 'PENDING' : 'ACTIVE',
                }),
            })
                .then((results) => results.json())
                .then((results) => {
                    const club_id = results.id
                    const promises: any[] = []
                    club.advisors.forEach((adv) => promises.push(
                        Backend(
                            MicroServices.Invite,
                            `/invites`,
                            {
                                method: 'POST',
                                body: JSON.stringify({
                                    email: adv.email,
                                    org_id,
                                    club_id: club_id,
                                    is_advisor: true,
                                    firstName: adv.firstName,
                                    lastName: adv.lastName,
                                })
                            }
                        )))
                    return Promise.all(promises)
                })
                .then((results) => {
                    resolve(results.reduce((acc, curr) => acc && curr.status === 201, true))
                })
                .catch((error) => {
                    console.log(error)
                    rejects(error)
                })
        })
}