import React, { useEffect, useState } from "react";
import "./styles.scoped.css";
import EventViewInformation from "../../components/EventViewInformation";
import EventPaticipanting from "../../components/EventPaticipanting";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { getEventInfo } from "../../store/actions/eventActions";
import Posts from "../../containers/Posts";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { ChatServiceInterface } from "../../interfaces/chat/ChatServiceInterface";

function Index({ChatServiceInstance}:{ChatServiceInstance:ChatServiceInterface}) {
  const { t, i18n } = useTranslation();
  const { id = '' } = useParams();
  const dispatch = useAppDispatch();

  const [selected, setSelected] = useState("Feed");
  const [screen, setScreen] = useState("Feed");
  const [searchParams, setSearchParams] = useSearchParams();
  const [moderators, setModerators] = useState([]);

  const event = useSelector((state: any) => state.getEvent.event || {});

  const getEventModerators = () => {
    Backend(
      MicroServices.Events,
      `/event/${id || ''}/moderators`)
      .then((response) => response.json())
      .then((results) => {
        setModerators(results);
      })
      .catch(console.error);
  };

  const reloadEvent = () => {
    if (id === '') {
      return;
    }
    dispatch<any>(getEventInfo(id))
      .then(console.log)
      .catch(console.log)
  };

  useEffect(() => {
    reloadEvent();
    setSelected("Information");
    setScreen("Information");
    getEventModerators();
  }, []);

  useEffect(() => {
    reloadEvent();
    setSelected("Information");
    setScreen("Information");
    getEventModerators();
  }, [id]);

  function selectScreen(screen: string) {
    switch (screen) {
      case "Feed":
        return <Posts whereIs={'event'} /*postInput={true}*/ eventId={id} tabActive={5} clubId={undefined} username={undefined} ChatServiceInstance={ChatServiceInstance} />;
        break;
      case "Information":
        return <>
          <EventViewInformation event={event} getEventModerators={getEventModerators} moderators={moderators} setModerators={setModerators} />
          <Posts whereIs={'event'} /*postInput={true}*/ eventId={id} tabActive={5} clubId={undefined} username={undefined} ChatServiceInstance={ChatServiceInstance} />
        </>
        break;
      case "Members":
        return <EventPaticipanting id={id} event={event} getEventModerators={getEventModerators} moderators={moderators} ChatServiceInstance={ChatServiceInstance} />;
        break;
      default:
        return <></>;
        break;
    }
  }

  selectScreen("Feed");

  return (
    <>
      <div className="club-view-navbar">
        {/*<div
          className={
            selected === "Feed" ? "club-view-row-selected" : "club-view-row"
          }
          id="left-rad-up"
          onClick={() => {
            setSelected("Feed");
            setScreen("Feed");
          }}
        >
          <div className={"section-label robotocondensed-normal-white-16px"}>
            Feed
          </div>
        </div>*/}
        <div
          className={
            selected === "Information"
              ? "club-view-row-selected"
              : "club-view-row"
          }
          onClick={() => {
            setSelected("Information");
            setScreen("Information");
          }}
        >
          <div className={"section-label robotocondensed-normal-white-16px"}>
            {t('event__information')}
          </div>
        </div>

        <div
          className={
            selected === "Members" ? "club-view-row-selected" : "club-view-row"
          }
          onClick={() => {
            setSelected("Members");
            setScreen("Members");
          }}
        >
          <div className={"section-label robotocondensed-normal-white-16px"}>
            {t('event__participanting')}
          </div>
        </div>
      </div>
      {selectScreen(screen)}
    </>
  );
}
export default Index;
