export const handleOnChangeInput = <T>(
    e: React.ChangeEvent<HTMLInputElement>,
    inputName: string,
    state: T,
    setState: React.Dispatch<React.SetStateAction<T>>,
) => setState({
    ...state,
    [inputName]: e.target.value,
})

export const handleOnChangeSelect = <T>(
    e: React.ChangeEvent<HTMLSelectElement>,
    inputName: string,
    state: T,
    setState: React.Dispatch<React.SetStateAction<T>>,
) => setState({
    ...state,
    [inputName]: e.target.value,
});

export interface FieldError {
    hasError: boolean,
    message: string,
  }
  
export const defaultFieldError = (): FieldError => ({
    hasError: false,
    message: '',
});

export const checkString = (term: string) => term.length > 0;