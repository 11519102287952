export const mapPosts = (posts: any []) =>
        posts.map((post) => ({
            id: post.post_id,
            type: (post.type as string).toLowerCase(),
            emoji: post.reactions?.length || 0,
            comments: post.comments?.length || 0,
            nameProfile: post.author_name,
            infoPost: post,
            link: post.link,
            nameReach: post.reach_name,
            textPost: post.content,
            imageProfile: post.author_avatar ||
                "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
            eventName: post.eventName,
            eventTimestamp: post.eventTimestamp,
            eventLocation: post.eventLocation,
            eventCover: post.eventCover,
            author: post.author,
            authorRole: post.author_role,
        }))     