import React, { useEffect, useState } from "react";
import "../../containers/SetupSchool/styles.scoped.css";
import { Advisor } from "./Communities";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WHITE, LAVENDER } from '../../components/ClubIconsNavBar';
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { Backend, MicroServices } from "../../helpers/backendHelper";
import { useSelector } from "react-redux";

function CardCommunity(props: any) {
  const { t, i18n } = useTranslation();
  function handleOnClickAddAdvisor(e: any) {
    props.handleOnClickAdv(e, props.title, props.id, props.name);
  }

  const [word, setWord] = useState<'' | 'More'>('');
  const [updateTitle, setUpdateTitle] = useState<boolean>(false);
  const [clubHasCode, setClubHasCode] = useState(false);

  const organization = useSelector(
    (state: any) => state.getOrgs.organizationInfo
  );

  useEffect(() => {
    reloadHasCode();
    if (props.title === 'Staff' && props.principal) {
      const principalAdv = !props.advisors.some((adv: any) => adv.email === props.principal.email);
      if (principalAdv) {
        console.log(principalAdv, props.id, [props.principal, ...props.advisors])
        props.updateAdvisors(props.id, [props.principal, ...props.advisors])
      }

      const invitePrinAdv = props.advisors.find((adv: any) => adv.email === props.principal.email);

      if (invitePrinAdv && !invitePrinAdv.name) {
        const advs = props.advisors.filter((adv: any) => adv.email !== props.principal.email);
        props.updateAdvisors(props.id, [props.principal, ...advs])
      }
    }
  }, [props]);

  useEffect(() => {
    if (props.club_id) {
      Backend(
        MicroServices.Invite,
        `/invites?pending=${organization.status.startsWith('P')}&club_id=${props.club_id}`
      )
        .then((response) => response.json())
        .then((results) => {
          results.find((invite: any) => !invite.role.includes('ADVISOR')) ? setWord('More') : setWord('')
        });
    }
  }, [props.advisors, props.invitesSend])

  const reloadHasCode = () => {
    if (props.club_id) {
      Backend(
        MicroServices.Club,
        `/clubs/${props.club_id}/code`
      )
        .then((response) => response.json())
        .then((results) => {
          setClubHasCode('code' in results)
        })
    }
  }

  const buttonText = () => {
    return (props.title.includes('Class')) ?
      `Invite ${word} Students` : `Invite ${word} ${props.title}`;
  }

  const handleDeleteAdv = (e: any, adv: Advisor) => {
    e.preventDefault()
    if (props.advisors.length <= 1) return

    Backend(
      MicroServices.Invite,
      `/invites/kill`,
    {
        method: "DELETE",
        body: JSON.stringify({
          club_id: props.club_id,
          email: adv.email
        }),
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then((response) => {
        if (response.status === 201) {
          props.updateAdvisors(props.id, props.advisors.filter((item: any) => item.email !== adv.email))
        } else {
          toast.error(t('toast_errorDeletingAdvisor'))
        }
      })
      .catch((error) => {
        toast.error(t('toast_errorDeletingAdvisor'))
        console.log(error)
      })
  }

  return (
    <div
      id={props.id}
      key={props.id}
      className={`card card-${props.active ? "active" : "desactive"}`}
    >
      <div className={`card-header type-${props.title}`}>
        <FontAwesomeIcon
          icon={faUser}
          size='xl'
          color={WHITE}
          className="icon-user  "
        />
        <FontAwesomeIcon
          className={`icon-header icon-${props.icon}`}
          icon={props.icon}
          size='xl'
          color={WHITE}
        />
        <div className="allWidth">
          <div className="rowTitleComm">
            {updateTitle ? (
              <input
                type="text"
                className="ModalCreateComm__titleInput"
                value={props.name}
                autoFocus
                onBlur={() => setUpdateTitle(false)}
                onChange={(e) => props.updateName(e.target.value)}
              />
            ) : (
              <div onClick={() => setUpdateTitle(true)} className="rowTitleComm">
                <p>{props.name}</p>
                <FontAwesomeIcon
                  icon="pen"
                  size="sm"
                  color={WHITE}
                  className="iconReactionLeft"
                />
              </div>
            )}
            
          </div>
          <span>{props.desc}</span>
        </div>
      </div>

      {props.advisors.length > 0 ? (
        <div className="staff-inputs">

          <div className="row">
            <label htmlFor="">
              {props.advisors.length === 1
                ? t('setupSchool__advisor')
                : `${props.advisors.length} ${t('setupSchool__advisors')}`}
            </label>
          </div>

          {props.advisors.map((adv: any) => (
            <div className="row" key={JSON.stringify(adv)}>
              <input disabled={true} type="text" defaultValue={`${adv.firstName || adv.firstname || adv.name || ''} ${adv.lastName || adv.lastname || ''}`} />
              {props.advisors.length > 1 ? (
                <FontAwesomeIcon
                  icon="times"
                  size='xl'
                  color={WHITE}
                  className="icon-times-white  "
                  onClick={(e) => handleDeleteAdv(e, adv)}
                />
              ) : (<></>)}
            </div>
          ))}

        <div className="card-adv-label">
          Please invite an invited member of Staff to be an Advisor.
        </div>

        </div>
      ) : (
        <div className="card-desc">
          Please invite an invited member of Staff to be an Advisor.
        </div>
      )}

      <div className="add-advisor">
        <button
          name="btn-addAdvisor"
          className="btnAddAdvisor"
          onClick={handleOnClickAddAdvisor}
        >
          <FontAwesomeIcon
            icon="user-plus"
            size='xl'
            color={WHITE}
            className="icon-user  "

          />
          <p>{t('editInformation__addAdvisor')}</p>
        </button>
      </div>

      <button
        name="btn-FillMembers"
        className={`buttonSendInvites ${props.btnActive ? "active" : "inactive"
          } `}
        onClick={(e) => props.handleOnClick(e, props.title, props.id, props.name)}
      >
        {buttonText()}
      </button>
      <div className="invites-count">
        {(props.invitesCount - props.advisors.length) > 0 ? `${props.invitesCount - props.advisors.length} invites sent` : ''}
        <br/>
        {clubHasCode && 'Club Link was generated'}
      </div>
    </div>
  );
}

export default CardCommunity;
